import React, { useState, useEffect } from 'react';
import FormGenerate from './FormGenerate';

const FormElementBoxLine = ({data}) => {
  var dataList = data.content;

  return (
    <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-inline">
        {
            dataList.map((data, key)=>{
                return <FormGenerate data={data} key={key}/>
            })
        }
        </div>
    </div>
  )
}

export default FormElementBoxLine;
