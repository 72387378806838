import React, { useState, useEffect, useRef } from 'react';
import Api from '../Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Link } from "react-router-dom";
import UserProfileCardChangePassword from '../UserProfileCardChangePassword';
import ProfileOverview  from './ProfileOverview';
import UploadFirebase from '../UploadFirebase';
import ClinicSelector from './ClinicSelector';
import ImageUri from '../ImageUri';



UIkit.use(Icons);

const ConsultProfileCard = (props) => {
  const [profile, setProfile] = useState(true);
  const [showPoup, setShowPoup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  
  
  async function loadProfile() {
    const profileTxt = localStorage.getItem('consultProfileData')
    const profileData = JSON.parse(profileTxt);

    if((profileData.clinicCode == null) || profileData.clinicCode == "") {
      openClinic();
    }

    setProfile(profileData);
  }

  function openClinic() {
    setShowPoup(true);
  }

  function closeClinic() {
    setShowPoup(false);
  }

  function closeAlert() {
    alert("close profile");
    setShowAlert(false);
  }

  async function onSelectClinic(data) {
    profile.clinicName = data.stk_name;

    var { result } = await Api.consult("ConsultUpdateClinic", {
      consultId: profile.consultId,
      clinicCode: data.stock,
      clinicName: data.stk_name,
    });

    if(result) {
      var profileTxt = localStorage.getItem('consultProfileData');
      const profileData = JSON.parse(profileTxt);
      profileData.clinicCode = data.stock;
      profileData.clinicName = data.stk_name;

      localStorage.setItem('consultProfileData', JSON.stringify(profileData));

      setAlertMessage("บันทึกเสร็จสิ้น");
      setShowAlert(true);
    }
  }

  useEffect(() => {
    loadProfile();
  }, []);

    return (<div className="uk-card uk-card-default">
      <div uk-grid="" className="uk-grid-small uk-padding-small">
        <div className="uk-width-1-2@s uk-width-1-1 uk-text-left@s uk-text-center">
          <div className="uk-inline">
          <span style={{backgroundColor: '#16d39a ', width: 20, height: 20, borderRadius: 10, position: 'absolute', right: 10, top: 10, border: '2px solid #FFF', zIndex: 1, display: 'inline-block'}}></span>
          <div className="uk-background-cover uk-inline uk-background-muted uk-display-inline-block d-user-profile-card-image uk-transition-toggle" style={{ backgroundImage: 'A' === '' ? false : '' }}>
            
            <label className="uk-position-center uk-transition-fade" style={{backgroundColor: `rgba(255,255,255,0.8)`, padding: '5px 10px', cursor: 'pointer'}}>
              เลือก
              <UploadFirebase  />
            </label>
          </div>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-1 uk-text-left@s uk-text-center">
          <div className="uk-margin-small-top"><b></b></div>
          
        </div>
      </div>
      <div uk-grid="" className="uk-grid-small uk-padding-small uk-padding-remove-top uk-margin-remove-top">
        <div className="uk-width-1-1">
          <div>Name: {profile.doctorName}</div>
          <div>Role: {profile.role}</div>
          <div>Clinic : {profile.clinicName}&nbsp;<a onClick={openClinic}>แก้ไข</a></div>
        </div>
        
      </div>
      <ClinicSelector show={showPoup} onClose={closeClinic} onSelect={onSelectClinic} />
    </div>
  )
};

export default ConsultProfileCard;