import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import Api from "./Api";
import moment from "moment";
import UIkit from "uikit";

const DrawingOnImage = (props) => {
  const { onLoading, onReset, isDisplayOnly } = props;
  const [detailImage, setDetailImage] = useState();
  const canvasRef = useRef(null);
  const canvasHistory = useRef([]);
  const [description, setDescription] = useState("");
  const inputRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(1);
  const [canvasHeight, setCanvasHeight] = useState(1);
  const [isShowCanvas, setIsShowCanvas] = useState(false);

  var paperWidth = 333;
  var paperHeight = 500;

  useEffect(() => {
    canvasRef.current = new fabric.Canvas(canvasRef.current);

    fabric.Image.fromURL(props.detailImage?.file_data, (img) => {
      console.log(img.width);
      console.log(img.height);
      console.log(paperWidth / img.width);
      console.log(paperHeight / img.height);

      var ratio = img.height / img.width;
      paperHeight = paperWidth * ratio;

      canvasRef.current.setBackgroundImage(
        img,
        canvasRef.current.renderAll.bind(canvasRef.current),
        {
          scaleX: paperWidth / img.width,
          scaleY: paperHeight / img.height,
        }
      );
    });

    canvasRef.current.isDrawingMode = true;
    canvasRef.current.freeDrawingBrush.color = "black";
    canvasRef.current.freeDrawingBrush.width = 3;

    canvasRef.current.on("path:created", (e) => {
      canvasHistory.current.push(e.path);
    });
  }, []);

  useEffect(() => {
    if (props.detailImage) {
      setIsShowCanvas(true);
    } else {
      setIsShowCanvas(false);
    }
  }, [props.detailImage, setIsShowCanvas]);

  useEffect(() => {
    if (isShowCanvas) {
      canvasRef.current.isDrawingMode = true;
    } else {
      canvasRef.current.isDrawingMode = false;
    }
  }, [canvasRef.current, isShowCanvas]);

  useEffect(() => {
    if (!isDisplayOnly) {
      canvasRef.current.isDrawingMode = true;
    } else {
      canvasRef.current.isDrawingMode = false;
    }
  }, [canvasRef.current, isDisplayOnly]);


  const undoDrawing = () => {
    if (canvasHistory.current.length > 0) {
      const removedPath = canvasHistory.current.pop();
      canvasRef.current.remove(removedPath);
      canvasRef.current.renderAll(); // เรียก renderAll() บน canvasRef.current
    }
  };
  // console.log(detailImage);
  const resetImage = async () => {
    try {
      onLoading(true);
      const { list } = await Api.consult("MemberFormFilesList", {
        file_name: props?.detailImage?.file_name,
      });
      var URL;
      list.map((value, key) => {
        URL = value.file_data;
      });
      if (list && list.length > 0) {
        onReset(list[0]);
      }
      // console.log(URL);
      setDrawImage(URL);
    } catch (error) {
      canvasRef.current.clear();
      onReset(undefined);
      console.error(error);
    } finally {
      onLoading(false);
    }
  };

  const setDrawImage = async (URL) => {
    canvasRef.current.clear();
    fabric.Image.fromURL(URL, (img) => {
      //painImg
      canvasRef.current.setBackgroundImage(
        img,
        canvasRef.current.renderAll.bind(canvasRef.current),
        {
          scaleX: paperWidth / img.width,
          scaleY: paperHeight / img.height,
        }
      );
    });
  };

  const saveImage = async () => {
    // const canvas = canvasRef.current;
    try {
      if (!isShowCanvas) return;
      if (canvasRef.current) {
        var dataURL = canvasRef.current.toDataURL("image/png"); // บันทึกรูปเป็น Data URL (base64)
      }
      onLoading(true);
      const profileTxt = localStorage.getItem("consultProfileData");
      const profileData = JSON.parse(profileTxt);
      const result = await Api.consult("MemberFormTxnFilesAdd", {
        id: props?.detailImage?.id,
        file_data: dataURL,
        // updated_by: profileData.consultId,
      });

      if (result.result) {
        UIkit.notification({
          message: "บันทึกสำเร็จ",
          status: "success",
          pos: "top-right",
          timeout: 5000,
        });

        props.onClose();
      } else {
        UIkit.notification({
          message: "ไม่สำเร็จ",
          status: "danger",
          pos: "top-right",
          timeout: 5000,
        });
      }
      onLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      onLoading(false);
    }
  };

  const handleClickBrowseFile = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    // 👇️ reset file input
    event.target.value = null;

    setDrawImage(URL.createObjectURL(fileObj));
  };

  return (
    <>
      <div className="form-modal-container">
        <div
          className="form-modal-dialog-container"
          style={{
            padding: 0,
            width: "100vh",
          }}
        >
          <div className="form-modal-dialog-header">
            <h1 className="uk-modal-title">{props.detailImage?.file_name}</h1>
            <div className="form-modal-right-header">
              <button
                className="uk-button-default form-modal-close-button"
                type="button"
                onClick={() => props.onClose()}
              >
                <span uk-icon="icon: close; ratio: 1"></span>
              </button>
            </div>
          </div>
          <div
            className="form-modal-dialog-body"
            style={{ padding: "0px 20px" }}
          >
            <p>{description}</p>
            <div className="uk-flex uk-flex-column">
              {!isDisplayOnly && (
                <div className="uk-flex  uk-width-1-1  uk-flex-right">
                  <button
                    className="uk-button uk-button-primary"
                    onClick={handleClickBrowseFile}
                  >
                    แนบไฟล์
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="uk-button uk-button-primary"
                    onClick={resetImage}
                  >
                    เริ่มใหม่
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="uk-button uk-button-primary"
                    onClick={undoDrawing}
                  >
                    ย้อนกลับ
                  </button>
                </div>
              )}

              <div
                className="uk-flex uk-flex-center"
                style={{ margin: "0px 0" }}
              >
                <canvas
                  className="uk-width-1-1"
                  ref={canvasRef}
                  height={paperHeight}
                  width={paperWidth}
                />
              </div>
            </div>
          </div>
          <div className="form-modal-dialog-footer uk-text-right">
            <button
              className="uk-button uk-button-default uk-modal-close"
              style={{ margin: 10, border: "5px solid red" }}
              type="button"
              onClick={() => props.onClose()}
            >
              ปิด
            </button>
            {!isDisplayOnly && (
              <>
                &nbsp;&nbsp;
                <button
                  className="uk-button uk-button-primary uk-modal-close"
                  style={{ margin: 10 }}
                  type="button"
                  onClick={() => saveImage()}
                >
                  บันทึก
                </button>
              </>
            )}
            &nbsp;&nbsp;
          </div>
        </div>
      </div>
      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
      />
    </>
  );
};

export default DrawingOnImage;
