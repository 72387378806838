import React, {Component} from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import HeaderSub from '../../Components/Member/HeaderSub';
import SmartHealthCompanyProfileCard from '../../Components/Member/SmartHealthCompanyProfileCard';
import ReportReport from '../../Components/Member/ReportReport';

UIkit.use(Icons);


export default class Report extends Component {
  state = {
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="Report" />
      <div className="uk-margin-small-top">
        <SmartHealthCompanyProfileCard />
      </div>
      <div className="uk-margin-top">
        <ReportReport />
      </div>
    </div>
  }
};
