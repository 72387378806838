import React, { Component } from 'react';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import $ from 'jquery';
import __ from './Language';

export default class LoginForm extends Component {

  state = {
    username: '',
    password: '',
    accept: false
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = () => {

  }


  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  login = (e) => {
    e.preventDefault();

    try {
      this.props.onSubmit({username : this.state.username, password : this.state.password});
      
    } catch(e) {
      UIkit.notification(`<span uk-icon='icon: close'></span> ${__(e.message)}`);
    }

  }

  render() {
    return <div>
      {
        this.props.loading ?
        <div className="uk-text-center uk-padding-small">
        <span uk-spinner=""></span>
      </div>
      :
      <form onSubmit={this.login} className="uk-margin-top uk-text-left" >
        <div className="uk-inline uk-width-1-1">
          <div>{__('ชื่อผู้ใช้')}<span className="uk-text-danger">*</span></div>
          <input className="uk-input" type="text"  name="username" onChange={this.updateValue} />
        </div>
        <div className="uk-inline uk-width-1-1 uk-margin-small-top">
          <div>{__('รหัสผ่าน')}<span className="uk-text-danger">*</span></div>
          <input className="uk-input" type="password"  name="password" onChange={this.updateValue} />
        </div>
        
        <button className="uk-button uk-button-primary uk-width-1-1 uk-margin-top" type="submit">{__('เข้าสู่ระบบ')}</button>
        
      </form>
      }

    </div>
  }
};
