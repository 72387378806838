import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe'
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import UserProfileCard from '../../Components/Member/UserProfileCard';
import DateTimePicker from "../../Components/DatePicker.js";
import moment from 'moment';
UIkit.use(Icons);

const ConsultAppointmentList = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataList, setDataList] = useState([]);

  async function listGet() {
    /*var { list } = await Api.consult("AppointmentList2", {
      dateStart: "1234"
    });

    setDataList(list);*/
    setLoadingApi(false);
  }

  function addForm() {
    window.location.href = '/consult/formAdd?formType=20';
  }

  useEffect(() => {
    listGet();
  });

  return (
     <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">คนไข้ - นายธนกิจ ศรีชัย</div>
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-1-5@m uk-width-1-1">
              
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
              
          </div>
          
          <div className="uk-width-1-5@m uk-width-1-1">
          </div>
          
        </div>
        <div uk-grid="" className="uk-flex">
          <div id="videoCall" className="uk-width-1-3@m uk-width-1-1" >
            <UserProfileCard/>
          </div>
          <div className="uk-width-2-3@m uk-width-1-1 uk-flex uk-flex-column">
            <div className="uk-card">
                <div className="uk-card-header">
                    <h3 className="uk-card-title">ข้อมูลคนไข้</h3>
                </div>
                <div className="uk-card-body">
                  <ul className="uk-tab"  data-uk-tab="{connect:'#memberTab'}">
                      <li className="uk-active"><a href="#">รายละเอียดการนัดหมาย</a></li>
                      <li><a href="#">แบบฟอร์มเภสัช</a></li>
                  </ul>
                  <ul id="memberTab" className="uk-switcher uk-margin">
                    <li><a href="#" id="autoplayer" data-uk-switcher-item="next"></a>
                      <div uk-grid="" className="uk-flex">
                        <div className="uk-width-1-5@m uk-width-1-1 dataLabel" >
                          คนไข้
                        </div>
                        <div className="uk-width-4-5@m uk-width-1-1 dataText" >
                          นาย รณรงค์ สองสาม
                        </div>
                        <div className="uk-width-1-5@m uk-width-1-1 dataLabel" >
                          วันที่
                        </div>
                        <div className="uk-width-4-5@m uk-width-1-1 dataText" >
                          23 มีนาคม 2566 14.30
                        </div>
                        
                        
                      </div>
                    </li>
                    
                    <li>
                    <div className="uk-overflow-auto">
                    <div uk-grid="" className="uk-flex-middle">
                    <div className="uk-width-1-2@m uk-width-1-1">
                          <select>
                            <option value="1"> แบบฟอร์มเภสัช</option>
                            <option value="2"> แบบฟอร์ม FAMMED</option>
                          </select>
                      </div>
                      
                      <div className="uk-width-1-2@m uk-width-1-1">
                        <button className="uk-button uk-button-primary" >ค้นหา</button>&nbsp;
                        <button className="uk-button uk-button-primary" onClick={addForm} >เพิ่มแบบฟอร์ม</button>
                      </div>
                      
                    </div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ประเภทฟอร์ม</th>
                <th>ดูรายละเอียด</th>
              </tr>
            </thead>
            <tbody>
              {
                loadingApi == true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                dataList.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :
                dataList.map((data, key)=>{
                  return <tr key={`row${data.id}`}>
                    <td>{key+1}</td>
                    <td>
                      {moment(data.dateadd).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {moment(data.dateadd).format('HH:mm น.')}
                    </td>
                    <td>
                    Ischemic Stroke F/U form
                    </td>
                    <td>
                      <a href="" >ดู</a> | <a href="" >แก้ไข</a>
                    </td>
                    
                  </tr>
                })
              }
            </tbody>
          </table>
          </div>
          
                    </li>
                  </ul>
                </div>
                <div className="uk-card-footer">
                  
                </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="uk-overflow-auto">
        
          </div>
      </div>
    </div>
  )
}

export default ConsultAppointmentList;