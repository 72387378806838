import React from 'react';

import FormElementTitle from './FormElementTitle';
import FormElementSubTitle from './FormElementSubTitle';
import FormElementLine from './FormElementLine';
import FormElementTextBox from './FormElementTextBox';
import FormElementBoxLine from './FormElementBoxLine';
import FormElementLabel from './FormElementLabel';
import FormElementTextArea from './FormElementTextArea';
import FormElementRadioGroup from './FormElementRadioGroup';
import FormElementRadio from './FormElementRadio';
import FormElementCheckBox from './FormElementCheckBox';
import FormElementSpace from './FormElementSpace';
import FormElementTableGroupYesNoReason from './FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from './FormElementTableGroupTextBox';
import FormElementTableGroupLevel from './FormElementTableGroupLevel';

const FormGenerate = ({data}) => {
    if(data.formElementType == "title") {
        return <FormElementTitle data={data} />;

    } else if(data.formElementType == "subTitle") {
        return <FormElementSubTitle data={data} />;

    } else if(data.formElementType == "hrLine") {
        return <FormElementLine data={data} />;
        
    } else if(data.formElementType == "textbox") {
        return <FormElementTextBox data={data} />;
        
    } else if(data.formElementType == "boxLine") {
        return <FormElementBoxLine data={data} />;
        
    } else if(data.formElementType == "label") {
        return <FormElementLabel data={data} />;
        
    } else if(data.formElementType == "textarea") {
        return <FormElementTextArea data={data} />;
        
    } else if(data.formElementType == "radioGroup") {
        return <FormElementRadioGroup data={data} />;
        
    } else if(data.formElementType == "radio") {
        return <FormElementRadio data={data} />;
        
    } else if(data.formElementType == "checkbox") {
        return <FormElementCheckBox data={data} />;
        
    } else if(data.formElementType == "space") {
        return <FormElementSpace data={data} />;
        
    } else if(data.formElementType == "tableGroupYesNoReason") {
        return <FormElementTableGroupYesNoReason data={data} />;
        
    } else if(data.formElementType == "tableGroupTextBox") {
        return <FormElementTableGroupTextBox data={data} />;
        
    } else if(data.formElementType == "tableGroupLevel") {
        return <FormElementTableGroupLevel data={data} />;
        
    } 
    
    else {
        return <div></div>;
    }
}

export default FormGenerate;
