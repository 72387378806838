import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import FormPaper from "../../Components/Consult/FormPaper";
import moment from "moment";

UIkit.use(Icons);

const PhamacyImage = (props) => {
  const [drugPic, setDrugPic] = useState([]);

  let params = decodeURIComponent(props.location.search);

  console.log(params);

  const query = new URLSearchParams(params);
  const name = query.get("name");
  const code = query.get("code");
  const hn = query.get("hn");
  const patientName = query.get("patientName") || "";
  const howTo = query.get("howTo") ? JSON.parse(query.get("howTo")) : "";
  const amt = query.get("amt");

  async function initComponent() {
    var picList = await getDrugPicture(code);
    setDrugPic(picList);
  }

  async function getDrugPicture(code) {
    var result = await Api.consult("GetDrugDescription", {
      drugCode: code,
    });
    var pic = result.data.pic;
    var pic_package = result.data.pic_package;
    var resultPic = "";
    var resultPicPackage = "";

    if (pic != "") {
      resultPic = "data:image/png;base64," + pic;
    } else {
      resultPic = no_img;
    }

    var picList = [];
    picList.push(resultPic);
    
    if (pic_package != "") {
      resultPicPackage = "data:image/png;base64," + pic_package;
      picList.push(resultPicPackage);
    } 

    return picList;
  }

  useEffect(() => {
    initComponent();
  }, []);

  return (
    <div className="uk-card uk-card-default uk-padding-small">
      <div className="d-card-header">
        <span>
          ผู้ป่วย:&nbsp;&nbsp;&nbsp;{patientName}&nbsp;&nbsp;&nbsp;HN
          :&nbsp;&nbsp;&nbsp;{hn}
        </span>{" "}
      </div>

      <table className="uk-table uk-table-small form-table">
        <tbody>
          <tr>
            <td>
              <span className="uk-text-large">ชื่อยา:</span>
            </td>
            <td>
              <span className="uk-text-large">{name}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="uk-text-large">จำนวน:</span>
            </td>
            <td>
              <span className="uk-text-large">{amt}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="uk-text-large">วิธีการใช้:</span>
            </td>
            <td>
              <span className="uk-text-large">
                <p>{howTo.sig}</p>
                <p>{howTo.sigadd}</p>
                <p>{howTo.signote}</p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>รูปยา </th>
              </tr>
            </thead>
            <tbody>
              {drugPic.length === 0 ? (
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">
                      ไม่พบข้อมูล
                    </div>
                  </td>
                </tr>
              ) : (
                drugPic.map((data, key) => {
                  return (
                    <tr key={`row${key}`}>
                      <td style={{ textAlign: "center" }}>
                        <img src={data} width={500} />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  /*
  return (
    <div className="uk-card uk-card-default uk-padding-small">
       <div className="d-card-header">คนไข้ - นายธนกิจ ศรีชัย</div>
       <div uk-grid="" className="uk-flex-middle">
         <div className="uk-width-1-1@m uk-width-1-1">
             ขณะนี้แพทย์กำลังให้คำปรึกษาคนไข้ท่านอื่นอยู่ค่ะ กรุณาปิดหน้าจอ และพยาบาลจะรีบติดต่อกลับไปค่ะ
         </div>
         
       </div>
     </div>
 )*/
};

export default PhamacyImage;
