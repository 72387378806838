import React, { useState, useEffect } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import DateTimePicker from "../../Components/DatePicker.js";
import moment from 'moment';
import MemberSelector from '../../Components/Consult/MemberSelector';
import SlotSelector from '../../Components/Consult/SlotSelector';
import AlertMessage from '../../Components/AlertMessage';

UIkit.use(Icons);

const AppointmentAdd = (props) => {
  const [showMember, setShowMember] = useState(false);
  const [showSlot, setShowSlot] = useState(false);
  const [clinicCode, setClinicCode] = useState(false);
  const [dataSave, setDataSave] = useState([]);
  const [memberTxt, setMemberTxt] = useState("");
  const [slotTxt, setSlotTxt] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  async function onSelectMember(data) {
    dataSave.memberId = data.id;
    dataSave.hn = data.hn;

    var text = data.name + ", HN : " + data.hn;
    setMemberTxt(text);
  }

  async function onSelectSlot(data) {
    dataSave.appointmentDate = data.appointmentDate;
    dataSave.slotId = data.slot_id;

    var text = data.appointmentDate + ", เวลา : " + data.start_time + " - " + data.end_time;
    setSlotTxt(text);
  }

  function closeAlert() {
    //setShowAlert(false);
    window.location.href = '/consult';
  }

  async function doSave() {
    var { result } = await Api.consult("ConsultAppointmentAdd", {
      appointmentDate: dataSave.appointmentDate,
      clinicCode: dataSave.clinicCode,
      clinicName: dataSave.clinicName,
      consultId: dataSave.consultId,
      consultName: dataSave.consultName,
      hn: dataSave.hn,
      memberId: dataSave.memberId,
      slotId: dataSave.slotId,
    });

    if(result) {
      setAlertMessage("บันทึกเสร็จสิ้น");
      setShowAlert(true);
    }
  }
  
  function openMember() {
    setShowMember(true);
  }

  function closeMember() {
    setShowMember(false);
  }

  function openSlot() {
    setShowSlot(true);
  }

  function closeSlot() {
    setShowSlot(false);
  }

  function init() {
    const profileTxt = localStorage.getItem('consultProfileData')
    const profileData = JSON.parse(profileTxt);

    dataSave.consultId = profileData.doctorId;
    dataSave.consultName = profileData.doctorName;
    dataSave.clinicCode = profileData.clinicCode;
    dataSave.clinicName = profileData.clinicName;

    setClinicCode(profileData.clinicCode);
  }

  useEffect(() => {
    init();
  }, []);

  return (
     <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">เพิ่มใบนัดหมาย</div>
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-1-5@m uk-width-1-1">
              คนไข้ (HN): 
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
              {memberTxt}
          </div>
          <div className="uk-width-3-5@m uk-width-1-1">
            <span></span><button className="uk-button uk-button-primary" onClick={openMember}>เลือกคนไข้</button>
          </div>
          
          
        </div>
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-1-5@m uk-width-1-1">
              เวลา: 
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
              {slotTxt}
          </div>
          <div className="uk-width-3-5@m uk-width-1-1">
            <span></span><button className="uk-button uk-button-primary" onClick={openSlot}>เลือกเวลา</button>
          </div>
          
          
        </div>
        

        <hr />
        <div className="uk-overflow-auto">
        <button className="uk-button uk-button-primary" onClick={doSave}>บันทึก</button> &nbsp; <button className="uk-button uk-button-secondayr">ยกเลิก</button>
          </div>
      </div>
      <MemberSelector show={showMember} onClose={closeMember} onSelect={onSelectMember} />
      <SlotSelector show={showSlot} clinicCode={clinicCode} onClose={closeSlot} onSelect={onSelectSlot} />
      <AlertMessage title={"ข้อความ"} message={alertMessage} show={showAlert} onClose={closeAlert} />
    </div>
  )
}

export default AppointmentAdd;