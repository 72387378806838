import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../Api';
import moment from 'moment';
import numeral from 'numeral';
import Rate from '../Rate';
import FullScreenElement from '../FullScreenElement';
UIkit.use(Icons);

export default class UserStatistic extends Component {

  state = {
    xAxisData: [],
    yAxisDataRating: [],
    yAxisDataAmount: [],
    chat: 0,
    avgMeal: 0,
    datelogin: moment().format('YYYY-MM-DD')
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var { data = [], chat = 0, datelogin } = await Api.member('UserStatistic');

    if (!data) data = [];

    var xAxisData = [];
    var yAxisDataRating = [];
    var yAxisDataAmount = [];

    var _sumMeal = 0;
    for (var i = 0; i < data.length; i++) {
      xAxisData.push(moment(data[i].dateadd).format('dddd'));
      yAxisDataRating.push(data[i].rating);
      yAxisDataAmount.push(data[i].amount);

      _sumMeal += +data[i].amount;
    }

    this.setState({ xAxisData, yAxisDataRating, yAxisDataAmount, chat, avgMeal: (_sumMeal / data.length), datelogin });
  }

  graphRender = () => {
    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        data: ['Rate', 'Meal']
      },

      // Enable drag recalculate
      calculable: true,

      // Horizontal axis
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: this.state.xAxisData,
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        type: 'value',
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      series: [
        {
          name: 'Rate',
          type: 'line',
          stack: 'Total',
          symbol: 'circle',
          smooth: true,
          symbolSize: 20,
          itemStyle: {
            color: '#ffa87d'
          },
          lineStyle: {
            color: '#ffa87d',
            width: 3
          },
          data: this.state.yAxisDataRating.map((data, index) => {
            if (index === (this.state.yAxisDataRating.length - 1)) {
              return {
                value: data,
                symbol: `path://M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z`,
                symbolSize: 35,
                label: {
                  show: true,
                  position: 'top',
                  fontSize: 24
                }
              }
            } else {
              return data;
            }
          })
        },
        {
          name: 'Meal',
          type: 'line',
          symbol: 'emptyCircle',
          itemStyle: {
            color: '#2dcee3',
          },
          lineStyle: {
            width: 2,
            color: '#F6BB42',
            shadowColor: 'rgba(0,0,0,0.5)',
            shadowBlur: 10,
            shadowOffsetX: 8,
            shadowOffsetY: 8
          },
          data: this.state.yAxisDataAmount.map((data, index) => {
            if (index === (this.state.yAxisDataRating.length - 1)) {
              return {
                value: data,
                symbol: `path://M755.188 64c-107.63 0-200.258 87.554-243.164 179-42.938-91.444-135.578-179-243.216-179-148.382 0-268.808 120.44-268.808 268.832 0 301.846 304.5 380.994 512.022 679.418 196.154-296.576 511.978-387.206 511.978-679.418 0-148.392-120.43-268.832-268.812-268.832z`,
                symbolSize: 30
              }
            } else {
              return data;
            }
          })
        }
      ]
    };
    return <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{ height: 350 }}
      theme={"theme_name"}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-user-health-statistic">
      <div className="d-card-header">
        <div className="d-card-header-left">
          STATISTIC
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-user-health-statistic" />
        </div>
      </div>
      <div className="uk-margin-top">
        <div className="uk-grid-small" uk-grid="">
          <div className="uk-text-center uk-width-1-3@s uk-width-1-2">
            <div>
              Chat
              <h2 style={{fontSize: 50, lineHeight: '45px'}}>{this.state.chat}</h2>
            </div>
            <div className="uk-margin-top">
              Avg Meal/day
              <h2 style={{fontSize: 50,  lineHeight: '45px'}}>{numeral(this.state.avgMeal).format('0,0.0')}</h2>
            </div>
          </div>
          <div className="uk-width-1-6 uk-visible@s"></div>
          <div className="uk-width-expand">
            <h4 style={{fontSize: 20, margin: 0}}>Last Login</h4>
            <div>{moment(this.state.datelogin).format("DD MMM YYYY")}</div>
            <h4 className="uk-margin-small-top" style={{fontSize: 20, margin: 0}}>Dietz Rate</h4>
            <div>
              <Rate readonly={true} initialRating={this.state.yAxisDataRating[this.state.yAxisDataRating.length - 1]} stop={5} />
              {' '}
              <span className="uk-text-success">/ {this.state.yAxisDataRating[this.state.yAxisDataRating.length - 1]}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        {this.graphRender()}
      </div>
    </div>
  }
};
