import NovalueCheck from './NoValueCheck';

export default (value) => {

  if(value === 'ปกติ' || value === 'ปกติ') {
    return { 
      title: 'ปกติ',
      color: 'green',
      caption: 'ปกติ',
      suggest: ''
    };
  } else {
    return { 
      title: 'ผิดปกติ',
      color: 'orange',
      caption: 'ผิดปกติ',
      suggest: ''
    };
  }

}