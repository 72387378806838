import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import ImageUri from '../ImageUri';
UIkit.use(Icons);

export default class CampaignReportProfileCard extends Component {
  campaign = {
    name: '',
    caption: '',
    datestart: '',
    datestop: ''
  }

  state = {
    campaign: {
      ...this.campaign
    },
    consultant: []
  }

  componentDidMount() {
    this.campaignProfileGet();
  }

  campaignProfileGet = async () => {
    var { campaign, consultant } = await Api.member('CampaignReportProfileCard', { id: this.props.campaignId });
    this.setState({
      campaign: campaign ? campaign : this.campaign,
      consultant: consultant ? consultant : []
    });
  }

  progressGet = () => {
    var _start = moment(this.state.campaign.datestart);
    var _stop = moment(this.state.campaign.datestop);
    var _now = moment();
    var _dayAll = _start.diff(_stop, 'days');
    var _dayNow = _start.diff(_now, 'days');

    return Math.ceil((_dayNow / _dayAll) * 100);
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding">
      <div uk-grid="" className="uk-flex-middle@s uk-flex-top">
        <div className="uk-width-1-1 uk-width-1-4@l uk-text-center">
          {this.state.campaign.image !== '' && <div className="uk-padding-small"><img src={ImageUri(this.state.campaign.image)} uk-img="" /></div>}
        </div>
        <div className="uk-width-1-1 uk-width-3-4@l">
          <h3>Campaign: {this.state.campaign.name}</h3>
          <p>{this.state.campaign.caption}</p>
          <div uk-grid="" className="uk-child-width-1-1 uk-child-width-1-4@l uk-child-width-1-2@s uk-grid-small">
            <div>
              <div uk-grid="" className="uk-grid-collapse uk-flex-middle uk-grid-match d-card-small d-card-small-datestart">
                <div className="uk-width-1-3 uk-text-center uk-height-1-1">
                  <div className="d-card-small-inside">
                    <span className="icon-event d-card-small-icon"></span>
                  </div>
                </div>
                <div className="uk-width-2-3">
                  <div className="d-card-small-content">
                    <b>Start Date</b>
                    <div>{moment(this.state.campaign.datestart).add(543, 'y').format('DD MMM YY')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div uk-grid="" className="uk-grid-collapse uk-flex-middle uk-grid-match d-card-small d-card-small-datestop">
                <div className="uk-width-1-3 uk-text-center uk-height-1-1">
                  <div className="d-card-small-inside">
                    <span className="icon-event d-card-small-icon"></span>
                  </div>
                </div>
                <div className="uk-width-2-3">
                  <div className="d-card-small-content">
                    <b>Due Date</b>
                    <div>{moment(this.state.campaign.datestop).add(543, 'y').format('DD MMM YY')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div uk-grid="" className="uk-grid-collapse uk-flex-middle uk-grid-match d-card-small d-card-small-people">
                <div className="uk-width-1-3 uk-text-center uk-height-1-1">
                  <div className="d-card-small-inside">
                    <span className="icon-people d-card-small-icon"></span>
                  </div>
                </div>
                <div className="uk-width-2-3">
                  <div className="d-card-small-content">
                    <b>Consultant</b>
                    <div>+ {this.state.consultant.length}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-padding-small">
                {
                  this.state.consultant.map((data, key) => {
                    if(key> 3) return false;
                    return <div key={`consult${key}`} className="uk-background-cover d-smart-health-consult-image" style={{ backgroundImage: (data.image === '' ? false : `url(${ImageUri(data.image)})`) }}>
                    </div>
                  })
                }
              </div>
            </div>
            <div className="uk-margin-top uk-text-center">
              <div className="d-progress">
                <div className="d-progress-inner" style={{ width: this.progressGet() + '%' }}></div>
              </div>
              Project Progress {this.progressGet()}%
              </div>
          </div>
        </div>
      </div>
    </div>
  }
};
