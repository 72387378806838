import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";


import $ from 'jquery';

UIkit.use(Icons);

export default class DateTimePicker extends Component {

  _id = 'dateTimePicker' + +new Date() + this.props.id;
  
  componentDidMount () {
    const { format, callback } = this.props;
    const startDateInput = $('#startDate');
    const endDateInput = $('#endDate');

    startDateInput.datetimepicker({
      format: format || 'DD/MM/YYYY ',
      defaultDate: this.props.date || moment(),
    });

    endDateInput.datetimepicker({
      format: format || 'DD/MM/YYYY',
      defaultDate: this.props.date || moment(),
    });
    startDateInput.on('dp.change', (e) => {
        if (typeof callback === 'function') {
          const selectedDates = {
            startDate: e.date.format('YYYY-MM-DD HH:mm'),
            endDate: endDateInput.data('DateTimePicker').date().format('YYYY-MM-DD HH:mm'),
          };
          endDateInput.data('DateTimePicker').minDate(e.date);
          callback(selectedDates);
        }
      });
  
    endDateInput.on('dp.change', (e) => {
        if (typeof callback === 'function') {
          const selectedDates = {
            startDate: startDateInput.data('DateTimePicker').date().format('YYYY-MM-DD HH:mm'),
            endDate: e.date.format('YYYY-MM-DD HH:mm'),
          };
          startDateInput.data('DateTimePicker').maxDate(e.date);
          callback(selectedDates);
        }
    });
  
  }

  render() {
    return  <div className="d-button-group input-group uk-button-group date uk-width-1-1"
    id={this._id}>
    <div className="input-group input-daterange">
      <input id="startDate" type="text" className="form-control uk-input" />
      <div className="input-group-addon">to</div>
      <input id="endDate" type="text" className="form-control uk-input" />
    </div>
  </div>
  }
};
