import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import DataType from '../DataType';
import Api from '../Api';
import Formular from '../Formular';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class DashboardSex extends Component {
  state = {
    male: 0,
    female: 0,
    maleRisk: 0,
    femaleRisk: 0
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var { female = 0, male = 0, bmiFemale = [], bmiMale = [] } = await Api.member('DashboardSex');


    var checkRisk = function (data) {
      var _riskAmount = 0;
      for (var i = data.length; i-- > 0;) {
        var _value = Formular['bmi'](+data[i].value);

        if (_value && _value.color !== 'green') _riskAmount++;
      }
      return _riskAmount;
    }

    var femaleRisk = checkRisk(bmiFemale || []);
    var maleRisk = checkRisk(bmiMale || []);

    this.setState({
      male: +male || 0,
      female: +female || 0,
      maleRisk: +maleRisk || 0,
      femaleRisk: +femaleRisk || 0
    });

  }

  renderChart = () => {
    var chartOptions = {

      // Add title
      title: {
        text: '',
        subtext: 'BMI Overweight',
        x: 'center',
        padding: 15
      },

      // Setup grid
      grid: {
        x: 40,
        x2: 40,
        y: 45,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // Pointer axis, the axis trigger effective
          type: 'shadow'        // The default is a straight line, optionally: 'line' | 'shadow'
        },
        formatter: function (params) {
          return params[0].name + '<br/>' + params[0].seriesName + ' : ' + (params[1].value + params[0].value) + '<br/>' + params[1].seriesName + ' : ' + params[1].value;
        }
      },

      // Add legend
      legend: {
        selectedMode: false,
        data: ['ทั้งหมด', 'น้ำหนักเกิน']
      },

      // Add custom colors
      color: ['#F98E76', '#99B898'],

      // Enable drag recalculate
      calculable: true,

      // Horizontal axis
      xAxis: [{
        type: 'category',
        data: ['Male', 'Female'],
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        type: 'value',
        boundaryGap: [0, 1],
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      series: [
        {
          name: 'ทั้งหมด',
          type: 'bar',
          stack: 'sum',
          barCategoryGap: '50%',
          itemStyle: {
            normal: {
              color: '#F98E76',
              barBorderColor: '#F98E76',
              barBorderWidth: 6,
              barBorderRadius: 0,
              label: {
                show: false,
                position: 'insideTop'
              }
            }
          },
          data: [(this.state.male - this.state.maleRisk), (this.state.female - this.state.femaleRisk)]
        },
        {
          name: 'น้ำหนักเกิน',
          type: 'bar',
          stack: 'sum',
          itemStyle: {
            normal: {
              color: '#fff',
              barBorderColor: '#F98E76',
              barBorderWidth: 6,
              barBorderRadius: 0,
              label: {
                show: true,
                position: 'top',
                formatter: function (params) {
                  for (var i = 0, l = chartOptions.xAxis[0].data.length; i < l; i++) {
                    if (chartOptions.xAxis[0].data[i] == params.name) {
                      return chartOptions.series[0].data[i] + params.value;
                    }
                  }
                },
                textStyle: {
                  color: '#F98E76'
                }
              }
            }
          },
          data: [this.state.maleRisk, this.state.femaleRisk]
        }
      ]
    };

    return <ReactEcharts
      option={chartOptions}
      style={{ height: 400 }}

      notMerge={true}
      lazyUpdate={true}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-dashboard-sex">
      <div className="d-card-header">
        <div className="d-card-header-left">
          SEX
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-dashboard-sex" />
        </div>
      </div>
      {this.renderChart()}
    </div>
  }
};
