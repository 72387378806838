import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import moment from 'moment';
import Formular from '../../Components/Formular.js';
import FullScreenElement from '../FullScreenElement';
import numeral from 'numeral';
import { data } from 'jquery';

UIkit.use(Icons);

export default class UserRiskMetric extends Component {

  state = {
    data: this.props.data,
    filterColor: 'green,yellow,orange,red'
  }

  componentDidMount() {
    var _data = {};
    for(var i=this.props.data.length; i-->0;) {
      _data[this.props.data[i].name] = this.props.data[i];
    }

    this.setState({data: _data});
  }


  cardTemplate = ({icon, title, caption, suggest, value, color, unit}) => {
    return <div className={"uk-padding-small d-color-white d-background-"+color}>
      <div className="uk-grid-small" uk-grid="">
        <div className="uk-width-1-4">
          {(icon === '' ? '' : <img src={`/assets/${icon}.png`} uk-img="" />)}
        </div>
        <div className="uk-width-expand">
          <h4 className="d-color-white uk-margin-remove-bottom">{title}</h4>
          <div className="uk-text-large">
          {value.indexOf('.') < 0 ? value : numeral(value).format('0,0.00')} <span className="uk-text-small" dangerouslySetInnerHTML={{ __html: unit }}></span></div>
          <p className="uk-text-bold">{caption}</p>
          <p>{suggest && `คำแนะนำ : ${suggest}`}</p>
        </div>
      </div>
    </div>
  }

  cardType = [
    {
      title: 'BMI',
      key: 'bmi',
      icon: 'fat',
      unit: `Kg/m<sup>2</sup>`
    },
    {
      title: 'Blood Pressure',
      key: 'sys/dias',
      icon: 'blood',
      unit: 'mm/Hg'
    },
    {
      title: 'FBS/FPG',
      key: 'fbs/fpg',
      icon: 'sugar',
      unit: 'mg/dL'
    },
    {
      title: 'HBA1C',
      key: 'hba1c',
      icon: 'sugar',
      unit: '%'
    },
    {
      title: 'BUN',
      key: 'bun',
      icon: 'kidney',
      unit: 'mg/dL'
    },
    {
      title: 'Creatinine',
      key: 'creatinine',
      icon: 'kidney',
      unit: 'mg/dL'
    },
    {
      title: 'eGFR',
      key: 'egfr',
      icon: 'kidney'
    },
    {
      title: 'Cholesterol',
      key: 'chol',
      icon: 'bloodlipids',
      unit: 'mg/dL'
    },
    {
      title: 'Triglycerides',
      key: 'tg',
      icon: 'bloodlipids'
    },
    {
      title: 'HDL',
      key: 'hdl',
      icon: 'bloodlipids',
      unit: 'mg/dL'
    },
    {
      title: 'LDL',
      key: 'ldl',
      icon: 'bloodlipids',
      unit: 'mg/dL'
    },
    {
      title: 'Uric',
      key: 'uric',
      icon: '',
      unit: 'mg/dL'
    },
    {
      title: 'SGOT',
      key: 'sgot',
      icon: 'liver',
      unit: 'U/L'
    },
    {
      title: 'SGPT',
      key: 'sgpt',
      icon: 'liver',
      unit: 'U/L'
    },
    {
      title: 'Alp',
      key: 'alp',
      icon: '',
      unit: 'U/L'
    },
    {
      title: 'ปอด',
      key: 'lung',
      icon: 'lung'
    },
    {
      title: 'เส้นรอบเอว',
      key: 'waistline',
      icon: 'fat',
      unit: 'นิ้ว'
    }
  ]

  cardRender = () => {
    return <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s" uk-grid="masonry: true">
      {this.cardType.map((data,index)=>{
        if(this.state.data[data.key] && this.state.data[data.key].detail) {
          if(this.state.filterColor.indexOf(this.state.data[data.key].detail.color) >= 0) {
            return <div key={'card'+index}>{this.cardTemplate({ ...this.state.data[data.key].detail, value: this.state.data[data.key].value, title: data.title, icon: data.icon, unit: data.unit || ''})}</div>
          } else {
            return false;
          }
        } else {
          return false;
        }
      })}
    </div>
  }

  selectfilterColorRender = () => {
    return <select className="uk-select uk-width-small" value={this.state.filterColor} onChange={e=>this.setState({filterColor: e.target.value},()=>this.cardRender())} >
      <option value="green,yellow,orange,red">All</option>
      <option value="green">Healthy</option>
      <option value="yellow,orange">Risk</option>
      <option value="red">High Risk</option>
    </select>
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-user-smarthealth-risk-metric">
      <div className="d-card-header">
        <div className="d-card-header-left">
          RISK METRIC
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.props.dataGet}></span>
          <FullScreenElement element="#d-user-smarthealth-risk-metric" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        {this.selectfilterColorRender()}
      </div>
      <div>
        {this.cardRender()}
      </div>
    </div>
  }
};
