import React, {
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const DrugAllergicSection = (props) => {
  const { data } = props;

  return (
    <React.Fragment>
      {data?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px",
            backgroundColor: "#f7e6e6",
            width: "fit-content",
            borderRadius: "8px",
          }}
        >
          <strong style={{ color: "#c62828" }}>ประวัติแพ้ยา</strong>
          {data?.map((item, key) => (
            <DrugAllergicCollapse key={`${item.group}-${item.sym}-${key}`} header={item.group} content={item.sym} />
          ))}
        </div>
      )}
      {data?.length == 0 && "ไม่มีประวัติ"}
    </React.Fragment>
  );
};

const DrugAllergicCollapse = (props) => {
  const [contentHeight, setContentHeight] = useState();
  const [height, setHeight] = useState("20px");
  const [rotate, setRotate] = useState(0);
  const [isCollapse, setIsCollapse] = useState(true);
  const ref = useRef();

  const { header, content } = props;

  const handleCollaspe = useCallback(() => {
    setIsCollapse(!isCollapse);
  });

  useEffect(() => {
    if (isCollapse) {
      setHeight("24px");
      setRotate(0);
    } else {
      setHeight(`${contentHeight + 24}px`);
      setRotate(90);
    }
  }, [isCollapse]);

  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.clientHeight);
    }
  }, [ref]);

  return (
    <div>
      <div style={{ height: height, overflow: "hidden", transition: "0.2s" }}>
        <button
          style={{
            background: "transparent",
            border: "none",
            color: "#d32f2f",
          }}
          onClick={handleCollaspe}
        >
          <div
            style={{
              display: "inline-flex",
              gap: "8px",
            }}
          >
            <span
              uk-icon="icon: triangle-right"
              style={{
                width: "20px",
                borderRadius: "50%",
                backgroundColor: "#d32f2f",
                color: "#f0d1d1",
                transform: `rotate(${rotate}deg)`,
                transition: "0.2s",
              }}
            ></span>
            <strong>{header}</strong>
          </div>
        </button>
        <div style={{ paddingTop: "4px", paddingLeft: "34px" }} ref={ref}>
          {content?.split(/\r\n/g).map((item, idx) => {
            return (
              <span key={idx}>
                {item}
                <br />
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DrugAllergicSection;
