import React, { useState, useEffect, useMemo, useCallback } from "react";

const LIST_OF_MONTH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

export const SavingProfileIPaper = (props) => {
  const { profile } = props;
  const date = new Date();
  return (
    <div style={{ display: "inline-flex", gap: "10px" }}>
      <span>ผู้บันทึก:&nbsp;&nbsp;{profile?.doctorName}</span>
      <span>
        วันที่ {date.getDate()} {LIST_OF_MONTH[date.getMonth()]}{" "}
        {date.getFullYear() + 543}
      </span>
      <span>
        เวลา {(date.getHours() < 10 ? "0" : "") + date.getHours()}:
        {(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()} น.
      </span>
    </div>
  );
};

export const PatientProfileIPaper = (props) => {
  const { memberProfile } = props;

  useEffect(() => {
    console.log(memberProfile);
  }, [memberProfile]);

  const getGender = useMemo(() => {
    if (memberProfile?.sex) {
      if (memberProfile?.sex == "ช") {
        return "ชาย";
      }
      if (memberProfile?.sex == "ญ") {
        return "หญิง";
      }
    }
    return "";
  }, [memberProfile]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "8px",
        fontSize: "14px",
      }}
    >
      <strong>ผู้ป่วย: {memberProfile?.patient_name}</strong>
      <strong>HN: {memberProfile?.hn}</strong>
      <strong>TXN : {memberProfile?.txn}</strong>
      <strong>เพศ : {getGender}</strong>
      <strong>อายุ : {memberProfile?.age}</strong>
      <strong>วันเกิด : {memberProfile?.birthDate_th}</strong>
    </div>
  );
};
