import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import { parse } from "date-fns";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormPPSPaper from "../FormPaper/FormPPSPaper.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormPPS = (props) => {
  const formConstants = {
    formName: "form_pps",
    formType: "30",
  };

  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      hideLoading();
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([patientData, patientRoomRequest]).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];
      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);


  useEffect(() => {
    if (isSubForm)
      if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getTotalScore = useMemo(() => {
    const { data_1n, data_2n, data_3n, data_4n, data_5n } = dataForm;
    const data = [
      data_1n || 0,
      data_2n || 0,
      data_3n || 0,
      data_4n || 0,
      data_5n || 0,
    ];
    return Math.min(...data);
  }, [dataForm]);

  useEffect(() => {
    setDataForm((data) => ({ ...data, data_6n: getTotalScore }));
  }, [getTotalScore, setDataForm]);

  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html)
  }, [dataForm, onAdd]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="แบบประเมินระดับผู้ป่วยที่ได้รับการดูแลแบบประคับประคอง ฉบับสวนดอก" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="(Palliative performance scale for adult Suandok) (PPS Adult Suandok)" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <table
            className="uk-table uk-table-small uk-table-divider form-table table-border"
            style={{
              borderCollapse: "collapse",
              border: "1px solid black !important",
            }}
          >
            <thead>
              <tr>
                <th width="100">ระดับ PPS ร้อยละ </th>
                <th>การเคลื่อนไหว</th>
                <th>การปฏิบัติกิจกรรมและการดำเนินโรค</th>
                <th>การทำกิจวัตรประจำวัน</th>
                <th>การรับประทานอาหาร</th>
                <th>ระดับความรู้สึกตัว</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"100"}
                    label="100"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"100"}
                    label="เคลื่อนไหวปกติ"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"100"}
                    label="ทำกิจกรรมและงานได้ตามปกติและไม่มีอาการของโรค"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"100"}
                    label="ทำได้เอง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"100"}
                    label="ปกติ"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"100"}
                    label="รู้สึกตัวดี"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"90"}
                    label="90"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"90"}
                    label="เคลื่อนไหวปกติ"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"90"}
                    label="ทำกิจกรรมและทำงานได้ตามปกติและมีอาการของโรคบางอาการ"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"90"}
                    label="ทำได้เอง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"90"}
                    label="ปกติ"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"90"}
                    label="รู้สึกตัวดี"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"80"}
                    label="80"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"80"}
                    label="เคลื่อนไหวปกติ"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"80"}
                    label="ต้องออกแรงอย่างมากในการทำกิจกรรมตามปกติและมีอาการของโรคบางอาการ"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"80"}
                    label="ทำได้เอง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"80"}
                    label="ปกติ หรือ ลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"80"}
                    label="รู้สึกตัวดี"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"70"}
                    label="70"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"70"}
                    label="ความสามารถในการเคลื่อนไหวลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"70"}
                    label="ไม่สามารถทำงานได้ตามปกติและมีอาการของโรคอย่างมาก"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"70"}
                    label="ทำได้เอง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"70"}
                    label="ปกติ หรือ ลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"70"}
                    label="รู้สึกตัวดี"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"60"}
                    label="60"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"60"}
                    label="ความสามารถในการเคลื่อนไหวลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"60"}
                    label="ไม่สามารถทำงานอดิเรกหรืองานบ้านได้และมีอาการของโรคอย่างมาก"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"60"}
                    label="ต้องการความช่วยเหลือเป็นบางครั้ง/บางเรื่อง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"60"}
                    label="ปกติ หรือ ลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"60"}
                    label="รู้สึกตัวดี หรือ สับสน"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"50"}
                    label="50"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"50"}
                    label="นั่ง หรือ นอนเป็นส่วนใหญ่"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"50"}
                    label="ไม่สามารถทำงานได้เลยและมีการลุกลามของโรค"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"50"}
                    label="ต้องการความช่วยเหลือมากขึ้น"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"50"}
                    label="ปกติ หรือ ลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"50"}
                    label="รู้สึกตัวดี หรือ สับสน"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"40"}
                    label="40"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"40"}
                    label="นอนอยู่บนเตียงเป็นส่วนใหญ่"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"40"}
                    label="ทำกิจกรรมได้น้อยมากและมีการลุกลามของโรค"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"40"}
                    label="ต้องการความช่วยเหลือเป็นส่วนใหญ่"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"40"}
                    label="ปกติ หรือ ลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"40"}
                    label="รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"30"}
                    label="30"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"30"}
                    label="นอนอยู่บนเตียงตลอดเวลา"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"30"}
                    label="ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"30"}
                    label="ต้องการความช่วยเหลือทั้งหมด"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"30"}
                    label="ปกติ หรือ ลดลง"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"30"}
                    label="รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"20"}
                    label="20"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"20"}
                    label="นอนอยู่บนเตียงตลอดเวลา"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"20"}
                    label="ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"20"}
                    label="ต้องการความช่วยเหลือทั้งหมด"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"20"}
                    label="จิบน้ำได้เล็กน้อย"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"20"}
                    label="รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={getTotalScore.toString()}
                    fieldValue={"10"}
                    label="10"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"10"}
                    label="นอนอยู่บนเตียงตลอดเวลา"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"10"}
                    label="ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"10"}
                    label="ต้องการความช่วยเหลือทั้งหมด"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"10"}
                    label="รับประทานทางปากไม่ได้"
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"10"}
                    label="ง่วงซึม หรือ ไม่รู้สึกตัว +/- สับสน"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"6n"}
                    value={dataForm?.data_1n ? getTotalScore.toString() : ""}
                    fieldValue={"0"}
                    label="0"
                    disabled
                  />
                </td>
                <td>
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"0"}
                    label="เสียชีวิต"
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <FormPPSPaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
            />}
        />
      </div>
    </>
  );
};

export default FormPPS;
