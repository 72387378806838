import React, { useState, useEffect, useCallback } from 'react';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import '../css/dietz.css';

UIkit.use(Icons);

const AlertMessage = (props) => {
  const { title, message, show } = props;

  function onClose() {
    props.onClose();
  }

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [show])

  return (
    <>
      {show ? <div uk-modal="esc-close: false; bg-close: false" className='popup-container'>
        <div className="uk-modal-dialog uk-margin-auto-vertical dialog-popup-container">
          <button className="uk-modal-close-default uk-close" type="button" onClick={onClose} ><span uk-icon="close"></span></button>
          <div className="uk-modal-header">
            <h5 className="uk-modal-title">{title}</h5>
          </div>
          <div className="uk-modal-body">
            {message}
          </div>
          <div className="uk-modal-footer uk-text-right">
            <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={onClose}>ปิด</button>
          </div>
        </div>
      </div> : null}
    </>
  )
}

export default AlertMessage;
