import React, { useState, useEffect } from 'react';
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import '../../css/dietz.css';

UIkit.use(Icons);

const ClinicSelector = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataListOrg, setDataListOrg] = useState([]);
  const [dataList, setDataList] = useState([]);
  const { show } = props;

  async function listGet() {
    var result = await Api.consult("ClinicList", {});
    var { list } = result;

    setDataListOrg(list);
    setDataList(list);
    setLoadingApi(false);
  }

  function filter() {
    const element = document.getElementById('txtSearch');
    
    const searchText = element.value;

    var dataFiltered = [];

    if(searchText != "") {
      dataFiltered = dataListOrg.filter(data=> data.code.indexOf(searchText) !== -1 )

    } else {
      dataFiltered = dataListOrg;
    }

    setDataList(dataFiltered);

  }

  function doSelect(code) {
    var dataSearch = dataListOrg.filter(data=> data.stock == code )

    props.onSelect(dataSearch[0]);

    props.onClose();
    UIkit.modal('#modal-select-clinic').hide();
  }

  function onClose() {
    props.onClose();
  }

  function showPopup() {
    UIkit.modal('#modal-select-clinic').show();
  }

  useEffect(() => {
    listGet();
    
  }, []);

  useEffect(() => {
    if (show) {
        UIkit.modal('#modal-select-clinic').show();
    
    } else {
        UIkit.modal('#modal-select-clinic').hide();
    }
    
  }, [ show ]);

  return (
    <div id="modal-select-clinic" className="uk-modal" >
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default uk-close" type="button" onClick={onClose} ></button>
          <div className="uk-modal-header">
              <h2 className="uk-modal-title">เลือก Clinic</h2>
          </div>
          <div className="uk-modal-body">
            <div>รหัส : <input type="textbox" id="txtSearch" /> <button className="uk-button uk-button-primary" onClick={filter}>ค้นหา</button></div>
            <div className="popupSelectScroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                <thead>
                  <tr>
                    <th>ที่</th>
                    <th>รหัส</th>
                    <th>ห้อง</th>
                    <th>เลือก</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    loadingApi == true ?
                    <tr>
                      <td colSpan="100%">
                        <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                      </td>
                    </tr>
                    :
                    dataList.length === 0 ? 
                    <tr>
                      <td colSpan="100%">
                        <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                      </td>
                    </tr>
                    :
                    dataList.map((data, key)=>{
                      return <tr key={`row${data.code}`}>
                        <td>{key+1}</td>
                        <td>
                          {data.stock}
                        </td>
                        <td>
                          {data.stk_name}
                        </td>
                        <td>
                        <button onClick={() => doSelect(data.stock)}>เลือก</button>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-modal-footer uk-text-right">
              <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={onClose}>Cancel</button>
          </div>
      </div>
    </div>
  )
}

export default ClinicSelector;
