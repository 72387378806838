import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const FormDeliveryWafarineMedicinePaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_deliverywafarinemedicine",
      formType: "29",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="3.3.3 แบบฟอร์มการติดตามการใช้ยาวาร์ฟารินที่จัดส่งทางไปรษณีย์"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={memberProfile} />
          </div>
          <div style={{ display: "inline-flex", gap: "8px", marginTop: "8px" }}>
            <FormElementLabel label="วิธีการให้คำแนะนำยา" mode="view" />
            <FormElementTextBox value={dataForm.data_63} mode="view" />
          </div>
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <FormElementLabel label="ติดตามใบสั่งยาเลขที่" mode="view" />
            <FormElementTextBox value={dataForm.data_62} mode="view" />
          </div>
          <FormElementTitle
            label="ส่วนที่ 1 ข้อมูลพื้นฐานผู้ป่วย"
            mode="view"
          />
          <table
            style={{
              marginTop: "8px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  รายการยาที่ติดตาม
                </th>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "4px" }}>
                    <FormElementLabel
                      value={dataForm.data_1}
                      mode="view"
                      translate={{ 1: "ห้องตรวจ", 2: "Refill ยา" }}
                    />
                    <span hidden={dataForm.data_1 != "1"}>
                      <FormElementTextBox value={dataForm.data_2} mode="view" />
                    </span>
                  </div>
                </td>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  วันที่แพทย์สั่งยา
                </th>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_3}
                    type="date"
                    dateFormat="dd/MM/yyyy"
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={
                    dataForm?.data_61?.length
                      ? dataForm?.data_61?.length + 2
                      : 2
                  }
                >
                  การโทรติดตามโดย เภสัชกร
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={2}
                >
                  ครั้งที่
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={2}
                >
                  ว/ด/ป, เวลา
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={2}
                >
                  ผู้โทรติดตาม
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={4}
                >
                  ผลการติดตาม
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  สำเร็จ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ไม่สำเร็จ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ยกเลิกการติดตาม
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  หมายเหตุ
                </th>
              </tr>
              {dataForm?.data_61?.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    {item.key}
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementTextBox
                      value={item.data_1}
                      type="date"
                      dateFormat={"dd/MM/yyyy, HH:mm"}
                      mode="view"
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementTextBox value={item.data_2} mode="view" />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    {item.data_3 == "1" && "/"}
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    {item.data_3 == "2" && "/"}
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    {item.data_3 == "3" && "/"}
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementTextBox value={item.data_4} mode="view" />
                  </td>
                </tr>
              ))}
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผู้ป่วยได้รับพัสดุ
                </th>
                <td
                  colSpan={7}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      value={dataForm.data_16}
                      translate={{ 2: "ไม่ได้รับพัสดุ", 1: "ได้รับพัสดุ" }}
                      mode="view"
                    />
                    <span hidden={dataForm.data_16 != "2"}>
                      <FormElementTextBox
                        value={dataForm.data_18}
                        mode="view"
                      />
                    </span>
                    <span hidden={dataForm.data_16 != "1"}>
                      <FormElementLabel
                        value={dataForm.data_17}
                        translate={{
                          1: "ครบถ้วนถูกต้องตามใบรายการยา",
                          2: "ไม่ครบหรือไม่ถูกต้องตามใบรายการยา",
                        }}
                        mode="view"
                      />
                    </span>
                    <span
                      hidden={
                        dataForm.data_16 != "1" || dataForm.data_17 != "1"
                      }
                    >
                      <FormElementTextBox
                        value={dataForm.data_60}
                        mode="view"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementTitle
            label="ส่วนที่ 2 ข้อมูลการใช้ยา warfarin"
            mode="view"
          />
          <table
            style={{
              marginTop: "8px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Indication Warfarin
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Target INR
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={2}
                >
                  Lab INR ล่าสุด
                </th>
                <td
                  rowSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      <FormElementTextBox
                        label="INR"
                        value={dataForm.data_19}
                        mode="view"
                      />
                      <FormElementTextBox
                        value={dataForm.data_20}
                        mode="view"
                        type="date"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      <FormElementLabel
                        value={dataForm.data_21}
                        mode="view"
                        translate={{ 1: "Lab รพ.สวนดอก", 2: "Lab นอกรพ." }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_22} mode="view" />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_23} mode="view" />
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementTitle
            label="ส่วนที่ 3 ข้อมูลความร่วมมือในการใช้ยาและอาการไม่พึงประสงค์"
            mode="view"
          />
          <table
            style={{
              marginTop: "8px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ขนาดยาที่ได้รับทางไปรษณีย์
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ขนาดยา Warfarin ที่ผู้ป่วยรับประทานจริง
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div >
                    <FormElementTextBox
                      value={dataForm.data_64}
                      mode="view"
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "4px" }}>
                    <FormElementTextBox
                      label="(Total"
                      value={dataForm.data_24}
                      mode="view"
                    />
                    <FormElementLabel label="mg/wk)" mode="view" />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "4px",
                      flexWrap: "wrap",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_25}
                      translate={{
                        1: "ตามระบุในใบสรุปรายการยา",
                        2: "ไม่ตรงตามระบุ",
                      }}
                      mode="view"
                    />
                    {dataForm.data_25 == "2" && (
                      <React.Fragment>
                        <FormElementTextBox
                          label="(Total"
                          value={dataForm.data_26}
                          mode="view"
                        />
                        <FormElementLabel label="mg/wk)" mode="view" />
                      </React.Fragment>
                    )}
                    {dataForm.data_25 == "2" && (
                      <React.Fragment>
                        <FormElementLabel
                          value={dataForm.data_27}
                          translate={{
                            1: "มีปรับยาจากรพ.อื่น/คลินิก",
                            2: "อื่นๆ",
                          }}
                          mode="view"
                        />
                        {dataForm.data_27 == "1" && (
                          <React.Fragment>
                            <FormElementTextBox
                              label="เมื่อวันที่"
                              value={dataForm.data_28}
                              type="date"
                              dateFormat="dd/MM/yyyy"
                              mode="view"
                            />
                            <FormElementTextBox
                              label="ปรับเป็น"
                              value={dataForm.data_29}
                              mode="view"
                            />
                          </React.Fragment>
                        )}
                        {dataForm.data_27 == "2" && (
                          <FormElementTextBox
                            value={dataForm.data_30}
                            mode="view"
                          />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  ความร่วมมือในการใช้ยา
                </th>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_31}
                    mode="view"
                    translate={{
                      1: "กินยาถูกต้องตามฉลากยาที่ระบุ",
                      2: "ขาดยาลืมกินยา",
                      3: "กินยาไม่ตรงตามฉลากยาที่ระบุ",
                    }}
                  />
                  <span hidden={dataForm.data_31 != "3"}>
                    <FormElementTextBox
                      label="เนื่องจาก"
                      value={dataForm.data_32}
                      mode="view"
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  การใช้ สมุนไพร อาหารเสริม หรือยาอื่นๆ
                </th>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_33}
                    mode="view"
                    translate={{
                      1: "ไม่มี",
                      2: "มี",
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {dataForm.data_33 == "2" && (
                      <div style={{ display: "inline-flex", gap: "8px" }}>
                        {dataForm.data_34 == "1" && "/"}
                        <span>ยา</span>
                        <FormElementTextBox
                          value={dataForm.data_35}
                          mode="view"
                        />
                      </div>
                    )}
                    {dataForm.data_33 == "2" && (
                      <div style={{ display: "inline-flex", gap: "8px" }}>
                        {dataForm.data_36 == "1" && "/"}
                        <span>อาหาร/อาหารเสริม</span>
                        <FormElementTextBox
                          value={dataForm.data_37}
                          mode="view"
                        />
                      </div>
                    )}
                    {dataForm.data_33 == "2" && (
                      <div style={{ display: "inline-flex", gap: "8px" }}>
                        {dataForm.data_38 == "1" && "/"}
                        <span>สมุนไพร</span>
                        <FormElementTextBox
                          value={dataForm.data_39}
                          mode="view"
                        />
                      </div>
                    )}
                    {dataForm.data_33 == "2" && (
                      <div style={{ display: "inline-flex", gap: "8px" }}>
                        {dataForm.data_40 == "1" && "/"}
                        <span>แอลกอฮอล์ </span>
                        <FormElementTextBox
                          value={dataForm.data_41}
                          mode="view"
                        />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  อาการไม่พึงประสงค์จากยา
                </th>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_42}
                    mode="view"
                    translate={{
                      0: "ไม่มี",
                      1: "มี",
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {dataForm.data_42 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_43}
                        mode="view"
                        translate={{
                          1: "ภาวะเลือดออกไม่รุนแรง (มีจ้ำเลือดตามผิวหนัง เลือดกำเดาไหล เลือดออกตามไรฟันมากผิดปกติ)",
                        }}
                      />
                    )}
                    {dataForm.data_42 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_44}
                        mode="view"
                        translate={{
                          1: "ภาวะเลือดออกที่รุนแรง (ไอเป็นเลือด หรือปัสสาวะ/อุจจาระมีสีดำ ปวดศรีษะมากและมีเลือดออกในสมอง)",
                        }}
                      />
                    )}
                    {dataForm.data_42 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_45}
                        mode="view"
                        translate={{
                          1: "แขนขาอ่อนแรงชาครึ่งซีก หน้าเบี้ยว ปากเบี้ยว พูดไม่ออก พูดไม่ชัด",
                        }}
                      />
                    )}
                    {dataForm.data_42 == "1" && (
                      <React.Fragment>
                        <FormElementLabel
                          value={dataForm.data_46}
                          mode="view"
                          translate={{
                            1: "ADR/อาการผิดปกติอื่นๆ",
                          }}
                        />
                        {dataForm.data_46 == "1" && (
                          <FormElementTextBox
                            value={dataForm.data_47}
                            mode="view"
                          />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  การจัดการเบื้องต้น
                </th>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormElementLabel
                      value={dataForm.data_48}
                      mode="view"
                      translate={{
                        1: "แนะนำให้หลีกเลี่ยง ยากลุ่ม NSIADs,อาหาร,อาหารเสริม หรือสมุนไพรที่มีผลทำให้ระดับยาวาร์ฟารินในเลือดเปลี่ยนแปลง",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_49}
                      mode="view"
                      translate={{
                        1: "แนะนำให้สังเกตอาการเลือดออกผิดปกติหรือมีอาการผิดปกติให้มาพบแพทย์ก่อนนัด",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_50}
                      mode="view"
                      translate={{
                        1: "ส่งต่อข้อมูลการติดตาม (ส่วนที่4)",
                      }}
                    />

                    <React.Fragment>
                      <FormElementLabel
                        value={dataForm.data_51}
                        mode="view"
                        translate={{
                          1: "อื่นๆ",
                        }}
                      />
                      {dataForm.data_51 == "1" && (
                        <FormElementTextBox
                          value={dataForm.data_52}
                          mode="view"
                        />
                      )}
                    </React.Fragment>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementTitle
            label="ส่วนที่ 4 ข้อมูลที่ต้องการส่งต่อ"
            mode="view"
          />
          <div style={{ display: "inline-flex", gap: "8px" }}>
            {dataForm.data_53 == "1" && <span>/ แพทย์</span>}
            {dataForm.data_54 == "1" && <span>/ เภสัชกร</span>}
            {dataForm.data_55 == "1" && (
              <span>/ พยาบาล/เจ้าหน้าที่ห้องตรวจ</span>
            )}
          </div>
          <FormElementTextBox value={dataForm.data_56} mode="view" />
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <FormElementTextBox
              label="ผู้รับการประเมิน (ผู้ป่วย/ญาติ)"
              value={dataForm.data_57}
              mode="view"
            />
            <FormElementLabel
              value={dataForm.data_58}
              mode="view"
              translate={{
                1: "ผู้ป่วย",
                2: "ญาติ",
              }}
            />
            <FormElementTextBox
              label="เภสัชกรผู้ประเมิน"
              value={dataForm.data_59}
              mode="view"
            />
          </div>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormDeliveryWafarineMedicinePaper;
