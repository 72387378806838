import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

import "../../../css/dietz.css";
import Api from "../../Api";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;

const FormGoodDeathPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const { memberProfile, profile } = props;

  var [familyItemsDropDownList, setFamilyItemsDropDownList] = useState({});
  var [wardDropDownList, setWardDropDownList] = useState({});

  useEffect(() => {
    props.addValue({
      formName: "form_gooddeath",
      formType: "4",
    });
  }, []);
  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  async function getFamilyItem() {
    try {
      var result = await Api.consult("GetFamilyItem", {});

      var { data } = result;
      setFamilyItemsDropDownList(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getWardList() {
    try {
      var result = await Api.consult("GetWardList", {});

      if (result.list.data) {
        const { _value } = result.list.data;

        console.log(_value);

        var data = {};
        _value.forEach((item) => (data[item.code] = item.name));
        setWardDropDownList(data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getFamilyItem();
    getWardList();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินการตายดี (Good death)"
            mode="view"
            padding="0px 0px 40px 0px"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={memberProfile} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <FormElementTitle
              label="ผู้ตอบแบบประเมินมีความเกี่ยวข้องกับผู้ป่วย"
              mode="view"
            />
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="หอผู้ป่วย" mode="view" />
              <FormElementLabel
                value={dataForm.data_1}
                mode="view"
                translate={wardDropDownList}
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="สถานที่เสียชีวิต" mode="view" />
              <FormElementLabel
                value={dataForm.data_2}
                mode="view"
                translate={{ 1: "ที่บ้าน", 2: "ที่โรงพยาบาล", 3: "อื่นๆ" }}
              />
              <span hidden={dataForm.data_2 != "3"}>
                <FormElementLabel label={dataForm.data_13} mode="view" />
              </span>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="ผู้ตอบแบบประเมิน" mode="view" />
              <FormElementLabel label={dataForm.data_3} mode="view" />
              <FormElementLabel label="ความสัมพันธ์" mode="view" />
              <FormElementLabel
                value={dataForm.data_4}
                mode="view"
                translate={familyItemsDropDownList}
              />
              <span hidden={dataForm.data_4 != "99"}>
                <FormElementLabel label={dataForm.data_12} mode="view" />
              </span>
            </div>
            <FormElementTitle
              label="กรุณาทําเครื่องหมาย ในช่องที่ตรงกับความเป็นจริง"
              mode="view"
            />
            <FormElementTitle
              label="1. ผู้ป่วยได้เสียชีวิตในสถานที่ตรงกับความต้องการของผู้ป่วย"
              mode="view"
            />
            <FormElementLabel
              value={dataForm.data_5}
              mode="view"
              translate={{ 1: "ใช่", 2: "ไม่ใช่" }}
            />
            <span hidden={dataForm.data_5 != "2"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel
                    label="สถานที่ที่ผู้ป่วยต้องการเสียชีวิต คือ"
                    mode="view"
                  />
                  <FormElementLabel label={dataForm.data_6} mode="view" />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel
                    label="สถานที่ที่ผู้ป่วยได้เสียชีวิตจริง คือ"
                    mode="view"
                  />
                  <FormElementLabel label={dataForm.data_7} mode="view" />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel label="มีสาเหตุเพราะ" mode="view" />
                  <FormElementLabel label={dataForm.data_8} mode="view" />
                </div>
              </div>
            </span>
            <FormElementTitle
              label="2. ในความรู้สึกของญาติ คิดว่าผู้ป่วยได้จากไปอย่างสงบ โดยที่อาการต่างๆได้รับการดูแลหรือไม่"
              mode="view"
            />
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "8px",
              }}
            >
              <FormElementLabel
                value={dataForm.data_9}
                mode="view"
                translate={{ 1: "ใช่", 0: "ไม่ใช่" }}
              />
              <span hidden={dataForm.data_9 != "1"}>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel label="ระดับ" mode="view" />
                  <FormElementLabel
                    value={dataForm.data_11}
                    mode="view"
                    translate={{
                      1: "ดีที่สุด",
                      2: "ดีมาก",
                      3: "ดี",
                      4: "พอใช้",
                      5: "น้อย",
                    }}
                  />
                </div>
              </span>
              <span hidden={dataForm.data_9 != "0"}>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel label="เพราะ (ต้องระบุ)" mode="view" />
                  <FormElementLabel label={dataForm.data_10} mode="view" />
                </div>
              </span>
            </div>
          </div>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormGoodDeathPaper;
