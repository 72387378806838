import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class SideMenu extends Component {

  state = {
    showSideMenu: false
  }

  logout = () => {
    localStorage.setItem('adminLogin', '');
    window.location.href="/admin";
  }

  menuRender = () => {
    var menu = [
      {
        icon: 'bubbles',
        label: 'ที่ปรึกษา',
        link: '/admin/consult'
      },
      {
        icon: 'event',
        label: 'ตารางนัด',
        link: '/admin/appointmentList'
      },
      {
        icon: 'pie-chart',
        label: 'สรุปผลคนไข้',
        link: '/admin/memberSummary'
      },
      {
        icon: 'graph',
        label: 'สรุปการดำเนินงาน',
        link: '/admin/consultStatistic'
      },
      {
        icon: 'wrench',
        label: 'ตั้งค่า',
        link: '/admin/setting'
      }
    ];

    var _nowpath = window.location.pathname;

    if(_nowpath[_nowpath.length - 1] === '/') _nowpath = _nowpath.substring(0, _nowpath.length - 1);

    return <ul className="uk-list">{
      menu.map((data, key) => {
        return <li key={'sideMenu' + key}>
          <a href={data.link} className={'d-font-mon ' + (data.link === '/admin' ? (data.link === _nowpath ? 'd-side-menu-active' : '') : (_nowpath === data.link? 'd-side-menu-active' : ''))} 
          onClick={()=>this.setState({showSideMenu: false})}
          >
            <span className={'icon-' + data.icon}></span>
            <span className="d-side-menu-show-hover">{data.label}</span>
          </a>
        </li>
      })
    }

      <li>
        <a onClick={this.logout}><span className={'icon-lock-open'}></span> ออกจากระบบ</a>
      </li>
    </ul>
  }
  

  render() {
    return <div>
    {this.state.showSideMenu && <div id="d-side-menu-overlay" className="uk-animation-fade" onClick={()=>this.setState({showSideMenu: false})}></div>}
      <div id="d-header-menu-mobile" uk-sticky="">
        <div className="uk-grid-collapse" uk-grid="">
          <div className="uk-width-1-4">
            <a className="d-header-menu-mobile-button" onClick={()=>{
              this.setState({showSideMenu: !this.state.showSideMenu});
            }}>
              <i className="icon-menu"></i>
            </a>
          </div>
          <div className="uk-width-1-2 uk-flex-middle uk-text-center">
            <img src="/assets/stack-logo-light.png" className="uk-margin-small-right" />
            <img src="/assets/logo_dietz.png" />
          </div>
          <div className="uk-width-1-4 uk-text-right">
          </div>
        </div>
      </div>
      <div className={"d-side-menu " + (this.state.showSideMenu ? 'd-side-menu-active': '')}>
        <div className="d-side-menu-logo-container uk-flex-middle">
          <img src="/assets/stack-logo-light.png" className="uk-margin-small-right" />
          <img src="/assets/logo_dietz.png" />
        </div>
        <div className="d-side-menu-title-container d-font-mon d-side-menu-show-hover">
          GENERAL
        </div>
        {this.menuRender()}
      </div>
    </div>
  }
};
