import Api from "../../Components/Api";

async function fetchDrugNarcoticsByTxn(hn, txn) {
    try {
        var result = await Api.consult("GetDrugByTxn", {
            hn: hn,
            txn: txn,
            patientType: "o",
        });

        var { list } = result;

        if (list.data && list.data.length > 0) {
            var data = list.data[0].filter(
                (item) => item.tele_drug_type == "ยาเสพติด" && item.status == "0"
            );
            return data;
        }
        return [];
    } catch (error) {
        console.error(`fetchDrugNarcoticsByTxn: hn(${hn}), txn(${txn})`, error);
        throw new Error(`Something went wrong.`);
    }
}

export default fetchDrugNarcoticsByTxn;