import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

import "../../../css/dietz.css";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;

const FormCathLabDischargePaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_cathlabdischarge",
      formType: "10",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  console.log("dataForm: ", dataForm);

  const hospitalTranslate = {
    "": "",
    "06009": "โรงพยาบาลแม่ตื่น จ.เชียงใหม่",
    10713: "โรงพยาบาลนครพิงค์ จ.เชียงใหม่",
    10714: "โรงพยาบาลลำพูน จ.ลำพูน",
    10719: "โรงพยาบาลศรีสังวาลย์ จ.แม่ฮ่องสอน",
    11119: "โรงพยาบาลจอมทอง จ.เชียงใหม่",
    11120: "โรงพยาบาลเทพรัตนเวชชานุกูล เฉลิมพระเกียรติ ๖๐ พรรษา จ.เชียงใหม่",
    11121: "โรงพยาบาลเชียงดาว จ.เชียงใหม่",
    11122: "โรงพยาบาลดอยสะเก็ด จ.เชียงใหม่",
    11123: "โรงพยาบาลแม่แตง จ.เชียงใหม่",
    11124: "โรงพยาบาลสะเมิง จ.เชียงใหม่",
    11125: "โรงพยาบาลฝาง จ.เชียงใหม่",
    11126: "โรงพยาบาลแม่อาย จ.เชียงใหม่",
    11127: "โรงพยาบาลพร้าว จ.เชียงใหม่",
    11128: "โรงพยาบาลสันป่าตอง จ.เชียงใหม่",
    11129: "โรงพยาบาลสันกำแพง จ.เชียงใหม่",
    11130: "โรงพยาบาลสันทราย จ.เชียงใหม่",
    11131: "โรงพยาบาลหางดง จ.เชียงใหม่",
    11132: "โรงพยาบาลฮอด จ.เชียงใหม่",
    11133: "โรงพยาบาลดอยเต่า จ.เชียงใหม่",
    11134: "โรงพยาบาลอมก๋อย จ.เชียงใหม่",
    11135: "โรงพยาบาลสารภี จ.เชียงใหม่",
    11136: "โรงพยาบาลเวียงแหง จ.เชียงใหม่",
    11137: "โรงพยาบาลไชยปราการ จ.เชียงใหม่",
    11138: "โรงพยาบาลแม่วาง จ.เชียงใหม่",
    11139: "โรงพยาบาลแม่ออน จ.เชียงใหม่",
    11140: "โรงพยาบาลแม่ทา จ.ลำพูน",
    11141: "โรงพยาบาลบ้านโฮ่ง จ.ลำพูน",
    11142: "โรงพยาบาลลี้ จ.ลำพูน",
    11143: "โรงพยาบาลทุ่งหัวช้าง จ.ลำพูน",
    11144: "โรงพยาบาลป่าซาง จ.ลำพูน",
    11145: "โรงพยาบาลบ้านธิ จ.ลำพูน",
    11507: "โรงพยาบาลกองบิน 41 จ.เชียงใหม่",
    11508: "โรงพยาบาลค่ายกาวิละ เชียงใหม่ จ.เชียงใหม่",
    11643: "โรงพยาบาลดอยหล่อ จ.เชียงใหม่",
    11978: "โรงพยาบาลดารารัศมี จ.เชียงใหม่",
    11992: "โรงพยาบาลเทพปัญญา 2 โรงพยาบาลทั่วไปขนาดกลาง จ.เชียงใหม่",
    11995: "โรงพยาบาลทั่วไปขนาดใหญ่เซ็นทรัลเชียงใหม่ เมโมเรียล จ.เชียงใหม่",
    11999: "โรงพยาบาลทั่วไปขนาดใหญ่เชียงใหม่เมดิคอลเซ็นเตอร์ จ.เชียงใหม่",
    12279: "โรงพยาบาลส่งเสริมสุขภาพ ศูนย์อนามัยที่ 1 จ.เชียงใหม่",
    12280: "โรงพยาบาลสวนปรุง จ.เชียงใหม่",
    12281: "โรงพยาบาลประสาทเชียงใหม่ จ.เชียงใหม่",
    12283: "โรงพยาบาลมะเร็งลำปาง จ.ลำปาง",
    13775: "สถาบันพัฒนาการเด็กราชนครินทร์ จ.เชียงใหม่",
    13780: "โรงพยาบาลมหาราชนครเชียงใหม่ มหาวิทยาลัยเชียงใหม่ จ.เชียงใหม่",
    13785: "โรงพยาบาลธัญญารักษ์เชียงใหม่ จ.เชียงใหม่",
    14197: "โรงพยาบาลธัญญรักษ์แม่ฮ่องสอน จ.แม่ฮ่องสอน",
    14550: "โรงพยาบาลทั่วไปขนาดใหญ่ เชียงใหม่ใกล้หมอ จ.เชียงใหม่",
    14555: "โรงพยาบาลทั่วไปขนาดกลางศิริเวช ลำพูน จ.ลำพูน",
    14916: "โรงพยาบาลทั่วไปขนาดกลางเชียงใหม่ฮอสพิทอล จ.เชียงใหม่",
    23736: "โรงพยาบาลวัดจันทร์ เฉลิมพระเกียรติ 80 พรรษา จ.เชียงใหม่",
    99999: "อื่นๆ",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="Heart Failure Data ศูนย์โรคหัวใจภาคเหนือ โรงพยาบาลมหาราชนครเชียงใหม่"
            mode="view"
            padding="0px 0px 40px 0px"
          />
          <FormElementTitle label="Cathlab CMU discharge set box" mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementTextBox
            value={dataForm.data_1}
            label="เบอร์โทรผู้ป่วย"
            mode="view"
            width="100"
          />
          <FormElementTextBox
            value={dataForm.data_2}
            label="เบอร์โทรญาติ"
            mode="view"
            width="100"
          />
        </div>
        <div>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    paddingTop: "20px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={2}
                >
                  รายละเอียด
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  <FormElementTextBox
                    value={dataForm.data_3}
                    type="date"
                    label="วันที่จำหน่าย"
                    mode="view"
                    width="100"
                  />
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  <FormElementTextBox
                    value={dataForm.data_4}
                    type="date"
                    label="24 - 48 ชั่วโมงหลังจำหน่าย"
                    mode="view"
                    width="100"
                  />
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เนื้อหาการให้คำแนะนำ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    maxWidth: "165px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผลการให้คำแนะนำ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เนื้อหาการประเมิน
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผลการให้คำแนะนำ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1. การให้คำแนะนำเกี่ยวกับการดูแลแผล</td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_5}
                      mode="view"
                      translate={{
                        1: "ตำแหน่งของแผล",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_6}
                      mode="view"
                      translate={{
                        1: "ลักษณะของแผล",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_50}
                      mode="view"
                      translate={{
                        1: "ภาวะแทรกซ้อน",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_51}
                      mode="view"
                      translate={{
                        1: "วิธีการดูแลแผลขณะอยู่ที่บ้าน",
                      }}
                    />
                  </div>
                </td>

                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_52}
                      mode="view"
                      translate={{
                        1: "เข้าใจดี",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_53}
                      mode="view"
                      translate={{
                        1: "ให้คำแนะนำเพิ่มเติม",
                      }}
                    />
                  </div>

                  <span hidden={dataForm.data_53 != "1"}>
                    <div style={{ maxWidth: "160px" }}>
                      <FormElementTextArea
                        value={dataForm.data_7}
                        mode="view"
                        width="100"
                      />
                    </div>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    - ดูแลแผลไม่ให้โดนน้ำ 3 วัน
                  </div>
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    - ดูหากมีเลือดออกให้กดเหนือรอยเข็มอย่างน้อย 10 นาที
                    ถ้าไม่หยุด ให้รีบไปพบแพทย์
                  </div>
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    - สังเกตอาการผิดปกติ เช่น เลือดออก แผลบวม ปวดแผลมาก
                    ให้รีบไปพบแพทย์
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <div style={{ paddingY: "10px", height: "60px" }}>
                      <FormElementLabel
                        value={dataForm.data_8}
                        mode="view"
                        translate={{ 0: "มีความรู้", 1: "ไม่มีความรู้" }}
                      />
                    </div>
                    <div style={{ paddingY: "10px", height: "60px" }}>
                      <FormElementLabel
                        value={dataForm.data_9}
                        mode="view"
                        translate={{ 0: "มีความรู้", 1: "ไม่มีความรู้" }}
                      />
                    </div>
                    <div style={{ paddingY: "10px", height: "60px" }}>
                      <FormElementLabel
                        value={dataForm.data_10}
                        mode="view"
                        translate={{ 0: "มีความรู้", 1: "ไม่มีความรู้" }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2. การให้คำแนะนำเกี่ยวกับการปฏิบัติกิจวัตรประจำวัน
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <FormElementLabel
                      value={dataForm.data_54}
                      mode="view"
                      translate={{
                        1: "สัปดาห์แรกหลังตรวจให้หลีกเลี่ยงการออกกำลังหรือกิจกรรมที่ใช้กำลังมาก",
                      }}
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_55}
                      mode="view"
                      translate={{
                        1: "เข้าใจดี",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_56}
                      mode="view"
                      translate={{
                        1: "ให้คำแนะนำเพิ่มเติม",
                      }}
                    />
                  </div>
                  <span hidden={dataForm.data_56 != "1"}>
                    <div style={{ maxWidth: "160px" }}>
                      <FormElementTextArea
                        value={dataForm.data_13}
                        mode="view"
                        width="100"
                      />
                    </div>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    - หลีกเลี่ยงการออกกำลังหรือทำกิจกรรมที่ใช้กำลังมาก
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    <FormElementLabel
                      value={dataForm.data_14}
                      mode="view"
                      translate={{ 1: "มีความรู้", 0: "ไม่มีความรู้" }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3. การควบคุมโรคที่เป็นปัจจัยเสี่ยง
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_15}
                      mode="view"
                      translate={{
                        0: "ไม่มีปัจจัยเสี่ยง",
                        1: "มี",
                      }}
                    />
                    <span hidden={dataForm.data_15 != "1"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <FormElementLabel
                          value={dataForm.data_41}
                          mode="view"
                          translate={{
                            1: "โรคเบาหวาน",
                          }}
                        />
                        <FormElementLabel
                          value={dataForm.data_42}
                          mode="view"
                          translate={{
                            1: "ความดันโลหิตสูง",
                          }}
                        />
                        <FormElementLabel
                          value={dataForm.data_43}
                          mode="view"
                          translate={{
                            1: "ระดับไขมันในเลือดสูง",
                          }}
                        />
                        <FormElementLabel
                          value={dataForm.data_44}
                          mode="view"
                          translate={{
                            1: "การเลิกบุหรี่",
                          }}
                        />
                      </div>
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_57}
                      mode="view"
                      translate={{
                        1: "เข้าใจดี",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_58}
                      mode="view"
                      translate={{
                        1: "ให้คำแนะนำเพิ่มเติม",
                      }}
                    />
                  </div>
                  <span hidden={dataForm.data_58 != "1"}>
                    <div style={{ maxWidth: "160px" }}>
                      <FormElementTextArea
                        value={dataForm.data_17}
                        label=""
                        mode="view"
                        width="100"
                      />
                    </div>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    - หลีกเลี่ยงปัจจัยเสี่ยง เช่น อาหารที่มีรสเค็มจัด
                    การสูบบุหรี่ การดิ่มเครื่องดื่มแอลกอฮอล์
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    <FormElementLabel
                      value={dataForm.data_18}
                      mode="view"
                      translate={{ 1: "มีความรู้", 0: "ไม่มีความรู้" }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4. การให้คำแนะนำเกี่ยวกับการติดตามผลการรักษา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <FormElementLabel
                        value={dataForm.data_59}
                        mode="view"
                        translate={{
                          1: "การเข้ารับการตรวจตามนัดครั้งถัดไปเพื่อติดตามอาการหรือวางแผนการรักษา",
                        }}
                      />
                      <FormElementLabel
                        value={dataForm.data_60}
                        mode="view"
                        translate={{
                          1: "อาการผิดปกติที่ต้องมาพบแพทย์ก่อนวันตรวจตามนัด",
                        }}
                      />
                    </div>
                  </div>
                </td>

                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <FormElementLabel
                        value={dataForm.data_61}
                        mode="view"
                        translate={{
                          1: "เข้าใจดี",
                        }}
                      />
                      <FormElementLabel
                        value={dataForm.data_62}
                        mode="view"
                        translate={{
                          1: "ให้คำแนะนำเพิ่มเติม",
                        }}
                      />
                    </div>
                  </div>
                  <span hidden={dataForm.data_62 != "1"}>
                    <div style={{ maxWidth: "160px" }}>
                      <FormElementTextArea
                        value={dataForm.data_21}
                        label=""
                        mode="view"
                        width="100"
                      />
                    </div>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    - ผลการรักษา
                  </div>
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    - แผนการรักษา
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    <FormElementLabel
                      value={dataForm.data_22}
                      mode="view"
                      translate={{ 1: "มีความรู้", 0: "ไม่มีความรู้" }}
                    />
                  </div>
                  <div style={{ paddingY: "10px", height: "60px" }}>
                    <FormElementLabel
                      value={dataForm.data_23}
                      mode="view"
                      translate={{ 1: "มีความรู้", 0: "ไม่มีความรู้" }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <FormElementLabel
            label="ข้อมูลที่ผู้ป่วยจะได้รับก่อนกลับบ้าน"
            mode="view"
          />
        </div>
        <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
          <FormElementLabel label="1. การรักษาต่อเนื่อง" mode="view" />
        </div>
        <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
          <FormElementLabel
            value={dataForm.data_40}
            translate={{
              1: "กรณี F/U รพ.มหาราชนครเชียงใหม่",
              2: "กรณี F/U ต่อที่ รพ.อื่น",
            }}
            mode="view"
          />
        </div>
        <span hidden={dataForm.data_40 != "1"}>
          <div
            style={{ paddingTop: "10px", display: "flex", flexDirection: "column", paddingLeft: "48px", gap: '8px' }}
          >
            <div style={{ display: 'inline-flex', gap: '8px' }}>
              <FormElementLabel
                value={dataForm.data_24}
                translate={{
                  1: "ใบนัดที่",
                }}
                mode="view"
              />
              <FormElementTextBox
                fieldId={"25"}
                value={dataForm.data_25}
                label="OPD"
                width="100"
                mode="view"
              />
              <FormElementTextBox
                fieldId={"26"}
                value={dataForm.data_26}
                type="date"
                label="วันที่"
                mode="view"
                width="100"
              />
            </div>
            <FormElementLabel
              value={dataForm.data_45}
              translate={{
                1: "การเตรียมตัวเมื่อมาตามนัดครั้งถัดไป (ใบส่งตัว, ยาทั้งหมด, ผล Lab, NPQI)",
              }}
              mode="view"
            />
          </div>
        </span>
        <span hidden={dataForm.data_40 != "2"}>
          <div
            style={{ paddingTop: "10px", display: "flex", flexDirection: "column", paddingLeft: "48px", gap: '8px' }}
          >
            <div style={{ display: 'inline-flex', gap: '8px' }}>
              <FormElementLabel
                value={dataForm.data_27}
                mode="view"
                width="100"
                translate={hospitalTranslate}
              />
              <span hidden={dataForm.data_27 != "99999"}>
                <FormElementTextBox
                  fieldId="65"
                  value={dataForm.data_65}
                  mode="view"
                />
              </span>
            </div>
            <FormElementLabel
              value={dataForm.data_46}
              translate={{
                1: "ผลการตรวจสอบหัวใจ",
              }}
              mode="view"
            />
             <FormElementLabel
              value={dataForm.data_47}
              translate={{
                1: "จดหมายตอบโรงพยาบาลต้นสังกัด",
              }}
              mode="view"
            />
          </div>
        </span>
        <div style={{ paddingTop: "10px" }}>
          <FormElementLabel
            value={dataForm.data_28}
            translate={{
              1: "ผลการตรวจสอบหัวใจ",
              9: "จดหมายตอบโรงพยาบาลต้นสังกัด",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="2. Medication"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_29}
            translate={{
              0: "no Home med",
              1: "มี Home med ยาเพียงพอถึงวันนัดครั้งถัดไป / add ยาชนิดเพิ่มเติม",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="3. เอกสารคำแนะนำการดูแลตนเอง"
            mode="view"
            padding="0px 10px 0px 0px"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            value={dataForm.data_49}
            translate={{
              1: "ได้รับ",
              0: "ไม่ได้รับ",
            }}
            mode="view"
            padding="0px 10px 0px 0px"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormElementLabel
            label="4. การนำเข้าสู่ line application consult for Same-day patient"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
              paddingLeft: "40px",
            }}
          >
            <FormElementLabel
              value={dataForm.data_30}
              translate={{
                0: "ไม่สำเร็จ",
                1: "สำเร็จ",
              }}
              mode="view"
              padding="0px 10px 0px 0px"
            />
            {dataForm.data_30 == "0" && (
              <FormElementTextBox
                value={dataForm.data_31}
                label="เนื่องจาก"
                mode="view"
                width="100"
              />
            )}
          </div>
          <FormElementTextBox
            value={
              "ลงชื่อ " +
              `${dataForm.data_32 ? dataForm.data_32 : ""}` +
              " พยาบาลผู้ตรวจสอบข้อมูล"
            }
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",

            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="การติดตามผู้ป่วย 24 - 48 ชั่วโมงหลังตรวจ"
            mode="view"
            padding="10px 0px 0px 0px"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="1. ภาวะแทรกซ้อน"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_33}
            translate={{ 1: "ไม่มี", 2: "hematoma", 3: "bleeding" }}
            mode="view"
            padding="0px 10px 0px 0px"
          />
          {dataForm.data_33 == "4" && (
            <FormElementTextBox
              value={dataForm.data_34}
              mode="view"
              width="100"
            />
          )}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="2. ระดับความวิตกกังวล (0-10 คะแนน)"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <FormElementTextArea
            value={dataForm.data_35}
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="3. ระดับความเจ็บปวดแผล (0-10 คะแนน)"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <FormElementTextArea
            value={dataForm.data_36}
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="4. ระดับความพึงพอใจ (0-10 คะแนน)"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <FormElementTextArea
            value={dataForm.data_37}
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",

            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementTextBox
            value={
              "ลงชื่อ " +
              `${dataForm.data_38 ? dataForm.data_38 : ""}` +
              " พยาบาล"
            }
            mode="view"
            width="100"
          />
          <span style={{ paddingLeft: "20px" }}></span>
          <FormElementTextBox
            value={dataForm.data_39}
            type="date"
            label="วันที่"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "4px",
            flexDirection: "column",
          }}
        >
          {dataForm.data_63?.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>{item.name}</span>
              <img src={item.base64} width={400} />
            </div>
          ))}
        </div>
        <span hidden={!dataForm.form_id}>
          <FormElementTextArea
            value={dataForm.data_64}
            mode="view"
            label="เหตุผลในการแก้ไข"
            width="100"
          />
        </span>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormCathLabDischargePaper;
