import React, { useState, useCallback,useEffect } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import DateTimePicker from "../../Components/DatePicker.js";
import SaveSuccessfulMessage from "../../Components/SaveSuccessfulMessage";
import moment from 'moment';
import { format } from 'date-fns';

UIkit.use(Icons);

const SlotManagement = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [formTypeList, setFormTypeList] = useState([]);
  const [dateQuery, setDateQuery] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [hn, setHN] = useState("");
  const [slotId, setSlotId] = useState(null);
  const [slot, setSlot] = useState(null);
  const [status, setStatus] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [clinicCode, setClinicCode] = useState(null);
  const [isShowSaveSuccessfulPopup, setIsShowSaveSuccessfulPopup] =
    useState(false);

  async function listGet() {
    const profileTxt = localStorage.getItem('consultProfileData')
    const profileData = JSON.parse(profileTxt);

    setClinicCode(profileData.clinicCode);

    var result = await Api.consult("SlotList", {
      showAll: '1',
      clinicCode: profileData.clinicCode,
      appointmentDate: dateQuery
    });

    console.log(result);

    var { list } = result;

    if(list != null) {
      setDataList(list);

    } else {
      setDataList([]);

    }

    setLoadingApi(false);

  }

  const handleCloseSavePopup = useCallback(() => {
    setIsShowSaveSuccessfulPopup(false);
    
    listGet();
  });

  function changeDate(data) {
    setDateQuery(data);
  }

  async function editSlot(slotId) {
    var result = await Api.consult("SlotDetail", {
      slot_id: slotId,
      clinicCode: clinicCode,
      appointmentDate: dateQuery
    });

    console.log(result);

    setSlotId(slotId);
    setStartTime(result.data.start_time);
    setEndTime(result.data.end_time);
    setSlot(result.data.slot);
    setStatus(result.data.status);

    UIkit.modal('#modal-edit').show();

    
  }

  async function onSaveSlot() {
    var result = await Api.consult("SlotSave", {
      slotId: slotId,
      slot: slot,
      status: status
    });

    UIkit.modal('#modal-edit').hide();

    if(result.result) {
      setIsShowSaveSuccessfulPopup(true);

    } else {
      console.log(result);
    }
  }

  function onClosePopup() {
    
  }

  useEffect(() => {
    listGet();
  }, []);

  return (
     <div className="uk-padding">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">การตั้งค่าห้องจ่ายยา</div>
          <div uk-grid="" className="uk-flex-middle">
            <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker id="dateStart" format="YYYY-MM-DD" callback={changeDate} />
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
            <button className="uk-button uk-button-primary" onClick={listGet}>ค้นหา</button>&nbsp;&nbsp;
            </div>
            
          </div>

          <hr />
          <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
              <thead>
                <tr>
                  <th>ที่</th>
                  <th>เวลาเริ่ม</th>
                  <th>เวลาสิ้นสุด</th>
                  <th>จำนวนผู้ป่วยที่รองรับ</th>
                  <th>สถานะ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  loadingApi == true ?
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                    </td>
                  </tr>
                  :
                  dataList.length === 0 ? 
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                    </td>
                  </tr>
                  :
                  dataList.map((data, key)=>{
                    return <tr key={`row${data.slot_id}`}>
                      <td>{key+1}</td>
                      <td>{data.start_time}</td>
                      <td>{data.end_time}</td>
                      <td>{data.reserved} / {data.slot}</td>
                      <td>{data.status == "1" ? "เปิดบริการ" : "ปิดบริการ"}</td>
                      <td><button  className='uk-button uk-button-primary' target='_blank' onClick={() => editSlot(data.slot_id)}>แก้ไข</button></td>
                    </tr>
                  })
                }
              </tbody>
            </table>
            </div>
        </div>
        <div id="modal-edit" className="uk-modal" >
          <div className="uk-modal-dialog">
            <button className="uk-modal-close-default uk-close" type="button" onClick={onClosePopup} ></button>
            <div className="uk-modal-header">
                <h2 className="uk-modal-title">แก้ไข</h2>
            </div>
            <div className="uk-modal-body">
              <div class="uk-grid">
                <div class="uk-width-2-5">{startTime} - {endTime}</div>
              </div>
              <div class="uk-grid">
                <div class="uk-width-2-5">จำนวนผู้ป่วยที่รองรับ</div>
                <div class="uk-width-3-5"><input type="text" placeholder="" value={slot} onChange={e => setSlot(e.target.value)} /></div>
              </div>
              <div class="uk-grid">
                <div class="uk-width-2-5">สถานะ</div>
                <div class="uk-width-3-5">
                <input type="radio" className='magicRadio' id='status_1' value={'1'} checked={status === "1"}  onChange={e => setStatus('1')}/><label className='labelMagicRadio' htmlFor='status_1'>เปิดบริการ</label>
                &nbsp;&nbsp;&nbsp;
                  <input type="radio" className='magicRadio' id='status_0' value={'0'} checked={status === "0"}  onChange={e => setStatus('0')}/><label className='labelMagicRadio' htmlFor='status_0'>ปิดบริการ</label>
                  
                  
                </div>
              </div>
            </div>
            <div className="uk-modal-footer uk-text-right">
                <button className="uk-button uk-button-primary " type="button" onClick={onSaveSlot}>บันทึกข้อมูล</button>
                <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={onClosePopup}>ยกเลิก</button>
            </div>
        </div>
      </div>
      <SaveSuccessfulMessage
          show={isShowSaveSuccessfulPopup}
          onClose={handleCloseSavePopup}
        />
    </div>
  )
}

export default SlotManagement;