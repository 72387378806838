import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from "../../Components/Api";
import Formular from "../Formular";
import GroupReport from "../Formular/GroupReport";
import moment from "moment";
import numeral from "numeral";
import FullScreenElement from "../FullScreenElement";
import $ from 'jquery';

UIkit.use(Icons);

export default class ReportReport extends Component {
  state = {
    loadingApi: false,
    selectYear: moment().subtract(1, 'year').format("YYYY"),
    selectAge: "",
    selectGender: "",
    selectDepartment: "",
    selectJob: "",
    optionDepartment: [{ label: "ทั้งหมด", value: "" }],
    optionJob: [{ label: "ทั้งหมด", value: "" }],
    data: {},
    tableGroupRisk: [],
  };

  componentDidMount() {
    this.dataGet();
  }

  dataRender = () => {
    var _data = this.state.data;
    var _memberDataNameRisk = {};
    var _memberDataGroupRisk = {};
    var _groupRisk = {};
    var _department = [];
    var _job = [];

    for (var name in _data) {
      for (var member in _data[name]) {
        var _memberData = _data[name][member];

        var _useMemberData = false;

        //if first call render option department and job
        if (this.state.optionDepartment.length < 1) {
          if (
            _memberData.position &&
            _department.indexOf(_memberData.position) < 0
          )
            _department.push(_memberData.position);

          if (_memberData.job && _job.indexOf(_memberData.job) < 0)
            _job.push(_memberData.job);
        }

        if (this.state.selectAge !== "") {
          _memberData.age = +_memberData.age;

          //filter
          switch (this.state.selectAge) {
            case 15:
              if (_memberData.age < 15) {
                _useMemberData = true;
              }
              break;
            case 25:
              if (_memberData.age >= 15 && _memberData.age <= 25) {
                _useMemberData = true;
              }
              break;
            case 61:
              if (_memberData.age > 60) {
                _useMemberData = true;
              }
              break;
            default:
              if (
                _memberData.age > +this.state.selectAge - 5 &&
                _memberData.age <= +this.state.selectAge
              ) {
                _useMemberData = true;
              }
              break;
          }
        } else {
          _useMemberData = true;
        }

        if (_useMemberData === true)
          if (this.state.selectGender !== "") {
            if (this.state.selectGender == _memberData.gender) {
              _useMemberData = true;
            } else {
              _useMemberData = false;
            }
          }

        if (_useMemberData === true)
          if (this.state.selectDepartment !== "") {
            if (this.state.selectDepartment == _memberData.position) {
              _useMemberData = true;
            } else {
              _useMemberData = false;
            }
          }

        if (_useMemberData === true)
          if (this.state.selectJob !== "") {
            if (this.state.selectJob == _memberData.job) {
              _useMemberData = true;
            } else {
              _useMemberData = false;
            }
          }

        if (_useMemberData === true) {
          if (_memberDataNameRisk[member] == null)
            _memberDataNameRisk[member] = {};

          _memberDataNameRisk[member].memberData = _memberData;
          _memberDataNameRisk[member][name] = _memberData;

          if (typeof Formular[name] === "function")
            _memberDataNameRisk[member][name].detail = Formular[name](
              _memberData.value,
              _memberData.age,
              _memberData.gender
            );
        }
      }
    }

    //Render group risk
    for (var member in _memberDataNameRisk) {
      if (_memberDataGroupRisk[member] === undefined)
        _memberDataGroupRisk[member] = {};

      _memberDataGroupRisk[member].memberData =
        _memberDataNameRisk[member].memberData;
      _memberDataGroupRisk[member].groupReport = GroupReport(
        _memberDataNameRisk[member]
      );
    }

    for (var member in _memberDataGroupRisk) {
      if (_memberDataGroupRisk[member].groupReport.length)
        _memberDataGroupRisk[member].groupReport.forEach((group) => {
          if (!_groupRisk[group.label])
            _groupRisk[group.label] = {
              all: {
                amount: 0,
                green: 0,
                yellow: 0,
                orange: 0,
                red: 0,
              },
              dept: {},
            };

          _groupRisk[group.label].all.amount++;
          _groupRisk[group.label].all[group.color]++;

          var _memberData = _memberDataGroupRisk[member].memberData;

          if (_groupRisk[group.label].dept[_memberData.position] == undefined) {
            _groupRisk[group.label].dept[_memberData.position] = {
              amount: 0,
              green: 0,
              yellow: 0,
              orange: 0,
              red: 0,
              job: {},
            };

            _groupRisk[group.label].dept[_memberData.position].amount++;
            _groupRisk[group.label].dept[_memberData.position][group.color]++;

            if (
              _groupRisk[group.label].dept[_memberData.position].job[
                _memberData.job
              ] == undefined
            ) {
              _groupRisk[group.label].dept[_memberData.position].job[
                _memberData.job
              ] = {
                amount: 0,
                green: 0,
                yellow: 0,
                orange: 0,
                red: 0,
              };

              _groupRisk[group.label].dept[_memberData.position].job[
                _memberData.job
              ].amount++;
              _groupRisk[group.label].dept[_memberData.position].job[
                _memberData.job
              ][group.color]++;
            }
          }
        });
    }

    this.tableRender(_groupRisk);

    if (this.state.optionDepartment.length < 1) {
      _department = _department.map((department) => ({
        label: department,
        value: department,
      }));

      _department.splice(0, 0, { label: "ทั้งหมด", value: "" });

      _job = _job.map((job) => ({ label: job, value: job }));

      _job.splice(0, 0, { label: "ทั้งหมด", value: "" });

      this.setState({
        optionDepartment: _department,
        optionJob: _job,
      });
    }
  };

  dataGet = async () => {
    this.setState({ loadingApi: true });

    var { data } = await Api.member("ReportReport", {
      selectYear: this.state.selectYear,
    });

    this.setState(
      { data, optionDepartment: [], optionJob: [] },
      this.dataRender
    );
  };

  tableRender = (groupRisk) => {
    var _colAmountRender = (name, dataToCol, option) => {
      if (option === undefined) option = {};
      if (option.className === undefined) option.className = "";

      return (
        <tr key={Math.random().toString(36).substring(7)}>
          <td>
            <div className={option.className}>
              {name === "" ? "ทั้งหมด" : name}
            </div>
          </td>
          <td>{dataToCol.amount}</td>
          <td>{dataToCol.green}</td>
          <td>
            {numeral((dataToCol.green / dataToCol.amount) * 100).format(
              "0,0.00"
            )}
          </td>
          <td>{dataToCol.yellow}</td>
          <td>
            {numeral((dataToCol.yellow / dataToCol.amount) * 100).format(
              "0,0.00"
            )}
          </td>
          <td>{dataToCol.orange}</td>
          <td>
            {numeral((dataToCol.orange / dataToCol.amount) * 100).format(
              "0,0.00"
            )}
          </td>
          <td>{dataToCol.red}</td>
          <td>
            {numeral((dataToCol.red / dataToCol.amount) * 100).format("0,0.00")}
          </td>
        </tr>
      );
    };

    var _tableRender = (name, data) => {
      var _row = [];

      _row.push(_colAmountRender("All users", data.all));

      for (var department in data.dept) {
        _row.push(_colAmountRender(department, data.dept[department]));

        for (var job in data.dept[department].job) {
          _row.push(
            _colAmountRender(job, data.dept[department].job[job], {
              className: "d-report-job-container uk-margin-left",
            })
          );
        }
      }

      return (
        <table className="uk-table uk-table-small uk-margin-bottom d-report-table">
          <thead>
            <tr>
              <th rowSpan="2">{name}</th>
              <th rowSpan="2">Amount</th>
              <th colSpan="2" className="d-background-green d-color-white">
                Normal
              </th>
              <th colSpan="2" className="d-background-yellow d-color-white">
                Low Risk
              </th>
              <th colSpan="2" className="d-background-orange d-color-white">
                Risk
              </th>
              <th colSpan="2" className="d-background-red d-color-white">
                High Risk
              </th>
            </tr>
            <tr>
              <th>Amount</th>
              <th>%</th>
              <th>Amount</th>
              <th>%</th>
              <th>Amount</th>
              <th>%</th>
              <th>Amount</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>{_row}</tbody>
        </table>
      );
    };

    var _table = [];

    for (var name in groupRisk) {
      _table.push(_tableRender(name, groupRisk[name]));
    }

    if (_table.length === 0)
      _table = <div className="uk-padding uk-text-center">No data</div>;

    this.setState({ tableGroupRisk: _table, loadingApi: false });
  };

  jobSelectRender = () => {
    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectJob: e.target.value }, this.dataRender)
        }
      >
        {this.state.optionJob.map((job) => {
          return <option value={job.value}>{job.label}</option>;
        })}
      </select>
    );
  };

  departmentSelectRender = () => {
    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectDepartment: e.target.value }, this.dataRender)
        }
      >
        {this.state.optionDepartment.map((department) => {
          return <option value={department.value}>{department.label}</option>;
        })}
      </select>
    );
  };

  ageSelectRender = () => {
    var _age = ["", 15, 25, 30, 35, 40, 45, 50, 55, 60, 61];

    var _ageLabel = [
      "ทั้งหมด",
      "น้อยกว่า 15 ปี",
      "15 - 25 ปี",
      "26 - 30 ปี",
      "31 - 35 ปี",
      "36 - 40 ปี",
      "41 - 45 ปี",
      "46 - 50 ปี",
      "51 - 55 ปี",
      "56 - 60 ปี",
      "61 ปี ขึ้นไป",
    ];

    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectAge: e.target.value }, () => this.dataRender())
        }
      >
        {_age.map((age, key) => {
          return <option value={age}>{_ageLabel[key]}</option>;
        })}
      </select>
    );
  };

  genderSelectRender = () => {
    var _gender = [
      {
        label: "ทั้งหมด",
        value: "",
      },
      {
        label: "ชาย",
        value: "1",
      },
      {
        label: "หญิง",
        value: "0",
      },
    ];

    return (
      <select
        className="uk-select"
        onChange={(e) =>
          this.setState({ selectGender: e.target.value }, () =>
            this.dataRender()
          )
        }
      >
        {_gender.map((gender) => {
          return <option value={gender.value}>{gender.label}</option>;
        })}
      </select>
    );
  };

  yearSelectRender = () => {
    var _option = [];

    for (var i = 0; i <= 10; i++) {
      var _year = moment().subtract(i, "years").format("YYYY");
      _option.push(
        <option key={_year} value={_year}>
          {_year}
        </option>
      );
    }

    return (
      <select
        className="uk-select"
        value={this.state.selectYear}
        onChange={(e) =>
          this.setState({ selectYear: e.target.value }, this.dataGet)
        }
      >
        {_option}
      </select>
    );
  };

  Print = () => {
    
    $("#d-print-container").remove();

    $("#root").prepend(
      $('<div id="d-print-container">').html(
        $("#d-report-report-container").html()
      )
    );

    window.print();

    $("#d-print-container").remove();
  }

  CsvExport = () => {
    var _csv = [];

    $('.d-report-table').each(function() {
      $(this).find('thead').find('tr').each(function() {
        _csv.push(`"${$(this).find('th').eq(0).text()}", AMOUNT, NORMAL, ,LOWRISK, ,RISK, ,HIGH RISK, `);

        _csv.push(`,,AMOUNT,%,AMOUNT,%,AMOUNT,%,AMOUNT,%`)
      })

      $(this).find('tbody').find('tr').each(function() {
        var _col = [];
        $(this).find('td').each(function(){ 
          _col.push(`"${$(this).text()}"`);
        })
        _csv.push(_col.join(','))
      })

      _csv.push('\r\n');
    });

    var _u = "\uFEFF";
    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(_u+_csv.join('\r\n')));
    a.setAttribute('download', 'report.csv');
    window.document.body.appendChild(a);
    a.click();
  }

  render() {
    return (
      <div
        className="uk-card uk-card-default uk-padding-small"
        id="d-report-report"
      >
        <div className="d-card-header">
          <div className="d-card-header-left">Report</div>
          <div className="d-card-header-right">
            <span className="icon-reload" onClick={this.dataGet}></span>
            <FullScreenElement element="#d-report-report" />
          </div>
        </div>
        <div
          uk-grid=""
          className="uk-child-width-1-5@m uk-child-width-1-2 uk-grid-small uk-margin-small-top"
        >
          <div>
            <div className="uk-text-meta">ตำแหน่ง</div>
            {this.jobSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">แผนก</div>
            {this.departmentSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">เพศ</div>
            {this.genderSelectRender()}
          </div>
          <div>
            <div className="uk-text-meta">อายุ</div>
            {this.ageSelectRender()}
          </div>
          <div className="uk-width-1-1 uk-width-1-5@m">
            <div className="uk-text-meta">ปี</div>
            {this.yearSelectRender()}
          </div>
        </div>
        {this.state.loadingApi ? (
          <div className="uk-padding uk-text-center">
            <span uk-spinner=""></span>
          </div>
        ) : (
          <div>
            <div uk-grid="" className="uk-grid-small uk-margin-small-top">
              <div className="uk-width-expand"></div>
              <div className="uk-width-auto@s uk-width-1-1">
                <button className="uk-button-default uk-button uk-button-small" onClick={this.Print}>Print</button>
              </div>
              <div className="uk-width-auto@s uk-width-1-1">
                <button className="uk-button-default uk-button uk-button-small" onClick={this.CsvExport}>Export</button>
              </div>
            </div>
            <div className="uk-margin-small-top uk-overflow-auto" id="d-report-report-container">
              {this.state.tableGroupRisk}
            </div>
          </div>
        )}
      </div>
    );
  }
}
