import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';
import { Link } from "react-router-dom";
import moment from 'moment';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class CampaignReportChat extends Component {

  state = {
    chatDay: [],
    chatWeek: [],
    chatMonth: []
  }

  componentDidMount() {
    this.dataGet();
  }

  async dataGet() {
    var { chatDay, chatWeek, chatMonth } = await Api.member('CampaignReportChat', { campaignId: this.props.id });
    this.setState({ chatDay: chatDay || [], chatWeek: chatWeek || [], chatMonth: chatMonth || [] });

  }

  chatListrender = (data) => {
    return <div className="uk-text-right uk-margin-small-top">
      {
        data.length === 0 ?
          <div className="uk-padding uk-text-center">ไม่พบรายการ</div>
          :
          <div>
            {
              data.map((data, index) => {
                if(!data.chatLast) return false;

                var _image = '';
                var _name = '';
                if(data.chatLast.chatby === 'c') {
                  _image = data.chatLast.consultimage;
                  _name = data.chatLast.consultname;
                } else {
                  _image = data.chatLast.memberimage;
                  _name = data.chatLast.membername;
                }

                return <div key={`chatList${index}`}>
                  <div uk-grid="" className="uk-grid-small uk-text-left">
                    <div className="uk-width-auto">
                      <div className="uk-background-muted uk-background-cover d-smart-health-chat-profile-image" style={{backgroundImage: (_image ==='' ? false : `url(${_image})` )}}></div>
                    </div>
                    <div className="uk-width-expand">
                      <div className="uk-position-relative">
                        <div className="d-smart-health-chat-arrow-left"></div>
                        <div className="d-smart-health-chat-content">
                          {data.chatLast.name}
                        </div>
                      </div>
                      <div>
                      <div className="d-smart-health-chat-by">
                        {_name}
                        </div>
                        <a href={'/campaign-chat?listId=' + data.chatLast.id} className="uk-button uk-button-primary uk-float-right uk-margin-small-top">View</a>
                      </div>
                    </div>
                  </div>
                  <div className="d-smart-health-chat-fromnow">
                    {moment(data.dateadd).fromNow()}
                  </div>
                </div>
              })
            }
          </div>
      }
    </div>
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-campaign-report-chat">
      <div className="d-card-header">
        <div className="d-card-header-left">
          Chat
      </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-campaign-report-chat" />
        </div>
      </div>
      <ul uk-tab="">
        <li><a href="#">Day</a></li>
        <li><a href="#">Week</a></li>
        <li><a href="#">Month</a></li>
      </ul>

      <ul className="uk-switcher uk-margin">
        <li>
          {this.chatListrender(this.state.chatDay)}
        </li>
        <li>
          {this.chatListrender(this.state.chatWeek)}
        </li>
        <li>
          {this.chatListrender(this.state.chatMonth)}
        </li>
      </ul>

    </div>
  }
};
