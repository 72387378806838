import React, { useState, useEffect, useCallback } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Iframe from "react-iframe";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import DrawingPaper from "../../Components/DrawingPaper.js";
import moment from "moment";
import { useBeforeunload } from "react-beforeunload";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import FormLoading from "../../Components/Consult/Common/FormLoading";

var WS_URL = process.env.REACT_APP_WS_URL;

UIkit.use(Icons);

const AppointmentCall = (props) => {
  const query = new URLSearchParams(props.location.search);
  const [loadingApi, setLoadingApi] = useState(true);
  const [consultName, setConsultName] = useState("");
  const [memberName, setMemberName] = useState("");
  const [iframSrc, setIframSrc] = useState("");
  const [roomData, setRoomData] = useState({});
  const [roomId, setRoomId] = useState(null);
  const [txn, setTxn] = useState(null);
  const [showWaitingText, setShowWaitingText] = useState(true);
  const [showDrawingPaper, setShowDrawingPaper] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFloatButton, setIsShowFloatButton] = useState(false);

  const { sendJsonMessage, getWebSocket } = useWebSocket(WS_URL, {
    onOpen: () => console.log("WebSocket connection opened."),
    onClose: () => doReconnect(),
    onMessage: (event) => onClientMessage(event),
    shouldReconnect: (closeEvent) => true,
  });

  function doReconnect() {
    console.log("WebSocket re-connection.");
    doConnectToWSServer(roomData);
  }

  function onClientMessage(event) {
    var message = JSON.parse(event.data);

    if (message.type == "WS_RECONNECT_CLIENT") {
      window.location.reload(false);
    } else if (message.type == "WS_CONNECT_CLIENT_VIDEOCALL") {
      connectVideoCall();
    }
  }

  const handleLoadingChange = useCallback(
    (loading) => {
      setIsLoading(loading);
    },
    [setIsLoading]
  );

  const validateIsExpiredLink = useCallback((data) => {
    if (data?.appointment_date) {
      var appointmentDate = new Date(data.appointment_date);
      appointmentDate.setHours(0, 0, 0, 0);
      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      return appointmentDate.getTime() < currentDate.getTime();
      // if (data?.appointment_time) {
      //   const hours = data.appointment_time.substring(0, 2);
      //   const minutes = data.appointment_time.substring(3, 5);
      //   appointmentDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);
      //   const diffHours =
      //     (currentDate.getTime() - appointmentDate.getTime()) / 1000 / 3600;
      //   return diffHours > 6;
      // } else {
      //   appointmentDate.setHours(0, 0, 0, 0);
      //   const diffHours =
      //     (currentDate.getTime() - appointmentDate.getTime()) / 1000 / 3600;
      //   return diffHours > 24;
      // }
    }
    return false;
  });

  async function getRoomDetail() {
    var txn = query.get("txn");
    var appointmentId = query.get("appointmentId");
    var actor = query.get("actor");
    var type = query.get("type");

    setTxn(txn);

    //Set title
    document.title =
      process.env.REACT_APP_TITLE + "-" + txn + "-Video Call Center";

    var result = await Api.consult("MemberGetByTxn", {
      txn: txn,
      appointmentId: appointmentId,
    });

    var { data } = result;

    console.log(data);

    var roomID = data.txn;
    var roomName = data.room_name;

    if (data.txn == null) {
      roomID = appointmentId;
    }

    setRoomId(roomID);

    if (validateIsExpiredLink(data)) {
      setIsLinkExpired(true);
      setShowWaitingText(false);
      return;
    } else {
      setIsLinkExpired(false);
    }

    if (data.txn != null) {
      var result = await Api.consult("MemberEntryRoom", {
        txn: data.txn,
        hn: data.hn,
        status: "entry",
      });
    }

    var room = {
      txn: roomID,
      hn: data.hn,
      name: data.name,
      appointmentId: data.appointmentId,
      actor: actor,
      type: type,
      roomCode: data.room_code,
    };

    setRoomData(room);
    doConnectToWSServer(room);

    if (actor != "patient" || (actor == "patient" && type == "2")) {
      connectVideoCall(roomID);
    }

    if (actor == "member") {
      var title = "";

      if (type == "1") {
        title = "คุณหมอ : ";

        setConsultName(title + data.consult_name);
      } else {
        title = "เภสัชกร : ";
      }
    } else {
      setMemberName(
        "ผู้ป่วย : " +
          data.name +
          " - HN : " +
          data.hn +
          " - ห้องตรวจ : " +
          roomName
      );
    }
  }

  async function connectVideoCall(p_roomID) {
    console.log("connectVideoCall", p_roomID);
    var txnData = "";

    if (p_roomID != null) {
      txnData = p_roomID;
    } else {
      txnData = roomId;
    }

    console.log("connect client " + new Date());
    setIsShowFloatButton(true);
    setShowWaitingText(false);

    setIframSrc("https://con.defence-innovation.com/r-" + txnData);

    setLoadingApi(false);
  }

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  function doCloseWindow() {
    try {
      window.close();
    } catch (err) {
      window.open(
        location.href
          .substring(0, location.href.indexOf("?"))
          .substring(0, location.href.indexOf("#")) + "#",
        "_self"
      );
      window.close();
    }
  }

  function doConnectToWSServer(roomData) {
    sendJsonMessage({
      type: "WS_INIT_DATA",
      content: roomData,
    });
  }

  function selectDrawFile() {
    console.log(showDrawingPaper);
    setShowDrawingPaper(true);
  }

  useEffect(() => {
    getRoomDetail();
  }, []);

  return (
    <>
      {isLoading && <FormLoading />}
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <span>{consultName}</span> <span>{memberName}</span>
        </div>
        {showWaitingText ? (
          <div uk-grid="" className="uk-flex">
            <div
              className="uk-width-1-1@m uk-width-1-1"
              style={{ textAlign: "center", fontSize: 30, backgroundColor: "" }}
            >
              กรุณารอเจ้าหน้าที่สักครู่ค่ะ
            </div>
          </div>
        ) : (
          <></>
        )}
        {isLinkExpired && (
          <div uk-grid="" className="uk-flex">
            <div
              className="uk-width-1-1@m uk-width-1-1"
              style={{ textAlign: "center", fontSize: 30, backgroundColor: "" }}
            >
              ลิงก์หมดอายุแล้ว
            </div>
          </div>
        )}
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-1-1@m uk-width-1-1">
            <Iframe
              allow="camera; microphone; fullscreen; display-capture; autoplay"
              src={iframSrc}
              width="100%"
              height="600"
              style="border: 0px;"
            ></Iframe>
          </div>
          {isShowFloatButton && (
            <Fab
              mainButtonStyles={{
                backgroundColor: "#3498db",
                width: 60,
                borderRadius: 48,
              }}
              actionButtonStyles={{
                backgroundColor: "#696969",
                color: "#27ae60",
              }}
              style={{ bottom: 90, left: 0 }}
              icon="+"
              event="click"
              alwaysShowTitle={true}
            >
              {/*
            <Action
                text="แนบไฟล์"
              >
                </Action>
          */}
              <Action text="วาดรูป" onClick={selectDrawFile}></Action>
            </Fab>
          )}
        </div>
      </div>
      <DrawingPaper
        show={showDrawingPaper}
        txn={txn}
        setShowDrawingPaper={setShowDrawingPaper}
        onLoading={handleLoadingChange}
      />
    </>
  );
  /*
  return (
    <div className="uk-card uk-card-default uk-padding-small">
       <div className="d-card-header">คนไข้ - นายธนกิจ ศรีชัย</div>
       <div uk-grid="" className="uk-flex-middle">
         <div className="uk-width-1-1@m uk-width-1-1">
             ขณะนี้แพทย์กำลังให้คำปรึกษาคนไข้ท่านอื่นอยู่ค่ะ กรุณาปิดหน้าจอ และพยาบาลจะรีบติดต่อกลับไปค่ะ
         </div>
         
       </div>
     </div>
 )*/
};

export default AppointmentCall;
