import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import UserProfileCardChangePassword from "../UserProfileCardChangePassword";
import UserProfileCardPersonalInformation from "../UserProfileCardPersonalInformation";
import ImageUri from "../ImageUri";

UIkit.use(Icons);

export default class UserProfileCard extends Component {
  state = {
    profile: {},
    company: {},
    bmi: "-",
    group: [],
    selectLinkActive: "Profile Overview",
    loadingApi: false,
  };

  componentDidMount() {
    this.profileGet();
  }

  profileGet = async () => {
    var { profile, bmi, company, group } = await Api.member("UserProfileCard");

    this.setState({
      bmi: profile.bmi ? profile.bmi : "-",
      profile: profile ? profile : {},
      company: company ? company : {},
      group: group || [],
      loadingApi: false,
    });
  };

  linkUserType = window.location.pathname;

  linkList = [
    {
      icon: "layers",
      label: "Profile Overview",
      link: "#",
    },
    {
      icon: "user",
      label: "Personal Information",
      before: "userProfileCardPersonalInformation",
      click: () => {
        this.setState({ selectLinkActive: "Personal Information" });
      },
    },
    {
      icon: "briefcase",
      label: "Account Information",
      before: "userProfileCardAccountInformation",
      click: () => {
        this.setState({ selectLinkActive: "Account Information" });
      },
    },
  ];

  userProfileCardChangePasswordRender = () => {
    return (
      <UserProfileCardChangePassword type="m" id={this.state.profile.id} />
    );
  };

  userProfileCardPersonalInformation = () => {
    return (
      <UserProfileCardPersonalInformation
        type="m"
        profile={this.state.profile}
      />
    );
  };

  userProfileCardAccountInformation = () => {
    return (
      <div className="uk-padding-small">
        ประเภทผู้ใช้งาน :{" "}
        {+this.state.company.level === 1 ? "ผู้ดูแลระบบ" : "ผู้ใช้ทั่วไป"}
      </div>
    );
  };

  render() {
    return this.state.loadingApi === true ? (
      <div className="uk-padding-small uk-text-center">
        <span uk-spinner=""></span>
      </div>
    ) : (
      <div className="uk-card uk-card-default">
        <div uk-grid="" className="uk-grid-small uk-padding-small">
          <div className="uk-width-1-3@s uk-width-1-1 uk-text-left@s uk-text-center">
            <div
              className="uk-background-cover uk-background-muted uk-display-inline-block d-user-profile-card-image"
              style={{
                backgroundImage: this.state.profile.image
                  ? `url(${ImageUri(this.state.profile.image)})`
                  : false,
              }}
            ></div>
          </div>
          <div className="uk-width-2-3@s uk-width-1-1 uk-text-left@s uk-text-center">
            <div className="uk-padding-small">
              
              <div className="uk-margin-small-top">
                Name:{" "}
                <b>
                  {this.state.profile.prefixname} {this.state.profile.name}{" "}
                  {this.state.profile.lastname}
                </b>
              </div>
              
            </div>
          </div>
        </div>
        <div
          uk-grid=""
          className="uk-grid-small uk-padding-small uk-padding-remove-top uk-margin-remove-top"
        >
          <div className="uk-width-1-3">
            <div>Email: </div>
            <div>Phone: </div>
            <div>Group: </div>
            <div>BMI: </div>
          </div>
          <div className="uk-width-2-3 uk-text-right">
            <div>
              <a href={"mailto:" + this.state.profile.email}>
                {this.state.profile.email}
              </a>
              &nbsp;
            </div>
            <div>{this.state.profile.tel}&nbsp;</div>
            <div>
              {this.state.group.map((group) => group.name).join(",")}&nbsp;
            </div>
            <div>{this.state.bmi}&nbsp;</div>
          </div>
        </div>
        <div className="d-user-profile-card">
          {this.linkList.map((data, index) => (
            <div key={`link${index}`}>
              {data.before &&
                this.state.selectLinkActive === data.label &&
                this[data.before]()}
              <a
                className={
                  "d-user-profile-card-link " +
                  (this.state.selectLinkActive === data.label
                    ? "d-link-active"
                    : "")
                }
                onClick={() => {
                  if (data.click) {
                    data.click();
                  }
                }}
              >
                <i className={`icon-${data.icon}`}></i> {data.label}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
