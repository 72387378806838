import React, { useEffect, useMemo, useState } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';

import Login from '../../Pages/Consult/Login';
import XLogin from '../../Pages/Consult/XLogin';
import SideMenu from './SideMenu';
import Dashboard from '../../Pages/Consult/Dashboard';
import Error404 from '../../Pages/Error404';
import HeaderMenu from './HeaderMenu';
import moment from 'moment';
import AppointmentList from '../../Pages/Consult/AppointmentList.js';
import AppointmentCall from '../../Pages/Consult/AppointmentCall';
import AppointmentCallRoom from '../../Pages/Consult/AppointmentCallRoom';
import AppointmentAdd from '../../Pages/Consult/AppointmentAdd';
import FormList from '../../Pages/Consult/FormList.js';
import SlotManagement from '../../Pages/Consult/SlotManagement';
import MemberVisit from '../../Pages/Consult/MemberVisit.js';
import Member from '../../Pages/Consult/Member.js';
import ConsultStatistic from '../../Pages/Consult/ConsultStatistic';
import MemberSummary from '../../Pages/Consult/MemberSummary';
import PrescriptionList from '../PrescriptionList';
import ConsultCampaign from './ConsultCampaign';
import ConsultCampaignDetail from './ConsultCampaignDetail';
import ConsultSMCReport from '../../Pages/Consult/ConsultSMCReport';
import VisitNote from './VisitNote';
import consultSMCFinanceReport from '../../Pages/Consult/ConsultSMCFinanceReport';
import FormAdd from '../../Pages/Consult/FormAdd';
import PhamacyImage from '../../Pages/Consult/PhamacyImage';
import FormPreview from '../../Pages/Consult/FormPreview';
import '../../css/dietz.css';
import '../../css/dietz2.css';
import AlertMessage from '../AlertMessage';
import queryString from 'query-string';
import { Switch, Route } from 'react-router-dom';
import Form from '../../Pages/Consult/Form.js';


const CustomerRoute = (props) => {
  const [login, setLogin] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(true);
  const [consult, setConsult] = useState({});

  useEffect(() => {
    init();
  }, []);

  function onCloseAlert() {
    setShowAlert(false);
  }

  async function loginToken(data) {
    var resultLogin = await Api.consult('ConsultLoginByForm', data);

    var { result, responseText, session } = resultLogin;

    if (result && session) {
      localStorage.setItem('consultLogin', session);

    } else {
      window.location.href = "consult";
    }
  }

  async function init() {
    var pathname = props.location.pathname.split('/').filter(Boolean).join('/');

    var byPass = false;

    if ((pathname == "consult/appointmentCall") || (pathname == "consult/appointmentCallRoom") || (pathname == "consult/xlogin")) {
      byPass = true;
    }

    var result = false;

    if (!byPass) {
      let params = queryString.parse(props.location.search)

      if (typeof (params["token"]) != "undefined") {
        await loginToken({ token_login: params["token"] });
      }

      try {
        var { result, consult } = await Api.consult('ConsultLoginBySession');

        setConsult(consult);

        localStorage.setItem('consultProfileData', JSON.stringify(consult));

      } catch (e) {
        console.error(e);
      }
    } else {
      result = true;
    }

    setLogin(result === true ? true : false);
    setLoadingLogin(false);

    const query = new URLSearchParams(props.location.search);
    const mode = query.get('mode');

    if (mode == "iviewer") {
      setShowSideMenu(false);
    }
  }

  const renderRoutes = useMemo(() => {
    // const routeProps = { {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult}};
    return <>
      <Switch>
        <Route path='/consult/consultSMCFinanceReport' render={() => <consultSMCFinanceReport  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/consultSMCReport' render={() => <ConsultSMCReport  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/visitNote' render={() => <VisitNote  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/campaignDetail' render={() => <ConsultCampaignDetail  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/campaign' render={() => <ConsultCampaign  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/prescriptionList' render={() => <PrescriptionList  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/slotManagement' render={() => <SlotManagement  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/formList' render={() => <FormList  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/memberSummary' render={() => <MemberSummary  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/consultStatistic' render={() => <ConsultStatistic  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/formPreview' render={() => <FormPreview  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/formAdd' render={() => <FormAdd  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/phamacyImage' render={() => <PhamacyImage  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/member' render={() => <Member  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/xlogin' render={() => <XLogin  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/appointmentAdd' render={() => <AppointmentAdd  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/appointmentCallRoom' render={() => <AppointmentCallRoom  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/appointmentCall' render={() => <AppointmentCall  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/memberVisit' render={() => <MemberVisit  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/appointmentList' render={() => <AppointmentList  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='/consult/form' render={() => <Form />} />
        <Route exact path='/consult' render={() => <Dashboard  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route exact path='/' render={() => <Dashboard  {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />} />
        <Route path='*' render={() => <Error404 />} />
      </Switch>
    </>
  });

  function routeRender() {
    var routes = {
      "/": Dashboard,
      "/consult": Dashboard,
      "/consult/appointmentList": AppointmentList,
      "/consult/memberVisit": MemberVisit,
      "/consult/appointmentCall": AppointmentCall,
      "/consult/appointmentCallRoom": AppointmentCallRoom,
      "/consult/appointmentAdd": AppointmentAdd,
      "/consult/xlogin": XLogin,
      "/consult/member": Member,
      "/consult/phamacyImage": PhamacyImage,
      "/consult/formAdd": FormAdd,
      "/consult/formPreview": FormPreview,
      "/consult/consultStatistic": ConsultStatistic,
      "/consult/memberSummary": MemberSummary,
      "/consult/formList": FormList,
      "/consult/slotManagement": SlotManagement,
      "/consult/prescriptionList": PrescriptionList,
      "/consult/campaign": ConsultCampaign,
      "/consult/campaignDetail": ConsultCampaignDetail,
      "/consult/visitNote": VisitNote,
      "/consult/consultSMCReport": ConsultSMCReport,
      "/consult/consultSMCFinanceReport": consultSMCFinanceReport
    };

    var pathname = props.location.pathname.split('/').filter(Boolean).join('/');
    var Route = routes[`/${pathname}`];
    if (Route) {
      return <Route {...props} qs={Api.queryString} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} consult={consult} />;
    }
    else {
      return <Error404 {...props} />;
    }
  }

  if (login) {
    return (
      <div uk-grid="" className="uk-grid-collapse">
        {
          (showSideMenu) ?
            <div id="d-side" className="uk-width-1-1">
              <SideMenu {...props} consult={consult} />
            </div>
            :
            ""
        }
        <div id="d-body" className="uk-width-expand uk-width-1-1 ">
          <HeaderMenu {...props} />
          <div>
            {renderRoutes}
            {/* {routeRender()} */}
          </div>
          <div className="d-footer">
            <div uk-grid="" className="uk-child-width-1-1 uk-child-width-1-2@l">
              <div>
                {/*
                Copyright © {moment().format('YYYY')} <a className="text-bold-800 grey darken-2" href="https://dietz.asia/" target="_blank" rel="noopener">dietz.asia </a>
                */}
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
        <AlertMessage title={"ข้อความ"} message={alertMessage} show={showAlert} onClose={onCloseAlert} />
      </div>
    )
  } else {
    return loadingLogin ?
      <div className="uk-text-center uk-padding-small d-page-loadings-login">
        <span uk-spinner=""></span>
      </div>
      :
      <Login {...props} />
  }
}

export default CustomerRoute;