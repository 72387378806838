import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import SelectMemberCompanygroup from '../../Components/Member/SelectMemberCompanygroup';
import FullScreenElement from '../FullScreenElement';
import DataTypeSelect from '../DataTypeSelect';

UIkit.use(Icons);

export default class CampaignReportAvgBmiLoss extends Component {
  state = {
    selectDataType : 'bmi',
    dataMale: [],
    dataFemale: [],
    monthGraph: [],
    selectMemberCompanyGroup: 'all'
  }

  month = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var {male,female, monthGraph} = await Api.member('CampaignReportAvgLoss', {campaignId: this.props.id, groupId: this.state.selectMemberCompanyGroup, dataType: this.state.selectDataType});
    
    this.setState({
      dataMale: male ? male.reverse() : [],
      dataFemale: female ? female.reverse() : [],
      monthGraph: monthGraph ? monthGraph.reverse() : []
    });
    
  }

  graphRender = () => {
    var option = {
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },
      tooltip: {
        trigger: "axis"
      },
      legend: {
        data: ["Male", "Female"]
      },
      color: ["#fd397a", "#2f94f3", "#FFA87D"],
      calculable: true,
      xAxis: [{
        type: "category",
        boundaryGap: false,
        data: this.state.monthGraph.map(data=>{ return this.month[+data - 1] ; }),
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],
      yAxis: [{
        type: "value",
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],
      series: [{
        name: "Female",
        type: "line",
        symbol: 'circle',
        smooth: true,
        itemStyle: {
          normal: {
            areaStyle: {
              type: "default"
            }
          }
        },
        data: this.state.dataFemale
      }, {
        name: "Male",
        type: "line",
        symbol: 'circle',
        smooth: true,
        itemStyle: {
          normal: {
            areaStyle: {
              type: "default"
            }
          }
        },
        data: this.state.dataMale
      }]
    };

    return <ReactEcharts
    option={option}
    notMerge={true}
    lazyUpdate={true}
    style={{height: 400}}
    theme={"theme_name"}
  />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-campaign-report-avg-bmi-loss">
      <div className="d-card-header">
        <div className="d-card-header-left">
          AVG LOSS
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-campaign-report-avg-bmi-loss" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        <span className="uk-margin-small-right">
        <SelectMemberCompanygroup id={this.props.id} callback={(result)=>this.setState({SelectMemberCompanygroup: result}, ()=>{
          this.dataGet();
        })} />
        </span>
        <DataTypeSelect notSelectCategory={true} value={this.state.selectDataType} callback={(value)=>{
          this.setState({ selectDataType: value }, ()=>this.dataGet());
        }} />
      </div>
      {this.graphRender()}
    </div>
  }
};
