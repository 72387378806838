import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';

var utilHandleChange = require('./FormUtil.js').handleChange;
var utilSetData = require('./FormUtil.js').setData;
var useEffectDataForm = require('./FormUtil.js').useEffectDataForm;
var useEffectPropsDataForm = require('./FormUtil.js').useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormFearFalling = (props) => {


    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    function setData(field, valueData) {
        var datas = { ...dataForm };

        datas = { ...datas, ["data_" + field]: valueData };

        setDataForm(datas);
    }

    function setDataObj(data) {
        var datas = { ...dataForm };

        datas = { ...datas, ...data };

        setDataForm(datas);
    }


    useEffect(() => {
        props.addValue({
            formName: "form_fearfalling",
            formType: "35"
        });
    }, []);

    useEffect(() => {
        useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);

    }, [dataForm]);

    useEffect(() => {
        useEffectPropsDataForm(setDataForm, props.dataForm);

    }, [props.dataForm]);

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1n,
            dataForm.data_2n,
            dataForm.data_3n,
            dataForm.data_4n,
            dataForm.data_5n,
            dataForm.data_6n,
            dataForm.data_7n,
        ]);
    }, [dataForm]);

    useEffect(() => {
        props.setScore(getTotalScore);
    }, [dataForm]);

    return (
        <div uk-grid="" className="uk-grid uk-padding-small">

            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementTitle label="Fear of falling (Form ที่ใช้ใน Post-falling assessment)" />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementTitle label="แบบประเมินความกังวลหรือกลัวการหกล้ม (ฉบับย่อ)" />
            </div>


            <div className="uk-width-1-1@m uk-width-1-1">
                <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                    <thead>
                        <tr>
                            <th colSpan={6}>
                                ต่อไปนี้ ผู้วิจัยขอสอบถามว่าท่านมีความกังวลอย่างไรต่อความเป็นไปได้ที่จะหกล้ม กรุณาตอบคำถามโดยคำนึงว่า โดยปกติท่านทำกิจกรรมเหล่านี้อย่างไร หากปัจจุบัน ท่านไม่ได้ทำกิจกรรมเหล่านี้ด้วยตัวเอง<br />
                                กรุณาตอบคำถามโดยคำนึงว่าท่านมีความกังวลอย่างไรเกี่ยวกับการหกล้มถ้าท่านทำกิจกรรมเหล่านี้ด้วยตัวเอง สำหรับกิจกรรมดังต่อไปนี้ กรุณาทำเครื่องหมายในช่องที่ใกล้เคียงมากที่สุดกับความเห็นของท่านในเรื่องความกังวลว่า<br />
                                อาจจะหกล้มหากท่านทำกิจกรรมเหล่านี้
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>ไม่กังวลหรือไม่กลัว (1)</th>
                            <th>กังวลหรือกลัว เล็กน้อย (2)</th>
                            <th>กังวลหรือกลัว ปานกลาง (3)</th>
                            <th>กังวลหรือกลัว มาก (4)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>การใส่หรือถอดเสื้อผ้า</td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>การอาบน้ำ</td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>การนั่งหรือลุกจากเก้าอี้</td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>การขึ้นหรือลงบันได</td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>การเอื้อมหยิบของเหนือศีรษะ หรือก้มลงเก็บของบนพื้น</td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>การเดินขึ้นหรือลงที่ลาดชัน</td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>การออกไปร่วมกิจกรรมทางสังคม เช่น กิจกรรมทางศาสนา การรวมญาติ หรือกิจกรรมชมรม</td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td colSpan={2}>คะแนนรวมทั้งหมด</td>
                            <td colSpan={4}>{getTotalScore}</td>
                        </tr>
                    </tbody>
                </table>


            </div>


        </div>
    )
}

export default FormFearFalling;


