import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Api from "../../Components/Api";

import FormFammed from "./FormType/FormFammed";
import FormESAS from "./FormType/FormESAS";
import FormPosScale from "./FormType/FormPosScale";
import FormGoodDeath from "./FormType/FormGoodDeath";
import FormTMSE from "./FormType/FormTMSE";
import FormNursingAssessment from "./FormType/FormNursingAssessment";
import FormACSCaringSet from "./FormType/FormACSCaringSet";
import FormACSTeleFollowUp from "./FormType/FormACSTeleFollowUp";
import FormHeartRepair from "./FormType/FormHeartRepair";
import FormCathLabDischarge from "./FormType/FormCathLabDischarge";
import FormCathLabAssessment from "./FormType/FormCathLabAssessment";

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from "moment";
import "../../css/dietz.css";
import FormHomeVisit from "./FormType/FormHomeVisit";
import FormConsultation from "./FormType/FormConsultation";
import FormHeartFailure from "./FormType/FormHeartFailure";
import FormHeartFailureOPD from "./FormType/FormHeartFailureOPD";
import FormHeartFailureDischargeCheckList from "./FormType/FormHeartFailureDischargeCheckList";
import FormADL from "./FormType/FormADL";
import FormStroke from "./FormType/FormStroke";
import FormRankinScale from "./FormType/FormRankinScale";
import FormPhamacy from "./FormType/FormPhamacy";
import FormPhamacyImage from "./FormType/FormPhamacyImage";
import FormNoac from "./FormType/FormNoac";
import FormWafarin from "./FormType/FormWafarin";
import FormInhaler from "./FormType/FormInhaler";
import FormInsurine from "./FormType/FormInsurine";
import FormBispho from "./FormType/FormBispho";
import FormDeliveryGeneralMedicine from "./FormType/FormDeliveryGeneralMedicine";
import FormDeliveryDrugMedicine from "./FormType/FormDeliveryDrugMedicine";
import FormDeliveryWafarineMedicine from "./FormType/FormDeliveryWafarineMedicine";
import FormPPS from "./FormType/FormPPS";
import FormMoca from "./FormType/FormMoca";
import Form2Q from "./FormType/Form2Q";
import Form8Q from "./FormType/Form8Q";
import Form9Q from "./FormType/Form9Q";
import FormPostFalling from "./FormType/FormPostFalling";
import FormFearFalling from "./FormType/FormFearFalling";
import FormZarit12 from "./FormType/FormZarit12";
import FormDrugAdherence from "./FormType/FormDrugAdherence";
import FormTelePharmacy from "./FormType/FormTelePharmacy";
import FormTGDS from "./FormType/FormTGDS";
import FormGAD7 from "./FormType/FormGAD7";
import FormIPaper from "./FormIPaper";

import AlertMessage from "../AlertMessage";

import MemberProfileFormHeader from "./MemberProfileFormHeader";

//

import FormStrokePaper from "./IPaper/FormStrokePaper";
import { ca, fi, tr } from "date-fns/locale";
import SaveSuccessfulMessage from "../SaveSuccessfulMessage";
import FormHomeVisitPaper from "./IPaper/FormHomeVisitPaper";
import FormConsultationPaper from "./IPaper/FormConsultationPaper";
import FormHeartFailurePaper from "./IPaper/FormHeartFailurePaper";
import FormHeartFailureOPDPaper from "./IPaper/FormHeartFailureOPDPaper";
import FormCathLabDischargePaper from "./IPaper/FormCathLabDischargePaper";
import FormHeartFailureDischargeCheckListPaper from "./IPaper/FormHeartFailureDischargeCheckListPaper";
import FormCathLabAssessmentPaper from "./IPaper/FormCathLabAssessmentPaper";
import FormACSCaringSetPaper from "./IPaper/FormACSCaringSetPaper";
import FormACSTeleFollowUpPaper from "./IPaper/FormACSTeleFollowUpPaper";
import FormHeartRepairPaper from "./IPaper/FormHeartRepairPaper";
import FormNursingAssessmentPaper from "./IPaper/FormNursingAssessmentPaper";
import FormTelePharmacyPaper from "./IPaper/FormTelePharmacyPaper";
import FormFammedPaper from "./IPaper/FormFammedPaper";
import FormHeartFailureClinic from "./FormType/FormHeartFailureClinic";
import FormMinnesotaLivingWithHeartFailure from "./FormType/FormMinnesotaLivingWithHeartFailure";
import FormADLS from "./FormType/FormADLS";
import FormIADLS from "./FormType/FormIADLS";
import IPaperPopupForm from "./IPaper/IPaperPopupForm";
import dateFormat from "dateformat";
import FormADLPaper from "./IPaper/FormADLPaper";
import FormRankinScalePaper from "./IPaper/FormRankinScalePaper";
import Form9QPaper from "./IPaper/Form9QPaper";
import FormMinnesotaLivingWithHeartFailurePaper from "./IPaper/FormMinnesotaLivingWithHeartFailurePaper";
import FormHeartFailureClinicPaper from "./IPaper/FormHeartFailureClinicPaper";
import FormPPSPaper from "./IPaper/FormPPSPaper";
import FormESASPaper from "./IPaper/FormESASPaper";
import FormPosScalePaper from "./IPaper/FormPosScalePaper";
import Form2QPaper from "./IPaper/Form2QPaper";
import FormPostFallingPaper from "./IPaper/FormPostFallingPaper";
import FormGoodDeathPaper from "./IPaper/FormGoodDeathPaper";
import FormTMSEPaper from "./IPaper/FormTMSEPaper";
import Form8QPaper from "./IPaper/Form8QPaper";
import FormMocaPaper from "./IPaper/FormMocaPaper";
import FormFearFallingPaper from "./IPaper/FormFearFallingPaper";
import FormZarit12Paper from "./IPaper/FormZarit12Paper";
import FormDrugAdherencePaper from "./IPaper/FormDrugAdherencePaper";
import FormTGDSPaper from "./IPaper/FormTGDSPaper";
import FormGAD7Paper from "./IPaper/FormGAD7Paper";
import FormWafarinPaper from "./IPaper/FormWafarinPaper";
import FormNoacPaper from "./IPaper/FormNoacPaper";
import FormInhalerPaper from "./IPaper/FormInhalerPaper";
import FormInsurinePaper from "./IPaper/FormInsurinePaper";
import FormBisphoPaper from "./IPaper/FormBisphoPaper";
import FormDeliveryGeneralMedicinePaper from "./IPaper/FormDeliveryGeneralMedicinePaper";
import FormDeliveryDrugMedicinePaper from "./IPaper/FormDeliveryDrugMedicinePaper";
import FormDeliveryWafarineMedicinePaper from "./IPaper/FormDeliveryWafarineMedicinePaper";

UIkit.use(Icons);

var utilPrepareDataBeforeSave =
  require("./FormType/FormUtil.js").prepareDataBeforeSave;
var utilParseStringToJson = require("./FormType/FormUtil.js").parseStringToJson;

const FormPaper = (props) => {
  const [profile, setProfile] = useState(true);
  const [fields, setFields] = useState({});
  const [previews, setPreviews] = useState({});
  const [formItem, setFormItem] = useState([]);
  const [dataFormOlds, setDataFormOlds] = useState([]);
  const [previewDatas, setPreviewDatas] = useState({});
  const [score, setScore] = useState(0);
  const [hn, setHN] = useState(null);
  const [txn, setTXN] = useState(null);
  const Ref = useRef(this);
  const [showFormPopupPreview, setShowFormPopupPreview] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  const [formType, setFormType] = useState(0);
  const [popupHtml, setPopupHtml] = useState("");
  const [isShowSaveSuccessfulPopup, setIsShowSaveSuccessfulPopup] =
    useState(false);
  const [isShowSaveFailedPopup, setIsShowSaveFailedPopup] = useState(false);
  const [iPaperShow, setIPaperShow] = useState(false);
  const [memberProfile, setMemberProfile] = useState({});
  const [isFetchDone, setIsFetchDone] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isShowPreview, setIsShowPreview] = useState(false);
  const previewHtmlRef = useRef();

  var hideSaveBtn = true;
  var hidePreviewBtn = false;

  if (typeof props.hideSaveBtn != "undefined" && !props.hideSaveBtn) {
    hideSaveBtn = false;
  }

  if (typeof props.hidePreviewBtn != "undefined" && props.hidePreviewBtn) {
    hidePreviewBtn = true;
  }

  function formInit() {
    const profileTxt = localStorage.getItem("consultProfileData");
    const profileData = JSON.parse(profileTxt);
    setProfile(profileData);
  }

  function addDataFormItem(data) {
    if (typeof data != "undefined") {
      var found = formItem.find((m) => m.data.formType == data.data.formType);

      console.log("found item ", found);

      if (typeof found != "undefined") {
        found.data = data.data;
      } else {
        formItem.push(data);
      }

      console.log(formItem);

      setFormItem((d) => formItem);
    }
  }

  useEffect(() => {
    async function getDataForm() {
      const { formId, formName, type } = props;

      if (typeof formId != "undefined" && formId != null) {
        var { data } = await Api.consult("ConsultFormGet", {
          formType: type,
          formId: formId,
          txn: txn,
        });

        if (!data) return;

        setHN(data.hn);
        setTXN(data.txn);

        console.log("data after fetch", data);
        data = prepareData(type, data);
        if (props.type == 38) {
          var finalData = await getDataDrug(data.hn, data.txn, data);
          setFields((fields) => ({ ...fields, ...finalData }));
        } else {
          setFields((fields) => ({ ...fields, ...data }));
        }
      }
    }

    getDataForm().then(() => setIsFetchDone(true));
  }, [props.formId]);

  const prepareData = useCallback((formType, data) => {
    console.log("prepareData");
    var consultProfileDataStorage = localStorage.getItem("consultProfileData");
    var consultProfileData = JSON.parse(consultProfileDataStorage);
    if (formType == 1) {
      data.data_2 = utilParseStringToJson(data.data_2);
      data.data_113 = utilParseStringToJson(data.data_113);
      data.data_114 = utilParseStringToJson(data.data_114);
      data.data_115 = utilParseStringToJson(data.data_115);
      data.data_116 = utilParseStringToJson(data.data_116);
    } else if (formType == 5) {
      data.data_28 = utilParseStringToJson(data.data_28);
    } else if (formType == 7) {
      data.data_181 = utilParseStringToJson(data.data_181);
      if (!data?.data_181) {
        data.data_181 = {
          age: "",
          pulse: "",
          sbp: "",
          creatinine: "",
          cardiacArrest: "0",
          st: "0",
          cardiacEnzymes: "0",
          killipClass: "0",
        };
      }
    } else if (formType == 10) {
      data.data_63 = utilParseStringToJson(data.data_63);
    } else if (formType == 11) {
      data.data_35 = utilParseStringToJson(data.data_35);
      data.data_79 = utilParseStringToJson(data.data_79);
      data.data_80 = utilParseStringToJson(data.data_80);
      data.data_81 = utilParseStringToJson(data.data_81);
      data.data_82 = utilParseStringToJson(data.data_82);
      data.data_83 = utilParseStringToJson(data.data_83);
      data.data_84 = utilParseStringToJson(data.data_84);
      data.data_85 = utilParseStringToJson(data.data_85);
      data.data_86 = utilParseStringToJson(data.data_86);
      data.data_87 = utilParseStringToJson(data.data_87);
      data.data_88 = utilParseStringToJson(data.data_88);
      data.data_89 = utilParseStringToJson(data.data_89);
      data.data_90 = utilParseStringToJson(data.data_90);
      data.data_91 = utilParseStringToJson(data.data_91);
      data.data_92 = utilParseStringToJson(data.data_92);
      data.data_93 = utilParseStringToJson(data.data_93);
      data.data_94 = utilParseStringToJson(data.data_94);
      data.data_95 = utilParseStringToJson(data.data_95);
      data.data_96 = utilParseStringToJson(data.data_96);
      data.data_97 = utilParseStringToJson(data.data_97);
      data.data_98 = utilParseStringToJson(data.data_98);
      data.data_99 = utilParseStringToJson(data.data_99);
      data.data_100 = utilParseStringToJson(data.data_100);
    } else if (formType == 12) {
      if (!data.data_2) {
        data.data_2 = dateFormat(new Date(), "yyyy-mm-dd");
      }
    } else if (formType == 14) {
      data.data_112 = utilParseStringToJson(data.data_112);
      data.data_114 = utilParseStringToJson(data.data_114);
      data.data_116 = utilParseStringToJson(data.data_116);
      data.data_120 = utilParseStringToJson(data.data_120);
      data.data_122 = utilParseStringToJson(data.data_122);
      data.data_124 = utilParseStringToJson(data.data_124);
      data.data_126 = utilParseStringToJson(data.data_126);
      data.data_128 = utilParseStringToJson(data.data_128);
      data.data_130 = utilParseStringToJson(data.data_130);
      data.data_132 = utilParseStringToJson(data.data_132);
      data.data_134 = utilParseStringToJson(data.data_134);
      data.data_136 = utilParseStringToJson(data.data_136);
      data.data_140 = utilParseStringToJson(data.data_140);
      data.data_142 = utilParseStringToJson(data.data_142);
      data.data_144 = utilParseStringToJson(data.data_144);
      data.data_146 = utilParseStringToJson(data.data_146);
      data.data_148 = utilParseStringToJson(data.data_148);
      data.data_150 = utilParseStringToJson(data.data_150);
      data.data_152 = utilParseStringToJson(data.data_152);
      data.data_154 = utilParseStringToJson(data.data_154);
      data.data_157 = utilParseStringToJson(data.data_157);
      data.data_159 = utilParseStringToJson(data.data_159);
      data.data_161 = utilParseStringToJson(data.data_161);
      data.data_165 = utilParseStringToJson(data.data_165);
      data.data_188 = utilParseStringToJson(data.data_188);
      data.data_167 = utilParseStringToJson(data.data_167);
      data.data_169 = utilParseStringToJson(data.data_169);
      data.data_138 = utilParseStringToJson(data.data_138);
    } else if (formType == 18) {
      data.data_26 = utilParseStringToJson(data.data_26);
    } else if (formType == 22) {
      if (!data.data_21) {
        data.data_21 = "1";
      }
      data.data_22 = consultProfileData.doctorName;
      data.data_27 = data.patient_name;
    } else if (formType == 23) {
      if (!data.data_21) {
        data.data_21 = "1";
      }
      data.data_22 = consultProfileData.doctorName;
      data.data_27 = data.patient_name;
    } else if (formType == 24) {
      if (!data.data_15) {
        data.data_15 = "1";
      }
      data.data_16 = consultProfileData.doctorName;
      data.data_35 = data.patient_name;
    } else if (formType == 25) {
      if (!data.data_17) {
        data.data_17 = "1";
      }
      data.data_18 = consultProfileData.doctorName;
      data.data_29 = data.patient_name;
    } else if (formType == 26) {
      if (!data.data_17) {
        data.data_17 = "1";
      }
      data.data_18 = consultProfileData.doctorName;
      data.data_24 = data.patient_name;
    } else if (formType == 29) {
      data.data_61 = utilParseStringToJson(data.data_61);
      if (typeof data.data_61 == "object" && data.data_61?.length > 0) {
        data.data_61.push({
          key: data.data_61.length + 1,
          data_1: dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"),
          data_2: "",
          data_3: "",
          data_4: "",
        });
      } else {
        data.data_61 = [
          {
            key: 1,
            data_1: dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"),
            data_2: "",
            data_3: "",
            data_4: "",
          },
        ];
      }
    } else if (formType == 38) {
      data.data_drug = utilParseStringToJson(data.data_drug);
    }

    return data;
  });

  useEffect(() => {
    if (props.dataForm != null) {
      setFields((fields) => ({ ...fields, ...props.dataForm }));
    }
  }, [props.dataForm]);

  useEffect(() => {
    if (props.type != 0) {
      setFormType(props.type);
    }
  }, [props.type]);

  useEffect(() => {
    if (props.hn != null && props.txn != null) {
      setHN(props.hn);
      setTXN(props.txn);
    }
  }, [props.hn, props.txn]);

  useEffect(() => {
    if (!hn && !txn) {
      var data = prepareData(props.type, {});
      setFields((fields) => ({ ...fields, ...data }));
    }
  }, []);

  async function getAllExistingData() {
    if (!hn || !txn) {
      return;
    }
    try {
      var result = await Api.consult("ConsultFormGetList", {
        formType: props.type,
        txn: txn,
        hn: hn,
        loadOld: "1",
      });

      var { list } = result;

      if (list != null) {
        setDataFormOlds(list);
      } else {
        setDataFormOlds([]);
      }
    } catch (error) {
      console.error("getAllExistingData", error);
    }
  }

  async function getSearchData(searchValue) {
    if (!hn) {
      return;
    }
    try {
      var result = await Api.consult("ConsultFormGetList", {
        formType: props.type,
        hn: hn,
        dateStart: searchValue.startDate,
        dateStop: searchValue.endDate,
        data_no: searchValue.seqNo,
      });

      var { list } = result;

      if (list != null) {
        setDataFormOlds(list);
      } else {
        setDataFormOlds([]);
      }
    } catch (error) {
      console.error("getSearchData", error);
      setDataFormOlds([]);
    }
  }

  async function getDataDrug(hn, txn, data) {
    console.log("getDataDrug", hn, txn);
    try {
      var result = await Api.consult("GetDrugByTxn", {
        hn: hn,
        txn: txn,
        patientType: "o",
      });

      console.log(result);

      var { list } = result;
      let drugList = [];
      let drugEnableList = [];

      if (list.data.length > 0) {
        for (const drug of list.data[0]) {
          if (drug.status == "0") {
            drugList.push(drug.tele_drug_type);
            drugEnableList.push(drug);
          }
        }
        if (data?.data_drug) {
          for (const drug of drugEnableList) {
            var oldDrug = data?.data_drug?.find((item) => item.id == drug.id);
            if (oldDrug) {
              drug.data_1 = oldDrug?.data_1 || "0";
              drug.data_2 = oldDrug?.data_2 || "";
            } else {
              drug.data_1 = "0";
              drug.data_2 = "";
            }
          }
        } else {
          for (const drug of drugEnableList) {
            drug.data_1 = "0";
            drug.data_2 = "";
          }
        }
        console.log("GetDrugByTxn", drugEnableList);
      }

      return {
        ...data,
        drug_list_length: drugEnableList.length,
        data_drug_type: JSON.stringify(drugList),
        data_drug: drugEnableList,
      };
    } catch (error) {
      console.error(error);
      return data;
    }
  }

  useEffect(() => {
    async function getData() {
      console.log("getData");
      if (hn != null && txn != null && !props.isPopup) {
        if (props.formId == null) {
          var result = await Api.consult("ConsultFormGetList", {
            formType: props.type,
            txn: txn,
          });

          var { list } = result;
          console.log("list of data", list);

          if (list != null) {
            console.log("load data from db");
            var data = prepareData(props.type, list[0]);

            if (props.type == 38) {
              var finalData = await getDataDrug(hn, txn, data);
              setFields((fields) => ({ ...fields, ...finalData }));
            } else {
              setFields((fields) => ({ ...fields, ...data }));
            }
          } else {
            console.log("data not found");
            var data = prepareData(props.type, {});

            if (props.type == 38) {
              var finalData = await getDataDrug(hn, txn, data);
              setFields((oldFields) => ({
                formType: oldFields?.formType,
                formName: oldFields?.formName,
                patient_name: oldFields?.patient_name,
                txn: txn,
                hn: hn,
                ...finalData,
              }));
            } else {
              setFields((oldFields) => ({
                formType: oldFields?.formType,
                formName: oldFields?.formName,
                patient_name: oldFields?.patient_name,
                txn: txn,
                hn: hn,
                ...data,
              }));
            }
          }
        }

        var result = await Api.consult("ConsultFormGetList", {
          formType: props.type,
          txn: txn,
          hn: hn,
          loadOld: "1",
        });

        var { list } = result;

        console.log("get data list", result);

        if (list != null) {
          if (props.type >= 22 && props.type <= 26) {
            if (!fields.data_no) {
              setFields((oldFields) => ({
                ...oldFields,
                data_no: list.length + 1,
              }));
            }
          }

          setDataFormOlds(list);
        } else {
          if (props.type >= 22 && props.type <= 26) {
            if (!fields.data_no) {
              setFields((oldFields) => ({
                ...oldFields,
                data_no: 1,
              }));
            }
          }
          setDataFormOlds([]);
        }
      }
    }

    getData().then(() => setIsFetchDone(true));
  }, [hn, txn, formType]);

  async function loadDataFormItem(formType, txn) {
    if (txn != null) {
      var result = await Api.consult("ConsultFormGetList", {
        formType: formType,
        txn: txn,
      });

      var { list } = result;

      console.log("list", list);

      const temp = formItem;
      if (list != null) {
        console.log("data formItem", formType, list[0]);
        temp.push({ data: list[0] });
        setFormItem(temp);
      } else {
        if (formType >= 22 && formType <= 26) {
          var result = await Api.consult("ConsultFormGetList", {
            formType: formType,
            txn: txn,
            hn: hn,
            loadOld: "1",
          });

          var { list } = result;

          const data_no = list != null ? list.length + 1 : 1;
          temp.push({ data: { formType: formType, data_no: data_no } });
          setFormItem(temp);
        }
      }
    }
  }

  async function clearDataForm() {
    var data = prepareData(props.type, {});

    setFields((oldFields) => ({
      formType: oldFields.formType,
      formName: oldFields.formName,
      ...data,
    }));
  }

  async function onSelectMember(data) {
    console.log("select member", data);
    if (typeof data.hn != "undefined" && typeof data.txn != "undefined") {
      setHN(data.hn);
      setTXN(data.txn);
      setFields((oldFields) => ({ ...oldFields, hn: data.hn, txn: data.txn }));
      setIsFetchDone(false);
      setFormItem([]);
    }
    setMemberProfile(data);
  }

  function setMemberData(data) {
    setMemberProfile(data);
  }

  function getDataFormItem(formType) {
    console.log("getDataFormItem", formItem);
    var found = formItem.find((m) => m.data.formType == formType);

    if (typeof found != "undefined") {
      return found.data;
    } else {
      return null;
    }
  }

  async function doSave() {
    if (typeof props.doSave != "undefined") {
      var paperHtml = "";
      if (previewHtmlRef.current) {
        paperHtml = previewHtmlRef.current.innerHTML;
      }

      console.log("paperHtml", paperHtml);

      fields["html"] = paperHtml;

      setIsShowPreview(false);
      props.doSave({ data: fields, score: score });
    } else {
      // var dataString = localStorage.getItem("FormDataSave");
      // var dataForSave = JSON.parse(dataString);

      var dataForSave = { ...fields, hn, txn };
      var dataSave;

      // localStorage.removeItem("FormDataSave");
      // dataForSave = JSON.parse(dataString);

      console.log(dataForSave);

      if (!validateSave(dataForSave.formType)) {
        setIsShowPreview(false);
        setIsShowSaveFailedPopup(true);
        return;
      }

      try {
        var paperHtml = "";
        if (previewHtmlRef.current) {
          paperHtml = previewHtmlRef.current.innerHTML;
        }

        //console.log(paperHtml);

        var dataSave = {};

        if (typeof dataForSave?.data_form_list != "undefined") {
          dataSave = dataForSave.data_form_list[0];
          dataSave = {
            ...dataSave,
            hn: hn,
            txn: txn,
            formType: dataForSave.formType,
            formName: dataSave.formName,
          };
        } else {
          dataSave = dataForSave;
        }

        utilPrepareDataBeforeSave(dataSave, [
          "data_form_list",
          "painImg",
          "drug_name",
        ]);

        console.log("data", dataSave);

        var error = false;

        var result = await Api.consult("ConsultFormSave", {
          ...dataSave,
          loginToken: profile.loginToken,
          formId: props.formId,
          consultId: profile.consultId,
          doctorId: profile.doctorId,
          html: paperHtml,
          memberId: 0,
          //debug:1,
        });

        if (!result.result) {
          error = true;
          console.log(formItem);
        }

        if (formItem.length > 0) {
          formItem.forEach(async (item) => {
            console.log("svae igtem");
            var result = await Api.consult("ConsultFormSave", {
              ...item.data,
              loginToken: profile.loginToken,
              consultId: profile.consultId,
              doctorId: profile.doctorId,
              html: item.data.html,
              memberId: 0,
              debug: 0, //1 for print sql
            });

            if (!result.result) {
              error = true;
              console.log(formItem);
            }
          });
        }

        if (!error) {
          clearDataForm();
          setIsShowPreview(false);
          setIsShowSaveSuccessfulPopup(true);
        } else {
          setIsShowPreview(false);
          console.log(result);
          setWarningMessage("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
          setIsShowSaveFailedPopup(true);
        }
      } catch (error) {
        console.log(error);
        setIsShowPreview(false);
        setIsShowSaveFailedPopup(true);
      }
    }
  }

  useEffect(() => {
    console.log(formItem);
  }, [formItem]);

  const handleCloseSavePopup = useCallback(() => {
    setIsShowSaveSuccessfulPopup(false);
    if (typeof props.doSave != "undefined") {
      props.doSave({ score: score });
    } else {
      window.location.href = "/consult/formList";
    }
  });

  function doPreview() {
    localStorage.setItem("FormDataSave", JSON.stringify(fields));

    // if (document.getElementById("htmlMemberData") != null) {
    //   var memberData = document.getElementById("htmlMemberData").innerHTML;
    //   memberData = memberData.replace(
    //     '<div class="uk-inline"><button class="uk-button uk-button-primary" target="_blank">เลือกผู้ป่วย</button>',
    //     ""
    //   );

    //   if (document.getElementById("htmlPreviewMemberData") != null) {
    //     document.getElementById("htmlPreviewMemberData").innerHTML = memberData;

    //     var paperHtml = Ref.current.innerHTML;
    //     document.getElementById("previewHtml").innerHTML = paperHtml;
    //   }
    // }

    setIsShowPreview(true);
    // UIkit.modal("#modal-preview").show();
  }

  function onClosePopupPreview(data) {
    setShowFormPopupPreview(false);
  }

  function onCloseSuccess() {
    setIsShowSaveSuccessfulPopup(false);

    window.location.href = "/consult/formList";
  }

  function addValue(data) {
    setFields((fields) => ({ ...fields, ...data }));
  }

  function validateSave(type) {
    console.log("save");
    setWarningMessage("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    if (type == "1") {
      if (fields.data_114?.length) {
        const isData114Valid = fields.data_114?.some((s) => s.is_edit);
        return !isData114Valid;
      }
    } else if (type == "6") {
      if (!fields.data_1) {
        setWarningMessage("จำเป็นต้องระบุ 1.1 สุขภาพของท่านเป็นอย่างไร");
        return false;
      }
      if (!fields.data_2) {
        setWarningMessage(
          "จำเป็นต้องระบุ 1.2 ท่านพึงพอใจต่อภาวะสุขภาพของท่านหรือไม่"
        );
        return false;
      }

      if (!fields.data_4) {
        setWarningMessage("จำเป็นต้องระบุ การดูแลสุขภาพเมื่อเจ็บป่วย");
        return false;
      }

      if (!fields.data_6) {
        setWarningMessage("จำเป็นต้องระบุ การปฏิบัติตามแผนการรักษา");
        return false;
      }

      if (!fields.data_7) {
        setWarningMessage(
          "จำเป็นต้องระบุ 1.4 การรับรู้ต่อความเจ็บป่วยในปัจจุบัน"
        );
        return false;
      }

      if (!fields.data_8) {
        setWarningMessage("จำเป็นต้องระบุ 1.5 การได้รับภูมิคุ้มกันโรค");
        return false;
      } else {
        if (fields.data_8 == "2" && !fields.data_9) {
          setWarningMessage("จำเป็นต้องระบุ 1.5 การได้รับภูมิคุ้มกันโรค");
          return false;
        }
      }

      if (!fields.data_10) {
        setWarningMessage("จำเป็นต้องระบุ 1.6 การใช้สารเสพติด");
        return false;
      } else {
        if (fields.data_10 == "1") {
          if (
            fields.data_12 != "1" &&
            fields.data_21 != "1" &&
            fields.data_25 != "1" &&
            fields.data_28 != "1"
          ) {
            setWarningMessage("จำเป็นต้องระบุ 1.6 การใช้สารเสพติด");
            return false;
          }

          if (fields.data_12 == "1" && !fields.data_11) {
            setWarningMessage("จำเป็นต้องระบุ Smoking type");
            return false;
          }

          if (fields.data_12 == "1" && fields.data_11 == "1") {
            if (!fields.data_13) {
              setWarningMessage("จำเป็นต้องระบุ ประเภทของบุหรี่");
              return false;
            }
            if (!fields.data_15) {
              setWarningMessage("จำเป็นต้องระบุ จำนวนของบุหรี่");
              return false;
            }
            if (!fields.data_16) {
              setWarningMessage("จำเป็นต้องระบุ ระยะเวลาที่เลิก");
              return false;
            }
          }

          if (fields.data_12 == "1" && fields.data_11 == "2") {
            if (!fields.data_17) {
              setWarningMessage("จำเป็นต้องระบุ ประเภทของบุหรี่");
              return false;
            }
            if (!fields.data_19) {
              setWarningMessage("จำเป็นต้องระบุ จำนวนของบุหรี่");
              return false;
            }
            if (!fields.data_20) {
              setWarningMessage("จำเป็นต้องระบุ ระยะเวลาที่เลิก");
              return false;
            }
          }

          if (fields.data_21 == "1") {
            if (!fields.data_22) {
              setWarningMessage("จำเป็นต้องระบุ ประเภท ของ Alcohol");
              return false;
            }
            if (!fields.data_23) {
              setWarningMessage("จำเป็นต้องระบุ ปริมาณที่บริโภค Alcohol");
              return false;
            }
            if (!fields.data_24) {
              setWarningMessage("จำเป็นต้องระบุ ความถี่");
              return false;
            }
          }

          if (fields.data_25 == "1") {
            if (!fields.data_26) {
              setWarningMessage(
                "จำเป็นต้องระบุ ประเภท ของ Addictive substance"
              );
              return false;
            }
            if (!fields.data_27) {
              setWarningMessage(
                "จำเป็นต้องระบุ ระยะเวลาที่ใช้ ของ Addictive substance"
              );
              return false;
            }
          }

          if (fields.data_28 == "1") {
            if (!fields.data_29) {
              setWarningMessage("จำเป็นต้องระบุ ประเภท ของ Other");
              return false;
            }
            if (!fields.data_30) {
              setWarningMessage("จำเป็นต้องระบุ ระยะเวลาที่ใช้ ของ Other");
              return false;
            }
          }
        }
      }

      if (!fields.data_31) {
        setWarningMessage(
          "จำเป็นต้องระบุ ประเภท ของ 2.1 แบบแผนการรับประทานอาหาร/การดื่มน้ำ"
        );
        return false;
      }
      if (!fields.data_34) {
        setWarningMessage("จำเป็นต้องระบุ จำนวนมื้อ");
        return false;
      }
      if (!fields.data_35) {
        setWarningMessage("จำเป็นต้องระบุ ปริมาณต่อมื้อ");
        return false;
      }
      if (!fields.data_36) {
        setWarningMessage("จำเป็นต้องระบุ การดื่มน้ำ ปริมาณ");
        return false;
      }
      if (!fields.data_37) {
        setWarningMessage("จำเป็นต้องระบุ แหล่งที่มาของน้ำดื่ม");
        return false;
      }

      if (!fields.data_38) {
        setWarningMessage("จำเป็นต้องระบุ 2.2 ปัญหาการรับประทานอาหาร");
        return false;
      } else {
        if (
          fields.data_38 == "1" &&
          fields.data_39 != "1" &&
          fields.data_40 != "1" &&
          fields.data_41 != "1" &&
          fields.data_42 != "1" &&
          fields.data_43 != "1" &&
          fields.data_44 != "1"
        ) {
          setWarningMessage("จำเป็นต้องระบุ 2.2 ปัญหาการรับประทานอาหาร");
          return false;
        }
      }

      if (!fields.data_46) {
        setWarningMessage(
          "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
        );
        return false;
      } else {
        if (fields.data_46 == "3" && !fields.data_47) {
          setWarningMessage(
            "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
          );
          return false;
        }

        if (fields.data_46 == "3" && fields.data_47 == "1") {
          if (!fields.data_48) {
            setWarningMessage(
              "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
            );
            return false;
          }
          if (!fields.data_49) {
            setWarningMessage("จำเป็นต้องระบุ ในระยะเวลา");
            return false;
          }
        }

        if (fields.data_46 == "3" && fields.data_47 == "2") {
          if (!fields.data_50) {
            setWarningMessage(
              "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
            );
            return false;
          }
          if (!fields.data_51) {
            setWarningMessage("จำเป็นต้องระบุ ในระยะเวลา");
            return false;
          }
        }
      }

      if (!fields.data_52) {
        setWarningMessage(
          "จำเป็นต้องระบุ 2.4 ปัญหาเกี่ยวกับสุขภาพผิวหนัง/เล็บ/ผม"
        );
        return false;
      }
      if (!fields.data_54) {
        setWarningMessage("จำเป็นต้องระบุ BMI");
        return false;
      }
      if (!fields.data_61) {
        setWarningMessage("จำเป็นต้องระบุ จำนวนครั้งที่ปัสสาวะต่อวัน");
        return false;
      }
      if (!fields.data_62) {
        setWarningMessage("จำเป็นต้องระบุ ปริมาณของปัสสาวะต่อครั้ง");
        return false;
      }
      if (!fields.data_63) {
        setWarningMessage("จำเป็นต้องระบุ ลักษณะการปัสสาวะ");
        return false;
      } else {
        if (
          fields.data_63 == "2" &&
          fields.data_64 != "1" &&
          fields.data_65 != "1" &&
          fields.data_66 != "1" &&
          fields.data_67 != "1"
        ) {
          setWarningMessage("จำเป็นต้องระบุ ลักษณะของปัสสาวะ");
          return false;
        }
      }

      if (!fields.data_69) {
        setWarningMessage("จำเป็นต้องระบุ จำนวนวันที่ขับถ่ายต่อสัปดาห์");
        return false;
      }
      if (!fields.data_70) {
        setWarningMessage("จำเป็นต้องระบุ ลักษณะของการขับถ่าย");
        return false;
      } else {
        if (
          fields.data_70 == "2" &&
          fields.data_71 != "1" &&
          fields.data_72 != "1" &&
          fields.data_73 != "1" &&
          fields.data_74 != "1" &&
          fields.data_75 != "1" &&
          fields.data_76 != "1"
        ) {
          setWarningMessage("จำเป็นต้องระบุ ลักษณะของการขับถ่าย");
          return false;
        }
      }
      if (!fields.data_78) {
        setWarningMessage("จำเป็นต้องระบุ 3.3 อาการเหงื่อออกมากผิดปกติ");
        return false;
      }
      if (!fields.data_80) {
        setWarningMessage("จำเป็นต้องระบุ 4.1 การนอนหลับ");
        return false;
      }
      if (!fields.data_81) {
        setWarningMessage("จำเป็นต้องระบุ ปกตินอนวันละ");
        return false;
      }
      if (!fields.data_82) {
        setWarningMessage("จำเป็นต้องระบุ เวลาเข้านอน");
        return false;
      }
      if (!fields.data_83) {
        setWarningMessage("จำเป็นต้องระบุ เวลาตื่น");
        return false;
      }
      if (!fields.data_84) {
        setWarningMessage("จำเป็นต้องระบุ ลักษณะการนอนหลับ");
        return false;
      }
      if (fields.data_84 == "2" && !fields.data_85) {
        setWarningMessage("จำเป็นต้องระบุ ลักษณะการนอนหลับ");
        return false;
      }
      if (!fields.data_86) {
        setWarningMessage("จำเป็นต้องระบุ ปัญหาในการนอนหลับ");
        return false;
      }
      if (!fields.data_88) {
        setWarningMessage(
          "จำเป็นต้องระบุ การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ"
        );
        return false;
      } else {
        if (fields.data_88 == "1") {
          if (!fields.data_89) {
            setWarningMessage(
              "จำเป็นต้องระบุ การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ"
            );
            return false;
          }
          if (!fields.data_90) {
            setWarningMessage("จำเป็นต้องระบุ ความถี่ในการใช้");
            return false;
          }
        }
      }
      if (!fields.data_91) {
        setWarningMessage("จำเป็นต้องระบุ 5.1 การทำกิจวัตรประจำวัน");
        return false;
      }
      if (!fields.data_92) {
        setWarningMessage("จำเป็นต้องระบุ 5.2 แบบแผนการออกกำลังกาย");
        return false;
      }
      if (!fields.data_93) {
        setWarningMessage("จำเป็นต้องระบุ วิธีการออกกำลังกาย");
        return false;
      }
      if (!fields.data_94) {
        setWarningMessage("จำเป็นต้องระบุ ระยะเวลาออกกำลังกาย");
        return false;
      }
      if (!fields.data_95) {
        setWarningMessage("จำเป็นต้องระบุ ความถี่");
        return false;
      }
      if (!fields.data_96) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 1. การเข้าห้องน้ำ"
        );
        return false;
      }
      if (!fields.data_97) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 2. การอาบน้ำ"
        );
        return false;
      }
      if (!fields.data_98) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 3. การรับประทานอาหาร"
        );
        return false;
      }
      if (!fields.data_99) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 4. การเคลื่อนไหวทั่วไป"
        );
        return false;
      }
      if (!fields.data_100) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 5. การแต่งตัว"
        );
        return false;
      }
      if (!fields.data_101) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 6. การหวีผม การทำความสะอาดปากและฟัน"
        );
        return false;
      }
      if (!fields.data_102) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 7. การซื้อของ/ทำธุระนอกบ้าน"
        );
        return false;
      }
      if (!fields.data_103) {
        setWarningMessage(
          "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 8. การเคลื่อนไหวบนเตียงกรณีผู้ป่วยติดเตียง"
        );
        return false;
      }
      if (!fields.data_104) {
        setWarningMessage("จำเป็นต้องระบุ 5.4 ปัญหาในการเคลื่อนไหว");
        return false;
      }
      if (!fields.data_106) {
        setWarningMessage("จำเป็นต้องระบุ ระดับความรู้สึกตัว (Consciousness)");
        return false;
      }
      if (!fields.data_107) {
        setWarningMessage("จำเป็นต้องระบุ ความจำ (Memory)");
        return false;
      }
      if (!fields.data_107) {
        setWarningMessage("จำเป็นต้องระบุ ความจำ (Memory)");
        return false;
      }
      if (!fields.data_109) {
        setWarningMessage("จำเป็นต้องระบุ การรับรู้เวลา");
        return false;
      }
      if (!fields.data_111) {
        setWarningMessage("จำเป็นต้องระบุ การรับรู้สถานที่");
        return false;
      }
      if (!fields.data_113) {
        setWarningMessage("จำเป็นต้องระบุ การรับรู้บุคคล");
        return false;
      }
      if (!fields.data_115) {
        setWarningMessage("จำเป็นต้องระบุ การได้ยิน");
        return false;
      }
      if (!fields.data_117) {
        setWarningMessage("จำเป็นต้องระบุ การมองเห็น");
        return false;
      }
      if (!fields.data_119) {
        setWarningMessage("จำเป็นต้องระบุ การรับกลิ่น");
        return false;
      }
      if (!fields.data_121) {
        setWarningMessage("จำเป็นต้องระบุ การรับรส");
        return false;
      }
      if (!fields.data_123) {
        setWarningMessage("จำเป็นต้องระบุ การสัมผัส");
        return false;
      }
      if (!fields.data_125) {
        setWarningMessage(
          "จำเป็นต้องระบุ สิ่งที่มีผลกระทบต่อตนเองและอัตมโนทัศน์"
        );
        return false;
      }
      if (!fields.data_127) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลกระทบต่อโครงสร้าง บทบาท และสัมพันธภาพของครอบครัว"
        );
        return false;
      }
      if (!fields.data_129) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลกระทบต่อสถานภาพ บทบาท และสัมพันธภาพในสังคม"
        );
        return false;
      }
      if (!fields.data_131) {
        setWarningMessage(
          "จำเป็นต้องระบุ บุคคลและแหล่งที่ให้ความช่วยเหลือผู้ป่วย"
        );
        return false;
      }
      if (!fields.data_133) {
        setWarningMessage(
          "จำเป็นต้องระบุ สิ่งที่เป็นอุปสรรคต่อการสร้างสัมพันธภาพ"
        );
        return false;
      }
      if (!fields.data_135) {
        setWarningMessage("จำเป็นต้องระบุ การคุมกำเนิด");
        return false;
      }
      if (!fields.data_137) {
        setWarningMessage("จำเป็นต้องระบุ ปัญหาเกี่ยวกับอวัยวะสืบพันธุ์");
        return false;
      }
      if (!fields.data_139) {
        setWarningMessage(
          "จำเป็นต้องระบุ พฤติกรรมที่แสดงออกสัมพันธ์กับเพศสภาพ"
        );
        return false;
      }
      if (!fields.data_141) {
        setWarningMessage("จำเป็นต้องระบุ เป็นผู้ป่วยเพศหญิง");
        return false;
      } else {
        if (fields.data_141 == "1") {
          if (!fields.data_142) {
            setWarningMessage("จำเป็นต้องระบุ ประจำเดือนมาครั้งสุดท้าย วันที่");
            return false;
          }
          if (!fields.data_143) {
            setWarningMessage("จำเป็นต้องระบุ Duration/cycle");
            return false;
          }
          if (!fields.data_144) {
            setWarningMessage("จำเป็นต้องระบุ Length of cycle ");
            return false;
          }
        }
      }
      if (!fields.data_146) {
        setWarningMessage(
          "จำเป็นต้องระบุ การเจ็บป่วยครั้งนี้ส่งผลกระทบต่อจิตใจ/เครียด/กังวล"
        );
        return false;
      }
      if (!fields.data_148) {
        setWarningMessage(
          "จำเป็นต้องระบุ ปัจจัยเสริมที่ทำให้เกิดความไม่สบายใจขณะเจ็บป่วย"
        );
        return false;
      }
      if (!fields.data_150) {
        setWarningMessage(
          "จำเป็นต้องระบุ บุคคลหรือแหล่งที่ให้ความช่วยเหลือเวลามีปัญหาหรือไม่สบายใจ"
        );
        return false;
      }
      if (!fields.data_152) {
        setWarningMessage("จำเป็นต้องระบุ การจัดการเมื่อเกิดความเครียด/กังวล");
        return false;
      }
      if (!fields.data_154) {
        setWarningMessage("จำเป็นต้องระบุ นับถือศาสนา");
        return false;
      }
      if (!fields.data_156) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผู้ป่วยเชื่อว่าการเจ็บป่วยในครั้งนี้เกิดจาก"
        );
        return false;
      }
      if (!fields.data_157) {
        setWarningMessage(
          "จำเป็นต้องระบุ สิ่งที่ต้องการ/มีค่ามากที่สุดในชีวิต"
        );
        return false;
      }
      if (!fields.data_159) {
        setWarningMessage("จำเป็นต้องระบุ สิ่งยึดเหนี่ยวในชีวิต");
        return false;
      }
      if (!fields.data_161) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลของการอยู่โรงพยาบาลต่อการปฏิบัติศาสนกิจ"
        );
        return false;
      }
    } else if (type == "7") {
      if (fields.data_1) {
        if (fields.data_1 == "1" && !fields.data_2) {
          setWarningMessage("จำเป็นต้องระบุ New case & arrival by");
          return false;
        }
        if (fields.data_1 == "2" && !fields.data_3) {
          setWarningMessage("จำเป็นต้องระบุ Refer form");
          return false;
        }
      } else {
        setWarningMessage(
          "จำเป็นต้องระบุ Timeline Record From For ACS โรงพยาบาลมหาราชนครเชียงใหม่"
        );
        return false;
      }

      if (!fields.data_4) {
        setWarningMessage("จำเป็นต้องระบุ วันที่-เวลา ของ 1. Onset of symptom");
        return false;
      }

      if (!fields.data_5) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 2. Arrive to ER 1st รพช"
        );
        return false;
      }

      if (!fields.data_7) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 3. First 12 leads ECG at รพช"
        );
        return false;
      }

      if (!fields.data_9) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 4. Depart from ER 1st hospital"
        );
        return false;
      }

      if (!fields.data_11) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 5. Arrive to ER Suandok"
        );
        return false;
      }

      if (!fields.data_13) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 6. First 12 leads ECG at Suandok"
        );
        return false;
      }

      if (!fields.data_15) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 7. ER Physician visit"
        );
        return false;
      }

      if (!fields.data_17) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 8. Final diagnosis at ER"
        );
        return false;
      }

      if (!fields.data_19) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 9. Consult cardiologist"
        );
        return false;
      }

      if (!fields.data_20) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 10. Cardiologist visit or call back"
        );
        return false;
      }

      if (!fields.data_22) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 11. Depart from ER Suandok"
        );
        return false;
      }

      if (!fields.data_23) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 12. Activated for PPCI"
        );
        return false;
      }

      if (!fields.data_24) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 13. Arrive to CCU/ward"
        );
        return false;
      }

      if (!fields.data_27) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 14. Arrive to Cath-Lab"
        );
        return false;
      }

      if (!fields.data_29) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 15. PPCI: Vascular access site"
        );
        return false;
      }

      if (!fields.data_31) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่-เวลา ของ 16. PPCI: Wire crossing"
        );
        return false;
      }

      if (!fields.data_32) {
        setWarningMessage("จำเป็นต้องระบุ Emergency contact (Name)");
        return false;
      }

      if (!fields.data_33) {
        setWarningMessage("จำเป็นต้องระบุ Relationship");
        return false;
      }

      if (!fields.data_34) {
        setWarningMessage("จำเป็นต้องระบุ Phone");
        return false;
      }

      if (!fields.data_35) {
        setWarningMessage("จำเป็นต้องระบุ Education");
        return false;
      }

      if (!fields.data_36) {
        setWarningMessage("จำเป็นต้องระบุ Occupation");
        return false;
      }

      if (!fields.data_37) {
        setWarningMessage("จำเป็นต้องระบุ Vital signs: Temp");
        return false;
      }

      if (!fields.data_38) {
        setWarningMessage("จำเป็นต้องระบุ HR");
        return false;
      }

      if (!fields.data_39) {
        setWarningMessage("จำเป็นต้องระบุ RR");
        return false;
      }

      if (!fields.data_40) {
        setWarningMessage("จำเป็นต้องระบุ BP");
        return false;
      }

      if (!fields.data_41) {
        setWarningMessage("จำเป็นต้องระบุ ส่วนสูง");
        return false;
      }

      if (!fields.data_42) {
        setWarningMessage("จำเป็นต้องระบุ น้ำหนัก");
        return false;
      }

      if (!fields.data_43) {
        setWarningMessage("จำเป็นต้องระบุ Smoking");
        return false;
      }

      if (!fields.data_44) {
        setWarningMessage("จำเป็นต้องระบุ HT");
        return false;
      }

      if (!fields.data_45) {
        setWarningMessage("จำเป็นต้องระบุ DLP");
        return false;
      }

      if (!fields.data_46) {
        setWarningMessage("จำเป็นต้องระบุ DM");
        return false;
      } else {
        if (fields.data_46 == "2" && !fields.data_47) {
          setWarningMessage("จำเป็นต้องระบุ DM");
          return false;
        }
      }

      if (!fields.data_48) {
        setWarningMessage("จำเป็นต้องระบุ Family Hx of CAD");
        return false;
      }

      if (!fields.data_49) {
        setWarningMessage("จำเป็นต้องระบุ Prior MI");
        return false;
      }

      if (!fields.data_50) {
        setWarningMessage("จำเป็นต้องระบุ Known CAD (stenosis > 50%)");
        return false;
      }

      if (!fields.data_51) {
        setWarningMessage("จำเป็นต้องระบุ CVD disease");
        return false;
      }

      if (!fields.data_52) {
        setWarningMessage("จำเป็นต้องระบุ PAD");
        return false;
      }

      if (!fields.data_53) {
        setWarningMessage("จำเป็นต้องระบุ Prior PCI");
        return false;
      }

      if (!fields.data_53) {
        setWarningMessage("จำเป็นต้องระบุ Prior PCI");
        return false;
      } else {
        if (fields.data_53 == "1" && !fields.data_54) {
          setWarningMessage("จำเป็นต้องระบุ Prior PCI last year");
          return false;
        }
      }

      if (!fields.data_55) {
        setWarningMessage("จำเป็นต้องระบุ Prior CABG");
        return false;
      } else {
        if (fields.data_55 == "1" && !fields.data_56) {
          setWarningMessage("จำเป็นต้องระบุ Prior CABG last year");
          return false;
        }
      }

      if (!fields.data_57) {
        setWarningMessage("จำเป็นต้องระบุ Prior HF");
        return false;
      }

      if (!fields.data_58) {
        setWarningMessage("จำเป็นต้องระบุ Prior valve surgery");
        return false;
      }

      if (!fields.data_59) {
        setWarningMessage("จำเป็นต้องระบุ Chronic lung");
        return false;
      }

      if (!fields.data_60) {
        setWarningMessage("จำเป็นต้องระบุ On dialysis");
        return false;
      }

      if (!fields.data_61) {
        setWarningMessage("จำเป็นต้องระบุ 1. HF");
        return false;
      } else {
        if (fields.data_61 == "1" && !fields.data_62) {
          setWarningMessage("จำเป็นต้องระบุ HF class");
          return false;
        }
      }

      if (!fields.data_63) {
        setWarningMessage("จำเป็นต้องระบุ 2. Arrhythmia");
        return false;
      } else {
        if (fields.data_63 == "1" && !fields.data_64) {
          setWarningMessage("จำเป็นต้องระบุ Arrhythmia type");
          return false;
        }
      }

      if (!fields.data_65) {
        setWarningMessage("จำเป็นต้องระบุ 3. CPR");
        return false;
      }

      if (!fields.data_66) {
        setWarningMessage("จำเป็นต้องระบุ 4. Death");
        return false;
      } else {
        if (fields.data_66 == "1" && !fields.data_67) {
          setWarningMessage("จำเป็นต้องระบุ Death type");
          return false;
        }
      }

      if (!fields.data_68) {
        setWarningMessage("จำเป็นต้องระบุ Diagnosis");
        return false;
      } else {
        if (
          fields.data_68 == "1" &&
          fields.data_137 != "1" &&
          fields.data_138 != "1" &&
          fields.data_139 != "1" &&
          fields.data_140 != "1" &&
          fields.data_141 != "1" &&
          fields.data_142 != "1"
        ) {
          setWarningMessage("จำเป็นต้องระบุ Area of involvement");
          return false;
        }

        if (fields.data_68 == "1" && !fields.data_70) {
          setWarningMessage("จำเป็นต้องระบุ STEMI Plan");
          return false;
        }

        if (fields.data_68 == "2" && !fields.data_71) {
          setWarningMessage("จำเป็นต้องระบุ NSTEACS Plan");
          return false;
        }
      }

      if (!fields.data_72) {
        setWarningMessage("จำเป็นต้องระบุ GRACE Score");
        return false;
      }

      if (fields.data_68 == "1" && !fields.data_73) {
        setWarningMessage(
          "จำเป็นต้องระบุ STEMI (ความเสี่ยงต่อ Cardiac arrest)"
        );
        return false;
      }

      if (fields.data_68 == "2" && !fields.data_74) {
        setWarningMessage(
          "จำเป็นต้องระบุ NSTEMI (ความเสี่ยงต่อ Cardiac arrest)"
        );
        return false;
      }

      if (!fields.data_75) {
        setWarningMessage("จำเป็นต้องระบุ Bleeding (ARC-HBR)");
        return false;
      }

      if (
        fields.data_76 != "1" &&
        fields.data_157 != "1" &&
        fields.data_158 != "1" &&
        fields.data_159 != "1" &&
        fields.data_160 != "1" &&
        fields.data_161 != "1"
      ) {
        setWarningMessage("จำเป็นต้องระบุ Heart Failure");
        return false;
      }

      if (
        fields.data_77 != "1" &&
        fields.data_162 != "1" &&
        fields.data_163 != "1" &&
        fields.data_164 != "1"
      ) {
        setWarningMessage("จำเป็นต้องระบุ Cardiac arrhythmia");
        return false;
      }

      if (
        fields.data_78 != "1" &&
        fields.data_165 != "1" &&
        fields.data_166 != "1" &&
        fields.data_167 != "1"
      ) {
        setWarningMessage("จำเป็นต้องระบุ Stroke");
        return false;
      }

      if (!fields.data_79) {
        setWarningMessage("จำเป็นต้องระบุ Diagnosis");
        return false;
      }

      if (!fields.data_80) {
        setWarningMessage("จำเป็นต้องระบุ Type");
        return false;
      }

      if (fields.data_79 == "1" && !fields.data_81) {
        setWarningMessage("จำเป็นต้องระบุ Area of involvement");
        return false;
      }

      if (!fields.data_82) {
        setWarningMessage("จำเป็นต้องระบุ Treatment");
        return false;
      }

      if (!fields.data_83) {
        setWarningMessage("จำเป็นต้องระบุ Underlying disease");
        return false;
      }

      if (!fields.data_84) {
        setWarningMessage("จำเป็นต้องระบุ Coronary angiogram");
        return false;
      }

      if (!fields.data_85) {
        setWarningMessage("จำเป็นต้องระบุ Revascularization");
        return false;
      }

      if (!fields.data_86) {
        setWarningMessage("จำเป็นต้องระบุ LVEF");
        return false;
      }

      if (!fields.data_87) {
        setWarningMessage("จำเป็นต้องระบุ Bleeding BARC type");
        return false;
      } else {
        if (fields.data_87 == "1" && !fields.data_88) {
          setWarningMessage("จำเป็นต้องระบุ Bleeding BARC type");
          return false;
        }
      }

      if (!fields.data_89) {
        setWarningMessage("จำเป็นต้องระบุ Infection");
        return false;
      } else {
        if (fields.data_89 == "1" && !fields.data_90) {
          setWarningMessage("จำเป็นต้องระบุ Infection");
          return false;
        }
      }

      if (!fields.data_91) {
        setWarningMessage("จำเป็นต้องระบุ AKI");
        return false;
      } else {
        if (fields.data_91 == "1" && !fields.data_92) {
          setWarningMessage("จำเป็นต้องระบุ AKI");
          return false;
        }
      }

      if (!fields.data_93) {
        setWarningMessage("จำเป็นต้องระบุ Respiratory failure");
        return false;
      } else {
        if (fields.data_93 == "1" && !fields.data_94) {
          setWarningMessage("จำเป็นต้องระบุ Respiratory failure");
          return false;
        }
      }

      if (!fields.data_95) {
        setWarningMessage("จำเป็นต้องระบุ Mechanical complication");
        return false;
      } else {
        if (fields.data_95 == "1" && !fields.data_96) {
          setWarningMessage("จำเป็นต้องระบุ Mechanical complication");
          return false;
        }
      }

      if (!fields.data_97) {
        setWarningMessage("จำเป็นต้องระบุ Arrhythmia");
        return false;
      } else {
        if (fields.data_97 == "1" && !fields.data_98) {
          setWarningMessage("จำเป็นต้องระบุ Arrhythmia");
          return false;
        }
      }

      if (!fields.data_99) {
        setWarningMessage("จำเป็นต้องระบุ Recurrent ACS");
        return false;
      } else {
        if (fields.data_99 == "1" && !fields.data_100) {
          setWarningMessage("จำเป็นต้องระบุ Recurrent ACS");
          return false;
        }
      }

      if (!fields.data_101) {
        setWarningMessage("จำเป็นต้องระบุ Heart failure");
        return false;
      } else {
        if (fields.data_101 == "1" && !fields.data_102) {
          setWarningMessage("จำเป็นต้องระบุ Heart failure");
          return false;
        }
      }

      if (
        fields.data_103 != "1" &&
        fields.data_151 != "1" &&
        fields.data_152 != "1" &&
        fields.data_153 != "1" &&
        fields.data_154 != "1" &&
        fields.data_155 != "1"
      ) {
        setWarningMessage("จำเป็นต้องระบุ Risk factors");
        return false;
      }

      if (!fields.data_143) {
        setWarningMessage("จำเป็นต้องระบุ LDL");
        return false;
      }

      if (!fields.data_144) {
        setWarningMessage("จำเป็นต้องระบุ HDL");
        return false;
      }

      if (!fields.data_145) {
        setWarningMessage("จำเป็นต้องระบุ TG");
        return false;
      }

      if (!fields.data_146) {
        setWarningMessage("จำเป็นต้องระบุ HbA1c");
        return false;
      }

      if (!fields.data_147) {
        setWarningMessage("จำเป็นต้องระบุ eGFR");
        return false;
      }

      if (!fields.data_148) {
        setWarningMessage("จำเป็นต้องระบุ Hb");
        return false;
      }

      if (!fields.data_149) {
        setWarningMessage("จำเป็นต้องระบุ Hct");
        return false;
      }

      if (!fields.data_105) {
        setWarningMessage("จำเป็นต้องระบุ Plan of Treatment แผนการรักษา");
        return false;
      }

      if (!fields.data_106) {
        setWarningMessage("จำเป็นต้องระบุ BP");
        return false;
      }

      if (!fields.data_107) {
        setWarningMessage("จำเป็นต้องระบุ HR");
        return false;
      }

      if (!fields.data_108) {
        setWarningMessage("จำเป็นต้องระบุ Home environment");
        return false;
      }

      if (!fields.data_109) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน ประเมินความรู้ความเข้าใจของผู้ป่วยและญาติเกี่ยวกับโรคหัวใจและหลอดเลือดและการรักษาที่ได้รับ"
        );
        return false;
      }

      if (fields.data_110 == "1" && !fields.data_169) {
        setWarningMessage(
          "จำเป็นต้องระบุ การปฏิบัติ/คำแนะนำเมื่อ Depression เป็น Absent"
        );
        return false;
      }

      if (fields.data_110 == "2" && !fields.data_111) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินเมื่อ Depression เป็น Present (2Q)"
        );
        return false;
      }

      if (fields.data_112 == "1" && !fields.data_170) {
        setWarningMessage(
          "จำเป็นต้องระบุ การปฏิบัติ/คำแนะนำเมื่อ Anxiety เป็น Absent"
        );
        return false;
      }

      if (fields.data_112 == "2" && !fields.data_113) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินเมื่อ Anxiety เป็น Present (VAS)"
        );
        return false;
      }

      if (!fields.data_114) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องโรคและการรักษาที่ได้รับ"
        );
        return false;
      }

      if (!fields.data_116) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องอาหารที่เหมาะสมกับโรค"
        );
        return false;
      }

      if (!fields.data_118) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องการรับประทานยา"
        );
        return false;
      }

      if (!fields.data_120) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องการออกกำลังกายที่เหมาะสมกับโรค"
        );
        return false;
      }

      if (!fields.data_122) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน การสังเกตอาการผิดปกติที่ควรมาพบแพทย์ก่อนนัด"
        );
        return false;
      }

      if (!fields.data_125) {
        setWarningMessage("จำเป็นต้องระบุ การประเมิน Cardiac rehabilitation");
        return false;
      }

      if (!fields.data_127) {
        setWarningMessage("จำเป็นต้องระบุ การประเมิน Home walking program");
        return false;
      }

      if (!fields.data_128) {
        setWarningMessage("จำเป็นต้องระบุ การประเมิน คู่มือการปฏิบัติตัว");
        return false;
      }

      if (!fields.data_129) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน Occupation/ Return to work /Return to daily activity recommendation"
        );
        return false;
      }
      if (!fields.data_130) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน And need occ Med consultation"
        );
        return false;
      }
      if (!fields.data_130) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมิน And need occ Med consultation"
        );
        return false;
      }
      if (!fields.data_131) {
        setWarningMessage("จำเป็นต้องระบุ วันที่ F/U");
        return false;
      }
      if (!fields.data_132) {
        setWarningMessage("จำเป็นต้องระบุ สถานที่");
        return false;
      }
      if (!fields.data_135) {
        setWarningMessage("จำเป็นต้องระบุ ส่งต่อข้อมูลผ่าน");
        return false;
      }
      if (!fields.data_136) {
        setWarningMessage("จำเป็นต้องระบุ สรุปปัญหาที่ส่งต่อ/ที่หลงเหลือ");
        return false;
      }
    } else if (type == "8") {
      if (!fields.data_1) {
        setWarningMessage("จำเป็นต้องระบุ Diagnosis");
        return false;
      }
      if (!fields.data_2) {
        setWarningMessage("จำเป็นต้องระบุ Underlying diseuse");
        return false;
      }
      if (!fields.data_3) {
        setWarningMessage("จำเป็นต้องระบุ LVEF");
        return false;
      }
      if (!fields.data_4) {
        setWarningMessage("จำเป็นต้องระบุ Last admission");
        return false;
      }
      if (!fields.data_5) {
        setWarningMessage("จำเป็นต้องระบุ ACS Tele follow up date of");
        return false;
      }
      if (!fields.data_101n) {
        setWarningMessage("จำเป็นต้องระบุ Follow-up times");
        return false;
      }
      if (!fields.data_6) {
        setWarningMessage(
          "จำเป็นต้องระบุ 1. Knowlegde of diseuse and treatment plan"
        );
        return false;
      }
      if (!fields.data_9) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 1. Knowlegde of diseuse and treatment plan"
        );
        return false;
      }
      if (!fields.data_10) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 1. Knowlegde of diseuse and treatment plan"
        );
        return false;
      }
      if (!fields.data_11) {
        setWarningMessage("จำเป็นต้องระบุ 2. Re-admission หรือ ER Visit");
        return false;
      }
      if (!fields.data_15) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 2. Re-admission หรือ ER Visit"
        );
        return false;
      }
      if (!fields.data_16) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 2. Re-admission หรือ ER Visit"
        );
        return false;
      }
      if (!fields.data_26) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 3. Abnormal symptoms"
        );
        return false;
      }
      if (!fields.data_27) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 3. Abnormal symptoms");
        return false;
      }
      if (!fields.data_28) {
        setWarningMessage(
          "จำเป็นต้องระบุ 4. Recognize abnormal symptoms and basic self care"
        );
        return false;
      }
      if (!fields.data_31) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 4. Recognize abnormal symptoms and basic self care"
        );
        return false;
      }
      if (!fields.data_32) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 4. Recognize abnormal symptoms and basic self care"
        );
        return false;
      }
      if (!fields.data_33) {
        setWarningMessage("จำเป็นต้องระบุ 5. Medication");
        return false;
      } else {
        if (
          fields.data_33 == "2" &&
          fields.data_34 == "1" &&
          fields.data_35 == "1" &&
          fields.data_36 == "1" &&
          fields.data_37 == "1" &&
          fields.data_38 == "1" &&
          fields.data_39 == "1" &&
          fields.data_40 == "1" &&
          fields.data_41 == "1" &&
          fields.data_42 == "1" &&
          fields.data_43 == "1"
        ) {
          setWarningMessage("จำเป็นต้องระบุ 5. Medication");
          return false;
        }
      }
      if (!fields.data_45) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 5. Medication"
        );
        return false;
      }
      if (!fields.data_46) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 5. Medication");
        return false;
      }
      if (!fields.data_49) {
        setWarningMessage("จำเป็นต้องระบุ Nursing Management ที่ 6. Exercise");
        return false;
      }
      if (!fields.data_50) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 6. Exercise");
        return false;
      }
      if (!fields.data_53) {
        setWarningMessage("จำเป็นต้องระบุ Nursing Management ที่ 7. Activity");
        return false;
      }
      if (!fields.data_54) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 7. Activity");
        return false;
      }
      if (!fields.data_55) {
        setWarningMessage("จำเป็นต้องระบุ 8. Return to work");
        return false;
      }
      if (!fields.data_57) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 8. Return to work"
        );
        return false;
      }
      if (!fields.data_58) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 8. Return to work");
        return false;
      }
      if (!fields.data_59) {
        setWarningMessage("จำเป็นต้องระบุ 9. Psychosocial problem");
        return false;
      } else {
        if (
          fields.data_59 == "1" &&
          fields.data_95 != "1" &&
          fields.data_96 != "1" &&
          fields.data_97 != "1" &&
          fields.data_98 != "1"
        ) {
          setWarningMessage("จำเป็นต้องระบุ 9. Psychosocial problem");
          return false;
        }
      }
      if (!fields.data_61) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 9. Psychosocial problem"
        );
        return false;
      }
      if (!fields.data_62) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 9. Psychosocial problem");
        return false;
      }
      if (!fields.data_64) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 10. smoking cessation");
        return false;
      } else {
        if (fields.data_64 == "2" && !fields.data_99) {
          setWarningMessage("จำเป็นต้องระบุ Comment ที่ 10. smoking cessation");
          return false;
        }
      }
      if (!fields.data_66) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 10. smoking cessation"
        );
        return false;
      }
      if (!fields.data_67) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 10. smoking cessation");
        return false;
      }
      if (!fields.data_71) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 11. Risk factor control and secondary prevention"
        );
        return false;
      }
      if (!fields.data_72) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 11. Risk factor control and secondary prevention"
        );
        return false;
      }
      if (!fields.data_74) {
        setWarningMessage("จำเป็นต้องระบุ Nursing Management ที่ 12. Diet");
        return false;
      }
      if (!fields.data_75) {
        setWarningMessage("จำเป็นต้องระบุ Comment ที่ 12. Diet");
        return false;
      }
      if (!fields.data_76) {
        setWarningMessage(
          "จำเป็นต้องระบุ 13. An appointment for next follow up"
        );
        return false;
      } else {
        if (fields.data_76 == "1" && !fields.data_77) {
          setWarningMessage("จำเป็นต้องระบุ Date");
          return false;
        }
        if (fields.data_76 == "1" && !fields.data_78) {
          setWarningMessage("จำเป็นต้องระบุ Hospital");
          return false;
        }
      }
      if (!fields.data_80) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 13. An appointment for next follow up"
        );
        return false;
      }
      if (!fields.data_81) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 13. An appointment for next follow up"
        );
        return false;
      }
      if (!fields.data_82) {
        setWarningMessage(
          "จำเป็นต้องระบุ 14. มีคำถามเพิ่มเติมหรือสิ่งสงสัยใดๆหรือไม่"
        );
        return false;
      }
      if (!fields.data_85) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 14. มีคำถามเพิ่มเติมหรือสิ่งสงสัยใดๆหรือไม่"
        );
        return false;
      }
      if (!fields.data_86) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 14. มีคำถามเพิ่มเติมหรือสิ่งสงสัยใดๆหรือไม่"
        );
        return false;
      }
      if (!fields.data_87) {
        setWarningMessage(
          "จำเป็นต้องระบุ 15. รับทราบให้ฉีด Influenza vaccine ทุกปี"
        );
        return false;
      }
      if (!fields.data_90) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 15. รับทราบให้ฉีด Influenza vaccine ทุกปี"
        );
        return false;
      }
      if (!fields.data_91) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 15. รับทราบให้ฉีด Influenza vaccine ทุกปี"
        );
        return false;
      }
      if (!fields.data_92) {
        setWarningMessage(
          "จำเป็นต้องระบุ 16. ความพึงพอใจในการโทรศัพท์ติดตามอาการ และให้คำแนะนำ"
        );
        return false;
      }
      if (!fields.data_93) {
        setWarningMessage(
          "จำเป็นต้องระบุ Nursing Management ที่ 16. ความพึงพอใจในการโทรศัพท์ติดตามอาการ และให้คำแนะนำ"
        );
        return false;
      }
      if (!fields.data_94) {
        setWarningMessage(
          "จำเป็นต้องระบุ Comment ที่ 16. ความพึงพอใจในการโทรศัพท์ติดตามอาการ และให้คำแนะนำ"
        );
        return false;
      }
    } else if (type == "9") {
      if (
        fields.data_1 != "1" &&
        fields.data_2 != "1" &&
        fields.data_3 != "1" &&
        fields.data_4 != "1" &&
        fields.data_5 != "1" &&
        fields.data_6 != "1"
      ) {
        setWarningMessage("จำเป็นต้องระบุ Check List");
        return false;
      }
      if (!fields.data_7) {
        setWarningMessage("จำเป็นต้องระบุ Activities before ACS");
        return false;
      }
      if (!fields.data_8) {
        setWarningMessage("จำเป็นต้องระบุ METs");
        return false;
      }
      if (!fields.data_9) {
        setWarningMessage("จำเป็นต้องระบุ Contraindication for exercise");
        return false;
      }
      if (!fields.data_10) {
        setWarningMessage(
          "จำเป็นต้องระบุ Risk stratification (criteria อยู่ หน้าถัดไป)"
        );
        return false;
      }
      if (!fields.data_11) {
        setWarningMessage("จำเป็นต้องระบุ Target METs");
        return false;
      }
      if (!fields.data_12) {
        setWarningMessage("จำเป็นต้องระบุ วันที่ใน ขั้นที่1 < 2METs ");
        return false;
      }
      if (!fields.data_18) {
        setWarningMessage("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_19) {
        setWarningMessage("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_20) {
        setWarningMessage("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_21) {
        setWarningMessage("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_22) {
        setWarningMessage("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_23) {
        setWarningMessage("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_24) {
        setWarningMessage("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_25) {
        setWarningMessage("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_26) {
        setWarningMessage("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่1 < 2METs");
        return false;
      }
      if (!fields.data_27) {
        setWarningMessage("จำเป็นต้องระบุ วันที่ใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_32) {
        setWarningMessage("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_33) {
        setWarningMessage("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_34) {
        setWarningMessage("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_35) {
        setWarningMessage("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_36) {
        setWarningMessage("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_37) {
        setWarningMessage("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_38) {
        setWarningMessage("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_39) {
        setWarningMessage("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_40) {
        setWarningMessage("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่2 < 3METs");
        return false;
      }
      if (!fields.data_41) {
        setWarningMessage("จำเป็นต้องระบุ วันที่ใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_46) {
        setWarningMessage("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_47) {
        setWarningMessage("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_48) {
        setWarningMessage("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_49) {
        setWarningMessage("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_50) {
        setWarningMessage("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_51) {
        setWarningMessage("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_52) {
        setWarningMessage("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_53) {
        setWarningMessage("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_54) {
        setWarningMessage("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่3 < 4METs");
        return false;
      }
      if (!fields.data_55) {
        setWarningMessage("จำเป็นต้องระบุ วันที่ใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_59) {
        setWarningMessage("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_60) {
        setWarningMessage("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_61) {
        setWarningMessage("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_62) {
        setWarningMessage("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_63) {
        setWarningMessage("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_64) {
        setWarningMessage("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_65) {
        setWarningMessage("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_66) {
        setWarningMessage("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_67) {
        setWarningMessage("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่4 < 5METs");
        return false;
      }
      if (!fields.data_68) {
        setWarningMessage("จำเป็นต้องระบุ วันที่ใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_75) {
        setWarningMessage("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_76) {
        setWarningMessage("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_77) {
        setWarningMessage("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_78) {
        setWarningMessage("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_79) {
        setWarningMessage("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_80) {
        setWarningMessage("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_81) {
        setWarningMessage("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_82) {
        setWarningMessage("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_83) {
        setWarningMessage("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่5 < 5METs");
        return false;
      }
      if (!fields.data_84) {
        setWarningMessage(
          "จำเป็นต้องระบุ วันที่ Contraindications for exercise"
        );
        return false;
      }
    } else if (type == "10") {
      if (!fields.data_3) {
        setWarningMessage("จำเป็นต้องระบุ วันที่จำหน่าย");
        return false;
      }
      if (!fields.data_4) {
        setWarningMessage("จำเป็นต้องระบุ 24 - 48 ชั่วโมงหลังจำหน่าย");
        return false;
      }
      if (fields.data_15 == "1") {
        if (
          fields.data_41 != "1" &&
          fields.data_42 != "1" &&
          fields.data_43 != "1" &&
          fields.data_44 != "1"
        ) {
          setWarningMessage("จำเป็นต้องระบุ ปัจจัยเสี่ยงอย่างน้อย 1 ตัว");
          return false;
        }
      }
      if (!fields.data_1) {
        setWarningMessage("จำเป็นต้องระบุ เบอร์โทรผู้ป่วย");
        return false;
      }
      if (!fields.data_2) {
        setWarningMessage("จำเป็นต้องระบุ เบอร์โทรญาติ");
        return false;
      }
      if (!fields.data_8) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลการให้คำแนะนำการดูแลแผลไม่ให้โดนน้ำ 3 วัน"
        );
        return false;
      }
      if (!fields.data_9) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลการให้คำแนะนำหากมีเลือดออกให้กดเหนือรอยเข็มอย่างน้อย 10 นาที ถ้าไม่หยุด ให้รีบไปพบแพทย์"
        );
        return false;
      }
      if (!fields.data_10) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลการให้คำแนะนำการสังเกตอาการผิดปกติ เช่น เลือดออก แผลบวม ปวดแผลมาก ให้รีบไปพบแพทย์"
        );
        return false;
      }
      if (!fields.data_14) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลการให้คำแนะนำการหลีกเลี่ยงการออกกำลังหรือทำกิจกรรมที่ใช้กำลังมาก"
        );
        return false;
      }
      if (!fields.data_18) {
        setWarningMessage(
          "จำเป็นต้องระบุ ผลการให้คำแนะนำการหลีกเลี่ยงปัจจัยเสี่ยง เช่น อาหารที่มีรสเค็มจัด การสูบบุหรี่ การดิ่มเครื่องดื่มแอลกอฮอล์"
        );
        return false;
      }
      if (!fields.data_22) {
        setWarningMessage("จำเป็นต้องระบุ ผลการให้คำแนะนำผลการรักษา");
        return false;
      }
      if (!fields.data_23) {
        setWarningMessage("จำเป็นต้องระบุ ผลการให้คำแนะนำแผนการรักษา");
        return false;
      }
      if (!fields.data_40) {
        setWarningMessage("จำเป็นต้องระบุ การรักษาต่อเนื่อง");
        return false;
      } else {
        if (fields.data_40 == "2" && !fields.data_27) {
          setWarningMessage("จำเป็นต้องระบุ การรักษาต่อเนื่อง");
          return false;
        }
      }
      if (!fields.data_29) {
        setWarningMessage("จำเป็นต้องระบุ Medication");
        return false;
      }
      if (!fields.data_49) {
        setWarningMessage("จำเป็นต้องระบุ เอกสารคำแนะนำการดูแลตนเอง");
        return false;
      }
      if (!fields.data_30) {
        setWarningMessage(
          "จำเป็นต้องระบุ การนำเข้าสู่ line application consult for Same-day patient"
        );
        return false;
      }
      if (!fields.data_32) {
        setWarningMessage("จำเป็นต้องระบุ พยาบาลผู้ตรวจสอบข้อมูล");
        return false;
      }
      if (!fields.data_33) {
        setWarningMessage("จำเป็นต้องระบุ ภาวะแทรกซ้อน");
        return false;
      }
      if (!fields.data_35) {
        setWarningMessage("จำเป็นต้องระบุ ระดับความวิตกกังวล (0-10 คะแนน)");
        return false;
      }
      if (!fields.data_36) {
        setWarningMessage("จำเป็นต้องระบุ ระดับความเจ็บปวดแผล (0-10 คะแนน)");
        return false;
      }
      if (!fields.data_37) {
        setWarningMessage("จำเป็นต้องระบุ ระดับความพึงพอใจ (0-10 คะแนน)");
        return false;
      }
      if (!fields.data_38) {
        setWarningMessage("จำเป็นต้องระบุ ลงชื่อพยาบาล");
        return false;
      }
    } else if (type == "11") {
      if (!fields.data_4) {
        setWarningMessage("จำเป็นต้องระบุ ward");
        return false;
      }
      if (!fields.data_5) {
        setWarningMessage("จำเป็นต้องระบุ Date");
        return false;
      }
      if (!fields.data_6) {
        setWarningMessage("จำเป็นต้องระบุ Cath no");
        return false;
      }
      if (!fields.data_7) {
        setWarningMessage("จำเป็นต้องระบุ Procedure perform");
        return false;
      }
      if (!fields.data_8) {
        setWarningMessage("จำเป็นต้องระบุ Diagnosis");
        return false;
      }
      if (!fields.data_9) {
        setWarningMessage("จำเป็นต้องระบุ น้ำหนัก");
        return false;
      }
      if (!fields.data_10) {
        setWarningMessage("จำเป็นต้องระบุ สูง");
        return false;
      }
      if (!fields.data_11) {
        setWarningMessage("จำเป็นต้องระบุ Consent");
        return false;
      }
      if (fields.data_13 == "0") {
        setWarningMessage("จำเป็นต้องระบุ สิทธิบัตร");
        return false;
      }
      if (!fields.data_15) {
        setWarningMessage("จำเป็นต้องระบุ Body Temp");
        return false;
      }
      if (!fields.data_16) {
        setWarningMessage("จำเป็นต้องระบุ Hb");
        return false;
      }
      if (!fields.data_17) {
        setWarningMessage("จำเป็นต้องระบุ BUN");
        return false;
      }
      if (!fields.data_18) {
        setWarningMessage("จำเป็นต้องระบุ PT");
        return false;
      }
      if (!fields.data_19) {
        setWarningMessage("จำเป็นต้องระบุ PTT");
        return false;
      }
      if (!fields.data_20) {
        setWarningMessage("จำเป็นต้องระบุ ASA");
        return false;
      }
      if (!fields.data_21) {
        setWarningMessage("จำเป็นต้องระบุ Hct");
        return false;
      }
      if (!fields.data_22) {
        setWarningMessage("จำเป็นต้องระบุ Cr");
        return false;
      }
      if (!fields.data_23) {
        setWarningMessage("จำเป็นต้องระบุ INR");
        return false;
      }
      if (!fields.data_24) {
        setWarningMessage("จำเป็นต้องระบุ Clopidogrel");
        return false;
      }
      if (!fields.data_25) {
        setWarningMessage("จำเป็นต้องระบุ WBC");
        return false;
      }
      if (!fields.data_26) {
        setWarningMessage("จำเป็นต้องระบุ eGFR");
        return false;
      }
      if (!fields.data_27) {
        setWarningMessage("จำเป็นต้องระบุ HIV");
        return false;
      }
      if (!fields.data_115) {
        setWarningMessage("จำเป็นต้องระบุ Ticagrelor");
        return false;
      }
      if (!fields.data_30) {
        setWarningMessage("จำเป็นต้องระบุ Plt");
        return false;
      }
      if (!fields.data_31) {
        setWarningMessage("จำเป็นต้องระบุ K");
        return false;
      }
      if (!fields.data_108) {
        setWarningMessage("จำเป็นต้องระบุ ATK");
        return false;
      }
      if (!fields.data_116) {
        setWarningMessage("จำเป็นต้องระบุ Prasugrel");
        return false;
      }
      if (!fields.data_35?.length) {
        setWarningMessage("จำเป็นต้องระบุ Other Lab");
        return false;
      }
      if (!fields.data_117) {
        setWarningMessage("จำเป็นต้องระบุ Enoxaparin");
        return false;
      }
      if (!fields.data_118) {
        setWarningMessage("จำเป็นต้องระบุ Wafarin");
        return false;
      }
      if (!fields.data_119) {
        setWarningMessage("จำเป็นต้องระบุ NOAC");
        return false;
      }
      if (!fields.data_33 || !fields.data_34) {
        setWarningMessage("จำเป็นต้องระบุ Last dose at");
        return false;
      }
      if (!fields.data_36 || !fields.data_37) {
        setWarningMessage("จำเป็นต้องระบุ No of dose");
        return false;
      }
      if (!fields.data_38) {
        setWarningMessage("จำเป็นต้องระบุ NPO");
        return false;
      }
      if (!fields.data_42) {
        setWarningMessage("จำเป็นต้องระบุ PVD");
        return false;
      }
      if (!fields.data_43) {
        setWarningMessage("จำเป็นต้องระบุ Allergic history");
        return false;
      }
      if (!fields.data_45) {
        setWarningMessage("จำเป็นต้องระบุ HT");
        return false;
      }
      if (!fields.data_50) {
        setWarningMessage("จำเป็นต้องระบุ DM");
        return false;
      }
      if (!fields.data_51) {
        setWarningMessage("จำเป็นต้องระบุ Bleeding risk score (BRC)");
        return false;
      }
      if (!fields.data_52) {
        setWarningMessage("จำเป็นต้องระบุ DLP");
        return false;
      }
      if (!fields.data_53) {
        setWarningMessage("จำเป็นต้องระบุ GRACE score");
        return false;
      }
      if (!fields.data_54) {
        setWarningMessage("จำเป็นต้องระบุ Family history");
        return false;
      }
      if (!fields.data_55) {
        setWarningMessage("จำเป็นต้องระบุ One day");
        return false;
      }
      if (!fields.data_57) {
        setWarningMessage("จำเป็นต้องระบุ Smoking");
        return false;
      }
      if (!fields.data_59) {
        setWarningMessage("จำเป็นต้องระบุ Telephone number");
        return false;
      }
      if (!fields.data_60) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินผลของปัญหาวิตกกังวลเกียวกับการรักษา"
        );
        return false;
      }
      if (!fields.data_61) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินผลของปัญหาพร่องความรู้เกี่ยวกับการตรวจรักษา"
        );
        return false;
      }
      if (!fields.data_62) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อภาวะ vasovagal reflex"
        );
        return false;
      }
      if (!fields.data_63) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อภาวะเลือดอุดตันจากก้อนเลือดหรือฟองอากาศ"
        );
        return false;
      }
      if (!fields.data_64) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อภาวะ vascular complications"
        );
        return false;
      }
      if (!fields.data_65) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่ออันตรายจากการได้รับสารทึบรังสีเช่นแพ้สารทึบรังสี"
        );
        return false;
      }
      if (!fields.data_66) {
        setWarningMessage(
          "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อการติดเชื้อบริเวณที่ใส่สายสวนหรือในกระแสโลหิต"
        );
        return false;
      }
      if (!fields.data_67) {
        setWarningMessage("จำเป็นต้องระบุ Anxiety of Pre procedure");
        return false;
      }
      if (!fields.data_68) {
        setWarningMessage("จำเป็นต้องระบุ Pain of Pre procedure");
        return false;
      }
      if (!fields.data_70) {
        setWarningMessage("จำเป็นต้องระบุ Anxiety of During procedure");
        return false;
      }
      if (!fields.data_71) {
        setWarningMessage("จำเป็นต้องระบุ Pain of During procedure");
        return false;
      }
      if (!fields.data_73) {
        setWarningMessage("จำเป็นต้องระบุ Anxiety of Post procedure");
        return false;
      }
      if (!fields.data_74) {
        setWarningMessage("จำเป็นต้องระบุ Pain of Post procedure");
        return false;
      }
      if (!fields.data_76) {
        setWarningMessage("จำเป็นต้องระบุ ชื่อ-นามสกุล");
        return false;
      }
      if (!fields.data_77) {
        setWarningMessage("จำเป็นต้องระบุ HN");
        return false;
      }
      if (!fields.data_78) {
        setWarningMessage("จำเป็นต้องระบุ Date");
        return false;
      }

      if (fields?.data_79?.data_1 == "1") {
        if (!fields?.data_79?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
          );
          return false;
        }
        if (!fields?.data_79?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
          );
          return false;
        }
        if (!fields?.data_79?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
          );
          return false;
        }
        if (!fields?.data_79?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
          );
          return false;
        }
        if (!fields?.data_79?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
          );
          return false;
        }
        if (!fields?.data_79?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
          );
          return false;
        }
      }

      if (fields?.data_80?.data_1 == "1") {
        if (!fields?.data_80?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_80?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_80?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_80?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_80?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_80?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
          );
          return false;
        }
      }

      if (fields?.data_81?.data_1 == "1") {
        if (!fields?.data_81?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
          );
          return false;
        }
        if (!fields?.data_81?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
          );
          return false;
        }
        if (!fields?.data_81?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
          );
          return false;
        }
        if (!fields?.data_81?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
          );
          return false;
        }
        if (!fields?.data_81?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
          );
          return false;
        }
        if (!fields?.data_81?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
          );
          return false;
        }
      }

      if (fields?.data_83?.data_1 == "1") {
        if (!fields?.data_83?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
          );
          return false;
        }
        if (!fields?.data_83?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
          );
          return false;
        }
        if (!fields?.data_83?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
          );
          return false;
        }
        if (!fields?.data_83?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
          );
          return false;
        }
        if (!fields?.data_83?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
          );
          return false;
        }
        if (!fields?.data_83?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
          );
          return false;
        }
      }

      if (fields?.data_84?.data_1 == "1") {
        if (!fields?.data_84?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
          );
          return false;
        }
        if (!fields?.data_84?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
          );
          return false;
        }
        if (!fields?.data_84?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
          );
          return false;
        }
        if (!fields?.data_84?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
          );
          return false;
        }
        if (!fields?.data_84?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
          );
          return false;
        }
        if (!fields?.data_84?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
          );
          return false;
        }
      }

      if (fields?.data_85?.data_1 == "1") {
        if (!fields?.data_85?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
          );
          return false;
        }
        if (!fields?.data_85?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
          );
          return false;
        }
        if (!fields?.data_85?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
          );
          return false;
        }
        if (!fields?.data_85?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
          );
          return false;
        }
        if (!fields?.data_85?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
          );
          return false;
        }
        if (!fields?.data_85?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
          );
          return false;
        }
      }

      if (fields?.data_86?.data_1 == "1") {
        if (!fields?.data_86?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
          );
          return false;
        }
        if (!fields?.data_86?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
          );
          return false;
        }
        if (!fields?.data_86?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
          );
          return false;
        }
        if (!fields?.data_86?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
          );
          return false;
        }
        if (!fields?.data_86?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
          );
          return false;
        }
        if (!fields?.data_86?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
          );
          return false;
        }
      }

      if (fields?.data_87?.data_1 == "1") {
        if (!fields?.data_87?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
          );
          return false;
        }
        if (!fields?.data_87?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
          );
          return false;
        }
        if (!fields?.data_87?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
          );
          return false;
        }
        if (!fields?.data_87?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
          );
          return false;
        }
        if (!fields?.data_87?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
          );
          return false;
        }
        if (!fields?.data_87?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
          );
          return false;
        }
      }

      if (fields?.data_88?.data_1 == "1") {
        if (!fields?.data_88?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
          );
          return false;
        }
        if (!fields?.data_88?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
          );
          return false;
        }
        if (!fields?.data_88?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
          );
          return false;
        }
        if (!fields?.data_88?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
          );
          return false;
        }
        if (!fields?.data_88?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
          );
          return false;
        }
        if (!fields?.data_88?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
          );
          return false;
        }
      }

      if (fields?.data_89?.data_1 == "1") {
        if (!fields?.data_89?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_89?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_89?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_89?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_89?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
          );
          return false;
        }
        if (!fields?.data_89?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
          );
          return false;
        }
      }

      if (fields?.data_90?.data_1 == "1") {
        if (!fields?.data_90?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
          );
          return false;
        }
        if (!fields?.data_90?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
          );
          return false;
        }
        if (!fields?.data_90?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
          );
          return false;
        }
        if (!fields?.data_90?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
          );
          return false;
        }
        if (!fields?.data_90?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
          );
          return false;
        }
        if (!fields?.data_90?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
          );
          return false;
        }
      }

      if (fields?.data_91?.data_1 == "1") {
        if (!fields?.data_91?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
          );
          return false;
        }
        if (!fields?.data_91?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
          );
          return false;
        }
        if (!fields?.data_91?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
          );
          return false;
        }
        if (!fields?.data_91?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
          );
          return false;
        }
        if (!fields?.data_91?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
          );
          return false;
        }
        if (!fields?.data_91?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
          );
          return false;
        }
      }

      if (fields?.data_92?.data_1 == "1") {
        if (!fields?.data_92?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
          );
          return false;
        }
        if (!fields?.data_92?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
          );
          return false;
        }
        if (!fields?.data_92?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
          );
          return false;
        }
        if (!fields?.data_92?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
          );
          return false;
        }
        if (!fields?.data_92?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
          );
          return false;
        }
        if (!fields?.data_92?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
          );
          return false;
        }
      }

      if (fields?.data_93?.data_1 == "1") {
        if (!fields?.data_93?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ I = hand hygiene / keep sterile"
          );
          return false;
        }
        if (!fields?.data_93?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ I = hand hygiene / keep sterile"
          );
          return false;
        }
        if (!fields?.data_93?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ I = hand hygiene / keep sterile"
          );
          return false;
        }
        if (!fields?.data_93?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ I = hand hygiene / keep sterile"
          );
          return false;
        }
        if (!fields?.data_93?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ I = hand hygiene / keep sterile"
          );
          return false;
        }
        if (!fields?.data_93?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ I = hand hygiene / keep sterile"
          );
          return false;
        }
      }

      if (fields?.data_94?.data_1 == "1") {
        if (!fields?.data_94?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
          );
          return false;
        }
        if (!fields?.data_94?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
          );
          return false;
        }
        if (!fields?.data_94?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
          );
          return false;
        }
        if (!fields?.data_94?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
          );
          return false;
        }
        if (!fields?.data_94?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
          );
          return false;
        }
        if (!fields?.data_94?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
          );
          return false;
        }
      }

      if (fields?.data_95?.data_1 == "1") {
        if (!fields?.data_95?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ I = ตรวจสอบ IV type, rate, IV site"
          );
          return false;
        }
        if (!fields?.data_95?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ I = ตรวจสอบ IV type, rate, IV site"
          );
          return false;
        }
        if (!fields?.data_95?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ I = ตรวจสอบ IV type, rate, IV site"
          );
          return false;
        }
        if (!fields?.data_95?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ I = ตรวจสอบ IV type, rate, IV site"
          );
          return false;
        }
        if (!fields?.data_95?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ I = ตรวจสอบ IV type, rate, IV site"
          );
          return false;
        }
        if (!fields?.data_95?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ I = ตรวจสอบ IV type, rate, IV site"
          );
          return false;
        }
      }

      if (fields?.data_96?.data_1 == "1") {
        if (!fields?.data_96?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
          );
          return false;
        }
        if (!fields?.data_96?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
          );
          return false;
        }
        if (!fields?.data_96?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
          );
          return false;
        }
        if (!fields?.data_96?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
          );
          return false;
        }
        if (!fields?.data_96?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
          );
          return false;
        }
        if (!fields?.data_96?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
          );
          return false;
        }
      }

      if (fields?.data_97?.data_1 == "1") {
        if (!fields?.data_97?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
          );
          return false;
        }
        if (!fields?.data_97?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
          );
          return false;
        }
        if (!fields?.data_97?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
          );
          return false;
        }
        if (!fields?.data_97?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
          );
          return false;
        }
        if (!fields?.data_97?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
          );
          return false;
        }
        if (!fields?.data_97?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
          );
          return false;
        }
      }

      if (fields?.data_98?.data_1 == "1") {
        if (!fields?.data_98?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
          );
          return false;
        }
        if (!fields?.data_98?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
          );
          return false;
        }
        if (!fields?.data_98?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
          );
          return false;
        }
        if (!fields?.data_98?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
          );
          return false;
        }
        if (!fields?.data_98?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
          );
          return false;
        }
        if (!fields?.data_98?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
          );
          return false;
        }
      }

      if (fields?.data_99?.data_1 == "1") {
        if (!fields?.data_99?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
          );
          return false;
        }
        if (!fields?.data_99?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
          );
          return false;
        }
        if (!fields?.data_99?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
          );
          return false;
        }
        if (!fields?.data_99?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
          );
          return false;
        }
        if (!fields?.data_99?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
          );
          return false;
        }
        if (!fields?.data_99?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
          );
          return false;
        }
      }

      if (fields?.data_100?.data_1 == "1") {
        if (!fields?.data_100?.data_2) {
          setWarningMessage(
            "จำเป็นต้องระบุ Time ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
          );
          return false;
        }
        if (!fields?.data_100?.data_3) {
          setWarningMessage(
            "จำเป็นต้องระบุ PR ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
          );
          return false;
        }
        if (!fields?.data_100?.data_4) {
          setWarningMessage(
            "จำเป็นต้องระบุ SpO2RR ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
          );
          return false;
        }
        if (!fields?.data_100?.data_5) {
          setWarningMessage(
            "จำเป็นต้องระบุ IBP ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
          );
          return false;
        }
        if (!fields?.data_100?.data_6) {
          setWarningMessage(
            "จำเป็นต้องระบุ BP ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
          );
          return false;
        }
        if (!fields?.data_100?.data_7) {
          setWarningMessage(
            "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
          );
          return false;
        }
      }

      if (!fields.data_101 || !fields.data_102) {
        setWarningMessage("จำเป็นต้องระบุ Contrast Type");
        return false;
      }
      if (!fields.data_103) {
        setWarningMessage("จำเป็นต้องระบุ Flu time");
        return false;
      }
      if (!fields.data_104) {
        setWarningMessage("จำเป็นต้องระบุ Exposure dose");
        return false;
      }
      if (!fields.data_105) {
        setWarningMessage("จำเป็นต้องระบุ Area dose");
        return false;
      }
    } else if (type == "38") {
      //#region Form 38 validator
      // var isData17Valid = true;
      // var count17 = 0;

      if (fields?.data_drug) {
        if (fields?.data_drug?.some((item) => item.data_1 == "0")) {
          setWarningMessage(
            "ไม่สามารถบันทึกได้ เนื่องจากท่านบันทึกคำแนะนำยาไม่ครบ"
          );
          return false;
        }
      }

      // if (typeof fields.data_17 != "object" && fields.data_17 != null) {
      //   const data_17 = utilParseStringToJson(fields.data_17);
      //   isData17Valid = data_17.every((item) => item.value == "1");

      //   count17 = data_17.length;
      // } else if (fields.data_17) {
      //   isData17Valid = fields.data_17?.every((item) => item.value == "1");
      //   count17 = fields.data_17.length;
      // }

      // if (!isData17Valid || count17 != fields?.drug_list_length) {
      //   setWarningMessage(
      //     "ไม่สามารถบันทึกได้ เนื่องจากท่านบันทึกคำแนะนำยาไม่ครบ"
      //   );
      //   return false;
      // }
      //#endregion
    }

    return true;
  }

  function formGenerate(type) {
    if (type == "1") {
      return (
        <FormFammed
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          addDataFormItem={addDataFormItem}
          getDataFormItem={getDataFormItem}
          loadDataFormItem={loadDataFormItem}
          hn={hn}
          txn={txn}
          formId={props.formId}
        />
      );
    } else if (type == "2") {
      return (
        <FormESAS
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "3") {
      return (
        <FormPosScale
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "4") {
      return (
        <FormGoodDeath
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "5") {
      return (
        <FormTMSE
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "6") {
      return (
        <FormNursingAssessment
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "7") {
      return (
        <FormACSCaringSet
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "8") {
      return (
        <FormACSTeleFollowUp
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "9") {
      return (
        <FormHeartRepair
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "10") {
      return (
        <FormCathLabDischarge
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          isFetchDone={isFetchDone}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "11") {
      return (
        <FormCathLabAssessment
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "12") {
      return (
        <FormHomeVisit
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          CBC
        />
      );
    } else if (type == "13") {
      return (
        <FormConsultation
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "14") {
      return (
        <FormHeartFailure
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "15") {
      return (
        <FormHeartFailureOPD
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "16") {
      return (
        <FormHeartFailureDischargeCheckList
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "17") {
      return (
        <FormADL
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          setScore={setScore}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "18") {
      return (
        <FormStroke
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          addDataFormItem={addDataFormItem}
          getDataFormItem={getDataFormItem}
          loadDataFormItem={loadDataFormItem}
          hn={hn}
          txn={txn}
          formId={props.formId}
        />
      );
    } else if (type == "19") {
      return (
        <FormRankinScale
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          setScore={setScore}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "20") {
      return (
        <FormPhamacy
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "21") {
      return (
        <FormPhamacyImage
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "22") {
      return (
        <FormWafarin
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          onSearch={(search) => getSearchData(search)}
          onClear={getAllExistingData}
        />
      );
    } else if (type == "23") {
      return (
        <FormNoac
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          onSearch={(search) => getSearchData(search)}
          onClear={getAllExistingData}
        />
      );
    } else if (type == "24") {
      return (
        <FormInhaler
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          onSearch={(search) => getSearchData(search)}
          onClear={getAllExistingData}
        />
      );
    } else if (type == "25") {
      return (
        <FormInsurine
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          onSearch={(search) => getSearchData(search)}
          onClear={getAllExistingData}
        />
      );
    } else if (type == "26") {
      return (
        <FormBispho
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          onSearch={(search) => getSearchData(search)}
          onClear={getAllExistingData}
        />
      );
    } else if (type == "27") {
      return (
        <FormDeliveryGeneralMedicine
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          profile={profile}
        />
      );
    } else if (type == "28") {
      return (
        <FormDeliveryDrugMedicine
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          profile={profile}
        />
      );
    } else if (type == "29") {
      return (
        <FormDeliveryWafarineMedicine
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          profile={profile}
          isFetchDone={isFetchDone}
        />
      );
    } else if (type == "30") {
      return (
        <FormPPS
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "31") {
      return (
        <FormMoca
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "32") {
      return (
        <Form2Q
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "33") {
      return (
        <Form8Q
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "34") {
      return (
        <Form9Q
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          setScore={setScore}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "35") {
      return (
        <FormFearFalling
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "36") {
      return (
        <FormZarit12
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "37") {
      return (
        <FormDrugAdherence
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "38") {
      return (
        <FormTelePharmacy
          isFetchDone={isFetchDone}
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          addDataFormItem={addDataFormItem}
          getDataFormItem={getDataFormItem}
          loadDataFormItem={loadDataFormItem}
          hn={hn}
          txn={txn}
          formId={props.formId}
        />
      );
    } else if (type == "39") {
      return (
        <FormTGDS
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "40") {
      return (
        <FormGAD7
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "41") {
      return (
        <FormPostFalling
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          addDataFormItem={addDataFormItem}
          getDataFormItem={getDataFormItem}
          loadDataFormItem={loadDataFormItem}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "42") {
      return (
        <FormHeartFailureClinic
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "43") {
      return (
        <FormMinnesotaLivingWithHeartFailure
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "44") {
      return (
        <FormADLS
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "45") {
      return (
        <FormIADLS
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
        />
      );
    }
  }

  function validNumWithDot(e) {
    // Allow: backspace, delete, tab, escape, enter and .(190)
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  function disableKeydown(e) {
    e.preventDefault();
  }

  function validNum(e) {
    // Allow: backspace, delete, tab, escape, enter and .(190)
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  function inputInit() {
    var elements = document.querySelectorAll("input[type='numeric']");

    [].forEach.call(elements, function (e) {
      e.addEventListener("keydown", (event) => validNum(event));
    });

    var elements = document.querySelectorAll("input[type='decimal']");

    [].forEach.call(elements, function (e) {
      e.addEventListener("keydown", (event) => validNumWithDot(event));
    });

    var elements = document.querySelectorAll(".date-picker");

    [].forEach.call(elements, function (e) {
      e.addEventListener("keydown", (event) => disableKeydown(event));
    });

    var elements = document.querySelectorAll(".disableKey");

    [].forEach.call(elements, function (e) {
      e.addEventListener("keydown", (event) => disableKeydown(event));
    });
  }

  useEffect(() => {
    formInit();
    //listGet();
    inputInit();
  }, []);

  function formGeneratePopup(type) {
    if (type == "1") {
      return (
        <FormFammedPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "2") {
      return (
        <FormESASPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "3") {
      return (
        <FormPosScalePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "4") {
      return (
        <FormGoodDeathPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "5") {
      return (
        <FormTMSEPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "6") {
      return (
        <FormNursingAssessmentPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "7") {
      return (
        <FormACSCaringSetPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "8") {
      return (
        <FormACSTeleFollowUpPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "9") {
      return (
        <FormHeartRepairPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "10") {
      return (
        <FormCathLabDischargePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "11") {
      return (
        <FormCathLabAssessmentPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "12") {
      return (
        <FormHomeVisitPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "13") {
      return (
        <FormConsultationPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "14") {
      return (
        <FormHeartFailurePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "15") {
      return (
        <FormHeartFailureOPDPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "16") {
      return (
        <FormHeartFailureDischargeCheckListPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "17") {
      return (
        <FormADLPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "18") {
      return (
        <FormStrokePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          memberProfile={memberProfile}
          profile={profile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "19") {
      return (
        <FormRankinScalePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "22") {
      return (
        <FormWafarinPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "23") {
      return (
        <FormNoacPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "24") {
      return (
        <FormInhalerPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "25") {
      return (
        <FormInsurinePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "26") {
      return (
        <FormBisphoPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "27") {
      return (
        <FormDeliveryGeneralMedicinePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "28") {
      return (
        <FormDeliveryDrugMedicinePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "29") {
      return (
        <FormDeliveryWafarineMedicinePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "30") {
      return (
        <FormPPSPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "31") {
      return (
        <FormMocaPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "32") {
      return (
        <Form2QPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "33") {
      return (
        <Form8QPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "34") {
      return (
        <Form9QPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "35") {
      return (
        <FormFearFallingPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "36") {
      return (
        <FormZarit12Paper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "37") {
      return (
        <FormDrugAdherencePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "38") {
      return (
        <FormTelePharmacyPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "39") {
      return (
        <FormTGDSPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "40") {
      return (
        <FormGAD7Paper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          setScore={setScore}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "41") {
      return (
        <FormPostFallingPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          profile={profile}
          memberProfile={memberProfile}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "42") {
      return (
        <FormHeartFailureClinicPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    } else if (type == "43") {
      return (
        <FormMinnesotaLivingWithHeartFailurePaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    }
  }

  const getIPaperForm = useCallback((type) => {
    if (type == "12") {
      return (
        <FormHomeVisitPaper
          dataForm={fields}
          dataFormOlds={dataFormOlds}
          addValue={addValue}
          hn={hn}
          txn={txn}
          isNewPaper={true}
          memberProfile={memberProfile}
          profile={profile}
        />
      );
    }
  });

  const onPrint = useCallback(() => {
    if (previewHtmlRef.current) {
      var printContents = previewHtmlRef.current.innerHTML;
      if (printContents) {
        var printWindow = window.open("", "_blank", "scrollbars=1");

        printWindow.document.write("<html>");
        printWindow.document.write(
          "<style>" +
            "@page { size: A4; margin: 0mm; } " +
            "@media print { body { -webkit-print-color-adjust: exact; margin: 14mm }}" +
            "body { -webkit-print-color-adjust: exact; font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; }" +
            "td { padding-left: 4px; padding-bottom: 4px; }" +
            "th { text-align: left; }" +
            "table { font-size: 14px; }" +
            "table,th,td { border-collapse: collapse; border: 1px solid grey; }" +
            "img {  -webkit-print-color-adjust: exact; }" +
            "</style>"
        );
        printWindow.document.write("<body>");
        printWindow.document.write("<div>");
        printWindow.document.write(printContents);
        printWindow.document.write("</div>");
        printWindow.document.write("</body>");
        printWindow.document.write("</html>");
        printWindow.document.close();
        // printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }
  });

  const renderPreview = useMemo(() => {
    if (previewHtmlRef.current) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(
        previewHtmlRef.current.innerHTML,
        "text/html"
      );
      return doc.body.innerHTML;
    }
    return null;
  }, [isShowPreview, previewHtmlRef]);

  return (
    <div className="uk-padding">
      {/*
      <div uk-grid="" className="uk-grid-small uk-padding-small">
        <div className="uk-inline formTitle">
          <a className="uk-button uk-button-primary" target='_blank'  onClick={doSave}>บันทึก</a>
        </div>
      </div>*/}
      <div className="uk-card uk-card-default">
        <div uk-grid="" className="uk-grid-small uk-padding-small">
          <div className="uk-width-1-1@m uk-width-1-1">
            <MemberProfileFormHeader
              hn={hn}
              txn={txn}
              addValue={addValue}
              onSelectMember={onSelectMember}
              formId={props.formId}
              setMemberData={setMemberData}
            />
          </div>
          {formGenerate(props.type)}
        </div>

        <div uk-grid="" className="uk-grid-small uk-padding-small">
          <div className="uk-inline formTitle">
            {!hidePreviewBtn ? (
              <a
                className="uk-button uk-button-primary"
                target="_blank"
                onClick={doPreview}
              >
                Preview
              </a>
            ) : (
              <></>
            )}
            &nbsp;&nbsp;
            {!hideSaveBtn ? (
              <button
                className="uk-button uk-button-primary uk-modal-close"
                type="button"
                onClick={doSave}
              >
                Save
              </button>
            ) : (
              <></>
            )}
            &nbsp;&nbsp;
            <button
              className="uk-button uk-button-primary"
              type="button"
              onClick={onPrint}
            >
              Print
            </button>
          </div>
        </div>
      </div>
      <div className="uk-card uk-card-default hidden">
        <div ref={Ref} uk-grid="" className="uk-grid-small uk-padding-small">
          {formGeneratePopup(props.type)}
        </div>
      </div>
      {isShowPreview && (
        <div className="form-modal-container">
          <div className="form-modal-dialog-container">
            <div className="form-modal-dialog-header">
              <h2 className="uk-modal-title"></h2>
              <div className="form-modal-right-header">
                <button
                  className="uk-button-danger form-modal-close-button"
                  type="button"
                  onClick={() => setIsShowPreview(false)}
                >
                  {" "}
                  <span uk-icon="icon: close; ratio: 1"></span>
                </button>
              </div>
            </div>
            <div className="form-modal-dialog-body">
              <div
                id="previewHtml"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "14px",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: renderPreview }}></div>
              </div>
            </div>
            <div
              className="form-modal-dialog-footer uk-text-right"
              style={{ padding: "8px" }}
            >
              <button
                className="uk-button uk-button-default uk-modal-close"
                type="button"
                onClick={() => setIsShowPreview(false)}
              >
                Close
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close"
                type="button"
                onClick={doSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div id="modal-preview" className="uk-modal">
        <div className="uk-modal-dialog uk-width-auto">
          <button className="uk-modal-close-default uk-close" type="button">
            <span uk-icon="icon: close; ratio: 1"></span>
          </button>
          <div className="uk-modal-body">
            <div id="previewHtml"></div>
          </div>
          <div className="uk-modal-footer uk-text-right">
            <button
              className="uk-button uk-button-default uk-modal-close"
              type="button"
            >
              Close
            </button>
            <button
              className="uk-button uk-button-primary uk-modal-close"
              type="button"
              onClick={doSave}
            >
              Save
            </button>
          </div>
        </div>
      </div> */}
      {/* <button onClick={() => doSave()}>save</button> */}
      {/* <button onClick={() => setIPaperShow(true)} hidden={props.type != "12"}>
        Preview
      </button> */}
      {/* <IPaperPopupForm
        show={iPaperShow}
        data={getIPaperForm(props.type)}
        onClose={() => setIPaperShow(false)}
      /> */}
      <SaveSuccessfulMessage
        show={isShowSaveSuccessfulPopup}
        onClose={handleCloseSavePopup}
      />
      <AlertMessage
        show={isShowSaveFailedPopup}
        title={"ไม่สามารถดำเนินการได้"}
        message={warningMessage}
        onClose={() => setIsShowSaveFailedPopup(false)}
      />
      <div hidden ref={previewHtmlRef}>
        {formGeneratePopup(props.type)}
      </div>
    </div>
  );
};

export default FormPaper;
