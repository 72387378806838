import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import Api from "../../Api";
import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import dateFormat from "dateformat";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilConvertDisplayDate = require("./FormUtil.js").convertDisplayDate;

const FormACSTeleFollowUp = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [opdCard, setOpdCard] = useState(false);
  var [historyWardList, setHistoryWardList] = useState([]);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_acstelefollowup",
      formType: "8",
    });
  }, []);

  useEffect(() => {
    console.log(props.memberProfile);
  }, [props.memberProfile]);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const initiateDataForm = useCallback(() => {
    if (!dataForm?.data_5) {
      setData("5", dateFormat(new Date(), "yyyy-mm-dd"));
    }
  }, [dataForm]);

  useEffect(() => {
    initiateDataForm();
  }, [dataForm]);

  useEffect(() => {
    console.log(dataForm);
  }, [dataForm]);

  useEffect(() => {
    getHistoryWard();
  }, [props.hn]);

  async function getHistoryWard() {
    try {
      if (props.hn) {
        var result = await Api.consult("GetHistoryWardByHN", {
          hn: props.hn,
        });

        if (result.list) {
          // setHistoryWard(result.list[0]);
          setHistoryWardList(result.list);
        } else {
          // setHistoryWard({});
          setHistoryWardList([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getOpdCard() {
    try {
      if (props.hn && props.txn && props.memberProfile?.room_code) {
        var result = await Api.consult("GetOPDCardByHN", {
          hn: props.hn,
          txn: props.txn,
          roomCode: props.memberProfile?.room_code,
        });

        if (result.list) {
          var data = result.list.find((item) => item.txn == props.txn);
          if (data) {
            console.log(data);
            setOpdCard(data);
          }
        } else {
          setOpdCard({});
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOpdCard();
  }, [props.hn, props.txn, props.memberProfile]);

  useEffect(() => {
    console.log("opd", opdCard);
    if (opdCard) {
      setData("2", opdCard.Past_History);
    } else {
      setData("2", "");
    }
  }, [opdCard]);

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="ACS Tele Follow Up" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span className="formTextBoxLabel">
            Diagnosis <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementRadio
            fieldId={"1"}
            value={dataForm.data_1}
            fieldValue={"1"}
            onChange={handleChange}
            label="STEMI"
          />
          <FormElementRadio
            fieldId={"1"}
            value={dataForm.data_1}
            fieldValue={"2"}
            onChange={handleChange}
            label="NSTEMI"
          />
          <FormElementRadio
            fieldId={"1"}
            value={dataForm.data_1}
            fieldValue={"3"}
            onChange={handleChange}
            label="Unsatble angina"
          />
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextArea
            fieldId={"2"}
            value={dataForm.data_2}
            label="Underlying diseuse"
            onChange={handleChange}
            width="100"
            cols={120}
            rows={3}
            isRequired
          />
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <div
            style={{
              display: "inline-flex",
              gap: "10px",
              alignItems: "start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
              }}
            >
              <FormElementTextBox
                fieldId={"3"}
                value={dataForm.data_3}
                type="decimal"
                validate={(value) =>
                  parseFloat(value) >= 10.0 && parseFloat(value) <= 79.9
                }
                label="LVEF"
                onChange={handleChange}
                width="100"
                isRequired
              />
              <span
                style={{
                  fontSize: "12px",
                  marginLeft: "52px",
                  marginTop: "8px",
                }}
              >
                ระบุ ได้ตั้งแต่ 10-79.9 เท่านั้น
              </span>
            </div>
            <span style={{ marginTop: "10px" }}>%</span>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              {/* <span className="formTextBoxLabel">
                Last admission <span style={{ color: "red" }}>*</span>
              </span> */}
              {/* <FormElementTextBox
                  
                  fieldId={"4"}
                  value={dataForm.data_4}
                  label=""
                  type="date"
                  onChange={handleChange}
                  width="100"
                /> */}
              <FormElementDropdown
                fieldId={"4"}
                value={dataForm.data_4}
                label=" Last admission"
                dataList={[{ value: "", label: "เลือกวันที่" }].concat(
                  historyWardList.map((item) => ({
                    value: item.dc,
                    label: utilConvertDisplayDate(item.dc),
                  }))
                )}
                onChange={handleChange}
                width="100"
                isRequired
              />
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span className="formTextBoxLabel">
            ACS Tele follow up date of <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementTextBox
            fieldId={"5"}
            value={dataForm.data_5}
            label=""
            type="date"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"101n"}
            value={dataForm.data_101n}
            label="Follow-up times"
            type="numeric"
            maxLength={10}
            onChange={handleChange}
            width="100"
            isRequired
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped bordered form-table">
          <thead>
            <tr>
              <th>Topics</th>
              <th>
                Nursing Management <span style={{ color: "red" }}>*</span>
              </th>
              <th>
                Comment <span style={{ color: "red" }}>*</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  1. Knowlegde of diseuse and treatment plan{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"6"}
                    value={dataForm.data_6}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                  />
                  <span
                    className={
                      dataForm.data_6 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"7"}
                      value={dataForm.data_7}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormElementRadio
                    fieldId={"6"}
                    value={dataForm.data_6}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="No"
                  />
                  <span
                    className={
                      dataForm.data_6 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"8"}
                      value={dataForm.data_8}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"9"}
                  value={dataForm.data_9}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"10"}
                  value={dataForm.data_10}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  2. &nbsp;&nbsp;&nbsp;
                  <div style={{ display: "inline-flex", gap: "16px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <FormElementRadio
                        fieldId={"11"}
                        value={dataForm.data_11}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Re-admission"
                        fontWeight="normal"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          gap: "4px",
                          paddingLeft: "20px",
                        }}
                        hidden={dataForm.data_11 != "1"}
                      >
                        <FormElementRadio
                          fieldId={"101"}
                          value={dataForm.data_101}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label="Yes"
                          fontWeight="normal"
                        />
                        <FormElementRadio
                          fieldId={"101"}
                          value={dataForm.data_101}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label="No"
                          fontWeight="normal"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <FormElementRadio
                        fieldId={"11"}
                        value={dataForm.data_11}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="ER Visit"
                        fontWeight="normal"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          gap: "4px",
                          paddingLeft: "20px",
                        }}
                        hidden={dataForm.data_11 != "2"}
                      >
                        <FormElementRadio
                          fieldId={"102"}
                          value={dataForm.data_102}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label="Yes"
                          fontWeight="normal"
                        />
                        <FormElementRadio
                          fieldId={"102"}
                          value={dataForm.data_102}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label="No"
                          fontWeight="normal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <div
                    hidden={dataForm.data_101 != "1" || dataForm.data_11 != "1"}
                  >
                    <span className="formTextBoxLabel">Date</span>
                    <FormElementTextBox
                      fieldId={"13"}
                      value={dataForm.data_13}
                      type="date"
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                    <FormElementTextBox
                      fieldId={"14"}
                      value={dataForm.data_14}
                      label="Hospital"
                      onChange={handleChange}
                      width="100"
                    />
                    <div
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <FormElementRadio
                          fieldId={"12"}
                          value={dataForm.data_12}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label="Cardiac caused"
                          fontWeight="normal"
                        />
                        <FormElementTextBox
                          fieldId={"115"}
                          value={dataForm.data_115}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <FormElementRadio
                          fieldId={"12"}
                          value={dataForm.data_12}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label="Non-Cardiac caused"
                          fontWeight="normal"
                        />
                        <FormElementTextBox
                          fieldId={"116"}
                          value={dataForm.data_116}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    hidden={dataForm.data_102 != "1" || dataForm.data_11 != "2"}
                  >
                    <span className="formTextBoxLabel">Date</span>
                    <FormElementTextBox
                      fieldId={"103"}
                      value={dataForm.data_103}
                      type="date"
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                    <FormElementTextBox
                      fieldId={"104"}
                      value={dataForm.data_104}
                      label="Hospital"
                      onChange={handleChange}
                      width="100"
                    />
                    <div
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <FormElementRadio
                          fieldId={"112"}
                          value={dataForm.data_112}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label="Cardiac caused"
                          fontWeight="normal"
                        />
                        <FormElementTextBox
                          fieldId={"117"}
                          value={dataForm.data_117}
                          onChange={handleChange}
                        />
                      </div>

                      <div
                        style={{
                          display: "inline-flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <FormElementRadio
                          fieldId={"112"}
                          value={dataForm.data_112}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label="Non-Cardiac caused"
                          fontWeight="normal"
                        />
                        <FormElementTextBox
                          fieldId={"118"}
                          value={dataForm.data_118}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"15"}
                  value={dataForm.data_15}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"16"}
                  value={dataForm.data_16}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  3. Abnormal symptoms
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"17"}
                    value={dataForm.data_17}
                    onChange={handleChange}
                    label="Chest pain"
                  />
                  <FormElementCheckBox
                    fieldId={"18"}
                    value={dataForm.data_18}
                    onChange={handleChange}
                    label="dyspnea"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"19"}
                    value={dataForm.data_19}
                    onChange={handleChange}
                    label="PND"
                  />
                  <FormElementCheckBox
                    fieldId={"20"}
                    value={dataForm.data_20}
                    onChange={handleChange}
                    label="orthopnea"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"21"}
                    value={dataForm.data_21}
                    onChange={handleChange}
                    label="edema"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"22"}
                    value={dataForm.data_22}
                    onChange={handleChange}
                    label=" bleeding/hematoma/pain/บวม/แดง/ร้อน"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"23"}
                    value={dataForm.data_23}
                    onChange={handleChange}
                    label=" Orthostatic hypotension"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"24"}
                    value={dataForm.data_24}
                    onChange={handleChange}
                    label="Other"
                  />
                  <span
                    className={
                      dataForm.data_24 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"25"}
                      value={dataForm.data_25}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"26"}
                  value={dataForm.data_26}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"27"}
                  value={dataForm.data_27}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  4. Recognize abnormal symptoms and basic self care
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"28"}
                    value={dataForm.data_28}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                  />
                  <span
                    className={
                      dataForm.data_28 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"29"}
                      value={dataForm.data_29}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormElementRadio
                    fieldId={"28"}
                    value={dataForm.data_28}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="No"
                  />
                  <span
                    className={
                      dataForm.data_28 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"30"}
                      value={dataForm.data_30}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"31"}
                  value={dataForm.data_31}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"32"}
                  value={dataForm.data_32}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">5. Medication</div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"33"}
                    value={dataForm.data_33}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Well adherence and have knowledge of medicine"
                  />
                </div>

                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"33"}
                    value={dataForm.data_33}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Non adherence to medication"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  hidden={dataForm.data_33 != "2"}
                >
                  <FormElementCheckBox
                    fieldId={"34"}
                    value={dataForm.data_34}
                    onChange={handleChange}
                    label="Forget to take medicine"
                  />
                  <FormElementCheckBox
                    fieldId={"35"}
                    value={dataForm.data_35}
                    onChange={handleChange}
                    label="Buy self medication"
                  />
                  <FormElementCheckBox
                    fieldId={"36"}
                    value={dataForm.data_36}
                    onChange={handleChange}
                    label="Stop taking medicine"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  hidden={dataForm.data_33 != "2"}
                >
                  <FormElementCheckBox
                    fieldId={"37"}
                    value={dataForm.data_37}
                    onChange={handleChange}
                    label="Self medication adjustment"
                  />
                  <FormElementCheckBox
                    fieldId={"38"}
                    value={dataForm.data_38}
                    onChange={handleChange}
                    label="Taking medicine incorrectly"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  hidden={dataForm.data_33 != "2"}
                >
                  <FormElementCheckBox
                    fieldId={"39"}
                    value={dataForm.data_39}
                    onChange={handleChange}
                    label="NSAIDs used"
                  />
                  <FormElementCheckBox
                    fieldId={"40"}
                    value={dataForm.data_40}
                    onChange={handleChange}
                    label="Herb used"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  hidden={dataForm.data_33 != "2"}
                >
                  <FormElementCheckBox
                    fieldId={"41"}
                    value={dataForm.data_41}
                    onChange={handleChange}
                    label="Improper adjustment of diuretics"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  hidden={dataForm.data_33 != "2"}
                >
                  <FormElementCheckBox
                    fieldId={"42"}
                    value={dataForm.data_42}
                    onChange={handleChange}
                    label="Improper use of sublingual medicine"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  hidden={dataForm.data_33 != "2"}
                >
                  <FormElementCheckBox
                    fieldId={"43"}
                    value={dataForm.data_43}
                    onChange={handleChange}
                    label="Other"
                  />
                  <span
                    className={
                      dataForm.data_43 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"44"}
                      value={dataForm.data_44}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"45"}
                  value={dataForm.data_45}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"46"}
                  value={dataForm.data_46}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">6. Exercise</div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"105"}
                    value={dataForm.data_105}
                    onChange={handleChange}
                    label="Walking program"
                    fontWeight="normal"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"106"}
                    value={dataForm.data_106}
                    onChange={handleChange}
                    label="Calisthenic exercise (warm-up-cool-down)"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"107"}
                    value={dataForm.data_107}
                    onChange={handleChange}
                    label="Other"
                  />
                  <span
                    className={
                      dataForm.data_107 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"48"}
                      value={dataForm.data_48}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"49"}
                  value={dataForm.data_49}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"50"}
                  value={dataForm.data_50}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">7. Activity</div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <span className="formTextBoxLabel">7.1 Pre D/C (METs)</span>
                  <FormElementRadio
                    fieldId={"114"}
                    value={dataForm.data_114}
                    fieldValue={"1"}
                    label="น้อยกว่า"
                    onChange={handleChange}
                    width="100"
                  />
                  <span hidden={dataForm.data_114 != "1"}>
                    <FormElementTextBox
                      fieldId={"113"}
                      type="numeric"
                      maxLength={10}
                      value={dataForm.data_113}
                      onChange={handleChange}
                      width="100"
                    />
                  </span>

                  <FormElementRadio
                    fieldId={"114"}
                    value={dataForm.data_114}
                    fieldValue={"2"}
                    label="มากกว่า"
                    onChange={handleChange}
                    width="100"
                  />
                  <span hidden={dataForm.data_114 != "2"}>
                    <FormElementTextBox
                      fieldId={"108"}
                      type="numeric"
                      maxLength={10}
                      value={dataForm.data_108}
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <span className="formTextBoxLabel">7.2 F/U (METs)</span>
                  <FormElementRadio
                    fieldId={"109"}
                    value={dataForm.data_109}
                    fieldValue={"1"}
                    label="น้อยกว่า"
                    onChange={handleChange}
                    width="100"
                  />
                  <span hidden={dataForm.data_109 != "1"}>
                    <FormElementTextBox
                      fieldId={"111"}
                      type="numeric"
                      maxLength={10}
                      value={dataForm.data_111}
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                  <FormElementRadio
                    fieldId={"109"}
                    value={dataForm.data_109}
                    fieldValue={"2"}
                    label="มากกว่า"
                    onChange={handleChange}
                    width="100"
                  />
                  <span hidden={dataForm.data_109 != "2"}>
                    <FormElementTextBox
                      fieldId={"110"}
                      type="numeric"
                      maxLength={10}
                      value={dataForm.data_110}
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"53"}
                  value={dataForm.data_53}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"54"}
                  value={dataForm.data_54}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  8. Return to work <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"55"}
                    value={dataForm.data_55}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                  />
                  <span
                    className={
                      dataForm.data_55 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"56"}
                      value={dataForm.data_56}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"55"}
                    value={dataForm.data_55}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="No"
                  />
                  <span
                    className={
                      dataForm.data_55 == "0" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"63"}
                      value={dataForm.data_63}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"57"}
                  value={dataForm.data_57}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"58"}
                  value={dataForm.data_58}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  9. Psychosocial problem{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"59"}
                    value={dataForm.data_59}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                    fontWeight="normal"
                  />
                  <span
                    className={
                      dataForm.data_59 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementCheckBox
                        fieldId={"95"}
                        value={dataForm.data_95}
                        onChange={handleChange}
                        label="Anxiety"
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"96"}
                        value={dataForm.data_96}
                        onChange={handleChange}
                        label=" Depression "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"97"}
                        value={dataForm.data_97}
                        onChange={handleChange}
                        label=" Sleeping disorder"
                      />
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementCheckBox
                        fieldId={"98"}
                        value={dataForm.data_98}
                        onChange={handleChange}
                        label="Other"
                        fontWeight="normal"
                      />
                      <span
                        className={
                          dataForm.data_98 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"60"}
                          value={dataForm.data_60}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </div>
                  </span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"59"}
                    value={dataForm.data_59}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="No"
                    fontWeight="normal"
                  />
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"61"}
                  value={dataForm.data_61}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"62"}
                  value={dataForm.data_62}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  10. smoking cessation <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"64"}
                    value={dataForm.data_64}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="No Smoking"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"64"}
                    value={dataForm.data_64}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Smoking"
                    fontWeight="normal"
                  />
                  <span
                    className={
                      dataForm.data_64 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementRadio
                        fieldId={"99"}
                        value={dataForm.data_99}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Quit Smoking"
                        fontWeight="normal"
                      />
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementRadio
                        fieldId={"99"}
                        value={dataForm.data_99}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Current Smoking "
                      />
                      <span
                        className={
                          dataForm.data_99 == "2" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"65"}
                          value={dataForm.data_65}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"66"}
                  value={dataForm.data_66}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"67"}
                  value={dataForm.data_67}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  11. Risk factor control and secondary prevention
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"68"}
                    value={dataForm.data_68}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="diet"
                  />
                  <FormElementCheckBox
                    fieldId={"69"}
                    value={dataForm.data_69}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="exercise"
                  />
                  <FormElementCheckBox
                    fieldId={"70"}
                    value={dataForm.data_70}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="medication"
                    fontWeight="normal"
                  />
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"71"}
                  value={dataForm.data_71}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"72"}
                  value={dataForm.data_72}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">12. Diet</div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"73"}
                    value={dataForm.data_73}
                    onChange={handleChange}
                    label="Knowledge of diet suitable for disease"
                    fontWeight="normal"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"119"}
                    value={dataForm.data_119}
                    onChange={handleChange}
                    label="Good adherence"
                    fontWeight="normal"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"120"}
                    value={dataForm.data_120}
                    onChange={handleChange}
                    label="Non adherence to dietary recommendation"
                    fontWeight="normal"
                  />
                  <span hidden={dataForm.data_120 != "1"}>
                    <FormElementTextBox
                      fieldId={"121"}
                      value={dataForm.data_121}
                      onChange={handleChange}
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"74"}
                  value={dataForm.data_74}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"75"}
                  value={dataForm.data_75}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  13. An appointment for next follow up{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"76"}
                    value={dataForm.data_76}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                    fontWeight="normal"
                  />
                  <span
                    className={
                      dataForm.data_76 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <span className="formTextBoxLabel">
                      Date <span style={{ color: "red" }}>*</span>
                    </span>
                    <FormElementTextBox
                      fieldId={"77"}
                      value={dataForm.data_77}
                      type="date"
                      label=""
                      onChange={handleChange}
                      width="100"
                      fontWeight="normal"
                    />
                    <FormElementTextBox
                      fieldId={"78"}
                      value={dataForm.data_78}
                      label="Hospital"
                      onChange={handleChange}
                      width="100"
                      fontWeight="normal"
                      isRequired
                    />
                  </span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"76"}
                    value={dataForm.data_76}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="No"
                    fontWeight="normal"
                  />
                  <span
                    className={
                      dataForm.data_76 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"79"}
                      value={dataForm.data_79}
                      label=""
                      onChange={handleChange}
                      width="100"
                      fontWeight="normal"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"80"}
                  value={dataForm.data_80}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"81"}
                  value={dataForm.data_81}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  14. มีคำถามเพิ่มเติมหรือสิ่งสงสัยใดๆหรือไม่{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"82"}
                    value={dataForm.data_82}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                  />
                  <span
                    className={
                      dataForm.data_82 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"83"}
                      value={dataForm.data_83}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"82"}
                    value={dataForm.data_82}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="No"
                  />
                  <span
                    className={
                      dataForm.data_82 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"84"}
                      value={dataForm.data_84}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"85"}
                  value={dataForm.data_85}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"86"}
                  value={dataForm.data_86}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  15. รับทราบให้ฉีด Influenza vaccine ทุกปี{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"87"}
                    value={dataForm.data_87}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                  />
                  <span
                    className={
                      dataForm.data_87 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"88"}
                      value={dataForm.data_88}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"87"}
                    value={dataForm.data_87}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="No"
                  />
                  <span
                    className={
                      dataForm.data_87 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"89"}
                      value={dataForm.data_89}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"90"}
                  value={dataForm.data_90}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"91"}
                  value={dataForm.data_91}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  16. ความพึงพอใจในการโทรศัพท์ติดตามอาการ และให้คำแนะนำ (1-5
                  คะแนน) <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="พึงพอใจมากที่สุด (5 คะแนน)"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="พึงพอใจมาก (4 คะแนน)"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="พึงพอใจปานกลาง (3 คะแนน) "
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"4"}
                    onChange={handleChange}
                    label="พึงพอใจเล็กน้อย (2 คะแนน) "
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"5"}
                    onChange={handleChange}
                    label="ไม่พึงพอใจ (1 คะแนน)"
                    fontWeight="normal"
                  />
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"93"}
                  value={dataForm.data_93}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"94"}
                  value={dataForm.data_94}
                  onChange={handleChange}
                  label=""
                  fontWeight="bold"
                  rows="5"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
          <span hidden={!dataForm.form_id}>
            <FormElementTextArea
              fieldId={"122"}
              rows={5}
              cols={100}
              value={dataForm.data_122}
              noLimit
              onChange={handleChange}
              label="เหตุผลในการแก้ไข"
              width="100"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FormACSTeleFollowUp;
