import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";

import Api from "../../Api";
import dateFormat from "dateformat";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

import PopupForm from "../PopupForm";
import FormElementTagInput from "../Form/FormElementTagInput";
import FormElementUploadImage from "../Form/FormElementUploadImage";
import FormElementIconButton from "../Form/FormElementIconButton";

import ShowFilesList from "../../ShowFilesList";
import SendFiles from "../../SendFiles";
import PainSelectorSection from "../Utils/PainSelectorSection";
import SOAPSection from "../Utils/SOAPSection";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchDrugAllergic from "../../API/fetchDrugAllergic.js";
import fetchDrugDataByTxn from "../../API/fetchDrugDataByTxn.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormFammedPaper from "../FormPaper/FormFammedPaper.js";
import fetchFormFileListByTxn from "../../API/fetchFormFileListByTxn.js";
import FormPopup from "../Common/FormPopup.js";
import FormPPS from "./FormPPS.js";
import FormESAS from "./FormESAS.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormPosScale from "./FormPosScale.js";
import FormGoodDeath from "./FormGoodDeath.js";
import FormTMSE from "./FormTMSE.js";
import FormADLS from "./FormADLS.js";
import FormIADLS from "./FormIADLS.js";
import FormMoca from "./FormMoca.js";
import Form2Q from "./Form2Q.js";
import Form9Q from "./Form9Q.js";
import Form8Q from "./Form8Q.js";
import FormTGDS from "./FormTGDS.js";
import FormGAD7 from "./FormGAD7.js";
import FormZarit12 from "./FormZarit12.js";
import FormDrugAdherence from "./FormDrugAdherence.js";
import FormFearFalling from "./FormFearFalling.js";
import FormPostFalling from "./FormPostFalling.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave =
  require("../Common/FormUtil.js").transformDataBeforeSave;
var utilGetValueChanged = require("../Common/FormUtil.js").getValueChanged;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormFammed = (props) => {
  const formConstants = {
    formName: "form_fammed",
    formType: "1",
  };
  const {
    patient,
    onLoading,
    setPatient,
    formId,
    profile,
    onSuccessPopupOpen,
    onErrorMessageChange,
    hn,
    txn,
  } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const [data2Disabled, setData2Disabled] = useState([false]);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [drugList, setDrugList] = useState([]);
  var [drugAllergics, setDrugAllergics] = useState([]);

  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  const [popupDataForm, setPopupDataForm] = useState({});
  const [isEditingData114, setIsEditingData114] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [painImage, setPainImage] = useState("");
  const [dataFilesList, setDataFilesList] = useState([]);

  const [ppsDataForm, setPpsDataForm] = useState({ data: {}, html: "" });
  const [esasDataForm, setEsasDataForm] = useState({ data: {}, html: "" });
  const [posDataForm, setPosDataForm] = useState({ data: {}, html: "" });
  const [goodDeathDataForm, setGoodDeathDataForm] = useState({
    data: {},
    html: "",
  });
  const [tmseDataForm, setTmseDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [mocaDataForm, setMocaDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [twoQDataForm, setTwoQDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [eightQDataForm, setEightQDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [nineQDataForm, setNineQDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [tgdsDataForm, setTgdsDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [gad7DataForm, setGad7DataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [fearDataForm, setFearDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [postFallingDataForm, setPostFallingDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [zaritDataForm, setZaritDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [drugDataForm, setDrugDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [adlsDataForm, setAdlsDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });
  const [iadlsDataForm, setIAdlsDataForm] = useState({
    data: {},
    html: "",
    score: NaN,
  });

  var vaccineList = [
    { label: "Select Vaccine", value: "" },
    { label: "Influenza", value: "1" },
    { label: "Tetanus", value: "2" },
    { label: "Others", value: "3" },
  ];

  var objectiveList = [
    "Clinical assessment (Other than pain)",
    "Pain assessment",
    "Advice for self-management",
    "Health promotion by med student",
    "Wound dressing",
    "NG care",
    "Foley's care",
  ];

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback(
    (open) => {
      setIsPreview(open);
    },
    [setIsPreview]
  );

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถดึงข้อมูลได้",
      isGoToMainPage: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถบันทึกรายการได้",
      isClose: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback(
    (message) => {
      onErrorMessageChange({
        open: true,
        header: "ไม่สามารถบันทึกได้",
        description: message,
        isClose: true,
      });
    },
    [onErrorMessageChange]
  );

  const prepareData = useCallback((form, profile) => {
    if (profile.doctorName) {
      form.data_36 = profile.doctorName;
    }

    if (!form.data_114) {
      form.data_114 = [];
    }

    return form;
  }, []);

  function doESAS() {
    setPopupFormType(2);
    setShowFormPopup(true);
  }

  function doPOS() {
    setPopupFormType(3);
    setShowFormPopup(true);
  }

  function doGoodDeath() {
    setPopupFormType(4);
    setShowFormPopup(true);
  }

  function doPPS() {
    setPopupFormType(30);
    setShowFormPopup(true);
  }

  function doTMSE() {
    setPopupFormType(5);
    setShowFormPopup(true);
  }

  function doMOCA() {
    setPopupFormType(31);
    setShowFormPopup(true);
  }

  function do2Q() {
    setPopupFormType(32);
    setShowFormPopup(true);
  }

  function do8Q() {
    setPopupFormType(33);
    setShowFormPopup(true);
  }

  function do9Q() {
    setPopupFormType(34);
    setShowFormPopup(true);
  }

  function doTGDS() {
    setPopupFormType(39);
    setShowFormPopup(true);
  }

  function doGAD7() {
    setPopupFormType(40);
    setShowFormPopup(true);
  }

  function doFear() {
    setPopupFormType(35);
    setShowFormPopup(true);
  }

  function doPostFalling() {
    setPopupFormType(41);
    setShowFormPopup(true);
  }

  function doZarit() {
    setPopupFormType(36);
    setShowFormPopup(true);
  }

  function doDrug() {
    setPopupFormType(37);
    setShowFormPopup(true);
  }

  function doADLS() {
    setPopupFormType(44);
    setShowFormPopup(true);
  }

  function doIADLS() {
    setPopupFormType(45);
    setShowFormPopup(true);
  }

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const drugAllergicRequest = fetchDrugAllergic(hn);
    const drugDataRequest = fetchDrugDataByTxn(hn, txn);
    const formFileListRequest = fetchFormFileListByTxn(txn);
    const ppsDataFormRequest = fetchConsultFormByTxn(txn, "30");
    const esasDataFormRequest = fetchConsultFormByTxn(txn, "2");
    const posDataFormRequest = fetchConsultFormByTxn(txn, "3");
    const goodDeathDataFormRequest = fetchConsultFormByTxn(txn, "4");
    const tmseDataFormRequest = fetchConsultFormByTxn(txn, "5");
    const mocaDataFormRequest = fetchConsultFormByTxn(txn, "31");
    const twoQDataFormRequest = fetchConsultFormByTxn(txn, "32");
    const eightQDataFormRequest = fetchConsultFormByTxn(txn, "33");
    const nineQDataFormRequest = fetchConsultFormByTxn(txn, "34");
    const tgdsDataFormRequest = fetchConsultFormByTxn(txn, "39");
    const gad7DataFormRequest = fetchConsultFormByTxn(txn, "40");
    const fearDataFormRequest = fetchConsultFormByTxn(txn, "35");
    const postFallingDataFormRequest = fetchConsultFormByTxn(txn, "41");
    const zaritDataFormRequest = fetchConsultFormByTxn(txn, "36");
    const drugDataFormRequest = fetchConsultFormByTxn(txn, "37");
    const adlsDataFormRequest = fetchConsultFormByTxn(txn, "44");
    const iadlsDataFormRequest = fetchConsultFormByTxn(txn, "45");
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([
      patientDataRequest,
      drugAllergicRequest,
      drugDataRequest,
      formFileListRequest,
      ppsDataFormRequest,
      esasDataFormRequest,
      posDataFormRequest,
      goodDeathDataFormRequest,
      tmseDataFormRequest,
      mocaDataFormRequest,
      twoQDataFormRequest,
      eightQDataFormRequest,
      nineQDataFormRequest,
      tgdsDataFormRequest,
      gad7DataFormRequest,
      fearDataFormRequest,
      postFallingDataFormRequest,
      zaritDataFormRequest,
      drugDataFormRequest,
      adlsDataFormRequest,
      iadlsDataFormRequest,
      patientRoomRequest,
    ])
      .then((values) => {
        const patientResponse = values[0];
        const drugAllergicResponse = values[1];
        const drugDataResponse = values[2];
        const formFileListResponse = values[3];
        const ppsDataFormResponse = values[4];
        const esasDataFormResponse = values[5];
        const posDataFormResponse = values[6];
        const goodDeathDataFormResponse = values[7];
        const tmseDataFormResponse = values[8];
        const mocaDataFormResponse = values[9];
        const twoQDataFormResponse = values[10];
        const eightQDataFormResposne = values[11];
        const nineQDataFormResponse = values[12];
        const tgdsDataFormResponse = values[13];
        const gad7DataFormResponse = values[14];
        const fearDataFormResposne = values[15];
        const postFallingDataFormResponse = values[16];
        const zaritDataFormResponse = values[17];
        const drugDataFormResponse = values[18];
        const adlsDataFormResponse = values[19];
        const iadlsDataFormResponse = values[20];
        const patientRoomResponse = values[21];

        data = prepareData({ ...data }, profile);
        setDrugList(drugDataResponse);
        setDrugAllergics(drugAllergicResponse);
        setDataFilesList(formFileListResponse);
        setPpsDataForm({ data: { ...ppsDataFormResponse, hn, txn } });
        setEsasDataForm({ data: { ...esasDataFormResponse, hn, txn } });
        setPosDataForm({ data: { ...posDataFormResponse, hn, txn } });
        setGoodDeathDataForm({
          data: { ...goodDeathDataFormResponse, hn, txn },
        });
        setTmseDataForm({ data: { ...tmseDataFormResponse, hn, txn } });
        setMocaDataForm({ data: { ...mocaDataFormResponse, hn, txn } });
        setTwoQDataForm({ data: { ...twoQDataFormResponse, hn, txn } });
        setEightQDataForm({ data: { ...eightQDataFormResposne, hn, txn } });
        setNineQDataForm({ data: { ...nineQDataFormResponse, hn, txn } });
        setTgdsDataForm({ data: { ...tgdsDataFormResponse, hn, txn } });
        setGad7DataForm({ data: { ...gad7DataFormResponse, hn, txn } });
        setFearDataForm({ data: { ...fearDataFormResposne, hn, txn } });
        setPostFallingDataForm({
          data: { ...postFallingDataFormResponse, hn, txn },
        });
        setZaritDataForm({ data: { ...zaritDataFormResponse, hn, txn } });
        setDrugDataForm({ data: { ...drugDataFormResponse, hn, txn } });
        setAdlsDataForm({ data: { ...adlsDataFormResponse, hn, txn } });
        setIAdlsDataForm({ data: { ...iadlsDataFormResponse, hn, txn } });
        setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
        setDataForm((form) => ({
          ...form,
          ...data,
          ...formConstants,
          hn,
          txn,
          patient_name: patientResponse.patient_name,
        }));
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        displayErrorFetchPopup();
      });
  });

  const fetchData = useCallback(
    async (formId, hn, txn) => {
      try {
        displayLoading();
        setDataForm({});
        let data = {};
        let hnValue, txnValue;
        if (formId) {
          data = await fetchConsultFormByFormId(formId, formConstants.formType);
          hnValue = data.hn;
          txnValue = data.txn;
        } else {
          data = await fetchConsultFormByTxn(txn, formConstants.formType);
          hnValue = hn;
          txnValue = txn;
        }
        await fetchOtherData(data, hnValue, txnValue);
      } catch (error) {
        console.error(error);
        hideLoading();
        displayErrorFetchPopup();
      }
    },
    [
      displayLoading,
      hideLoading,
      displayErrorFetchPopup,
      setDataForm,
      fetchOtherData,
    ]
  );

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null);
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn)
      fetchData(null, patient.hn, patient.txn);
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn);
  }, [hn, txn, formId]);

  function handleChange_data1(i, event) {
    if (event.target.value == "1") {
      setData2Disabled(true);
    } else {
      setData2Disabled(false);
    }

    handleChange(i, event);
  }

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const getADLAndIADLTotalScore = useMemo(() => {
    return utilSumTotalScore([dataForm.data_97n, dataForm.data_96n]);
  }, [dataForm.data_97n, dataForm.data_96n]);

  const onTagInputChange = useCallback(
    (fieldId, data) => {
      var valueData = [];
      if (data) {
        valueData = data.map((value, index) => ({
          key: index,
          value: value,
        }));
      }
      setDataForm((data) => ({ ...data, [fieldId]: valueData }));
    },
    [setDataForm]
  );

  useEffect(() => {
    console.log("dataForm", dataForm);
  }, [dataForm]);

  const onUploadFileInputChange = useCallback(
    (fieldId, files) => {
      setDataForm((data) => ({ ...data, [fieldId]: files }));
    },
    [setDataForm]
  );

  const handleCloseSubFormPopup = useCallback(() => {
    setShowFormPopup(false);
    setPopupFormType(undefined);
  }, [setShowFormPopup, setPopupFormType]);

  const handleSubFormDataChange = useCallback(
    (data, html, score, other) => {
      if (popupFormType == 30) {
        setPpsDataForm({ data: { ...data }, html });
      } else if (popupFormType == 2) {
        setEsasDataForm({ data: { ...data }, html });
      } else if (popupFormType == 3) {
        setPosDataForm({ data: { ...data }, html });
      } else if (popupFormType == 4) {
        setGoodDeathDataForm({ data: { ...data }, html });
      } else if (popupFormType == 5) {
        setTmseDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 44) {
        setAdlsDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 45) {
        setIAdlsDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 31) {
        setMocaDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 32) {
        setTwoQDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 33) {
        setEightQDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 34) {
        setNineQDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 39) {
        setTgdsDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 40) {
        setGad7DataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 36) {
        setZaritDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 37) {
        setDrugDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 35) {
        setFearDataForm({ data: { ...data }, html, score });
      } else if (popupFormType == 41) {
        if (other?.zaritDataForm?.data) {
          setZaritDataForm({
            data: { ...other?.zaritDataForm?.data },
            html: other?.zaritDataForm?.html,
            score: other?.zaritDataForm?.score,
          });
        }
        if (other?.adlsDataForm?.data) {
          setAdlsDataForm({
            data: { ...other?.adlsDataForm?.data },
            html: other?.adlsDataForm?.html,
            score: other?.adlsDataForm?.score,
          });
        }
        if (other?.iadlsDataForm?.data) {
          setIAdlsDataForm({
            data: { ...other?.iadlsDataForm?.data },
            html: other?.iadlsDataForm?.html,
            score: other?.iadlsDataForm?.score,
          });
        }
        if (other?.fearDataForm?.data) {
          setFearDataForm({
            data: { ...other?.fearDataForm?.data },
            html: other?.fearDataForm?.html,
            score: other?.fearDataForm?.score,
          });
        }
        setPostFallingDataForm({ data: { ...data }, html, score });
      }
      setShowFormPopup(false);
      setPopupFormType(undefined);
    },
    [
      setShowFormPopup,
      setPopupFormType,
      popupFormType,
      setPpsDataForm,
      setEsasDataForm,
      setPosDataForm,
      setGoodDeathDataForm,
      setTmseDataForm,
      setAdlsDataForm,
      setMocaDataForm,
      setTwoQDataForm,
      setNineQDataForm,
      setEightQDataForm,
      setTgdsDataForm,
      setGad7DataForm,
      setZaritDataForm,
      setDrugDataForm,
      setFearDataForm,
      setIAdlsDataForm,
    ]
  );

  useEffect(() => {
    if (ppsDataForm.data?.data_6n?.toString()) {
      setDataForm((data) => ({ ...data, data_13n: ppsDataForm.data?.data_6n }));
    }
  }, [ppsDataForm.data, setDataForm]);

  useEffect(() => {
    if (esasDataForm.html) {
      setDataForm((data) => ({ ...data, data_14: "ทำการประเมินแล้ว" }));
    }
  }, [esasDataForm.html, setDataForm]);

  useEffect(() => {
    if (posDataForm.html) {
      setDataForm((data) => ({ ...data, data_15: "ทำการประเมินแล้ว" }));
    }
  }, [posDataForm.html, setDataForm]);

  useEffect(() => {
    if (goodDeathDataForm.html) {
      setDataForm((data) => ({ ...data, data_16: "ทำการประเมินแล้ว" }));
    }
  }, [goodDeathDataForm.html, setDataForm]);

  useEffect(() => {
    if (!isNaN(tmseDataForm.score)) {
      setDataForm((data) => ({ ...data, data_37n: tmseDataForm.score }));
    }
  }, [tmseDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(adlsDataForm.score)) {
      setDataForm((data) => ({ ...data, data_96n: adlsDataForm.score }));
    }
  }, [adlsDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(iadlsDataForm.score)) {
      setDataForm((data) => ({ ...data, data_97n: iadlsDataForm.score }));
    }
  }, [iadlsDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(mocaDataForm.score)) {
      setDataForm((data) => ({ ...data, data_38n: mocaDataForm.score }));
    }
  }, [mocaDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(twoQDataForm.score)) {
      setDataForm((data) => ({ ...data, data_39n: twoQDataForm.score }));
    }
  }, [twoQDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(nineQDataForm.score)) {
      setDataForm((data) => ({ ...data, data_40n: nineQDataForm.score }));
    }
  }, [nineQDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(tgdsDataForm.score)) {
      setDataForm((data) => ({ ...data, data_41n: tgdsDataForm.score }));
    }
  }, [tgdsDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(gad7DataForm.score)) {
      setDataForm((data) => ({ ...data, data_42n: gad7DataForm.score }));
    }
  }, [gad7DataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(eightQDataForm.score)) {
      setDataForm((data) => ({ ...data, data_112n: eightQDataForm.score }));
    }
  }, [eightQDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(zaritDataForm.score)) {
      setDataForm((data) => ({ ...data, data_79n: zaritDataForm.score }));
    }
  }, [zaritDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(drugDataForm.score)) {
      setDataForm((data) => ({ ...data, data_80n: drugDataForm.score }));
    }
  }, [drugDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(fearDataForm.score)) {
      setDataForm((data) => ({ ...data, data_78n: fearDataForm.score }));
    }
  }, [fearDataForm.score, setDataForm]);

  useEffect(() => {
    if (postFallingDataForm.html) {
      setDataForm((data) => ({ ...data, data_78: "ทำการประเมินแล้ว" }));
    }
  }, [postFallingDataForm.score, setDataForm]);

  const validateSave = useCallback(() => {
    if (dataForm?.data_114 && dataForm?.data_114?.length > 0) {
      if (dataForm.data_114?.some((s) => s.is_edit)) {
        displayErrorValidatePopup(
          "เนื่องจากท่านบันทึก Drug Allergy ไม่สมบูรณ์"
        );
        return false;
      }
    }
    return true;
  }, [dataForm, displayErrorValidatePopup]);

  const handleSave = useCallback(
    (html) => {
      async function saveForm(paperHtml) {
        displayLoading();
        try {
          if (validateSave()) {
            const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);

            handlePreviewOpenChange(false);
            await saveConsultForm(
              saveData,
              profile.loginToken,
              dataForm.form_id,
              profile.consultId,
              profile.doctorId,
              paperHtml,
              patient
            );

            const subFormSaveRequest = [];

            const ppsSaveData = utilTransformDataBeforeSave(
              { ...ppsDataForm.data },
              []
            );
            if (ppsDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  ppsSaveData,
                  profile.loginToken,
                  ppsSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  ppsDataForm.html,
                  patient
                )
              );
            }

            const esasSaveData = utilTransformDataBeforeSave(
              { ...esasDataForm.data },
              []
            );
            if (esasDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  esasSaveData,
                  profile.loginToken,
                  esasSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  esasDataForm.html,
                  patient
                )
              );
            }

            const posSaveData = utilTransformDataBeforeSave(
              { ...posDataForm.data },
              []
            );
            if (posDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  posSaveData,
                  profile.loginToken,
                  posSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  posDataForm.html,
                  patient
                )
              );
            }

            const goodDeathSaveData = utilTransformDataBeforeSave(
              { ...goodDeathDataForm.data },
              []
            );
            if (goodDeathDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  goodDeathSaveData,
                  profile.loginToken,
                  goodDeathSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  goodDeathDataForm.html,
                  patient
                )
              );
            }

            const tmseSaveData = utilTransformDataBeforeSave(
              { ...tmseDataForm.data },
              []
            );
            if (tmseDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  tmseSaveData,
                  profile.loginToken,
                  tmseSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  tmseDataForm.html,
                  patient
                )
              );
            }

            const adlsSaveData = utilTransformDataBeforeSave(
              { ...adlsDataForm.data },
              []
            );
            if (!isNaN(adlsDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  adlsSaveData,
                  profile.loginToken,
                  adlsSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  adlsDataForm.html,
                  patient
                )
              );
            }

            const iadlsSaveData = utilTransformDataBeforeSave(
              { ...iadlsDataForm.data },
              []
            );
            if (!isNaN(iadlsDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  iadlsSaveData,
                  profile.loginToken,
                  iadlsSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  iadlsDataForm.html,
                  patient
                )
              );
            }

            const mocaSaveData = utilTransformDataBeforeSave(
              { ...mocaDataForm.data },
              []
            );
            if (!isNaN(mocaDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  mocaSaveData,
                  profile.loginToken,
                  mocaSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  mocaDataForm.html,
                  patient
                )
              );
            }

            const twoQSaveData = utilTransformDataBeforeSave(
              { ...twoQDataForm.data },
              []
            );
            if (!isNaN(twoQDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  twoQSaveData,
                  profile.loginToken,
                  twoQSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  twoQDataForm.html,
                  patient
                )
              );
            }

            const eightQSaveData = utilTransformDataBeforeSave(
              { ...eightQDataForm.data },
              []
            );
            if (!isNaN(eightQDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  eightQSaveData,
                  profile.loginToken,
                  eightQSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  eightQDataForm.html,
                  patient
                )
              );
            }

            const nineQSaveData = utilTransformDataBeforeSave(
              { ...nineQDataForm.data },
              []
            );
            if (!isNaN(nineQDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  nineQSaveData,
                  profile.loginToken,
                  nineQSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  nineQDataForm.html,
                  patient
                )
              );
            }

            const tgdsSaveData = utilTransformDataBeforeSave(
              { ...tgdsDataForm.data },
              []
            );
            if (!isNaN(tgdsDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  tgdsSaveData,
                  profile.loginToken,
                  tgdsSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  tgdsDataForm.html,
                  patient
                )
              );
            }

            const gad7SaveData = utilTransformDataBeforeSave(
              { ...gad7DataForm.data },
              []
            );
            if (!isNaN(gad7DataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  gad7SaveData,
                  profile.loginToken,
                  gad7SaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  gad7DataForm.html,
                  patient
                )
              );
            }

            const zaritSaveData = utilTransformDataBeforeSave(
              { ...zaritDataForm.data },
              []
            );
            if (!isNaN(zaritDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  zaritSaveData,
                  profile.loginToken,
                  zaritSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  zaritDataForm.html,
                  patient
                )
              );
            }

            const drugSaveData = utilTransformDataBeforeSave(
              { ...drugDataForm.data },
              []
            );
            if (!isNaN(drugDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  drugSaveData,
                  profile.loginToken,
                  drugSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  drugDataForm.html,
                  patient
                )
              );
            }

            const fearSaveData = utilTransformDataBeforeSave(
              { ...fearDataForm.data },
              []
            );
            if (!isNaN(fearDataForm.score)) {
              subFormSaveRequest.push(
                saveConsultForm(
                  fearSaveData,
                  profile.loginToken,
                  fearSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  fearDataForm.html,
                  patient
                )
              );
            }

            const postFallingSaveData = utilTransformDataBeforeSave(
              { ...postFallingDataForm.data },
              []
            );
            if (postFallingDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  postFallingSaveData,
                  profile.loginToken,
                  postFallingSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  postFallingDataForm.html,
                  patient
                )
              );
            }

            if (subFormSaveRequest.length > 0) {
              Promise.all([...subFormSaveRequest])
                .then(() => {
                  hideLoading();
                  onSuccessPopupOpen(true);
                })
                .catch((error) => {
                  hideLoading();
                  displayErrorSavePopup();
                });
            } else {
              hideLoading();
              onSuccessPopupOpen(true);
            }
          } else {
            hideLoading();
          }
        } catch (error) {
          hideLoading();
          displayErrorSavePopup();
        }
      }
      saveForm(html);
    },
    [
      dataForm,
      ppsDataForm,
      esasDataForm,
      posDataForm,
      goodDeathDataForm,
      tmseDataForm,
      adlsDataForm,
      iadlsDataForm,
      mocaDataForm,
      twoQDataForm,
      nineQDataForm,
      eightQDataForm,
      gad7DataForm,
      zaritDataForm,
      drugDataForm,
      validateSave,
      displayLoading,
      hideLoading,
      onSuccessPopupOpen,
      displayErrorSavePopup,
      patient,
    ]
  );

  const getSubFormProps = useMemo(() => {
    return {
      patient: patient,
      profile: profile,
      onLoading: onLoading,
      isSubForm: true,
      onAdd: handleSubFormDataChange,
      onErrorMessageChange: onErrorMessageChange,
    };
  }, [
    patient,
    profile,
    onLoading,
    handleSubFormDataChange,
    onErrorMessageChange,
  ]);

  const renderSubForm = useMemo(() => {
    if (popupFormType == 30) {
      return <FormPPS data={ppsDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 2) {
      return <FormESAS data={esasDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 3) {
      return <FormPosScale data={posDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 4) {
      return (
        <FormGoodDeath data={goodDeathDataForm.data} {...getSubFormProps} />
      );
    } else if (popupFormType == 5) {
      return <FormTMSE data={tmseDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 44) {
      return <FormADLS data={adlsDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 45) {
      return <FormIADLS data={iadlsDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 31) {
      return <FormMoca data={mocaDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 32) {
      return <Form2Q data={twoQDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 33) {
      return <Form8Q data={eightQDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 34) {
      return <Form9Q data={nineQDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 39) {
      return <FormTGDS data={tgdsDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 40) {
      return <FormGAD7 data={gad7DataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 36) {
      return <FormZarit12 data={zaritDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 37) {
      return (
        <FormDrugAdherence data={drugDataForm.data} {...getSubFormProps} />
      );
    } else if (popupFormType == 35) {
      return <FormFearFalling data={fearDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 41) {
      return (
        <FormPostFalling
          data={postFallingDataForm.data}
          zaritData={zaritDataForm.data}
          fearFallingData={fearDataForm.data}
          adlsData={adlsDataForm.data}
          iadlsData={iadlsDataForm.data}
          {...getSubFormProps}
        />
      );
    } else {
      return <>ไม่พบข้อมูล</>;
    }
  }, [
    popupFormType,
    getSubFormProps,
    ppsDataForm.data,
    esasDataForm.data,
    posDataForm.data,
    goodDeathDataForm.data,
    tmseDataForm.data,
    adlsDataForm.data,
    mocaDataForm.data,
    twoQDataForm.data,
    nineQDataForm.data,
    eightQDataForm.data,
    tgdsDataForm.data,
    gad7DataForm.data,
    zaritDataForm.data,
    drugDataForm.data,
    fearDataForm.data,
    postFallingDataForm.data,
    iadlsDataForm.data,
  ]);

  function doShowPic(name, code, howTo, amt) {
    let url =
      "/consult/phamacyImage?mode=iviewer&" +
      encodeURIComponent(
        "name=" +
          name +
          "&code=" +
          code +
          "&howTo=" +
          JSON.stringify(howTo) +
          "&amt=" +
          amt +
          "&patientName=" +
          dataForm.patient_name +
          "&hn=" +
          dataForm.hn
      );

    window.open(url, "imagePhama").focus();
  }

  const handleData114ValueChange = useCallback(
    (fieldName, dataIndex, event) => {
      if (typeof dataForm.data_114 == "undefined") {
        return;
      }
      if (event != null && typeof event.target != "undefined") {
        dataForm.data_114[dataIndex][fieldName] = event.target.value;
        setDataForm((data) => ({ ...data, data_114: dataForm.data_114 }));
      } else {
        dataForm.data_114[dataIndex][fieldName] = event;
        setDataForm((data) => ({ ...data, data_114: dataForm.data_114 }));
      }
    },
    [dataForm, setDataForm]
  );

  const addData114 = useCallback(() => {
    if (dataForm?.data_114 && dataForm?.data_114?.length > 0) {
      console.log("if");
      const tempData = dataForm.data_114;
      tempData.push({
        group: "",
        sym: "",
        date_add: dateFormat(new Date(), "yyyy-mm-dd"),
        is_edit: true,
      });
      setDataForm((data) => ({ ...data, data_114: tempData }));
    } else {
      console.log("else");
      setDataForm((data) => ({
        ...data,
        data_114: [
          {
            group: "",
            sym: "",
            date_add: dateFormat(new Date(), "yyyy-mm-dd"),
            is_edit: true,
          },
        ],
      }));
    }
    setIsEditingData114(true);
  }, [setIsEditingData114, dataForm, setDataForm]);

  const editData114 = useCallback(
    (index) => {
      dataForm.data_114?.forEach((item) => {
        delete item.is_edit;
      });
      const data = dataForm.data_114[index];
      data.is_edit = true;
      dataForm.data_114[index] = data;
      setDataForm((data) => ({ ...data, data_114: dataForm.data_114 }));
      setIsEditingData114(true);
    },
    [setIsEditingData114, dataForm, setDataForm]
  );

  const deleteData114 = useCallback(
    (index) => {
      dataForm.data_114.splice(index, 1);
      setDataForm((data) => ({ ...data, data_114: dataForm.data_114 }));
    },
    [dataForm, setDataForm]
  );

  const finishData114 = useCallback(
    (index) => {
      const data = dataForm.data_114[index];
      if (data.is_edit) delete data.is_edit;
      dataForm.data_114[index] = data;
      setDataForm((data) => ({ ...data, data_114: dataForm.data_114 }));
      setIsEditingData114(false);
    },
    [setIsEditingData114, dataForm, setDataForm]
  );

  const getDataFileList = useCallback(() => {
    async function fetchDataFileList(txn) {
      if (txn) {
        var result = await fetchFormFileListByTxn(dataForm.txn);
        setDataFilesList(result);
      }
    }
    fetchDataFileList(dataForm.txn);
  }, [dataForm?.txn]);

  return (
    <div
      uk-grid=""
      className="uk-grid-small uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "hidden" }}
    >
      <div className=" uk-flex uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1 ">
          <FormElementTitle label="HOME VISIT RECORD FORM (CMU 2023)" />
        </div>
        <div className="uk-flex uk-width-1-3@m uk-width-1-2@l uk-width-1-1  uk-flex-right">
          {dataForm.txn ? (
            <>
              <ShowFilesList
                dataForm={dataForm}
                txn={patient.txn}
              />
              <SendFiles dataForm={dataForm} getData={getDataFileList} />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className="uk-width-1-1@m uk-width-1-1"
        style={{ paddingLeft: "24px" }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementCheckBox
                    fieldId={"1"}
                    value={dataForm.data_1}
                    onChange={handleChange}
                    label="Virtual OPD"
                  />
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_1 != "1"}
                >
                  Method:
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_1 != "1"}
                >
                  <FormElementRadio
                    fieldId={"90"}
                    value={dataForm.data_90}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Video call"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_1 != "1"}
                >
                  <FormElementRadio
                    fieldId={"90"}
                    value={dataForm.data_90}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Phone call"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementCheckBox
                    fieldId={"89"}
                    value={dataForm.data_89}
                    onChange={handleChange}
                    label="Home visit"
                  />
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  Types:
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementRadio
                    fieldId={"91"}
                    value={dataForm.data_91}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Transitional case"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementRadio
                    fieldId={"91"}
                    value={dataForm.data_91}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Continuous case"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                  hidden={dataForm.data_91 != "2"}
                >
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="General Fammed"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                  hidden={dataForm.data_91 != "2"}
                >
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Palliative"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  Methods
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementCheckBox
                    fieldId={"93"}
                    value={dataForm.data_93}
                    onChange={handleChange}
                    label="Tele"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_93 != "1" || dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                >
                  <FormElementRadio
                    fieldId={"95"}
                    value={dataForm.data_95}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Video call"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_93 != "1" || dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                  hidden={dataForm.data_93 != "1"}
                >
                  <FormElementRadio
                    fieldId={"95"}
                    value={dataForm.data_95}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Phone call"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementCheckBox
                    fieldId={"94"}
                    value={dataForm.data_94}
                    onChange={handleChange}
                    label="On-site"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <div className="dietz-input-container">
                <span
                  className="formTextBoxLabel"
                  style={{ paddingTop: "8px" }}
                >
                  Objective:
                </span>
                <FormElementTagInput
                  value={
                    dataForm.data_2
                      ? dataForm.data_2.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_2", data)}
                  dataList={objectiveList}
                  placeholder="ระบุ"
                />
              </div>
            </div>
            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <div className="dietz-input-container">
                <span className="formTextBoxLabel ">Problem List:</span>
                <div style={{ display: "inline-flex", gap: "8px" }}>
                  <FormElementCheckBox
                    fieldId={"104"}
                    label="HTN"
                    magicRadio
                    value={dataForm.data_104}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"105"}
                    label="DLP"
                    magicRadio
                    value={dataForm.data_105}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"106"}
                    label="DM"
                    magicRadio
                    value={dataForm.data_106}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"107"}
                    label="CVA"
                    magicRadio
                    value={dataForm.data_107}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"108"}
                    label="HD"
                    magicRadio
                    value={dataForm.data_108}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"109"}
                    label="COPD"
                    magicRadio
                    value={dataForm.data_109}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"110"}
                    label="OA"
                    magicRadio
                    value={dataForm.data_110}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"111"}
                    label="Dementia"
                    magicRadio
                    value={dataForm.data_111}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementTextArea
                fieldId={"3"}
                rows={5}
                cols={50}
                value={dataForm.data_3}
                onChange={handleChange}
                noLimit
              />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <FormElementLabel label="Current Medication:" />
              <div className="uk-width-1-1@m uk-width-1-1">
                <div
                  className="form-table-container"
                  style={{ maxWidth: `${tableMaxWidth}px` }}
                >
                  <div className="form-table-scroll">
                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th style={{ whiteSpace: "nowrap" }}>ชื่อยา</th>
                          <th style={{ width: "400px" }}>วิธีใช้</th>
                          <th>จำนวน</th>
                          <th style={{ whiteSpace: "nowrap" }}>
                            สถานะการปรับเปลี่ยนยา
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {drugList.length === 0 ? (
                          <tr>
                            <td colSpan="100%">
                              <div className="uk-padding-small uk-text-center">
                                ไม่พบข้อมูล
                              </div>
                            </td>
                          </tr>
                        ) : (
                          drugList.map((data, key) => {
                            const howToDesc = {
                              sig: data.sig,
                              sigadd: data.sigadd,
                              signote: data.signote,
                            };
                            return (
                              <tr key={`row${data.code}`}>
                                <td>{key + 1}</td>
                                <td>
                                  <a
                                    onClick={(e) =>
                                      doShowPic(
                                        data.name,
                                        data.code,
                                        howToDesc,
                                        data.amt
                                      )
                                    }
                                  >
                                    {data.name}
                                  </a>
                                </td>
                                <td>
                                  <p>{data.sig}</p>
                                  <p>{data.sigadd}</p>
                                  <p>{data.signote}</p>
                                </td>
                                <td>{data.amt}</td>
                                <td></td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
              <FormElementTextArea
                fieldId={"5"}
                rows={5}
                cols={50}
                value={dataForm.data_5}
                onChange={handleChange}
                noLimit
              />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementLabel label="Drug Allergy:" />
              <div
                className="form-table-container"
                style={{ maxWidth: `${tableMaxWidth}px` }}
              >
                <div className="form-table-scroll">
                  <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                    <thead>
                      <tr>
                        <th>Group</th>
                        <th>Sym</th>
                        <th>Date_add</th>
                        <th style={{ width: "120px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {drugAllergics?.map((item) => (
                        <tr>
                          <td>{item.group}</td>
                          <td>{item.sym}</td>
                          <td>
                            <FormElementTextBox
                              value={item.date_add}
                              type="date"
                              onChange={(e) => {}}
                              disabled
                            />
                          </td>
                          <td></td>
                        </tr>
                      ))}
                      {dataForm?.data_114?.map((item, index) => (
                        <tr>
                          <td>
                            {!item.is_edit ? (
                              item.group
                            ) : (
                              <FormElementTextBox
                                value={item.group}
                                onChange={(i, e) =>
                                  handleData114ValueChange("group", index, e)
                                }
                              />
                            )}
                          </td>
                          <td>
                            {!item.is_edit ? (
                              item.sym
                            ) : (
                              <FormElementTextArea
                                value={item.sym}
                                onChange={(i, e) =>
                                  handleData114ValueChange("sym", index, e)
                                }
                                cols={80}
                                rows={2}
                                noLimit
                              />
                            )}
                          </td>
                          <td>
                            <FormElementTextBox
                              value={item.date_add}
                              type="date"
                              onChange={(i, e) =>
                                handleData114ValueChange("date_add", index, e)
                              }
                              disabled={!item.is_edit}
                            />
                          </td>
                          <td>
                            <div
                              style={{
                                display: "inline-flex",
                                gap: "8px",
                              }}
                            >
                              {!isEditingData114 && (
                                <FormElementIconButton
                                  icon="pencil"
                                  sizeRatio={1}
                                  onClick={(e) => editData114(index)}
                                />
                              )}
                              {!isEditingData114 && (
                                <FormElementIconButton
                                  icon="trash"
                                  sizeRatio={1}
                                  onClick={(e) => deleteData114(index)}
                                />
                              )}
                              {item.is_edit && (
                                <FormElementIconButton
                                  icon="check"
                                  sizeRatio={1}
                                  onClick={(e) => finishData114(index)}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "14px",
                      marginTop: "-8px",
                      paddingLeft: "4px",
                    }}
                  >
                    <FormElementButton
                      label="เพิ่ม"
                      onClick={addData114}
                      disabled={isEditingData114}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementDropdown
                fieldId={"10"}
                value={dataForm.data_10}
                onChange={handleChange}
                dataList={vaccineList}
                label="Vaccination: "
              />
              <span
                className={
                  dataForm.data_10 == "3" ? "form-show" : "form-hidden"
                }
              >
                <span className="formTextBoxLabel">
                  <FormElementTextBox
                    fieldId={"11"}
                    value={dataForm.data_11}
                    onChange={handleChange}
                    width="100"
                    placeholder="ระบุ"
                  />
                </span>
              </span>
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label=" Palliative care" />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1.1 &nbsp;&nbsp; Advance care plan &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"98"}
                value={dataForm.data_98}
                fieldValue={"1"}
                onChange={handleChange}
                label="มี"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"98"}
                value={dataForm.data_98}
                fieldValue={"0"}
                onChange={handleChange}
                label="ไม่มี"
              />
            </div>
            <div
              className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top"
              style={{ paddingLeft: "40px" }}
            >
              DNR &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"99"}
                value={dataForm.data_99}
                fieldValue={"1"}
                onChange={handleChange}
                label="Full"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"99"}
                value={dataForm.data_99}
                fieldValue={"2"}
                onChange={handleChange}
                label="Other"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"100"}
                value={dataForm.data_100}
                onChange={handleChange}
                width="100"
                placeholder="ระบุ"
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1.2 &nbsp;&nbsp; Living will &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"101"}
                value={dataForm.data_101}
                fieldValue={"1"}
                onChange={handleChange}
                label="มี"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"101"}
                value={dataForm.data_101}
                fieldValue={"0"}
                onChange={handleChange}
                label="ไม่มี"
              />
            </div>
            <div
              className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top"
              style={{ paddingLeft: "42px" }}
            >
              DNR &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"102"}
                value={dataForm.data_102}
                fieldValue={"1"}
                onChange={handleChange}
                label="Full"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"102"}
                value={dataForm.data_102}
                fieldValue={"2"}
                onChange={handleChange}
                label="Other"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"103"}
                value={dataForm.data_103}
                onChange={handleChange}
                width="100"
                placeholder="ระบุ"
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              2. &nbsp;&nbsp;{" "}
              <FormElementTextBox
                fieldId={"13n"}
                value={dataForm.data_13n}
                disabled
                onChange={handleChange}
                label="PPS"
                width="50"
              />
              <FormElementButton label="PPS" onClick={doPPS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              3. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"14"}
                value={dataForm.data_14}
                disabled
                onChange={handleChange}
                label="ESAS"
                width="50"
              />
              <FormElementButton label="ESAS" onClick={doESAS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              4. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"15"}
                value={dataForm.data_15}
                disabled
                onChange={handleChange}
                label="POS"
                width="50"
              />
              <FormElementButton label="POS" onClick={doPOS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              5. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"16"}
                value={dataForm.data_16}
                disabled
                onChange={handleChange}
                label="Good death"
                width="50"
              />
              <FormElementButton label="Good death" onClick={doGoodDeath} />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementDropdown
                fieldId={"7"}
                dataList={[
                  {
                    label: "Select functional assessment",
                    value: "",
                  },
                  {
                    label: "Bed-ridden",
                    value: "1",
                  },
                  {
                    label: "Home bound",
                    value: "2",
                  },
                  {
                    label: "Restriction",
                    value: "3",
                  },
                ]}
                label="Functional assessment"
                value={dataForm.data_7}
                onChange={handleChange}
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementTextBox
                fieldId={"96n"}
                value={dataForm.data_96n}
                onChange={handleChange}
                label=""
                width="50"
                disabled
              />
              <FormElementButton label="ADLS" onClick={doADLS} />
              &nbsp;&nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"97n"}
                value={dataForm.data_97n}
                onChange={handleChange}
                label=""
                width="50"
                disabled
              />
              <FormElementButton label="IADLS" onClick={doIADLS} />
              &nbsp;&nbsp;&nbsp; คะแนนรวม: {getADLAndIADLTotalScore}
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label=" Psychology assessment" />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"37n"}
                value={dataForm.data_37n}
                onChange={handleChange}
                label="TMSE"
                width="50"
                disabled
              />
              <FormElementButton label="TMSE" onClick={doTMSE} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              2. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"38n"}
                value={dataForm.data_38n}
                onChange={handleChange}
                label="MOCA"
                width="50"
                disabled
              />
              <FormElementButton label="MOCA" onClick={doMOCA} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              3. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"39n"}
                value={dataForm.data_39n}
                onChange={handleChange}
                label="2Q"
                width="50"
                disabled
              />
              <FormElementButton label="2Q" onClick={do2Q} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              4. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"40n"}
                value={dataForm.data_40n}
                onChange={handleChange}
                label="PHQ-9"
                width="50"
                disabled
              />
              <FormElementButton label="PHQ-9" onClick={do9Q} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              5. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"112n"}
                value={dataForm.data_112n}
                onChange={handleChange}
                label="PHQ-8"
                width="50"
                disabled
              />
              <FormElementButton label="PHQ-8" onClick={do8Q} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              6. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"41n"}
                value={dataForm.data_41n}
                onChange={handleChange}
                label="TGDS"
                width="50"
                disabled
              />
              <FormElementButton label="TGDS" onClick={doTGDS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              7. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"42n"}
                value={dataForm.data_42n}
                onChange={handleChange}
                label="GAD-7"
                width="50"
                disabled
              />
              <FormElementButton label="GAD-7" onClick={doGAD7} />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label="Other assessment tools" />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"78"}
                value={dataForm.data_78}
                onChange={handleChange}
                label="Post-falling assessment"
                width="50"
                disabled
              />
              <FormElementButton
                label="Post-falling assessment"
                onClick={doPostFalling}
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              2. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"78n"}
                value={dataForm.data_78n}
                onChange={handleChange}
                label="Fear of Falling"
                width="50"
                disabled
              />
              <FormElementButton label="Fear of Falling" onClick={doFear} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              3. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"79n"}
                value={dataForm.data_79n}
                onChange={handleChange}
                label="Zarit-12"
                width="50"
                disabled
              />
              <FormElementButton label="Zarit-12" onClick={doZarit} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              4. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"80n"}
                value={dataForm.data_80n}
                onChange={handleChange}
                label="Drug adherence"
                width="50"
                disabled
              />
              <FormElementButton label="Drug adherence" onClick={doDrug} />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <table className="uk-table uk-table-small uk-table-divider form-table">
                <tbody>
                  <tr>
                    <th
                      className="center"
                      rowSpan={3}
                      style={{ width: "80px" }}
                    >
                      PE
                    </th>
                    <td className="center" rowSpan={3}>
                      V/S
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"44"}
                          value={dataForm.data_44}
                          onChange={handleChange_data1}
                          label="BT"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"45"}
                          value={dataForm.data_45}
                          onChange={handleChange_data1}
                          label="BP1"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"46"}
                          value={dataForm.data_46}
                          onChange={handleChange_data1}
                          label="BP2"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"47"}
                          value={dataForm.data_47}
                          onChange={handleChange_data1}
                          label="HR"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"48"}
                          value={dataForm.data_48}
                          onChange={handleChange_data1}
                          label="RR"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"49"}
                          value={dataForm.data_49}
                          onChange={handleChange_data1}
                          label="O2sat"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">GA</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"50"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_50}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">HEENT</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"51"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_51}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Heart</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"52"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_52}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Lung</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"53"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_53}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Abd</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"54"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_54}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Ext</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"55"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_55}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Neuro</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"56"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_56}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Other</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"57"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_57}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementUploadImage
                          value={dataForm.data_113}
                          onChange={(files) =>
                            onUploadFileInputChange("data_113", files)
                          }
                          maxLength={6}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label="Pain" />
              <PainSelectorSection
                data={dataForm.data_115}
                onChange={(value) =>
                  setDataForm((data) => ({ ...data, data_115: value }))
                }
                onCaptureImage={(img) => setPainImage(img)}
              />
            </div>
          </div>
          <FormElementLine />

          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
              <table className="uk-table uk-table-small uk-table-divider form-table">
                <thead>
                  <tr>
                    <th colSpan={2}>ILLNESS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Idea</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"29"}
                        value={dataForm.data_29}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Feeling</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"30"}
                        value={dataForm.data_30}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Function</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"31"}
                        value={dataForm.data_31}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Expectation</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"32"}
                        value={dataForm.data_32}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <table className="uk-table uk-table-small uk-table-divider form-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Assessment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nutrition:</td>
                    <td>
                      <FormElementTextArea
                        fieldId={"81"}
                        value={dataForm.data_81}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Exercise:</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"82"}
                        value={dataForm.data_82}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Home environment (Safety):</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"83"}
                        value={dataForm.data_83}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Spiritual:</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"84"}
                        value={dataForm.data_84}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Caregiver:</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"85"}
                        value={dataForm.data_85}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel
                label="Problem List and Plan of Management (SOAP)"
                fontWeight="bold"
              />
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                <SOAPSection
                  data={dataForm.data_116}
                  onChange={(value) =>
                    setDataForm((data) => ({ ...data, data_116: value }))
                  }
                />
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel label="Follow up" fontWeight="bold" />
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                <FormElementTextArea
                  rows={1}
                  cols={80}
                  fieldId={"34"}
                  value={dataForm.data_34}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                  noLimit
                />
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel label="Plan for next HV" fontWeight="bold" />
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top uk-padding-remove-left">
                <FormElementTextArea
                  rows={3}
                  cols={80}
                  fieldId={"35"}
                  value={dataForm.data_35}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                  noLimit
                />
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top uk-padding-remove-left">
                <FormElementTextBox
                  fieldId={"36"}
                  value={dataForm.data_36}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Medical Student/Physician"
                  width="50"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={
          <FormFammedPaper
            dataForm={dataForm}
            patient={patient}
            profile={profile}
            drugAllergics={drugAllergics}
            drugList={drugList}
            painImage={painImage}
          />
        }
      />
      <FormPopup show={showFormPopup} onClose={handleCloseSubFormPopup}>
        {renderSubForm}
      </FormPopup>
    </div>
  );
};

export default FormFammed;
