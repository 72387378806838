import React, { useState, useEffect, useMemo } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormInhalerPaper = (props) => {
  const { dataForm, patient, profile, nxOpdDropDownList } = props;
  var [nxOpds, setNxOpds] = useState({});

  useEffect(() => {
    let result = {};
    nxOpdDropDownList?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setNxOpds(result)
  }, [nxOpdDropDownList, setNxOpds]);

  const getTotalScore = useMemo(() => {
    if (dataForm) {
      let rawScore = utilSumTotalScore([
        dataForm.data_1n,
        dataForm.data_2n,
        dataForm.data_3n,
        dataForm.data_4n,
        dataForm.data_5n,
        dataForm.data_6n,
        dataForm.data_7n,
      ]);

      return ((rawScore * 100) / 7).toFixed(2);
    }
    return null;
  }, [dataForm]);

  const getScoreDesc = useMemo(() => {
    if (!getTotalScore) {
      return "";
    } else if (getTotalScore >= 80) {
      return "ผ่านเกณฑ์";
    } else {
      return "ไม่ผ่านเกณฑ์";
    }
  }, [getTotalScore]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="3.2.3 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยาพ่นสูด Controller/Reliever"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <div>
            <FormElementTextBox
              label="วิธีการให้คำแนะนำยา"
              value={dataForm.data_38}
              mode="view"
            />
          </div>
          <FormElementTitle label="รูปแบบยาพ่น" mode="view" />
          <div style={{ display: "inline-flex", gap: "16px" }}>
            {dataForm.data_18 == "1" && <span>MDI</span>}
            {dataForm.data_19 == "1" && <span>Turbuhaler</span>}
            {dataForm.data_20 == "1" && <span>Respimat</span>}
            {dataForm.data_21 == "1" && <span>Accuhaler</span>}
            {dataForm.data_22 == "1" && <span>Breezhaler</span>}
            {dataForm.data_23 == "1" && <span>Ellipta</span>}
            {dataForm.data_24 == "1" && <span>Handihaler</span>}
            {dataForm.data_36 == "1" && <span>Rapihaler</span>}
          </div>
          <table
            style={{
              marginTop: "16px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                    rowSpan={3}
                  >
                    หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                    colSpan={3}
                  >
                    ประเมินครั้งที่ {dataForm.data_no}
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_17}
                      mode="view"
                      dateFormat="dd/MM/yyyy"
                      type="date"
                    />
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ติดตามครั้งถัดไป
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                    rowSpan={2}
                  >
                    หมายเหตุ
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ทราบ
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ไม่ทราบ
                  </th>
                </tr>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1. ผู้ป่วยทราบข้อบ่งใช้และชนิดของยาพ่นสูดที่ได้รับ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_8} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2. ผู้ป่วยทราบขนาดยาที่ได้รับในครั้งนี้
                  และทราบวิธีปฏิบัติตัวเมื่อลืมพ่นยา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_9} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3. ผู้ป่วยใช้ยาได้ถูกต้องตามขั้นตอนการใช้อุปกรณ์นั้นๆ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "1" && "/"}
                </td>
                <td>{dataForm.data_3n == "0" && "/"}</td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_10} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4. ผู้ป่วยทราบอาการไม่พึงประสงค์และวิธีการป้องกัน
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_11} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5. ผู้ป่วยทราบวิธีการตรวจสอบปริมาณยาคงเหลือ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_12} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6. ผู้ป่วยทราบวิธีทำความสะอาดอุปกรณ์ และวิธีการเก็บรักษา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_13} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7. ผู้ป่วยทราบอาการที่ต้องมาพบแพทย์ก่อนนัด
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_14} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนนรวม
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ({getTotalScore}%) {getScoreDesc}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผู้รับการประเมิน (ผู้ป่วย/ญาติ)
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementTextBox value={dataForm.data_35} mode="view" />
                    <FormElementLabel
                      value={dataForm.data_15}
                      mode="view"
                      translate={{ 1: "ผู้ป่วย", 2: "ญาติ" }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เภสัชกรผู้ประเมิน
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_16} mode="view" />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <FormElementTitle label="การนัดหมายครั้งถัดไป" mode="view" />
            <FormElementLabel label="ห้องตรวจ" mode="view" />
            <FormElementLabel
              value={dataForm.data_32}
              mode="view"
              translate={nxOpds}
            />
            <FormElementTextBox
              value={dataForm.data_33}
              mode="view"
              type="date"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <article>
              <FormElementTitle
                label='คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥ 6 คะแนน) จึงถือว่า "ผ่านเกณฑ์"'
                mode="view"
              />
            </article>
            <article>
              <FormElementTitle
                label="หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ"
                mode="view"
              />
            </article>
          </div>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormInhalerPaper;
