import React from 'react';

const FormElementTableGroupLevel = ({data}) => {

  function TableGroupLevel({data}) {
    var contentItem = [];

    if(data.contentItem) {
      contentItem = data.contentItem;
    }

    return (
      <span>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th rowSpan={2}>{ data.label[0] }</th>
              <th rowSpan={2}>{ data.label[1] }</th>
              <th colSpan={5}>{ data.labelGroup }</th>
            </tr>
            <tr>
              <th>{ data.label[2] }</th>
              <th>{ data.label[3] }</th>
              <th>{ data.label[4] }</th>
              <th>{ data.label[5] }</th>
              <th>{ data.label[6] }</th>
              <th>{ data.label[7] }</th>
            </tr>
          </thead>
          <tbody>
          {

            contentItem.map((data, key)=>{
                return (
                  <tr key={key}>
                    <td>{ data.label }</td>
                    <td><input type='radio' /></td>
                    <td><input type='radio' /></td>
                    <td><input type='radio' /></td>
                    <td><input type='radio' /></td>
                    <td><input type='radio' /></td>
                    <td><input type='radio' /></td>
                  </tr>
                )
            })

          }
          </tbody>
        </table>

      </span>
    );
  }

  if(data.style == "inline") {
    return <TableGroupLevel data={data} />;

  } else {
    return (
      <div className="uk-width-1-1@m uk-width-1-1">
          <TableGroupLevel data={data} />
      </div>
    )
  }
}

export default FormElementTableGroupLevel;
