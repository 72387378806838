import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx"; ถ้าจะใช้ style ต้องสมัคร pro
import * as XLSX from "xlsx-js-style";
import moment from "moment"

export {
    formStroke,
    formNursingAssessment,
    formACSCaringSet,
    formACSFollowUP,
    formHeartFailure,
    formCathLabDischarge,
    formHeartFailureDischargeCheckList,
    formMinnesotaLivingWithHeartFailure,
    formHeartFailureClinic,
    formHeartFailureOPD
}
//แปลง data ตาม schema ที่กำหนดไว้
const translateValue = (key, schema) => {
    return schema[key]
}
//แบ่งวันกับเวลา
const splitDateTime = (date) => {
    const splitDate = date.split(' ');
    const dateformat = moment(splitDate[0]).add(543, 'year').format('DD-MM-YYYY')
    splitDate[0] = dateformat
    return splitDate
}
//ดึงแค่ value มาใช้
const mapValue = (data) => {
    const value = data ? JSON.parse(data).map((item) => item.value) : []
    return value
}
//รวมหลายๆช้อยที่เลือกไว้ที่เดียว
const mergeData = (data, schema) => {
    var newdata = []
    if (data) {
        data.forEach((item, index) => {
            if (item && item == '1') {
                newdata.push(schema[index + 1])
            }
        })
        return newdata
    } else {
        return ""
    }
}
const getFallBackCheckboxNull = (data) => {
    if (data == "1") {
        return data;
    }
    return null;
}

const setDateFormat = (date) => {
    var dateformat = ""
    const isDateTime = moment(date, 'YYYY-MM-DD HH:mm:ss', true).isValid();
    if (date) {
        if (isDateTime) {
            dateformat = moment(date).add(543, 'year').format('DD-MM-YYYY HH:mm:ss')
        } else {
            dateformat = moment(date).add(543, 'year').format('DD-MM-YYYY')
        }
    }
    return dateformat
}
const setWorkSheet = (header, labelForm, value) => {
    //สร้าง worksheet
    //ให้เริ่มที่ A3 และไม่ต้องใส่ header (field) 
    const ws = XLSX.utils.json_to_sheet([labelForm], { origin: 'A3', skipHeader: true });
    //เพิ่ม header เริ่มที่ A1
    XLSX.utils.sheet_add_json(ws, header, { origin: 'A1' });
    //กำหนดขนาดข้อมูลที่จะแสดง
    const lastRow = 3 + value.length; // 3 มาจากแถวเริ่มต้นที่ A3
    // กำหนดค่า !ref โดยใช้เซลล์แรกถึงเซลล์สุดท้ายที่มีข้อมูล
    ws['!ref'] = `A1:HH${lastRow}`;

    //กำหนด style
    const styleHeader = {
        fill: {
            fgColor: {
                rgb: "C0504D"
            }
        },
        font: {
            color: {
                rgb: "FFFFFF"
            }
        }
    }

    const styleLable = {
        fill: {
            fgColor: {
                rgb: "538DD5"
            }
        },
        font: {
            color: {
                rgb: "FFFFFF"
            }
        }//other prop
    }
    // กำหนดความกว้างเริ่มต้นของ column
    var wscols = []
    for (var key in ws) {
        if (key != "!ref") {
            if (!(key === 'A2' || key === 'B2' || key === 'C2')) {
                ws[key].s = styleLable
            }
            wscols.push({ wch: 12 })
        }
    }
    ws['!cols'] = wscols;

    //set style
    ws['A1'].s = styleHeader
    ws['B1'].s = styleHeader
    ws['C1'].s = styleHeader

    return ws
}
const setWorkBook = (ws) => {
    const wb = XLSX.utils.book_new();
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    //เพิ่ม ws ใส่ wb โดยใช้ชื่อ 'Sheet1'
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //แปลง wb เป็น excel
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //สร้าง blob obj (เก็บข้อมูลในรูปแบบ binary) จาก excelBuffer
    const dataExport = new Blob([excelBuffer], { type: fileType });
    return dataExport
}

const genColKey = (n) => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const colKey = [];

    for (let i = 0; i < n; i++) {
        const round = Math.floor(i / 26);
        const rest = i % 26;
        let key = "";
        if (round) {
            key = `${letters[round - 1]}${letters[rest]}`;
        } else {
            key = letters[i];
        }
        colKey.push(key);
    }
    return colKey;
};

const formStroke = (value, filter) => {
    try {
        //กำหนด label ในการแปลง
        const schemaDiag = {
            1: "Large artery atherosclerosis",
            2: "Cardioembolic stroke",
            3: "Lacunar stroke",
            4: "Stroke of other determined etiology",
            5: "Stroke of undetermined etiology",
        };
        const schema15 = {
            1: "Oral",
            2: "Tube",
        };
        const schema16 = {
            "": "Normal",
            null: "Normal",
            undefined: "Normal",
            1: "Normal",
            2: "Catheter",
        };

        const schemaControl = {
            1: "Controlled",
            2: "Uncontrolled",
        };
        const schema2 = {
            "Y": "Yes",
            "N": "No"
        }
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }

        //กำหนดหัวข้อและข้อมูลในแถวแรกสุด
        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];
        //กำหนดหัวข้อทั้งหมดของฟอร์ม
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา", "Stroke onset", "rtPA", "Mechanical thrombectomy", "Diagnosis", "รายละเอียดเพิ่มเติม",
            "Clinical Diagnosis", "1st F/U", "Date", "MRS", "BI", "PHQ9", "Feeding", "Voiding",
            "HTN", "DM", "AF", "Others"
        ]

        const ws = setWorkSheet(header, labelForm, value)

        // เพิ่มข้อมูล
        // loop value ทั้งหมด
        value.forEach((data, index) => {
            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4
            //แยกวันกับเวลา
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);
            var dataForm = {}
            var data_26 = []
            if (data.data_26) {
                JSON.parse(data.data_26).map((value, key) => {
                    data_26.push(value);
                })
            }
            // var data_25 = []
            // if (data.data_25) {
            //     var cleanedData_25 = JSON.parse(data.data_25.replace(/\\"/g, '"').replace(/"{/g, '{').replace(/}"/g, '}'))
            //     cleanedData_25.map((value) => {
            //         data_25.push(value.label)
            //     });
            // }

            const values = [
                { v: data.member_name },//"ชื่อผู้ป่วย"   
                { v: data.hn },//"hn" 
                { v: data.txn },//"txn" 
                { v: translateValue(data.member_gender, gender) },//"เพศ"
                { v: birthday[0] },//"วันเกิด"
                { v: data.consult_name },//"ชื่อผู้บันทึก"
                { v: created_date[0] },//"วันที่บันทึก"
                { v: created_date[1] },//"เวลา"
                { v: setDateFormat(data.data_1) },//"Stroke onset"
                { v: translateValue(data.data_2, schema2) },//"rtPA"
                { v: translateValue(data.data_3, schema2) },//"Mechanical thrombectomy"
                { v: translateValue(data.data_4, schemaDiag) },//"Diagnosis"
                { v: data.data_5 || data.data_6 || data.data_7 || data.data_8 || data.data_9 },//"รายละเอียดเพิ่มเติม"   
                { v: data_26 },//"Clinical Diagnosis"
                { v: data.data_10 },//"1st F/U"
                { v: setDateFormat(data.data_11) },//"Date"
                { v: data.data_12n },//"MRS"
                { v: data.data_13n },//"BI"
                { v: data.data_14n },//"PHQ9"
                { v: translateValue(data.data_15, schema15) },//"Feeding"
                { v: translateValue(data.data_16, schema16) },//"Voiding"           
                { v: translateValue(data.data_18, schemaControl) },//"HTN"
                { v: translateValue(data.data_20, schemaControl) },//"DM"
                { v: translateValue(data.data_22, schemaControl) },//"AF"
                { v: data.data_24 },//"Others"
            ]

            console.log('data excel', values);

            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }

        });
        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }
    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}
const formNursingAssessment = async (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema1 = { 1: "ดีมาก", 2: "ดี", 3: "แย่", 4: "อื่น ๆ" }
        const schema2 = { 1: "พึงพอใจ", 2: "ไม่พึงพอใจ" }
        const schema4 = {
            1: "ไปรพ. /คลินิก",
            2: "ซื้อยารับประทานเอง",
            3: "อื่น ๆ",
        }
        const schema6 = {
            1: "ปฏิบัติสม่ำเสมอ",
            2: "บางครั้ง",
            3: "ไม่ปฏิบัติ",
        }
        const schema7 = {
            1: "รุนแรงมาก",
            2: "รุนแรงปานกลาง",
            3: "ไม่รุนแรง",
        }
        const schema8 = { 1: "No", 2: "Yes" }
        const schema10 = { 0: "Never", 1: "Yes" }
        const schema11 = {
            1: "Ex smoking󠄀",
            2: "Current smoking",
        }
        const schema13 = {
            1: "บุหรี่ซอง",
            2: "บุหรี่ไฟฟ้า",
            3: "ยาเส้น",
            4: "อื่น ๆ"
        }
        const schema31 = {
            1: "อาหารธรรมดา",
            2: "อาหารอ่อน",
            3: "อาหารเหลว/นม",
            4: "อาหารเฉพาะโรค ระบุ",
            5: "อื่น ๆ",
        }
        const schema38 = { 0: "No", 1: "Yes" }
        const schema46 = {
            1: "ไม่ทราบ",
            2: "ไม่มีการเปลี่ยนแปลง",
            3: "เปลี่ยนแปลง",
        }
        const schema60 = {
            1: "No risk",
            2: "Risk > 2 continue for nutrition assessment",
        }
        const schema63 = { 1: " ปกติ ", 2: "ผิดปกติ" }
        const schema80 = { 1: "เพียงพอ", 2: "ไม่เพียงพอ" }
        const schema84 = { 1: "หลับสนิท", 2: "หลับไม่สนิท" }
        const schema85 = { 1: "ฝัน", 2: "ไม่ฝัน" }
        const schema86 = { 0: "ไม่มี", 1: "มี" }
        const schema92 = {
            1: "ไม่ออกกำลังกาย",
            2: "ออกกำลังกายสม่ำเสมอ",
            3: "ออกกำลังกายแต่ไม่สม่ำเสมอ",
        }
        const schema96 = { 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }
        const schema106 = {
            1: " Alert (ภาวะการรู้ตัวปกติ) ",
            2: " Confused (สับสน)",
            3: "Drowsiness (ซึม)",
            4: " Stuporous (ภาวะซึมมากหลับลึก)",
            5: " Coma (ภาวะหมดสติ) ",
        }
        const schema107 = {
            1: "ปกติ",
            2: "ผิดปกติ",
        }
        const schema125 = {
            1: "ลักษณะรูปร่างหน้าตา",
            2: "อารมณ์/ความรู้สึก",
            3: "การรับรู้ความสามารถของตนเอง",
            4: "อื่น ๆ",
        }
        const schema139 = {
            1: "สัมพันธ์",
            2: "ไม่สัมพันธ์",
        }
        const schema141 = {
            1: "ใช่",
            0: "ไม่ใช่",
        }
        const schema144 = {
            1: "Regular สม่ำเสมอ",
            0: "Irregular ไม่สม่ำเสมอ",
        }
        const schema146 = {
            0: "ไม่ส่งผล",
            1: "ส่งผล",
        }
        const schema152 = {
            1: "แยกตัว",
            2: "พูดคุยกับบุคคลอื่น",
            3: "ใช้ยา",
            4: "อื่น ๆ",
        }
        const schema154 = {
            1: "พุทธ",
            2: "คริสต์",
            3: "อิสลาม",
            4: "อื่น ๆ",
        }
        const schema91 = {
            1: "ทำได้",
            2: "ทำได้บางส่วน",
            3: "ทำไม่ได้",
        }
        const schema39 = {
            1: "การกลืน",
            2: "การเคี้ยว",
            3: "เบื่ออาหาร",
            4: "คลื่นไส้",
            5: "อาเจียน",
            6: "อื่นๆ"
        }
        const schema54 = {
            1: "Decrease food or nutrient at least 7 days (1)",
            2: "BW reduction during the past 6 months (1)",
            3: "BMI < 18.5 kg/m2 or > 25 kg/m2 (1)",
            4: "Critically ill or intermediate care condition (1)",
        }
        const schema64 = {
            1: "ขุ่น",
            2: "แสบขัด",
            3: "กลั้นปัสสาวะไม่ได้",
            4: "อื่นๆ"
        }
        const schema71 = {
            1: "ท้องผูก",
            2: "ท้องเสีย",
            3: "กลั้นอุจจาระไม่ได้",
            4: "ขับถ่ายทางหน้าท้อง",
            5: "ริดสีดวง",
            6: "อื่นๆ"
        }
        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",
            //1.1
            "1.1 สุขภาพของท่านเป็นอย่างไร", "ระบุ",
            //1.2
            "1.2 ท่านพึงพอใจต่อภาวะสุขภาพของท่านหรือไม่", "ระบุ",
            //1.3
            "1.3 การปฏิบัติตามแผนการรักษาและการดูแลในอดีต",
            "การดูแลสุขภาพเมื่อเจ็บป่วย", "ระบุ",
            "การปฏิบัติตามแผนการรักษา",
            //1.4
            "การรับรู้ต่อความเจ็บป่วยในปัจจุบัน",
            //1.5
            "การได้รับภูมิคุ้มกันโรค", "ระบุ",
            //1.6
            "การใช้สารเสพติด",
            "Smoking", "Ex smoking󠄀", "ประเภทของบุหรี่", "ระบุ", "จำนวน", "ระยะเวลาที่เลิก", "Current smoking",
            "ประเภทของบุหรี่", "ระบุ", "จำนวน", "ระยะเวลาที่เลิก",
            "Alcohol", "ประเภท", "ปริมาณที่บริโภค", "ความถี่",
            "Addictive substance", "ประเภท", "ระยะเวลาที่ใช้",
            "Other", "ระบุ", "ระยะเวลาที่ใช้",
            //2.1
            "2.1 แบบแผนการรับประทานอาหาร/การดื่มน้ำ",
            "ประเภทอาหาร", "ระบุอาหารเฉพาะโรค", "อื่นๆ ระบุ",
            "จำนวนมื้อ", "ปริมาณต่อมื้อ", "ปริมาณการดื่มน้ำ", "แหล่งที่มาของน้ำดื่ม",
            //2.2
            "2.2 ปัญหาการรับประทานอาหาร", "รายการปัญหา", "อื่นๆ ระบุ",
            //2.3
            "การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง", "เพิ่มขึ้น", "ในระยะเวลา", "ลดลง", "ในระยะเวลา",
            //2.4
            "ปัญหาเกี่ยวกับสุขภาพผิวหนัง/เล็บ/ผม", "ระบุ",
            //2.5
            "2.5 Nutrition Screening การคัดกรองภาวะโภชนาการ",
            "BMI", "รายการการคัดกรองภาวะโภชนาการ", "Total score", "Risk",
            //3.1
            "3.1 ปัสสาวะ", "ปริมาณ", "ลักษณะ", "รายการความผิดปกติ", "อื่นๆ ระบุ",
            //3.2
            "การขับถ่าย", "ลักษณะ", "รายการความผิดปกติ", "อื่นๆ ระบุ",
            //3.3
            "อาการเหงื่อออกมากผิดปกติ", "ระบุ",
            //4.1
            "4.1 การนอนหลับ", "ปกตินอนวันละ", "เวลาเข้านอน", "เวลาตื่น",
            "ลักษณะการนอนหลับ", "ฝัน/ไม่ฝัน", "ปัญหาในการนอนหลับ", "ระบุ",
            "การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ", "ระบุ", "ความถี่ในการใช้",
            //5.1
            "การทำกิจวัตรประจำวัน",
            //5.2
            "แบบแผนการออกกำลังกาย", "วิธีการออกกำลังกาย", "ระยะเวลาออกกำลังกาย", "ความถี่",
            //5.3
            "5.3 ความสามารถในการช่วยเหลือตนเองในกิจวัตรประจำวัน",
            "1. การเข้าห้องน้ำ", "2. การอาบน้ำ", "3. การรับประทานอาหาร", "4. การเคลื่อนไหวทั่วไป", "5. การแต่งตัว",
            "6. การหวีผม การทำความสะอาดปากและฟัน", "7. การซื้อของ/ทำธุระนอกบ้าน", "8. การเคลื่อนไหวบนเตียงกรณีผู้ป่วยติดเตียง",
            //5.4
            "ปัญหาในการเคลื่อนไหว", "ระบุ",
            //6.1
            "6.1 การรู้คิด (Cognition)",
            "1. ระดับความรู้สึกตัว", "2. ความจำ", "ระบุ", "3. Orientation", "- การรับรู้เวลา", "ระบุ", "- การรับรู้สถานที่", "ระบุ",
            "- การรับรู้บุคคล", "ระบุ",
            //6.2
            "6.2 การรับรู้ (Perception)",
            "1. การได้ยิน", "ระบุ", "2. การมองเห็น", "ระบุ", "3. การรับกลิ่น", "ระบุ", "4. การรับรส", "ระบุ", "5. การสัมผัส", "ระบุ",
            //7
            "สิ่งที่มีผลกระทบต่อตนเองและอัตมโนทัศน์", "ระบุ",
            //8
            "ผลกระทบของความเจ็บป่วยครั้งนี้",
            "- ต่อโครงสร้าง บทบาท และสัมพันธภาพของครอบครัว", "ระบุ", "- ต่อสถานภาพ บทบาท และสัมพันธภาพในสังคม", "ระบุ", "บุคคลและแหล่งที่ให้ความช่วยเหลือผู้ป่วย", "ระบุ",
            "สิ่งที่เป็นอุปสรรคต่อการสร้างสัมพันธภาพ", "ระบุ",
            //9
            "การคุมกำเนิด", "ระบุ", "ปัญหาเกี่ยวกับอวัยวะสืบพันธุ์", "ระบุ", "พฤติกรรมที่แสดงออกสัมพันธ์กับเพศสภาพ", "ระบุ",
            "เป็นผู้ป่วยเพศหญิง", "ประจำเดือนมาครั้งสุดท้าย", "Duration/cycle", "Length of cycle", "ทุก every",
            //10
            "การเจ็บป่วยครั้งนี้ส่งผลกระทบต่อจิตใจ/เครียด/กังวล", "ระบุ", "ปัจจัยเสริมที่ทำให้เกิดความไม่สบายใจขณะเจ็บป่วย", "ระบุ",
            "บุคคลหรือแหล่งที่ให้ความช่วยเหลือเวลามีปัญหาหรือไม่สบายใจ", "ระบุ", "การจัดการเมื่อเกิดความเครียด/กังวล", "ระบุ",
            //11
            "นับถือศาสนา", "ระบุ", "ผู้ป่วยเชื่อว่าการเจ็บป่วยในครั้งนี้เกิดจาก", "สิ่งที่ต้องการ/มีค่ามากที่สุดในชีวิต", "ระบุ",
            "สิ่งยึดเหนี่ยวในชีวิต", "ระบุ", "ผลของการอยู่โรงพยาบาลต่อการปฏิบัติศาสนกิจ", "ระบุ", "เหตุผลในการแก้ไข"

        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ]

        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {
            const rowIndex = index + 4;

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //1.1 
            { v: translateValue(data.data_1, schema1) }, //1.1 สุขภาพของท่านเป็นอย่างไร
            { v: data.data_163 },//ระบุ
            //1.2
            { v: translateValue(data.data_2, schema2) }, //1.2 ท่านพึงพอใจต่อภาวะสุขภาพของท่านหรือไม่
            { v: data.data_3 }, //ระบุ
            //1.3
            { v: "->" },//1.3 การปฏิบัติตามแผนการรักษาและการดูแลในอดีต
            { v: translateValue(data.data_4, schema4) },//การดูแลสุขภาพเมื่อเจ็บป่วย
            { v: data.data_5 },//ระบุ
            { v: translateValue(data.data_6, schema6) },//การปฏิบัติตามแผนการรักษา
            //1.4
            { v: translateValue(data.data_7, schema7) },//1.4 การรับรู้ต่อความเจ็บป่วยในปัจจุบัน
            //1.5
            { v: translateValue(data.data_8, schema8) },//1.5 การได้รับภูมิคุ้มกันโรค
            { v: data.data_9 },//ระบุ
            //1.6 การใช้สารเสพติด
            { v: translateValue(data.data_10, schema10) },// 1.6 การใช้สารเสพติด

            { v: data.data_12 == "1" ? "Yes" : "" },//Smoking
            { v: data.data_11 == "1" ? "Yes" : "" }, //Ex smoking󠄀
            { v: translateValue(data.data_13, schema13) }, //ประเภทของบุหรี่
            { v: data.data_14 }, //ระบุ
            { v: data.data_15 },  //จำนวน
            { v: data.data_16 }, //ระยะเวลาที่เลิก
            { v: data.data_11 == "2" ? "Yes" : "" }, //Current smoking
            { v: translateValue(data.data_17, schema13) }, //ประเภทของบุหรี่
            { v: data.data_18 }, //ระบุ
            { v: data.data_19 },//จำนวน
            { v: data.data_20 }, //ระยะเวลาที่เลิก

            { v: data.data_21 == "1" ? "Yes" : "" },//Alcohol
            { v: data.data_22 },//ประเภท
            { v: data.data_23 },//ปริมาณที่บริโภค
            { v: data.data_24 },//ความถี่

            { v: data.data_25 == "1" ? "Yes" : "" },//Addictive substance
            { v: data.data_26 },//ประเภท
            { v: data.data_27 },//ระยะเวลาที่ใช้

            { v: data.data_28 == "1" ? "Yes" : "" },//Other
            { v: data.data_29 },//ประเภท
            { v: data.data_30 },//ระยะเวลาที่ใช้
            //2.1
            { v: "->" },//2.1 แบบแผนการรับประทานอาหาร/การดื่มน้ำ
            { v: translateValue(data.data_31, schema31) },//ประเภท
            { v: data.data_32 },//ระบุอาหารเฉพาะโรค 
            { v: data.data_33 },//อื่นๆ ระบุ
            { v: data.data_34 },//จำนวนมื้อ
            { v: data.data_35 },//ปริมาณต่อมื้อ
            { v: data.data_36 },//ปริมาณการดื่มน้ำ 
            { v: data.data_37 },//แหล่งที่มาของน้ำดื่ม
            //2.2 ปัญหาการรับประทานอาหาร
            { v: translateValue(data.data_38, schema38) }, //2.2 ปัญหาการรับประทานอาหาร
            { v: mergeData([data.data_39, data.data_40, data.data_41, data.data_42, data.data_43, data.data_44], schema39) }, //รายการปัญหา
            { v: data.data_45 }, //อื่นๆ ระบุ
            //2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง
            { v: translateValue(data.data_46, schema46) }, //2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา
            { v: data.data_48 }, //เพิ่มขึ้น
            { v: data.data_49 }, //ในระยะเวลา
            { v: data.data_50 }, //ลดลง
            { v: data.data_51 }, //ในระยะเวลา
            //2.4 ปัญหาเกี่ยวกับสุขภาพผิวหนัง/เล็บ/ผม
            { v: translateValue(data.data_52, schema38) },//2.4 ปัญหาเกี่ยวกับสุขภาพผิวหนัง/เล็บ/ผม
            { v: data.data_53 }, //ระบุ
            //2.5 Nutrition Screening การคัดกรองภาวะโภชนาการ
            { v: "->" },//2.5 Nutrition Screening การคัดกรองภาวะโภชนาการ
            { v: data.data_54 },//BMI
            { v: mergeData([data.data_55, data.data_56, data.data_57, data.data_58], schema54) },//รายการการคัดกรองภาวะโภชนาการ
            { v: data.data_59 }, //Total score
            { v: translateValue(data.data_60, schema60) }, //Risk
            //3.1 แบบแผนการขับถ่าย
            { v: data.data_61 }, //3.1 ปัสสาวะ
            { v: data.data_62 }, //ปริมาณ
            { v: translateValue(data.data_63, schema63) }, //ลักษณะ
            { v: mergeData([data.data_64, data.data_65, data.data_66, data.data_67], schema64) }, //รายการความผิดปกติ
            { v: data.data_68 }, //อื่นๆ ระบุ
            //3.2
            { v: data.data_69 }, //3.2 การขับถ่าย
            { v: translateValue(data.data_70, schema63) }, //ลักษณะ
            { v: mergeData([data.data_71, data.data_72, data.data_73, data.data_74, data.data_75, data.data_76], schema71) }, //รายการความผิดปกติ
            { v: data.data_77 }, //อื่นๆ ระบุ 
            //3.3
            { v: translateValue(data.data_78, schema38) }, //3.3 อาการเหงื่อออกมากผิดปกติ
            { v: data.data_79 }, //ระบุ 
            //4.1
            { v: translateValue(data.data_80, schema80) }, //4.1 การนอนหลับ
            { v: data.data_81 }, //ปกตินอนวันละ
            { v: data.data_82 }, //เวลาเข้านอน
            { v: data.data_83 }, //เวลาตื่น
            { v: translateValue(data.data_84, schema84) }, //ลักษณะการนอนหลับ
            { v: translateValue(data.data_85, schema85) }, //ฝัน/ไม่ฝัน
            { v: translateValue(data.data_86, schema86) }, //ปัญหาในการนอนหลับ
            { v: data.data_87 }, //ระบุ
            { v: translateValue(data.data_88, schema86) }, //การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ
            { v: data.data_89 }, //ระบุ
            { v: data.data_90 }, //ความถี่ในการใช้
            //5.1
            { v: translateValue(data.data_91, schema91) }, //5.1 การทำกิจวัตรประจำวัน
            //5.
            { v: translateValue(data.data_92, schema92) }, //5.2 แบบแผนการออกกำลังกาย
            { v: data.data_93 }, //วิธีการออกกำลังกาย
            { v: data.data_94 }, //ระยะเวลาออกกำลังกาย
            { v: data.data_95 }, //ความถี่
            //5.3
            { v: "->" },//5.3 ความสามารถในการช่วยเหลือตนเองในกิจวัตรประจำวัน
            { v: translateValue(data.data_96, schema96) }, //1. การเข้าห้องน้ำ
            { v: translateValue(data.data_97, schema96) }, //2. การอาบน้ำ
            { v: translateValue(data.data_98, schema96) }, //3. การรับประทานอาหาร
            { v: translateValue(data.data_99, schema96) }, //4. การเคลื่อนไหวทั่วไป
            { v: translateValue(data.data_100, schema96) }, //5. การแต่งตัว
            { v: translateValue(data.data_101, schema96) }, //6. การหวีผม การทำความสะอาดปากและฟัน
            { v: translateValue(data.data_102, schema96) }, //7. การซื้อของ/ทำธุระนอกบ้าน
            { v: translateValue(data.data_103, schema96) }, //8. การเคลื่อนไหวบนเตียงกรณีผู้ป่วยติดเตียง
            //5.4
            { v: translateValue(data.data_104, schema86) }, //5.4 ปัญหาในการเคลื่อนไหว
            { v: data.data_105 }, //ระบุ
            //6.1
            { v: "->" },//6.1 การรู้คิด (Cognition)
            { v: translateValue(data.data_106, schema106) },//1. ระดับความรู้สึกตัว (Consciousness)
            { v: translateValue(data.data_107, schema107) },//2. ความจำ (Memory)
            { v: data.data_107 == "2" ? data.data_108 : "" }, //ระบุ
            { v: "->" },//3. Orientation
            { v: translateValue(data.data_109, schema107) },//- การรับรู้เวลา
            { v: data.data_110 },
            { v: translateValue(data.data_111, schema107) },//- การรับรู้สถานที่
            { v: data.data_112 },
            { v: translateValue(data.data_113, schema107) },//- การรับรู้บุคคล
            { v: data.data_114 },
            //6.2 
            { v: "->" },//6.2 การรับรู้ (Perception)
            { v: translateValue(data.data_115, schema107) },//1. การได้ยิน
            { v: data.data_116 },
            { v: translateValue(data.data_117, schema107) },//2. การมองเห็น
            { v: data.data_118 },
            { v: translateValue(data.data_119, schema107) },//3. การรับกลิ่น
            { v: data.data_120 },
            { v: translateValue(data.data_121, schema107) },//4. การรับรส
            { v: data.data_122 },
            { v: translateValue(data.data_123, schema107) },//5. การสัมผัส
            { v: data.data_124 },
            //7
            { v: translateValue(data.data_125, schema125) },//สิ่งที่มีผลกระทบต่อตนเองและอัตมโนทัศน์
            { v: data.data_126 },
            //8
            { v: "->" },//ผลกระทบของความเจ็บป่วยครั้งนี้
            { v: translateValue(data.data_127, schema86) },//- ต่อโครงสร้าง บทบาท และสัมพันธภาพของครอบครัว
            { v: data.data_128 },
            { v: translateValue(data.data_129, schema86) },//- ต่อสถานภาพ บทบาท และสัมพันธภาพในสังคม

            { v: data.data_130 },
            { v: translateValue(data.data_131, schema86) },//"บุคคลและแหล่งที่ให้ความช่วยเหลือผู้ป่วย"
            { v: data.data_132 },//"ระบุ"
            { v: translateValue(data.data_133, schema86) },//"สิ่งที่เป็นอุปสรรคต่อการสร้างสัมพันธภาพ"
            { v: data.data_134 },//"ระบุ"
            //9
            { v: translateValue(data.data_135, schema86) },//"การคุมกำเนิด"
            { v: data.data_136 },//"ระบุ"
            { v: translateValue(data.data_137, schema86) },//"ปัญหาเกี่ยวกับอวัยวะสืบพันธุ์"
            { v: data.data_138 },//"ระบุ"
            { v: translateValue(data.data_139, schema139) },//"พฤติกรรมที่แสดงออกสัมพันธ์กับเพศสภาพ"
            { v: data.data_140 },//"ระบุ"
            { v: translateValue(data.data_141, schema141) },//"เป็นผู้ป่วยเพศหญิง"
            { v: data.data_141 == "1" ? setDateFormat(data.data_142) : "" },//"ประจำเดือนมาครั้งสุดท้าย"
            { v: data.data_141 == "1" ? data.data_143 : "" },//"Duration/cycle"
            { v: data.data_141 == "1" ? translateValue(data.data_144, schema144) : "" },//"Length of cycle"
            { v: data.data_141 == "1" && data.data_144 == "1" ? data.data_145 : "" },//"ทุก every"
            //10 
            { v: translateValue(data.data_146, schema146) },//"การเจ็บป่วยครั้งนี้ส่งผลกระทบต่อจิตใจ/เครียด/กังวล"
            { v: data.data_147 },//"ระบุ"
            { v: translateValue(data.data_148, schema86) },//"ปัจจัยเสริมที่ทำให้เกิดความไม่สบายใจขณะเจ็บป่วย"
            { v: data.data_149 },//"ระบุ"  
            { v: translateValue(data.data_150, schema86) },//"บุคคลหรือแหล่งที่ให้ความช่วยเหลือเวลามีปัญหาหรือไม่สบายใจ"
            { v: data.data_151 },//"ระบุ"
            { v: translateValue(data.data_152, schema152) },//"การจัดการเมื่อเกิดความเครียด/กังวล"
            { v: data.data_153 },//"ระบุ"
            //11
            { v: translateValue(data.data_154, schema154) },//"นับถือศาสนา"
            { v: data.data_155 },//"ระบุ"
            { v: data.data_156 },//"ผู้ป่วยเชื่อว่าการเจ็บป่วยในครั้งนี้เกิดจาก"
            { v: translateValue(data.data_157, schema86) },//"สิ่งที่ต้องการ/มีค่ามากที่สุดในชีวิต"
            { v: data.data_158 },//"ระบุ"
            { v: translateValue(data.data_159, schema86) },//"สิ่งยึดเหนี่ยวในชีวิต"
            { v: data.data_160 },//"ระบุ"
            { v: translateValue(data.data_161, schema86) },//"ผลของการอยู่โรงพยาบาลต่อการปฏิบัติศาสนกิจ"
            { v: data.data_162 },//"ระบุ"
            { v: data.data_164 },]//"เหตุผลในการแก้ไข"

            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }

        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }


    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}

const formACSCaringSet = (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema2 = { 1: ": byself", 2: ": family", 3: ": EMS" }
        const schema43 = { 1: "Current smoker", 2: "Ex smoker", 3: "Never" }
        const schema44 = { 0: "No", 1: "Yes" }
        const schema46 = {
            1: "No",
            2: "Yes",
            3: "Diet",
            4: "Oral",
            5: "Insulin",
        }
        const schema62 = {
            1: "II",
            2: "III",
            3: "IV",
        }
        const schema63 = {
            0: "No",
            1: "Yes",
            2: "Type",
            3: "CHB",
            4: "VT/VF",
        }

        const schema64 = {
            2: "CHB",
            3: "VT/VF",
        }
        const schema67 = {
            1: "pre hosp",
            2: "ER",
            3: "on admission",
            4: "during transfer",
        }
        const schema68 = {
            1: "STEMI",
            2: "NSTEACS",
        }
        const schema70 = {
            1: "Fibrinolytic",
            2: "PPCI",
            3: "Rescue PCI",
            4: "PHI ( pharmacoinvasive)",
            5: "Secondary PCI (PCI after 24hr)",
            6: "Conservative",
        }
        const schema71 = {
            1: "Invasive",
            2: "Conservative",
        }
        const schema73 = {
            1: "low (<126)",
            2: "intermediate (126 – <155)",
            3: "high (>155)",
        }
        const schema74 = {
            1: "low (1-108)",
            2: "intermediate (109 – 140)",
            3: "high (>140)",
        }
        const schema75 = {
            1: "yes, high risk",
            2: "no risk",
        }

        const schema79 = { 1: "STEMI", 2: "NSTEMI/UA" }

        const schema109 = { 1: "Done", 2: "Not Done" }

        const schema110 = { 1: "absent", 2: "present (2Q)" }


        const schema111 = { 1: "Mild", 2: "Moderate", 3: "Severe (9Q)" }

        const schema112 = { 1: "absent", 2: "present (2Q)" }

        const schema114 = { 1: "Done", 2: "Not Done" }
        const schema135 = {
            1: "E-Cardio application",
            2: "Electronic/ LINE Tele Follow up",
            3: "paper",
            4: "none",
        }
        const schema33 = {
            "01": "สามี",
            "02": "ภรรยา",
            "03": "บุตร",
            "04": "พ่อ",
            "05": "แม่",
            "99": "อื่นๆ"
        }
        const schema35 = {
            "01": "ระดับประถมศึกษา",
            "02": "ระดับมัธยมศึกษาตอนต้น",
            "03": "ระดับมัธยมศึกษาตอนปลาย",
            "04": "ประกาศนียบัตรวิชาชีพชั้นสูง",
            "05": "อนุปริญญา",
            "06": "ระดับปริญญาตรี",
            "07": "ระดับปริญญาโท",
            "08": "ระดับปริญญาเอก",
            "99": "อื่นๆ"
        }
        const schema36 = {
            "000": "ไม่ประกอบอาชีพ",
            "001": "แพทย์",
            "002": "พยาบาล",
            "003": "ทหาร",
            "004": "ตำรวจ",
            "005": "วิศวกร",
            "006": "นิติกร/ทนายความ/ผู้พิพากษา/อัยการ",
            "007": "นักสังคมสงเคราะห์/พัฒนาชุมชน",
            "008": "สจ๊วต/แอร์โฮสเตส",
            "009": "นักบิน",
            "123": "นักประพันธ์",
            "124": "นักข่าว",
            "126": "นักแสดง",
            "127": "นักร้อง",
            "128": "นักดนตรี",
            "129": "ช่างภาพ",
            "201": "รับราชการ",
            "202": "ครู-อาจารย์ (ราชการ)",
            "203": "พนักงานมหาวิทยาลัย",
            "206": "ข้าราชการการเมือง",
            "208": "ข้าราชการบำนาญ",
            "209": "ลูกจ้างชั่วคราว",
            "210": "ลูกจ้างประจำ",
            "211": "กำนัน",
            "213": "ผู้ใหญ่บ้าน",
            "214": "ผู้ช่วยผู้ใหญ่บ้าน",
            "215": "แพทย์ประจำตำบล",
            "216": "เจ้าหน้าที่ปกครองส่วนท้องถิ่น (อบต.)",
            "217": "เจ้าหน้าที่สาธารณสุข",
            "218": "อาสาสมัครสาธารณสุข/ผู้สื่อข่าวสาธารณสุข",
            "301": "ผู้บริหารรัฐวิสาหกิจ",
            "302": "พนักงานรัฐวิสาหกิจ",
            "303": "ครู-อาจารย์ (เอกชน)",
            "401": "เจ้าของกิจการ",
            "402": "พนักงานบริษัท/ห้าง/ร้านเอกชน",
            "403": "รับจ้างทั่วไป",
            "404": "นักธุรกิจ/ผู้ประกอบธุรกจิส่วนตัว",
            "405": "ผู้ปฏิบัติงานเอกชนอื่น ๆ",
            "502": "เกษตรกรรม",
            "606": "ค้าขาย",
            "607": "ช่างเสริมสวย",
            "719": "ผู้ปฏิบัติงานด้านบริการอื่น ๆ",
            "824": "ผู้ปฏิบัติงานช่างอื่น ๆ",
            "900": "นักเรียน,นักศึกษา",
            "901": "นักบวช",
            "902": "ในความปกครอง",
            "903": "แม่บ้าน",
        }
        const schema76 = {
            1: "EF<40%",
            2: "Ant wall",
            3: "Not reperfusion in STEMI",
            4: "eGFR<60",
            5: "High risk GRACE",
            6: "N/A"
        }
        const schema137 = {
            1: "Anterior",
            2: "Inferior",
            3: "Lateral",
            4: "Posterior",
            5: "RV",
            6: "Unknown"
        }
        const schema77 = {
            1: "Electrolyte (K,mg)",
            2: "EF<40%",
            3: "Ant wall",
            4: "N/A"
        }
        const schema78 = {
            1: "EF<40%",
            2: "Ant wall",
            3: "Not reperfusion in STEMI",
            4: "N/A"
        }
        const schema103 = {
            1: "DLP",
            2: "DM",
            3: "HT",
            4: "Smoking",
            5: "BMI > 25",
            6: "FmHx"
        }
        const hospitalTranslate = {
            "06009": "โรงพยาบาลแม่ตื่น จ.เชียงใหม่",
            10713: "โรงพยาบาลนครพิงค์ จ.เชียงใหม่",
            10714: "โรงพยาบาลลำพูน จ.ลำพูน",
            10719: "โรงพยาบาลศรีสังวาลย์ จ.แม่ฮ่องสอน",
            11119: "โรงพยาบาลจอมทอง จ.เชียงใหม่",
            11120: "โรงพยาบาลเทพรัตนเวชชานุกูล เฉลิมพระเกียรติ ๖๐ พรรษา จ.เชียงใหม่",
            11121: "โรงพยาบาลเชียงดาว จ.เชียงใหม่",
            11122: "โรงพยาบาลดอยสะเก็ด จ.เชียงใหม่",
            11123: "โรงพยาบาลแม่แตง จ.เชียงใหม่",
            11124: "โรงพยาบาลสะเมิง จ.เชียงใหม่",
            11125: "โรงพยาบาลฝาง จ.เชียงใหม่",
            11126: "โรงพยาบาลแม่อาย จ.เชียงใหม่",
            11127: "โรงพยาบาลพร้าว จ.เชียงใหม่",
            11128: "โรงพยาบาลสันป่าตอง จ.เชียงใหม่",
            11129: "โรงพยาบาลสันกำแพง จ.เชียงใหม่",
            11130: "โรงพยาบาลสันทราย จ.เชียงใหม่",
            11131: "โรงพยาบาลหางดง จ.เชียงใหม่",
            11132: "โรงพยาบาลฮอด จ.เชียงใหม่",
            11133: "โรงพยาบาลดอยเต่า จ.เชียงใหม่",
            11134: "โรงพยาบาลอมก๋อย จ.เชียงใหม่",
            11135: "โรงพยาบาลสารภี จ.เชียงใหม่",
            11136: "โรงพยาบาลเวียงแหง จ.เชียงใหม่",
            11137: "โรงพยาบาลไชยปราการ จ.เชียงใหม่",
            11138: "โรงพยาบาลแม่วาง จ.เชียงใหม่",
            11139: "โรงพยาบาลแม่ออน จ.เชียงใหม่",
            11140: "โรงพยาบาลแม่ทา จ.ลำพูน",
            11141: "โรงพยาบาลบ้านโฮ่ง จ.ลำพูน",
            11142: "โรงพยาบาลลี้ จ.ลำพูน",
            11143: "โรงพยาบาลทุ่งหัวช้าง จ.ลำพูน",
            11144: "โรงพยาบาลป่าซาง จ.ลำพูน",
            11145: "โรงพยาบาลบ้านธิ จ.ลำพูน",
            11507: "โรงพยาบาลกองบิน 41 จ.เชียงใหม่",
            11508: "โรงพยาบาลค่ายกาวิละ เชียงใหม่ จ.เชียงใหม่",
            11643: "โรงพยาบาลดอยหล่อ จ.เชียงใหม่",
            11978: "โรงพยาบาลดารารัศมี จ.เชียงใหม่",
            11992: "โรงพยาบาลเทพปัญญา 2 โรงพยาบาลทั่วไปขนาดกลาง จ.เชียงใหม่",
            11995: "โรงพยาบาลทั่วไปขนาดใหญ่เซ็นทรัลเชียงใหม่ เมโมเรียล จ.เชียงใหม่",
            11999: "โรงพยาบาลทั่วไปขนาดใหญ่เชียงใหม่เมดิคอลเซ็นเตอร์ จ.เชียงใหม่",
            12279: "โรงพยาบาลส่งเสริมสุขภาพ ศูนย์อนามัยที่ 1 จ.เชียงใหม่",
            12280: "โรงพยาบาลสวนปรุง จ.เชียงใหม่",
            12281: "โรงพยาบาลประสาทเชียงใหม่ จ.เชียงใหม่",
            12283: "โรงพยาบาลมะเร็งลำปาง จ.ลำปาง",
            13775: "สถาบันพัฒนาการเด็กราชนครินทร์ จ.เชียงใหม่",
            13780: "โรงพยาบาลมหาราชนครเชียงใหม่ มหาวิทยาลัยเชียงใหม่ จ.เชียงใหม่",
            13785: "โรงพยาบาลธัญญารักษ์เชียงใหม่ จ.เชียงใหม่",
            14197: "โรงพยาบาลธัญญรักษ์แม่ฮ่องสอน จ.แม่ฮ่องสอน",
            14550: "โรงพยาบาลทั่วไปขนาดใหญ่ เชียงใหม่ใกล้หมอ จ.เชียงใหม่",
            14555: "โรงพยาบาลทั่วไปขนาดกลางศิริเวช ลำพูน จ.ลำพูน",
            14916: "โรงพยาบาลทั่วไปขนาดกลางเชียงใหม่ฮอสพิทอล จ.เชียงใหม่",
            23736: "โรงพยาบาลวัดจันทร์ เฉลิมพระเกียรติ 80 พรรษา จ.เชียงใหม่",
            99999: "อื่นๆ",
        };

        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",

            "New case", "Arrival by", "Refer from", "ชื่อโรงพยาบาล", "อื่นๆ ระบุ",
            //event 28
            "Event", "วันที่-เวลา",
            "Event", "วันที่-เวลา", "Onset to FMC 2-1",
            "Event", "วันที่-เวลา", "ER to EKG รพช 3-2",
            "Event", "วันที่-เวลา", "Di-Do 4-2",
            "Event", "วันที่-เวลา", "Transfer time 5-4",
            "Event", "วันที่-เวลา", "ER to EKG รพช 4-5",
            "Event", "วันที่-เวลา", "Do to Dr 7-5",
            "Event", "วันที่-เวลา", "ER to Dx 8-5",
            "Event", "วันที่-เวลา",
            "Event", "วันที่-เวลา", "ER to Cardio 10-5",
            "Event", "วันที่-เวลา",
            "Event", "วันที่-เวลา",
            "Event", "วันที่-เวลา", "ER to ward 13-11",
            "Event", "วันที่-เวลา", "ER to cath 14-11",
            "Event", "วันที่-เวลา", "Do to vas access 15-5",
            "Event", "วันที่-เวลา", "Do to wire 16-5",
            //ข้อมูลพื้นฐาน 1/2 (สำหรับ CCU & IPD) 11
            "Emergency contact (Name)", "Relationship", "Phone", "Education", "Occupation",
            "Vital signs: Temp", "HR", "RR", "BP", "ส่วนสูง", "น้ำหนัก",
            //ข้อมูลพื้นฐาน 2/2 (สำหรับ CCU & IPD ) 17
            "Smoking", "HT", "DLP", "DM", "รูปแบบการใช้", "Family Hx of CAD", "Prior MI", "Known CAD (stenosis > 50%)",
            "CVD disease", "PAD", "Prior PCI", "Last year", "Prior CABG", "Last year", "Prior HF",
            "Prior valve surgery", "Chronic lung", "On dialysis",
            //Complication at admission: 7
            "HF", "Class", "Arrhythmia", "Type", "CPR", "Death", "Status",
            //Diagnosis and plan management. (สำหรับแพทย์) 9
            "Diagnosis", "Area of involvement", "STEMI Plan", "NSTEACS Plan",
            //Risk assessment (สำหรับแพทย์ CCU&IPD) 18
            "GRACE score", "STEMI (ความเสี่ยงต่อ Cardiac arrest)", "NSTEMI (ความเสี่ยงต่อ Cardiac arrest)",
            "Bleeding (ARC-HBR)", "Heart Failure", "Cardiac arrhythmia", "Stroke",
            //Discharge summary physician 27
            "Diagnosis", "Type", "Area Of Involvement", "Treatment", "Underlying", "Coronary Angiogram Disease"
            , "Revascularization", "LVEF", "Bleeding BARC Type", "ระบุ", "Infection", "ระบุ",
            "AKI", "ระบุ", "Respiratory Failure", "ระบุ", "Mechanical Complication",
            "ระบุ", "Arrhythmia", "ระบุ", "Recurrent ACS", "ระบุ", "Heart Failure", "ระบุ",
            "Risk Factors", "Lab", "LDL", "HDL", "TG", "HbA1c", "eGFR", "Hb", "Hct", "Plan Of Treatment แผนการรักษา",
            //Discharge summary nurse
            "BP", "HR", "Home environment",
            "ประเมินความรู้", "การปฏิบัติ/คำแนะนำ",
            "Depression", "การประเมิน", "การปฏิบัติ/คำแนะนำ",
            "Anxiety", "การประเมิน", "การปฏิบัติ/คำแนะนำ",

            "ให้ความรู้เรื่องโรคและการรักษาที่ได้รับ", "ระบุ", "การปฏิบัติ/คำแนะนำ",
            "ให้ความรู้เรื่องอาหารที่เหมาะสมกับโรค", "ระบุ", "การปฏิบัติ/คำแนะนำ",
            "ให้ความรู้เรื่องการรับประทานยา", "ระบุ", "การปฏิบัติ/คำแนะนำ",
            "ให้ความรู้เรื่องการออกกำลังกายที่เหมาะสมกับโรค", "ระบุ", "การปฏิบัติ/คำแนะนำ",
            "การสังเกตอาการผิดปกติที่ควรมาพบแพทย์ก่อนนัด", "ระบุ", "การปฏิบัติ/คำแนะนำ",

            "Other", "การปฏิบัติ/คำแนะ",

            "Cardiac rehabilitation", "METs at discharge", "Home walking program", "คู่มือการปฏิบัติตัว", "การปฏิบัติ/คำแนะนำ",

            "Occupation/ Return to work /Return to daily activity recommendation", "And need occ Med consultation", "การปฏิบัติ/คำแนะ",

            "วันที่ F/U", "สถานที่", "Refer รพ", "ชื่อโรงพยาบาล", "ส่งต่อข้อมูลผ่าน", "การปฏิบัติ/คำแนะ",

            "สรุปปัญหาที่ส่งต่อ/ที่หลงเหลือ", "การปฏิบัติ/คำแนะ", "เหตุผลในการแก้ไข"

        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];

        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {
            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values,
            const values = [
                { v: data.member_name },
                { v: data.hn },
                { v: data.txn },
                { v: translateValue(data.member_gender, gender) },
                { v: birthday[0] },
                { v: data.consult_name },
                { v: created_date[0] },
                { v: created_date[1] },
                //db
                //Timeline Recor,
                { v: data.data_1 == "1" ? "Yes" : "" }, //new cas,
                { v: data.data_1 == "1" ? translateValue(data.data_2, schema2) : "" }, //arrival b,
                { v: data.data_1 == "2" ? "Yes" : "" }, //refer fro,
                { v: data.data_1 == "2" ? translateValue(data.data_3, hospitalTranslate) : "" }, //ชื่อโรงพยาบา,
                { v: data.data_182 }, //อื่นๆ ระบุ
                //event 2,
                { v: "1. Onset of symptom" }, //even,
                { v: setDateFormat(data.data_4) }, //วันที่-เวล,
                { v: "2. Arrive to ER 1st รพช" }, //even,
                { v: setDateFormat(data.data_5) }, //วันที่-เวล,
                { v: data.data_6 }, //Onset to FMC 2-,
                { v: "3. First 12 leads ECG at รพช" }, //even,
                { v: setDateFormat(data.data_7) }, //วันที่-เวล,
                { v: data.data_8 }, //ER to EKG รพช 3-,
                { v: "4. Depart from ER 1st hospital" }, //even,
                { v: setDateFormat(data.data_9) }, //วันที่-เวล,
                { v: data.data_10 }, //Di-Do 4-,
                { v: "5. Arrive to ER Suandok" }, //even,
                { v: setDateFormat(data.data_11) }, //วันที่-เวล,
                { v: data.data_12 }, //Transfer time 5-,
                { v: "6. First 12 leads ECG at Suandok" }, //even,
                { v: setDateFormat(data.data_13) }, //วันที่-เวล,
                { v: data.data_14 }, //ER to EKG รพช 6-,
                { v: "7. ER Physician visit" }, //even,
                { v: setDateFormat(data.data_15) }, //วันที่-เวล,
                { v: data.data_16 }, //Do to Dr 7-,
                { v: "8. Final diagnosis at ER" }, //even,
                { v: setDateFormat(data.data_17) }, //วันที่-เวล,
                { v: data.data_18 }, //ER to Dx 8-,
                { v: "9. Consult cardiologist" }, //even,
                { v: setDateFormat(data.data_19) }, //วันที่-เวล,
                { v: "10. Cardiologist visit or call back" }, //even,
                { v: setDateFormat(data.data_20) }, //วันที่-เวล,
                { v: data.data_21 }, //ER to Cardio 10-,
                { v: "11. Depart from ER Suandok" }, //even,
                { v: setDateFormat(data.data_22) }, //วันที่-เวล,
                { v: "12. Activated for PPCI" }, //even,
                { v: setDateFormat(data.data_23) }, //วันที่-เวล,
                { v: "13. Arrive to CCU/ward" }, //even,
                { v: setDateFormat(data.data_24) }, //วันที่-เวล,
                { v: data.data_25 }, //ER to ward 13-1,
                { v: "14. Arrive to Cath-Lab" }, //even,
                { v: setDateFormat(data.data_27) }, //วันที่-เวล,
                { v: data.data_28 }, //ER to cath 14-1,
                { v: "15. PPCI: Vascular access site" }, //even,
                { v: setDateFormat(data.data_29) }, //วันที่-เวล,
                { v: data.data_30 }, //Do to vas access 15-,
                { v: "16. PPCI: Wire crossing" }, //even,
                { v: setDateFormat(data.data_31) }, //วันที่-เวล,
                { v: data.data_150 }, //Do to wire 16-5
                //ข้อมูลพื้นฐาน 1/2 (สำหรับ CCU & IPD) 1,
                { v: data.data_32 },
                { v: translateValue(data.data_33, schema33) },
                { v: data.data_34 },
                { v: translateValue(data.data_35, schema35) },
                { v: translateValue(data.data_36, schema36) },
                { v: data.data_37 },
                { v: data.data_38 },
                { v: data.data_39 },
                { v: data.data_40 },
                { v: data.data_41 },
                { v: data.data_42 },
                //ข้อมูลพื้นฐาน 2/2 (สำหรับ CCU & IPD ) 1,
                { v: translateValue(data.data_43, schema43) }, //Smokin,
                { v: translateValue(data.data_44, schema44) }, //H,
                { v: translateValue(data.data_45, schema44) }, //DL,
                { v: translateValue(data.data_46, schema46) }, //D,
                { v: data.data_46 == "2" ? translateValue(data.data_47, schema46) : "" }, //รูปแบบการใช,
                { v: translateValue(data.data_48, schema44) }, //Family Hx of CA,
                { v: translateValue(data.data_49, schema44) }, //Prior M,
                { v: translateValue(data.data_50, schema44) }, //Known CAD (stenosis > 50%,
                { v: translateValue(data.data_51, schema44) }, //CVD disease,
                { v: translateValue(data.data_52, schema44) }, //PA,
                { v: translateValue(data.data_53, schema44) }, //Prior PCI,
                { v: data.data_53 == "1" ? setDateFormat(data.data_54) : "" }, //Last yea,
                { v: translateValue(data.data_55, schema44) }, //Prior CAB,
                { v: data.data_55 == "1" ? setDateFormat(data.data_56) : "" }, //Last yea,
                { v: translateValue(data.data_57, schema44) }, //Prior HF,
                { v: translateValue(data.data_58, schema44) }, //Prior valve surger,
                { v: translateValue(data.data_59, schema44) }, //Chronic lun,
                { v: translateValue(data.data_60, schema44) }, //On dialysis
                // Complication at admission: ,
                { v: translateValue(data.data_61, schema44) }, //H,
                { v: translateValue(data.data_62, schema62) }, //Clas,
                { v: translateValue(data.data_63, schema63) }, //Arrhythmia,
                { v: translateValue(data.data_64, schema64) }, //Typ,
                { v: translateValue(data.data_65, schema44) }, //CP,
                { v: translateValue(data.data_66, schema44) }, //Deat,
                { v: translateValue(data.data_67, schema67) }, //Status
                //Diagnosis and plan management. (สำหรับแพทย์) ,
                { v: translateValue(data.data_68, schema68) }, //Diagnosi,
                { v: mergeData([data.data_137, data.data_138, data.data_139, data.data_140, data.data_141, data.data_142], schema137) }, //Area of involvement,
                { v: translateValue(data.data_70, schema70) }, //STEMI Pla,
                { v: translateValue(data.data_71, schema71) }, //NSTEACS Plan

                // Risk assessment 18
                { v: data.data_72 }, //Grace Score
                { v: data.data_68 == "1" ? translateValue(data.data_73, schema73) : "" }, //STEMI (ความเสี่ยงต่อ Cardiac arrest) 
                { v: data.data_68 == "2" ? translateValue(data.data_74, schema74) : "" }, //NSTEMI (ความเสี่ยงต่อ Cardiac arrest) 
                { v: translateValue(data.data_75, schema75) }, //Bleeding (ARC-HBR)
                { v: mergeData([data.data_76, data.data_157, data.data_158, data.data_159, data.data_160, data.data_161], schema76) }, // Heart Failure 
                { v: mergeData([data.data_77, data.data_162, data.data_163, data.data_164], schema77) }, // Cardiac arrhythmia
                { v: mergeData([data.data_78, data.data_165, data.data_166, data.data_167], schema78) }, //Stroke

                //Discharge summary physician27
                { v: translateValue(data.data_79, schema79) },
                { v: data.data_80 },
                { v: data.data_79 == "1" ? data.data_81 : "" },
                { v: data.data_82 },
                { v: data.data_83 },
                { v: data.data_84 },
                { v: data.data_85 },
                { v: data.data_86 },
                { v: translateValue(data.data_87, schema44) },
                { v: data.data_88 },
                { v: translateValue(data.data_89, schema44) },
                { v: data.data_90 },
                { v: translateValue(data.data_91, schema44) },
                { v: data.data_92 },
                { v: translateValue(data.data_93, schema44) },
                { v: data.data_94 },
                { v: translateValue(data.data_95, schema44) },
                { v: data.data_96 },
                { v: translateValue(data.data_97, schema44) },
                { v: data.data_98 },
                { v: translateValue(data.data_99, schema44) },
                { v: data.data_100 },
                { v: translateValue(data.data_101, schema44) },
                { v: data.data_102 },
                { v: mergeData([data.data_103, data.data_151, data.data_152, data.data_153, data.data_154, data.data_155], schema103) },//Risk Factors
                { v: "->" },//Lab
                { v: data.data_143 }, // LDL
                { v: data.data_144 }, // HDL
                { v: data.data_145 }, // TG
                { v: data.data_146 }, //HbA1c
                { v: data.data_147 }, //eGFR
                { v: data.data_148 }, //Hb
                { v: data.data_149 }, //Hct
                { v: data.data_105 }, //Plan Of Treatment แผนการรักษา
                //Discharge summary nurse (หอผู้ป่วยที่จำหน่ายผู้ป่วย) 44
                { v: data.data_106 },//"BP"
                { v: data.data_107 },//"HR"
                { v: data.data_108 },//"Home environment"

                { v: translateValue(data.data_109, schema109) },//"ประเมินความรู้"
                { v: data.data_168 },//"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_110, schema110) },//"Depression"
                { v: translateValue(data.data_111, schema111) },//"การประเมิน"
                { v: data.data_169 },//"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_112, schema112) },//"Anxiety"
                { v: translateValue(data.data_113, schema111) },//"การประเมิน"
                { v: data.data_170 },////"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_114, schema114) },//"ให้ความรู้เรื่องโรคและการรักษาที่ได้รับ"
                { v: data.data_115 },//"ระบุ"
                { v: data.data_171 },//"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_116, schema114) },//"ให้ความรู้เรื่องอาหารที่เหมาะสมกับโรค"
                { v: data.data_117 },//"ระบุ"
                { v: data.data_172 },//"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_118, schema114) },//"ให้ความรู้เรื่องการรับประทานยา"
                { v: data.data_119 },//"ระบุ"
                { v: data.data_173 },//"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_120, schema114) },//"ให้ความรู้เรื่องการออกกำลังกายที่เหมาะสมกับโรค"
                { v: data.data_121 },//"ระบุ"
                { v: data.data_174 },//"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_122, schema114) },//"การสังเกตอาการผิดปกติที่ควรมาพบแพทย์ก่อนนัด"
                { v: data.data_123 },//"ระบุ"
                { v: data.data_175 },//"การปฏิบัติ/คำแนะนำ"

                { v: data.data_124 },//"Other"
                { v: data.data_176 },//"การปฏิบัติ/คำแนะ"

                { v: translateValue(data.data_125, schema44) },//"Cardiac rehabilitation"
                { v: data.data_126 },//"METs at discharge"
                { v: translateValue(data.data_127, schema44) },//"Home walking program"
                { v: translateValue(data.data_128, schema44) },//"คู่มือการปฏิบัติตัว"
                { v: data.data_177 },//"การปฏิบัติ/คำแนะนำ"

                { v: translateValue(data.data_129, schema109) },//"Occupation/ Return to work /Return to daily activity recommendation"
                { v: translateValue(data.data_130, schema109) },//"And need occ Med consultation"
                { v: data.data_178 },//"การปฏิบัติ/คำแนะ"

                { v: setDateFormat(data.data_131) },//"วันที่ F/U"
                { v: data.data_132 },//"สถานที่"
                { v: data.data_133 == "1" ? "Yes" : "" }, //Refer รพ.//"Refer รพ"
                { v: data.data_134 }, //ชื่อโรงพยาบาล//"ชื่อโรงพยาบาล"
                { v: translateValue(data.data_135, schema135) }, //ส่งต่อข้อมูลผ่าน//"ส่งต่อข้อมูลผ่าน"
                { v: data.data_179 },//"การปฏิบัติ/คำแนะ"

                { v: data.data_136 },//"สรุปปัญหาที่ส่งต่อ/ที่หลงเหลือ"
                { v: data.data_180 },//"การปฏิบัติ/คำแนะ"
                { v: data.data_156 },//"เหตุผลในการแก้ไข"
            ]
            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }

        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }

    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}

const formACSFollowUP = (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema1 = {
            1: "STEMI",
            2: "NSTEMI",
            3: "Unsatble angina"
        }
        const schema11 = {
            1: "Re-admission",
            2: "ER Visit"
        }
        const schema101 = {
            0: "No",
            1: "Yes"
        }
        const schema12 = {
            1: "Cardiac caused",
            2: "Non-Cardiac caused"
        }

        const schema28 = { 1: "Yes", 2: "No" }
        const schema33 = {
            1: "Well adherence and have knowledge of medicine",
            2: "Non adherence to medication"
        }
        const schema114 = { 1: "น้อยกว่า", 2: "มากกว่า" }

        const schema59 = { 1: "YES", 2: "NO" }

        const schema64 = { 1: "No Smoking", 2: "Smoking" }

        const schema99 = { 1: "Quit Smoking", 2: "Current Smoking" }

        const schema92 = {
            1: "มากที่สุด (5 คะแนน)",
            2: "มาก (4 คะแนน)",
            3: "ปานกลาง (3 คะแนน)",
            4: "เล็กน้อย (2 คะแนน)",
            5: "เล็กน้อย (2 คะแนน)"
        }
        const schema17 = {
            1: "Chest pain",
            2: "dyspnea",
            3: "PND",
            4: "orthopnea",
            5: "edema",
            6: "bleeding/hematoma/pain/บวม/แดง/ร้อน",
            7: "Orthostatic hypotension",
            8: "Other"
        }
        const schema34 = {
            1: "Forget to take medicine",
            2: "Buy self medication",
            3: "Stop taking medicine",
            4: "Self medication adjustment",
            5: "Taking medicine incorrectly",
            6: "NSAIDs used",
            7: "Herb used",
            8: "Improper adjustment of diuretics",
            9: "Improper use of sublingual medicine",
            10: "Other"
        }
        const schema105 = {
            1: "Walking program",
            2: "Calisthenic exercise (warm-up-cool-down)",
            3: "Other"
        }
        const schema95 = {
            1: "Anxiety",
            2: "Depression",
            3: "Sleeping disorder",
            4: "Other"
        }
        const schema68 = {
            1: "diet",
            2: "exercise",
            3: "medication"
        }
        const schema73 = {
            1: "Knowledge of diet suitable for disease",
            2: "Good adherence",
            3: "Non adherence to dietary recommendation"
        }

        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",

            //ACS Tele Follow Up
            "Diagnosis", "Underlying diseuse", "LVEF", "Last admission", "ACS Tele follow up date of", "Follow-up times",
            //Topics
            //1
            "1. Knowlegde of diseuse and treatment plan", "ระบุ", "Nursing Management", "Comment",
            //2
            "2. ", "YES/NO", "RE date", "RE Hospital", "RE Caridiac/NON-Caridiac", "RE Caridiac", "RE NON-Caridiac", "ER date", "ER Hospital", "Caridiac/NON-Caridiac", "ER Caridiac", "ER NON-Caridiac", "Nursing Management", "Comment",
            //3.
            "3. Abnormal symptoms", "อื่นๆ ระบุ", "Nursing Management", "Comment",
            //4.
            "4. Recognize abnormal symptoms and basic self care",
            "ระบุ", "Nursing Management", "Comment",
            //5.
            "Medication", "Non adherence to medication List", "อื่นๆ ระบุ", "Nursing Management", "Comment",
            //6
            "6. Exercise", "อื่นๆ ระบุ", "Nursing Management", "Comment",
            //7
            "7.1 Pre D/C (METs)", "ระบุ", "7.2 F/U (METs)", "ระบุ", "Nursing Management", "Comment",
            //8.
            "8. Return to work", "ระบุ", "Nursing Management", "Comment",
            //9.
            "9. Psychosocial problem", "รายการปัญหา", "อื่นๆ ระบุ", "Nursing Management", "Comment",
            //10.
            "10. smoking cessation", "Type of Smoking", "ระบุ", "Nursing Management", "Comment",
            //11
            "11. Risk factor control and secondary prevention", "Nursing Management", "Comment",
            //12
            "12. Diet", "Non adherence to dietary recommendation", "Nursing Management", "Comment",
            //13
            "13. An appointment for next follow up", "Date", "Hospital",
            "(No)ระบุ", "Nursing Management", "Comment",
            //14
            "14. มีคำถามเพิ่มเติมหรือสิ่งสงสัยใดๆหรือไม่", "ระบุ", "Nursing Management",
            "Comment",
            //15
            "15. รับทราบให้ฉีด Influenza vaccine ทุกปี", "ระบุ", "Nursing Management", "Comment",
            //16 
            "16. ความพึงพอใจในการโทรศัพท์ติดตามอาการ และให้คำแนะนำ (1-5 คะแนน)",
            "Nursing Management", "Comment", "เหตุผลในการแก้ไข"
        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];

        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {
            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //ACS Tele Follow Up
            { v: translateValue(data.data_1, schema1) }, //Diagnosis
            { v: data.data_2 }, //Underlying diseuse
            { v: data.data_3 }, //LVEF
            { v: setDateFormat(data.data_4) }, //Last admission
            { v: setDateFormat(data.data_5) }, //ACS Tele follow up date of
            { v: data.data_101n }, //Follow-up times
            //Topic
            //1.
            { v: translateValue(data.data_6, schema59) }, //1. Knowlegde of diseuse and treatment plan
            { v: data.data_7 || data.data_8 }, //ระบุ
            { v: data.data_9 }, //Nursing Management
            { v: data.data_10 }, //Comment
            //2.
            { v: translateValue(data.data_11, schema11) },//2.
            { v: translateValue(data.data_101, schema101) || translateValue(data.data_102, schema101) }, //YES/NO
            { v: setDateFormat(data.data_13) }, //RE date
            { v: data.data_14 }, //RE Hospital
            { v: translateValue(data.data_12, schema12) }, //RE Caridiac/NON-Caridiac
            { v: data.data_115 }, //RE Caridiac
            { v: data.data_116 }, //RE NON-Caridiac
            { v: data.data_103 }, //ER date
            { v: data.data_104 }, //ER Hospital
            { v: translateValue(data.data_112, schema12) }, //ER Caridiac/NON-Caridiac
            { v: data.data_117 }, // ER Caridiac
            { v: data.data_118 }, // ER NON-Caridiac
            { v: data.data_15 }, //Nursing Management
            { v: data.data_16 }, //Comment
            //3.
            { v: mergeData([data.data_17, data.data_18, data.data_19, data.data_20, data.data_21, data.data_22, data.data_23, data.data_24], schema17) }, //3. Abnormal symptoms
            { v: data.data_25 }, //อื่นๆ ระบุ
            { v: data.data_26 }, //Nursing Management
            { v: data.data_27 }, //Comment
            //4.
            { v: translateValue(data.data_28, schema28) }, //4. Recognize abnormal symptoms and basic self care
            { v: data.data_29 || data.data_30 }, //ระบุ
            { v: data.data_31 }, //Nursing Management
            { v: data.data_32 }, //Comment
            //5.
            { v: translateValue(data.data_33, schema33) }, //5. Medication
            { v: mergeData([data.data_34, data.data_35, data.data_36, data.data_37, data.data_38, data.data_39, data.data_40, data.data_41, data.data_42, data.data_43], schema34) }, //Non adherence to medication list
            { v: data.data_44 }, //อื่นๆ ระบุ
            { v: data.data_45 }, //Nursing Management
            { v: data.data_46 }, //Comment
            //6.
            { v: mergeData([data.data_105, data.data_106, data.data_47], schema105) }, //6. Exercise
            { v: data.data_48 }, //อื่นๆ ระบุ
            { v: data.data_49 }, //Nursing Management
            { v: data.data_50 }, //Comment
            //7.
            { v: translateValue(data.data_114, schema114) }, // 7.1 Pre D/C (METs)
            { v: data.data_113 || data.data_108 }, //ระบุ
            { v: translateValue(data.data_109, schema114) },// 7.2 F/U (METs) 
            { v: data.data_111 || data.data_110 }, //ระบุ
            { v: data.data_53 }, //Nursing Management
            { v: data.data_54 }, //Comment
            //8
            { v: translateValue(data.data_55, schema101) }, //8. Return to work 
            { v: data.data_55 == "1" ? data.data56 : (data.data_55 == "2" ? data.data_63 : "") }, //ระบุ
            { v: data.data_57 }, //Nursing Management
            { v: data.data_58 }, //Comment
            // 9
            { v: translateValue(data.data_59, schema59) }, //9. Psychosocial problem
            { v: mergeData([data.data_95, data.data_96, data.data_97, data.data_98], schema95) }, //รายการปัญหา
            { v: data.data_60 }, //อื่นๆ ระบุ
            { v: data.data_61 }, //Nursing Management
            { v: data.data_62 }, //Comment
            //10
            { v: translateValue(data.data_64, schema64) }, //10. smoking cessation
            { v: translateValue(data.data_99, schema99) }, //Type of Smoking
            { v: data.data_65 }, //ระบุ
            { v: data.data_66 }, //Nursing Management
            { v: data.data_67 }, //Comment
            //11
            { v: mergeData([data.data_68, data.data_69, data.data_70], schema68) }, //11. Risk factor control and secondary prevention
            { v: data.data_71 }, //Nursing Management
            { v: data.data_72 }, //Comment
            //12
            { v: mergeData([data.data_73, data.data_119, data.data_120], schema73) }, //12. Diet
            { v: data.data_121 }, //non adherence to dietary recommendation
            { v: data.data_74 }, //Nursing Management
            { v: data.data_75 }, //Comment
            //13
            { v: translateValue(data.data_76, schema59) }, //13. An appointment for next follow up
            { v: data.data_76 == "1" ? setDateFormat(data.data_77) : "" }, //Date
            { v: data.data_76 == "1" ? data.data_78 : "" }, //Hospital
            { v: data.data_76 == "2" ? data.data_79 : "" }, //(No)ระบุ
            { v: data.data_80 }, //Nursing Management
            { v: data.data_81 }, //Comment
            //14
            { v: translateValue(data.data_82, schema59) },//14. มีคำถามเพิ่มเติมหรือสิ่งสงสัยใดๆหรือไม่
            { v: data.data_82 == "1" ? data.data_83 : (data.data_82 == "2" ? data.data_84 : "") }, //ระบุ
            { v: data.data_85 }, //Nursing Management
            { v: data.data_86 }, //Comment
            //15
            { v: translateValue(data.data_87, schema59) }, //15. รับทราบให้ฉีด Influenza vaccine ทุกปี
            { v: data.data_87 == "1" ? data.data_data_8883 : (data.data_87 == "2" ? data.data_89 : "") }, //ระบุ
            { v: data.data_90 },  //Nursing Management
            { v: data.data_91 }, //Comment
            //16
            { v: translateValue(data.data_92, schema92) }, //16. ความพึงพอใจในการโทรศัพท์ติดตามอาการ และให้คำแนะนำ (1-5 คะแนน)
            { v: data.data_93 }, //Nursing Management
            { v: data.data_94 },  //Comment
            { v: data.data_122 }, //เหตุผลในการแก้ไข
            ]
            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }
        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }


    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}

const formHeartFailure = async (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema40 = {
            1: "Heart Failure, primary diagnosis, with CAD",
            2: "Heart Failure, primary diagnosis, no CAD",
            3: "Heart Failure, secondary diagnosis"
        }
        const schema69 = {
            1: "I",
            2: "II",
            3: "III",
            4: "IV"
        }
        const schema104 = {
            1: "Type 1",
            2: "Type 2",
            3: "ND",
        }
        const schema106 = {
            1: "<5 years",
            2: "5 - <10 years",
            3: "10 - <20 years",
            4: ">=20 years"
        }

        const schema107 = {
            1: "Obstructive",
            2: "Central",
            3: "Mixed",
            4: "Unknown/Not Documented"
        }
        const schema108 = {
            1: "O2",
            2: "CPAP",
            3: "Adaptive Servo-Ventilation",
            4: "None",
            5: "Unknown/Not Documented"
        }
        const schema117 = {
            1: "ACE Inhibitor Angiotensin receptor blocker (ARB) Angiotensin Receptor Neprilysin Inhibitor (ARNI)",
            2: "Angiotensin receptor blocker (ARB)",
            3: "Angiotensin Receptor Neprilysin Inhibitor (ARNI)",

        }
        const schema22 = {
            1: "CHF",
            2: "Recent HF hospitalities ; < 6 month",
        }
        const schema25 = {
            1: "ครั้งนี้",
            2: "ครั้งล่าสุด",
        }

        const schema37 = {
            "Y": "Yes",
            "N": "No",
        }
        const schema41 = {
            "1": "Yes",
            "2": "No",
        }
        const schema82 = {
            "1": "Flu",
            "2": "others infections",
        }
        const schema105 = {
            "1": "New Diagnosis of Diabetes",
            "2": "Unknown",
        }
        const schema156 = {
            "1": "Thiazide/Thiazide-like",
            "2": "Loop",
        }
        const schema207 = {
            "1": "Normal",
            "2": "LBBB",
            "3": "RBBB",
            "4": "NS-IVCD",
            "5": "Paced",
            "6": "Not available",
            "7": "Other",
        }
        const schema27 = {
            "1": "Ischemic/CAD",
            "2": "Non-Ischemic"
        }
        const schema29 = {
            1: "Alcohol/Other Drug",
            2: "Chemotherapy",
            3: "Familial",
            4: "Hypertensive",
            5: "Peripartum",
            6: "Viral",
            7: "Other Etiology",
            8: "Unknown Etiology"
        }
        const schema43 = {
            1: "Acute Pulmonary Edema",
            2: "Dizziness/Syncope",
            3: "Dyspnea",
            4: "ICD Shock/Sustained",
            5: "Ventricular Arrhythmia",
            6: "Pulmonary Congestion",
            7: "Volume overload/Weight Gain",
            8: "Worsening fatigue",
            9: "Other"
        }
        const schema52 = {
            1: "Arrhythmia",
            2: "Pneumonia/respiratory process",
            3: "Noncompliance - medication",
            4: "Worsening Renal Failure",
            5: "Ischemia/ACS",
            6: "Uncontrolled HTN",
            7: "Noncompliance – Dietary",
            8: "Other"
        }
        const schema60 = {
            1: "Chest Pain",
            2: "Orthopnea",
            3: "Palpitations",
            4: "Dyspnea at rest",
            5: "Fatigue",
            6: "PND",
            7: "Dyspnea on Exertion",
            8: "Decreased appetite/early satiety",
            9: "Dizziness/lightheadedness/syncope"
        }
        const schema70 = {
            1: "Anemia",
            2: "Atrial Fib (chronic or recurrent)",
            3: "Atrial Flutter (chronic or recurrent)",
            4: "CAD",
            5: "CardioMEMs (implantable hemodynamic monitor)",
            6: "COPD or Asthma",
            7: "CRT-D (cardiac resynchronization therapy with ICD)",
            8: "CRT-P (cardiac resynchronization therapy-pacing only)",
            9: "CVA/TIA",
            10: "Depression",
            11: "Diabetes",
            12: "Dialysis (chronic)",
            13: "Emerging Infectious Disease",
            14: "Familial hypercholesterolemia",
            15: "Heart Transplant",
            16: "Hyperlipidemia",
            17: "Hypertension",
            18: "ICD only",
            19: "Kidney Transplant",
            20: "Left Ventricular Assist Device",
            21: "Pacemaker",
            22: "Peripheral Vascular Disease",
            23: "Prior CABG",
            24: "Prior MI",
            25: "Prior PCI",
            26: "Renal insufficiency - chronic (SCr>2.0) Cr cl<60",
            27: "Sleep-Disordered Breathing",
            28: "TAVR",
            29: "TMVR",
            30: "Tricuspid Valve procedure",
            31: "Valvular Heart Disease",
            32: "Ventricular assist device",
            33: "No Medical History"
        }
        const schema38 = {
            0: "0",
            1: "1",
            2: "2",
            3: ">2",
        }
        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",

            "HF No.", "ส่วนสูง", "น้ำหนัก", "สถานภาพผู้ป่วย", "ศาสนา", "อาชีพ", "จำนวนสมาชิกในครอบครัว", "รายได้ของครอบครัวต่อเดือน", "ภูมิลำเนา", "ที่อยู่", "เบอร์โทรศัพท์ผู้ป่วย", "ชื่อผู้ดูแล", "ความสัมพันธ์กับผู้ป่วย", "เบอร์โทรศัพท์ผู้ดูแล", "ชื่อญาติ", "เบอร์โทรศัพท์ญาติ", "แหล่งสนับสนุนทางสังคมหรือผู้ให้ความช่วยเหลือทางการเงิน",

            "วันรับโครงการ", "IPD (AHF)", "Admit Date", "Discharge Date", "OPD", "สถานะ", "Last Admit Date", "Last Discharge Date",

            "ประวัติการนอนโรงพยาบาล(ถ้ามี)", "รายละเอียด",

            "history of", "Ischemic/CAD", "Non-Ischemic", "Known history of HF prior to this visit ?", "# of hospital admissions in past 6 mo. for HF:", ">2 ระบุ", "Heart Failure Diagnosis", "Atrial Fibrillation", "Documented New Onset?",
            , "Atrial Flutter", "Documented New Onset?",

            "Characterization of HF at admission or when first recognized", "อื่นๆ ระบุ",

            "Other Conditions Contributing to HF Exacerbation", "อื่นๆ ระบุ",

            "First presentation Symptoms (Closest to Admission)", "NYHA class",

            "Medical History", "Emerging Infectious Disease",

            "Diabetes Type", "Diabetes Duration", "ระบุ", "Sleep-Disordered Breathing Type", "Equipment used at home", "History of cigarette smoking?", "History of vaping or e-cigarette use in the past 12 months?", "Patient on no meds prior to admission", "Anti platelet agent", "Anticoagulation Therapy", "Drug", "แรกรับ", "Beta-Blocker", "Aldosterone Antagonist", "SGLT2 Inhibitor", "Ivabradine", "Vericiguat", "Antiarrhythmic", "Ca channel blocker", "Anti-hyperglycemic medications", "DPP-4 Inhibitors", "GLP-1 receptor agonist", "Insulin",

            "Metformin", "Sulfonylurea", "Thiazolidinedione", "Other Oral Agents", "Other injectable/subcutaneous agents", "Digoxin",

            "Diuretic", "แรกรับ", "Hydralazine", "Nitrate", "Statin", "Other Lipid lowering agent", "NSAIDS/COXZI", "Other medications prior to enrolments",
            //Lab
            "CBC", "FBS", "BUN electrolyte", "LFT", "TFT", "NT-Pro BNP",
            //ผลการตรวจพิเศษต่างๆ
            "CXR", "Date", "EKG", "QRS Duration (ms)", "Not available", "QRS Morphology", "ระบุ", "Echocardiogram", "Date", "EF", "CAG", "Date", "6 MWT", "Date", "ระบุ", "Other"

        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];

        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {

            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //Heart Failure Data
            { v: data.data_1 }, //"HF No." 
            { v: data.data_2n }, //ส่วนสูง
            { v: data.data_3n }, //น้ำหนัก
            { v: data.data_4 }, //สถานภาพผู้ป่วย
            { v: data.data_5 }, //ศาสนา
            { v: data.data_6 }, //อาชีพ
            { v: data.data_7n }, //จำนวนสมาชิกในครอบครัว
            { v: data.data_8n }, //รายได้ของครอบครัวต่อเดือน
            { v: data.data_9 }, //ภูมิลำเนา
            { v: data.data_10 }, //ที่อยู่
            { v: data.data_11 }, //เบอร์โทรศัพท์ผู้ป่วย
            { v: data.data_12 }, //ชื่อผู้ดูแล
            { v: data.data_13 }, //ความสัมพันธ์กับผู้ป่วย
            { v: data.data_14 }, //เบอร์โทรศัพท์ผู้ดูแล
            { v: data.data_15 }, //ชื่อญาติ
            { v: data.data_16 }, //เบอร์โทรศัพท์ญาติ
            { v: data.data_17 }, //แหล่งสนับสนุนทางสังคมหรือผู้ให้ความช่วยเหลือทางการเงิน
            //2.
            { v: setDateFormat(data.data_18) }, //วันรับโครงการ
            { v: data.data_19 == "1" ? ("Yes") : ("") }, //IPD (AHF)
            { v: data.data_19 == "1" ? setDateFormat(data.data_20) : "" }, //Admit Date
            { v: data.data_19 == "1" ? setDateFormat(data.data_21) : "" }, //Discharge Date
            { v: data.data_19 == "2" ? ("Yes") : ("") }, //OPD
            { v: data.data_19 == "2" ? translateValue(data.data_22, schema22) : "" },//สถานะ
            { v: data.data_19 == "2" ? setDateFormat(data.data_23) : "" }, //Last Admit Date
            { v: data.data_19 == "2" ? setDateFormat(data.data_24) : "" }, //Last Discharge Date
            //3.
            { v: translateValue(data.data_25, schema25) }, //ประวัติการนอนโรงพยาบาล(ถ้ามี)
            { v: data.data_26 }, //รายละเอียด
            //4.
            { v: translateValue(data.data_27, schema27) }, //history of
            { v: data.data_28 }, // Ischemic/CAD
            { v: mergeData([data.data_29, data.data_30, data.data_31, data.data_32, data.data_33, data.data_34, data.data_35, data.data_36], schema29) }, //Non-Ischemic
            { v: translateValue(data.data_37, schema37) }, //Known history of HF prior to this visit ?
            { v: translateValue(data.data_38, schema38) },//# of hospital admissions in past 6 mo. for HF: 
            { v: data.data_39 }, // >2 ระบุ
            { v: translateValue(data.data_40, schema40) }, //Heart Failure Diagnosis
            { v: translateValue(data.data_41, schema41) }, //Atrial Fibrillation (At presentation or during hospitalization)
            { v: setDateFormat(data.data_197) }, //Documented New Onset?
            { v: translateValue(data.data_42, schema41) }, //Atrial Flutter (At presentation or during hospitalization)
            { v: setDateFormat(data.data_199) }, //Documented New Onset?
            { v: mergeData([data.data_43, data.data_44, data.data_45, data.data_46, data.data_47, data.data_48, data.data_49, data.data_50, data.data_51], schema43) }, //Characterization of HF at admission or when first recognized
            { v: data.data_203 }, //อื่นๆ ระบุ
            { v: mergeData([data.data_52, data.data_53, data.data_54, data.data_55, data.data_56, data.data_57, data.data_58, data.data_59], schema52) }, //Other Conditions Contributing to HF Exacerbation 
            { v: data.data_204 }, //"อื่นๆ ระบุ"
            //5.
            { v: mergeData([data.data_60, data.data_61, data.data_62, data.data_63, data.data_64, data.data_65, data.data_66, data.data_67, data.data_68], schema60) }, //"5. First presentation Symptoms (Closest to Admission)"
            { v: translateValue(data.data_69, schema69) }, //"NYHA class"
            {
                v: mergeData([data.data_70, data.data_71, data.data_72, data.data_73,
                data.data_74, data.data_75, data.data_76, data.data_77, data.data_78, data.data_79, data.data_80, data.data_81, data.data_82,
                data.data_83, data.data_84, data.data_85, data.data_86, data.data_87, data.data_88, data.data_89, data.data_90, data.data_91,
                data.data_92, data.data_93, data.data_94, data.data_95, data.data_96, data.data_97, data.data_98, data.data_99, data.data_100, data.data_101, data.data_102], schema70)
            }, //"Medical History" 
            { v: data.data_82 == "1" ? translateValue(data.data_103, schema82) : "" }, //"Emerging Infectious Disease"
            { v: translateValue(data.data_104, schema104) }, //Diabetes Type
            { v: translateValue(data.data_105, schema105) }, //Diabetes Duration
            { v: translateValue(data.data_106, schema106) }, //ระบุ
            { v: translateValue(data.data_107, schema107) }, //Sleep-Disordered Breathing Type:
            { v: translateValue(data.data_108, schema108) }, //Equipment used at home:
            { v: translateValue(data.data_109, schema37) }, //History of cigarette smoking? (In the past 12 months) 
            { v: translateValue(data.data_110, schema37) }, //History of vaping or e-cigarette use in the past 12 months?
            //Medications
            { v: mapValue(data.data_112) }, //Patient on no meds prior to admission
            { v: mapValue(data.data_114) }, //Anti platelet agent
            { v: mapValue(data.data_116) }, //Anticoagulation Therapy
            { v: translateValue(data.data_117, schema117) }, //Drug
            { v: mapValue(data.data_120) }, //แรกรับ
            { v: mapValue(data.data_122) }, //Beta-Blocker
            { v: mapValue(data.data_124) }, //Aldosterone Antagonist
            { v: mapValue(data.data_126) }, //"SGLT2 Inhibitor"
            { v: mapValue(data.data_128) }, //"Ivabradine"
            { v: mapValue(data.data_130) }, //"Vericiguat"
            { v: mapValue(data.data_132) }, //"Antiarrhythmic"
            { v: mapValue(data.data_134) }, //"Ca channel blocker"
            { v: mapValue(data.data_136) }, //"Anti-hyperglycemic medications:"
            { v: mapValue(data.data_138) }, //"DPP-4 Inhibitors"
            { v: mapValue(data.data_140) }, //"GLP-1 receptor agonist"
            { v: mapValue(data.data_142) },//"Insulin"

            { v: mapValue(data.data_144) }, //"Metformin"
            { v: mapValue(data.data_146) }, //"Sulfonylurea"
            { v: mapValue(data.data_148) }, //"Thiazolidinedione"
            { v: mapValue(data.data_150) }, //"Other Oral Agents"
            { v: mapValue(data.data_152) }, //"Other injectable/subcutaneous agents"
            { v: mapValue(data.data_154) }, //"Digoxin"

            { v: translateValue(data.data_156, schema156) }, //Diuretic
            { v: mapValue(data.data_157) }, //แรกรับ
            { v: mapValue(data.data_159) }, //"Hydralazine"
            { v: mapValue(data.data_161) }, //"Nitrate"
            { v: mapValue(data.data_165) }, //"Statin"
            { v: mapValue(data.data_188) }, //"Other Lipid lowering agent"
            { v: mapValue(data.data_167) }, //"NSAIDS/COXZI"
            { v: mapValue(data.data_169) }, //"Other medications prior to enrolments"
            //Lab
            { v: data.data_170 }, //"CBC"
            { v: data.data_171 }, //"FBS"
            { v: data.data_200 }, //"BUN electrolyte"
            { v: data.data_201 }, //"LFT"
            { v: data.data_202 }, //"TFT"
            { v: data.data_172 }, //"NT-Pro BNP"
            //ผลการตรวจพิเศษต่างๆ
            { v: data.data_173 == "1" ? "Yes" : "" }, //"CXR"
            { v: setDateFormat(data.data_174) }, //"Date"
            { v: data.data_175 == "1" ? "Yes" : "" }, //"EKG"
            { v: data.data_205 }, //"QRS Duration (ms)"
            { v: data.data_206 == "1" ? "Yes" : "" }, //"Not available"
            { v: translateValue(data.data_207, schema207) }, //"QRS Morphology"
            { v: data.data_208 }, //"ระบุ"
            { v: data.data_177 == "1" ? "Yes" : "" }, //"Echocardiogram"
            { v: setDateFormat(data.data_178) }, //"Date"
            { v: data.data_179 }, //"EF"
            { v: data.data_182 == "1" ? "Yes" : "" }, //"CAG"
            { v: setDateFormat(data.data_183) }, //"Date"
            { v: data.data_184 == "1" ? "Yes" : "" }, //"6 MWT"
            { v: setDateFormat(data.data_185) }, //"Date"
            { v: data.data_189 }, //"ระยะที่ได้"
            { v: data.data_187 }, //"อื่นๆ ระบุ"
            ]

            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }

        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }


    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}

const formCathLabDischarge = (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema1 = {
            1: "ตำแหน่งของแผล",
            2: "ลักษณะของแผล",
            3: "ภาวะแทรกซ้อน",
            4: "วิธีการดูแลแผลขณะอยู่ที่บ้าน"
        }
        const schema2 = {
            1: "เข้าใจดี",
            2: "ให้คำแนะนำเพิ่มเติม",
        }
        const schema3 = {
            1: "โรคเบาหวาน",
            2: "ความดันโลหิตสูง",
            3: "ระดับไขมันในเลือดสูง",
            4: "การเลิกบุหรี่"
        }
        const schema4 = {
            1: "การเข้ารับการตรวจตามนัดครั้งถัดไปเพื่อติดตามอาการหรือวางแผนการรักษา",
            2: "อาการผิดปกติที่ต้องมาพบแพทย์ก่อนวันตรวจตามนัด",
        }

        const schema8 = {
            0: "ไม่มีความรู้",
            1: "มีความรู้",
        }
        const schema29 = {
            0: "no Home med",
            1: "มี Home med ยาเพียงพอถึงวันนัดครั้งถัดไป / add ยาชนิดเพิ่มเติม",
        }
        const schema49 = {
            0: "ไม่ได้รับ",
            1: "ได้รับ",
        }
        const schema30 = {
            0: "สำเร็จ",
            1: "ไม่สำเร็จ",
        }
        const schema33 = {
            0: "ไม่มี",
            1: "มี",
        }
        const schema48 = {
            1: "hematoma",
            2: "bleeding",
            3: "อื่นๆ",
        }

        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",

            "เบอร์โทรผู้ป่วย", "เบอร์โทรญาติ",

            "วันที่จำหน่าย", "1. การให้คำแนะนำเกี่ยวกับการดูแลแผล",
            "เนื้อหาการให้คำแนะนำ", "ผลการให้คำแนะนำ", "ระบุ",

            "2. การให้คำแนะนำเกี่ยวกับการปฏิบัติกิจวัตรประจำวัน",
            "เนื้อหาการให้คำแนะนำ", "ผลการให้คำแนะนำ", "ระบุ",

            "3. การควบคุมโรคที่เป็นปัจจัยเสี่ยง", "เนื้อหาการให้คำแนะนำ", "ผลการให้คำแนะนำ", "ระบุ",

            "4. การให้คำแนะนำเกี่ยวกับการติดตามผลการรักษา", "เนื้อหาการให้คำแนะนำ", "ผลการให้คำแนะนำ", "ระบุ",

            "24 - 48 ชั่วโมงหลังจำหน่าย",

            "เนื้อหาการประเมิน", "ดูแลแผลไม่ให้โดนน้ำ 3 วัน", "หากมีเลือดออกให้กดเหนือรอยเข็มอย่างน้อย 10 นาที ถ้าไม่หยุด ให้รีบไปพบแพทย์",
            "สังเกตอาการผิดปกติ เช่น เลือดออก แผลบวม ปวดแผลมาก ให้รีบไปพบแพทย์", "หลีกเลี่ยงการออกกำลังหรือทำกิจกรรมที่ใช้กำลังมาก",
            "หลีกเลี่ยงปัจจัยเสี่ยง เช่น อาหารที่มีรสเค็มจัด การสูบบุหรี่ การดิ่มเครื่องดื่มแอลกอฮอล์", "ผลการรักษา", "แผนการรักษา",

            "1. การรักษาต่อเนื่อง", "กรณี F/U รพ.มหาราชนครเชียงใหม่",
            "ใบนัดที่", "OPD", "วันที่", "การเตรียมตัวเมื่อมาตามนัดครั้งถัดไป",

            "กรณี F/U ต่อที่ รพ.อื่น", "ระบุ", "ผลการตรวจสวนหัวใจ", "จดหมายตอบโรงพยาบาลต้นสังกัด",

            "2. Medication", "Status",
            "3. เอกสารคำแนะนำการดูแลตนเอง", "Status",
            "4. การนำเข้าสู่ line application consult for Same-day patient", "Status", "เนื่องจาก", "ลงชื่อ",

            "การติดตามผู้ป่วย 24 - 48 ชั่วโมงหลังตรวจ", "1. ภาวะแทรกซ้อน", "Status", "ระบุ", "2. ระดับความวิตกกังวล (0-10 คะแนน)", "3. ระดับความเจ็บปวดแผล (0-10 คะแนน)",
            "4. ระดับความพึงพอใจ (0-10 คะแนน)", "ลงชื่อ", "date", "เหตุผลในการแก้ไข"
        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];
        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {
            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //Cathlab CMU discharge set box
            { v: data.data_1 }, //"เบอร์โทรผู้ป่วย" 
            { v: data.data_2 }, //"เบอร์โทรญาติ"

            { v: setDateFormat(data.data_3) }, //วันที่จำหน่าย
            { v: "->" }, //1. การให้คำแนะนำเกี่ยวกับการดูแลแผล
            { v: mergeData([data.data_5, data.data_6, data.data_50, data.data_51], schema1) }, //เนื้อหาการให้คำแนะนำ
            { v: mergeData([data.data_52, data.data_53], schema2) }, //ผลการให้คำแนะนำ
            { v: data.data_7 }, //ระบุ

            { v: "->" }, //2. การให้คำแนะนำเกี่ยวกับการปฏิบัติกิจวัตรประจำวัน
            { v: data.data_54 ? "สัปดาห์แรกหลังตรวจให้หลีกเลี่ยงการออกกำลังหรือกิจกรรมที่ใช้กำลังมาก" : "" }, //เนื้อหาการให้คำแนะนำ
            { v: mergeData([data.data_55, data.data_56], schema2) }, //ผลการให้คำแนะนำ
            { v: data.data_13 }, //ระบุ

            { v: "->" }, //3. การควบคุมโรคที่เป็นปัจจัยเสี่ยง
            { v: ((data.data_15 == "1") && data.data_15) ? mergeData([data.data_41, data.data_42, data.data_43, data.data_44], schema3) : (data.data_15 == "0" ? "ไม่มีปัจจัยเสี่ยง" : "") }, //เนื้อหาการให้คำแนะนำ
            { v: mergeData([data.data_57, data.data_58], schema2) }, //ผลการให้คำแนะนำ
            { v: data.data_17 }, //ระบุ

            { v: "->" }, //4. การให้คำแนะนำเกี่ยวกับการติดตามผลการรักษา
            { v: mergeData([data.data_59, data.data_60], schema4) }, //เนื้อหาการให้คำแนะนำ
            { v: mergeData([data.data_61, data.data_62], schema2) }, //ผลการให้คำแนะนำ
            { v: data.data_21 }, //ระบุ

            { v: setDateFormat(data.data_4) }, //"24 - 48 ชั่วโมงหลังจำหน่าย"

            { v: "->" }, //เนื้อหาการประเมิน
            { v: translateValue(data.data_8, schema8) }, //ดูแลแผลไม่ให้โดนน้ำ 3 วัน
            { v: translateValue(data.data_9, schema8) },//หากมีเลือดออกให้กดเหนือรอยเข็มอย่างน้อย 10 นาที ถ้าไม่หยุด ให้รีบไปพบแพทย์
            { v: translateValue(data.data_10, schema8) }, //สังเกตอาการผิดปกติ เช่น เลือดออก แผลบวม ปวดแผลมาก ให้รีบไปพบแพทย์
            { v: translateValue(data.data_14, schema8) }, //หลีกเลี่ยงการออกกำลังหรือทำกิจกรรมที่ใช้กำลังมาก
            { v: translateValue(data.data_18, schema8) }, //หลีกเลี่ยงปัจจัยเสี่ยง เช่น อาหารที่มีรสเค็มจัด การสูบบุหรี่ การดิ่มเครื่องดื่มแอลกอฮอล์
            { v: translateValue(data.data_22, schema8) }, //ผลการรักษา
            { v: translateValue(data.data_23, schema8) }, //แผนการรักษา
            //4.
            { v: "->" }, // 1. การรักษาต่อเนื่อง
            { v: data.data_40 == "1" ? ("Yes") : ("") }, //กรณี F/U รพ.มหาราชนครเชียงใหม่
            { v: data.data_40 == "1" ? data.data_24 : "" },//ใบนัดที่
            { v: data.data_40 == "1" ? data.data_25 : "" }, //OPD
            { v: data.data_40 == "1" ? setDateFormat(data.data_26) : "" }, //วันที่
            { v: data.data_40 == "1" ? (data.data_45 == "1" ? ("Yes") : ("")) : "" }, //การเตรียมตัวเมื่อมาตามนัดครั้งถัดไป

            { v: data.data_40 == "2" ? ("Yes") : ("") }, //กรณี F/U ต่อที่ รพ.อื่น
            { v: data.data_40 == "2" ? data.data_27 : "" }, //ระบุ
            { v: data.data_40 == "2" ? translateValue(data.data_46, schema49) : "" }, //ผลการตรวจสวนหัวใจ
            { v: data.data_40 == "2" ? translateValue(data.data_47, schema49) : "" }, //จดหมายตอบโรงพยาบาลต้นสังกัด

            { v: "->" }, // 2. Medication
            { v: translateValue(data.data_29, schema29) },//Status

            { v: "->" }, //3. เอกสารคำแนะนำการดูแลตนเอง
            { v: translateValue(data.data_49, schema49) }, //Status

            { v: "->" }, //4. การนำเข้าสู่ line application consult for Same-day patient
            { v: translateValue(data.data_30, schema30) }, //Status
            { v: data.data_31 }, //เนื่องจาก
            { v: data.data_32 }, //ลงชื่อ

            { v: "->" }, //การติดตามผู้ป่วย 24 - 48 ชั่วโมงหลังตรวจ
            { v: translateValue(data.data_33, schema33) }, //1. ภาวะแทรกซ้อน
            { v: data.data_33 == "1" ? translateValue(data.data_48, schema48) : "" }, //Status
            { v: data.data_33 == "1" && data.data_48 == "3" ? data.data_34 : "" }, //ระบุ
            { v: data.data_35 }, //2. ระดับความวิตกกังวล (0-10 คะแนน)
            { v: data.data_36 }, //3. ระดับความเจ็บปวดแผล (0-10 คะแนน)
            { v: data.data_37 }, //4. ระดับความพึงพอใจ (0-10 คะแนน)
            { v: data.data_38 }, //ลงชื่อ
            { v: setDateFormat(data.data_39) }, //date
            { v: data.data_64 }, //เหตุผลในการแก้ไข
            ]
            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }
        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }


    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}

const formHeartFailureDischargeCheckList = (value, filter) => {
    try {
        const schema1 = { 1: "Yes", 2: "No" }

        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",

            "1. ปัจจัยที่ทำให้อาการกำเริบ * ได้รับการค้นหาและแก้ไขแล้ว",
            "Reason Not Done/Contraindications",
            "2.ได้รับการตรวจ Echocardiogram แล้ว (ในรายที่ไม่เคยตรวจมาก่อน)", "EF", "Reason Not Done/Contraindications",
            "3.ไม่มีภาวะน้ำ เกินหรือขาดสารน้ำ อย่างชัดเจน", "Reason Not Done/Contraindications",
            "4. หยุด NTG, Dobutamine และ Furosemide ที่ให้ทางหลอดเลือดดำมากเกิน 24 ชม.", "Reason Not Done/Contraindications",
            "5. เปลี่ยนจากยาขับปัสสาวะแบบฉีดเป็นแบบรับประทานแล้วและได้ประเมินผลว่ายาขับปัสสาวะแบบรับประทานสามารถควบคุมอาการผู้ป่วยได้",
            "Reason Not Done/Contraindications", "6. ไม่มีความจำเป็นในการปรับยารับประทานมาเกิน 24 ชั่วโมง",
            "Reason Not Done/Contraindications",
            "7. ค่าการทำงานของไตและเกลือแร่คงที่และอยู่ในระดับปกติหรือใกล้เคียงปกติตาม baseline ของผู้ป่วย", "Reason Not Done/Contraindications",
            "8. ในผู้ป่วย HFrEF ได้เริ่มยาดังนี้(ในรายใหม่) หรือปรับ dose ให้เหมาะสมแล้ว (ในรายเก่า) หากไม่สามารถให้ยาได้หรือจำเป็นต้องลดขนาดยาได้มีการบันทึกเหตุผลไว้แล้ว",
            "Reason Not Done/Contraindications", "-ACEI/ARB/ARNI", "Reason Not Done/Contraindications",
            "-Beta-blocker", "Reason Not Done/Contraindications", "-Aldosterone Receptor Antagonists",
            "Reason Not Done/Contraindications", "-SGLT2 inhibitor", "Reason Not Done/Contraindications",
            "9. ปัจจัยเสี่ยงของโรคหัวใจและหลอดเลือดได้รับการประเมินและควบคุมแล้ว เช่น ระดับ LDL, FBS, HbA1C",
            "Reason Not Done/Contraindications",
            "10. ผู้ป่วยได้รับการให้ความรู้เรื่องการปฏิบัติตัวในการดูแลตนเองสำหรับโรคหัวใจล้มเหลวโดยพยาบาลประจำหอผู้ป่วยหรือพยาบาลประจำ HF clinic",
            "Reason Not Done/Contraindications", "11. มีการปรึกษาเพื่อนัดเข้าคลินิกหัวใจล้มเหลว ในรายที่เข้าเกณฑ์",
            "Reason Not Done/Contraindications", "12. มีการนัดผู้ป่วย 1-2 สัปดาห์หลังจำหน่ายที่เรียบร้อยแล้ว",
            "Reason Not Done/Contraindications", "1.ให้ผู้ป่วยเริ่ม Ambulate เพื่อประเมิน Function Capacity",
            "Reason Not Done/Contraindications", "2. การสอนและให้คำแนะนำในหัวข้อดังนี้",
            "- การเฝ้าระวังภาวะน้ำหนักเกิน สังเกตอาการบวม การชั่งน้ำหนัก (แนะนำให้ผู้ป่วยมีเครื่องชั่งน้ำหนักที่บ้าน)",
            "Reason Not Done/Contraindications", "- การรับประทานยาอย่างต่อเนื่องและถูกต้อง หลีกเลี่ยงยาสมุนไพรยา NSAID",
            "Reason Not Done/Contraindications", "- การงดเหล้าและบุหรี่", "Reason Not Done/Contraindications",
            "- การหลีกเลี่ยงอาหารรสเค็ม การจำกัดน้ำดื่ม (ในบางรายการ) โดยเฉพาะผู้ป่วยที่ยังบวม, ผู้ป่วยโรคไต",
            "Reason Not Done/Contraindications", "- การกลับไปทำงาน การทำกิจกรรมและการออกกำลังกายที่เหมาะสม",
            "Reason Not Done/Contraindications", "- การสอนการปรับยาขับปัสสาวะโดยผู้ป่วยเอง (Flexible diuretic regimen)",
            "Reason Not Done/Contraindications"
        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];
        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {
            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //Cathlab CMU discharge set box
            { v: translateValue(data.data_1, schema1) }, //1. ปัจจัยที่ทำให้อาการกำเริบ * ได้รับการค้นหาและแก้ไขแล้ว
            { v: data.data_2 }, //"Reason Not Done/Contraindications"
            { v: translateValue(data.data_3, schema1) }, //2.ได้รับการตรวจ Echocardiogram แล้ว (ในรายที่ไม่เคยตรวจมาก่อน)
            { v: data.data_105n + " %" }, //EF
            { v: data.data_4 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_5, schema1) }, //3.ไม่มีภาวะน้ำ เกินหรือขาดสารน้ำ อย่างชัดเจน
            { v: data.data_6 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_7, schema1) }, //4. หยุด NTG, Dobutamine และ Furosemide ที่ให้ทางหลอดเลือดดำมากเกิน 24 ชม.
            { v: data.data_8 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_9, schema1) }, //5. เปลี่ยนจากยาขับปัสสาวะแบบฉีดเป็นแบบรับประทานแล้วและได้ประเมินผลว่ายาขับปัสสาวะแบบรับประทานสามารถควบคุมอาการผู้ป่วยได้
            { v: data.data_10 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_11, schema1) }, //6. ไม่มีความจำเป็นในการปรับยารับประทานมาเกิน 24 ชั่วโมง
            { v: data.data_12 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_13, schema1) }, //7. ค่าการทำงานของไตและเกลือแร่คงที่และอยู่ในระดับปกติหรือใกล้เคียงปกติตาม baseline ของผู้ป่วย
            { v: data.data_14 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_15, schema1) }, //8. ในผู้ป่วย HFrEF ได้เริ่มยาดังนี้(ในรายใหม่) หรือปรับ dose ให้เหมาะสมแล้ว (ในรายเก่า) หากไม่สามารถให้ยาได้หรือจำเป็นต้องลดขนาดยาได้มีการบันทึกเหตุผลไว้แล้ว
            { v: data.data_16 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_17, schema1) }, //ACEI/ARB/ARNI
            { v: data.data_18 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_19, schema1) }, //Beta-blocker
            { v: data.data_20 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_21, schema1) }, //Aldosterone Receptor Antagonists
            { v: data.data_22 },//Reason Not Done/Contraindications
            { v: translateValue(data.data_23, schema1) }, //SGLT2 inhibitor
            { v: data.data_24 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_25, schema1) }, //9. ปัจจัยเสี่ยงของโรคหัวใจและหลอดเลือดได้รับการประเมินและควบคุมแล้ว เช่น ระดับ LDL, FBS, HbA1C
            { v: data.data_26 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_27, schema1) }, //10. ผู้ป่วยได้รับการให้ความรู้เรื่องการปฏิบัติตัวในการดูแลตนเองสำหรับโรคหัวใจล้มเหลวโดยพยาบาลประจำหอผู้ป่วยหรือพยาบาลประจำ HF clinic
            { v: data.data_28 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_29, schema1) }, //11. มีการปรึกษาเพื่อนัดเข้าคลินิกหัวใจล้มเหลว ในรายที่เข้าเกณฑ์
            { v: data.data_30 },//Reason Not Done/Contraindications
            { v: translateValue(data.data_31, schema1) }, //12. มีการนัดผู้ป่วย 1-2 สัปดาห์หลังจำหน่ายที่เรียบร้อยแล้ว
            { v: data.data_32 }, //Reason Not Done/Contraindications
            //การวางแผนการจำหน่ายเน้นประเด็นเหล่านี้
            { v: translateValue(data.data_33, schema1) }, //1.ให้ผู้ป่วยเริ่ม Ambulate เพื่อประเมิน Function Capacity
            { v: data.data_34 }, //Reason Not Done/Contraindications
            { v: "->" }, //2. การสอนและให้คำแนะนำในหัวข้อดังนี้
            { v: translateValue(data.data_37, schema1) }, //- การเฝ้าระวังภาวะน้ำหนักเกิน สังเกตอาการบวม การชั่งน้ำหนัก (แนะนำให้ผู้ป่วยมีเครื่องชั่งน้ำหนักที่บ้าน)
            { v: data.data_38 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_39, schema1) },//- - การรับประทานยาอย่างต่อเนื่องและถูกต้อง หลีกเลี่ยงยาสมุนไพรยา NSAID
            { v: data.data_40 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_41, schema1) }, //- การงดเหล้าและบุหรี่
            { v: data.data_42 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_43, schema1) }, //- การหลีกเลี่ยงอาหารรสเค็ม การจำกัดน้ำดื่ม (ในบางรายการ) โดยเฉพาะผู้ป่วยที่ยังบวม, ผู้ป่วยโรคไต
            { v: data.data_44 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_45, schema1) }, //- การกลับไปทำงาน การทำกิจกรรมและการออกกำลังกายที่เหมาะสม
            { v: data.data_46 }, //Reason Not Done/Contraindications
            { v: translateValue(data.data_47, schema1) }, //- การสอนการปรับยาขับปัสสาวะโดยผู้ป่วยเอง (Flexible diuretic regimen)
            { v: data.data_48 }, //Reason Not Done/Contraindications
            ]
            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }
        })

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx")
        return { status: "success" }
    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}
const formMinnesotaLivingWithHeartFailure = (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema1 = {
            0: "ไม่มีผลกระทบ",
            1: "เล็กน้อย",
            2: "น้อย",
            3: "ปานกลาง",
            4: "มาก",
            5: "มากที่สุด"
        }
        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",

            "1. ทำให้มีอาการบวมที่ปลายมือ ปลายเท้า ขา และข้อเท้า",
            "2. ทำให้ต้องนั่งพักหรือนอนตอนกลางวันหรือในระหว่างพักงาน",
            "3. ทำให้เดินหรือขึ้นลงบันไดลำบาก",
            "4. ทำให้การทำงานบ้านหรืองานสวนเหนื่อยเร็ว",
            "5. ทำให้เดินทางไปในที่ไกลๆ ลำบาก",
            "6. ทำให้นอนไม่หลับตอนกลางคืนหรือนอนหลับแล้วลุกขึ้นมาหายใจหอบตอนกลางคืน",
            "7. ทำให้การพบปะสังสรรค์กับเพื่อนหรือการทำกิจกรรมร่วมกับเพื่อนหรือครอบครัวลดลง",
            "8. ทำให้ทำงานได้ลดลง",
            "9. ไม่สามารถทำงานนอกเวลา ทำอาชีพเสริม ทำงานอดิเรกหรือเล่นกีฬาได้",
            "10. ทำให้มีผลกระทบต่อการมีเพศสัมพันธ์",
            "11. รับประทานอาหารได้น้อยลงหรือเบื่ออาหาร",
            "12. ทำให้หายใจตื้น หายใจลำบาก",
            "13. เหนื่อย เมื่อยล้า อ่อนเพลีย แม้ทำกิจกรรมเบาๆ",
            "14. ทำให้ต้องนอนเข้ารักษาตัวในโรงพยาบาล",
            "15. ทำให้เสียค่าใช้จ่ายในการรักษาพยาบาลมาก",
            "16. ทำให้เกิดภาวะข้างเคียงจากการรับประทานยา",
            "17. ทำให้ห่างเหินจากเพื่อนและครอบครัว",
            "18. ทำให้สูญเสียการควบคุมในชีวิต",
            "19. ทำให้เกิดความวิตกกังวล",
            "20. ทำให้สนใจสิ่งต่างๆ ลดลง และจดจำสิ่งต่างๆ ลดลง",
            "21. ทำให้รู้สึกซึมเศร้า"
        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];
        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {
            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //Cathlab CMU discharge set box
            { v: translateValue(data.data_84, schema1) }, //1. ทำให้มีอาการบวมที่ปลายมือ ปลายเท้า ขา และข้อเท้า
            { v: translateValue(data.data_85, schema1) }, //2. ทำให้ต้องนั่งพักหรือนอนตอนกลางวันหรือในระหว่างพักงาน
            { v: translateValue(data.data_86, schema1) }, //3. ทำให้เดินหรือขึ้นลงบันไดลำบาก
            { v: translateValue(data.data_87, schema1) }, //4. ทำให้การทำงานบ้านหรืองานสวนเหนื่อยเร็ว
            { v: translateValue(data.data_88, schema1) }, //5. ทำให้เดินทางไปในที่ไกลๆ ลำบาก
            { v: translateValue(data.data_89, schema1) }, //6. ทำให้นอนไม่หลับตอนกลางคืนหรือนอนหลับแล้วลุกขึ้นมาหายใจหอบตอนกลางคืน
            { v: translateValue(data.data_90, schema1) }, //7. ทำให้การพบปะสังสรรค์กับเพื่อนหรือการทำกิจกรรมร่วมกับเพื่อนหรือครอบครัวลดลง
            { v: translateValue(data.data_91, schema1) }, //8. ทำให้ทำงานได้ลดลง
            { v: translateValue(data.data_92, schema1) }, //9. ไม่สามารถทำงานนอกเวลา ทำอาชีพเสริม ทำงานอดิเรกหรือเล่นกีฬาได้
            { v: translateValue(data.data_93, schema1) }, //10. ทำให้มีผลกระทบต่อการมีเพศสัมพันธ์
            { v: translateValue(data.data_94, schema1) }, //11. รับประทานอาหารได้น้อยลงหรือเบื่ออาหาร
            { v: translateValue(data.data_95, schema1) }, //12. ทำให้หายใจตื้น หายใจลำบาก
            { v: translateValue(data.data_96, schema1) }, //13. เหนื่อย เมื่อยล้า อ่อนเพลีย แม้ทำกิจกรรมเบาๆ
            { v: translateValue(data.data_97, schema1) }, //14. ทำให้ต้องนอนเข้ารักษาตัวในโรงพยาบาล
            { v: translateValue(data.data_98, schema1) }, //15. ทำให้เสียค่าใช้จ่ายในการรักษาพยาบาลมาก
            { v: translateValue(data.data_99, schema1) }, //16. ทำให้เกิดภาวะข้างเคียงจากการรับประทานยา
            { v: translateValue(data.data_100, schema1) }, //17. ทำให้ห่างเหินจากเพื่อนและครอบครัว
            { v: translateValue(data.data_101, schema1) }, //18. ทำให้สูญเสียการควบคุมในชีวิต
            { v: translateValue(data.data_102, schema1) }, //19. ทำให้เกิดความวิตกกังวล
            { v: translateValue(data.data_103, schema1) }, //20. ทำให้สนใจสิ่งต่างๆ ลดลง และจดจำสิ่งต่างๆ ลดลง
            { v: translateValue(data.data_104, schema1) }, //21. ทำให้รู้สึกซึมเศร้า
            ]
            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }
        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }


    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}
const formHeartFailureClinic = (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema3 = { 1: "มี", 2: "ไม่มี" }
        const schema26 = { 1: "มี", 0: "ไม่มี" }
        const schema5 = {
            1: "I ไม่มีอาการเหนื่อย",
            2: "II มีอาการเหนื่อยเล็กน้อยขณะออกแรง",
            3: "III มีอาการเหนื่อยเมื่อทำกิจวัตรประจำวัน",
            4: "IV มีอาการเหนื่อยขณะที่พักอยู่เฉยๆ"
        }

        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",

            "ครั้งที่", "วันที่",
            "มีอาการหายใจเหนื่อยหอบหรือหายใจลำบากในวันที่ผ่านมาหรือไม่", "หมายเหตุ",

            "ประเมิน NYHA Functional class", "หมายเหตุ",

            " มีอาการหายใจลำบากหลังจากนอนหลับในตอนกลางคืน พอลุกมาแล้วดีขึ้นในวันที่ผ่านมาหรือไม่ (PND)", "หมายเหตุ",

            "ไม่สามารถนอนราบได้เนื่องจากมีความผิดปกติของการหายใจในวันที่ผ่านมาหรือไม่", "หมายเหตุ"

            , "มีอาการบวมของร่างกายในวันที่ผ่านมาหรือไม่ ตำแหน่งไหน (Edema)", "ผลการให้คำแนะนำ",

            "น้ำหนักเดิม", "น้ำหนักใหม่", "หมายเหตุ",
            "ความดันโลหิต", "ชีพจรที่บ้าน (ถ้ามีเครื่องวัดความดันโลหิต)", "หมายเหตุ",

            "มีอาการอ่อนเพลียในวันที่ผ่านมาหรือไม่ (Fatigue)", "หมายเหตุ",

            "มีอาการเวียนศีรษะ หรือหน้ามืดในวันที่ผ่านมาหรือไม่", "หมายเหตุ",

            "โดยรวมแล้วในวันที่ผ่านมาคุณรู้สึกดีขึ้นหรือแย่ลงกว่าเดิม (QOL)", "หมายเหตุ",
            "คุณมีคำถามที่จะต้องการถามพยาบาลหรือไม่", "หมายเหตุ",
            "ER visit", "หมายเหตุ", "Re admit", "หมายเหตุ",
            "อาหารที่รับประทาน/การหลีกเลี่ยงอาหารรสเค็ม", "การดื่มน้ำ",
            "การรับประทานยาต่อเนื่อง", "การเฝ้าระวังภาวะสารน้ำเกิน/การปรับยาขับปัสสาวะ",
            "การทำกิจกรรม", "การออกกำลังกายที่เหมาะสม", "การงดดื่มเหล้าและสูบบุหรี่",
            "การหลีกเลี่ยงการใช้สมุนไพร/ยา NSAIDS", "คำแนะนำให้กับผู้ป่วย"
        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];
        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {
            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //Cathlab CMU discharge set box
            { v: data.data_1n }, //"ครั้งที่" 
            { v: data.data_2 }, //"วันที่"
            { v: translateValue(data.data_3, schema3) }, //มีอาการหายใจเหนื่อยหอบหรือหายใจลำบากในวันที่ผ่านมาหรือไม่
            { v: data.data_4 }, //หมายเหตุ
            { v: translateValue(data.data_5, schema5) }, //ประเมิน NYHA Functional class
            { v: data.data_6 }, //หมายเหตุ
            { v: translateValue(data.data_7, schema3) }, //ไม่สามารถนอนราบได้เนื่องจากมีความผิดปกติของการหายใจในวันที่ผ่านมาหรือไม่ (Orthopnea)
            { v: data.data_8 }, //หมายเหตุ
            { v: translateValue(data.data_9, schema3) }, // มีอาการหายใจลำบากหลังจากนอนหลับในตอนกลางคืน พอลุกมาแล้วดีขึ้นในวันที่ผ่านมาหรือไม่ (PND)
            { v: data.data_10 }, //หมายเหตุ
            { v: translateValue(data.data_11, schema3) }, //มีอาการบวมของร่างกายในวันที่ผ่านมาหรือไม่ ตำแหน่งไหน (Edema)
            { v: data.data_12 }, //หมายเหตุ
            { v: data.data_13n }, //น้ำหนักเดิม
            { v: data.data_14n }, //น้ำหนักใหม่
            { v: data.data_15 }, //หมายเหตุ
            { v: data.data_16 }, //ความดันโลหิต
            { v: data.data_39n }, //ชีพจรที่บ้าน (ถ้ามีเครื่องวัดความดันโลหิต)
            { v: data.data_40 }, //หมายเหตุ
            { v: translateValue(data.data_18, schema3) }, //มีอาการอ่อนเพลียในวันที่ผ่านมาหรือไม่ (Fatigue)
            { v: data.data_19 }, //หมายเหตุ
            { v: translateValue(data.data_20, schema3) }, //มีอาการเวียนศีรษะ หรือหน้ามืดในวันที่ผ่านมาหรือไม่
            { v: data.data_21 }, //หมายเหตุ
            { v: data.data_22 },//โดยรวมแล้วในวันที่ผ่านมาคุณรู้สึกดีขึ้นหรือแย่ลงกว่าเดิม (QOL)
            { v: data.data_23 }, //หมายเหตุ
            { v: translateValue(data.data_24, schema3) }, //คุณมีคำถามที่จะต้องการถามพยาบาลหรือไม่
            { v: data.data_25 }, //หมายเหตุ
            { v: translateValue(data.data_26, schema26) }, //ER visit
            { v: data.data_28 }, //หมายเหตุ
            { v: translateValue(data.data_27, schema26) }, // Re admit
            { v: data.data_41 }, //หมายเหตุ
            { v: data.data_29 },//อาหารที่รับประทาน/การหลีกเลี่ยงอาหารรสเค็ม
            { v: data.data_30 }, //การดื่มน้ำ
            { v: data.data_31 }, //การรับประทานยาต่อเนื่อง
            { v: data.data_33 }, //การเฝ้าระวังภาวะสารน้ำเกิน/การปรับยาขับปัสสาวะ
            { v: data.data_34 }, //การทำกิจกรรม
            { v: data.data_35 }, //การออกกำลังกายที่เหมาะสม
            { v: data.data_36 }, //การงดดื่มเหล้าและสูบบุหรี่
            { v: data.data_37 }, //การหลีกเลี่ยงการใช้สมุนไพร/ยา NSAIDS
            { v: data.data_38 }, // คำแนะนำให้กับผู้ป่วย
            ]
            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }
        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }


    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}
const formHeartFailureOPD = (value, filter) => {
    try {
        const gender = {
            "ช": "ชาย",
            "ชาย": "ชาย",
            "ญ": "หญิง",
            "หญิง": "หญิง",
        }
        const schema13 = { 1: "I", 2: "II", 3: "III", 4: "IV" }
        const schema125 = { 1: "Yes", 0: "No" }
        const schema31 = {
            1: "Yes",
            2: "No",
        }
        const schema33 = {
            1: "Yes",
            0: "No",
        }
        //------------------
        const labelForm = [
            "ชื่อผู้ป่วย", "hn", "txn", "เพศ", "วันเกิด", "ชื่อผู้บันทึก",
            "วันที่บันทึก", "เวลา",
            "Date", "BW", "เพิ่มเติม", "HR", "BP", "RR", "BP", "SaO2", "BP", "Functional Class", "Nurse note", "ER visit", "ER admit", "Pharmacist note",
            "PT", "PTT", "INR",

            "Na", "K", "Cl", "HCO3", "BUN", "FBS", "Cr",
            "Date", "EF", "NT-pro BNP",

            "Doctor note รายละเอียดการซักประวัติ ตรวจร่างกาย", "การคุมกำเนิด ",
            "Flu. vaccine", "ปี", "Covid vaccine", "วันที่ฉีดยาล่าสุด",

            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Drug", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Other antihypertensive", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Other2", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Other3", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
            "Other4", "รายการยาเดิม", "As Ordered", "Actual", "จํานวนยาคงเหลือ", "สรุปจํานวนยา",
        ]

        const startDate = setDateFormat(filter.date.startDate)
        const endDate = setDateFormat(filter.date.startDate)
        const header = [
            { 'วันที่': `${startDate} to ${endDate}`, 'HN': filter.hn, 'Form Type': value[0].form_type_name },
        ];

        const ws = setWorkSheet(header, labelForm, value)

        value.forEach((data, index) => {

            const rowIndex = index + 4; // นับแถวเริ่มต้นที่ A4

            //handle day
            const birthday = splitDateTime(data.birthday);
            const created_date = splitDateTime(data.created_date);

            //set values
            const values = [{ v: data.member_name },
            { v: data.hn },
            { v: data.txn },
            { v: translateValue(data.member_gender, gender) },
            { v: birthday[0] },
            { v: data.consult_name },
            { v: created_date[0] },
            { v: created_date[1] },
            //db
            //Heart Failure Data
            { v: setDateFormat(data.data_1) }, //"Date" 
            { v: data.data_2 }, //BW
            { v: data.data_3 }, //เพิ่มเติม
            { v: data.data_4 }, //HR
            { v: data.data_5 + " / " + data.data_6 + " (นอน) " }, //BP
            { v: data.data_7 }, //RR
            { v: data.data_8 + " / " + data.data_9 + " (นั่ง) " }, //BP
            { v: data.data_10 }, //SaO2
            { v: data.data_11 + " / " + data.data_12 + " (ยืน) " }, //BP
            { v: translateValue(data.data_13, schema13) }, //Functional Class
            { v: data.data_14 }, //Nurse note
            { v: translateValue(data.data_125, schema125) }, //ER visit
            { v: translateValue(data.data_126, schema125) }, //ER admit
            { v: data.data_15 }, //Pharmacist note

            { v: data.data_16 }, //PT
            { v: data.data_17 }, //PTT
            { v: data.data_18 }, //INR
            //2.
            { v: data.data_19 }, //Na
            { v: data.data_20 }, //K
            { v: data.data_21 }, //Cl
            { v: data.data_22 }, //HCO3
            { v: data.data_23 }, //BUN
            { v: data.data_24 },//FBS
            { v: data.data_25 }, //Cr

            { v: setDateFormat(data.data_26) }, //Date
            { v: data.data_27 }, //EF
            { v: data.data_28 }, //NT-pro BNP

            { v: data.data_29 }, //Doctor note รายละเอียดการซักประวัติ ตรวจร่างกาย
            { v: data.data_30 }, //การคุมกำเนิด 
            { v: translateValue(data.data_31, schema31) }, //Flu. vaccine
            { v: data.data_31 == "1" ? data.data_32 : "" },//ปี
            { v: translateValue(data.data_33, schema33) }, //Covid vaccine
            { v: data.data_33 == "1" ? setDateFormat(data.data_124) : "" }, //วันที่ฉีดยาล่าสุด

            { v: "Antiplatelet" }, //Drug
            { v: data.data_34 }, //รายการยาเดิม
            { v: data.data_35 }, //As Ordered
            { v: data.data_36 }, //Actual
            { v: data.data_37 }, //จํานวนยาคงเหลือ
            { v: data.data_38 }, //สรุปจํานวนยา

            { v: "Anticoagulant" },//Drug
            { v: data.data_39 }, // 
            { v: data.data_40 }, //
            { v: data.data_41 }, //
            { v: data.data_42 }, //
            { v: data.data_43 }, //
            { v: "ACEI/ARB/ARNI" }, //Drug
            { v: data.data_44 }, //
            { v: data.data_45 }, //
            { v: data.data_46 }, //
            { v: data.data_47 }, //
            { v: data.data_48 }, //
            { v: "β-Blocker" }, //Drug
            { v: data.data_49 }, //
            { v: data.data_50 }, //
            { v: data.data_51 }, //
            { v: data.data_52 },
            { v: data.data_53 },

            { v: "Spironolactone" }, //Drug
            { v: data.data_54 }, //
            { v: data.data_55 }, //
            { v: data.data_56 }, //
            { v: data.data_57 }, //
            { v: data.data_58 }, //
            { v: "SGLT2i" }, //Drug
            { v: data.data_59 }, //
            //5.
            { v: data.data_60 }, //
            { v: data.data_61 }, //
            { v: data.data_62 }, //
            { v: data.data_63 }, //
            { v: "Ivabradine" }, //Drug
            { v: data.data_64 }, //
            { v: data.data_65 }, //
            { v: data.data_66 }, //
            { v: data.data_67 }, //
            { v: data.data_68 }, //

            { v: "Digoxin" }, //Drug
            { v: data.data_69 }, //
            { v: data.data_70 }, //
            { v: data.data_71 }, //
            { v: data.data_72 }, //
            { v: data.data_73 }, //
            { v: "Vericiguat" }, //Drug
            { v: data.data_74 }, //
            { v: data.data_75 }, //
            { v: data.data_76 }, //
            { v: data.data_77 }, //
            { v: data.data_78 }, //
            { v: "Nitrate" }, //Drug
            { v: data.data_79 }, //
            { v: data.data_80 }, //"
            { v: data.data_81 }, //
            { v: data.data_82 }, //
            { v: data.data_83 }, //
            //Discharge summary physician27
            { v: "Hydralazine" }, //Drug
            { v: data.data_84 }, //
            { v: data.data_85 }, //
            { v: data.data_86 }, //
            { v: data.data_87 }, //
            { v: data.data_88 }, //
            { v: "Diuretic" }, //Drug
            { v: data.data_89 }, //
            { v: data.data_90 }, //
            { v: data.data_91 }, //
            { v: data.data_92 }, //
            { v: data.data_93 }, //
            { v: "Lipid lowering agent" }, //Drug
            { v: data.data_94 }, //
            { v: data.data_95 }, //
            { v: data.data_96 }, //
            { v: data.data_97 }, //
            { v: data.data_98 }, //
            { v: "Antidiabetic agent" }, //Drug
            { v: data.data_99 }, //
            { v: data.data_100 }, //
            { v: data.data_101 }, //
            //Medications
            { v: data.data_102 }, //
            { v: data.data_103 }, //
            { v: "Other antihypertensive" }, //Other antihypertensive
            { v: data.data_104 }, //
            { v: data.data_105 }, //
            { v: data.data_106 }, //
            { v: data.data_107 }, //
            { v: data.data_108 }, //
            { v: "Other2" }, //Other2
            { v: data.data_109 }, //
            { v: data.data_110 }, //
            { v: data.data_111 }, //
            { v: data.data_112 }, //
            { v: data.data_113 }, //
            { v: "Other3" }, //Other3
            { v: data.data_114 },//
            { v: data.data_115 }, //
            { v: data.data_116 }, //
            { v: data.data_117 }, //
            { v: data.data_118 }, //
            { v: "Other4" }, //"Other4"
            { v: data.data_119 }, //
            { v: data.data_120 }, //
            { v: data.data_121 }, //
            { v: data.data_122 }, //
            { v: data.data_123 }, //
            ]
            const genKey = genColKey(values.length)
            for (let i = 0; i < values.length; i++) {
                const key = genKey[i]
                ws[`${key}${rowIndex}`] = values[i];
            }
        });

        //data for export
        const dataExport = setWorkBook(ws)
        //export file
        FileSaver.saveAs(dataExport, value[0].form_type_name + ".xlsx");
        return { status: "success" }


    } catch (e) {
        console.log(e);
        return { status: "error" }
    }
}