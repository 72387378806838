import numeral from 'numeral';
import React, { Component } from 'react';
import Api from './Api.js';
import '../prescriptionFile.css';
import moment from 'moment';

export default class PrescriptionPrint extends Component {

  state = {
    errorText : "",
    data: {},
    loadingApi: true
  }

  componentDidMount() {
    this.dataGet();
  }
  
  dataGet = async () => {
    if(this.state.loadingApi === false) this.setState({loadingApi: true});

    var _response = await Api[Api.queryString.consult === 'true' ? 
      'consult' : 'member']("PrescriptionFileGet", {token: Api.queryString.token});


    if(_response.result === false) {
      this.setState({errorText: _response.responseText});
    } else {

      var _data = _response.data;

      try {
        _data.content = JSON.parse(_data.content);
      } catch(e) {

      }

      if(!_data) {
        this.setState({errorText: "ไม่พบข้อมูล", loadingApi: false});
      } else {

        this.setState({errorText: "", data: _data, loadingApi: false}, ()=>{
          if(Api.queryString.print === 'true') {
            window.print();
          }
        });
      }

    }
  }


  shippingDealer = {
    'kerry' : 'ขนส่ง Kerry',
    'thaipost' : 'ไปรษณีย์ไทย',
    'flash' : 'ขนส่ง Flash',
    'scg' : 'ขนส่งคุมอุณหภูมิ'
  }

  render() {
    return  <div>
      {this.state.errorText ? this.state.errorText : 
      this.state.loadingApi !== true && <div>
        <div>
          <h1>ใบสั่งยา</h1>
          <hr />
        </div>
        <div>
          <table width="100%" cellPadding={10} cellSpacing={10}>
            <tbody>
              <tr>
                <td width="60%">
                  <table width="100%">
                    <td><strong>ID :</strong> {this.state.data.content.option.id}</td>
                    <tr><td><strong>ชื่อลูกค้า : </strong></td><td>{this.state.data.content.option.customer_name}</td></tr>
                    <tr><td><strong>ที่อยู่ : </strong></td><td>{this.state.data.content.option.customer_address} รหัสไปรษณีย์ {this.state.data.content.option.customer_postcode}</td></tr>
                    <tr><td><strong>เลขประจำตัวผู้เสียภาษี : </strong></td><td>{this.state.data.content.option.customer_tax_id}</td></tr>
                    <tr><td><strong>สำนักงาน/สาขาเลขที่ : </strong></td><td>{this.state.data.content.option.customer_branch}</td></tr>
                    <tr><td></td><td></td></tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <td><strong>Visit number :</strong> {this.state.data.content.option.visit_number}</td>
                    <tr><td><strong>วันที่ : </strong></td><td>{moment(this.state.data.content.option.date).format('DD/MM/YYYY')}</td></tr>
                    <tr><td><strong>เครดิต : </strong></td><td>{this.state.data.content.option.credit}</td></tr>
                    <tr><td><strong>ครบกำหนด : </strong></td><td>{moment(this.state.data.content.option.credit_until_date).format('DD/MM/YYYY')}</td></tr>
                    <tr><td><strong>ผู้ให้บริการ : </strong></td><td>{this.state.data.content.option.admin_name}</td></tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>ใบสั่งยา {this.state.data.content.option.customer_name}</strong>
                </td>
                <td>
                  <strong>เลขที่อ้างอิง</strong> {this.state.data.content.option.ref_number} 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table width="100%" border="1">
            <tr>
              <th>ลำดับ</th>
              <th>ชื่อสินค้า/รายละเอียด</th>
              <th>จำนวน</th>
              <th>หน่วย</th>
              <th>ราคาต่อหน่วย</th>
              <th>ราคารวม</th>
            </tr>
            {
              this.state.data.content.list.length > 0 ?
              this.state.data.content.list.map((data, index)=>{
                return <tr key={`row${index}`} >
                  <td>{index+1}</td>
                  <td>
                    <div>{data.name}</div>
                    <div>{data.detail}</div>
                  </td>
                  <td>{numeral(data.amount).format('0,0')}</td>
                  <td>{data.unit}</td>
                  <td>{numeral(data.priceperunit).format('0,0.00')}</td>
                  <td>{numeral(data.price).format('0,0.00')}</td>
                </tr>
              })
              :
              <tr>
                <td colSpan="100%">
                  <div className="uk-text-center uk-padding">
                    ไม่มีรายการ
                  </div>
                </td>
              </tr>
            }
          </table>
        </div>
        <div style={{marginTop: 20}}>
          <table width="100%">
            <tbody>
              <tr>
                <td width="60%">
                  <strong>หมายเหตุ :</strong> {this.state.data.content.option.note}
                  
                  {+this.state.data.content.option.price_summary < 1000 && <div className="uk-margin-top">หากท่านเลือกวิธีชำระด้วย Internet Banking ทางธนาคารจะคิดค่าธรรมเนียมเพิ่มเติม รายการละ 15  บาท ในกรณียอดชำระต่ำกว่า 1,000 บาท</div>}
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td><strong>รวมเป็นเงิน</strong></td>
                      <td style={{'textAlign': 'right'}}>{numeral(this.state.data.content.option.price).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <td><strong>ส่วนลด {this.state.data.content.option.discount} %</strong></td>
                      <td style={{'textAlign': 'right'}}>{numeral(this.state.data.content.option.price_discount).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <td><strong>ราคาหลังหักส่วนลด</strong></td>
                      <td style={{'textAlign': 'right'}}>{numeral(this.state.data.content.option.price_after_discount).format('0,0.00')}</td>
                    </tr>
                    {
                      this.state.data.content.option.shipping === '1' && [<tr>
                      <td><strong>การขนส่ง</strong></td>
                      <td style={{'textAlign': 'right'}}></td>
                    </tr>,
                    <tr>
                      <td><strong>น้ำหนักโดยประมาณ</strong></td>
                      <td style={{'textAlign': 'right'}}>
                        {this.state.data.content.option.weight} กรัม
                      </td>
                    </tr>,
                    <tr>
                      <td><strong>ขนาดกล่องพัสดุ</strong></td>
                      <td style={{'textAlign': 'right'}}>
                        {this.state.data.content.option.width.split('x').map(d=>numeral(d).format('0.0')).join(' x ')} ซม
                        
                      </td>
                    </tr>,
                    <tr>
                      <td>
                        <strong>{this.shippingDealer[this.state.data.content.option.shippingDealer]}</strong>
                      </td>
                      <td style={{'textAlign': 'right'}}>
                        {this.state.data.content.option[this.state.data.content.option.shippingDealer+'price']} บาท
                        {this.state.data.content.option.shippingDealer === 'scg' && <span> {this.state.data.content.option.temp} °C</span> }
                      </td>
                    </tr>
                    ]
                    }
                    
                    
                    <tr>
                      <td><strong>ค่าธรรมเนียมออนไลน์</strong></td>
                      <td style={{'textAlign': 'right'}}>{numeral(this.state.data.content.option.com_online).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <td><strong>ภาษีมูลค่าเพิ่ม 7%</strong></td>
                      <td style={{'textAlign': 'right'}}>{numeral(this.state.data.content.option.price_with_vat7).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <td><strong>จำนวนเงินรวมทั้งสิ้น</strong></td>
                      <td style={{'textAlign': 'right'}}>{numeral(this.state.data.content.option.price_summary).format('0,0.00')}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{padding: 50}}>
          {this.state.data.content.option.signature === '1' && <div style={{width: 200}}>
            <div style={{borderBottom: '1px solid #000', marginTop: 150}}></div>
            <div style={{textAlign: "center", marginTop: 10}}>({this.state.data.content.option.admin_name})</div>
          </div>}
        </div>
      </div>
      }
    </div>;
  }
}
