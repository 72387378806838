import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import __ from '../Language.js';
import GoogleMapReact from 'google-map-react';


UIkit.use(Icons);

const Marker = ({ text }) => <div><img src={'/pin.png'} uk-img="" width="30" /></div>;

export default class MemberRegisterLocation extends Component {

  state = {
    error_geo : '',
    lat: '',
    lng: '',
    address_name: '',
    address: '',
    postcode: '',
    address_note: '',
    draggable: true,
    center: []
  }

  stateInit = () => {
    this.setState({
      address_name: this.props.address_name,
      address: this.props.address,
      address_note: this.props.address_note,
      postcode: this.props.postcode,
      lat: parseFloat(this.props.lat) || "",
      lng: parseFloat(this.props.lng) || "",
      center: [+this.props.lat || "", +this.props.lng || ""]
    })
  }
  
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(position !== null && !this.props.lat && !this.props.lnt) {
          this.setState({lat: position.coords.latitude, lng: position.coords.longitude, center: [position.coords.latitude, position.coords.longitude] }, ()=>{
            this.props.callback(this.state)
          });
        }
      }, (error) => {
        var _err_label = '';

        switch(error.code) {
          case error.PERMISSION_DENIED:
            _err_label = "ผู้ใช้ปฏิเสธการเข้าถึงตำแหน่งที่ตั้ง"
            break;
          case error.POSITION_UNAVAILABLE:
            _err_label = "ไม่สามารถเรียกดูตำแหน่งได้"
            break;
          case error.TIMEOUT:
            _err_label = "หมดเวลาเลือกตำแหน่ง โปรดลองใหม่อีกครั้ง"
            break;
          case error.UNKNOWN_ERROR:
            _err_label = "เกิดข้อผิดพลาด"
            break;
        }

        this.setState({error_geo: _err_label});
      });
    } else {
      this.setState({error_geo: __("Geolocation is not supported by this browser.")})
    }
  }

  componentDidMount() {
    this.getLocation();
    this.stateInit();

  }

  updateValue= (key, value) => {
    this.setState({[key]: value}, ()=>{
      this.props.callback(this.state)
    });
  }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
        draggable: false,
        lat: mouse.lat,
        lng: mouse.lng
    });
  }

  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true }, ()=>{
      
      this.props.callback(this.state)
    });
  }

  labelRender = (label, not_require) => {
    if(this.props.formType === 'admin') {
      return <label className='uk-margin-small-top'>{label}</label>
    } else {
      return <div>
        {__(label)}
        {not_require !== true && <span className="uk-text-danger">*</span>}
      </div>
    }
  }

  render() {
    return <div>
      <div style={{ height: '500px', width: '100%', marginTop: 10 }}>
      <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAC21z9-ARaJgIjIns2BewsRlwKhqibfDE' }}
          draggable={this.state.draggable}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseUp={this.onMarkerInteractionMouseUp}
          onChildMouseMove={this.onMarkerInteraction}
          center={this.state.center}
          defaultZoom={20}
          yesIWantToUseGoogleMapApiInternals={true}
          options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
        >
          <Marker
            lat={this.state.lat}
            lng={this.state.lng}
            text="ตำแหน่ง"
          />
        </GoogleMapReact>
      </div>
      {this.state.error_geo && <div className='uk-text-center uk-text-danger uk-padding-small'>{this.state.error_geo}</div>}
      <div className='uk-text-meta uk-margin-small-top uk-margin-small-bottom'>{__('ระบบจำเป็นต้องขอที่อยู่จัดส่งยาจากท่านเพื่อจัดหาจุดบริการที่ใกล้เคียงกับท่านมากที่สุด')}</div>
      {this.labelRender('ละติจูด')}
      <input className='uk-input' type='text' placeholder={__('ละติจูด')}  onChange={(e)=>{
        if(e.target.value.includes(',') === true) {
          var _pos = e.target.value.split(',');

          this.updateValue('lat', _pos[0]);
          this.updateValue('lng', _pos[1]);

        } else {
          if(isNaN(e.target.value) === true) {
            UIkit.notification("โปรดใส่ตัวเลขที่ถูกต้อง")
  
            return;
          }
  
          this.updateValue('lat', e.target.value);
          
        }

      }} value={this.state.lat} />
      {this.labelRender('ลองจิจูด')}
      <input className='uk-input' type='text' placeholder={__('ลองจิจูด')} onChange={(e)=>{
        if(isNaN(e.target.value) === true) {
          UIkit.notification("โปรดใส่ตัวเลขที่ถูกต้อง")

          return;
        }

        this.updateValue('lng', e.target.value)

      }} value={this.state.lng} />
      { this.props.hideAddressName !== true && 
      <div>
        {this.labelRender('ชื่อสถานที่จัดส่งยา')}
        <input className='uk-input' type='text' placeholder={__('ชื่อสถานที่จัดส่งยา')} onChange={(e)=>this.updateValue('address_name', e.target.value)} value={this.state.address_name} />
      </div>}
      { this.props.hideAddress !== true && 
      <div>
        {this.labelRender('ที่อยู่')}
        <textarea className='uk-textarea' placeholder={__('ที่อยู่')} onChange={(e)=>this.updateValue('address', e.target.value)} value={this.state.address}></textarea>
      </div>
      }
      { this.props.hidePostcode !== true && 
      <div>
        {this.labelRender('รหัสไปรษณีย์')}
        <input className='uk-input' type='text' placeholder={__('รหัสไปรษณีย์')} maxLength={5} onChange={(e)=>{
          
          if(/^\d*?\d*$/.test(e.target.value)) this.updateValue('postcode', e.target.value)}
        } value={this.state.postcode} />
      </div>
      }
      { this.props.hideNote !== true && 
      <div>
        {this.labelRender('หมายเหตุ', true)}
        <textarea className='uk-textarea' placeholder={__('หมายเหตุ')} onChange={(e)=>this.updateValue('address_note', e.target.value)} value={this.state.address_note}></textarea>
      </div>
      }
    </div>
  }
};
