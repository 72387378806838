import React, { useState, useEffect, useMemo, useCallback } from "react";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementDropdown from "../Form/FormElementDropdown";

const GRACEScoreSection = (props) => {
  const { onChange, onCalculate, data } = props;

  const age = data?.age || "";
  const pulse = data?.pulse || "";
  const sbp = data?.sbp || "";
  const creatinine = data?.creatinine || "";
  const cardiacArrest = data?.cardiacArrest || "";
  const st = data?.st || "";
  const cardiacEnzymes = data?.cardiacEnzymes || "";
  const killipClass = data?.killipClass || "";

  const [agePoint, setAgePoint] = useState(0);
  const [pulsePoint, setPulsePoint] = useState(0);
  const [sbpPoint, setSbpPoint] = useState(0);
  const [creatininePoint, setCreatininePoint] = useState(0);
  const [score, setScore] = useState(0);

  const handleChange = useCallback((id, value) => {
    onChange({ ...data, [id]: value });
  });

  useEffect(() => {
    const n_age = parseInt(age);
    if (n_age < 30) {
      setAgePoint(0);
    } else if (n_age >= 35 && n_age < 45) {
      setAgePoint((age - 35) * 1.8);
    } else if (n_age >= 45 && n_age < 55) {
      setAgePoint(18 + (n_age - 45) * 1.8);
    } else if (n_age >= 55 && n_age < 65) {
      setAgePoint(36 + (n_age - 55) * 1.8);
    } else if (n_age >= 65 && n_age < 75) {
      setAgePoint(54 + (n_age - 65) * 1.9);
    } else if (n_age >= 75 && n_age < 85) {
      setAgePoint(73 + (n_age - 75) * 1.8);
    } else if (n_age >= 85 && n_age < 90) {
      setAgePoint(91 + (n_age - 85) * 1.8);
    } else if (n_age >= 90) {
      setAgePoint(100);
    }
  }, [age]);

  useEffect(() => {
    const n_pulse = parseInt(pulse);
    if (n_pulse < 70) {
      setPulsePoint(0);
    } else if (n_pulse >= 70 && n_pulse < 80) {
      setPulsePoint((n_pulse - 70) * 0.3);
    } else if (n_pulse >= 80 && n_pulse < 90) {
      setPulsePoint(3 + (n_pulse - 80) * 0.2);
    } else if (n_pulse >= 90 && n_pulse < 100) {
      setPulsePoint(5 + (n_pulse - 90) * 0.3);
    } else if (n_pulse >= 100 && n_pulse < 110) {
      setPulsePoint(8 + (n_pulse - 100) * 0.2);
    } else if (n_pulse >= 110 && n_pulse < 150) {
      setPulsePoint(10 + (n_pulse - 110) * 0.3);
    } else if (n_pulse >= 150 && n_pulse < 200) {
      setPulsePoint(22 + (n_pulse - 150) * 0.3);
    } else if (n_pulse >= 200) {
      setPulsePoint(34);
    }
  }, [pulse]);

  useEffect(() => {
    const n_sbp = parseFloat(sbp);
    if (n_sbp < 80) {
      setSbpPoint(40);
    } else if (n_sbp >= 80 && n_sbp < 100) {
      setSbpPoint(40 - (n_sbp - 80) * 0.3);
    } else if (n_sbp >= 100 && n_sbp < 110) {
      setSbpPoint(34 - (n_sbp - 100) * 0.3);
    } else if (n_sbp >= 110 && n_sbp < 120) {
      setSbpPoint(31 - (n_sbp - 110) * 0.4);
    } else if (n_sbp >= 120 && n_sbp < 130) {
      setSbpPoint(27 - (n_sbp - 120) * 0.3);
    } else if (n_sbp >= 130 && n_sbp < 140) {
      setSbpPoint(24 - (n_sbp - 130) * 0.3);
    } else if (n_sbp >= 140 && n_sbp < 150) {
      setSbpPoint(20 - (n_sbp - 140) * 0.4);
    } else if (n_sbp >= 150 && n_sbp < 160) {
      setSbpPoint(17 - (n_sbp - 150) * 0.3);
    } else if (n_sbp >= 160 && n_sbp < 180) {
      setSbpPoint(14 - (n_sbp - 160) * 0.3);
    } else if (n_sbp >= 180 && n_sbp < 200) {
      setSbpPoint(8 - (n_sbp - 180) * 0.4);
    } else if (n_sbp >= 200) {
      setSbpPoint(0);
    }
  }, [sbp]);

  useEffect(() => {
    const n_creatinine = parseFloat(creatinine);
    if (n_creatinine < 0.2) {
      setCreatininePoint(n_creatinine * (1 / 0.2));
    } else if (n_creatinine >= 0.2 && n_creatinine < 0.4) {
      setCreatininePoint(1 + (n_creatinine - 0.2) * (2 / 0.2));
    } else if (n_creatinine >= 0.4 && n_creatinine < 0.6) {
      setCreatininePoint(3 + (n_creatinine - 0.4) * (1 / 0.2));
    } else if (n_creatinine >= 0.6 && n_creatinine < 0.8) {
      setCreatininePoint(4 + (n_creatinine - 0.6) * (2 / 0.2));
    } else if (n_creatinine >= 0.8 && n_creatinine < 1.0) {
      setCreatininePoint(6 + (n_creatinine - 0.8) * (1 / 0.2));
    } else if (n_creatinine >= 1.0 && n_creatinine < 1.2) {
      setCreatininePoint(7 + (n_creatinine - 1.0) * (1 / 0.2));
    } else if (n_creatinine >= 1.2 && n_creatinine < 1.4) {
      setCreatininePoint(8 + (n_creatinine - 1.2) * (2 / 0.2));
    } else if (n_creatinine >= 1.4 && n_creatinine < 1.6) {
      setCreatininePoint(10 + (n_creatinine - 1.4) * (1 / 0.2));
    } else if (n_creatinine >= 1.6 && n_creatinine < 1.8) {
      setCreatininePoint(11 + (n_creatinine - 1.6) * (2 / 0.2));
    } else if (n_creatinine >= 1.8 && n_creatinine < 2.0) {
      setCreatininePoint(13 + (n_creatinine - 1.8) * (1 / 0.2));
    } else if (n_creatinine >= 2.0 && n_creatinine < 3.0) {
      setCreatininePoint(14 + (n_creatinine - 2.0) * (7 / 1));
    } else if (n_creatinine >= 3.0 && n_creatinine < 4.0) {
      setCreatininePoint(21 + (n_creatinine - 3.0) * (7 / 1));
    } else if (n_creatinine >= 4.0) {
      setCreatininePoint(28);
    }
  }, [creatinine]);

  useEffect(() => {
    const n_killipClass = parseInt(killipClass);
    const n_cardiacArrest = parseInt(cardiacArrest);
    const n_st = parseInt(st);
    const n_cardiacEnzymes = parseInt(cardiacEnzymes);
    if (age && pulse && sbp && creatinine) {
      const score = Math.floor(
        n_killipClass +
          agePoint +
          pulsePoint +
          sbpPoint +
          creatininePoint +
          17 * n_st +
          13 * n_cardiacEnzymes +
          30 * n_cardiacArrest
      );
      setScore(score);
      onCalculate(score);
    } else {
      setScore(0);
      onCalculate(0);
    }
  }, [
    agePoint,
    pulsePoint,
    sbpPoint,
    creatininePoint,
    killipClass,
    cardiacArrest,
    st,
    cardiacEnzymes,
    age,
    pulse,
    sbp,
    creatinine,
  ]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        border: "2px solid #00BFA5",
        padding: "8px",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <span
        style={{
          position: "absolute",
          top: "-12px",
          left: "16px",
          background: "#fff",
          fontSize: "16px",
          padding: "0px 4px",
          color: "#00BFA5",
        }}
      >
        GRACE score
      </span>
      <table>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "middle" }}>Age</td>
            <td>
              <FormElementTextBox
                fieldId="grace_score_age"
                value={age}
                regEx={/^[0-9]*$/g}
                onChange={(i, e) => handleChange("age", e.target.value)}
                maxLength={3}
              />
            </td>
            <td style={{ verticalAlign: "middle" }}>years</td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
              Heart rate/pulse
            </td>
            <td>
              <FormElementTextBox
                value={pulse}
                placeholder="Norm: 60-100"
                onChange={(i, e) => handleChange("pulse", e.target.value)}
                regEx={/^[0-9]*$/g}
              />
            </td>
            <td style={{ verticalAlign: "middle" }}>bpm</td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
              Systolic BP
            </td>
            <td>
              <FormElementTextBox
                value={sbp}
                placeholder="Norm: 100-120"
                onChange={(i, e) => handleChange("sbp", e.target.value)}
                regEx={/^\d*\.?\d{0,2}$/g}
              />
            </td>
            <td style={{ verticalAlign: "middle" }}>mmHg</td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
              Creatinine
            </td>
            <td>
              <FormElementTextBox
                value={creatinine}
                placeholder="Norm: 0.7-1.3"
                onChange={(i, e) => handleChange("creatinine", e.target.value)}
                regEx={/^\d*\.?\d{0,2}$/g}
              />
            </td>
            <td style={{ verticalAlign: "middle" }}>mg/dL</td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
              Cardiac arrest at admission
            </td>
            <td colSpan={2}>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <FormElementRadio
                  fieldId="grace_score_cardiacArrest"
                  value={cardiacArrest}
                  fieldValue={"1"}
                  label="Yes"
                  magicRadio
                  onChange={(i, e) =>
                    handleChange("cardiacArrest", e.target.value)
                  }
                />
                <FormElementRadio
                  fieldId="grace_score_cardiacArrest"
                  value={cardiacArrest}
                  fieldValue={"0"}
                  label="No"
                  magicRadio
                  onChange={(i, e) =>
                    handleChange("cardiacArrest", e.target.value)
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
              ST segment deviation on EKG?
            </td>
            <td colSpan={2}>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <FormElementRadio
                  fieldId="grace_score_st"
                  value={st}
                  fieldValue={"1"}
                  label="Yes"
                  magicRadio
                  onChange={(i, e) => handleChange("st", e.target.value)}
                />
                <FormElementRadio
                  fieldId="grace_score_st"
                  value={st}
                  fieldValue={"0"}
                  label="No"
                  magicRadio
                  onChange={(i, e) => handleChange("st", e.target.value)}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
              Abnormal cardiac enzymes
            </td>
            <td colSpan={2}>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <FormElementRadio
                  fieldId="grace_score_cardiacEnzymes"
                  value={cardiacEnzymes}
                  fieldValue={"1"}
                  label="Yes"
                  magicRadio
                  onChange={(i, e) =>
                    handleChange("cardiacEnzymes", e.target.value)
                  }
                />
                <FormElementRadio
                  fieldId="grace_score_cardiacEnzymes"
                  value={cardiacEnzymes}
                  fieldValue={"0"}
                  label="No"
                  magicRadio
                  onChange={(i, e) =>
                    handleChange("cardiacEnzymes", e.target.value)
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}>
              Killip class (signs/symptoms)
            </td>
            <td colSpan={2}>
              <FormElementDropdown
                value={killipClass}
                dataList={[
                  { label: "No CHF", value: "0" },
                  { label: "Rales and/or JVD", value: "15" },
                  { label: "Pulmonary edema", value: "29" },
                  { label: "Cardiogenic shock", value: "44" },
                ]}
                onChange={(i, e) => handleChange("killipClass", e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{ background: "#00BFA5", padding: "4px", borderRadius: "4px" }}
      >
        <div
          style={{
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            paddingLeft: "14px",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "32px", fontWeight: "bold" }}>
              {score}
            </span>
            <span style={{ fontWeight: "bold" }}>point(s)</span>
          </div>
          <span style={{ color: "#fff" }}>GRACE Score</span>
        </div>
      </div>
    </div>
  );
};

export default GRACEScoreSection;
