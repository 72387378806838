import React from 'react';
import FormGenerate from './FormGenerate';

const FormElementRadioGroup = ({data}) => {

  function RadioGroup({data}) {

    var radioItem = data.content
    var id = data.id;

    return (
        radioItem.map((data, key)=>{
          data["id"] = id;

            return <FormGenerate data={data} key={key}/>
        })
    );
  }

  if(data.style == "inline") {
    return <RadioGroup data={data} />;

  } else {
    return (
      <div className="uk-width-1-1@m uk-width-1-1">
          <RadioGroup data={data} />
      </div>
    )
  }
}

export default FormElementRadioGroup;
