const { useCallback } = require("react");

function handleChange(i, event, setIsEditForm, setDataForm) {
  var valueData = "";

  if (event != null && typeof event.target != "undefined") {
    if (event.target.value == "on") {
      if (event.target.checked) {
        valueData = "1";
      } else {
        valueData = "0";
      }
    } else {
      valueData = event.target.value;
    }
  } else {
    valueData = event;
  }

  setIsEditForm(true);
  setDataForm((dataForm) => ({ ...dataForm, ["data_" + i]: valueData }));
}

exports.handleChange = handleChange;

function handleValueChange(fieldName, value, setIsEditForm, setDataForm) {
  var valueData = null;
  if (value !== null && typeof value !== "undefined") {
    valueData = value;
  }

  setIsEditForm(true);
  setDataForm((dataForm) => ({ ...dataForm, [fieldName]: valueData }));
}

exports.handleValueChange = handleValueChange;

function setData(field, valueData, setIsEditForm, setDataForm) {
  setIsEditForm(true);
  setDataForm((dataForm) => ({ ...dataForm, ["data_" + field]: valueData }));
}

exports.setData = setData;

function useEffectDataForm(isEditForm, dataForm, setIsEditForm, addValue) {
  if (isEditForm) {
    setIsEditForm(false);
    addValue(dataForm);
  }
}

exports.useEffectDataForm = useEffectDataForm;

function useEffectPropsDataForm(setDataForm, dataForm) {
  setDataForm(dataForm);
}

exports.useEffectPropsDataForm = useEffectPropsDataForm;

function sumTotalScore(dataArray) {
  dataArray = dataArray.map((item) =>
    item == "" || item == null || typeof item == "undefined" ? "0" : item
  );
  return dataArray.reduce((accumulator, currentValue) => {
    return parseInt(accumulator) + parseInt(currentValue);
  }, 0);
}

exports.sumTotalScore = sumTotalScore;

function prepareDataBeforeSave(data, ignoredFields) {
  const dataArray = Object.keys(data);

  if (dataArray.includes("data_undefined")) {
    delete data["data_undefined"];
  }

  dataArray.forEach((item) => {
    if (!ignoredFields.includes(item)) {
      if (data[item] == null || data[item] == NaN) {
        delete data[item];
      }
      if (typeof data[item] === "object") {
        data[item] = JSON.stringify(data[item]);
      }
      // if (typeof data[item] === "string" && !data[item]) {
      //   data[item] = null;
      // }
    }
  });
}

exports.prepareDataBeforeSave = prepareDataBeforeSave;

function parseStringToJson(value) {
  if (value && typeof value == "string") {
    return JSON.parse(value);
  }
  return value;
}

exports.parseStringToJson = parseStringToJson;

function setDataFormItem(data, setIsEditForm, setDataForm) {
  var valueData = "";

  if (event != null && typeof event.target != "undefined") {
    if (event.target.value == "on") {
      if (event.target.checked) {
        valueData = "1";
      } else {
        valueData = "0";
      }
    } else {
      valueData = event.target.value;
    }
  } else {
    valueData = event;
  }

  setIsEditForm(true);
  setDataForm((dataForm) => ({ ...dataForm, ["data_" + i]: valueData }));
}

exports.handleChange = handleChange;

function getInputValue(event) {
  var valueData = "";

  if (event != null && typeof event.target != "undefined") {
    if (event.target.value == "on") {
      if (event.target.checked) {
        valueData = "1";
      } else {
        valueData = "0";
      }
    } else {
      valueData = event.target.value;
    }
  } else {
    valueData = event;
  }

  return valueData;
}

exports.getInputValue = getInputValue;

function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

exports.getBase64 = getBase64;

function loadXHR(url) {
  return new Promise(function (resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function () {
        reject("Network error.");
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject("Loading error:" + xhr.statusText);
        }
      };
      xhr.send();
    } catch (err) {
      reject(err.message);
    }
  });
}

exports.loadXHR = loadXHR;

function getValueChanged(event) {
  var valueData = "";

  if (event != null && typeof event.target != "undefined") {
    if (event.target.value == "on") {
      if (event.target.checked) {
        valueData = "1";
      } else {
        valueData = "0";
      }
    } else {
      valueData = event.target.value;
    }
  } else {
    valueData = event;
  }

  return valueData;
}

exports.getValueChanged = getValueChanged;

function convertDisplayDate(value) {
  // value = value.substring(0, 10);
  const thYear = value.substring(0, 4);
  const enYear = parseInt(thYear) + 543;

  const parts = value.replace(thYear, enYear).split("-");

  if (value.length > 10) {
    const year = parts[0];
    const time = parts[2].split(" ")[1];
    const month = parts[1];
    const day = parts[2].substring(0, 2);

    return day + "/" + month + "/" + year + " " + time;
  } else {
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    return day + "/" + month + "/" + year;
  }
}

exports.convertDisplayDate = convertDisplayDate;
