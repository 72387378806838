import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormZarit12Paper = (props) => {
  const { dataForm, patient, profile} = props;

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
      dataForm.data_10n,
      dataForm.data_11n,
      dataForm.data_12n,
    ]);
  }, [dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="Zarit-12 (Form ที่ใช้ใน Post-falling assessment)"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <FormElementTitle
            label="ในแต่ละข้อให้เลือกช่องที่คิดว่าตรงกับท่านมากที่สุด"
            mode="view"
          />
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ไม่เคยเลย (0)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  นานๆครั้ง (1)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  บางครั้ง (2)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ค่อนข้างบ่อย (3)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  แทบทุกครั้ง (4)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1. ท่านรู้สึกว่าท่านไม่มีเวลาเพียงพอสำหรับตัวเอง
                  เนื่องจากว่าใช้เวลาในการดูแล ไม่เวลาของตัวเอง
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2.
                  ท่านรู้สึกว่ามีความเครียดทั้งงานที่ต้องดูแลผู้ป่วยและงานอื่นที่ต้องรับผิดชอบ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3. ท่านรู้สึกหงุดหงิดใจ หรือโกรธ ขณะที่อยู่ใกล้ผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4.
                  ท่านรู้สึกว่าผู้ป่วยทำให้ความสัมพันธ์ของท่านกับสมาชิกครอบครัวหรือเพื่อนแย่ลง
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5. ท่านรู้สึกตึงเครียดขณะที่อยู่ใกล้ผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6. ท่านรู้สึกว่าสุขภาพของท่านไม่ค่อยดี
                  เนื่องมาจากการดูแลผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7.
                  ท่านรู้สึกว่าท่านไม่มีความเป็นส่วนตัวเท่าที่ต้องการเนื่องจากการดูแลผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  8.
                  ท่านรู้สึกว่าท่านไม่สามารถมีสังคมได้ตามปกติเนื่องจากการดูแลผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_8n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_8n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_8n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_8n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_8n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  9. ท่านรู้สึกว่าท่านไม่สามารถควบคุมจัดการชีวิตตนเองได้
                  ตั้งแต่ดูแลผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_9n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_9n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_9n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_9n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_9n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  10. ท่านรู้สึกไม่มั่นใจในการดูแลผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_10n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_10n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_10n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_10n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_10n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  11. ท่านรู้สึกว่าท่านควรจะได้รับการดูแลจากญาติคนอื่น
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_11n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_11n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_11n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_11n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_11n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  12. ท่านรู้สึกว่าท่านน่าจะดูแลผู้ป่วยได้ดีกว่านี้
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_12n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_12n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_12n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_12n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_12n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนนรวมทั้งหมด
                </td>
                <td
                  colSpan={5}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getTotalScore}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormZarit12Paper;
