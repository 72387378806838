import React, { useState, useEffect, useRef, useCallback } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import dateFormat from "dateformat";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import PopupForm from "../PopupForm";
import { data } from "jquery";
import DrugAllergicSection from "../Utils/DrugAllergicSection";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilHandleValueChange = require("./FormUtil.js").handleValueChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilGetValueChanged = require("./FormUtil.js").getValueChanged;

const FormNursingAssessment = (props) => {
  const { isFetchDone } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  const [popupDataForm, setPopupDataForm] = useState({});
  var [memberData, setMemberData] = useState({});
  var [diagList, setDiagList] = useState([]);
  var [drugList, setDrugList] = useState([]);
  var [hideSaveBtnPopup, setHideSaveBtnPopup] = useState(false);
  var [hn, setHn] = useState(null);
  var [txn, setTxn] = useState(null);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [allOfData17Checked, setAllOfData17Checked] = useState("0");
  var [drugAllergics, setDrugAllergics] = useState([]);
  var [nxOpdDropDownList, setNxOpdDropDownList] = useState([]);
  var [opdVerify, setOpdVerify] = useState({});

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  function onClosePopup(data) {
    setShowFormPopup(false);

    if (data) {
      console.log(data);
      props.addDataFormItem(data);

      /*
      if ([22, 23, 24, 25, 26].includes(popupFormType)) {
        const newData = data.data.data_form_list.pop();
        props.addDataFormItem({ data: newData });

      } else if ([27, 28, 29].includes(popupFormType)) {
        props.addDataFormItem({ data: data.data });
      }*/
    }
  }

  useEffect(() => {
    props.addValue({
      formName: "form_telepharmacy",
      formType: "38",
    });
  }, []);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  useEffect(() => {
    console.log(dataForm);
  }, [dataForm]);

  useEffect(() => {
    if (isFetchDone) {
      setHn(props.hn);
      setTxn(props.txn);

      console.log("load form item", props.hn, props.txn);

      //Load form item
      if (props.txn != null) {
        props.loadDataFormItem(22, props.txn);
        props.loadDataFormItem(23, props.txn);
        props.loadDataFormItem(24, props.txn);
        props.loadDataFormItem(25, props.txn);
        props.loadDataFormItem(26, props.txn);
      }
    }
  }, [props.hn, props.txn, isFetchDone]);

  useEffect(() => {
    if (isFetchDone && props.hn && props.txn) {
      getDataDiag();
      getDataDrug();
      getDrugAllergic();
      getDrugOpdVerifyNoteByTxn();
      getNxOPDList();
    }
  }, [isFetchDone, props.hn, props.txn]);

  async function getDataDiag() {
    console.log("get Diag");

    var result = await Api.consult("GetDiagByTxn", {
      txn: props.txn,
    });

    console.log(result);

    var { list } = result;

    if (list != null) {
      if (list.icd10.length > 0) {
        setDiagList(list.icd10);
      } else {
        setDiagList([]);
      }
    }
  }

  async function getDataDrug() {
    console.log("get data sub form 38");
    var result = await Api.consult("GetDrugByTxn", {
      hn: props.hn,
      txn: props.txn,
      patientType: "o",
    });

    console.log(result);

    var { list } = result;
    let drugList = [];
    let drugEnableList = [];

    if (list.data.length > 0) {
      for (const drug of list.data[0]) {
        if (drug.status == "0") {
          drugList.push(drug.tele_drug_type);
          drugEnableList.push(drug);
        }
      }
      setDrugList(drugEnableList);
    } else {
      setDrugList([]);
    }
  }

  async function getDrugOpdVerifyNoteByTxn() {
    if (props.hn && props.txn) {
      try {
        var result = await Api.consult("GetDrugOpdVerifyNoteByTxn", {
          hn: props.hn,
          txn: props.txn,
        });

        if (result?.list?.text) {
          setOpdVerify(result.list);
        } else {
          setOpdVerify({});
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function getNxOPDList() {
    try {
      var result = await Api.consult("GetNxOPDList", {});

      if (result.list.data) {
        const data = result.list.data;
        const dataArray = Object.keys(data);
        const list = dataArray.map((item) => ({
          value: item,
          label: data[item],
        }));

        setNxOpdDropDownList(list);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getDrugAllergic() {
    try {
      if (props.hn && props.txn) {
        var result = await Api.consult("GetDrugAllergic", {
          hn: props.hn,
        });

        if (result.list.data.allergic_desc[0].list) {
          setDrugAllergics(result.list.data.allergic_desc[0].list);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isFetchDone) {
      if (drugList.length > 0) {
        props.addValue({
          data_32: drugList[0].dtm,
          data_8: drugList[0].doc_name,
        });
      } else {
        props.addValue({
          data_32: "",
          data_8: "",
        });
      }
    }
  }, [drugList, isFetchDone]);

  useEffect(() => {
    if (drugList.length > 0 && nxOpdDropDownList.length > 0) {
      const data = nxOpdDropDownList.find((s) => s.value == drugList[0].room);
      props.addValue({ data_7: data.label });
    } else {
      props.addValue({ data_7: "" });
    }
  }, [drugList, nxOpdDropDownList]);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    const date = new Date();
    if (!dataForm.data_29) {
      props.addValue({ data_29: dateFormat(date, "yyyy-mm-dd") });
    }

    if (!dataForm.data_30) {
      props.addValue({ data_30: dateFormat(date, "HH:MM") });
    }
  }, [dataForm]);

  const handleDataDrugValueChange = useCallback((i, key, event) => {
    const temp_data_drug = dataForm?.data_drug;
    const value = utilGetValueChanged(event);
    const json = { ...temp_data_drug[i], [key]: value };
    temp_data_drug[i] = json;
    const allCheckedValue = temp_data_drug?.every((item) => item.data_1 == "1")
      ? "1"
      : "0";
    console.log("temp_data_drug", temp_data_drug);
    setAllOfData17Checked(allCheckedValue);
    props.addValue({ data_drug: temp_data_drug });
  });

  const handleAllCheckedChange = useCallback((event) => {
    const value = utilGetValueChanged(event);

    if (dataForm?.data_drug) {
      const temp_data_drug = dataForm?.data_drug;
      temp_data_drug?.forEach((item) => (item.data_1 = value));
      props.addValue({ data_drug: temp_data_drug });
      setAllOfData17Checked(value);
    }
  });

  useEffect(() => {
    if (dataForm?.data_drug) {
      const value = dataForm?.data_drug?.every((item) => item.data_1 == "1")
        ? "1"
        : "0";
      setAllOfData17Checked(value);
    }
  }, [dataForm.data_drug, allOfData17Checked]);

  function doPhamacyImage() {
    setPopupFormType(21);
    setHideSaveBtnPopup(true);
    setShowFormPopup(true);
  }

  function doPopupDrugForm(type, name) {
    if (type == "warfarin") {
      doWafarin();
    } else if (type == "Noacs") {
      doNoac();
    } else if (type == "สูดพ่น") {
      doInhaler(name);
    } else if (type == "insulin") {
      doInsurine();
    } else if (type == "bisphosphonates") {
      doBispho();
    }
  }

  function doWafarin() {
    var dataFormItem = props.getDataFormItem(22);
    console.log("dataFormItem", dataFormItem);
    setPopupDataForm(dataFormItem);
    setPopupFormType(22);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doNoac() {
    var dataFormItem = props.getDataFormItem(23);
    setPopupDataForm(dataFormItem);
    setPopupFormType(23);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doInhaler(name) {
    var dataFormItem = props.getDataFormItem(24);
    setPopupDataForm({ ...dataFormItem, drug_name: name });
    setPopupFormType(24);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doInsurine() {
    var dataFormItem = props.getDataFormItem(25);
    setPopupDataForm(dataFormItem);
    setPopupFormType(25);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doBispho() {
    var dataFormItem = props.getDataFormItem(26);
    setPopupDataForm(dataFormItem);
    setPopupFormType(26);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doDeliveryGeneralMedicine() {
    var dataFormItem = props.getDataFormItem(27);
    setPopupDataForm(dataFormItem);
    setPopupFormType(27);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doDeliveryDrugMedicine() {
    var dataFormItem = props.getDataFormItem(28);
    setPopupDataForm(dataFormItem);
    setPopupFormType(28);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doDeliveryWafarineMedicine() {
    var dataFormItem = props.getDataFormItem(29);
    setPopupDataForm(dataFormItem);
    setPopupFormType(29);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function onSelectMember(data) {
    setMemberData(data);
  }

  function doShowPic(name, code, howTo, amt) {
    let url =
      "/consult/phamacyImage?mode=iviewer&" +
      encodeURIComponent(
        "name=" +
          name +
          "&code=" +
          code +
          "&howTo=" +
          JSON.stringify(howTo) +
          "&amt=" +
          amt +
          "&patientName=" +
          dataForm.patient_name +
          "&hn=" +
          dataForm.hn
      );

    window.open(url, "imagePhama").focus();
  }

  return (
    <div
      uk-grid=""
      className="uk-grid uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "none" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" แบบบันทึกการให้คำแนะนำการใช้ยาผ่านระบบ Telemedicine " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Diagnosis " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>type</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {diagList.length === 0 ? (
              <tr>
                <td colSpan="100%">
                  <div className="uk-padding-small uk-text-center">
                    ไม่พบข้อมูล
                  </div>
                </td>
              </tr>
            ) : (
              diagList.map((data, key) => {
                return (
                  <tr key={`row${data.icd10}`}>
                    <td>{key + 1}</td>
                    <td>{data.dx_type_name}</td>
                    <td>{data.icd10_name}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"7"}
          value={dataForm.data_7}
          label="OPD"
          onChange={handleChange}
          width="100"
        />
        <FormElementTextBox
          fieldId={"8"}
          value={dataForm.data_8}
          label="แพทย์ "
          onChange={handleChange}
          width="100"
        />
        <span className="formTextBoxLabel">วันที่แพทย์สั่งยา</span>
        <FormElementTextBox
          fieldId={"32"}
          type="date"
          value={dataForm.data_32}
          onChange={handleChange}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          label="วิธีการให้คำแนะนำยา"
          fieldId="34"
          value={dataForm.data_34}
          onChange={handleChange}
          rows={4}
          cols={100}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ประวัติการแพ้ยา" />
        <div className="uk-width-1-1@m uk-width-1-1">
          <DrugAllergicSection data={drugAllergics} />
        </div>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" รายการยาที่แพทย์สั่ง ห้องตรวจ " /> &nbsp;
        &nbsp; &nbsp;
        {/* <FormElementButton label="รูปยา" onClick={doPhamacyImage} /> */}
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div className="form-table-scroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th style={{ whiteSpace: "nowrap" }}>ชื่อยา</th>
                  <th style={{ width: "400px" }}>วิธีใช้</th>
                  <th>จำนวน</th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    สถานะการปรับเปลี่ยนยา
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    แนะนำยา{" "}
                    <FormElementCheckBox
                      fieldId={"check-all"}
                      value={allOfData17Checked}
                      onChange={(ignored, event) =>
                        handleAllCheckedChange(event)
                      }
                    />
                  </th>
                  <th style={{ width: "400px" }}>ปัญหาการใช้ยาที่พบ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataForm?.data_drug?.length === 0 || !dataForm?.data_drug ? (
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataForm?.data_drug?.map((data, key) => {
                    const howToDesc = {
                      sig: data.sig,
                      sigadd: data.sigadd,
                      signote: data.signote,
                    };
                    return (
                      <tr key={`row-drug-${key}`}>
                        <td>{key + 1}</td>
                        <td>
                          <a
                            onClick={(e) =>
                              doShowPic(
                                data.name,
                                data.code,
                                howToDesc,
                                data.amt
                              )
                            }
                          >
                            {data.name}
                          </a>
                        </td>
                        <td>
                          <p>{data.sig}</p>
                          <p>{data.sigadd}</p>
                          <p>{data.signote}</p>
                        </td>
                        <td>{data.amt}</td>
                        <td>{data.remed_status + " " + data.dru_note}</td>
                        <td>
                          <div className="uk-width-1-5">
                            <FormElementCheckBox
                              fieldId={`checkbox-${key}`}
                              value={data.data_1 || "0"}
                              onChange={(i, event) =>
                                handleDataDrugValueChange(key, "data_1", event)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <FormElementTextArea
                            fieldId={`textarea-${key}`}
                            value={data.data_2 || ""}
                            cols={80}
                            rows={3}
                            onChange={(i, event) =>
                              handleDataDrugValueChange(key, "data_2", event)
                            }
                            noLimit
                          />
                        </td>
                        <td>
                          {data.tele_drug_type != "" &&
                          data.tele_drug_type != "ยาเสพติด" ? (
                            <FormElementButton
                              label="ฟอร์ม"
                              onClick={() =>
                                doPopupDrugForm(data.tele_drug_type, data.name)
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ข้อมูลที่ส่งต่อจากเภสัชกรคัดกรองคำสั่งใช้ยา " />
        <div className="uk-width-1-1@m uk-width-1-1">
          {Object.keys(opdVerify).length > 0 && (
            <div
              style={{ display: "flex", gap: "12px", flexDirection: "column" }}
            >
              <span>{opdVerify.text_head}</span>
              {opdVerify.text?.phar_note?.map((item, i) => {
                const texts = item?.note_desc?.split("<br>");
                return (
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <strong>-</strong>
                    <span>
                      {texts.map((t) => (
                        <span>
                          {t}
                          <br />
                        </span>
                      ))}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          {Object.keys(opdVerify).length == 0 && "ไม่มีข้อมูล"}
        </div>
      </div>
      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"20"}
          value={dataForm.data_20}
          cols={100}
          rows={5}
          onChange={handleChange}
          noLimit
        />
      </div> */}
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" รายการยาที่ได้รับจากสถานพยาบาลอื่น/ สมุนไพร/ อาหารเสริม " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"21"}
          value={dataForm.data_21}
          cols={100}
          rows={5}
          onChange={handleChange}
          noLimit
        />
      </div>

      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" แบบติดตามการประเมินความรู้ผู้ป่วย  " />
      </div>

      <div className="uk-width-1-1">
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <FormElementLabel label="Warfarin" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doWafarin} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="NOAC" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doNoac} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="ยาพ่นสูด" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doInhaler} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="insulin" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doInsurine} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="bisphosphonate แบบรับประทาน" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doBispho} />
          </div>
        </div>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" แบบติดตามการได้รับยาและการใช้ยาที่จัดส่งทางไปรษณีย์  " />
      </div>

      <div className="uk-width-1-1">
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <FormElementLabel label="ยาทั่วไป" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton
              label="ฟอร์ม"
              onClick={doDeliveryGeneralMedicine}
            />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="ยาเสพติด" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doDeliveryDrugMedicine} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="Warfarin" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton
              label="ฟอร์ม"
              onClick={doDeliveryWafarineMedicine}
            />
          </div>
        </div>
      </div> */}

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ข้อมูลที่ต้องการส่งต่อนัดครั้งถัดไป  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="  แพทย์  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"23"}
          value={dataForm.data_23}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="  พยาบาล  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"24"}
          value={dataForm.data_24}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="  เภสัช  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"25"}
          value={dataForm.data_25}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="เจ้าหน้าที่อื่น" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"26"}
          value={dataForm.data_26}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ปัญหาอื่น" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"27"}
          value={dataForm.data_27}
          onChange={handleChange}
          noLimit
        />
      </div>
      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ข้อมูลผู้ให้บริการ" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"28"}
          value={dataForm.data_28}
          label="ชื่อ"
          onChange={handleChange}
          width="100"
        />
        <FormElementTextBox
          fieldId={"33"}
          value={dataForm.data_33}
          label="นามสกุล"
          onChange={handleChange}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <span className="formTextBoxLabel">วันที่</span>
        <FormElementTextBox
          fieldId={"29"}
          type="date"
          value={dataForm.data_29}
          onChange={handleChange}
          width="100"
        />
        <FormElementTextBox
          fieldId={"30"}
          value={dataForm.data_30}
          label="เวลาเริ่มต้น"
          onChange={() => {}}
          width="100"
        />
        <FormElementTextBox
          fieldId={"31"}
          value={dataForm.data_31}
          label="เวลาสิ้นสุด"
          onChange={handleChange}
          width="100"
        />
      </div> */}
      <PopupForm
        hideSaveBtn={hideSaveBtnPopup}
        hn={hn}
        txn={txn}
        show={showFormPopup}
        dataForm={popupDataForm}
        formType={popupFormType}
        onClose={onClosePopup}
        formId={props.formId || dataForm.form_id}
      ></PopupForm>
    </div>
  );
};

export default FormNursingAssessment;
