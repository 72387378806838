import React, { useCallback, useEffect, useRef, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import "../../../css/dietz.css";

const FormElementTagInput = (props) => {
  const {
    placeholder,
    value,
    onChange,
    dataList,
    disabled,
    onInputChange,
    isPreSelect,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const classNames = `tag-input-container ${disabled ? "input-disabled" : ""}`;

  const validateDuplicateValue = useCallback((itemValue) => {
    if (itemValue.length > 0) {
      return value?.some((item) => item == itemValue) ? true : false;
    } else {
      return true;
    }
  });

  const handleInputChange = useCallback((e) => {
    if (e.key == "Enter") {
      if (!validateDuplicateValue(inputValue)) {
        const changedValue = value ? value : [];
        changedValue.push(inputValue);
        onChange(changedValue);
        setInputValue("");
      }
    }
  });

  useEffect(() => {
    if (dataList && !isPreSelect) {
      if (dataList.includes(inputValue)) {
        if (!validateDuplicateValue(inputValue)) {
          const changedValue = value ? value : [];
          changedValue.push(inputValue);
          onChange(changedValue);
          setInputValue("");
        }
      }
    }
  }, [inputValue, dataList]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("keypress", handleInputChange);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("keypress", handleInputChange);
      }
    };
  }, [handleInputChange]);

  const handleTagRemove = useCallback((i) => {
    value.splice(i, 1);
    onChange(value);
  });

  return (
    <div
      className={classNames}
      style={{ border: "1px solid grey", padding: "8px", borderRadius: "4px" }}
    >
      {value?.map((item, i) => {
        return (
          <div key={i} className="tag-value-container">
            <span className="tag-value-label">{item}</span>
            <button
              style={{
                color: "#fff",
                fontWeight: "bolder",
                width: "16px",
                height: "16px",
                minWidth: "16px",
                minHeight: "16px",
              }}
              uk-icon={`icon: close; ratio: 0.8;}`}
              onClick={() => handleTagRemove(i)}
              disabled={disabled}
            />
          </div>
        );
      })}

      <input
        className="uk-input"
        ref={inputRef}
        onChange={(e) => {
          setInputValue(e.target.value);
          if (typeof onInputChange == "function") {
            onInputChange(e.target.value);
          }
        }}
        value={inputValue}
        placeholder={placeholder}
        list="select"
        size={inputValue.length || 4}
        disabled={disabled}
      />
      {dataList && (
        <datalist id="select">
          {dataList.map((data, key) => {
            return <option key={key}>{data}</option>;
          })}
        </datalist>
      )}
    </div>
  );
};

export default FormElementTagInput;
