import React, { useState, useEffect, useRef, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

import "../../../css/dietz.css";
import "../../../css/reactTags.css";

const FormStrokePaper = (props) => {
  const { dataForm, patient, profile, oldData } = props;

  var lblYN = {
    Y: "Yes",
    N: "No",
  };

  var lblDiag = {
    1: "Large artery atherosclerosis",
    2: "Cardioembolic stroke",
    3: "Lacunar stroke",
    4: "Stroke of other determined etiology",
    5: "Stroke of undetermined etiology",
  };

  var lbl15 = {
    1: "Oral",
    2: "Tube",
  };

  var lbl16 = {
    "": "Normal",
    null: "Normal",
    undefined: "Normal",
    1: "Normal",
    2: "Catheter",
  };

  var lblControl = {
    1: "Controlled",
    2: "Uncontrolled",
  };

  function translateData25(valueTxt) {
    let label = "";

    if (valueTxt != null && typeof valueTxt != "undefined") {
      valueTxt = valueTxt.split('["').join("[");
      valueTxt = valueTxt.split('"]').join("]");
      valueTxt = valueTxt.split('}"').join("}");
      valueTxt = valueTxt.split('"{').join("{");
      valueTxt = valueTxt.split('\\"').join('"');

      let items = JSON.parse(valueTxt);

      for (const item of items) {
        label += ", " + item.label;
      }

      label = label.substring(1);
    }

    return label;
  }

  //#region Form Function

  //#endregion

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="Ischemic Stroke F/U form" mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            mode="view"
            label="Stroke onset: "
            padding="0px 10px 0px 0px"
          />
          <FormElementTextBox
            value={dataForm.data_1}
            type="date"
            format="DD/MM/YYYY"
            mode="view"
            width="100"
          />
          <FormElementLabel
            mode="view"
            label="rtPA : "
            padding="0px 10px 0px 40px"
          />
          <FormElementLabel
            value={dataForm.data_2}
            translate={lblYN}
            mode="view"
          />
          <FormElementLabel
            mode="view"
            label="Mechanical thrombectomy : "
            padding="0px 10px 0px 40px"
          />
          <FormElementLabel
            value={dataForm.data_3}
            translate={lblYN}
            mode="view"
          />
        </div>
        <div style={{ flexBasis: "100%", height: "0" }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormElementLabel
              label="Diagnosis : "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementLabel
              value={dataForm.data_4}
              translate={lblDiag}
              mode="view"
            />
          </div>
          <div>
            <FormElementTextArea
              rows={2}
              value={dataForm.data_5}
              mode="view"
              padding="0px 10px 0px 80px"
              width="100"
            />
            <FormElementTextArea
              rows={2}
              value={dataForm.data_6}
              mode="view"
              padding="0px 10px 0px 80px"
              width="100"
            />
            <FormElementTextArea
              rows={2}
              value={dataForm.data_7}
              mode="view"
              padding="0px 10px 0px 80px"
              width="100"
            />
            <FormElementTextArea
              rows={2}
              value={dataForm.data_8}
              mode="view"
              padding="0px 10px 0px 80px"
              width="100"
            />
            <FormElementTextArea
              rows={2}
              value={dataForm.data_9}
              mode="view"
              padding="0px 10px 0px 80px"
              width="100"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormElementLabel
              label="Clinical Diagnosis : "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            {/* <FormElementLabel
              label={translateData25(dataForm.data_25)}
              mode="view"
            /> */}
            <div
              style={{
                display: "flex",
                gap: "4px",
                flexDirection: 'column',
                paddingLeft: "8px",
                marginBottom: '8px'
              }}
            >
              {typeof dataForm.data_26 == "object" &&
                dataForm.data_26 &&
                dataForm.data_26.map((item, index) =>
                  <span style={{ whiteSpace: "wrap" }}>• {item}</span>
                )}
            </div>
          </div>
        </div>
        {oldData.length > 0 ? (
          <div
            className="form-table-container"
          >
            <div>
              <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                <thead>
                  <tr>
                    <th rowSpan={2}>ครั้งที่</th>
                    <th rowSpan={2}>F/U</th>
                    <th colSpan={3}>Evaluation</th>
                  </tr>
                  <tr>
                    <th>MRS</th>
                    <th>BI</th>
                    <th>PHQ9</th>
                  </tr>
                </thead>
                <tbody>
                  {oldData.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <FormElementTextBox
                              type="numeric"
                              value={item.data_10}
                              label={` F/U (`}
                              withDropdown
                              dataList={[
                                { value: "1", label: "1" },
                                { value: "2", label: "2" },
                                { value: "3", label: "3" },
                                { value: "30", label: "1 month" },
                                { value: "60", label: "2 months" },
                                { value: "90", label: "3 months" },
                                { value: "120", label: "4 months" },
                                { value: "150", label: "5 months" },
                                { value: "180", label: "6 months" },
                                { value: "210", label: "7 months" },
                                { value: "240", label: "8 months" },
                                { value: "270", label: "9 months" },
                                { value: "300", label: "10 months" },
                                { value: "330", label: "11 months" },
                                { value: "365", label: "1 year" },
                              ]}
                              mode="view"
                              width="100"
                            />
                            <FormElementLabel mode="view" label=" day )" />
                            <FormElementTextBox
                              value={item.data_11}
                              type="date"
                              format="DD/MM/YYYY"
                              width="100"
                              mode="view"
                            />
                          </div>
                        </td>
                        <td>{item.data_12n}</td>
                        <td>{item.data_13n}</td>
                        <td>{item.data_14n}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ flexBasis: "100%", height: "0" }}></div>

        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
          >
            <FormElementTextBox
              type="numeric"
              value={dataForm.data_10}
              label={`ครั้งที่ ${oldData.length + 1} F/U (`}
              mode="view"
              width="100"
            />
            <FormElementLabel mode="view" label=" day )" />
            &nbsp;&nbsp;
            <FormElementTextBox
              value={dataForm.data_11}
              type="date"
              label="date: "
              format="DD/MM/YYYY"
              mode="view"
              width="100"
            />
          </div>
          <div style={{ flexBasis: "100%", height: "0" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
          >
            <FormElementLabel mode="view" label="Evaluation " />
            &nbsp;&nbsp;&nbsp;
            <FormElementTextBox
              type="numeric"
              value={dataForm.data_12n}
              label="MRS : "
              mode="view"
              width="100"
            />
            <FormElementTextBox
              type="numeric"
              value={dataForm.data_13n}
              label="BI : "
              mode="view"
              width="100"
            />
            <FormElementTextBox
              type="numeric"
              value={dataForm.data_14n}
              label="PHQ9 : "
              mode="view"
              width="100"
            />
          </div>
        </React.Fragment>

        <div style={{ flexBasis: "100%", height: "0" }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            mode="view"
            label="Feeding : "
            padding="0px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_15}
            translate={lbl15}
            mode="view"
          />
        </div>
        <div style={{ flexBasis: "100%", height: "0" }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            mode="view"
            label="Voiding : "
            padding="0px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_16}
            translate={lbl16}
            mode="view"
          />
        </div>
        <div style={{ flexBasis: "100%", height: "0" }}></div>
        <div style={{ padding: "10px 0px 0px 0px" }}>
          <FormElementLabel label="Risk Factor" mode="view" />
        </div>
        {dataForm.data_17 == "1" ? (
          <>
            <div
              style={{
                flexBasis: "100%",
                height: "0",
                padding: "10px 0px 0px 0px",
              }}
            ></div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormElementCheckBox
                value={dataForm.data_17}
                padding="0px 10px 0px 40px"
                mode="view"
                label=" HTN : "
              />
              <FormElementLabel
                value={dataForm.data_18}
                translate={lblControl}
                mode="view"
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {dataForm.data_19 == "1" ? (
          <>
            <div style={{ flexBasis: "100%", height: "0" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 0px 0px 0px",
              }}
            >
              <FormElementCheckBox
                value={dataForm.data_19}
                padding="0px 10px 0px 40px"
                mode="view"
                label=" DM : "
              />
              <FormElementLabel
                value={dataForm.data_20}
                translate={lblControl}
                mode="view"
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {dataForm.data_21 == "1" ? (
          <>
            <div style={{ flexBasis: "100%", height: "0" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 0px 0px 0px",
              }}
            >
              <FormElementCheckBox
                value={dataForm.data_21}
                padding="0px 10px 0px 40px"
                mode="view"
                label=" AF : "
              />
              <FormElementLabel
                value={dataForm.data_22}
                translate={lblControl}
                mode="view"
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {dataForm.data_23 == "1" ? (
          <>
            <div style={{ flexBasis: "100%", height: "0" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 0px 0px 0px",
              }}
            >
              <FormElementCheckBox
                value={dataForm.data_23}
                padding="0px 10px 0px 40px"
                mode="view"
                label=" Others : "
              />
              <FormElementLabel
                value={dataForm.data_24}
                label={dataForm.data_24}
                mode="view"
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormStrokePaper;

FormStrokePaper.defaultProps = {
  myRef: { current: {} },
};
