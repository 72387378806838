import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../Api';
import moment from 'moment';
import Rate from '../Rate';
import FullScreenElement from '../FullScreenElement';
import ImageUri from "../ImageUri";

UIkit.use(Icons);

export default class UserMeal extends Component {

  state = {
    data: [],
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var {data=[]} = await Api.member('UserMeal');

    this.setState({data: data || []});
  }


  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-user-health-meal">
      <div className="d-card-header">
        <div className="d-card-header-left">
          MEAL
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-user-health-meal" />
        </div>
      </div>
      <div>
        <table className="uk-table uk-table-divider">
          <thead>
            <tr>
              <th colSpan="2">ชื่ออาหาร</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.data.map((data,key)=>{
                return <tr key={`rowMeal`+data.id}>
                  <td>
                    {data.image !== '' && <img src={ImageUri(data.image)} uk-img="" width="60" />}
                  </td>
                  <td>
                    <div className="uk-text-small">{data.name}</div>
                  </td>
                  <td>
                    <Rate readonly={true} initialRating={data.rating} stop={5} />
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  }
};
