import React, { useEffect } from 'react';
import "../../../css/dietz.css";

const FormLoading = (props) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [document.body.style.overflow]);

    useEffect(() => {
        return () => { document.body.style.overflow = "auto"; }
    }, []);

    return <>
        <div className='dietz-loading-background'>
            <div className='dietz-loading-container'>
                <div uk-spinner="ratio: 2.5" className='dietz-loading-spinner' />
                <span>กำลังโหลดข้อมูล</span>
            </div>
        </div></>
}
export default FormLoading;