import React, { useCallback, useState, useRef, useEffect } from 'react';
import '../../../css/dietz.css';
import IPaperPopupForm from '../IPaper/IPaperPopupForm';

const FormActionButtonSection = (props) => {
    const { formContent, onSave, isPreview, onOpen, onClose, isSubForm, onAdd } = props;
    const ref = useRef();

    const handlePrint = useCallback(() => {
        if (ref.current) {
            var printContents = ref.current.innerHTML;
            if (printContents) {
                var printWindow = window.open(
                    "http://www.yahoo.com",
                    "_blank",
                    "scrollbars=1"
                );
                printWindow.document.write("<html>");
                printWindow.document.write(
                    "<style>" +
                    "@page { size: A4; margin: 0mm; } " +
                    "@media print { body { -webkit-print-color-adjust: exact; margin: 20mm; } }" +
                    "td { padding-left: 4px; padding-bottom: 4px; }" +
                    "table { font-size: 14px; }" +
                    "table,th,td { border-collapse: collapse; border: 1px solid grey; }" +
                    "</style>"
                );
                printWindow.document.write("<body>");
                printWindow.document.write("<div>");
                printWindow.document.write(printContents);
                printWindow.document.write("</div>");
                printWindow.document.write("</body>");
                printWindow.document.write("</html>");
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();
                printWindow.close();
            }
        }
    });

    const handleSave = useCallback(() => {
        onSave(ref?.current?.innerHTML);
    }, [ref?.current?.innerHTML, onSave]);

    const handleSaveSubForm = useCallback(() => {
        onAdd(ref?.current?.innerHTML);
    }, [ref?.current?.innerHTML, onAdd]);

    return <>
        <div className='dietz-action-button-container'>
            {!isSubForm && <span>
                <button className="uk-button uk-button-primary"
                    type="button" onClick={onOpen}>
                    Preview
                </button>
            </span>}
            {isSubForm && <span>
                <button className="uk-button uk-button-primary"
                    type="button" onClick={handleSaveSubForm}>
                    Save
                </button>
            </span>}
            <span hidden={formContent == null}>
                <button className="uk-button uk-button-primary"
                    type="button" onClick={handlePrint}>
                    Print
                </button>
            </span>
        </div>
        <div ref={ref} hidden>{formContent}</div>
        {isPreview && <IPaperPopupForm
            data={formContent}
            onClose={onClose}
            onSave={handleSave}
        />}
    </>
}

export default FormActionButtonSection;