import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class IconCard extends Component {

  render() {
    return <div className="uk-grid-collapse uk-flex-middle d-icon-card" uk-grid="">
      <div className="uk-width-1-4 uk-height-1-1" >
        <div className="uk-height-1-1 uk-padding-small" style={{backgroundColor: this.props.option.backgroundColorLeft}}>
          <div className={`icon-${this.props.option.icon} d-icon-card-icon`}></div>
        </div>
      </div>
      <div className="uk-width-1-2 uk-height-1-1">
        <div className="uk-height-1-1 uk-padding-small" style={{backgroundColor: this.props.option.backgroundColorRight}}>
          <h4 className="uk-margin-remove d-icon-card-title">{this.props.option.title}</h4>
          <div className="d-icon-card-caption">{this.props.option.caption}</div>
        </div>
      </div>
      <div className="uk-width-1-4 uk-height-1-1" >
        <div className="uk-height-1-1 d-icon-card-value" style={{backgroundColor: this.props.option.backgroundColorRight}}>
          {this.props.option.value}
        </div>
      </div>
    </div>
  }
};
