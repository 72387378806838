import React, { useState, useEffect, useMemo, useCallback } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "../../../css/dietz.css";

UIkit.use(Icons);

const ToolsTipPopUp = (props) => {
  const { title, children, show, onClose } = props;

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <>
      {show ? (
        <div className="tooltip-container">
          <div className="tooltip-dialog-container">
            <div>
              <div className="tooltip-dialog-header">
                <h5 className="uk-modal-title">{title || ""}</h5>
                <div className="form-modal-right-header">
                  <button
                    className="uk-button-default form-modal-close-button"
                    type="button"
                    onClick={onClose}
                  >
                    <span uk-icon="icon: close; ratio: 1"></span>
                  </button>
                </div>
              </div>
              <div className="tooltip-dialog-body">{children}</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ToolsTipPopUp;
