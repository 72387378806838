import firebase from "firebase";
 
const config = {
  apiKey: "AIzaSyBTPPSG4a62Z-LsARFRhTnWWyqbHjoVHpM",
  authDomain: "diet-z.firebaseapp.com",
  storageBucket: "diet-z.appspot.com",
  databaseURL: "https://diet-z.firebaseio.com"
};
firebase.initializeApp(config);

firebase.auth().signInAnonymously().catch(function(error) {
  
});

