import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value > 90) {
    return { 
      title: 'ไตปกติ',
      color: 'green',
      caption: 'กรณีพบความผิดปกติที่ไต จะเป็นไตเสื่อมระยะที่ 1 ควรปรึกษาแพทย์',
      suggest: 'รับประทานยาอย่างสม่ำเสมอ รับประทานอาหาร และออกกำลังกายตามปกติ'
    };
  } else if(value > 60) {
    return { 
      title: 'ไตเสื่อมระยะที่ 2',
      color: 'green',
      caption: 'ไตเสื่อมระยะที่ 2',
      suggest: 'รับประทานยาอย่างสม่ำเสมอ รับประทานอาหาร และออกกำลังกายตามปกติ'
    };
  } else if(value > 30) {
    return { 
      title: 'ไตเสื่อมระยะที่ 3',
      color: 'yellow',
      caption: 'ไตเสื่อมระยะที่ 3',
      suggest: 'เข้าคลินิกไตเรื้อรัง ปรับพฤติกรรมตามคำแนะนำของทีมสหวิชาชีพ รับประทานยาอย่างสม่ำเสมอ ควบคุมอาหารตามคำแนะนำของโภชนากร และออกกำลังกายอย่างสม่ำเสมอ'
    };
  } else if(value > 15) {
    return { 
      title: 'ไตเสื่อมระยะที่ 4',
      color: 'orange',
      caption: 'ไตเสื่อมระยะที่ 4',
      suggest: 'เข้าคลินิกไตเรื้อรัง ได้รับคำแนะนำการรักษาบำบัดทดแทนไต และวางแผนการรักษา ปรับพฤติกรรมตามคำแนะนำของทีมสหวิชาชีพ รับประทานยาอย่างสม่ำเสมอ ควบคุมอาหารตามคำแนะนำของโภชนากร และออกกำลังกายอย่างสม่ำเสมอ'
    };
  } else {
    return { 
      title: 'ไตเสื่อมระยะสุดท้าย',
      color: 'red',
      caption: 'ไตเสื่อมระยะสุดท้าย พิจารณาการรักษาบำบัดทดแทนไต',
      suggest: 'เข้าคลินิกไตเรื้อรัง ได้รับคำแนะนำการรักษาบำบัดทดแทนไตและวางแผนการรักษา ปรับพฤติกรรมตามคำแนะนำของทีมสหวิชาชีพ รับประทานยาอย่างสม่ำเสมอ ควบคุมอาหารตามคำแนะนำของโภชนากร และออกกำลังกายอย่างสม่ำเสมอ'
    };
  }
}