import React, { useState, useEffect, useMemo } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const FormNoacPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [data, setData] = useState({});
  var [nxOpds, setNxOpds] = useState([]);

  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_noac",
      formType: "23",
    });
    getNxOPDList();
  }, []);

  async function getNxOPDList() {
    try {
      var result = await Api.consult("GetNxOPDList", {});

      if (result.list.data) {
        const data = result.list.data;
        setNxOpds(data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    setData({
      ...dataForm,
    });
  }, [dataForm]);

  const getTotalScore = useMemo(() => {
    if (data) {
      let rawScore = utilSumTotalScore([
        data.data_1n,
        data.data_2n,
        data.data_3n,
        data.data_4n,
        data.data_5n,
        data.data_6n,
        data.data_7n,
        data.data_8n,
        data.data_9n,
        data.data_10n,
      ]);

      return (rawScore * 100) / 10;
    }
    return null;
  }, [data]);

  const getScoreDesc = useMemo(() => {
    if (!getTotalScore) {
      return "";
    } else if (getTotalScore >= 80) {
      return "ผ่านเกณฑ์";
    } else {
      return "ไม่ผ่านเกณฑ์";
    }
  }, [getTotalScore]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="3.2.2 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยาNOACs"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={memberProfile} />
          </div>
          <div>
            <FormElementTextBox
              label="วิธีการให้คำแนะนำยา"
              value={data.data_28}
              mode="view"
            />
          </div>
          <table
            style={{
              marginTop: "16px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                    rowSpan={3}
                  >
                    หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                    colSpan={3}
                  >
                    ประเมินครั้งที่ {data.data_no}
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementTextBox
                      value={data.data_23}
                      mode="view"
                      dateFormat="dd/MM/yyyy"
                      type="date"
                    />
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ติดตามครั้งถัดไป
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                    rowSpan={2}
                  >
                    หมายเหตุ
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ทราบ
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ไม่ทราบ
                  </th>
                </tr>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1. ผู้ป่วยทราบว่ายา NOACs เป็นยาต้านการแข็งตัวของเลือด
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_1n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_1n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_11} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2. ผู้ป่วยทราบข้อบ่งใช้ของยา NOACs และทราบความแตกต่างระหว่างยา
                  NOACs และยาวาร์ฟาริน (กรณีเปลี่ยนยาวาร์ฟารินเป็นยา NOACs)
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_2n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_2n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_12} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3. ผู้ป่วยทราบขนาดยาที่ได้รับวันนี้ และ
                  ทราบวิธีปฏิบัติตัวเมื่อลืมรับประทานยา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_3n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_3n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_13} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4. ผู้ป่วยทราบว่า ไม่ควรปรับเพิ่มหรือลดขนาดยาเอง
                  และทราบเหตุผลที่ต้องรับประทานยาตรงเวลาอย่างเคร่งครัด
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_4n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_4n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_14} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5.
                  ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะเลือดออกที่ผิดปกติ
                  และทราบวิธีปฏิบัติตัวหากเกิดภาวะดังกล่าว
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_5n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_5n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_15} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6. ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะลิ่มเลือดอุดตัน
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_6n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_6n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_16} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7.
                  ผู้ป่วยทราบว่าต้องแจ้งบุคลากรทางการแพทย์ทุกครั้งที่เข้ารับบริการว่าตนรับประทานยา
                  NOACs อยู่ โดยเฉพาะการทำทันตกรรม การวางแผนตั้งครรภ์
                  และไม่ควรฉีดยาเข้ากล้ามเนื้อ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_7n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_7n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_17} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  8. ผู้ป่วยทราบว่าต้องปรึกษาแพทย์หรือเภสัชกรทุกครั้งก่อนซื้อยา
                  อาหารเสริม หรือสมุนไพรมารับประทานเอง
                  และทราบว่าควรหลีกเลี่ยงการดื่มแอลกอฮอล์ งดสูบบุหรี่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_8n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_8n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_18} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  9.
                  ผู้ป่วยทราบว่าควรหลีกเลี่ยงกิจกรรมที่เสี่ยงต่อกระแทกหรือการบาดเจ็บ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_9n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_9n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_19} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  10. ผู้ป่วยทราบว่าควรเก็บยาให้พ้นแสง และความชื้น
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_10n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {data.data_10n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_20} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนนรวม
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ({getTotalScore}%) {getScoreDesc}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผู้รับการประเมิน (ผู้ป่วย/ญาติ)
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementTextBox value={data.data_27} mode="view" />
                    <FormElementLabel
                      value={data.data_21}
                      mode="view"
                      translate={{ 1: "ผู้ป่วย", 2: "ญาติ" }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เภสัชกรผู้ประเมิน
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={data.data_22} mode="view" />
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementTitle
            label="คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥8คะแนน) จึงถือว่า “ผ่านเกณฑ์” หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ"
            mode="view"
          />
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <FormElementTitle label="การนัดหมายครั้งถัดไป" mode="view" />
            <FormElementLabel label="ห้องตรวจ" mode="view" />
            <FormElementLabel
              value={data.data_24}
              translate={nxOpds}
              mode="view"
            />
            <FormElementTextBox
              value={data.data_25}
              mode="view"
              type="date"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <FormElementTextBox
            label="ข้อมูลเพิ่มเติม"
            value={data.data_26}
            mode="view"
          />
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormNoacPaper;
