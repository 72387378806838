import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementImageView from "../Form/FormElementImageView";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";
const form31_1_image = require("../../../images/form_31_1.jpg");
const form31_2_image = require("../../../images/form_31_2.jpg");
const camelImage = require("../../../images/form_31_2_1.png");
const rhinoImage = require("../../../images/form_31_2_2.png");
const lionImage = require("../../../images/form_31_2_3.png");

import "../../../css/dietz.css";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilLoadXHR = require("../Common/FormUtil.js").loadXHR;
var utilGetBase64 = require("../Common/FormUtil.js").getBase64;

const FormMocaPaper = (props) => {
  const { dataForm, patient, profile } = props;
  var [image1, setImage1] = useState("");
  var [image2, setImage2] = useState("");
  var [image3, setImage3] = useState("");
  var [image4, setImage4] = useState("");
  var [image5, setImage5] = useState("");

  useEffect(() => {
    utilLoadXHR(form31_1_image).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage1(result);
      });
    });
    utilLoadXHR(form31_2_image).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage2(result);
      });
    });
    utilLoadXHR(lionImage).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage3(result);
      });
    });
    utilLoadXHR(rhinoImage).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage4(result);
      });
    });
    utilLoadXHR(camelImage).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage5(result);
      });
    });
  }, []);

  const getScore1 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1,
      dataForm.data_2,
      dataForm.data_3,
      dataForm.data_4,
      dataForm.data_5,
    ]);
  }, [dataForm]);

  const getScore2 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_6,
      dataForm.data_7,
      dataForm.data_8,
    ]);
  }, [dataForm]);

  const getScore4_1 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_19, dataForm.data_20]);
  }, [dataForm]);

  const getScore4_2 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_21]);
  }, [dataForm]);

  const getScore4_3 = useMemo(() => {
    let rawScore = utilSumTotalScore([
      dataForm.data_22,
      dataForm.data_23,
      dataForm.data_24,
      dataForm.data_25,
      dataForm.data_26,
    ]);
    if (rawScore >= 4) {
      return 3;
    } else if (rawScore >= 2) {
      return 2;
    } else if (rawScore == 1) {
      return 1;
    } else {
      return 0;
    }
  }, [dataForm]);

  const getScore5_1 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_27, dataForm.data_28]);
  }, [dataForm]);

  const getScore5_2 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_29]);
  }, [dataForm]);

  const getScore6 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_31, dataForm.data_32]);
  }, [dataForm]);

  const getScore7 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_33,
      dataForm.data_34,
      dataForm.data_35,
      dataForm.data_36,
      dataForm.data_37,
    ]);
  }, [dataForm]);

  const getScore8 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_38,
      dataForm.data_39,
      dataForm.data_40,
      dataForm.data_41,
      dataForm.data_42,
      dataForm.data_43,
    ]);
  }, [dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      getScore1,
      getScore2,
      getScore4_1,
      getScore4_2,
      getScore4_3,
      getScore5_1,
      getScore5_2,
      getScore6,
      getScore7,
      getScore8,
    ]);
  }, [dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="MONTREAL COGNITIVE ASSESSMENT (MOCA)"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <div style={{ display: "inline-flex", gap: "8px", marginTop: "8px" }}>
            <FormElementLabel label="วันที่ทำการทดสอบ" mode="view" />
            <FormElementTextBox
              value={dataForm.data_44}
              dateFormat={"dd/MM/yyyy"}
              type="date"
              mode="view"
            />
          </div>
          <table
            style={{
              marginTop: "8px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  VISUOSPATIAL / EXECUTIVE
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <FormElementImageView 
                        value={dataForm.data_45} 
                        width="200"
                        height="200" />
                      {/* <img src={image1} width={200} height={150} /> */}
                      {dataForm.data_1 == "1" && "/"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <FormElementImageView 
                        value={dataForm.data_46} 
                        width="200"
                        height="200" />
                      {/* <img src={image2} width={200} height={100} /> */}
                      {dataForm.data_2 == "1" && "/"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <FormElementImageView 
                        value={dataForm.data_47} 
                        width="200"
                        height="200" />
                      <FormElementLabel
                        label="วาดหน้าปัดนาฬิกา บอกเวลาที่ 11.10 น. (3 คะแนน)"
                        mode="view"
                      />
                      <div style={{ display: "inline-flex", gap: "8px" }}>
                        {dataForm.data_3 == "1" && "/"}
                        <FormElementLabel label="รูปร่าง" mode="view" />
                        {dataForm.data_4 == "1" && "/"}
                        <FormElementLabel label="ตัวเลข" mode="view" />
                        {dataForm.data_5 == "1" && "/"}
                        <FormElementLabel label="เข็ม" mode="view" />
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore1}/5
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  NAMING
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <img src={image3} width={200} height={130} />
                      {dataForm.data_6 == "1" && "/"}
                    </div>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <img src={image4} width={200} height={130} />
                      {dataForm.data_7 == "1" && "/"}
                    </div>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <img src={image5} width={200} height={130} />
                      {dataForm.data_8 == "1" && "/"}
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore2}/3
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  colSpan={7}
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  MEMORY
                </th>
              </tr>
              <tr>
                <th
                  rowSpan={3}
                  style={{
                    backgroundColor: "#EFF0F3",
                    textAlign: "center",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  อ่านชุดคำเหล่านี้แล้วให้ผู้ทดสอบ <br />
                  ทวนซ้ำ ทดสอบ 2 ครั้ง <br />
                  และถามซ้ำอีกครั้งหลัง 5 นาที
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "100px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "80px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  หน้า
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "80px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผ้าไหม
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "80px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  วัด
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "80px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  มะลิ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "80px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  สีแดง
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ทวนครั้งที่ 1
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_9 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_10 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_11 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_12 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_13 == "1" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ทวนครั้งที่ 2
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_14 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_15 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_16 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_17 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {" "}
                  {dataForm.data_18 == "1" && "/"}
                </td>
              </tr>
            </thead>
          </table>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ATTENTION
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนน
                </th>
              </tr>
              <tr>
                <th
                  rowSpan={2}
                  style={{
                    background: "#EFF0F3",
                    textAlign: "center",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  อ่านตัวเลขต่อไปนี้ตามลำดับ (1 ตัว/วินาที)
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      label="ให้ผู้ทดสอบทบทวนซ้ำตามลำดับ"
                      mode="view"
                    />
                    {dataForm.data_19 == "1" && "/"}
                    <FormElementLabel label="2 1 8 5 4" mode="view" />
                  </div>
                </td>
                <td
                  rowSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore4_1}/2
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      label="ผู้ทดสอบทวนซ้ำแบบย้อนลำดับ"
                      mode="view"
                    />
                    {dataForm.data_20 == "1" && "/"}
                    <FormElementLabel label="7 4 2" mode="view" />
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    textAlign: "center",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  อ่านออกเสียงตัวเลขต่อไปนี้แล้วให้ผู้ทดสอบเคาะโต๊ะ <br />
                  เมื่อได้ยินเสียงอ่านเลข "1" (ไม่มีคะแนนถ้าผิดเกิน 2 ครั้ง)
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    {dataForm.data_21 == "1" && "/"}
                    <FormElementLabel
                      label="5 2 1 3 9 4 1 1 8 0 6 2 1 5 1 9 4 5 1 1 1 4 1 9 0 5 1 1 2"
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore4_2}/1
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    textAlign: "center",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เริ่มจาก 100 ลบไปเรื่อยๆทีละ 7
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      {dataForm.data_22 == "1" && "/"}
                      <FormElementLabel label="93" mode="view" />
                      {dataForm.data_23 == "1" && "/"}
                      <FormElementLabel label="86" mode="view" />
                      {dataForm.data_24 == "1" && "/"}
                      <FormElementLabel label="79" mode="view" />
                      {dataForm.data_25 == "1" && "/"}
                      <FormElementLabel label="72" mode="view" />
                      {dataForm.data_26 == "1" && "/"}
                      <FormElementLabel label="65" mode="view" />
                    </div>
                    <FormElementLabel
                      label="ลบถูก 4 ตัวหรือ 5 ตัวได้ 3 คะแนน, 2 หรือ 3 ตัวได้ 2 คะแนน, 1 ตัวได้ 1 คะแนน, 0 ตัว ไม่ได้คะแนน"
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore4_3}/3
                </td>
              </tr>
            </thead>
          </table>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  LANGUAGE
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel label="Repeat:" mode="view" />
                    {dataForm.data_27 == "1" && "/"}
                    <FormElementLabel
                      label="ฉันรู้ว่าจอมเป็นคนเดียวที่มาช่วยงานในวันนี้"
                      mode="view"
                    />
                    {dataForm.data_28 == "1" && "/"}
                    <FormElementLabel
                      label="แมวมักซ่อนตัวอยู่หลังเก้าอี้เมื่อมีหมาอยู่ในห้อง"
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore5_1}/2
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      label={`Fluency/ บอกคำที่ขึ้นต้นด้วยตัวอักษร "ก" ให้มากที่สุดใน 1 นาที`}
                      mode="view"
                    />
                    <FormElementLabel label="ก" mode="view" />
                    {dataForm.data_29 == "1" && "/"}
                    <FormElementTextBox value={dataForm.data_30} mode="view" />
                    <FormElementLabel
                      label="(N มากกว่าหรือเท่ากับ 11 words)"
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore5_2}/1
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ABSTRACTION
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      label="บอกความเหมือนระหว่าง 2 สิ่ง เช่น กล้วย-ส้ม : เป็นผลไม้"
                      mode="view"
                    />
                    {dataForm.data_31 == "1" && "/"}
                    <FormElementLabel label="รถไฟ - จักรยาน" mode="view" />
                    {dataForm.data_32 == "1" && "/"}
                    <FormElementLabel label="นาฬิกา - ไม้บรรทัด" mode="view" />
                  </div>
                </td>
                <td>{getScore6}/2</td>
              </tr>
            </tbody>
          </table>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  colSpan={8}
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  DELAYED RECALL
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนน
                </th>
              </tr>
              <tr>
                <th
                  rowSpan={3}
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Optional
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ให้ทวนชุดคำที่จำไว้ก่อนหน้านี้โดยไม่มีการให้ตัวช่วย
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "60px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      marginLeft: "4px",
                    }}
                  >
                    {dataForm.data_33 == "1" && "/"}
                    <FormElementLabel label="หน้า" />
                  </div>
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "70px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      marginLeft: "4px",
                    }}
                  >
                    {dataForm.data_34 == "1" && "/"}
                    <FormElementLabel label="ผ้าไหม" />
                  </div>
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "60px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      marginLeft: "4px",
                    }}
                  >
                    {dataForm.data_35 == "1" && "/"}
                    <FormElementLabel label="วัด" />
                  </div>
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "60px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      marginLeft: "4px",
                    }}
                  >
                    {dataForm.data_36 == "1" && "/"}
                    <FormElementLabel label="มะลิ" />
                  </div>
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "60px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      marginLeft: "4px",
                    }}
                  >
                    {dataForm.data_37 == "1" && "/"}
                    <FormElementLabel label="สีแดง" />
                  </div>
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ให้คะแนนเฉพะาคำที่ทวนได้โดยไม่ให้ตัวช่วย
                </td>
                <td
                  rowSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore7}/5
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Catagory Cue
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Multiple choice Cue
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
              </tr>
            </thead>
          </table>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ORIENTATION
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    {dataForm.data_38 == "1" && "/"}
                    <FormElementLabel label="วันที่" />
                    {dataForm.data_39 == "1" && "/"}
                    <FormElementLabel label="เดือน" />
                    {dataForm.data_40 == "1" && "/"}
                    <FormElementLabel label="ปี" />
                    {dataForm.data_41 == "1" && "/"}
                    <FormElementLabel label="วัน" />
                    {dataForm.data_42 == "1" && "/"}
                    <FormElementLabel label="สถานที่" />
                    {dataForm.data_43 == "1" && "/"}
                    <FormElementLabel label="จังหวัด" />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getScore8}/6
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementTitle
            label={`คะแนนรวม: ${getTotalScore}/30`}
            mode="view"
          />
          <FormElementTitle
            label={`เพิ่ม 1 คะแนน ถ้าจำนวนปีการศึกษา น้อยกว่าหรือเท่ากับ 6`}
            mode="view"
          />
          <FormElementTitle label={`ค่าปกติ > 25/30`} mode="view" />
          <FormElementTitle label={`ระดับคะแนน`} mode="view" />
          <FormElementTitle label={`ถ้าคะแนนน้อยกว่า 25 ผิดปกติ`} mode="view" />
          <FormElementTitle
            label={`ถ้าคะแนนมากกว่าหรือเท่ากับ 25 ปกติ`}
            mode="view"
          />
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormMocaPaper;
