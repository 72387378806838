import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

import "../../../css/dietz.css";
const FormHeartFailurePaper = (props) => {
  const { dataForm, patient, profile } = props;

  const lblGender = {
    1: "ชาย",
    2: "หญิง",
  };
  const lblOPDIPD = {
    1: "IPD (AHF)",
    2: "OPD",
  };
  const lblOPDOption = {
    1: "CHF",
    2: "Recent hospitalities ; < 6 month",
  };
  const lbl24 = {
    1: "ครั้งนี้",
    2: "ครั้งล่าสุด",
  };
  const lbl26 = {
    1: "Ischemic/CAD",
    2: "Non-Ischemic",
  };
  const lbl29 = {
    Y: "Yes",
    N: "No",
  };
  const lbl201 = {
    1: "Alcohol/Other Drug",
    0: "",
  };
  const lbl202 = {
    1: "Chemotherapy",
    0: "",
  };
  const lbl203 = {
    1: "Familial",
    0: "",
  };
  const lbl204 = {
    1: "Hypertensive",
    0: "",
  };
  const lbl205 = {
    1: "Peripartum",
    0: "",
  };
  const lbl206 = {
    1: "Viral",
    0: "",
  };
  const lbl207 = {
    1: "Other Etiology",
    0: "",
  };
  const lbl208 = {
    1: "Unknown Etiology",
    0: "",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="Heart Failure Data ศูนย์โรคหัวใจภาคเหนือ โรงพยาบาลมหาราชนครเชียงใหม่"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel label="HF No. " padding="0px 10px 0px 0px" />
          <FormElementTextBox value={dataForm.data_1} mode="view" width="100" />
        </div>
        <div style={{ flexBasis: "100%", height: "0" }}></div>
        <FormElementLabel label="1. Patient profiles" mode="view" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          {/* <div style={{ display: "flex", flexDirection: "row" }}>
            <FormElementLabel
              label="สิทธิ์การรักษา: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_4} mode="view" />
          </div> */}
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="ชื่อผู้ป่วย: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_197} mode="view" />
            <FormElementLabel
              label="HN: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_198} mode="view" />
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            {/* <FormElementLabel
              label="อายุ: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_199 + " ปี"} mode="view" />

            <FormElementLabel
              label="เพศ: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementLabel
              value={dataForm.data_200}
              translate={lblGender}
              mode="view"
            /> */}
            <FormElementLabel
              label="ส่วนสูง: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_2n} mode="view" />
            <FormElementLabel
              label="น้ำหนัก: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_3n} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="สถานภาพผู้ป่วย: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_4} mode="view" />

            <FormElementLabel
              label="ศาสนา: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_5} mode="view" />
            <FormElementLabel
              label="อาชีพ: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_6} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="จำนวนสมาชิกในครอบครัว: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_7n} mode="view" />
            <FormElementLabel
              label="คน"
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementLabel
              label="รายได้ของครอบครัวต่อเดือน: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_8n} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="ภูมิลำเนา: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_9} mode="view" />

            <FormElementLabel
              label="เบอร์โทรศัพท์ผู้ป่วย: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_11} mode="view" />
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px",
          }}>
            <FormElementLabel
              label="ที่อยู่: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_10} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="ชื่อผู้ดูแล: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_12} mode="view" />
            <FormElementLabel
              label="ความสัมพันธ์กับผู้ป่วย: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_13} mode="view" />
            <FormElementLabel
              label="เบอร์โทรศัพท์ผู้ดูแล: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_14} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="ชื่อญาติ: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_15} mode="view" />
            <FormElementLabel
              label="เบอร์โทรศัพท์ญาติ: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_16} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="แหล่งสนับสนุนทางสังคมหรือผู้ให้ความช่วยเหลือทางการเงิน: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_17} mode="view" />
          </div>
        </div>

        <FormElementLabel label="2. Admission profiles" mode="view" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "40px",
            paddingTop: "10px",
          }}
        >
          <FormElementLabel
            label="รับเข้าไว้โครงการ date: "
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <FormElementTextBox
            value={dataForm.data_18}
            type="date"
            format="DD/MM/YYYY"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "40px",
            paddingTop: "10px",
          }}
        >
          <FormElementLabel
            value={dataForm.data_19}
            translate={lblOPDIPD}
            mode="view"
            padding="0px 10px 0px 0px"
          />
          {dataForm.data_19 == "1" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "40px",
              }}
            >
              <FormElementLabel
                label="Admit Date: "
                mode="view"
                padding="0px 10px 0px 0px"
              />
              <FormElementTextBox
                value={dataForm.data_20}
                type="date"
                format="DD/MM/YYYY"
                mode="view"
                width="100"
              />
              <FormElementLabel
                label="Discharge Date: "
                mode="view"
                padding="0px 10px 0px 40px"
              />
              <FormElementTextBox
                value={dataForm.data_21}
                type="date"
                format="DD/MM/YYYY"
                mode="view"
                width="100"
              />
            </div>
          )}
          {dataForm.data_19 == "2" && (
            <div>
              <FormElementLabel
                value={dataForm.data_22}
                translate={lblOPDOption}
                mode="view"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "40px",
                }}
              >
                <FormElementLabel
                  label="Last Admit Date: "
                  mode="view"
                  padding="0px 10px 0px 0px"
                />
                <FormElementTextBox
                  value={dataForm.data_23}
                  type="date"
                  format="DD/MM/YYYY"
                  mode="view"
                  width="100"
                />
                <FormElementLabel
                  label="Last Discharge Date: "
                  mode="view"
                  padding="0px 10px 0px 40px"
                />
                <FormElementTextBox
                  value={dataForm.data_24}
                  type="date"
                  format="DD/MM/YYYY"
                  mode="view"
                  width="100"
                />
              </div>
            </div>
          )}
        </div>
        <FormElementLabel
          label="3. ประวัติการนอนโรงพยาบาล(ถ้ามี)"
          mode="view"
        />
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            value={dataForm.data_25}
            translate={lbl24}
            mode="view"
          />
          {dataForm.data_26 && (
            <FormElementTextArea value={": " + dataForm.data_26} mode="view" />
          )}
        </div>
        <FormElementLabel
          label="4. Heart Failure History Etiology: Check if history of"
          mode="view"
        />
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormElementLabel
            value={dataForm.data_27}
            translate={lbl26}
            mode="view"
          />
          {dataForm.data_27 == "2" && (
            <div
              style={{
                paddingLeft: "40px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <FormElementTextBox
                value={": " + dataForm.data_28}
                mode="view"
                width="100"
              /> */}
              {dataForm.data_29 == "1" && (
                <FormElementLabel
                  translate={lbl201}
                  value={dataForm.data_29}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
              {dataForm.data_30 == "1" && (
                <FormElementLabel
                  translate={lbl202}
                  value={dataForm.data_30}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
              {dataForm.data_31 == "1" && (
                <FormElementLabel
                  translate={lbl203}
                  value={dataForm.data_31}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
              {dataForm.data_32 == "1" && (
                <FormElementLabel
                  translate={lbl204}
                  value={dataForm.data_32}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
              {dataForm.data_33 == "1" && (
                <FormElementLabel
                  translate={lbl205}
                  value={dataForm.data_33}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
              {dataForm.data_34 == "1" && (
                <FormElementLabel
                  translate={lbl206}
                  value={dataForm.data_34}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
              {dataForm.data_35 == "1" && (
                <FormElementLabel
                  translate={lbl207}
                  value={dataForm.data_35}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
              {dataForm.data_35 == "1" && (
                <FormElementLabel
                  translate={lbl208}
                  value={dataForm.data_35}
                  padding="0px 10px 0px 0px"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          )}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Known history of HF prior to this visit: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={lbl29}
            value={dataForm.data_37}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="# of hospital admissions in past 6 mo. for HF: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{ 1: "1", 2: "2", 3: "> 2", 4: "4" }}
            value={dataForm.data_38}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
          {dataForm.data_39 && (
            <FormElementTextBox
              value={dataForm.data_39}
              padding="0px 10px 0px 40px"
              mode="view"
              width="100"
            />
          )}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Heart Failure Diagnosis: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              1: "Heart Failure, primary diagnosis, with CAD",
              2: "Heart Failure, primary diagnosis, no CAD",
              3: "Heart Failure, secondary diagnosis",
            }}
            value={dataForm.data_40}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Atrial Fibrillation (At presentation or during hospitalization): "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              1: "Yes",
              2: "No",
            }}
            value={dataForm.data_41}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
          <span
            hidden={dataForm.data_41 != "1"}
            style={{ display: "inline-flex" }}
          >
            <FormElementLabel
              translate={{
                1: "Documented New Onset",
              }}
              value={dataForm.data_196}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
            <FormElementTextBox
              value={dataForm.data_197}
              type="date"
              mode="view"
            />
          </span>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Atrial Flutter (At presentation or during hospitalization): "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              1: "Yes",
              2: "No",
              3: "Documented New Onset",
            }}
            value={dataForm.data_42}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
          <span
            hidden={dataForm.data_42 != "1"}
            style={{ display: "inline-flex" }}
          >
            <FormElementLabel
              translate={{
                1: "Documented New Onset?",
              }}
              value={dataForm.data_198}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
            <FormElementTextBox
              value={dataForm.data_199}
              type="date"
              mode="view"
            />
          </span>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormElementLabel
            label="Characterization of HF at admission or when first recognized: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          {dataForm.data_43 && (
            <FormElementLabel
              translate={{
                1: "Acute Pulmonary Edema",
                2: "Dizziness/Syncope",
                3: "Dyspnea",
                4: "ICD Shock/Sustained",
                5: "Ventricular Arrhythmia",
                6: "Pulmonary Congestion",
                7: "Volume overload/Weight Gain",
                8: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_43}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_44 && (
            <FormElementLabel
              translate={{
                1: "Dizziness/Syncope",
                3: "Dyspnea",
                4: "ICD Shock/Sustained",
                5: "Ventricular Arrhythmia",
                6: "Pulmonary Congestion",
                7: "Volume overload/Weight Gain",
                8: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_44}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_45 && (
            <FormElementLabel
              translate={{
                1: "Dyspnea",
                4: "ICD Shock/Sustained",
                5: "Ventricular Arrhythmia",
                6: "Pulmonary Congestion",
                7: "Volume overload/Weight Gain",
                8: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_45}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_46 && (
            <FormElementLabel
              translate={{
                1: "ICD Shock/Sustained",
                5: "Ventricular Arrhythmia",
                6: "Pulmonary Congestion",
                7: "Volume overload/Weight Gain",
                8: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_46}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_47 && (
            <FormElementLabel
              translate={{
                1: "Ventricular Arrhythmia",
                6: "Pulmonary Congestion",
                7: "Volume overload/Weight Gain",
                8: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_47}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_48 && (
            <FormElementLabel
              translate={{
                1: "Pulmonary Congestion",
                7: "Volume overload/Weight Gain",
                8: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_48}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_49 && (
            <FormElementLabel
              translate={{
                1: "Volume overload/Weight Gain",
                8: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_49}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_50 && (
            <FormElementLabel
              translate={{
                1: "Worsening fatigue",
                9: "Other",
              }}
              value={dataForm.data_50}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_51 && (
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel
                translate={{
                  1: "Other",
                }}
                value={dataForm.data_51}
                padding="0px 10px 0px 10px"
                mode="view"
                width="100"
              />
              <span hidden={dataForm.data_51 != "1"}>
                <FormElementTextBox value={dataForm.data_203} mode="view" />
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormElementLabel
            label="Other Conditions Contributing to HF Exacerbation (Select all that apply): "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          {dataForm.data_52 && (
            <FormElementLabel
              translate={{
                1: "Arrhythmia",
                2: "Pneumonia/respiratory process",
                3: "Noncompliance - medication",
                4: "Worsening Renal Failure",
                5: "Ischemia/ACS",
                6: "Uncontrolled HTN",
                7: "Noncompliance – Dietary",
                8: "Other",
              }}
              value={dataForm.data_52}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_53 && (
            <FormElementLabel
              translate={{
                1: "Pneumonia/respiratory process",
                3: "Noncompliance - medication",
                4: "Worsening Renal Failure",
                5: "Ischemia/ACS",
                6: "Uncontrolled HTN",
                7: "Noncompliance – Dietary",
                8: "Other",
              }}
              value={dataForm.data_53}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_54 && (
            <FormElementLabel
              translate={{
                1: "Noncompliance - medication",
                4: "Worsening Renal Failure",
                5: "Ischemia/ACS",
                6: "Uncontrolled HTN",
                7: "Noncompliance – Dietary",
                8: "Other",
              }}
              value={dataForm.data_54}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_55 && (
            <FormElementLabel
              translate={{
                1: "Worsening Renal Failure",
                5: "Ischemia/ACS",
                6: "Uncontrolled HTN",
                7: "Noncompliance – Dietary",
                8: "Other",
              }}
              value={dataForm.data_55}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_56 && (
            <FormElementLabel
              translate={{
                1: "Ischemia/ACS",
                6: "Uncontrolled HTN",
                7: "Noncompliance – Dietary",
                8: "Other",
              }}
              value={dataForm.data_56}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_57 && (
            <FormElementLabel
              translate={{
                1: "Uncontrolled HTN",
                7: "Noncompliance – Dietary",
                8: "Other",
              }}
              value={dataForm.data_57}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_58 && (
            <FormElementLabel
              translate={{
                1: "Noncompliance – Dietary",
                8: "Other",
              }}
              value={dataForm.data_58}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_59 && (
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel
                translate={{
                  1: "Other",
                }}
                value={dataForm.data_59}
                padding="0px 10px 0px 10px"
                mode="view"
                width="100"
              />
              <span hidden={dataForm.data_51 != "1"}>
                <FormElementTextBox value={dataForm.data_204} mode="view" />
              </span>
            </div>
          )}
        </div>
        <FormElementLabel
          label="5. First presentation Symptoms (Closest to Admission) Select all that apply: "
          padding="0px 0px 0px 0px"
          mode="view"
        />
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {dataForm.data_60 && (
            <FormElementLabel
              translate={{
                1: "Chest Pain",
                2: "Orthopnea",
                3: "Palpitations",
                4: "Dyspnea at rest",
                5: "Fatigue",
                6: "PND",
                7: "Dyspnea on Exertion",
                8: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_60}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_61 && (
            <FormElementLabel
              translate={{
                1: "Orthopnea",
                3: "Palpitations",
                4: "Dyspnea at rest",
                5: "Fatigue",
                6: "PND",
                7: "Dyspnea on Exertion",
                8: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_61}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}{" "}
          {dataForm.data_62 && (
            <FormElementLabel
              translate={{
                1: "Palpitations",
                4: "Dyspnea at rest",
                5: "Fatigue",
                6: "PND",
                7: "Dyspnea on Exertion",
                8: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_62}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}{" "}
          {dataForm.data_63 && (
            <FormElementLabel
              translate={{
                1: "Dyspnea at rest",
                5: "Fatigue",
                6: "PND",
                7: "Dyspnea on Exertion",
                8: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_63}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}{" "}
          {dataForm.data_64 && (
            <FormElementLabel
              translate={{
                1: "Fatigue",
                6: "PND",
                7: "Dyspnea on Exertion",
                8: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_64}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}{" "}
          {dataForm.data_65 && (
            <FormElementLabel
              translate={{
                1: "PND",
                7: "Dyspnea on Exertion",
                8: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_65}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}{" "}
          {dataForm.data_66 && (
            <FormElementLabel
              translate={{
                1: "Dyspnea on Exertion",
                8: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_66}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}{" "}
          {dataForm.data_67 && (
            <FormElementLabel
              translate={{
                1: "Decreased appetite/early satiety",
                9: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_67}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}{" "}
          {dataForm.data_68 && (
            <FormElementLabel
              translate={{
                1: "Dizziness/lightheadedness/syncope",
              }}
              value={dataForm.data_68}
              padding="0px 10px 0px 0px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_68 == "1" && (
            <div
              style={{
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                label="NYHA class: "
                padding="0px 0px 0px 0px"
                mode="view"
              />
              <FormElementLabel
                translate={{ 1: "I", 2: "II", 3: "III", 4: "IV" }}
                value={dataForm.data_69}
                padding="0px 10px 0px 10px"
                mode="view"
                width="100"
              />
            </div>
          )}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormElementLabel label="Medical History" mode="view" />
          <FormElementLabel
            label="Medical History (Select all that apply): "
            padding="10px 0px 0px 0px"
            mode="view"
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {dataForm.data_70 == "1" && (
            <FormElementLabel
              translate={{ 1: "Anemia" }}
              value={dataForm.data_70}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_71 == "1" && (
            <FormElementLabel
              translate={{ 1: "Atrial Fib (chronic or recurrent)" }}
              value={dataForm.data_71}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_72 == "1" && (
            <FormElementLabel
              translate={{ 1: "Atrial Flutter (chronic or recurrent)" }}
              value={dataForm.data_72}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_73 == "1" && (
            <FormElementLabel
              translate={{ 1: "CAD" }}
              value={dataForm.data_73}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_74 == "1" && (
            <FormElementLabel
              translate={{ 1: "CardioMEMs (implantable hemodynamic monitor)" }}
              value={dataForm.data_74}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_75 == "1" && (
            <FormElementLabel
              translate={{ 1: "COPD or Asthma" }}
              value={dataForm.data_75}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_76 == "1" && (
            <FormElementLabel
              translate={{
                1: "CRT-D (cardiac resynchronization therapy with ICD)",
              }}
              value={dataForm.data_76}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_77 == "1" && (
            <FormElementLabel
              translate={{
                1: "CRT-P (cardiac resynchronization therapy-pacing only)",
              }}
              value={dataForm.data_77}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_78 == "1" && (
            <FormElementLabel
              translate={{ 1: "CVA/TIA" }}
              value={dataForm.data_78}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_79 == "1" && (
            <FormElementLabel
              translate={{ 1: "Depression" }}
              value={dataForm.data_79}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_80 == "1" && (
            <FormElementLabel
              translate={{ 1: "Diabetes" }}
              value={dataForm.data_80}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_81 == "1" && (
            <FormElementLabel
              translate={{ 1: "Dialysis (chronic)" }}
              value={dataForm.data_81}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_82 == "1" && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormElementLabel
                translate={{ 1: "Emerging Infectious Disease: " }}
                value={dataForm.data_82}
                padding="0px 10px 0px 10px"
                mode="view"
                width="100"
              />
              {dataForm.data_82 == "1" && (
                <FormElementLabel
                  translate={{ 1: "Flu", 2: "others infections" }}
                  value={dataForm.data_103}
                  padding="0px 10px 0px 10px"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          )}
          {dataForm.data_83 == "1" && (
            <FormElementLabel
              translate={{ 1: "Familial hypercholesterolemia" }}
              value={dataForm.data_83}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_84 == "1" && (
            <FormElementLabel
              translate={{ 1: "Heart Transplant" }}
              value={dataForm.data_84}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_85 == "1" && (
            <FormElementLabel
              translate={{ 1: "Hyperlipidemia" }}
              value={dataForm.data_85}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_86 == "1" && (
            <FormElementLabel
              translate={{ 1: "Hypertension" }}
              value={dataForm.data_86}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_87 == "1" && (
            <FormElementLabel
              translate={{ 1: "ICD only" }}
              value={dataForm.data_87}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_88 == "1" && (
            <FormElementLabel
              translate={{ 1: "Kidney Transplant" }}
              value={dataForm.data_88}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_89 == "1" && (
            <FormElementLabel
              translate={{ 1: "Left Ventricular Assist Device" }}
              value={dataForm.data_89}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_90 == "1" && (
            <FormElementLabel
              translate={{ 1: "Pacemaker" }}
              value={dataForm.data_90}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_91 == "1" && (
            <FormElementLabel
              translate={{ 1: "Peripheral Vascular Disease" }}
              value={dataForm.data_91}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_92 == "1" && (
            <FormElementLabel
              translate={{ 1: "Prior CABG" }}
              value={dataForm.data_92}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_93 == "1" && (
            <FormElementLabel
              translate={{ 1: "Prior MI" }}
              value={dataForm.data_93}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_94 == "1" && (
            <FormElementLabel
              translate={{ 1: "Prior PCI" }}
              value={dataForm.data_94}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_95 == "1" && (
            <FormElementLabel
              translate={{
                1: "Renal insufficiency - chronic (SCr>2.0) Cr cl<60",
              }}
              value={dataForm.data_95}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_96 == "1" && (
            <FormElementLabel
              translate={{ 1: "Sleep-Disordered Breathing" }}
              value={dataForm.data_96}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_97 == "1" && (
            <FormElementLabel
              translate={{ 1: "TAVR" }}
              value={dataForm.data_97}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_98 == "1" && (
            <FormElementLabel
              translate={{ 1: "TMVR" }}
              value={dataForm.data_98}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_99 == "1" && (
            <FormElementLabel
              translate={{ 1: "Tricuspid Valve procedure" }}
              value={dataForm.data_99}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_100 == "1" && (
            <FormElementLabel
              translate={{ 1: "Valvular Heart Disease" }}
              value={dataForm.data_100}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_101 == "1" && (
            <FormElementLabel
              translate={{ 1: "Ventricular assist device" }}
              value={dataForm.data_101}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
          {dataForm.data_102 == "1" && (
            <FormElementLabel
              translate={{ 1: "No Medical History" }}
              value={dataForm.data_102}
              padding="0px 10px 0px 10px"
              mode="view"
              width="100"
            />
          )}
        </div>
        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Diabetes Type: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{ 1: "Type 1", 2: "Type 2", 3: "ND" }}
            value={dataForm.data_104}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Diabetes Duration: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              1: "New Diagnosis of Diabetes",
              2: "Unknown",
            }}
            value={dataForm.data_105}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
          <FormElementLabel
            translate={{
              1: "<5 years",
              2: "5 - <10 years",
              3: "10 - <20 years",
              4: ">=20 years",
            }}
            value={dataForm.data_106}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Sleep-Disordered Breathing Type: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              1: "Obstructive",
              2: "Central",
              3: "Mixed",
              4: "Unknown/Not Documented",
            }}
            value={dataForm.data_107}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="Equipment used at home: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              1: "O2",
              2: "CPAP",
              3: "Adaptive Servo-Ventilation",
              4: "None",
              5: "Unknown/Not Documented",
            }}
            value={dataForm.data_108}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="History of cigarette smoking? (In the past 12 months): "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              Y: "Yes",
              N: "No",
            }}
            value={dataForm.data_109}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormElementLabel
            label="History of vaping or e-cigarette use in the past 12 months?: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            translate={{
              Y: "Yes",
              N: "No/ND",
            }}
            value={dataForm.data_110}
            padding="0px 10px 0px 10px"
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormElementLabel
            label="Medications Used Prior to Admission or HF consultation: [Select all that apply]: "
            padding="0px 0px 0px 0px"
            mode="view"
          />
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Drug
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  แรกรับ
                </th>
              </tr>
            </thead>
            <tbody>
              {dataForm.data_111 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_111}
                      mode="view"
                      translate={{
                        1: "Patient on no meds prior to admission",
                        0: "",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_112 == "object" &&
                        dataForm.data_112 &&
                        dataForm.data_112.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_113 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_113}
                      mode="view"
                      translate={{ 1: "Anti platelet agent", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_114 == "object" &&
                        dataForm.data_114 &&
                        dataForm.data_114.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_115 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_115}
                      mode="view"
                      translate={{ 1: "Anticoagulation Therapy", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_116 == "object" &&
                        dataForm.data_116 &&
                        dataForm.data_116.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_117 != "" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_117}
                      mode="view"
                      translate={{
                        1: "ACE Inhibitor Angiotensin receptor blocker (ARB) Angiotensin Receptor Neprilysin Inhibitor (ARNI)",
                        2: "Angiotensin receptor blocker (ARB)",
                        3: "Angiotensin Receptor Neprilysin Inhibitor (ARNI)",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_120 == "object" &&
                        dataForm.data_120 &&
                        dataForm.data_120.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_121 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_121}
                      mode="view"
                      translate={{ 1: "Beta-Blocker", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_122 == "object" &&
                        dataForm.data_122 &&
                        dataForm.data_122.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_123 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_123}
                      mode="view"
                      translate={{ 1: "Aldosterone Antagonist", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_124 == "object" &&
                        dataForm.data_124 &&
                        dataForm.data_124.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_125 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_125}
                      mode="view"
                      translate={{ 1: "SGLT2 Inhibitor", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_126 == "object" &&
                        dataForm.data_126 &&
                        dataForm.data_126.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_127 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_127}
                      mode="view"
                      translate={{ 1: "Ivabradine", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_128 == "object" &&
                        dataForm.data_128 &&
                        dataForm.data_128.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_129 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_129}
                      mode="view"
                      translate={{ 1: "Vericiguat", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_130 == "object" &&
                        dataForm.data_130 &&
                        dataForm.data_130.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_131 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_131}
                      mode="view"
                      translate={{ 1: "Antiarrhythmic", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_132 == "object" &&
                        dataForm.data_132 &&
                        dataForm.data_132.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_133 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_133}
                      mode="view"
                      translate={{ 1: "Ca channel blocker", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_134 == "object" &&
                        dataForm.data_134 &&
                        dataForm.data_134.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_135 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_135}
                      mode="view"
                      translate={{
                        1: "Anti-hyperglycemic medications:",
                        0: "",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_136 == "object" &&
                        dataForm.data_136 &&
                        dataForm.data_136.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_137 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_137}
                      mode="view"
                      translate={{ 1: "DPP-4 Inhibitors", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_138 == "object" &&
                        dataForm.data_138 &&
                        dataForm.data_138.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_139 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_139}
                      mode="view"
                      translate={{ 1: "GLP-1 receptor agonist", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_140 == "object" &&
                        dataForm.data_140 &&
                        dataForm.data_140.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_141 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_141}
                      mode="view"
                      translate={{ 1: "Insulin", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_142 == "object" &&
                        dataForm.data_142 &&
                        dataForm.data_142.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_143 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_143}
                      mode="view"
                      translate={{ 1: "Metformin", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_144 == "object" &&
                        dataForm.data_144 &&
                        dataForm.data_144.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_145 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_145}
                      mode="view"
                      translate={{ 1: "Sulfonylurea", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_146 == "object" &&
                        dataForm.data_146 &&
                        dataForm.data_146.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_147 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_147}
                      mode="view"
                      translate={{ 1: "Thiazolidinedione", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_148 == "object" &&
                        dataForm.data_148 &&
                        dataForm.data_148.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_149 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_149}
                      mode="view"
                      translate={{ 1: "Other Oral Agents", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_150 == "object" &&
                        dataForm.data_150 &&
                        dataForm.data_150.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_151 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_151}
                      mode="view"
                      translate={{
                        1: "Other injectable/subcutaneous agents",
                        0: "",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_152 == "object" &&
                        dataForm.data_152 &&
                        dataForm.data_152.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_153 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_153}
                      mode="view"
                      translate={{ 1: "Digoxin", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_154 == "object" &&
                        dataForm.data_154 &&
                        dataForm.data_154.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_155 == "1" && (
                <tr>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_155}
                      mode="view"
                      translate={{ 1: "Diuretic: ", 0: "" }}
                    />
                    <div style={{ marginLeft: "34px" }}>
                      <FormElementLabel
                        value={dataForm.data_156}
                        fieldValue="1"
                        mode="view"
                        translate={{ 1: "Thiazide/Thiazide-like ", 2: "Loop" }}
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      {typeof dataForm.data_157 == "object" &&
                        dataForm.data_157 &&
                        dataForm.data_157.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_158 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_158}
                      mode="view"
                      translate={{ 1: "Hydralazine", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_159 == "object" &&
                        dataForm.data_159 &&
                        dataForm.data_159.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_160 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_160}
                      mode="view"
                      translate={{ 1: "Nitrate", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_161 == "object" &&
                        dataForm.data_161 &&
                        dataForm.data_161.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_163 == "1" && (
                <tr>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_163}
                      mode="view"
                      padding={"0px 20px 0px 0px"}
                      translate={{ 1: "Statin ", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_165 == "object" &&
                        dataForm.data_165 &&
                        dataForm.data_165.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_164 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_164}
                      mode="view"
                      padding={"0px 20px 0px 0px"}
                      translate={{ 1: "Other Lipid lowering agent", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_188 == "object" &&
                        dataForm.data_188 &&
                        dataForm.data_188.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_166 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_166}
                      mode="view"
                      translate={{ 1: "NSAIDS/COXZI", 0: "" }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_167 == "object" &&
                        dataForm.data_167 &&
                        dataForm.data_167.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
              {dataForm.data_168 == "1" && (
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_168}
                      mode="view"
                      translate={{
                        1: "Other medications prior to enrolments",
                        0: "",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      {typeof dataForm.data_169 == "object" &&
                        dataForm.data_169 &&
                        dataForm.data_169.map((item) => {
                          return <span>{item.value}</span>;
                        })}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Lab
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  CBC
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_170}
                    rows={2}
                    cols={50}
                    mode="view"
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  FBS
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_171}
                    rows={2}
                    cols={50}
                    mode="view"
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  BUN electrolyte
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_200}
                    rows={2}
                    cols={50}
                    mode="view"
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  LFT
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_201}
                    rows={2}
                    cols={50}
                    mode="view"
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  TFT
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_202}
                    rows={2}
                    cols={50}
                    mode="view"
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  NT-Pro BNP
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_172}
                    rows={2}
                    cols={50}
                    mode="view"
                    label=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* //TODO waiting for bug fixing */}
          <FormElementLabel
            label="ผลการตรวจพิเศษต่าง ๆ : "
            padding="0px 0px 0px 0px"
            mode="view"
          />
        </div>
        {dataForm.data_173 == "1" && (
          <div
            style={{
              paddingTop: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_174}
              label="CXR: date: "
              type="date"
              format="DD/MM/YYYY"
              mode="view"
              width="100"
            />
          </div>
        )}
        {dataForm.data_175 == "1" && (
          <div
            style={{
              paddingTop: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "row",
              gap: "12px",
            }}
          >
            <span>EKG:</span>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "inline-flex", gap: "8px" }}>
                <span>QRS Duration (ms)</span>
                <FormElementTextBox value={dataForm.data_205} mode="view" />
                <FormElementLabel
                  value={dataForm.data_206}
                  mode="view"
                  translate={{ 1: "Not available" }}
                />
              </div>
              <div style={{ display: "inline-flex", gap: "8px" }}>
                <span>QRS Morphology</span>
                <FormElementLabel
                  value={dataForm.data_207}
                  mode="view"
                  translate={{
                    1: "Normal",
                    2: "LBBB",
                    3: "RBBB",
                    4: "NS-IVCD",
                    5: "Paced",
                    6: "Not available",
                    7: "Other",
                  }}
                />
                <span hidden={dataForm.data_207 != "7"}>
                  <FormElementTextBox value={dataForm.data_208} mode="view" />
                </span>
              </div>
            </div>
            {/* <FormElementTextBox
              value={dataForm.data_176}
              label="EKG: date: "
              type="date"
              format="DD/MM/YYYY"
              mode="view"
              width="100"
            /> */}
          </div>
        )}
        {dataForm.data_177 == "1" && (
          <div
            style={{
              paddingTop: "10px",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
            }}
          >
            <FormElementLabel label="Echocardiogram: " mode="view" />

            <div
              style={{
                paddingTop: "10px",

                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementTextBox
                value={dataForm.data_178}
                label="date: "
                type="date"
                format="DD/MM/YYYY"
                mode="view"
                width="100"
              />
              <div
                style={{
                  paddingLeft: "10px",
                }}
              >
                <FormElementTextBox
                  label="EF: "
                  value={dataForm.data_179}
                  mode="view"
                  width="100"
                />
              </div>
            </div>
          </div>
        )}
        {dataForm.data_182 == "1" && (
          <div
            style={{
              paddingTop: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FormElementTextBox
              label="CAG: date: "
              value={dataForm.data_183}
              type="date"
              format="DD/MM/YYYY"
              mode="view"
              width="100"
            />
          </div>
        )}
        {dataForm.data_184 == "1" && (
          <React.Fragment>
            <div
              style={{
                paddingTop: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementTextBox
                value={dataForm.data_185}
                type="date"
                label="6 MWT: date: "
                format="DD/MM/YYYY"
                mode="view"
                width="100"
              />
              <div style={{ display: "inline-flex", gap: "8px" }}>
                <FormElementTextBox value={dataForm.data_189} mode="view" />
                <FormElementLabel label="เมตร" mode="view" />
              </div>
            </div>
          </React.Fragment>
        )}
        {dataForm.data_186 == "1" && (
          <div
            style={{
              paddingTop: "10px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FormElementTextBox
              label="Other: "
              value={dataForm.data_187}
              mode="view"
              width="100"
            />
          </div>
        )}
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormHeartFailurePaper;
