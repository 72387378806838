import React, { useState, useEffect, useCallback } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import dateFormat from "dateformat";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchPatientVisit from "../../API/fetchPatientVisit.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormDeliveryGeneralMedicinePaper from "../FormPaper/FormDeliveryGeneralMedicinePaper.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormDeliveryGeneralMedicine = (props) => {
  const formConstants = {
    formName: "form_deliverygeneralmedicine",
    formType: "27",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, txn, hn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const prepareData = useCallback((form, patientResponse, patientVisitResponse, profile) => {
    if (!form?.data_1) {
      form.data_1 = patientVisitResponse.tel;
    }
    if (!form?.data_2) {
      form.data_2 = patientVisitResponse.emergency_tel;
    }
    if (!form?.data_20) {
      form.data_20 = patientResponse.patient_name;
    }
    if (!form.data_5) {
      form.data_5 = dateFormat(new Date(), "yyyy-mm-dd");
    }
    if (!form.data_6) {
      form.data_6 = profile.doctorName
    }
    if (!form?.data_21) {
      form.data_21 = "1";
    }
    if (!form?.data_22) {
      form.data_22 = profile.doctorName;
    }
    return form;
  }, []);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const patientVisitRequest = fetchPatientVisit(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all(
      [patientData, patientVisitRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const patientVisitResponse = values[1];
      const patientRoomResponse = values[2];

      data = prepareData({ ...data }, patientResponse, patientVisitResponse, profile);

      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType)
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null)
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    return true;
  }, []);

  const handleSave = useCallback((html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  }, [dataForm, validateSave, displayLoading, hideLoading, onSuccessPopupOpen, displayErrorSavePopup, patient]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" 3.3.1 แบบฟอร์มการติดตามการใช้ยาทั่วไปที่จัดส่งทางไปรษณีย์ " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"1"}
          value={dataForm.data_1}
          label="เบอร์โทรศัพท์"
          type="numeric"
          onChange={handleChange}
          maxLength={10}
          width="100"
        />
        <FormElementTextBox
          fieldId={"2"}
          value={dataForm.data_2}
          label="เบอร์โทรสำรอง"
          type="numeric"
          maxLength={10}
          onChange={handleChange}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"3"}
          value={dataForm.data_3}
          label="ห้องตรวจ"
          onChange={handleChange}
          width="100"
        />
        <span className="formTextBoxLabel">วันที่สั่งยา</span>
        <FormElementTextBox
          fieldId={"4"}
          value={dataForm.data_4}
          type="date"
          label=""
          onChange={handleChange}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <span className="formTextBoxLabel">วันที่ติดตาม</span>
        <FormElementTextBox
          fieldId={"5"}
          value={dataForm.data_5}
          type="date"
          minDate={dataForm.data_4}
          onChange={handleChange}
          width="100"
        />
        <FormElementTextBox
          fieldId={"6"}
          value={dataForm.data_6}
          label="เภสัชกรผู้ติดตาม"
          onChange={() => { }}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <span className="formTextBoxLabel">วิธีการให้คำแนะนำยา</span>
        <FormElementRadio
          fieldId={"37"}
          value={dataForm.data_37}
          fieldValue={"1"}
          onChange={handleChange}
          label="วิดีโอคอล"
        />
        <FormElementRadio
          fieldId={"37"}
          value={dataForm.data_37}
          fieldValue={"2"}
          onChange={handleChange}
          label="โทรศัพท์"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <span className="formTextBoxLabel">ผลการติดตาม</span>
        <FormElementRadio
          fieldId={"7"}
          value={dataForm.data_7}
          fieldValue={"1"}
          onChange={handleChange}
          label="ติดตามสำเร็จ"
        />
        <FormElementRadio
          fieldId={"7"}
          value={dataForm.data_7}
          fieldValue={"2"}
          onChange={handleChange}
          label="ต้องติดตามซ้ำ (โทรแล้วต้องติดตามซ้ำ)"
        />
        <FormElementRadio
          fieldId={"7"}
          value={dataForm.data_7}
          fieldValue={"3"}
          onChange={handleChange}
          label="ติดต่อไม่ได้ (ไม่รับสาย)"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <span className="formTextBoxLabel">ผู้ให้ข้อมูลการติดตาม</span>
        &nbsp;&nbsp;&nbsp;
        <FormElementRadio
          fieldId={"8"}
          value={dataForm.data_8}
          fieldValue={"1"}
          onChange={handleChange}
          label=" ผู้ป่วย "
        />
        <FormElementRadio
          fieldId={"8"}
          value={dataForm.data_8}
          fieldValue={"2"}
          onChange={handleChange}
          label=" อื่นๆ "
        />
        <span className={dataForm.data_8 == "2" ? "form-show" : "form-hidden"}>
          <FormElementTextBox
            fieldId={"9"}
            value={dataForm.data_9}
            label="ระบุ"
            onChange={handleChange}
            width="100"
          />
        </span>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"10"}
          value={dataForm.data_10}
          label="Pharmacist note"
          onChange={handleChange}
          rows={3}
          cols={100}
          width="100"
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>หัวข้อ</th>
                <th>ใช่</th>
                <th>ไม่ใช่</th>
                <th>หมายเหตุ</th>
              </tr>
              <tr>
                <th colSpan={4}>
                  {" "}
                  ความถูกต้อง สมบูรณ์ของยาที่ได้รับทางไปรษณีย์{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> 1. ผู้ป่วยได้รับพัสดุหรือไม่</td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"11"}
                    value={dataForm.data_11}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"11"}
                    value={dataForm.data_11}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementTextArea
                    fieldId={"12"}
                    value={dataForm.data_12}
                    label=""
                    onChange={handleChange}
                    width="100"
                    rows={1}
                    noLimit
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  2. จำนวนรายการยาครบถ้วนหรือไม่
                  <FormElementTextBox
                    fieldId={"13"}
                    value={dataForm.data_13}
                    type="numeric"
                    label="(ยาจำนวน"
                    onChange={handleChange}
                    width="100"
                  />
                  รายการ)
                </td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"14"}
                    value={dataForm.data_14}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"14"}
                    value={dataForm.data_14}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementTextArea
                    fieldId={"15"}
                    value={dataForm.data_15}
                    label=""
                    onChange={handleChange}
                    width="100"
                    rows={1}
                    noLimit
                  />
                </td>
              </tr>
              <tr>
                <td> 3. รายการยาถูกต้องตามใบรายการยาหรือไม่</td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"16"}
                    value={dataForm.data_16}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"16"}
                    value={dataForm.data_16}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementTextArea
                    fieldId={"17"}
                    value={dataForm.data_17}
                    label=""
                    onChange={handleChange}
                    width="100"
                    rows={1}
                    noLimit
                  />
                </td>
              </tr>
              <tr>
                <td> 4. พัสดุยาสภาพสมบูรณ์ สติกเกอร์ฉีกขาดหรือไม่</td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"18"}
                    value={dataForm.data_18}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementRadio
                    fieldId={"18"}
                    value={dataForm.data_18}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="  "
                  />
                </td>
                <td>
                  {" "}
                  <FormElementTextArea
                    fieldId={"19"}
                    value={dataForm.data_19}
                    label=""
                    onChange={handleChange}
                    width="100"
                    rows={1}
                    noLimit
                  />
                </td>
              </tr>
              {/* <tr>
                <td className="uk-text-right">
                  ผู้รับการประเมิน (ผู้ป่วย/ญาติ)
                </td>
                <td colSpan={3} style={{ whiteSpace: "nowrap" }}>
                  <FormElementTextBox
                    fieldId={"20"}
                    value={dataForm.data_20}
                    placeholder="ชื่อผู้รับการประเมิน"
                    onChange={handleChange}
                    width="100"
                  />
                  <FormElementDropdown
                    fieldId={"21"}
                    value={dataForm.data_21}
                    dataList={[
                      { label: "เลือกผู้รับการประเมิน", value: "0" },
                      { label: "ผู้ป่วย", value: "1" },
                      { label: "ญาติ", value: "2" },
                    ]}
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td className="uk-text-right">เภสัชกรผู้ประเมิน</td>
                <td colSpan={3}>{dataForm.data_22 || ""}</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={<FormDeliveryGeneralMedicinePaper
          dataForm={dataForm}
          patient={patient}
          profile={profile}
        />}
      />
    </div>
  );
};

export default FormDeliveryGeneralMedicine;
