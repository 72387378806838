import Api from "../Api";

async function fetchHistoryWardByHn(hn) {
    try {
        var result = await Api.consult("GetHistoryWardByHN", {
            hn,
        });

        if (!result.list) {
            return [];
        }

        return result.list;
    } catch (error) {
        console.error(`fetchHistoryWardByHn hn(${hn})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchHistoryWardByHn;