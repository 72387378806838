import React, { useState, useEffect } from "react";

import "../../../css/dietz.css";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementTitle from "../Form/FormElementTitle";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";
import Api from "../../Api";

const FormACSCaringSetPaper = (props) => {

  const { dataForm, patient, profile, relationshipList, educationList, occupationList } = props;

  var [education, setEducation] = useState({});
  var [occupation, setOccupation] = useState({});
  var [relationship, setRelationship] = useState({});

  useEffect(() => {
    let result = {};
    relationshipList?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setRelationship(result)
  }, [relationshipList, setRelationship]);

  useEffect(() => {
    let result = {};
    educationList?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setEducation(result)
  }, [educationList, setEducation]);

  useEffect(() => {
    let result = {};
    occupationList?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setOccupation(result)
  }, [occupationList, setOccupation]);

  const hospitalTranslate = {
    "06009": "โรงพยาบาลแม่ตื่น จ.เชียงใหม่",
    10713: "โรงพยาบาลนครพิงค์ จ.เชียงใหม่",
    10714: "โรงพยาบาลลำพูน จ.ลำพูน",
    10719: "โรงพยาบาลศรีสังวาลย์ จ.แม่ฮ่องสอน",
    11119: "โรงพยาบาลจอมทอง จ.เชียงใหม่",
    11120: "โรงพยาบาลเทพรัตนเวชชานุกูล เฉลิมพระเกียรติ ๖๐ พรรษา จ.เชียงใหม่",
    11121: "โรงพยาบาลเชียงดาว จ.เชียงใหม่",
    11122: "โรงพยาบาลดอยสะเก็ด จ.เชียงใหม่",
    11123: "โรงพยาบาลแม่แตง จ.เชียงใหม่",
    11124: "โรงพยาบาลสะเมิง จ.เชียงใหม่",
    11125: "โรงพยาบาลฝาง จ.เชียงใหม่",
    11126: "โรงพยาบาลแม่อาย จ.เชียงใหม่",
    11127: "โรงพยาบาลพร้าว จ.เชียงใหม่",
    11128: "โรงพยาบาลสันป่าตอง จ.เชียงใหม่",
    11129: "โรงพยาบาลสันกำแพง จ.เชียงใหม่",
    11130: "โรงพยาบาลสันทราย จ.เชียงใหม่",
    11131: "โรงพยาบาลหางดง จ.เชียงใหม่",
    11132: "โรงพยาบาลฮอด จ.เชียงใหม่",
    11133: "โรงพยาบาลดอยเต่า จ.เชียงใหม่",
    11134: "โรงพยาบาลอมก๋อย จ.เชียงใหม่",
    11135: "โรงพยาบาลสารภี จ.เชียงใหม่",
    11136: "โรงพยาบาลเวียงแหง จ.เชียงใหม่",
    11137: "โรงพยาบาลไชยปราการ จ.เชียงใหม่",
    11138: "โรงพยาบาลแม่วาง จ.เชียงใหม่",
    11139: "โรงพยาบาลแม่ออน จ.เชียงใหม่",
    11140: "โรงพยาบาลแม่ทา จ.ลำพูน",
    11141: "โรงพยาบาลบ้านโฮ่ง จ.ลำพูน",
    11142: "โรงพยาบาลลี้ จ.ลำพูน",
    11143: "โรงพยาบาลทุ่งหัวช้าง จ.ลำพูน",
    11144: "โรงพยาบาลป่าซาง จ.ลำพูน",
    11145: "โรงพยาบาลบ้านธิ จ.ลำพูน",
    11507: "โรงพยาบาลกองบิน 41 จ.เชียงใหม่",
    11508: "โรงพยาบาลค่ายกาวิละ เชียงใหม่ จ.เชียงใหม่",
    11643: "โรงพยาบาลดอยหล่อ จ.เชียงใหม่",
    11978: "โรงพยาบาลดารารัศมี จ.เชียงใหม่",
    11992: "โรงพยาบาลเทพปัญญา 2 โรงพยาบาลทั่วไปขนาดกลาง จ.เชียงใหม่",
    11995: "โรงพยาบาลทั่วไปขนาดใหญ่เซ็นทรัลเชียงใหม่ เมโมเรียล จ.เชียงใหม่",
    11999: "โรงพยาบาลทั่วไปขนาดใหญ่เชียงใหม่เมดิคอลเซ็นเตอร์ จ.เชียงใหม่",
    12279: "โรงพยาบาลส่งเสริมสุขภาพ ศูนย์อนามัยที่ 1 จ.เชียงใหม่",
    12280: "โรงพยาบาลสวนปรุง จ.เชียงใหม่",
    12281: "โรงพยาบาลประสาทเชียงใหม่ จ.เชียงใหม่",
    12283: "โรงพยาบาลมะเร็งลำปาง จ.ลำปาง",
    13775: "สถาบันพัฒนาการเด็กราชนครินทร์ จ.เชียงใหม่",
    13780: "โรงพยาบาลมหาราชนครเชียงใหม่ มหาวิทยาลัยเชียงใหม่ จ.เชียงใหม่",
    13785: "โรงพยาบาลธัญญารักษ์เชียงใหม่ จ.เชียงใหม่",
    14197: "โรงพยาบาลธัญญรักษ์แม่ฮ่องสอน จ.แม่ฮ่องสอน",
    14550: "โรงพยาบาลทั่วไปขนาดใหญ่ เชียงใหม่ใกล้หมอ จ.เชียงใหม่",
    14555: "โรงพยาบาลทั่วไปขนาดกลางศิริเวช ลำพูน จ.ลำพูน",
    14916: "โรงพยาบาลทั่วไปขนาดกลางเชียงใหม่ฮอสพิทอล จ.เชียงใหม่",
    23736: "โรงพยาบาลวัดจันทร์ เฉลิมพระเกียรติ 80 พรรษา จ.เชียงใหม่",
    99999: "อื่นๆ",
  };

  async function getFamilyItem() {
    try {
      var result = await Api.consult("GetFamilyItem", {});

      var { data } = result;
      console.log(data);
      setRelationship(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getEducation() {
    try {
      var result = await Api.consult("GetEducationItem", {});

      var { data } = result;
      console.log(data);
      setEducation(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getOccupation() {
    try {
      var result = await Api.consult("GetOccupationItem", {});

      var { data } = result;
      console.log(data);
      setOccupation(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getFamilyItem();
    getEducation();
    getOccupation();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="Suandok ACS caring set" mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <FormElementLabel label="Timeline" mode="view" />
        <table style={{ borderCollapse: "collapse", border: "1px solid grey" }}>
          <thead>
            <tr style={{ paddingTop: "10px" }}>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Owner
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
                colSpan={5}
              >
                Timeline Record form for ACS โรงพยาบาลมหาราชนครเชียงใหม่
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER
              </td>
              <td
                colSpan={5}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_1}
                  translate={{ 0: "", 1: "New case & arrival by" }}
                  mode="view"
                />
                <span hidden={dataForm.data_1 != "1"}>
                  <FormElementLabel
                    value={dataForm.data_2}
                    translate={{ 1: ": byself", 2: ": family", 3: ": EMS" }}
                    mode="view"
                  />
                </span>
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER
              </td>
              <td
                colSpan={5}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementLabel
                  value={dataForm.data_1}
                  translate={{ 1: "", 2: "Refer from" }}
                  mode="view"
                />
                <span hidden={dataForm.data_1 != "2"}>
                  <FormElementLabel
                    value={dataForm.data_3}
                    mode="view"
                    translate={hospitalTranslate}
                  />
                  <span hidden={dataForm.data_3 != "99999"}>
                    <FormElementTextBox mode="view" value={dataForm.data_182} />
                  </span>
                </span>
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              ></th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Event
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                วันที่ - เวลา
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
                colSpan={2}
              >
                เวลาที่ใช้ไป
              </th>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                1. Onset of symptom
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_4}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                2. Arrive to ER 1st รพช
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_5}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Onset to FMC 2-1
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_6}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                3. First 12 leads ECG at รพช
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_7}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER to EKG รพช 3-2
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_8}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                4. Depart from ER 1st hospital
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_9}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Di-Do 4-2
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_10}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                5. Arrive to ER Suandok
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_11}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Transfer time 5-4
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_12}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                6. First 12 leads ECG at Suandok
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_13}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER to EKG รพช 6-5
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_14}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                7. ER Physician visit
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_15}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Do to Dr 7-5
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_16}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                8. Final diagnosis at ER
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_17}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER to Dx 8-5
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_18}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                9. Consult cardiologist
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_19}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                10. Cardiologist visit or call back
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_20}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER to Cardio 10-5
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_21}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                11. Depart from ER Suandok
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_22}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Cath Lab
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                12. Activated for PPCI
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_23}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                CCU/CL
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                13. Arrive to CCU/ward
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_24}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER to ward 13-11
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_25}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Cath Lab
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                14. Arrive to Cath-Lab
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_27}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER to cath 14-11
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  id={28}
                  value={dataForm.data_28}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Cath Lab
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                15.PPCI: Vascular access site
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_29}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Do to vas access 15-5
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_30}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr style={{ paddingTop: "10px" }}>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Cath Lab
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                16.PPCI: Wire crossing
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  fieldId={30}
                  value={dataForm.data_31}
                  mode="view"
                  type="date"
                  dateFormat="dd/MM/yyyy, HH:mm"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Do to wire 16-5
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_150}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            label="Suandok ACS caring set"
            mode="view"
            padding="10px 0px 0px 0px"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            label="ข้อมูลพื้นฐาน 1/2 (สำหรับ CCU & IPD )"
            mode="view"
            padding="10px 0px 0px 40px"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 30px",
          }}
        >
          <FormElementTextBox
            value={dataForm.data_32}
            label="Emergency contact (Name): "
            mode="view"
            width="100"
          />
          <FormElementLabel label="Relationship: " mode="view" />
          &nbsp;
          <FormElementLabel
            value={dataForm.data_33}
            translate={relationship}
            mode="view"
          />
          <span hidden={dataForm.data_33 != "99"}>
            <FormElementTextBox
              value={dataForm.data_183}
              mode="view"
              width="100"
            />
          </span>
          &nbsp;
          <FormElementTextBox
            value={dataForm.data_34}
            label="Phone: "
            mode="view"
            width="100"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 30px",
          }}
        >
          <FormElementLabel label="Education: " mode="view" />
          &nbsp;
          <FormElementLabel
            value={dataForm.data_35}
            translate={education}
            mode="view"
          />
          &nbsp;
          <FormElementLabel label="Occupation: " mode="view" />
          &nbsp;
          <FormElementLabel
            value={dataForm.data_36}
            translate={occupation}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 30px",
          }}
        >
          <FormElementTextBox
            value={dataForm.data_37}
            label="Vital signs: Temp: "
            mode="view"
            width="100"
          />
          <span style={{ paddingLeft: "10px" }}>oC</span>
          <FormElementTextBox
            value={dataForm.data_38}
            label="HR: "
            mode="view"
            width="100"
          />
          <span style={{ paddingLeft: "10px" }}>time/min</span>
          <FormElementTextBox
            value={dataForm.data_39}
            label="RR: "
            mode="view"
            width="100"
          />
          <span style={{ paddingLeft: "10px" }}>time/min</span>
          <FormElementTextBox
            value={dataForm.data_40}
            label="BP: "
            mode="view"
            width="100"
          />
          <span style={{ paddingLeft: "10px" }}>mmHg.</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 30px",
          }}
        >
          <FormElementTextBox
            value={dataForm.data_41}
            label="ส่วนสูง: "
            mode="view"
            width="100"
          />
          <span style={{ paddingLeft: "10px" }}>ซม.</span>
          <FormElementTextBox
            value={dataForm.data_42}
            label="น้ำหนัก: "
            mode="view"
            width="100"
          />
          <span style={{ paddingLeft: "10px" }}>กก.</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            label="ข้อมูลพื้นฐาน 2/2 (สำหรับ CCU & IPD )"
            mode="view"
            padding="10px 0px 0px 40px"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Smoking: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_43}
            translate={{ 1: "Current smoker", 2: "Ex smoker", 3: "Never" }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="HT: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_44}
            translate={{ 0: "No", 1: "Yes" }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="DLP: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_45}
            translate={{
              0: "No",
              1: "Yes (LDL > 100 หรือ HDL < 40 ในเพศชาย หรือ HDL < 50ในเพศหญิงหรือมีประวัติการใช้ยา statin)",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="DM: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_46}
            translate={{
              1: "No",
              2: "Yes",
              3: "Diet",
              4: "Oral",
              5: "Insulin (HbA1C > 6.5 หรือ FBS > 126 หรือ random BS > 200 หรือ มีประวัติใช้ยาต้านเบาหวาน)",
            }}
            mode="view"
          />
          <FormElementLabel
            value={dataForm.data_47}
            translate={{
              1: "No",
              2: "Yes",
              3: "Diet",
              4: "Oral",
              5: "Insulin (HbA1C > 6.5 หรือ FBS > 126 หรือ random BS > 200 หรือ มีประวัติใช้ยาต้านเบาหวาน)",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Family Hx of CAD: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_48}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Prior MI: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_49}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Known CAD (stenosis > 50%): "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_50}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="CVD disease: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_51}
            translate={{
              0: "No",
              1: "Yes CVD=cerebrovascular disease",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="PAD: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_52}
            translate={{
              0: "No",
              1: "Yes CVD=cerebrovascular disease",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Prior PCI: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_53}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
          <span style={{ display: 'inline-flex', marginLeft: '8px' }} hidden={dataForm.data_53 != '1'}>
            <FormElementTextBox
              label="Last year"
              mode="view"
              padding="0px 0px 0px 10px"
            />
            <FormElementTextBox
              value={dataForm.data_54}
              type="date"
              mode="view"
              padding="0px 0px 0px 10px"
            />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Prior CABG: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_55}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
          <span style={{ display: 'inline-flex', marginLeft: '8px' }} hidden={dataForm.data_55 != '1'}>
            <FormElementTextBox
              label="Last year"
              mode="view"
              padding="0px 0px 0px 10px"
            />
            <FormElementTextBox
              value={dataForm.data_56}
              type="date"
              mode="view"
              padding="0px 0px 0px 10px"
            />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Prior HF: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_57}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Prior valve surgery: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_58}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Chronic lung: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_59}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="On dialysis: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_60}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Complication at admission: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 80px",
          }}
        >
          <FormElementLabel
            label="1. HF: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_61}
            translate={{
              0: "No",
              1: "Yes, class: ",
            }}
            mode="view"
          />
          {dataForm.data_61 == "1" && (
            <FormElementLabel
              value={dataForm.data_62}
              translate={{
                1: "II",
                2: "III",
                3: "IV",
              }}
              mode="view"
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 80px",
          }}
        >
          <FormElementLabel
            label="2. Arrhythmia: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_63}
            translate={{
              0: "No",
              1: "Yes",
              2: "Type",
              3: "CHB",
              4: "VT/VF",
            }}
            mode="view"
          />
          <FormElementLabel
            value={dataForm.data_64}
            translate={{
              2: "CHB",
              3: "VT/VF",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 80px",
          }}
        >
          <FormElementLabel
            label="3. CPR: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_65}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 80px",
          }}
        >
          <FormElementLabel
            label="4. Death: "
            mode="view"
            padding="10px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_66}
            translate={{
              0: "No",
              1: "Yes",
            }}
            mode="view"
          />
          <FormElementLabel
            value={dataForm.data_67}
            translate={{
              1: "pre hosp",
              2: "ER",
              3: "on admission",
              4: "during transfer",
            }}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            label="Diagnosis and plan management. (สำหรับแพทย์)"
            mode="view"
            padding="10px 0px 0px 0px"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Diagnosis: "
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <FormElementLabel
            value={dataForm.data_68}
            translate={{
              1: "STEMI",
              2: "NSTEACS",
            }}
            mode="view"
          />
        </div>

        <span hidden={dataForm.data_68 != "1"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 80px",
            }}
          >
            <FormElementLabel
              label="Area of involvement: "
              mode="view"
              padding="0px 10px 0px 0px"
            />
            {dataForm.data_137 && (
              <FormElementLabel
                value={dataForm.data_137}
                translate={{
                  1: "Anterior",
                  2: "Inferior",
                  3: "Lateral",
                  4: "Posterior",
                  5: "RV",
                  6: "Unknown",
                }}
                mode="view"
              />
            )}
            {dataForm.data_138 && (
              <FormElementLabel
                value={dataForm.data_138}
                translate={{
                  1: "Inferior",
                  3: "Lateral",
                  4: "Posterior",
                  5: "RV",
                  6: "Unknown",
                }}
                mode="view"
              />
            )}
            {dataForm.data_139 && (
              <FormElementLabel
                value={dataForm.data_139}
                translate={{
                  1: "Lateral",
                  4: "Posterior",
                  5: "RV",
                  6: "Unknown",
                }}
                mode="view"
              />
            )}
            {dataForm.data_140 && (
              <FormElementLabel
                value={dataForm.data_140}
                translate={{
                  1: "Posterior",
                  5: "RV",
                  6: "Unknown",
                }}
                mode="view"
              />
            )}
            {dataForm.data_141 && (
              <FormElementLabel
                value={dataForm.data_141}
                translate={{
                  1: "RV",
                  6: "Unknown",
                }}
                mode="view"
              />
            )}
            {dataForm.data_142 && (
              <FormElementLabel
                value={dataForm.data_142}
                translate={{
                  1: "Unknown",
                }}
                mode="view"
              />
            )}
          </div>
        </span>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Plan "
            mode="view"
            padding="0px 10px 0px 0px"
          />
        </div>
        <span hidden={dataForm.data_68 != "1"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
          >
            <FormElementLabel
              label="STEMI Plan: "
              mode="view"
              padding="0px 10px 0px 40px"
            />
            <FormElementLabel
              value={dataForm.data_70}
              translate={{
                1: "Fibrinolytic",
                2: "PPCI",
                3: "Rescue PCI",
                4: "PHI ( pharmacoinvasive)",
                5: "Secondary PCI (PCI after 24hr)",
                6: "Conservative",
              }}
              mode="view"
            />
          </div>
        </span>
        <span hidden={dataForm.data_68 != "2"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
          >
            <FormElementLabel
              label="NSTEACS Plan: "
              mode="view"
              padding="0px 10px 0px 40px"
            />
            <FormElementLabel
              value={dataForm.data_71}
              translate={{
                1: "Invasive",
                2: "Conservative",
              }}
              mode="view"
            />
          </div>
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Risk assessment (สำหรับแพทย์ CCU&IPD) ประเมินแรกรับ ภายใน 24 ชั่วโมง"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <FormElementTextBox
                      label="GRACE score"
                      value={dataForm.data_72}
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  hidden={dataForm.data_68 != "1"}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span>STEMI (ความเสี่ยงต่อ Cardiac arrest)</span>
                  <div>
                    <FormElementLabel
                      value={dataForm.data_73}
                      mode="view"
                      translate={{
                        1: "low (<126)",
                        2: "intermediate (126 – <155)",
                        3: "high (>155)",
                      }}
                    />
                  </div>
                </td>
                <td
                  hidden={dataForm.data_68 != "2"}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span>NSTEMI (ความเสี่ยงต่อ Cardiac arrest)</span>
                  <div>
                    <FormElementLabel
                      value={dataForm.data_74}
                      mode="view"
                      translate={{
                        1: "low (1-108)",
                        2: "intermediate (109-140)",
                        3: "high (>140)",
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <span>Bleeding (ARC-HBR)</span>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <FormElementLabel
                      value={dataForm.data_75}
                      mode="view"
                      translate={{
                        1: "yes, high risk",
                        2: "no risk",
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <span>Heart Failure</span>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {dataForm.data_76 == "1" && <span>EF{"<"}40%</span>}
                    {dataForm.data_157 == "1" && <span>Ant wall</span>}
                    {dataForm.data_158 == "1" && (
                      <span>Not reperfusion in STEMI</span>
                    )}
                    {dataForm.data_159 == "1" && <span>eGFR{"<"}60</span>}
                    {dataForm.data_160 == "1" && <span>High risk GRACE</span>}
                    {dataForm.data_161 == "1" && <span>N/A</span>}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <span>Cardiac arrhythmia</span>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {dataForm.data_77 == "1" && <span>Electrolyte (K,mg)</span>}
                    {dataForm.data_162 == "1" && <span>EF{"<"}40%</span>}
                    {dataForm.data_163 == "1" && <span>Ant wall</span>}
                    {dataForm.data_164 == "1" && <span>N/A</span>}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <span>Strok</span>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {dataForm.data_78 == "1" && <span>Electrolyte (K,mg)</span>}
                    {dataForm.data_165 == "1" && <span>Ant wall</span>}
                    {dataForm.data_166 == "1" && (
                      <span>Not reperfusion in STEMI</span>
                    )}
                    {dataForm.data_167 == "1" && <span>N/A</span>}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Risk assessment (สำหรับแพทย์ CCU&IPD) ประเมินแรกรับ ภายใน 24 ชั่วโมง"
            mode="view"
            padding="0px 10px 0px 0px"
          />
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Diagnosis/ Type:{" "}
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <FormElementLabel
                      value={dataForm.data_79}
                      translate={{ 1: "STEMI", 2: "NSTEMI/UA" }}
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_80}
                    mode="view"
                    label="type"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm.data_79 != "1"}>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Area of involvement:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_81}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Treatment:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_82}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Underlying disease:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_83}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Coronary angiogram:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_84}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Revascularization:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_85}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  LVEF:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_86}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={8}
                >
                  Complication during admission:{" "}
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Bleeding BARC type:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_87}
                    translate={{ 0: "No", 1: "Yes" }}
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                  <FormElementTextBox
                    value={dataForm.data_88}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Infection:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_89}
                    translate={{ 0: "No", 1: "Yes" }}
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                  <FormElementTextBox
                    value={dataForm.data_90}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  AKI:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_91}
                    translate={{ 0: "No", 1: "Yes" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_92}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Respiratory failure:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_93}
                    translate={{ 0: "No", 1: "Yes" }}
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                  <FormElementTextBox
                    value={dataForm.data_94}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Mechanical complication:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_95}
                    translate={{ 0: "No", 1: "Yes" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_96}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Arrhythmia:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_97}
                    translate={{ 0: "No", 1: "Yes" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_98}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Recurrent ACS:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_99}
                    translate={{ 0: "No", 1: "Yes" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />

                  <FormElementTextBox
                    value={dataForm.data_100}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Heart failure:{" "}
                </th>
                <td
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_101}
                    translate={{ 0: "No", 1: "Yes" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_102}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Risk factors:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                    gap: '8px',
                  }}
                >
                  {dataForm.data_103 == '1' && <span>DLP</span>}
                  {dataForm.data_151 == '1' && <span>DM</span>}
                  {dataForm.data_152 == '1' && <span>HT</span>}
                  {dataForm.data_153 == '1' && <span>Smoking</span>}
                  {dataForm.data_154 == '1' && <span>{'BMI > 25'}</span>}
                  {dataForm.data_155 == '1' && <span>FmHx</span>}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Lab:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                    flexWrap: 'wrap'
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_143}
                    mode="view"
                    label="LDL="
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_144}
                    mode="view"
                    label="HDL="
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_145}
                    mode="view"
                    label="TG="
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_146}
                    mode="view"
                    label="HbA1c="
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_147}
                    mode="view"
                    label="eGFR="
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_148}
                    mode="view"
                    label="Hb="
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_149}
                    mode="view"
                    label="Hct="
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Plan of Treatment แผนการรักษา:{" "}
                </th>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    value={dataForm.data_105}
                    mode="view"
                    width="100"
                    rows={4}
                    cols={80}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="Discharge summary nurse (หอผู้ป่วยที่จำหน่ายผู้ป่วย)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 40px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 40px",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_106}
              mode="view"
              label="BP: "
              width="100 "
            />
            <span style={{ paddingLeft: "10px" }}> mmHg</span>
            <FormElementTextBox
              value={dataForm.data_107}
              mode="view"
              label="HR: "
              width="100 "
            />
            <span style={{ paddingLeft: "10px" }}>bpm (at discharge time)</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 40px",
            }}
          >
            <FormElementTextArea
              value={dataForm.data_108}
              rows={2}
              cols={80}
              mode="view"
              label="Home environment: "
              width="100 "
            />
          </div>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  รายละเอียดขั้นตอนการจำหน่าย
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การประเมิน
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การปฏิบัติ/คำแนะนำ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ประเมินความรู้ความเข้าใจของผู้ป่วยและญาติเกี่ยวกับโรคหัวใจและหลอดเลือดและการรักษาที่ได้รับ:
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_109}
                    translate={{ 1: "Done", 2: "Not Done" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_168} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>การประเมินด้านจิตสังคม: </strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>
                    <strong>Depression: </strong>
                  </span>
                  <FormElementLabel
                    value={dataForm.data_110}
                    translate={{ 1: "absent", 2: "present (2Q)" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span hidden={dataForm.data_110 != "2"}>
                    <FormElementLabel
                      value={dataForm.data_111}
                      translate={{ 1: "Mild", 2: "Moderate", 3: "Severe (9Q)" }}
                      mode="view"
                    />
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_169} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>
                    <strong>Anxiety: </strong>
                  </span>
                  <FormElementLabel
                    value={dataForm.data_112}
                    translate={{ 1: "absent", 2: "present (2Q)" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span hidden={dataForm.data_112 != "2"}>
                    <FormElementLabel
                      value={dataForm.data_113}
                      translate={{ 1: "Mild", 2: "Moderate", 3: "Severe (9Q)" }}
                      mode="view"
                    />
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_170} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ให้ความรู้เรื่องโรคและการรักษาที่ได้รับ:{" "}
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_114}
                    translate={{ 1: "Done", 2: "Not Done" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_115}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_171} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ให้ความรู้เรื่องอาหารที่เหมาะสมกับโรค:{" "}
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_116}
                    translate={{ 1: "Done", 2: "Not Done" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_117}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_172} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ให้ความรู้เรื่องการรับประทานยา:{" "}
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_118}
                    translate={{ 1: "Done", 2: "Not Done" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_119}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_173} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ให้ความรู้เรื่องการออกกำลังกายที่เหมาะสมกับโรค:{" "}
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_120}
                    translate={{ 1: "Done", 2: "Not Done" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />

                  <FormElementTextBox
                    value={dataForm.data_121}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_174} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การสังเกตอาการผิดปกติที่ควรมาพบแพทย์ก่อนนัด:{" "}
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_122}
                    translate={{ 1: "Done", 2: "Not Done" }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  <FormElementTextBox
                    value={dataForm.data_123}
                    mode="view"
                    label="ระบุ"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_175} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  อื่นๆ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    value={dataForm.data_124}
                    rows={2}
                    cols={50}
                    mode="view"
                    label=""
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_176} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingTop: "0px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Cardiac Rehabilitation Program Phase I คำแนะนำที่ให้:
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                    }}
                  >
                    <strong>Cardiac rehabilitation: </strong>

                    <div style={{ paddingLeft: "10px" }}>
                      <FormElementLabel
                        value={dataForm.data_125}
                        translate={{ 0: "No", 1: "Yes" }}
                        mode="view"
                      />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <FormElementTextBox
                        value={dataForm.data_126}
                        mode="view"
                        width="100"
                      />
                    </div>
                    <span style={{ paddingLeft: "10px" }}>
                      METs at discharge
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                    }}
                  >
                    <span>
                      <strong>Home walking program: </strong>
                    </span>
                    <div style={{ paddingLeft: "10px" }}>
                      <FormElementLabel
                        value={dataForm.data_127}
                        translate={{ 0: "No", 1: "Yes" }}
                        mode="view"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                    }}
                  >
                    <span style={{ paddingRight: "10px" }}>
                      <strong>คู่มือการปฏิบัติตัว: </strong>
                    </span>
                    <FormElementLabel
                      value={dataForm.data_128}
                      translate={{ 0: "No", 1: "Yes" }}
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_177} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Occupation/ Return to work /Return to daily activity
                  recommendation:
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <FormElementLabel
                      value={dataForm.data_129}
                      translate={{ 1: "Done", 2: "Not Done" }}
                      mode="view"
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <span style={{ paddingRight: "10px" }}>
                      <strong>And need occ Med consultation: </strong>
                    </span>
                    <FormElementLabel
                      value={dataForm.data_130}
                      translate={{ 1: "Done", 2: "Not Done" }}
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_178} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ให้ความรู้เรื่องการมาตรวจตามนัด:
                  <div style={{ paddingRight: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_131}
                      type="date"
                      label="วันที่ F/U วันที่: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingRight: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_132}
                      mode="view"
                      label="สถานที่: "
                      width="100"
                    />
                  </div>
                  <div
                    style={{
                      paddingRight: "10px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_133}
                      mode="view"
                      translate={{ 1: "Refer รพ.: " }}
                      width="100"
                      padding="0px 10px 0px 0px"
                    />
                    <FormElementTextBox
                      value={dataForm.data_134}
                      mode="view"
                      label=""
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingRight: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_135}
                      translate={{
                        1: "E-Cardio application",
                        2: "Electronic/ LINE Tele Follow up",
                        3: "paper",
                        4: "none",
                      }}
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_179} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  สรุปปัญหาที่ส่งต่อ/ที่หลงเหลือ:{" "}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>
                    <FormElementTextArea
                      rows={5}
                      cols={50}
                      value={dataForm.data_136}
                      mode="view"
                      label=""
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea value={dataForm.data_180} mode="view" />
                </td>
              </tr>
            </tbody>
          </table>
          <span hidden={!dataForm.form_id}>
            <FormElementTextArea
              value={dataForm.data_156}
              mode="view"
              label="เหตุผลในการแก้ไข"
              width="100"
            />
          </span>
          <SavingProfileIPaper profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default FormACSCaringSetPaper;
