import React, { useState, useEffect, useCallback } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Iframe from 'react-iframe'
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import DateTimePicker from "../../Components/DatePicker.js";
import moment from 'moment';
import { useBeforeunload } from 'react-beforeunload';

import WSConst from '../../Components/Consult/Common/WSConst';
import MemberAppointmentCall from '../../Components/Consult/MemberAppointmentCall';

import TeleLogTable from '../../Components/Consult/TeleLogTable';

var WS_URL = process.env.REACT_APP_WS_URL;

UIkit.use(Icons);

const AppointmentCallRoom = (props) => {
  const query = new URLSearchParams(props.location.search);

  const [loadingApi, setLoadingApi] = useState(true);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [consultName, setConsultName] = useState("");
  const [memberCallList, setMemberCallList] = useState([]);
  const [memberCallListDisplay, setMemberCallListDisplay] = useState([]);
  const [memberName, setMemberName] = useState("");
  const [roomName, setRoomName] = useState("");
  const [iframSrc, setIframSrc] = useState("");
  const [memberClicked, setMemberClicked] = useState(null);
  const [roomData, setRoomData] = useState({});
  const [reconnectTxn, setReconnectTxn] = useState(null);
  const [showPopupDetail, setShowPopupDetail] = useState(false);
  const [searching, setSearching] = useState(false);
  const [roomPrefix, setRoomPrefix] = useState("Txn");

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE + "-Nurse Room Video Call Center";
 }, []);

  const { sendJsonMessage, getWebSocket } = useWebSocket(WS_URL, {
    onOpen: () => onConnectionOpen(),
    onClose: () => onConnectionClosed(),
    onMessage: (event) => onClientMessage(event),
    shouldReconnect: (closeEvent) => true
  });

  function onConnectionOpen() {
    console.log("Room Call Connected to " + WS_URL);
  }

  function onConnectionClosed() {

  }

  async function onClientMessage(event) {
    var message = JSON.parse(event.data);

    if(message.type == "WS_INIT_PATIENT_DATA") {
      var memberData = message.message;

      var found = memberCallList.find(m => m.txn == memberData.txn);

      if(typeof(found) == "undefined") {
        var onlineStatus = "online";
        var actionStatus = "new";
        var length = memberCallList.length;

        var memberCalls = memberCallList;
        memberCalls.push(createMemberData({ 
          actorId : memberData.actorId, 
          txn : memberData.txn, 
          hn : memberData.hn, 
          patientName : memberData.name, 
          onlineStatus : onlineStatus, 
          entryTime : memberData.entryTime, 
          actionStatus : actionStatus }));

        setMemberCallList((d) => (memberCalls));

      } else {
        found.onlineStatus = "online";
        found.actionStatus = "new";

        if((memberClicked != null) && (memberClicked.actorId == found.actorId)) {
          found.actionStatus = "active";
        }
      }

      //Log
      var result = await Api.consult("MemberEntryRoomLog", {
        txn: memberData.txn,
        hn: memberData.hn,
        status: "entry"
      });

    } else if(message.type == "WS_PATIENT_OFFLINE") {
      var memberData = message.message;

      var found = memberCallList.find(m => m.txn == memberData.txn);

      if(memberData.txn != reconnectTxn) {
        if(typeof(found) != "undefined") {
          found.onlineStatus = "offline";
          found.actionStatus = "";
        }
  
        if(memberData.txn != null) {
          var result = await Api.consult("MemberEntryRoom", {
            txn: found.txn,
            hn: found.hn,
            status: "exit"
          });
        }

        if(memberData.txn != null) {
          var result = await Api.consult("MemberEntryRoomLog", {
            txn: found.txn,
            hn: found.hn,
            status: "exit"
          });

          console.log(result);
        }
  
        setMemberCallList((d) => (memberCallList));

      } else {
        setReconnectTxn(null);
      }
    }
  }

  function recalculateRoom() {
    var found = memberCallList.find(m => m.actionStatus == "active");

    if(typeof(found) == "undefined") {
      var onlines = memberCallList.find(m => m.onlineStatus == "online");

      if(typeof(onlines) != "undefined") {
        onlines.actionStatus = "active";
      }

      setMemberCallList((d) => (memberCallList));
    }
    
  }

  async function listGet() {
    var roomCode = query.get('roomCode');
    setRoomName(roomCode);

    var room = {
      actor : "controller",
      roomCode : roomCode
    }

    setRoomData(room);
    doConnectToWSServer(room);

    setLoadingApi(false);
  }

  function doConnectToWSServer(roomData) {
    sendJsonMessage({
      type: 'WS_INIT_DATA',
      content: roomData
    });
  }

  function doConnectClientVideocall(p_txn) {
    setTimeout(function() {
      connectClientVideoCall(p_txn)
    }, 3000); 
  }

  function connectClientVideoCall(p_txn) {
    var roomData = {
      txn : p_txn,
      roomCode : roomName
    }

    //console.log("call WS_CONNECT_CLIENT_VIDEOCALL " + new Date());

    sendJsonMessage({
      type: 'WS_CONNECT_CLIENT_VIDEOCALL',
      content: roomData
    });
  }

  function reconnectClient(p_txn) {
    var roomData = {
      txn : p_txn,
      roomCode : roomName
    }

    setReconnectTxn(p_txn);

    sendJsonMessage({
      type: 'WS_RECONNECT_CLIENT',
      content: roomData
    });
  }

  function startCall() {
    const d = document.getElementById("1234");
    d.setAttribute("allow", "camera; microphone; fullscreen; display-capture; autoplay");
  }

  function stopCall() {
    const d = document.getElementById("1234");
    d.setAttribute("allow", "fullscreen; display-capture; autoplay");
  }

  function createMemberData(dataJson) {
    var data = {
      actorId : dataJson.actorId,
      txn : dataJson.txn,
      hn : dataJson.hn,
      patientName : dataJson.patientName,
      onlineStatus : dataJson.onlineStatus,
      entryTime : dataJson.entryTime,
      actionStatus : dataJson.actionStatus,
      statusName : "เข้าใช้งาน"
    }

    return data;
  }

  function onClickMemberCall(data) {
    if((data.onlineStatus == null) || (data.onlineStatus != "offline")) {
      var callList = memberCallList.map((m) => {
        if(m.onlineStatus != "offline") {
          m.onlineStatus = "online";
          m.actionStatus = "";
        }
  
        return m;
      });

      if(data.onlineStatus != null) {
        var found = memberCallList.find(m => m.actorId == data.actorId);
        found.actionStatus = "active";
        found.onlineStatus = "online";
      }

      setMemberCallList((d) => (callList));

      if(data.onlineStatus != null) {
        setMemberClicked(data);
        reconnectClient(data.txn);
      }
      
      if(data.onlineStatus == null) {
        setMemberClicked(null);
      }

    } else {
      alertOffline();
    }

    
  }

  function doFilter() {
    const element = document.getElementById('txtSearch');
    
    const searchText = element.value;

    var dataFiltered = [];
    var dataFilteredHn = [];
    var dataFilteredName = [];

    if(searchText != "") {
      dataFilteredHn = memberCallList.filter(data=> data.hn.indexOf(searchText) !== -1 )
      dataFilteredName = memberCallList.filter(data=> data.patientName.indexOf(searchText) !== -1 )
      dataFiltered = [...dataFilteredHn, ...dataFilteredName];

      setMemberCallListDisplay(dataFiltered);

    } 
  }

  function doClearFilter() {
    document.getElementById('txtSearch').value = "";
    setMemberCallListDisplay(memberCallList);
    setSearching(false);
  }

  function removeMember() {
    var removed = memberCallList.filter(function(member) { 
      return member.actorId !== memberClicked.actorId
    })

    setMemberCallList(removed);

    UIkit.modal('#modal-member-disconnect').hide();
  }

  function doSignOut(txn) {
    onClickMemberCall({onlineStatus : null});
  }

  function alertOffline() {
    UIkit.modal('#modal-member-disconnect').show();
  }

  function openTeleLog() {
    setShowPopupDetail(true);
  }

  function closeTeleLog() {
    setShowPopupDetail(false);
  }

  useEffect(() => {
    listGet();
  }, [websocketConnected]);

  useEffect(() => {
    if(!searching) {
      setMemberCallListDisplay(memberCallList);
    }
  }, [memberCallList]);

  return (
    <div className="uk-padding-small">
      
    <div className="uk-card uk-card-default uk-padding-small">
      <div className="d-card-header"><span>Telemed : {roomName}</span> </div>
      <div className="uk-overflow-auto">
        {
        (memberCallListDisplay.length > 0) ?
          memberCallListDisplay.map((data, key)=>{
              var iframeItemSrc = "https://con.defence-innovation.com/r-" + data.txn;
              var display = false;

              if(data.actionStatus == "active") {
                display = true;
              }

              //console.log("connect client " + data.txn + " " + new Date() + " " + display);

              doConnectClientVideocall(data.txn);

              return (
                (display ?
                  <div  key={"k" + data.actorId}>
                <Iframe  id={data.actorId} allow="camera; microphone; fullscreen; display-capture; autoplay" 
                      src={iframeItemSrc}
                      width="100%"
                      height='600'
                      style="border: 0px;"></Iframe></div>
                      :
                      <></>
                )
              )
            }) : 
            <div uk-grid="" className="uk-flex-middle">
              <div className="uk-width-1-1@m uk-width-1-1 MessageCenter">
                  รอผู้ป่วยเข้าห้อง
              </div>
            </div>
          }
        </div>
        <div uk-grid="" className="uk-grid-small uk-padding-small">
        <div>ค้นหา : <input type="textbox" id="txtSearch" /> <button className="uk-button uk-button-primary" onClick={doFilter}>ค้นหา</button> <button className="uk-button uk-button-primary" onClick={doClearFilter}>แสดงทั้งหมด</button> <button className="uk-button uk-button-primary"  onClick={openTeleLog}>ตารางการ เข้า ออก</button> <span></span></div>
          
        </div>
        <div uk-grid="" className="uk-grid-small uk-padding-small">
        {
        (memberCallListDisplay.length > 0) ?
        memberCallListDisplay.map((data, key)=>{
              return (
                <MemberAppointmentCall  key={"member" + data.actorId} status={data.onlineStatus} actionStatus={data.actionStatus} callData={data} doSignOut={doSignOut} onClick={onClickMemberCall} />
              )
            }) : ""
          }
      </div>
    </div>
    <div id="modal-member-disconnect" className="uk-modal" >
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default uk-close" type="button"  ></button>
          <div className="uk-modal-body">
            ผู้ป่วยดำเนินการเสร็จแล้วใช่หรือไม่
          </div>
          <div className="uk-modal-footer uk-text-right">
              <button className="uk-button uk-button-primary" target='_blank' onClick={removeMember}>ใช่</button>
              &nbsp;&nbsp;
              <button className="uk-button uk-button-default uk-modal-close" type="button" >ไม่ใช่</button>
          </div>
      </div>
    </div>
    <TeleLogTable show={showPopupDetail} onClose={closeTeleLog} />
  </div>
  )
  /*
  return (
    <div className="uk-card uk-card-default uk-padding-small">
       <div className="d-card-header">คนไข้ - นายธนกิจ ศรีชัย</div>
       <div uk-grid="" className="uk-flex-middle">
         <div className="uk-width-1-1@m uk-width-1-1">
             ขณะนี้แพทย์กำลังให้คำปรึกษาคนไข้ท่านอื่นอยู่ค่ะ กรุณาปิดหน้าจอ และพยาบาลจะรีบติดต่อกลับไปค่ะ
         </div>
         
       </div>
     </div>
 )*/
}

export default AppointmentCallRoom;