import React, { Component } from "react";
import ImageUriReduce from './ImageUriReduce';
import moment from 'moment';
import UIkit from "uikit";

class UploadFirebase extends Component {
  state = {
    username: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: ""
  };


  render() {
    return <div className="uk-text-center">
    </div>
  }
}

export default UploadFirebase;