import React, { useState, useEffect, useRef, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import Api from "../../Api";
import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import FormElementTagInput from "../Form/FormElementTagInput";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormCathLabAssessmentPaper from "../FormPaper/FormCathLabAssessmentPaper.js";
import fetchHistoryWardByHn from "../../API/fetchHistoryWardByHn.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilGetValueChanged = require("../Common/FormUtil.js").getValueChanged;
var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;
var utilConvertDisplayDate = require("../Common/FormUtil.js").convertDisplayDate;

const FormCathLabAssessment = (props) => {
  const formConstants = {
    formName: "form_cathlabassessment",
    formType: "11",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [historyWardList, setHistoryWardList] = useState([]);
  // var [historyWard, setHistoryWard] = useState({});

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const prepareData = useCallback((form, profile) => {
    return form;
  }, []);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const historyWardDataRequest = fetchHistoryWardByHn(hn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all(
      [patientDataRequest, historyWardDataRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const historyWardDataResponse = values[1];
      const patientRoomResponse = values[2];

      data = prepareData({ ...data }, profile)
      setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
      setHistoryWardList(historyWardDataResponse);
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType)
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null)
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    if (!dataForm.data_4) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ward");
      return false;
    }
    if (!dataForm.data_5) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Date");
      return false;
    }
    if (!dataForm.data_6) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Cath no");
      return false;
    }
    if (!dataForm.data_7) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Procedure perform");
      return false;
    }
    if (!dataForm.data_8) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Diagnosis");
      return false;
    }
    if (!dataForm.data_9) {
      displayErrorValidatePopup("จำเป็นต้องระบุ น้ำหนัก");
      return false;
    }
    if (!dataForm.data_10) {
      displayErrorValidatePopup("จำเป็นต้องระบุ สูง");
      return false;
    }
    if (!dataForm.data_11) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Consent");
      return false;
    }
    if (dataForm.data_13 == "0") {
      displayErrorValidatePopup("จำเป็นต้องระบุ สิทธิบัตร");
      return false;
    }
    if (!dataForm.data_15) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Body Temp");
      return false;
    }
    if (!dataForm.data_16) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Hb");
      return false;
    }
    if (!dataForm.data_17) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BUN");
      return false;
    }
    if (!dataForm.data_18) {
      displayErrorValidatePopup("จำเป็นต้องระบุ PT");
      return false;
    }
    if (!dataForm.data_19) {
      displayErrorValidatePopup("จำเป็นต้องระบุ PTT");
      return false;
    }
    if (!dataForm.data_20) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ASA");
      return false;
    }
    if (!dataForm.data_21) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Hct");
      return false;
    }
    if (!dataForm.data_22) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Cr");
      return false;
    }
    if (!dataForm.data_23) {
      displayErrorValidatePopup("จำเป็นต้องระบุ INR");
      return false;
    }
    if (!dataForm.data_24) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Clopidogrel");
      return false;
    }
    if (!dataForm.data_25) {
      displayErrorValidatePopup("จำเป็นต้องระบุ WBC");
      return false;
    }
    if (!dataForm.data_26) {
      displayErrorValidatePopup("จำเป็นต้องระบุ eGFR");
      return false;
    }
    if (!dataForm.data_27) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HIV");
      return false;
    }
    if (!dataForm.data_115) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Ticagrelor");
      return false;
    }
    if (!dataForm.data_30) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Plt");
      return false;
    }
    if (!dataForm.data_31) {
      displayErrorValidatePopup("จำเป็นต้องระบุ K");
      return false;
    }
    if (!dataForm.data_108) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ATK");
      return false;
    }
    if (!dataForm.data_116) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Prasugrel");
      return false;
    }
    if (!dataForm.data_35?.length) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Other Lab");
      return false;
    }
    if (!dataForm.data_117) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Enoxaparin");
      return false;
    }
    if (!dataForm.data_118) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Wafarin");
      return false;
    }
    if (!dataForm.data_119) {
      displayErrorValidatePopup("จำเป็นต้องระบุ NOAC");
      return false;
    }
    if (!dataForm.data_33 || !dataForm.data_34) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Last dose at");
      return false;
    }
    if (!dataForm.data_36 || !dataForm.data_37) {
      displayErrorValidatePopup("จำเป็นต้องระบุ No of dose");
      return false;
    }
    if (!dataForm.data_38) {
      displayErrorValidatePopup("จำเป็นต้องระบุ NPO");
      return false;
    }
    if (!dataForm.data_42) {
      displayErrorValidatePopup("จำเป็นต้องระบุ PVD");
      return false;
    }
    if (!dataForm.data_43) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Allergic history");
      return false;
    }
    if (!dataForm.data_45) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HT");
      return false;
    }
    if (!dataForm.data_50) {
      displayErrorValidatePopup("จำเป็นต้องระบุ DM");
      return false;
    }
    if (!dataForm.data_51) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Bleeding risk score (BRC)");
      return false;
    }
    if (!dataForm.data_52) {
      displayErrorValidatePopup("จำเป็นต้องระบุ DLP");
      return false;
    }
    if (!dataForm.data_53) {
      displayErrorValidatePopup("จำเป็นต้องระบุ GRACE score");
      return false;
    }
    if (!dataForm.data_54) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Family history");
      return false;
    }
    if (!dataForm.data_55) {
      displayErrorValidatePopup("จำเป็นต้องระบุ One day");
      return false;
    }
    if (!dataForm.data_57) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Smoking");
      return false;
    }
    if (!dataForm.data_59) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Telephone number");
      return false;
    }
    if (!dataForm.data_60) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินผลของปัญหาวิตกกังวลเกียวกับการรักษา"
      );
      return false;
    }
    if (!dataForm.data_61) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินผลของปัญหาพร่องความรู้เกี่ยวกับการตรวจรักษา"
      );
      return false;
    }
    if (!dataForm.data_62) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อภาวะ vasovagal reflex"
      );
      return false;
    }
    if (!dataForm.data_63) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อภาวะเลือดอุดตันจากก้อนเลือดหรือฟองอากาศ"
      );
      return false;
    }
    if (!dataForm.data_64) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อภาวะ vascular complications"
      );
      return false;
    }
    if (!dataForm.data_65) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่ออันตรายจากการได้รับสารทึบรังสีเช่นแพ้สารทึบรังสี"
      );
      return false;
    }
    if (!dataForm.data_66) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินผลของปัญหาอาจเกิด/เสี่ยงต่อการติดเชื้อบริเวณที่ใส่สายสวนหรือในกระแสโลหิต"
      );
      return false;
    }
    if (!dataForm.data_67) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Anxiety of Pre procedure");
      return false;
    }
    if (!dataForm.data_68) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Pain of Pre procedure");
      return false;
    }
    if (!dataForm.data_70) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Anxiety of During procedure");
      return false;
    }
    if (!dataForm.data_71) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Pain of During procedure");
      return false;
    }
    if (!dataForm.data_73) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Anxiety of Post procedure");
      return false;
    }
    if (!dataForm.data_74) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Pain of Post procedure");
      return false;
    }
    if (!dataForm.data_76) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ชื่อ-นามสกุล");
      return false;
    }
    if (!dataForm.data_77) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HN");
      return false;
    }
    if (!dataForm.data_78) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Date");
      return false;
    }

    if (dataForm?.data_79?.data_1 == "1") {
      if (!dataForm?.data_79?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
        );
        return false;
      }
      if (!dataForm?.data_79?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
        );
        return false;
      }
      if (!dataForm?.data_79?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
        );
        return false;
      }
      if (!dataForm?.data_79?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
        );
        return false;
      }
      if (!dataForm?.data_79?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
        );
        return false;
      }
      if (!dataForm?.data_79?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม"
        );
        return false;
      }
    }

    if (dataForm?.data_80?.data_1 == "1") {
      if (!dataForm?.data_80?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_80?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_80?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_80?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_80?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_80?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของอธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย"
        );
        return false;
      }
    }

    if (dataForm?.data_81?.data_1 == "1") {
      if (!dataForm?.data_81?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
        );
        return false;
      }
      if (!dataForm?.data_81?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
        );
        return false;
      }
      if (!dataForm?.data_81?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
        );
        return false;
      }
      if (!dataForm?.data_81?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
        );
        return false;
      }
      if (!dataForm?.data_81?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
        );
        return false;
      }
      if (!dataForm?.data_81?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ"
        );
        return false;
      }
    }

    if (dataForm?.data_83?.data_1 == "1") {
      if (!dataForm?.data_83?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
        );
        return false;
      }
      if (!dataForm?.data_83?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
        );
        return false;
      }
      if (!dataForm?.data_83?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
        );
        return false;
      }
      if (!dataForm?.data_83?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
        );
        return false;
      }
      if (!dataForm?.data_83?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
        );
        return false;
      }
      if (!dataForm?.data_83?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของสังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ"
        );
        return false;
      }
    }

    if (dataForm?.data_84?.data_1 == "1") {
      if (!dataForm?.data_84?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
        );
        return false;
      }
      if (!dataForm?.data_84?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
        );
        return false;
      }
      if (!dataForm?.data_84?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
        );
        return false;
      }
      if (!dataForm?.data_84?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
        );
        return false;
      }
      if (!dataForm?.data_84?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
        );
        return false;
      }
      if (!dataForm?.data_84?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม"
        );
        return false;
      }
    }

    if (dataForm?.data_85?.data_1 == "1") {
      if (!dataForm?.data_85?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
        );
        return false;
      }
      if (!dataForm?.data_85?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
        );
        return false;
      }
      if (!dataForm?.data_85?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
        );
        return false;
      }
      if (!dataForm?.data_85?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
        );
        return false;
      }
      if (!dataForm?.data_85?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
        );
        return false;
      }
      if (!dataForm?.data_85?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของเตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้"
        );
        return false;
      }
    }

    if (dataForm?.data_86?.data_1 == "1") {
      if (!dataForm?.data_86?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
        );
        return false;
      }
      if (!dataForm?.data_86?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
        );
        return false;
      }
      if (!dataForm?.data_86?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
        );
        return false;
      }
      if (!dataForm?.data_86?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
        );
        return false;
      }
      if (!dataForm?.data_86?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
        );
        return false;
      }
      if (!dataForm?.data_86?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง"
        );
        return false;
      }
    }

    if (dataForm?.data_87?.data_1 == "1") {
      if (!dataForm?.data_87?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
        );
        return false;
      }
      if (!dataForm?.data_87?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
        );
        return false;
      }
      if (!dataForm?.data_87?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
        );
        return false;
      }
      if (!dataForm?.data_87?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
        );
        return false;
      }
      if (!dataForm?.data_87?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
        );
        return false;
      }
      if (!dataForm?.data_87?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของสวนล้างสายสวนทุกชนิดด้วย NSS + heparin"
        );
        return false;
      }
    }

    if (dataForm?.data_88?.data_1 == "1") {
      if (!dataForm?.data_88?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
        );
        return false;
      }
      if (!dataForm?.data_88?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
        );
        return false;
      }
      if (!dataForm?.data_88?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
        );
        return false;
      }
      if (!dataForm?.data_88?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
        );
        return false;
      }
      if (!dataForm?.data_88?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
        );
        return false;
      }
      if (!dataForm?.data_88?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของทำความสะอาดลวดนำก่อนใช้ทุกครั้ง"
        );
        return false;
      }
    }

    if (dataForm?.data_89?.data_1 == "1") {
      if (!dataForm?.data_89?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_89?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_89?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_89?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_89?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
        );
        return false;
      }
      if (!dataForm?.data_89?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย"
        );
        return false;
      }
    }

    if (dataForm?.data_90?.data_1 == "1") {
      if (!dataForm?.data_90?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
        );
        return false;
      }
      if (!dataForm?.data_90?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
        );
        return false;
      }
      if (!dataForm?.data_90?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
        );
        return false;
      }
      if (!dataForm?.data_90?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
        );
        return false;
      }
      if (!dataForm?.data_90?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
        );
        return false;
      }
      if (!dataForm?.data_90?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของกดแผลห้ามเลือดจนเลือดหยุด pressure dressing / หมอนทรายทับแผล"
        );
        return false;
      }
    }

    if (dataForm?.data_91?.data_1 == "1") {
      if (!dataForm?.data_91?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
        );
        return false;
      }
      if (!dataForm?.data_91?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
        );
        return false;
      }
      if (!dataForm?.data_91?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
        );
        return false;
      }
      if (!dataForm?.data_91?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
        );
        return false;
      }
      if (!dataForm?.data_91?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
        );
        return false;
      }
      if (!dataForm?.data_91?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของสังเกตภาวะเลือดออกปวดบวมบริเวณแผล"
        );
        return false;
      }
    }

    if (dataForm?.data_92?.data_1 == "1") {
      if (!dataForm?.data_92?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
        );
        return false;
      }
      if (!dataForm?.data_92?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
        );
        return false;
      }
      if (!dataForm?.data_92?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
        );
        return false;
      }
      if (!dataForm?.data_92?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
        );
        return false;
      }
      if (!dataForm?.data_92?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
        );
        return false;
      }
      if (!dataForm?.data_92?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout"
        );
        return false;
      }
    }

    if (dataForm?.data_93?.data_1 == "1") {
      if (!dataForm?.data_93?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ I = hand hygiene / keep sterile"
        );
        return false;
      }
      if (!dataForm?.data_93?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ I = hand hygiene / keep sterile"
        );
        return false;
      }
      if (!dataForm?.data_93?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ I = hand hygiene / keep sterile"
        );
        return false;
      }
      if (!dataForm?.data_93?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ I = hand hygiene / keep sterile"
        );
        return false;
      }
      if (!dataForm?.data_93?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ I = hand hygiene / keep sterile"
        );
        return false;
      }
      if (!dataForm?.data_93?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ I = hand hygiene / keep sterile"
        );
        return false;
      }
    }

    if (dataForm?.data_94?.data_1 == "1") {
      if (!dataForm?.data_94?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
        );
        return false;
      }
      if (!dataForm?.data_94?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
        );
        return false;
      }
      if (!dataForm?.data_94?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
        );
        return false;
      }
      if (!dataForm?.data_94?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
        );
        return false;
      }
      if (!dataForm?.data_94?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
        );
        return false;
      }
      if (!dataForm?.data_94?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน"
        );
        return false;
      }
    }

    if (dataForm?.data_95?.data_1 == "1") {
      if (!dataForm?.data_95?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ I = ตรวจสอบ IV type, rate, IV site"
        );
        return false;
      }
      if (!dataForm?.data_95?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ I = ตรวจสอบ IV type, rate, IV site"
        );
        return false;
      }
      if (!dataForm?.data_95?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ I = ตรวจสอบ IV type, rate, IV site"
        );
        return false;
      }
      if (!dataForm?.data_95?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ I = ตรวจสอบ IV type, rate, IV site"
        );
        return false;
      }
      if (!dataForm?.data_95?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ I = ตรวจสอบ IV type, rate, IV site"
        );
        return false;
      }
      if (!dataForm?.data_95?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ I = ตรวจสอบ IV type, rate, IV site"
        );
        return false;
      }
    }

    if (dataForm?.data_96?.data_1 == "1") {
      if (!dataForm?.data_96?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
        );
        return false;
      }
      if (!dataForm?.data_96?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
        );
        return false;
      }
      if (!dataForm?.data_96?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
        );
        return false;
      }
      if (!dataForm?.data_96?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
        );
        return false;
      }
      if (!dataForm?.data_96?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
        );
        return false;
      }
      if (!dataForm?.data_96?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ"
        );
        return false;
      }
    }

    if (dataForm?.data_97?.data_1 == "1") {
      if (!dataForm?.data_97?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
        );
        return false;
      }
      if (!dataForm?.data_97?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
        );
        return false;
      }
      if (!dataForm?.data_97?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
        );
        return false;
      }
      if (!dataForm?.data_97?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
        );
        return false;
      }
      if (!dataForm?.data_97?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
        );
        return false;
      }
      if (!dataForm?.data_97?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ D = ปรับระดับเตียงให้สะดวกขึ้นลง"
        );
        return false;
      }
    }

    if (dataForm?.data_98?.data_1 == "1") {
      if (!dataForm?.data_98?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
        );
        return false;
      }
      if (!dataForm?.data_98?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
        );
        return false;
      }
      if (!dataForm?.data_98?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
        );
        return false;
      }
      if (!dataForm?.data_98?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
        );
        return false;
      }
      if (!dataForm?.data_98?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
        );
        return false;
      }
      if (!dataForm?.data_98?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา"
        );
        return false;
      }
    }

    if (dataForm?.data_99?.data_1 == "1") {
      if (!dataForm?.data_99?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
        );
        return false;
      }
      if (!dataForm?.data_99?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
        );
        return false;
      }
      if (!dataForm?.data_99?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
        );
        return false;
      }
      if (!dataForm?.data_99?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
        );
        return false;
      }
      if (!dataForm?.data_99?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
        );
        return false;
      }
      if (!dataForm?.data_99?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน"
        );
        return false;
      }
    }

    if (dataForm?.data_100?.data_1 == "1") {
      if (!dataForm?.data_100?.data_2) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ Time ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
        );
        return false;
      }
      if (!dataForm?.data_100?.data_3) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ PR ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
        );
        return false;
      }
      if (!dataForm?.data_100?.data_4) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ SpO2RR ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
        );
        return false;
      }
      if (!dataForm?.data_100?.data_5) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ IBP ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
        );
        return false;
      }
      if (!dataForm?.data_100?.data_6) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ BP ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
        );
        return false;
      }
      if (!dataForm?.data_100?.data_7) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ อาการ/การพยาบาล/การประเมิน ของ N = สอบถามและตอบสนองความต้องการความช่วยเหลือ"
        );
        return false;
      }
    }

    if (!dataForm.data_101 || !dataForm.data_102) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Contrast Type");
      return false;
    }
    if (!dataForm.data_103) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Flu time");
      return false;
    }
    if (!dataForm.data_104) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Exposure dose");
      return false;
    }
    if (!dataForm.data_105) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Area dose");
      return false;
    }
    return true;
  }, [dataForm, displayErrorValidatePopup])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer.current]);

  const onTagInputChange = useCallback((fieldId, data) => {
    var valueData = [];
    if (data) {
      valueData = data.map((value, index) => ({
        key: index,
        value: value,
      }));
    }
    setDataForm((data) => ({ ...data, [fieldId]: valueData }));
  }, [setDataForm]);

  const handleJsonValueChange = useCallback((i, key, event) => {
    const value = utilGetValueChanged(event);
    const json = { ...dataForm[i], [key]: value };
    setDataForm((data) => ({ ...data, [i]: json }));
  }, [setDataForm, dataForm]);

  return (
    <div ref={parentTableContainer} style={{ overflowX: "hidden" }}>
      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="Catheterization Laboratory Assessment" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="Cardiac Center,Maharaj Nakorn Chiang Mai Hospital tel: 36711,36712" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTextBox
            fieldId={"4"}
            value={dataForm.data_4}
            label="ward"
            onChange={handleChange}
            width="100"
            isRequired
          />
          {/* <FormElementTextBox
            fieldId={"5"}
            value={dataForm.data_5}
            type="date"
            label="Date"
            onChange={handleChange}
            width="100"
            isRequired
          /> */}
          <FormElementDropdown
            fieldId={"5"}
            value={dataForm.data_3}
            label="Date"
            dataList={[{ value: "", label: "เลือกวันที่" }].concat(
              historyWardList.map((item) => ({
                value: item.adm,
                label: utilConvertDisplayDate(item.adm),
              }))
            )}
            onChange={handleChange}
            width="100"
            isRequired
          />
          <FormElementTextBox
            fieldId={"6"}
            value={dataForm.data_6}
            label="Cath no"
            onChange={handleChange}
            width="100"
            isRequired
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              rowGap: "14px",
              columnGap: "10px",
            }}
          >
            <FormElementTextBox
              fieldId={"7"}
              value={dataForm.data_7}
              label="Procedure perform"
              onChange={handleChange}
              width="100"
              isRequired
            />
            <FormElementTextBox
              fieldId={"8"}
              value={dataForm.data_8}
              label="Diagnosis"
              onChange={handleChange}
              width="100"
              isRequired
            />
            <div className="uk-display-inline">
              <FormElementTextBox
                fieldId={"9"}
                value={dataForm.data_9}
                type="decimal"
                label="น้ำหนัก"
                onChange={handleChange}
                width="100"
                isRequired
              />
              <span className="formTextBoxLabel">กก.</span>
            </div>
            <div className="uk-display-inline">
              <FormElementTextBox
                fieldId={"10"}
                value={dataForm.data_10}
                type="decimal"
                label="สูง"
                onChange={handleChange}
                width="100"
                isRequired
              />
              <span className="formTextBoxLabel">ซม.</span>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <span className="formTextBoxLabel">
            Consent <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"1"}
            onChange={handleChange}
            label="Yes"
          />
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"2"}
            onChange={handleChange}
            label="No"
          />
          <span
            className={dataForm.data_11 == "2" ? "form-show" : "form-hidden"}
          >
            <FormElementTextBox
              fieldId={"12"}
              value={dataForm.data_12}
              label=""
              onChange={handleChange}
              width="100"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <span className="formTextBoxLabel">
            สิทธิบัตร <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementDropdown
            fieldId={"13"}
            value={dataForm.data_13}
            dataList={[
              { value: "0", label: "เลือกสิทธิบัตร" },
              { value: "1", label: "เบิกได้" },
              { value: "2", label: "บัตรทอง" },
              { value: "3", label: "ปกส." },
              { value: "4", label: "จ่ายเอง" },
              { value: "5", label: "อื่นๆ" },
            ]}
            onChange={handleChange}
            width="100"
          />
          <span
            className={dataForm.data_13 == "5" ? "form-show" : "form-hidden"}
          >
            &nbsp;&nbsp;&nbsp;
            <FormElementTextBox
              fieldId={"14"}
              value={dataForm.data_14}
              label=""
              onChange={handleChange}
              width="100"
              placeholder="ระบุ"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div
            className="form-table-container"
            style={{ maxWidth: `${tableMaxWidth}px` }}
          >
            <div className="form-table-scroll">
              <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                <tbody>
                  <tr>
                    <th colSpan={4}>
                      <FormElementTextBox
                        fieldId={"15"}
                        value={dataForm.data_15}
                        label="Body Temp= "
                        type="decimal"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                      <span className="formTextBoxLabel">c</span>
                    </th>
                    <th rowSpan={10} className="center">
                      Medication check list
                    </th>
                    <th></th>
                    <th>Yes</th>
                    <th>No</th>
                  </tr>
                  <tr>
                    <th rowSpan={9} className="center">
                      Lab Check list
                    </th>
                    <td>
                      <FormElementTextBox
                        fieldId={"16"}
                        value={dataForm.data_16}
                        label="Hb"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"17"}
                        value={dataForm.data_17}
                        label="BUN"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"18"}
                        value={dataForm.data_18}
                        label="PT"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                      <FormElementTextBox
                        fieldId={"19"}
                        value={dataForm.data_19}
                        label="PTT"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      ASA <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"20"}
                        value={dataForm.data_20}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"20"}
                        value={dataForm.data_20}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementTextBox
                        fieldId={"21"}
                        value={dataForm.data_21}
                        label="Hct"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"22"}
                        value={dataForm.data_22}
                        label="Cr"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"23"}
                        value={dataForm.data_23}
                        label="INR"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      Clopidogrel <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"24"}
                        value={dataForm.data_24}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"24"}
                        value={dataForm.data_24}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementTextBox
                        fieldId={"25"}
                        value={dataForm.data_25}
                        label="WBC"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"26"}
                        value={dataForm.data_26}
                        label="eGFR"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="formTextBoxLabel">
                          HIV <span style={{ color: "red" }}>*</span>
                        </span>
                        <FormElementRadio
                          fieldId={"27"}
                          value={dataForm.data_27}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label="neg"
                        />
                        <FormElementRadio
                          fieldId={"27"}
                          value={dataForm.data_27}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label="pos"
                        />
                        <FormElementRadio
                          fieldId={"27"}
                          value={dataForm.data_27}
                          fieldValue={"3"}
                          onChange={handleChange}
                          label="NA"
                        />
                      </div>
                    </td>
                    <td>
                      Ticagrelor <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"115"}
                        value={dataForm.data_115}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"115"}
                        value={dataForm.data_115}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementTextBox
                        fieldId={"30"}
                        value={dataForm.data_30}
                        label="Plt"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"31"}
                        value={dataForm.data_31}
                        label="K"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <div className="dietz-input-container">
                        <span
                          className="formTextBoxLabel"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          ATK <span style={{ color: "red" }}>*</span> :
                        </span>
                        <FormElementRadio
                          fieldId={"108"}
                          value={dataForm.data_108}
                          label="No"
                          fieldValue="0"
                          onChange={handleChange}
                          width="100"
                        />
                        <FormElementRadio
                          fieldId={"108"}
                          value={dataForm.data_108}
                          label="Yes"
                          fieldValue="1"
                          onChange={handleChange}
                          width="100"
                        />
                        <span hidden={dataForm.data_108 != "1"}>
                          <FormElementRadio
                            fieldId={"107"}
                            value={dataForm.data_107}
                            label="Negative"
                            fieldValue="0"
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                        <span hidden={dataForm.data_999 != "1"}>
                          <FormElementRadio
                            fieldId={"107"}
                            value={dataForm.data_107}
                            label="Positive"
                            fieldValue="1"
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      Prasugrel <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"116"}
                        value={dataForm.data_116}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"116"}
                        value={dataForm.data_116}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} rowSpan={5}>
                      <span className="formTextBoxLabel">
                        Other Lab: <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementTagInput
                        value={
                          dataForm.data_35
                            ? dataForm.data_35.map((item) => item.value)
                            : []
                        }
                        onChange={(data) => onTagInputChange("data_35", data)}
                        dataList={["CXR", "EKG"]}
                        placeholder="ระบุ"
                      />
                    </td>
                    <td>
                      Enoxaparin <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"117"}
                        value={dataForm.data_117}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"117"}
                        value={dataForm.data_117}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Wafarin <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"118"}
                        value={dataForm.data_118}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"118"}
                        value={dataForm.data_118}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      NOAC <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"119"}
                        value={dataForm.data_119}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"119"}
                        value={dataForm.data_119}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Last dose at <span style={{ color: "red" }}>*</span>
                      &nbsp;&nbsp;{" "}
                      <FormElementTextBox
                        fieldId={"33"}
                        value={dataForm.data_33}
                        onChange={handleChange}
                        width="100"
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"34"}
                        value={dataForm.data_34}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"34"}
                        value={dataForm.data_34}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      No of dose <span style={{ color: "red" }}>*</span>
                      &nbsp;&nbsp;{" "}
                      <FormElementTextBox
                        fieldId={"36"}
                        value={dataForm.data_36}
                        onChange={handleChange}
                        width="100"
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"37"}
                        value={dataForm.data_37}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"37"}
                        value={dataForm.data_37}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan={4} className="center">
                      Pre-Cath assessment
                    </th>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        NPO <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"38"}
                        value={dataForm.data_38}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Yes,"
                      />
                      <span className="formTextBoxLabel">time </span>
                      <FormElementTextBox
                        fieldId={"39"}
                        value={dataForm.data_39}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementRadio
                        fieldId={"38"}
                        value={dataForm.data_38}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="No"
                      />
                    </td>
                    <td>
                      <span className="formTextBoxLabel">Clean&shave</span>
                      <FormElementCheckBox
                        fieldId={"109"}
                        value={dataForm.data_109}
                        onChange={handleChange}
                        label="Yes"
                      />
                    </td>
                    <th rowSpan={5} className="center">
                      Vascular Complications Risk
                    </th>
                    <td colSpan={3}></td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <span className="formTextBoxLabel">IV, Plug</span>
                      <FormElementCheckBox
                        fieldId={"40"}
                        value={dataForm.data_40}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementTextBox
                        fieldId={"41"}
                        value={dataForm.data_41}
                        label="note"
                        onChange={handleChange}
                        width="100"
                      />
                    </td>
                    <td colSpan={3}>
                      <span className="formTextBoxLabel">
                        PVD <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"42"}
                        value={dataForm.data_42}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"42"}
                        value={dataForm.data_42}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"42"}
                        value={dataForm.data_42}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <span className="formTextBoxLabel">
                        Allergic history <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"43"}
                        value={dataForm.data_43}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"43"}
                        value={dataForm.data_43}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <span hidden={dataForm.data_43 != "1"}>
                        <FormElementTextBox
                          fieldId={"44"}
                          value={dataForm.data_44}
                          label="note"
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        HT <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"45"}
                        value={dataForm.data_45}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"45"}
                        value={dataForm.data_45}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"45"}
                        value={dataForm.data_45}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                    <th rowSpan={5} className="center">
                      CAD risk
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <FormElementRadio
                            fieldId={"47"}
                            value={dataForm.data_47}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label="Dorsalis pedis"
                          />
                          <span hidden={dataForm.data_47 != "1"}>
                            <FormElementRadio
                              fieldId={"111"}
                              value={dataForm.data_111}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label="Left"
                            />
                            <FormElementTextBox
                              fieldId={"48"}
                              value={dataForm.data_48}
                              onChange={handleChange}
                              width="100"
                            />
                            <FormElementRadio
                              fieldId={"111"}
                              value={dataForm.data_111}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label="Right"
                            />
                            <FormElementTextBox
                              fieldId={"49"}
                              value={dataForm.data_49}
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <FormElementRadio
                            fieldId={"47"}
                            value={dataForm.data_47}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label="Other"
                          />
                          <span hidden={dataForm.data_47 != "2"}>
                            <FormElementTextBox
                              fieldId={"112"}
                              value={dataForm.data_112}
                              onChange={handleChange}
                              width="100"
                            />

                            <FormElementRadio
                              fieldId={"113"}
                              value={dataForm.data_113}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label="Left"
                            />
                            <FormElementTextBox
                              fieldId={"114"}
                              value={dataForm.data_114}
                              onChange={handleChange}
                              width="100"
                            />
                            <FormElementRadio
                              fieldId={"113"}
                              value={dataForm.data_113}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label="Right"
                            />
                            <FormElementTextBox
                              fieldId={"120"}
                              value={dataForm.data_120}
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        DM <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"50"}
                        value={dataForm.data_50}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"50"}
                        value={dataForm.data_50}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"50"}
                        value={dataForm.data_50}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <span className="formTextBoxLabel">
                        Bleeding risk score (BRC){" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"51"}
                        value={dataForm.data_51}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"51"}
                        value={dataForm.data_51}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="No"
                      />
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        DLP <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"52"}
                        value={dataForm.data_52}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"52"}
                        value={dataForm.data_52}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"52"}
                        value={dataForm.data_52}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <FormElementTextBox
                        fieldId={"53"}
                        value={dataForm.data_53}
                        label="GRACE score ="
                        onChange={handleChange}
                        width="100"
                        type="numeric"
                        isRequired
                      />
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        Family history <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"54"}
                        value={dataForm.data_54}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"54"}
                        value={dataForm.data_54}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"54"}
                        value={dataForm.data_54}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <span className="formTextBoxLabel">
                        One day <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"55"}
                        value={dataForm.data_55}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"55"}
                        value={dataForm.data_55}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No,"
                      />
                      <FormElementTextBox
                        fieldId={"56"}
                        value={dataForm.data_56}
                        label="note"
                        onChange={handleChange}
                        width="100"
                        type="numeric"
                      />
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        Smoking <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"57"}
                        value={dataForm.data_57}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"57"}
                        value={dataForm.data_57}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Ex"
                      />
                      <FormElementRadio
                        fieldId={"57"}
                        value={dataForm.data_57}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <span hidden={dataForm.data_57 != "2"}>
                        <FormElementTextBox
                          fieldId={"58"}
                          value={dataForm.data_58}
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={8}>
                      <FormElementTextBox
                        fieldId={"59"}
                        value={dataForm.data_59}
                        label="Telephone number:"
                        type="numeric"
                        onChange={handleChange}
                        width="100"
                        maxLength={10}
                        isRequired
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <FormElementLine />
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementLabel label="แบบบันทึกทางการพยาบาล" fontWeight="bold" />
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <thead>
              <tr>
                <th>ปัญหา</th>
                <th>ผลที่คาดหวัง</th>
                <th>
                  ประเมินผล <span style={{ color: "red" }}>*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.วิตกกังวลเกียวกับการรักษา</td>
                <td>1.ความวิตกกังวลลดลง</td>
                <td>
                  <FormElementTextBox
                    fieldId={"60"}
                    value={dataForm.data_60}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>2.พร่องความรู้เกี่ยวกับการตรวจรักษา</td>
                <td>2.ให้ความร่วมมือปฏิบัติตัวถูกต้อง</td>
                <td>
                  <FormElementTextBox
                    fieldId={"61"}
                    value={dataForm.data_61}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>3.อาจเกิด/เสี่ยงต่อภาวะ vasovagal reflex</td>
                <td>3.ไม่มีภาวะ vasovagal reflex</td>
                <td>
                  <FormElementTextBox
                    fieldId={"62"}
                    value={dataForm.data_62}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  4.อาจเกิด/เสี่ยงต่อภาวะเลือดอุดตันจากก้อนเลือดหรือฟองอากาศ
                </td>
                <td>4.ระดับความรู้สึกตัวปกติ peripheral perfusion ปกติ</td>
                <td>
                  <FormElementTextBox
                    fieldId={"63"}
                    value={dataForm.data_63}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>5.อาจเกิด/เสี่ยงต่อภาวะ vascular complications</td>
                <td>5.แผลไม่มีเลือดออกไม่ปวดไม่บวม</td>
                <td>
                  <FormElementTextBox
                    fieldId={"64"}
                    value={dataForm.data_64}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  6.อาจเกิด/เสี่ยงต่ออันตรายจากการได้รับสารทึบรังสีเช่นแพ้สารทึบรังสี
                </td>
                <td>6.ไม่มีอาการแสดงของการแพ้สารทึบรังสี</td>
                <td>
                  <FormElementTextBox
                    fieldId={"65"}
                    value={dataForm.data_65}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  7.อาจเกิด/เสี่ยงต่อการติดเชื้อบริเวณที่ใส่สายสวนหรือในกระแสโลหิต
                </td>
                <td>7.ไม่มีอาการแสดงของการติดเชื้อ</td>
                <td>
                  <FormElementTextBox
                    fieldId={"66"}
                    value={dataForm.data_66}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <FormElementLine />
        <div className="uk-width-1-1@muk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <tbody>
              <tr>
                <th></th>
                <th>
                  Anxiety <span style={{ color: "red" }}>*</span>
                </th>
                <th>
                  pain <span style={{ color: "red" }}>*</span>
                </th>
                <th>Level rating scale</th>
                <th></th>
              </tr>
              <tr>
                <td>Pre procedure</td>
                <td>
                  <FormElementTextBox
                    fieldId={"67"}
                    value={dataForm.data_67}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"68"}
                    value={dataForm.data_68}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>mild</td>
                <td>
                  <FormElementTextBox
                    fieldId={"69"}
                    value={dataForm.data_69}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>During procedure</td>
                <td>
                  <FormElementTextBox
                    fieldId={"70"}
                    value={dataForm.data_70}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"71"}
                    value={dataForm.data_71}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>moderate</td>
                <td>
                  <FormElementTextBox
                    fieldId={"72"}
                    value={dataForm.data_72}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>Post procedure</td>
                <td>
                  <FormElementTextBox
                    fieldId={"73"}
                    value={dataForm.data_73}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"74"}
                    value={dataForm.data_74}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>severe</td>
                <td>
                  <FormElementTextBox
                    fieldId={"75"}
                    value={dataForm.data_75}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <FormElementLine />
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementTextBox
            fieldId={"76"}
            value={dataForm.data_76}
            label="ชื่อ-นามสกุล"
            onChange={handleChange}
            width="100"
            isRequired
          />
          <FormElementTextBox
            fieldId={"77"}
            value={dataForm.data_77}
            label="HN"
            onChange={handleChange}
            maxLength={7}
            type="numeric"
            width="100"
            isRequired
          />
          <FormElementTextBox
            fieldId={"78"}
            value={dataForm.data_78}
            label="Date"
            type="date"
            onChange={handleChange}
            width="100"
            isRequired
          />
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <div
            className="form-table-container"
            style={{ maxWidth: `${tableMaxWidth}px` }}
          >
            <div className="form-table-scroll">
              <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                <tbody>
                  <tr>
                    <th>แผนการพยาบาล</th>
                    <th>✓</th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Time <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      PR <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      SpO2RR <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      IBP <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      BP <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap", minWidth: "400px" }}>
                      อาการ/การพยาบาล/การประเมิน{" "}
                      <span style={{ color: "red" }}>*</span>
                    </th>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_79.data_1"}
                        value={dataForm?.data_79?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_79", "data_1", e)
                        }
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_2"}
                          value={dataForm?.data_79?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_3"}
                          value={dataForm?.data_79?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_4"}
                          value={dataForm?.data_79?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_5"}
                          value={dataForm?.data_79?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_6"}
                          value={dataForm?.data_79?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_79.data_7"}
                          value={dataForm?.data_79?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      อธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_80.data_1"}
                        value={dataForm?.data_80?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_80", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_2"}
                          value={dataForm?.data_80?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_3"}
                          value={dataForm?.data_80?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_4"}
                          value={dataForm?.data_80?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_5"}
                          value={dataForm?.data_80?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_6"}
                          value={dataForm?.data_80?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_80.data_7"}
                          value={dataForm?.data_80?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_81.data_1"}
                        value={dataForm?.data_81?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_81", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_2"}
                          value={dataForm?.data_81?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_3"}
                          value={dataForm?.data_81?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_4"}
                          value={dataForm?.data_81?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_5"}
                          value={dataForm?.data_81?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_6"}
                          value={dataForm?.data_81?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_81.data_7"}
                          value={dataForm?.data_81?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      สังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_82.data_1"}
                        value={dataForm?.data_82?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_82", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_2"}
                          value={dataForm?.data_82?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_3"}
                          value={dataForm?.data_82?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_4"}
                          value={dataForm?.data_82?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_5"}
                          value={dataForm?.data_82?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_6"}
                          value={dataForm?.data_82?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_82.data_7"}
                          value={dataForm?.data_82?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_83.data_1"}
                        value={dataForm?.data_83?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_83", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_2"}
                          value={dataForm?.data_83?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_3"}
                          value={dataForm?.data_83?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_4"}
                          value={dataForm?.data_83?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_5"}
                          value={dataForm?.data_83?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_6"}
                          value={dataForm?.data_83?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_83.data_7"}
                          value={dataForm?.data_83?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      เตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_84.data_1"}
                        value={dataForm?.data_84?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_84", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_2"}
                          value={dataForm?.data_84?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_3"}
                          value={dataForm?.data_84?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_4"}
                          value={dataForm?.data_84?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_5"}
                          value={dataForm?.data_84?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_6"}
                          value={dataForm?.data_84?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_84.data_7"}
                          value={dataForm?.data_84?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_85.data_1"}
                        value={dataForm?.data_85?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_85", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_2"}
                          value={dataForm?.data_85?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_3"}
                          value={dataForm?.data_85?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_4"}
                          value={dataForm?.data_85?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_5"}
                          value={dataForm?.data_85?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_6"}
                          value={dataForm?.data_85?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_85.data_7"}
                          value={dataForm?.data_85?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      สวนล้างสายสวนทุกชนิดด้วย NSS + heparin
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_86.data_1"}
                        value={dataForm?.data_86?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_86", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_2"}
                          value={dataForm?.data_86?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_3"}
                          value={dataForm?.data_86?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_4"}
                          value={dataForm?.data_86?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_5"}
                          value={dataForm?.data_86?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_6"}
                          value={dataForm?.data_86?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_86.data_7"}
                          value={dataForm?.data_86?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ทำความสะอาดลวดนำก่อนใช้ทุกครั้ง
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_87.data_1"}
                        value={dataForm?.data_87?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_87", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_2"}
                          value={dataForm?.data_87?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_3"}
                          value={dataForm?.data_87?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_4"}
                          value={dataForm?.data_87?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_5"}
                          value={dataForm?.data_87?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_6"}
                          value={dataForm?.data_87?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_87.data_7"}
                          value={dataForm?.data_87?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_88.data_1"}
                        value={dataForm?.data_88?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_88", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_2"}
                          value={dataForm?.data_88?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_3"}
                          value={dataForm?.data_88?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_4"}
                          value={dataForm?.data_88?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_5"}
                          value={dataForm?.data_88?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_6"}
                          value={dataForm?.data_88?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_88.data_7"}
                          value={dataForm?.data_88?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      กดแผลห้ามเลือดจนเลือดหยุด pressure dressing /
                      หมอนทรายทับแผล
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_90.data_1"}
                        value={dataForm?.data_90?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_90", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_2"}
                          value={dataForm?.data_90?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_3"}
                          value={dataForm?.data_90?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_4"}
                          value={dataForm?.data_90?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_5"}
                          value={dataForm?.data_90?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_6"}
                          value={dataForm?.data_90?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_90.data_7"}
                          value={dataForm?.data_90?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      สังเกตภาวะเลือดออกปวดบวมบริเวณแผล
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_91.data_1"}
                        value={dataForm?.data_91?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_91", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_2"}
                          value={dataForm?.data_91?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_3"}
                          value={dataForm?.data_91?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_4"}
                          value={dataForm?.data_91?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_5"}
                          value={dataForm?.data_91?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_6"}
                          value={dataForm?.data_91?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_91.data_7"}
                          value={dataForm?.data_91?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_92.data_1"}
                        value={dataForm?.data_92?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_92", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_2"}
                          value={dataForm?.data_92?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_3"}
                          value={dataForm?.data_92?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_4"}
                          value={dataForm?.data_92?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_5"}
                          value={dataForm?.data_92?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_6"}
                          value={dataForm?.data_92?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_92.data_7"}
                          value={dataForm?.data_92?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      I = hand hygiene / keep sterile
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_93.data_1"}
                        value={dataForm?.data_93?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_93", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_2"}
                          value={dataForm?.data_93?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_3"}
                          value={dataForm?.data_93?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_4"}
                          value={dataForm?.data_93?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_5"}
                          value={dataForm?.data_93?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_6"}
                          value={dataForm?.data_93?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_93.data_7"}
                          value={dataForm?.data_93?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_94.data_1"}
                        value={dataForm?.data_94?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_94", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_2"}
                          value={dataForm?.data_94?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_3"}
                          value={dataForm?.data_94?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_4"}
                          value={dataForm?.data_94?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_5"}
                          value={dataForm?.data_94?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_6"}
                          value={dataForm?.data_94?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_94.data_7"}
                          value={dataForm?.data_94?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      I = ตรวจสอบ IV type, rate, IV site
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_95.data_1"}
                        value={dataForm?.data_95?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_95", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_2"}
                          value={dataForm?.data_95?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_3"}
                          value={dataForm?.data_95?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_4"}
                          value={dataForm?.data_95?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_5"}
                          value={dataForm?.data_95?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_6"}
                          value={dataForm?.data_95?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_95.data_7"}
                          value={dataForm?.data_95?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_96.data_1"}
                        value={dataForm?.data_96?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_96", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_2"}
                          value={dataForm?.data_96?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_3"}
                          value={dataForm?.data_96?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_4"}
                          value={dataForm?.data_96?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_5"}
                          value={dataForm?.data_96?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_6"}
                          value={dataForm?.data_96?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_96.data_7"}
                          value={dataForm?.data_96?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      D = ปรับระดับเตียงให้สะดวกขึ้นลง
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_97.data_1"}
                        value={dataForm?.data_97?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_97", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_2"}
                          value={dataForm?.data_97?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_3"}
                          value={dataForm?.data_97?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_4"}
                          value={dataForm?.data_97?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_5"}
                          value={dataForm?.data_97?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_6"}
                          value={dataForm?.data_97?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_97.data_7"}
                          value={dataForm?.data_97?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_98.data_1"}
                        value={dataForm?.data_98?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_98", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_2"}
                          value={dataForm?.data_98?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_3"}
                          value={dataForm?.data_98?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_4"}
                          value={dataForm?.data_98?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_5"}
                          value={dataForm?.data_98?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_6"}
                          value={dataForm?.data_98?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_98.data_7"}
                          value={dataForm?.data_98?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_99.data_1"}
                        value={dataForm?.data_99?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_99", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_2"}
                          value={dataForm?.data_99?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_3"}
                          value={dataForm?.data_99?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_4"}
                          value={dataForm?.data_99?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_5"}
                          value={dataForm?.data_99?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_6"}
                          value={dataForm?.data_99?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_99.data_7"}
                          value={dataForm?.data_99?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      N = สอบถามและตอบสนองความต้องการความช่วยเหลือ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_100.data_1"}
                        value={dataForm?.data_100?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_100", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_2"}
                          value={dataForm?.data_100?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_3"}
                          value={dataForm?.data_100?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_4"}
                          value={dataForm?.data_100?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_5"}
                          value={dataForm?.data_100?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_6"}
                          value={dataForm?.data_100?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_100.data_7"}
                          value={dataForm?.data_100?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementTextBox
            fieldId={"101"}
            value={dataForm.data_101}
            label="Contrast Type"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">/</span>
          <FormElementTextBox
            fieldId={"102"}
            value={dataForm.data_102}
            label=""
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">ml.</span>
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementTextBox
            fieldId={"103"}
            value={dataForm.data_103}
            label="Flu time"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">
            min / Exposure dose <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementTextBox
            fieldId={"104"}
            value={dataForm.data_104}
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">
            mGy. / Area dose <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementTextBox
            fieldId={"105"}
            value={dataForm.data_105}
            onChange={handleChange}
            width="100"
            type="decimal"
          />
          <span className="formTextBoxLabel">cGyCm2</span>
          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ marginTop: "16px" }}
          >
            <span hidden={!dataForm.form_id}>
              <FormElementTextArea
                fieldId={"106"}
                rows={5}
                cols={100}
                value={dataForm.data_106}
                noLimit
                onChange={handleChange}
                label="เหตุผลในการแก้ไข"
                width="100"
              />
            </span>
          </div>
        </div>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={<FormCathLabAssessmentPaper
          dataForm={dataForm}
          patient={patient}
          profile={profile}
        />}
      />
    </div>
  );
};

export default FormCathLabAssessment;
