import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FontElementSendDrawingPaper from "../Form/FormElementSendDrawingPaper";


import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import FormElementUploadImage from "../Form/FormElementUploadImage";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const tmseImg = require("../../../images/TMSE.png");

const FormTMSE = (props) => {
  var answerList = [
    { label: "เลือกผู้ตอบ", value: "" },
    { label: "ผู้ป่วย", value: "1" },
    { label: "ผู้ดูแล", value: "2" },
  ];

  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  function setDataObj(data) {
    var datas = { ...dataForm };

    datas = { ...datas, ...data };

    setDataForm(datas);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_tmse",
      formType: "5",
    });
  }, []);

  useEffect(() => {
    //console.log(dataForm);
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getScore1 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1,
      dataForm.data_2,
      dataForm.data_3,
      dataForm.data_4,
      dataForm.data_5,
      dataForm.data_6,
    ]);
  }, [dataForm]);

  const getScore2 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_7,
      dataForm.data_8,
      dataForm.data_9,
    ]);
  }, [dataForm]);

  const getScore3 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_10,
      dataForm.data_11,
      dataForm.data_12,
      dataForm.data_13,
      dataForm.data_14,
    ]);
  }, [dataForm]);

  const getScore4 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_15,
      dataForm.data_16,
      dataForm.data_17,
    ]);
  }, [dataForm]);

  const getScore5_1 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_18, dataForm.data_19]);
  }, [dataForm]);

  const getScore5 = useMemo(() => {
    return utilSumTotalScore([
      getScore5_1,
      dataForm.data_20n,
      dataForm.data_21n,
      dataForm.data_22n,
      dataForm.data_23n,
      dataForm.data_24n,
    ]);
  }, [dataForm]);

  const getScore6 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_25,
      dataForm.data_26,
      dataForm.data_27,
    ]);
  }, [dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      getScore1,
      getScore2,
      getScore3,
      getScore4,
      getScore5,
      getScore6,
    ]);
  }, [dataForm]);

  useEffect(() => {
    props.setScore(getTotalScore);
  }, [dataForm]);

  const onUploadFileInputChange = useCallback((fieldId, files) => {
    setData(fieldId, files);
  });

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="TMSE" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="การคัดกรองสภาพสมอง โดย Thai Mental State Examination" />
        &nbsp;
        <FormElementLabel label={`(ผลรวมคะแนน: ${getTotalScore})`} />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-2@m uk-width-1-2">
            <FormElementLabel label="1. Orientation" />
          </div>
          <div className="uk-width-1-2@m uk-width-1-2">
            <span className="formTextBoxLabel">
              <strong>{getScore1}/6</strong> คะแนน
            </span>
          </div>
        </div>
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <div uk-grid="" className="uk-grid-small">
                <div className="uk-width-1-2@m uk-width-1-1">
                  <FormElementCheckBox
                    onChange={handleChange}
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={""}
                    label="วัน วันอะไรของสัปดาห์ (จันทร์ อังคาร พุธ พฤหัส ศุกร์ เสาร์ อาทิตย์)"
                  />
                </div>
                <div className="uk-width-1-2@m uk-width-1-1">
                  <FormElementCheckBox
                    onChange={handleChange}
                    fieldId={"2"}
                    value={dataForm.data_2}
                    fieldValue={""}
                    label="วันที่ วันที่เท่าไหร่"
                  />
                </div>
                <div className="uk-width-1-2@m uk-width-1-1">
                  <FormElementCheckBox
                    onChange={handleChange}
                    fieldId={"3"}
                    value={dataForm.data_3}
                    fieldValue={""}
                    label="เดือน เดือนอะไร"
                  />
                </div>
                <div className="uk-width-1-2@m uk-width-1-1">
                  <FormElementCheckBox
                    onChange={handleChange}
                    fieldId={"4"}
                    value={dataForm.data_4}
                    fieldValue={""}
                    label="ขณะนี้เป็นช่วง (ตอน) ไหนของวัน (เช้า เที่ยง บ่าย เย็น ) "
                  />
                </div>
                <div className="uk-width-1-2@m uk-width-1-1">
                  <FormElementCheckBox
                    onChange={handleChange}
                    fieldId={"5"}
                    value={dataForm.data_5}
                    fieldValue={""}
                    label="ที่นี้ที่ไหน(บริเวณที่ตรวจ)"
                  />
                </div>
                <div className="uk-width-1-2@m uk-width-1-1">
                  <FormElementCheckBox
                    onChange={handleChange}
                    fieldId={"6"}
                    value={dataForm.data_6}
                    fieldValue={""}
                    label="คนทเี่ห็นในภาพนี้มีอาชีพอะไร "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormElementLine />
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-2@m uk-width-1-2">
            <FormElementLabel label="2. Registration" />
          </div>
          <div className="uk-width-1-2@m uk-width-1-2">
            <span className="formTextBoxLabel">
              <strong>{getScore2}/3</strong> คะแนน
            </span>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
            <div className="uk-width-1-1@m uk-width-1-1">
              <p>
                ผู้ทดสอบ บอกชื่อของ 3 อย่าง โดยพูดห่างกัน คร้ังละ 1 วินาที
                เพียงครั้งเดียว แล้วจึงให้ผู้ทดสอบบอกให้ครบ
                ตามที่ผู้ทดสอบบอกในครั้งแรก ให้ 1 คะแนนในแต่ละคำตอบที่ตอบถูก
                <br />
                (หมายเหตุ หลังจากให้คะแนนแล้วให้บอกซ้ำจนผู้ถูกทดสอบจำได้ทั้ง 3
                อย่าง และบอกให้ผู้ถูกทดสอบทราบว่าสักครู่จะกลับมาถามใหม่)
              </p>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"7"}
                value={dataForm.data_7}
                fieldValue={""}
                label="ต้นไม้"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"8"}
                value={dataForm.data_8}
                fieldValue={""}
                label="รถยนต์"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"9"}
                value={dataForm.data_9}
                fieldValue={""}
                label="มือ"
              />
            </div>
          </div>
        </div>
      </div>
      <FormElementLine />
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-2@m uk-width-1-2">
            <FormElementLabel label="3. Attention" />
          </div>
          <div className="uk-width-1-2@m uk-width-1-2">
            <span className="formTextBoxLabel">
              <strong>{getScore3}/5</strong> คะแนน
            </span>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
            <div className="uk-width-1-1@m uk-width-1-1">
              <p>
                ให้บอกวันอาทิตย์-วันเสาร์ย้อนหลังให้ครบสัปดาห์ (ให้ตอบซ้าได้ 1
                ครั้ง)
              </p>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <span className="formTextBoxLabel">วันอาทิตย์...</span>
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"10"}
                value={dataForm.data_10}
                fieldValue={""}
                label="เสาร์"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"11"}
                value={dataForm.data_11}
                fieldValue={""}
                label="ศุกร์"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"12"}
                value={dataForm.data_12}
                fieldValue={""}
                label="พฤหัสบดี"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"13"}
                value={dataForm.data_13}
                fieldValue={""}
                label="พุธ"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"14"}
                value={dataForm.data_14}
                fieldValue={""}
                label="อังคาร"
              />
            </div>
          </div>
        </div>
      </div>
      <FormElementLine />
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-2@m uk-width-1-2">
            <FormElementLabel label="4. Calculation" />
          </div>
          <div className="uk-width-1-2@m uk-width-1-2">
            <span className="formTextBoxLabel">
              <strong>{getScore4}/3</strong> คะแนน
            </span>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
            <div className="uk-width-1-1@m uk-width-1-1">
              <p>
                ให้คำนวณ 100-7 ไปเรื่อยๆ 3 คร้ัง (ให้ 1 คะแนน
                ในแต่ละครั้งที่ตอบถูก ใช้เวลาคิด ในแต่ละช่วงคำตอบไม่เกิน 1 นาที
                หลังจากจบคำถาม) ถ้าผู้ถูก ทดสอบไม่ตอบคำถามที่ 1 ให้ตั้งเลข
                <br />
                93-7 ลองทาในการคำนวณครั้งต่อไป และ 86-7 ในคร้ังสุดท้ายตามลำดับ
              </p>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"15"}
                value={dataForm.data_15}
                fieldValue={""}
                label="100 – 7"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"16"}
                value={dataForm.data_16}
                fieldValue={""}
                label="93 – 7"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"17"}
                value={dataForm.data_17}
                fieldValue={""}
                label="86 – 7"
              />
            </div>
          </div>
        </div>
      </div>
      <FormElementLine />
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementLabel label="5. Language" />
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
            <div uk-grid="" className="uk-grid-small">
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  {" "}
                  -
                  ผู้ทดสอบชี้ไปที่สิ่งของที่กำหนดแล้วถามผู้ถูกทดสอบว่าโดยทั่วไป
                  “เราเรียกสิ่งนี้ว่าอะไร”
                </span>
                <FormElementCheckBox
                  onChange={handleChange}
                  fieldId={"18"}
                  value={dataForm.data_18}
                  fieldValue={""}
                  label="ปากกา"
                />
                <FormElementCheckBox
                  onChange={handleChange}
                  fieldId={"19"}
                  value={dataForm.data_19}
                  fieldValue={""}
                  label="กระดาษ"
                />
              </div>
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  <strong>{getScore5_1}/2</strong> คะแนน
                </span>
              </div>
            </div>
            <div uk-grid="" className="uk-grid-small">
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  {" "}
                  - ผู้ทดสอบบอกผู้ถูก ทดสอบว่า จงฟังประโยคต่อไปนี้ให้ดี
                  แล้วจำไว้ จากนั้นให้พูดตาม “ยายพาหลานไปซื้อขนมที่ตลาด”
                </span>
              </div>
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  <strong>
                    <FormElementTextBox
                      fieldId={"20n"}
                      value={dataForm.data_20n}
                      type="numeric"
                      onChange={handleChange}
                      width="100"
                      regEx={/^[0,1]{0,1}?$/}
                    />
                    /1
                  </strong>{" "}
                  คะแนน
                </span>
              </div>
            </div>
            <div uk-grid="" className="uk-grid-small">
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  {" "}
                  - จงทำตามคำสั่งต่อไปนี้ (มี 3 ขั้นตอนคำสั่ง)
                  ให้ผู้ทดสอบพูดต่อกัน ไปให้ครบประโยคทั้ง 3 ขั้นตอน
                  ให้คะแนนขั้นตอนละ 1 คะแนน
                  (หยิบกระดาษมือขวา-พับกระดาษเป็นครึ่งแผ่น-แล้วส่งกระดาษให้ผู้ตรวจ)
                </span>
              </div>
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  <strong>
                    <FormElementTextBox
                      fieldId={"21n"}
                      value={dataForm.data_21n}
                      type="numeric"
                      onChange={handleChange}
                      width="100"
                      regEx={/^[0,1,2,3]{0,1}?$/}
                    />
                    /3
                  </strong>{" "}
                  คะแนน
                </span>
              </div>
            </div>
            <div uk-grid="" className="uk-grid-small">
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  {" "}
                  - ให้ผู้ถูกทดสอบอ่านแล้วทำตาม(หลับตา)
                </span>
              </div>
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  <strong>
                    <FormElementTextBox
                      fieldId={"22n"}
                      value={dataForm.data_22n}
                      type="numeric"
                      onChange={handleChange}
                      width="100"
                      regEx={/^[0,1]{0,1}?$/}
                    />
                    /1
                  </strong>{" "}
                  คะแนน
                </span>
              </div>
            </div>
            <div uk-grid="" className="uk-grid-small">
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  {" "}
                  - จงวาดภาพต่อไปนี้ให้เหมือนตัวอย่างมากที่สุด
                  เท่าที่ท่านจะสามารถทำได้
                </span>
              </div>
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  <strong>
                    <FormElementTextBox
                      fieldId={"23n"}
                      value={dataForm.data_23n}
                      type="numeric"
                      onChange={handleChange}
                      width="100"
                      regEx={/^[0,1,2]{0,1}?$/}
                    />
                    /2
                  </strong>{" "}
                  คะแนน
                </span>
              </div>
            </div>
            <div uk-grid="" className="uk-grid-small">
              <div className="uk-width-1-2@m uk-width-1-2">
                <FontElementSendDrawingPaper 
                  fieldId={"29"} 
                  value={dataForm.data_29} 
                  formId="5" 
                  txn={dataForm.txn} 
                  fileId="58" 
                  width="333"
                  height="500"
                  onChange={handleChange} />
              </div>
              
            </div>
            <div uk-grid="" className="uk-grid-small">
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  {" "}
                  -
                  กล้วยกับส้มเหมือนกันคือผลไม้แมวกับสุนัขเหมือนกันคือ(เป็นสัตว์,เป็นสิ่งมีชีวิต)
                </span>
              </div>
              <div className="uk-width-1-2@m uk-width-1-2">
                <span className="formTextBoxLabel">
                  <strong>
                    <FormElementTextBox
                      fieldId={"24n"}
                      value={dataForm.data_24n}
                      type="numeric"
                      onChange={handleChange}
                      width="100"
                      regEx={/^[0,1]{0,1}?$/}
                    />
                    /1
                  </strong>{" "}
                  คะแนน
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormElementLine />
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-2@m uk-width-1-2">
            <FormElementLabel label="6. Recall" />
          </div>
          <div className="uk-width-1-2@m uk-width-1-2">
            <span className="formTextBoxLabel">
              <strong>{getScore6}/3</strong> คะแนน
            </span>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
            <div className="uk-width-1-1@m uk-width-1-1">
              <p>สิ่งของ 3 อย่างที่ให้จำเมื่อสักครู่มีอะไรบ้าง</p>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"25"}
                value={dataForm.data_25}
                fieldValue={""}
                label="ต้นไม้"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"26"}
                value={dataForm.data_26}
                fieldValue={""}
                label="รถยนต์"
              />
              <FormElementCheckBox
                onChange={handleChange}
                fieldId={"27"}
                value={dataForm.data_27}
                fieldValue={""}
                label="มือ"
              />
            </div>
          </div>
        </div>
      </div>
      <FormElementLine />
      <div className="uk-width-1-1@m uk-width-1-1">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-1@m uk-width-1-1">
            <img src={tmseImg} width={800} height={400} />
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label={`(ผลรวมคะแนน: ${getTotalScore})`} />
        &nbsp;
        <FormElementLabel label="ปกติ มากกว่า 23 คะแนน" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementUploadImage
          value={dataForm.data_28}
          onChange={(files) => onUploadFileInputChange("28", files)}
          maxLength={1}
        />
      </div>
    </div>
  );
};

export default FormTMSE;
