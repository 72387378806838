import Api from "../../Components/Api";

async function fetchDiagByTxn(txn) {
    try {
        var result = await Api.consult("GetDiagByTxn", {
            txn: txn,
        });
        var { list } = result;

        if (list != null) {
            if (list.icd10.length > 0) {
                return list.icd10;
            }
            return [];
        }
    } catch (error) {
        console.error(`fetchDiagByTxn: txn(${txn})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchDiagByTxn;