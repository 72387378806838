import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import Form8QPaper from "../FormPaper/Form8QPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const Form8Q = (props) => {
  const formConstants = {
    formName: "form_8q",
    formType: "33",
  };

  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      hideLoading();
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all([patientData, patientRoomRequest]).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];

      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);

  useEffect(() => {
    if (!isSubForm) {
      if (!formId && hn && txn) fetchData(null, hn, txn)
    }
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
    ]);
  }, [dataForm]);

  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html, getTotalScore);
  }, [dataForm, onAdd, getTotalScore]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="แบบประเมินการฆ่าตัวตาย 8 คำถาม (8Q)" />
        </div>

        <div className="uk-width-1-1@m uk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <thead>
              <tr>
                <th>ลำดับคำถาม</th>
                <th>ระยะเวลา</th>
                <th>คำถาม</th>
                <th>ไม่มี</th>
                <th>มี</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td></td>
                <td>คิดอยากตาย หรือคิดว่าตายไปจะดีกว่า</td>
                <td>
                  <FormElementRadio
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td></td>
                <td>อยากทำร้ายตัวเอง หรือ ทำให้ตัวเองบาดเจ็บ</td>
                <td>
                  <FormElementRadio
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"2"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>ในช่วง 1 เดือนที่ผ่านมารวมวันนี้</td>
                <td>คิดเกี่ยวกับการฆ่าตัวตาย</td>
                <td>
                  <FormElementRadio
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"6"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2}></td>
                <td rowSpan={2}>ในช่วง 1 เดือนที่ผ่านมารวมวันนี้</td>
                <td rowSpan={2}>
                  (ถ้าตอบว่าคิดเกี่ยวกับฆ่าตัวตายให้ถามต่อ) .... ท่านสามารถ
                  ควบคุมความอยากฆ่าตัวตายที่ท่านคิดอยู่นั้นได้หรือไม่ หรือ
                  บอกได้ไหมว่าคงจะไม่ทำตามความคิดนั้นในขณะนี้
                </td>
                <th>ได้</th>
                <th>ไม่ได้</th>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"8"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>4.</td>
                <td>ในช่วง 1 เดือนที่ผ่านมารวมวันนี้</td>
                <td>มีแผนการที่จะฆ่าตัวตาย</td>
                <td>
                  <FormElementRadio
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"8"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td>ในช่วง 1 เดือนที่ผ่านมารวมวันนี้</td>
                <td>
                  ได้เตรียมการที่จะทำร้ายตนเองหรือเตรียมการที่จะฆ่าตัวตายโดยตั้งใจว่าจะให้ตายจริงๆ
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"6n"}
                    value={dataForm.data_6n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"6n"}
                    value={dataForm.data_6n}
                    fieldValue={"9"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>6.</td>
                <td></td>
                <td>ได้ทำให้ตนเองบาดเจ็บแต่ไม่ตั้งใจที่จะทำให้เสียชีวิต</td>
                <td>
                  <FormElementRadio
                    fieldId={"7n"}
                    value={dataForm.data_7n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"7n"}
                    value={dataForm.data_7n}
                    fieldValue={"4"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>7.</td>
                <td></td>
                <td>ได้พยายามฆ่าตัวตายโดยคาดหวัง/ตั้งใจที่จะให้ตาย</td>
                <td>
                  <FormElementRadio
                    fieldId={"8n"}
                    value={dataForm.data_8n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"8n"}
                    value={dataForm.data_8n}
                    fieldValue={"10"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>8.</td>
                <td>ตลอดชีวิตที่ผ่านมา</td>
                <td>ท่านเคยพยายามฆ่าตัวตาย</td>
                <td>
                  <FormElementRadio
                    fieldId={"9n"}
                    value={dataForm.data_9n}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"9n"}
                    value={dataForm.data_9n}
                    fieldValue={"4"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3}>คะแนนรวมทั้งหมด</td>
                <td colSpan={2}>{getTotalScore}</td>
              </tr>
            </tbody>
          </table>

          <div className="uk-width-1-1@m uk-width-1-1">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>
                <tr>
                  <th>คะแนนรวม</th>
                  <th>การแปลผล</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0</td>
                  <td>ไม่มีแนวโน้มฆ่าตัวตายในปัจจุบัน</td>
                </tr>
                <tr>
                  <td>1 - 8</td>
                  <td>มีแนวโน้มที่จะฆ่าตัวตายในปัจจุบัน ระดับน้อย</td>
                </tr>
                <tr>
                  <td>9 - 16</td>
                  <td>มีแนวโน้มที่จะฆ่าตัวตายในปัจจุบัน ระดับปานกลาง</td>
                </tr>
                <tr>
                  <td> ≥17 </td>
                  <td>มีแนวโน้มที่จะฆ่าตัวตายในปัจจุบัน ระดับรุนแรง</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementTitle label="ถ้าคะแนน 8Q ≥ 17 ส่งต่อโรงพยาบาลมีจิตแพทย์ด่วน" />
          </div>
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <Form8QPaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
            />}
        />
      </div>
    </>
  );
};

export default Form8Q;
