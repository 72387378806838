import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import DateTimePicker from '../../Components/DatePicker.js';
import numeral from 'numeral';
import SortArray from '../../Components/SortArray.js'

UIkit.use(Icons);

export default class ConsultStatistic extends Component {

  state ={
    list: [],
    dateStart: moment().startOf('d'),
    dateStop: moment().add(1, 'd'),
    campaignSelect: null,
    campaignList: [],
    sortBy: 'chat_amount',
    sortType: 'desc'
  }

  campaignListGet = async () => {
    var {list} = await Api.consult('CampaignList');

    this.setState({campaignList: list || [], campaignSelect: list[0] ? list[0].id : null}, ()=>{ 
      this.listGet();
    });
  }

  listGet = async () => {
    var {list} = await Api.consult('HivStatisticList', {campaignId: this.state.campaignSelect,
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD HH:mm:ss'),
      dateStop: moment(this.state.dateStop).format('YYYY-MM-DD HH:mm:ss')});

      if(!list) list = [];

      this.setState({list});
  }

  sortRender = (by) => {
    var _list = this.state.list;
    var _sortType = this.state.sortType;
    var _sortBy = this.state.sortBy;

    if(by === this.state.sortBy) {
      _sortType = this.state.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      _sortBy = by;
    }

    _list = SortArray(_list, _sortBy, (_sortType === 'desc' ? true : false));


    this.setState({list: _list, sortBy: _sortBy, sortType:_sortType });
  }

  componentDidMount () {
    this.campaignListGet();
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small">
        <div class="d-card-header">รายงานการใช้งาน</div>
        <div uk-grid="">
          <div className="uk-width-auto@m uk-width-1-1">
              <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value)=>this.setState({dateStart: value})}/>
          </div>
          <div className="uk-width-auto@m uk-width-1-1">
              <DateTimePicker id="dateStop" date={this.state.dateStop} callback={(value)=>this.setState({dateStop: value})} />
          </div>
          <div className="uk-width-auto@m uk-width-1-1">
            {
              this.state.campaignList.length ? 
              <select className="uk-select" value={this.state.campaignSelect} onChange={(e)=>this.setState({campaignSelect: e.target.value})}>
                {
                  this.state.campaignList.map((data, key) => {
                    return <option key={`campaignoption${key}`} value={data.id}>{data.name}</option>

                  })
                }
              </select>
              :
              null
            }
            
          </div>
          <div className="uk-width-expand@m uk-width-1-1">
            <button className="uk-button uk-button-primary uk-width-auto@m uk-width-1-1" onClick={this.listGet}>ค้นหา</button>
          </div>
        </div>
        <hr />
        
        <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th>ที่</th>
              <th onClick={()=>this.sortRender('membername')}>ชื่อคนไข้ที่ให้บริการและกดจบการรักษา</th>
              <th onClick={()=>this.sortRender('datefinish')}>วันที่จบการรักษา</th>
              <th onClick={()=>this.sortRender('consultname')}>ชื่อบุคลากร</th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.length === 0 ? 
              <tr>
                <td colSpan="100%">
                  <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                </td>
              </tr>
              :
              this.state.list.map((data, index)=>{
                return <tr key={`row${index}`}>
                  <td>{index+1}</td>
                  <td>{data.membername}</td>
                  <td>{data.datefinish}</td>
                  <td>{data.consultname}</td>
                </tr>
              })
            }
          </tbody>
        </table>
        </div>
      </div>
  }
};
