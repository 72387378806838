import React, { useState, useEffect, useRef, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormMinnesotaLivingWithHeartFailure = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var radioTable = useRef();

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_minnesotalivingwithheartfailure",
      formType: "43",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_84,
      dataForm.data_85,
      dataForm.data_86,
      dataForm.data_87,
      dataForm.data_88,
      dataForm.data_89,
      dataForm.data_90,
      dataForm.data_91,
      dataForm.data_92,
      dataForm.data_93,
      dataForm.data_94,
      dataForm.data_95,
      dataForm.data_96,
      dataForm.data_97,
      dataForm.data_98,
      dataForm.data_99,
      dataForm.data_100,
      dataForm.data_101,
      dataForm.data_102,
      dataForm.data_103,
      dataForm.data_104,
    ]);
  }, [dataForm]);

  useEffect(() => {
    if (radioTable.current) {
      const tds = radioTable.current.getElementsByTagName("td");
      for (let i = 0; i < tds.length; i++) {
        const radioInputs = tds[i].getElementsByTagName("input");
        if (radioInputs.length > 0) {
          const input = radioInputs[0];
          tds[i].addEventListener("click", function () {
            const fieldId = input.name.replace("data_", "");
            setData(fieldId, input.value);
          });
        }
      }
    }
  }, [radioTable]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบสอบถามวิถีชีวิตของผู้ป่วยหัวใจล้มเหลว (The Minnesota Living with Heart Failure)" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel
          label="คำชี้แจง : แบบสอบถามนี้เป็นแบบสอบถามที่ต้องการทราบผลกระทบของโรคหัวใจล้มเหลวที่มีต่อการดำเนินชีวิตของท่าน ในช่วง"
          fontWeight="normal"
        />
        <br />
        <FormElementLabel
          label="เดือนที่ผ่านมาให้ท่านเลือกตัวเลือกที่ตรงกับสภาวะจริงที่ท่านประสบอยู่ในขณะนั้น"
          fontWeight="normal"
        />
        <br />
        <FormElementLabel
          label="ท่านคิดว่าภาวะหัวใจล้มเหลวมีผลต่อชีวิตความเป็นอยู่ของท่านอย่างไร"
          fontWeight="normal"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table
          className="uk-table uk-table-small uk-table-divider uk-table-striped table-border"
          ref={radioTable}
        >
          <thead>
            <tr>
              <th rowSpan={2}>รายการ</th>
              <th colSpan={6}>มีผลกระทบต่อการดำเนินชีวิตของท่าน</th>
            </tr>
            <tr>
              <th style={{ whiteSpace: "nowrap" }}>ไม่มีผลกระทบ</th>
              <th>เล็กน้อย</th>
              <th>น้อย</th>
              <th>ปานกลาง</th>
              <th>มาก</th>
              <th>มากที่สุด</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1. ทำให้มีอาการบวมที่ปลายมือ ปลายเท้า ขา และข้อเท้า</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>2. ทำให้ต้องนั่งพักหรือนอนตอนกลางวันหรือในระหว่างพักงาน</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>3. ทำให้เดินหรือขึ้นลงบันไดลำบาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>4. ทำให้การทำงานบ้านหรืองานสวนเหนื่อยเร็ว</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>5. ทำให้เดินทางไปในที่ไกลๆ ลำบาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                6.
                ทำให้นอนไม่หลับตอนกลางคืนหรือนอนหลับแล้วลุกขึ้นมาหายใจหอบตอนกลางคืน
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                7.
                ทำให้การพบปะสังสรรค์กับเพื่อนหรือการทำกิจกรรมร่วมกับเพื่อนหรือครอบครัวลดลง
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>8. ทำให้ทำงานได้ลดลง</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                9. ไม่สามารถทำงานนอกเวลา ทำอาชีพเสริม ทำงานอดิเรกหรือเล่นกีฬาได้
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>10. ทำให้มีผลกระทบต่อการมีเพศสัมพันธ์</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>11. รับประทานอาหารได้น้อยลงหรือเบื่ออาหาร</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>12. ทำให้หายใจตื้น หายใจลำบาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>13. เหนื่อย เมื่อยล้า อ่อนเพลีย แม้ทำกิจกรรมเบาๆ</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>14. ทำให้ต้องนอนเข้ารักษาตัวในโรงพยาบาล</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>15. ทำให้เสียค่าใช้จ่ายในการรักษาพยาบาลมาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>16. ทำให้เกิดภาวะข้างเคียงจากการรับประทานยา</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>17. ทำให้ห่างเหินจากเพื่อนและครอบครัว</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>18. ทำให้สูญเสียการควบคุมในชีวิต</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>19. ทำให้เกิดความวิตกกังวล</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>20. ทำให้สนใจสิ่งต่างๆ ลดลง และจดจำสิ่งต่างๆ ลดลง</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>21. ทำให้รู้สึกซึมเศร้า</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td className="uk-text-right">คะแนนรวม</td>
              <td colSpan={6}>{getTotalScore}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormMinnesotaLivingWithHeartFailure;
