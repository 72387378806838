import React, { useState, useEffect } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "../../../css/dietz.css";

UIkit.use(Icons);

const FormPopup = (props) => {
    const { show, children, title, onClose } = props;

    useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [show]);

    return (
        <>
            {show ? (
                <div className="form-modal-container">
                    <div className="form-modal-dialog-container">
                        <div className="form-modal-dialog-header">
                            <h5 className="uk-modal-title">{title || ""}</h5>
                            <div className="form-modal-right-header">
                                <button
                                    className="uk-button-default form-modal-close-button"
                                    type="button"
                                    onClick={onClose}
                                >
                                    <span uk-icon="icon: close; ratio: 1" className="dietz-danger-icon"></span>
                                </button>
                            </div>
                        </div>
                        <div className="form-modal-dialog-body">
                            <div className="uk-padding">
                                <div className="uk-card uk-card-default">
                                    <div uk-grid="" className="uk-grid-small uk-padding-small">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-modal-dialog-footer uk-text-right">
                            <button
                                className="uk-button uk-button-default uk-modal-close"
                                type="button"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default FormPopup;
