import React from 'react';

const FormElementSpace = ({data}) => {
  return (
    <div className="uk-width-1-1@m uk-width-1-1">
        <div style={{height:20}}></div>
    </div>
  )
}

export default FormElementSpace;
