import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';

UIkit.use(Icons);

export default class SmartHealthCampaign extends Component {

  state = {
    campaign: <span uk-spinner=""></span>
  }

  componentDidMount() {
    this.campaignGet();
  }

  async campaignGet() {
    var {campaign} = await Api.member('SmartHealthCampaign');
    this.setState({campaign});
  }

  render() {
    return <IconCard option={{
      backgroundColorLeft: '#008385',
      backgroundColorRight: '#00b5b8',
      icon: 'flag',
      title: 'Campaign',
      caption: '',
      value: this.state.campaign || 0
    }} />
  }
};
