import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormMinnesotaLivingWithHeartFailurePaper from "../FormPaper/FormMinnesotaLivingWithHeartFailurePaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave =
  require("../Common/FormUtil.js").transformDataBeforeSave;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormMinnesotaLivingWithHeartFailure = (props) => {
  const formConstants = {
    formName: "form_minnesotalivingwithheartfailure",
    formType: "43",
  };
  const {
    patient,
    onLoading,
    setPatient,
    formId,
    profile,
    onSuccessPopupOpen,
    onErrorMessageChange,
    hn,
    txn,
  } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var radioTable = useRef();

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback(
    (open) => {
      setIsPreview(open);
    },
    [setIsPreview]
  );

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถดึงข้อมูลได้",
      isGoToMainPage: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถบันทึกรายการได้",
      isClose: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback(
    (message) => {
      onErrorMessageChange({
        open: true,
        header: "ไม่สามารถบันทึกได้",
        description: message,
        isClose: true,
      });
    },
    [onErrorMessageChange]
  );

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([patientDataRequest, patientRoomRequest])
      .then((values) => {
        const patientResponse = values[0];
        const patientRoomResponse = values[1];

        setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
        setDataForm((form) => ({
          ...form,
          ...data,
          ...formConstants,
          hn,
          txn,
          patient_name: patientResponse.patient_name,
        }));
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        displayErrorFetchPopup();
      });
  });

  const fetchData = useCallback(
    async (formId, hn, txn) => {
      try {
        displayLoading();
        setDataForm({});
        let data = {};
        let hnValue, txnValue;
        if (formId) {
          data = await fetchConsultFormByFormId(formId, formConstants.formType);
          hnValue = data.hn;
          txnValue = data.txn;
        } else {
          data = await fetchConsultFormByTxn(txn, formConstants.formType);
          hnValue = hn;
          txnValue = txn;
        }
        await fetchOtherData(data, hnValue, txnValue);
      } catch (error) {
        hideLoading();
        displayErrorFetchPopup();
      }
    },
    [
      displayLoading,
      hideLoading,
      displayErrorFetchPopup,
      setDataForm,
      fetchOtherData,
    ]
  );

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null);
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn)
      fetchData(null, patient.hn, patient.txn);
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn);
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    return true;
  }, []);

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(
            saveData,
            profile.loginToken,
            dataForm.form_id,
            profile.consultId,
            profile.doctorId,
            paperHtml,
            patient
          );
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_84,
      dataForm.data_85,
      dataForm.data_86,
      dataForm.data_87,
      dataForm.data_88,
      dataForm.data_89,
      dataForm.data_90,
      dataForm.data_91,
      dataForm.data_92,
      dataForm.data_93,
      dataForm.data_94,
      dataForm.data_95,
      dataForm.data_96,
      dataForm.data_97,
      dataForm.data_98,
      dataForm.data_99,
      dataForm.data_100,
      dataForm.data_101,
      dataForm.data_102,
      dataForm.data_103,
      dataForm.data_104,
    ]);
  }, [dataForm]);

  useEffect(() => {
    if (radioTable.current) {
      const tds = radioTable.current.getElementsByTagName("td");
      for (let i = 0; i < tds.length; i++) {
        const radioInputs = tds[i].getElementsByTagName("input");
        if (radioInputs.length > 0) {
          const input = radioInputs[0];
          tds[i].addEventListener("click", function () {
            setDataForm((data) => ({ ...data, [input.name]: input.value }));
          });
        }
      }
    }
  }, [radioTable, setDataForm]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบสอบถามวิถีชีวิตของผู้ป่วยหัวใจล้มเหลว (The Minnesota Living with Heart Failure)" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel
          label="คำชี้แจง : แบบสอบถามนี้เป็นแบบสอบถามที่ต้องการทราบผลกระทบของโรคหัวใจล้มเหลวที่มีต่อการดำเนินชีวิตของท่าน ในช่วง"
          fontWeight="normal"
        />
        <br />
        <FormElementLabel
          label="เดือนที่ผ่านมาให้ท่านเลือกตัวเลือกที่ตรงกับสภาวะจริงที่ท่านประสบอยู่ในขณะนั้น"
          fontWeight="normal"
        />
        <br />
        <FormElementLabel
          label="ท่านคิดว่าภาวะหัวใจล้มเหลวมีผลต่อชีวิตความเป็นอยู่ของท่านอย่างไร"
          fontWeight="normal"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table
          className="uk-table uk-table-small uk-table-divider uk-table-striped table-border"
          ref={radioTable}
        >
          <thead>
            <tr>
              <th rowSpan={2}>รายการ</th>
              <th colSpan={6}>มีผลกระทบต่อการดำเนินชีวิตของท่าน</th>
            </tr>
            <tr>
              <th style={{ whiteSpace: "nowrap" }}>ไม่มีผลกระทบ</th>
              <th>เล็กน้อย</th>
              <th>น้อย</th>
              <th>ปานกลาง</th>
              <th>มาก</th>
              <th>มากที่สุด</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1. ทำให้มีอาการบวมที่ปลายมือ ปลายเท้า ขา และข้อเท้า</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>2. ทำให้ต้องนั่งพักหรือนอนตอนกลางวันหรือในระหว่างพักงาน</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"85"}
                  value={dataForm.data_85}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>3. ทำให้เดินหรือขึ้นลงบันไดลำบาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>4. ทำให้การทำงานบ้านหรืองานสวนเหนื่อยเร็ว</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>5. ทำให้เดินทางไปในที่ไกลๆ ลำบาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                6.
                ทำให้นอนไม่หลับตอนกลางคืนหรือนอนหลับแล้วลุกขึ้นมาหายใจหอบตอนกลางคืน
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                7.
                ทำให้การพบปะสังสรรค์กับเพื่อนหรือการทำกิจกรรมร่วมกับเพื่อนหรือครอบครัวลดลง
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"90"}
                  value={dataForm.data_90}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>8. ทำให้ทำงานได้ลดลง</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                9. ไม่สามารถทำงานนอกเวลา ทำอาชีพเสริม ทำงานอดิเรกหรือเล่นกีฬาได้
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>10. ทำให้มีผลกระทบต่อการมีเพศสัมพันธ์</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>11. รับประทานอาหารได้น้อยลงหรือเบื่ออาหาร</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"94"}
                  value={dataForm.data_94}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>12. ทำให้หายใจตื้น หายใจลำบาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>13. เหนื่อย เมื่อยล้า อ่อนเพลีย แม้ทำกิจกรรมเบาๆ</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"96"}
                  value={dataForm.data_96}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>14. ทำให้ต้องนอนเข้ารักษาตัวในโรงพยาบาล</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>15. ทำให้เสียค่าใช้จ่ายในการรักษาพยาบาลมาก</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"98"}
                  value={dataForm.data_98}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>16. ทำให้เกิดภาวะข้างเคียงจากการรับประทานยา</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>17. ทำให้ห่างเหินจากเพื่อนและครอบครัว</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"100"}
                  value={dataForm.data_100}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>18. ทำให้สูญเสียการควบคุมในชีวิต</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>19. ทำให้เกิดความวิตกกังวล</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"102"}
                  value={dataForm.data_102}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>20. ทำให้สนใจสิ่งต่างๆ ลดลง และจดจำสิ่งต่างๆ ลดลง</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"103"}
                  value={dataForm.data_103}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>21. ทำให้รู้สึกซึมเศร้า</td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td className="td-pointer">
                <FormElementRadio
                  fieldId={"104"}
                  value={dataForm.data_104}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td className="uk-text-right">คะแนนรวม</td>
              <td colSpan={6}>{getTotalScore}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={
          <FormMinnesotaLivingWithHeartFailurePaper
            dataForm={dataForm}
            patient={patient}
            profile={profile}
          />
        }
      />
    </div>
  );
};

export default FormMinnesotaLivingWithHeartFailure;
