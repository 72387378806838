import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import form9_1_image from "../../../images/form_9_1.jpg";
import form9_2_image from "../../../images/form_9_2.jpg";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

import "../../../css/dietz.css";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;

var utilLoadXHR = require("../FormType/FormUtil.js").loadXHR;
var utilGetBase64 = require("../FormType/FormUtil.js").getBase64;

const FormHeartRepairPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [image1, setImage1] = useState("");
  var [image2, setImage2] = useState("");
  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_heartrepair",
      formType: "9",
    });
  }, []);

  useEffect(() => {
    utilLoadXHR(form9_1_image).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage1(result);
      });
    });
    utilLoadXHR(form9_2_image).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage2(result);
      });
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบบันทึกการฟื้นฟูหัวใจสมรรถภาพหัวใจ โรงพยาบาลมหาราชนครเชียงใหม่"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <div
          style={{
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel label="Check List: " mode="view" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 40px",
            }}
          >
            <FormElementLabel
              padding="0px 10px 0px 0px"
              value={dataForm.data_1}
              mode="view"
              translate={{ 1: "T < 38.5" }}
            />
            <FormElementLabel
              padding="0px 10px 0px 0px"
              value={dataForm.data_2}
              mode="view"
              translate={{ 1: "BP:  < 180/110 mmHg, HR:  < 120 bpm" }}
            />
            <FormElementLabel
              padding="0px 10px 0px 0px"
              value={dataForm.data_3}
              mode="view"
              translate={{ 1: "No orthostatic hypotension > 20 mmHg" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 40px",
            }}
          >
            <FormElementLabel
              padding="0px 10px 0px 0px"
              mode="view"
              value={dataForm.data_4}
              translate={{
                1: "No chest pain at least 8 hours before exercise",
              }}
            />
            <FormElementLabel
              padding="0px 10px 0px 0px"
              mode="view"
              value={dataForm.data_5}
              translate={{ 1: "No dyspnea" }}
            />
            <FormElementLabel
              padding="0px 10px 0px 0px"
              mode="view"
              value={dataForm.data_6}
              translate={{ 1: "No uncontrolled arrhythmia" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_7}
              label="Activities before ACS"
              mode="view"
              width="100"
            />
            <div
              style={{
                padding: "0px 0px 0px 10px",
              }}
            >
              <FormElementTextBox
                value={dataForm.data_8}
                label="METs"
                mode="view"
                width="100"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <span style={{ paddingRight: "10px" }}>
            Contraindication for exercise
          </span>
          <FormElementLabel
            mode="view"
            value={dataForm.data_9}
            translate={{ 0: "Yes (see page 2)", 1: "No" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <span style={{ paddingRight: "10px" }}>
            Risk stratification (criteria อยู่ หน้าถัดไป)
          </span>
          <FormElementLabel
            translate={{ 0: "Low", 1: "Moderate", 2: "High" }}
            value={dataForm.data_10}
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <span style={{ paddingRight: "10px" }}>
            Target HR: = resting HR: + 20 bpm
          </span>
          <FormElementTextBox
            value={dataForm.data_11}
            label="Target METs"
            type="numeric"
            mode="view"
            width="100"
          />
          <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
            at discharge time
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ขั้นตอน
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Program
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Vital signs ก่อนฝึก
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Vital signs หลังฝึก
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Note/Signature
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="ขั้นที่1 < 2METs" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      type="date"
                      value={dataForm.data_12}
                      label=""
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_13}
                      mode="view"
                      translate={{ 1: "พักผ่อนบนเตียง" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_14}
                      mode="view"
                      translate={{ 1: "Complete bed bath" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_15}
                      mode="view"
                      translate={{ 1: "Toilet with bed pan" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_16}
                      mode="view"
                      translate={{ 1: "Breathing exercise" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_17}
                      mode="view"
                      translate={{
                        1: "ออกกำลังกายท่าที่1-3ตามรูป * ท่าละ5ครั้ง",
                      }}
                    />
                  </div>
                  <span>
                    <strong>วันละ2ครั้ง</strong>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_18}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_19}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_20}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_21}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_22}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_23}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_24}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_25}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextArea
                      value={dataForm.data_26}
                      mode="view"
                      fontWeight="bold"
                      rows="11"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="ขั้นที่2 < 3METs" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      placeholder="วันที่"
                      type="date"
                      value={dataForm.data_27}
                      label=""
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_28}
                      mode="view"
                      translate={{
                        1: "ลงนั่งเก้าอี้ข้างเตียงเพื่อทำกิจวัตรประจำวันเช่น",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>รับประทานอาหารนั่งถ่ายอุจจาระ</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_29}
                      mode="view"
                      translate={{ 1: "Partial bed bath" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_30}
                      mode="view"
                      translate={{
                        1: "ออกกำลังกายท่าที่ 1-6 * ท่าละ 5-10 ครั้ง",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_31}
                      mode="view"
                      translate={{ 1: "ยืนทรงตัว" }}
                    />
                  </div>
                  <span>
                    <strong>วันละ 2 ครั้งเช้าเย็น 2.1, 2.3, 2.4</strong>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_32}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_33}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_34}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_35}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_36}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_37}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_38}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_39}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextArea
                      value={dataForm.data_40}
                      mode="view"
                      fontWeight="bold"
                      rows="11"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="ขั้นที่3 < 4METs" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      placeholder="วันที่"
                      type="date"
                      value={dataForm.data_41}
                      label=""
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_42}
                      mode="view"
                      translate={{
                        1: "เริ่มเดินในห้องได้โดยการเดินรอบเตียงช้าๆ",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_43}
                      mode="view"
                      translate={{
                        1: "ออกกำลังกายท่าที่ 1-7 * ท่าละ 10 ครั้ง",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_44}
                      mode="view"
                      translate={{ 1: "ออกกำลังกายท่าที่ 8 ท่าละ 5 ครั้ง" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_45}
                      mode="view"
                      translate={{ 1: "เดินย่ำอยู่กับที่" }}
                    />
                  </div>
                  <span>
                    <strong>วันละ 2 ครั้งเช้าเย็น 3.1-3.4</strong>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_46}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_47}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_48}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_49}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_50}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_51}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_52}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_53}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextArea
                      value={dataForm.data_54}
                      mode="view"
                      label=""
                      fontWeight="bold"
                      rows="11"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="ขั้นที่4 < 5METs" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      placeholder="วันที่"
                      type="date"
                      value={dataForm.data_55}
                      label=""
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_56}
                      mode="view"
                      translate={{
                        1: "ทำกิจวัตรประจำวันได้ด้วยตนเองโดยมีผู้ดูแล",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_57}
                      mode="view"
                      translate={{
                        1: "ออกกำลังกายท่าที่ 1-8 * ท่าละ 10 ครั้งเช้า-เย็น",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_58}
                      mode="view"
                      translate={{
                        1: "เดินเป็นระยะทาง 15-25 เมตรไป-กลับโดยมีผู้ดูแล",
                      }}
                    />
                  </div>
                  <span>
                    <strong>วันละ 2 ครั้งเช้าเย็น</strong>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_59}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_60}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_61}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_62}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_63}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_64}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_65}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_66}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextArea
                      value={dataForm.data_67}
                      mode="view"
                      label=""
                      fontWeight="bold"
                      rows="11"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="ขั้นที่5 < 5METs" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      type="date"
                      placeholder="วันที่"
                      value={dataForm.data_68}
                      label=""
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_69}
                      mode="view"
                      translate={{
                        1: "สามารถปฏิบัติกิจวัตรประจำวันได้ด้วยตนเองโดยไม่มีผู้ดูแล",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_70}
                      mode="view"
                      translate={{
                        1: "ออกกำลังกายท่าที่ 1-9 * ท่าละ 10 ครั้งเช้าเย็น",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_71}
                      mode="view"
                      translate={{
                        1: "เดินภายในหอผู้ป่วยระยะทางประมาณ 50 เมตรไป-กลับ โดยมีผู้ดูแล",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_72}
                      mode="view"
                      translate={{ 1: "เดินออกนอกหอผู้ป่วยโดยมีผู้ดูแล" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_73}
                      mode="view"
                      translate={{
                        1: "เดินขึ้นบันไดแบบพักขา 10-15 ขั้นกลับด้วยลิฟท์",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_74}
                      mode="view"
                      translate={{
                        1: "เดินขึ้นบันไดแบบพักขา 10-15 ขั้นพักจนหายเหนื่อยแล้ว",
                      }}
                    />
                  </div>
                  <span>
                    <strong>เดินลงบันไดแบบพักขา 10-15 ขั้น</strong>
                  </span>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_75}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_76}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_77}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_78}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_79}
                      label="EKG: "
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_80}
                      label="HR: "
                      mode="view"
                      width="100"
                    />
                    <span>bpm</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_81}
                      label="BP: "
                      mode="view"
                      width="100"
                    />
                    <span>mmHg</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <span>Modifield BORG</span>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_82}
                      label="scale: "
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementTextArea
                      value={dataForm.data_83}
                      mode="view"
                      label=""
                      fontWeight="bold"
                      rows="11"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div>Contraindications for exercise</div>
                  <div>
                    <FormElementTextBox
                      placeholder="Day"
                      type="date"
                      value={dataForm.data_84}
                      label=""
                      mode="view"
                      width="100"
                    />
                  </div>
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Indications for termination of exercise
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_85}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Unstable angina" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_86}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Resting SBP > 200mmHg, DBP > 100mmHg" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_87}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Orthostatic BP drop > 20 mmHg with symptoms",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_88}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Significant Aortic Stenosis" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_89}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Acute systemic illness or fever" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_90}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Uncontrolled sinus tachycardia > 120BPM",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_91}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Uncontrolled atrial or ventricular dysrhythmia",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_92}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Uncompensated heart failure" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_93}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Third degree AV block with out pacemaker",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_94}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Active pericarditis, myocarditis" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_95}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Recent embolism, Thrombophlebitis" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_96}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Resting ST-segment depression or elevation",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_97}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Uncontrolled DM" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_98}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Severe orthopedic condition that would prohibit Exercise",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_99}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Other medical conditions that could be aggravated by exercise",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_100}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Other metabolic conditions, such as acute thyroiditis, hypokalemia, hyperkalemia, or hypovolemia (until adequately treat)",
                      }}
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_101}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "HR > TargetHR, THR Post MI = Resting HR + ๒๐ bpm, THR Post heart surgery = Resting HR + ๓๐ bpm",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_102}
                      fieldValue={""}
                      mode="view"
                      translate={{ 1: "Diastolic blood pressure > ๑๑๐ mmHg" }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_103}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Systolic blood pressure drop > ๑๐ mmHg",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_104}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Significant atrial or ventricular arrhythmia with or with out symptoms: Multifocal atrial tachycardia (MAT), New onset of atrial fibrillation, Supraventricular tachycardia (SVT), Atrial flutter, Ventricular fibrillation (VF), Ventricular tachycardia (VT) and PVC(triplet PVC, multifocal PVC>30%,R on T phenomenon)",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_105}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Second or third-degree heart block(after exercise)",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      value={dataForm.data_106}
                      fieldValue={""}
                      mode="view"
                      translate={{
                        1: "Signs and symptoms of cardiac insufficiency(Angina, Marked dyspnea, EKG change suggestive of ischemia)",
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            paddingTop: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormElementLabel
            label="แหล่งอ้างอิง ACSM guidelines,9th edition"
            mode="view"
          />
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={3}
                >
                  Risk stratification
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Low risk
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Moderate risk
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  High risk
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="-EF>50%" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-No resting or exercise induced complex"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="Dysrhythmias" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Uncomplicated cardiac events"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Asymptomatic normal hemodynamic response to"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="exercise" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="-FC> 7 METs" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Absence of clinical"
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="-EF 40-49%" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Signs/symptoms at 5-6.9 METs or in recovery"
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="-EF <40%" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Hx. of cardiac arrest or sudden death"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Complex ventricular arrhythmia at rest or with exercise"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Complicated cardiac events"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Symptomatic & abnormal hemodynamic response"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel label="to exercise" mode="view" />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Signs/symptoms < 5 METs"
                      mode="view"
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel
                      label="-Clinically significant depression"
                      mode="view"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementLabel
            label="แหล่งอ้างอิง ACSM guidelines,9th edition"
            mode="view"
          />
          <img src={image1} alt="image_1" />
          <div
            style={{
              padding: "10px 10px 10px 10px",
              border: "1px solid grey",
              width: "fit-content",
            }}
          >
            <FormElementLabel label="Modified BORG scale" mode="view" />
            <div>
              ไม่เหนื่อยเลย=0, น้อยมาก=1, น้อย=2, ปานกลาง=3, ค่อนข้างมาก=4,
              มาก=5, มากๆ=7, มากเกือบที่สุด=9, แทบไม่ไหว=10
            </div>
          </div>
          <img src={image2} alt="image_2" />
          <div>
            <p>
              Ross Arena, Deborah Riebe, Paul D Thompson Wolters
              Kluwer/Lippincott Williams & Wilkins, Philadelphia Linda S
              Pescatello. (2014). ACSM’s Guidelines for Exercise Testing and
              Prescription 9th Ed. Philadelphia: Wolters Kluwer
              Health/Lippincott Williams & Wilkins, Philadelphia, cop. 2014
            </p>
            <p>
              พรรณรัตน บญโถ. (2552).
              คู่มือการปฏิบัติงานเรื่องการพยาบาลผู้ป่วยโรคกล้ามเนื้อหัวใจตายเฉียบพลันและการฟื้นฟู
              สมรรถภาพหัวใจระยะผู้ป่วยใน. เชียงใหม่: ฝ่ายการพยาบาล
              โรงพยาบาลมหาราชนครเชียงใหม่.
            </p>
            <p>
              อรินทยา พรหมินธิกุล. (2546). ใน อภิชาต สุคนธสรรพ์, และ ศรัณย์
              ควรประเสริฐ, Coronary Heart Disease New Guidelines (หน้า 293-309).
              เชยงใหม่: ไอแอมออเกไนเซอร์
            </p>
          </div>
        </div>
        <span hidden={!dataForm.form_id}>
          <FormElementTextArea
            label="เหตุผลในการแก้ไข"
            value={dataForm.data_107}
            mode="view"
          />
        </span>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormHeartRepairPaper;
