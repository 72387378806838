import React, { useState, useEffect, useMemo } from "react";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTitle from "../Form/FormElementTitle";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const Form9QPaper = (props) => {
  const { dataForm, profile, memberProfile } = props;

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
    ]);
  }, [dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินภาวะซึมเศร้า 9 คำถาม (9Q)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <table
          className="table-border uk-table uk-table-small"
          style={{
            marginTop: "14px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <th
              style={{ borderCollapse: "collapse", border: "1px solid grey" }}
            >
              ในช่วง 2
              สัปดาห์ที่ผ่านมารวมทั้งวันนี้ท่านมีอาการเหล่านี้บ่อยแค่ไหน
            </th>
            <th
              style={{
                whiteSpace: "nowrap",
                borderCollapse: "collapse",
                border: "1px solid grey",
              }}
            >
              ไม่มีเลย
            </th>
            <th
              style={{
                whiteSpace: "nowrap",
                borderCollapse: "collapse",
                border: "1px solid grey",
              }}
            >
              เป็นบางวัน 1-7 วัน{" "}
            </th>
            <th
              style={{
                whiteSpace: "nowrap",
                borderCollapse: "collapse",
                border: "1px solid grey",
              }}
            >
              เป็นบ่อย {">"} 7 วัน
            </th>
            <th
              style={{
                whiteSpace: "nowrap",
                borderCollapse: "collapse",
                border: "1px solid grey",
              }}
            >
              เป็นทุกวัน
            </th>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                1. เบื่อ ไม่สนใจอยากทำอะไร
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                2. ไม่สบายใจ ซึมเศร้า ท้อแท้
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                3. หลับยากหรือหลับๆตื่นๆหรือหลับมากไป
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                4. เหนื่อยง่ายหรือไม่ค่อยมีแรง
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                5. เบื่ออาหารหรือกินมากเกินไป
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                6. รู้สึกไม่ดีกับตัวเอง คิดว่าตัวเองล้มเหลวหรือครอบครัวผิดหวัง
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                7. สมาธิไม่ดี เวลาทำอะไร เช่น ดูโทรทัศน์ ฟังวิทยุ
                หรือทำงานที่ต้องใช้ความตั้งใจ
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                8. พูดช้า
                ทำอะไรช้าลงจนคนอื่นสังเกตเห็นได้หรือกระสับกระส่ายไม่สามารถอยู่นิ่งได้เหมือนที่เคยเป็น
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_8n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_8n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_8n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_8n == "3" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                9. คิดทำร้ายตนเอง หรือคิดว่าถ้าตายไปคงจะดี
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "3" && "/"}
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="table-border uk-table uk-table-small"
          style={{
            marginTop: "14px",
            width: "auto",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                คะแนนรวม
              </th>
              <th
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การแปรผล
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {"<"} 7
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ไม่มีอาการของโรคซึมเศร้าหรือมีอาการของโรคซึมเศร้าระดับน้อยมาก
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                7-12
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการของโรคซึมเศร้า ระดับน้อย
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                13-18
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการของโรคซึมเศร้า ระดับปานกลาง
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {"≥"} 19
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการของโรคซึมเศร้า ระดับรุนแรง
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <FormElementLabel label="ถ้าคะแนน 9Q ≥ 7 ให้ประเมินแนวโน้มการฆ่าตัวตาย ด้วย 8Q" />
        </div>
        <div>
          <FormElementLabel label={`คะแนนรวม: ${getTotalScore}`} />
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default Form9QPaper;
