import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';

var utilHandleChange = require('./FormUtil.js').handleChange;
var utilSetData = require('./FormUtil.js').setData;
var useEffectDataForm = require('./FormUtil.js').useEffectDataForm;
var useEffectPropsDataForm = require('./FormUtil.js').useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormZarit12 = (props) => {


    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    function setData(field, valueData) {
        var datas = { ...dataForm };

        datas = { ...datas, ["data_" + field]: valueData };

        setDataForm(datas);
    }

    function setDataObj(data) {
        var datas = { ...dataForm };

        datas = { ...datas, ...data };

        setDataForm(datas);
    }


    useEffect(() => {
        props.addValue({
            formName: "form_zarit12",
            formType: "36"
        });
    }, []);

    useEffect(() => {
        useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);

    }, [dataForm]);

    useEffect(() => {
        useEffectPropsDataForm(setDataForm, props.dataForm);

    }, [props.dataForm]);

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1n,
            dataForm.data_2n,
            dataForm.data_3n,
            dataForm.data_4n,
            dataForm.data_5n,
            dataForm.data_6n,
            dataForm.data_7n,
            dataForm.data_8n,
            dataForm.data_9n,
            dataForm.data_10n,
            dataForm.data_11n,
            dataForm.data_12n,
        ]);
    }, [dataForm]);

    useEffect(() => {
        props.setScore(getTotalScore);
    }, [dataForm]);

    return (
        <div uk-grid="" className="uk-grid uk-padding-small">


            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementTitle label="Zarit-12 (Form ที่ใช้ใน Post-falling assessment)" />
            </div>

            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementTitle label="ในแต่ละข้อให้เลือกช่องที่คิดว่าตรงกับท่านมากที่สุด" />
            </div>


            <div className="uk-width-1-1@m uk-width-1-1">
                <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ไม่เคยเลย (0)</th>
                            <th>นานๆครั้ง (1)</th>
                            <th>บางครั้ง (2)</th>
                            <th>ค่อนข้างบ่อย (3)</th>
                            <th>แทบทุกครั้ง (4)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1. ท่านรู้สึกว่าท่านไม่มีเวลาเพียงพอสำหรับตัวเอง เนื่องจากว่าใช้เวลาในการดูแล ไม่เวลาของตัวเอง</td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>2. ท่านรู้สึกว่ามีความเครียดทั้งงานที่ต้องดูแลผู้ป่วยและงานอื่นที่ต้องรับผิดชอบ</td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>3. ท่านรู้สึกหงุดหงิดใจ หรือโกรธ ขณะที่อยู่ใกล้ผู้ป่วย</td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>4. ท่านรู้สึกว่าผู้ป่วยทำให้ความสัมพันธ์ของท่านกับสมาชิกครอบครัวหรือเพื่อนแย่ลง</td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>5. ท่านรู้สึกตึงเครียดขณะที่อยู่ใกล้ผู้ป่วย</td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>6. ท่านรู้สึกว่าสุขภาพของท่านไม่ค่อยดี เนื่องมาจากการดูแลผู้ป่วย</td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>7. ท่านรู้สึกว่าท่านไม่มีความเป็นส่วนตัวเท่าที่ต้องการเนื่องจากการดูแลผู้ป่วย</td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>8. ท่านรู้สึกว่าท่านไม่สามารถมีสังคมได้ตามปกติเนื่องจากการดูแลผู้ป่วย</td>
                            <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>9. ท่านรู้สึกว่าท่านไม่สามารถควบคุมจัดการชีวิตตนเองได้ ตั้งแต่ดูแลผู้ป่วย</td>
                            <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>10. ท่านรู้สึกไม่มั่นใจในการดูแลผู้ป่วย</td>
                            <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>11. ท่านรู้สึกว่าท่านควรจะได้รับการดูแลจากญาติคนอื่น</td>
                            <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>12. ท่านรู้สึกว่าท่านน่าจะดูแลผู้ป่วยได้ดีกว่านี้</td>
                            <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>คะแนนรวมทั้งหมด</td>
                            <td colSpan={5}>{getTotalScore}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FormZarit12;


