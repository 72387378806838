import React, { useState, useEffect, useCallback } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import { data, event } from "jquery";
import dateFormat from "dateformat";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormDeliveryWafarineMedicinePaper from "../FormPaper/FormDeliveryWafarineMedicinePaper.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchDrugDataByTxn from "../../API/fetchDrugDataByTxn.js";
import FormElementIconButton from "../Form/FormElementIconButton.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave =
  require("../Common/FormUtil.js").transformDataBeforeSave;
var utilgetValueChanged = require("../Common/FormUtil.js").getValueChanged;

const FormDeliveryWafarineMedicine = (props) => {
  const formConstants = {
    formName: "form_deliverywafarinemedicine",
    formType: "29",
  };
  const {
    patient,
    onLoading,
    setPatient,
    formId,
    profile,
    onSuccessPopupOpen,
    onErrorMessageChange,
    hn,
    txn,
  } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [drugList, setDrugList] = useState([]);
  const [addingData61, setAddingData61] = useState({});
  const [isAddData61, setIsAddData61] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isSaveNewData61, setIsSaveNewData61] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback(
    (open) => {
      setIsPreview(open);
    },
    [setIsPreview]
  );

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถดึงข้อมูลได้",
      isGoToMainPage: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถบันทึกรายการได้",
      isClose: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback(
    (message) => {
      onErrorMessageChange({
        open: true,
        header: "ไม่สามารถบันทึกได้",
        description: message,
        isClose: true,
      });
    },
    [onErrorMessageChange]
  );

  const handleData61ValueChange = useCallback(
    (fieldName, dataIndex, event) => {
      if (typeof dataForm.data_61 == "undefined") {
        return;
      }
      var temp = dataForm?.data_61 || [];
      const value = utilgetValueChanged(event);
      temp[dataIndex][fieldName] = value;
      setDataForm((data) => ({ ...data, data_61: temp }));
    },
    [setDataForm, dataForm]
  );

  const prepareData = useCallback(
    (form, patientResponse, drugDataResponse, profile) => {
      if (!form?.data_57) {
        form.data_57 = patientResponse.patient_name;
      }
      if (!form.data_58) {
        form.data_58 = "1";
      }
      if (!form.data_59) {
        form.data_59 = profile.doctorName;
      }
      if (drugDataResponse && drugDataResponse?.length > 0) {
        form.data_62 = drugDataResponse[0].paper_id;
      }

      if (form?.data_61 && form?.data_61?.length > 0) {
        form.data_61.forEach((item) => delete item.is_not_save);
      }

      setIsSaveNewData61(false);

      return form;
    },
    []
  );

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const drugDataRequest = fetchDrugDataByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all([patientData, drugDataRequest, patientRoomRequest])
      .then((values) => {
        const patientResponse = values[0];
        const drugDataResponse = values[1];
        const patientRoomResponse = values[2];

        data = prepareData(
          { ...data },
          patientResponse,
          drugDataResponse,
          profile
        );

        setPatient({ ...patientResponse, ...patientRoomResponse });
        setDataForm((form) => ({
          ...form,
          ...data,
          ...formConstants,
          hn,
          txn,
          patient_name: patientResponse.patient_name,
        }));
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        displayErrorFetchPopup();
      });
  });

  const fetchData = useCallback(
    async (formId, hn, txn) => {
      try {
        displayLoading();
        setDataForm({});
        let data = {};
        let hnValue, txnValue;
        if (formId) {
          data = await fetchConsultFormByFormId(formId, formConstants.formType);
          hnValue = data.hn;
          txnValue = data.txn;
        } else {
          data = await fetchConsultFormByTxn(txn, formConstants.formType);
          hnValue = hn;
          txnValue = txn;
        }
        await fetchOtherData(data, hnValue, txnValue);
      } catch (error) {
        console.error(error);
        hideLoading();
        displayErrorFetchPopup();
      }
    },
    [
      displayLoading,
      hideLoading,
      displayErrorFetchPopup,
      setDataForm,
      fetchOtherData,
    ]
  );

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null);
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn)
      fetchData(null, patient.hn, patient.txn);
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn);
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    if (
      (dataForm?.data_61 &&
        dataForm?.data_61.length > 0 &&
        dataForm?.data_61.some((item) => item.is_edit)) ||
      isAddData61
    ) {
      displayErrorValidatePopup(
        "เนื่องจากท่านบันทึกการโทรติดตามโดยเภสัชกรไม่สมบูรณ์"
      );
      return false;
    }
    return true;
  }, [dataForm, isAddData61]);

  const handleSave = useCallback(
    (html) => {
      async function saveForm(paperHtml) {
        displayLoading();
        try {
          if (validateSave()) {
            const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
            if (dataForm?.data_61 && dataForm?.data_61?.length > 0) {
              dataForm.data_61.forEach((item) => delete item.is_not_save);
            }

            handlePreviewOpenChange(false);
            await saveConsultForm(
              saveData,
              profile.loginToken,
              dataForm.form_id,
              profile.consultId,
              profile.doctorId,
              paperHtml,
              patient
            );
            hideLoading();
            onSuccessPopupOpen(true);
          } else {
            hideLoading();
          }
        } catch (error) {
          hideLoading();
          displayErrorSavePopup();
        }
      }
      saveForm(html);
    },
    [
      dataForm,
      validateSave,
      displayLoading,
      hideLoading,
      onSuccessPopupOpen,
      displayErrorSavePopup,
      patient,
    ]
  );

  const handleAddData61 = useCallback(() => {
    if (!isSaveNewData61) {
      setIsAddData61(true);
      setAddingData61({
        key: (dataForm?.data_61 && dataForm?.data_61?.length + 1) || 1,
        data_1: dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"),
        data_2: "",
        data_3: "",
        data_4: "",
        is_not_save: true,
      });

      const temp = dataForm?.data_61 || [];
      temp.forEach((item) => delete item.is_edit);
      setDataForm((data) => ({ ...data, data_61: temp }));
    }
  }, [dataForm?.data_61, setIsAddData61, setAddingData61, isSaveNewData61]);

  const handleData61Delete = useCallback(
    (index) => {
      const temp = dataForm?.data_61 || [];
      temp.splice(index, 1);
      setDataForm((data) => ({ ...data, data_61: temp }));
      setIsSaveNewData61(false);
    },
    [setDataForm, dataForm, setIsSaveNewData61]
  );

  const handleData61Edit = useCallback(
    (index) => {
      const temp = dataForm?.data_61 || [];
      temp[index].is_edit = true;
      setDataForm((data) => ({ ...data, data_61: temp }));
    },
    [dataForm]
  );

  const handleData61Finish = useCallback(
    (index) => {
      const data = dataForm.data_61[index];
      if (data.is_edit) delete data.is_edit;
      dataForm.data_61[index] = data;
      setDataForm((data) => ({ ...data, data_61: dataForm.data_61 }));
    },
    [dataForm]
  );

  const handleAddData61Delete = useCallback(() => {
    setIsAddData61(false);
    setAddingData61({});
  }, [setIsAddData61, setAddingData61]);

  const handleAddData61Change = useCallback(
    (field, e) => {
      const value = utilgetValueChanged(e);
      setAddingData61((data) => ({ ...data, [field]: value }));
    },
    [setAddingData61]
  );

  const handleAddData61Save = useCallback(() => {
    const temp = dataForm?.data_61 || [];
    temp.push({ ...addingData61 });
    setDataForm((data) => ({ ...data, data_61: temp }));
    setIsAddData61(false);
    setIsSaveNewData61(true);
    setAddingData61({});
  }, [
    setDataForm,
    addingData61,
    setIsAddData61,
    setAddingData61,
    setIsSaveNewData61,
  ]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" 3.3.3 แบบฟอร์มการติดตามการใช้ยาวาร์ฟารินที่จัดส่งทางไปรษณีย์ " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"63"}
          onChange={handleChange}
          width="100"
          label="วิธีการให้คำแนะนำยา"
          cols={120}
          rows={5}
          value={dataForm.data_63}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          label="ติดตามใบสั่งยาเลขที่"
          fieldId="62"
          value={dataForm.data_62}
          onChange={handleChange}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 1 ข้อมูลพื้นฐานผู้ป่วย" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <tbody>
            <tr>
              <th className="center">รายการยาที่ติดตาม</th>
              <td colSpan={3}>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementRadio
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ห้องตรวจ "
                  />
                  <span
                    className={
                      dataForm.data_1 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"2"}
                      value={dataForm.data_2}
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <FormElementRadio
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" Refill ยา "
                  />
                </div>
              </td>
              <th className="center">วันที่แพทย์สั่งยา</th>
              <td colSpan={3}>
                <FormElementTextBox
                  fieldId={"3"}
                  value={dataForm.data_3}
                  type="date"
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th
                rowSpan={
                  (dataForm?.data_61?.length && dataForm?.data_61?.length != 0
                    ? dataForm?.data_61?.length + 2
                    : 3) +
                  (dataForm?.data_61?.length &&
                  dataForm?.data_61?.length != 0 &&
                  isAddData61
                    ? 1
                    : 0)
                }
                className="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  <span>การโทรติดตามโดย เภสัชกร</span>
                  <FormElementIconButton
                    icon={"plus-circle"}
                    sizeRatio={1}
                    onClick={handleAddData61}
                  />
                </div>
              </th>
              <th
                rowSpan={2}
                className="center"
                style={{ whiteSpace: "nowrap" }}
              >
                ครั้งที่
              </th>
              <th
                rowSpan={2}
                className="center"
                style={{ whiteSpace: "nowrap" }}
              >
                ว/ด/ป, เวลา
              </th>
              <th rowSpan={2} className="center">
                ผู้โทรติดตาม
              </th>
              <th colSpan={5} className="center">
                ผลการติดตาม
              </th>
            </tr>
            <tr>
              <th>สำเร็จ</th>
              <th>ไม่สำเร็จ</th>
              <th>ยกเลิกการติดตาม</th>
              <th colSpan={2}>หมายเหตุ</th>
            </tr>
            {(!dataForm?.data_61 || dataForm?.data_61?.length == 0) &&
              !isAddData61 && (
                <tr>
                  <td colSpan={7}>ไม่มีข้อมูล</td>
                </tr>
              )}
            {dataForm?.data_61?.map((item, index) => {
              return (
                <tr>
                  <td>{item.key}</td>
                  <td>
                    <FormElementTextBox
                      fieldId={"1"}
                      value={item.data_1}
                      type="date"
                      withTime
                      dateFormat={"dd/MM/yyyy, HH:mm"}
                      onChange={(i, e) =>
                        handleData61ValueChange("data_1", index, e)
                      }
                      width="100"
                      disabled={!item.is_edit}
                    />
                  </td>
                  <td>
                    <FormElementTextBox
                      fieldId={"2"}
                      value={item.data_2}
                      label=""
                      onChange={(i, e) =>
                        handleData61ValueChange("data_2", index, e)
                      }
                      width="100"
                      disabled={!item.is_edit}
                    />
                  </td>
                  <td className="td-radio-input">
                    <FormElementRadio
                      fieldId={`radio-3-${index}`}
                      value={item.data_3}
                      fieldValue={"1"}
                      onChange={(i, e) =>
                        handleData61ValueChange("data_3", index, e)
                      }
                      disabled={!item.is_edit}
                    />
                  </td>
                  <td className="td-radio-input">
                    <FormElementRadio
                      fieldId={`radio-3-${index}`}
                      value={item.data_3}
                      fieldValue={"2"}
                      onChange={(i, e) =>
                        handleData61ValueChange("data_3", index, e)
                      }
                      disabled={!item.is_edit}
                    />
                  </td>
                  <td className="td-radio-input">
                    <FormElementRadio
                      fieldId={`radio-3-${index}`}
                      value={item.data_3}
                      fieldValue={"3"}
                      onChange={(i, e) =>
                        handleData61ValueChange("data_3", index, e)
                      }
                      disabled={!item.is_edit}
                    />
                  </td>
                  <td className="td-remark">
                    <FormElementTextBox
                      fieldId={"4"}
                      value={item.data_4}
                      onChange={(i, e) =>
                        handleData61ValueChange("data_4", index, e)
                      }
                      width="100"
                      disabled={!item.is_edit}
                    />
                  </td>
                  <td>
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "4px",
                      }}
                    >
                      {dataForm?.data_61.length == index + 1 &&
                        !item.is_edit &&
                        !isAddData61 && (
                          <FormElementIconButton
                            icon="pencil"
                            sizeRatio={1}
                            onClick={(e) => handleData61Edit(index)}
                          />
                        )}
                      {item.is_not_save && (
                        <FormElementIconButton
                          icon="trash"
                          sizeRatio={1}
                          onClick={(e) => handleData61Delete(index)}
                        />
                      )}
                      {item.is_edit && (
                        <FormElementIconButton
                          icon="check"
                          sizeRatio={1}
                          onClick={(e) => handleData61Finish(index)}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            {isAddData61 && (
              <tr>
                <td>{addingData61.key}</td>
                <td>
                  <FormElementTextBox
                    fieldId={`textbox-1-${addingData61}`}
                    value={addingData61?.data_1}
                    type="date"
                    withTime
                    dateFormat={"dd/MM/yyyy, HH:mm"}
                    onChange={(i, e) => handleAddData61Change("data_1", e)}
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={`textbox-2-${addingData61}`}
                    value={addingData61?.data_2}
                    label=""
                    onChange={(i, e) => handleAddData61Change("data_2", e)}
                    width="100"
                  />
                </td>
                <td className="td-radio-input">
                  <FormElementRadio
                    fieldId={`radio-3-${addingData61}`}
                    value={addingData61?.data_3}
                    fieldValue={"1"}
                    onChange={(i, e) => handleAddData61Change("data_3", e)}
                  />
                </td>
                <td className="td-radio-input">
                  <FormElementRadio
                    fieldId={`radio-3-${addingData61}`}
                    value={addingData61?.data_3}
                    fieldValue={"2"}
                    onChange={(i, e) => handleAddData61Change("data_3", e)}
                  />
                </td>
                <td className="td-radio-input">
                  <FormElementRadio
                    fieldId={`radio-3-${addingData61}`}
                    value={addingData61?.data_3}
                    fieldValue={"3"}
                    onChange={(i, e) => handleAddData61Change("data_3", e)}
                  />
                </td>
                <td className="td-remark">
                  <FormElementTextBox
                    fieldId={`textbox-3-${addingData61}`}
                    value={addingData61?.data_4}
                    onChange={(i, e) => handleAddData61Change("data_4", e)}
                    width="100"
                  />
                </td>
                <td>
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "8px",
                    }}
                  >
                    {addingData61.is_not_save && (
                      <FormElementIconButton
                        icon="trash"
                        sizeRatio={1}
                        onClick={handleAddData61Delete}
                      />
                    )}
                    {addingData61.is_not_save && (
                      <FormElementIconButton
                        icon="check"
                        sizeRatio={1}
                        onClick={handleAddData61Save}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}

            {/* <tr>
              <td>2</td>
              <td>
                <FormElementTextBox
                  fieldId={"8"}
                  value={dataForm.data_8}
                  type="date"
                  withTime
                  dateFormat={"dd/MM/yyyy, HH:mm"}
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"9"}
                  value={dataForm.data_9}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"10"}
                  value={dataForm.data_10}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"10"}
                  value={dataForm.data_10}
                  fieldValue={"2"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"10"}
                  value={dataForm.data_10}
                  fieldValue={"3"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-remark">
                <FormElementTextBox
                  fieldId={"11"}
                  value={dataForm.data_11}
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <FormElementTextBox
                  fieldId={"12"}
                  value={dataForm.data_12}
                  type="date"
                  withTime
                  dateFormat={"dd/MM/yyyy, HH:mm"}
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"13"}
                  value={dataForm.data_13}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"14"}
                  value={dataForm.data_14}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"14"}
                  value={dataForm.data_14}
                  fieldValue={"2"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"14"}
                  value={dataForm.data_14}
                  fieldValue={"3"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-remark">
                <FormElementTextBox
                  fieldId={"15"}
                  value={dataForm.data_15}
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr> */}
            <tr>
              <th className="center">ผู้ป่วยได้รับพัสดุ</th>
              <td colSpan={8}>
                <div className="uk-width-1-1@m uk-width-1-1 uk-display-inline">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <FormElementRadio
                        fieldId={"16"}
                        value={dataForm.data_16}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ไม่ได้รับพัสดุ "
                      />
                      <span hidden={dataForm.data_16 != "2"}>
                        <span className="formTextBoxLabel">
                          <FormElementTextBox
                            fieldId={"18"}
                            value={dataForm.data_18}
                            label=""
                            onChange={handleChange}
                            width="100"
                            placeholder="เนื่องจาก"
                          />
                        </span>
                      </span>
                    </div>

                    <FormElementRadio
                      fieldId={"16"}
                      value={dataForm.data_16}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ได้รับพัสดุ"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        paddingLeft: "20px",
                      }}
                      hidden={dataForm.data_16 != "1"}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <FormElementRadio
                          fieldId={"17"}
                          value={dataForm.data_17}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ครบถ้วนถูกต้องตามใบรายการยา "
                        />
                        <span hidden={dataForm.data_17 != "1"}>
                          <FormElementTextBox
                            fieldId={"60"}
                            value={dataForm.data_60}
                            onChange={handleChange}
                            width="100"
                            placeholder="จำนวนรายการยา"
                            type="numeric"
                            maxLength={10}
                          />
                        </span>
                      </div>
                      <FormElementRadio
                        fieldId={"17"}
                        value={dataForm.data_17}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ไม่ครบหรือไม่ถูกต้องตามใบรายการยา "
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-left"></div>
                {/* <div
                  className="uk-width-1-1@m uk-width-1-1 uk-display-inline"
                  style={{ marginTop: "14px" }}
                >
                  <span
                    className={
                      dataForm.data_16 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormElementRadio
                      fieldId={"17"}
                      value={dataForm.data_17}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ครบถ้วนถูกต้องตามใบรายการยา "
                    />
                    <FormElementRadio
                      fieldId={"17"}
                      value={dataForm.data_17}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" ไม่ครบหรือไม่ถูกต้องตามใบรายการยา "
                    />
                  </span>
                </div> */}
                {/* <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ marginTop: "14px" }}
                >
                  <FormElementRadio
                    fieldId={"16"}
                    value={dataForm.data_16}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ไม่ได้รับพัสดุ "
                  />
                  <span
                    className={
                      dataForm.data_16 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <span className="formTextBoxLabel">
                      <FormElementTextBox
                        fieldId={"18"}
                        value={dataForm.data_18}
                        label=""
                        onChange={handleChange}
                        width="100"
                        placeholder="เนื่องจาก"
                      />
                    </span>
                  </span>
                </div> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 2 ข้อมูลการใช้ยา warfarin" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider">
          <tbody>
            <tr>
              <th className="center">Indication Warfarin</th>
              <th className="center">Target INR</th>
              <th className="center" rowSpan={2}>
                Lab INR ล่าสุด
              </th>
              <td rowSpan={2}>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"19"}
                    value={dataForm.data_19}
                    label="INR"
                    onChange={handleChange}
                    width="100"
                  />
                  <FormElementTextBox
                    fieldId={"20"}
                    value={dataForm.data_20}
                    type="date"
                    onChange={handleChange}
                    width="100"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  <FormElementRadio
                    fieldId={"21"}
                    value={dataForm.data_21}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" Lab รพ.สวนดอก "
                  />
                  <FormElementRadio
                    fieldId={"21"}
                    value={dataForm.data_21}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" Lab นอกรพ. "
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <FormElementTextArea
                  fieldId={"22"}
                  value={dataForm.data_22}
                  onChange={handleChange}
                  width="100"
                  cols={40}
                  noLimit
                />
              </td>
              <td>
                {" "}
                <FormElementTextBox
                  fieldId={"23"}
                  value={dataForm.data_23}
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 3 ข้อมูลความร่วมมือในการใช้ยาและอาการไม่พึงประสงค์" />
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <tbody>
            <tr>
              <th>ขนาดยาที่ได้รับทางไปรษณีย์</th>
              <th>ขนาดยา Warfarin ที่ผู้ป่วยรับประทานจริง</th>
            </tr>
            <tr>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div>
                    <FormElementTextArea
                      fieldId={"64"}
                      value={dataForm.data_64}
                      label=""
                      onChange={handleChange}
                      cols={40}
                      noLimit
                    />
                  </div>
                  <div>
                    <span className="formTextBoxLabel">(Total</span>
                    <FormElementTextBox
                      fieldId={"24"}
                      value={dataForm.data_24}
                      label=""
                      type="numeric"
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">mg/wk)</span>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <FormElementRadio
                    fieldId={"25"}
                    value={dataForm.data_25}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ตามระบุในใบสรุปรายการยา"
                  />
                </div>
                <div style={{ marginTop: "14px" }}>
                  <FormElementRadio
                    fieldId={"25"}
                    value={dataForm.data_25}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ไม่ตรงตามระบุ ดังนี้"
                  />
                  <span
                    className={
                      dataForm.data_25 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"26"}
                      value={dataForm.data_26}
                      type="numeric"
                      label="(Total"
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">mg/wk)</span>
                  </span>
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ marginLeft: "30px" }}
                >
                  <span
                    className={
                      dataForm.data_25 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <div className="uk-width-1-1">
                      <FormElementRadio
                        fieldId={"27"}
                        value={dataForm.data_27}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มีปรับยาจากรพ.อื่น/คลินิก"
                      />
                      <span
                        className={
                          dataForm.data_27 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <span className="formTextBoxLabel">เมื่อวันที่</span>
                        <FormElementTextBox
                          fieldId={"28"}
                          value={dataForm.data_28}
                          type="date"
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">ปรับเป็น</span>
                        <FormElementTextBox
                          fieldId={"29"}
                          value={dataForm.data_29}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </div>
                    <div className="uk-width-1-1">
                      <FormElementRadio
                        fieldId={"27"}
                        value={dataForm.data_27}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" อื่นๆ"
                      />
                      <span
                        className={
                          dataForm.data_27 == "2" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"30"}
                          value={dataForm.data_30}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </div>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>ความร่วมมือในการใช้ยา</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" กินยาถูกต้องตามฉลากยาที่ระบุ"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" ขาดยาลืมกินยา"
                    />
                  </div>
                  <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label=" กินยาไม่ตรงตามฉลากยาที่ระบุ"
                    />
                    <span
                      className={
                        dataForm.data_31 == "3" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"32"}
                        value={dataForm.data_32}
                        label="เนื่องจาก"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>การใช้ สมุนไพร อาหารเสริม หรือยาอื่นๆ</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"33"}
                      value={dataForm.data_33}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ไม่มี"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"33"}
                      value={dataForm.data_33}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" มี "
                    />
                    <span
                      className={
                        dataForm.data_33 == "2" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"34"}
                        value={dataForm.data_34}
                        onChange={handleChange}
                        label="ยา"
                      />
                      <span
                        className={
                          dataForm.data_34 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"35"}
                          value={dataForm.data_35}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementCheckBox
                        fieldId={"36"}
                        value={dataForm.data_36}
                        onChange={handleChange}
                        label="อาหาร/อาหารเสริม"
                      />
                      <span
                        className={
                          dataForm.data_36 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"37"}
                          value={dataForm.data_37}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementCheckBox
                        fieldId={"38"}
                        value={dataForm.data_38}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label="สมุนไพร"
                      />
                      <span
                        className={
                          dataForm.data_38 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"39"}
                          value={dataForm.data_39}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementCheckBox
                        fieldId={"40"}
                        value={dataForm.data_40}
                        onChange={handleChange}
                        label="แอลกอฮอล์ "
                      />
                      <span
                        className={
                          dataForm.data_40 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"41"}
                          value={dataForm.data_41}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>อาการไม่พึงประสงค์จากยา</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={"0"}
                      onChange={handleChange}
                      label="ไม่มี"
                    />
                    <FormElementRadio
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="มี"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"43"}
                        value={dataForm.data_43}
                        onChange={handleChange}
                        label=" ภาวะเลือดออกไม่รุนแรง (มีจ้ำเลือดตามผิวหนัง เลือดกำเดาไหล เลือดออกตามไรฟันมากผิดปกติ)"
                      />
                    </span>
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"44"}
                        value={dataForm.data_44}
                        onChange={handleChange}
                        label=" ภาวะเลือดออกที่รุนแรง (ไอเป็นเลือด หรือปัสสาวะ/อุจจาระมีสีดำ ปวดศรีษะมากและมีเลือดออกในสมอง)"
                      />
                    </span>
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"45"}
                        value={dataForm.data_45}
                        onChange={handleChange}
                        label=" แขนขาอ่อนแรงชาครึ่งซีก หน้าเบี้ยว ปากเบี้ยว พูดไม่ออก พูดไม่ชัด"
                      />
                    </span>
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"46"}
                        value={dataForm.data_46}
                        onChange={handleChange}
                        label=" ADR/อาการผิดปกติอื่นๆ "
                      />
                      <span
                        className={
                          dataForm.data_46 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"47"}
                          value={dataForm.data_47}
                          label=" "
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>การจัดการเบื้องต้น</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"48"}
                      value={dataForm.data_48}
                      onChange={handleChange}
                      label=" แนะนำให้หลีกเลี่ยง ยากลุ่ม NSIADs,อาหาร,อาหารเสริม หรือสมุนไพรที่มีผลทำให้ระดับยาวาร์ฟารินในเลือดเปลี่ยนแปลง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"49"}
                      value={dataForm.data_49}
                      onChange={handleChange}
                      label=" แนะนำให้สังเกตอาการเลือดออกผิดปกติหรือมีอาการผิดปกติให้มาพบแพทย์ก่อนนัด"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"50"}
                      value={dataForm.data_50}
                      onChange={handleChange}
                      label=" ส่งต่อข้อมูลการติดตาม (ส่วนที่4)"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"51"}
                      value={dataForm.data_51}
                      onChange={handleChange}
                      label=" อื่นๆ "
                    />
                    <span
                      className={
                        dataForm.data_51 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"52"}
                        value={dataForm.data_52}
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 4 ข้อมูลที่ต้องการส่งต่อ" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementCheckBox
          fieldId={"53"}
          value={dataForm.data_53}
          onChange={handleChange}
          label="แพทย์"
        />
        <FormElementCheckBox
          fieldId={"54"}
          value={dataForm.data_54}
          onChange={handleChange}
          label="เภสัชกร"
        />
        <FormElementCheckBox
          fieldId={"55"}
          value={dataForm.data_55}
          onChange={handleChange}
          label="พยาบาล/เจ้าหน้าที่ห้องตรวจ"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"56"}
          rows={5}
          cols={120}
          value={dataForm.data_56}
          onChange={handleChange}
          width="100"
          noLimit
        />
      </div>
      <div
        className="uk-width-1-1@m uk-width-1-1"
        style={{ display: "inline-flex", alignItems: "center", gap: "20px" }}
      >
        <div>
          <FormElementTextBox
            fieldId={"57"}
            value={dataForm.data_57}
            placeholder="ชื่อผู้รับการประเมิน"
            label=" ผู้รับการประเมิน (ผู้ป่วย/ญาติ)"
            onChange={handleChange}
            width="100"
          />
          <FormElementDropdown
            fieldId={"58"}
            value={dataForm.data_58}
            dataList={[
              { label: "เลือกผู้รับการประเมิน", value: "0" },
              { label: "ผู้ป่วย", value: "1" },
              { label: "ญาติ", value: "2" },
            ]}
            onChange={handleChange}
            width="100"
          />
        </div>
        <span>เภสัชกรผู้ประเมิน {dataForm.data_59 || ""}</span>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={
          <FormDeliveryWafarineMedicinePaper
            dataForm={dataForm}
            patient={patient}
            profile={profile}
          />
        }
      />
    </div>
  );
};

export default FormDeliveryWafarineMedicine;
