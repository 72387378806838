import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import FormElementDropdown from "../Form/FormElementDropdown";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDateTextBox from "../Form/FormElementDateTextBox";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormNursingAssessmentPaper from "../FormPaper/FormNursingAssessmentPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave =
  require("../Common/FormUtil.js").transformDataBeforeSave;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormNursingAssessment = (props) => {
  const formConstants = {
    formName: "form_nursingassessment",
    formType: "6",
  };
  const {
    patient,
    onLoading,
    setPatient,
    formId,
    profile,
    onSuccessPopupOpen,
    onErrorMessageChange,
    hn,
    txn,
  } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback(
    (open) => {
      setIsPreview(open);
    },
    [setIsPreview]
  );

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถดึงข้อมูลได้",
      isGoToMainPage: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถบันทึกรายการได้",
      isClose: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback(
    (message) => {
      onErrorMessageChange({
        open: true,
        header: "ไม่สามารถบันทึกได้",
        description: message,
        isClose: true,
      });
    },
    [onErrorMessageChange]
  );

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([patientDataRequest, patientRoomRequest])
      .then((values) => {
        const patientResponse = values[0];
        const patientRoomResponse = values[1];
        setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
        setDataForm((form) => ({
          ...form,
          ...data,
          ...formConstants,
          hn,
          txn,
          patient_name: patientResponse.patient_name,
        }));
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        displayErrorFetchPopup();
      });
  });

  const fetchData = useCallback(
    async (formId, hn, txn) => {
      try {
        displayLoading();
        setDataForm({});
        let data = {};
        let hnValue, txnValue;
        if (formId) {
          data = await fetchConsultFormByFormId(formId, formConstants.formType);
          hnValue = data.hn;
          txnValue = data.txn;
        } else {
          data = await fetchConsultFormByTxn(txn, formConstants.formType);
          hnValue = hn;
          txnValue = txn;
        }
        await fetchOtherData(data, hnValue, txnValue);
      } catch (error) {
        hideLoading();
        displayErrorFetchPopup();
      }
    },
    [
      displayLoading,
      hideLoading,
      displayErrorFetchPopup,
      setDataForm,
      fetchOtherData,
    ]
  );

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null);
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn)
      fetchData(null, patient.hn, patient.txn);
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn);
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    if (!dataForm.data_1) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 1.1 สุขภาพของท่านเป็นอย่างไร");
      return false;
    }
    if (!dataForm.data_2) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ 1.2 ท่านพึงพอใจต่อภาวะสุขภาพของท่านหรือไม่"
      );
      return false;
    }

    if (!dataForm.data_4) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การดูแลสุขภาพเมื่อเจ็บป่วย");
      return false;
    }

    if (!dataForm.data_6) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การปฏิบัติตามแผนการรักษา");
      return false;
    }

    if (!dataForm.data_7) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ 1.4 การรับรู้ต่อความเจ็บป่วยในปัจจุบัน"
      );
      return false;
    }

    if (!dataForm.data_8) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 1.5 การได้รับภูมิคุ้มกันโรค");
      return false;
    } else {
      if (dataForm.data_8 == "2" && !dataForm.data_9) {
        displayErrorValidatePopup("จำเป็นต้องระบุ 1.5 การได้รับภูมิคุ้มกันโรค");
        return false;
      }
    }

    if (!dataForm.data_10) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 1.6 การใช้สารเสพติด");
      return false;
    } else {
      if (dataForm.data_10 == "1") {
        if (
          dataForm.data_12 != "1" &&
          dataForm.data_21 != "1" &&
          dataForm.data_25 != "1" &&
          dataForm.data_28 != "1"
        ) {
          displayErrorValidatePopup("จำเป็นต้องระบุ 1.6 การใช้สารเสพติด");
          return false;
        }

        if (dataForm.data_12 == "1" && !dataForm.data_11) {
          displayErrorValidatePopup("จำเป็นต้องระบุ Smoking type");
          return false;
        }

        if (dataForm.data_12 == "1" && dataForm.data_11 == "1") {
          if (!dataForm.data_13) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ประเภทของบุหรี่");
            return false;
          }
          if (!dataForm.data_15) {
            displayErrorValidatePopup("จำเป็นต้องระบุ จำนวนของบุหรี่");
            return false;
          }
          if (!dataForm.data_16) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ระยะเวลาที่เลิก");
            return false;
          }
        }

        if (dataForm.data_12 == "1" && dataForm.data_11 == "2") {
          if (!dataForm.data_17) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ประเภทของบุหรี่");
            return false;
          }
          if (!dataForm.data_19) {
            displayErrorValidatePopup("จำเป็นต้องระบุ จำนวนของบุหรี่");
            return false;
          }
          if (!dataForm.data_20) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ระยะเวลาที่เลิก");
            return false;
          }
        }

        if (dataForm.data_21 == "1") {
          if (!dataForm.data_22) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ประเภท ของ Alcohol");
            return false;
          }
          if (!dataForm.data_23) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ปริมาณที่บริโภค Alcohol");
            return false;
          }
          if (!dataForm.data_24) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ความถี่");
            return false;
          }
        }

        if (dataForm.data_25 == "1") {
          if (!dataForm.data_26) {
            displayErrorValidatePopup(
              "จำเป็นต้องระบุ ประเภท ของ Addictive substance"
            );
            return false;
          }
          if (!dataForm.data_27) {
            displayErrorValidatePopup(
              "จำเป็นต้องระบุ ระยะเวลาที่ใช้ ของ Addictive substance"
            );
            return false;
          }
        }

        if (dataForm.data_28 == "1") {
          if (!dataForm.data_29) {
            displayErrorValidatePopup("จำเป็นต้องระบุ ประเภท ของ Other");
            return false;
          }
          if (!dataForm.data_30) {
            displayErrorValidatePopup(
              "จำเป็นต้องระบุ ระยะเวลาที่ใช้ ของ Other"
            );
            return false;
          }
        }
      }
    }

    if (!dataForm.data_31) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ประเภท ของ 2.1 แบบแผนการรับประทานอาหาร/การดื่มน้ำ"
      );
      return false;
    }
    if (!dataForm.data_34) {
      displayErrorValidatePopup("จำเป็นต้องระบุ จำนวนมื้อ");
      return false;
    }
    if (!dataForm.data_35) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ปริมาณต่อมื้อ");
      return false;
    }
    if (!dataForm.data_36) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การดื่มน้ำ ปริมาณ");
      return false;
    }
    if (!dataForm.data_37) {
      displayErrorValidatePopup("จำเป็นต้องระบุ แหล่งที่มาของน้ำดื่ม");
      return false;
    }

    if (!dataForm.data_38) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 2.2 ปัญหาการรับประทานอาหาร");
      return false;
    } else {
      if (
        dataForm.data_38 == "1" &&
        dataForm.data_39 != "1" &&
        dataForm.data_40 != "1" &&
        dataForm.data_41 != "1" &&
        dataForm.data_42 != "1" &&
        dataForm.data_43 != "1" &&
        dataForm.data_44 != "1"
      ) {
        displayErrorValidatePopup("จำเป็นต้องระบุ 2.2 ปัญหาการรับประทานอาหาร");
        return false;
      }
    }

    if (!dataForm.data_46) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
      );
      return false;
    } else {
      if (dataForm.data_46 == "3" && !dataForm.data_47) {
        displayErrorValidatePopup(
          "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
        );
        return false;
      }

      if (dataForm.data_46 == "3" && dataForm.data_47 == "1") {
        if (!dataForm.data_48) {
          displayErrorValidatePopup(
            "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
          );
          return false;
        }
        if (!dataForm.data_49) {
          displayErrorValidatePopup("จำเป็นต้องระบุ ในระยะเวลา");
          return false;
        }
      }

      if (dataForm.data_46 == "3" && dataForm.data_47 == "2") {
        if (!dataForm.data_50) {
          displayErrorValidatePopup(
            "จำเป็นต้องระบุ 2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา"
          );
          return false;
        }
        if (!dataForm.data_51) {
          displayErrorValidatePopup("จำเป็นต้องระบุ ในระยะเวลา");
          return false;
        }
      }
    }

    if (!dataForm.data_52) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ 2.4 ปัญหาเกี่ยวกับสุขภาพผิวหนัง/เล็บ/ผม"
      );
      return false;
    }
    if (!dataForm.data_54) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BMI");
      return false;
    }
    if (!dataForm.data_61) {
      displayErrorValidatePopup("จำเป็นต้องระบุ จำนวนครั้งที่ปัสสาวะต่อวัน");
      return false;
    }
    if (!dataForm.data_62) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ปริมาณของปัสสาวะต่อครั้ง");
      return false;
    }
    if (!dataForm.data_63) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ลักษณะการปัสสาวะ");
      return false;
    } else {
      if (
        dataForm.data_63 == "2" &&
        dataForm.data_64 != "1" &&
        dataForm.data_65 != "1" &&
        dataForm.data_66 != "1" &&
        dataForm.data_67 != "1"
      ) {
        displayErrorValidatePopup("จำเป็นต้องระบุ ลักษณะของปัสสาวะ");
        return false;
      }
    }

    if (!dataForm.data_69) {
      displayErrorValidatePopup("จำเป็นต้องระบุ จำนวนวันที่ขับถ่ายต่อสัปดาห์");
      return false;
    }
    if (!dataForm.data_70) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ลักษณะของการขับถ่าย");
      return false;
    } else {
      if (
        dataForm.data_70 == "2" &&
        dataForm.data_71 != "1" &&
        dataForm.data_72 != "1" &&
        dataForm.data_73 != "1" &&
        dataForm.data_74 != "1" &&
        dataForm.data_75 != "1" &&
        dataForm.data_76 != "1"
      ) {
        displayErrorValidatePopup("จำเป็นต้องระบุ ลักษณะของการขับถ่าย");
        return false;
      }
    }
    if (!dataForm.data_78) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 3.3 อาการเหงื่อออกมากผิดปกติ");
      return false;
    }
    if (!dataForm.data_80) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 4.1 การนอนหลับ");
      return false;
    }
    if (!dataForm.data_81) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ปกตินอนวันละ");
      return false;
    }
    if (!dataForm.data_82) {
      displayErrorValidatePopup("จำเป็นต้องระบุ เวลาเข้านอน");
      return false;
    }
    if (!dataForm.data_83) {
      displayErrorValidatePopup("จำเป็นต้องระบุ เวลาตื่น");
      return false;
    }
    if (!dataForm.data_84) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ลักษณะการนอนหลับ");
      return false;
    }
    if (dataForm.data_84 == "2" && !dataForm.data_85) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ลักษณะการนอนหลับ");
      return false;
    }
    if (!dataForm.data_86) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ปัญหาในการนอนหลับ");
      return false;
    }
    if (!dataForm.data_88) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ"
      );
      return false;
    } else {
      if (dataForm.data_88 == "1") {
        if (!dataForm.data_89) {
          displayErrorValidatePopup(
            "จำเป็นต้องระบุ การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ"
          );
          return false;
        }
        if (!dataForm.data_90) {
          displayErrorValidatePopup("จำเป็นต้องระบุ ความถี่ในการใช้");
          return false;
        }
      }
    }
    if (!dataForm.data_91) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 5.1 การทำกิจวัตรประจำวัน");
      return false;
    }
    if (!dataForm.data_92) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 5.2 แบบแผนการออกกำลังกาย");
      return false;
    }
    if (!dataForm.data_93) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วิธีการออกกำลังกาย");
      return false;
    }
    if (!dataForm.data_94) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ระยะเวลาออกกำลังกาย");
      return false;
    }
    if (!dataForm.data_95) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ความถี่");
      return false;
    }
    if (!dataForm.data_96) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 1. การเข้าห้องน้ำ"
      );
      return false;
    }
    if (!dataForm.data_97) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 2. การอาบน้ำ"
      );
      return false;
    }
    if (!dataForm.data_98) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 3. การรับประทานอาหาร"
      );
      return false;
    }
    if (!dataForm.data_99) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 4. การเคลื่อนไหวทั่วไป"
      );
      return false;
    }
    if (!dataForm.data_100) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 5. การแต่งตัว"
      );
      return false;
    }
    if (!dataForm.data_101) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 6. การหวีผม การทำความสะอาดปากและฟัน"
      );
      return false;
    }
    if (!dataForm.data_102) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 7. การซื้อของ/ทำธุระนอกบ้าน"
      );
      return false;
    }
    if (!dataForm.data_103) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความสามารถในการช่วยเหลือตนเอง 8. การเคลื่อนไหวบนเตียงกรณีผู้ป่วยติดเตียง"
      );
      return false;
    }
    if (!dataForm.data_104) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 5.4 ปัญหาในการเคลื่อนไหว");
      return false;
    }
    if (!dataForm.data_106) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความรู้สึกตัว (Consciousness)"
      );
      return false;
    }
    if (!dataForm.data_107) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ความจำ (Memory)");
      return false;
    }
    if (!dataForm.data_107) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ความจำ (Memory)");
      return false;
    }
    if (!dataForm.data_109) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การรับรู้เวลา");
      return false;
    }
    if (!dataForm.data_111) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การรับรู้สถานที่");
      return false;
    }
    if (!dataForm.data_113) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การรับรู้บุคคล");
      return false;
    }
    if (!dataForm.data_115) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การได้ยิน");
      return false;
    }
    if (!dataForm.data_117) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การมองเห็น");
      return false;
    }
    if (!dataForm.data_119) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การรับกลิ่น");
      return false;
    }
    if (!dataForm.data_121) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การรับรส");
      return false;
    }
    if (!dataForm.data_123) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การสัมผัส");
      return false;
    }
    if (!dataForm.data_125) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ สิ่งที่มีผลกระทบต่อตนเองและอัตมโนทัศน์"
      );
      return false;
    }
    if (!dataForm.data_127) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลกระทบต่อโครงสร้าง บทบาท และสัมพันธภาพของครอบครัว"
      );
      return false;
    }
    if (!dataForm.data_129) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลกระทบต่อสถานภาพ บทบาท และสัมพันธภาพในสังคม"
      );
      return false;
    }
    if (!dataForm.data_131) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ บุคคลและแหล่งที่ให้ความช่วยเหลือผู้ป่วย"
      );
      return false;
    }
    if (!dataForm.data_133) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ สิ่งที่เป็นอุปสรรคต่อการสร้างสัมพันธภาพ"
      );
      return false;
    }
    if (!dataForm.data_135) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การคุมกำเนิด");
      return false;
    }
    if (!dataForm.data_137) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ปัญหาเกี่ยวกับอวัยวะสืบพันธุ์");
      return false;
    }
    if (!dataForm.data_139) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ พฤติกรรมที่แสดงออกสัมพันธ์กับเพศสภาพ"
      );
      return false;
    }
    if (!dataForm.data_141) {
      displayErrorValidatePopup("จำเป็นต้องระบุ เป็นผู้ป่วยเพศหญิง");
      return false;
    } else {
      if (dataForm.data_141 == "1") {
        if (!dataForm.data_142) {
          displayErrorValidatePopup(
            "จำเป็นต้องระบุ ประจำเดือนมาครั้งสุดท้าย วันที่"
          );
          return false;
        }
        if (!dataForm.data_143) {
          displayErrorValidatePopup("จำเป็นต้องระบุ Duration/cycle");
          return false;
        }
        if (!dataForm.data_144) {
          displayErrorValidatePopup("จำเป็นต้องระบุ Length of cycle ");
          return false;
        }
      }
    }
    if (!dataForm.data_146) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การเจ็บป่วยครั้งนี้ส่งผลกระทบต่อจิตใจ/เครียด/กังวล"
      );
      return false;
    }
    if (!dataForm.data_148) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ปัจจัยเสริมที่ทำให้เกิดความไม่สบายใจขณะเจ็บป่วย"
      );
      return false;
    }
    if (!dataForm.data_150) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ บุคคลหรือแหล่งที่ให้ความช่วยเหลือเวลามีปัญหาหรือไม่สบายใจ"
      );
      return false;
    }
    if (!dataForm.data_152) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การจัดการเมื่อเกิดความเครียด/กังวล"
      );
      return false;
    }
    if (!dataForm.data_154) {
      displayErrorValidatePopup("จำเป็นต้องระบุ นับถือศาสนา");
      return false;
    }
    if (!dataForm.data_156) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผู้ป่วยเชื่อว่าการเจ็บป่วยในครั้งนี้เกิดจาก"
      );
      return false;
    }
    if (!dataForm.data_157) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ สิ่งที่ต้องการ/มีค่ามากที่สุดในชีวิต"
      );
      return false;
    }
    if (!dataForm.data_159) {
      displayErrorValidatePopup("จำเป็นต้องระบุ สิ่งยึดเหนี่ยวในชีวิต");
      return false;
    }
    if (!dataForm.data_161) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลของการอยู่โรงพยาบาลต่อการปฏิบัติศาสนกิจ"
      );
      return false;
    }
    return true;
  }, [dataForm, displayErrorValidatePopup]);

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(
            saveData,
            profile.loginToken,
            dataForm.form_id,
            profile.consultId,
            profile.doctorId,
            paperHtml,
            patient
          );
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_55,
      dataForm.data_56,
      dataForm.data_57,
      dataForm.data_58,
    ]);
  }, [dataForm.data_55, dataForm.data_56, dataForm.data_57, dataForm.data_58]);

  useEffect(() => {
    setDataForm((data) => ({ ...data, data_59: getTotalScore }));
  }, [getTotalScore, setDataForm]);

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Nursing Assessment" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Health assessment 1" fontWeight="bold" />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel
            label="1.การรับรู้เกี่ยวกับการดูแลสุขภาพ"
            fontWeight="bold"
          />

          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div className="uk-padding-small" style={{ width: "50%" }}>
              <span>
                1.1 สุขภาพของท่านเป็นอย่างไร{" "}
                <span style={{ color: "red" }}>*</span>
              </span>
              <div className="uk-padding-small">
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="ดีมาก"
                />
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="ดี"
                />
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label="แย่"
                />
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label="อื่นๆ"
                />
                <span
                  className={
                    dataForm.data_1 == "4" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"163"}
                    value={dataForm.data_163}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div className="uk-padding-small" style={{ width: "50%" }}>
              <span>
                1.2 ท่านพึงพอใจต่อภาวะสุขภาพของท่านหรือไม่{" "}
                <span style={{ color: "red" }}>*</span>
              </span>
              <div className="uk-padding-small">
                <FormElementRadio
                  fieldId={"2"}
                  value={dataForm.data_2}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="พึงพอใจ"
                />
                <FormElementRadio
                  fieldId={"2"}
                  value={dataForm.data_2}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="ไม่พึงพอใจ"
                />
                <span
                  className={
                    dataForm.data_2 == "2" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"3"}
                    value={dataForm.data_3}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div
              className="uk-padding-small uk-padding-remove-top"
              style={{ width: "50%" }}
            >
              1.3 การปฏิบัติตามแผนการรักษาและการดูแลในอดีต
              <div className="uk-padding-small">
                <span>
                  การดูแลสุขภาพเมื่อเจ็บป่วย{" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>
              <div className="uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                <FormElementRadio
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" ไปรพ. /คลินิก "
                />
                <FormElementRadio
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" ซื้อยารับประทานเอง "
                />
                <FormElementRadio
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=" อื่นๆ"
                />
                <span
                  className={
                    dataForm.data_4 == "3" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"5"}
                    value={dataForm.data_5}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <span>
                  การปฏิบัติตามแผนการรักษา{" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
                <div
                  className="uk-padding-small uk-padding-remove-left"
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <FormElementRadio
                    fieldId={"6"}
                    value={dataForm.data_6}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ปฏิบัติสม่ำเสมอ "
                  />
                  <FormElementRadio
                    fieldId={"6"}
                    value={dataForm.data_6}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" บางครั้ง "
                  />
                  <FormElementRadio
                    fieldId={"6"}
                    value={dataForm.data_6}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label=" ไม่ปฏิบัติ  "
                  />
                </div>
              </div>
            </div>
            <div
              className="uk-padding-small uk-padding-remove-top"
              style={{ width: "50%" }}
            >
              1.4 การรับรู้ต่อความเจ็บป่วยในปัจจุบัน{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"7"}
                  value={dataForm.data_7}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" รุนแรงมาก "
                />
                <FormElementRadio
                  fieldId={"7"}
                  value={dataForm.data_7}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" รุนแรงปานกลาง "
                />
                <FormElementRadio
                  fieldId={"7"}
                  value={dataForm.data_7}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=" ไม่รุนแรง "
                />
              </div>
            </div>
          </div>

          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div
              className="uk-padding-small uk-padding-remove-top"
              style={{ width: "50%" }}
            >
              <span>
                1.5 การได้รับภูมิคุ้มกันโรค{" "}
                <span style={{ color: "red" }}>*</span>
              </span>
              <div className="uk-padding-small">
                <FormElementRadio
                  fieldId={"8"}
                  value={dataForm.data_8}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" No"
                />
                <FormElementRadio
                  fieldId={"8"}
                  value={dataForm.data_8}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" Yes"
                />
                <span
                  className={
                    dataForm.data_8 == "2" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"9"}
                    value={dataForm.data_9}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div
              className="uk-padding-small uk-padding-remove-top"
              style={{ width: "50%" }}
            >
              <span>
                1.6 การใช้สารเสพติด <span style={{ color: "red" }}>*</span>
              </span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                  <FormElementRadio
                    fieldId={"10"}
                    value={dataForm.data_10}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label=" Never "
                  />
                  <FormElementRadio
                    fieldId={"10"}
                    value={dataForm.data_10}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" Yes "
                  />
                </div>
                <div className="uk-width-1-1@muk-width-1-1">
                  <span
                    className={
                      dataForm.data_10 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementCheckBox
                          fieldId={"12"}
                          value={dataForm.data_12}
                          onChange={handleChange}
                          label=" Smoking "
                        />
                        <span
                          className={
                            dataForm.data_12 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementRadio
                              fieldId={"11"}
                              value={dataForm.data_11}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label=" Ex smoking󠄀 "
                            />
                            <span
                              className={
                                dataForm.data_11 == "1"
                                  ? "form-show"
                                  : "form-hidden"
                              }
                            >
                              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                                <strong>
                                  ประเภทของบุหรี่{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </strong>
                              </div>
                              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                                <FormElementRadio
                                  fieldId={"13"}
                                  value={dataForm.data_13}
                                  fieldValue={"1"}
                                  onChange={handleChange}
                                  label=" บุหรี่ซอง "
                                />
                                <FormElementRadio
                                  fieldId={"13"}
                                  value={dataForm.data_13}
                                  fieldValue={"2"}
                                  onChange={handleChange}
                                  label=" บุหรี่ไฟฟ้า "
                                />
                                <FormElementRadio
                                  fieldId={"13"}
                                  value={dataForm.data_13}
                                  fieldValue={"3"}
                                  onChange={handleChange}
                                  label=" ยาเส้น "
                                />
                                <FormElementRadio
                                  fieldId={"13"}
                                  value={dataForm.data_13}
                                  fieldValue={"4"}
                                  onChange={handleChange}
                                  label=" อื่นๆ"
                                />
                                <span
                                  className={
                                    dataForm.data_13 == "4"
                                      ? "form-show"
                                      : "form-hidden"
                                  }
                                >
                                  <FormElementTextBox
                                    fieldId={"14"}
                                    value={dataForm.data_14}
                                    label=""
                                    onChange={handleChange}
                                    width="100"
                                  />
                                </span>
                              </div>
                              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                                <FormElementTextBox
                                  fieldId={"15"}
                                  value={dataForm.data_15}
                                  label="จำนวน"
                                  onChange={handleChange}
                                  width="100"
                                  type="numeric"
                                  isRequired
                                />
                                <span className="formTextBoxLabel">
                                  มวน/วัน
                                </span>
                                <FormElementTextBox
                                  fieldId={"16"}
                                  value={dataForm.data_16}
                                  label="ระยะเวลาที่เลิก"
                                  onChange={handleChange}
                                  width="100"
                                  type="decimal"
                                  isRequired
                                />
                                <span className="formTextBoxLabel">
                                  ปี/เดือน
                                </span>
                              </div>
                            </span>
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementRadio
                              fieldId={"11"}
                              value={dataForm.data_11}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label=" Current smoking "
                            />
                            <span
                              className={
                                dataForm.data_11 == "2"
                                  ? "form-show"
                                  : "form-hidden"
                              }
                            >
                              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                                <strong>
                                  ประเภทของบุหรี่{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </strong>
                              </div>
                              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                                <FormElementRadio
                                  fieldId={"17"}
                                  value={dataForm.data_17}
                                  fieldValue={"1"}
                                  onChange={handleChange}
                                  label=" บุหรี่ซอง "
                                />
                                <FormElementRadio
                                  fieldId={"17"}
                                  value={dataForm.data_17}
                                  fieldValue={"2"}
                                  onChange={handleChange}
                                  label=" บุหรี่ไฟฟ้า "
                                />
                                <FormElementRadio
                                  fieldId={"17"}
                                  value={dataForm.data_17}
                                  fieldValue={"3"}
                                  onChange={handleChange}
                                  label=" ยาเส้น "
                                />
                                <FormElementRadio
                                  fieldId={"17"}
                                  value={dataForm.data_17}
                                  fieldValue={"4"}
                                  onChange={handleChange}
                                  label=" อื่นๆ"
                                />
                                <span
                                  className={
                                    dataForm.data_17 == "4"
                                      ? "form-show"
                                      : "form-hidden"
                                  }
                                >
                                  <FormElementTextBox
                                    fieldId={"18"}
                                    value={dataForm.data_18}
                                    label=""
                                    onChange={handleChange}
                                    width="100"
                                  />
                                </span>
                              </div>
                              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                                <FormElementTextBox
                                  fieldId={"19"}
                                  value={dataForm.data_19}
                                  label="จำนวน"
                                  onChange={handleChange}
                                  width="100"
                                  type="numeric"
                                  isRequired
                                />
                                <span className="formTextBoxLabel">
                                  มวน/วัน
                                </span>
                                <FormElementTextBox
                                  fieldId={"20"}
                                  value={dataForm.data_20}
                                  label="ระยะเวลาที่เลิก"
                                  onChange={handleChange}
                                  width="100"
                                  type="decimal"
                                  isRequired
                                />
                                <span className="formTextBoxLabel">
                                  ปี/เดือน
                                </span>
                              </div>
                            </span>
                          </div>
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementCheckBox
                          fieldId={"21"}
                          value={dataForm.data_21}
                          onChange={handleChange}
                          label=" Alcohol "
                        />
                        <span
                          className={
                            dataForm.data_21 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementTextBox
                              fieldId={"22"}
                              value={dataForm.data_22}
                              label="ประเภท"
                              onChange={handleChange}
                              width="100"
                              isRequired
                            />
                            <FormElementTextBox
                              fieldId={"23"}
                              value={dataForm.data_23}
                              label="ปริมาณที่บริโภค"
                              onChange={handleChange}
                              width="100"
                              isRequired
                            />
                            <FormElementTextBox
                              fieldId={"24"}
                              value={dataForm.data_24}
                              label="ความถี่"
                              onChange={handleChange}
                              width="100"
                              type="numeric"
                              isRequired
                            />
                            <span className="formTextBoxLabel">
                              ครั้ง/สัปดาห์
                            </span>
                          </div>
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementCheckBox
                          fieldId={"25"}
                          value={dataForm.data_25}
                          onChange={handleChange}
                          label=" Addictive substance "
                        />
                        <span
                          className={
                            dataForm.data_25 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementTextBox
                              fieldId={"26"}
                              value={dataForm.data_26}
                              label="ประเภท"
                              onChange={handleChange}
                              width="100"
                              isRequired
                            />
                            <FormElementTextBox
                              fieldId={"27"}
                              value={dataForm.data_27}
                              label="ระยะเวลาที่ใช้"
                              onChange={handleChange}
                              width="100"
                              type="decimal"
                              isRequired
                            />
                            <span className="formTextBoxLabel">ปี</span>
                          </div>
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementCheckBox
                          fieldId={"28"}
                          value={dataForm.data_28}
                          onChange={handleChange}
                          label=" Other "
                        />
                        <span
                          className={
                            dataForm.data_28 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"29"}
                            value={dataForm.data_29}
                            label=""
                            onChange={handleChange}
                            width="100"
                            isRequired
                          />
                          <FormElementTextBox
                            fieldId={"30"}
                            value={dataForm.data_30}
                            label="ระยะเวลาที่ใช้"
                            onChange={handleChange}
                            width="100"
                            type="decimal"
                            isRequired
                          />
                          <span className="formTextBoxLabel">ปี</span>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Health assessment 2 " />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="2. อาหารและการเผาผลาญสารอาหาร " />
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            2.1 แบบแผนการรับประทานอาหาร/การดื่มน้ำ{" "}
            <span style={{ color: "red" }}>*</span>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <span className="formTextBoxLabel">
                  ประเภท <span style={{ color: "red" }}>*</span>
                </span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" อาหารธรรมดา "
                    />
                  </div>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" อาหารอ่อน "
                    />
                  </div>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label=" อาหารเหลว/นม "
                    />
                  </div>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"4"}
                      onChange={handleChange}
                      label=" อาหารเฉพาะโรค"
                    />
                    <span
                      className={
                        dataForm.data_31 == "4" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"32"}
                        value={dataForm.data_32}
                        label="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"5"}
                      onChange={handleChange}
                      label=" อื่นๆ"
                    />
                    <span
                      className={
                        dataForm.data_31 == "5" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"33"}
                        value={dataForm.data_33}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                        <FormElementTextBox
                          fieldId={"34"}
                          value={dataForm.data_34}
                          label="จำนวนมื้อ"
                          onChange={handleChange}
                          width="100"
                          type="numeric"
                          isRequired
                        />
                        <span className="formTextBoxLabel">มื้อ/วัน</span>
                        <FormElementTextBox
                          fieldId={"35"}
                          value={dataForm.data_35}
                          label="ปริมาณต่อมื้อ"
                          onChange={handleChange}
                          width="100"
                          isRequired
                        />
                      </div>
                      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                        <FormElementTextBox
                          fieldId={"36"}
                          value={dataForm.data_36}
                          label="การดื่มน้ำ ปริมาณ"
                          onChange={handleChange}
                          width="100"
                          type="decimal"
                          isRequired
                        />
                        <span className="formTextBoxLabel">ลิตร/วัน</span>
                        <FormElementTextBox
                          fieldId={"37"}
                          value={dataForm.data_37}
                          label="แหล่งที่มาของน้ำดื่ม"
                          onChange={handleChange}
                          width="100"
                          isRequired
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div
              className="uk-padding-small uk-padding-remove-left"
              style={{ width: "50%" }}
            >
              2.2 ปัญหาการรับประทานอาหาร <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <FormElementRadio
                          fieldId={"38"}
                          value={dataForm.data_38}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" No"
                        />
                      </td>
                      <td colSpan={2}>
                        <FormElementRadio
                          fieldId={"38"}
                          value={dataForm.data_38}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" Yes "
                        />
                      </td>
                      <td>
                        <span
                          className={
                            dataForm.data_38 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                            <FormElementCheckBox
                              fieldId={"39"}
                              value={dataForm.data_39}
                              onChange={handleChange}
                              label=" การกลืน "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"40"}
                              value={dataForm.data_40}
                              onChange={handleChange}
                              label=" การเคี้ยว "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"41"}
                              value={dataForm.data_41}
                              onChange={handleChange}
                              label=" เบื่ออาหาร "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"42"}
                              value={dataForm.data_42}
                              onChange={handleChange}
                              label=" คลื่นไส้ "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"43"}
                              value={dataForm.data_43}
                              onChange={handleChange}
                              label=" อาเจียน "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"44"}
                              value={dataForm.data_44}
                              onChange={handleChange}
                              label=" อื่นๆ "
                            />
                            <span
                              className={
                                dataForm.data_44 == "1"
                                  ? "form-show"
                                  : "form-hidden"
                              }
                            >
                              <FormElementTextBox
                                fieldId={"45"}
                                value={dataForm.data_45}
                                label=""
                                onChange={handleChange}
                                width="100"
                              />
                            </span>
                          </div>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="uk-padding-small" style={{ width: "50%" }}>
              2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"46"}
                  value={dataForm.data_46}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" ไม่ทราบ "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"46"}
                  value={dataForm.data_46}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" ไม่มีการเปลี่ยนแปลง "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"46"}
                  value={dataForm.data_46}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=" เปลี่ยนแปลง "
                />
              </div>
              <span
                className={
                  dataForm.data_46 == "3" ? "form-show" : "form-hidden"
                }
              >
                <div
                  className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  style={{ marginLeft: "44px" }}
                >
                  <div>
                    <FormElementRadio
                      fieldId={"47"}
                      value={dataForm.data_47}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" เพิ่มขึ้น"
                    />
                    <span
                      className={
                        dataForm.data_47 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <span style={{ color: "red" }}>*</span>
                      <FormElementTextBox
                        fieldId={"48"}
                        value={dataForm.data_48}
                        label=""
                        onChange={handleChange}
                        width="100"
                        type="decimal"
                        size={5}
                      />
                      <span className="formTextBoxLabel">kg</span>
                      <FormElementTextBox
                        fieldId={"49"}
                        value={dataForm.data_49}
                        label="ในระยะเวลา"
                        onChange={handleChange}
                        width="100"
                        type="decimal"
                        isRequired
                        size={5}
                      />
                      <span className="formTextBoxLabel">เดือน</span>
                    </span>
                  </div>
                </div>
                <div
                  className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  style={{ marginLeft: "44px" }}
                >
                  <FormElementRadio
                    fieldId={"47"}
                    value={dataForm.data_47}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ลดลง"
                  />
                  <span
                    className={
                      dataForm.data_47 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <span style={{ color: "red" }}>*</span>
                    <FormElementTextBox
                      fieldId={"50"}
                      value={dataForm.data_50}
                      label=""
                      onChange={handleChange}
                      width="100"
                      type="decimal"
                      size={5}
                    />
                    <span className="formTextBoxLabel">kg</span>
                    <FormElementTextBox
                      fieldId={"51"}
                      value={dataForm.data_51}
                      label="ในระยะเวลา"
                      onChange={handleChange}
                      width="100"
                      type="decimal"
                      isRequired
                      size={5}
                    />
                    <span className="formTextBoxLabel">เดือน</span>
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div
              className="uk-padding-small uk-padding-remove-left"
              style={{ width: "50%" }}
            >
              2.4 ปัญหาเกี่ยวกับสุขภาพผิวหนัง/เล็บ/ผม{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"52"}
                  value={dataForm.data_52}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" No"
                />
                <FormElementRadio
                  fieldId={"52"}
                  value={dataForm.data_52}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" Yes"
                />
                <span
                  className={
                    dataForm.data_52 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"53"}
                    value={dataForm.data_53}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div className="uk-padding-small" style={{ width: "50%" }}>
              2.5 Nutrition Screening การคัดกรองภาวะโภชนาการ
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementTextBox
                  fieldId={"54"}
                  value={dataForm.data_54}
                  label="BMI"
                  onChange={handleChange}
                  width="100"
                  type="decimal"
                  isRequired
                />
                <span className="formTextBoxLabel">kg/m2</span>
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementCheckBox
                  fieldId={"55"}
                  value={dataForm.data_55}
                  onChange={handleChange}
                  label="Decrease food or nutrient at least 7 days (1) "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementCheckBox
                  fieldId={"56"}
                  value={dataForm.data_56}
                  onChange={handleChange}
                  label="BW reduction during the past 6 months (1) "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementCheckBox
                  fieldId={"57"}
                  value={dataForm.data_57}
                  onChange={handleChange}
                  label="BMI < 18.5 kg/m2 or > 25 kg/m2 (1) "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementCheckBox
                  fieldId={"58"}
                  value={dataForm.data_58}
                  onChange={handleChange}
                  label="Critically ill or intermediate care condition (1)  "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
                <FormElementTextBox
                  fieldId={"59"}
                  value={dataForm.data_59}
                  label="Total score"
                  onChange={handleChange}
                  width="100"
                  disabled
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementRadio
                  fieldId={"60"}
                  value={dataForm.data_60}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="No risk"
                />
                <FormElementRadio
                  fieldId={"60"}
                  value={dataForm.data_60}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Risk > 2 continue for nutrition assessment  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Health assessment 3.4" />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="3. แบบแผนการขับถ่าย" />
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div className="uk-padding-small" style={{ width: "50%" }}>
              3.1
              <FormElementTextBox
                fieldId={"61"}
                value={dataForm.data_61}
                label="ปัสสาวะ"
                onChange={handleChange}
                width="100"
                type="numeric"
                isRequired
              />
              <span className="formTextBoxLabel">ครั้ง/วัน</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementTextBox
                  fieldId={"62"}
                  value={dataForm.data_62}
                  label="ปริมาณ"
                  onChange={handleChange}
                  width="100"
                  type="decimal"
                  isRequired
                />
                <span className="formTextBoxLabel">CC/ครั้ง</span>
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                  <span className="formTextBoxLabel">
                    ลักษณะ <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                      <FormElementRadio
                        fieldId={"63"}
                        value={dataForm.data_63}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ปกติ "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementRadio
                        fieldId={"63"}
                        value={dataForm.data_63}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ผิดปกติ "
                      />
                    </div>
                    <span
                      className={
                        dataForm.data_63 == "2" ? "form-show" : "form-hidden"
                      }
                    >
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"64"}
                          value={dataForm.data_64}
                          onChange={handleChange}
                          label=" ขุ่น "
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"65"}
                          value={dataForm.data_65}
                          onChange={handleChange}
                          label=" แสบขัด "
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"66"}
                          value={dataForm.data_66}
                          onChange={handleChange}
                          label=" กลั้นปัสสาวะไม่ได้ "
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"67"}
                          value={dataForm.data_67}
                          onChange={handleChange}
                          label=" อื่นๆ"
                        />
                        <span
                          className={
                            dataForm.data_67 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"68"}
                            value={dataForm.data_68}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-padding-small" style={{ width: "50%" }}>
              3.2
              <FormElementTextBox
                fieldId={"69"}
                value={dataForm.data_69}
                label="การขับถ่าย"
                onChange={handleChange}
                width="100"
                type="numeric"
                isRequired
              />
              <span className="formTextBoxLabel">วัน/สัปดาห์</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                  <span className="formTextBoxLabel">
                    ลักษณะ <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                      <FormElementRadio
                        fieldId={"70"}
                        value={dataForm.data_70}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ปกติ "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementRadio
                        fieldId={"70"}
                        value={dataForm.data_70}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ผิดปกติ "
                      />
                    </div>
                    <span
                      className={
                        dataForm.data_70 == "2" ? "form-show" : "form-hidden"
                      }
                    >
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"71"}
                          value={dataForm.data_71}
                          onChange={handleChange}
                          label=" ท้องผูก "
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"72"}
                          value={dataForm.data_72}
                          onChange={handleChange}
                          label=" ท้องเสีย "
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"73"}
                          value={dataForm.data_73}
                          onChange={handleChange}
                          label="  กลั้นอุจจาระไม่ได้ "
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"74"}
                          value={dataForm.data_74}
                          onChange={handleChange}
                          label=" ขับถ่ายทางหน้าท้อง"
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"75"}
                          value={dataForm.data_75}
                          onChange={handleChange}
                          label=" ริดสีดวง"
                        />
                      </div>
                      <div
                        className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                        style={{ marginLeft: "44px" }}
                      >
                        <FormElementCheckBox
                          fieldId={"76"}
                          value={dataForm.data_76}
                          onChange={handleChange}
                          label=" อื่นๆ "
                        />
                        <span
                          className={
                            dataForm.data_76 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"77"}
                            value={dataForm.data_77}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div className="uk-padding-small" style={{ width: "50%" }}>
              3.3 อาการเหงื่อออกมากผิดปกติ{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"78"}
                  value={dataForm.data_78}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" No"
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
                <FormElementRadio
                  fieldId={"78"}
                  value={dataForm.data_78}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" Yes"
                />
                <span
                  className={
                    dataForm.data_78 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"79"}
                    value={dataForm.data_79}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
          <FormElementLabel label="4. แบบแผนการพักผ่อนและนอนหลับ (Sleep-rest pattern)" />
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            4.1 การนอนหลับ <span style={{ color: "red" }}>*</span>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementRadio
                fieldId={"80"}
                value={dataForm.data_80}
                fieldValue={"1"}
                onChange={handleChange}
                label=" เพียงพอ "
              />
              <FormElementRadio
                fieldId={"80"}
                value={dataForm.data_80}
                fieldValue={"0"}
                onChange={handleChange}
                label=" ไม่เพียงพอ "
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementTextBox
                fieldId={"81"}
                value={dataForm.data_81}
                label="ปกตินอนวันละ"
                type="decimal"
                onChange={handleChange}
                width="100"
                isRequired
              />
              <span className="formTextBoxLabel">ชม./วัน</span>
              <FormElementTextBox
                fieldId={"82"}
                value={dataForm.data_82}
                label="เวลาเข้านอน"
                onChange={handleChange}
                width="100"
                regEx={/^\d{0,2}(\.\d{0,2})?$/}
                isRequired
              />
              <span className="formTextBoxLabel">น.</span>
              <FormElementTextBox
                fieldId={"83"}
                value={dataForm.data_83}
                label="เวลาตื่น"
                onChange={handleChange}
                width="100"
                regEx={/^\d{0,2}(\.\d{0,2})?$/}
                isRequired
              />
              <span className="formTextBoxLabel">น.</span>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>
                ลักษณะการนอนหลับ <span style={{ color: "red" }}>*</span>
              </strong>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" หลับสนิท "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"84"}
                  value={dataForm.data_84}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" หลับไม่สนิท "
                />
              </div>
              <span
                className={
                  dataForm.data_84 == "2" ? "form-show" : "form-hidden"
                }
              >
                <div
                  className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  style={{ marginLeft: "44px" }}
                >
                  <FormElementRadio
                    fieldId={"85"}
                    value={dataForm.data_85}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ฝัน "
                  />
                </div>
                <div
                  className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  style={{ marginLeft: "44px" }}
                >
                  <FormElementRadio
                    fieldId={"85"}
                    value={dataForm.data_85}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ไม่ฝัน "
                  />
                </div>
              </span>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>
                ปัญหาในการนอนหลับ <span style={{ color: "red" }}>*</span>
              </strong>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"86"}
                  value={dataForm.data_86}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี "
                />
                <span
                  className={
                    dataForm.data_86 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"87"}
                    value={dataForm.data_87}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>
                การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ{" "}
                <span style={{ color: "red" }}>*</span>
              </strong>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"88"}
                  value={dataForm.data_88}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี"
                />
                <span
                  className={
                    dataForm.data_88 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <span style={{ color: "red" }}>*</span>
                  <FormElementTextBox
                    fieldId={"89"}
                    value={dataForm.data_89}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                  <FormElementTextBox
                    fieldId={"90"}
                    value={dataForm.data_90}
                    label="ความถี่ในการใช้"
                    onChange={handleChange}
                    width="100"
                    type="numeric"
                    isRequired
                  />
                  <span className="formTextBoxLabel">วัน/สัปดาห์</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Health assessment 5" />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="5. กิจกรรมและแบบแผนการออกกำลังกาย" />
          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              5.1 การทำกิจวัตรประจำวัน <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="ทำได้"
                />
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="ทำได้บางส่วน"
                />
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label="ทำไม่ได้"
                />
              </div>
            </div>
            <div style={{ width: "50%" }}>
              5.2 แบบแผนการออกกำลังกาย <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" ไม่ออกกำลังกาย "
                />
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" ออกกำลังกายสม่ำเสมอ "
                />
                <FormElementRadio
                  fieldId={"92"}
                  value={dataForm.data_92}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=" ออกกำลังกายแต่ไม่สม่ำเสมอ "
                />
              </div>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementTextBox
                  fieldId={"93"}
                  value={dataForm.data_93}
                  label="วิธีการออกกำลังกาย"
                  onChange={handleChange}
                  width="100"
                  isRequired
                />
              </div>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementTextBox
                  fieldId={"94"}
                  value={dataForm.data_94}
                  label="ระยะเวลาออกกำลังกาย"
                  type="decimal"
                  onChange={handleChange}
                  width="100"
                  isRequired
                />
                <span className="formTextBoxLabel">นาที/ครั้ง</span>
              </div>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                <FormElementTextBox
                  fieldId={"95"}
                  value={dataForm.data_95}
                  label="ความถี่"
                  type="numeric"
                  onChange={handleChange}
                  width="100"
                  isRequired
                />
                <span className="formTextBoxLabel">ครั้ง/สัปดาห์</span>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"></div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            5.3 ความสามารถในการช่วยเหลือตนเองในกิจวัตรประจำวัน
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                <thead>
                  <tr>
                    <th rowSpan={2}>
                      ความสามารถในการช่วยเหลือตนเองในกิจวัตรประจำวัน
                    </th>
                    <th colSpan={5}>ระดับความสามารถในการช่วยเหลือตนเอง</th>
                  </tr>
                  <tr>
                    <th>0</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      1. การเข้าห้องน้ำ <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"96"}
                        value={dataForm.data_96}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"96"}
                        value={dataForm.data_96}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"96"}
                        value={dataForm.data_96}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"96"}
                        value={dataForm.data_96}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"96"}
                        value={dataForm.data_96}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      2. การอาบน้ำ <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"97"}
                        value={dataForm.data_97}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"97"}
                        value={dataForm.data_97}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"97"}
                        value={dataForm.data_97}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"97"}
                        value={dataForm.data_97}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"97"}
                        value={dataForm.data_97}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      3. การรับประทานอาหาร{" "}
                      <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"98"}
                        value={dataForm.data_98}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"98"}
                        value={dataForm.data_98}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"98"}
                        value={dataForm.data_98}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"98"}
                        value={dataForm.data_98}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"98"}
                        value={dataForm.data_98}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      4. การเคลื่อนไหวทั่วไป{" "}
                      <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"99"}
                        value={dataForm.data_99}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"99"}
                        value={dataForm.data_99}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"99"}
                        value={dataForm.data_99}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"99"}
                        value={dataForm.data_99}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"99"}
                        value={dataForm.data_99}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      5. การแต่งตัว <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"100"}
                        value={dataForm.data_100}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"100"}
                        value={dataForm.data_100}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"100"}
                        value={dataForm.data_100}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"100"}
                        value={dataForm.data_100}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"100"}
                        value={dataForm.data_100}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      6. การหวีผม การทำความสะอาดปากและฟัน{" "}
                      <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"101"}
                        value={dataForm.data_101}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"101"}
                        value={dataForm.data_101}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"101"}
                        value={dataForm.data_101}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"101"}
                        value={dataForm.data_101}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"101"}
                        value={dataForm.data_101}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      7. การซื้อของ/ทำธุระนอกบ้าน{" "}
                      <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"102"}
                        value={dataForm.data_102}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"102"}
                        value={dataForm.data_102}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"102"}
                        value={dataForm.data_102}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"102"}
                        value={dataForm.data_102}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"102"}
                        value={dataForm.data_102}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      8. การเคลื่อนไหวบนเตียงกรณีผู้ป่วยติดเตียง{" "}
                      <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"103"}
                        value={dataForm.data_103}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"103"}
                        value={dataForm.data_103}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"103"}
                        value={dataForm.data_103}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"103"}
                        value={dataForm.data_103}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"103"}
                        value={dataForm.data_103}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=""
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel label="ระดับความสามารถในการทำกิจกรรม" />
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
                ระดับ 0 : ทำได้ด้วยตนเอง
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                ระดับ 1 : ต้องใช้อุปกรณ์ช่วยเหลือ เช่น ในผู้สูงอายุที่ลุกได้เอง
                แต่เวลาเดินต้องใช้ไม้เท้า
                หรือในผู้รับบริการหรือผู้ป่วยจิตเวชที่ต้องเตือนให้ทำ
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                ระดับ 2 : ต้องการการช่วยเหลือ
                หรือต้องการคำแนะนำจากผู้อื่นแล้วทำเองต่อได้ เช่น
                ต้องมีผู้ช่วยพยุงขึ้นจากเตียงแล้วลุกเดินได้หรือในผู้รับบริการที่ต้องมีผู้คอยแนะนำหรือบอกในการทำกิจกรรม
                จากนั้นทำเองได้
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                ระดับ 3 : ต้องการความช่วยเหลือจากบุคคลอื่น ร่วมกับใช้อุปกรณ์ช่วย
                เช่น ผู้ที่เป็นอัมพาตซีกด้านซ้าย ต้องการผู้ช่วยพยุงข้างซ้าย
                และใช้ไม้เท้าช่วยเดิน หรือในผู้รับบริการที่ต้องมีคนคอยดูแลให้ทำ
                กิจกรรมตลอดเวลา
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
                ระดับ 4 : ช่วยเหลือตัวเองไม่ได้เลย หรือไม่สามารถทำเองได้
                ต้องให้ผู้อื่นช่วยทำกิจกรรมให้ตลอดเวลา
              </div>
            </div>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            5.4 ปัญหาในการเคลื่อนไหว <span style={{ color: "red" }}>*</span>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementRadio
                fieldId={"104"}
                value={dataForm.data_104}
                fieldValue={"0"}
                onChange={handleChange}
                label=" ไม่มี "
              />
              <FormElementRadio
                fieldId={"104"}
                value={dataForm.data_104}
                fieldValue={"1"}
                onChange={handleChange}
                label=" มี"
              />
              <span
                className={
                  dataForm.data_104 == "1" ? "form-show" : "form-hidden"
                }
              >
                <FormElementTextBox
                  fieldId={"105"}
                  value={dataForm.data_105}
                  label="ระบุ"
                  onChange={handleChange}
                  width="100"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Health assessment 6, 7" />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="6. แบบแผนการรู้คิด การรับรู้ การสื่อสาร" />
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            6.1 การรู้คิด (Cognition)
            <div
              className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
              style={{ display: "inline-flex", flexWrap: "nowrap" }}
            >
              <div style={{ width: "50%" }}>
                1. ระดับความรู้สึกตัว (Consciousness){" "}
                <span style={{ color: "red" }}>*</span>
                <div
                  className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <FormElementRadio
                    fieldId={"106"}
                    value={dataForm.data_106}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" Alert (ภาวะการรู้ตัวปกติ) "
                  />
                  <FormElementRadio
                    fieldId={"106"}
                    value={dataForm.data_106}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" Confused (สับสน) "
                  />
                  <FormElementRadio
                    fieldId={"106"}
                    value={dataForm.data_106}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label=" Drowsiness (ซึม)  "
                  />
                  <FormElementRadio
                    fieldId={"106"}
                    value={dataForm.data_106}
                    fieldValue={"4"}
                    onChange={handleChange}
                    label=" Stuporous (ภาวะซึมมากหลับลึก) "
                  />
                  <FormElementRadio
                    fieldId={"106"}
                    value={dataForm.data_106}
                    fieldValue={"5"}
                    onChange={handleChange}
                    label=" Coma (ภาวะหมดสติ) "
                  />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                2. ความจำ (Memory) <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"107"}
                    value={dataForm.data_107}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ปกติ "
                  />
                  <FormElementRadio
                    fieldId={"107"}
                    value={dataForm.data_107}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ผิดปกติ "
                  />
                  <span
                    className={
                      dataForm.data_107 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"108"}
                      value={dataForm.data_108}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"></div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              3. Orientation <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                - การรับรู้เวลา <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"109"}
                    value={dataForm.data_109}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ปกติ "
                  />
                  <FormElementRadio
                    fieldId={"109"}
                    value={dataForm.data_109}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ผิดปกติ "
                  />
                  <span
                    className={
                      dataForm.data_109 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"110"}
                      value={dataForm.data_110}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                - การรับรู้สถานที่ <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"111"}
                    value={dataForm.data_111}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ปกติ "
                  />
                  <FormElementRadio
                    fieldId={"111"}
                    value={dataForm.data_111}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ผิดปกติ "
                  />
                  <span
                    className={
                      dataForm.data_111 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"112"}
                      value={dataForm.data_112}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                - การรับรู้บุคคล <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"113"}
                    value={dataForm.data_113}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ปกติ "
                  />
                  <FormElementRadio
                    fieldId={"113"}
                    value={dataForm.data_113}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ผิดปกติ "
                  />
                  <span
                    className={
                      dataForm.data_113 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"114"}
                      value={dataForm.data_114}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            6.2 การรับรู้ (Perception)
            <div
              className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
              style={{ display: "inline-flex", flexWrap: "nowrap" }}
            >
              <div style={{ width: "50%" }}>
                <div className="uk-width-1-1@m uk-width-1-1">
                  1. การได้ยิน <span style={{ color: "red" }}>*</span>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"115"}
                      value={dataForm.data_115}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ปกติ "
                    />
                    <FormElementRadio
                      fieldId={"115"}
                      value={dataForm.data_115}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" ผิดปกติ  "
                    />
                    <span
                      className={
                        dataForm.data_115 == "2" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"116"}
                        value={dataForm.data_116}
                        label="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="uk-width-1-1@muk-width-1-1">
                  2. การมองเห็น <span style={{ color: "red" }}>*</span>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"117"}
                      value={dataForm.data_117}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ปกติ "
                    />
                    <FormElementRadio
                      fieldId={"117"}
                      value={dataForm.data_117}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" ผิดปกติ  "
                    />
                    <span
                      className={
                        dataForm.data_117 == "2" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"118"}
                        value={dataForm.data_118}
                        label="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
              style={{ display: "inline-flex", flexWrap: "nowrap" }}
            >
              <div style={{ width: "50%" }}>
                3. การรับกลิ่น <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"119"}
                    value={dataForm.data_119}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ปกติ "
                  />
                  <FormElementRadio
                    fieldId={"119"}
                    value={dataForm.data_119}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ผิดปกติ  "
                  />
                  <span
                    className={
                      dataForm.data_119 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"120"}
                      value={dataForm.data_120}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
              <div style={{ width: "50%" }}>
                4. การรับรส <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"121"}
                    value={dataForm.data_121}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ปกติ "
                  />
                  <FormElementRadio
                    fieldId={"121"}
                    value={dataForm.data_121}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ผิดปกติ  "
                  />
                  <span
                    className={
                      dataForm.data_121 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"122"}
                      value={dataForm.data_122}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              5. การสัมผัส <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
                <FormElementRadio
                  fieldId={"123"}
                  value={dataForm.data_123}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" ปกติ "
                />
                <FormElementRadio
                  fieldId={"123"}
                  value={dataForm.data_123}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" ผิดปกติ "
                />
                <span
                  className={
                    dataForm.data_123 == "2" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"124"}
                    value={dataForm.data_124}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="7. การรับรู้ตนเองและอัตมโนทัศน์" />
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            สิ่งที่มีผลกระทบต่อตนเองและอัตมโนทัศน์{" "}
            <span style={{ color: "red" }}>*</span>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementRadio
                fieldId={"125"}
                value={dataForm.data_125}
                fieldValue={"1"}
                onChange={handleChange}
                label=" ลักษณะรูปร่างหน้าตา "
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementRadio
                fieldId={"125"}
                value={dataForm.data_125}
                fieldValue={"2"}
                onChange={handleChange}
                label=" อารมณ์/ความรู้สึก "
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementRadio
                fieldId={"125"}
                value={dataForm.data_125}
                fieldValue={"3"}
                onChange={handleChange}
                label=" การรับรู้ความสามารถของตนเอง "
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementRadio
                fieldId={"125"}
                value={dataForm.data_125}
                fieldValue={"4"}
                onChange={handleChange}
                label=" อื่น ๆ "
              />
              <span
                className={
                  dataForm.data_125 == "4" ? "form-show" : "form-hidden"
                }
              >
                <FormElementTextBox
                  fieldId={"126"}
                  value={dataForm.data_126}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Health assessment 8, 9" />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="8. บทบาทและสัมพันธภาพ" />
          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              ผลกระทบของความเจ็บป่วยครั้งนี้
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                - ต่อโครงสร้าง บทบาท และสัมพันธภาพของครอบครัว{" "}
                <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"127"}
                    value={dataForm.data_127}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label=" ไม่มี "
                  />
                  <FormElementRadio
                    fieldId={"127"}
                    value={dataForm.data_127}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" มี  "
                  />
                  <div
                    className={
                      dataForm.data_127 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"128"}
                      value={dataForm.data_128}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                - ต่อสถานภาพ บทบาท และสัมพันธภาพในสังคม{" "}
                <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"129"}
                    value={dataForm.data_129}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label=" ไม่มี "
                  />
                  <FormElementRadio
                    fieldId={"129"}
                    value={dataForm.data_129}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" มี  "
                  />
                  <span
                    className={
                      dataForm.data_129 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"130"}
                      value={dataForm.data_130}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                บุคคลและแหล่งที่ให้ความช่วยเหลือผู้ป่วย{" "}
                <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"131"}
                    value={dataForm.data_131}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label=" ไม่มี "
                  />
                  <FormElementRadio
                    fieldId={"131"}
                    value={dataForm.data_131}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" มี  "
                  />
                  <span
                    className={
                      dataForm.data_131 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"132"}
                      value={dataForm.data_132}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                สิ่งที่เป็นอุปสรรคต่อการสร้างสัมพันธภาพ{" "}
                <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"133"}
                    value={dataForm.data_133}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label=" ไม่มี "
                  />
                  <FormElementRadio
                    fieldId={"133"}
                    value={dataForm.data_133}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" มี  "
                  />
                  <span
                    className={
                      dataForm.data_133 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"134"}
                      value={dataForm.data_134}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="9. เพศและการเจริญพันธุ์" />
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              การคุมกำเนิด <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"135"}
                  value={dataForm.data_135}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"135"}
                  value={dataForm.data_135}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี "
                />
                <span
                  className={
                    dataForm.data_135 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"136"}
                    value={dataForm.data_136}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              ปัญหาเกี่ยวกับอวัยวะสืบพันธุ์{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"137"}
                  value={dataForm.data_137}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"137"}
                  value={dataForm.data_137}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี  "
                />
                <span
                  className={
                    dataForm.data_137 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"138"}
                    value={dataForm.data_138}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              พฤติกรรมที่แสดงออกสัมพันธ์กับเพศสภาพ{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"139"}
                  value={dataForm.data_139}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" สัมพันธ์ "
                />
                <FormElementRadio
                  fieldId={"139"}
                  value={dataForm.data_139}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" ไม่สัมพันธ์  "
                />
                <span
                  className={
                    dataForm.data_139 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"140"}
                    value={dataForm.data_140}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              เป็นผู้ป่วยเพศหญิง <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"141"}
                  value={dataForm.data_141}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่ใช่ "
                />
                <FormElementRadio
                  fieldId={"141"}
                  value={dataForm.data_141}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" ใช่ "
                />
              </div>
              <div
                className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                hidden={dataForm.data_141 != "1"}
              >
                ประวัติการมีประจำเดือน
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <span className="formTextBoxLabel">
                    ประจำเดือนมาครั้งสุดท้าย วันที่{" "}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  {/* <FormElementDatePicker
                  fieldId="142"
                  value={dataForm.data_142}
                  onChange={handleChange}
                /> */}
                  <FormElementDateTextBox
                    value={dataForm.data_142}
                    onChange={(v) =>
                      setDataForm((data) => ({ ...data, data_142: v }))
                    }
                  />
                  {/* <FormElementTextBox
                  fieldId={"142"}
                  type="date"
                  value={dataForm.data_142}
                  label=""
                  onChange={handleChange}
                  width="100"
                /> */}
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementTextBox
                    fieldId={"143"}
                    value={dataForm.data_143}
                    label="Duration/cycle"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <span className="formTextBoxLabel">
                    Length of cycle <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementRadio
                      fieldId={"144"}
                      value={dataForm.data_144}
                      fieldValue={"0"}
                      onChange={handleChange}
                      label=" Irregular ไม่สม่ำเสมอ "
                    />
                  </div>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
                    <FormElementRadio
                      fieldId={"144"}
                      value={dataForm.data_144}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" Regular; สม่ำเสมอ  "
                    />
                    <span
                      className={
                        dataForm.data_144 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"145"}
                        value={dataForm.data_145}
                        label="/ ทุก every"
                        onChange={handleChange}
                        type="numeric"
                        width="100"
                      />
                      <span className="formTextBoxLabel">day(s) วัน</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Health assessment 10,11" />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="10. การปรับตัวและการทนทานต่อความเครียด" />
          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              การเจ็บป่วยครั้งนี้ส่งผลกระทบต่อจิตใจ/เครียด/กังวล{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"146"}
                  value={dataForm.data_146}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่ส่งผล "
                />
                <FormElementRadio
                  fieldId={"146"}
                  value={dataForm.data_146}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" ส่งผล "
                />
                <span
                  className={
                    dataForm.data_146 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"147"}
                    value={dataForm.data_147}
                    label="ระบุ "
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              ปัจจัยเสริมที่ทำให้เกิดความไม่สบายใจขณะเจ็บป่วย{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"148"}
                  value={dataForm.data_148}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"148"}
                  value={dataForm.data_148}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี "
                />
                <span
                  className={
                    dataForm.data_148 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"149"}
                    value={dataForm.data_149}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              บุคคลหรือแหล่งที่ให้ความช่วยเหลือเวลามีปัญหาหรือไม่สบายใจ{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"150"}
                  value={dataForm.data_150}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"150"}
                  value={dataForm.data_150}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี  "
                />
                <span
                  className={
                    dataForm.data_150 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"151"}
                    value={dataForm.data_151}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              การจัดการเมื่อเกิดความเครียด/กังวล{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"152"}
                  value={dataForm.data_152}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" แยกตัว "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"152"}
                  value={dataForm.data_152}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" พูดคุยกับบุคคลอื่น "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"152"}
                  value={dataForm.data_152}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=" ใช้ยา "
                />
              </div>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
                <FormElementRadio
                  fieldId={"152"}
                  value={dataForm.data_152}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=" อื่น ๆ"
                />
                <span
                  className={
                    dataForm.data_152 == "4" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"153"}
                    value={dataForm.data_153}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel label="11. ค่านิยมและความเชื่อ" />
          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              นับถือศาสนา <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"154"}
                  value={dataForm.data_154}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" พุทธ "
                />
                <FormElementRadio
                  fieldId={"154"}
                  value={dataForm.data_154}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=" คริสต์ "
                />
                <FormElementRadio
                  fieldId={"154"}
                  value={dataForm.data_154}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=" อิสลาม "
                />
                <FormElementRadio
                  fieldId={"154"}
                  value={dataForm.data_154}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=" อื่น ๆ"
                />
                <span
                  className={
                    dataForm.data_154 == "4" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"155"}
                    value={dataForm.data_155}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                ผู้ป่วยเชื่อว่าการเจ็บป่วยในครั้งนี้เกิดจาก{" "}
                <span style={{ color: "red" }}>*</span>&nbsp;&nbsp;&nbsp;
                <FormElementTextBox
                  fieldId={"156"}
                  value={dataForm.data_156}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </div>
            </div>
          </div>

          <div
            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ display: "inline-flex", flexWrap: "nowrap" }}
          >
            <div style={{ width: "50%" }}>
              สิ่งที่ต้องการ/มีค่ามากที่สุดในชีวิต{" "}
              <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"157"}
                  value={dataForm.data_157}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"157"}
                  value={dataForm.data_157}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี "
                />
                <span
                  className={
                    dataForm.data_157 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"158"}
                    value={dataForm.data_158}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              สิ่งยึดเหนี่ยวในชีวิต <span style={{ color: "red" }}>*</span>
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                <FormElementRadio
                  fieldId={"159"}
                  value={dataForm.data_159}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=" ไม่มี "
                />
                <FormElementRadio
                  fieldId={"159"}
                  value={dataForm.data_159}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" มี  "
                />
                <span
                  className={
                    dataForm.data_159 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"160"}
                    value={dataForm.data_160}
                    label="ระบุ"
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            ผลของการอยู่โรงพยาบาลต่อการปฏิบัติศาสนกิจ{" "}
            <span style={{ color: "red" }}>*</span>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementRadio
                fieldId={"161"}
                value={dataForm.data_161}
                fieldValue={"0"}
                onChange={handleChange}
                label=" ไม่มี "
              />
              <FormElementRadio
                fieldId={"161"}
                value={dataForm.data_161}
                fieldValue={"1"}
                onChange={handleChange}
                label=" มี  "
              />
              <span
                className={
                  dataForm.data_161 == "1" ? "form-show" : "form-hidden"
                }
              >
                <FormElementTextBox
                  fieldId={"162"}
                  value={dataForm.data_162}
                  label="ระบุ"
                  onChange={handleChange}
                  width="100"
                />
              </span>
            </div>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
            <span hidden={!dataForm.form_id}>
              <FormElementTextArea
                fieldId={"164"}
                rows={5}
                cols={100}
                value={dataForm.data_164}
                noLimit
                onChange={handleChange}
                label="เหตุผลในการแก้ไข"
                width="100"
              />
            </span>
          </div>
        </div>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={
          <FormNursingAssessmentPaper
            dataForm={dataForm}
            patient={patient}
            profile={profile}
          />
        }
      />
    </div>
  );
};

export default FormNursingAssessment;
