import Api from "../../Components/Api";

async function fetchConsultFormListBySearch(hn, type, search) {
    try {
        var result = await Api.consult("ConsultFormGetList", {
            formType: type,
            hn: hn,
            dateStart: search.startDate,
            dateStop: search.endDate,
            data_no: search.seqNo,
        });
        var { list } = result;

        if (!list) return [];

        return list;
    } catch (error) {
        console.error(`fetchConsultFormListBySearch: hn(${hn}), type(${type}), search(${search})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchConsultFormListBySearch;