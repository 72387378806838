import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const FormDrugAdherencePaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_drugadherence",
      formType: "37",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
    ]);
  }, [dataForm]);

  const getScoreDesc = useMemo(() => {
    if (getTotalScore >= 4) {
      return "1";
    } else if (getTotalScore >= 2 && getTotalScore <= 3) {
      return "2";
    } else {
      return "3";
    }
  }, [dataForm.data_1n, dataForm.data_2n, dataForm.data_3n, dataForm.data_4n]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="Drug adherence" mode="view" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={memberProfile} />
          </div>
          <FormElementTitle
            label="4-item Morisky Green Levine Medication Adherence Scale"
            mode="view"
          />
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คำถาม
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ใช่ (0 คะแนน)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ไม่ใช่ (1 คะแนน)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คุณเคยลืมใช้ยาควบคุมโรคประจำตัว
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "1" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คุณเคยรู้สึกไม่ใส่ใจกับการใช้ยาควบคุมโรคประจำตัว
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "1" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เมื่อรู้สึกว่าโรคประจำตัวควบคุมได้ดี บางครั้งคุณหยุดใช้ยา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "1" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เมื่อรู้สึกไม่ดีเกี่ยวกับการทานยาควบคุมโรคประจำตัว
                  คุณหยุดใช้ยาหรือไม่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "1" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนนรวมทั้งหมด
                </td>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getTotalScore}/4
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{
              display: "inline-flex",
              gap: "8px",
              paddingLeft: "16px",
              marginTop: "8px",
            }}
          >
            <strong>การแปลผล</strong>
            <FormElementLabel
              value={getScoreDesc}
              translate={{
                1: "สม่ำเสมอดี (4 คะแนน)",
                2: "ปานกลาง (2-3 คะแนน)",
                3: "ไม่สม่ำเสมอ (0-1 คะแนน)",
              }}
              mode="view"
            />
          </div>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormDrugAdherencePaper;
