import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import HeaderSub from '../../Components/Member/HeaderSub';
import numeral from 'numeral';
import ImageUri from '../../Components/ImageUri';
import  moment from 'moment';

UIkit.use(Icons);

export default class Smc extends Component {
  state = {
    step : 1,
  }
  
  componentDidMount () {

  }

  step1Render = () => {
    return <div>
      <h1>ยืนยันตัวตน</h1>
        <div>เลข HN *</div>
        <div>(HN Number)</div>
        <div>
          <input type="text" />
        </div>
        <div>วันเดือนปีเกิด *</div>
        <div>
          <input type="date" />
        </div>
        <button type="submit"><i uk-icon="user"></i> ยืนยันตัวตน</button>
    </div>
  }

  stepRender = () => {
    return this[`step${this.state.step}Render`]();
  }

  render() {
    return <div className="uk-padding">
      <form>
        {
          this.stepRender()
        }
      </form>
    </div>
  }
};
