import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import DateTimePicker from '../../Components/DatePicker.js';
import HeaderSub from '../../Components/Member/HeaderSub.js';
UIkit.use(Icons);

export default class HealthSummary extends Component {
  state = {
    amountMember: 0,
    amountMemberExpire: 0,
    amountDiabetes: 0,
    amountSysDias: 0,
    amountNormal: 0,
    avgHba1c: 0,
    avgBmi: 0,
    bloodsugarBelow70: 0,
    avgBloodsugarBelow70: 0,
    bloodsugarMore250: 0,
    avgBloodsugarMore250: 0,
    amountTimeinrange: 0,
    avgTimeinrange: 0,
    avgBw: 0,
    avgLdl: 0,
    avgBwDecrease: 0,
    avgFatDecrease: 0,
    dateStart: moment().subtract(7, 'month').startOf('d'),
    dateStop: moment().endOf('d'),
  }

   memberSummaryDataGet = async () => {
    var _response = await Api.member('MemberSummaryDataGet', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD HH:mm:ss'),
      dateStop: moment(this.state.dateStop).format('YYYY-MM-DD HH:mm:ss')
    });


    var _data_diabetes = {
      hba1c: {
        value: 0,
        amount: 0
      },
      bmi: {
        value: 0,
        amount: 0
      },
      bloodsugar: {
        value: 0,
        amount: 0
      },
      bloodsugar54: {
        value: 0,
        amount: 0,
      },
      bloodsugar70: {
        value: 0,
        amount: 0,
      },
      bloodsugar250: {
        value: 0,
        amount: 0,
      },
      bloodsugarnormal: {
        value: 0,
        amount: 0,
      },
      bloodsugarnormal70_180: {
        value: 0,
        amount: 0,
      },
      bloodsugar180_250: {
        value: 0,
        amount: 0,
      },
      bloodsugarnormal: {
        value: 0,
        amount: 0
      },
      weight: {
        value: 0,
        amount: 0
      },
      ldl: {
        value: 0,
        amount: 0
      }
    };

    var _data_obesity = {
      weight: {},
      fat: {}
    }


    var _start_month = moment(this.state.dateStart);
    var _stop_month  = moment(this.state.dateStop);
    for(var i =_start_month; i<_stop_month; i=i.add(1, 'month')) {
      var _month = i.format('MM-YY');

      for(var obesity in _data_obesity) {

        if(!_data_obesity[obesity][_month]) _data_obesity[obesity][_month] = {
          amount: 0,
          value: 0,
        };
      }

    }

    if(!_response.data) _response.data = [];

    _response.data.forEach(function(data) {
      
      data.value = +data.value;

      switch(data.name) {
        case 'hb1ac' : case 'bmi' : case 'weight' : case 'ldl' :
          if(!isNaN(data.value)) {
            _data_diabetes[data.name].value += data.value;
            _data_diabetes[data.name].amount++;
          }
        break;
        case 'fbs' : case 'fbs/fpg' :
          if(!isNaN(data.value)) {
            _data_diabetes.bloodsugar.value += data.value;
            _data_diabetes.bloodsugar.amount++;
            
            

            if (data.value < 70) {
              if (data.value < 54) {
                _data_diabetes.bloodsugar54.value += data.value;
                _data_diabetes.bloodsugar54.amount++;
              }

              _data_diabetes.bloodsugar70.value += data.value;
              _data_diabetes.bloodsugar70.amount++;
              
            } else if (data.value > 180) {

              if (data.value <= 250) {
              
                _data_diabetes.bloodsugar180_250.value += data.value;
                _data_diabetes.bloodsugar180_250.amount++;
              } else if (data.value > 250) {
                _data_diabetes.bloodsugar250.value += data.value;
                _data_diabetes.bloodsugar250.amount++;
              }
            } else {
              _data_diabetes.bloodsugarnormal.value += data.value;
              _data_diabetes.bloodsugarnormal.amount++;

              if(data.value >= 70 && data.value <= 180) {
                _data_diabetes.bloodsugarnormal70_180.value += data.value;
                _data_diabetes.bloodsugarnormal70_180.amount++;

              }
            }
          }

          

        break;
        default:
      };

      

      if(data.name === 'weight' || data.name === 'fat') {
        var _month_format = moment(data.dateadd, 'YYYY-MM-DD HH:mm:ss').format('MM-YY');
        
          if(!isNaN(data.value) && _data_obesity[data.name][_month_format]) {
            _data_obesity[data.name][_month_format].amount++;
            _data_obesity[data.name][_month_format].value += data.value;
          }
      }
      
    });

    for(var key in _data_diabetes) {
      if(_data_diabetes[key].amount != 0) {
        if(_data_diabetes['bloodsugar'].amount > 0 
        &&
        ['bloodsugar54','bloodsugar70', 'bloodsugarnormal',  'bloodsugarnormal70_180', 'bloodsugar180_250', 'bloodsugar250'].includes(key) === true
        
        ) {
    

          _data_diabetes[key].avg = ((_data_diabetes[key].amount/_data_diabetes['bloodsugar'].amount)*100).toFixed(2);
        } else {
          _data_diabetes[key].avg = (_data_diabetes[key].value/_data_diabetes[key].amount).toFixed(2);
        }
      } else {
        _data_diabetes[key].avg = '-';
      }
    }


    var _avg_obesity = {};

    for(var obesity in _data_obesity) {
      var _avg_percent_lastmonth = '-';

      if(!_avg_obesity[obesity]) _avg_obesity[obesity] = {
        value: 0,
        amount: 0
      };

      for(var month in _data_obesity[obesity]) {
        if(_data_obesity[obesity][month].amount != 0) {
          _data_obesity[obesity][month].avg = +(_data_obesity[obesity][month].value/_data_obesity[obesity][month].amount).toFixed(2);
        } else {
          _data_obesity[obesity][month].avg = '-';
        }

        if(_avg_percent_lastmonth !== '-' && _data_obesity[obesity][month].avg !== '-') {
          _data_obesity[obesity][month].percent = ((_data_obesity[obesity][month].avg - _avg_percent_lastmonth) / _avg_percent_lastmonth) *100;

          _avg_obesity[obesity].value +=  _data_obesity[obesity][month].percent;
          _avg_obesity[obesity].amount++;
        } else {
          _data_obesity[obesity][month].percent = '-';
        }

        _avg_percent_lastmonth = _data_obesity[obesity][month].avg ;
      }

    }

    
    for(var obesity in _avg_obesity) {
      if(_avg_obesity[obesity].amount !== 0) {

        _avg_obesity[obesity].avg = (_avg_obesity[obesity].value/_avg_obesity[obesity].amount).toFixed(2);
      } else {

        _avg_obesity[obesity].avg = '-';
      }
    }

    
    this.setState({
      avgHba1c: _data_diabetes.hba1c.avg,
      avgBmi: _data_diabetes.bmi.avg,
      bloodsugarBelow54: _data_diabetes.bloodsugar54.amount,
      avgBloodsugarBelow54: _data_diabetes.bloodsugar54.avg,
      bloodsugarBelow70: _data_diabetes.bloodsugar70.amount,
      avgBloodsugarBelow70: _data_diabetes.bloodsugar70.avg,
      bloodsugarMore180_250: _data_diabetes.bloodsugar180_250.amount,
      avgBloodsugarMore180_250: _data_diabetes.bloodsugar180_250.avg,
      bloodsugarMore250: _data_diabetes.bloodsugar250.amount,
      avgBloodsugarMore250: _data_diabetes.bloodsugar250.avg,
      amountTimeinrange: _data_diabetes.bloodsugarnormal.amount,
      avgTimeinrange: _data_diabetes.bloodsugarnormal.avg,
      amountTimeinrange70_180: _data_diabetes.bloodsugarnormal70_180.amount,
      avgTimeinrange70_180: _data_diabetes.bloodsugarnormal70_180.avg,
      avgBw: _data_diabetes.weight.avg,
      avgLdl: _data_diabetes.ldl.avg,
      avgBwDecrease: _avg_obesity.weight.avg,
      avgFatDecrease: _avg_obesity.fat.avg
    })
   }



   componentDidMount() {
     this.memberSummaryDataGet(); 
   }

  render() {
    return <div className="uk-padding">
    <HeaderSub title="ตารางสรุปผลสุขภาพ" />
    <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">ตารางสรุปผลสุขภาพ
      </div>
      <div uk-grid="">
          <div className="uk-width-auto@m uk-width-1-1">
              <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value)=>this.setState({dateStart: value})}/>
          </div>
          <div className="uk-width-auto@m uk-width-1-1">
              <DateTimePicker id="dateStop" date={this.state.dateStop} callback={(value)=>this.setState({dateStop: value})} />
          </div>
        <div className="uk-width-expand@m uk-width-1-1">
          <button className="uk-button uk-button-primary uk-width-1-1 uk-width-auto@m" onClick={this.memberSummaryDataGet}>ค้นหา</button>
        </div>
      </div>
      <hr />
      <div className="uk-child-width-1-1 uk-child-width-1-2@m" uk-grid="">
        <div>
          <table className="uk-table uk-table-divider uk-table-striped uk-width-1-1 uk-table-small">
            <thead>
              <tr>
                <th colSpan="100%">
                เบาหวาน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Average HbA1c all pt in clinic</td>
                <td></td>
                <td>{this.state.avgHba1c}</td>
              </tr>
              <tr>
                <td>Average Bmi</td>
                <td></td>
                <td>{this.state.avgBmi}</td>
              </tr>
              <tr>
                <td>Event of blood sugar below 54 (very low)</td>
                <td>{this.state.bloodsugarBelow54} ครั้ง</td>
                <td>{this.state.avgBloodsugarBelow54} %</td>
              </tr>
              <tr>
                <td>Event of blood sugar below 70</td>
                <td>{this.state.bloodsugarBelow70} ครั้ง</td>
                <td>{this.state.avgBloodsugarBelow70} %</td>
              </tr>
              <tr>
                <td>Average time in range of patient</td>
                <td>{this.state.amountTimeinrange} ครั้ง</td>
                <td>{this.state.avgTimeinrange} % </td>
              </tr>
              <tr>
                <td>Average time in range of patient (70-180)</td>
                <td>{this.state.amountTimeinrange70_180} ครั้ง</td>
                <td>{this.state.avgTimeinrange70_180} % </td>
              </tr>
              <tr>
                <td>Event of blood sugar more than 180-250 (High)</td>
                <td>{this.state.bloodsugarMore180_250} ครั้ง</td>
                <td>{this.state.avgBloodsugarMore180_250} %</td>
              </tr>
              <tr>
                <td>Event of blood sugar more than 250</td>
                <td>{this.state.bloodsugarMore250} ครั้ง</td>
                <td>{this.state.avgBloodsugarMore250} %</td>
              </tr>
              <tr>
                <td>Average bw per month</td>
                <td></td>
                <td>{this.state.avgBw} Kg</td>
              </tr>
              <tr>
                <td>Average LDL</td>
                <td></td>
                <td>{this.state.avgLdl} mg/dL</td>
              </tr>
            </tbody>
            </table>
        </div>
        <div>
          <table className="uk-table uk-table-divider uk-table-striped uk-width-1-1 uk-table-small">

            <thead>
              <tr>
                <th colSpan="100%">
                Obesity
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Average bw decrease per month</td>
                <td>{this.state.avgBwDecrease}</td>
              </tr>
              <tr>
                <td>Average % fat decrease per month</td>
                <td>{this.state.avgFatDecrease}</td>
              </tr>
            </tbody>
            </table>
        </div>
      </div>

    </div>
    </div>
  }
};
