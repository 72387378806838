//import axios from 'axios';
import $ from "jquery";
//import configData from "../config.json";
//import configData from "../config";
import axios from "axios";

var Api = {
  consult: async (type, data) => {
    return await $.ajax({
      method: "POST",
      url: process.env.REACT_APP_SERVER_URL,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      },
      data: JSON.stringify({ type: "Consult/" + type, ...data }),
      beforeSend: function (request) {
        request.setRequestHeader(
          "Authorization",
          window.localStorage.getItem("consultLogin") || ""
        );
      },
      error: function (e) {
        console.log("error");
        console.log(e);
      },
    }).promise();
  },
  downloadFile: async (type, data, fileName) => {
    await axios({
      url: process.env.REACT_APP_SERVER_URL,
      method: "POST",
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer " + (window.localStorage.getItem("consultLogin") || ""),
      },
      data: JSON.stringify({ type: "Consult/" + type, ...data }),
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  },
  dietzdashboard: async (type, data) => {
    var response = await $.ajax({
      method: "POST",
      url: Api.serverUrl,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      },
      data: JSON.stringify({ type: "DietzDashboard/" + type, ...data }),
      error: function (e) {
        console.log(e);
      },
    }).promise();

    return response;
  },
  jsonList: async (subType, search) => {
    return await $.ajax({
      method: "GET",
      url:
        process.env.REACT_APP_SERVER_URL +
        `?type=Consult/JsonList&subType=${subType}?term=${search}`,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      },
      error: function (e) {
        console.log("error");
        console.log(e);
      },
    }).promise();
  },
  queryString: (function (a) {
    if (a == "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
      var p = a[i].split("=", 2);
      if (p.length == 1) b[p[0]] = "";
      else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
  })(window.location.search.substr(1).split("&")),
};

export default Api;
