import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import Api from "../../Api";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
var utilHandleChange = require("./FormUtil.js").handleChange;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormGoodDeath = (props) => {
  var answerList = [
    { label: "เลือกผู้ตอบ", value: "" },
    { label: "ผู้ป่วย", value: "1" },
    { label: "ผู้ดูแล", value: "2" },
  ];

  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [familyItemsDropDownList, setFamilyItemsDropDownList] = useState([]);
  var [wardDropDownList, setWardDropDownList] = useState([]);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    var datas = { ...dataForm };

    datas = { ...datas, ["data_" + field]: valueData };

    setDataForm(datas);
  }

  function setDataObj(data) {
    var datas = { ...dataForm };

    datas = { ...datas, ...data };

    setDataForm(datas);
  }

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    props.addValue({
      formName: "form_gooddeath",
      formType: "4",
    });
  }, []);

  async function getFamilyItem() {
    try {
      var result = await Api.consult("GetFamilyItem", {});

      var { data } = result;
      const dataArray = Object.keys(data);

      const list = dataArray.map((item) => ({
        value: item,
        label: data[item],
      }));
      setFamilyItemsDropDownList(list);
    } catch (err) {
      console.log(err);
    }
  }

  async function getWardList() {
    try {
      var result = await Api.consult("GetWardList", {});

      if (result.list.data) {
        const { _value } = result.list.data;
        const list = _value.map((item) => ({
          value: item.code,
          label: item.name,
        }));
        setWardDropDownList(list);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getFamilyItem();
    getWardList();
  }, []);

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบประเมินการตายดี (Good death)" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ผู้ตอบแบบประเมินมีความเกี่ยวข้องกับผู้ป่วย" />
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementDropdown
          fieldId={"1"}
          value={dataForm.data_1}
          label="หอผู้ป่วย"
          onChange={handleChange}
          width="100"
          dataList={[{ value: "", label: "เลือกหอผู้ป่วย" }].concat(
            wardDropDownList
          )}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
        <span className="formTextBoxLabel">สถานที่เสียชีวิต</span>
        <div className="dietz-display-inline-container">
          <FormElementRadio
            fieldId={"2"}
            fieldValue={"1"}
            value={dataForm.data_2}
            onChange={handleChange}
            label="ที่บ้าน"
          />
          <FormElementRadio
            fieldId={"2"}
            fieldValue={"2"}
            value={dataForm.data_2}
            onChange={handleChange}
            label="ที่โรงพยาบาล"
          />
          <FormElementRadio
            fieldId={"2"}
            fieldValue={"3"}
            value={dataForm.data_2}
            onChange={handleChange}
            label="อื่นๆ"
          />
        </div>
        <span hidden={dataForm.data_2 != "3"}>
          <FormElementTextBox
            fieldId={"13"}
            value={dataForm.data_13}
            placeholder="ระบุ"
            onChange={handleChange}
            width="100"
          />
        </span>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"3"}
          value={dataForm.data_3}
          label="ผู้ตอบแบบประเมิน"
          onChange={handleChange}
          width="100"
        />
        <FormElementDropdown
          fieldId={"4"}
          value={dataForm.data_4}
          label="ความสัมพันธ์"
          onChange={handleChange}
          dataList={[{ value: "", label: "เลือกความสัมพันธ์" }].concat(
            familyItemsDropDownList
          )}
          width="100"
        />
        <span hidden={dataForm.data_4 != "99"}>
          <FormElementTextBox
            fieldId={"12"}
            value={dataForm.data_12}
            placeholder="ระบุ"
            onChange={handleChange}
          />
        </span>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLine />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="กรุณาทําเครื่องหมาย  ในช่องที่ตรงกับความเป็นจริง" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="1. ผู้ป่วยได้เสียชีวิตในสถานที่ตรงกับความต้องการของผู้ป่วย" />
      </div>

      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
        <FormElementRadio
          fieldId={"5"}
          value={dataForm.data_5}
          fieldValue={"1"}
          onChange={handleChange}
          name="data2"
          label="ใช่"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
        <FormElementRadio
          fieldId={"5"}
          value={dataForm.data_5}
          fieldValue={"2"}
          onChange={handleChange}
          name="data2"
          label="ไม่ใช่"
        />
        <span className={dataForm.data_5 == "2" ? "form-show" : "form-hidden"}>
          <FormElementTextBox
            fieldId={"6"}
            value={dataForm.data_6}
            label="สถานที่ที่ผู้ป่วยต้องการเสียชีวิต คือ"
            onChange={handleChange}
            width="100"
          />
        </span>
      </div>
      <div
        className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
        style={{ marginLeft: "50px" }}
      >
        <span className={dataForm.data_5 == "2" ? "form-show" : "form-hidden"}>
          <FormElementTextBox
            fieldId={"7"}
            value={dataForm.data_7}
            label="สถานที่ที่ผู้ป่วยได้เสียชีวิตจริง คือ"
            onChange={handleChange}
            width="100"
          />
        </span>
      </div>
      <div
        className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
        style={{ marginLeft: "50px" }}
      >
        <span className={dataForm.data_5 == "2" ? "form-show" : "form-hidden"}>
          <FormElementTextArea
            fieldId={"8"}
            value={dataForm.data_8}
            rows={3}
            cols={70}
            onChange={handleChange}
            label="มีสาเหตุเพราะ"
          />
        </span>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLine />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="2. ในความรู้สึกของญาติ คิดว่าผู้ป่วยได้จากไปอย่างสงบ โดยที่อาการต่างๆได้รับการดูแลหรือไม่" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementRadio
          fieldId={"9"}
          value={dataForm.data_9}
          fieldValue={"1"}
          onChange={handleChange}
          name="data3"
          label="ใช่"
        />
        <span className={dataForm.data_9 == "1" ? "form-show" : "form-hidden"}>
          <span
            className="formTextBoxLabel"
            style={{ marginLeft: "30px", marginRight: "20px" }}
          >
            ระดับ
          </span>
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"1"}
            onChange={handleChange}
            name="data3"
            label="ดีที่สุด"
          />
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"2"}
            onChange={handleChange}
            name="data3"
            label="ดีมาก"
          />
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"3"}
            onChange={handleChange}
            name="data3"
            label="ดี"
          />
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"4"}
            onChange={handleChange}
            name="data3"
            label="พอใช้"
          />
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"5"}
            onChange={handleChange}
            name="data3"
            label="น้อย"
          />
        </span>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementRadio
          fieldId={"9"}
          value={dataForm.data_9}
          fieldValue={"0"}
          onChange={handleChange}
          name="data3"
          label="ไม่ใช่"
        />
        <span className={dataForm.data_9 == "0" ? "form-show" : "form-hidden"}>
          <FormElementTextBox
            fieldId={"10"}
            value={dataForm.data_10}
            onChange={handleChange}
            label="เพราะ (ต้องระบุ)"
            width="100"
          />
        </span>
        {/* <FormElementTextBox fieldId={""} value={dataForm.data_} width="100" /> */}
      </div>
    </div>
  );
};

export default FormGoodDeath;
