import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import DataType from '../DataType';
import Api from '../Api';
import Formular from '../Formular';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class DashboardDept extends Component {
    state = {
        department: []
    }

    componentDidMount() {
        this.dataGet();
    }

    dataGet = async () => {
        var { department = [] } = await Api.member('DashboardDept');

        this.setState({ department: department || [] })

    }

    renderChart = () => {
        var chartOptions = {

            // Add title
            title: {
                text: 'Dept',
                subtext: 'Employees by Department',
                x: 'center'
            },

            // Add legend
            /*
            legend: {
                orient: 'vertical',
                x: 'left',
                data: this.state.department.map(data => (data.name))
            },
            */

            // Add custom colors
            color: ['#00A5A8', '#626E82', '#FF7D4D', '#FF4558', '#16D39A'],

            // Display toolbox
            toolbox: {
                show: true,
                orient: 'vertical',
                feature: {
                    mark: {
                        show: true,
                        title: {
                            mark: 'Markline switch',
                            markUndo: 'Undo markline',
                            markClear: 'Clear markline'
                        }
                    },
                    dataView: {
                        show: true,
                        readOnly: false,
                        title: 'View data',
                        lang: ['View chart data', 'Close', 'Update']
                    },
                    magicType: {
                        show: true,
                        title: {
                            pie: 'Switch to pies',
                            funnel: 'Switch to funnel',
                        },
                        type: ['pie', 'funnel'],
                        option: {
                            funnel: {
                                x: '25%',
                                y: '20%',
                                width: '50%',
                                height: '70%',
                                funnelAlign: 'left',
                                max: 1548
                            }
                        }
                    },
                    restore: {
                        show: true,
                        title: 'Restore'
                    },
                    saveAsImage: {
                        show: true,
                        title: 'Same as image',
                        lang: ['Save']
                    }
                }
            },

            // Enable drag recalculate
            calculable: true,

            // Add series
            series: [
                {
                    name: 'Browsers',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '14',
                            fontWeight: 'bold',
                            formatter: '{b}' + '\n\n' + '{c} ({d}%)',
                        }
                    },
                    labelLine: {
                        show: false
                    },

                    data: this.state.department
                }
            ]
        };

        return <ReactEcharts
            option={chartOptions}
            style={{ height: 400 }}
            notMerge={true}
            lazyUpdate={true}
        />
    }

    render() {
        return <div className="uk-card uk-card-default uk-padding-small" id="d-dashboard-dept-container">
            <div className="d-card-header">
                <div className="d-card-header-left">
                DEPARTMENT
                </div>
                <div className="d-card-header-right">
                    <span className='icon-reload' onClick={this.dataGet}></span>
                    <FullScreenElement element="#d-dashboard-dept-container" />
                </div>
            </div>
            {this.renderChart()}
        </div>
    }
};
