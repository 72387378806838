import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import moment from 'moment';

UIkit.use(Icons);

export default class UserLastestAdviceByDietitian extends Component {

  state = {
    data: {},
  }

  componentDidMount() {
    this.userMealGet();
  }

  userMealGet = async () => {
    var {data} = await Api.member('UserLastestAdviceByDietitian');
    if(!data) data = {};

    this.setState({data});
  }


  render() {
    return <div className="uk-card uk-card-default uk-padding-small">
      <div className="d-card-header uk-padding-small">
        <div className="d-card-header-left" style={{width: '100%'}}>
          LASTEST ADVICE
        </div>
      </div>
      <div>
        {
          this.state.data.id ?
          <div className="uk-grid-small" uk-grid="">
            <div className="uk-width-auto">
            <div className="uk-background-muted uk-background-cover d-user-last-advice-profile-image" style={{backgroundImage: (this.state.data.image ==='' ? false : `url(${this.state.data.image})` )}}></div>
            </div>
            <div className="uk-width-expand">
              <div className="uk-text-primary">{this.state.data.nameprefix} {this.state.data.name} {this.state.data.lastname}</div>
              <div className="uk-text-small uk-text-break" dangerouslySetInnerHTML={{__html: this.state.data.value }} ></div>
            </div>
          </div>
          :
          <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
        }
        
      </div>
      <div style={{borderTop: '1px solid #ECEFF1'}}></div>
      <div className="uk-padding-small uk-text-small">{moment(this.state.data.dateadd).fromNow()}</div>
    </div>
  }
};
