import React, { useState, useEffect } from "react";
import Api from "./Api";
import DrawingOnImage from "./DrawingOnImage";

export default function DrawingPaper(props) {
  const { show, onLoading } = props;
  const [showImage, setShowImage] = useState(false);
  const [detailImage, setDetailImage] = useState({});
  const [data, setData] = useState(null);

  const onClose = () => {
    setShowImage(false);
    props.setShowDrawingPaper(false);
  };

  const editImage = (data) => {
    setDetailImage(data);
    setShowImage(true);
  };

  useEffect(() => {
    setShowImage(show);
  }, [show]);

  useEffect(() => {
    async function loadImage() {
      console.log(props.txn);
      try {
        onLoading(true);
        if (props.show && props.txn != null) {
          const result = await Api.consult("MemberFormTxnFilesList", {
            txn: props.txn,
            is_active: "1",
          });

          console.log(result);

          if (result.list.length > 0) {
            editImage(result.list[0]);
          }
        }
      } catch (error) {
        editImage(undefined);
        console.error(error);
      } finally {
        onLoading(false);
      }
    }

    loadImage();
  }, [props.show, props.txn]);

  const handleReset = (data) => {
    setDetailImage(data);
  };

  return (
    <>
      &nbsp;&nbsp;
      {showImage ? (
        <DrawingOnImage
          detailImage={detailImage}
          onClose={onClose}
          onLoading={onLoading}
          onReset={handleReset}
        />
      ) : null}
    </>
  );
}
