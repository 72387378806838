import React, { useState, useEffect } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import DateTimePicker from "../../Components/DatePicker.js";
import moment from "moment";
UIkit.use(Icons);

const ConsultAppointmentList = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataList, setDataList] = useState([]);

  async function listGet() {
    const profileTxt = localStorage.getItem("consultProfileData");
    const profileData = JSON.parse(profileTxt);

    var { list } = await Api.consult("AppointmentList", {
      appointmentDate: "2023-04-03",
      clinicCode: profileData.clinicCode,
    });

    if (list != null) {
      setDataList(list);
    } else {
      setDataList([]);
    }

    setLoadingApi(false);
  }

  useEffect(() => {
    listGet();
  }, []);

  return (
    <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">ใบนัดหมาย</div>
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-1-5@m uk-width-1-1"></div>
          <div className="uk-width-1-5@m uk-width-1-1"></div>

          {/* <div className="uk-width-1-5@m uk-width-1-1">
            <button className="uk-button uk-button-primary" onClick={listGet}>
              ค้นหา
            </button>
          </div> */}
        </div>

        <hr />
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>วีดิโอคอล</th>
                <th>ส่งลิงค์</th>
                <th>ประเภทการนัดหมาย</th>
                <th>สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {loadingApi == true ? (
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">
                      <span uk-spinner=""></span>
                    </div>
                  </td>
                </tr>
              ) : dataList.length === 0 ? (
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">
                      ไม่พบข้อมูล
                    </div>
                  </td>
                </tr>
              ) : (
                dataList.map((data, key) => {
                  return (
                    <tr key={`row${data.id}`}>
                      <td>{key + 1}</td>
                      <td>{moment(data.dateadd).format("DD/MM/YYYY")}</td>
                      <td>{moment(data.dateadd).format("HH:mm น.")}</td>
                      <td></td>
                      <td>
                        <a
                          href={`/consult/chat?messageSelectIndex=ca${data.campaignid}_co${data.consultid}_m${data.memberid}`}
                        >
                          {data.name}
                        </a>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ConsultAppointmentList;
