import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import PopupForm from "../PopupForm";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import FormFearFalling from "./FormFearFalling.js";
import FormZarit12 from "./FormZarit12.js";
import FormADLS from "./FormADLS.js";
import FormIADLS from "./FormIADLS.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormPostFallingPaper from "../FormPaper/FormPostFallingPaper.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import FormPopup from "../Common/FormPopup.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormPostFalling = (props) => {
  const formConstants = {
    formName: "form_postfalling",
    formType: "41",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, zaritData, fearFallingData, adlsData, iadlsData, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [zaritDataForm, setZaritDataForm] = useState({ data: {}, html: '', score: NaN });
  const [adlsDataForm, setAdlsDataForm] = useState({ data: {}, html: '', score: NaN });
  const [iadlsDataForm, setIAdlsDataForm] = useState({ data: {}, html: '', score: NaN });
  const [fearDataForm, setFearDataForm] = useState({ data: {}, html: '', score: NaN });
  const [isPreview, setIsPreview] = useState(false);
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);

  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      setZaritDataForm(({ data: { ...zaritData, hn, txn, } }));
      setAdlsDataForm(({ data: { ...adlsData, hn, txn, } }));
      setIAdlsDataForm(({ data: { ...iadlsData, hn, txn, } }));
      setFearDataForm(({ data: { ...fearFallingData, hn, txn, } }));
      hideLoading();
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const fearDataFormRequest = fetchConsultFormByTxn(txn, "35");
    const zaritDataFormRequest = fetchConsultFormByTxn(txn, "36");
    const adlsDataFormRequest = fetchConsultFormByTxn(txn, "44");
    const iadlsDataFormRequest = fetchConsultFormByTxn(txn, "45");
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all(
      [patientDataRequest, fearDataFormRequest, zaritDataFormRequest,
        adlsDataFormRequest, iadlsDataFormRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const fearDataFormResponse = values[1];
      const zaritDataFormResponse = values[2];
      const adlsDataFormResponse = values[3];
      const iadlsDataFormResponse = values[4];
      const patientRoomResponse = values[5];

      setFearDataForm({ data: { ...fearDataFormResponse, hn, txn } });
      setZaritDataForm({ data: { ...zaritDataFormResponse, hn, txn } });
      setAdlsDataForm({ data: { ...adlsDataFormResponse, hn, txn } });
      setIAdlsDataForm({ data: { ...iadlsDataFormResponse, hn, txn } });
      setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn })
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType)
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);

  useEffect(() => {
    if (isSubForm)
      if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, []);

  useEffect(() => {
    console.log('dataForm', dataForm);
  }, [dataForm]);

  const handleSave = useCallback((html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);

          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);

          const subFormSaveRequest = [];

          const adlsSaveData = utilTransformDataBeforeSave({ ...adlsDataForm.data }, []);
          if (!isNaN(adlsDataForm.score)) {
            subFormSaveRequest.push(saveConsultForm(adlsSaveData, profile.loginToken, adlsSaveData.form_id, profile.consultId, profile.doctorId, adlsDataForm.html, patient));
          }

          const iadlsSaveData = utilTransformDataBeforeSave({ ...iadlsDataForm.data }, []);
          if (!isNaN(iadlsDataForm.score)) {
            subFormSaveRequest.push(saveConsultForm(iadlsSaveData, profile.loginToken, iadlsSaveData.form_id, profile.consultId, profile.doctorId, iadlsDataForm.html, patient));
          }

          const zaritSaveData = utilTransformDataBeforeSave({ ...zaritDataForm.data }, []);
          if (!isNaN(zaritDataForm.score)) {
            subFormSaveRequest.push(saveConsultForm(zaritSaveData, profile.loginToken, zaritSaveData.form_id, profile.consultId, profile.doctorId, zaritDataForm.html, patient));
          }

          const fearSaveData = utilTransformDataBeforeSave({ ...fearDataForm.data }, []);
          if (!isNaN(fearDataForm.score)) {
            subFormSaveRequest.push(saveConsultForm(fearSaveData, profile.loginToken, fearSaveData.form_id, profile.consultId, profile.doctorId, fearDataForm.html, patient));
          }

          if (subFormSaveRequest.length > 0) {
            Promise.all([...subFormSaveRequest]).then(() => {
              hideLoading();
              onSuccessPopupOpen(true);
            }).catch((error) => {
              hideLoading();
              displayErrorSavePopup();
            })
          } else {
            hideLoading();
            onSuccessPopupOpen(true);
          }
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  }, [dataForm, adlsDataForm,
    iadlsDataForm, zaritDataForm,
    fearDataForm, validateSave, displayLoading, hideLoading, onSuccessPopupOpen, displayErrorSavePopup, patient]);

  function doFES_I() {
    setPopupFormType(35);
    setShowFormPopup(true);
  }

  function doZarit_12() {
    setPopupFormType(36);
    setShowFormPopup(true);
  }

  function doADLS() {
    setPopupFormType(44);
    setShowFormPopup(true);
  }

  function doIADLS() {
    setPopupFormType(45);
    setShowFormPopup(true);
  }

  const handleSubFormDataChange = useCallback((data, html, score) => {
    if (popupFormType == 35) {
      setFearDataForm({ data: { ...data }, html, score });
    } else if (popupFormType == 36) {
      setZaritDataForm({ data: { ...data }, html, score });
    } else if (popupFormType == 44) {
      setAdlsDataForm({ data: { ...data }, html, score });
    } else if (popupFormType == 45) {
      setIAdlsDataForm({ data: { ...data }, html, score });
    }
    setShowFormPopup(false);
    setPopupFormType(undefined);
  }, [setShowFormPopup, setPopupFormType, popupFormType,
    setAdlsDataForm, setZaritDataForm, , setFearDataForm,
    setIAdlsDataForm]);

  const getSubFormProps = useMemo(() => {
    return {
      patient: patient,
      profile: profile,
      onLoading: onLoading,
      isSubForm: true,
      onAdd: handleSubFormDataChange,
      onErrorMessageChange: onErrorMessageChange
    }
  }, [patient, profile, onLoading, handleSubFormDataChange, onErrorMessageChange]);

  const renderSubForm = useMemo(() => {
    if (popupFormType == 35) {
      return <FormFearFalling data={fearDataForm.data} {...getSubFormProps} />
    } else if (popupFormType == 36) {
      return <FormZarit12 data={zaritDataForm.data} {...getSubFormProps} />
    } else if (popupFormType == 44) {
      return <FormADLS data={adlsDataForm.data} {...getSubFormProps} />
    } else if (popupFormType == 45) {
      return <FormIADLS data={iadlsDataForm.data} {...getSubFormProps} />
    } else {
      return <>ไม่พบข้อมูล</>
    }
  }, [popupFormType, getSubFormProps,
    fearDataForm, zaritDataForm, adlsDataForm, iadlsDataForm]);

  const handleCloseSubFormPopup = useCallback(() => {
    setShowFormPopup(false);
    setPopupFormType(undefined);
  }, [setShowFormPopup, setPopupFormType]);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const handleAddDataToParent = useCallback((html) => {
    const data = {
      zaritDataForm,
      fearDataForm,
      adlsDataForm,
      iadlsDataForm
    }
    onAdd(dataForm, html, null, data);
  }, [dataForm, onAdd, zaritDataForm, fearDataForm, adlsDataForm, iadlsDataForm]);

  useEffect(() => {
    if (!isNaN(fearDataForm.score)) {
      setDataForm((data) => ({ ...data, data_45n: fearDataForm.score }))
    }
  }, [fearDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(adlsDataForm.score)) {
      setDataForm((data) => ({ ...data, data_38n: adlsDataForm.score }))
    }
  }, [adlsDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(iadlsDataForm.score)) {
      setDataForm((data) => ({ ...data, data_40n: iadlsDataForm.score }))
    }
  }, [iadlsDataForm.score, setDataForm]);

  useEffect(() => {
    if (!isNaN(zaritDataForm.score)) {
      setDataForm((data) => ({ ...data, data_48n: zaritDataForm.score }))
    }
  }, [zaritDataForm.score, setDataForm]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div
        uk-grid=""
        className="uk-grid uk-padding-small"
        ref={parentTableContainer}
        style={{ overflowX: "hidden" }}
      >
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="Post-falling assessment " />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="POST-FALL ASSESSMENT (FAMILY MEDICINE, CHIANG MAI UNIVERSITY)" />
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <div className="dietz-display-inline-container">
              <FormElementRadio
                fieldId={"1"}
                value={dataForm.data_1}
                fieldValue={"1"}
                onChange={handleChange}
                label=" On admission "
              />
              <FormElementRadio
                fieldId={"1"}
                value={dataForm.data_1}
                fieldValue={"2"}
                onChange={handleChange}
                label=" Home visit "
              />
              <FormElementRadio
                fieldId={"1"}
                value={dataForm.data_1}
                fieldValue={"3"}
                onChange={handleChange}
                label=" Virtual home visit "
              />
            </div>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">เป็นการประเมิน</span>
            <div className="dietz-display-inline-container">
              <FormElementRadio
                fieldId={"2"}
                value={dataForm.data_2}
                fieldValue={"1"}
                onChange={handleChange}
                label=" ครั้งแรก "
              />
              <FormElementRadio
                fieldId={"2"}
                value={dataForm.data_2}
                fieldValue={"2"}
                onChange={handleChange}
                label=" ติดตาม "
              />
            </div>
            <FormElementTextBox
              fieldId={"3"}
              value={dataForm.data_3}
              label="ที่"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">เดือนหลังล้ม</span>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <div
              className="form-table-container"
            // style={{ maxWidth: `${tableMaxWidth}px` }}
            >
              <div className="form-table-scroll">
                <table className="uk-table uk-table-small uk-table-divider form-table">
                  <tbody>
                    <tr>
                      <th colSpan={3}>
                        <span className="formTextBoxLabel">
                          ส่วนนี้ประเมินก่อนออกจากโรงพยาบาลและติดตามผู้ป่วย
                        </span>
                        <FormElementTextBox
                          fieldId={"4"}
                          value={dataForm.data_4}
                          type="date"
                          label="วันที่"
                          onChange={handleChange}
                          width="100"
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <span className="formTextBoxLabel"> Physical health</span>
                      </th>
                      <th colSpan={2}>
                        <span className="formTextBoxLabel">
                          Review underlying diseases (ดูจากใบหลัก)
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <FormElementTextBox
                          fieldId={"5"}
                          value={dataForm.data_5}
                          label="Pain score: average"
                          type="numeric"
                          maxLength={2}
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">/10</span>
                        <FormElementTextBox
                          fieldId={"6"}
                          value={dataForm.data_6}
                          label="ambulation"
                          type="numeric"
                          maxLength={2}
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">/10</span>
                      </td>
                      <th colSpan={2}>
                        <FormElementTextBox
                          fieldId={"7"}
                          value={dataForm.data_7}
                          label="Medication (ดูใบหลัก) ทานยาทั้งหมด"
                          onChange={handleChange}
                          type="numeric"
                          width="100"
                        />
                        <span className="formTextBoxLabel">ชนิด</span>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            Complain ปัญหาการมองเห็น
                          </span>
                          <FormElementRadio
                            fieldId={"8"}
                            value={dataForm.data_8}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"8"}
                            value={dataForm.data_8}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <span
                            className={
                              dataForm.data_8 == "1" ? "form-show" : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"9"}
                              value={dataForm.data_9}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">ปัญหาการได้ยิน</span>
                          <FormElementRadio
                            fieldId={"10"}
                            value={dataForm.data_10}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"10"}
                            value={dataForm.data_10}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <span
                            className={
                              dataForm.data_10 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"11"}
                              value={dataForm.data_11}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            ปัญหาการนอนหลับ
                          </span>
                          <FormElementRadio
                            fieldId={"12"}
                            value={dataForm.data_12}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"12"}
                            value={dataForm.data_12}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <span
                            className={
                              dataForm.data_12 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"13"}
                              value={dataForm.data_13}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">น้ำหนัก</span>
                          <FormElementTextBox
                            fieldId={"14"}
                            value={dataForm.data_14}
                            label="ระบุ"
                            type="numeric"
                            maxLength={3}
                            onChange={handleChange}
                            width="100"
                          />
                          <span className="formTextBoxLabel">kg</span>
                          <FormElementRadio
                            fieldId={"15"}
                            value={dataForm.data_15}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่เปลี่ยนแปลง "
                          />
                          <FormElementRadio
                            fieldId={"15"}
                            value={dataForm.data_15}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" เพิ่ม "
                          />
                          <FormElementRadio
                            fieldId={"15"}
                            value={dataForm.data_15}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label=" ลด "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            รู้สึกไม่มั่นคงในการเดิน
                          </span>
                          <FormElementRadio
                            fieldId={"16"}
                            value={dataForm.data_16}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"16"}
                            value={dataForm.data_16}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <span
                            className={
                              dataForm.data_16 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"17"}
                              value={dataForm.data_17}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                      </td>
                      <td colSpan={2}>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            มียากลุ่มเหล่านี้หรือไม่
                          </span>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"18"}
                              value={dataForm.data_18}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label=" ไม่มี "
                            />
                            <FormElementCheckBox
                              fieldId={"19"}
                              value={dataForm.data_19}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label=" Diuretics  "
                            />
                            <FormElementCheckBox
                              fieldId={"20"}
                              value={dataForm.data_20}
                              fieldValue={"3"}
                              onChange={handleChange}
                              label=" Anti-hypertensive  "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"21"}
                              value={dataForm.data_21}
                              fieldValue={"4"}
                              onChange={handleChange}
                              label=" Anti-depressants  "
                            />
                            <FormElementCheckBox
                              fieldId={"22"}
                              value={dataForm.data_22}
                              fieldValue={"5"}
                              onChange={handleChange}
                              label=" Sedative/hypnotics  "
                            />
                            <FormElementCheckBox
                              fieldId={"23"}
                              value={dataForm.data_23}
                              fieldValue={"6"}
                              onChange={handleChange}
                              label=" Anti-histamine  "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementCheckBox
                              fieldId={"24"}
                              value={dataForm.data_24}
                              fieldValue={"7"}
                              onChange={handleChange}
                              label=" Anti-cholinergics "
                            />
                            <FormElementCheckBox
                              fieldId={"25"}
                              value={dataForm.data_25}
                              fieldValue={"8"}
                              onChange={handleChange}
                              label=" Anti-psychotics  "
                            />
                            <FormElementCheckBox
                              fieldId={"26"}
                              value={dataForm.data_26}
                              fieldValue={"9"}
                              onChange={handleChange}
                              label=" Anti-convulsants "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small  uk-padding-remove-left uk-padding-remove-bottom">
                            <span className="formTextBoxLabel">
                              ประเมินการกินยา
                            </span>
                            <FormElementRadio
                              fieldId={"27"}
                              value={dataForm.data_27}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label=" good adherence "
                            />
                            <FormElementRadio
                              fieldId={"27"}
                              value={dataForm.data_27}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label=" poor compliance "
                            />
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small  uk-padding-remove-left uk-padding-remove-bottom">
                            <span className="formTextBoxLabel">
                              ปัญหาในการทานยา
                            </span>
                            <FormElementRadio
                              fieldId={"28"}
                              value={dataForm.data_28}
                              fieldValue={"0"}
                              onChange={handleChange}
                              label=" ไม่มี "
                            />
                            <FormElementRadio
                              fieldId={"28"}
                              value={dataForm.data_28}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label=" มี "
                            />
                            <span
                              className={
                                dataForm.data_28 == "1"
                                  ? "form-show"
                                  : "form-hidden"
                              }
                            >
                              <FormElementTextBox
                                fieldId={"29"}
                                value={dataForm.data_29}
                                label=""
                                onChange={handleChange}
                                width="100"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormElementLine />
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                          <span className="formTextBoxLabel">Gait aid</span>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementRadio
                              fieldId={"30"}
                              value={dataForm.data_30}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label=" none "
                            />
                            <FormElementRadio
                              fieldId={"30"}
                              value={dataForm.data_30}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label=" ไม้เท้า "
                            />
                            <span
                              className={
                                dataForm.data_30 == "2"
                                  ? "form-show"
                                  : "form-hidden"
                              }
                            >
                              <FormElementTextBox
                                fieldId={"31"}
                                value={dataForm.data_31}
                                label=""
                                onChange={handleChange}
                                width="100"
                              />
                              <span className="formTextBoxLabel">ขา</span>
                            </span>
                          </div>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementRadio
                              fieldId={"30"}
                              value={dataForm.data_30}
                              fieldValue={"3"}
                              onChange={handleChange}
                              label=" walker "
                            />
                            <FormElementRadio
                              fieldId={"30"}
                              value={dataForm.data_30}
                              fieldValue={"4"}
                              onChange={handleChange}
                              label=" อื่นๆ "
                            />
                            <span
                              className={
                                dataForm.data_30 == "4"
                                  ? "form-show"
                                  : "form-hidden"
                              }
                            >
                              <FormElementTextBox
                                fieldId={"32"}
                                value={dataForm.data_32}
                                label=""
                                onChange={handleChange}
                                width="100"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">การล้มซ้ำ</span>
                          <FormElementRadio
                            fieldId={"33"}
                            value={dataForm.data_33}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"33"}
                            value={dataForm.data_33}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <FormElementTextBox
                            fieldId={"34"}
                            value={dataForm.data_34}
                            label="ครั้งล่าสุดนับจากล้มครั้งก่อน"
                            onChange={handleChange}
                            width="100"
                          />
                          <span className="formTextBoxLabel">เดือน</span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">Complication</span>
                          <FormElementRadio
                            fieldId={"35"}
                            value={dataForm.data_35}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"35"}
                            value={dataForm.data_35}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <span
                            className={
                              dataForm.data_35 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"36"}
                              value={dataForm.data_36}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <FormElementLine />
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                          <span className="formTextBoxLabel">
                            ปัจจุบัน ดื่มสุรา
                          </span>
                          <FormElementRadio
                            fieldId={"37"}
                            value={dataForm.data_37}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่ดื่ม "
                          />
                          <FormElementRadio
                            fieldId={"37"}
                            value={dataForm.data_37}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ดื่ม "
                          />
                          <div
                            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom "
                            style={{ display: "inline-flex", flexWrap: "wrap" }}
                          >
                            <FormElementTextBox
                              fieldId={"38n"}
                              value={dataForm.data_38n}
                              onChange={handleChange}
                              width="100"
                              size={5}
                              disabled
                            />
                            <FormElementButton label="ADLs" onClick={doADLS} />
                          </div>
                        </div>
                      </td>
                      <td>
                        <FormElementLine />
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                          <span className="formTextBoxLabel">สูบบุหรี่</span>
                          <FormElementRadio
                            fieldId={"39"}
                            value={dataForm.data_39}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่สูบ "
                          />
                          <FormElementRadio
                            fieldId={"39"}
                            value={dataForm.data_39}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" สูบ "
                          />
                          <div
                            className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom "
                            style={{ display: "inline-flex", flexWrap: "wrap" }}
                          >
                            <FormElementTextBox
                              fieldId={"40n"}
                              value={dataForm.data_40n}
                              onChange={handleChange}
                              width="100"
                              size={5}
                              disabled
                            />
                            <FormElementButton label="IADLs" onClick={doIADLS} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="formTextBoxLabel">Mental health</span>
                      </th>
                      <th colSpan={2}>
                        <span className="formTextBoxLabel">
                          Caregiver burden and family issue
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                          <span className="formTextBoxLabel">
                            Cognitive problem
                          </span>
                          <FormElementRadio
                            fieldId={"41"}
                            value={dataForm.data_41}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"41"}
                            value={dataForm.data_41}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <span
                            className={
                              dataForm.data_41 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"42"}
                              value={dataForm.data_42}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">Depressed mood</span>
                          <FormElementRadio
                            fieldId={"43"}
                            value={dataForm.data_43}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"43"}
                            value={dataForm.data_43}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            Fear of falling กลัวล้มในอีก 1 ปีไหม
                          </span>
                          <FormElementRadio
                            fieldId={"44"}
                            value={dataForm.data_44}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่กลัว "
                          />
                          <FormElementRadio
                            fieldId={"44"}
                            value={dataForm.data_44}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" กลัว "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            คะแนนแบบประเมิน
                          </span>
                          <FormElementTextBox
                            fieldId={"45n"}
                            value={dataForm.data_45n}
                            label="Fear of falling"
                            onChange={handleChange}
                            width="100"
                            disabled
                          />
                          <FormElementButton
                            label="Fear of falling"
                            onClick={doFES_I}
                          />
                          <span className="formTextBoxLabel">
                            (ที่ 2 สัปดาห์ และ 2 เดือน)
                          </span>
                        </div>
                      </td>
                      <td colSpan={2}>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                          <FormElementRadio
                            fieldId={"46"}
                            value={dataForm.data_46}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"46"}
                            value={dataForm.data_46}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี  "
                          />
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                            <FormElementTextArea
                              fieldId={"47"}
                              rows={4}
                              cols={70}
                              value={dataForm.data_47}
                              label="ระบุรายละเอียด "
                              onChange={handleChange}
                              width="100"
                              noLimit
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            คะแนนแบบประเมิน
                          </span>
                          <FormElementTextBox
                            fieldId={"48n"}
                            value={dataForm.data_48n}
                            label="Zarit-12 "
                            onChange={handleChange}
                            width="100"
                            disabled
                          />
                          <FormElementButton
                            label="Zarit-12"
                            onClick={doZarit_12}
                          />
                          <span className="formTextBoxLabel">
                            (ที่ 2 สัปดาห์ และ 2 เดือน)
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="formTextBoxLabel">
                          Environmental hazards (ขอภาพจากญาติ โดยเฉพาะจุดที่ล้ม)
                        </span>
                      </th>
                      <th colSpan={2}>
                        <span className="formTextBoxLabel">
                          ประเมินการดูแลตนเอง + Teach back
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                          <FormElementCheckBox
                            fieldId={"49"}
                            value={dataForm.data_49}
                            onChange={handleChange}
                            label=" แสงไฟไม่เพียงพอ "
                          />
                          <FormElementCheckBox
                            fieldId={"50"}
                            value={dataForm.data_50}
                            onChange={handleChange}
                            label=" พื้นลื่น  "
                          />
                          <FormElementCheckBox
                            fieldId={"51"}
                            value={dataForm.data_51}
                            onChange={handleChange}
                            label=" ของวางเกะกะ  "
                          />
                          <FormElementCheckBox
                            fieldId={"52"}
                            value={dataForm.data_52}
                            onChange={handleChange}
                            label=" พรมเช็ดเท้า "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <FormElementCheckBox
                            fieldId={"53"}
                            value={dataForm.data_53}
                            onChange={handleChange}
                            label=" ไม่มีราวจับห้องน้ำ  "
                          />
                          <FormElementCheckBox
                            fieldId={"54"}
                            value={dataForm.data_54}
                            onChange={handleChange}
                            label=" บันไดเสี่ยงต่อการหกล้ม "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <FormElementCheckBox
                            fieldId={"55"}
                            value={dataForm.data_55}
                            label="พื้นที่ภายนอกบ้านไม่เหมาะสม"
                            onChange={handleChange}
                          />
                          <span
                            className={
                              dataForm.data_55 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"57"}
                              value={dataForm.data_57}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <FormElementCheckBox
                            fieldId={"56"}
                            value={dataForm.data_56}
                            label="อื่นๆ"
                            onChange={handleChange}
                          />
                          <span
                            className={
                              dataForm.data_56 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"58"}
                              value={dataForm.data_58}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            ได้ทำการปรับปรุงบ้านแล้ว
                          </span>
                          <FormElementRadio
                            fieldId={"59"}
                            value={dataForm.data_59}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่ใช่ "
                          />
                          <FormElementRadio
                            fieldId={"59"}
                            value={dataForm.data_59}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ใช่ "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span
                            className={
                              dataForm.data_59 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextArea
                              rows={5}
                              cols={70}
                              fieldId={"60"}
                              value={dataForm.data_60}
                              label="โดยวิธี"
                              onChange={handleChange}
                              width="100"
                              noLimit
                            />
                          </span>
                        </div>
                      </td>
                      <td colSpan={2}>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                          <span className="formTextBoxLabel">
                            Clip VDO การดูแลตนเองในผู้ป่วย
                          </span>
                          <FormElementRadio
                            fieldId={"61"}
                            value={dataForm.data_61}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ได้ดู "
                          />
                          <FormElementRadio
                            fieldId={"61"}
                            value={dataForm.data_61}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ยังไม่ได้ดู "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            เข้าใจความสำคัญของการป้องกันความเสี่ยงในการล้ม
                          </span>
                          <FormElementRadio
                            fieldId={"62"}
                            value={dataForm.data_62}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ใช่ "
                          />
                          <FormElementRadio
                            fieldId={"62"}
                            value={dataForm.data_62}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่ใช่ "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            วางแผนแก้ไขปัญหาที่ทำให้เกิดความเสี่ยงล้ม
                          </span>
                          <FormElementRadio
                            fieldId={"63"}
                            value={dataForm.data_63}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ใช่ "
                          />
                          <FormElementRadio
                            fieldId={"63"}
                            value={dataForm.data_63}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่ใช่ "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">การฝึกกายภาพ</span>
                          <FormElementRadio
                            fieldId={"64"}
                            value={dataForm.data_64}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ได้ทำ "
                          />
                          <FormElementRadio
                            fieldId={"64"}
                            value={dataForm.data_64}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่ได้ทำ "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            เลือกการทานอาหารอย่างเหมาะสม
                          </span>
                          <FormElementRadio
                            fieldId={"65"}
                            value={dataForm.data_65}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ตอบได้ "
                          />
                          <FormElementRadio
                            fieldId={"65"}
                            value={dataForm.data_65}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ตอบไม่ได้ "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            ความกังวลและความเครียด
                          </span>
                          <FormElementRadio
                            fieldId={"66"}
                            value={dataForm.data_66}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"66"}
                            value={dataForm.data_66}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span
                            className={
                              dataForm.data_66 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextArea
                              rows={3}
                              cols={70}
                              fieldId={"67"}
                              value={dataForm.data_67}
                              label="ระบุ "
                              onChange={handleChange}
                              width="100"
                              noLimit
                            />
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            ความมั่นใจในการดูแลตนเองเมื่อกลับไปบ้าน
                          </span>
                          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"0"}
                              onChange={handleChange}
                              label=" 0 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label=" 1 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label=" 2 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"3"}
                              onChange={handleChange}
                              label=" 3 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"4"}
                              onChange={handleChange}
                              label=" 4 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"5"}
                              onChange={handleChange}
                              label=" 5 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"6"}
                              onChange={handleChange}
                              label=" 6 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"7"}
                              onChange={handleChange}
                              label=" 7 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"8"}
                              onChange={handleChange}
                              label=" 8 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"9"}
                              onChange={handleChange}
                              label=" 9 "
                            />
                            <FormElementRadio
                              fieldId={"68n"}
                              value={dataForm.data_68n}
                              fieldValue={"10"}
                              onChange={handleChange}
                              label=" 10 "
                            />
                          </div>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            สาเหตุที่ไม่มั่นใจ
                          </span>
                          <FormElementTextArea
                            rows={2}
                            cols={60}
                            fieldId={"69"}
                            value={dataForm.data_69}
                            label=" "
                            onChange={handleChange}
                            width="100"
                            noLimit
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <FormElementLine />
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom uk-padding-remove-top">
                          <span className="formTextBoxLabel">
                            หากเป็นการติดตาม
                            ประเด็นที่เป็นปัญหาเดิมได้รับการแก้ไขแล้วหรือไม่
                          </span>
                          <FormElementRadio
                            fieldId={"70"}
                            value={dataForm.data_70}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" แก้ไขเสร็จแล้ว "
                          />
                          <FormElementRadio
                            fieldId={"70"}
                            value={dataForm.data_70}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label=" แก้ไขบางส่วน "
                          />
                          <FormElementRadio
                            fieldId={"70"}
                            value={dataForm.data_70}
                            fieldValue={"3"}
                            onChange={handleChange}
                            label=" ยังไม่ได้แก้ไขเลย "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormElementLine />
                        <span className="formTextBoxLabel">
                          หมายเหตุ: การวางแผนการดูแลให้เขียนในใบหลัก
                        </span>
                      </td>
                      <td colSpan={2}>
                        <FormElementLine />
                        <FormElementTextBox
                          fieldId={"71"}
                          value={dataForm.data_71}
                          label="ชื่อผู้ประเมิน "
                          onChange={handleChange}
                          width="100"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <FormPostFallingPaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
            />}
        />
        <FormPopup show={showFormPopup} onClose={handleCloseSubFormPopup}>
          {renderSubForm}
        </FormPopup>
      </div>
    </>
  );
};

export default FormPostFalling;
