import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import $ from 'jquery';

UIkit.use(Icons);

export default class DateTimePicker extends Component {

  _id = 'dateTimePicker' + +new Date() + this.props.id;

  componentDidMount () {
    $("#" + this._id)
    .on("dp.change",  (e) => {

      if(typeof this.props.callback === 'function') {
        this.props.callback(e.date.format("YYYY-MM-DD HH:mm"));
      }
    })
    .datetimepicker({
      format: this.props.format ||  "DD/MM/YYYY HH:mm",
      defaultDate: this.props.date || moment(),
    });
  }


  render() {
    return  <div
      className="d-button-group input-group uk-button-group date uk-width-1-1"
      id={this._id}
    >
      <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
      <span
        className="uk-button input-group-addon"
        style={{ width: 45, marginLeft: 0 }}
      >
        <span className="glyphicon glyphicon-calendar"></span>
      </span>
    </div>
  }
};
