import Heart from './Heart';
import Brain from './Brain';

export default (data) => {
  var statusRender = function (colorArray) {
    if (colorArray.indexOf('red') >= 0) {
      return 'red';
    } else if (colorArray.indexOf('orange') >= 0) {
      return 'orange';
    } else if (colorArray.indexOf('yellow') >= 0) {
      return 'yellow';
    } else if (colorArray.indexOf('green') >= 0) {
      return 'green';
    }
  }

  var _data = {};
  if (Array.isArray(data)) {
    for (var i = data.length; i-- > 0;) {
      _data[data[i].name] = data[i];
    }
  } else {
    _data = data;
  }

  var _group = {
    diabetes: () => {
      if ((_data['fbs/fpg'] || _data['fbs']) && _data.hba1c) {
      //if ((_data['fbs/fpg'] || _data['fbs'])) {
        
        if(_data['fbs'] && !_data['fbs/fpg']) _data['fbs/fpg'] = _data['fbs'];

        return {
          icon: 'sugar',
          label: 'น้ำตาล',
          //color: statusRender([_data['fbs/fpg'].detail.color]),
          //detail: [_data['fbs/fpg']]
          color: statusRender([_data['fbs/fpg'].detail.color, _data.hba1c.detail.color]),
          detail: [_data['fbs/fpg'], _data.hba1c]
        }
      } else return false;
    },
    kidney: () => {
      if (_data.creatinine && _data.egfr && _data.bun) {
      //if (_data.creatinine) {
        return {
          icon: 'kidney',
          label: 'ไต',
          //color: statusRender([_data.creatinine.detail.color]),
          //detail: [_data.creatinine]
          color: statusRender([_data.creatinine.detail.color, _data.egfr.detail.color, _data.bun.detail.color]),
          detail: [_data.creatinine, _data.egfr, _data.bun]
        }
      } else return false;
    },
    bloodPressure: () => {
      if (_data['sys/dias']) {
        try {
          return {
            icon: 'blood',
            label: 'ความดันโลหิต',
            color: statusRender([_data['sys/dias'].detail.color]),
            detail: [_data['sys/dias']]
          }
        } catch (e) {
          console.error(e);
          console.error(_data);
          return false;
        }

      } else return false;
    },

    fat: () => {
      if (_data.waistline && _data.bmi) {
        return {
          icon: 'fat',
          label: 'อ้วนลงพุง',
          color: statusRender([_data.waistline.detail.color, _data.bmi.detail.color]),
          detail: [_data.waistline, _data.bmi]
        }
      } else return false;
    },
    bloodLipids: () => {
      if (_data.chol && _data.hdl && _data.ldl && _data.tg) {
        return {
          icon: 'bloodlipids',
          label: 'ไขมันในเลือด',
          color: statusRender([_data.chol.detail.color, _data.hdl.detail.color, _data.ldl.detail.color, _data.tg.detail.color]),
          detail: [_data.chol, _data.hdl, _data.ldl, _data.tg]
        }
      } else return false;
    },
    liver: () => {
      if (_data.sgpt && _data.sgot) {
        try {
          return {
            icon: 'liver',
            label: 'ตับ',
            color: statusRender([_data.sgpt.detail.color, _data.sgot.detail.color]),
            detail: [_data.sgpt, _data.sgot]
          }
        } catch (e) {
          console.error(e);
          console.error(_data);
          return false;
        }

      } else return false;
    },
    lung: () => {
      if (_data.lung) {
        return {
          icon: 'lung',
          label: 'ปอด',
          color: (_data.lung.value === 'ปกติ' || _data.lung.value === 'ปกติ' ? 'green' : 'orange'),
          detail: [_data.lung]
        }
      } else return false;
    },
    colon: () => {
      if (_data.colon) {
        return {
          icon: 'intestine',
          label: 'ลำไส้ใหญ่',
          color: (_data.colon.value === 'ปกติ' || _data.lung.value === 'ปกติ' ? 'green' : 'orange'),
          detail: [_data.colon]
        }
      } else return false;
    },
    breast: () => {
      if (_data.breast) {
        return {
          icon: 'breast',
          label: 'เต้านม',
          color: (_data.breast.value === 'ปกติ' || _data.lung.value === 'ปกติ' ? 'green' : 'orange'),
          detail: [_data.breast]
        }
      } else return false;
    },
    heart: () => {
      if (_data['sys/dias'] && _data.chol) {
        
        var _result = Heart({
          'sys/dias': _data['sys/dias'],
          chol: _data.chol
        });

        
        return {
          icon: 'hearth',
          label: 'หัวใจ',
          color: _result.detail.color,
          detail: [_result, _data['sys/dias'], _data.chol]
        }

      } else return false;
    },
    brain: () => {
      if (_data['sys/dias'] && _data.chol) {
        var _result = Brain({
          'sys/dias': _data['sys/dias'],
          chol: _data.chol
        });
        
        return {
          icon: 'brain',
          label: 'สมอง',
          color: _result.detail.color,
          detail: [_result, _data['sys/dias'], _data.chol]
        }
      } else return false;
    }
  };

  var _record = [];
  for (var i in _group) {
    _record.push(_group[i]());
  }
  return _record.filter(Boolean);
}