import React from 'react';

const FormElementSubTitle = ({data}) => {
  return (
    <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-inline formSubTitle">
          {data.label}
        </div>
    </div>
  )
}

export default FormElementSubTitle;
