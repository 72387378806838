import React, { useState, useEffect, useRef, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormHeartFailureDischargeCheckListPaper from "../FormPaper/FormHeartFailureDischargeCheckListPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormHeartFailureDischargeCheckList = (props) => {
  const formConstants = {
    formName: "form_heartfailuredischargechecklist",
    formType: "16",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const prepareData = useCallback((form, profile) => {
    return form;
  }, []);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all(
      [patientDataRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];

      data = prepareData({ ...data }, profile)
      setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType)
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null)
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });


  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="HEART FAILURE DISCHARGE CHECKLIST" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel
          label="โรงพยาบาลมหาราชนครเชียงใหม่ คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่ (PCT Cardiology)"
          fontWeight="normal"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel
          label="เลือกช่อง Yes ถ้าได้ทำหรือ เลือกช่อง No ถ้าไม่ได้ทำ"
          fontWeight="normal"
        />
      </div>

      <div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th colSpan={2}>หัวข้อ</th>
              <th>Yes</th>
              <th>No</th>
              <th>Reason Not Done/Contraindications</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan={16} className="center">
                แพทย์/เภสัชกร
              </th>
              <td>
                1. ปัจจัยที่ทำให้อาการกำเริบ * ได้รับการค้นหาและแก้ไขแล้ว{" "}
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"2"}
                  rows={1}
                  value={dataForm.data_2 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                2.ได้รับการตรวจ Echocardiogram แล้ว (ในรายที่ไม่เคยตรวจมาก่อน)
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementTextBox
                  fieldId={"105n"}
                  label="EF"
                  value={dataForm.data_105n}
                  type="decimal"
                  onChange={handleChange}
                  width="100"
                  maxLength={4}
                />
                %
              </td>
              <td>
                <FormElementRadio
                  fieldId={"3"}
                  value={dataForm.data_3}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"3"}
                  value={dataForm.data_3}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"4"}
                  rows={1}
                  value={dataForm.data_4 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>3.ไม่มีภาวะน้ำ เกินหรือขาดสารน้ำ อย่างชัดเจน</td>
              <td>
                <FormElementRadio
                  fieldId={"5"}
                  value={dataForm.data_5}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5"}
                  value={dataForm.data_5}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"6"}
                  rows={1}
                  value={dataForm.data_6 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                4. หยุด NTG, Dobutamine และ Furosemide
                ที่ให้ทางหลอดเลือดดำมากเกิน 24 ชม.
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7"}
                  value={dataForm.data_7}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7"}
                  value={dataForm.data_7}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"8"}
                  rows={1}
                  value={dataForm.data_8 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                5.
                เปลี่ยนจากยาขับปัสสาวะแบบฉีดเป็นแบบรับประทานแล้วและได้ประเมินผลว่ายาขับปัสสาวะแบบรับประทานสามารถควบคุมอาการผู้ป่วยได้
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9"}
                  value={dataForm.data_9}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9"}
                  value={dataForm.data_9}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"10"}
                  rows={1}
                  value={dataForm.data_10 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>6. ไม่มีความจำเป็นในการปรับยารับประทานมาเกิน 24 ชั่วโมง</td>
              <td>
                <FormElementRadio
                  fieldId={"11"}
                  value={dataForm.data_11}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11"}
                  value={dataForm.data_11}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"12"}
                  rows={1}
                  value={dataForm.data_12 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                7.
                ค่าการทำงานของไตและเกลือแร่คงที่และอยู่ในระดับปกติหรือใกล้เคียงปกติตาม
                baseline ของผู้ป่วย
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13"}
                  value={dataForm.data_13}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13"}
                  value={dataForm.data_13}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"14"}
                  rows={1}
                  value={dataForm.data_14 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                8. ในผู้ป่วย HFrEF ได้เริ่มยาดังนี้(ในรายใหม่) หรือปรับ dose
                ให้เหมาะสมแล้ว (ในรายเก่า)
                หากไม่สามารถให้ยาได้หรือจำเป็นต้องลดขนาดยาได้มีการบันทึกเหตุผลไว้แล้ว
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15"}
                  value={dataForm.data_15}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15"}
                  value={dataForm.data_15}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"16"}
                  rows={1}
                  value={dataForm.data_16 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>- ACEI/ARB/ARNI</td>
              <td>
                <FormElementRadio
                  fieldId={"17"}
                  value={dataForm.data_17}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"17"}
                  value={dataForm.data_17}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"18"}
                  rows={1}
                  value={dataForm.data_18 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>- Beta-blocker</td>
              <td>
                <FormElementRadio
                  fieldId={"19"}
                  value={dataForm.data_19}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"19"}
                  value={dataForm.data_19}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"20"}
                  rows={1}
                  value={dataForm.data_20 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>- Aldosterone Receptor Antagonists</td>
              <td>
                <FormElementRadio
                  fieldId={"21"}
                  value={dataForm.data_21}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"21"}
                  value={dataForm.data_21}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"22"}
                  rows={1}
                  value={dataForm.data_22 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>- SGLT2 inhibitor</td>
              <td>
                <FormElementRadio
                  fieldId={"23"}
                  value={dataForm.data_23}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"23"}
                  value={dataForm.data_23}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"24"}
                  rows={1}
                  value={dataForm.data_24 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                9.
                ปัจจัยเสี่ยงของโรคหัวใจและหลอดเลือดได้รับการประเมินและควบคุมแล้ว
                เช่น ระดับ LDL, FBS, HbA1C
              </td>
              <td>
                <FormElementRadio
                  fieldId={"25"}
                  value={dataForm.data_25}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"25"}
                  value={dataForm.data_25}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"26"}
                  rows={1}
                  value={dataForm.data_26 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                10.
                ผู้ป่วยได้รับการให้ความรู้เรื่องการปฏิบัติตัวในการดูแลตนเองสำหรับโรคหัวใจล้มเหลวโดยพยาบาลประจำหอผู้ป่วยหรือพยาบาลประจำ
                HF clinic
              </td>
              <td>
                <FormElementRadio
                  fieldId={"27"}
                  value={dataForm.data_27}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"27"}
                  value={dataForm.data_27}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"28"}
                  rows={1}
                  value={dataForm.data_28 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                11. มีการปรึกษาเพื่อนัดเข้าคลินิกหัวใจล้มเหลว ในรายที่เข้าเกณฑ์
              </td>
              <td>
                <FormElementRadio
                  fieldId={"29"}
                  value={dataForm.data_29}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"29"}
                  value={dataForm.data_29}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"30"}
                  rows={1}
                  value={dataForm.data_30 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                12. มีการนัดผู้ป่วย 1-2 สัปดาห์หลังจำหน่ายที่เรียบร้อยแล้ว
              </td>
              <td>
                <FormElementRadio
                  fieldId={"31"}
                  value={dataForm.data_31}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"31"}
                  value={dataForm.data_31}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"32"}
                  rows={1}
                  value={dataForm.data_32 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <th rowSpan={10} className="center">
                พยาบาล
              </th>
            </tr>
            <tr>
              <td colSpan={4}>
                <FormElementLabel
                  label="การวางแผนการจำหน่ายเน้นประเด็นเหล่านี้ "
                  fontWeight="normal"
                />
              </td>
            </tr>
            <tr>
              <td>1.ให้ผู้ป่วยเริ่ม Ambulate เพื่อประเมิน Function Capacity</td>
              <td>
                <FormElementRadio
                  fieldId={"33"}
                  value={dataForm.data_33}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"33"}
                  value={dataForm.data_33}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"34"}
                  rows={1}
                  value={dataForm.data_34 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>2. การสอนและให้คำแนะนำในหัวข้อดังนี้</td>
              <td>
                {/* <FormElementRadio
                  fieldId={"35"}
                  value={dataForm.data_35}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                /> */}
              </td>
              <td>
                {/* <FormElementRadio
                  fieldId={"35"}
                  value={dataForm.data_35}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                /> */}
              </td>
              <td>
                {/* <FormElementTextArea
                  fieldId={"36"}
                  rows={1}
                  value={dataForm.data_36 || ""}
                  onChange={handleChange}
                  width="100"
                /> */}
              </td>
            </tr>
            <tr>
              <td>
                การเฝ้าระวังภาวะน้ำเกิน สังเกตอาการปวดบวม -
                การเฝ้าระวังภาวะน้ำเกิน สังเกตอาการบวม การชั่งน้ำหนัก
                (แนะนำให้ผู้ป่วยมีเครื่องชั่งน้ำหนักที่บ้าน)
              </td>
              <td>
                <FormElementRadio
                  fieldId={"37"}
                  value={dataForm.data_37}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"37"}
                  value={dataForm.data_37}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"38"}
                  rows={1}
                  value={dataForm.data_38 || ""}
                  onChange={handleChange}
                  width="100"
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                - การรับประทานยาอย่างต่อเนื่องและถูกต้อง หลีกเลี่ยงยาสมุนไพรยา
                NSAID
              </td>
              <td>
                <FormElementRadio
                  fieldId={"39"}
                  value={dataForm.data_39}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"39"}
                  value={dataForm.data_39}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"40"}
                  value={dataForm.data_40 || ""}
                  onChange={handleChange}
                  rows={1}
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>- การงดเหล้าและบุหรี่</td>
              <td>
                <FormElementRadio
                  fieldId={"41"}
                  value={dataForm.data_41}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"41"}
                  value={dataForm.data_41}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"42"}
                  value={dataForm.data_42 || ""}
                  onChange={handleChange}
                  rows={1}
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                - การหลีกเลี่ยงอาหารรสเค็ม การจำกัดน้ำดื่ม (ในบางรายการ)
                โดยเฉพาะผู้ป่วยที่ยังบวม, ผู้ป่วยโรคไต
              </td>
              <td>
                <FormElementRadio
                  fieldId={"43"}
                  value={dataForm.data_43}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"43"}
                  value={dataForm.data_43}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"44"}
                  value={dataForm.data_44 || ""}
                  onChange={handleChange}
                  rows={1}
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>- การกลับไปทำงาน การทำกิจกรรมและการออกกำลังกายที่เหมาะสม</td>
              <td>
                <FormElementRadio
                  fieldId={"45"}
                  value={dataForm.data_45}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"45"}
                  value={dataForm.data_45}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"46"}
                  value={dataForm.data_46 || ""}
                  onChange={handleChange}
                  rows={1}
                  noLimit
                />
              </td>
            </tr>
            <tr>
              <td>
                - การสอนการปรับยาขับปัสสาวะโดยผู้ป่วยเอง (Flexible diuretic
                regimen)
              </td>
              <td>
                <FormElementRadio
                  fieldId={"47"}
                  value={dataForm.data_47}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"47"}
                  value={dataForm.data_47}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"48"}
                  value={dataForm.data_48 || ""}
                  onChange={handleChange}
                  rows={1}
                  noLimit
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel
          label="*ปัจจัยที่ทำให้อาการกำเริบเช่น – Noncompliance เรื่องการรับประทานยา อาหาร และน้ำดื่ม /การได้รับยา NSAID"
          fontWeight="normal"
        />
        <br />
        <FormElementLabel
          label="/ COX 2 inhibitor / ยาเบาหวานกลุ่ม TZD / ภาวะโรคร่วมกำเริบ, ภาวะซีดมาก ไทรอยด์เป็นพิษ ภาวะติดเชื้อ"
          fontWeight="normal"
        />
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={<FormHeartFailureDischargeCheckListPaper
          dataForm={dataForm}
          patient={patient}
          profile={profile}
        />}
      />
    </div>
  );
};

export default FormHeartFailureDischargeCheckList;
