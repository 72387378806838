import React, { Component } from "react";
import Api from "../Components/Api.js";
import ReactEcharts from "echarts-for-react";
import moment from 'moment';

var _month_name = {
  '01' : 'Jan',
  '02' : 'Feb',
  '03' : 'Mar',
  '04' : 'Apr',
  '05' : 'May',
  '06' : 'Jun',
  '07' : 'Jul',
  '08' : 'Aug',
  '09' : 'Sep',
  '10' : 'Oct',
  '11' : 'Nov',
  '12' : 'Dec'
}

export default class TelemonitoringChat extends Component {
  state = {
    amount: 0,
    loadingApi: true,
    dataX: [],
    dataY: []
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    var _result = await Api.dietzdashboard("TelemonitoringChat");

    if (!_result.data) _result.data = [];

    var _init_data = {};
    for(var i=12; i-->0;) {
      _init_data[moment().subtract(i, 'month').format('YYYY-MM')] = 0;

    }

    
    var _dataX = [];
    var _dataY = [];
    var _amount =0;


    _result.data.forEach(function (data, index) {
      if (index < 12) {
        _init_data[data.yearmonth] = +data.amount;
      }

      _amount += +data.amount;

    });

    for(var i in _init_data) {
      _dataY.push(_init_data[i]);
      _dataX.push(_month_name[i.split('-')[1]])
    }


    this.setState({
      dataX: _dataX,
      dataY: _dataY,
      amount: _amount,
      loadingApi: false,
    });
  }

  renderChart = () => {
    var chartOptions = {
      xAxis: {
        type: "category",
        data: this.state.dataX,
        axisLine: {
          lineStyle: {
            width: 0,
            color: '#AAA'
          }
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            width: 0,
            color: '#AAA'
          }
        },
      },
      series: [
        {
          data: this.state.dataY,
          type: "line",
          smooth: true,
          itemStyle: {
              borderWidth: 3,
              borderColor: '#4e73df',
              color: '#4e73df'
          }
        },
      ],
      color: ['#4e73df'],
      // Enable drag recalculate
      calculable: true,

      // Add tooltip
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // Pointer axis, the axis trigger effective
          type: 'shadow'        // The default is a straight line, optionally: 'line' | 'shadow'
        },
      },
    };

    return (
      <ReactEcharts
        option={chartOptions}
        style={{ height: 250 }}
        lazyUpdate={true}
      />
    );
  };

  render() {
    return (
      <div className="dd-card dd-shadow">
        <div className="dd-card-header">
        Chat
        </div>
        <div className="uk-padding">
        {this.state.loadingApi === true ? (
          <div>
            <span uk-spinner=""></span>
          </div>
        ) : this.renderChart()
          
        }

        </div>
      </div>
    );
  }
}
