import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import Api from '../Api';

export default class SideMenu extends Component {

  state = {
    showSideMenu: false,
    chatUnread: 0,
    teleconsultAmount: 0,
    videoCallShow: false,
    videoCallMemberId: null,
    videoCallConsultId: null,
    videoCallCampaignId: null,
    videoCallName: '',
    videoCallImage: '',
    teleconsultVideoCallShow: false,
    teleconsultVideoCallName: '',
    teleconsultVideoCallImage : '',
    teleconsultVideoCallConsultid : ''
  }


  componentDidMount() {
    setInterval(()=>{
      //this.unreadChatGet();
    }, 10000)

    //this.firebaseListenUpdate();
  }

  teleconsultFromConsultIdGet = async (consultId) => {
    var _response = await Api.consult('TeleconsultFromConsultIdGet', {
      consultId
    });

    if(_response.result === true) {
      this.setState({
        teleconsultVideoCallName: `${_response.data.nameprefix} ${_response.data.name} ${_response.data.lastname}`,
        teleconsultVideoCallImage : _response.data.image,
        teleconsultVideoCallShow: true,
        teleconsultVideoCallConsultid: consultId
      })

    } else {
      this.setState({
        teleconsultVideoCallName: '',
        teleconsultVideoCallImage : '',
        teleconsultVideoCallShow: false,
        teleconsultVideoCallConsultid: ''

      })
    }
  }

  chatListGet = async () => {
    var {
      list
    } = await Api.consult("ChatListV2", {
      campaignId: 'all',
      chatListPage: 1,
      keyword: "",
      filterChatTypeSelect: "all"
    });

    if(Array.isArray(list))
      list.forEach((data) => {
        if(data.chatLast != null && parseInt(data.unreadConsult) > 0 && data.chatLast.name.includes('มีการเรียกระบบวีดีโอคอล') === true) {

          this.setState({
            videoCallShow: true,
            videoCallName: `${data.nameprefix} ${data.name} ${data.lastname}`,
            videoCallImage: data.image,
            videoCallMemberId: data.memberid,
            videoCallConsultId: data.consultid,
            videoCallCampaignId: data.campaignid
          })

          return false;
        }
      })
  }
  
  unreadChatGet = async () => {
    var {amount, teleconsultAmount} = await Api.consult('ChatUnread');
    this.setState({chatUnread: amount || 0, teleconsultAmount: teleconsultAmount || 0});
  }

  menuRender = () => {
    var menu = [
      {
        icon: 'user',
        label: 'User',
        link: '/consult'
      },
      {
        icon: 'event',
        label: 'ใบนัดหมาย',
        link: '/consult/appointmentList'
      },
      {
        icon: 'book-open',
        label: 'บันทึกการดูแล (Visit Note)',
        link: '/consult/visitNote'
      },
      {
        icon: 'book-open',
        label: 'รายการแบบฟอร์ม',
        link: '/consult/formList'
      },
      {
        icon: 'docs',
        label: 'สรุปผลการดำเนินงาน',
        link: '/consult/consultSMCReport'
      },
      {
        icon: 'note',
        label: 'ตั้งค่าห้องจ่ายยา',
        link: '/consult/slotManagement'
      },
      /*
      
      {
        icon: 'notebook',
        label: 'ตารางน้ำตาลเบาหวานขณะตั้งครรภ์',
        link: '/consult/diabetes'
      },
      {
        icon: 'speech',
        label: 'Teleconsult (Doctor to Doctor)',
        link: '/consult/teleConsult'
      },
      {
        icon: 'pie-chart',
        label: 'สรุปผลคนไข้',
        link: '/consult/memberSummary'
      },
      {
        icon: 'graph',
        label: 'สรุปการดำเนินงาน',
        link: '/consult/consultStatistic'
      },
      
      */
      /*
      {
        icon: 'camrecorder',
        label: 'Video Call (Demo)',
        link: 'https://con.defence-innovation.com/UpperInitiativesFulfilQuietly',
        type: 'a'
      }*/
    ];

    var _nowpath = window.location.pathname;

    return <ul className="uk-list">{
      menu.map((data, key) => {
        var _noti = null;

        if(data.label === 'Chat/Video call') {
          _noti = +this.state.chatUnread > 0 ? <span className="uk-badge uk-float-right uk-button-danger" >{this.state.chatUnread}</span> : null
        } else if(data.label === 'Teleconsult (Doctor to Doctor)') {
          _noti = +this.state.teleconsultAmount > 0 ? <span className="uk-badge uk-float-right uk-button-danger" >{this.state.teleconsultAmount}</span> : null
        }

        var _text = <div>
          <span className={'icon-' + data.icon}></span>{' '}
          <span className="d-side-menu-show-hover">{_noti} {data.label}</span>
        </div>

        return <li key={'sideMenu' + key}>
          {
            data.type === 'a' ?
              <a className="d-font-mon" href={data.link} target="_blank" rel="_noopener">{_text}</a>
              :
              <Link to={data.link} className={'d-font-mon ' + (data.link === '/consult' ? (data.link === _nowpath || (_nowpath === '/' && data.link==='/consult') ? 'd-side-menu-active' : '') : (_nowpath.indexOf(data.link) >= 0 ? 'd-side-menu-active' : ''))}
                onClick={() => this.setState({ showSideMenu: false })}
              >
                {_text}
              </Link>
          }

        </li>
      })
    }
    </ul>
  }

  render() {
    return <div>
      {this.state.videoCallShow === true && <VideoCallBox 
      apiType="consult" 
      campaignId={this.state.videoCallCampaignId} 
      consultId={this.state.videoCallConsultId} 
      memberId={this.state.videoCallMemberId} 
      name={this.state.videoCallName} 
      buttonTitle='คุยกับคนไข้' 
      image={this.state.videoCallImage} 
      closeCallback={()=>{
        this.setState({videoCallShow: false});
      }}
    />}

      {this.state.teleconsultVideoCallShow === true && <TeleconsultViedeoCallBox {...this.props}
      name={this.state.teleconsultVideoCallName}
      image={this.state.teleconsultVideoCallImage}
      fromConsultId={this.state.teleconsultVideoCallConsultid}
      closeCallback={()=> {
        firebase.database().ref('teleconsult_videocall/' + this.props.consult.id).set(null);

        this.setState({
          teleconsultVideoCallName: '',
          teleconsultVideoCallImage : '',
          teleconsultVideoCallShow: false,
          teleconsultVideoCallConsultid: ''
          
        })
      }}
      /> }
      {this.state.showSideMenu && <div id="d-side-menu-overlay" className="uk-animation-fade" onClick={() => this.setState({ showSideMenu: false })}></div>}
      <div id="d-header-menu-mobile" uk-sticky="">
        <div className="uk-grid-collapse" uk-grid="">
          <div className="uk-width-1-4">
            <a className="d-header-menu-mobile-button" onClick={() => {
              this.setState({ showSideMenu: !this.state.showSideMenu });
            }}>
              <i className="icon-menu"></i>
            </a>
          </div>
          <div className="uk-width-1-2 uk-flex-middle uk-text-center">
            <img src="/assets/stack-logo-light.png" className="uk-margin-small-right" />
            <img src="/assets/logo_dietz.png" />
          </div>
          <div className="uk-width-1-4 uk-text-right">
            <a className="d-header-menu-mobile-button" onClick={() => {
              
              setTimeout(function() {
                $("#d-header-menu").toggleClass('d-header-menu-active uk-animation-slide-top-small')
              }, 50);
            }}>
              <i className="icon-options-vertical"></i>
            </a>
          </div>
        </div>
      </div>
      <div className={"d-side-menu " + (this.state.showSideMenu ? 'd-side-menu-active' : '')}>
        <div className="d-side-menu-logo-container uk-flex-middle">
          <img src="/assets/stack-logo-light.png" className="uk-margin-small-right" />
          <img src="/assets/logo_dietz.png" />
        </div>
        <div className="d-side-menu-title-container d-font-mon d-side-menu-show-hover">
          GENERAL
        </div>
        {this.menuRender()}
      </div>
    </div>
  }
};
