import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import FormElementTagInput from "../Form/FormElementTagInput";
import FormElementIconButton from "../Form/FormElementIconButton";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormHeartFailurePaper from "../FormPaper/FormHeartFailurePaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormHeartFailure = (props) => {
  const formConstants = {
    formName: "form_heartfailure",
    formType: "14",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const prepareData = useCallback((form, profile) => {
    return form;
  }, []);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all(
      [patientDataRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];
      data = prepareData({ ...data }, profile)
      setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType)
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null)
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const onTagInputChange = useCallback((fieldId, data) => {
    var valueData = [];
    if (data) {
      valueData = data.map((value, index) => ({
        key: index,
        value: value,
      }));
    }
    setDataForm((data) => ({ ...data, [fieldId]: valueData }));
  });

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Heart Failure Data ศูนย์โรคหัวใจภาคเหนือ โรงพยาบาลมหาราชนครเชียงใหม่" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"1"}
          value={dataForm.data_1}
          label="HF No."
          onChange={handleChange}
          width="100"
        />
      </div>
      <div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <tbody>
            <tr></tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="1.  Patient profiles" />
        <br />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"2n"}
            value={dataForm.data_2n}
            type="decimal"
            label="ส่วนสูง"
            onChange={handleChange}
            width="100"
            maxLength={6}
          />
          <FormElementTextBox
            fieldId={"3n"}
            value={dataForm.data_3n}
            type="decimal"
            label="น้ำหนัก"
            onChange={handleChange}
            width="100"
            maxLength={6}
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"4"}
            value={dataForm.data_4}
            label="สถานภาพผู้ป่วย"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"5"}
            value={dataForm.data_5}
            label="ศาสนา"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"6"}
            value={dataForm.data_6}
            label="อาชีพ"
            onChange={handleChange}
            width="100"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"7n"}
            value={dataForm.data_7n}
            type="numeric"
            label="จำนวนสมาชิกในครอบครัว"
            onChange={handleChange}
            width="100"
          />
          <span className="formTextBoxLabel">คน</span>
          <FormElementTextBox
            fieldId={"8n"}
            value={dataForm.data_8n}
            type="numeric"
            label="รายได้ของครอบครัวต่อเดือน"
            onChange={handleChange}
            width="100"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"9"}
            value={dataForm.data_9}
            label="ภูมิลำเนา"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"11"}
            value={dataForm.data_11}
            type="numeric"
            label="เบอร์โทรศัพท์ผู้ป่วย"
            maxLength={10}
            onChange={handleChange}
            width="100"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextArea
            fieldId={"10"}
            value={dataForm.data_10}
            label="ที่อยู่"
            onChange={handleChange}
            width="100"
            cols={120}
            rows={2}
            noLimit
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"12"}
            value={dataForm.data_12}
            label="ชื่อผู้ดูแล"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"13"}
            value={dataForm.data_13}
            label="ความสัมพันธ์กับผู้ป่วย"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"14"}
            value={dataForm.data_14}
            type="numeric"
            label="เบอร์โทรศัพท์ผู้ดูแล"
            maxLength={10}
            onChange={handleChange}
            width="100"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"15"}
            value={dataForm.data_15}
            label="ชื่อญาติ"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"16"}
            value={dataForm.data_16}
            type="numeric"
            label="เบอร์โทรศัพท์ญาติ"
            maxLength={10}
            onChange={handleChange}
            width="100"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"17"}
            value={dataForm.data_17}
            label="แหล่งสนับสนุนทางสังคมหรือผู้ให้ความช่วยเหลือทางการเงิน"
            onChange={handleChange}
            width="100"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="2. Admission profiles" />
        <br />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"18"}
            value={dataForm.data_18}
            type="date"
            label="รับเข้าไว้โครงการ date"
            onChange={handleChange}
            width="100"
          />
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
          style={{ marginLeft: "100px" }}
        >
          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementRadio
              fieldId={"19"}
              value={dataForm.data_19}
              fieldValue={"1"}
              onChange={handleChange}
              label="IPD (AHF)"
            />
            <span
              className={dataForm.data_19 == "1" ? "form-show" : "form-hidden"}
            >
              <FormElementTextBox
                fieldId={"20"}
                type="date"
                value={dataForm.data_20}
                onChange={handleChange}
                label="Admit Date"
              />
              <FormElementTextBox
                fieldId={"21"}
                type="date"
                value={dataForm.data_21}
                onChange={handleChange}
                label="Discharge Date"
              />
            </span>
          </div>
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
          style={{ marginLeft: "100px" }}
        >
          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementRadio
              fieldId={"19"}
              value={dataForm.data_19}
              fieldValue={"2"}
              onChange={handleChange}
              label="OPD"
            />
            <span
              className={dataForm.data_19 == "2" ? "form-show" : "form-hidden"}
            >
              <div
                className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                style={{ marginLeft: "20px" }}
              >
                <FormElementRadio
                  fieldId={"22"}
                  value={dataForm.data_22}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=" CHF"
                />
                <FormElementRadio
                  fieldId={"22"}
                  value={dataForm.data_22}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Recent HF hospitalities ; < 6 month "
                />
                <br />
              </div>
              <div
                className="uk-width-1-1@m uk-width-1-1 uk-padding-small"
                style={{ marginLeft: "20px" }}
              >
                <FormElementTextBox
                  fieldId={"23"}
                  type="date"
                  value={dataForm.data_23}
                  onChange={handleChange}
                  label="Last Admit Date"
                />
                <FormElementTextBox
                  fieldId={"24"}
                  type="date"
                  value={dataForm.data_24}
                  onChange={handleChange}
                  label="Last Discharge Date"
                />
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="3. ประวัติการนอนโรงพยาบาล(ถ้ามี)" />
        <br />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"25"}
            value={dataForm.data_25}
            fieldValue={"1"}
            onChange={handleChange}
            label="ครั้งนี้/"
          />
          <FormElementRadio
            fieldId={"25"}
            value={dataForm.data_25}
            fieldValue={"2"}
            onChange={handleChange}
            label="ครั้งล่าสุด"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextArea
            fieldId={"26"}
            rows={10}
            cols={120}
            value={dataForm.data_26}
            onChange={handleChange}
            noLimit
            width="100"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="4. Heart Failure History Etiology: Check if history of:" />
        <br />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"27"}
            value={dataForm.data_27}
            fieldValue={"1"}
            onChange={handleChange}
            label="Ischemic/CAD"
          />
          <span
            className={dataForm.data_27 == "1" ? "form-show" : "form-hidden"}
          >
            <FormElementTextBox
              fieldId={"28"}
              value={dataForm.data_28}
              rows={2}
              cols={50}
              onChange={handleChange}
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"27"}
            value={dataForm.data_27}
            fieldValue={"2"}
            onChange={handleChange}
            label="Non-Ischemic"
          />
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
          style={{ marginLeft: "100px" }}
        >
          <span
            className={dataForm.data_27 == "2" ? "form-show" : "form-hidden"}
          >
            <FormElementCheckBox
              fieldId={"29"}
              value={dataForm.data_29}
              onChange={handleChange}
              label="Alcohol/Other Drug"
            />
            <FormElementCheckBox
              fieldId={"30"}
              value={dataForm.data_30}
              onChange={handleChange}
              label="Chemotherapy"
            />
            <FormElementCheckBox
              fieldId={"31"}
              value={dataForm.data_31}
              onChange={handleChange}
              label="Familial"
            />
            <FormElementCheckBox
              fieldId={"32"}
              value={dataForm.data_32}
              onChange={handleChange}
              label="Hypertensive"
            />
            <FormElementCheckBox
              fieldId={"33"}
              value={dataForm.data_33}
              onChange={handleChange}
              label="Peripartum"
            />
            <FormElementCheckBox
              fieldId={"34"}
              value={dataForm.data_34}
              onChange={handleChange}
              label="Viral"
            />
            <FormElementCheckBox
              fieldId={"35"}
              value={dataForm.data_35}
              onChange={handleChange}
              label="Other Etiology"
            />
            <FormElementCheckBox
              fieldId={"36"}
              value={dataForm.data_36}
              onChange={handleChange}
              label="Unknown Etiology"
            />
          </span>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 ">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>Known history of HF prior to this visit ?</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"37"}
            value={dataForm.data_37}
            fieldValue={"Y"}
            onChange={handleChange}
            label="Yes"
          />
          <FormElementRadio
            fieldId={"37"}
            value={dataForm.data_37}
            fieldValue={"N"}
            onChange={handleChange}
            label="No"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong># of hospital admissions in past 6 mo. for HF:</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"38"}
            value={dataForm.data_38}
            fieldValue={"0"}
            onChange={handleChange}
            label="0"
          />
          <FormElementRadio
            fieldId={"38"}
            value={dataForm.data_38}
            fieldValue={"1"}
            onChange={handleChange}
            label="1"
          />
          <FormElementRadio
            fieldId={"38"}
            value={dataForm.data_38}
            fieldValue={"2"}
            onChange={handleChange}
            label="2"
          />
          <FormElementRadio
            fieldId={"38"}
            value={dataForm.data_38}
            fieldValue={"3"}
            onChange={handleChange}
            label=">2"
          />
          <span
            className={dataForm.data_38 == "3" ? "form-show" : "form-hidden"}
          >
            <FormElementTextBox
              fieldId={"39"}
              value={dataForm.data_39}
              onChange={handleChange}
            />
          </span>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>Heart Failure Diagnosis</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"40"}
            value={dataForm.data_40}
            fieldValue={"1"}
            onChange={handleChange}
            label="Heart Failure, primary diagnosis, with CAD"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"40"}
            value={dataForm.data_40}
            fieldValue={"2"}
            onChange={handleChange}
            label="Heart Failure, primary diagnosis, no CAD"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"40"}
            value={dataForm.data_40}
            fieldValue={"3"}
            onChange={handleChange}
            label="Heart Failure, secondary diagnosis"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>
            Atrial Fibrillation (At presentation or during hospitalization)
          </strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"41"}
            value={dataForm.data_41}
            fieldValue={"1"}
            onChange={handleChange}
            label="Yes"
          />
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            hidden={dataForm.data_41 != "1"}
          >
            <FormElementCheckBox
              fieldId={"196"}
              value={dataForm.data_196}
              onChange={handleChange}
              label="Documented New Onset?"
            />
            <FormElementTextBox
              fieldId={"197"}
              value={dataForm.data_197}
              onChange={handleChange}
              type="date"
            />
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"41"}
            value={dataForm.data_41}
            fieldValue={"2"}
            onChange={handleChange}
            label="No"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"></div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>
            Atrial Flutter (At presentation or during hospitalization)
          </strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"42"}
            value={dataForm.data_42}
            fieldValue={"1"}
            onChange={handleChange}
            label="Yes"
          />
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            hidden={dataForm.data_42 != "1"}
          >
            <FormElementCheckBox
              fieldId={"198"}
              value={dataForm.data_198}
              onChange={handleChange}
              label="Documented New Onset?"
            />
            <FormElementTextBox
              fieldId={"199"}
              value={dataForm.data_199}
              onChange={handleChange}
              type="date"
            />
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"42"}
            value={dataForm.data_42}
            fieldValue={"2"}
            onChange={handleChange}
            label="No"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>
            Characterization of HF at admission or when first recognized
          </strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementCheckBox
            fieldId={"43"}
            value={dataForm.data_43}
            onChange={handleChange}
            label="Acute Pulmonary Edema"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"44"}
            value={dataForm.data_44}
            onChange={handleChange}
            label="Dizziness/Syncope"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"45"}
            value={dataForm.data_45}
            onChange={handleChange}
            label="Dyspnea"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"46"}
            value={dataForm.data_46}
            onChange={handleChange}
            label="ICD Shock/Sustained"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"47"}
            value={dataForm.data_47}
            onChange={handleChange}
            label="Ventricular Arrhythmia"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"48"}
            value={dataForm.data_48}
            onChange={handleChange}
            label="Pulmonary Congestion"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"49"}
            value={dataForm.data_49}
            onChange={handleChange}
            label="Volume overload/Weight Gain"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"50"}
            value={dataForm.data_50}
            onChange={handleChange}
            label="Worsening fatigue"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"51"}
            value={dataForm.data_51}
            onChange={handleChange}
            label="Other"
          />
          <span hidden={dataForm.data_51 != "1"}>
            <FormElementTextBox
              fieldId={"203"}
              value={dataForm.data_203}
              onChange={handleChange}
              placeholder="ระบุ"
            />
          </span>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>
            Other Conditions Contributing to HF Exacerbation (Select all that
            apply)
          </strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementCheckBox
            fieldId={"52"}
            value={dataForm.data_52}
            onChange={handleChange}
            label="Arrhythmia"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"53"}
            value={dataForm.data_53}
            onChange={handleChange}
            label="Pneumonia/respiratory process"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"54"}
            value={dataForm.data_54}
            onChange={handleChange}
            label="Noncompliance - medication"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"55"}
            value={dataForm.data_55}
            onChange={handleChange}
            label="Worsening Renal Failure"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"56"}
            value={dataForm.data_56}
            onChange={handleChange}
            label="Ischemia/ACS"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"57"}
            value={dataForm.data_57}
            onChange={handleChange}
            label="Uncontrolled HTN"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"58"}
            value={dataForm.data_58}
            onChange={handleChange}
            label="Noncompliance – Dietary"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"59"}
            value={dataForm.data_59}
            onChange={handleChange}
            label="Other"
          />
          <span hidden={dataForm.data_59 != "1"}>
            <FormElementTextBox
              fieldId={"204"}
              value={dataForm.data_204}
              onChange={handleChange}
              placeholder="ระบุ"
            />
          </span>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom" />
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="5. First presentation Symptoms (Closest to Admission) Select all that apply" />
        <br />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementCheckBox
            fieldId={"60"}
            value={dataForm.data_60}
            onChange={handleChange}
            label="Chest Pain"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"61"}
            value={dataForm.data_61}
            onChange={handleChange}
            label="Orthopnea"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"62"}
            value={dataForm.data_62}
            onChange={handleChange}
            label="Palpitations"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"63"}
            value={dataForm.data_63}
            onChange={handleChange}
            label="Dyspnea at rest"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"64"}
            value={dataForm.data_64}
            onChange={handleChange}
            label="Fatigue"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"65"}
            value={dataForm.data_65}
            onChange={handleChange}
            label="PND"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"66"}
            value={dataForm.data_66}
            onChange={handleChange}
            label="Dyspnea on Exertion"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"67"}
            value={dataForm.data_67}
            onChange={handleChange}
            label="Decreased appetite/early satiety"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"68"}
            value={dataForm.data_68}
            onChange={handleChange}
            label="Dizziness/lightheadedness/syncope"
          />
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
          style={{ marginLeft: "20px" }}
        >
          <span
            className={dataForm.data_68 == "1" ? "form-show" : "form-hidden"}
          >
            <span className="formTextBoxLabel">NYHA class</span>
            <FormElementRadio
              fieldId={"69"}
              value={dataForm.data_69}
              fieldValue={"1"}
              onChange={handleChange}
              label="I"
            />
            <FormElementRadio
              fieldId={"69"}
              value={dataForm.data_69}
              fieldValue={"2"}
              onChange={handleChange}
              label="II"
            />
            <FormElementRadio
              fieldId={"69"}
              value={dataForm.data_69}
              fieldValue={"3"}
              onChange={handleChange}
              label="III"
            />
            <FormElementRadio
              fieldId={"69"}
              value={dataForm.data_69}
              fieldValue={"4"}
              onChange={handleChange}
              label="IV"
            />
          </span>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Medical History" />
        <br />
        <FormElementLabel label="Medical History (Select all that apply):" />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"70"}
            value={dataForm.data_70}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Anemia"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"71"}
            value={dataForm.data_71}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Atrial Fib (chronic or recurrent)"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"72"}
            value={dataForm.data_72}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Atrial Flutter (chronic or recurrent)"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"73"}
            value={dataForm.data_73}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="CAD"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"74"}
            value={dataForm.data_74}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="CardioMEMs (implantable hemodynamic monitor)"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"75"}
            value={dataForm.data_75}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="COPD or Asthma"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"76"}
            value={dataForm.data_76}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="CRT-D (cardiac resynchronization therapy with ICD)"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"77"}
            value={dataForm.data_77}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="CRT-P (cardiac resynchronization therapy-pacing only)"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"78"}
            value={dataForm.data_78}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="CVA/TIA"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"79"}
            value={dataForm.data_79}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Depression"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"80"}
            value={dataForm.data_80}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Diabetes"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"81"}
            value={dataForm.data_81}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Dialysis (chronic)"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"82"}
            value={dataForm.data_82}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Emerging Infectious Disease"
          />
        </div>
        <span className={dataForm.data_82 == "1" ? "form-show" : "form-hidden"}>
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            style={{ marginLeft: "34px" }}
          >
            <FormElementRadio
              fieldId={"103"}
              value={dataForm.data_103}
              fieldValue={"1"}
              onChange={handleChange}
              label="Flu "
            />
            <FormElementRadio
              fieldId={"103"}
              value={dataForm.data_103}
              fieldValue={"2"}
              onChange={handleChange}
              label="others infections"
            />
          </div>
        </span>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"83"}
            value={dataForm.data_83}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Familial hypercholesterolemia"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"84"}
            value={dataForm.data_84}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Heart Transplant"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"85"}
            value={dataForm.data_85}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Hyperlipidemia"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"86"}
            value={dataForm.data_86}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Hypertension"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"87"}
            value={dataForm.data_87}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="ICD only"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"88"}
            value={dataForm.data_88}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Kidney Transplant"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"89"}
            value={dataForm.data_89}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Left Ventricular Assist Device"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"90"}
            value={dataForm.data_90}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Pacemaker"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"91"}
            value={dataForm.data_91}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Peripheral Vascular Disease"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"92"}
            value={dataForm.data_92}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Prior CABG"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"93"}
            value={dataForm.data_93}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Prior MI"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"94"}
            value={dataForm.data_94}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Prior PCI"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"95"}
            value={dataForm.data_95}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Renal insufficiency - chronic (SCr>2.0) Cr cl<60"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"96"}
            value={dataForm.data_96}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Sleep-Disordered Breathing"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"97"}
            value={dataForm.data_97}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="TAVR"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"98"}
            value={dataForm.data_98}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="TMVR"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"99"}
            value={dataForm.data_99}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Tricuspid Valve procedure"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"100"}
            value={dataForm.data_100}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Valvular Heart Disease"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"101"}
            value={dataForm.data_101}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Ventricular assist device"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"102"}
            value={dataForm.data_102}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="No Medical History"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>Diabetes Type:</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"104"}
            value={dataForm.data_104}
            fieldValue={"1"}
            onChange={handleChange}
            label="Type 1"
          />
          <FormElementRadio
            fieldId={"104"}
            value={dataForm.data_104}
            fieldValue={"2"}
            onChange={handleChange}
            label="Type 2"
          />
          <FormElementRadio
            fieldId={"104"}
            value={dataForm.data_104}
            fieldValue={"3"}
            onChange={handleChange}
            label="ND"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>Diabetes Duration:</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"105"}
            value={dataForm.data_105}
            fieldValue={"1"}
            onChange={handleChange}
            label="New Diagnosis of Diabetes"
          />
          <span
            className={dataForm.data_105 == "1" ? "form-show" : "form-hidden"}
          >
            <FormElementRadio
              fieldId={"106"}
              value={dataForm.data_106}
              fieldValue={"1"}
              onChange={handleChange}
              label="<5 years"
            />
            <FormElementRadio
              fieldId={"106"}
              value={dataForm.data_106}
              fieldValue={"2"}
              onChange={handleChange}
              label="5 - <10 years"
            />
            <FormElementRadio
              fieldId={"106"}
              value={dataForm.data_106}
              fieldValue={"3"}
              onChange={handleChange}
              label="10 - <20 years"
            />
            <FormElementRadio
              fieldId={"106"}
              value={dataForm.data_106}
              fieldValue={"4"}
              onChange={handleChange}
              label=">=20 years"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"105"}
            value={dataForm.data_105}
            fieldValue={"2"}
            onChange={handleChange}
            label="Unknown"
          />
        </div>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>Sleep-Disordered Breathing Type:</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"107"}
            value={dataForm.data_107}
            fieldValue={"1"}
            onChange={handleChange}
            label="Obstructive"
          />
          <FormElementRadio
            fieldId={"107"}
            value={dataForm.data_107}
            fieldValue={"2"}
            onChange={handleChange}
            label="Central"
          />
          <FormElementRadio
            fieldId={"107"}
            value={dataForm.data_107}
            fieldValue={"3"}
            onChange={handleChange}
            label="Mixed"
          />
          <FormElementRadio
            fieldId={"107"}
            value={dataForm.data_107}
            fieldValue={"4"}
            onChange={handleChange}
            label="Unknown/Not Documented"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>Equipment used at home:</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"108"}
            value={dataForm.data_108}
            fieldValue={"1"}
            onChange={handleChange}
            label="O2"
          />
          <FormElementRadio
            fieldId={"108"}
            value={dataForm.data_108}
            fieldValue={"2"}
            onChange={handleChange}
            label="CPAP"
          />
          <FormElementRadio
            fieldId={"108"}
            value={dataForm.data_108}
            fieldValue={"3"}
            onChange={handleChange}
            label="Adaptive Servo-Ventilation"
          />
          <FormElementRadio
            fieldId={"108"}
            value={dataForm.data_108}
            fieldValue={"4"}
            onChange={handleChange}
            label="None"
          />
          <FormElementRadio
            fieldId={"108"}
            value={dataForm.data_108}
            fieldValue={"5"}
            onChange={handleChange}
            label="Unknown/Not Documented"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>History of cigarette smoking? (In the past 12 months)</strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"109"}
            value={dataForm.data_109}
            fieldValue={"Y"}
            onChange={handleChange}
            label="Yes"
          />
          <FormElementRadio
            fieldId={"109"}
            value={dataForm.data_109}
            fieldValue={"N"}
            onChange={handleChange}
            label="No"
          />
        </div>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <strong>
            History of vaping or e-cigarette use in the past 12 months?
          </strong>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
          <FormElementRadio
            fieldId={"110"}
            value={dataForm.data_110}
            fieldValue={"Y"}
            onChange={handleChange}
            label="Yes"
          />
          <FormElementRadio
            fieldId={"110"}
            value={dataForm.data_110}
            fieldValue={"N"}
            onChange={handleChange}
            label="No/ND"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom" />
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Medications Used Prior to Admission or HF consutation: [Select all that apply]" />
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>Drug</th>
              <th style={{ maxWidth: "400px" }}>แรกรับ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"111"}
                  value={dataForm.data_111}
                  onChange={handleChange}
                  label="Patient on no meds prior to admission"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_112
                      ? dataForm.data_112.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_112", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_111 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"113"}
                  value={dataForm.data_113}
                  onChange={handleChange}
                  label="Anti platelet agent"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_114
                      ? dataForm.data_114.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_114", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_113 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"115"}
                  value={dataForm.data_115}
                  onChange={handleChange}
                  label="Anticoagulation Therapy"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_116
                      ? dataForm.data_116.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_116", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_115 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <FormElementRadio
                    fieldId={"117"}
                    value={dataForm.data_117}
                    onChange={handleChange}
                    fieldValue="1"
                    label="ACE Inhibitor Angiotensin receptor blocker (ARB) Angiotensin Receptor Neprilysin Inhibitor (ARNI)"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                  <FormElementRadio
                    fieldId={"117"}
                    value={dataForm.data_117}
                    onChange={handleChange}
                    fieldValue="2"
                    label="Angiotensin receptor blocker (ARB)"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                  <FormElementRadio
                    fieldId={"117"}
                    value={dataForm.data_117}
                    onChange={handleChange}
                    fieldValue="3"
                    label="Angiotensin Receptor Neprilysin Inhibitor (ARNI)"
                  />
                </div>
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_120
                      ? dataForm.data_120.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_120", data)}
                  placeholder="ระบุ"
                  disabled={!["1", "2", "3"].includes(dataForm.data_117)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"121"}
                  value={dataForm.data_121}
                  onChange={handleChange}
                  label="Beta-Blocker"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_122
                      ? dataForm.data_122.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_122", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_121 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"123"}
                  value={dataForm.data_123}
                  onChange={handleChange}
                  label="Aldosterone Antagonist"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_124
                      ? dataForm.data_124.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_124", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_123 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"125"}
                  value={dataForm.data_125}
                  onChange={handleChange}
                  label="SGLT2 Inhibitor"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_126
                      ? dataForm.data_126.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_126", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_125 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"127"}
                  value={dataForm.data_127}
                  onChange={handleChange}
                  label="Ivabradine"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_128
                      ? dataForm.data_128.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_128", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_127 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"129"}
                  value={dataForm.data_129}
                  onChange={handleChange}
                  label="Vericiguat"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_130
                      ? dataForm.data_130.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_130", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_129 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"131"}
                  value={dataForm.data_131}
                  onChange={handleChange}
                  label="Antiarrhythmic"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_132
                      ? dataForm.data_132.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_132", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_131 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"133"}
                  value={dataForm.data_133}
                  onChange={handleChange}
                  label="Ca channel blocker"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_134
                      ? dataForm.data_134.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_134", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_133 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"135"}
                  value={dataForm.data_135}
                  onChange={handleChange}
                  label="Anti-hyperglycemic medications:"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_136
                      ? dataForm.data_136.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_136", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_135 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"137"}
                  value={dataForm.data_137}
                  onChange={handleChange}
                  label="DPP-4 Inhibitors"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_138
                      ? dataForm.data_138.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_138", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_137 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"139"}
                  value={dataForm.data_139}
                  onChange={handleChange}
                  label="GLP-1 receptor agonist"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_140
                      ? dataForm.data_140.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_140", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_139 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"141"}
                  value={dataForm.data_141}
                  onChange={handleChange}
                  label="Insulin"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_142
                      ? dataForm.data_142.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_142", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_141 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"143"}
                  value={dataForm.data_143}
                  onChange={handleChange}
                  label="Metformin"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_144
                      ? dataForm.data_144.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_144", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_143 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"145"}
                  value={dataForm.data_145}
                  onChange={handleChange}
                  label="Sulfonylurea"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_146
                      ? dataForm.data_146.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_146", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_145 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"147"}
                  value={dataForm.data_147}
                  onChange={handleChange}
                  label="Thiazolidinedione"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_148
                      ? dataForm.data_148.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_148", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_147 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"149"}
                  value={dataForm.data_149}
                  onChange={handleChange}
                  label="Other Oral Agents"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_150
                      ? dataForm.data_150.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_150", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_149 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"151"}
                  value={dataForm.data_151}
                  onChange={handleChange}
                  label="Other injectable/subcutaneous agents"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_152
                      ? dataForm.data_152.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_152", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_151 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"153"}
                  value={dataForm.data_153}
                  onChange={handleChange}
                  label="Digoxin"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_154
                      ? dataForm.data_154.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_154", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_153 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"155"}
                  value={dataForm.data_155}
                  onChange={handleChange}
                  label="Diuretic "
                />
                <div
                  className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
                  style={{ marginLeft: "34px" }}
                >
                  <span
                    className={
                      dataForm.data_155 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementRadio
                      fieldId={"156"}
                      value={dataForm.data_156}
                      fieldValue="1"
                      onChange={handleChange}
                      label="Thiazide/Thiazide-like"
                    />
                    <FormElementRadio
                      fieldId={"156"}
                      value={dataForm.data_156}
                      fieldValue="2"
                      onChange={handleChange}
                      label="Loop"
                    />
                  </span>
                </div>
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_157
                      ? dataForm.data_157.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_157", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_155 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"158"}
                  value={dataForm.data_158}
                  onChange={handleChange}
                  label="Hydralazine"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_159
                      ? dataForm.data_159.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_159", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_158 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"160"}
                  value={dataForm.data_160}
                  onChange={handleChange}
                  label="Nitrate"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_161
                      ? dataForm.data_161.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_161", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_160 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"163"}
                  value={dataForm.data_163}
                  onChange={handleChange}
                  label="Statin "
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_165
                      ? dataForm.data_165.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_165", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_163 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"164"}
                  value={dataForm.data_164}
                  onChange={handleChange}
                  label="Other Lipid lowering agent"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_188
                      ? dataForm.data_188.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_188", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_164 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"166"}
                  value={dataForm.data_166}
                  onChange={handleChange}
                  label="NSAIDS/COXZI"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_167
                      ? dataForm.data_167.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_167", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_166 != "1"}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"168"}
                  value={dataForm.data_168}
                  onChange={handleChange}
                  label="Other medications prior to enrolments"
                />
              </td>
              <td>
                <FormElementTagInput
                  value={
                    dataForm.data_169
                      ? dataForm.data_169.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("data_169", data)}
                  placeholder="ระบุ"
                  disabled={dataForm.data_168 != "1"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>Lab</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                CBC <FormElementIconButton icon="search" sizeRatio="1" />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"170"}
                  value={dataForm.data_170}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                FBS <FormElementIconButton icon="search" sizeRatio="1" />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"171"}
                  value={dataForm.data_171}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                BUN electrolyte{" "}
                <FormElementIconButton icon="search" sizeRatio="1" />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"200"}
                  value={dataForm.data_200}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                LFT <FormElementIconButton icon="search" sizeRatio="1" />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"201"}
                  value={dataForm.data_201}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                TFT <FormElementIconButton icon="search" sizeRatio="1" />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"202"}
                  value={dataForm.data_202}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                NT-Pro BNP <FormElementIconButton icon="search" sizeRatio="1" />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"172"}
                  value={dataForm.data_172}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ผลการตรวจพิเศษต่างๆ" />
        <br />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"173"}
            value={dataForm.data_173}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="CXR : "
          />
          <div className="uk-display-inline" hidden={dataForm.data_173 != "1"}>
            <FormElementTextBox
              fieldId={"174"}
              type="date"
              value={dataForm.data_174}
              rows={2}
              cols={50}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"175"}
            value={dataForm.data_175}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="EKG "
          />
          <div className="uk-display-inline" hidden={dataForm.data_175 != "1"}>
            <div
              style={{
                marginTop: "8px",
                paddingLeft: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <span> QRS Duration (ms)</span>
                <FormElementTextBox
                  fieldId={"205"}
                  value={dataForm.data_205}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  type="numeric"
                  maxLength={10}
                />
                <FormElementCheckBox
                  fieldId={"206"}
                  value={dataForm.data_206}
                  onChange={handleChange}
                  label="Not available"
                />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <span> QRS Morphology</span>
                <FormElementRadio
                  fieldId={"207"}
                  value={dataForm.data_207}
                  fieldValue={"1"}
                  label="Normal"
                  onChange={handleChange}
                />
                <FormElementRadio
                  fieldId={"207"}
                  value={dataForm.data_207}
                  fieldValue={"2"}
                  label="LBBB"
                  onChange={handleChange}
                />
                <FormElementRadio
                  fieldId={"207"}
                  value={dataForm.data_207}
                  fieldValue={"3"}
                  label="RBBB"
                  onChange={handleChange}
                />
                <FormElementRadio
                  fieldId={"207"}
                  value={dataForm.data_207}
                  fieldValue={"4"}
                  label="NS-IVCD"
                  onChange={handleChange}
                />
                <FormElementRadio
                  fieldId={"207"}
                  value={dataForm.data_207}
                  fieldValue={"5"}
                  label="Paced"
                  onChange={handleChange}
                />
                <FormElementRadio
                  fieldId={"207"}
                  value={dataForm.data_207}
                  fieldValue={"6"}
                  label="Not available"
                  onChange={handleChange}
                />
                <FormElementRadio
                  fieldId={"207"}
                  value={dataForm.data_207}
                  fieldValue={"7"}
                  label="Other"
                  onChange={handleChange}
                />
                <span hidden={dataForm.data_207 != "7"}>
                  <FormElementTextBox
                    fieldId={"208"}
                    value={dataForm.data_208}
                    rows={2}
                    cols={50}
                    onChange={handleChange}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"177"}
            value={dataForm.data_177}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Echocardiogram"
          />
          <div className="uk-display-inline" hidden={dataForm.data_177 != "1"}>
            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementTextBox
                fieldId={"178"}
                type="date"
                value={dataForm.data_178}
                rows={2}
                cols={50}
                onChange={handleChange}
              />
              <FormElementTextBox
                fieldId={"179"}
                value={dataForm.data_179}
                rows={2}
                cols={50}
                onChange={handleChange}
                label="EF"
              />
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"182"}
            value={dataForm.data_182}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="CAG"
          />
          <div className="uk-display-inline" hidden={dataForm.data_182 != "1"}>
            <FormElementTextBox
              fieldId={"183"}
              type="date"
              value={dataForm.data_183}
              rows={2}
              cols={50}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"184"}
            value={dataForm.data_184}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="6 MWT"
          />
          <div className="uk-display-inline" hidden={dataForm.data_184 != "1"}>
            <FormElementTextBox
              fieldId={"185"}
              type="date"
              value={dataForm.data_185}
              onChange={handleChange}
            />
            <FormElementTextBox
              fieldId={"189"}
              type="numeric"
              value={dataForm.data_189}
              onChange={handleChange}
            />
            <span className="formTextBoxLabel">เมตร</span>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementCheckBox
            fieldId={"186"}
            value={dataForm.data_186}
            rows={2}
            cols={50}
            onChange={handleChange}
            label="Other"
          />
          <div className="uk-display-inline" hidden={dataForm.data_186 != "1"}>
            <FormElementTextBox
              fieldId={"187"}
              value={dataForm.data_187}
              rows={2}
              cols={50}
              onChange={handleChange}
              label=""
            />
          </div>
        </div>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={<FormHeartFailurePaper
          dataForm={dataForm}
          patient={patient}
          profile={profile}
        />}
      />
    </div>
  );
};

export default FormHeartFailure;
