import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../Api';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class DashboardAvgBmiLoss extends Component {
  state = {
    bmiMale: [],
    bmiFemale: [],
    monthGraph: []
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var {bmiMale, bmiFemale, monthGraph} = await Api.member('DashboardAvgBmiLoss');

    this.setState({
      bmiFemale: bmiFemale ? bmiFemale.reverse() : [],
      bmiMale: bmiMale ? bmiMale.reverse() : [],
      monthGraph: monthGraph ? monthGraph.reverse() : []
    });
  }

  month = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];

  renderChart = () => {
    var chartOptions = {
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },
      tooltip: {
        trigger: "axis"
      },
      legend: {
        data: ["Male", "Female"],
      },
      color: ["#fd397a", "#2f94f3", "#FFA87D"],
      calculable: true,
      xAxis: [{
        type: "category",
        boundaryGap: 1,
        data: this.state.monthGraph.map(data=>(this.month[+data-1])),
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],
      yAxis: [{
        type: "value",
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],
      series: [{
        name: "Female",
        type: "line",
        symbol: 'circle',
        smooth: true,
        data: this.state.bmiFemale
      }, {
        name: "Male",
        type: "line",
        symbol: 'circle',
        smooth: true,
        data: this.state.bmiMale
      }]
    };

    return <ReactEcharts
      option={chartOptions}

      style={{ height: 400 }}
      notMerge={true}
      lazyUpdate={true}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-dashboard-avg-bmi-loss">
      <div className="d-card-header">
        <div className="d-card-header-left">
          AVG BMI LOSS
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-dashboard-avg-bmi-loss" />
        </div>
      </div>
      {this.renderChart()}
    </div>
  }
};
