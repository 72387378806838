import React, { Component } from "react";
import TelemonitoringHospital from "./TelemonitoringHospital.js";
import TelemonitoringConsult from "./TelemonitoringConsult.js";
import TelemonitoringPartner from "./TelemonitoringPartner.js";
import TelemonitoringHealthRecord from "./TelemonitoringHealthRecord.js";
import TelemonitoringChat from "./TelemonitoringChat.js";
import TelemonitoringVideoCall from "./TelemonitoringVideoCall.js";
import TelemonitoringUser from "./TelemonitoringUser.js";

export default class Telemonitoring extends Component {
  render() {
    return (
      <div>
        <h1 className="uk-h4 uk-margin-small-top">NCDs Telemedicine Dashboard</h1>
        <div className="uk-grid">
          <div className="uk-width-2-3@m uk-width-1-1">
            <div className="uk-grid uk-child-width-1-3@m uk-child-width-1-1 ">
              <div className="uk-margin-small-bottom">
                <TelemonitoringHospital />
              </div>
              <div className="uk-margin-small-bottom">
                <TelemonitoringConsult />
              </div>
              <div>
                <TelemonitoringPartner />
              </div>
            </div>
            <div className="uk-margin-top">
              <TelemonitoringHealthRecord />
            </div>
            <div className="uk-margin-top">
              <TelemonitoringChat />
            </div>
            <div className="uk-margin-top">
              <TelemonitoringVideoCall />
            </div>
          </div>
          <div className="uk-width-1-3@m uk-width-1-1">
            <TelemonitoringUser />
          </div>
        </div>
      </div>
    );
  }
}
