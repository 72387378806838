import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import form2_image from "../../../images/form_2.png";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import FormElementDatePicker from "../Form/FormElementDatePicker";
var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormESAS = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }
  //   function handleChange(i, event) {
  //     props.onChange(i, event);
  //   }
  var answerList = [
    { label: "เลือกผู้ตอบ", value: "" },
    { label: "ผู้ป่วย", value: "1" },
    { label: "ผู้ดูแล", value: "2" },
  ];
  function setData(field, valueData) {
    var datas = { ...dataForm };

    datas = { ...datas, ["data_" + field]: valueData };

    setDataForm(datas);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_esas",
      formType: "2",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบประเมิน ESAS" />
        <br />
        <FormElementTitle label="Palliative Performance Scale and Edmonton Symptom Assessment System" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="โปรดใส่ตัวเลข (0-10) ที่ตรงกับระดับความรู้สึกของท่านมากที่สุด ณ ขณะนี้" />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top">
          <img src={form2_image} alt="image_1" />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"1"}
          value={dataForm.data_1}
          label="ผู้ตอบ"
          onChange={handleChange}
          width="50"
        />
        <FormElementTextBox
          fieldId={"2"}
          value={dataForm.data_2}
          label="ครั้งที่"
          type="numeric"
          onChange={handleChange}
          width="50"
        />
        <div
          style={{
            display: "inline-flex",
            rowGap: "8px",
            alignItems: "center",
          }}
        >
          <span className="formTextBoxLabel" style={{ whiteSpace: "nowrap" }}>
            วันที่-เวลา
          </span>
          <FormElementDatePicker
            value={dataForm.data_3}
            onChange={(date) => props.addValue({ data_3: date })}
            withTime
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th rowSpan={2}>รายการ</th>
              <th colSpan={11}>ระดับความรู้สึก</th>
            </tr>
            <tr>
              <th>0</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>10</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PPS</td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>ปวด</td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>เหนื่อย/อ่อนเพลีย</td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>คลื่นไส้</td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>

              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>ซึมเศร้า</td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>วิตกกังวล</td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>ง่วงซึม/สะลึมสะลือ</td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>เบื่ออาหาร</td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>ไม่สบายกายและใจ</td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>เหนื่อยหอบ</td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
            <tr>
              <td>
                อื่นๆ
                <FormElementTextBox
                  fieldId={"14"}
                  value={dataForm.data_14}
                  label=""
                  onChange={handleChange}
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"3"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"4"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"5"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"6"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"7"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"8"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"9"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"10"}
                  onChange={handleChange}
                  label=""
                  width="50"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="หมายเหตุ กรณีญาติผู้ป่วยเป็นผู้ตอบ ไม่ต้องประเมิน อาการซึมเศร้า วิตกกังวล และ ไม่สบายใจหากผู้ป่วยเสียชีวิตก่อนการประเมินครั้งถัดไป ไม่ต้องประเมิน" />
      </div>
    </div>
  );
};

export default FormESAS;
