import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";
import Api from "../../Api";

const FormDeliveryDrugMedicinePaper = (props) => {
  const { dataForm, patient, profile, drugList } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="3.3.2 แบบฟอร์มการติดตามการใช้ยาเสพติดที่จัดส่งทางไปรษณีย์"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <div>
            <FormElementTextBox
              label="วิธีการให้คำแนะนำยา"
              value={dataForm.data_31}
              mode="view"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="เบอร์โทรศัพท์"
                value={dataForm.data_1}
                mode="view"
              />
              <FormElementTextBox
                label="เบอร์โทรสำรอง"
                value={dataForm.data_2}
                mode="view"
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="ห้องตรวจ"
                value={dataForm.data_3}
                mode="view"
              />
              <FormElementTextBox
                label="วันที่สั่งยา"
                value={dataForm.data_4}
                type="date"
                dateFormat="dd/MM/yyyy"
                mode="view"
              />
            </div>
            <div
              style={{ display: "flex", gap: "8px", flexDirection: "column" }}
            >
              <FormElementLabel label="ยาเสพติดที่ได้รับ" mode="view" />
              <table
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "60px",
                        background: "#EFF0F3",
                        borderCollapse: "collapse",
                        border: "1px solid grey",
                      }}
                    >
                      ลำดับ
                    </th>
                    <th
                      style={{
                        background: "#EFF0F3",
                        borderCollapse: "collapse",
                        border: "1px solid grey",
                      }}
                    >
                      ชื่อยา
                    </th>
                    <th
                      style={{
                        background: "#EFF0F3",
                        borderCollapse: "collapse",
                        border: "1px solid grey",
                      }}
                    >
                      วิธีใช้
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {drugList.length === 0 && (
                    <tr>
                      <td
                        colSpan="100%"
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div className="uk-padding-small uk-text-center">
                          ไม่พบข้อมูล
                        </div>
                      </td>
                    </tr>
                  )}
                  {drugList.map((data, i) => {
                    return (
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {data.name}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <p>{data.sig}</p>
                          <p>{data.sigadd}</p>
                          <p>{data.signote}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="วันที่ติดตาม"
                value={dataForm.data_6}
                type="date"
                dateFormat="dd/MM/yyyy"
                mode="view"
              />
              <FormElementTextBox
                label="เภสัชกรผู้ติดตาม"
                value={dataForm.data_7}
                mode="view"
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="ผลการติดตาม" mode="view" />
              <FormElementLabel
                value={dataForm.data_8}
                mode="view"
                translate={{
                  1: "ติดตามสำเร็จ",
                  2: "ต้องติดตามซ้ำ (โทรแล้วต้องติดตามซ้ำ)",
                  3: "ติดต่อไม่ได้ (ไม่รับสาย)",
                }}
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="ผู้ให้ข้อมูลการติดตาม" mode="view" />
              <FormElementLabel
                value={dataForm.data_9}
                mode="view"
                translate={{
                  1: "ผู้ป่วย",
                  2: "อื่นๆ",
                }}
              />
              <span hidden={dataForm.data_9 != "2"}>
                <FormElementTextBox value={dataForm.data_10} mode="view" />
              </span>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="Pharmacist note"
                value={dataForm.data_11}
                mode="view"
              />
            </div>
          </div>
          <table
            style={{
              marginTop: "8px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  หัวข้อ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ใช่
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ไม่ใช่
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  หมายเหตุ
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={4}
                >
                  ความถูกต้อง สมบูรณ์ของยาที่ได้รับทางไปรษณีย์
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1. ผู้ป่วยได้รับพัสดุหรือไม่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_12 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_12 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_13} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "4px" }}>
                    <span>2. จำนวนรายการยาครบถ้วนหรือไม่ (ยาจำนวน</span>
                    <span>
                      <FormElementTextBox
                        value={dataForm.data_14}
                        mode="view"
                      />
                    </span>
                    <span>รายการ)</span>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_15 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_15 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_16} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3. รายการยาถูกต้องตามใบรายการยาหรือไม่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_17 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_17 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_18} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4. พัสดุยาสภาพสมบูรณ์ สติกเกอร์ฉีกขาดหรือไม่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_19 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_19 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_20} mode="view" />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={4}
                >
                  อาการไม่พึงประสงค์จากยา
                </th>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_25}
                    translate={{ 0: "ไม่มี", 1: "มี" }}
                    mode="view"
                  />
                  <span hidden={dataForm.data_25 != "1"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                      }}
                    >
                      <FormElementTextBox
                        value={dataForm.data_32}
                        mode="view"
                      />
                    </div>
                  </span>
                </td>
              </tr>
              {/* <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผู้รับการประเมิน (ผู้ป่วย/ญาติ)
                </td>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementTextBox value={dataForm.data_21} mode="view" />
                    <FormElementLabel
                      value={dataForm.data_22}
                      mode="view"
                      translate={{ 1: "ผู้ป่วย", 2: "ญาติ" }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เภสัชกรผู้ประเมิน
                </td>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_23} mode="view" />
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormDeliveryDrugMedicinePaper;
