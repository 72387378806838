import React, { Component } from 'react';
import Api from '../Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import numeral from 'numeral';
import DateTimePicker from '../DatePicker.js';
import ImageUri from '../ImageUri';
import UploadFirebase from '../UploadFirebase';
import $ from 'jquery';

UIkit.use(Icons);

export default class SMCReport extends Component {
  state = {
    dateStart: moment().subtract(1, 'M').startOf('d'),
    dateEnd: moment(),
    group: 'self',
    list: [],
    imagePreview: null,
    loadingNotiApi: false,
    loadingShippingConfirmPrice: false,
    statistic: {
      ems: 0,
      totalRecommend: 0,
      totalRecommendText: "",
      total22: 0,
      total22Text: "",
      total29: 0,
      total29Text: "",
      total28: 0,
      total28Text: "",
      total24: 0,
      total24Text: "",
      total25: 0,
      total25Text: "",
      total29C: 0,
      total29CText: "",
      total28C: 0,
      total28CText: "",

      drivethru: 0,
      telehealth: 0,
      mentalhealth: 0,
      income: 0
    }
  }

  componentDidMount() {
    this.listGet();
  }

  listGet = async () => {
    var _response = await Api.consult('SummaryTotalRecommend', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD')
    });

    var _response29 = await Api.consult('SummaryTotalRecommendFormDrugType', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      formType1: '29',
      formName1: "form_deliverywafarinemedicine",
      condition1: "",
      isDelivery: "1",
      drugType: "warfarin",
    });

    var _response22 = await Api.consult('SummaryTotalRecommendFormDrugType', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      formType1: '22',
      formName1: "form_wafarin",
      condition1: "",
      drugType: "ยาเสพติด",
    });

    var _response28 = await Api.consult('SummaryTotalRecommendFormDrugType', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      formType1: '28',
      formName1: "form_deliverydrugmedicine",
      condition1: "",
      isDelivery: "1",
      drugType: "ยาเสพติด",
    });

    var _response24 = await Api.consult('SummaryTotalRecommendFormDrugType', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      formType1: '24',
      formName1: "form_inhaler",
      condition1: "",
      drugType: "สูดพ่น",
    });

    var _response25 = await Api.consult('SummaryTotalRecommendFormDrugType', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      formType1: '25',
      formName1: "form_insulin",
      condition1: "",
      drugType: "insulin",
    });

    var _response29C = await Api.consult('SummaryTotalRecommendFormDrugType', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      formType1: '29',
      formName1: 'form_deliverywafarinemedicine',
      condition1: "rpt29c",
      drugType: "warfarin",
    });

    var _response28C = await Api.consult('SummaryTotalRecommendFormDrugType', {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      formType1: '28',
      formName1: 'form_deliverydrugmedicine',
      condition1: "rpt28c",
      drugType: "ยาเสพติด",
    });


    var _statistic =  {
      totalRecommend: _response.data.totalData,
      totalRecommendText: _response.data.dataText,
      total22: _response22.data.totalData,
      total22Text: _response22.data.dataText,
      total29: _response29.data.totalData,
      total29Text: _response29.data.dataText,
      total28: _response28.data.totalData,
      total28Text: _response28.data.dataText,
      total24: _response24.data.totalData,
      total24Text: _response24.data.dataText,
      total25: _response25.data.totalData,
      total25Text: _response25.data.dataText,
      total29C: _response29C.data.totalData,
      total29CText: _response29C.data.dataText,
      total28C: _response28C.data.totalData,
      total28CText: _response28C.data.dataText,

      drivethru: 0,
    };

    this.setState({list: _response.list || [], statistic: _statistic, loadingShippingConfirmPrice: false});
  }

  render() {
    return <div>
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">รายงานสรุปผลการดำเนินงาน</div>
          <div uk-grid="" className="uk-flex-middle">
            <div className="uk-width-1-5@m uk-width-1-1">
            <label>ค้นหาจากวันที่</label>
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
                <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value)=>this.setState({dateStart: value})} format={'DD/MM/YYYY'} />
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
            <label>ถึง</label>
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
                <DateTimePicker id="dateStart" date={this.state.dateEnd} callback={(value)=>this.setState({dateEnd: value})} format={'DD/MM/YYYY'} />
            </div>
            
            
            <div className="uk-width-1-5@m uk-width-1-1 uk-text-right">
              <button className="uk-button uk-button-primary" onClick={this.listGet}>ค้นหา</button>
            </div>
          </div>
          <div>

            <table className='uk-table uk-table-divider uk-table-striped uk-margin-remove-top'>
              <thead>
                <tr>
                  <th>รายการ</th>
                  <th>ร้อยละ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>อัตราผู้ป่วยส่งยาไปรษณีย์ที่ได้รับคำแนะนำการใช้ยาโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล ({this.state.statistic.totalRecommendText})</td>
                  <td>{this.state.statistic.totalRecommend}</td>
                </tr>
                <tr>
                  <td>อัตราผู้ป่วยส่งยาไปรษณีย์กลุ่มยาความเสี่ยงสูงที่ได้รับคำแนะนำการใช้ยาโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาวาฟาริน) ({this.state.statistic.total22Text})</td>
                  <td>{this.state.statistic.total22}</td>
                </tr>
                <tr>
                  <td>อัตราผู้ป่วยส่งยาไปรษณีย์กลุ่มยาความเสี่ยงสูงที่ได้รับคำแนะนำการใช้ยาโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาเสพติด) ({this.state.statistic.total28Text})</td>
                  <td>{this.state.statistic.total28}</td>
                </tr>
                <tr>
                  <td>อัตราผู้ป่วยส่งยาไปรษณีย์กลุ่มยาความเสี่ยงสูงที่ได้รับการติดตามยาการใช้ยาโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาวาฟาริน) ({this.state.statistic.total29Text})</td>
                  <td>{this.state.statistic.total29}</td>
                </tr>
                <tr>
                  <td>อัตราผู้ป่วยส่งยาไปรษณีย์กลุ่มยาความเสี่ยงสูงที่ได้รับการติดตามยาการใช้ยาโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาเสพติด) ({this.state.statistic.total28Text})</td>
                  <td>{this.state.statistic.total28}</td>
                </tr>
                <tr>
                  <td>อัตราผู้ป่วยที่ผ่านการสอนการใช้ยากลุ่มเทคนิคพิเศษโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาพ่นสูดทางปาก) ({this.state.statistic.total24Text})</td>
                  <td>{this.state.statistic.total24}</td>
                </tr>
                <tr>
                  <td>อัตราผู้ป่วยที่ผ่านการสอนการใช้ยากลุ่มเทคนิคพิเศษโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาฉีดอินซูลิน) ({this.state.statistic.total25Text})</td>
                  <td>{this.state.statistic.total25}</td>
                </tr>
                <tr>
                  <td>อัตราการเกิดอาการไม่พึงประสงค์จากการใช้ยาในผู้ป่วยส่งยาไปรษณีย์กลุ่มความเสี่ยงสูงที่ได้รับคำแนะนำโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาวาฟาริน) ({this.state.statistic.total29CText})</td>
                  <td>{this.state.statistic.total29C}</td>
                </tr>
                <tr>
                  <td>อัตราการเกิดอาการไม่พึงประสงค์จากการใช้ยาในผู้ป่วยส่งยาไปรษณีย์กลุ่มความเสี่ยงสูงที่ได้รับคำแนะนำโดยเภส้ชกรผ่านระบบการแพทย์ทางไกล (ยาเสพติด) ({this.state.statistic.total28CText})</td>
                  <td>{this.state.statistic.total28C}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
        </div>
        
    </div>
  }
};
