import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";

const FormElementDateTextBox = (props) => {
  const { value, onChange } = props;
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (value && value?.length == 10) {
      var year = value?.substring(0, 4);
      var month = value?.substring(5, 7);
      var date = value?.substring(8, 10);

      setInputValue(`${date}/${month}/${parseInt(year) + 543}`);
    } else {
      setInputValue("");
    }
  }, [value]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  });

  const handleBlur = useCallback((e) => {
    if (!inputValue.includes("_")) {
      var dd = inputValue?.substring(0, 2);
      var month = inputValue?.substring(3, 5);
      var year = inputValue?.substring(6, 10);

      const date = `${parseInt(year) - 543}-${month}-${dd}`;

      if (moment(date, "YYYY-MM-DD", true).isValid()) {
        onChange(date);
      } else {
        onChange(null);
        setInputValue("");
      }
    } else {
      onChange(null);
      setInputValue("");
    }
  });

  return (
    <ReactInputMask
      mask={"99/99/9999"}
      placeholder="DD/MM/YYYY"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default FormElementDateTextBox;
