import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormFearFallingPaper = (props) => {
  const { dataForm, patient, profile } = props;

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
    ]);
  }, [dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="Fear of falling (Form ที่ใช้ใน Post-falling assessment)"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <FormElementTitle
            label="แบบประเมินความกังวลหรือกลัวการหกล้ม (ฉบับย่อ)"
            mode="view"
          />
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <thead>
              <tr>
                <th
                  colSpan={6}
                  style={{
                    background: "#EFF0F3",
                    padding: "4px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ต่อไปนี้
                  ผู้วิจัยขอสอบถามว่าท่านมีความกังวลอย่างไรต่อความเป็นไปได้ที่จะหกล้ม
                  กรุณาตอบคำถามโดยคำนึงว่า โดยปกติท่านทำกิจกรรมเหล่านี้อย่างไร
                  หากปัจจุบัน ท่านไม่ได้ทำกิจกรรมเหล่านี้ด้วยตัวเอง
                  กรุณาตอบคำถามโดยคำนึงว่าท่านมีความกังวลอย่างไรเกี่ยวกับการหกล้มถ้าท่านทำกิจกรรมเหล่านี้ด้วยตัวเอง
                  สำหรับกิจกรรมดังต่อไปนี้
                  กรุณาทำเครื่องหมายในช่องที่ใกล้เคียงมากที่สุดกับความเห็นของท่านในเรื่องความกังวลว่า
                  อาจจะหกล้มหากท่านทำกิจกรรมเหล่านี้
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ไม่กังวลหรือไม่กลัว (1)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  กังวลหรือกลัว เล็กน้อย (2)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  กังวลหรือกลัว ปานกลาง (3)
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  กังวลหรือกลัว มาก (4)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การใส่หรือถอดเสื้อผ้า
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การอาบน้ำ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การนั่งหรือลุกจากเก้าอี้
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การขึ้นหรือลงบันได
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การเอื้อมหยิบของเหนือศีรษะ หรือก้มลงเก็บของบนพื้น
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การเดินขึ้นหรือลงที่ลาดชัน
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การออกไปร่วมกิจกรรมทางสังคม เช่น กิจกรรมทางศาสนา การรวมญาติ
                  หรือกิจกรรมชมรม
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "3" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนนรวมทั้งหมด
                </td>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getTotalScore}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormFearFallingPaper;
