

export default (array, key, desc) => {

  return array.sort((a, b) => {

    var x = a[key];
    var y = b[key];

    if(!isNaN(+x)) x = +x;
    else x = x.toLocaleLowerCase();

    
    if(!isNaN(+y)) y = +y;
    else y = y.toLocaleLowerCase();

    if(desc === true) {

      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    } else {
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));

    }
  });
  
}
