import React, { Component } from 'react';
import Api from './Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import UploadFirebase from './UploadFirebase';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';
import moment from 'moment';
import MemberRegisterLocation from '../Components/Member/MemberRegisterLocation';

UIkit.use(Icons);

export default class UserProfileCardPersonalInformation extends Component {
  state = {
    loadingApi: false,
    profile: { ...this.props.profile }
  }

  componentDidMount() {
    console.log(this.props)
  }

  profileUpdate = async (key) => {
    var responseText = '';

    this.setState({ loadingApi: true });

    var { responseText, result, query } = await Api[this.props.type === 'c' ? 'consult' : 'member']('MemberProfileUpdate', {
      key,
      value: this.state.profile[key],
      name: {
        name: this.state.profile.name,
        nameprefix: this.state.profile.nameprefix,
        lastname: this.state.profile.lastname,
      }
    });

    if (key === 'image' || key === 'name') window.location.reload();

    this.setState({ loadingApi: false,
      [`${key}Edit`]: false });

    UIkit.notification(responseText);

  }

  updateValue = (type, value, isUpdate) => {
    this.setState((state) => {
      state.profile[type] = value;
      return { profile: state.profile };
    }, () => {
      if (isUpdate) this.profileUpdate(type);
    });
  }

  datepickerGet = () => {

    var _this = this;
    
    $('#datetimepicker-birthday')
      .on('dp.change', function (e) {
        _this.updateValue('birthday', e.date.format('YYYY-MM-DD'));
      })
      .datetimepicker({
        format: "DD/MM/YYYY",
        defaultDate: this.state.profile.birthday === '0000-00-00' || isNaN(this.state.profile.birthday) || !this.state.profile.birthday ? moment() : moment(this.state.profile.birthday),
      });
  }

  inputSelectRender = (type, option) => {
    var _valueOption = option.filter(data => (this.state.profile[type] == data.value ? data.label : false));
    if (_valueOption[0]) {
      _valueOption = _valueOption[0].label;
    } else {
      _valueOption = '-';
    }

    return <div>
      {
        this.state[type + 'Edit'] ?
          <select className="uk-select uk-form-small" value={this.state.profile[type]} onChange={e => this.updateValue(type, e.target.value, true)}>
            {option.map((data, index) => {
              return <option key={index} value={data.value}>{data.label}</option>
            })}
          </select>
          :
          <div>{_valueOption} <i className="icon-pencil d-button-edit" onClick={() => this.setState({ [type + 'Edit']: true })}></i>
          </div>
      }
    </div>
  }

  inputTextRender = (type) => {
    return <div>
      {this.state[type + 'Edit'] ?
        <div className="uk-button-group">
          <input type="text" className="uk-input uk-form-small" value={this.state.profile[type]} onChange={e => this.updateValue(type, e.target.value)} />
          <button className="uk-button uk-button-small d-button-submit icon-check" onClick={() => this.profileUpdate(type)}>
          </button>
        </div>
        :
        <div>
          {this.state.profile[type]} <i className="icon-pencil d-button-edit" onClick={() => this.setState({ [type + 'Edit']: true })}></i>
        </div>
      }
    </div>
  }

  geoData = {
    lat: null,
    lng: null
  }

  render() {
    return <div className="uk-padding-small">
      <div uk-grid="" className="uk-grid-small uk-padding-small">
        <div className="uk-width-1-3@s uk-width-1-1 uk-text-center">
          <div className="uk-background-cover uk-background-muted uk-display-inline-block d-user-profile-card-image" style={{ backgroundImage: this.state.profile.image === '' ? false : `url(${this.state.profile.image})` }}>
          </div>
          <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1 uk-margin-small-top">
            เลือกรูปภาพ
            <UploadFirebase cb={(media) => {
              this.updateValue('image', media);
              this.profileUpdate('image');
            }} />
          </label>
        </div>
        <div className="uk-width-2-3@s uk-width-1-1 uk-text-left@s uk-text-center">
          <table id="d-table-personal-information" className="uk-table uk-width-1-1 uk-table-small uk-text-left">
            <tbody>
              <tr>
                <td width="130">
                  ชื่อ-สกุล
              </td>
                <td>
                  <div>
                    {
                      this.state.nameEdit ?
                        <div>
                          <input type="text" className="uk-input uk-form-small" value={this.state.profile.nameprefix} onChange={e => this.updateValue('nameprefix', e.target.value)} />
                          <input type="text" className="uk-input uk-form-small" value={this.state.profile.name} onChange={e => this.updateValue('name', e.target.value)} />
                          <input type="text" className="uk-input uk-form-small" value={this.state.profile.lastname} onChange={e => this.updateValue('lastname', e.target.value)} />
                          <button className="uk-button uk-button-small d-button-submit icon-check" onClick={() => {
                            this.profileUpdate('nameprefix');
                            this.profileUpdate('name');
                            this.profileUpdate('lastname');
                          }}>
                          </button>
                        </div>
                        :
                        <div>
                          {this.state.profile.nameprefix}{' '}
                          {this.state.profile.name}{' '}
                          {this.state.profile.lastname} <i className="icon-pencil d-button-edit" onClick={() => this.setState({ nameEdit: true })}></i>
                        </div>
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>เลขบัตรประจำตัว</td>
                <td>
                  {this.inputTextRender('nid')}
                </td>
              </tr>
              <tr>
                <td>
                  วัน/เดือน/ปี เกิด
              </td>
                <td>
                  {
                    this.state.birthdayEdit ?
                      <div className="d-button-group input-group uk-button-group date" id="datetimepicker-birthday">
                        <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
                        <span className="uk-button input-group-addon" style={{ width: 45, marginLeft: 0 }}>
                          <span className="glyphicon glyphicon-calendar"></span>
                        </span>
                        <button className="uk-button uk-button-small d-button-submit icon-check" onClick={() => this.profileUpdate('birthday')}></button>
                      </div>
                      :
                      <div>
                        {moment(this.state.profile.birthday).format('DD/MM/YYYY')} <i className="icon-pencil d-button-edit" onClick={() => this.setState({ birthdayEdit: true },()=>this.datepickerGet())}></i>
                      </div>
                  }
                </td>
              </tr>
              <tr>
                <td>
                  ที่อยู่จัดส่งยา
                </td>
                <td>
                  {this.inputTextRender('address')}
                </td>
              </tr>
              <tr>
                <td>
                  รหัสไปรษณีย์
                </td>
                <td>
                  <div>
                  {this.state.postcodeEdit ?
                    <div className="uk-button-group">
                      <input type="text" maxLength={5} className="uk-input uk-form-small" value={this.state.profile.postcode} onChange={e => {
                        if(/^\d*?\d*$/.test(e.target.value))
                          this.updateValue('postcode', e.target.value)} 
                      }/>
                      <button className="uk-button uk-button-small d-button-submit icon-check" onClick={() => {
                        if(this.state.profile.postcode.length === 5) {
                          this.profileUpdate('postcode')
                        } else {
                          UIkit.notification("โปรดใส่รหัสไปรษณีย์ให้ถูกต้อง");
                        }
                      }}>
                      </button>
                    </div>
                    :
                    <div>
                      {this.state.profile.postcode} <i className="icon-pencil d-button-edit" onClick={() => this.setState({ postcodeEdit: true })}></i>
                    </div>
                  }
                </div>
                </td>
              </tr>
              <tr>
                <td>พิกัด</td>
                <td>
                แก้ไข <i className="icon-pencil d-button-edit" onClick={() => this.setState({ latlngEdit: true })}></i> 
                  {
                    this.state.latlngEdit === true && 
                    <div className="d-popup-background">
                    <div className="d-popup-body uk-text-left">
                      <MemberRegisterLocation 
                        callback={(data)=>{
                          this.geoData.lat = data.lat;
                          this.geoData.lng = data.lng;
                        }}
                        lat={this.state.profile.lat} 
                        lng={this.state.profile.lng} 
                        hideAddressName={true} 
                        hideAddress={true} 
                        hideNote={true} 
                        hidePostcode={true} 
                        formType="admin" 
                      />
                    
                      <div className="uk-margin-small-top uk-text-right">
                        <button
                          className="uk-button uk-button-small uk-button-default"
                          onClick={() => {
                            this.setState({ latlngEdit: false });
                          }}
                        >
                          ยกเลิก
                        </button>
                        <button className="uk-button uk-button-small uk-button-primary uk-margin-small-left" onClick={() => {
                          
                          this.updateValue('lat', this.geoData.lat, true); 
                          this.updateValue('lng', this.geoData.lng, true);

                          this.setState({latlngEdit: false });

                        }}>บันทึก</button>
                      </div>
                    </div>
                  </div>
                  }
                  
                </td>
              </tr>
              <tr>
                <td>
                  อีเมล
                </td>
                <td>
                  {this.inputTextRender('email')}
                </td>
              </tr>
              <tr>
                <td>โทร</td>
                <td>
                  {this.inputTextRender('tel')}
                </td>
              </tr>
              <tr>
                <td>ส่วนสูง (cm)</td>
                <td>
                  {this.inputTextRender('height')}
                </td>
              </tr>
              <tr>
                <td>น้ำหนัก (Kg)</td>
                <td>
                  {this.inputTextRender('weight')}
                </td>
              </tr>
              <tr>
                <td>เพศ</td>
                <td>
                  {this.inputSelectRender('gender', [
                    { label: 'ชาย', value: '1' },
                    { label: 'หญิง', value: '0' }
                  ])}
                </td>
              </tr>
              <tr>
                <td>สูบบุหรี่</td>
                <td>
                  {this.inputSelectRender('smoking', [
                    { label: 'สูบบุหรี่', value: '1' },
                    { label: 'ไม่สูบบุหรี่', value: '0' }
                  ])}
                </td>
              </tr>
              <tr>
                <td>เบาหวาน</td>
                <td>
                  {this.inputSelectRender('diabetes', [
                    { label: 'เป็นเบาหวาน', value: '1' },
                    { label: 'เป็นเบาหวานชนิดที่ 1', value: '2' },
                    { label: 'เป็นเบาหวานชนิดที่ 2', value: '3' },
                    { label: 'เป็นเบาหวานในคนท้อง', value: '4' },
                    { label: 'ไม่เป็นเบาหวาน', value: '0' }
                  ])}
                </td>
              </tr>
              <tr>
                <td>ความดันโลหิตสูง</td>
                <td>
                  {this.inputSelectRender('bloodpressure', [
                    { label: 'เป็น', value: '1' },
                    { label: 'ไม่เป็น', value: '0' }
                  ])}
                </td>
              </tr>
              <tr>
                <td>โรคประจำตัว</td>
                <td>
                  {this.inputTextRender('congenitaldisease')}
                </td>
              </tr>
              <tr>
                <td>แพ้ยา</td>
                <td>
                  {this.inputTextRender('drugallergy')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  }
};
