import React, { Component } from 'react';
import Api from '../../Components/Api';
import HIVKey from '../../Components/HIVKey';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';

UIkit.use(Icons);

var MONTH = ['01','02','03','04','05','06','07','08','09','10','11','12'];

export default class HivTable extends Component {

  state = {
    loadingData: true,
    campaignHiv: false,
    year: moment().format('YYYY')
  }

  componentDidMount () {
    this.HivDataGet();
  }

  hivData = {};

  tagData = {};

  tag = ['tb','adr','sti','adherence','narcotic','vl','2q','unsafe_sex','partner','preconception','covid'];

  HivDataGet = async () => {
    this.setState({loadingData: true});

    var _response = await Api.consult("HivDataTableGet", {
      year: this.state.year,
      campaignId: this.props.campaignId || 'all'
    });

    console.log(_response)

    if(!Array.isArray(_response.data)) _response.data = [];
    if(!Array.isArray(_response.healthnoti)) _response.healthnoti = [];

    this.hivData = {};
    this.tagData = {};
    for(var tag of this.tag) {
      this.tagData[tag] = {
        sum: 0
      }

      for(var month of MONTH) {
        this.tagData[tag][month] = 0
      }
    }
    
    _response.data.forEach((data) => {

      var _key = `${data.keyname}-${data.value}`;
      var _value = data.amount;
      
      if(['how_many_tablet_are_left', 'how_many_day_are_left', 'how_many_tablet_per_day', 'symptom_other_abnormal', 'missing_because_other_detail'].includes(data.keyname) === true) {
        _key = data.keyname;
        _value = data.amount;
      }


      if(this.hivData[_key] == null) {
        this.hivData[_key] = {};
      }

      var _month = moment(data.dateadd, 'YYYY-MM-DD').format('MM');

      if(this.hivData[_key][_month] == null) {
        this.hivData[_key][_month] = _value;
      }
    })

    for(var data of _response.healthnoti) {
      var _month = moment(data.datelog, 'YYYY-MM-DD').format('MM');

      this.tagData[data.notikey][_month] += parseInt(data.amount);
      this.tagData[data.notikey].sum += parseInt(data.amount);
    }
    
    console.log(this.tagData)

    this.setState({loadingData: false, campaignHiv: _response.campaignHiv});

  }

  rowRender = (data) => {
    var _key = `${data.name}-${data.value}`;

    
    if(data.label || data.label_before || data.label_after || data.show_on_column === true) {

      if(data.key === 'how_many_tablet_are_left') {
        _key = data.key;
        data.label = 'จำนวผู้ตอบคำถามจำนวนยาต้านไวรัสเหลือกี่เม็ด';
      }
      else if(data.key === 'how_many_day_are_left') {
        _key = data.key;
        data.label = 'จำนวผู้ตอบคำถามจำนวนยาต้านไวรัสเหลือกี่วัน';
      }
      else if(data.key === 'how_many_tablet_per_day') {
        _key = data.key;
        data.label = 'จำนวผู้ตอบคำถามจำนวนยาที่รับประทานต่อวัน';
      }
      else if(data.key === 'missing_because_other_detail') {
        _key = data.key;

      }
      

      var _sum = 0;

      MONTH.forEach((month) => {
        if(this.hivData[_key] != null && this.hivData[_key][month] != null) {
          _sum += parseInt(this.hivData[_key][month]);
        }
      })

      return <tr key={_key}>
        <td className='uk-text-left'><div dangerouslySetInnerHTML={{__html: `${data.text_prefix || ''}${data.label}`}}></div></td>
        <td>{_sum > 0 ? _sum : ''}</td>
        {MONTH.map((month)=>{
          return <td key={_key+month}>{this.hivData[_key] != null && this.hivData[_key][month] != null ? this.hivData[_key][month] : ''}</td>
        })}
      </tr>

    } else {

    }
  }

  childRender = (a) => {
    return a.map((data) => {
      return [
        this.rowRender(data),
        data.child && this.childRender(data.child)
      ]
    })
  }

  TableRender = () => {
    return [
      this.tagRowRender(),
      this.childRender(HIVKey)
    ]
  }

  tagRowRender = () => {
    var _row = [];

    for(var tag in this.tagData) {
      _row.push(
        <tr key={tag}>
        <td className='uk-text-left'><span className={"uk-label uk-text-small d-background-red"}>{tag}</span></td>
        <td>{this.tagData[tag].sum || ''}</td>
        {MONTH.map((month)=>{
          return <td key={tag+month}>{this.tagData[tag][month] || ''}</td>
        })}
      </tr>
      )
    }

    return _row;
  }

  yearSelectRender = () => {
    var _startYear = moment().subtract(10, 'y').format('YYYY');
    var _stopYear = moment().format('YYYY');

    var _option = []

    for(var i=_startYear; i<=_stopYear; i++) {
      _option.push(<option value={i}>{i}</option>)
    }
    return <select value={this.state.year} onChange={(e)=>this.setState({year: e.target.value}, ()=>{
      this.HivDataGet();
    })} className='uk-width-small uk-select'>{_option}</select>
  }
  
  render() {
    return this.props.hideIfNoHiv !== true || (this.props.hideIfNoHiv && this.state.campaignHiv === true) ? <div className="uk-card uk-card-default uk-padding-small uk-margin-top ">
      <div className="d-card-header">แบบประเมินตนเองก่อนเข้าพบแพทย์สำหรับข้อมูลสร้างเสริมสุขภาพ</div>
      <div className="uk-overflow-auto">
        <table className='uk-table uk-table-divider uk-table-small d-table-border-vertical uk-text-center'>
          <thead>
            <tr>
              <th>เลือกปี {this.yearSelectRender()}</th>
              <th className='uk-text-center'>รวม</th>
            {MONTH.map((data)=>{
              return <th className='uk-text-center'>{data}</th>;
            })}
            </tr>
          </thead>
          <tbody>
            {
              this.state.loadingData === true ?
              <div><span uk-spinner=""></span></div>
              :
              this.TableRender()
            }
          </tbody>
        </table>
      </div>
    </div>
    :
    null
  }
};
