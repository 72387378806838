import React, { useEffect, useState } from "react";

const FormElementRadio = (props) => {
  const [selected, setSelected] = useState("no");

  var show = "none";

  var isMagicRadio = false;

  if (typeof props.magicRadio != "undefined") {
    isMagicRadio = props.magicRadio;
  }

  if (props.label != "") {
    show = "block";
  }

  var paddingStyle = "0px 0px 0px 0px";

  if (typeof props.padding != "undefined") {
    paddingStyle = props.padding;
  }

  const styleObj = {
    padding: paddingStyle,
  };

  var className = "formRadio uk-radio";

  if (isMagicRadio) {
    className += " magicRadio";
    if (selected === "yes") {
      className += " check";
    }
  }

  function onChange(e) {
    props.onChange(props.fieldId, e);
  }

  useEffect(() => {
    if (typeof props.value != "undefined" && props.value != null) {
      if (props.value == props.fieldValue) {
        setSelected("yes");
      } else {
        setSelected("no");
      }
    } else {
      setSelected("no");
    }
  }, [props.value]);

  return (
    <div className="uk-display-inline radio-container" style={styleObj}>
      <div style={{ display: "inline-flex" }}>
        <div className="uk-display-inline">
          <input
            className={className}
            type="radio"
            disabled={props.disabled}
            id={"data_" + props.fieldId + "_" + props.fieldValue}
            name={"data_" + props.fieldId}
            value={props.fieldValue}
            onChange={(e) => onChange(e)}
            checked={selected === "yes"}
          />
        </div>
        {typeof props.label != "undefined" && props.label != "" ? (
          <label
            className={`labelMagicRadio ${selected == "yes" ? "checked" : ""}`}
            htmlFor={"data_" + props.fieldId + "_" + props.fieldValue}
          >
            {props.label}
          </label>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FormElementRadio;
