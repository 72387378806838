import React, { useState, useEffect, useMemo, useCallback } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";

import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import PopupForm from "../PopupForm";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

const FormTelePharmacyPaper = (props) => {
  const { dataForm, patient, profile, drugAllergics, opdVerify, diagList } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบบันทึกการให้คำแนะนำการใช้ยาผ่านระบบ Telemedicine"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <div
          style={{
            padding: "0px 0px 0px 40px",
          }}
        >
          <div
            style={{
              padding: "0px 0px 0px 40px",
            }}
          >
            <FormElementTitle label="Diagnosis" mode="view" />
            <table
              className="paper-table"
              style={{ borderCollapse: "collapse", border: "1px solid grey" }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ลำดับ
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    Type
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {diagList.length === 0 ? (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid grey",
                      }}
                    >
                      <div className="uk-padding-small uk-text-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                ) : (
                  diagList.map((data, key) => {
                    return (
                      <tr key={`row-icd10-${key}`}>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {key + 1}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {data.dx_type_name}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {data.icd10_name}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div
            style={{
              padding: "10px 0px 0px 40px",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_7}
              label="OPD"
              mode="view"
              width="100"
            />
            <FormElementTextBox
              value={dataForm.data_8}
              label="แพทย์ "
              mode="view"
              width="100"
            />
            <FormElementTextBox
              value={dataForm.data_32}
              label="วันที่แพทย์สั่งยา "
              mode="view"
              width="100"
              type="date"
            />
          </div>
          <div
            style={{
              padding: "20px 0px 0px 40px",
            }}
          >
            <FormElementTitle label="วิธีการให้คำแนะนำยา" mode="view" />
            <FormElementTextBox
              value={dataForm.data_34}
              mode="view"
              width="100"
            />
          </div>
          <div
            style={{
              padding: "20px 0px 0px 40px",
            }}
          >
            <FormElementTitle label="ประวัติการแพ้ยา" mode="view" />
          </div>
          <div
            style={{
              padding: "10px 0px 0px 60px",
            }}
          >
            {drugAllergics.length > 0 &&
              drugAllergics.map((item, key) => (
                <div key={key}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    marginBottom: "8px",
                  }}
                >
                  <strong>{item.group}</strong>
                  <div style={{ paddingLeft: "14px" }}>
                    {item.sym?.split(/\r\n/g).map((item, idx) => {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </div>
                </div>
              ))}
            {drugAllergics.length == 0 && "ไม่มีประวัติ"}
          </div>
          <div
            style={{
              padding: "10px 0px 0px 40px",
            }}
          >
            <FormElementTitle
              label="รายการยาที่แพทย์สั่ง ห้องตรวจ"
              mode="view"
            />
          </div>
          <table
            className="paper-table"
            style={{
              padding: "10px 0px 0px 40px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ลำดับ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ชื่อยา
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  วิธีใช้
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  จำนวน
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  สถานะการปรับเปลี่ยนยา
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  แนะนำยา
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ปัญหาการใช้ยาที่พบ
                </th>
              </tr>
            </thead>
            <tbody>
              {dataForm?.data_drug?.length === 0 || !dataForm?.data_drug ? (
                <tr>
                  <td
                    colSpan="100%"
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <div className="uk-padding-small uk-text-center">
                      ไม่พบข้อมูล
                    </div>
                  </td>
                </tr>
              ) : (
                dataForm?.data_drug?.map((data, key) => {
                  return (
                    <tr key={`row-drug-${key}`}>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        {key + 1}
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        {data.name}
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <p>{data.sig}</p>
                        <p>{data.sigadd}</p>
                        <p>{data.signote}</p>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        {data.amt}
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        {data.remed_status + " " + data.dru_note}
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div className="uk-width-1-5">
                          {
                            <FormElementCheckBox
                              fieldId={`checkbox-${key}`}
                              value={data.data_1}
                              mode="view"
                            />
                          }
                        </div>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        {
                          <FormElementTextArea
                            fieldId={`textarea-${key}`}
                            value={data.data_2}
                            cols={80}
                            rows={3}
                            mode="view"
                          />
                        }
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <span>
            <div
              style={{
                padding: "10px 0px 0px 40px",
              }}
            >
              <FormElementTitle
                label="ข้อมูลที่ส่งต่อจากเภสัชกรคัดกรองคำสั่งใช้ยา"
                mode="view"
              />
            </div>
            <div
              style={{
                padding: "10px 0px 0px 80px",
              }}
            >
              <div className="uk-width-1-1@m uk-width-1-1">
                {Object.keys(opdVerify).length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <span>{opdVerify.text_head}</span>
                    {opdVerify.text?.phar_note?.map((item, i) => {
                      const texts = item?.note_desc?.split("<br>");
                      return (
                        <div style={{ display: "inline-flex", gap: "8px" }} key={i}>
                          <strong>-</strong>
                          <span>
                            {texts.map((t, j) => (
                              <span key={j}>
                                {t}
                                <br />
                              </span>
                            ))}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                {Object.keys(opdVerify).length == 0 && "ไม่มีข้อมูล"}
              </div>
            </div>
          </span>
          <span>
            <div
              style={{
                padding: "10px 0px 0px 40px",
              }}
            >
              <FormElementTitle
                label="รายการยาที่ได้รับจากสถานพยาบาลอื่น/ สมุนไพร/ อาหารเสริม"
                mode="view"
              />
            </div>
            <div
              style={{
                padding: "10px 0px 0px 80px",
              }}
            >
              <FormElementTextArea
                value={dataForm.data_21}
                cols={100}
                rows={5}
                mode="view"
              />
            </div>
          </span>
        </div>
        {/* <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            mode="view"
            label=" แบบติดตามการประเมินความรู้ผู้ป่วย  "
          />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="Warfarin" />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="NOAC" />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="ยาพ่นสูด" />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="insulin" />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="bisphosphonate แบบรับประทาน" />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            mode="view"
            label=" แบบติดตามการได้รับยาและการใช้ยาที่จัดส่งทางไปรษณีย์  "
          />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="ยาทั่วไป" />
        </div>
        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="ยาเสพติด" />
        </div>

        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel mode="view" label="Warfarin" />
        </div> */}
        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            mode="view"
            label="ข้อมูลที่ต้องการส่งต่อนัดครั้งถัดไป"
          />
        </div>

        <div style={{ paddingLeft: "40px" }}>
          <span hidden={!dataForm.data_23}>
            <div
              style={{
                padding: "10px 0px 0px 40px",
              }}
            >
              <FormElementTitle label="แพทย์" mode="view" />
            </div>
            <div
              style={{
                padding: "10px 0px 0px 80px",
              }}
            >
              <FormElementTextArea
                value={dataForm.data_23}
                cols={100}
                rows={5}
                mode="view"
              />
            </div>
          </span>
          <span hidden={!dataForm.data_24}>
            <div
              style={{
                padding: "10px 0px 0px 40px",
              }}
            >
              <FormElementTitle label="พยาบาล" mode="view" />
            </div>
            <div
              style={{
                padding: "10px 0px 0px 80px",
              }}
            >
              <FormElementTextArea
                value={dataForm.data_24}
                cols={100}
                rows={5}
                mode="view"
              />
            </div>
          </span>
          <span hidden={!dataForm.data_25}>
            <div
              style={{
                padding: "10px 0px 0px 40px",
              }}
            >
              <FormElementTitle label="เภสัช" mode="view" />
            </div>

            <div
              style={{
                padding: "10px 0px 0px 80px",
              }}
            >
              <FormElementTextArea
                value={dataForm.data_25}
                cols={100}
                rows={5}
                mode="view"
              />
            </div>
          </span>
          <span hidden={!dataForm.data_26}>
            <div
              style={{
                padding: "10px 0px 0px 40px",
              }}
            >
              <FormElementTitle label="เจ้าหน้าที่อื่น" mode="view" />
            </div>

            <div
              style={{
                padding: "10px 0px 0px 80px",
              }}
            >
              <FormElementTextArea
                value={dataForm.data_26}
                cols={100}
                rows={5}
                mode="view"
              />
            </div>
          </span>
          <span hidden={!dataForm.data_27}>
            <div
              style={{
                padding: "10px 0px 0px 40px",
              }}
            >
              <FormElementTitle label="ปัญหาอื่น" mode="view" />
            </div>

            <div
              style={{
                padding: "10px 0px 0px 80px",
              }}
            >
              <FormElementTextArea
                value={dataForm.data_27}
                cols={100}
                rows={5}
                mode="view"
              />
            </div>
          </span>
          {/* <div
            style={{
              padding: "10px 0px 0px 40px",
            }}
          >
            <FormElementTitle label="ข้อมูลผู้ให้บริการ" mode="view" />
          </div> */}
          {/* <div
            style={{
              padding: "10px 0px 0px 80px",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_28}
              label="ชื่อ"
              mode="view"
              width="100"
            />
            <FormElementTextBox
              value={dataForm.data_33}
              label="นามสกุล"
              mode="view"
              width="100"
            />
            <FormElementTextBox
              value={dataForm.data_29}
              label="วันที่ "
              mode="view"
              width="100"
              type="date"
            />
            <FormElementTextBox
              value={dataForm.data_30}
              label="เวลาเริ่มต้น "
              mode="view"
              width="100"
            />
            <FormElementTextBox
              value={dataForm.data_31}
              label="เวลาสิ้นสุด "
              mode="view"
              width="100"
            />
          </div> */}
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormTelePharmacyPaper;
