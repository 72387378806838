import React, { useState, useEffect, useMemo, useCallback } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import FormElementLabel from "../Form/FormElementLabel";
import "../../../css/dietz.css";
import FormElementButton from "../Form/FormElementButton";
import { createPortal } from "react-dom";
import MemberSelector from "../MemberSelector";

UIkit.use(Icons);

const FormMemberProfileHeader = (props) => {
  const { memberData, onSelectMember } = props;
  const { isSelectDisabled } = props;
  const [showMemberSelect, setShowMemberSelect] = useState(false);
  const [hideSelectBtn, setHideSelectBtn] = useState(false);
  const [divStyle, setDivStyle] = useState({});
  const [mode, setMode] = useState("edit");

  const handleCloseMember = useCallback((data) => {
    if (data != null) {
      onSelectMember(data);
    }
    setShowMemberSelect(false);
  }, [setShowMemberSelect, onSelectMember]);

  const handleMemberSelectOpen = useCallback(() => {
    setShowMemberSelect(true);
  }, [setShowMemberSelect])

  useEffect(() => {
    if (isSelectDisabled) {
      setHideSelectBtn(isSelectDisabled);
    }
  }, [isSelectDisabled]);

  var paddingStyle = "0px 20px 0px 0px";

  const getBirthDate = useMemo(() => {
    if (memberData?.birthDate) {
      const year = memberData.birthDate.substring(0, 4);
      const month = memberData.birthDate.substring(5, 7);
      const date = memberData.birthDate.substring(8, 10);
      return date + "/" + month + "/" + (parseInt(year) + 543);
    }
    return "";
  }, [memberData]);

  const getGender = useMemo(() => {
    if (memberData?.sex) {
      if (memberData?.sex == "ช") {
        return "ชาย";
      }
      if (memberData?.sex == "ญ") {
        return "หญิง";
      }
    }
    return "";
  }, [memberData]);

  return (
    <div className="uk-width-1-1@m uk-width-1-1" key={"memberEdit"}>
      {memberData?.patient_name != null ? (
        <div style={divStyle} id="htmlMemberData">
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="ผู้ป่วย : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.patient_name}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="HN : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.hn}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="TXN : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.txn}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="เพศ : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={getGender}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="อายุ : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.age}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="วันเกิด : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={getBirthDate}
          />
          {!hideSelectBtn ? (
            <FormElementButton
              padding={paddingStyle}
              label="เลือกผู้ป่วย"
              onClick={handleMemberSelectOpen}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          {!hideSelectBtn && <FormElementButton label="เลือกผู้ป่วย" onClick={handleMemberSelectOpen} />}
        </>
      )}
      {createPortal(
        <MemberSelector show={showMemberSelect} onClose={handleCloseMember} />,
        document.body
      )}
    </div>
  );
};

export default FormMemberProfileHeader;
