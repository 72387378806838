import React, {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useCallback,
} from "react";

import TagsInput from '../../TagsInput';

const FormElementTagsInput = (props) => {
  var [isNewMode, setIsNewMode] = useState(true);

  var mode = "edit";

  if (typeof props.mode != "undefined") {
    mode = props.mode;
  }
  
  function onChange(e) {
    props.onChange(props.fieldId, e);
  }

  if (mode != "view") {
    return (
      <div className="uk-display-inline">
        <TagsInput txn={props.txn} titleSelect={props.titleSelect} subType={props.subType} value={props.value} onChange={(e) => onChange(e)} />
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span>{value}</span> 
      </div>
    );
  }
};

export default FormElementTagsInput;
