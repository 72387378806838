import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import form9_1_image from "../../../images/form_9_1.jpg";
import form9_2_image from "../../../images/form_9_2.jpg";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormHeartRepairPaper from "../FormPaper/FormHeartRepairPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormHeartRepair = (props) => {
  const formConstants = {
    formName: "form_heartrepair",
    formType: "9",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all(
      [patientDataRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];

      setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null)
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    if (
      dataForm.data_1 != "1" &&
      dataForm.data_2 != "1" &&
      dataForm.data_3 != "1" &&
      dataForm.data_4 != "1" &&
      dataForm.data_5 != "1" &&
      dataForm.data_6 != "1"
    ) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Check List");
      return false;
    }
    if (!dataForm.data_7) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Activities before ACS");
      return false;
    }
    if (!dataForm.data_8) {
      displayErrorValidatePopup("จำเป็นต้องระบุ METs");
      return false;
    }
    if (!dataForm.data_9) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Contraindication for exercise");
      return false;
    }
    if (!dataForm.data_10) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ Risk stratification (criteria อยู่ หน้าถัดไป)"
      );
      return false;
    }
    if (!dataForm.data_11) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Target METs");
      return false;
    }
    if (!dataForm.data_12) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่ใน ขั้นที่1 < 2METs ");
      return false;
    }
    if (!dataForm.data_18) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_19) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_20) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_21) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_22) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_23) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_24) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_25) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_26) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่1 < 2METs");
      return false;
    }
    if (!dataForm.data_27) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่ใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_32) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_33) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_34) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_35) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_36) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_37) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_38) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_39) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_40) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่2 < 3METs");
      return false;
    }
    if (!dataForm.data_41) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่ใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_46) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_47) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_48) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_49) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_50) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_51) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_52) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_53) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_54) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่3 < 4METs");
      return false;
    }
    if (!dataForm.data_55) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่ใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_59) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_60) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_61) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_62) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_63) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_64) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_65) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_66) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_67) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่4 < 5METs");
      return false;
    }
    if (!dataForm.data_68) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่ใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_75) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG ก่อนฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_76) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR ก่อนฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_77) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP ก่อนฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_78) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale ก่อนฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_79) {
      displayErrorValidatePopup("จำเป็นต้องระบุ EKG หลังฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_80) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR หลังฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_81) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP หลังฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_82) {
      displayErrorValidatePopup("จำเป็นต้องระบุ scale หลังฝึกใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_83) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Note/Signature ใน ขั้นที่5 < 5METs");
      return false;
    }
    if (!dataForm.data_84) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่ Contraindications for exercise"
      );
      return false;
    }
    return true;
  },
    [dataForm, displayErrorValidatePopup]);

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบบันทึกการฟื้นฟูหัวใจสมรรถภาพหัวใจ โรงพยาบาลมหาราชนครเชียงใหม่" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span className="formTextBoxLabel">
            Check List: <span style={{ color: "red" }}>*</span>
          </span>
        </div>
        <div
          className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top"
          style={{ marginLeft: "24px" }}
        >
          <FormElementCheckBox
            fieldId={"1"}
            value={dataForm.data_1}
            onChange={handleChange}
            label="T < 38.5"
          />
          <FormElementCheckBox
            fieldId={"2"}
            value={dataForm.data_2}
            onChange={handleChange}
            label="BP < 180/110 mmHg, HR < 120 bpm"
          />
          <FormElementCheckBox
            fieldId={"3"}
            value={dataForm.data_3}
            onChange={handleChange}
            label="No orthostatic hypotension > 20 mmHg"
          />
        </div>
        <div
          className="uk-width-1-1@muk-width-1-1 uk-padding-small"
          style={{ marginLeft: "24px" }}
        >
          <FormElementCheckBox
            fieldId={"4"}
            value={dataForm.data_4}
            onChange={handleChange}
            label="No chest pain at least 8 hours before exercise"
          />
          <FormElementCheckBox
            fieldId={"5"}
            value={dataForm.data_5}
            onChange={handleChange}
            label="No dyspnea"
          />
          <FormElementCheckBox
            fieldId={"6"}
            value={dataForm.data_6}
            onChange={handleChange}
            label="No uncontrolled arrhythmia"
          />
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
          <FormElementTextBox
            fieldId={"7"}
            value={dataForm.data_7}
            label="Activities before ACS"
            onChange={handleChange}
            width="100"
            isRequired
          />
          <FormElementTextBox
            fieldId={"8"}
            value={dataForm.data_8}
            label="METs"
            placeholder="เช่น"
            onChange={handleChange}
            width="100"
            isRequired
          />
        </div>
        <div
          className="uk-width-1-1@muk-width-1-1 uk-padding-small"
          style={{ display: "inline-flex", flexWrap: "nowrap" }}
        >
          <div style={{ width: "50%" }}>
            <span className="formTextBoxLabel">
              Contraindication for exercise{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <FormElementRadio
              fieldId={"9"}
              value={dataForm.data_9}
              fieldValue={"0"}
              onChange={handleChange}
              label="Yes (see page 2)"
            />
            <FormElementRadio
              fieldId={"9"}
              value={dataForm.data_9}
              fieldValue={"1"}
              onChange={handleChange}
              label="No"
            />
          </div>
          <div style={{ width: "50%" }}>
            <span className="formTextBoxLabel">
              Risk stratification (criteria อยู่ หน้าถัดไป){" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <FormElementRadio
              fieldId={"10"}
              value={dataForm.data_10}
              fieldValue={"0"}
              onChange={handleChange}
              label="Low"
            />
            <FormElementRadio
              fieldId={"10"}
              value={dataForm.data_10}
              fieldValue={"1"}
              onChange={handleChange}
              label="Moderate"
            />
            <FormElementRadio
              fieldId={"10"}
              value={dataForm.data_10}
              fieldValue={"2"}
              onChange={handleChange}
              label="High"
            />
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
          <span className="formTextBoxLabel">
            Target HR = resting HR + 20 bpm
          </span>
          <FormElementTextBox
            fieldId={"11"}
            value={dataForm.data_11}
            label="Target METs"
            type="numeric"
            onChange={handleChange}
            width="100"
            isRequired
          />
          <span className="formTextBoxLabel">at discharge time</span>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>ขั้นตอน</th>
              <th>Program</th>
              <th>Vital signs ก่อนฝึก</th>
              <th>Vital signs หลังฝึก</th>
              <th>
                Note/Signature <span style={{ color: "red" }}>*</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <FormElementLabel label="ขั้นที่1 < 2METs" />
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"12"}
                    type="date"
                    placeholder="วันที่"
                    value={dataForm.data_12}
                    label=""
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"13"}
                    value={dataForm.data_13}
                    fieldValue={""}
                    onChange={handleChange}
                    label="พักผ่อนบนเตียง"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"14"}
                    value={dataForm.data_14}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Complete bed bath"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"15"}
                    value={dataForm.data_15}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Toilet with bed pan"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"16"}
                    value={dataForm.data_16}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Breathing exercise"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"17"}
                    value={dataForm.data_17}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ออกกำลังกายท่าที่1-3ตามรูป * ท่าละ5ครั้ง"
                  />
                </div>
                <span className="formTextBoxLabel">
                  <strong>วันละ2ครั้ง</strong>
                </span>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"18"}
                    value={dataForm.data_18}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"19"}
                    value={dataForm.data_19}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"20"}
                    value={dataForm.data_20}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"21"}
                    value={dataForm.data_21}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"22"}
                    value={dataForm.data_22}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"23"}
                    value={dataForm.data_23}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"24"}
                    value={dataForm.data_24}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"25"}
                    value={dataForm.data_25}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextArea
                    fieldId={"26"}
                    value={dataForm.data_26}
                    onChange={handleChange}
                    fontWeight="bold"
                    rows="11"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <FormElementLabel label="ขั้นที่2 < 3METs" />
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"27"}
                    placeholder="วันที่"
                    type="date"
                    value={dataForm.data_27}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"28"}
                    value={dataForm.data_28}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ลงนั่งเก้าอี้ข้างเตียงเพื่อทำกิจวัตรประจำวันเช่น"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">
                    รับประทานอาหารนั่งถ่ายอุจจาระ
                  </span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"29"}
                    value={dataForm.data_29}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Partial bed bath"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"30"}
                    value={dataForm.data_30}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ออกกำลังกายท่าที่ 1-6 * ท่าละ 5-10 ครั้ง"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"31"}
                    value={dataForm.data_31}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ยืนทรงตัว"
                  />
                </div>
                <span className="formTextBoxLabel">
                  <strong>วันละ 2 ครั้งเช้าเย็น 2.1, 2.3, 2.4</strong>
                </span>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"32"}
                    value={dataForm.data_32}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"33"}
                    value={dataForm.data_33}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"34"}
                    value={dataForm.data_34}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"35"}
                    value={dataForm.data_35}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"36"}
                    value={dataForm.data_36}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"37"}
                    value={dataForm.data_37}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"38"}
                    value={dataForm.data_38}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"39"}
                    value={dataForm.data_39}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextArea
                    fieldId={"40"}
                    value={dataForm.data_40}
                    onChange={handleChange}
                    fontWeight="bold"
                    rows="11"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <FormElementLabel label="ขั้นที่3 < 4METs" />
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"41"}
                    placeholder="วันที่"
                    type="date"
                    value={dataForm.data_41}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"42"}
                    value={dataForm.data_42}
                    fieldValue={""}
                    onChange={handleChange}
                    label="เริ่มเดินในห้องได้โดยการเดินรอบเตียงช้าๆ"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"43"}
                    value={dataForm.data_43}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ออกกำลังกายท่าที่ 1-7 * ท่าละ 10 ครั้ง"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"44"}
                    value={dataForm.data_44}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ออกกำลังกายท่าที่ 8 ท่าละ 5 ครั้ง"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"45"}
                    value={dataForm.data_45}
                    fieldValue={""}
                    onChange={handleChange}
                    label="เดินย่ำอยู่กับที่"
                  />
                </div>
                <span className="formTextBoxLabel">
                  <strong>วันละ 2 ครั้งเช้าเย็น 3.1-3.4</strong>
                </span>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"46"}
                    value={dataForm.data_46}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"47"}
                    value={dataForm.data_47}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"48"}
                    value={dataForm.data_48}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"49"}
                    value={dataForm.data_49}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"50"}
                    value={dataForm.data_50}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"51"}
                    value={dataForm.data_51}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"52"}
                    value={dataForm.data_52}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"53"}
                    value={dataForm.data_53}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextArea
                    fieldId={"54"}
                    value={dataForm.data_54}
                    onChange={handleChange}
                    label=""
                    fontWeight="bold"
                    rows="11"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <FormElementLabel label="ขั้นที่4 < 5METs" />
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"55"}
                    placeholder="วันที่"
                    type="date"
                    value={dataForm.data_55}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"56"}
                    value={dataForm.data_56}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ทำกิจวัตรประจำวันได้ด้วยตนเองโดยมีผู้ดูแล"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"57"}
                    value={dataForm.data_57}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ออกกำลังกายท่าที่ 1-8 * ท่าละ 10 ครั้งเช้า-เย็น"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"58"}
                    value={dataForm.data_58}
                    fieldValue={""}
                    onChange={handleChange}
                    label="เดินเป็นระยะทาง 15-25 เมตรไป-กลับโดยมีผู้ดูแล"
                  />
                </div>
                <span className="formTextBoxLabel">
                  <strong>วันละ 2 ครั้งเช้าเย็น</strong>
                </span>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"59"}
                    value={dataForm.data_59}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"60"}
                    value={dataForm.data_60}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"61"}
                    value={dataForm.data_61}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"62"}
                    value={dataForm.data_62}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"63"}
                    value={dataForm.data_63}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"64"}
                    value={dataForm.data_64}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"65"}
                    value={dataForm.data_65}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"66"}
                    value={dataForm.data_66}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextArea
                    fieldId={"67"}
                    value={dataForm.data_67}
                    onChange={handleChange}
                    label=""
                    fontWeight="bold"
                    rows="11"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <FormElementLabel label="ขั้นที่5 < 5METs" />
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"68"}
                    type="date"
                    placeholder="วันที่"
                    value={dataForm.data_68}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"69"}
                    value={dataForm.data_69}
                    fieldValue={""}
                    onChange={handleChange}
                    label="สามารถปฏิบัติกิจวัตรประจำวันได้ด้วยตนเองโดยไม่มีผู้ดูแล"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"70"}
                    value={dataForm.data_70}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ออกกำลังกายท่าที่ 1-9 * ท่าละ 10 ครั้งเช้าเย็น"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"71"}
                    value={dataForm.data_71}
                    fieldValue={""}
                    onChange={handleChange}
                    label="เดินภายในหอผู้ป่วยระยะทางประมาณ 50 เมตร ไป-กลับโดยมีผู้ดูแล"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"72"}
                    value={dataForm.data_72}
                    fieldValue={""}
                    onChange={handleChange}
                    label="เดินออกนอกหอผู้ป่วยโดยมีผู้ดูแล"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"73"}
                    value={dataForm.data_73}
                    fieldValue={""}
                    onChange={handleChange}
                    label="เดินขึ้นบันไดแบบพักขา 10-15 ขั้นกลับด้วยลิฟท์"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"74"}
                    value={dataForm.data_74}
                    fieldValue={""}
                    onChange={handleChange}
                    label="เดินขึ้นบันไดแบบพักขา 10-15 ขั้นพักจนหายเหนื่อยแล้ว"
                  />
                </div>
                <span className="formTextBoxLabel">
                  <strong>เดินลงบันไดแบบพักขา 10-15 ขั้น</strong>
                </span>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"75"}
                    value={dataForm.data_75}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"76"}
                    value={dataForm.data_76}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"77"}
                    value={dataForm.data_77}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"78"}
                    value={dataForm.data_78}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"79"}
                    value={dataForm.data_79}
                    label="EKG"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"80"}
                    value={dataForm.data_80}
                    label="HR"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">bpm</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"81"}
                    value={dataForm.data_81}
                    label="BP"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                  <span className="formTextBoxLabel">mmHg</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <span className="formTextBoxLabel">Modifield BORG</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"82"}
                    value={dataForm.data_82}
                    label="scale"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextArea
                    fieldId={"83"}
                    value={dataForm.data_83}
                    onChange={handleChange}
                    label=""
                    fontWeight="bold"
                    rows="11"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <FormElementLine />
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  Contraindications for exercise{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"84"}
                    placeholder="Day"
                    type="date"
                    value={dataForm.data_84}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </div>
              </th>
              <th>Indications for termination of exercise</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"85"}
                    value={dataForm.data_85}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Unstable angina"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"86"}
                    value={dataForm.data_86}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Resting SBP > 200mmHg, DBP > 100mmHg"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"87"}
                    value={dataForm.data_87}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Orthostatic BP drop > 20 mmHg with symptoms"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"88"}
                    value={dataForm.data_88}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Significant Aortic Stenosis"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"89"}
                    value={dataForm.data_89}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Acute systemic illness or fever"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"90"}
                    value={dataForm.data_90}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Uncontrolled sinus tachycardia > 120BPM"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"91"}
                    value={dataForm.data_91}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Uncontrolled atrial or ventricular dysrhythmia"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Uncompensated heart failure"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"93"}
                    value={dataForm.data_93}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Third degree AV block with out pacemaker"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"94"}
                    value={dataForm.data_94}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Active pericarditis, myocarditis"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"95"}
                    value={dataForm.data_95}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Recent embolism, Thrombophlebitis"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"96"}
                    value={dataForm.data_96}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Resting ST-segment depression or elevation"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"97"}
                    value={dataForm.data_97}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Uncontrolled DM"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"98"}
                    value={dataForm.data_98}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Severe orthopedic condition that would prohibit Exercise"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"99"}
                    value={dataForm.data_99}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Other medical conditions that could be aggravated by exercise"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"100"}
                    value={dataForm.data_100}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Other metabolic conditions, such as acute thyroiditis, hypokalemia, hyperkalemia, or hypovolemia (until adequately treat)"
                  />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"101"}
                    value={dataForm.data_101}
                    fieldValue={""}
                    onChange={handleChange}
                    label="HR > TargetHR, THR Post MI = Resting HR + ๒๐ bpm, THR Post heart surgery = Resting HR + ๓๐ bpm"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"102"}
                    value={dataForm.data_102}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Diastolic blood pressure ≥ ๑๑๐ mmHg"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"103"}
                    value={dataForm.data_103}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Systolic blood pressure drop > ๑๐ mmHg"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"104"}
                    value={dataForm.data_104}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Significant atrial or ventricular arrhythmia with or with out symptoms: Multifocal atrial tachycardia (MAT), New onset of atrial fibrillation, Supraventricular tachycardia (SVT), Atrial flutter, Ventricular fibrillation (VF), Ventricular tachycardia (VT) and PVC(triplet PVC, multifocal PVC>30%,R on T phenomenon)"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"105"}
                    value={dataForm.data_105}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Second or third-degree heart block(after exercise)"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                  <FormElementCheckBox
                    fieldId={"106"}
                    value={dataForm.data_106}
                    fieldValue={""}
                    onChange={handleChange}
                    label="Signs and symptoms of cardiac insufficiency(Angina, Marked dyspnea, EKG change suggestive of ischemia)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementLine />
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="แหล่งอ้างอิง ACSM guidelines,9th edition" />
        </div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th colSpan={3} className="center">
                Risk stratification
              </th>
            </tr>
            <tr>
              <th className="center">Low risk</th>
              <th className="center">Moderate risk</th>
              <th className="center">High risk</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-EF>50%" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-No resting or exercise induced complex" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="Dysrhythmias" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Uncomplicated cardiac events" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Asymptomatic normal hemodynamic response to" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="exercise" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-FC> 7 METs" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Absence of clinical" />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-EF 40-49%" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Signs/symptoms at 5-6.9 METs or in recovery" />
                </div>
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-EF <40%" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Hx. of cardiac arrest or sudden death" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Complex ventricular arrhythmia at rest or with exercise" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Complicated cardiac events" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Symptomatic & abnormal hemodynamic response" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="to exercise" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Signs/symptoms < 5 METs" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementLabel label="-Clinically significant depression" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementLine />
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="แหล่งอ้างอิง ACSM guidelines,9th edition" />
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top">
            <img src={form9_1_image} alt="image_1" />
          </div>
          <div
            className="uk-padding-small"
            style={{ border: "1px solid grey", width: "fit-content" }}
          >
            <FormElementLabel label="Modified BORG scale" />
            <div>
              ไม่เหนื่อยเลย=0, น้อยมาก=1, น้อย=2, ปานกลาง=3, ค่อนข้างมาก=4,
              มาก=5, มากๆ=7, มากเกือบที่สุด=9, แทบไม่ไหว=10
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
            <img src={form9_2_image} alt="image_2" />
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
          <p>
            Ross Arena, Deborah Riebe, Paul D Thompson Wolters Kluwer/Lippincott
            Williams & Wilkins, Philadelphia Linda S Pescatello. (2014). ACSM’s
            Guidelines for Exercise Testing and Prescription 9th Ed.
            Philadelphia: Wolters Kluwer Health/Lippincott Williams & Wilkins,
            Philadelphia, cop. 2014
          </p>
          <p>
            พรรณรัตน บญโถ. (2552).
            คู่มือการปฏิบัติงานเรื่องการพยาบาลผู้ป่วยโรคกล้ามเนื้อหัวใจตายเฉียบพลันและการฟื้นฟู
            สมรรถภาพหัวใจระยะผู้ป่วยใน. เชียงใหม่: ฝ่ายการพยาบาล
            โรงพยาบาลมหาราชนครเชียงใหม่.
          </p>
          <p>
            อรินทยา พรหมินธิกุล. (2546). ใน อภิชาต สุคนธสรรพ์, และ ศรัณย์
            ควรประเสริฐ, Coronary Heart Disease New Guidelines (หน้า 293-309).
            เชียงใหม่: ไอแอม ออแกไนซ์เซอร์
          </p>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
          <span hidden={!dataForm.form_id}>
            <FormElementTextArea
              fieldId={"107"}
              rows={5}
              cols={100}
              value={dataForm.data_107}
              noLimit
              onChange={handleChange}
              label="เหตุผลในการแก้ไข"
              width="100"
            />
          </span>
        </div>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={<FormHeartRepairPaper
          dataForm={dataForm}
          patient={patient}
          profile={profile}
        />}
      />
    </div>
  );
};

export default FormHeartRepair;
