//obj[chol,age,sys,diabetes,gender,smoking']

export default (user) => {
  if (user.diabetes === 1) {
    if (user.smoking === 1) {
      if (user.gender === 1) { /** ช */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 55) {
          

          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        } else if (user.age < 60) {
          

          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          }
        } else if (user.age < 65) {
          

          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'red';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          }
        } else {
          

          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'red';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'red';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'red';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'reder';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'red';
            } else if (user.sys < 160) {
              return 'reder';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          }
        }
      } else { /** ญ */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 55) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else if (user.age < 60) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        } else if (user.age < 65) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          }
        } else {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          }
        }
        /*** */
      }

    } else { /** no smoking */
      if (user.gender === 1) { /** ช */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 55) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else if (user.age < 60) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        } else if (user.age < 65) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'reder';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          }
        } else {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'red';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'orange';
            } else if (user.sys < 160) {
              return 'red';
            } else if (user.sys < 180) {
              return 'reder';
            } else {
              return 'reder';
            }
          }
        }
      } else { /** ญ */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          }
        } else if (user.age < 55) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else if (user.age < 60) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else if (user.age < 65) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        } else {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'reder';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          }
        }
        /*** */
      }
    }

  } else {
    /** no diabets */

    if (user.smoking === 1) {
      if (user.gender === 1) { /** ช */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          }
        } else if (user.age < 55) {
          

          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 60) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else if (user.age < 65) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        } else {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'reder';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'orange';
            } else if (user.sys < 180) {
              return 'red';
            } else {
              return 'reder';
            }
          }
        }
      } else { /** ญ */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          }
        } else if (user.age < 55) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 60) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 65) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        }
        /*** */
      }

    } else { /** no smoking */
      if (user.gender === 1) { /** ช */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          }
        } else if (user.age < 55) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 60) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else if (user.age < 65) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'orange';
            }
          }
        } else {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        }
      } else { /** ญ */
        /*** */
        if (user.age < 50) {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          }
        } else if (user.age < 55) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'green';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'orange';
            }
          }
        } else if (user.age < 60) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'green';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          }
        } else if (user.age < 65) {
          
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'yellow';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'green';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          }
        } else {
          if (user.chol < 200) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 240) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'yellow';
            } else {
              return 'orange';
            }
          } else if (user.chol < 280) {
            if (user.sys < 140) {
              return 'green';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'orange';
            }
          } else if (user.chol < 320) {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          } else {
            if (user.sys < 140) {
              return 'yellow';
            } else if (user.sys < 160) {
              return 'yellow';
            } else if (user.sys < 180) {
              return 'orange';
            } else {
              return 'red';
            }
          }
        }
        /*** */
      }
    }

  
  }
}