import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import FormElementIconButton from "./FormElementIconButton";
import "../../../css/dietz.css";

const FormElementUploadImage = (props) => {
  const { maxLength, onChange, value, hideDesc } = props;
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const ref = useRef();

  useEffect(() => {
    if (value) {
      setFiles([...value]);
    }
  }, [value]);

  const convertBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  });

  const validateInput = useCallback((uploadFile) => {
    if (files.filter((s) => s.name == uploadFile.name).length > 0) {
      setErrorMessage("ไม่สามารถอัปโหลดรูปได้ เนื่องจากมีชื่อซ้ำ");
      return false;
    }
    setErrorMessage("");
    return true;
  });

  const onUpload = useCallback(async (e) => {
    if (maxLength && files.length >= maxLength) {
      return;
    }

    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const base64 = await convertBase64(file);
      const uploadFile = {
        name: file.name,
        size: file.size,
        type: file.type,
        base64: base64,
      };

      if (validateInput(uploadFile)) {
        setFiles([...files, uploadFile]);
        onChange([...files, uploadFile]);
      } else {
      }
    }
  });

  const onDelete = useCallback((i) => {
    const tempFiles = files;
    tempFiles.splice(i, 1);
    setFiles([...tempFiles]);
    onChange([...tempFiles]);
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("click", (e) => {
        ref.current.value = null;
      });
    }
  }, [ref]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          gap: "14px",
          alignItems: "center",
        }}
      >
        <label
          style={{
            background: "#00BFA5",
            color: "white",
            padding: "0px 8px",
            height: "50px",
            width: "fit-content",
            cursor: "pointer",
            display: "flex",
            fontWeight: "normal",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            whiteSpace: "nowrap",
          }}
        >
          <span uk-icon="upload" style={{ width: "20px" }} />
          <span style={{ marginLeft: "4px" }}>อัปโหลดรูปภาพ</span>
          <input
            ref={ref}
            style={{ display: "none" }}
            type="file"
            onChange={(e) => onUpload(e)}
            accept="image/*"
          />
        </label>
        {/* {files.length > 0 && <span>{files.length} file(s)</span>} */}
        {!hideDesc && maxLength && (
          <span>อัปโหลดสูงสุดได้ {maxLength} รูปภาพ</span>
        )}
      </div>
      {errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
      <div
        style={{
          display: "inline-flex",
          gap: "14px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {files.map((item, i) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              border: "2px solid #00BFA5",
              borderRadius: "4px",
            }}
          >
            <div
              style={{
                height: "35px",
                background: "#00BFA5",
                color: "white",
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ width: "100%", paddingLeft: "8px" }}>
                {item.name}
              </span>
              <button
                style={{
                  color: "white",
                  background: "transparent",
                  border: "none",
                }}
                onClick={(e) => onDelete(i)}
              >
                <span uk-icon="close" style={{ color: "white" }} />
              </button>
            </div>
            <div style={{ padding: "8px" }}>
              <img
                src={item.base64}
                alt={item.name}
                width={400}
                name={item.name}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormElementUploadImage;
