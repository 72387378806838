import React, { useState, useEffect, useRef } from "react";
import Api from "../../Components/Api";

import FormFammed from "./FormType/FormFammed";
import FormESAS from "./FormType/FormESAS";
import FormPosScale from "./FormType/FormPosScale";
import FormGoodDeath from "./FormType/FormGoodDeath";
import FormTMSE from "./FormType/FormTMSE";
import FormNursingAssessment from "./FormType/FormNursingAssessment";
import FormACSCaringSet from "./FormType/FormACSCaringSet";
import FormACSTeleFollowUp from "./FormType/FormACSTeleFollowUp";
import FormHeartRepair from "./FormType/FormHeartRepair";
import FormCathLabDischarge from "./FormType/FormCathLabDischarge";
import FormCathLabAssessment from "./FormType/FormCathLabAssessment";

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from "moment";
import "../../css/dietz.css";
import FormHomeVisit from "./FormType/FormHomeVisit";
import FormConsultation from "./FormType/FormConsultation";
import FormHeartFailure from "./FormType/FormHeartFailure";
import FormHeartFailureOPD from "./FormType/FormHeartFailureOPD";
import FormHeartFailureDischargeCheckList from "./FormType/FormHeartFailureDischargeCheckList";
import FormADL from "./FormType/FormADL";
import FormStrokePaper from "./IPaper/FormStrokePaper";
import FormRankinScale from "./FormType/FormRankinScale";
import FormPhamacy from "./FormType/FormPhamacy";
import FormPhamacyImage from "./FormType/FormPhamacyImage";
import FormNoac from "./FormType/FormNoac";
import FormWafarin from "./FormType/FormWafarin";
import FormInhaler from "./FormType/FormInhaler";
import FormInsurine from "./FormType/FormInsurine";
import FormBispho from "./FormType/FormBispho";
import FormDeliveryGeneralMedicine from "./FormType/FormDeliveryGeneralMedicine";
import FormDeliveryDrugMedicine from "./FormType/FormDeliveryDrugMedicine";
import FormDeliveryWafarineMedicine from "./FormType/FormDeliveryWafarineMedicine";
import FormPPS from "./FormType/FormPPS";
import FormMoca from "./FormType/FormMoca";
import Form2Q from "./FormType/Form2Q";
import Form8Q from "./FormType/Form8Q";
import Form9Q from "./FormType/Form9Q";
import FormPostFalling from "./FormType/FormPostFalling";
import FormFearFalling from "./FormType/FormFearFalling";
import FormZarit12 from "./FormType/FormZarit12";
import FormDrugAdherence from "./FormType/FormDrugAdherence";
import FormTelePharmacy from "./FormType/FormTelePharmacy";
import FormTGDS from "./FormType/FormTGDS";
import FormGAD7 from "./FormType/FormGAD7";
import FormHomeVisitPaper from "./IPaper/FormHomeVisitPaper";
import FormConsultationPaper from "./IPaper/FormConsultationPaper";
import FormHeartFailurePaper from "./IPaper/FormHeartFailurePaper";
import FormHeartFailureOPDPaper from "./IPaper/FormHeartFailureOPDPaper";
import FormCathLabDischargePaper from "./IPaper/FormCathLabDischargePaper";
import FormHeartFailureDischargeCheckListPaper from "./IPaper/FormHeartFailureDischargeCheckListPaper";
import FormCathLabAssessmentPaper from "./IPaper/FormCathLabAssessmentPaper";
import FormACSCaringSetPaper from "./IPaper/FormACSCaringSetPaper";
import FormACSTeleFollowUpPaper from "./IPaper/FormACSTeleFollowUpPaper";
import FormHeartRepairPaper from "./IPaper/FormHeartRepairPaper";
import FormNursingAssessmentPaper from "./IPaper/FormNursingAssessmentPaper";
import FormTelePharmacyPaper from "./IPaper/FormTelePharmacyPaper";
import FormFammedPaper from "./IPaper/FormFammedPaper";

UIkit.use(Icons);

const FormIPaper = (props) => {
  const [profile, setProfile] = useState(true);
  const [fields, setFields] = useState({});
  const [score, setScore] = useState(0);
  const [hn, setHN] = useState(null);
  const [txn, setTXN] = useState(null);
  const Ref = useRef(this);

  var hideSaveBtn = false;

  if (typeof props.hideSaveBtn != "undefined" && props.hideSaveBtn) {
    hideSaveBtn = true;
  }

  function formInit() {
    const profileTxt = localStorage.getItem("consultProfileData");
    const profileData = JSON.parse(profileTxt);
    setProfile(profileData);
  }

  useEffect(() => {
    async function getDataForm() {
      const { formId, type } = props;

      if (typeof formId != "undefined" && formId != null) {
        var { data } = await Api.consult("ConsultFormGet", {
          formType: type,
          formId: formId,
        });

        setHN(data.hn);
        setTXN(data.txn);

        setFields((fields) => ({ ...fields, ...data }));
      }
    }

    getDataForm();
  }, [props.formId]);

  useEffect(() => {
    console.log(props.type);
    console.log(props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    setHN(props.hn);
    setTXN(props.txn);

    // for test, can delete it.
    /*
    setFields(data => ({
      ...data, data_table_1: [
        {
          data_1: '1',
          data_2: 'xxxx',
          data_3: '',
          data_4: '',
          data_5: '(link จาก EMR)',
          data_6: '1',
          data_7: ''
        },
        {
          data_1: '2',
          data_2: 'yyyy',
          data_3: '',
          data_4: '',
          data_5: '(link จาก EMR)',
          data_6: '2',
          data_7: ''
        }
      ],
      data_forms: [
        {
          question_1: '1',
          remark_1: '',
          question_2: '1',
          remark_2: '',
          question_2: '1',
          remark_2: '',
          question_3: '1',
          remark_3: '',
          question_4: '1',
          remark_4: '',
          question_5: '1',
          remark_5: '',
          question_6: '1',
          remark_6: '',
          question_6: '1',
          remark_6: '',
          question_7: '1',
          remark_7: '',
          question_8: '1',
          remark_8: '',
          question_9: '1',
          remark_9: '',
        }
      ]
    }));*/
    //
  }, [props.hn, props.txn]);

  function addValue(data) {
    setFields((fields) => ({ ...fields, ...data }));
  }

  function doPrint() {
    var paperHtml = Ref.current.innerHTML;
    console.log(paperHtml);
  }

  function formGeneratePopup(type) {
    console.log("preview " + type);

    if (type == "1") {
      return (
        <FormFammedPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "2") {
      return (
        <FormESAS dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "3") {
      return (
        <FormPosScale dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "4") {
      return (
        <FormGoodDeath
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "5") {
      return (
        <FormTMSE dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "6") {
      return (
        <FormNursingAssessmentPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "7") {
      return (
        <FormACSCaringSetPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "8") {
      return (
        <FormACSTeleFollowUpPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "9") {
      return (
        <FormHeartRepairPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "10") {
      return (
        <FormCathLabDischargePaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "11") {
      return (
        <FormCathLabAssessmentPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "12") {
      return (
        <FormHomeVisitPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "13") {
      return (
        <FormConsultationPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "14") {
      return (
        <FormHeartFailurePaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "15") {
      return (
        <FormHeartFailureOPDPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "16") {
      return (
        <FormHeartFailureDischargeCheckListPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "17") {
      return (
        <FormADL
          dataForm={fields}
          setScore={setScore}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "18") {
      return (
        <FormStrokePaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "19") {
      return (
        <FormRankinScale
          dataForm={fields}
          setScore={setScore}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "20") {
      return (
        <FormPhamacy dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "21") {
      return (
        <FormPhamacyImage
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "22") {
      return (
        <FormWafarin dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "23") {
      return (
        <FormNoac dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "24") {
      return (
        <FormInhaler dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "25") {
      return (
        <FormInsurine dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "26") {
      return (
        <FormBispho dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "27") {
      return (
        <FormDeliveryGeneralMedicine
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "28") {
      return (
        <FormDeliveryDrugMedicine
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "29") {
      return (
        <FormDeliveryWafarineMedicine
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "30") {
      return (
        <FormPPS dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "31") {
      return (
        <FormMoca dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "32") {
      return <Form2Q dataForm={fields} addValue={addValue} hn={hn} txn={txn} />;
    } else if (type == "33") {
      return <Form8Q dataForm={fields} addValue={addValue} hn={hn} txn={txn} />;
    } else if (type == "34") {
      return (
        <Form9Q
          dataForm={fields}
          setScore={setScore}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "35") {
      return (
        <FormFearFalling
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "36") {
      return (
        <FormZarit12 dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "37") {
      return (
        <FormDrugAdherence
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "38") {
      return (
        <FormTelePharmacyPaper
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    } else if (type == "39") {
      return (
        <FormTGDS dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "40") {
      return (
        <FormGAD7 dataForm={fields} addValue={addValue} hn={hn} txn={txn} />
      );
    } else if (type == "41") {
      return (
        <FormPostFalling
          dataForm={fields}
          addValue={addValue}
          hn={hn}
          txn={txn}
        />
      );
    }
  }

  useEffect(() => {
    formInit();
    //listGet();
  }, []);

  return (
    <div className="uk-padding">
      <div className="uk-card uk-card-default">
        <div ref={Ref} uk-grid="" className="uk-grid-small uk-padding-small">
          {formGeneratePopup(props.type)}
        </div>
        <div uk-grid="" className="uk-grid-small uk-padding-small">
          <div className="uk-inline formTitle">
            <a
              className="uk-button uk-button-primary"
              target="_blank"
              onClick={doPrint}
            >
              Print
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormIPaper;
