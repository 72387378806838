import React, { useState, useEffect, useMemo } from "react";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTitle from "../Form/FormElementTitle";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const FormADLPaper = (props) => {
  const { dataForm, profile, memberProfile } = props;

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
      dataForm.data_10n,
    ]);
  }, [dataForm]);

  useEffect(() => {
    //console.log(dataForm);
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินกิจวัตรประจําวัน ดัชนีบาร์เธลเอดีแอล (Barthel Activities of Daily Living : ADL)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <FormElementLabel label="การประเมินกิจวัตรประจำวัน" mode="view" />
        <table
          className="table-border uk-table uk-table-small"
          style={{
            marginTop: "14px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <tbody>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                1. รับประทานอาหารเมื่อเตรียมไว้ให้เรียบร้อยต่อหน้า
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_1n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ไม่สามารถตักอาหารเข้าปากได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ตักอาหารเองได้ แต่ต้องมีคนช่วย เช่น
                ช่วยใช้ช้อนตักเตรียมให้/ตัดเป็นชิ้นเล็กๆให้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ตักอาหารและช่วยตัวเองได้เป็นปกติ
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                2. การล้างหน้า หวีผม แปรงฟัน โกนหนวดในระยะเวลา 24–48
                ชั่วโมงที่ผ่านมา
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_2n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ต้องการความช่วยเหลือ
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ทำได้เอง (รวมทั้งที่ทำได้เองถ้าเตรียมอุปกรณ์ไว้ให้)
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                3. ลุกนั่งจากที่นอน หรือจากเตียงไปยังเก้าอี้
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_3n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ไม่สามารถนั่งได้ (นั่งแล้วจะล้มเสมอ) หรือต้องใช้คน 2
                คนช่วยกันยกขึ้น
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ต้องใช้คนแข็งแรงหรือมีทักษะ 1 คน/ใช้คนทั่วไป 2
                คนพยุงดันขึ้นมาจึงจะนั่งอยู่ได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ต้องการความช่วยเหลือบ้าง
                เช่นช่วยพยุงเล็กน้อย/ต้องมีคนดูแลเพื่อความปลอดภัย
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "15" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ทำได้เอง
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                4. การใช้ห้องน้ำ
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_4n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ช่วยตัวเองไม่ได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ทำเองได้บ้าง ต้องการความช่วยเหลือในบางสิ่ง
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ช่วยเหลือตนเองได้ดี
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                5. การเคลื่อนที่ภายในห้องหรือบ้าน
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_5n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                เคลื่อนที่ไปไหนไม่ได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ใช้รถเข็นช่วยให้เคลื่อนที่ได้เอง (ไม่ต้องมีคนเข็นให้)
                เข้าออกมุมห้องหรือประตูได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                เดินหรือเคลื่อนที่โดยมีคนช่วย เช่น พยุง
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "15" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                เดินหรือเคลื่อนที่ได้เอง
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                6. การสวมใส่เสื้อผ้า
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_6n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ต้องมีคนสวมใส่ให้ ช่วยตัวเองแทบไม่ได้หรือได้น้อย
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ช่วยตัวเองได้ประมาณร้อยละ 50 ที่เหลือต้องมีคนช่วย
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ช่วยตัวเองได้ดี (รวมทั้งการติดกระดุม รูดซิป
                ใส่เสื้อผ้าที่ดัดแปลงให้เหมาะสมก็ได้)
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                7. การขึ้นลงบันได 1 ชั้น
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_7n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ไม่สามารถทำได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ต้องการคนช่วย
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ขึ้นลงได้เอง (ถ้าต้องใช้เครื่องช่วยเดิน เช่น Walker
                จะต้องเอาขึ้นลงได้ด้วย)
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                8. การอาบน้ำ
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_8n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ต้องมีคนช่วยทำให้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_8n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                อาบน้ำได้เอง
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                9. การกลั้นการถ่ายอุจจาระใน 1 สัปดาห์ที่ผ่านมา
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_9n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                กลั้นไม่ได้ หรือต้องการการสวนอุจจาระอยู่เสมอ
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                กลั้นไม่ได้บางครั้ง (ไม่เกิน 1 ครั้งต่อสัปดาห์)
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                กลั้นได้ปกติ
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                10. การกลั้นปัสสาวะในระยะ 1 สัปดาห์ที่ผ่านมา
              </th>
            </tr>
            <tr>
              <td
                style={{
                  minWidth: "50px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {dataForm.data_10n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                กลั้นไม่ได้ หรือใส่สายสวนปัสสาวะ แต่ไม่สามารถดูแลเองได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_10n == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                กลั้นไม่ได้บางครั้ง (ไม่เกิน 1 ครั้งต่อสัปดาห์)
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_10n == "10" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                กลั้นได้ปกติ
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementLabel label={`คะแนนรวม: ${getTotalScore}`} />
        <div>
          <SavingProfileIPaper profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default FormADLPaper;
