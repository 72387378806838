import React, { useEffect, useSearchParams } from 'react';
import Api from '../../Components/Api';
import LoginForm from '../../Components/LoginForm';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import LanguageLoginSelect from '../../Components/LanguageLoginSelect';
import __ from '../../Components/Language';
import queryString from 'query-string';

UIkit.use(Icons);

const MemberLogin = (props) => {
  async function login(data) {
    var { result, responseText, session } = await Api.member('MemberLoginByForm', data);

    UIkit.notification(`<span uk-icon='icon: ${result ? 'check' : 'close'}'></span> ${__(responseText)}`);

    if (result && session) {
      localStorage.setItem('memberLogin', session);

      if(props.qs.redirect) {
        window.location.href = `${decodeURIComponent(props.qs.redirect)}?route=${decodeURIComponent(props.qs.redirect)}&token_member=${encodeURIComponent(session)}`;
      } else {
        window.location.href = "/member";
      }
    }
  }

  useEffect(() => {
    let params = queryString.parse(props.location.search)

    
    if(typeof(params["hn"]) != "undefined") {
      login({hn : params["hn"]});
    }

    
  }, []);

    return (<div>{/*
      <div className="d-form-login-body uk-padding-small">
        <div className="uk-card uk-card-default uk-padding uk-margin-top uk-padding-remove-top d-form-login-container uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
          <LanguageLoginSelect />
          <div className="uk-text-center uk-padding-small">
            <img src="/logo_dietz.png" />
          </div>
          <h1 className="d-form-login-title">{__('เข้าสู่ระบบ')}</h1>
          <LoginForm {...props} onSubmit={login} />
        </div>
      </div>*/}
    </div>)
}

export default MemberLogin;