import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import moment from 'moment';
import FullScreenElement from '../FullScreenElement';

import DataTypeSelect from '../../Components/DataTypeSelect.js';
import DataType from '../../Components/DataType.js';

UIkit.use(Icons);

export default class UserHealthRecord extends Component {

  state = {
    dataType: 'bmi',
    xAxisData: [],
    yAxisData: {},
  }

  componentDidMount() {
    this.dataGet();
  }

  async dataGet() {
    var _dataType = JSON.parse(JSON.stringify(this.state.dataType));
    _dataType = '(' + _dataType.split(',').map(d => `'${d}'`).join(',') + ')';

    var { data } = await Api.member('UserHealthRecord', { dataType: _dataType });

    if (!data) data = [];

    var xAxisData = [];
    var yAxisData = {
      data0: { name: '', data: [] },
      data1: { name: '', data: [] },
      data2: { name: '', data: [] },
      data3: { name: '', data: [] },
      data4: { name: '', data: [] },
    };


    var _dataByDate = {};

    for (var i = data.length; i-- > 0;) {
      var _dateadd = moment(data[i].dateadd).format('DD/MM/YY');

      if (!_dataByDate[_dateadd]) _dataByDate[_dateadd] = {};

      if (data[i].name === 'sys/dias') {
        var value = data[i].value.split('/');
        _dataByDate[_dateadd].sys = value[0];
        _dataByDate[_dateadd].dias = value[1];

      } else {
        _dataByDate[_dateadd][data[i].name] = data[i].value;
      }

    }

    for (var i in _dataByDate) {
      xAxisData.push(i);

      var keyAxisY = 0;

      for (var i1 in _dataByDate[i]) {
        var _di1 = _dataByDate[i][i1];

        yAxisData[`data${keyAxisY}`].name = i1;
        yAxisData[`data${keyAxisY}`].data.push(_di1);

        keyAxisY++;

      }
    };

    xAxisData.reverse();


    /*
    for(var i=0; i<data.length; i++) {
      xAxisData.push(moment(data[i].dateadd).format('DD/MM/YY'));

      if(this.state.dataType === 'sys/dias') {
        var value = data[i].value.split('/');
        yAxisData.push(value[0]);
        yAxisData1.push(value[1]);
      } else {
        yAxisData.push(data[i].value);
      }
    }
    */

    this.setState({ xAxisData: xAxisData.reverse(), yAxisData: yAxisData });
  }

  selectDataTypeRender = () => {
    return <DataTypeSelect dataType={this.state.dataType} callback={(value) => {
      this.setState({ dataType: value }, () => this.dataGet());
    }} />
    /*
    return <select className="uk-select uk-width-small" value={this.state.dataType} onChange={e=>this.setState({dataType: e.target.value},()=>this.dataGet())} >
      {DataType.map((data, index)=>{
        return <option value={data}>{data}</option>
      })}
    </select>*/
  }

  graphRender = () => {

    var ySeries = [];

    for (var i in this.state.yAxisData) {

      ySeries.push({
        name: this.state.yAxisData[i].name,
        type: "line",
        symbol: 'circle',
        smooth: true,
        symbolSize: 7,
        itemStyle: {
          normal: {
            areaStyle: {
              type: "default"
            }
          }
        },
        data: this.state.yAxisData[i].data
      });
    }


    var option = {
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },
      tooltip: {
        trigger: "axis"
      },
      legend: {
        data: ["BMI"]
      },
      color: ["#2f94f3", "#40C7CA", "#FFA87D"],
      calculable: true,
      xAxis: [{
        type: "category",
        boundaryGap: false,
        data: this.state.xAxisData,
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000',
          fontSize: 10,
        },
      }],
      yAxis: [{
        type: "value",
        boundaryGap: true,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],
      series: ySeries
    };

    return <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-user-health-record">
      <div className="d-card-header">
        <div className="d-card-header-left">
          HEALTH RECORDS
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-user-health-record" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        {this.selectDataTypeRender()}
      </div>
      {this.graphRender()}
    </div>
  }
};
