import React, { useEffect, useSearchParams } from 'react';
import Api from '../../Components/Api';
import LoginForm from '../../Components/LoginForm';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import queryString from 'query-string';

UIkit.use(Icons);

const ConsultLoginX = (props) => {

  async function login(data) {
    var { result, responseText, session } = await Api.consult('ConsultLoginByForm', data);

    UIkit.notification(`<span uk-icon='icon: ${result ? 'check' : 'close'}'></span> ${responseText}`);

    if (result && session) {
      localStorage.setItem('consultLogin', session);
      
      window.location.href="/consult";
    }
  }

  useEffect(() => {
    let params = queryString.parse(props.location.search)

    if(typeof(params["token"]) != "undefined") {
      login({token_login : params["token"]});
    }

    
  }, []);

  return (
    <div id="d-consult-background-login" className="uk-background-cover">
      <div className="uk-position-center">
        <div className=" uk-card uk-card-default uk-padding-small uk-width-medium">
          <h4 className="uk-text-center">เข้าสู่ระบบ</h4>
          <LoginForm {...props} onSubmit={login} />
        </div>
      </div>
    </div>
  )
}

export default ConsultLoginX;