import Api from "../../Components/Api";

async function fetchFormFileListByTxn(txn) {
    try {
        var result = await Api.consult("FormTxnFilesList", {
            txn: txn,
        });

        if (result.list) {
            return result.list;
        } 
        return [];
    } catch (error) {
        console.error(`fetchFormFileListByTxn: txn(${txn})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchFormFileListByTxn;