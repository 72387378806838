import React, { useState, useEffect } from "react";
import Api from "../../Api";
import UIkit from "uikit";

const FormElementSendDrawingPaper = (props) => {
  const [attachImg, setAttachImg] = useState(null);

  useEffect(() => {
    if (props.value != null) {
      setAttachImg(props.value);
    }
  }, [props.value]);

  const attachFiles = async () => {
    const result = await Api.consult("FormTxnFilesList", {
      txn: props.txn,
      is_active: "1",
    });

    if (result.list.length > 0) {
      const resultFilesList = await Api.consult("FormTxnFilesList", {
        id: result.list[0].id,
      });

      if (resultFilesList.list.length > 0) {
        props.onChange(props.fieldId, resultFilesList.list[0].file_data);
      }
    }

    // setAttachImg(result.list[0].file_data);
  };

  const deleteFiles = async () => {
    props.onChange(props.fieldId, "");

    setAttachImg(null);
  };

  const sendFiles = async () => {
    const fileId = props.fileId;

    const profileTxt = localStorage.getItem("consultProfileData");
    const profileData = JSON.parse(profileTxt);

    const resultTxnFilesAdd = await Api.consult("FormTxnFilesSend", {
      form_id: props.formId,
      txn: props.txn,
      file_id: fileId,
      created_by: profileData.consultId,
    });

    if (!resultTxnFilesAdd.result) {
      console.log(resultTxnFilesAdd.responseText);
      UIkit.notification({
        message: "ไม่สามารถส่งรูปให้ผู้ป่วยได้",
        status: "danger",
        pos: "top-right",
        timeout: 5000,
      });
    } else {
      UIkit.notification({
        message: "ส่งรูปให้ผู้ป่วยสำเร็จ",
        status: "success",
        pos: "top-right",
        timeout: 5000,
      });
    }
  };

  return (
    <div className="uk-inline">
      {attachImg ? (
        <img src={attachImg} width={props.width} height={props.height} />
      ) : null}
      <br />
      <button className="uk-button uk-button-primary" onClick={sendFiles}>
        ส่งรูปให้ผู้ป่วย
      </button>
      &nbsp;
      <button className="uk-button uk-button-primary" onClick={attachFiles}>
        แนบรูปจากผู้ป่วย
      </button>
      &nbsp;
      <button className="uk-button uk-button-primary" onClick={deleteFiles}>
        ลบรูป
      </button>
    </div>
  );
};

export default FormElementSendDrawingPaper;
