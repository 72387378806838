import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import FontElementSendDrawingPaper from "../Form/FormElementSendDrawingPaper";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import Api from "../../Api";
import FormElementDatePicker from "../Form/FormElementDatePicker";
import FormElementUploadImage from "../Form/FormElementUploadImage";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormConsultation = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [familyItemsDropDownList, setFamilyItemsDropDownList] = useState([]);
  var [nxOpdDropDownList, setNxOpdDropDownList] = useState([]);
  var [patientVisitData, setPatientVisitData] = useState({});

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    async function initValue() {
      var initValue = {
        formName: "form_consultation",
        formType: "13",
      };

      props.addValue(initValue);
    }
    initValue();
  }, []);

  useEffect(() => {
    var consultProfileDataStorage = localStorage.getItem("consultProfileData");
    var consultProfileData = JSON.parse(consultProfileDataStorage);
    props.addValue({ ...dataForm, data_23: consultProfileData.doctorName });
  }, [localStorage.getItem("consultProfileData")]);

  async function getPatientVisit() {
    var result = await Api.consult("GetPatientVisit", {
      hn: props.hn,
      txn: props.txn,
    });

    var { data } = result;
    setPatientVisitData(data);
  }

  useEffect(() => {
    getPatientVisit();
  }, [props.hn, props.txn]);

  useEffect(() => {
    setData("3", patientVisitData?.type);
    setData("4", patientVisitData?.addr);
    setData("32", patientVisitData?.room_code);
  }, [patientVisitData]);

  async function getFamilyItem() {
    try {
      var result = await Api.consult("GetFamilyItem", {});

      var { data } = result;
      const dataArray = Object.keys(data);

      const list = dataArray.map((item) => ({
        value: item,
        label: data[item],
      }));
      setFamilyItemsDropDownList(list);
    } catch (err) {
      console.log(err);
    }
  }

  async function getNxOPDList() {
    try {
      var result = await Api.consult("GetNxOPDList", {});

      if (result.list.data) {
        const data = result.list.data;
        const dataArray = Object.keys(data);
        const list = dataArray.map((item) => ({
          value: item,
          label: data[item],
        }));
        setNxOpdDropDownList(list);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getFamilyItem();
    getNxOPDList();
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Family Medicine, CMU" />
        <br />
        <FormElementTitle label="Consultation Form" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1"></div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"32"}
          value={dataForm.data_32}
          label="ห้อง"
          disableKey={true}
          onChange={handleChange}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <span className="formTextBoxLabel" style={{ whiteSpace: "nowrap" }}>
            วันที่-เวลา
          </span>
          <FormElementDatePicker
            value={dataForm.data_1}
            withTime
            onChange={(v) => setData("1", v)}
          />
        </div>

        <FormElementDropdown
          fieldId={"2"}
          value={dataForm.data_2}
          label="หน่วยงานที่ส่งปรึกษา"
          onChange={handleChange}
          dataList={[{ label: "เลือกหน่วยงานที่ส่งปรึกษา", value: "" }].concat(
            nxOpdDropDownList
          )}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="1. ข้อมูลผู้ป่วย" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"3"}
          value={dataForm.data_3}
          label="สิทธิ์การรักษา"
          disableKey={true}
          onChange={handleChange}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"4"}
          value={dataForm.data_4}
          label="ที่อยู่"
          onChange={handleChange}
          disableKey={true}
          width="500"
          // regEx={/^\d*['/']{0,1}?\d*$/}
          maxLength={255}
          rows={3}
          cols={80}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"9"}
          value={dataForm.data_9}
          label="ญาติผู้ป่วย"
          onChange={handleChange}
          width="100"
        />
        <FormElementDropdown
          fieldId={"10"}
          value={dataForm.data_10}
          label="ความสัมพันธ์"
          onChange={handleChange}
          dataList={[{ value: "0", label: "เลือกความสัมพันธ์" }].concat(
            familyItemsDropDownList
          )}
          width="100"
        />
        <span hidden={dataForm.data_10 != "99"}>
          <FormElementTextBox
            fieldId={"31"}
            value={dataForm.data_31}
            placeholder="ระบุ"
            onChange={handleChange}
          />
        </span>
        <FormElementTextBox
          fieldId={"11"}
          value={dataForm.data_11}
          type="numeric"
          label="เบอร์ติดต่อ"
          onChange={handleChange}
          width="100"
          maxLength="10"
        />
      </div>
      <div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <tbody>
            <tr>
              <td>
                <FormElementLabel
                  label="Family tree and family profile (หากมีข้อมูล)"
                  padding="0px 10px 0px 0px"
                />
                <br />
                <FontElementSendDrawingPaper 
                  fieldId={"12"} 
                  value={dataForm.data_12} 
                  formId="13" 
                  txn={dataForm.txn} 
                  fileId="60" 
                  width="333"
                  height="500"
                  onChange={handleChange} />
                
              </td>
            </tr>
          </tbody>
        </table>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel
            label="2.	Biological problem and current management (+medications)"
            padding="0px 10px 0px 0px"
          />
          <br />
          <FormElementTextArea
            fieldId={"13"}
            rows={10}
            cols={150}
            value={dataForm.data_13}
            onChange={handleChange}
            noLimit
            width="100"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementSpace />
          <FormElementLabel
            label="3.	Psychosocial & spiritual problem and current management "
            padding="0px 10px 0px 0px"
          />
          <br />
          <FormElementTextArea
            fieldId={"14"}
            rows={10}
            cols={150}
            value={dataForm.data_14}
            onChange={handleChange}
            noLimit
            width="100"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementSpace />
          <FormElementLabel label="4.	อุปกรณ์การแพทย์ที่ต้องใช้  " width="100" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div uk-grid="" className="uk-grid">
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementRadio
                fieldId={"15"}
                value={dataForm.data_15}
                fieldValue={"1"}
                onChange={handleChange}
                label="ไม่มี"
              />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementRadio
                fieldId={"15"}
                value={dataForm.data_15}
                fieldValue={"2"}
                onChange={handleChange}
                label="มี"
              />
              <span
                className={
                  dataForm.data_15 == "2" ? "form-show" : "form-hidden"
                }
              >
                <span className="formTextBoxLabel">ได้แก่</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementCheckBox
                  fieldId={"16"}
                  value={dataForm.data_16}
                  onChange={handleChange}
                  label="NG tube"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementCheckBox
                  fieldId={"17"}
                  value={dataForm.data_17}
                  onChange={handleChange}
                  label="Tracheostomy tube"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementCheckBox
                  fieldId={"18"}
                  value={dataForm.data_18}
                  onChange={handleChange}
                  label="Foley's cath"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementCheckBox
                  fieldId={"19"}
                  value={dataForm.data_19}
                  onChange={handleChange}
                  label="O2Canula"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementCheckBox
                  fieldId={"20"}
                  value={dataForm.data_20}
                  onChange={handleChange}
                  label="อื่นๆ"
                />
                <span
                  className={
                    dataForm.data_20 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"21"}
                    value={dataForm.data_21}
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementSpace />
          <FormElementLabel
            label="5.	Other suggestions  "
            padding="0px 10px 0px 0px"
          />
          <br />
          <FormElementTextArea
            fieldId={"22"}
            rows={10}
            cols={150}
            value={dataForm.data_22}
            onChange={handleChange}
            width="100"
            noLimit
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTextBox
            fieldId={"23"}
            value={dataForm.data_23}
            label="ผู้ส่งปรึกษา"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"24"}
            value={dataForm.data_24}
            label="เบอร์ติดต่อ"
            type="numeric"
            onChange={handleChange}
            width="100"
            maxLength="10"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementSpace />
          <FormElementLabel label="การวางแผนการดูแลโดยแพทย์เวชศาสตร์ครอบครัว" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div uk-grid="" className="uk-grid">
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementRadio
                fieldId={"25"}
                value={dataForm.data_25}
                fieldValue={"1"}
                onChange={handleChange}
                label="เยี่ยมบ้าน onsite"
              />
              <span
                className={
                  dataForm.data_25 == "1" ? "form-show" : "form-hidden"
                }
              >
                <FormElementCheckBox
                  fieldId={"26"}
                  value={dataForm.data_26}
                  onChange={handleChange}
                  label="พักเขตอำเภอเมืองหรือระยะเวลาเดินทางไม่เกิน 15 นาที"
                />
                <FormElementCheckBox
                  fieldId={"27"}
                  value={dataForm.data_27}
                  onChange={handleChange}
                  label="ผู้ป่วย/ครอบครัวยินยอมให้เยี่ยมบ้าน"
                />
              </span>
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementRadio
                fieldId={"25"}
                value={dataForm.data_25}
                fieldValue={"2"}
                onChange={handleChange}
                label="เยี่ยมแบบ Virtual Home Visit"
              />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementRadio
                fieldId={"25"}
                value={dataForm.data_25}
                fieldValue={"3"}
                onChange={handleChange}
                label="ยังไม่เยี่ยม"
              />
              <span
                className={
                  dataForm.data_25 == "3" ? "form-show" : "form-hidden"
                }
              >
                <FormElementTextBox
                  fieldId={"28"}
                  value={dataForm.data_28}
                  label="เนื่องจาก"
                  onChange={handleChange}
                  width="100"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="Note" padding="0px 10px 0px 0px" />
          <br />
          <FormElementTextArea
            fieldId={"29"}
            rows={10}
            cols={150}
            value={dataForm.data_29}
            onChange={handleChange}
            width="100"
            noLimit
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTextBox
            fieldId={"30"}
            label="ผู้รับปรึกษา"
            value={dataForm.data_30}
            onChange={handleChange}
            width="100"
          />
        </div>
      </div>
    </div>
  );
};

export default FormConsultation;
