import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import { parse } from "date-fns";
var utilHandleChange = require("./FormUtil.js").handleChange;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormPPS = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    var datas = { ...dataForm };

    datas = { ...datas, ["data_" + field]: valueData };

    setDataForm(datas);
  }

  function setDataObj(data) {
    var datas = { ...dataForm };

    datas = { ...datas, ...data };

    setDataForm(datas);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_pps",
      formType: "30",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  // const getTotalScore = useMemo(
  //   (data) => {
  //     return Math.min(...data) * 10;
  //   },
  //   [dataForm]
  // );

  // const getCurrentData = useCallback(() => {
  //   const data = [];
  //   if (dataForm.data_1n) {
  //     data.push({ key: "1n", value: parseInt(dataForm.data_1n) });
  //   }
  //   if (dataForm.data_2n) {
  //     data.push({ key: "2n", value: parseInt(dataForm.data_2n) });
  //   }
  //   if (dataForm.data_3n) {
  //     data.push({ key: "3n", value: parseInt(dataForm.data_3n) });
  //   }
  //   if (dataForm.data_4n) {
  //     data.push({ key: "4n", value: parseInt(dataForm.data_4n) });
  //   }
  //   if (dataForm.data_5n) {
  //     data.push({ key: "5n", value: parseInt(dataForm.data_5n) });
  //   }
  //   if (dataForm.data_6n) {
  //     data.push({ key: "6n", value: parseInt(dataForm.data_6n) });
  //   }
  //   return data;
  // }, [dataForm]);

  // useEffect(() => {
  //   props.setScore(getTotalScore(getCurrentData().map((item) => item.value)));
  // }, [dataForm]);

  // const isDisabledData2Choice1 = useMemo(() => {
  //   if (parseInt(dataForm.data_1n) < 8) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData2Choice2 = useMemo(() => {
  //   if (parseInt(dataForm.data_1n) < 6 && isDisabledData2Choice1) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData2Choice3 = useMemo(() => {
  //   if (parseInt(dataForm.data_1n) < 5 && isDisabledData2Choice2) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData2Choice4 = useMemo(() => {
  //   if (parseInt(dataForm.data_1n) < 4 && isDisabledData2Choice3) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData2Choice5 = useMemo(() => {
  //   if (parseInt(dataForm.data_1n) < 1 && isDisabledData2Choice4) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice1 = useMemo(() => {
  //   if (parseInt(dataForm.data_1n) < 10 || parseInt(dataForm.data_2n) < 10) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice2 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 9 || parseInt(dataForm.data_2n) < 9) &&
  //     isDisabledData3Choice1
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice3 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 8 || parseInt(dataForm.data_2n) < 8) &&
  //     isDisabledData3Choice2
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice4 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 7 || parseInt(dataForm.data_2n) < 7) &&
  //     isDisabledData3Choice3
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice5 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 6 || parseInt(dataForm.data_2n) < 6) &&
  //     isDisabledData3Choice4
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice6 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 5 || parseInt(dataForm.data_2n) < 5) &&
  //     isDisabledData3Choice5
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice7 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 4 || parseInt(dataForm.data_2n) < 4) &&
  //     isDisabledData3Choice6
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData3Choice8 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 1 || parseInt(dataForm.data_2n) < 1) &&
  //     isDisabledData3Choice6
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData4Choice1 = useMemo(() => {
  //   if (
  //     parseInt(dataForm.data_1n) < 7 ||
  //     parseInt(dataForm.data_2n) < 7 ||
  //     isDisabledData3Choice4
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData4Choice2 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 6 ||
  //       parseInt(dataForm.data_2n) < 6 ||
  //       parseInt(dataForm.data_3n) < 6) &&
  //     isDisabledData4Choice1
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData4Choice3 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 5 ||
  //       parseInt(dataForm.data_2n) < 5 ||
  //       parseInt(dataForm.data_3n) < 5) &&
  //     isDisabledData4Choice2
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData4Choice4 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 4 ||
  //       parseInt(dataForm.data_2n) < 4 ||
  //       parseInt(dataForm.data_3n) < 4) &&
  //     isDisabledData4Choice3
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData4Choice5 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 1 ||
  //       parseInt(dataForm.data_2n) < 1 ||
  //       parseInt(dataForm.data_3n) < 1) &&
  //     isDisabledData4Choice4
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData5Choice1 = useMemo(() => {
  //   if (
  //     parseInt(dataForm.data_1n) < 9 ||
  //     parseInt(dataForm.data_2n) < 9 ||
  //     parseInt(dataForm.data_3n) < 9 ||
  //     parseInt(dataForm.data_4n) < 9
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData5Choice2 = useMemo(() => {
  //   if (
  //     parseInt(dataForm.data_1n) < 3 ||
  //     parseInt(dataForm.data_2n) < 3 ||
  //     parseInt(dataForm.data_3n) < 3 ||
  //     parseInt(dataForm.data_4n) < 3
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData5Choice3 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 2 ||
  //       parseInt(dataForm.data_2n) < 2 ||
  //       parseInt(dataForm.data_3n) < 2 ||
  //       parseInt(dataForm.data_4n) < 2) &&
  //     isDisabledData5Choice2
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData5Choice4 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 1 ||
  //       parseInt(dataForm.data_2n) < 1 ||
  //       parseInt(dataForm.data_3n) < 1 ||
  //       parseInt(dataForm.data_4n) < 1) &&
  //     isDisabledData5Choice3
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData6Choice1 = useMemo(() => {
  //   if (
  //     parseInt(dataForm.data_1n) < 7 ||
  //     parseInt(dataForm.data_2n) < 7 ||
  //     parseInt(dataForm.data_3n) < 7 ||
  //     parseInt(dataForm.data_4n) < 7 ||
  //     parseInt(dataForm.data_5n) < 7
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData6Choice2 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 5 ||
  //       parseInt(dataForm.data_2n) < 5 ||
  //       parseInt(dataForm.data_3n) < 5 ||
  //       parseInt(dataForm.data_4n) < 5 ||
  //       parseInt(dataForm.data_5n) < 5) &&
  //     isDisabledData6Choice1
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData6Choice3 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 2 ||
  //       parseInt(dataForm.data_2n) < 2 ||
  //       parseInt(dataForm.data_3n) < 2 ||
  //       parseInt(dataForm.data_4n) < 2 ||
  //       parseInt(dataForm.data_5n) < 2) &&
  //     isDisabledData6Choice2
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  // const isDisabledData6Choice4 = useMemo(() => {
  //   if (
  //     (parseInt(dataForm.data_1n) < 1 ||
  //       parseInt(dataForm.data_2n) < 1 ||
  //       parseInt(dataForm.data_3n) < 1 ||
  //       parseInt(dataForm.data_4n) < 1 ||
  //       parseInt(dataForm.data_5n) < 1) &&
  //     isDisabledData6Choice3
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [dataForm]);

  const getTotalScore = useMemo(() => {
    const { data_1n, data_2n, data_3n, data_4n, data_5n } = dataForm;
    const data = [
      data_1n || 0,
      data_2n || 0,
      data_3n || 0,
      data_4n || 0,
      data_5n || 0,
    ];
    return Math.min(...data);
  }, [dataForm]);

  useEffect(() => {
    props.setScore(getTotalScore);
  }, [getTotalScore]);

  useEffect(() => {
    setData("6n", getTotalScore);
  }, [getTotalScore]);

  useEffect(() => {
    console.log("score", getTotalScore);
  }, [getTotalScore]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบประเมินระดับผู้ป่วยที่ได้รับการดูแลแบบประคับประคอง ฉบับสวนดอก" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="(Palliative performance scale for adult Suandok) (PPS Adult Suandok)" />
      </div>

      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider form-table">
          <tbody>
            <tr>
              <th className="center">ระดับ PPS ร้อยละ </th>
              <td colSpan={8}>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"10"}
                  label="100"
                />

                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"9"}
                    label="90"
                  />
                </span>
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"8"}
                    label="80"
                  />
                </span>
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"7"}
                    label="70"
                  />
                </span>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"6"}
                  label="60"
                />
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"5"}
                    label="50"
                  />
                </span>
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"4"}
                    label="40"
                  />
                </span>
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"3"}
                    label="30"
                  />
                </span>
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"2"}
                    label="20"
                  />
                </span>
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"1"}
                    label="10"
                  />
                </span>
                <span className="formTextBoxLabel">
                  <FormElementRadio
                    onChange={handleChange}
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"0"}
                    label="0"
                  />
                </span>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th className="center">การเคลื่อนไหว</th>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"10"}
                  label="เคลื่อนไหวปกติ"
                  disabled={isDisabledData2Choice1}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"7"}
                  label="ความสามารถในการเคลื่อนไหวลดลง"
                  disabled={isDisabledData2Choice2}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"5"}
                  label="นั่งหรือนอนเป็นส่วนใหญ่"
                  disabled={isDisabledData2Choice3}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"4"}
                  label="นอนอยู่บนเตียงเป็นส่วนใหญ่"
                  disabled={isDisabledData2Choice4}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"3"}
                  label="นอนอยู่บนเตียงตลอดเวลา"
                  disabled={isDisabledData2Choice5}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"0"}
                  label="เสียชีวิต"
                />
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th className="center">การปฏิบัติกิจกรรมและการดำเนินโรค</th>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"10"}
                  label="ทำกิจกรรมและงานได้ตามปกติและไม่มีอาการของโรค"
                  disabled={isDisabledData3Choice1}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"9"}
                  label="ทำกิจกรรมและทำงานได้ตามปกติและมีอาการของโรคบางอาการ"
                  disabled={isDisabledData3Choice2}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"8"}
                  label="ต้องออกแรงอย่างมากในการทำกิจกรรมตามปกติและมีอาการของโรคบางอาการ"
                  disabled={isDisabledData3Choice3}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"7"}
                  label="ไม่สามารถทำงานได้ตามปกติและมีอาการของโรคอย่างมาก"
                  disabled={isDisabledData3Choice4}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"6"}
                  label="ไม่สามารถทำงานอดิเรกหรืองานบ้านได้และมีอาการของโรคอย่างมาก"
                  disabled={isDisabledData3Choice5}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"5"}
                  label="ไม่สามารถทำงานได้เลยและมีการลุกลามของโรค"
                  disabled={isDisabledData3Choice6}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"4"}
                  label="ทำกิจกรรมได้น้อยมากและมีการลุกลามของโรค"
                  disabled={isDisabledData3Choice7}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"3"}
                  label="ไม่สามารถทำกิจกรรมใดๆและมีการลุกลามของโรค"
                  disabled={isDisabledData3Choice8}
                />
              </td>
            </tr>
          </tbody>
          <tbody>
            <th className="center">การทำกิจวัตรประจำวัน</th>
            <td>
              <FormElementRadio
                onChange={handleChange}
                fieldId={"4n"}
                value={dataForm.data_4n}
                fieldValue={"10"}
                label="ทำได้เอง"
                disabled={isDisabledData4Choice1}
              />
            </td>
            <td>
              <FormElementRadio
                onChange={handleChange}
                fieldId={"4n"}
                value={dataForm.data_4n}
                fieldValue={"6"}
                label="ต้องการความช่วยเหลือเป็นบางครั้ง/บางเรื่อง"
                disabled={isDisabledData4Choice2}
              />
            </td>
            <td>
              <FormElementRadio
                onChange={handleChange}
                fieldId={"4n"}
                value={dataForm.data_4n}
                fieldValue={"5"}
                label="ต้องการความช่วยเหลือมากขึ้น"
                disabled={isDisabledData4Choice3}
              />
            </td>
            <td>
              <FormElementRadio
                onChange={handleChange}
                fieldId={"4n"}
                value={dataForm.data_4n}
                fieldValue={"4"}
                label="ต้องการความช่วยเหลือเป็นส่วนใหญ่"
                disabled={isDisabledData4Choice4}
              />
            </td>
            <td>
              <FormElementRadio
                onChange={handleChange}
                fieldId={"4n"}
                value={dataForm.data_4n}
                fieldValue={"3"}
                label="ต้องการความช่วยเหลือทั้งหมด"
                disabled={isDisabledData4Choice5}
              />
            </td>
          </tbody>
          <tbody>
            <tr>
              <th className="center">การรับประทานอาหาร</th>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"10"}
                  label="ปกติ"
                  disabled={isDisabledData5Choice1}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"8"}
                  label="ปกติ หรือ ลดลง"
                  disabled={isDisabledData5Choice2}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"2"}
                  label="จิบน้ำได้เล็กน้อย"
                  disabled={isDisabledData5Choice3}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"1"}
                  label="รับประทานทางปากไม่ได้"
                  disabled={isDisabledData5Choice4}
                />
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th className="center">ระดับความรู้สึกตัว</th>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"10"}
                  label="รู้สึกตัวดี"
                  disabled={isDisabledData6Choice1}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"6"}
                  label="รู้สึกตัวดี หรือ สับสน"
                  disabled={isDisabledData6Choice2}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"4"}
                  label="รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                  disabled={isDisabledData6Choice3}
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"1"}
                  label="ง่วงซึมหรือไม่รู้ตัว +/- สับสน"
                  disabled={isDisabledData6Choice4}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div className="uk-width-1-1@m uk-width-1-1">
        <table
          className="uk-table uk-table-small uk-table-divider form-table table-border"
          style={{
            borderCollapse: "collapse",
            border: "1px solid black !important",
          }}
        >
          <thead>
            <tr>
              <th width="100">ระดับ PPS ร้อยละ </th>
              <th>การเคลื่อนไหว</th>
              <th>การปฏิบัติกิจกรรมและการดำเนินโรค</th>
              <th>การทำกิจวัตรประจำวัน</th>
              <th>การรับประทานอาหาร</th>
              <th>ระดับความรู้สึกตัว</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"100"}
                  label="100"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"100"}
                  label="เคลื่อนไหวปกติ"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"100"}
                  label="ทำกิจกรรมและงานได้ตามปกติและไม่มีอาการของโรค"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"100"}
                  label="ทำได้เอง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"100"}
                  label="ปกติ"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"100"}
                  label="รู้สึกตัวดี"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"90"}
                  label="90"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"90"}
                  label="เคลื่อนไหวปกติ"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"90"}
                  label="ทำกิจกรรมและทำงานได้ตามปกติและมีอาการของโรคบางอาการ"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"90"}
                  label="ทำได้เอง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"90"}
                  label="ปกติ"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"90"}
                  label="รู้สึกตัวดี"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"80"}
                  label="80"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"80"}
                  label="เคลื่อนไหวปกติ"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"80"}
                  label="ต้องออกแรงอย่างมากในการทำกิจกรรมตามปกติและมีอาการของโรคบางอาการ"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"80"}
                  label="ทำได้เอง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"80"}
                  label="ปกติ หรือ ลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"80"}
                  label="รู้สึกตัวดี"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"70"}
                  label="70"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"70"}
                  label="ความสามารถในการเคลื่อนไหวลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"70"}
                  label="ไม่สามารถทำงานได้ตามปกติและมีอาการของโรคอย่างมาก"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"70"}
                  label="ทำได้เอง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"70"}
                  label="ปกติ หรือ ลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"70"}
                  label="รู้สึกตัวดี"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"60"}
                  label="60"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"60"}
                  label="ความสามารถในการเคลื่อนไหวลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"60"}
                  label="ไม่สามารถทำงานอดิเรกหรืองานบ้านได้และมีอาการของโรคอย่างมาก"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"60"}
                  label="ต้องการความช่วยเหลือเป็นบางครั้ง/บางเรื่อง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"60"}
                  label="ปกติ หรือ ลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"60"}
                  label="รู้สึกตัวดี หรือ สับสน"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"50"}
                  label="50"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"50"}
                  label="นั่ง หรือ นอนเป็นส่วนใหญ่"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"50"}
                  label="ไม่สามารถทำงานได้เลยและมีการลุกลามของโรค"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"50"}
                  label="ต้องการความช่วยเหลือมากขึ้น"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"50"}
                  label="ปกติ หรือ ลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"50"}
                  label="รู้สึกตัวดี หรือ สับสน"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"40"}
                  label="40"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"40"}
                  label="นอนอยู่บนเตียงเป็นส่วนใหญ่"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"40"}
                  label="ทำกิจกรรมได้น้อยมากและมีการลุกลามของโรค"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"40"}
                  label="ต้องการความช่วยเหลือเป็นส่วนใหญ่"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"40"}
                  label="ปกติ หรือ ลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"40"}
                  label="รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"30"}
                  label="30"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"30"}
                  label="นอนอยู่บนเตียงตลอดเวลา"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"30"}
                  label="ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"30"}
                  label="ต้องการความช่วยเหลือทั้งหมด"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"30"}
                  label="ปกติ หรือ ลดลง"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"30"}
                  label="รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"20"}
                  label="20"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"20"}
                  label="นอนอยู่บนเตียงตลอดเวลา"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"20"}
                  label="ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"20"}
                  label="ต้องการความช่วยเหลือทั้งหมด"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"20"}
                  label="จิบน้ำได้เล็กน้อย"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"20"}
                  label="รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={getTotalScore.toString()}
                  fieldValue={"10"}
                  label="10"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"10"}
                  label="นอนอยู่บนเตียงตลอดเวลา"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"10"}
                  label="ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"10"}
                  label="ต้องการความช่วยเหลือทั้งหมด"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"10"}
                  label="รับประทานทางปากไม่ได้"
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"10"}
                  label="ง่วงซึม หรือ ไม่รู้สึกตัว +/- สับสน"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"6n"}
                  value={dataForm?.data_1n ? getTotalScore.toString() : ""}
                  fieldValue={"0"}
                  label="0"
                  disabled
                />
              </td>
              <td>
                <FormElementRadio
                  onChange={handleChange}
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"0"}
                  label="เสียชีวิต"
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormPPS;
