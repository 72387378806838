import React from 'react';

const FormElementTitle = (props) => {

  var {mode} = props;

  return (
    <>
    {
    (mode != "view") ?
    <div className="uk-inline formTitle" style={{ fontSize:30 }}>
      {props.label}
    </div>
    : 
    <div style={{fontWeight: 'bold'}} >
      {props.label}
    </div>
    }
    </>
  )
}

export default FormElementTitle;
