import React, { useEffect, useSearchParams } from 'react';
import Api from '../../Components/Api';
import LoginForm from '../../Components/LoginForm';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

UIkit.use(Icons);

const ConsultLogin = (props) => {
  const [cookies, setCookie] = useCookies(['currentUrlBeforeLogin']);

  async function login(data) {
    var { result, responseText, session } = await Api.consult('ConsultLoginByForm', data);

    UIkit.notification(`<span uk-icon='icon: ${result ? 'check' : 'close'}'></span> ${responseText}`);

    if (result && session) {
      localStorage.setItem('consultLogin', session);
      
      window.location.href=window.location.href;

    } else {
      
    }
  }

  useEffect(() => {
    let params = queryString.parse(props.location.search)

    //Cookie
    setCookie('currentUrlBeforeLogin', window.location.href, { path: '/' });

    if(typeof(params["token"]) != "undefined") {
      login({token_login : params["token"]});
    }

    
  }, []);

  return (
    <div id="d-consult-background-login" className="uk-background-cover">
      <div className="uk-position-center">
        <div className=" uk-card uk-card-default uk-padding-small uk-width-medium">
          <h4 className="uk-text-center">เข้าสู่ระบบ</h4>
          <a className="uk-button uk-button-primary uk-width-1-1 uk-margin-top" type="button" href="https://isuandok.med.cmu.ac.th/apps/cmuoauth/telemed.php">เข้าสู่ระบบ</a>
        </div>
      </div>
    </div>
  )
}

export default ConsultLogin;