import React from "react";
var ReactDOM = require("react-dom");

const FormElementLabel = (props) => {
  var mode = "edit";

  if (typeof props.mode != "undefined") {
    mode = props.mode;
  }

  var paddingStyle = "0px 0px 0px 0px";

  if (typeof props.padding != "undefined") {
    paddingStyle = props.padding;
  }

  const styleObj = {
    color: props.color,
    textAlign: "center",
    fontWeight: "bold",
    padding: paddingStyle,
  };

  const styleObjView = {
    color: props.color,
    textAlign: "center",

    padding: paddingStyle,
  };

  var labelView = "";

  if (mode == "view") {
    if (typeof props.label != "undefined") {
      labelView = props.label;
    } else {
      if (
        typeof props.value != "undefined" &&
        typeof props.translate != "undefined"
      ) {
        labelView = props.translate[props.value];
      }
    }
  }

  return (
    <>
      {mode != "view" ? (
        <div className="uk-inline">
          <span
            style={styleObj}
            dangerouslySetInnerHTML={{ __html: props.label }}
          ></span>
        </div>
      ) : (
        <div>
          <span
            style={styleObjView}
            dangerouslySetInnerHTML={{ __html: labelView }}
          ></span>
        </div>
      )}
    </>
  );
};

export default FormElementLabel;
