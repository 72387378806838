
export default (word) => {
  var _w = {
    'โปรดใส่ชื่อผู้ใช้และรหัสผ่าน' : 'Please enter a username and password.',
    'โปรดยอมรับเงื่อนไขและนโยบาย' : 'Please accept the terms and policies.',
    'ชื่อผู้ใช้' : 'Username',
    'ชื่อผู้ใช้ (ภาษาอังกฤษหรือตัวเลข สามารถใช้เลขบัตรประชาชนได้)' : 'Username (English letters or numbers, National ID available)',
    'สถานพยาบาล' : 'Hospital',
    'ทั่วไป' : 'general',
    'แคมเปญ' : 'Campaign',
    'รหัสผ่าน' : 'Password',
    'รหัสผ่าน (สามารถใช้วันเดือนปีเกิดเป็นรหัสผ่านได้ เช่น 10082524)' : 'Password (Birthdate format available ex. 10081954)',
    'คุณได้อ่านและยอมรับ' : 'You have read and agree',
    'เงื่อนไขการใช้บริการ' : 'terms of service',
    'และ' : 'and',
    'นโยบายความเป็นส่วนตัว' : 'privacy policy',
    'เข้าสู่ระบบ' : 'Login',
    'ถ้ายังไม่ได้ลงทะเบียน': 'If not registered',
    'ลงทะเบียนที่นี่' : 'register here',
    'รูปแบบของอีเมลไม่ถูกต้อง' : 'The email format is incorrect.',
    'โปรดใส่ข้อมูลให้ครบ' : 'Please complete the information.',
    'โปรดใส่รหัสผ่าน 8 ตัวอักษรหรือมากกว่า' : 'Please enter a password of 8 characters or more.',
    'ส่งรหัส OTP ไปยัง ' : 'Send OTP to',
    'มีจำนวน 8 ตัว' : 'Has 8 characters',
    'เป็นตัวเลขหรือตัวอักษร' : 'In numbers or letters',
    'เบอร์โทรศัพท์มือถือ': 'Mobile phone number',
    'สมัครใช้บริการสำหรับคนไข้' : 'Register for patient',
    'สมัครสมาชิก' : 'Register',
    'หากคุณมีบัญชีกับเราแล้ว' : 'If you already have an account with us',
    'ล็อคอินเข้าสู่ระบบ' : 'Login to the system',
    'กรุณากรอกรหัส 4 หลัก' : 'Enter the 4 digit code.',
    'ที่ท่านได้รับจาก SMS': 'from SMS',
    'ยืนยันสมัครสมาชิก' : 'Confirm Register',
    'ยังไม่ได้รับ SMS' : "If haven't received the SMS",
    'กดส่งใหม่' : 'press here to resend',
    'คำนำหน้าชื่อ' : 'Title',
    'ชื่อ' : 'Name',
    'นามสกุล' : 'Surname',
    'อีเมล' : 'Email',
    'เพศ' : 'Gender',
    'หญิง' : 'Woman',
    'ชาย' : 'Man',
    'เลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือหมายเลขพาสปอร์ต' : 'ID card number or social security card number or passport number',
    'วันเดือนปีเกิด' : 'Date of birth',
    'วันที่ เวลา' : 'Date time',
    'รูปภาพ' : 'Image',
    'เรียกดู' : 'Browse',
    'lineID (ุถ้ามี)' : 'lineID (If any)',
    'เราขอข้อมูลวันเดือนปีเกิดและเพศ เพื่อที่จะวิเคราะห์ความเสี่ยงสุขภาพจากอายุและเพศของคุณได้ เช่น ความเสี่ยงโรคไต หรือ โรคเบาหวาน เราขอข้อมูลเลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือ หมายเลขพาสปอร์ต เพื่อกรณีเชื่อมโยงผลการตรวจสุขภาพในรูปแบบองค์กรโดยจะเก็บข้อมูลเป็นความลับ ไม่เปิดเผยยังหน่วยงานอื่นใด ที่ไม่ได้เกี่ยวข้องกับการรักษาพยาบาลของท่าน' : 'We ask for date of birth and gender. In order to analyze your health risks based on your age and gender, such as your risk of kidney disease or diabetes, we ask for your ID number or Social Security number or passport number. In the case of linking the results of the health examination in an organizational form, the information will be kept confidential. not disclosed to any other agencies that are not related to your medical treatment',
    'เสร็จสิ้น' : 'Completed',
    'กรณีที่ท่านลงทะเบียนเพื่อใช้งานในโรงพยาบาล หรือ องค์กร กรุณาใส่รหัสเพื่อเข้าใช้งาน(สามารถสอบถามได้ที่พยาบาลหรือฝ่ายทรัพยากรบุคคลของท่าน)': 'If you are registered for use in a hospital or organization, please enter the access code (you can ask your nurse or human resources department).',
    'คุณต้องยอมรับการใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ': 'You must agree to the use of particularly sensitive personal information.',
    'ท่านรับทราบและยอมรับ' : 'You acknowledge and accept',
    'การใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ' : 'Use of personal information that is particularly sensitive',
    'รหัสผ่านควรตั้งให้มี 8 ตัวขึ้นไป ประกอบด้วยตัวเลขและตัวอักษรเพื่อความปลอดภัย' : 'Password should be set to 8 or more characters and contain numbers and letters for security purposes.',
    'โปรดติดต่อรพ.ของท่านเพื่อสอบถามรหัส' : 'Please contact your hospital for the code.',
    'ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล' : 'Campaign period In the case of individual patients',
    ' วัน' : ' days',
    "วันที่สมัคร" : "Register date",
    "วันที่หมดอายุ" : "Expiration date",
    "ค่าใช้จ่าย" : "Price",
    "กรุณาชำระค่าบริการก่อนกดสมัครสมาชิก วิธีใดวิธีหนึ่ง" : "Please pay the service fee before clicking apply for membership. either way",
    "กรอกรหัสโปรโมชัน" : "Enter the promo code",
    "ชำระเงิน" : "Pay",
    "ไม่มีค่าใช้จ่าย" : "Free",
    "ชำระเงินแล้ว" : "Paid",
    "โปรดชำระเงินเพื่อดำเนินการต่อ" : "Please pay to continue.",
    "ค.ศ." : "A.D.",
    "สมัครแคมเปญแล้ว" : "Campaign registered",
    "กลับหน้าหลัก" : "Back to homepage",
    "เลือกแคมเปญ" : "Campaign select"
  }; 

  if(_w[word] === undefined) {
    return word;
  } else {
    
    if(localStorage.getItem('language') === 'th') {
      return word
    } else {
      return _w[word];
    }
    
  }
}
