import React, { useState, useEffect } from 'react';

import Api from "../../Api";
import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import no_img from '../../../images/no_img.jpg'

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';
import MemberProfileFormHeader from '../MemberProfileFormHeader';

const FormPhamacyImage = (props) => {
    var [drugList, setDrugList] = useState([]);

    async function onMemberDataLoaded(data) {
        var result = await Api.consult("GetDrugByTxn", {
            hn : props.hn,
            //txn : "919705964",
            txn : props.txn,
            patientType : 'o',
        });

        var { list } = result;

        if(list.data.length > 0) {
            var drugDatas = list.data[0];

            for await (const drug of drugDatas) {
                var pic = await getDrugPicture(drug.code);

                if(pic != "") {
                    drug.pic = "data:image/png;base64," + pic;
                    
                } else {
                    drug.pic = no_img;
                }
                
            }

            setDrugList(drugDatas);
    
        } else {
            setDrugList([]);
        }
    }

    useEffect(() => {
		async function getData() {
            var result = await Api.consult("GetDrugByTxn", {
                hn : props.hn,
                //txn : "919705964",
                txn : props.txn,
                patientType : 'o',
            });

            console.log(result);
    
            var { list } = result;
    
            if(list.data.length > 0) {
                var drugDatas = list.data[0];
    
                for await (const drug of drugDatas) {
                    var pic = await getDrugPicture(drug.code);
    
                    if(pic != "") {
                        drug.pic = "data:image/png;base64," + pic;
                        
                    } else {
                        drug.pic = no_img;
                    }
                    
                }
    
                setDrugList(drugDatas);
        
            } else {
                setDrugList([]);
            }
        }

        getData();

    }, [props.hn,props.txn]);

    async function getDrugPicture(code) {
        var result = await Api.consult("GetDrugDescription", {
            drugCode : code,
        });

        return result.data.pic;
    }

    return (
        <div uk-grid="" className="uk-grid uk-padding-small">
            <div className="uk-width-1-1@m uk-width-1-1">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped"  >
                <thead>
                    <tr>
                        <td >รูปยา </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        drugList.length === 0 ? 
                        <tr>
                            <td colSpan="100%">
                                <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                            </td>
                        </tr>
                        :
                        drugList.map((data, key)=>{
                        return <tr key={`row${data.code}`}>
                            <td style={{ textAlign: "center" }} >
                            <img src={data.pic} width={500} /><br/>
                                {data.name}
                            </td>
                        </tr>
                        })
                    }
            </tbody>
            </table>
            </div>
            
        </div>
        
    )
}
  
  export default FormPhamacyImage;
  

