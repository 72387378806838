import React, {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useCallback,
  useRef,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";
import moment from "moment";
import th from "date-fns/locale/th";
import { FiCalendar } from "react-icons/fi";
import { getDate, getMonth, getYear } from "date-fns";
import { valHooks } from "jquery";

const years = Array.from(
  { length: 50 },
  (_, i) => new Date().getFullYear() - i + 543
)
  .reverse()
  .concat(
    Array.from(
      { length: 49 },
      (_, i) => new Date().getFullYear() + (i + 1) + 543
    )
  );
const LIST_OF_MONTH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const FormElementTextBox = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const drowdownRef = useRef();

  var maxLength = 50;

  if (typeof props.maxLength != "undefined") {
    maxLength = props.maxLength;
  }

  var mode = "edit";

  if (typeof props.mode != "undefined") {
    mode = props.mode;
  }

  var disableKey = false;

  if (typeof props.disableKey != "undefined") {
    disableKey = props.disableKey;
  }

  registerLocale("th", th);

  function onDatePickerChange(date) {
    setStartDate(date);

    if (props.withTime) {
      props.onChange(props.fieldId, dateFormat(date, "yyyy-mm-dd HH:MM:ss"));
    } else {
      props.onChange(props.fieldId, dateFormat(date, "yyyy-mm-dd"));
    }
  }

  function onChange(e) {
    if (props.regEx) {
      if (e.target.value.match(props.regEx)) {
        props.onChange(props.fieldId, e);
      }
    } else {
      if (props.type === "decimal") {
        if (e.target.value.match(/^-?\d*\.?\d{0,2}$/g) || !e.target.value) {
          props.onChange(props.fieldId, e);
        }
      } else if (props.type === "numeric") {
        if (e.target.value.match(/^[0-9]*$/) || !e.target.value) {
          props.onChange(props.fieldId, e);
        }
      } else {
        props.onChange(props.fieldId, e);
      }
    }
  }

  const onFocus = useCallback((e) => {
    if (props.withDropdown) {
      setIsDropdownOpen(true);
    }
  });

  const onBlur = useCallback((e) => {
    if (props.withDropdown) {
      setIsDropdownOpen(false);
    }
    if (typeof props.validate == "function") {
      if (!props.validate(e.target.value)) {
        props.onChange(props.fieldId, "");
      }
    }
  });

  useEffect(() => {
    if (typeof props.value != "undefined") {
      if (props.type == "date") {
        if (props.value) {
          setStartDate(new Date(props.value));
        } else {
          setStartDate(null);
          if (typeof props.onChange != "undefined") {
            props.onChange(props.fieldId, null);
          }
        }
      }
    } else {
      if (props.type == "date") {
        setStartDate(null);
      }
    }
  }, [props.value, props.type]);

  useEffect(() => {
    if (props.type == "numeric" || props.type == "decimal") {
      if (props.value === "" || typeof props.value == "undefined") {
        if (typeof props.onChange != "undefined") {
          props.onChange(props.fieldId, null);
        }
      }
    } else if (props.type == "date") {
      if (typeof props.minDate != "undefined") {
        setMinDate(new Date(props.minDate));
      }
    }
  }, [props.value, props.type]);

  useEffect(() => {
    if (typeof props.maxDate != "undefined") {
      setMaxDate(new Date(props.maxDate));
    }
  }, [props.maxDate]);

  useEffect(() => {
    if (typeof props.minDate != "undefined") {
      setMinDate(new Date(props.minDate));
    }
  }, [props.minDate]);

  var type = "textbox";
  var className = "formTextBox uk-input";

  if (typeof props.type != "undefined") {
    type = props.type;

    if (type == "numeric") {
      className += " numeric uk-input";
    } else if (type == "decimal") {
      className += " decimal uk-input";
    }
  }

  if (disableKey) {
    className += " disableKey";
  }

  const renderDate = useCallback((value) => {
    let thYear = value.substring(6, 10);
    let enYear = parseInt(thYear) + 543;
    return value.replace(thYear, enYear);
  });

  const renderDateViewMode = useCallback((value) => {
    // value = value.substring(0, 10);
    const thYear = value.substring(0, 4);
    const enYear = parseInt(thYear) + 543;

    const parts = value.replace(thYear, enYear).split("-");

    if (value.length > 10) {
      const year = parts[0];
      const time = parts[2].split(" ")[1];
      const month = parts[1];
      const day = parts[2].substring(0, 2);

      return day + "/" + month + "/" + year + " " + time;
    } else {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return day + "/" + month + "/" + year;
    }
  });

  if (mode != "view") {
    if (props.type != "date") {
      return (
        <div className="uk-display-inline" style={{ whiteSpace: "nowrap" }}>
          {props.label ? (
            <span className="formTextBoxLabel">
              {props.label}{" "}
              {props.isRequired ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          ) : null}
          <span
            style={{
              position: "relative",
              height: "auto",
              width: "100%",
            }}
          >
            <input
              type={type}
              className={className}
              placeholder={props.placeholder}
              onChange={(e) => onChange(e)}
              value={props.value === 0 || props.value ? props.value.toString() : ""}
              style={{ width: props.width }}
              disabled={props.disabled}
              maxLength={maxLength}
              onFocus={(e) => onFocus(e)}
              onBlur={(e) => onBlur(e)}
              size={props.size ? props.size : undefined}
            />
            {props.withDropdown && props.dataList && isDropdownOpen && (
              <div className="custom-select-container" ref={drowdownRef}>
                {props.dataList.map((item) => (
                  <div
                    className="custom-option-container"
                    onMouseDown={(e) => props.onSelect(item.value)}
                  >
                    <span style={{ padding: "4px" }}>{item.label}</span>
                  </div>
                ))}
              </div>
            )}
          </span>
        </div>
      );
    } else {
      const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="uk-display-inline" style={{ padding: "0px" }}>
          <button
            style={{ minWidth: "60px" }}
            className="uk-button uk-button-primary uk-width-1-1"
            onClick={onClick}
            ref={ref}
          >
            <span style={{ whiteSpace: "nowrap" }}>
              {value != null && value != "" ? (
                renderDate(value)
              ) : (
                <FiCalendar />
              )}
            </span>
          </button>
        </div>
      ));

      const renderCustomHeader = useCallback(
        ({ date, decreaseMonth, increaseMonth, changeYear }) => (
          <div style={{ width: "100%" }}>
            <button
              className="nav-month-button previous"
              style={{ position: "absolute", left: "0px" }}
              onClick={decreaseMonth}
            >
              <span uk-icon="icon: triangle-left; ratio: 1.0" />
            </button>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <strong className="header-label">
                {LIST_OF_MONTH[getMonth(date)]}
              </strong>
              <select
                value={getYear(date) + 543}
                onChange={({ target: { value } }) => changeYear(value - 543)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="nav-month-button next"
              onClick={increaseMonth}
              style={{ position: "absolute", right: "0px" }}
            >
              <span uk-icon="icon: triangle-right; ratio: 1.0" />
            </button>
          </div>
        )
      );

      return (
        <div
          className="uk-display-inline date-picker"
          style={{ whiteSpace: "nowrap" }}
        >
          {props.label ? (
            <span className="formTextBoxLabel">
              {props.label}{" "}
              {props.isRequired ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          ) : null}
          <DatePicker
            wrapperClassName="date-picker-custom"
            // showTimeSelect={props.withTime}
            dateFormat={props.dateFormat ? props.dateFormat : "dd/MM/yyyy"}
            placeholderText={props.placeholder}
            timeFormat="HH:mm"
            selected={startDate}
            locale="th"
            maxDate={maxDate}
            minDate={minDate}
            onChange={(date) => onDatePickerChange(date)}
            disabled={props.disabled}
            className="date-picker"
            customInput={<DateCustomInput />}
            timeInputLabel="เวลา:"
            renderCustomHeader={renderCustomHeader}
            showTimeInput={props.withTime}
          />
        </div>
      );
    }
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {props.label ? <span>{props.label}</span> : null}{" "}
        {props.value != null && props.value != "" && props.type == "date" ? (
          <pre
            style={{
              padding: "0px 10px 0px 10px",
              margin: "0px",
              border: "none",
              overflow: "hidden",
              whiteSpace: "pre-wrap",
            }}
            dangerouslySetInnerHTML={{
              __html:
                props.type == "date" && props.value && props.mode == "view"
                  ? renderDateViewMode(props.value)
                  : null,
            }}
          ></pre>
        ) : (
          <pre
            style={{
              padding: "0px 10px 0px 10px",
              margin: "0px",
              border: "none",
              overflow: "hidden",
              whiteSpace: "pre-wrap",
            }}
            dangerouslySetInnerHTML={{
              __html:
                props.value != null && typeof props.value != "undefined"
                  ? props.value
                  : "",
            }}
          ></pre>
        )}
      </div>
    );
  }
};

export default FormElementTextBox;
