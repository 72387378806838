import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import HeaderSub from '../../Components/Member/HeaderSub';

UIkit.use(Icons);

export default class WorkHealthRecordList extends Component {
  state = {
    list: []
  }

  listGet = async () => {
    var _result = await Api.member("WorkHealthRecordList");
    
    this.setState({list: _result.data || []})
  }

  componentDidMount() {
    this.listGet()
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="บันทึกสุขภาพ" />
      <div className="uk-margin-small-top">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">รายการบันทึกสุขภาพ</div>
            <div className="d-card-header-right">
              <a className="uk-button uk-button-primary uk-button-small" href="/work-health-record">เพิ่มสมุดสุขภาพ</a>
            </div>
            </div>
        <table className="uk-table uk-table-small uk-table-striped">
          <thead>
            <tr>
              <th>วันที่</th>
              <th>ประเภท</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.length === 0 ? 
            <tr><td colSpan="100%"><div className="uk-text-center uk-padding-small">ไม่พบรายการ</div></td></tr>
            : this.state.list.map((data, index) => {
              return <tr>
                <td>{data.dateadd}</td>
                <td>{data.datatype}</td>
                <td>
                  <a href={'/work-health-record?dateadd=' + encodeURI(data.dateadd)}><span uk-icon="pencil"></span></a>
                </td>
              </tr>
            })}
          </tbody>
        </table>
        </div>
      </div>
    </div>
  }
};
