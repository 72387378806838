import React, { useState, useEffect }  from "react";
import Api from "../../Api";
import UIkit from "uikit";

const FormElementImageView = (props) => {
  const [attachImg, setAttachImg] = useState(null);

  useEffect(() => {
    if(props.value != null) {
      setAttachImg(props.value);
    }
  }, [props.value]);

  return (
    <div className="uk-inline">
      {attachImg ? (
        <img src={attachImg} width={props.width} height={props.height} />
      ) : null}
    </div>
  );
};

export default FormElementImageView;
