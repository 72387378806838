import "../../../css/dietz.css";

const FormElementIconButton = (props) => {
  const { icon, disabled, sizeRatio, onClick } = props;
  return (
    <>
      <button style={{ minHeight: '32px', minWidth: '32px' }}
        className="icon-button"
        uk-icon={`icon: ${icon};ratio: ${sizeRatio ? sizeRatio : "1"}`}
        onClick={onClick}
        disabled={disabled}
      />
    </>
  );
};

export default FormElementIconButton;
