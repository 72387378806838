import NovalueCheck from './NoValueCheck';

export default (value) => {
  if(NovalueCheck(value)) return NovalueCheck(value);

  value = +value;

  if(value >= 250) {
    return { 
      title: 'น้ำตาลสูงวิกฤติ',
      color: 'red',
      caption: 'น้ำตาลสูงวิกฤติ',
      suggest: 'รับประทานยาอย่างสม่ำเสมอตามที่แพทย์สั่ง พบนักกำหนดอาหารและควบคุมอาหารตามคำแนะนำ หากมีอาการผิดปกติควรกลับมาพบแพทย์อีกครั้ง'
    };
  } else if(value >= 180) {
    return { 
      title: 'น้ำตาลสูงมาก',
      color: 'orange',
      caption: 'น้ำตาลสูงมาก',
      suggest: 'รับประทานยาอย่างสม่ำเสมอตามที่แพทย์สั่ง พบนักกำหนดอาหารและควบคุมอาหารตามคำแนะนำ'
    };
  } else if(value >= 130) {
    return { 
      title: 'น้ำตาลสูง',
      color: 'yellow',
      caption: 'น้ำตาลสูง',
      suggest: 'รับประทานยาอย่างสม่ำเสมอตามที่แพทย์สั่ง และควบคุมอาหารตามคำแนะนำ'
    };
  } else if(value >= 70) {
    return { 
      title: 'น้ำตาลควบคุมได้',
      color: 'green',
      caption: 'น้ำตาลควบคุมได้',
      suggest: 'รับประทานอาหารและออกกำลังกายตามปกติ'
    };
  } else {
    return { 
      title: 'น้ำตาลในเลือดต่ำ',
      color: 'red',
      caption: 'น้ำตาลในเลือดต่ำ',
      suggest: 'ปรึกษาแพทย์เพื่อปรับยา รับประทานอาหารให้ตรงเวลาสัมพันธ์กับการใช้ยาเบาหวาน'
    }
  }
}