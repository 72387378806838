import React, { useState, useEffect, useCallback } from "react";
import Api from "./Api";
import DrawingOnImage from "./DrawingOnImage";
import fetchFormFileListByTxn from "./API/fetchFormFileListByTxn";

export default function ShowFilesList(props) {
  const [showFiles, setShowFiles] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [detailImage, setDetailImage] = useState({});
  const [dataFilesList, setDataFilesList] = useState([]);

  const onClose = () => {
    setShowFiles(false);
  };
  const editImage = async (data) => {
    await getImage(data.id);
  };

  const onCloseShowImage = () => {
    setShowImage(false);
  };

  const getImage = useCallback(async (id) => {
    var result = await Api.consult("FormTxnFilesList", {
      id: id,
    });

    if (result.list && result.list.length > 0) {
      setDetailImage(result.list[0]);
      setShowImage(true);
    }
  }, []);

  const getDataFileList = useCallback((txn) => {
    async function fetchDataFileList(txn) {
      if (txn) {
        var result = await fetchFormFileListByTxn(txn);
        setDataFilesList(result);
      }
    }
    fetchDataFileList(txn);
  }, []);

  useEffect(() => {
    if (!showFiles) return;
    getDataFileList(props.txn);
  }, [showFiles, props.txn]);

  return (
    <>
      <button
        className="uk-button uk-button-primary "
        style={{ height: "40px" }}
        onClick={() => setShowFiles(true)}
      >
        แสดงไฟล์
      </button>
      &nbsp;&nbsp;
      {showFiles ? (
        <div className="form-modal-container">
          <div
            className="form-modal-dialog-container"
            style={{
              padding: 0,
              width: "100vh",
            }}
          >
            <div className="form-modal-dialog-header">
              <h1 className="uk-modal-title">ไฟล์รูปทั้งหมด</h1>
              <div className="form-modal-right-header">
                <button
                  className="uk-button-default form-modal-close-button"
                  type="button"
                  onClick={onClose}
                >
                  <span uk-icon="icon: close; ratio: 1"></span>
                </button>
              </div>
            </div>
            <div
              className="form-modal-dialog-body"
              style={{ padding: "30px 40px" }}
            >
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Incidunt fugit quia sint minima non facere laborum distinctio
                unde explicabo odio? Eos facere consequatur tempora quasi minima
                nihil ea enim voluptate!
              </p> */}
              <table className="uk-table ">
                <caption>กรุณาเลือกไฟล์รูปที่ต้องการ</caption>
                <thead>
                  <tr>
                    <th>ที่</th>
                    <th>ชื่อไฟล์</th>
                    <th className="uk-width-small">Image</th>
                  </tr>
                </thead>
                <tbody>
                  {dataFilesList ? (
                    dataFilesList.map((value, index) => {
                      return (
                        <tr key={value.id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="uk-flex uk-flex-column">
                              <p>{value.file_name}</p>
                              <img
                                src={value.file_data}
                                alt={value.file_name}
                                style={{ width: 100, height: 100 }}
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              className="uk-button uk-button-primary"
                              type="button"
                              onClick={() => editImage(value)}
                            >
                              Show
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
            <div className="form-modal-dialog-footer uk-text-right">
              <button
                className="uk-button uk-button-default uk-modal-close"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showImage ? (
        <DrawingOnImage
          detailImage={detailImage}
          isShowImage={setShowImage}
          getData={props.getData}
          onClose={onCloseShowImage}
          isDisplayOnly
        />
      ) : null}
    </>
  );
}
