import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import form31_1_image from "../../../images/form_31_1.jpg";
import form31_2_image from "../../../images/form_31_2.jpg";
import FontElementSendDrawingPaper from "../Form/FormElementSendDrawingPaper";

import camelImage from "../../../images/form_31_2_1.png";
import rhinoImage from "../../../images/form_31_2_2.png";
import lionImage from "../../../images/form_31_2_3.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import dateFormat from "dateformat";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import FormElementUploadImage from "../Form/FormElementUploadImage";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormMocaPaper from "../FormPaper/FormMocaPaper.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormMoca = (props) => {
  const formConstants = {
    formName: "form_moca",
    formType: "31",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const prepareData = useCallback((form) => {
    if (!form?.data_44) {
      form.data_44 = dateFormat(new Date(), "yyyy-mm-dd");
    }

    return form;
  }, []);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      data = prepareData({ ...data });
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      hideLoading();
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([patientData, patientRoomRequest]).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];
      data = prepareData({ ...data });
      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);

  useEffect(() => {
    if (!isSubForm) {
      if (!formId && hn && txn) fetchData(null, hn, txn)
    }
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getScore1 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1,
      dataForm.data_2,
      dataForm.data_3,
      dataForm.data_4,
      dataForm.data_5,
    ]);
  }, [dataForm]);

  const getScore2 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_6,
      dataForm.data_7,
      dataForm.data_8,
    ]);
  }, [dataForm]);

  const getScore4_1 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_19, dataForm.data_20]);
  }, [dataForm]);

  const getScore4_2 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_21]);
  }, [dataForm]);

  const getScore4_3 = useMemo(() => {
    let rawScore = utilSumTotalScore([
      dataForm.data_22,
      dataForm.data_23,
      dataForm.data_24,
      dataForm.data_25,
      dataForm.data_26,
    ]);
    if (rawScore >= 4) {
      return 3;
    } else if (rawScore >= 2) {
      return 2;
    } else if (rawScore == 1) {
      return 1;
    } else {
      return 0;
    }
  }, [dataForm]);

  const getScore5_1 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_27, dataForm.data_28]);
  }, [dataForm]);

  const getScore5_2 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_29]);
  }, [dataForm]);

  const getScore6 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_31, dataForm.data_32]);
  }, [dataForm]);

  const getScore7 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_33,
      dataForm.data_34,
      dataForm.data_35,
      dataForm.data_36,
      dataForm.data_37,
    ]);
  }, [dataForm]);

  const getScore8 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_38,
      dataForm.data_39,
      dataForm.data_40,
      dataForm.data_41,
      dataForm.data_42,
      dataForm.data_43,
    ]);
  }, [dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      getScore1,
      getScore2,
      getScore4_1,
      getScore4_2,
      getScore4_3,
      getScore5_1,
      getScore5_2,
      getScore6,
      getScore7,
      getScore8,
    ]);
  }, [dataForm]);

  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html, getTotalScore);
  }, [dataForm, onAdd, getTotalScore]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="MONTREAL COGNITIVE ASSESSMENT (MOCA)" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <span className="formTextBoxLabel">วันที่ทำการทดสอบ</span>
          <FormElementTextBox
            fieldId={"44"}
            value={dataForm.data_44}
            type="date"
            onChange={handleChange}
            width="100"
          />
        </div>

        <div className="uk-width-1-1@m uk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider form-table">
            <thead>
              <tr>
                <th >VISUOSPATIAL / EXECUTIVE</th>
                <th >คะแนน</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    ลากเส้น <br />
                    <FontElementSendDrawingPaper
                      fieldId={"45"}
                      value={dataForm.data_45}
                      formId="31"
                      txn={dataForm.txn}
                      fileId="10"
                      width="333"
                      height="333"
                      onChange={handleChange} />
                  </div>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementCheckBox
                      fieldId={"1"}
                      value={dataForm.data_1}
                      onChange={handleChange}
                      label=""
                    />
                  </div>
                </td>
                <td rowSpan={3}>{getScore1}/5</td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    คัดลอกลูกบาศก์ <br />
                    <FontElementSendDrawingPaper
                      fieldId={"46"}
                      value={dataForm.data_46}
                      formId="31"
                      txn={dataForm.txn}
                      fileId="57"
                      width="333"
                      height="500"
                      onChange={handleChange} />
                  </div>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementCheckBox
                      fieldId={"2"}
                      value={dataForm.data_2}
                      fieldValue={""}
                      onChange={handleChange}
                      label=""
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom  uk-padding-remove-top">
                    วาดหน้าปัดนาฬิกา บอกเวลาที่ 11.10 น. (3 คะแนน) <br />
                    <FontElementSendDrawingPaper
                      fieldId={"47"}
                      value={dataForm.data_47}
                      formId="31"
                      txn={dataForm.txn}
                      fileId="59"
                      width="333"
                      height="500"
                      onChange={handleChange} />

                  </div>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementCheckBox
                      fieldId={"3"}
                      value={dataForm.data_3}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" รูปร่าง"
                    />
                    <FormElementCheckBox
                      fieldId={"4"}
                      value={dataForm.data_4}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" ตัวเลข"
                    />
                    <FormElementCheckBox
                      fieldId={"5"}
                      value={dataForm.data_5}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" เข็ม"
                    />
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
          <table className="uk-table uk-table-small uk-table-divider form-table">
            <thead>
              <th colSpan={3}>NAMING</th>
              <th>คะแนน</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1">
                    <img src={lionImage} alt="lion_image" width={400} />
                  </div>
                </td>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1">
                    <img src={rhinoImage} alt="rhino_image" width={400} />
                  </div>
                </td>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1">
                    <img src={camelImage} alt="camel_image" width={400} />
                  </div>
                </td>
                <td>{getScore2}/3</td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"6"}
                      value={dataForm.data_6}
                      fieldValue={""}
                      onChange={handleChange}
                    />
                  </div>
                </td>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"7"}
                      value={dataForm.data_7}
                      fieldValue={""}
                      onChange={handleChange}
                    />
                  </div>
                </td>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"8"}
                      value={dataForm.data_8}
                      fieldValue={""}
                      onChange={handleChange}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <thead>
              <th colSpan={7}>MEMORY</th>
            </thead>
            <tbody>
              <tr>
                <th rowSpan={3} className="center">
                  อ่านชุดคำเหล่านี้แล้วให้ผู้ทดสอบ <br />
                  ทวนซ้ำ ทดสอบ 2 ครั้ง <br />
                  และถามซ้ำอีกครั้งหลัง 5 นาที
                </th>
                <th></th>
                <th>หน้า</th>
                <th>ผ้าไหม</th>
                <th>วัด</th>
                <th>มะลิ</th>
                <th>สีแดง</th>
              </tr>
              <tr>
                <td>ทวนครั้งที่ 1</td>
                <td>
                  <FormElementCheckBox
                    fieldId={"9"}
                    value={dataForm.data_9}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"10"}
                    value={dataForm.data_10}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"11"}
                    value={dataForm.data_11}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"12"}
                    value={dataForm.data_12}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"13"}
                    value={dataForm.data_13}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>ทวนครั้งที่ 2</td>
                <td>
                  <FormElementCheckBox
                    fieldId={"14"}
                    value={dataForm.data_14}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"15"}
                    value={dataForm.data_15}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"16"}
                    value={dataForm.data_16}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"17"}
                    value={dataForm.data_17}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementCheckBox
                    fieldId={"18"}
                    value={dataForm.data_18}
                    fieldValue={""}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <thead>
              <tr>
                <th colSpan={2}>ATTENTION</th>
                <th>คะแนน</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowSpan={2} className="center">
                  อ่านตัวเลขต่อไปนี้ตามลำดับ (1 ตัว/วินาที)
                </th>
                <td>
                  <span className="formTextBoxLabel">
                    ให้ผู้ทดสอบทบทวนซ้ำตามลำดับ
                  </span>
                  <FormElementCheckBox
                    fieldId={"19"}
                    value={dataForm.data_19}
                    fieldValue={""}
                    onChange={handleChange}
                    label=" 2 1 8 5 4"
                  />
                </td>
                <td rowSpan={2}>{getScore4_1}/2</td>
              </tr>
              <tr>
                <td>
                  <span className="formTextBoxLabel">
                    ผู้ทดสอบทวนซ้ำแบบย้อนลำดับ
                  </span>
                  <FormElementCheckBox
                    fieldId={"20"}
                    value={dataForm.data_20}
                    fieldValue={""}
                    onChange={handleChange}
                    label=" 7 4 2"
                  />
                </td>
              </tr>
              <tr>
                <th className="center">
                  อ่านออกเสียงตัวเลขต่อไปนี้แล้วให้ผู้ทดสอบเคาะโต๊ะ
                  <br />
                  เมื่อได้ยินเสียงอ่านเลข "1" (ไม่มีคะแนนถ้าผิดเกิน 2 ครั้ง)
                </th>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small ">
                    <FormElementCheckBox
                      fieldId={"21"}
                      value={dataForm.data_21}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" 5 2 1 3 9 4 1 1 8 0 6 2 1 5 1 9 4 5 1 1 1 4 1 9 0 5 1 1 2"
                    />
                  </div>
                </td>
                <td>{getScore4_2}/1</td>
              </tr>
              <tr>
                <th className="center">เริ่มจาก 100 ลบไปเรื่อยๆทีละ 7</th>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    <FormElementCheckBox
                      fieldId={"22"}
                      value={dataForm.data_22}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" 93"
                    />
                    <FormElementCheckBox
                      fieldId={"23"}
                      value={dataForm.data_23}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" 86"
                    />
                    <FormElementCheckBox
                      fieldId={"24"}
                      value={dataForm.data_24}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" 79"
                    />
                    <FormElementCheckBox
                      fieldId={"25"}
                      value={dataForm.data_25}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" 72"
                    />
                    <FormElementCheckBox
                      fieldId={"26"}
                      value={dataForm.data_26}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" 65"
                    />
                  </div>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                    ลบถูก 4 ตัวหรือ 5 ตัวได้ 3 คะแนน, 2 หรือ 3 ตัวได้ 2 คะแนน, 1
                    ตัวได้ 1 คะแนน, 0 ตัว ไม่ได้คะแนน
                  </div>
                </td>
                <td>{getScore4_3}/3</td>
              </tr>
            </tbody>
          </table>
          <table className="uk-table uk-table-small uk-table-divider form-table">
            <thead>
              <tr>
                <th>LANGUAGE</th>
                <th>คะแนน</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left">
                    <span className="formTextBoxLabel">Repeat:</span>
                    <FormElementCheckBox
                      fieldId={"27"}
                      value={dataForm.data_27}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" ฉันรู้ว่าจอมเป็นคนเดียวที่มาช่วยงานในวันนี้"
                    />
                    <FormElementCheckBox
                      fieldId={"28"}
                      value={dataForm.data_28}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" แมวมักซ่อนตัวอยู่หลังเก้าอี้เมื่อมีหมาอยู่ในห้อง"
                    />
                  </div>
                </td>
                <td>{getScore5_1}/2</td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left">
                    <span className="formTextBoxLabel">
                      Fluency/ บอกคำที่ขึ้นต้นด้วยตัวอักษร "ก" ให้มากที่สุดใน 1
                      นาที
                    </span>
                    <span className="formTextBoxLabel">ก</span>
                    <FormElementCheckBox
                      fieldId={"29"}
                      value={dataForm.data_29}
                      fieldValue={""}
                      onChange={handleChange}
                      label=""
                    />
                    <FormElementTextBox
                      fieldId={"30"}
                      value={dataForm.data_30}
                      label=""
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">
                      (N มากกว่าหรือเท่ากับ 11 words)
                    </span>
                  </div>
                </td>
                <td>{getScore5_2}/1</td>
              </tr>
            </tbody>
          </table>
          <table className="uk-table uk-table-small uk-table-divider form-table">
            <thead>
              <tr>
                <th>ABSTRACTION</th>
                <th>คะแนน</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left">
                    <span className="formTextBoxLabel">
                      {" "}
                      บอกความเหมือนระหว่าง 2 สิ่ง เช่น กล้วย-ส้ม : เป็นผลไม้
                    </span>
                    <FormElementCheckBox
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" รถไฟ - จักรยาน"
                    />
                    <FormElementCheckBox
                      fieldId={"32"}
                      value={dataForm.data_32}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" นาฬิกา - ไม้บรรทัด"
                    />
                  </div>
                </td>
                <td>{getScore6}/2</td>
              </tr>
            </tbody>
          </table>
          <table className="uk-table uk-table-small uk-table-divider form-table">
            <tbody>
              <tr>
                <th colSpan={8}>DELAYED RECALL</th>
                <th>คะแนน</th>
              </tr>
              <tr>
                <th rowSpan={3} className="center">
                  Optional
                </th>
                <th>ให้ทวนชุดคำที่จำไว้ก่อนหน้านี้โดยไม่มีการให้ตัวช่วย</th>
                <th>
                  <FormElementCheckBox
                    fieldId={"33"}
                    value={dataForm.data_33}
                    fieldValue={""}
                    onChange={handleChange}
                    label="หน้า"
                  />
                </th>
                <th>
                  <FormElementCheckBox
                    fieldId={"34"}
                    value={dataForm.data_34}
                    fieldValue={""}
                    onChange={handleChange}
                    label="ผ้าไหม"
                  />
                </th>
                <th>
                  <FormElementCheckBox
                    fieldId={"35"}
                    value={dataForm.data_35}
                    fieldValue={""}
                    onChange={handleChange}
                    label="วัด"
                  />
                </th>
                <th>
                  <FormElementCheckBox
                    fieldId={"36"}
                    value={dataForm.data_36}
                    fieldValue={""}
                    onChange={handleChange}
                    label="มะลิ"
                  />
                </th>
                <th>
                  <FormElementCheckBox
                    fieldId={"37"}
                    value={dataForm.data_37}
                    fieldValue={""}
                    onChange={handleChange}
                    label="สีแดง"
                  />
                </th>
                <td rowSpan={3}> ให้คะแนนเฉพะาคำที่ทวนได้โดยไม่ให้ตัวช่วย</td>
                <td rowSpan={3}>{getScore7}/5</td>
              </tr>
              <tr>
                <td>Catagory Cue</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Multiple choice Cue</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <table className="uk-table uk-table-small uk-table-divider form-table">
            <tbody>
              <tr>
                <th>ORIENTATION</th>
                <th>คะแนน</th>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left">
                    <FormElementCheckBox
                      fieldId={"38"}
                      value={dataForm.data_38}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" วันที่"
                    />
                    <FormElementCheckBox
                      fieldId={"39"}
                      value={dataForm.data_39}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" เดือน"
                    />
                    <FormElementCheckBox
                      fieldId={"40"}
                      value={dataForm.data_40}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" ปี"
                    />
                    <FormElementCheckBox
                      fieldId={"41"}
                      value={dataForm.data_41}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" วัน"
                    />
                    <FormElementCheckBox
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" สถานที่"
                    />
                    <FormElementCheckBox
                      fieldId={"43"}
                      value={dataForm.data_43}
                      fieldValue={""}
                      onChange={handleChange}
                      label=" จังหวัด"
                    />
                  </div>
                </td>
                <td>{getScore8}/6</td>
              </tr>
            </tbody>
          </table>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              คะแนนรวม: <strong>{getTotalScore}/30</strong>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              เพิ่ม 1 คะแนน ถ้าจำนวนปีการศึกษา น้อยกว่าหรือเท่ากับ 6
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              ค่าปกติ {">"} 25/30
            </div>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel label="ระดับคะแนน" />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              ถ้าคะแนนน้อยกว่า 25 ผิดปกติ
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              ถ้าคะแนนมากกว่าหรือเท่ากับ 25 ปกติ
            </div>
          </div>
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <FormMocaPaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
            />}
        />
      </div>
    </>
  );
};

export default FormMoca;
