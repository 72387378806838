import React, { useState, useCallback, useEffect } from 'react';
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import '../../css/dietz.css';
import SaveSuccessfulMessage from "../../Components/SaveSuccessfulMessage";

UIkit.use(Icons);

const AppointmentDetail = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataListOrg, setDataListOrg] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [name, setName] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const { show } = props;
  const [isShowSaveSuccessfulPopup, setIsShowSaveSuccessfulPopup] =
    useState(false);

  function onClose() {
    props.onClose();
  }

  function showPopup() {
    UIkit.modal('#modal-data').show();
  }

  async function doSave() {
    var result = await Api.consult("AppointmentSave", {
      appointmentId: props.id,
      status: status,
      remark: remark
    });

    

    if(result.result) {
      setIsShowSaveSuccessfulPopup(true);

    } else {
      console.log(result);
    }
  }

  const handleCloseSavePopup = useCallback(() => {
    setIsShowSaveSuccessfulPopup(false);
    props.onClose();
  });

  useEffect(() => {
    async function loadData() {
      if(typeof(props.id) != "undefined") {
        var res = await Api.consult("AppointmentDetail", {
          "id" : props.id,
        });

        console.log(res);

        setName(res.data.name);
        setAppointmentDate(moment(res.data.appointment_date).format('DD/MM/YYYY'));
        setAppointmentTime(res.data.start_time + " - " + res.data.end_time);
        setStatus(res.data.status);
        setRemark(res.data.remark);
      }
    }

    loadData();
    
  }, [props.id]);

  useEffect(() => {
    if (show) {
        UIkit.modal('#modal-data').show();
    
    } else {
        UIkit.modal('#modal-data').hide();
    }
    
  }, [ show ]);

  return (
    <div id="modal-data" className="uk-modal" >
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default uk-close" type="button" onClick={onClose} ></button>
          <div className="uk-modal-header">
              <h2 className="uk-modal-title">รายละเอียดการนัดหมาย</h2>
          </div>
          <div className="uk-modal-body">
          <div class="uk-grid">
              <div class="uk-width-2-5">ชื่อคนไข้</div>
              <div class="uk-width-3-5">
                {name}
              </div>
            </div>
            <div class="uk-grid">
              <div class="uk-width-2-5">วันที่</div>
              <div class="uk-width-3-5">
                {appointmentDate}
              </div>
            </div>
            <div class="uk-grid">
              <div class="uk-width-2-5">เวลา</div>
              <div class="uk-width-3-5">
                {appointmentTime}
              </div>
            </div>
            <div class="uk-grid">
              <div class="uk-width-2-5">สถานะการนัดหมาย</div>
              <div class="uk-width-3-5">
                <select className='uk-input' value={status} onChange={e => setStatus(e.target.value)}>
                  <option value="">เลือกสถานะ</option>
                  <option value="n">รายการใหม่</option>
                  <option value="g">ได้รับ link นัดหมาย</option>
                  <option value="h">ระบุเวลานัดหมายแล้ว</option>
                  <option value="i">กำลังคุยกับเภสัช</option>
                  <option value="j">คุยกับเภสัชเสร็จสิ้น</option>
                </select>
              </div>
            </div>
            <div class="uk-grid">
              <div class="uk-width-2-5">หมายเหตุ</div>
              <div class="uk-width-3-5">
                <textarea rows={5} cols={20} className='formTextArea uk-textarea' value={remark} onChange={e => setRemark(e.target.value)}></textarea>
              </div>
            </div>
          </div>
          <div className="uk-modal-footer uk-text-right">
              <button className="uk-button uk-button-primary " type="button" onClick={doSave}>บันทึกข้อมูล</button>
              <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={onClose}>ยกเลิก</button>
          </div>
      </div>

      <SaveSuccessfulMessage
          show={isShowSaveSuccessfulPopup}
          onClose={handleCloseSavePopup}
        />
    </div>
  )
}

export default AppointmentDetail;
