import React, { useState, useEffect, useCallback, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

import "../../../css/dietz.css";
import FormElementLabel from "../Form/FormElementLabel";

const FormHomeVisitPaper = (props) => {
  const { dataForm, patient, profile } = props;

  const lblTable = {
    1: "มี",
    2: "ไม่มี",
  };

  const lblEnvironment = {
    1: "ดี - ดูเรียบร้อย ไม่มีขยะ ไม่มีฝุ่นละอองฟุ้ง กระจาย ไม่มีสัตว์เลี้ยงภายในบ้าน",
    2: "พอใช้ - มีขยะและฝุ่นบ้าง ไม่มีสัตว์เลี้ยงภายในบ้าน",
    3: "ปรับปรุง - เต็มด้วยขยะ ฝุ่นละอองฟุ้งกระจาย รอบบ้านมีน้ำขัง",
  };

  const lblWaterSource = {
    1: "ดี - ใช้น้ำประปา ใช้น้ำที่ผลิตสำหรับดื่ม ถ้าเป็นประปาหมู่บ้านต้องใส ไร้กลิ่น",
    2: "พอใช้ - ใช้น้ำฝนหรือน้ำบ่อที่ผ่านเครื่องกรองแล้ว",
    3: "ปรับปรุง - ใช้น้ำบ่อหรือน้ำคลอง น้ำมีลักษณะขุ่น หรือมีกลิ่น",
  };

  const lblHygiene = {
    1: "ดี - สะอาด เป็นไปตามคำแนะนำ",
    2: "พอใช้ - สะอาดพอสมควร",
    3: "ปรับปรุง - สกปรก เต็มไปด้วยตะไคร้น้ำ",
  };

  const lblWard = {
    1: "ดี - ไม่คับแคบ สะอาด ไม่มีกลิ่น เก็บของเรียบร้อย อากาศถ่ายเทดี",
    2: "พอใช้ - คับแคบ ค่อนข้างสะอาด และมีหยากไย่บ้าง",
    3: "ปรับปรุง - สกปรก รกรุงรัง มีหยากไย่ทั่วๆห้อง",
  };

  const lblPotionExchange = {
    1: "ดี - สะอาด มีพื้นที่กว้างขวาง ไม่อับชื้น มีแสงสว่างเพียงพอ",
    2: "พอใช้ - มีพื้นที่พอสมควร ไม่สกปรก มีหยากไย่บ้าง แสงสว่างเพียงพอ",
    3: "ปรับปรุง - คับแคบ สกปรก แสงสว่างไม่เพียงพอ",
  };

  const lblPotionStorage = {
    1: "ดี - ไม่โดนแดด ไม่อับชื้น มีการวางกล่องน้ำยาอย่างมีระเบียบ",
    2: "พอใช้ - รกรุงรัง น้ำยาจัดวางไม่เป็นระเบียบ",
    3: "ปรับปรุง - โดดแดด หรือหรือชื้นแฉะ",
  };

  const lblPotionEquipment = {
    1: "ดี - สะอาด อุปกรณ์ครบ เป็นระเบียบ",
    2: "พอใช้ - อุปกรณ์ครบแต่ไม่เป็นระเบียบ",
    3: "ปรับปรุง - อุปกรณ์ไม่ครบ",
  };

  const lblPatientPotion = {
    1: "ดี - ล้างมือถูกต้องเปลี่ยนอย่างคล่องแคล่ว",
    2: "พอใช้ - ล้างมือถูกต้อง มีการใส่ mask เปลี่ยนน้ำยาเงอะงะ",
    3: "ปรับปรุง - ไม่ใส่ mask ไม่ทำความสะอาดโต๊ะและอุปกรณ์",
  };

  const lblDestroyUsedPotionMethod = {
    1: "ดี - เทน้ำยาที่ใช้แล้วทิ้งในส้วม เก็บถุงน้ำยา ทิ้งขยะ",
    2: "พอใช้ - เทน้ำยาที่ใช้แล้วในส้วม ทิ้งถุงเปล่า ไม่เป็นที่ทาง",
    3: "ปรับปรุง - ไม่เทน้ำยา ทิ้งลงถังขยะทั้งถุง",
  };

  const lblKidneyDialog = {
    1: "ดี - จดบันทึกอย่างมีระเบียบหลังเปลี่ยนน้ำยา",
    2: "พอใช้ - จดบันทึกทันที หลังเปลี่ยนน้ำยา",
    3: "ปรับปรุง - ไม่จดบันทึก หรือบันทึกภายหลัง",
  };

  const lblHowToKeepPotion = {
    1: "ดี - เก็บยาไว้ในตู้หรือกล่องอย่างมีระเบียบ ไม่โดนแดดส่อง",
    2: "พอใช้ - เก็บยาไว้ใช้ตามชั้นวางของแต่ยังอยู่ในถุงเดียวกัน",
    3: "ปรับปรุง - วางยาไว้กระจัดกระจาย ขอดูยาแล้วแจ้งว่าหาไม่เจอ",
  };

  const lblHowToKeepVaccine = {
    1: "ดี - เก็บยาไว้ในตู้เย็นอย่างมีระเบียบ ไม่โดนแดดส่อง",
    2: "พอใช้ - วางยาไว้กระจัดกระจาย เก็บยาไว้บริเวณฝาตู้เย็น",
    3: "ปรับปรุง - เก็บยาไว้ที่อุณหภูมิห้อง",
  };

  const lblMentalHealthRating = {
    1: "ดี - รู้สึกดี กระปรี้กระเปร่า กระตือรือร้น มีกำลังใจ",
    2: "พอใช้ - รู้สึกดี กระปรี้กระเปร่าบางครั้ง ท้อแท้เบื่อหน่ายบางครั้ง",
    3: "ปรับปรุง - รู้สึกท้อแท้เบื่อหน่ายบ่อยครั้ง",
  };

  const lblPatienFamilyAttention = {
    1: "ดี - ครอบครัวให้การสนับสนุน และช่วยเหลือให้กำลังใจเป็นอย่างดี",
    2: "พอใช้ - ครอบครัวให้การสนับสนุนและช่วยเหลือบ้าง",
    3: "ปรับปรุง - ครอบครัวไม่ให้การช่วยเหลือเลย",
  };

  const lblData98 = {
    1: "1.5",
    2: "2.5",
    3: "4.25",
    4: "7.5",
    99: "อื่นๆ",
  };

  return (
    <React.Fragment>
      {!props.isNewPaper && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "800px",
            backgroundColor: "#fff",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0px 0px 0px 0px",
                justifyContent: "center",
              }}
            >
              <FormElementTitle
                label="แบบประเมินการเยี่ยมบ้าน (Home Visit)"
                mode="view"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 0px 0px 0px",
              }}
              id="htmlPreviewMemberData"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
            >
              <PatientProfileIPaper memberProfile={patient} />
            </div>
            <div
              style={{ display: "inline-flex", gap: "8px", marginTop: "14px" }}
            >
              <FormElementTextBox
                label="ครั้งที่"
                value={dataForm.data_1}
                mode="view"
                width="100"
              />

              <FormElementTextBox
                label="วันที่"
                value={dataForm.data_2}
                mode="view"
                width="100"
                type="date"
              />
            </div>
            <div
              style={{
                padding: "10px 0px 0px 0px",
              }}
            >
              <FormElementLabel
                mode="view"
                label="เหตุผลในการเยี่ยมบ้าน"
                padding="0px 10px 0px 0px"
              />
            </div>
            <div
              style={{
                padding: "10px 0px 0px 0px",
              }}
            >
              <FormElementTextBox
                value={dataForm.data_5}
                padding="0px 10px 0px 0px"
                mode="view"
                width="100"
              />
            </div>
            <div
              style={{
                padding: "10px 0px 0px 0px",
              }}
            >
              <FormElementLabel label="รูปแบบการรักษา" mode="view" />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 0px",
                  }}
                >
                  <FormElementLabel
                    label="ระบบน้ำยา: "
                    mode="view"
                    padding="10px 10px 0px 40px"
                  />
                  <FormElementTextBox value={dataForm.data_6} mode="view" />

                  <FormElementLabel
                    label="จำนวนลิตร/วัน: "
                    mode="view"
                    padding="10px 10px 0px 40px"
                  />
                  <FormElementTextBox value={dataForm.data_7} mode="view" />

                  <FormElementLabel
                    label="ลิตร/ถุง"
                    mode="view"
                    padding="10px 10px 0px 40px"
                  />
                  <FormElementLabel
                    value={dataForm.data_98}
                    mode="view"
                    translate={lblData98}
                  />

                  {dataForm.data_98 == "99" && (
                    <FormElementTextBox value={dataForm.data_99} mode="view" />
                  )}

                  <FormElementLabel
                    label="จำนวนรอบ (cycle) /วัน: "
                    mode="view"
                    padding="10px 10px 0px 40px"
                  />
                  <FormElementTextBox value={dataForm.data_8} mode="view" />

                  {/* <FormElementDropdown
                    value={dataForm.data_98}
                    label="ลิตร/ถุง"
                    onChange={handleChange}
                    dataList={[
                      { value: "", label: "เลือกจำนวนลิตร/ถุง" },
                      { value: "1", label: "1.5" },
                      { value: "2", label: "2.5" },
                      { value: "3", label: "4.25" },
                      { value: "4", label: "7.5" },
                      { value: "99", label: "อื่นๆ" },
                    ]}
                    mode="view"
                  /> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 40px",
                  }}
                >
                  <FormElementLabel
                    label="ความเข้มข้น (%) น้ำยา: "
                    mode="view"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px 0px 0px 40px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <FormElementLabel
                        label="1.5 %"
                        mode="view"
                        padding="10px 10px 0px 0px"
                      />
                      <FormElementTextBox value={dataForm.data_9} mode="view" />
                      <FormElementLabel
                        label="ถุง/วัน"
                        mode="view"
                        padding="0px 0px 0px 10px"
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <FormElementLabel
                        label="2.5 %"
                        mode="view"
                        padding="10px 10px 0px 0px"
                      />
                      <FormElementTextBox
                        value={dataForm.data_10}
                        mode="view"
                      />
                      <FormElementLabel
                        label="ถุง/วัน"
                        mode="view"
                        padding="0px 0px 0px 10px"
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <FormElementLabel
                        label="4.25 %"
                        mode="view"
                        padding="10px 10px 0px 0px"
                      />
                      <FormElementTextBox
                        value={dataForm.data_11}
                        mode="view"
                      />
                      <FormElementLabel
                        label="ถุง/วัน"
                        mode="view"
                        padding="0px 0px 0px 10px"
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <FormElementLabel
                        label="7.5 %"
                        mode="view"
                        padding="10px 10px 0px 0px"
                      />
                      <FormElementTextBox
                        value={dataForm.data_78}
                        mode="view"
                      />
                      <FormElementLabel
                        label="ถุง/วัน"
                        mode="view"
                        padding="0px 0px 0px 10px"
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormElementLabel
                      label="กำไร/ขาดทุน: "
                      mode="view"
                      padding="10px 10px 0px 0px"
                    />
                    <FormElementTextBox value={dataForm.data_12} mode="view" />
                    <FormElementLabel
                      label="ml."
                      mode="view"
                      padding="0px 0px 0px 10px"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "0px 0px 0px 40px",
                    }}
                  >
                    <FormElementLabel
                      label="ลักษณะน้ำยาที่ปล่อยออกมา: "
                      mode="view"
                      padding="10px 10px 0px 0px"
                    />
                    {dataForm.data_92 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_92}
                        translate={{ 1: "ใส" }}
                        mode="view"
                        padding="0px 10px 0px 0px"
                      />
                    )}
                    {dataForm.data_91 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_91}
                        translate={{ 1: "ขุ่น" }}
                        mode="view"
                        padding="0px 10px 0px 0px"
                      />
                    )}
                    {dataForm.data_93 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_93}
                        translate={{ 1: "เหลือง" }}
                        mode="view"
                        padding="0px 10px 0px 0px"
                      />
                    )}
                    {dataForm.data_94 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_94}
                        translate={{ 1: "มีวุ้น" }}
                        mode="view"
                        padding="0px 10px 0px 0px"
                      />
                    )}
                    {dataForm.data_95 == "1" && (
                      <FormElementLabel
                        value={dataForm.data_95}
                        translate={{ 1: "สีแดง" }}
                        mode="view"
                        padding="0px 10px 0px 0px"
                      />
                    )}
                    {dataForm.data_96 == "1" && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <FormElementLabel
                          value={dataForm.data_96}
                          translate={{ 1: "อื่น ๆ" }}
                          mode="view"
                          padding="0px 10px 0px 0px"
                        />
                        <FormElementTextBox
                          value={dataForm.data_97}
                          mode="view"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormElementLabel
                      label="ระยะเวลาน้ำยาเข้าออกต่อรอบ"
                      mode="view"
                      padding="10px 10px 0px 0px"
                    />
                    <FormElementTextBox value={dataForm.data_100} mode="view" />
                    <FormElementLabel
                      label="นาที"
                      mode="view"
                      padding="0px 0px 0px 10px"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormElementLabel
                      label="ปริมาณน้ำดื่ม"
                      mode="view"
                      padding="10px 10px 0px 0px"
                    />
                    <FormElementTextBox value={dataForm.data_101} mode="view" />
                    <FormElementLabel
                      label="cc"
                      mode="view"
                      padding="0px 0px 0px 10px"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormElementLabel
                      label="ปริมาณปัสสาวะ"
                      mode="view"
                      padding="10px 10px 0px 0px"
                    />
                    <FormElementTextBox value={dataForm.data_102} mode="view" />
                    <FormElementLabel
                      label="cc"
                      mode="view"
                      padding="0px 0px 0px 10px"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormElementLabel
                      label="APD setting: "
                      mode="view"
                      padding="10px 40px 0px 0px"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "00px 0px 0px 0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <FormElementLabel
                            label="Total volume: "
                            mode="view"
                            padding="10px 10px 0px 0px"
                          />
                          <FormElementTextBox
                            value={dataForm.data_14}
                            mode="view"
                          />
                          <FormElementLabel
                            label="ml."
                            mode="view"
                            padding="0px 0px 0px 10px"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "0px 0px 0px 40px",
                          }}
                        >
                          <FormElementLabel
                            label="Treatment time: "
                            mode="view"
                            padding="10px 10px 0px 0px"
                          />
                          <FormElementTextBox
                            value={dataForm.data_15}
                            mode="view"
                          />
                          <FormElementLabel
                            label="hr."
                            mode="view"
                            padding="0px 0px 0px 10px"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "0px 0px 0px 40px",
                          }}
                        >
                          <FormElementLabel
                            label="Filled volume: "
                            mode="view"
                            padding="10px 10px 0px 0px"
                          />
                          <FormElementTextBox
                            value={dataForm.data_16}
                            mode="view"
                          />
                          <FormElementLabel
                            label="ml."
                            mode="view"
                            padding="0px 0px 0px 10px"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "10px 0px 0px 0px",
                          }}
                        >
                          <FormElementLabel
                            label="I-drain alarm: "
                            mode="view"
                            padding="10px 10px 0px 0px"
                          />
                          <FormElementTextBox
                            value={dataForm.data_17}
                            mode="view"
                          />
                          <FormElementLabel
                            label="ml."
                            mode="view"
                            padding="0px 0px 0px 10px"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "10px 0px 0px 40px",
                          }}
                        >
                          <FormElementLabel
                            label="Minimum alarm: "
                            mode="view"
                            padding="10px 10px 0px 0px"
                          />
                          <FormElementTextBox
                            value={dataForm.data_18}
                            mode="view"
                          />
                          <FormElementLabel
                            label="%"
                            mode="view"
                            padding="0px 0px 0px 10px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: "10px 0px 0px 40px" }}>
                  <FormElementLabel
                    label="ประเมินอาการ & อาการแสดง"
                    mode="view"
                    padding="10px 0px 0px 0px"
                  />
                  <div style={{ padding: "10px 0px 0px 0px" }}>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid grey",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "0px 20px 0px 0px",
                              background: "#EFF0F3",
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            หัวข้อ
                          </th>
                          <th
                            style={{
                              padding: "0px 20px 0px 0px",
                              background: "#EFF0F3",
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            มี/ไม่มี
                          </th>
                          <th
                            style={{
                              padding: "0px 20px 0px 0px",
                              background: "#EFF0F3",
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            รายละเอียดเพิ่มเติม
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            1. วิงเวียน
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_19}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_20}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            2. คลื่นไส้/อาเจียน
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_79}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_80}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            3. เบื่ออาหาร
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_83}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_84}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            4. ไอ
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_21}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_22}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            5. บวม
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_23}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_24}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            6. หายใจหอบเหนื่อย
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_25}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_26}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            7. อ่อนเพลีย
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_27}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_28}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            8. การนอนหลับ
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_29}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_30}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            9. การขับถ่าย (ท้องผูก)
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementLabel
                              translate={lblTable}
                              value={dataForm.data_31}
                              mode="view"
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_32}
                              mode="view"
                              rows={2}
                              cols={50}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <FormElementTitle
                    label="ประเมินทั่วไป"
                    mode="view"
                    padding="10px 0px 0px 0px"
                  />
                  <div style={{ padding: "10px 0px 0px 0px" }}>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid grey",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              background: "#EFF0F3",
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            หัวข้อ
                          </th>
                          <th
                            style={{
                              background: "#EFF0F3",
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            ปัญหาที่พบ
                          </th>
                          <th
                            style={{
                              background: "#EFF0F3",
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            รายละเอียดเพิ่มเติม
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>1. สภาพแวดล้อมที่พัก</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  translate={lblEnvironment}
                                  value={dataForm.data_33}
                                  mode="view"
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_34}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_35}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>2. แหล่งน้ำใช้</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_36}
                                  mode="view"
                                  translate={lblWaterSource}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_37}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_38}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>
                                  3. ห้องน้ำ, ที่ล้างมือ, สบู่ล้างมือ
                                </strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_39}
                                  mode="view"
                                  translate={lblHygiene}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_40}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_41}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>4. ห้องที่ผู้ป่วยพัก</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_42}
                                  mode="view"
                                  translate={lblWard}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_43}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_44}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>5. สถานที่เปลี่ยนน้ำยา</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_45}
                                  mode="view"
                                  translate={lblPotionExchange}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_46}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_47}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>6. สถานที่เก็บน้ำยาถุงใหม่</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_48}
                                  mode="view"
                                  translate={lblPotionStorage}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_49}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_50}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>
                                  7. อุปกรณ์ในการเปลี่ยนน้ำยาของผู้ป่วย
                                </strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_51}
                                  mode="view"
                                  translate={lblPotionEquipment}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_52}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_53}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>8. การเปลี่ยนน้ำยาของผู้ป่วย</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_54}
                                  mode="view"
                                  translate={lblPatientPotion}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_55}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_56}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>9. วิธีกำจัดถุงน้ำยาที่ใช้แล้ว</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_57}
                                  mode="view"
                                  translate={lblDestroyUsedPotionMethod}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_58}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_59}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>10. จดบันทึกการล้างไต</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_60}
                                  mode="view"
                                  translate={lblKidneyDialog}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_61}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_62}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>
                                  11. การเก็บรักษายาที่ใช้รับประทาน
                                </strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_63}
                                  mode="view"
                                  translate={lblHowToKeepPotion}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_64}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_65}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>12. การเก็บรักษายาฉีด</strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_66}
                                  mode="view"
                                  translate={lblHowToKeepVaccine}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_67}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_68}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <div>
                                <strong>
                                  13. การประเมินด้านจิตใจของผู้ป่วย
                                </strong>
                              </div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_69}
                                  mode="view"
                                  translate={lblMentalHealthRating}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_70}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_71}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <div>
                              <strong>14. การประเมินครอบครัวของผู้ป่วย</strong>
                            </div>
                            <div>
                              <div>
                                <FormElementLabel
                                  value={dataForm.data_72}
                                  mode="view"
                                  translate={lblPatienFamilyAttention}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_73}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                          <td
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid grey",
                            }}
                          >
                            <FormElementTextArea
                              value={dataForm.data_74}
                              mode="view"
                              rows={5}
                              cols={50}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px 0px 0px 40px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 40px 0px 0px",
                    }}
                  >
                    <FormElementTitle
                      label="หมายเหตุ"
                      mode="view"
                      padding="10px 40px 0px 0px"
                    />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 0px 0px",
                    }}
                  >
                    <FormElementTextBox value={dataForm.data_75} mode="view" />
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px 0px 0px 40px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 40px 0px 0px",
                    }}
                  >
                    <FormElementTitle
                      label="ลงชื่อผู้ตรวจเยี่ยม"
                      mode="view"
                      padding="10px 40px 0px 0px"
                    />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 0px 0px",
                    }}
                  >
                    <FormElementTextBox value={dataForm.data_76} mode="view" />
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px 0px 0px 40px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 40px 0px 0px",
                    }}
                  >
                    <FormElementTitle
                      label="ลงชื่อผู้ได้รับการตรวจเยี่ยม"
                      mode="view"
                      padding="10px 40px 0px 0px"
                    />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 0px 0px",
                    }}
                  >
                    <FormElementTextBox value={dataForm.data_77} mode="view" />
                  </div>
                </div>
                <SavingProfileIPaper profile={profile} />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.isNewPaper && <IPaperContent {...props} />}
    </React.Fragment>
  );
};

export default FormHomeVisitPaper;

const LIST_OF_MONTH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const IPaperContent = (props) => {
  const { memberProfile, dataForm } = props;

  useEffect(() => {
    console.log(props);
  }, [props]);

  const getCurrentDate = useMemo(() => {
    const date = new Date();
    return `วันที่ ${date.getDate()} ${LIST_OF_MONTH[date.getMonth()]} ${
      date.getFullYear() + 543
    }`;
  }, []);

  const getCurrentTime = useMemo(() => {
    const date = new Date();
    return `เวลา ${date.getHours()}:${date.getMinutes()} น.`;
  }, []);

  const convertDate = useCallback((value) => {
    const thYear = value.substring(0, 4);
    const enYear = parseInt(thYear) + 543;

    const parts = value.replace(thYear, enYear).split("-");

    if (value.length > 10) {
      const year = parts[0];
      const time = parts[2].split(" ")[1];
      const month = parts[1];
      const day = parts[2].substring(0, 2);

      return day + "/" + month + "/" + year;
    } else {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return day + "/" + month + "/" + year;
    }
  });

  const getValue = useCallback((value) => {
    if (value == "1") {
      return "มี";
    } else {
      return "ไม่มี";
    }
  });

  const getGender = useCallback((value) => {
    if (value == "ช") {
      return "ชาย";
    } else {
      return "หญิง";
    }
  });

  return (
    // <table style={{ width: '100%' }}>
    //   <thead >
    //     <tr>
    //       <td style={{ paddingTop: '40px' }}>header</td>
    //     </tr>
    //   </thead>
    //   <tfoot style={{ height: '50px' }}>
    //     <tr>
    //       <td style={{ position: 'fixed', bottom: "0" }}>
    //         footer
    //       </td>
    //     </tr>
    //   </tfoot>
    //   <tbody>
    //     <tr>
    //       <td>
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />
    //         dawdawdawdawdaw <br />

    //       </td>
    //     </tr>
    //   </tbody>
    // </table>
    <div
      style={{
        // padding: "20px 20px 30px 20px",
        fontSize: "14px",
        fontFamily:
          "'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',Roboto, 'Helvetica Neue', Arial, sans-serif",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div style={{ fontSize: "16px" }}>
        <strong>{getCurrentDate}</strong>
      </div>
      <div style={{ display: "inline-flex", gap: "10px" }}>
        <div style={{ display: "inline-flex", gap: "4px" }}>
          <span>ชื่อ-สกุล</span>
          <span>{memberProfile.name}</span>
        </div>
        <div style={{ display: "inline-flex", gap: "4px" }}>
          <span>เพศ</span>
          <span>{getGender(memberProfile.sex)}</span>
        </div>
        <div style={{ display: "inline-flex", gap: "4px" }}>
          <span>กรุ๊บเลือด</span>
          <span>O</span>
        </div>
      </div>
      <div style={{ display: "inline-flex", gap: "10px" }}>
        <div style={{ display: "inline-flex", gap: "4px" }}>
          <span>อายุ</span>
          <span>{memberProfile?.ageFull?.y}</span>
          <span>ปี</span>
          <span>{memberProfile?.ageFull?.m}</span>
          <span>เดือน</span>
          <span>{memberProfile?.ageFull?.d}</span>
          <span>วัน</span>
        </div>
        <div style={{ display: "inline-flex", gap: "4px" }}>
          <span>เกิด</span>
          <span>{memberProfile.birthDate_th}</span>
        </div>
        <div style={{ display: "inline-flex", gap: "4px" }}>
          <span>โทร</span>
          <span>0815554444</span>
        </div>
      </div>
      <div
        style={{
          display: "inline-flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            gap: "14px",
            whiteSpace: "nowrap",
            fontSize: "16px",
          }}
        >
          <strong>{props.profile.doctorName}</strong>
          <strong>{getCurrentTime}</strong>
        </div>
        <div
          className="line"
          style={{ backgroundColor: "grey", width: "100%", height: "2px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginTop: "0px",
        }}
      >
        <strong style={{ color: "#00BFA5", marginBottom: "4px" }}>
          แบบประเมินการเยี่ยมบ้าน (Home Visit)
        </strong>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "0px 0px",
          }}
        >
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <span>ครั้งที่</span>
              <span>{dataForm.data_1}</span>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <span>วันที่</span>
              <span>{convertDate(dataForm.data_2)}</span>
            </div>
          </div>
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                เหตุผลในการเยี่ยมบ้าน
              </span>
              <span>{dataForm.data_5}</span>
            </div>
          </div>
          <strong style={{ marginTop: "4px" }}>รูปแบบการรักษา</strong>
          <strong style={{ marginTop: "4px" }}>ประเมินอาการ & อาการแสดง</strong>
          <table style={{ marginTop: "8px" }} className="paper-table">
            <thead>
              <tr>
                <th>หัวข้อ</th>
                <th style={{ whiteSpace: "nowrap" }}>มี/ไม่มี</th>
                <th>รายละเอียดเพิ่มเติม</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>1. วิงเวียน</td>
                <td>{getValue(dataForm.data_19)}</td>
                <td>{dataForm.data_20}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>2. คลื่นไส้/อาเจียน</td>
                <td>{getValue(dataForm.data_79)}</td>
                <td>{dataForm.data_80}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>3. เบื่ออาหาร</td>
                <td>{getValue(dataForm.data_83)}</td>
                <td>{dataForm.data_84}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>4. ไอ</td>
                <td>{getValue(dataForm.data_21)}</td>
                <td>{dataForm.data_22}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>5. บวม</td>
                <td>{getValue(dataForm.data_23)}</td>
                <td>{dataForm.data_24}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>6. หายใจหอบเหนื่อย</td>
                <td>{getValue(dataForm.data_25)}</td>
                <td>{dataForm.data_26}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>7. อ่อนเพลีย</td>
                <td>{getValue(dataForm.data_27)}</td>
                <td>{dataForm.data_28}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>8. การนอนหลับ</td>
                <td>{getValue(dataForm.data_29)}</td>
                <td>{dataForm.data_30}</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>
                  9. การขับถ่าย (ท้องผูก)
                </td>
                <td>{getValue(dataForm.data_31)}</td>
                <td>{dataForm.data_32}</td>
              </tr>
            </tbody>
          </table>

          <strong style={{ marginTop: "4px" }}>ประเมินทั่วไป</strong>
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <span style={{ whiteSpace: "nowrap" }}>หมายเหตุ</span>
              <span>{dataForm.data_75}</span>
            </div>
          </div>
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <span style={{ whiteSpace: "nowrap" }}>ลงชื่อผู้ตรวจเยี่ยม</span>
              <span>{dataForm.data_76}</span>
            </div>
          </div>
          <div style={{ display: "inline-flex", gap: "10px" }}>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                ลงชื่อผู้ได้รับการตรวจเยี่ยม
              </span>
              <span>{dataForm.data_77}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
