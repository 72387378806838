import React, { useState, useEffect, useMemo } from 'react';
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import '../../css/dietz.css';
import '../../css/dietz2.css';


UIkit.use(Icons);

const MemberAppointmentCall = (props) => {
    const [classStatus, setClassStatus] = useState("MemberAC");

    useEffect(() => {
        var className = "MemberAC ";

        if(props.callData.onlineStatus == "offline") {
            className += "MemberACOffline";
            
        }   

        if(props.callData.actionStatus == "request") {
            className += "MemberACRequest";
            
        } else if(props.callData.actionStatus == "new") {
            className += "MemberACNew";

        }  else if(props.callData.actionStatus == "active") {
            className += "MemberACActive";

        } 

        setClassStatus(className);

    }, [props.status, props.actionStatus]);

    const renderTime = useMemo(() => {
        if (!props?.callData?.entryTime) return '';

        const tempTime = props?.callData?.entryTime.split(/:/g);
        return `${(tempTime[0].length < 2 ? "0" : "") + tempTime[0]}:${(tempTime[1].length < 2 ? "0" : "") + tempTime[1]}`;
    },[props.callData.entryTime]);

    return (
        <div className="uk-width-1-6@m uk-width-1-1" key={props.callData.txn}>
            <div className={classStatus} >
                <div  onClick={(event) => props.onClick(props.callData)}>
                    <div className="MemberAcHN">  HN : {props.callData.hn} </div>
                    <div className="MemberAcName">{props.callData.patientName}</div>
                    <div className="MemberAcConnectDateTime"> {renderTime} น.</div>
                    <div className="MemberStatusName"> สถานะ : {props.callData.statusName}</div>
                    <div ><br/></div>
                </div>
                <div > <button 
                        style={{padding: "0px 0px 0px 0px", width:200, height:50}} 
                        onClick={(e)=>props.onClick(props.callData)}
                        disabled={( props.callData.onlineStatus == "offline" ? true : false )}
                        >คุยกับผู้ป่วย</button>.</div>
                <br/>
                <div > <button onClick={(e)=>props.doSignOut(props.callData)}>ออกจากห้อง</button>.</div>
            </div>
        </div>
        
    )

}

export default MemberAppointmentCall;