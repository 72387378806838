import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';

UIkit.use(Icons);

export default class DashboardDepartment extends Component {

  state = {
    department: <span uk-spinner=""></span>
  }

  componentDidMount() {
    this.userGet();
  }

  async userGet() {
    var {department} = await Api.member('DashboardDepartment');
    this.setState({department: '+ ' + department});
  }

  render() {
    return <IconCard option={{
      backgroundColorLeft: '#ff425c',
      backgroundColorRight: '#ff7588',
      icon: 'layers',
      title: 'Department',
      caption: this.state.department || 0
    }} />
  }
};
