import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import DataTable from 'react-data-table-component';
import Rate from '../../Components/Rate';
import numeral from 'numeral';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class CampaignReportGroupOfUser extends Component {
  state = {
    loadingApi: true,
    data: [],
    selectRange: 'yesterday'
  }

  componentDidMount() {
    this.dataGet();
  }

  columns = [
    {
      name: 'Group',
      selector: 'groupname',
      sortable: true
    },
    {
      name: 'Active',
      selector: 'active',
      sortable: true
    },
    {
      name: 'Change',
      cell: row => <div>{this.graphRender(row.graph.map(data=>(data || 0)))}</div>,
      sortable: true
    },
    {
      name: 'Chat',
      selector: 'chatM',
      sortable: true
    },
    {
      name: 'Avg Dietz Rate',
      cell: row => <div className={+row.dietz >= 4 ? 'uk-text-success' : 'uk-text-danger'}>{this.ratingGet(row.dietz)} / {numeral(row.dietz).format('0.0') || 0}</div>,
      sortable: true
    },
    {
      name: 'Advices',
      selector: 'chatC',
      sortable: true
    },
  ]

  dataGet = async () => {
    var { group = [] } = await Api.member('CampaignReportGroupOfUser', { campaignId: this.props.id, selectRange: this.state.selectRange });
    this.setState({ data: group || [] });
  }

  ratingGet = (rating) => {
    return <Rate readonly={true} initialRating={rating} stop={5} />
  }

  graphRender = (data) => {

    var chartOptions = {
      grid: {
        left: 0
      },
      xAxis: {
        boundaryGap: false,
        type: 'category',
        show: false
      },
      yAxis: {
        boundaryGap: false,
        show: false
      },
      series: [{
        data,
        type: 'line',
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          color: '#F98E76',
      },
      }]
    };

    return <ReactEcharts
      option={chartOptions}
      style={{ height: 100, width: 200 }}

      notMerge={true}
      lazyUpdate={true}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-campaign-report-group-of-user">
      <div className="d-card-header">
        <div className="d-card-header-left">
          GROUP OF USERS
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={() => this.dataGet()}></span>
          <FullScreenElement element="#d-campaign-report-group-of-user" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top">
        <div className="uk-button-group">
        <button className="uk-button uk-button-primary uk-margin-small-right" onClick={() => this.setState({ selectRange: 'yesterday' }, () => this.dataGet())}>Yesterday</button>
        <button className="uk-button uk-button-primary uk-margin-small-right" onClick={() => this.setState({ selectRange: 'lastMonth' }, () => this.dataGet())}>Lastmonth </button>
        <button className="uk-button uk-button-success" onClick={() => this.setState({ selectRange: 'alltime' }, () => this.dataGet())}>All</button>
        </div>
      </div>
      <div id="d-table-group-of-user-container">
        <DataTable
          striped={true}
          noHeader={true}
          pagination={true}
          columns={this.columns}
          data={this.state.data}
          style={{
            border: '1px solid #E4E7ED'
          }}
        />
      </div>
    </div>
  }
};
