import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import dateFormat from "dateformat";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormHomeVisit = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_homevisit",
      formType: "12",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบประเมินการเยี่ยมบ้าน (Home Visit)" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"1"}
          value={dataForm.data_1}
          type="numeric"
          label="ครั้งที่"
          onChange={handleChange}
          width="100"
        />
        <span className="formTextBoxLabel">วันที่</span>
        <FormElementTextBox
          fieldId={"2"}
          value={dataForm.data_2}
          type="date"
          onChange={handleChange}
          width="100"
        />
      </div>

      {/* <div>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTextBox fieldId={"3"} value={dataForm.data_3} label="ขื่อ - นามสกุล" onChange={handleChange} width="100" disabled />
                    <span className='formTextBoxLabel'>เพศ</span>
                    &nbsp;&nbsp;&nbsp;
                    <FormElementRadio fieldId={"27"} value={dataForm.data_27} fieldValue={"1"} onChange={handleChange} label=" ชาย " disabled />
                    <FormElementRadio fieldId={"27"} value={dataForm.data_27} fieldValue={"2"} onChange={handleChange} label=" หญิง " disabled />
                    <FormElementTextBox fieldId={"4"} value={dataForm.data_4} type="numeric" label="อายุ" onChange={handleChange} width="100" disabled />
                    <span className='formTextBoxLabel'>ปี</span>
                </div>
            </div> */}
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"5"}
          value={dataForm.data_5}
          label="เหตุผลในการเยี่ยมบ้าน"
          noLimit
          onChange={handleChange}
          rows={5}
          cols={100}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="รูปแบบการรักษา" fontWeight="bold" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          style={{
            display: "inline-flex",
            flexWrap: "wrap",
            rowGap: "28px",
            columnGap: "10px",
          }}
        >
          <FormElementTextBox
            fieldId={"6"}
            value={dataForm.data_6}
            label="ระบบน้ำยา"
            onChange={handleChange}
            width="100"
          />
          <FormElementTextBox
            fieldId={"7"}
            value={dataForm.data_7}
            label="จำนวนลิตร/วัน"
            onChange={handleChange}
            width="100"
          />
          <FormElementDropdown
            fieldId={"98"}
            value={dataForm.data_98}
            label="ลิตร/ถุง"
            onChange={handleChange}
            dataList={[
              { value: "", label: "เลือกจำนวนลิตร/ถุง" },
              { value: "1", label: "1.5" },
              { value: "2", label: "2.5" },
              { value: "3", label: "4.25" },
              { value: "4", label: "7.5" },
              { value: "99", label: "อื่นๆ" },
            ]}
          />
          <span hidden={dataForm.data_98 != "99"}>
            <FormElementTextBox
              fieldId={"99"}
              value={dataForm.data_99}
              type="decimal"
              placeholder="ระบุ"
              maxLength={10}
              onChange={handleChange}
              width="100"
            />
          </span>
          <FormElementTextBox
            fieldId={"8"}
            value={dataForm.data_8}
            type="numeric"
            label="จำนวนรอบ (Cycle)/วัน"
            maxLength={10}
            // regEx={/^[2,3,4,5,6]{0,1}?$/}
            onChange={handleChange}
            width="100"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        {/* <table className="uk-table uk-table-small uk-table-divider form-table ">
          <tbody>
            <tr>
              <td>
                <FormElementTextBox
                  fieldId={"6"}
                  value={dataForm.data_6}
                  label="ระบบน้ำยา"
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"7"}
                  value={dataForm.data_7}
                  label="จำนวนลิตร/วัน"
                  onChange={handleChange}
                  width="100"
                />
                <FormElementDropdown
                  fieldId={"98"}
                  value={dataForm.data_98}
                  label="ลิตร/ถุง"
                  onChange={handleChange}
                  dataList={[
                    { value: "", label: "เลือกจำนวนลิตร/ถุง" },
                    { value: "1", label: "1.5" },
                    { value: "2", label: "2.5" },
                    { value: "3", label: "4.25" },
                    { value: "4", label: "7.5" },
                    { value: "99", label: "อื่นๆ" },
                  ]}
                />
                <span hidden={dataForm.data_98 != "99"}>
                  <FormElementTextBox
                    fieldId={"99"}
                    value={dataForm.data_99}
                    type="decimal"
                    placeholder="ระบุ"
                    maxLength={10}
                    onChange={handleChange}
                    width="100"
                  />
                </span>
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"8"}
                  value={dataForm.data_8}
                  type="numeric"
                  label="จำนวนรอบ (Cycle)/วัน"
                  regEx={/^[2,3,4,5,6]{0,1}?$/}
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
          </tbody>
        </table> */}
        <table className="uk-table uk-table-small uk-table-divider form-table ">
          <tbody>
            <tr>
              <td rowSpan={4}>ความเข้มข้น (%) น้ำยา</td>
              <td colSpan={3}>
                <FormElementTextBox
                  fieldId={"9"}
                  value={dataForm.data_9}
                  type="decimal"
                  label="1.5%"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">ถุง/วัน</span>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <FormElementTextBox
                  fieldId={"10"}
                  value={dataForm.data_10}
                  type="decimal"
                  label="2.5%"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">ถุง/วัน</span>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <FormElementTextBox
                  fieldId={"11"}
                  value={dataForm.data_11}
                  type="decimal"
                  label="4.25%"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">ถุง/วัน</span>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <FormElementTextBox
                  fieldId={"78"}
                  value={dataForm.data_78}
                  type="decimal"
                  label="7.5%"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">ถุง/วัน</span>
              </td>
            </tr>
            <tr>
              <td>
                <FormElementTextBox
                  fieldId={"12"}
                  value={dataForm.data_12}
                  label="กำไร/ขาดทุน"
                  onChange={handleChange}
                  regEx={/^-?\d*\.?\d{0,2}$/g}
                  width="100"
                />
                <span className="formTextBoxLabel">ml.</span>
              </td>
              <td colSpan={3}>
                <span className="formTextBoxLabel">
                  ลักษณะน้ำยาที่ปล่อยออกมา
                </span>
                <FormElementCheckBox
                  fieldId={"92"}
                  value={dataForm.data_92}
                  onChange={handleChange}
                  label="ใส"
                />
                <FormElementCheckBox
                  fieldId={"91"}
                  value={dataForm.data_91}
                  onChange={handleChange}
                  label="ขุ่น"
                />
                <FormElementCheckBox
                  fieldId={"93"}
                  value={dataForm.data_93}
                  onChange={handleChange}
                  label="เหลือง"
                />
                <FormElementCheckBox
                  fieldId={"94"}
                  value={dataForm.data_94}
                  onChange={handleChange}
                  label="มีวุ้น"
                />
                <FormElementCheckBox
                  fieldId={"95"}
                  value={dataForm.data_95}
                  onChange={handleChange}
                  label="สีแดง"
                />
                <FormElementCheckBox
                  fieldId={"96"}
                  value={dataForm.data_96}
                  onChange={handleChange}
                  label="อื่น ๆ"
                />
                <span
                  className={
                    dataForm.data_96 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"97"}
                    value={dataForm.data_97}
                    label=""
                    onChange={handleChange}
                    placeholder="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <FormElementTextBox
                  fieldId={"100"}
                  value={dataForm.data_100}
                  label="ระยะเวลาน้ำยาเข้าออกต่อรอบ"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">นาที</span>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <FormElementTextBox
                      fieldId={"101"}
                      value={dataForm.data_101}
                      label="ปริมาณน้ำดื่ม"
                      onChange={handleChange}
                      regEx={/^-?\d*\.?\d{0,2}$/g}
                      validate={(value) =>
                        parseFloat(value) >= 0 && parseFloat(value) <= 3000
                      }
                      width="100"
                     />
                    <span className="formTextBoxLabel">cc</span>
                  </div>
                  <span
                    style={{
                      fontSize: "12px",
                      marginLeft: "100px",
                      marginTop: "8px",
                    }}
                  >
                    ระบุ ได้ตั้งแต่ 0-3000 เท่านั้น
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <FormElementTextBox
                      fieldId={"102"}
                      value={dataForm.data_102}
                      label="ปริมาณปัสสาวะ"
                      onChange={handleChange}
                      regEx={/^-?\d*\.?\d{0,2}$/g}
                      validate={(value) =>
                        parseFloat(value) >= 0 && parseFloat(value) <= 3000
                      }
                      width="100"
                    />
                    <span className="formTextBoxLabel">cc</span>
                  </div>
                  <span
                    style={{
                      fontSize: "12px",
                      marginLeft: "112px",
                      marginTop: "8px",
                    }}
                  >
                    ระบุ ได้ตั้งแต่ 0-3000 เท่านั้น
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td rowSpan={5}>
                <span className="formTextBoxLabel">APD setting</span>
              </td>
              <td className="uk-text-right">Total volume</td>
              <td>
                <FormElementTextBox
                  fieldId={"14"}
                  value={dataForm.data_14}
                  type="decimal"
                  label=""
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">ml.</span>
              </td>
            </tr>
            <tr>
              <td className="uk-text-right">Treatment time</td>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"15"}
                  value={dataForm.data_15}
                  type="decimal"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">hr.</span>
              </td>
            </tr>
            <tr>
              <td className="uk-text-right">Filled volume</td>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"16"}
                  value={dataForm.data_16}
                  type="decimal"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">ml.</span>
              </td>
            </tr>
            <tr>
              <td className="uk-text-right">I-drain alarm</td>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"17"}
                  value={dataForm.data_17}
                  type="decimal"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">ml.</span>
              </td>
            </tr>
            <tr>
              <td className="uk-text-right">Minimum alarm</td>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"18"}
                  value={dataForm.data_18}
                  type="decimal"
                  onChange={handleChange}
                  width="100"
                />
                <span className="formTextBoxLabel">%</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="ประเมินอาการ & อาการแสดง" fontWeight="bold" />
      </div>
      <div className="uk-width-2-3">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped  form-table">
          <thead>
            <tr>
              <th>หัวข้อ</th>
              <th>มี</th>
              <th>ไม่มี</th>
              <th>รายละเอียดเพิ่มเติม</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1. วิงเวียน</td>
              <td>
                <FormElementRadio
                  fieldId={"19"}
                  value={dataForm.data_19}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"19"}
                  value={dataForm.data_19}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"20"}
                  value={dataForm.data_20}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>2. คลื่นไส้/อาเจียน</td>
              <td>
                <FormElementRadio
                  fieldId={"79"}
                  value={dataForm.data_79}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"79"}
                  value={dataForm.data_79}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"80"}
                  value={dataForm.data_80}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>3. เบื่ออาหาร</td>
              <td>
                <FormElementRadio
                  fieldId={"83"}
                  value={dataForm.data_83}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"83"}
                  value={dataForm.data_83}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"84"}
                  value={dataForm.data_84}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>4. ไอ</td>
              <td>
                <FormElementRadio
                  fieldId={"21"}
                  value={dataForm.data_21}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"21"}
                  value={dataForm.data_21}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"22"}
                  value={dataForm.data_22}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>5. บวม</td>
              <td>
                <FormElementRadio
                  fieldId={"23"}
                  value={dataForm.data_23}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"23"}
                  value={dataForm.data_23}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"24"}
                  value={dataForm.data_24}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>6. หายใจหอบเหนื่อย</td>
              <td>
                <FormElementRadio
                  fieldId={"25"}
                  value={dataForm.data_25}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"25"}
                  value={dataForm.data_25}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"26"}
                  value={dataForm.data_26}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>7. อ่อนเพลีย</td>
              <td>
                <FormElementRadio
                  fieldId={"27"}
                  value={dataForm.data_27}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"27"}
                  value={dataForm.data_27}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"28"}
                  value={dataForm.data_28}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>8. การนอนหลับ</td>
              <td>
                <FormElementRadio
                  fieldId={"29"}
                  value={dataForm.data_29}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"29"}
                  value={dataForm.data_29}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"30"}
                  value={dataForm.data_30}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>9. การขับถ่าย (ท้องผูก)</td>
              <td>
                <FormElementRadio
                  fieldId={"31"}
                  value={dataForm.data_31}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"31"}
                  value={dataForm.data_31}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"32"}
                  value={dataForm.data_32}
                  noLimit
                  onChange={handleChange}
                  rows={2}
                  cols={50}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementTitle label="ประเมินทั่วไป" fontWeight="bold" />
      </div>
      <div className="uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>หัวข้อ</th>
              <th>ปัญหาที่พบ</th>
              <th>ข้อเสนอแนะ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>1. สภาพแวดล้อมที่พัก</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"33"}
                      value={dataForm.data_33}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - ดูเรียบร้อย ไม่มีขยะ ไม่มีฝุ่นละอองฟุ้ง กระจาย ไม่มีสัตว์เลี้ยงภายในบ้าน"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"33"}
                      value={dataForm.data_33}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - มีขยะและฝุ่นบ้าง ไม่มีสัตว์เลี้ยงภายในบ้าน"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"33"}
                      value={dataForm.data_33}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - เต็มด้วยขยะ ฝุ่นละอองฟุ้งกระจาย รอบบ้านมีน้ำขัง"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"34"}
                  value={dataForm.data_34}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"35"}
                  value={dataForm.data_35}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>2. แหล่งน้ำใช้</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"36"}
                      value={dataForm.data_36}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - ใช้น้ำประปา ใช้น้ำที่ผลิตสำหรับดื่ม ถ้าเป็นประปาหมู่บ้านต้องใส ไร้กลิ่น"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"36"}
                      value={dataForm.data_36}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - ใช้น้ำฝนหรือน้ำบ่อที่ผ่านเครื่องกรองแล้ว"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"36"}
                      value={dataForm.data_36}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - ใช้น้ำบ่อหรือน้ำคลอง น้ำมีลักษณะขุ่น หรือมีกลิ่น"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"37"}
                  value={dataForm.data_37}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"38"}
                  value={dataForm.data_38}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>3. ห้องน้ำ, ที่ล้างมือ, สบู่ล้างมือ</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"39"}
                      value={dataForm.data_39}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - สะอาด เป็นไปตามคำแนะนำ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"39"}
                      value={dataForm.data_39}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - สะอาดพอสมควร"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"39"}
                      value={dataForm.data_39}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - สกปรก เต็มไปด้วยตะไคร้น้ำ"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"40"}
                  value={dataForm.data_40}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"41"}
                  value={dataForm.data_41}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>4. ห้องที่ผู้ป่วยพัก</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - ไม่คับแคบ สะอาด ไม่มีกลิ่น เก็บของเรียบร้อย อากาศถ่ายเทดี"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - คับแคบ ค่อนข้างสะอาด และมีหยากไย่บ้าง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - สกปรก รกรุงรัง มีหยากไย่ทั่วๆห้อง"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"43"}
                  value={dataForm.data_43}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"44"}
                  value={dataForm.data_44}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>

            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>5. สถานที่เปลี่ยนน้ำยา</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"45"}
                      value={dataForm.data_45}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - สะอาด มีพื้นที่กว้างขวาง ไม่อับชื้น มีแสงสว่างเพียงพอ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"45"}
                      value={dataForm.data_45}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - มีพื้นที่พอสมควร ไม่สกปรก มีหยากไย่บ้าง แสงสว่างเพียงพอ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"45"}
                      value={dataForm.data_45}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - คับแคบ สกปรก แสงสว่างไม่เพียงพอ"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"46"}
                  value={dataForm.data_46}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"47"}
                  value={dataForm.data_47}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>6. สถานที่เก็บน้ำยาถุงใหม่</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"48"}
                      value={dataForm.data_48}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - ไม่โดนแดด ไม่อับชื้น มีการวางกล่องน้ำยาอย่างมีระเบียบ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"48"}
                      value={dataForm.data_48}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - รกรุงรัง น้ำยาจัดวางไม่เป็นระเบียบ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"48"}
                      value={dataForm.data_48}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - โดดแดด หรือชื้นแฉะ"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"49"}
                  value={dataForm.data_49}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"50"}
                  value={dataForm.data_50}
                  noLimit
                  onChange={handleChange}
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>7. อุปกรณ์ในการเปลี่ยนน้ำยาของผู้ป่วย</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"51"}
                      value={dataForm.data_51}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - สะอาด อุปกรณ์ครบ เป็นระเบียบ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"51"}
                      value={dataForm.data_51}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - อุปกรณ์ครบแต่ไม่เป็นระเบียบ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"51"}
                      value={dataForm.data_51}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - อุปกรณ์ไม่ครบ"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"52"}
                  value={dataForm.data_52}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"53"}
                  value={dataForm.data_53}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>8. การเปลี่ยนน้ำยาของผู้ป่วย</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"54"}
                      value={dataForm.data_54}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - ล้างมือถูกต้องเปลี่ยนอย่างคล่องแคล่ว"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"54"}
                      value={dataForm.data_54}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - ล้างมือถูกต้อง มีการใส่ mask เปลี่ยนน้ำยาเงอะงะ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"54"}
                      value={dataForm.data_54}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - ไม่ใส่ mask ไม่ทำความสะอาดโต๊ะและอุปกรณ์"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"55"}
                  value={dataForm.data_55}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"56"}
                  value={dataForm.data_56}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>9. วิธีกำจัดถุงน้ำยาที่ใช้แล้ว</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"57"}
                      value={dataForm.data_57}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - เทน้ำยาที่ใช้แล้วทิ้งในส้วม เก็บถุงน้ำยา ทิ้งขยะ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"57"}
                      value={dataForm.data_57}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - เทน้ำยาที่ใช้แล้วในส้วม ทิ้งถุงเปล่า ไม่เป็นที่ทาง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"57"}
                      value={dataForm.data_57}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - ไม่เทน้ำยา ทิ้งลงถังขยะทั้งถุง"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"58"}
                  value={dataForm.data_58}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"59"}
                  value={dataForm.data_59}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>10. จดบันทึกการล้างไต</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"60"}
                      value={dataForm.data_60}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - จดบันทึกอย่างมีระเบียบหลังเปลี่ยนน้ำยา"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"60"}
                      value={dataForm.data_60}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - จดบันทึกทันที หลังเปลี่ยนน้ำยา"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"60"}
                      value={dataForm.data_60}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - ไม่จดบันทึก หรือบันทึกภายหลัง"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"61"}
                  value={dataForm.data_61}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"62"}
                  value={dataForm.data_62}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>11. การเก็บรักษายาที่ใช้รับประทาน</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"63"}
                      value={dataForm.data_63}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - เก็บยาไว้ในตู้หรือกล่องอย่างมีระเบียบ ไม่โดนแดดส่อง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"63"}
                      value={dataForm.data_63}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - เก็บยาไว้ใช้ตามชั้นวางของแต่ยังอยู่ในถุงเดียวกัน"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"63"}
                      value={dataForm.data_63}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - วางยาไว้กระจัดกระจาย ขอดูยาแล้วแจ้งว่าหาไม่เจอ"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"64"}
                  value={dataForm.data_64}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"65"}
                  value={dataForm.data_65}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>12. การเก็บรักษายาฉีด</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"66"}
                      value={dataForm.data_66}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - เก็บยาไว้ในตู้เย็นอย่างมีระเบียบ ไม่โดนแดดส่อง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"66"}
                      value={dataForm.data_66}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - วางยาไว้กระจัดกระจาย เก็บยาไว้บริเวณฝาตู้เย็น"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"66"}
                      value={dataForm.data_66}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - เก็บยาไว้ที่อุณหภูมิห้อง"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"67"}
                  value={dataForm.data_67}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"68"}
                  value={dataForm.data_68}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>13. การประเมินด้านจิตใจของผู้ป่วย</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"69"}
                      value={dataForm.data_69}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - รู้สึกดี กระปรี้กระเปร่า กระตือรือร้น มีกำลังใจ"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"69"}
                      value={dataForm.data_69}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - รู้สึกดี กระปรี้กระเปร่าบางครั้ง ท้อแท้เบื่อหน่ายบางครั้ง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"69"}
                      value={dataForm.data_69}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - รู้สึกท้อแท้เบื่อหน่ายบ่อยครั้ง"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"70"}
                  value={dataForm.data_70}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"71"}
                  value={dataForm.data_71}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div uk-grid="" className="uk-flex uk-flex-column">
                  <div className="uk-width-1-1">
                    <strong>14. การประเมินครอบครัวของผู้ป่วย</strong>
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"72"}
                      value={dataForm.data_72}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="ดี - ครอบครัวให้การสนับสนุน และช่วยเหลือให้กำลังใจเป็นอย่างดี"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"72"}
                      value={dataForm.data_72}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="พอใช้ - ครอบครัวให้การสนับสนุนและช่วยเหลือบ้าง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"72"}
                      value={dataForm.data_72}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="ปรับปรุง - ครอบครัวไม่ให้การช่วยเหลือเลย"
                    />
                  </div>
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"73"}
                  value={dataForm.data_73}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"74"}
                  value={dataForm.data_74}
                  onChange={handleChange}
                  noLimit
                  rows={5}
                  cols={50}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"75"}
          value={dataForm.data_75}
          label="หมายเหตุ"
          onChange={handleChange}
          noLimit
          rows={5}
          cols={150}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"76"}
          value={dataForm.data_76}
          label="ลงชื่อผู้ตรวจเยี่ยม"
          onChange={handleChange}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"77"}
          value={dataForm.data_77}
          label="ลงชื่อผู้ได้รับการตรวจเยี่ยม"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default FormHomeVisit;
