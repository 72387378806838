import React, { useState, useEffect } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import FormPaper from "./FormPaper";
import "../../css/dietz.css";

UIkit.use(Icons);

const PopupForm = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataListOrg, setDataListOrg] = useState([]);
  const [dataList, setDataList] = useState([]);
  const { show, formType, title, dataForm } = props;

  function doSavePopup(data) {
    props.onClose(data);
  }

  function onClose() {
    props.onClose();
  }

  useEffect(() => {
    //listGet();
    //console.log(show);
  }, [show]);

  // useEffect(() => {
  //   if (show) {
  //       UIkit.modal('#modal-select-clinic').show();

  //   } else {
  //       UIkit.modal('#modal-select-clinic').hide();
  //   }

  // }, [ show ]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <>
      {show ? (
        <div className="form-modal-container">
          <div className="form-modal-dialog-container">
            <div className="form-modal-dialog-header">
              <h5 className="uk-modal-title">{title || ""}</h5>
              <div className="form-modal-right-header">
                <button
                  className="uk-button-default form-modal-close-button"
                  type="button"
                  onClick={onClose}
                >
                  {" "}
                  <span uk-icon="icon: close; ratio: 1"></span>
                </button>
              </div>
            </div>
            <div className="form-modal-dialog-body">
              <FormPaper
                hn={props.hn}
                txn={props.txn}
                hideSaveBtn={false}
                hidePreviewBtn={true}
                type={formType}
                doSave={doSavePopup}
                dataForm={dataForm}
                isPopup={true}
                formId={props.formId || props.hn || props.txn}
              />
            </div>
            <div className="form-modal-dialog-footer uk-text-right">
              <button
                className="uk-button uk-button-default uk-modal-close"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PopupForm;
