import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe'
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import FormPaper from "../../Components/Consult/FormPaper";
import moment from 'moment';

UIkit.use(Icons);

const FormAdd = (props) => {
  
  const query = new URLSearchParams(props.location.search);
  const formType = query.get('formType');
  const formName = query.get('formName');
  const hn = query.get('hn');
  const txn = query.get('txn');
  const formId = query.get('formId');

  return (
     <FormPaper type={formType} hn={hn} formName={formName} txn={txn} formId={formId}  isPopup={false} />
  )
}

export default FormAdd;