import React, { useState, useEffect, useCallback } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import { data, event } from "jquery";
import dateFormat from "dateformat";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormDeliveryWafarineMedicine = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [drugList, setDrugList] = useState([]);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_deliverywafarinemedicine",
      formType: "29",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  async function getDataDrug() {
    var result = await Api.consult("GetDrugByTxn", {
      hn: props.hn,
      txn: props.txn,
      patientType: "o",
    });

    var { list } = result;
    let drugList = [];

    if (list.data.length > 0) {
      setDrugList(list.data[0]);

      for (const drug of list.data[0]) {
        if (drug.tele_drug_type != "") {
          drugList.push(drug.tele_drug_type);
        }
      }
    } else {
      setDrugList([]);
    }
  }

  useEffect(() => {
    getDataDrug();
  }, [props.hx, props.txn]);

  useEffect(() => {
    if (drugList?.length && drugList?.length > 0) {
      setData("62", drugList[0].paper_id);
    }
  }, [drugList]);

  useEffect(() => {
    var consultProfileDataStorage = localStorage.getItem("consultProfileData");
    var consultProfileData = JSON.parse(consultProfileDataStorage);
    if (dataForm.data_61?.length && dataForm.data_61?.length > 0) {
      const data = dataForm.data_61;
      data[data.length - 1].data_2 = consultProfileData.doctorName;
      setData("61", data);
    }
  }, [localStorage.getItem("consultProfileData"), dataForm.data_61?.length]);

  useEffect(() => {
    if (!dataForm.data_57) {
      setData("57", dataForm.patient_name);
    }
  }, [dataForm.patient_name]);

  useEffect(() => {
    if (!dataForm.data_58) {
      setData("58", "1");
    }
    if (!dataForm.data_59) {
      setData("59", props.profile.doctorName);
    }
  }, [props]);

  const handleData61ValueChange = useCallback((fieldName, dataIndex, event) => {
    if (typeof dataForm.data_61 == "undefined") {
      return;
    }
    if (event != null && typeof event.target != "undefined") {
      dataForm.data_61[dataIndex][fieldName] = event.target.value;
      setData("61", dataForm.data_61);
    } else {
      dataForm.data_61[dataIndex][fieldName] = event;
      setData("61", dataForm.data_61);
    }
  });

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" 3.3.3 แบบฟอร์มการติดตามการใช้ยาวาร์ฟารินที่จัดส่งทางไปรษณีย์ " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"63"}
          onChange={handleChange}
          width="100"
          label="วิธีการให้คำแนะนำยา"
          cols={120}
          rows={5}
          value={dataForm.data_63}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          label="ติดตามใบสั่งยาเลขที่"
          fieldId="62"
          value={dataForm.data_62}
          onChange={handleChange}
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 1 ข้อมูลพื้นฐานผู้ป่วย" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <tbody>
            <tr>
              <th className="center">รายการยาที่ติดตาม</th>
              <td colSpan={3}>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementRadio
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ห้องตรวจ "
                  />
                  <span
                    className={
                      dataForm.data_1 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"2"}
                      value={dataForm.data_2}
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <FormElementRadio
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" Refill ยา "
                  />
                </div>
              </td>
              <th className="center">วันที่แพทย์สั่งยา</th>
              <td colSpan={3}>
                <FormElementTextBox
                  fieldId={"3"}
                  value={dataForm.data_3}
                  type="date"
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th
                rowSpan={
                  dataForm?.data_61?.length ? dataForm?.data_61?.length + 2 : 2
                }
                className="center"
              >
                การโทรติดตามโดย เภสัชกร
              </th>
              <th
                rowSpan={2}
                className="center"
                style={{ whiteSpace: "nowrap" }}
              >
                ครั้งที่
              </th>
              <th
                rowSpan={2}
                className="center"
                style={{ whiteSpace: "nowrap" }}
              >
                ว/ด/ป, เวลา
              </th>
              <th rowSpan={2} className="center">
                ผู้โทรติดตาม
              </th>
              <th colSpan={4} className="center">
                ผลการติดตาม
              </th>
            </tr>
            <tr>
              <th>สำเร็จ</th>
              <th>ไม่สำเร็จ</th>
              <th>ยกเลิกการติดตาม</th>
              <th>หมายเหตุ</th>
            </tr>
            {props.isFetchDone &&
              dataForm?.data_61?.map((item, index) => {
                return (
                  <tr>
                    <td>{item.key}</td>
                    <td>
                      <FormElementTextBox
                        fieldId={"1"}
                        value={item.data_1}
                        type="date"
                        withTime
                        dateFormat={"dd/MM/yyyy, HH:mm"}
                        onChange={(i, e) =>
                          handleData61ValueChange("data_1", index, e)
                        }
                        width="100"
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"2"}
                        value={item.data_2}
                        label=""
                        onChange={(i, e) =>
                          handleData61ValueChange("data_2", index, e)
                        }
                        width="100"
                      />
                    </td>
                    <td className="td-radio-input">
                      <FormElementRadio
                        fieldId={`radio-3-${index}`}
                        value={item.data_3}
                        fieldValue={"1"}
                        onChange={(i, e) =>
                          handleData61ValueChange("data_3", index, e)
                        }
                      />
                    </td>
                    <td className="td-radio-input">
                      <FormElementRadio
                        fieldId={`radio-3-${index}`}
                        value={item.data_3}
                        fieldValue={"2"}
                        onChange={(i, e) =>
                          handleData61ValueChange("data_3", index, e)
                        }
                      />
                    </td>
                    <td className="td-radio-input">
                      <FormElementRadio
                        fieldId={`radio-3-${index}`}
                        value={item.data_3}
                        fieldValue={"3"}
                        onChange={(i, e) =>
                          handleData61ValueChange("data_3", index, e)
                        }
                      />
                    </td>
                    <td className="td-remark">
                      <FormElementTextBox
                        fieldId={"4"}
                        value={item.data_4}
                        onChange={(i, e) =>
                          handleData61ValueChange("data_4", index, e)
                        }
                        width="100"
                      />
                    </td>
                  </tr>
                );
              })}

            {/* <tr>
              <td>2</td>
              <td>
                <FormElementTextBox
                  fieldId={"8"}
                  value={dataForm.data_8}
                  type="date"
                  withTime
                  dateFormat={"dd/MM/yyyy, HH:mm"}
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"9"}
                  value={dataForm.data_9}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"10"}
                  value={dataForm.data_10}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"10"}
                  value={dataForm.data_10}
                  fieldValue={"2"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"10"}
                  value={dataForm.data_10}
                  fieldValue={"3"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-remark">
                <FormElementTextBox
                  fieldId={"11"}
                  value={dataForm.data_11}
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <FormElementTextBox
                  fieldId={"12"}
                  value={dataForm.data_12}
                  type="date"
                  withTime
                  dateFormat={"dd/MM/yyyy, HH:mm"}
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"13"}
                  value={dataForm.data_13}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"14"}
                  value={dataForm.data_14}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"14"}
                  value={dataForm.data_14}
                  fieldValue={"2"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-radio-input">
                <FormElementRadio
                  fieldId={"14"}
                  value={dataForm.data_14}
                  fieldValue={"3"}
                  onChange={handleChange}
                />
              </td>
              <td className="td-remark">
                <FormElementTextBox
                  fieldId={"15"}
                  value={dataForm.data_15}
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr> */}
            <tr>
              <th className="center">ผู้ป่วยได้รับพัสดุ</th>
              <td colSpan={7}>
                <div className="uk-width-1-1@m uk-width-1-1 uk-display-inline">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <FormElementRadio
                        fieldId={"16"}
                        value={dataForm.data_16}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ไม่ได้รับพัสดุ "
                      />
                      <span hidden={dataForm.data_16 != "2"}>
                        <span className="formTextBoxLabel">
                          <FormElementTextBox
                            fieldId={"18"}
                            value={dataForm.data_18}
                            label=""
                            onChange={handleChange}
                            width="100"
                            placeholder="เนื่องจาก"
                          />
                        </span>
                      </span>
                    </div>

                    <FormElementRadio
                      fieldId={"16"}
                      value={dataForm.data_16}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ได้รับพัสดุ"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        paddingLeft: "20px",
                      }}
                      hidden={dataForm.data_16 != "1"}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <FormElementRadio
                          fieldId={"17"}
                          value={dataForm.data_17}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ครบถ้วนถูกต้องตามใบรายการยา "
                        />
                        <span hidden={dataForm.data_17 != "1"}>
                          <FormElementTextBox
                            fieldId={"60"}
                            value={dataForm.data_60}
                            onChange={handleChange}
                            width="100"
                            placeholder="จำนวนรายการยา"
                            type="numeric"
                            maxLength={10}
                          />
                        </span>
                      </div>
                      <FormElementRadio
                        fieldId={"17"}
                        value={dataForm.data_17}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ไม่ครบหรือไม่ถูกต้องตามใบรายการยา "
                      />
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-left"></div>
                {/* <div
                  className="uk-width-1-1@m uk-width-1-1 uk-display-inline"
                  style={{ marginTop: "14px" }}
                >
                  <span
                    className={
                      dataForm.data_16 == "1" ? "form-show" : "form-hidden"
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormElementRadio
                      fieldId={"17"}
                      value={dataForm.data_17}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ครบถ้วนถูกต้องตามใบรายการยา "
                    />
                    <FormElementRadio
                      fieldId={"17"}
                      value={dataForm.data_17}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" ไม่ครบหรือไม่ถูกต้องตามใบรายการยา "
                    />
                  </span>
                </div> */}
                {/* <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ marginTop: "14px" }}
                >
                  <FormElementRadio
                    fieldId={"16"}
                    value={dataForm.data_16}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ไม่ได้รับพัสดุ "
                  />
                  <span
                    className={
                      dataForm.data_16 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <span className="formTextBoxLabel">
                      <FormElementTextBox
                        fieldId={"18"}
                        value={dataForm.data_18}
                        label=""
                        onChange={handleChange}
                        width="100"
                        placeholder="เนื่องจาก"
                      />
                    </span>
                  </span>
                </div> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 2 ข้อมูลการใช้ยา warfarin" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider">
          <tbody>
            <tr>
              <th className="center">Indication Warfarin</th>
              <th className="center">Target INR</th>
              <th className="center" rowSpan={2}>
                Lab INR ล่าสุด
              </th>
              <td rowSpan={2}>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementTextBox
                    fieldId={"19"}
                    value={dataForm.data_19}
                    label="INR"
                    onChange={handleChange}
                    width="100"
                  />
                  <FormElementTextBox
                    fieldId={"20"}
                    value={dataForm.data_20}
                    type="date"
                    onChange={handleChange}
                    width="100"
                  />
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  <FormElementRadio
                    fieldId={"21"}
                    value={dataForm.data_21}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" Lab รพ.สวนดอก "
                  />
                  <FormElementRadio
                    fieldId={"21"}
                    value={dataForm.data_21}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" Lab นอกรพ. "
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <FormElementTextArea
                  fieldId={"22"}
                  value={dataForm.data_22}
                  onChange={handleChange}
                  width="100"
                  cols={40}
                  noLimit
                />
              </td>
              <td>
                {" "}
                <FormElementTextBox
                  fieldId={"23"}
                  value={dataForm.data_23}
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 3 ข้อมูลความร่วมมือในการใช้ยาและอาการไม่พึงประสงค์" />
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <tbody>
            <tr>
              <th>ขนาดยาที่ได้รับทางไปรษณีย์</th>
              <th>ขนาดยา Warfarin ที่ผู้ป่วยรับประทานจริง</th>
            </tr>
            <tr>
              <td>
                <div style={{ display: 'flex', flexDirection: 'column', gap: "8px" }}>
                  <div>
                    <FormElementTextArea
                      fieldId={"64"}
                      value={dataForm.data_64}
                      label=""
                      onChange={handleChange}
                      cols={40}
                      noLimit
                    />
                  </div>
                  <div>
                    <span className="formTextBoxLabel">(Total</span>
                    <FormElementTextBox
                      fieldId={"24"}
                      value={dataForm.data_24}
                      label=""
                      type="numeric"
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">mg/wk)</span>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <FormElementRadio
                    fieldId={"25"}
                    value={dataForm.data_25}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" ตามระบุในใบสรุปรายการยา"
                  />
                </div>
                <div
                  style={{ marginTop: "14px" }}
                >
                  <FormElementRadio
                    fieldId={"25"}
                    value={dataForm.data_25}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" ไม่ตรงตามระบุ ดังนี้"
                  />
                  <span
                    className={
                      dataForm.data_25 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <FormElementTextBox
                      fieldId={"26"}
                      value={dataForm.data_26}
                      type="numeric"
                      label="(Total"
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">mg/wk)</span>
                  </span>
                </div>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ marginLeft: "30px" }}
                >
                  <span
                    className={
                      dataForm.data_25 == "2" ? "form-show" : "form-hidden"
                    }
                  >
                    <div className="uk-width-1-1">
                      <FormElementRadio
                        fieldId={"27"}
                        value={dataForm.data_27}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มีปรับยาจากรพ.อื่น/คลินิก"
                      />
                      <span
                        className={
                          dataForm.data_27 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <span className="formTextBoxLabel">เมื่อวันที่</span>
                        <FormElementTextBox
                          fieldId={"28"}
                          value={dataForm.data_28}
                          type="date"
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">ปรับเป็น</span>
                        <FormElementTextBox
                          fieldId={"29"}
                          value={dataForm.data_29}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </div>
                    <div className="uk-width-1-1">
                      <FormElementRadio
                        fieldId={"27"}
                        value={dataForm.data_27}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" อื่นๆ"
                      />
                      <span
                        className={
                          dataForm.data_27 == "2" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"30"}
                          value={dataForm.data_30}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </div>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>ความร่วมมือในการใช้ยา</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" กินยาถูกต้องตามฉลากยาที่ระบุ"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" ขาดยาลืมกินยา"
                    />
                  </div>
                  <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementRadio
                      fieldId={"31"}
                      value={dataForm.data_31}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label=" กินยาไม่ตรงตามฉลากยาที่ระบุ"
                    />
                    <span
                      className={
                        dataForm.data_31 == "3" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"32"}
                        value={dataForm.data_32}
                        label="เนื่องจาก"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>การใช้ สมุนไพร อาหารเสริม หรือยาอื่นๆ</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"33"}
                      value={dataForm.data_33}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=" ไม่มี"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"33"}
                      value={dataForm.data_33}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=" มี "
                    />
                    <span
                      className={
                        dataForm.data_33 == "2" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"34"}
                        value={dataForm.data_34}
                        onChange={handleChange}
                        label="ยา"
                      />
                      <span
                        className={
                          dataForm.data_34 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"35"}
                          value={dataForm.data_35}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementCheckBox
                        fieldId={"36"}
                        value={dataForm.data_36}
                        onChange={handleChange}
                        label="อาหาร/อาหารเสริม"
                      />
                      <span
                        className={
                          dataForm.data_36 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"37"}
                          value={dataForm.data_37}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementCheckBox
                        fieldId={"38"}
                        value={dataForm.data_38}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label="สมุนไพร"
                      />
                      <span
                        className={
                          dataForm.data_38 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"39"}
                          value={dataForm.data_39}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementCheckBox
                        fieldId={"40"}
                        value={dataForm.data_40}
                        onChange={handleChange}
                        label="แอลกอฮอล์ "
                      />
                      <span
                        className={
                          dataForm.data_40 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"41"}
                          value={dataForm.data_41}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>อาการไม่พึงประสงค์จากยา</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1">
                    <FormElementRadio
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={"0"}
                      onChange={handleChange}
                      label="ไม่มี"
                    />
                    <FormElementRadio
                      fieldId={"42"}
                      value={dataForm.data_42}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="มี"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"43"}
                        value={dataForm.data_43}
                        onChange={handleChange}
                        label=" ภาวะเลือดออกไม่รุนแรง (มีจ้ำเลือดตามผิวหนัง เลือดกำเดาไหล เลือดออกตามไรฟันมากผิดปกติ)"
                      />
                    </span>
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"44"}
                        value={dataForm.data_44}
                        onChange={handleChange}
                        label=" ภาวะเลือดออกที่รุนแรง (ไอเป็นเลือด หรือปัสสาวะ/อุจจาระมีสีดำ ปวดศรีษะมากและมีเลือดออกในสมอง)"
                      />
                    </span>
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"45"}
                        value={dataForm.data_45}
                        onChange={handleChange}
                        label=" แขนขาอ่อนแรงชาครึ่งซีก หน้าเบี้ยว ปากเบี้ยว พูดไม่ออก พูดไม่ชัด"
                      />
                    </span>
                  </div>
                  <div className="uk-width-1-1">
                    <span
                      className={
                        dataForm.data_42 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementCheckBox
                        fieldId={"46"}
                        value={dataForm.data_46}
                        onChange={handleChange}
                        label=" ADR/อาการผิดปกติอื่นๆ "
                      />
                      <span
                        className={
                          dataForm.data_46 == "1" ? "form-show" : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"47"}
                          value={dataForm.data_47}
                          label=" "
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>การจัดการเบื้องต้น</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div uk-grid="" className="uk-grid">
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"48"}
                      value={dataForm.data_48}
                      onChange={handleChange}
                      label=" แนะนำให้หลีกเลี่ยง ยากลุ่ม NSIADs,อาหาร,อาหารเสริม หรือสมุนไพรที่มีผลทำให้ระดับยาวาร์ฟารินในเลือดเปลี่ยนแปลง"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"49"}
                      value={dataForm.data_49}
                      onChange={handleChange}
                      label=" แนะนำให้สังเกตอาการเลือดออกผิดปกติหรือมีอาการผิดปกติให้มาพบแพทย์ก่อนนัด"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"50"}
                      value={dataForm.data_50}
                      onChange={handleChange}
                      label=" ส่งต่อข้อมูลการติดตาม (ส่วนที่4)"
                    />
                  </div>
                  <div className="uk-width-1-1">
                    <FormElementCheckBox
                      fieldId={"51"}
                      value={dataForm.data_51}
                      onChange={handleChange}
                      label=" อื่นๆ "
                    />
                    <span
                      className={
                        dataForm.data_51 == "1" ? "form-show" : "form-hidden"
                      }
                    >
                      <FormElementTextBox
                        fieldId={"52"}
                        value={dataForm.data_52}
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ส่วนที่ 4 ข้อมูลที่ต้องการส่งต่อ" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementCheckBox
          fieldId={"53"}
          value={dataForm.data_53}
          onChange={handleChange}
          label="แพทย์"
        />
        <FormElementCheckBox
          fieldId={"54"}
          value={dataForm.data_54}
          onChange={handleChange}
          label="เภสัชกร"
        />
        <FormElementCheckBox
          fieldId={"55"}
          value={dataForm.data_55}
          onChange={handleChange}
          label="พยาบาล/เจ้าหน้าที่ห้องตรวจ"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"56"}
          rows={5}
          cols={120}
          value={dataForm.data_56}
          onChange={handleChange}
          width="100"
          noLimit
        />
      </div>
      <div
        className="uk-width-1-1@m uk-width-1-1"
        style={{ display: "inline-flex", alignItems: "center", gap: "20px" }}
      >
        <div>
          <FormElementTextBox
            fieldId={"57"}
            value={dataForm.data_57}
            placeholder="ชื่อผู้รับการประเมิน"
            label=" ผู้รับการประเมิน (ผู้ป่วย/ญาติ)"
            onChange={handleChange}
            width="100"
          />
          <FormElementDropdown
            fieldId={"58"}
            value={dataForm.data_58}
            dataList={[
              { label: "เลือกผู้รับการประเมิน", value: "0" },
              { label: "ผู้ป่วย", value: "1" },
              { label: "ญาติ", value: "2" },
            ]}
            onChange={handleChange}
            width="100"
          />
        </div>
        <span>เภสัชกรผู้ประเมิน {dataForm.data_59 || ""}</span>
      </div>
    </div>
  );
};

export default FormDeliveryWafarineMedicine;
