import Api from "../../Components/Api";
import transformResponse from '../API/transformResponse';

async function fetchDrugDataByTxn(hn, txn) {
    try {
        var result = await Api.consult("GetDrugByTxn", {
            hn: hn,
            txn: txn,
            patientType: "o",
        });

        var { list } = result;
        let drugEnableList = [];

        if (list.data.length > 0) {
            for (const drug of list.data[0]) {
                if (drug.status == "0") {
                    drugEnableList.push(drug);
                }
            }
            return drugEnableList;
        } else {
            return [];
        }
    } catch (error) {
        console.error(`fetchDrugDataByTxn: hn(${hn}), txn(${txn})`, error);
        throw new Error(`Something went wrong.`);
    }
}

export default fetchDrugDataByTxn;