import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import SelectMemberCompanygroup from '../../Components/Member/SelectMemberCompanygroup';
import moment from 'moment';
import Rate from '../Rate';
import numeral from 'numeral';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class CampaignReportDietzRate extends Component {
  state = {
    selectMemberCompanyGroup: 'all',
    dietzGraph: [],
    dayGraph: []
  }

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var { dayGraph, dietzGraph } = await Api.member('CampaignReportDietzRate', { campaignId: this.props.id, groupId: this.state.selectMemberCompanyGroup });
    
    this.setState({
      dietzGraph: dietzGraph ? dietzGraph.reverse() : [],
      dayGraph: dayGraph ? dayGraph.reverse() : []
    })
  }

  graphRender = () => {
    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        data: ['Dietz Rate']
      },

      // Enable drag recalculate
      calculable: true,

      // Horizontal axis
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: this.state.dayGraph.map(data => (moment(data).format('dddd'))),
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        type: 'value',
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      series: [
        {
          name: 'Dietz Rate',
          type: 'line',
          symbol: 'circle',
          symbolSize: 20,
          itemStyle: {
            color: '#ffa87d'
          },
          lineStyle: {
            color: '#ffa87d',
            width: 3
          },
          smooth: true,
          symbolSize: 20,
          data: this.state.dietzGraph.map((data, index) => {
            if (index === (this.state.dietzGraph.length - 1)) {
              return {
                value: data,
                symbol: `path://M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z`,
                symbolSize: 50,
                label: {
                  show: true,
                  position: 'top',
                  fontSize: 24
                }
              }
            }
            else {
              return data;
            }
          })
        }
      ]
    };

    return <ReactEcharts
      option={option}
      notMerge={true}
      style={{height: 400}}
      lazyUpdate={true}
    />
  }

  lastRateGet = () => {
    return this.state.dietzGraph[this.state.dietzGraph.length - 1] || 0;
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-campaign-dietz-rate">
      <div className="d-card-header">
        <div className="d-card-header-left">
          DIETZ RATE
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-campaign-dietz-rate" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        <SelectMemberCompanygroup id={this.props.id} callback={(result) => this.setState({ SelectMemberCompanygroup: result }, () => {
          this.dataGet();
        })} />
      </div>

      <div className="uk-padding uk-text-center">Avg Dietz Rate 
        {' '}
        <Rate readonly={true} initialRating={this.lastRateGet()} stop={5} />
        {' '}
        <span className="uk-text-success">{numeral(this.lastRateGet()).format('0.0')}/5</span></div>
      {this.graphRender()}
    </div>
  }
};
