import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import numeral from "numeral";
import moment from "moment";
import CampaignRenew from "../CampaignRenew.js";

UIkit.use(Icons);

export default class ConsultCampaignTable extends Component {
  state = {
    list: [],
    popupRenewShow: false,

    //For renew
    memberCampaignId: null,
    campaignId: null
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.listGet();
  };

  listGet = async () => {
    var _resposnse = await Api.member("MemberCampaignTableGet");

    this.setState({ list: _resposnse.list || [] });
  };

  render() {
    return (
      <div className="uk-card uk-card-default uk-margin-bottom uk-padding-small">
        <div class="d-card-header">
          <div class="d-card-header-left">ประวัติการเข้าร่วมแคมเปญ</div>
          <div class="d-card-header-right"></div>
        </div>
        {this.state.popupRenewShow === true || Api.queryString.refno ? <CampaignRenew show={this.state.popupRenewShow} apiType={"member"} campaignId={this.state.campaignId} closePopup={()=>this.setState({popupRenewShow: false}, ()=>this.listGet())} /> : null }
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-divider">
            <thead>
              <tr>
                <th>ชื่อแคมเปญ</th>
                <th>องค์กร</th>
                <th>ระยะเวลาแคมเปญ (วัน)</th>
                <th>วันที่เริ่ม</th>
                <th>วันที่สิ้นสุด</th>
                <th>ค่าใช้จ่าย</th>
                <th>สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length === 0 ? (
                <tr>
                  <td colSpan={"100%"} className="uk-text-center">
                    ไม่มีรายการ
                  </td>
                </tr>
              ) : (
                this.state.list.map((data, index) => {
                  
                  return (
                    <tr key={`campaignTableRow${index}`}>
                      <td>{data.campaignname}</td>
                      <td>{data.companyname}</td>
                      <td>{data.day}</td>
                      <td>{data.datestart}</td>
                      <td>{data.datestop}</td>
                      <td>{numeral(data.price).format("0,0.00")}</td>

                      <td>
                        {data.datestop === "0000-00-00"
                          ? ""
                          : (moment(data.datestop) > moment()
                              ? "ไม่หมดอายุ"
                              :  <div>
                                  หมดอายุ (ต่ออายุครั้งที่ {data.renew})
                                  <div className="uk-margin-small-top"><a onClick={()=>{
                                    this.setState({memberCampaignId: data.id, campaignId: data.campaignid, memberCampaignRenew: data.renew}, ()=>{
                                      this.setState({popupRenewShow: true})
                                    });
                                  }}>ต่ออายุ</a></div>
                                </div>)}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
