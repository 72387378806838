import React from 'react';

const FormElementLine = ({data}) => {
  return (
    <div className="uk-width-1-1@m uk-width-1-1">
        <hr  />
    </div>
  )
}

export default FormElementLine;
