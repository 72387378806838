import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from 'moment';
import ReactEcharts from 'echarts-for-react';

UIkit.use(Icons);

var MONTH = ['01','02','03','04','05','06','07','08','09','10','11','12'];
var MONTH_NAME = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']

export default class OnlineStat extends Component {
  state = {
    loadingData: true
  };

  data = {};

  componentDidMount() { 
    this.init();
  }

  init = () => {
    this.dataGet();
  };

  dataGet = async () => {

    var _use_year = this.props.year || moment().format('YYYY');

    var _response = await Api.consult("OnlineMemberTableGet", {
      year: _use_year,
      campaignId: this.props.campaignId || 'all'
    });
 

    var _data = {
      appointment: {},
      hiv: {},
      consultlogvideocall: {},
      memberlogvideocall: {},
      chat: {},
      member: {},
      consult: {},
      // consult_cumulative: {}
    };

    for(var i in _data) {
      MONTH.forEach(function(m) {
        _data[i][m] = 0;
      });
    }

    if(_response.appointment) {
      _response.appointment.forEach((a) => {
        if(moment(a.dateadd).format('YYYY') === _use_year)
          _data.appointment[moment(a.dateadd).format('MM')]++;
      });
    }
    
    if(_response.hiv) {
      _response.hiv.forEach((h) => {
        if(moment(h.dateadd).format('YYYY') === _use_year)
        _data.hiv[moment(h.dateadd).format('MM')] += parseInt(h.amount);
      });
    }
    
    if(_response.consultlogvideocall) {
      _response.consultlogvideocall.forEach((l) => {
        if(moment(l.datelog).format('YYYY') === _use_year)
        _data.consultlogvideocall[moment(l.datelog).format('MM')] += parseInt(l.amount);
      });
    }
    
    if(_response.memberlogvideocall) {
      _response.memberlogvideocall.forEach((l) => {
        if(moment(l.datelog).format('YYYY') === _use_year)
          _data.memberlogvideocall[moment(l.datelog).format('MM')] += parseInt(l.amount);
      });
    }

    if(_response.chat) {
      _response.chat.forEach((l) => {
        if(moment(l.dateadd).format('YYYY') === _use_year)
          _data.chat[moment(l.dateadd).format('MM')]++;
      });
    }

    if(_response.member) {
      _response.member.forEach((l) => {
        
        if(moment(l.dateadd).format('YYYY') === _use_year)
          _data.member[moment(l.dateadd).format('MM')]++;
      });
    }

    if(_response.consult) {
      _response.consult.forEach((l) => {
        // _consult_cumulative_amount++;
        

        if(moment(l.dateadd).format('YYYY') === _use_year)
          _data.consult[moment(l.dateadd).format('MM')]++;
      });

      // var _consult_cumulative_amount = 0;

      
      // for(var month in _data.consult) {
      //   _consult_cumulative_amount += _data.consult[month]

      //   _data.consult_cumulative[month] += _consult_cumulative_amount;
      // }

    }

    this.data = _data;

    this.setState({loadingData: false});
  };

  row = [
    {
      label: 'จำนวนผู้ที่มีการทำนัด Telehealth',
      key: 'appointment'
    },
    {
      label: 'จำนวนผู้ตอบแบบประเมินตนเองก่อนพบแพทย์',
      key: 'hiv'
    },
    {
      label: 'จำนวนบุคลากรกดปุ่มวีดีโอคอล',
      key: 'consultlogvideocall'
    },
    {
      label: 'จำนวนคนไข้กดปุ่มวีดีโอคอล',
      key: 'memberlogvideocall'
    },
    {
      label: 'จำนวนคนไข้ที่สมัครใหม่',
      key: 'member'
    },
    {
      label: 'จำนวนบุคลากรทางการแพทย์ทั้งหมดที่ลงทะเบียน (นับจำนวน usernameที่สมัครใหม่ในแต่ละเดือน)',
      key: 'consult'
    },
    // {
    //   label: 'จำนวนบุคลากรทางการแพทย์สะสม',
    //   key: 'consult_cumulative'
    // },
    {
      label: 'จำนวนการแชท',
      key: 'chat'
    },
  ]

  rowRender = ({index, key, label}) => {
    var _sum = 0;


    MONTH.forEach((m) => {
      _sum += parseFloat(this.data[key][m]);
    });

    return <tr>
      <td>{label}</td>
      <td>{_sum}</td>
      {MONTH.map((m) => {
        
        return <td>{this.data[key][m]}</td>
      })}
    </tr>
  }
  
  graphRender = () => {
    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: this.row.map((data,index) => { return data.label; })
      },

      // Enable drag recalculate
      calculable: true,

      // Horizontal axis
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: MONTH_NAME,
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        type: 'value',
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      series: this.row.map((data,index) => {
        return {
          legend: true,
          name: data.label,
          type: 'line',
          symbol: 'circle',
          smooth: true,
          symbolSize: 5,
          data: MONTH.map((m) => {
            return this.data[data.key][m]
          })
        }
      })
    };

    return <ReactEcharts
      option={option}
      notMerge={true}
      style={{height: 400}}
      lazyUpdate={true}
    />
  }


  CsvExport = () => {
    var _csv = [];


    var _header = ['""','"รวม"'];

    MONTH_NAME.forEach((m) => {

      _header.push(`"${m}"`)
    });

    _csv.push([_header.join(',')])


    this.row.forEach((data)=>{

      var _row_data = [];
      
      var _sum = 0;

      MONTH.forEach((m) => {
        _sum += parseFloat(this.data[data.key][m]);

        _row_data.push(`"${this.data[data.key][m]}"`)
      });

      _row_data.unshift(_sum);

      _row_data.unshift(`"${data.label}"`);

      _csv.push([_row_data.join(',')]);
    });

    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF"+_csv.join('\r\n')));
    a.setAttribute('download', 'แอพพลิเคชัน.csv');
    window.document.body.appendChild(a);
    a.click();
  }
  
  render() {
    return (
      <div className="uk-card uk-card-default uk-margin-bottom uk-padding-small">
        <div class="d-card-header">
          <div class="d-card-header-left">แอพพลิเคชัน</div>
          <div class="d-card-header-right">
          <button className='uk-button d-text-white' onClick={this.CsvExport}><span uk-icon="download"></span> Export CSV</button>
          </div>
        </div>
        <div>
          {
            this.state.loadingData === true ?
            <div><span uk-spinner=""></span></div>
            :
            <div className="uk-overflow-auto">
              {this.graphRender()}
              <table className="uk-table uk-table-divider uk-table-small d-table-border-vertical uk-text-center d-border">
                <thead>
                  <tr>
                  <th>
                  </th>
                  <th className="uk-text-center">รวม</th>
                  {
                    MONTH_NAME.map((m)=>{
                      return <th className="uk-text-center">{m}</th>
                    })
                  }
                  </tr>
                </thead>
                <tbody>
                {this.row.map((data, index)=>{
                  return this.rowRender({
                    index: index,
                    key: data.key,
                    label: data.label
                  });
                })}
                </tbody>
              </table>
            </div>

          }
          
        </div>
      </div>
    );
  }
}
