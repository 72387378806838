import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementButton from "../Form/FormElementButton";

var utilGetInputValue = require("../FormType/FormUtil.js").getInputValue;

const SearchTeleSection = (props) => {
  var [searchStartDate, setSearchStartDate] = useState(null);
  var [searchEndDate, setSearchEndDate] = useState(null);
  var [searchSeqNo, setSearchSeqNo] = useState("");

  const onSearch = useCallback(() => {
    const searchData = {
      startDate: searchStartDate,
      endDate: searchEndDate,
      seqNo: searchSeqNo,
    };
    props.onSearch(searchData);
  });

  const onClear = useCallback(() => {
    setSearchStartDate(null);
    setSearchEndDate(null);
    setSearchSeqNo("");
    if (props.onClear) {
      props.onClear();
    }
  });

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <FormElementTextBox
                label="วันที่เริ่มต้น"
                onChange={(i, event) =>
                  setSearchStartDate(utilGetInputValue(event))
                }
                width="100"
                type="date"
                value={searchStartDate}
              />
            </td>
            <td>
              <FormElementTextBox
                label="วันที่สิ้นสุด"
                minDate={searchStartDate}
                onChange={(i, event) =>
                  setSearchEndDate(utilGetInputValue(event))
                }
                width="100"
                type="date"
                value={searchEndDate}
              />
            </td>
            <td>
              <FormElementTextBox
                label="ครั้งที่"
                onChange={(i, event) =>
                  setSearchSeqNo(utilGetInputValue(event))
                }
                maxLength={10}
                width="100"
                type="numeric"
                value={searchSeqNo}
              />
            </td>
            <td>
              <FormElementButton label="ค้นหา" onClick={onSearch} />
            </td>
            <td>
              <FormElementButton
                label="ยกเลิก"
                type="danger"
                onClick={onClear}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SearchTeleSection;
