import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementIconButton from "../Form/FormElementIconButton";
import FormElementButton from "../Form/FormElementButton";

const SOAPSection = (props) => {
  const { data, onChange } = props;

  const [currentForm, setCurrentForm] = useState({
    s: "",
    o: "",
    a: "",
    p: "",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [rowSelected, setRowSelected] = useState(undefined);

  const handleCurrentFormChange = useCallback((id, value) => {
    setCurrentForm({ ...currentForm, [id]: value });
  });

  const onAdd = useCallback(() => {
    setCurrentForm({ s: "", o: "", a: "", p: "" });
    setIsEditing(true);
  });

  useEffect(() => {
    console.log(data);
  }, [data]);

  const onSave = useCallback(() => {
    if (data) {
      if (rowSelected != undefined) {
        const saveData = data;
        saveData[rowSelected] = { ...currentForm };
        onChange(saveData);
      } else {
        const saveData = data;
        saveData.push({ ...currentForm });
        onChange(saveData);
      }
    } else {
      onChange([{ ...currentForm }]);
    }
    setIsEditing(false);
    setRowSelected(undefined);
    setCurrentForm({ s: "", o: "", a: "", p: "" });
  });

  const onCancel = useCallback(() => {
    setIsEditing(false);
    setRowSelected(undefined);
    setCurrentForm({ s: "", o: "", a: "", p: "" });
  });

  const onDelete = useCallback((index) => {
    const saveData = data?.filter((item, i) => index != i);
    onChange(saveData);
    setIsEditing(false);
    setRowSelected(undefined);
  });

  const onEdit = useCallback((form, index) => {
    setCurrentForm({ ...form });
    setRowSelected(index);
    setIsEditing(true);
  });

  const renderData = useMemo(() => {
    return (
      <>
        {data && (
          <div
            style={{
              display: "flex",
              gap: "8px",
              flexDirection: "column",
              padding: "8px",
              borderRadius: "8px",
              width: "fit-content",
            }}
          >
            {data?.map((item, i) => (
              <>
                <div style={{ display: "inline-flex", height: "50px" }}>
                  <span
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    ครั้งที่ {i + 1}
                  </span>
                  <span
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      {isEditing && rowSelected == i && (
                        <>
                          <FormElementIconButton
                            icon="close"
                            onClick={onCancel}
                          />
                          <FormElementIconButton
                            icon="check"
                            onClick={onSave}
                          />
                        </>
                      )}
                      {!(isEditing && rowSelected == i) && (
                        <>
                          <FormElementIconButton
                            icon="pencil"
                            onClick={() => onEdit(item, i)}
                          />
                          <FormElementIconButton
                            icon="trash"
                            onClick={() => onDelete(i)}
                          />
                        </>
                      )}
                    </div>
                  </span>
                </div>

                {isEditing && rowSelected == i && (
                  <>
                    <div>
                      <FormElementTextArea
                        label="S:"
                        value={currentForm.s}
                        rows={3}
                        cols={80}
                        noLimit
                        onChange={(i, e) =>
                          handleCurrentFormChange("s", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormElementTextArea
                        label="O:"
                        value={currentForm.o}
                        rows={3}
                        cols={80}
                        noLimit
                        onChange={(i, e) =>
                          handleCurrentFormChange("o", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormElementTextArea
                        label="A:"
                        value={currentForm.a}
                        rows={3}
                        cols={80}
                        noLimit
                        onChange={(i, e) =>
                          handleCurrentFormChange("a", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormElementTextArea
                        label="P:"
                        value={currentForm.p}
                        rows={3}
                        cols={80}
                        noLimit
                        onChange={(i, e) =>
                          handleCurrentFormChange("p", e.target.value)
                        }
                      />
                    </div>
                  </>
                )}
                {!(isEditing && rowSelected == i) && (
                  <>
                    <div>
                      <FormElementTextArea
                        label="S:"
                        value={item.s}
                        rows={3}
                        cols={80}
                        noLimit
                        disabled
                      />
                    </div>
                    <div>
                      <FormElementTextArea
                        label="O:"
                        value={item.o}
                        rows={3}
                        cols={80}
                        noLimit
                        disabled
                      />
                    </div>
                    <div>
                      <FormElementTextArea
                        label="A:"
                        value={item.a}
                        rows={3}
                        cols={80}
                        noLimit
                        disabled
                      />
                    </div>
                    <div>
                      <FormElementTextArea
                        label="P:"
                        value={item.p}
                        rows={3}
                        cols={80}
                        noLimit
                        disabled
                      />
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        )}
      </>
    );
  }, [{ ...data }, isEditing]);

  const renderAddingForm = useMemo(() => {
    if (isEditing && rowSelected == undefined) {
      return (
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            border: "1px solid #00b5b8",
            padding: "8px",
            borderRadius: "8px",
            width: "fit-content",
          }}
        >
          <div style={{ display: "inline-flex", height: "50px" }}>
            <span
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              ครั้งที่ {data ? data.length + 1 : 1}
            </span>
            <span
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div style={{ display: "inline-flex", gap: "8px" }}>
                <FormElementIconButton icon="close" onClick={onCancel} />
                <FormElementIconButton icon="check" onClick={onSave} />
              </div>
            </span>
          </div>
          <div>
            <FormElementTextArea
              label="S:"
              value={currentForm.s}
              rows={3}
              cols={80}
              noLimit
              onChange={(i, e) => handleCurrentFormChange("s", e.target.value)}
            />
          </div>
          <div>
            <FormElementTextArea
              label="O:"
              value={currentForm.o}
              rows={3}
              cols={80}
              noLimit
              onChange={(i, e) => handleCurrentFormChange("o", e.target.value)}
            />
          </div>
          <div>
            <FormElementTextArea
              label="A:"
              value={currentForm.a}
              rows={3}
              cols={80}
              noLimit
              onChange={(i, e) => handleCurrentFormChange("a", e.target.value)}
            />
          </div>
          <div>
            <FormElementTextArea
              label="P:"
              value={currentForm.p}
              rows={3}
              cols={80}
              noLimit
              onChange={(i, e) => handleCurrentFormChange("p", e.target.value)}
            />
          </div>
        </div>
      );
    }
    return null;
  }, [isEditing, rowSelected, data, handleCurrentFormChange, onCancel]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {renderData}
      {renderAddingForm}

      {!isEditing && rowSelected == undefined && (
        <FormElementButton label="เพิ่ม" onClick={onAdd} />
      )}
    </div>
  );
};

export default SOAPSection;
