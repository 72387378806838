import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from '../../Components/Api';
import $ from 'jquery';

UIkit.use(Icons);

export default class WorkHealthRecord extends Component {
  state = {
    company: {},
    profile: {},
    lab: {},
    campaign: [],
    campaignSelect: '',
    typeDataSelect: 'ตรวจสุขภาพก่อนเข้างาน'
  };

  typeData = [
    "ตรวจสุขภาพก่อนเข้างาน",
    "ตรวจสุขภาพประจำปี/ตรวจตามปัจจัยเสี่ยง",
    "ตรวจสุขภาพด้วยตนเองที่บ้าน",
    "ตรวจสุขภาพที่โรงพยาบาลเพื่อติดตามอาการ",
    "อื่นๆ"
  ]

  componentDidMount () {
    this.init();
  }


  init = () => {
    this.profileGet();
  }

  labGet = async () => {
    if(this.props.qs && this.props.qs.dateadd) {
      var _response = await Api.member("WorkHealthRecordGet", {dateadd: this.props.qs.dateadd, memberId: this.props.memberId || false});

      if(_response.data) {
        var _lab = {};

        _response.data.forEach((data)=>{
          _lab[data.name] = data;
        })


        this.setState({
          lab: _lab,
          typeDataSelect: _response.data[0].datatype,
          campaignSelect: _response.data[0].campaignid
        }, ()=>{
          if(this.props.qs.print === '1') this.printPrompt();
        });
      }

    }

  }

  profileGet = async () => {
    var { profile, company, group } = await Api.member("UserProfileCard", {memberId: this.props.memberId || false});


    
  };

  saveForm = async () => {
    var _response = await Api.member("WorkHealthRecordSave", {dataType: this.state.typeDataSelect, lab: this.state.lab, memberId: this.props.member.id});
    UIkit.notification(_response.responseText);

    if(!this.props.qs.dateadd && _response.result) window.location.href = "/work-health-record?dateadd=" + encodeURI(_response.dateadd);
  }

  setLabValue =(key, value) => {
    this.setState((state)=>{
      if(!state.lab[key]) state.lab[key] = {
        id: '',
        value: value,
        checkresult: '',
        checkplace: '',
        checknote: ''
      }

      state.lab[key].value = value;
      return {lab: state.lab};
    })
  }

  getForm = (name, option) => {

    if(!option) option = {};

    if(!option.style) option.style ={}

    if(!option.type) option.type = "text";

    if(option.type === 'textarea') {
      return <textarea className="uk-textarea"  style={option.style} onChange={(e)=>this.setLabValue(name, e.target.value)} value={this.state.lab[name] ? this.state.lab[name].value :  ''}></textarea>
    } else {
      return <input className="uk-input" type={option.type} style={option.style} onChange={(e)=>this.setLabValue(name, e.target.value)} value={this.state.lab[name]  ? this.state.lab[name].value : ''} />;
    }
  };

  
  printPrompt = () => {
    
    $("#d-print-container").remove();

    $("#root").prepend(
      $('<div id="d-print-container">').html([
        `<style>.uk-input, .uk-textarea{ border-width: 0 !important; resize: none; color: inherit; padding: 0 !important; } body { background: none !important;}</style>`,
        $(".d-work-health-record-container").html()
      ])
    );
    
    
    $("#d-print-container").css({display: 'none'});
    window.print();

    $("#d-print-container").html("");

    if(this.props.printCallback) this.props.printCallback();
  };


  render() {
    return (
      <div className="uk-padding">
        <div className="uk-margin-small-bottom uk-text-right">
          <button onClick={this.printPrompt} className="uk-button uk-button-default d-button-header-sub uk-button-small"><span uk-icon="print"></span> พิมพ์</button>
        </div>
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">บันทึกสุขภาพ</div>
            </div>
          
          <div className="uk-margin-small-bottom">
            <div className="uk-margin-small-bottom uk-text-bold">ประเภทการบันทึก</div>
            <select className="uk-select" value={this.state.typeDataSelect} onChange={(e)=>this.setState({typeDataSelect: e.target.value})}>{this.typeData.map((value, index)=> <option key={`typeData${index}`} value={value}>{value}</option>)}</select>
          </div>
          <div className="d-work-health-record-container">
            <div>
              {/**  */}
              
              <div className="d-page-break"></div>

              {/**  */}
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ รายงานผลการตรวจร่างกายเบื้องต้น (Physical Examination) ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td>
                      <span className="d-text-blue">น้่ำหนัก (Weight)</span>
                      {" "}<span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 60 }}
                      >
                        {this.getForm("weight", {
                          style: {
                            width: 60
                          }
                        })}
                      </span>{" "}
                      <span className="d-text-blue">กิโลกรัม</span>
                    </td>
                    <td>
                      <span className="d-text-blue">ส่วนสูง (Height)</span>
                      {" "}<span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 60 }}
                      >
                        {this.getForm("height", {style: {width: 60}})}
                      </span>{" "}
                      <span className="d-text-blue">เซนติเมตร</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="d-text-blue">ดัชนีมวลกาย(BMI) </span>
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 65 }}
                      >
                        {this.getForm("bmi", {style: {width: 65}})}
                      </span>
                      <span className="d-text-blue">
                        กก./ม<sup>2</sup>.
                      </span>{" "}
                      <span className="d-text-pink">
                        [ค่าปกติ:18.5-24.9 กก./ม<sup>2</sup>.]
                      </span>
                    </td>
                    <td>
                      <span className="d-text-blue">ABO </span>
                      <span
                          className="uk-text-center"
                          style={{ display: "inline-block", width: 65 }}
                        >
                        {this.getForm("abo", {style: {width: 65}})}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div>
                  {this.getForm("bmi-note", {type: 'textarea'})}
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td>
                      <span className="d-text-blue">ความดันโลหิต (BP) </span>
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 65}}
                      >
                        {this.getForm("sys/dias", {style: {width: 65}})}
                      </span>
                      <span className="d-text-blue">ม.ม.ปรอท</span>
                    </td>
                    <td>
                      <span className="d-text-blue">ชีพจร (Pulse) </span>
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 45 }}
                      >
                        {this.getForm("pulse", {style: {width: 45}})}
                      </span>
                      <span className="d-text-blue">ครั้ง/นาที</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                
                <div>
                  {this.getForm("pulse-note", {type: 'textarea'})}
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td>
                      <div className="d-text-blue uk-text-center">
                        โรคประจำตัว
                      </div>
                      <div>
                        {this.getForm("congenitaldisease", {type: 'textarea'})}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-text-blue uk-text-center">
                        ประวัติแพ้ยา,แพ้อาหาร
                      </div>
                      <div>
                        {this.getForm("drug-allergy", {type: 'textarea'})}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div>
                  <div className="uk-text-center">
                    <span className="d-text-blue">
                      <u>รายละเอียดของผู้ที่เข้ารับการตรวจ</u>
                    </span>
                  </div>
                  <div>
                    <span className="d-text-blue">บริษัท : </span>{" "}
                    {this.state.company.name}
                    <span className="d-text-purple">
                      <span
                        className="uk-text-center"
                        style={{ display: "inline-block", width: 65 }}
                      ></span>
                    </span>
                  </div>
                  <div>
                    <span className="d-text-blue">ชื่อ-นามสกุล : </span>{" "}
                    <span>
                      {this.state.profile.nameprefix} {this.state.profile.name}{" "}
                      {this.state.profile.lastname}
                    </span>
                  </div>
                  <div>
                    <span className="d-text-blue">แผนก/ตำแหน่ง : </span>{" "}
                    <span>{this.state.company.position}</span>
                  </div>
                  <div>
                    <span className="d-text-blue">รหัส : </span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 64 }}
                    >{this.getForm("no", {style: {width: 90}})}</span>
                    <span className="d-text-blue uk-margin-left">
                      ลำดับที่ :{" "}
                    </span>{this.getForm("order", {style: {width: 120}})}
                  </div>

                  <div>
                    <span className="d-text-blue">อายุ : </span>
                    <span
                      className="uk-text-center"
                      style={{ display: "inline-block", width: 65 }}
                    >
                      {this.getForm("age", {style: {width: 45}})}
                    </span>
                    <span className="d-text-blue">ปี</span>
                    <span className="d-text-blue uk-margin-left">
                      ตรวจวันที่ :{" "}
                    </span>{" "}
                    {this.getForm("dateLab", {style: {width: 120}})}
                    <span></span>
                  </div>
                </div>
                <div>
                  <div className="uk-text-center">
                    <span className="d-text-blue">
                      <u>
                        สรุปผลการตรวจร่างกายเบื้องต้นและตรวจร่างกายทั่วไปโดยแพทย์
                      </u>
                    </span>
                  </div>
                  <div className="d-note-border-bottom">
                    - ผลตรวจร่างกายทั่วไปโดยแพทย์
                  </div>
                  <div>
                    {this.getForm("conclusion", {type: 'textarea'})} {this.getForm("conclusion-more", {type: 'textarea'})}
                  </div>
                </div>
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td>
                      <div className="d-text-blue uk-text-center">
                        [ สรุปผลการตรวจภาพรังสีทรวงอก (Chest X-Ray) ]
                      </div>
                      <div className="d-note-border-bottom">
                        * ผลตรวจภาพรังสีทรวงอก{" "}
                      </div>
                      <div>
                        {this.getForm("chest-x-ray", {type: 'textarea'})}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  cellPadding="10"
                  cellSpacing="0"
                  width="100%"
                  className="uk-margin-small-top"
                >
                  <tbody>
                  <tr>
                    <td>
                      <div className="d-text-blue uk-text-center">
                        [ สรุปผลตรวจคลื่นไฟฟ้าหัวใจ (Electrocardiogram-EKG) ]
                      </div>
                      <div className="d-note-border-bottom">
                        * ผลตรวจคลื่นไฟฟ้าหัวใจ (EKG){" "}
                      </div>
                      <div>
                        {this.getForm("ekg", {type: 'textarea'})}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table
                  border="1"
                  cellPadding="10"
                  cellSpacing="0"
                  width="100%"
                  className="uk-margin-small-top"
                >
                  <tbody>
                  <tr>
                    <td>
                      <div className="d-text-blue uk-text-center">
                        [ ผลตรวจสายตาอาชีวอนามัย (Occupation Vision Tests) ]
                      </div>
                      <table
                        border="1"
                        cellPadding="10"
                        cellSpacing="0"
                        width="90%"
                        className="uk-margin-small-top"
                        style={{ margin: "auto" }}
                      >
                        <tbody>
                        <tr>
                          <td className="d-text-purple">ประเภทการทดสอบ</td>
                          <td
                            className="d-text-purple uk-text-center"
                            width="30%"
                          >
                            ผลตรวจ
                          </td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบการมองระยะไกล</td>
                          <td>{this.getForm("visual-acuity")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบการมองระยะใกล้</td>
                          <td>
                            {" "}
                            L : {this.getForm("close-visual-left", {style: {width: 60}})} R :{" "}
                            {this.getForm("close-visual-right", {style: {width: 60}})}
                          </td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">
                            ทดสอบการมองชัดลึก (ภาพ 3 มิติ)
                          </td>
                          <td>{this.getForm("stereo-depth")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบการแยกสี</td>
                          <td>{this.getForm("color-blindness")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">
                            ทดสอบความสมดุลกล้ามเนื้อตา
                          </td>
                          <td>{this.getForm("eye-muscle-balance")}</td>
                        </tr>
                        <tr>
                          <td className="d-text-blue">ทดสอบลานสายตา</td>
                          <td>
                            L : {this.getForm("horizontal-visual-field-left", {style: {width: 60}})} R :{" "}
                            {this.getForm("horizontal-visual-field-right", {style: {width: 60}})}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div>
                        {this.getForm("occupation-note", {type: 'textarea'})}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div className="d-page-break"></div>

              {/** */}

              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ ผลการตรวจปัสสาวะ (Urinalysis) ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td width="40%">สีปัสสาวะ (Color)</td>
                    <td colSpan="2">{this.getForm("ua-color")}</td>
                  </tr>
                  <tr>
                    <td>ความขุ่นใส (Appearance)</td>
                    <td colSpan="2">{this.getForm("ua-apperance")}</td>
                  </tr>
                  <tr>
                    <td className="d-text-blue">Urine Strip</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center" width="150">
                      ค่าปกติ
                    </td>
                  </tr>
                  <tr>
                    <td>ความถ่วงจําเพาะ (Sp.gr.)</td>
                    <td className="uk-text-center">{this.getForm("ua-spgr")}</td>
                    <td className="d-text-blue uk-text-center">1.005-1.030</td>
                  </tr>
                  <tr>
                    <td>ค่าความเป็นกรด, ด่าง (pH)</td>
                    <td className="uk-text-center">{this.getForm("ua-ph")}</td>
                    <td className="d-text-blue uk-text-center">5.0 - 8.0</td>
                  </tr>
                  <tr>
                    <td>น้ําตาลในปัสสาวะ (Glucose)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-glucose")}
                    </td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>โปรตีนในปัสสาวะ (Protein)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-protein")}
                    </td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>เลือดในปัสสาวะ (Blood)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-blood")}</td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>แอมเฟตามีน (Amphetamine)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-amphetamine")}</td>
                    <td className="d-text-blue uk-text-center">Negative</td>
                  </tr>
                  <tr>
                    <td>ไซลีน (Xylene)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-xylene")}</td>
                    <td className="d-text-blue uk-text-center">&#60; 1.5 g/g. Cr</td>
                  </tr>
                  <tr>
                    <td>โครเมียม (Chromium)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-chromium")}</td>
                    <td className="d-text-blue uk-text-center">&#60; 30 ug/g. Cr</td>
                  </tr>
                  <tr>
                    <td>ฟอร์มาลดีไฮด์ (Formaldehyde)</td>
                    <td className="uk-text-center">
                      {this.getForm("ua-formaldehyde")}</td>
                    <td className="d-text-blue uk-text-center">&#60; 0.004 mg/m5</td>
                  </tr>
                  <tr>
                    <td className="d-text-blue" colSpan="3">
                      Urine Microscopy
                    </td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดขาว (WBC)</td>
                    <td className="uk-text-center">{this.getForm("ua-wbc")}</td>
                    <td className="d-text-blue uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดแดง (RBC)</td>
                    <td className="uk-text-center">{this.getForm("ua-rbc")}</td>
                    <td className="d-text-blue uk-text-center">0 - 3 HPF</td>
                  </tr>
                  <tr>
                    <td>เซลล์เยื่อบุ (Epithelial Cell)</td>
                    <td className="uk-text-center">{this.getForm("ua-epithelial")}</td>
                    <td className="d-text-blue uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>แบคทีเรีย (Bacteria)</td>
                    <td className="uk-text-center" colSpan="2">{this.getForm("ua-bacteria")}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ ผลตรวจนับเม็ดเลือดแบบสมบูรณ์ (Complete Blood Count) ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td className="d-text-purple uk-text-center" width="40%">
                      รายละเอียดการตรวจ
                    </td>
                    <td className="d-text-purple uk-text-center" width="100">
                      ผลตรวจ
                    </td>
                    <td className="d-text-purple uk-text-center">ค่าปกติ</td>
                  </tr>
                  <tr>
                    <td>ฮีโมโกลบิน (Hb)</td>
                    <td className="uk-text-center">{this.getForm("hb")}</td>
                    <td className="d-text-blue uk-text-center">
                      M13-18,F 12-16 g/dl
                    </td>
                  </tr>
                  <tr>
                    <td>ฮีมาโตคริต (Hct)</td>
                    <td className="uk-text-center">{this.getForm("hct")}</td>
                    <td className="d-text-blue uk-text-center">
                      M 37-54 % , F 34-48 %
                    </td>
                  </tr>
                  <tr>
                    <td>จํานวนเม็ดเลือดแดง (RBC)</td>
                    <td className="uk-text-center">
                      {this.getForm("rbc-count")}
                    </td>
                    <td className="d-text-blue uk-text-center">
                      4-6x10<sup>6</sup> cells/mm<sup>3</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>จํานวนเม็ดเลือดขาว (WBC)</td>
                    <td className="uk-text-center">{this.getForm("wbc")}</td>
                    <td className="d-text-blue uk-text-center">
                      4,500 - 11,000 cells/mm<sup>2</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>- นิวโตรฟิล (Neutrophil) (NE)</td>
                    <td className="uk-text-center">
                      {this.getForm("neutrophil-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">40 - 75 %</td>
                  </tr>
                  <tr>
                    <td>- ลิมโฟไซท์ (Lymphocyte) (LY)</td>
                    <td className="uk-text-center">
                      {this.getForm("lymphocyte-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">20 - 45 %</td>
                  </tr>
                  <tr>
                    <td>- โมโนไซท์ (Monocyte) (MO)</td>
                    <td className="uk-text-center">
                      {this.getForm("monocyte-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">2 - 10 %</td>
                  </tr>
                  <tr>
                    <td>- อีโอสิโนฟิล (Eosinophil) (EO)</td>
                    <td className="uk-text-center">
                      {this.getForm("eosinophil-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">0 - 8 %</td>
                  </tr>
                  <tr>
                    <td>
                     - เบโซฟิล (Basophils) (BA)
                    </td>
                    <td className="uk-text-center">
                      {this.getForm("ba-ratio")}
                    </td>
                    <td className="d-text-blue uk-text-center">0 - 1 %</td>
                  </tr>
                  <tr>
                    <td>จํานวนเกล็ดเลือด (Platelet)</td>
                    <td className="uk-text-center">
                      {this.getForm("platelets")}
                    </td>
                    <td className="d-text-blue uk-text-center">
                      140,000-450,000 cell/mm
                    </td>
                  </tr>
                  <tr>
                    <td>RBC Index</td>
                    <td colSpan="2">
                      <div>
                        <span className="d-text-blue" style={{ marginRight: 20 }}>
                          - MCV
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {this.getForm("mcv", {style: {width: 100}})}
                        </span>{" "}
                        <span className="d-text-blue">fl. ( 80 - 100 fl. )</span>
                      </div>
                      <div>
                        <span className="d-text-blue" style={{ marginRight: 20 }}>
                          - MCH
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {this.getForm("mch", {style: {width: 80}})}
                        </span>{" "}
                        <span className="d-text-blue">pg. ( 27 - 33 pg. )</span>
                      </div>
                      <div>
                        <span className="d-text-blue" style={{ marginRight: 20 }}>
                          - MCHC
                        </span>
                        <span style={{ display: "inline-block" }}>
                          {this.getForm("mchc", {style: {width: 80}})}
                        </span>{" "}
                        <span className="d-text-blue">g/dl ( 31 - 35 g/dl )</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>ลักษณะรูปร่างเม็ดเลือดแดง (RBC Morphology)</td>
                    <td colSpan="2">
                      <div style={{ minHeight: 50 }}>
                        {this.getForm("rbc-morph")}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <div className="d-text-blue uk-text-center">
                  [ สรุปผลตรวจนับเม็ดเลือดแบบสมบูรณ์ (Complete Blood Count) ]
                </div>
                <div>
                  * ผลตรวจนับเม็ดเลือดแบบสมบูรณ์
                </div>
                <div>
                  {this.getForm("blood-summary", {type: 'textarea'})}

                </div>
              </div>
              <div className="d-page-break"></div>
              {/** */}
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ ผลตรวจสมรรถภาพการได้ยิน (Audiometry) ]
                </div>
                <div className="uk-overflow-auto">
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td className="d-text-purple uk-text-center">
                      ความถี่ (เฮิร์ตซ์)
                    </td>
                    <td className="d-text-blue uk-text-center">500</td>
                    <td className="d-text-blue uk-text-center">1000</td>
                    <td className="d-text-blue uk-text-center">2000</td>
                    <td className="d-text-blue uk-text-center">3000</td>
                    <td className="d-text-blue uk-text-center">4000</td>
                    <td className="d-text-blue uk-text-center">6000</td>
                    <td className="d-text-blue uk-text-center">8000</td>
                  </tr>
                  <tr>
                    <td className="d-text-purple uk-text-center">หูขวา</td>
                    <td>{this.getForm("audiogram-r-500")}</td>
                    <td>{this.getForm("audiogram-r-1000")}</td>
                    <td>{this.getForm("audiogram-r-2000")}</td>
                    <td>{this.getForm("audiogram-r-3000")}</td>
                    <td>{this.getForm("audiogram-r-4000")}</td>
                    <td>{this.getForm("audiogram-r-6000")}</td>
                    <td>{this.getForm("audiogram-r-8000")}</td>
                  </tr>
                  <tr>
                    <td className="d-text-purple uk-text-center">หูซ้าย</td>
                    <td>{this.getForm("audiogram-l-500")}</td>
                    <td>{this.getForm("audiogram-l-1000")}</td>
                    <td>{this.getForm("audiogram-l-2000")}</td>
                    <td>{this.getForm("audiogram-l-3000")}</td>
                    <td>{this.getForm("audiogram-l-4000")}</td>
                    <td>{this.getForm("audiogram-l-6000")}</td>
                    <td>{this.getForm("audiogram-r-8000")}</td>
                  </tr>
                  </tbody>
                </table>
                </div>
                <div
                  className="d-text-purple uk-text-center"
                  style={{ padding: 10 }}
                >
                  ค่าปกติ : ระดับการได้ยินไม่เกิน 25 dB
                </div>
                <div className="d-text-blue uk-text-center">
                  <u className="d-text-blue">
                    สรุปผลตรวจสมรรถภาพการได้ยิน (Audiometry)
                  </u>
                </div>
                <div>
                  {this.getForm("audiogram", {type: 'textarea'})}
                </div>
                <div>
                  {this.getForm("audiogram-more", {type: 'textarea'})}
                </div>
              </div>
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ ผลตรวจสมรรถภาพปอด (Spirometry) ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td className="d-text-blue uk-text-center">FVC (L)</td>
                    <td className="d-text-blue uk-text-center">FEV1 (L)</td>
                    <td className="d-text-blue uk-text-center">FEV1/FVC (%)</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("fvc")}</div>
                    </td>
                    <td>{this.getForm("fev1")}</td>
                    <td>{this.getForm("fev1/fvc%")}</td>
                    <td>{this.getForm("spirometry-result")}</td>
                  </tr>
                  </tbody>
                </table>
                <div
                  className="d-text-pink uk-padding-small uk-text-center"
                  style={{ fontSize: 12 }}
                >{`หมายเหตุ : ค่า %FEV1/FVC อายุ < 50 ปี ใช้เกณฑ์ 75 % และ อายุ ≥ 50 ปี ใช้เกณฑ์ 70 %`}</div>

                <div className="d-text-blue uk-text-center">
                  [ ผลตรวจสมรรถภาพปอด (Spirometry) ]
                </div>
                <div className="d-note-border-bottom">{this.getForm("pft", {type:'textarea'})}</div>
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ ผลตรวจทางห้องปฏิบัติการ (Laboratory) ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td className="d-text-blue uk-text-center">รายการตรวจ</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                    <td className="d-text-blue uk-text-center">สรุปผล</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจระดับน้ําตาลในเลือด
                    </td>
                  </tr>
                  <tr>
                    <td>Fasting Blood Sugar (FBS)</td>
                    <td className="uk-text-center">{this.getForm("fbs/fpg")}</td>
                    <td className="d-text-blue">70-99 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("fbs/fpg-result")}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจหาระดับไขมันในเลือด
                    </td>
                  </tr>
                  <tr>
                    <td>Cholesterol</td>
                    <td className="uk-text-center">{this.getForm("chol")}</td>
                    <td className="d-text-blue">0-200 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("chol-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Triglyceride</td>
                    <td className="uk-text-center">{this.getForm("tg")}</td>
                    <td className="d-text-blue">0-160 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("tg-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>HDL-Cholesterol</td>
                    <td className="uk-text-center">{this.getForm("hdl")}</td>
                    <td className="d-text-blue">{`>45 mg/dl`}</td>
                    <td className="uk-text-center">
                      {this.getForm("hdl-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>LDL-Cholesterol</td>
                    <td className="uk-text-center">{this.getForm("ldl")}</td>
                    <td className="d-text-blue">0-160 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("ldl-result")}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจการทํางานของตับ
                    </td>
                  </tr>
                  <tr>
                    <td>Total protein</td>
                    <td className="uk-text-center">{this.getForm("t.protein")}</td>
                    <td className="d-text-blue">6.6-8.7 g/dl</td>
                    <td className="uk-text-center">{this.getForm("t.protein")}</td>
                  </tr>
                  <tr>
                    <td>Total Bilirubin</td>
                    <td className="uk-text-center">
                      {this.getForm("t.bilirubin")}
                    </td>
                    <td className="d-text-blue">0.3-1.2 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("t.bilirubin-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>SGOT (AST)</td>
                    <td className="uk-text-center">{this.getForm("sgot")}</td>
                    <td className="d-text-blue">10-40 U/L</td>
                    <td className="uk-text-center">
                      {this.getForm("sgot-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>SGPT (ALT)</td>
                    <td className="uk-text-center">{this.getForm("sgpt")}</td>
                    <td className="d-text-blue">10-40 U/L</td>
                    <td className="uk-text-center">
                      {this.getForm("sgpt-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Alk. Phosphatase</td>
                    <td className="uk-text-center">
                      {this.getForm("alk.phosphatase")}
                    </td>
                    <td className="d-text-blue">38-126 U/L</td>
                    <td className="uk-text-center">
                      {this.getForm("alk.phosphatase-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Ammonia</td>
                    <td className="uk-text-center">
                      {this.getForm("ammonia")}
                    </td>
                    <td className="d-text-blue">25-94 mg%</td>
                    <td className="uk-text-center">
                      {this.getForm("ammonia-result")}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจการทํางานของไต
                    </td>
                  </tr>
                  <tr>
                    <td>BUN</td>
                    <td className="uk-text-center">{this.getForm("bun")}</td>
                    <td className="d-text-blue">7.8-20.3 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("bun-result")}
                    </td>
                  </tr>
                  <tr>
                    <td>Creatinine</td>
                    <td className="uk-text-center">
                      {this.getForm("creatinine")}
                    </td>
                    <td className="d-text-blue">0.7-1.5 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("creatinine-result")}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจระดับกรดยูริคในเลือด
                    </td>
                  </tr>
                  <tr>
                    <td>Uric Acid</td>
                    <td className="uk-text-center">{this.getForm("uric")}</td>
                    <td className="d-text-blue">2.3-8.2 mg/dl</td>
                    <td className="uk-text-center">
                      {this.getForm("uric-result")}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="d-text-purple">
                      ตรวจไวรัสตับอักเสบ บี
                    </td>
                  </tr>
                  <tr>
                    <td>HBs Ag (เชื้อไวรัสตับอักเสบบี)</td>
                    <td>{this.getForm("hbsag")}</td>
                    <td>{this.getForm("hbsag-normal")}</td>
                    <td>{this.getForm("hbsag-result")}</td>
                  </tr>
                  <tr>
                    <td>HBs Ab (ภูมิคุ้มกัน)</td>
                    <td>{this.getForm("hbsab")}</td>
                    <td>{this.getForm("hbsab-normal")}</td>
                    <td>{this.getForm("hbsab-result")}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div className="d-page-break"></div>

              {/** */}
              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ ผลตรวจรายการอื่นๆ(Other) และสารพิเศษ (Toxic) ]
                </div>
                <table border="1"  cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td className="d-text-blue uk-text-center">รายการตรวจ</td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                    <td className="d-text-blue uk-text-center">สรุปผล</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("other-list-1", {type: 'textarea'})}</div>
                    </td>
                    <td>{this.getForm("other-value-1", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-normal-1", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-result-1", {type: 'textarea'})}</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("other-list-2", {type: 'textarea'})}</div>
                    </td>
                    <td>{this.getForm("other-value-2", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-normal-2", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-result-2", {type: 'textarea'})}</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{this.getForm("other-list-3", {type: 'textarea'})}</div>
                    </td>
                    <td>{this.getForm("other-value-3", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-normal-3", {type: 'textarea'})}</td>
                    <td>{this.getForm("other-result-3", {type: 'textarea'})}</td>
                  </tr>
                  </tbody>
                </table>

                <div className="d-text-blue uk-text-center uk-margin-small-top">
                  [ ผลตรวจอุจจาระ (Stool Examination) ]
                </div>
                <table border="1" cellPadding="10" cellSpacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td className="d-text-blue uk-text-center">
                      รายละเอียดการตรวจ
                    </td>
                    <td className="d-text-blue uk-text-center">ผลตรวจ</td>
                    <td className="d-text-blue uk-text-center">ค่าปกติ</td>
                  </tr>
                  <tr>
                    <td>สีของอุจจาระ (Color)</td>
                    <td>{this.getForm("stool-color-result")}</td>
                    <td className="d-text-green uk-text-center">{this.getForm("stool-color-normal")}</td>
                  </tr>
                  <tr>
                    <td>ลักษณะทั่วไป (Appearance)</td>
                    <td>{this.getForm("stool-appearance-result")}</td>
                    <td className="d-text-green uk-text-center">{this.getForm("stool-appearance-normal")}</td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดขาว (WBC)</td>
                    <td>{this.getForm("stool-wbc-result")}</td>
                    <td className="d-text-green uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>เม็ดเลือดแดง (RBC)</td>
                    <td>{this.getForm("stool-rbc-result")}</td>
                    <td className="d-text-green uk-text-center">0 - 5 HPF</td>
                  </tr>
                  <tr>
                    <td>พยาธิ,ไข่พยาธิ (parasite,Ova)</td>
                    <td>{this.getForm("stool-parasite-result")}</td>
                    <td className="d-text-green uk-text-center">{this.getForm("stool-parasite-normal")}</td>
                  </tr>
                  </tbody>
                </table>
                <div>
                  {this.getForm("stool-examination-note", {type: 'textarea'})}
                </div>
              </div>

              <div className="d-user-health-book-container">
                <div className="d-text-blue uk-text-center">
                  [ ผลตรวจเพาะเชื้อก่อโรคระบบทางเดินอาหาร ( Stool Culture ) ]
                </div>
                <div>
                  <span className="d-text-blue">ผลตรวจ</span>
                </div>
                <div>
                  {this.getForm("stool-culture-value", {type: 'textarea'})}
                </div>
                <div>
                  <span className="d-text-blue">สรุปผลตรวจ</span>
                </div>
                <div>
                  {this.getForm("stool-culture-note", {type: 'textarea'})}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button className="uk-button uk-button-primary uk-width-1-1" onClick={this.saveForm}>บันทึก</button>
          </div>
        </div>
      </div>
    );
  }
}
