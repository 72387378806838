import React, { useEffect } from 'react';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "../../../css/dietz.css";

UIkit.use(Icons);

const FormErrorPopup = (props) => {
    const { onClose, onGoToMainPage, message } = props;

    useEffect(() => {
        window.scroll(0, 0);
        document.body.style.overflow = "hidden";
    }, [document.body.style.overflow]);

    useEffect(() => {
        return () => { document.body.style.overflow = "auto"; }
    }, []);

    // useEffect(() => {
    //     console.log(document.body.style.overflow, open);
    //     if (open && document.body.style.overflow == 'auto') {
    //         document.body.style.overflow = "hidden";
    //     } else if (!open) {
    //         document.body.style.overflow = "auto";
    //     }
    // }, [document.body.style.overflow, open]);

    return (
        <>
            <div className="dietz-loading-background">
                <div className="dietz-success-popup-container" >
                    <div className='dietz-popup-header'>
                        <span className='dietz-save-error-icon' uk-icon="icon: close; ratio: 1.2"></span>
                        <span className='dietz-popup-title'>{message.header || 'เกิดข้อผิดพลาด'}</span>
                    </div>
                    <div className='dietz-popup-body'>
                        <article>{message.description}</article>
                    </div>
                    <div className='dietz-popup-footer'>
                        {message.isClose && <button className="uk-button uk-button-primary"
                            type="button" onClick={onClose}>
                            ปิด
                        </button>}
                        {message.isGoToMainPage && <button className="uk-button uk-button-primary"
                            type="button" onClick={onGoToMainPage}>
                            ไปที่หน้ารายการหลัก
                        </button>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormErrorPopup;
