import Rating from 'react-rating';
import React, { Component } from 'react';
import $ from 'jquery';

export default class FullScreenElement extends Component {
  state = {
    isFull: false
  }

  goFull = () => {
    $(this.props.element).addClass('d-fullscreen-element');
    this.setState({isFull: true});
  }

  goMin = () => {

    $(this.props.element).removeClass('d-fullscreen-element');
    this.setState({isFull: false});
  }

  render() {
    return  this.state.isFull ?
      <span className='icon-size-actual' onClick={this.goMin}></span>
      :
      <span className='icon-frame' onClick={this.goFull}></span>
  }
    
    

}
