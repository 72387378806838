import React, { Component } from 'react';

export default class LanguageLoginSelect extends Component {

  langageSelect = (language) => {
    localStorage.setItem('language', language);
    window.location.reload();
  }

  render() {
    return <div className="uk-text-right d-language-login-select-container">
      <a className="uk-link-reset uk-display-inline-block uk-margin-small-right" onClick={()=>this.langageSelect('th')}>
        <img src="/assets/th_flag.png" width="20" />
        <div className="uk-text-center">TH</div>
      </a>
      <a className="uk-link-reset uk-display-inline-block" onClick={()=>this.langageSelect('en')}>
        <img src="/assets/en_flag.png" width="20" />
        <div className="uk-text-center">ENG</div>
      </a>
    </div>
  }

}
