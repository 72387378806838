import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';

import ReactPaginate from 'react-paginate';

class AdminDataAddForm extends Component {
  field = [
    { key: 'name', label: 'ประเภท' },
    { key: 'value', label: 'ค่า' },
    { key: 'memberid', label: 'รหัสสมาชิก' },
    { key: 'campaignid', label: 'รหัสแคมเปญ' },
    { key: 'dateadd', label: 'ปปปป-ดด-วว' }
  ]

  state = {
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    this.setState((state) => {
      var stateInit = {};
      if(this.props.data) {
        this.field.map(data => { stateInit[data.key] = this.props.data[data.key]; });
      } else {
        this.field.map(data => { stateInit[data.key] = ''; });
      }
      
      return stateInit;
    });

    if (this.props.show) {
      UIkit.modal('#modal-form-data').show();
    } else {
      UIkit.modal('#modal-form-data').hide();
    }
  }


  DataAdd = async (e) => {
    e.preventDefault();
    var data = this.state;
    if(this.props.data) {
      data.id = this.props.data.id;
    }

    var {result, responseText, query} = await Api.admin( this.props.data ? 'DataUpdate' : 'DataAdd', data);
    UIkit.notification({ message: responseText, status: result ? 'success' : 'danger' })
    this.props.callback();
    UIkit.modal('#modal-form-data').hide();
  }


  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return <div id="modal-form-data" uk-modal="bg-close: false; esc-close: false;">
      <div className="uk-modal-dialog uk-modal-body">
        <button className="uk-modal-close-default" type="button" uk-close=""></button>
        <h2 className="uk-modal-title">จัดการข้อมูล</h2>
        <form onSubmit={this.DataAdd} className="uk-width-1-1">
          {
            this.field.map(data => {
              return <div>
                <label>{data.label}</label>
                <input type="text" className="uk-input" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
              </div>
            })
          }
          <button className="uk-input uk-button-primary uk-margin-small-top" type="submit">{this.props.data ? 'บันทึก' : 'เพิ่ม' }</button>
        </form>
      </div>
    </div>
  }
}

export default class AdminData extends Component {

  state = {
    dataList: [],
    formShow: false,
    data: false,
    pageCount: 0,
    pageNow: 1,
  }

  perPage = 100

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    this.dataListGet();

    this.UploadInit();
    this.UploadV2Init();
    this.UploadV3Init();
  }

  UploadV3Init = () => {

    var bar = document.getElementById('js-progressbar');
    let list = [];

    UIkit.upload('.js-upload-v3', {

      url: Api.serverUrl+'CsvUploadFile.php',
      multiple: false,
      name: 'file',
      beforeSend: function (environment) {
        environment.headers.authorization = window.localStorage.getItem('adminLogin') || '';
        environment.data.append('typeUpload', 'datalabv3');
      },
      beforeAll: function () {
        bar.value = 0;
        list = [];
      },
      loadStart: function (e) {
        bar.removeAttribute('hidden');
        bar.max = e.total;
      },
      progress: function (e) {
        bar.max = e.total;
      },
      loadEnd: function (e) {
        console.log(arguments[0])
        if(arguments[0].currentTarget.responseText !== '') list.push(arguments[0].currentTarget.responseText.trim());

        bar.max = e.total;
      },
      completeAll:  () => {
        setTimeout(()=>{
          bar.setAttribute('hidden', 'hidden');
          this.dataListGet();
        }, 1000);
      }

    });
  }

  UploadV2Init = () => {

    var bar = document.getElementById('js-progressbar');
    let list = [];

    UIkit.upload('.js-upload-v2', {

      url: Api.serverUrl+'CsvUploadFile.php',
      multiple: false,
      name: 'file',
      beforeSend: function (environment) {
        environment.headers.authorization = window.localStorage.getItem('adminLogin') || '';
        environment.data.append('typeUpload', 'datalabv2');
      },
      beforeAll: function () {
        bar.value = 0;
        list = [];
      },
      loadStart: function (e) {
        bar.removeAttribute('hidden');
        bar.max = e.total;
      },
      progress: function (e) {
        bar.max = e.total;
      },
      loadEnd: function (e) {
        if(arguments[0].currentTarget.responseText !== '') list.push(arguments[0].currentTarget.responseText.trim());

        bar.max = e.total;
      },
      completeAll:  () => {
        setTimeout(()=>{
          bar.setAttribute('hidden', 'hidden');
          this.dataListGet();
        }, 1000);
      }

    });
  }

  UploadInit = () => {

    var bar = document.getElementById('js-progressbar');
    let list = [];

    UIkit.upload('.js-upload', {

      url: Api.serverUrl+'CsvUploadFile.php',
      multiple: false,
      name: 'file',
      beforeSend: function (environment) {
        environment.headers.authorization = window.localStorage.getItem('adminLogin') || '';
        environment.data.append('typeUpload', 'csv');
      },
      beforeAll: function () {
        bar.value = 0;
        list = [];
      },
      loadStart: function (e) {
        bar.removeAttribute('hidden');
        bar.max = e.total;
      },
      progress: function (e) {
        bar.max = e.total;
      },
      loadEnd: function (e) {
        if(arguments[0].currentTarget.responseText !== '') list.push(arguments[0].currentTarget.responseText.trim());

        bar.max = e.total;
      },
      completeAll:  () => {
        setTimeout(()=>{
          bar.setAttribute('hidden', 'hidden');
          this.dataListGet();
        }, 1000);
      }

    });
  }

  dataListGet = async () => {
    var { list, amount } = await Api.admin('DataList', {
      perPage : this.perPage,
      pageNow : this.state.pageNow
    });

    this.setState({ dataList: list || [], formShow: false, pageCount: +amount/this.perPage  }, ()=>this.setState({formShow: false}));
  }

  handlePageClick = (page) => {
    this.setState({pageNow: page.selected+1}, ()=>this.dataListGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
  }

  uploadV3Render = () => {
    return <div className="js-upload-v3 uk-placeholder uk-text-center">
    <span uk-icon="icon: cloud-upload"></span>
    <span className="uk-text-middle">VERSION 3 .CSV </span>
    <div uk-form-custom="">
        <input type="file" accept=".csv" />
        <span className="uk-link">คลิก</span>
    </div>
    <div><a href="/exlabv3.csv" className="uk-margin-top">ดาวน์โหลดตัวอย่าง v3</a></div>
  </div>
  }

  uploadV2Render = () => {
    return <div className="js-upload-v2 uk-placeholder uk-text-center">
    <span uk-icon="icon: cloud-upload"></span>
    <span className="uk-text-middle">VERSION 2 .CSV  </span>
    <div uk-form-custom="">
        <input type="file" accept=".csv" />
        <span className="uk-link">คลิก</span>
    </div>
    <div><a href="/exlabv2.csv" className="uk-margin-top">ดาวน์โหลดตัวอย่าง v2</a></div>
  </div>
  }

  uploadV1Render = () => {
    return <div className="js-upload uk-placeholder uk-text-center">
    <span uk-icon="icon: cloud-upload"></span>
    <span className="uk-text-middle">VERSION 1 .CSV </span>
    <div uk-form-custom="">
        <input type="file" accept=".csv" />
        <span className="uk-link">คลิก</span>
    </div>
    <div><a href="/exlabv1.csv" className="uk-margin-top">ดาวน์โหลดตัวอย่าง v1</a></div>
  </div>
  }

  uploaderLabel = {
    "m" : "บันทึกเอง",
    "" : "นำเข้าหรือบันทึกโดยแอดมิน",
    "api" : "Api"
  }

  render() {
    return <div>
      <div className="d-admin-header-row uk-text-right uk-margin-bottom">
        <div uk-grid="" className="uk-grid-small uk-child-width-1-1 uk-child-width-1-3@s">
          <div>{this.uploadV1Render()}</div>
          <div>{this.uploadV2Render()}</div>
          <div>{this.uploadV3Render()}</div>
        </div>
        <progress id="js-progressbar" className="uk-progress" value="0" max="100" hidden></progress>
        <button className="uk-button uk-button-small uk-button-secondary" onClick={() => this.setState({ data: false,formShow: true }, () => this.setState({ formShow: true }))} >เพิ่มข้อมูล</button>
      </div>
      <AdminDataAddForm  {...this.props} callback={this.dataListGet} show={this.state.formShow} data={this.state.data} />

      {this.paginateRender()}

      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>วันที่</th>
            <th>รหัสสมาชิก</th>
            <th>ประเภท</th>
            <th>ค่า</th>
            <th>บริษัท</th>
            <th>แคมเปญ</th>
            <th>แหล่งที่มา</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.dataList.map((data, key) => {
              console.log(data)
              return <tr key={`Data${data.id}`}>
                <td>{(this.perPage * (this.state.pageNow-1 ) + key + 1)}</td>
                <td>{data.dateadd}</td>
                <td>{data.memberid}</td>
                <td>{data.name}</td>
                <td>{data.value}</td>
                <td>{data.companyname}</td>
                <td>{data.campaignname}</td>
                <td>{this.uploaderLabel[data.uploader] || data.uploader}</td>
                <td>
                  <button className="uk-button uk-button-small" onClick={() => this.setState({ data: data, formShow: true }, () => this.setState({ formShow: true }))} >
                    <i uk-icon="icon: pencil"></i>
                  </button>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>

      {this.paginateRender()}
    </div>
  }
};
