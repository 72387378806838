import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../Api';

UIkit.use(Icons);

export default class SelectMemberCompanygroup extends Component {
  state = {
    memberCompanygroup: [],
    selectMemberCompanyGroup: 'all'
  }

  componentDidMount() {
    this.init();
  }

  init() {
    this.groupGet();
  }

  async groupGet() {
    var {list} = await Api.member('SelectMemberCompanygroup', {campaignId: this.props.id});
    this.setState({memberCompanygroup: list || [] });
  }

  render() {
    return <select className="uk-select uk-width-small" value={this.state.selectMemberCompanyGroup} onChange={(e)=>{
      this.setState({selectMemberCompanyGroup: e.target.value});
      this.props.callback(e.target.value);
    }}>
          <option value="all">ทั้งหมด</option>
          {
            this.state.memberCompanygroup.map((data,index)=>{
              return <option key={`optionGroup${data.companygroupid}`} value={data.companygroupid}>{data.name}</option>
            })
          }
        </select>
  }
};
