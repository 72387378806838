import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const Form2Q = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    var datas = { ...dataForm };

    datas = { ...datas, ["data_" + field]: valueData };

    setDataForm(datas);
  }

  function setDataObj(data) {
    var datas = { ...dataForm };

    datas = { ...datas, ...data };

    setDataForm(datas);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_2q",
      formType: "32",
    });
  }, []);

  useEffect(() => {
    setDataForm(props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([dataForm.data_1, dataForm.data_2]);
  }, [dataForm]);

  useEffect(() => {
    props.setScore(getTotalScore);
  }, [dataForm]);

  const getScoreDesc = useMemo(() => {
    if (dataForm.data_1 && dataForm.data_2) {
      if (getTotalScore > 0) {
        return "มีแนวโน้มที่จะเป็นโรคซึมเศร้า";
      } else {
        return "ปกติ ไม่เป็นโรคซึมเศร้า";
      }
    }
  }, [getTotalScore, dataForm.data_1, dataForm.data_2]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบคัดกรองภาวะซึมเศร้า 2 คำถาม (2Q)" />
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>คำถาม</th>
              <th>มี</th>
              <th>ไม่มี</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                1. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ ท่านรู้สึก หดหู่ เศร้า
                หรือท้อแท้สิ้นหวัง หรือไม่
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td>
                2. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ท่านรู้สึก เบื่อ
                ทำอะไรก็ไม่เพลิดเพลิน หรือไม่
              </td>
              <td>
                <FormElementRadio
                  fieldId={"2"}
                  value={dataForm.data_2}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"2"}
                  value={dataForm.data_2}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label=""
                />
              </td>
            </tr>
            <tr>
              <td className="uk-text-right">การแปลผล</td>
              <td colSpan={2}>{getScoreDesc}</td>
            </tr>
          </tbody>
        </table>

        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="การแปลผล" />
        </div>

        <div className="uk-width-1-1@m uk-width-1-1">
          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementLabel label="ถ้าคำตอบ ไม่มี ทั้ง 2 คำถาม ถือ ว่า ปกติ ไม่เป็นโรคซึมเศร้า" />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementLabel label="ถ้าคำตอบ มี ข้อใดข้อหนึ่งหรือทั้ง 2 ข้อ (มีอาการใดๆ ในคำถามที่ 1 และ 2 ) หมายถึง “เป็นผู้มีความเสี่ยง” หรือ “มีแนวโน้มที่จะเป็นโรคซึมเศร้า” ให้ประเมินต่อด้วยแบบประเมิน โรคซึมเศร้า 9Q 2 คำถาม (2Q)" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form2Q;
