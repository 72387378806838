import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import Api from "../../Api";
import FormElementUploadImage from "../Form/FormElementUploadImage";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchPatientVisit from "../../API/fetchPatientVisit.js";
import fetchHistoryWardByHn from "../../API/fetchHistoryWardByHn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormCathLabDischargePaper from "../FormPaper/FormCathLabDischargePaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave =
  require("../Common/FormUtil.js").transformDataBeforeSave;
var utilConvertDisplayDate =
  require("../Common/FormUtil.js").convertDisplayDate;

const HOSPITALS = [
  { value: "", label: "เลือกโรงพยาบาล" },
  { value: "06009", label: "โรงพยาบาลแม่ตื่น จ.เชียงใหม่" },
  { value: "10713", label: "โรงพยาบาลนครพิงค์ จ.เชียงใหม่" },
  { value: "10714", label: "โรงพยาบาลลำพูน จ.ลำพูน" },
  { value: "10719", label: "โรงพยาบาลศรีสังวาลย์ จ.แม่ฮ่องสอน" },
  { value: "11119", label: "โรงพยาบาลจอมทอง จ.เชียงใหม่" },
  {
    value: "11120",
    label: "โรงพยาบาลเทพรัตนเวชชานุกูล เฉลิมพระเกียรติ ๖๐ พรรษา จ.เชียงใหม่",
  },
  { value: "11121", label: "โรงพยาบาลเชียงดาว จ.เชียงใหม่" },
  { value: "11122", label: "โรงพยาบาลดอยสะเก็ด จ.เชียงใหม่" },
  { value: "11123", label: "โรงพยาบาลแม่แตง จ.เชียงใหม่" },
  { value: "11124", label: "โรงพยาบาลสะเมิง จ.เชียงใหม่" },
  { value: "11125", label: "โรงพยาบาลฝาง จ.เชียงใหม่" },
  { value: "11126", label: "โรงพยาบาลแม่อาย จ.เชียงใหม่" },
  { value: "11127", label: "โรงพยาบาลพร้าว จ.เชียงใหม่" },
  { value: "11128", label: "โรงพยาบาลสันป่าตอง จ.เชียงใหม่" },
  { value: "11129", label: "โรงพยาบาลสันกำแพง จ.เชียงใหม่" },
  { value: "11130", label: "โรงพยาบาลสันทราย จ.เชียงใหม่" },
  { value: "11131", label: "โรงพยาบาลหางดง จ.เชียงใหม่" },
  { value: "11132", label: "โรงพยาบาลฮอด จ.เชียงใหม่" },
  { value: "11133", label: "โรงพยาบาลดอยเต่า จ.เชียงใหม่" },
  { value: "11134", label: "โรงพยาบาลอมก๋อย จ.เชียงใหม่" },
  { value: "11135", label: "โรงพยาบาลสารภี จ.เชียงใหม่" },
  { value: "11136", label: "โรงพยาบาลเวียงแหง จ.เชียงใหม่" },
  { value: "11137", label: "โรงพยาบาลไชยปราการ จ.เชียงใหม่" },
  { value: "11138", label: "โรงพยาบาลแม่วาง จ.เชียงใหม่" },
  { value: "11139", label: "โรงพยาบาลแม่ออน จ.เชียงใหม่" },
  { value: "11140", label: "โรงพยาบาลแม่ทา จ.ลำพูน" },
  { value: "11141", label: "โรงพยาบาลบ้านโฮ่ง จ.ลำพูน" },
  { value: "11142", label: "โรงพยาบาลลี้ จ.ลำพูน" },
  { value: "11143", label: "โรงพยาบาลทุ่งหัวช้าง จ.ลำพูน" },
  { value: "11144", label: "โรงพยาบาลป่าซาง จ.ลำพูน" },
  { value: "11145", label: "โรงพยาบาลบ้านธิ จ.ลำพูน" },
  { value: "11507", label: "โรงพยาบาลกองบิน 41 จ.เชียงใหม่" },
  { value: "11508", label: "โรงพยาบาลค่ายกาวิละ เชียงใหม่ จ.เชียงใหม่" },
  { value: "11643", label: "โรงพยาบาลดอยหล่อ จ.เชียงใหม่" },
  { value: "11978", label: "โรงพยาบาลดารารัศมี จ.เชียงใหม่" },
  {
    value: "11992",
    label: "โรงพยาบาลเทพปัญญา 2 โรงพยาบาลทั่วไปขนาดกลาง จ.เชียงใหม่",
  },
  {
    value: "11995",
    label: "โรงพยาบาลทั่วไปขนาดใหญ่เซ็นทรัลเชียงใหม่ เมโมเรียล จ.เชียงใหม่",
  },
  {
    value: "11999",
    label: "โรงพยาบาลทั่วไปขนาดใหญ่เชียงใหม่เมดิคอลเซ็นเตอร์ จ.เชียงใหม่",
  },
  {
    value: "12279",
    label: "โรงพยาบาลส่งเสริมสุขภาพ ศูนย์อนามัยที่ 1 จ.เชียงใหม่",
  },
  { value: "12280", label: "โรงพยาบาลสวนปรุง จ.เชียงใหม่" },
  { value: "12281", label: "โรงพยาบาลประสาทเชียงใหม่ จ.เชียงใหม่" },
  { value: "12283", label: "โรงพยาบาลมะเร็งลำปาง จ.ลำปาง" },
  { value: "13775", label: "สถาบันพัฒนาการเด็กราชนครินทร์ จ.เชียงใหม่" },
  {
    value: "13780",
    label: "โรงพยาบาลมหาราชนครเชียงใหม่ มหาวิทยาลัยเชียงใหม่ จ.เชียงใหม่",
  },
  { value: "13785", label: "โรงพยาบาลธัญญารักษ์เชียงใหม่ จ.เชียงใหม่" },
  { value: "14197", label: "โรงพยาบาลธัญญรักษ์แม่ฮ่องสอน จ.แม่ฮ่องสอน" },
  {
    value: "14550",
    label: "โรงพยาบาลทั่วไปขนาดใหญ่ เชียงใหม่ใกล้หมอ จ.เชียงใหม่",
  },
  { value: "14555", label: "โรงพยาบาลทั่วไปขนาดกลางศิริเวช ลำพูน จ.ลำพูน" },
  {
    value: "14916",
    label: "โรงพยาบาลทั่วไปขนาดกลางเชียงใหม่ฮอสพิทอล จ.เชียงใหม่",
  },
  {
    value: "23736",
    label: "โรงพยาบาลวัดจันทร์ เฉลิมพระเกียรติ 80 พรรษา จ.เชียงใหม่",
  },
  {
    value: "99999",
    label: "อื่นๆ",
  },
];

const FormCathLabDischarge = (props) => {
  const formConstants = {
    formName: "form_cathlabdischarge",
    formType: "10",
  };
  const {
    patient,
    onLoading,
    setPatient,
    formId,
    profile,
    onSuccessPopupOpen,
    onErrorMessageChange,
    hn,
    txn,
  } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [patientVisit, setPatientVisit] = useState({});
  var [historyWardList, setHistoryWardList] = useState([]);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback(
    (open) => {
      setIsPreview(open);
    },
    [setIsPreview]
  );

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถดึงข้อมูลได้",
      isGoToMainPage: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถบันทึกรายการได้",
      isClose: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback(
    (message) => {
      onErrorMessageChange({
        open: true,
        header: "ไม่สามารถบันทึกได้",
        description: message,
        isClose: true,
      });
    },
    [onErrorMessageChange]
  );

  const prepareData = useCallback((form, profile, patientVisitResponse) => {
    if (!form?.data_1) {
      form.data_1 = patientVisitResponse?.tel;
    }
    if (!form?.data_2) {
      form.data_2 = patientVisitResponse?.emergency_tel;
    }
    return form;
  }, []);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const patientVisitDataRequest = fetchPatientVisit(hn, txn);
    const historyWardDataRequest = fetchHistoryWardByHn(hn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([
      patientDataRequest,
      patientVisitDataRequest,
      historyWardDataRequest,
      patientRoomRequest,
    ])
      .then((values) => {
        const patientResponse = values[0];
        const patientVisitResponse = values[1];
        const historyWardResponse = values[2];
        const patientRoomResponse = values[3];

        data = prepareData({ ...data }, profile, patientVisitResponse);
        setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
        setPatientVisit(patientVisitResponse);
        setHistoryWardList(historyWardResponse);
        setDataForm((form) => ({
          ...form,
          ...data,
          ...formConstants,
          hn,
          txn,
          patient_name: patientResponse.patient_name,
        }));
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        displayErrorFetchPopup();
      });
  });

  const fetchData = useCallback(
    async (formId, hn, txn) => {
      try {
        displayLoading();
        setDataForm({});
        let data = {};
        let hnValue, txnValue;
        if (formId) {
          data = await fetchConsultFormByFormId(formId, formConstants.formType);
          hnValue = data.hn;
          txnValue = data.txn;
        } else {
          data = await fetchConsultFormByTxn(txn, formConstants.formType);
          hnValue = hn;
          txnValue = txn;
        }
        await fetchOtherData(data, hnValue, txnValue);
      } catch (error) {
        hideLoading();
        displayErrorFetchPopup();
      }
    },
    [
      displayLoading,
      hideLoading,
      displayErrorFetchPopup,
      setDataForm,
      fetchOtherData,
    ]
  );

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null);
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn)
      fetchData(null, patient.hn, patient.txn);
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn);
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    if (!dataForm.data_3) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่จำหน่าย");
      return false;
    }
    if (!dataForm.data_4) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 24 - 48 ชั่วโมงหลังจำหน่าย");
      return false;
    }
    if (dataForm.data_15 == "1") {
      if (
        dataForm.data_41 != "1" &&
        dataForm.data_42 != "1" &&
        dataForm.data_43 != "1" &&
        dataForm.data_44 != "1"
      ) {
        displayErrorValidatePopup("จำเป็นต้องระบุ ปัจจัยเสี่ยงอย่างน้อย 1 ตัว");
        return false;
      }
    }
    if (!dataForm.data_1) {
      displayErrorValidatePopup("จำเป็นต้องระบุ เบอร์โทรผู้ป่วย");
      return false;
    }
    if (!dataForm.data_2) {
      displayErrorValidatePopup("จำเป็นต้องระบุ เบอร์โทรญาติ");
      return false;
    }
    if (!dataForm.data_8) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลการให้คำแนะนำการดูแลแผลไม่ให้โดนน้ำ 3 วัน"
      );
      return false;
    }
    if (!dataForm.data_9) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลการให้คำแนะนำหากมีเลือดออกให้กดเหนือรอยเข็มอย่างน้อย 10 นาที ถ้าไม่หยุด ให้รีบไปพบแพทย์"
      );
      return false;
    }
    if (!dataForm.data_10) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลการให้คำแนะนำการสังเกตอาการผิดปกติ เช่น เลือดออก แผลบวม ปวดแผลมาก ให้รีบไปพบแพทย์"
      );
      return false;
    }
    if (!dataForm.data_14) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลการให้คำแนะนำการหลีกเลี่ยงการออกกำลังหรือทำกิจกรรมที่ใช้กำลังมาก"
      );
      return false;
    }
    if (!dataForm.data_18) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ผลการให้คำแนะนำการหลีกเลี่ยงปัจจัยเสี่ยง เช่น อาหารที่มีรสเค็มจัด การสูบบุหรี่ การดิ่มเครื่องดื่มแอลกอฮอล์"
      );
      return false;
    }
    if (!dataForm.data_22) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ผลการให้คำแนะนำผลการรักษา");
      return false;
    }
    if (!dataForm.data_23) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ผลการให้คำแนะนำแผนการรักษา");
      return false;
    }
    if (!dataForm.data_40) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การรักษาต่อเนื่อง");
      return false;
    } else {
      if (dataForm.data_40 == "2" && !dataForm.data_27) {
        displayErrorValidatePopup("จำเป็นต้องระบุ การรักษาต่อเนื่อง");
        return false;
      }
    }
    if (!dataForm.data_29) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Medication");
      return false;
    }
    if (!dataForm.data_49) {
      displayErrorValidatePopup("จำเป็นต้องระบุ เอกสารคำแนะนำการดูแลตนเอง");
      return false;
    }
    if (!dataForm.data_30) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การนำเข้าสู่ line application consult for Same-day patient"
      );
      return false;
    }
    if (!dataForm.data_32) {
      displayErrorValidatePopup("จำเป็นต้องระบุ พยาบาลผู้ตรวจสอบข้อมูล");
      return false;
    }
    if (!dataForm.data_33) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ภาวะแทรกซ้อน");
      return false;
    }
    if (!dataForm.data_35) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความวิตกกังวล (0-10 คะแนน)"
      );
      return false;
    }
    if (!dataForm.data_36) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ ระดับความเจ็บปวดแผล (0-10 คะแนน)"
      );
      return false;
    }
    if (!dataForm.data_37) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ระดับความพึงพอใจ (0-10 คะแนน)");
      return false;
    }
    if (!dataForm.data_38) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ลงชื่อพยาบาล");
      return false;
    }
    return true;
  }, [dataForm, displayErrorValidatePopup]);

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(
            saveData,
            profile.loginToken,
            dataForm.form_id,
            profile.consultId,
            profile.doctorId,
            paperHtml,
            patient
          );
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const onUploadFileInputChange = useCallback(
    (fieldId, files) => {
      setDataForm((data) => ({ ...data, [fieldId]: files }));
    },
    [setDataForm]
  );

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Cathlab CMU discharge set box" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"1"}
            value={dataForm.data_1}
            label="เบอร์โทรผู้ป่วย"
            onChange={handleChange}
            width="100"
            regEx={/^-?\d*$/g}
            maxLength={10}
            isRequired
          />
          <FormElementTextBox
            fieldId={"2"}
            value={dataForm.data_2}
            label="เบอร์โทรญาติ"
            onChange={handleChange}
            width="100"
            regEx={/^-?\d*$/g}
            maxLength={10}
            isRequired
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table table-border">
            <thead>
              <tr>
                <th rowSpan={2}>รายละเอียด</th>
                <th colSpan={2}>
                  {/* <FormElementTextBox
                    fieldId={"3"}
                    value={dataForm.data_3}
                    type="date"
                    label="วันที่จำหน่าย"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  /> */}
                  <FormElementDropdown
                    fieldId={"3"}
                    value={dataForm.data_3}
                    label="วันที่จำหน่าย"
                    dataList={[
                      { value: "", label: "เลือกวันที่จำหน่าย" },
                    ].concat(
                      historyWardList.map((item) => ({
                        value: item.dc,
                        label: utilConvertDisplayDate(item.dc),
                      }))
                    )}
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </th>
              </tr>
              <tr>
                <th>เนื้อหาการให้คำแนะนำ</th>
                <th>ผลการให้คำแนะนำ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1. การให้คำแนะนำเกี่ยวกับการดูแลแผล</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <FormElementCheckBox
                      fieldId={"5"}
                      value={dataForm.data_5}
                      onChange={handleChange}
                      label="ตำแหน่งของแผล"
                    />
                    <FormElementCheckBox
                      fieldId={"6"}
                      value={dataForm.data_6}
                      onChange={handleChange}
                      label="ลักษณะของแผล"
                    />
                    <FormElementCheckBox
                      fieldId={"50"}
                      value={dataForm.data_50}
                      onChange={handleChange}
                      label="ภาวะแทรกซ้อน"
                    />
                    <FormElementCheckBox
                      fieldId={"51"}
                      value={dataForm.data_51}
                      onChange={handleChange}
                      label="วิธีการดูแลแผลขณะอยู่ที่บ้าน"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <FormElementCheckBox
                      fieldId={"52"}
                      value={dataForm.data_52}
                      onChange={handleChange}
                      label="เข้าใจดี"
                    />

                    <FormElementCheckBox
                      fieldId={"53"}
                      value={dataForm.data_53}
                      onChange={handleChange}
                      label="ให้คำแนะนำเพิ่มเติม"
                    />
                    <span hidden={dataForm.data_53 != "1"}>
                      <FormElementTextArea
                        fieldId={"7"}
                        value={dataForm.data_7}
                        cols={50}
                        rows={2}
                        noLimit
                        placeholder="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>2. การให้คำแนะนำเกี่ยวกับการปฏิบัติกิจวัตรประจำวัน</td>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <FormElementCheckBox
                        fieldId={"54"}
                        value={dataForm.data_54}
                        onChange={handleChange}
                        label="สัปดาห์แรกหลังตรวจให้หลีกเลี่ยงการออกกำลังหรือกิจกรรมที่ใช้กำลังมาก"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <FormElementCheckBox
                      fieldId={"55"}
                      value={dataForm.data_55}
                      onChange={handleChange}
                      label="เข้าใจดี"
                    />
                    <FormElementCheckBox
                      fieldId={"56"}
                      value={dataForm.data_56}
                      onChange={handleChange}
                      label="ให้คำแนะนำเพิ่มเติม"
                    />
                    <span hidden={dataForm.data_56 != "1"}>
                      <FormElementTextArea
                        fieldId={"13"}
                        value={dataForm.data_13}
                        cols={50}
                        rows={2}
                        noLimit
                        placeholder="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3. การควบคุมโรคที่เป็นปัจจัยเสี่ยง</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <FormElementRadio
                      fieldId={"15"}
                      value={dataForm.data_15}
                      fieldValue={"0"}
                      onChange={handleChange}
                      label="ไม่มีปัจจัยเสี่ยง"
                    />
                    <FormElementRadio
                      fieldId={"15"}
                      value={dataForm.data_15}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="มี"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                      hidden={dataForm.data_15 != "1"}
                    >
                      <FormElementCheckBox
                        fieldId={"41"}
                        value={dataForm.data_41}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="โรคเบาหวาน"
                      />
                      <FormElementCheckBox
                        fieldId={"42"}
                        value={dataForm.data_42}
                        onChange={handleChange}
                        label="ความดันโลหิตสูง"
                      />
                      <FormElementCheckBox
                        fieldId={"43"}
                        value={dataForm.data_43}
                        onChange={handleChange}
                        label="ระดับไขมันในเลือดสูง"
                      />
                      <FormElementCheckBox
                        fieldId={"44"}
                        value={dataForm.data_44}
                        onChange={handleChange}
                        label="การเลิกบุหรี่"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <FormElementCheckBox
                      fieldId={"57"}
                      value={dataForm.data_57}
                      onChange={handleChange}
                      label="เข้าใจดี"
                    />
                    <FormElementCheckBox
                      fieldId={"58"}
                      value={dataForm.data_58}
                      onChange={handleChange}
                      label="ให้คำแนะนำเพิ่มเติม"
                    />
                    <span hidden={dataForm.data_58 != "1"}>
                      <FormElementTextArea
                        fieldId={"17"}
                        value={dataForm.data_17}
                        cols={50}
                        rows={2}
                        noLimit
                        placeholder="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td rowSpan={2}>
                  4. การให้คำแนะนำเกี่ยวกับการติดตามผลการรักษา
                </td>
                <td rowSpan={2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <FormElementCheckBox
                      fieldId={"59"}
                      value={dataForm.data_59}
                      onChange={handleChange}
                      label="การเข้ารับการตรวจตามนัดครั้งถัดไปเพื่อติดตามอาการหรือวางแผนการรักษา"
                    />
                    <FormElementCheckBox
                      fieldId={"60"}
                      value={dataForm.data_60}
                      onChange={handleChange}
                      label="อาการผิดปกติที่ต้องมาพบแพทย์ก่อนวันตรวจตามนัด"
                    />
                  </div>
                </td>
                <td rowSpan={2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <FormElementCheckBox
                      fieldId={"61"}
                      value={dataForm.data_61}
                      onChange={handleChange}
                      label="เข้าใจดี"
                    />
                    <FormElementCheckBox
                      fieldId={"62"}
                      value={dataForm.data_62}
                      onChange={handleChange}
                      label="ให้คำแนะนำเพิ่มเติม"
                    />
                    <span hidden={dataForm.data_62 != "1"}>
                      <FormElementTextArea
                        fieldId={"21"}
                        value={dataForm.data_21}
                        cols={50}
                        rows={2}
                        noLimit
                        placeholder="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table table-border">
            <thead>
              <tr>
                <th colSpan={3}>
                  <FormElementTextBox
                    fieldId={"4"}
                    value={dataForm.data_4}
                    type="date"
                    label="24 - 48 ชั่วโมงหลังจำหน่าย"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </th>
              </tr>
              <tr>
                <th rowSpan={2}>เนื้อหาการประเมิน</th>
                <th colSpan={2}>
                  ผลการให้คำแนะนำ <span style={{ color: "red" }}>*</span>
                </th>
              </tr>
              <tr>
                <th>มีความรู้</th>
                <th>ไม่มีความรู้</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    - ดูแลแผลไม่ให้โดนน้ำ 3 วัน
                  </div>
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"8"}
                    value={dataForm.data_8}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"8"}
                    value={dataForm.data_8}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    - หากมีเลือดออกให้กดเหนือรอยเข็มอย่างน้อย 10 นาที ถ้าไม่หยุด
                    ให้รีบไปพบแพทย์
                  </div>
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"9"}
                    value={dataForm.data_9}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"9"}
                    value={dataForm.data_9}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    - สังเกตอาการผิดปกติ เช่น เลือดออก แผลบวม ปวดแผลมาก
                    ให้รีบไปพบแพทย์
                  </div>
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"10"}
                    value={dataForm.data_10}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"10"}
                    value={dataForm.data_10}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    - หลีกเลี่ยงการออกกำลังหรือทำกิจกรรมที่ใช้กำลังมาก
                  </div>
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"14"}
                    value={dataForm.data_14}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"14"}
                    value={dataForm.data_14}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    - หลีกเลี่ยงปัจจัยเสี่ยง เช่น อาหารที่มีรสเค็มจัด
                    การสูบบุหรี่ การดิ่มเครื่องดื่มแอลกอฮอล์
                  </div>
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"18"}
                    value={dataForm.data_18}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"18"}
                    value={dataForm.data_18}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    - ผลการรักษา
                  </div>
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"22"}
                    value={dataForm.data_22}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"22"}
                    value={dataForm.data_22}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                    - แผนการรักษา
                  </div>
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"23"}
                    value={dataForm.data_23}
                    fieldValue={"1"}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"23"}
                    value={dataForm.data_23}
                    fieldValue={"0"}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementLabel
              label="ข้อมูลที่ผู้ป่วยจะได้รับก่อนกลับบ้าน"
              fontWeight="bold"
            />
            <div
              className="uk-width-1-1@m uk-width-1-1"
              style={{ display: "inline-flex", flexWrap: "nowrap" }}
            >
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <span>
                  1. การรักษาต่อเนื่อง <span style={{ color: "red" }}>*</span>
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    paddingLeft: "32px",
                    marginTop: "14px",
                  }}
                >
                  <FormElementRadio
                    fieldId={"40"}
                    value={dataForm.data_40}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="กรณี F/U รพ.มหาราชนครเชียงใหม่"
                  />
                  <FormElementRadio
                    fieldId={"40"}
                    value={dataForm.data_40}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="กรณี F/U ต่อที่ รพ.อื่น"
                  />
                  <span hidden={dataForm.data_40 != "1"}>
                    <div>
                      <FormElementCheckBox
                        fieldId={"24"}
                        value={dataForm.data_24}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="ใบนัดที่"
                      />
                      <FormElementTextBox
                        fieldId={"25"}
                        value={dataForm.data_25}
                        label="OPD"
                        onChange={handleChange}
                        width="100"
                      />
                      <span className="formTextBoxLabel">วันที่</span>
                      <FormElementTextBox
                        fieldId={"26"}
                        value={dataForm.data_26}
                        type="date"
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <FormElementCheckBox
                      fieldId={"45"}
                      value={dataForm.data_45}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="การเตรียมตัวเมื่อมาตามนัดครั้งถัดไป (ใบส่งตัว, ยาทั้งหมด, ผล Lab, NPQI)"
                    />
                  </span>
                  <span hidden={dataForm.data_40 != "2"}>
                    <FormElementDropdown
                      fieldId={"27"}
                      value={dataForm.data_27}
                      label="ระบุ"
                      onChange={handleChange}
                      width="100"
                      dataList={HOSPITALS}
                    />
                    <span hidden={dataForm.data_27 != "99999"}>
                      <FormElementTextBox
                        placeholder="ระบุ"
                        fieldId="65"
                        value={dataForm.data_65}
                        onChange={handleChange}
                      />
                    </span>
                  </span>

                  <span hidden={dataForm.data_40 != "2"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <FormElementCheckBox
                        fieldId={"46"}
                        value={dataForm.data_46}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="ผลการตรวจสอบหัวใจ"
                      />

                      <FormElementCheckBox
                        fieldId={"47"}
                        value={dataForm.data_47}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="จดหมายตอบโรงพยาบาลต้นสังกัด"
                      />
                    </div>
                  </span>
                </div>
              </div>
              <div className="uk-padding-small" style={{ width: "50%" }}>
                2. Medication <span style={{ color: "red" }}>*</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    paddingLeft: "14px",
                    marginTop: "14px",
                  }}
                >
                  <FormElementRadio
                    fieldId={"29"}
                    value={dataForm.data_29}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="no Home med "
                  />
                  <FormElementRadio
                    fieldId={"29"}
                    value={dataForm.data_29}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="มี Home med ยาเพียงพอถึงวันนัดครั้งถัดไป / add ยาชนิดเพิ่มเติม "
                  />
                </div>
              </div>
            </div>

            <div
              className="uk-width-1-1@m uk-width-1-1"
              style={{ display: "inline-flex", flexWrap: "nowrap" }}
            >
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <div>
                  3. เอกสารคำแนะนำการดูแลตนเอง{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    paddingLeft: "32px",
                    marginTop: "14px",
                  }}
                >
                  <FormElementRadio
                    fieldId={"49"}
                    value={dataForm.data_49}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="ได้รับ"
                  />
                  <FormElementRadio
                    fieldId={"49"}
                    value={dataForm.data_49}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="ไม่ได้รับ"
                  />
                </div>
              </div>
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <div>
                  4. การนำเข้าสู่ line application consult for Same-day patient{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    paddingLeft: "32px",
                    marginTop: "14px",
                  }}
                >
                  <FormElementRadio
                    fieldId={"30"}
                    value={dataForm.data_30}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="สำเร็จ"
                  />
                  <FormElementRadio
                    fieldId={"30"}
                    value={dataForm.data_30}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="ไม่สำเร็จ"
                  />
                  <span hidden={dataForm.data_30 != "0"}>
                    <FormElementTextBox
                      fieldId={"31"}
                      value={dataForm.data_31}
                      label="เนื่องจาก "
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
              <FormElementTextBox
                fieldId={"32"}
                value={dataForm.data_32}
                label="ลงชื่อ"
                onChange={handleChange}
                width="100"
              />
              <span className="formTextBoxLabel">
                พยาบาลผู้ตรวจสอบข้อมูล <span style={{ color: "red" }}>*</span>
              </span>
            </div>
          </div>

          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
            <FormElementLine />
            <FormElementLabel
              label="การติดตามผู้ป่วย 24 - 48 ชั่วโมงหลังตรวจ"
              fontWeight="bold"
            />
            <div
              className="uk-width-1-1@m uk-width-1-1"
              style={{ display: "inline-flex", flexWrap: "nowrap" }}
            >
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <span className="formTextBoxLabel">
                  1. ภาวะแทรกซ้อน <span style={{ color: "red" }}>*</span>
                </span>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                    marginRight: "16px",
                  }}
                >
                  <FormElementRadio
                    fieldId={"33"}
                    value={dataForm.data_33}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="ไม่มี "
                  />
                  <FormElementRadio
                    fieldId={"33"}
                    value={dataForm.data_33}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="มี "
                  />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                  hidden={dataForm.data_33 != "1"}
                >
                  <FormElementRadio
                    fieldId={"48"}
                    value={dataForm.data_48}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="hematoma "
                  />
                  <FormElementRadio
                    fieldId={"48"}
                    value={dataForm.data_48}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="bleeding "
                  />
                  <FormElementRadio
                    fieldId={"48"}
                    value={dataForm.data_48}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="อื่นๆ"
                  />
                  <span hidden={dataForm.data_48 != "3"}>
                    <FormElementTextBox
                      fieldId={"34"}
                      value={dataForm.data_34}
                      placeholder="ระบุ"
                      onChange={handleChange}
                      width="100"
                    />
                  </span>
                </div>
              </div>
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <FormElementDropdown
                  fieldId={"35"}
                  value={dataForm.data_35}
                  label="2. ระดับความวิตกกังวล (0-10 คะแนน)"
                  dataList={[
                    { value: "", label: "-" },
                    { value: "0", label: "0" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                    { value: "6", label: "6" },
                    { value: "7", label: "7" },
                    { value: "8", label: "8" },
                    { value: "9", label: "9" },
                    { value: "10", label: "10" },
                  ]}
                  onChange={handleChange}
                  width="100"
                  isRequired
                />
              </div>
            </div>
            <div
              className="uk-width-1-1@m uk-width-1-1"
              style={{ display: "inline-flex", flexWrap: "nowrap" }}
            >
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <FormElementDropdown
                  fieldId={"36"}
                  value={dataForm.data_36}
                  label="3. ระดับความเจ็บปวดแผล (0-10 คะแนน)"
                  dataList={[
                    { value: "", label: "-" },
                    { value: "0", label: "0" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                    { value: "6", label: "6" },
                    { value: "7", label: "7" },
                    { value: "8", label: "8" },
                    { value: "9", label: "9" },
                    { value: "10", label: "10" },
                  ]}
                  onChange={handleChange}
                  width="100"
                  isRequired
                />
              </div>
              <div className="uk-padding-small" style={{ width: "50%" }}>
                <FormElementDropdown
                  fieldId={"37"}
                  value={dataForm.data_37}
                  label="4. ระดับความพึงพอใจ (0-10 คะแนน)"
                  dataList={[
                    { value: "", label: "-" },
                    { value: "0", label: "0" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                    { value: "6", label: "6" },
                    { value: "7", label: "7" },
                    { value: "8", label: "8" },
                    { value: "9", label: "9" },
                    { value: "10", label: "10" },
                  ]}
                  onChange={handleChange}
                  width="100"
                  isRequired
                />
              </div>
            </div>
            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
              <FormElementTextBox
                fieldId={"38"}
                value={dataForm.data_38}
                label="ลงชื่อ"
                onChange={handleChange}
                width="100"
              />
              <span className="formTextBoxLabel">พยาบาล</span>
              <span className="formTextBoxLabel">วันที่</span>
              <FormElementTextBox
                fieldId={"39"}
                value={dataForm.data_39}
                label=""
                type="date"
                onChange={handleChange}
                width="100"
              />
            </div>
            <div
              className="uk-width-1-1@m uk-width-1-1 uk-padding-small"
              style={{ width: "100%" }}
            >
              <FormElementUploadImage
                value={dataForm.data_63}
                onChange={(files) => onUploadFileInputChange("data_63", files)}
                maxLength={6}
              />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
              <span hidden={!dataForm.form_id}>
                <FormElementTextArea
                  fieldId={"64"}
                  rows={5}
                  cols={100}
                  value={dataForm.data_64}
                  noLimit
                  onChange={handleChange}
                  label="เหตุผลในการแก้ไข"
                  width="100"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={
          <FormCathLabDischargePaper
            dataForm={dataForm}
            patient={patient}
            profile={profile}
          />
        }
      />
    </div>
  );
};

export default FormCathLabDischarge;
