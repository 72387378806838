import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

export default class Error404 extends Component {
  render() {
    return <div className="uk-position-relative uk-height-1-1">
      <div className="uk-text-center" style={{paddingTop: 100}}>
        <span className="icon-ban" style={{fontSize: '100px', color: '#ff425c'}}></span>
        <h1 className="uk-margin-remove" style={{paddingTop: 20}}>404</h1>
        <p className="uk-margin-remove">Page not found.</p>
      </div>
    </div>
  }
};
