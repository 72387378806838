import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactEcharts from 'echarts-for-react';
import Api from '../../Components/Api';
import SelectMemberCompanygroup from '../../Components/Member/SelectMemberCompanygroup';
import FullScreenElement from '../FullScreenElement';

UIkit.use(Icons);

export default class CampaignReportActivity extends Component {
  state = {
    selectMemberCompanyGroup: 'all',
    chatM: [],
    chatC: [],
    avgActiveUser: [],
    monthGraph: [],
    user: []
  }

  month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  componentDidMount() {
    this.dataGet();
  }

  dataGet = async () => {
    var { chatM, chatC, avgActiveUser, monthGraph, user } = await Api.member('CampaignReportActivity', { campaignId: this.props.id, groupId: this.state.selectMemberCompanyGroup });

    this.setState({
      chatM: chatM ? chatM.reverse() : [],
      chatC: chatC ? chatC.reverse() : [],
      avgActiveUser: avgActiveUser ? avgActiveUser.reverse() : [],
      monthGraph: monthGraph ? monthGraph.reverse() : [],
      user: user ? user.reverse() : []
    });
  }

  renderGraph = () => {
    var option = {

      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25
      },

      // Add tooltip
      tooltip: {
        trigger: 'axis'
      },

      // Add legend
      legend: {
        data: ['Chat', 'Advice', 'Active', 'user']
      },

      // Add custom colors
      color: ['#F98E76', '#16D39A', '#2DCEE3', '#FF7588', '#626E82'],

      // Enable drag recalculate
      calculable: true,

      // Hirozontal axis
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: this.state.monthGraph.map(data => { return this.month[+data - 1]; }),
        z: 10,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Vertical axis
      yAxis: [{
        type: 'value',
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#3975AF'
          }
        },
        axisLabel: {
          color: '#000'
        }
      }],

      // Add series
      // Add series
      series: [
        {
          name: 'Chat',
          type: 'line',
          stack: 'Total',
          symbol: 'circle',
          data: this.state.chatM
        },
        {
          name: 'Advice',
          type: 'line',
          stack: 'Total',
          symbol: 'circle',
          data: this.state.chatC
        },
        {
          name: 'Active',
          type: 'line',
          stack: 'Total',
          symbol: 'circle',
          data: this.state.avgActiveUser
        },
        {
          name: 'user',
          type: 'line',
          stack: 'Total',
          symbol: 'circle',
          data: this.state.user
        }
      ]
    };
    return <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{height: 400}}
      theme={"theme_name"}
    />
  }

  render() {
    return <div className="uk-card uk-card-default uk-padding-small" id="d-campaign-report-activity">
      <div className="d-card-header">
        <div className="d-card-header-left">
          Activity
        </div>
        <div className="d-card-header-right">
          <span className='icon-reload' onClick={this.dataGet}></span>
          <FullScreenElement element="#d-campaign-report-activity" />
        </div>
      </div>
      <div className="uk-text-right uk-margin-top uk-margin-bottom">
        <SelectMemberCompanygroup id={this.props.id} callback={(result) => this.setState({ SelectMemberCompanygroup: result }, () => {
          this.dataGet();
        })} />
      </div>
      <div uk-grid="" className="uk-grid-small uk-child-width-1-4@s uk-child-width-1-2 uk-margin-small-bottom">
        <div className="uk-visible@s"></div>
        <div className="uk-text-left uk-text-center@s">Total Chat <span className="uk-text-success">{this.state.chatM[this.state.chatM.length-1]}</span></div>
        <div className="uk-text-right uk-text-center@s">Total Advice <span className="uk-text-success">{this.state.chatC[this.state.chatC.length-1]}</span></div>
        <div className="uk-visible@s"></div>
      </div>
      {this.renderGraph()}
    </div>
  }
};
