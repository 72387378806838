import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';
import saveConsultForm from '../../API/saveConsultForm.js';
import fetchConsultFormByFormId from '../../API/fetchConsultFormByFormId.js';
import fetchConsultFormByTxn from '../../API/fetchConsultFormByTxn.js';
import fetchMemberByTxn from '../../API/fetchMemberByTxn.js';
import FormActionButtonSection from '../Common/FormActionButtionSection.js';
import FormFearFallingPaper from '../FormPaper/FormFearFallingPaper.js';
import FormMemberProfileHeader from '../Common/FormMemberProfiileHeader.js';
import fetchMemberListHis from '../../API/fetchMemberListHis.js';

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormFearFalling = (props) => {
    const formConstants = {
        formName: "form_fearfalling",
        formType: "35"
    };
    const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    const [isPreview, setIsPreview] = useState(false);

    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    useEffect(() => {
        console.log('data', data);
    }, [data])

    const handlePreviewOpenChange = useCallback((open) => {
        setIsPreview(open);
    }, [setIsPreview])

    const displayLoading = useCallback(() => {
        onLoading(true);
    }, [onLoading]);

    const hideLoading = useCallback(() => {
        onLoading(false);
    }, [onLoading]);

    const displayErrorFetchPopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
    }, [onErrorMessageChange]);

    const displayErrorSavePopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
    }, [onErrorMessageChange]);

    const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
        try {
            displayLoading();
            setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
            hideLoading();
            document.body.style.overflow = "hidden";
        } catch (error) {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
            document.body.style.overflow = "hidden";
        }
    });

    const fetchOtherData = useCallback(async (data, hn, txn) => {
        const patientData = fetchMemberByTxn(hn, txn);
        const patientRoomRequest = fetchMemberListHis(hn, txn);
        Promise.all([patientData, patientRoomRequest]).then((values) => {
            const patientResponse = values[0];
            const patientRoomResponse = values[1];

            setPatient({ ...patientResponse, ...patientRoomResponse });
            setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
            hideLoading();
        }).catch((error) => {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
        });
    });

    const fetchData = useCallback(async (formId, hn, txn) => {
        try {
            displayLoading();
            setDataForm({});
            let data = {};
            let hnValue, txnValue;
            if (formId) {
                data = await fetchConsultFormByFormId(formId, formConstants.formType);
                hnValue = data.hn;
                txnValue = data.txn;
            } else {
                data = await fetchConsultFormByTxn(txn, formConstants.formType);
                hnValue = hn;
                txnValue = txn;
            }
            await fetchOtherData(data, hnValue, txnValue);
        } catch (error) {
            hideLoading();
            displayErrorFetchPopup();
        }
    }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

    // fetch data when has formId #Edit
    useEffect(() => {
        if (!isSubForm) {
            if (formId) fetchData(formId, null, null)
        }
    }, [formId, isSubForm]);

    // fetch data when formId is null and hn,txn are not null #Add or this is subForm
    useEffect(() => {
        if (!isSubForm) {
            if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
        }
    }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

    // use for subForm
    useEffect(() => {
        if (isSubForm) {
            fetchSubFormOtherData(data, data.hn, data.txn);
        }
    }, [isSubForm, data]);

    useEffect(() => {
        if (!isSubForm) {
            if (!formId && hn && txn) fetchData(null, hn, txn)
        }
    }, [hn, txn, formId, isSubForm]);

    const validateSave = useCallback(() => {
        return true;
    }, [])

    const handleSave = useCallback(async (html) => {
        async function saveForm(paperHtml) {
            displayLoading();
            try {
                if (validateSave()) {
                    const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
                    handlePreviewOpenChange(false);
                    await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
                    hideLoading();
                    onSuccessPopupOpen(true);
                } else {
                    hideLoading();
                }
            } catch (error) {
                hideLoading();
                displayErrorSavePopup();
            }
        }
        saveForm(html);
    });

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1n,
            dataForm.data_2n,
            dataForm.data_3n,
            dataForm.data_4n,
            dataForm.data_5n,
            dataForm.data_6n,
            dataForm.data_7n,
        ]);
    }, [dataForm]);

    const handleAddDataToParent = useCallback((html) => {
        onAdd(dataForm, html, getTotalScore);
    }, [dataForm, onAdd, getTotalScore]);

    return (
        <>
            {isSubForm &&
                <FormMemberProfileHeader
                    memberData={patient}
                    onSelectMember={() => { }}
                    isSelectDisabled={true}
                />}
            <div uk-grid="" className="uk-grid uk-padding-small">

                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTitle label="Fear of falling (Form ที่ใช้ใน Post-falling assessment)" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTitle label="แบบประเมินความกังวลหรือกลัวการหกล้ม (ฉบับย่อ)" />
                </div>


                <div className="uk-width-1-1@m uk-width-1-1">
                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                        <thead>
                            <tr>
                                <th colSpan={6}>
                                    ต่อไปนี้ ผู้วิจัยขอสอบถามว่าท่านมีความกังวลอย่างไรต่อความเป็นไปได้ที่จะหกล้ม กรุณาตอบคำถามโดยคำนึงว่า โดยปกติท่านทำกิจกรรมเหล่านี้อย่างไร หากปัจจุบัน ท่านไม่ได้ทำกิจกรรมเหล่านี้ด้วยตัวเอง<br />
                                    กรุณาตอบคำถามโดยคำนึงว่าท่านมีความกังวลอย่างไรเกี่ยวกับการหกล้มถ้าท่านทำกิจกรรมเหล่านี้ด้วยตัวเอง สำหรับกิจกรรมดังต่อไปนี้ กรุณาทำเครื่องหมายในช่องที่ใกล้เคียงมากที่สุดกับความเห็นของท่านในเรื่องความกังวลว่า<br />
                                    อาจจะหกล้มหากท่านทำกิจกรรมเหล่านี้
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>ไม่กังวลหรือไม่กลัว (1)</th>
                                <th>กังวลหรือกลัว เล็กน้อย (2)</th>
                                <th>กังวลหรือกลัว ปานกลาง (3)</th>
                                <th>กังวลหรือกลัว มาก (4)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>การใส่หรือถอดเสื้อผ้า</td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>การอาบน้ำ</td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>การนั่งหรือลุกจากเก้าอี้</td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>การขึ้นหรือลงบันได</td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>การเอื้อมหยิบของเหนือศีรษะ หรือก้มลงเก็บของบนพื้น</td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>การเดินขึ้นหรือลงที่ลาดชัน</td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>การออกไปร่วมกิจกรรมทางสังคม เช่น กิจกรรมทางศาสนา การรวมญาติ หรือกิจกรรมชมรม</td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>คะแนนรวมทั้งหมด</td>
                                <td colSpan={4}>{getTotalScore}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <FormActionButtonSection
                    isPreview={isPreview}
                    onOpen={() => handlePreviewOpenChange(true)}
                    onClose={() => handlePreviewOpenChange(false)}
                    onSave={handleSave}
                    onAdd={(html) => handleAddDataToParent(html)}
                    isSubForm={isSubForm}
                    formContent={
                        <FormFearFallingPaper
                            dataForm={dataForm}
                            patient={patient}
                            profile={profile}
                        />}
                />
            </div>
        </>

    )
}

export default FormFearFalling;


