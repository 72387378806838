import React, { useState, useEffect, useCallback, useMemo } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import painImg from "../../../images/pain.png";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

import "../../../css/dietz.css";

var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;
var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;

const FormFammedPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const [data2Disabled, setData2Disabled] = useState([false]);
  var [hn, setHn] = useState(false);
  var [txn, setTxn] = useState(false);
  const { memberProfile, profile } = props;

  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  var [drugList, setDrugList] = useState([]);
  var [drugAllergics, setDrugAllergics] = useState([]);

  useEffect(() => {
    props.addValue({
      formName: "form_fammed",
      formType: "1",
    });
  }, []);

  useEffect(() => {
    setHn(props.hn);
    setTxn(props.txn);
  }, [props.hn, props.txn]);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getADLAndIADLTotalScore = useMemo(() => {
    return utilSumTotalScore([dataForm.data_97n, dataForm.data_96n]);
  }, [dataForm.data_97n, dataForm.data_96n]);

  async function getDataDrug() {
    var result = await Api.consult("GetDrugByTxn", {
      hn: props.hn,
      txn: props.txn,
      patientType: "o",
    });

    var { list } = result;
    let drugList = [];

    if (list.data.length > 0) {
      setDrugList(list.data[0]);

      for (const drug of list.data[0]) {
        if (drug.tele_drug_type != "") {
          drugList.push(drug.tele_drug_type);
        }
      }
    } else {
      setDrugList([]);
    }
  }

  async function getDrugAllergic() {
    try {
      if (props.hn && props.txn) {
        var result = await Api.consult("GetDrugAllergic", {
          hn: props.hn,
        });

        if (result.list.data.allergic_desc[0].list) {
          setDrugAllergics(result.list.data.allergic_desc[0].list);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDataDrug();
    getDrugAllergic();
  }, [props.hn, props.txn]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="HOME VISIT RECORD FORM (CMU 2023)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <div
          style={{
            paddingTop: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <div>
              <div style={{ marginBottom: "10px" }}>
                {dataForm.data_1 == "1" && (
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      value={dataForm.data_1}
                      mode="view"
                      translate={{ 1: "Virtual OPD" }}
                    />
                    <FormElementLabel label="Method:" mode="view" />
                    <FormElementLabel
                      value={dataForm.data_90}
                      mode="view"
                      translate={{ 1: "Video call", 2: "Phone call" }}
                    />
                  </div>
                )}
              </div>
              <div style={{ marginBottom: "10px" }}>
                {dataForm.data_89 == "1" && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      <FormElementLabel
                        value={dataForm.data_89}
                        mode="view"
                        translate={{ 1: "Home visit" }}
                      />
                      <FormElementLabel label="Types:" mode="view" />
                      <FormElementLabel
                        value={dataForm.data_91}
                        mode="view"
                        translate={{
                          1: "Transitional case",
                          2: "Continuous case",
                        }}
                      />
                      <span hidden={dataForm.data_91 != "2"}>
                        <FormElementLabel
                          value={dataForm.data_92}
                          mode="view"
                          translate={{ 1: "General Fammed", 2: "Palliative" }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        marginLeft: "72px",
                      }}
                    >
                      <FormElementLabel label="Methods" mode="view" />
                      <FormElementLabel
                        value={dataForm.data_93}
                        mode="view"
                        translate={{ 1: "Tele" }}
                      />
                      <FormElementLabel
                        value={dataForm.data_95}
                        mode="view"
                        translate={{ 1: "Video call", 2: "Phone call" }}
                      />
                      <FormElementLabel
                        value={dataForm.data_94}
                        mode="view"
                        translate={{ 1: "On-site" }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <FormElementLabel
                  value={dataForm.data_2}
                  mode="view"
                  label="Objective: "
                />
                <div style={{ display: "inline-flex", gap: "4px" }}>
                  {typeof dataForm.data_2 == "object" &&
                    dataForm.data_2 &&
                    dataForm.data_2.map((item) => <span>{item.value}</span>)}
                </div>
              </div>
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                <span
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  Problem List:
                </span>
                <div style={{ display: "inline-flex", gap: "4px" }}>
                  <FormElementLabel
                    value={dataForm.data_104}
                    translate={{ 1: "HTN" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_105}
                    translate={{ 1: "DLP" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_106}
                    translate={{ 1: "DM" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_107}
                    translate={{ 1: "CVA" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_108}
                    translate={{ 1: "HD" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_109}
                    translate={{ 1: "COPD" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_110}
                    translate={{ 1: "OA" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_111}
                    translate={{ 1: "Dementia" }}
                    mode="view"
                  />
                </div>
              </div>
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                <FormElementTextArea
                  rows={10}
                  cols={50}
                  value={dataForm.data_3}
                  mode="view"
                />
              </div>
            </div>

            <div>
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                <span style={{ paddingRight: "10px" }}>Current Medication</span>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        ลำดับ
                      </th>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        ชื่อยา
                      </th>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        วิธีใช้
                      </th>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        จำนวน
                      </th>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        สถานะการปรับเปลี่ยนยา
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {drugList.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <div className="uk-padding-small uk-text-center">
                            ไม่พบข้อมูล
                          </div>
                        </td>
                      </tr>
                    ) : (
                      drugList.map((data, key) => {
                        return (
                          <tr key={`row${data.code}`}>
                            <td
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              {key + 1}
                            </td>
                            <td
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              {data.name}
                            </td>
                            <td
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              <article>{data.sig}</article>
                              <article>{data.sigadd}</article>
                              <article>{data.signote}</article>
                            </td>
                            <td
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              {data.amt}
                            </td>
                            <td></td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <FormElementTextArea
                  rows={10}
                  cols={50}
                  value={dataForm.data_5}
                  mode="view"
                />
                <span style={{ paddingRight: "10px" }}>Drug Allergy</span>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Group
                      </th>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Sym
                      </th>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Date_add
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {drugAllergics.map((item) => (
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {item.group}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {item.sym}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextBox
                            value={item.date_add}
                            type="date"
                            mode="view"
                          />
                        </td>
                      </tr>
                    ))}
                    {dataForm.data_114?.map((item) => (
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {item.group}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          {item.sym}
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextBox
                            value={item.date_add}
                            type="date"
                            mode="view"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  display: "inline-flex",
                  gap: "8px",
                }}
              >
                <span>Vaccination:</span>
                <FormElementLabel
                  value={dataForm.data_10}
                  mode="view"
                  translate={{ 1: "Influenza", 2: "Tetanus", 3: "Others" }}
                />
                <span hidden={dataForm.data_10 != "3"}>
                  <FormElementTextBox value={dataForm.data_11} mode="view" />
                </span>
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormElementTitle label="Palliative care" mode="view" />
              <div style={{ display: "inline-flex", gap: "8px" }}>
                <span>1.1 Advance care plan</span>
                <FormElementLabel
                  value={dataForm.data_98}
                  mode="view"
                  translate={{ 1: "มี", 0: "ไม่มี" }}
                />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                  paddingLeft: "20px",
                }}
              >
                <span>DNR</span>
                <FormElementLabel
                  value={dataForm.data_99}
                  mode="view"
                  translate={{ 1: "Full", 2: "Other" }}
                />
                <FormElementLabel label={dataForm.data_100} mode="view" />
              </div>
              <div style={{ display: "inline-flex", gap: "8px" }}>
                <span>1.2 Living will</span>
                <FormElementLabel
                  value={dataForm.data_101}
                  mode="view"
                  translate={{ 1: "มี", 0: "ไม่มี" }}
                />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                  paddingLeft: "20px",
                }}
              >
                <span>DNR</span>
                <FormElementLabel
                  value={dataForm.data_102}
                  mode="view"
                  translate={{ 1: "Full", 2: "Other" }}
                />
                <FormElementLabel label={dataForm.data_103} mode="view" />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                }}
              >
                <span>2. PPS</span>
                <FormElementLabel label={dataForm.data_13n} mode="view" />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                }}
              >
                <span>3. ESAS</span>
                <FormElementLabel label={dataForm.data_14} mode="view" />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                }}
              >
                <span>4. POS</span>
                <FormElementLabel label={dataForm.data_15} mode="view" />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                }}
              >
                <span>5. Good death</span>
                <FormElementLabel label={dataForm.data_16} mode="view" />
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                }}
              >
                <span>Functional assessment</span>
                <FormElementLabel
                  value={dataForm.data_7}
                  mode="view"
                  translate={{
                    1: "Bed-ridden",
                    2: "Home bound",
                    3: "Restriction",
                  }}
                />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "8px",
                }}
              >
                <span>ADLS</span>
                <FormElementLabel label={dataForm.data_96n} mode="view" />
                <span>IADLS</span>
                <FormElementLabel label={dataForm.data_97n} mode="view" />
                <span>คะแนนรวม: {getADLAndIADLTotalScore}</span>
              </div>
            </div>

            <div>
              <div>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                        colSpan={2}
                      >
                        ILLNESS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Idea:
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextArea
                          rows={2}
                          cols={80}
                          value={dataForm.data_29}
                          mode="view"
                          label=""
                          width="50"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Feeling:
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextArea
                          rows={2}
                          cols={80}
                          value={dataForm.data_30}
                          mode="view"
                          label=""
                          width="50"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Function:
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextArea
                          rows={2}
                          cols={80}
                          value={dataForm.data_31}
                          mode="view"
                          label=""
                          width="50"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Expectation:
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextArea
                          rows={2}
                          cols={80}
                          value={dataForm.data_32}
                          mode="view"
                          label=""
                          width="50"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <FormElementLabel
                mode="view"
                label="Problem List and Plan of Management (SOAP): "
              />
              <div style={{ marginTop: "8px" }}>
                {dataForm?.data_116?.map((item, i) => (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                      marginBottom: "8px",
                    }}
                  >
                    <strong>ครั้งที่ {i + 1}</strong>
                    <FormElementTextBox label="S:" value={item.s} mode="view" />
                    <FormElementTextBox label="O:" value={item.o} mode="view" />
                    <FormElementTextBox label="A:" value={item.a} mode="view" />
                    <FormElementTextBox label="P:" value={item.p} mode="view" />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <FormElementLabel mode="view" label="Follow up: " />
              <div>
                <FormElementTextArea
                  rows={10}
                  cols={80}
                  value={dataForm.data_34}
                  mode="view"
                  label=""
                  width="50"
                />
              </div>
            </div>
            <div>
              <FormElementLabel mode="view" label="Plan for next HV: " />
              <div>
                <FormElementTextArea
                  rows={10}
                  cols={80}
                  value={dataForm.data_35}
                  mode="view"
                  label=""
                  width="50"
                />
              </div>
            </div>
            <div>
              <div>
                <FormElementTextBox
                  value={dataForm.data_36}
                  mode="view"
                  label="Medical Student/Physician: "
                  width="50"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: "40px",
            }}
          >
            <div>
              <div>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          width: "100px",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                        rowSpan={7}
                      >
                        PSY
                      </th>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_37n}
                            mode="view"
                            label="TIMSE: "
                            width="50"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_38n}
                            mode="view"
                            label="MOCA: "
                            width="50"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_39n}
                            mode="view"
                            label="2Q: "
                            width="50"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_40n}
                            mode="view"
                            label="PHQ-9: "
                            width="50"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_41n}
                            mode="view"
                            label="TGDS: "
                            width="50"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_42n}
                            mode="view"
                            label="GAD-7: "
                            width="50"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_43}
                            mode="view"
                            label="Other: "
                            width="50"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          width: "80px",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                        rowSpan={3}
                      >
                        PE
                      </th>
                      <td
                        rowSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        V/S
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_44}
                            mode="view"
                            label="BT: "
                          />
                        </div>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_45}
                            mode="view"
                            label="BP1: "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_46}
                            mode="view"
                            label="BP2: "
                          />
                        </div>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_47}
                            mode="view"
                            label="HR: "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_48}
                            mode="view"
                            label="RR: "
                          />
                        </div>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextBox
                            value={dataForm.data_49}
                            mode="view"
                            label="O2sat: "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        GA
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_50}
                            mode="view"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        HEENT
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_51}
                            mode="view"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Heart
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_52}
                            mode="view"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Lung
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_53}
                            mode="view"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Abd
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_54}
                            mode="view"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Ext
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_55}
                            mode="view"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Neuro
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_56}
                            mode="view"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: "#EFF0F3",
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Other
                      </th>
                      <td
                        colSpan={3}
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <div>
                          <FormElementTextArea
                            rows={1}
                            cols={80}
                            value={dataForm.data_57}
                            mode="view"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            flexDirection: "column",
                          }}
                        >
                          {dataForm.data_113?.map((item) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{item.name}</span>
                              <img src={item.base64} width={400} />
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                {dataForm?.painImg && (
                  <img
                    src={dataForm?.painImg}
                    alt="pain_image"
                    width={800}
                    height={400}
                  />
                )}
                <div
                  style={{
                    display: "inline-flex",
                    gap: "14px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {dataForm?.data_115?.map((item) => (
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <table
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                width: "50px",
                                background: "#EFF0F3",
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              Pain
                            </th>
                            <th
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              #{item.id} {item.pain}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th
                              style={{
                                background: "#EFF0F3",
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              <FormElementLabel
                                value={item.type}
                                translate={{
                                  1: "Neuropathic",
                                  2: "Nociceptive",
                                  3: "Visceral",
                                }}
                                mode="view"
                              />
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                background: "#EFF0F3",
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              Pain Score
                            </th>
                            <td
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid grey",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "4px",
                                }}
                              >
                                <FormElementTextBox
                                  label="Min"
                                  value={item.min}
                                  mode="view"
                                />
                                <FormElementTextBox
                                  label="Max"
                                  value={item.max}
                                  mode="view"
                                />
                                <FormElementTextBox
                                  label="Mean"
                                  value={item.mean}
                                  mode="view"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div style={{ marginTop: "14px" }}>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            background: "#EFF0F3",
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                          colSpan={2}
                        >
                          Other assessment tools
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th
                          style={{
                            background: "#EFF0F3",
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Post-falling assessment
                        </th>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextBox
                            value={dataForm.data_78}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            background: "#EFF0F3",
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Zarit-12
                        </th>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextBox
                            value={dataForm.data_79n}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            background: "#EFF0F3",
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Drug adherence
                        </th>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextBox
                            value={dataForm.data_80n}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <div>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            background: "#EFF0F3",
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                          colSpan={2}
                        >
                          Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Nutrition:
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextArea
                            rowSpan={2}
                            value={dataForm.data_81}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Exercise:
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextArea
                            rowSpan={2}
                            value={dataForm.data_82}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Home environment (Safety):
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextArea
                            rowSpan={2}
                            value={dataForm.data_83}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Spiritual:
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextArea
                            rowSpan={2}
                            value={dataForm.data_84}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          Caregiven:
                        </td>
                        <td
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid grey",
                          }}
                        >
                          <FormElementTextArea
                            rowSpan={2}
                            value={dataForm.data_85}
                            fieldValue={"1"}
                            mode="view"
                            label=""
                            width="50"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormFammedPaper;
