import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../../Components/Api';
import HeaderSub from '../../Components/Member/HeaderSub';
import numeral from 'numeral';
import ImageUri from '../../Components/ImageUri';
import  moment from 'moment';

UIkit.use(Icons);

export default class PrescriptionList extends Component {
  state = {
    list: []
  }
  
  listGet = async () => {
    var _resposne = await Api.member("PrescriptionList");

    this.setState({list: _resposne.data || []});
  }

  componentDidMount () {
    this.listGet();
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="ใบสั่งยา" />
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">
          <div className='d-card-header-left'>ใบสั่งยาของฉัน</div>
        </div>
        <div className="d-table-container uk-margin-small-top">
          <table className='uk-table uk-table-divider uk-table-small uk-table-striped' id="d-prescription-table">
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>เลขที่เอกสาร</th>
                <th>ประเภทใบสั่งยา</th>
                <th>ชื่อนามสกุลคนไข้</th>
                <th>ชื่อผู้ออกใบสั่งยา</th>
                <th>HN</th>
                <th>หมายเลขโทรศัพท์</th>
                <th>ยอดรวมสุทธิชำระเงิน</th>
                <th>วิธีการจัดส่ง</th>
                <th>สถานะล่าสุด</th>
                <th>เอกสารประกอบ</th>
              </tr>
            </thead>
            <tbody>
              {this.state.list.length ? 
                this.state.list.map((data, index)=>{
                  return <tr key={`prescription${index}`}>
                    <td>{index+1}</td>
                    <td><a href={"/prescription-file?token=" + data.token_file} target="_blank" rel="noopenner">{data.content.option.id}</a></td>
                    <td>{data.content.option.type || 'ใบสั่งยาสร้างจากระบบ'}</td>
                    <td>{data.content.option.customer_name}</td>
                    <td>{data.content.option.admin_name}</td>
                    <td>{data.content.option.hospital_number}</td>
                    <td>{data.tel}</td>
                    <td>{numeral(data.content.option.price_summary).format('0,0.00')}</td>
                    <td>{data.shipping}</td>
                    <td>
                      {data.content.option.status}
                      
                      <button
                           uk-icon="history"
                            className="d-prescription-link"
                            onClick={() => {
                              data.logView = !data.logView;

                              this.forceUpdate();
                            }}
                          >
                          </button>
                          {data.logView === true &&
                          Array.isArray(data.statuslog) === true && data.statuslog.length ? (
                            <ul>
                              {data.statuslog.map((log) => {
                                return (
                                  <li>
                                    <strong>{log.status}</strong>
                                    <div className="uk-margin-small-left uk-text-meta">
                                      <div>
                                        วันที่{" "} : 
                                        {moment(
                                          log.date,
                                          "YYYY-MM-DD HH:mm:ss"
                                        ).format("DD/MM/YY")}
                                      </div>
                                      <div>
                                        เวลา{" "} : 
                                        {moment(
                                          log.date,
                                          "YYYY-MM-DD HH:mm:ss"
                                        ).format("HH:mm")}
                                      </div>
                                      <div>บันทึกโดย : {log.consult}</div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : null}
                    </td>
                    <td>
                    {
                      Array.isArray(data.files) && <ul>{data.files.map((file, file_index) => {
                        return <li key={`file${file_index}`}>
                        <strong>
                          {file.file ? 
                          <span uk-lightbox="">
                            <a href={ImageUri(file.file)} target="_blank" rel="noopenner" >{file.name}</a>
                          </span> : (file.name || 'ไม่พบชื่อไฟล์')}
                        </strong>
                        </li>
                      })}</ul>
                    }
                    </td>
                  </tr>
                })
                :
                <tr>
                  <td colSpan={'100%'} className='uk-padding uk-text-center'>ไม่พบรายการ</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  }
};
