import Api from "../../Components/Api";

async function fetchNxOpdList() {
    try {
        var result = await Api.consult("GetNxOPDList", {});

        if (!result?.list?.data) return [];

        const data = result.list.data;
        const dataArray = Object.keys(data);
        const list = dataArray.map((item) => ({
            value: item,
            label: data[item],
        }));

        return list;
    } catch (error) {
        console.error(`fetchNxOpdList`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchNxOpdList;