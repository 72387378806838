import React, { useState, useEffect } from 'react';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import '../css/dietz.css';

UIkit.use(Icons);

const SaveSuccessfulMessage = (props) => {
    const { show } = props;

    function onClose() {
        props.onClose();
    }

    useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [show])

    return (
        <>
            {show ? <div uk-modal="esc-close: false; bg-close: false;" className='popup-container'>
                <div className="uk-modal-dialog uk-margin-auto-vertical dialog-popup-container" >
                    <div className="uk-modal-body uk-text-center">
                        <span className='save-successful-icon' uk-icon="icon: check; ratio: 3"></span>
                        <h3>บันทึกข้อมูลสำเร็จ</h3>
                    </div>
                    <div className="uk-modal-footer uk-text-center">
                        <button className="uk-button uk-button-default" type="button" onClick={onClose}>ปิด</button>
                    </div>
                </div>
            </div> : null}
        </>
    )
}

export default SaveSuccessfulMessage;
