import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

import "../../../css/dietz.css";
import FormElementTextArea from "../Form/FormElementTextArea";

const FormNursingAssessmentPaper = (props) => {
  const { dataForm, patient, profile } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="Nursing Assessment" mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <FormElementLabel label="Health assessment 1" mode="view" />
        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="1.การรับรู้เกี่ยวกับการดูแลสุขภาพ"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0px 0px 40px",
            }}
          >
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1.1 สุขภาพของท่านเป็นอย่างไร: "
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_1}
                translate={{ 1: "ดีมาก", 2: "ดี", 3: "แย่", 4: "อื่น ๆ" }}
                mode="view"
              />
              {dataForm.data_1 == "4" && (
                <span style={{ paddingLeft: "10px" }}>
                  <FormElementTextBox
                    value={dataForm.data_163}
                    mode="view"
                    width="100"
                  />
                </span>
              )}
            </div>
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1.2 ท่านพึงพอใจต่อภาวะสุขภาพของท่านหรือไม่: "
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_2}
                translate={{ 1: "พึงพอใจ", 2: "ไม่พึงพอใจ" }}
                mode="view"
              />
              {dataForm.data_2 == "2" && (
                <span style={{ paddingLeft: "10px" }}>
                  <FormElementTextBox
                    value={dataForm.data_3}
                    mode="view"
                    width="100"
                  />
                </span>
              )}
            </div>
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1.3 การปฏิบัติตามแผนการรักษาและการดูแลในอดีต: "
                mode="view"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                  paddingLeft: "40px",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  label="การดูแลสุขภาพเมื่อเจ็บป่วย: "
                  mode="view"
                />

                <FormElementLabel
                  value={dataForm.data_4}
                  translate={{
                    1: "ไปรพ. /คลินิก",
                    2: "ซื้อยารับประทานเอง",
                    3: "อื่น ๆ",
                  }}
                  mode="view"
                />
                {dataForm.data_4 == "3" && (
                  <span style={{ paddingLeft: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_5}
                      label=""
                      mode="view"
                      width="100"
                    />
                  </span>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                  paddingLeft: "40px",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  label="การปฏิบัติตามแผนการรักษา: "
                  mode="view"
                />

                <FormElementLabel
                  value={dataForm.data_6}
                  translate={{
                    1: "ปฏิบัติสม่ำเสมอ",
                    2: "บางครั้ง",
                    3: "ไม่ปฏิบัติ",
                  }}
                  mode="view"
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1.4 การรับรู้ต่อความเจ็บป่วยในปัจจุบัน: "
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_7}
                translate={{
                  1: "รุนแรงมาก",
                  2: "รุนแรงปานกลาง",
                  3: "ไม่รุนแรง",
                }}
                mode="view"
              />
            </div>
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1.5 การได้รับภูมิคุ้มกันโรค: "
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_8}
                translate={{ 1: "No", 2: "Yes" }}
                mode="view"
              />
              {dataForm.data_8 == "2" && (
                <span style={{ paddingLeft: "10px" }}>
                  <FormElementTextBox
                    value={dataForm.data_9}
                    mode="view"
                    width="100"
                  />
                </span>
              )}
            </div>
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1.6 การใช้สารเสพติด: "
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_10}
                translate={{ 0: "Never", 1: "Yes" }}
                padding="0px 10px 0px 40px"
                mode="view"
              />

              {dataForm.data_10 == "1" && (
                <div>
                  {dataForm.data_12 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormElementLabel
                        mode="view"
                        value={dataForm.data_12}
                        translate={{ 1: "Smoking: " }}
                        padding="10px 10px 0px 80px"
                      />
                      {dataForm.data_12 == "1" && (
                        <span
                          style={{ paddingLeft: "10px", paddingTop: "10px" }}
                        >
                          <div>
                            <FormElementLabel
                              value={dataForm.data_11}
                              mode="view"
                              translate={{
                                1: "Ex smoking󠄀",
                                2: "Current smoking",
                              }}
                            />
                            {dataForm.data_11 == "1" ? (
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div>
                                  <strong style={{ paddingRight: "10px" }}>
                                    ประเภทของบุหรี่:
                                  </strong>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <FormElementLabel
                                    value={dataForm.data_13}
                                    mode="view"
                                    translate={{
                                      1: "บุหรี่ซอง",
                                      2: "บุหรี่ไฟฟ้า",
                                      3: "ยาเส้น",
                                      4: "อื่น ๆ",
                                    }}
                                  />
                                  {dataForm.data_13 == "4" && (
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <FormElementTextBox
                                        value={dataForm.data_14}
                                        label=""
                                        mode="view"
                                        width="100"
                                      />
                                    </span>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <FormElementTextBox
                                    value={dataForm.data_15}
                                    label="จำนวน"
                                    mode="view"
                                    width="100"
                                  />
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    มวน/วัน
                                  </span>
                                  <FormElementTextBox
                                    value={dataForm.data_16}
                                    label="ระยะเวลาที่เลิก"
                                    mode="view"
                                    width="100"
                                  />
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    ปี/เดือน
                                  </span>
                                </div>
                              </span>
                            ) : (
                              dataForm.data_11 == "2" && (
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div>
                                    <strong style={{ paddingRight: "10px" }}>
                                      ประเภทของบุหรี่:
                                    </strong>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FormElementLabel
                                      value={dataForm.data_17}
                                      mode="view"
                                      translate={{
                                        1: "บุหรี่ซอง",
                                        2: "บุหรี่ไฟฟ้า",
                                        3: "ยาเส้น",
                                        4: "อื่น ๆ",
                                      }}
                                    />
                                    {dataForm.data_17 == "4" && (
                                      <span
                                        style={{
                                          paddingLeft: "10px",
                                          paddingRight: "10px",
                                        }}
                                      >
                                        <FormElementTextBox
                                          value={dataForm.data_18}
                                          label=""
                                          mode="view"
                                          width="100"
                                        />
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FormElementTextBox
                                      value={dataForm.data_19}
                                      label="จำนวน"
                                      mode="view"
                                      width="100"
                                    />
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      มวน/วัน
                                    </span>
                                    <FormElementTextBox
                                      value={dataForm.data_20}
                                      label="ระยะเวลาที่เลิก"
                                      mode="view"
                                      width="100"
                                    />
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      ปี/เดือน
                                    </span>
                                  </div>
                                </span>
                              )
                            )}
                          </div>
                        </span>
                      )}
                    </div>
                  )}
                  {dataForm.data_21 && (
                    <div
                      style={{
                        paddingLeft: "80px",
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      <FormElementLabel
                        value={dataForm.data_21}
                        translate={{ 1: "Alcohol: " }}
                        mode="view"
                      />
                      {dataForm.data_21 == "1" && (
                        <span
                          style={{
                            paddingLeft: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ paddingLeft: "10px" }}>
                            <FormElementTextBox
                              value={dataForm.data_22}
                              label="ประเภท: "
                              mode="view"
                              width="100"
                            />
                          </div>
                          <div style={{ paddingLeft: "10px" }}>
                            <FormElementTextBox
                              value={dataForm.data_23}
                              label="ปริมาณที่บริโภค: "
                              mode="view"
                              width="100"
                            />
                          </div>
                          <div style={{ paddingLeft: "10px" }}>
                            <FormElementTextBox
                              value={dataForm.data_24}
                              label="ความถี่: "
                              mode="view"
                              width="100"
                            />
                          </div>
                          <span style={{ paddingLeft: "10px" }}>
                            ครั้ง/สัปดาห์
                          </span>
                        </span>
                      )}
                    </div>
                  )}
                  {dataForm.data_25 && (
                    <div
                      style={{
                        paddingLeft: "80px",
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      <FormElementLabel
                        value={dataForm.data_25}
                        translate={{ 1: "Addictive substance: " }}
                        mode="view"
                      />
                      {dataForm.data_25 == "1" && (
                        <span
                          style={{
                            paddingLeft: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ paddingLeft: "10px" }}>
                            <FormElementTextBox
                              value={dataForm.data_26}
                              label="ประเภท: "
                              mode="view"
                              width="100"
                            />
                          </div>
                          <div style={{ paddingLeft: "10px" }}>
                            <FormElementTextBox
                              value={dataForm.data_27}
                              label="ระยะเวลาที่ใช้: "
                              mode="view"
                              width="100"
                            />
                          </div>
                          <span style={{ paddingLeft: "10px" }}>ปี</span>
                        </span>
                      )}
                    </div>
                  )}
                  {dataForm.data_28 && (
                    <div
                      style={{
                        paddingLeft: "80px",
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      <FormElementLabel
                        value={dataForm.data_28}
                        translate={{ 1: "Other: " }}
                        mode="view"
                      />
                      {dataForm.data_28 == "1" && (
                        <span
                          style={{
                            paddingLeft: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ paddingLeft: "10px" }}>
                            <FormElementTextBox
                              value={dataForm.data_29}
                              label="ประเภท: "
                              mode="view"
                              width="100"
                            />
                          </div>
                          <div style={{ paddingLeft: "10px" }}>
                            <FormElementTextBox
                              value={dataForm.data_30}
                              label="ระยะเวลาที่ใช้: "
                              mode="view"
                              width="100"
                            />
                          </div>
                          <span style={{ paddingLeft: "10px" }}>ปี</span>
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <FormElementLabel label="Health assessment 2" mode="view" />
        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel label="2. อาหารและการเผาผลาญสารอาหาร" mode="view" />
          <div
            style={{
              paddingTop: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormElementLabel
              padding="0px 10px 0px 0px"
              label="2.1 แบบแผนการรับประทานอาหาร/การดื่มน้ำ: "
              mode="view"
            />
            <div style={{ paddingLeft: "40px" }}>
              <FormElementLabel mode="view" label="ประเภท: " />
              <div
                style={{
                  paddingLeft: "40px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_31}
                  mode="view"
                  translate={{
                    1: "อาหารธรรมดา",
                    2: "อาหารอ่อน",
                    3: "อาหารเหลว/นม",
                    4: "อาหารเฉพาะโรค ระบุ",
                    5: "อื่น ๆ",
                  }}
                />
                {dataForm.data_31 == "4" ? (
                  <FormElementTextBox mode="view" value={dataForm.data_32} />
                ) : (
                  dataForm.data_31 == "5" && (
                    <FormElementTextBox mode="view" value={dataForm.data_33} />
                  )
                )}
              </div>
              <div
                style={{
                  paddingLeft: "40px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "40px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormElementTextBox
                    label="จำนวนมื้อ: "
                    mode="view"
                    value={dataForm.data_34}
                  />
                  <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    มื้อ/วัน
                  </span>
                  <FormElementTextBox
                    label="ปริมาณต่อมื้อ: "
                    mode="view"
                    value={dataForm.data_35}
                  />
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "40px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormElementTextBox
                    label="การดื่มน้ำ ปริมาณ: "
                    mode="view"
                    value={dataForm.data_36}
                  />
                  <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    ลิตร/วัน
                  </span>
                  <FormElementTextBox
                    label="แหล่งที่มาของน้ำดื่ม: "
                    mode="view"
                    value={dataForm.data_37}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "10px",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="2.2 ปัญหาการรับประทานอาหาร: "
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_38}
                translate={{ 0: "No", 1: "Yes" }}
                mode="view"
              />
              {dataForm.data_38 == "1" && (
                <div style={{ paddingLeft: "10px" }}>
                  {dataForm.data_39 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        value={dataForm.data_39}
                        mode="view"
                        translate={{ 1: " การกลืน " }}
                      />
                    </div>
                  )}

                  {dataForm.data_40 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        value={dataForm.data_40}
                        mode="view"
                        translate={{ 1: " การเคี้ยว " }}
                      />
                    </div>
                  )}

                  {dataForm.data_41 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        value={dataForm.data_41}
                        mode="view"
                        translate={{ 1: " เบื่ออาหาร " }}
                      />
                    </div>
                  )}

                  {dataForm.data_42 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        value={dataForm.data_42}
                        mode="view"
                        translate={{ 1: " คลื่นไส้ " }}
                      />
                    </div>
                  )}

                  {dataForm.data_43 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        value={dataForm.data_43}
                        mode="view"
                        translate={{ 1: " อาเจียน " }}
                      />
                    </div>
                  )}

                  {dataForm.data_44 == "1" && (
                    <div
                      style={{
                        paddingTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormElementLabel
                        value={dataForm.data_44}
                        mode="view"
                        translate={{ 1: " อื่น ๆ " }}
                      />
                      {dataForm.data_44 == "1" && (
                        <span style={{ paddingLeft: "10px" }}>
                          <FormElementTextBox
                            value={dataForm.data_45}
                            label=""
                            mode="view"
                            width="100"
                          />
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "10px",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="2.3 การเปลี่ยนแปลงน้ำหนัก/ส่วนสูง ใน 6 เดือนที่ผ่านมา: "
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_46}
                translate={{
                  1: "ไม่ทราบ",
                  2: "ไม่มีการเปลี่ยนแปลง",
                  3: "เปลี่ยนแปลง",
                }}
                mode="view"
              />
              {dataForm.data_46 == "3" && (
                <span>
                  <div
                    style={{
                      paddingLeft: "10px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_47}
                      mode="view"
                      translate={{ 1: "เพิ่มขึ้น", 2: "ลดลง" }}
                    />
                    {dataForm.data_47 == "1" ? (
                      <span
                        style={{
                          paddingLeft: "10px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_48}
                          label=""
                          mode="view"
                          width="100"
                        />
                        <span
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          kg
                        </span>
                        <FormElementTextBox
                          value={dataForm.data_49}
                          label="ในระยะเวลา"
                          mode="view"
                          width="100"
                        />
                        <span
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          เดือน
                        </span>
                      </span>
                    ) : (
                      dataForm.data_47 == "2" && (
                        <span style={{ display: "flex", flexDirection: "row" }}>
                          <FormElementTextBox
                            value={dataForm.data_50}
                            label=""
                            mode="view"
                            width="100"
                          />
                          <span
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            kg
                          </span>
                          <FormElementTextBox
                            value={dataForm.data_51}
                            label="ในระยะเวลา"
                            mode="view"
                            width="100"
                          />
                          <span
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            เดือน
                          </span>
                        </span>
                      )
                    )}
                  </div>
                </span>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "10px",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="2.4 ปัญหาเกี่ยวกับสุขภาพผิวหนัง/เล็บ/ผม: "
                mode="view"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_52}
                  mode="view"
                  translate={{ 0: "No", 1: "Yes" }}
                />

                {dataForm.data_52 == "1" && (
                  <span style={{ paddingLeft: "10px" }}>
                    <FormElementTextBox
                      value={dataForm.data_53}
                      label="ระบุ"
                      mode="view"
                      width="100"
                    />
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "10px",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="2.5 Nutrition Screening การคัดกรองภาวะโภชนาการ: "
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <FormElementTextBox
                  value={dataForm.data_54}
                  label="BMI"
                  mode="view"
                  width="100"
                />
                <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  kg/m2
                </span>
              </div>
              {dataForm.data_55 && (
                <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
                  <FormElementLabel
                    value={dataForm.data_55}
                    mode="view"
                    translate={{
                      1: "Decrease food or nutrient at least 7 days (1) ",
                    }}
                  />
                </div>
              )}
              {dataForm.data_56 && (
                <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                  <FormElementLabel
                    value={dataForm.data_56}
                    mode="view"
                    translate={{
                      1: "BW reduction during the past 6 months (1) ",
                    }}
                  />
                </div>
              )}
              {dataForm.data_57 && (
                <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                  <FormElementLabel
                    value={dataForm.data_57}
                    mode="view"
                    translate={{ 1: "BMI < 18.5 kg/m2 or > 25 kg/m2 (1) " }}
                  />
                </div>
              )}
              {dataForm.data_58 && (
                <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                  <FormElementLabel
                    value={dataForm.data_58}
                    mode="view"
                    translate={{
                      1: "Critically ill or intermediate care condition (1)  ",
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                  paddingLeft: "40px",
                }}
              >
                <FormElementTextBox
                  value={dataForm.data_59}
                  label="Total score"
                  mode="view"
                  width="100"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormElementLabel
                  value={dataForm.data_60}
                  mode="view"
                  translate={{
                    1: "No risk",
                    2: "Risk > 2 continue for nutrition assessment",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <FormElementLabel label="Health assessment 3.4" mode="view" />
        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel label="3. แบบแผนการขับถ่าย" mode="view" />
          <div
            style={{
              paddingTop: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            3.1
            <FormElementTextBox
              value={dataForm.data_61}
              label="ปัสสาวะ"
              mode="view"
              width="100"
            />
            <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              ครั้ง/วัน
            </span>
            <FormElementTextBox
              value={dataForm.data_62}
              label="ปริมาณ"
              mode="view"
              width="100"
            />
            <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              CC/ครั้ง
            </span>
            <div>
              <div>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormElementLabel
                      mode="view"
                      label="ลักษณะ: "
                      padding="0px 10px 0px 0px"
                    />
                    <FormElementLabel
                      value={dataForm.data_63}
                      mode="view"
                      translate={{ 1: " ปกติ ", 2: "ผิดปกติ" }}
                    />
                  </div>

                  {dataForm.data_63 == "2" && (
                    <span style={{ paddingTop: "10px" }}>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_64}
                          mode="view"
                          translate={{ 1: " ขุ่น " }}
                        />
                      </div>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_65}
                          mode="view"
                          translate={{ 1: " แสบขัด " }}
                        />
                      </div>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_66}
                          mode="view"
                          translate={{ 1: " กลั้นปัสสาวะไม่ได้ " }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "44px",
                        }}
                      >
                        <FormElementLabel
                          value={dataForm.data_67}
                          mode="view"
                          translate={{ 1: " อื่นๆ" }}
                        />
                        {dataForm.data_67 == "1" && (
                          <span>
                            <FormElementTextBox
                              value={dataForm.data_68}
                              label=""
                              mode="view"
                              width="100"
                            />
                          </span>
                        )}
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              paddingTop: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            3.2
            <FormElementTextBox
              value={dataForm.data_69}
              label="การขับถ่าย"
              mode="view"
              width="100"
            />
            <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              วัน/สัปดาห์
            </span>
            <div>
              <div>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormElementLabel
                      mode="view"
                      label="ลักษณะ: "
                      padding="0px 10px 0px 0px"
                    />
                    <FormElementLabel
                      value={dataForm.data_70}
                      mode="view"
                      translate={{ 1: " ปกติ ", 2: "ผิดปกติ" }}
                    />
                  </div>

                  {dataForm.data_70 == "2" && (
                    <span style={{ paddingTop: "10px" }}>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_71}
                          mode="view"
                          translate={{ 1: " ท้องผูก " }}
                        />
                      </div>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_72}
                          mode="view"
                          translate={{ 1: " ท้องเสีย " }}
                        />
                      </div>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_73}
                          mode="view"
                          translate={{ 1: " กลั้นอุจจาระไม่ได้ " }}
                        />
                      </div>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_74}
                          mode="view"
                          translate={{ 1: " ขับถ่ายทางหน้าท้อง " }}
                        />
                      </div>
                      <div style={{ marginLeft: "44px" }}>
                        <FormElementLabel
                          value={dataForm.data_75}
                          mode="view"
                          translate={{ 1: " ริดสีดวง " }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "44px",
                        }}
                      >
                        <FormElementLabel
                          value={dataForm.data_76}
                          mode="view"
                          translate={{ 1: " อื่นๆ" }}
                        />
                        {dataForm.data_67 == "1" && (
                          <span>
                            <FormElementTextBox
                              value={dataForm.data_77}
                              label=""
                              mode="view"
                              width="100"
                            />
                          </span>
                        )}
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              padding="0px 10px 0px 0px"
              label="3.3 อาการเหงื่อออกมากผิดปกติ: "
              mode="view"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                value={dataForm.data_78}
                mode="view"
                translate={{ 0: "No", 1: "Yes" }}
              />

              {dataForm.data_78 == "1" && (
                <span style={{ paddingLeft: "10px" }}>
                  <FormElementTextBox
                    value={dataForm.data_79}
                    label="ระบุ"
                    mode="view"
                    width="100"
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "10px 0px 0px 40px",
          }}
        >
          <FormElementLabel
            label="4. แบบแผนการพักผ่อนและนอนหลับ (Sleep-rest pattern)"
            mode="view"
          />
          <div
            style={{
              paddingLeft: "40px",
              paddingTop: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                label="4.1 การนอนหลับ"
                mode="view"
                padding="0px 10px 0px 0px"
              />
              <FormElementLabel
                value={dataForm.data_80}
                mode="view"
                translate={{ 1: "เพียงพอ", 2: "ไม่เพียงพอ" }}
              />
            </div>
            <div
              style={{
                paddingLeft: "40px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementTextBox
                value={dataForm.data_81}
                label="ปกตินอนวันละ"
                type="numeric"
                mode="view"
                width="100"
              />
              <span style={{ padding: "0px 10px 0px 10px" }}>ชม./วัน</span>
              <FormElementTextBox
                value={dataForm.data_82}
                label="เวลาเข้านอน"
                mode="view"
                width="100"
              />
              <span style={{ padding: "0px 10px 0px 10px" }}>น.</span>
              <FormElementTextBox
                value={dataForm.data_83}
                label="เวลาตื่น"
                mode="view"
                width="100"
              />
              <span style={{ padding: "0px 10px 0px 10px" }}>น.</span>
            </div>
            <div
              style={{
                paddingLeft: "40px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                label="ลักษณะการนอนหลับ"
                mode="view"
                padding="0px 10px 0px 0px"
              />
              <FormElementLabel
                value={dataForm.data_84}
                mode="view"
                translate={{ 1: "หลับสนิท", 2: "หลับไม่สนิท" }}
              />
              {dataForm.data_84 == "2" && (
                <FormElementLabel
                  value={dataForm.data_85}
                  mode="view"
                  translate={{ 1: "ฝัน", 2: "ไม่ฝัน" }}
                />
              )}
            </div>
            <div
              style={{
                paddingLeft: "40px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                label="ปัญหาในการนอนหลับ"
                mode="view"
                padding="0px 10px 0px 0px"
              />
              <FormElementLabel
                value={dataForm.data_86}
                mode="view"
                translate={{ 0: "ไม่มี", 1: "มี" }}
              />
              {dataForm.data_86 == "1" && (
                <span style={{ paddingLeft: "10px" }}>
                  <FormElementTextBox
                    value={dataForm.data_87}
                    mode="view"
                    width="100"
                  />
                </span>
              )}
            </div>
            <div
              style={{
                paddingLeft: "40px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                label="การใช้ยาหรืออุปกรณ์ที่ช่วยในการนอนหลับ"
                mode="view"
                padding="0px 10px 0px 0px"
              />
              <FormElementLabel
                value={dataForm.data_88}
                mode="view"
                translate={{ 0: "ไม่มี", 1: "มี" }}
              />
              {dataForm.data_88 == "1" && (
                <span
                  style={{
                    paddingLeft: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_89}
                    label=""
                    mode="view"
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_90}
                    label="ความถี่ในการใช้"
                    mode="view"
                    width="100"
                  />
                  <span style={{ paddingLeft: "10px" }}>วัน/สัปดาห์</span>
                </span>
              )}
            </div>
          </div>
        </div>
        <FormElementLabel label="Health assessment 5" mode="view" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "40px",
          }}
        >
          <FormElementLabel
            label="5. กิจกรรมและแบบแผนการออกกำลังกาย"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "40px",
              gap: "8px",
            }}
          >
            <FormElementLabel label="5.1 การทำกิจวัตรประจำวัน: " mode="view" />
            <FormElementLabel
              value={dataForm.data_91}
              mode="view"
              translate={{
                1: "ทำได้",
                2: "ทำได้บางส่วน",
                3: "ทำไม่ได้",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "40px",
              gap: "8px",
            }}
          >
            <FormElementLabel label="5.2 แบบแผนการออกกำลังกาย: " mode="view" />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "40px",
              }}
            >
              <FormElementLabel
                value={dataForm.data_92}
                mode="view"
                translate={{
                  1: "ไม่ออกกำลังกาย",
                  2: "ออกกำลังกายสม่ำเสมอ",
                  3: "ออกกำลังกายแต่ไม่สม่ำเสมอ",
                }}
              />
            </div>
            <FormElementTextBox
              label="วิธีการออกกำลังกาย: "
              mode="view"
              value={dataForm.data_93}
              padding="0px 10px 0px 0px"
            />
            <FormElementTextBox
              label="ระยะเวลาออกกำลังกาย: "
              mode="view"
              value={dataForm.data_94}
              padding="0px 10px 0px 0px"
            />
            <span style={{ paddingLeft: "10px" }}> นาที/ครั้ง</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "40px",
            }}
          >
            <FormElementTextBox
              label="ความถี่: "
              mode="view"
              value={dataForm.data_95}
              padding="0px 10px 0px 0px"
            />
            <span style={{ paddingLeft: "10px" }}> ครั้ง/สัปดาห์</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "40px",
            }}
          >
            <FormElementLabel
              label="5.3 ความสามารถในการช่วยเหลือตนเองในกิจวัตรประจำวัน: "
              mode="view"
            />
            <table
              style={{ borderCollapse: "collapse", border: "1px solid grey" }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ความสามารถในการช่วยเหลือตนเองในกิจวัตรประจำวัน
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    ระดับความสามารถในการช่วยเหลือตนเอง
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    1.การเข้าห้องน้ำ
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_96}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    2.การอาบน้ำ
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_97}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    3.การรับประทานอาหาร
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_98}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    4.การเคลื่อนไหวทั่วไป
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_99}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    5.การแต่งตัว
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_100}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    6.การหวีผม การทำความสะอาดปากและฟัน
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_101}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    7.การซื้อของ/ทำธุระนอกบ้าน
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_102}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    8.การเคลื่อนไหวบนเตียงกรณีผู้ป่วยติดเตียง
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_103}
                      translate={{ 1: "0", 2: "1", 3: "2", 4: "3", 5: "4" }}
                      mode="view"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ paddingTop: "10px" }}>
              <FormElementLabel
                label="ระดับความสามารถในการทำกิจกรรม"
                mode="view"
              />
              <div style={{ paddingTop: "10px" }}>ระดับ 0 : ทำได้ด้วยตนเอง</div>
              <div style={{ paddingTop: "10px" }}>
                ระดับ 1 : ต้องใช้อุปกรณ์ช่วยเหลือ เช่น ในผู้สูงอายุที่ลุกได้เอง
                แต่เวลาเดินต้องใช้ไม้เท้า
                หรือในผู้รับบริการหรือผู้ป่วยจิตเวชที่ต้องเตือนให้ทำ
              </div>
              <div style={{ paddingTop: "10px" }}>
                ระดับ 2 : ต้องการการช่วยเหลือ หรือต้องการค่าแนะนำจากผู้อื่น
                แล้วทำเองต่อได้ เช่น ต้องมีผู้ช่วยพยุงขึ้น จากเตียง
                แล้วลุกเดินได้หรือในผู้รับบริการที่ต้องมีผู้คอยแนะนำหรือบอกในการทำกิจกรรม
                จากนั้นทำเองได้
              </div>
              <div style={{ paddingTop: "10px" }}>
                ระดับ 3 : ต้องการความช่วยเหลือจากบุคคลอื่น ร่วมกับใช้อุปกรณ์ช่วย
                เช่น ผู้ที่เป็นอัมพาตซีกด้านซ้าย ต้องการผู้ช่วยพยุงข้างซ้าย
                และใช้ไม้เท้าช่วยเดิน หรือในผู้รับบริการที่ต้องมีคนคอยดูแลให้ทำ
                กิจกรรมตลอดเวลา
              </div>
              <div style={{ paddingTop: "10px" }}>
                ระดับ 4 : ช่วยเหลือตัวเองไม่ได้เลย หรือไม่สามารถทำเองได้
                ต้องให้ผู้อื่นช่วยทำกิจกรรมให้ตลอดเวลา
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "40px",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel label="5.4 ปัญหาในการเคลื่อนไหว: " mode="view" />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                value={dataForm.data_104}
                mode="view"
                translate={{ 0: "ไม่มี", 1: "มี ระบุ" }}
              />

              {dataForm.data_104 == "1" && (
                <span style={{ paddingLeft: "10px" }}>
                  <FormElementTextBox
                    value={dataForm.data_105}
                    label="ระบุ"
                    mode="view"
                    width="100"
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        <FormElementLabel label="Health assessment 6,7" mode="view" />
        <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
          <FormElementLabel
            label="6. แบบแผนการรู้คิด การรับรู้ การสื่อสาร"
            mode="view"
          />
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel label="6.1 การรู้คิด (Cognition)" mode="view" />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1. ระดับความรู้สึกตัว (consciousness)"
                mode="view"
              />
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_106}
                mode="view"
                translate={{
                  1: " Alert (ภาวะการรู้ตัวปกติ) ",
                  2: " Confused (สับสน)",
                  3: "Drowsiness (ซึม)",
                  4: " Stuporous (ภาวะซึมมากหลับลึก)",
                  5: " Coma (ภาวะหมดสติ) ",
                }}
              />
            </div>
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="2. ความจำ (Memory)"
                mode="view"
              />
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_107}
                mode="view"
                translate={{
                  1: "ปกติ",
                  2: "ผิดปกติ",
                }}
              />
              {dataForm.data_107 == "2" && (
                <FormElementTextBox
                  value={dataForm.data_108}
                  label="ระบุ: "
                  mode="view"
                  width="100"
                />
              )}
            </div>
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="3. Orientation"
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  label="- การรับรู้เวลา"
                  mode="view"
                />
                <FormElementLabel
                  value={dataForm.data_109}
                  mode="view"
                  translate={{
                    1: "ปกติ",
                    2: "ผิดปกติ",
                  }}
                />
                {dataForm.data_109 == "2" && (
                  <FormElementTextBox
                    value={dataForm.data_110}
                    label="ระบุ: "
                    mode="view"
                    width="100"
                  />
                )}
              </div>
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  label="- การรับรู้สถานที่"
                  mode="view"
                />
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_111}
                  mode="view"
                  translate={{
                    1: "ปกติ",
                    2: "ผิดปกติ",
                  }}
                />
                {dataForm.data_111 == "2" && (
                  <FormElementTextBox
                    value={dataForm.data_112}
                    label="ระบุ: "
                    mode="view"
                    width="100"
                  />
                )}
              </div>
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  label="- การรับรู้บุคคล"
                  mode="view"
                />
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_113}
                  mode="view"
                  translate={{
                    1: "ปกติ",
                    2: "ผิดปกติ",
                  }}
                />
                {dataForm.data_113 == "2" && (
                  <FormElementTextBox
                    value={dataForm.data_114}
                    label="ระบุ: "
                    mode="view"
                    width="100"
                  />
                )}
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              padding="0px 10px 0px 0px"
              label="6.2 การรับรู้ (perception)"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="1. การได้ยิน"
                mode="view"
              />
              <FormElementLabel
                value={dataForm.data_115}
                mode="view"
                translate={{
                  1: "ปกติ",
                  2: "ผิดปกติ",
                }}
              />
              {dataForm.data_115 == "2" && (
                <FormElementTextBox
                  value={dataForm.data_116}
                  padding="0px 10px 0px 0px"
                  label="ระบุ: "
                  mode="view"
                  width="100"
                />
              )}
            </div>
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="2. การมองเห็น"
                mode="view"
              />
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_117}
                mode="view"
                translate={{
                  1: "ปกติ",
                  2: "ผิดปกติ",
                }}
              />
              {dataForm.data_117 == "2" && (
                <FormElementTextBox
                  value={dataForm.data_118}
                  label="ระบุ: "
                  mode="view"
                  width="100"
                />
              )}
            </div>
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="3. การรับกลิ่น"
                mode="view"
              />
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_119}
                mode="view"
                translate={{
                  1: "ปกติ",
                  2: "ผิดปกติ",
                }}
              />
              {dataForm.data_119 == "2" && (
                <FormElementTextBox
                  value={dataForm.data_120}
                  label="ระบุ: "
                  mode="view"
                  width="100"
                />
              )}
            </div>
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="4. การรับรส"
                mode="view"
              />
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_121}
                mode="view"
                translate={{
                  1: "ปกติ",
                  2: "ผิดปกติ",
                }}
              />
              {dataForm.data_121 == "2" && (
                <FormElementTextBox
                  value={dataForm.data_122}
                  label="ระบุ: "
                  mode="view"
                  width="100"
                />
              )}
            </div>
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                label="5. การสัมผัส"
                mode="view"
              />
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_123}
                mode="view"
                translate={{
                  1: "ปกติ",
                  2: "ผิดปกติ",
                }}
              />
              {dataForm.data_123 == "2" && (
                <FormElementTextBox
                  value={dataForm.data_124}
                  label="ระบุ: "
                  mode="view"
                  width="100"
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
          <FormElementLabel
            label="7. การรับรู้ตนเองและอัตมโนทัศน์"
            mode="view"
          />
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="สิ่งที่มีผลกระทบต่อตนเองและอัตมโนทัศน์"
              mode="view"
            />
          </div>
          <div style={{ paddingLeft: "80px", paddingTop: "10px" }}>
            <FormElementLabel
              value={dataForm.data_125}
              translate={{
                1: "ลักษณะรูปร่างหน้าตา",
                2: "อารมณ์/ความรู้สึก",
                3: "การรับรู้ความสามารถของตนเอง",
                4: "อื่น ๆ",
              }}
              mode="view"
            />
            {dataForm.data_125 == "4" && (
              <FormElementTextBox
                value={dataForm.data_126}
                mode="view"
                width="100"
              />
            )}
          </div>
        </div>
        <FormElementLabel label="Health assessment 8,9" mode="view" />
        <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
          <FormElementLabel label="8. บทบาทและสัมพันธภาพ" mode="view" />
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="ผลกระทบของความเจ็บป่วยครั้งนี้"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
              }}
            >
              <FormElementLabel
                label="- ต่อโครงสร้าง บทบาท และสัมพันธภาพของครอบครัว"
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  paddingRight: "10px",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_127}
                  translate={{
                    0: "ไม่มี",
                    1: "มี",
                  }}
                  mode="view"
                />
                {dataForm.data_127 == "1" && (
                  <FormElementTextBox
                    value={dataForm.data_128}
                    mode="view"
                    width="100"
                  />
                )}
              </div>
              <FormElementLabel
                label="- ต่อสถานภาพ บทบาท และสัมพันธภาพในสังคม"
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  paddingRight: "10px",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_129}
                  translate={{
                    0: "ไม่มี",
                    1: "มี",
                  }}
                  mode="view"
                />
                {dataForm.data_129 == "1" && (
                  <FormElementTextBox
                    value={dataForm.data_130}
                    mode="view"
                    width="100"
                  />
                )}
              </div>
              <FormElementLabel
                label="บุคคลและแหล่งที่ให้ความช่วยเหลือผู้ป่วย"
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  paddingRight: "10px",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_131}
                  translate={{
                    0: "ไม่มี",
                    1: "มี",
                  }}
                  mode="view"
                />
                {dataForm.data_131 == "1" && (
                  <FormElementTextBox
                    value={dataForm.data_132}
                    mode="view"
                    width="100"
                  />
                )}
              </div>
              <FormElementLabel
                label="สิ่งที่เป็นอุปสรรคต่อการสร้างสัมพันธภาพ"
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  paddingRight: "10px",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_133}
                  translate={{
                    0: "ไม่มี",
                    1: "มี",
                  }}
                  mode="view"
                />
                {dataForm.data_133 == "1" && (
                  <FormElementTextBox
                    value={dataForm.data_134}
                    mode="view"
                    width="100"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
          <FormElementLabel label="9. เพศและการเจริญพันธุ์" mode="view" />
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel label="การคุมกำเนิด" mode="view" />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_135}
                translate={{
                  0: "ไม่มี",
                  1: "มี",
                }}
                mode="view"
              />
              {dataForm.data_135 == "1" && (
                <FormElementTextBox
                  value={dataForm.data_136}
                  label="ระบุ"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          </div>
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="ปัญหาเกี่ยวกับอวัยวะสืบพันธุ์"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_137}
                translate={{
                  0: "ไม่มี",
                  1: "มี",
                }}
                mode="view"
              />
              {dataForm.data_137 == "1" && (
                <FormElementTextBox
                  value={dataForm.data_138}
                  label="ระบุ"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          </div>
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="พฤติกรรมที่แสดงออกสัมพันธ์กับเพศสภาพ"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_139}
                translate={{
                  1: "สัมพันธ์",
                  2: "ไม่สัมพันธ์",
                }}
                mode="view"
              />
              <span hidden={dataForm.data_139 != "1"}>
                <FormElementTextBox
                  label="ระบุ"
                  value={dataForm.data_140}
                  mode="view"
                />
              </span>
            </div>
          </div>
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel label="เป็นผู้ป่วยเพศหญิง" mode="view" />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_141}
                translate={{
                  1: "ใช่",
                  0: "ไม่ใช่",
                }}
                mode="view"
              />
            </div>
            <span hidden={dataForm.data_141 != "1"}>
              <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
                <FormElementLabel label="ประวัติการมีประจำเดือน" mode="view" />
                <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
                  <FormElementTextBox
                    label="ประจำเดือนมาครั้งสุดท้าย วันที่"
                    type="date"
                    value={dataForm.data_142}
                    mode="view"
                  />
                </div>
                <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
                  <FormElementTextBox
                    label="Duration/cycle"
                    value={dataForm.data_143}
                    mode="view"
                  />
                </div>
                <div
                  style={{
                    paddingLeft: "40px",
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormElementLabel
                    label="Length of cycle"
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                  <FormElementLabel
                    padding="0px 10px 0px 0px"
                    value={dataForm.data_144}
                    translate={{
                      1: "Regular สม่ำเสมอ",
                      0: "Irregular ไม่สม่ำเสมอ",
                    }}
                    mode="view"
                  />
                  {dataForm.data_144 == "1" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormElementTextBox
                        value={dataForm.data_145}
                        label="/ ทุก every"
                        width="100"
                        mode="view"
                      />
                      <span style={{ paddingLeft: "10px" }}>day(s) วัน</span>
                    </div>
                  )}
                </div>
              </div>
            </span>
          </div>
        </div>
        <FormElementLabel label="Health assessment 1" mode="view" />
        <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
          <FormElementLabel
            label="10. การปรับตัวและการทนทานต่อความเครียด"
            mode="view"
          />
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="การเจ็บป่วยครั้งนี้ส่งผลกระทบต่อจิตใจ/เครียด/กังวล"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_146}
                translate={{
                  0: "ไม่ส่งผล",
                  1: "ส่งผล",
                }}
                mode="view"
              />
              {dataForm.data_146 == "1" && (
                <FormElementTextBox
                  value={dataForm.data_147}
                  label="ระบุ"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          </div>
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="ปัจจัยเสริมที่ทำให้เกิดความไม่สบายใจขณะเจ็บป่วย"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_148}
                translate={{
                  0: "ไม่มี",
                  1: "มี",
                }}
                mode="view"
              />
              {dataForm.data_148 == "1" && (
                <FormElementTextBox
                  value={dataForm.data_149}
                  label="ระบุ"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          </div>
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="บุคคลหรือแหล่งที่ให้ความช่วยเหลือเวลามีปัญหาหรือไม่สบายใจ"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_150}
                translate={{
                  0: "ไม่มี",
                  1: "มี",
                }}
                mode="view"
              />
              {dataForm.data_150 == "1" && (
                <FormElementTextBox
                  value={dataForm.data_151}
                  label="ระบุ"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          </div>
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel
              label="การจัดการเมื่อเกิดความเครียด/กังวล"
              mode="view"
            />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_152}
                translate={{
                  1: "แยกตัว",
                  2: "พูดคุยกับบุคคลอื่น",
                  3: "ใช้ยา",
                  4: "อื่น ๆ",
                }}
                mode="view"
              />
              {dataForm.data_152 == "4" && (
                <FormElementTextBox
                  value={dataForm.data_153}
                  label="ระบุ"
                  mode="view"
                  width="100"
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
          <FormElementLabel label="11. ค่านิยมและความเชื่อ" mode="view" />
          <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
            <FormElementLabel label="นับถือศาสนา" mode="view" />
            <div
              style={{
                paddingLeft: "40px",
                paddingTop: "10px",
                display: "flex",
                paddingRight: "10px",
                flexDirection: "row",
              }}
            >
              <FormElementLabel
                padding="0px 10px 0px 0px"
                value={dataForm.data_154}
                translate={{
                  1: "พุทธ",
                  2: "คริสต์",
                  3: "อิสลาม",
                  4: "อื่น ๆ",
                }}
                mode="view"
              />
              {dataForm.data_154 == "4" && (
                <FormElementTextBox
                  value={dataForm.data_155}
                  label="ระบุ"
                  mode="view"
                  width="100"
                />
              )}
            </div>
            <FormElementTextBox
              value={dataForm.data_156}
              label="ผู้ป่วยเชื่อว่าการเจ็บป่วยในครั้งนี้เกิดจาก"
              mode="view"
              width="100"
            />
            <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
              <FormElementLabel
                label="สิ่งที่ต้องการ/มีค่ามากที่สุดในชีวิต"
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  paddingRight: "10px",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_157}
                  translate={{
                    0: "ไม่มี",
                    1: "มี",
                  }}
                  mode="view"
                />
                {dataForm.data_157 == "1" && (
                  <FormElementTextBox
                    value={dataForm.data_158}
                    label="ระบุ"
                    mode="view"
                    width="100"
                  />
                )}
              </div>
            </div>
            <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
              <FormElementLabel label="สิ่งยึดเหนี่ยวในชีวิต" mode="view" />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  paddingRight: "10px",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_159}
                  translate={{
                    0: "ไม่มี",
                    1: "มี",
                  }}
                  mode="view"
                />
                {dataForm.data_159 == "1" && (
                  <FormElementTextBox
                    value={dataForm.data_160}
                    label="ระบุ"
                    mode="view"
                    width="100"
                  />
                )}
              </div>
            </div>
            <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
              <FormElementLabel
                label="ผลของการอยู่โรงพยาบาลต่อการปฏิบัติศาสนกิจ"
                mode="view"
              />
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  display: "flex",
                  paddingRight: "10px",
                  flexDirection: "row",
                }}
              >
                <FormElementLabel
                  padding="0px 10px 0px 0px"
                  value={dataForm.data_161}
                  translate={{
                    0: "ไม่มี",
                    1: "มี",
                  }}
                  mode="view"
                />
                {dataForm.data_161 == "1" && (
                  <FormElementTextBox
                    value={dataForm.data_162}
                    label="ระบุ"
                    mode="view"
                    width="100"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <span hidden={!dataForm.form_id}>
          <FormElementTextArea
            label="เหตุผลในการแก้ไข"
            value={dataForm.data_164}
            mode="view"
          />
        </span>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormNursingAssessmentPaper;
