import React from "react";

const FormElementDropdown = (props) => {
  return (
    <div className="uk-display-inline">
      {props.label ? (
        <>
          <span className="formTextBoxLabel">
            {props.label}{" "}
            {props.isRequired ? <span style={{ color: "red" }}>*</span> : null}
          </span>
        </>
      ) : null}
      <select
        style={{ width: "fit-content" }}
        className="uk-input"
        onChange={(e) => props.onChange(props.fieldId, e)}
        disabled={props.disabled}
        value={props.value}
      >
        {props.dataList.map((data, key) => {
          return (
            <option key={key} value={data.value}>
              {data.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default FormElementDropdown;
