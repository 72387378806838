import Api from "../../Components/Api";

async function fetchMemberByTxn(hn, txn) {
    try {
        var result = await Api.consult("MemberTxnDetail", {
            hn: hn,
            txn: txn,
        });
        var { data } = result;

        if (!data) throw new Error(`member data not found`);

        return {
            ...data,
            patient_name: data.titleName + " " + data.firstName + " " + data.lastName,
            hn, txn
        };
    } catch (error) {
        console.error(`fetchDiagByTxn: txn(${txn})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchMemberByTxn;