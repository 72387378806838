import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormTGDS = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    var datas = { ...dataForm };

    datas = { ...datas, ["data_" + field]: valueData };

    setDataForm(datas);
  }

  function setDataObj(data) {
    var datas = { ...dataForm };

    datas = { ...datas, ...data };

    setDataForm(datas);
  }

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    props.addValue({
      formName: "form_tgds",
      formType: "39",
    });
  }, []);

  useEffect(() => {
    console.log(dataForm);
  }, [dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
      dataForm.data_10n,
      dataForm.data_11n,
      dataForm.data_12n,
      dataForm.data_13n,
      dataForm.data_14n,
      dataForm.data_15n,
    ]);
  }, [dataForm]);

  useEffect(() => {
    props.setScore(getTotalScore);
  }, [dataForm]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบวัดความเศร้าในผู้สูงอายุไทย 15 ข้อ (TGDS-15)*" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="เลือกคำตอบที่ตรงกับความรู้สึกของคุณในช่วง 1 สัปดาห์ที่ผ่านมา" />
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th rowSpan={2}>หัวข้อ</th>
              <th colSpan={2}>คำตอบ</th>
            </tr>
            <tr>
              <th>ใช่</th>
              <th>ไม่ใช่</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1. โดยทั่วไปแล้วคุณพึงพอใจกับชีวิตตัวเองหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>2. คุณลดกิจกรรมหรือความสนใจในสิ่งต่างๆลงหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>3. คุณรู้สึกว่าชีวิตของคุณว่างเปล่าหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>4. คุณรู้สึกเบื่อๆ อยู่บ่อยครั้งหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>5. คุณอารมณ์ดีเป็นส่วนใหญ่หรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>6. คุณกลัวว่าอะไรร้ายๆ จะเกิดขึ้นกับคุณหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"6n"}
                  value={dataForm.data_6n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>7. คุณรู้สึกมีความสุขเป็นส่วนใหญ่หรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"7n"}
                  value={dataForm.data_7n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>8. คุณรู้สึกหมดหนทางอยู่บ่อยครั้งหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"8n"}
                  value={dataForm.data_8n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>9. คุณชอบอยู่กับบ้านมากกว่าออกไปหาอะไรทำนอกบ้านหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"9n"}
                  value={dataForm.data_9n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>10. คุณรู้สึกว่าคุณมีปัญหาความจำมากกว่าใครๆ หรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"10n"}
                  value={dataForm.data_10n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                11.
                คุณคิดว่าการที่มีชีวิตอยู่มาได้จนถึงทุกวันนี้มันช่างแสนวิเศษใช่หรือไม่
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"11n"}
                  value={dataForm.data_11n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                12.
                คุณรู้สึกหรือไม่ว่าชีวิตที่กำลังเป็นอยู่ตอนนี้ช่างไร้ค่าเหลือเกิน
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"12n"}
                  value={dataForm.data_12n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>13. คุณรู้สึกมีกำลังเต็มที่หรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"13n"}
                  value={dataForm.data_13n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>14. คุณรู้สึกหมดหวังกับสิ่งที่คุณกำลังเผชิญหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"14n"}
                  value={dataForm.data_14n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"14n"}
                  value={dataForm.data_14n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>15. คุณคิดว่าคนอื่นๆ ดีกว่าคุณหรือไม่</td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"15n"}
                  value={dataForm.data_15n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>คะแนนรวมทั้งหมด</td>
              <td colSpan={2}>{getTotalScore}/15</td>
            </tr>
          </tbody>
        </table>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
            <strong>การคิดคะแนน</strong>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>ให้คะแนน 1 คะแนนในข้อต่อไปนี้</strong>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>ตอบ ใช่ 2-4, 6, 8-10, 12, 14-15</strong>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>ตอบ ไม่ใช่ 1, 5, 7, 11, 13</strong>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>
                6 คะแนนขึ้นไปบ่งบอกว่ามีภาวะซึมเศร้า
                ควรติดตามหรือส่งพบแพทย์ประเมินอาการทางคลินิก
              </strong>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <strong>11 คะแนนขึ้นไปมีภาวะซึมเศร้าแน่นอน ควรพบจิตแพทย์</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTGDS;
