import React, { useState, useEffect, useMemo } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormWafarinPaper = (props) => {

  const { dataForm, patient, profile, nxOpdDropDownList } = props;

  const [nxOpds, setNxOpds] = useState({});

  useEffect(() => {
    let result = {};
    nxOpdDropDownList?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setNxOpds(result)
  }, [nxOpdDropDownList, setNxOpds]);

  const getTotalScore = useMemo(() => {
    if (dataForm) {
      let rawScore = utilSumTotalScore([
        dataForm.data_1n,
        dataForm.data_2n,
        dataForm.data_3n,
        dataForm.data_4n,
        dataForm.data_5n,
        dataForm.data_6n,
        dataForm.data_7n,
        dataForm.data_8n,
        dataForm.data_9n,
        dataForm.data_10n,
      ]);

      return (rawScore * 100) / 10;
    }
    return null;
  }, [dataForm]);

  const getScoreDesc = useMemo(() => {
    if (!getTotalScore) {
      return "";
    } else if (getTotalScore >= 80) {
      return "ผ่านเกณฑ์";
    } else {
      return "ไม่ผ่านเกณฑ์";
    }
  }, [getTotalScore]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="3.2 แบบประเมินความรู้ผู้ป่วยหลังได้รับคำแนะนำการใช้ยากลุ่มพิเศษจากเภสัชกร 5 แบบฟอร์ม"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <FormElementTitle
            label="3.2.1 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยาวาร์ฟาริน"
            mode="view"
          />
        </div>
        <div>
          <FormElementTextBox
            label="วิธีการให้คำแนะนำยา"
            value={dataForm.data_28}
            mode="view"
          />
        </div>
        <table
          style={{
            marginTop: "16px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
                rowSpan={3}
              >
                หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
                colSpan={3}
              >
                ประเมินครั้งที่ {dataForm.data_no}
              </th>
            </tr>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextBox
                  value={dataForm.data_23}
                  mode="view"
                  dateFormat="dd/MM/yyyy"
                  type="date"
                />
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ติดตามครั้งถัดไป
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
                rowSpan={2}
              >
                หมายเหตุ
              </th>
            </tr>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ทราบ
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ไม่ทราบ
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                1. ผู้ป่วยทราบว่ายาวาร์ฟารินเป็นยาต้านการแข็งตัวของเลือด
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_11} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                2. ผู้ป่วยทราบข้อบ่งใช้ของยาวาร์ฟาริน
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_12} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                3. ผู้ป่วยทราบว่าต้องทำการตรวจค่าการแข็งตัวของเลือด (INR) และ
                สามารถบอกเป้าหมายค่า INR ของตนได้
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_3n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_13} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                4. ผู้ป่วยทราบขนาดยาที่ได้รับวันนี้ และ
                ทราบวิธีปฏิบัติตัวเมื่อลืมรับประทานยา
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_4n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_14} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                5. ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะเลือดออกที่ผิดปกติ
                และทราบวิธีปฏิบัติตัวหากเกิดภาวะดังกล่าว
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_5n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_15} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                6. ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะลิ่มเลือดอุดตัน
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_6n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_16} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                7.
                ผู้ป่วยทราบว่าต้องแจ้งบุคลากรทางการแพทย์ทุกครั้งที่เข้ารับบริการว่าตนรับประทานยาวาร์ฟารินอยู่
                (โดยเฉพาะการทำทันตกรรม
                การวางแผนตั้งครรภ์และไม่ควรฉีดยาเข้ากล้ามเนื้อ)
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_7n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_17} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                8. ผู้ป่วยทราบว่าต้องปรึกษาแพทย์หรือเภสัชกรทุกครั้งก่อนซื้อยา
                อาหารเสริม หรือสมุนไพรมารับประทานเอง
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_8n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_8n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_18} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                9. ผู้ป่วยทราบว่าอาหารจำพวกผักใบเขียว
                รวมถึงการบริโภคอาหารบางอย่าง เช่น ขิง, น้ำเต้าหู้,มะม่วงสุก
                อาจส่งผลต่อระดับยาวาร์ฟารินได้
                และทราบว่าควรหลีกเลี่ยงการสูบบุหรี่ การดื่มแอลกอฮอล์
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_9n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_19} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                10. ผู้ป่วยทราบว่าควรเก็บยาให้พ้นแสงและความชื้นโดยใส่ในซองสีชา
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_10n == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_10n == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_20} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                คะแนนรวม
              </td>
              <td
                colSpan={3}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ({getTotalScore}%) {getScoreDesc}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ผู้รับการประเมิน (ผู้ป่วย/ญาติ)
              </td>
              <td
                colSpan={3}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div style={{ display: "inline-flex", gap: "8px" }}>
                  <FormElementTextBox value={dataForm.data_27} mode="view" />
                  <FormElementLabel
                    value={dataForm.data_21}
                    mode="view"
                    translate={{ 1: "ผู้ป่วย", 2: "ญาติ" }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: "right",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                เภสัชกรผู้ประเมิน
              </td>
              <td
                colSpan={3}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_22} mode="view" />
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementTitle
          label="คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥8คะแนน) จึงถือว่า “ผ่านเกณฑ์” หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ"
          mode="view"
        />
        <div style={{ display: "inline-flex", gap: "8px" }}>
          <FormElementTitle label="การนัดหมายครั้งถัดไป" mode="view" />
          <FormElementLabel label="ห้องตรวจ" mode="view" />
          <FormElementLabel
            value={dataForm.data_24}
            translate={nxOpds}
            mode="view"
          />
          <FormElementTextBox
            value={dataForm.data_25}
            mode="view"
            type="date"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <FormElementTextBox
          label="ข้อมูลเพิ่มเติม"
          value={dataForm.data_26}
          mode="view"
        />
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormWafarinPaper;
