import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import ReactInputMask from "react-input-mask";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormHeartFailureClinicPaper from "../FormPaper/FormHeartFailureClinicPaper.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchConsultFormListByHn from "../../API/fetchConsultFormListByHn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormHeartFailureClinic = (props) => {
  const formConstants = {
    formName: "form_heartfailureclinic",
    formType: "42",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [lastIndex, setLastIndex] = useState(0);
  var [dataFormOlds, setDataFormOlds] = useState([]);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const prepareData = useCallback((form, profile, oldDataResponse) => {
    if (Array.isArray(oldDataResponse)) {
      if (oldDataResponse.length > 2) {
        const data = oldDataResponse.slice(-2);
        data.reverse();
        setDataFormOlds(data);
      } else if (oldDataResponse.length > 0 && oldDataResponse.length <= 2) {
        const data = oldDataResponse;
        data.reverse();
        setDataFormOlds(data);
      } else {
        setDataFormOlds([]);
      }
    }

    return form;
  }, []);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const oldDataRequest = fetchConsultFormListByHn(hn, txn, formConstants.formType);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all(
      [patientDataRequest, oldDataRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const oldDataResponse = values[1];
      const patientRoomResponse = values[2];

      data = prepareData({ ...data }, profile, oldDataResponse);
      setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null)
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const renderAssessmentFormSubHeader1 = useMemo(() => {
    return (
      <React.Fragment>
        <th colSpan={2}>ครั้งที่ {dataForm.data_1n}</th>
        <th rowSpan={3} style={{ minWidth: "300px" }}>
          หมายเหตุ
        </th>
        {dataFormOlds.map((item) => (
          <React.Fragment>
            <th colSpan={2}>ครั้งที่ {item.data_1n}</th>
            <th rowSpan={3} style={{ minWidth: "300px" }}>
              หมายเหตุ
            </th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader2 = useMemo(() => {
    return (
      <React.Fragment>
        <th colSpan={2}>
          <FormElementTextBox
            fieldId={"2"}
            label="วันที่"
            type="date"
            value={dataForm.data_2}
            onChange={handleChange}
          />
        </th>
        {dataFormOlds.map((item) => (
          <th colSpan={2}>
            <FormElementTextBox
              value={item.data_2}
              label="วันที่"
              type="date"
              disabled
            />
          </th>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader3 = useMemo(() => {
    return (
      <React.Fragment>
        <th>มี</th>
        <th>ไม่มี</th>
        {dataFormOlds.map((item) => (
          <>
            <th>มี</th>
            <th>ไม่มี</th>
          </>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormHeader = useMemo(() => {
    return (
      <React.Fragment>
        <tr>
          <th rowSpan={3}>การนติดตามอาการ</th>
          {renderAssessmentFormSubHeader1}
        </tr>
        <tr>{renderAssessmentFormSubHeader2}</tr>
        <tr>{renderAssessmentFormSubHeader3}</tr>
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderRow1Content = useMemo(() => {
    return (
      <React.Fragment>
        <td>
          มีอาการหายใจเหนื่อยหอบหรือหายใจลำบากในวันที่ผ่านมาหรือไม่ (Dyspnea)
        </td>
        <td>
          <FormElementRadio
            fieldId={"3"}
            value={dataForm.data_3}
            fieldValue={"1"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"3"}
            value={dataForm.data_3}
            fieldValue={"2"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"4"}
            value={dataForm.data_4 || ""}
            onChange={handleChange}
            noLimit
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <React.Fragment>
            <td>
              <FormElementRadio
                fieldId={`row1-item-${i}-1`}
                value={item.data_3}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row1-item-${i}-2`}
                value={item.data_3}
                fieldValue={"2"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_4 || ""}
                disabled
                noLimit
                rows={3}
                cols={80}
              />
            </td>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderRow2Content = useMemo(() => {
    return (
      <React.Fragment>
        <td>
          ประเมิน NYHA Functional class
          <br />
          I ไม่มีอาการเหนื่อย
          <br />
          II มีอาการเหนื่อยเล็กน้อยขณะออกแรง
          <br />
          III มีอาการเหนื่อยเมื่อทำกิจวัตรประจำวัน
          <br />
          IV มีอาการเหนื่อยขณะที่พักอยู่เฉยๆ
        </td>
        <td colSpan={2}>
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <FormElementRadio
              fieldId={"5"}
              value={dataForm.data_5}
              fieldValue={"1"}
              onChange={handleChange}
              label="I"
            />
            <FormElementRadio
              fieldId={"5"}
              value={dataForm.data_5}
              fieldValue={"2"}
              onChange={handleChange}
              label="II"
            />
            <FormElementRadio
              fieldId={"5"}
              value={dataForm.data_5}
              fieldValue={"3"}
              onChange={handleChange}
              label="III"
            />
            <FormElementRadio
              fieldId={"5"}
              value={dataForm.data_5}
              fieldValue={"4"}
              onChange={handleChange}
              label="IV"
            />
          </div>
        </td>
        <td>
          <FormElementTextArea
            fieldId={"6"}
            value={dataForm.data_6 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <React.Fragment>
            <td colSpan={2}>
              <div style={{ display: "inline-flex", gap: "8px" }}>
                <FormElementRadio
                  fieldId={`row2-item-${i}-1`}
                  value={item.data_5}
                  fieldValue={"1"}
                  label="I"
                  disabled
                />
                <FormElementRadio
                  fieldId={`row2-item-${i}-2`}
                  value={item.data_5}
                  fieldValue={"2"}
                  label="II"
                  disabled
                />
                <FormElementRadio
                  fieldId={`row2-item-${i}-3`}
                  value={item.data_5}
                  fieldValue={"3"}
                  label="III"
                  disabled
                />
                <FormElementRadio
                  fieldId={`row2-item-${i}-4`}
                  value={item.data_5}
                  fieldValue={"4"}
                  label="IV"
                  disabled
                />
              </div>
            </td>
            <td>
              <FormElementTextArea
                value={item.data_6 || ""}
                noLimit
                rows={3}
                cols={80}
                disabled
              />
            </td>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  });

  const renderRow3Content = useMemo(() => {
    return (
      <React.Fragment>
        <td>
          ไม่สามารถนอนราบได้เนื่องจากมีความผิดปกติของการหายใจในวันที่ผ่านมาหรือไม่
          (Orthopnea)
        </td>
        <td>
          <FormElementRadio
            fieldId={"7"}
            value={dataForm.data_7}
            fieldValue={"1"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"7"}
            value={dataForm.data_7}
            fieldValue={"2"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"8"}
            value={dataForm.data_8 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <React.Fragment>
            <td>
              <FormElementRadio
                fieldId={`row3-item-${i}-1`}
                value={item.data_7}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row3-item-${i}-2`}
                value={item.data_7}
                fieldValue={"2"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_8 || ""}
                noLimit
                disabled
                rows={3}
                cols={80}
              />
            </td>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  });

  const renderRow4Content = useMemo(() => {
    return (
      <React.Fragment>
        <td>
          มีอาการหายใจลำบากหลังจากนอนหลับในตอนกลางคืน
          พอลุกมาแล้วดีขึ้นในวันที่ผ่านมาหรือไม่ (PND)
        </td>
        <td>
          <FormElementRadio
            fieldId={"9"}
            value={dataForm.data_9}
            fieldValue={"1"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"9"}
            value={dataForm.data_9}
            fieldValue={"2"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"10"}
            value={dataForm.data_10 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <React.Fragment>
            <td>
              <FormElementRadio
                fieldId={`row4-item-${i}-1`}
                value={item.data_9}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row4-item-${i}-2`}
                value={item.data_9}
                fieldValue={"2"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_10 || ""}
                noLimit
                disabled
                rows={3}
                cols={80}
              />
            </td>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  });

  const renderRow5Content = useMemo(() => {
    return (
      <React.Fragment>
        <td>มีอาการบวมของร่างกายในวันที่ผ่านมาหรือไม่ ตำแหน่งไหน (Edema)</td>
        <td>
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"1"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"2"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"12"}
            value={dataForm.data_12 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <React.Fragment>
            <td>
              <FormElementRadio
                fieldId={`row5-item-${i}-1`}
                value={item.data_11}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row5-item-${i}-2`}
                value={item.data_11}
                fieldValue={"2"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_12 || ""}
                noLimit
                rows={3}
                cols={80}
                disabled
              />
            </td>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  });

  const renderRow6Content = useMemo(() => {
    return (
      <>
        <td>
          <FormElementTextBox
            fieldId={"13n"}
            value={dataForm.data_13n}
            type="decimal"
            label="น้ำหนักเดิม"
            onChange={handleChange}
            width="100"
            maxLength={6}
          />
        </td>
        <td colSpan={2}>
          <FormElementTextBox
            fieldId={"14n"}
            value={dataForm.data_14n}
            type="decimal"
            onChange={handleChange}
            label="น้ำหนักใหม่"
            width="100"
            maxLength={6}
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"15"}
            value={dataForm.data_15 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <>
            <td colSpan={2}>
              <FormElementTextBox
                value={item.data_14n}
                type="decimal"
                label="น้ำหนักใหม่"
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_15 || ""}
                noLimit
                rows={3}
                cols={80}
                disabled
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const handleData16Change = useCallback((e) => {
    const value = e.target.value;
    setDataForm((data) => ({ ...data, data_16: value }));
  }, [setDataForm]);

  const renderRow7Content = useMemo(() => {
    return (
      <>
        <td>ความดันโลหิต</td>
        <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
          <ReactInputMask
            value={dataForm?.data_16}
            mask={"999/999"}
            onChange={handleData16Change}
            type="text"
          />
          {/* <FormElementTextBox
            fieldId={"16n"}
            value={dataForm.data_16n}
            type="numeric"
            onChange={handleChange}
            label=""
          /> */}
        </td>
        <td>
          <FormElementTextArea
            fieldId={"17"}
            value={dataForm.data_17 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item) => (
          <>
            <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
              <FormElementTextBox value={item.data_16} disabled />
              {/* <FormElementTextBox
                value={item.data_16n}
                type="numeric"
                disabled
              /> */}
            </td>
            <td>
              <FormElementTextArea
                value={item.data_17 || ""}
                noLimit
                disabled
                rows={3}
                cols={80}
              />
            </td>
          </>
        ))}
      </>
    );
  }, [handleData16Change, dataFormOlds, dataForm]);

  const renderRow8Content = useMemo(() => {
    return (
      <>
        <td>ชีพจรที่บ้าน (ถ้ามีเครื่องวัดความดันโลหิต)</td>
        <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
          <FormElementTextBox
            fieldId={"39n"}
            value={dataForm.data_39n}
            type="numeric"
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"40"}
            value={dataForm.data_40 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item) => (
          <>
            <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
              <FormElementTextBox
                value={item.data_39n}
                type="numeric"
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_40 || ""}
                noLimit
                disabled
                rows={3}
                cols={80}
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow9Content = useMemo(() => {
    return (
      <>
        <td>มีอาการอ่อนเพลียในวันที่ผ่านมาหรือไม่ (Fatigue)</td>
        <td>
          <FormElementRadio
            fieldId={"18"}
            value={dataForm.data_18}
            fieldValue={"1"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"18"}
            value={dataForm.data_18}
            fieldValue={"2"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"19"}
            value={dataForm.data_19 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <>
            <td>
              <FormElementRadio
                fieldId={`row9-item-${i}-1`}
                value={item.data_18}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row9-item-${i}-2`}
                value={item.data_18}
                fieldValue={"2"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_19 || ""}
                noLimit
                rows={3}
                cols={80}
                disabled
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow10Content = useMemo(() => {
    return (
      <>
        <td>มีอาการเวียนศีรษะ หรือหน้ามืดในวันที่ผ่านมาหรือไม่</td>
        <td>
          <FormElementRadio
            fieldId={"20"}
            value={dataForm.data_20}
            fieldValue={"1"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"20"}
            value={dataForm.data_20}
            fieldValue={"2"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"21"}
            value={dataForm.data_21 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <>
            <td>
              <FormElementRadio
                fieldId={`row10-item-${i}-1`}
                value={item.data_20}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row10-item-${i}-2`}
                value={item.data_20}
                fieldValue={"2"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_21 || ""}
                noLimit
                rows={3}
                cols={80}
                disabled
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow11Content = useMemo(() => {
    return (
      <>
        <td>โดยรวมแล้วในวันที่ผ่านมาคุณรู้สึกดีขึ้นหรือแย่ลงกว่าเดิม (QOL)</td>
        <td colSpan={2}>
          <FormElementTextBox
            fieldId={"22"}
            value={dataForm.data_22}
            onChange={handleChange}
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"23"}
            value={dataForm.data_23 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item) => (
          <>
            <td colSpan={2}>
              <FormElementTextBox value={item.data_22} disabled />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_23 || ""}
                noLimit
                disabled
                rows={3}
                cols={80}
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow12Content = useMemo(() => {
    return (
      <>
        <td>คุณมีคำถามที่จะต้องการถามพยาบาลหรือไม่</td>
        <td>
          <FormElementRadio
            fieldId={"24"}
            value={dataForm.data_24}
            fieldValue={"1"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"24"}
            value={dataForm.data_24}
            fieldValue={"2"}
            onChange={handleChange}
            label=""
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"25"}
            value={dataForm.data_25 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <>
            <td>
              <FormElementRadio
                fieldId={`row12-item-${i}-1`}
                value={item.data_24}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row12-item-${i}-2`}
                value={item.data_24}
                fieldValue={"2"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_25 || ""}
                noLimit
                rows={3}
                cols={80}
                disabled
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow13Content = useMemo(() => {
    return (
      <>
        <td>ER visit</td>
        <td>
          <FormElementRadio
            fieldId={"26"}
            value={dataForm.data_26}
            fieldValue={"1"}
            onChange={handleChange}
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"26"}
            value={dataForm.data_26}
            fieldValue={"0"}
            onChange={handleChange}
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"28"}
            value={dataForm.data_28 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item, i) => (
          <>
            <td>
              <FormElementRadio
                fieldId={`row13-item-${i}-1`}
                value={item.data_26}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                fieldId={`row13-item-${i}-2`}
                value={item.data_26}
                fieldValue={"0"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_28 || ""}
                noLimit
                disabled
                rows={3}
                cols={80}
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow14Content = useMemo(() => {
    return (
      <>
        <td>Re admit</td>
        <td>
          <FormElementRadio
            fieldId={"27"}
            value={dataForm.data_27}
            fieldValue={"1"}
            onChange={handleChange}
          />
        </td>
        <td>
          <FormElementRadio
            fieldId={"27"}
            value={dataForm.data_27}
            fieldValue={"0"}
            onChange={handleChange}
          />
        </td>
        <td>
          <FormElementTextArea
            fieldId={"41"}
            value={dataForm.data_41 || ""}
            noLimit
            onChange={handleChange}
            rows={3}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item) => (
          <>
            <td>
              <FormElementRadio
                value={item.data_27}
                fieldValue={"1"}
                disabled
              />
            </td>
            <td>
              <FormElementRadio
                value={item.data_27}
                fieldValue={"0"}
                disabled
              />
            </td>
            <td>
              <FormElementTextArea
                value={item.data_41 || ""}
                noLimit
                disabled
                rows={3}
                cols={80}
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow15Content = useMemo(() => {
    return (
      <>
        <td>อาหารที่รับประทาน/การหลีกเลี่ยงอาหารรสเค็ม</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"29"}
            value={dataForm.data_29 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <td colSpan={3}>
            <FormElementTextBox value={item.data_29 || ""} disabled rows={1} />
          </td>
        ))}
      </>
    );
  });

  const renderRow16Content = useMemo(() => {
    return (
      <>
        <td>การดื่มน้ำ</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"30"}
            value={dataForm.data_30 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <td colSpan={3}>
            <FormElementTextBox value={item.data_30 || ""} rows={1} disabled />
          </td>
        ))}
      </>
    );
  });

  const renderRow17Content = useMemo(() => {
    return (
      <>
        <td>การรับประทานยาต่อเนื่อง</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"31"}
            value={dataForm.data_31 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <>
            <td colSpan={3}>
              <FormElementTextBox
                value={item.data_31 || ""}
                rows={1}
                disabled
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow18Content = useMemo(() => {
    return (
      <>
        <td>การเฝ้าระวังภาวะสารน้ำเกิน/การปรับยาขับปัสสาวะ</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"33"}
            value={dataForm.data_33 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <>
            <td colSpan={3}>
              <FormElementTextBox
                value={item.data_33 || ""}
                disabled
                rows={1}
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow19Content = useMemo(() => {
    return (
      <>
        <td>การทำกิจกรรม</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"34"}
            value={dataForm.data_34 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <>
            <td colSpan={3}>
              <FormElementTextBox
                value={item.data_34 || ""}
                disabled
                rows={1}
              />
            </td>
          </>
        ))}
      </>
    );
  });

  const renderRow20Content = useMemo(() => {
    return (
      <>
        <td>การออกกำลังกายที่เหมาะสม</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"35"}
            value={dataForm.data_35 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <td colSpan={3}>
            <FormElementTextBox value={item.data_35 || ""} disabled rows={1} />
          </td>
        ))}
      </>
    );
  });

  const renderRow21Content = useMemo(() => {
    return (
      <>
        <td>การงดดื่มเหล้าและสูบบุหรี่</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"36"}
            value={dataForm.data_36 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <td colSpan={3}>
            <FormElementTextBox value={item.data_36 || ""} disabled rows={1} />
          </td>
        ))}
      </>
    );
  });

  const renderRow22Content = useMemo(() => {
    return (
      <>
        <td>การหลีกเลี่ยงการใช้สมุนไพร/ยา NSAIDS</td>
        <td colSpan={3}>
          <FormElementTextBox
            fieldId={"37"}
            value={dataForm.data_37 || ""}
            onChange={handleChange}
            rows={1}
          />
        </td>
        {dataFormOlds.map((item) => (
          <td colSpan={3}>
            <FormElementTextBox value={item.data_37 || ""} disabled rows={1} />
          </td>
        ))}
      </>
    );
  });

  const renderRow23Content = useMemo(() => {
    return (
      <>
        <td>คำแนะนำให้กับผู้ป่วย</td>
        <td colSpan={3}>
          <FormElementTextArea
            fieldId={"38"}
            value={dataForm.data_38 || ""}
            noLimit
            onChange={handleChange}
            rows={4}
            cols={80}
          />
        </td>
        {dataFormOlds.map((item) => (
          <td colSpan={3}>
            <FormElementTextArea
              value={item.data_38 || ""}
              noLimit
              disabled
              rows={4}
              cols={80}
            />
          </td>
        ))}
      </>
    );
  });

  const renderContent = useMemo(() => {
    return (
      <>
        <tr>{renderRow1Content}</tr>
        <tr>{renderRow2Content}</tr>
        <tr>{renderRow3Content}</tr>
        <tr>{renderRow4Content}</tr>
        <tr>{renderRow5Content}</tr>
        <tr>{renderRow6Content}</tr>
        <tr>{renderRow7Content}</tr>
        <tr>{renderRow8Content}</tr>
        <tr>{renderRow9Content}</tr>
        <tr>{renderRow10Content}</tr>
        <tr>{renderRow11Content}</tr>
        <tr>{renderRow12Content}</tr>
        <tr>{renderRow13Content}</tr>
        <tr>{renderRow14Content}</tr>
        <tr>
          <td>
            <FormElementLabel label="การติดตามดูแลตนเอง" fontWeight="normal" />
          </td>
          <td colSpan={3}>
            <FormElementLabel label="การปฏิบัติตัว" fontWeight="normal" />
          </td>
        </tr>
        <tr>{renderRow15Content}</tr>
        <tr>{renderRow16Content}</tr>
        <tr>{renderRow17Content}</tr>
        <tr>{renderRow18Content}</tr>
        <tr>{renderRow19Content}</tr>
        <tr>{renderRow20Content}</tr>
        <tr>{renderRow21Content}</tr>
        <tr>{renderRow22Content}</tr>
        <tr>{renderRow23Content}</tr>
      </>
    );
  }, [dataFormOlds, dataForm, renderRow1Content, renderRow2Content, renderRow3Content,
    renderRow4Content, renderRow5Content, renderRow6Content, renderRow7Content,
    renderRow8Content, renderRow9Content, renderRow10Content, renderRow11Content,
    renderRow12Content, renderRow13Content, renderRow14Content, renderRow15Content,
    renderRow16Content, renderRow17Content, renderRow18Content, renderRow19Content,
    renderRow20Content, renderRow21Content, renderRow22Content, renderRow23Content]);

  return (
    <div
      uk-grid=""
      className="uk-grid uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "hidden" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="แบบบันทึกติดตามทางโทรศัพท์ผู้ป่วย HF(Heart failure clinic)" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div className="form-table-scroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>{renderAssessmentFormHeader}</thead>
              <tbody>{renderContent}</tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div className="form-table-scroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>
                <tr>
                  <th rowSpan={3}>การติดตามอาการ</th>
                  <th colSpan={2}>
                    <FormElementTextBox
                      fieldId={"1n"}
                      type="numeric"
                      value={dataForm.data_1n}
                      label="ครั้งที่"
                      onChange={handleChange}
                      width="100"
                    />
                  </th>
                  <th rowSpan={3}>หมายเหตุ</th>
                </tr>
                <tr>
                  <th colSpan={2}>
                    <FormElementTextBox
                      fieldId={"2"}
                      type="date"
                      value={dataForm.data_2}
                      label="วันที่"
                      onChange={handleChange}
                      width="100"
                    />
                  </th>
                </tr>
                <tr>
                  <th>มี</th>
                  <th>ไม่มี</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    มีอาการหายใจเหนื่อยหอบหรือหายใจลำบากในวันที่ผ่านมาหรือไม่
                    (Dyspnea)
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"3"}
                      value={dataForm.data_3}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"3"}
                      value={dataForm.data_3}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"4"}
                      value={dataForm.data_4 || ""}
                      onChange={handleChange}
                      noLimit
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    ประเมิน NYHA Functional class
                    <br />
                    I ไม่มีอาการเหนื่อย
                    <br />
                    II มีอาการเหนื่อยเล็กน้อยขณะออกแรง
                    <br />
                    III มีอาการเหนื่อยเมื่อทำกิจวัตรประจำวัน
                    <br />
                    IV มีอาการเหนื่อยขณะที่พักอยู่เฉยๆ
                  </td>
                  <td colSpan={2}>
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      <FormElementRadio
                        fieldId={"5"}
                        value={dataForm.data_5}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="I"
                      />
                      <FormElementRadio
                        fieldId={"5"}
                        value={dataForm.data_5}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="II"
                      />
                      <FormElementRadio
                        fieldId={"5"}
                        value={dataForm.data_5}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label="III"
                      />
                      <FormElementRadio
                        fieldId={"5"}
                        value={dataForm.data_5}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label="IV"
                      />
                    </div>
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"6"}
                      value={dataForm.data_6 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    ไม่สามารถนอนราบได้เนื่องจากมีความผิดปกติของการหายใจในวันที่ผ่านมาหรือไม่
                    (Orthopnea)
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"7"}
                      value={dataForm.data_7}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"7"}
                      value={dataForm.data_7}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"8"}
                      value={dataForm.data_8 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    มีอาการหายใจลำบากหลังจากนอนหลับในตอนกลางคืน
                    พอลุกมาแล้วดีขึ้นในวันที่ผ่านมาหรือไม่ (PND)
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"9"}
                      value={dataForm.data_9}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"9"}
                      value={dataForm.data_9}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"10"}
                      value={dataForm.data_10 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    มีอาการบวมของร่างกายในวันที่ผ่านมาหรือไม่ ตำแหน่งไหน (Edema)
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"11"}
                      value={dataForm.data_11}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"11"}
                      value={dataForm.data_11}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"12"}
                      value={dataForm.data_12 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormElementTextBox
                      fieldId={"13n"}
                      value={dataForm.data_13n}
                      type="decimal"
                      label="น้ำหนักเดิม"
                      onChange={handleChange}
                      width="100"
                    />
                  </td>
                  <td colSpan={2}>
                    <FormElementTextBox
                      fieldId={"14n"}
                      value={dataForm.data_14n}
                      type="decimal"
                      onChange={handleChange}
                      label="น้ำหนักใหม่"
                      width="100"
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"15"}
                      value={dataForm.data_15 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>ความดันโลหิต</td>
                  <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
                    <FormElementTextBox
                      fieldId={"16n"}
                      value={dataForm.data_16n}
                      type="numeric"
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"17"}
                      value={dataForm.data_17 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>ชีพจรที่บ้าน (ถ้ามีเครื่องวัดความดันโลหิต)</td>
                  <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
                    <FormElementTextBox
                      fieldId={"39n"}
                      value={dataForm.data_39n}
                      type="numeric"
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"40"}
                      value={dataForm.data_40 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>มีอาการอ่อนเพลียในวันที่ผ่านมาหรือไม่ (Fatigue)</td>
                  <td>
                    <FormElementRadio
                      fieldId={"18"}
                      value={dataForm.data_18}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"18"}
                      value={dataForm.data_18}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"19"}
                      value={dataForm.data_19 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>มีอาการเวียนศีรษะ หรือหน้ามืดในวันที่ผ่านมาหรือไม่</td>
                  <td>
                    <FormElementRadio
                      fieldId={"20"}
                      value={dataForm.data_20}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"20"}
                      value={dataForm.data_20}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"21"}
                      value={dataForm.data_21 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    โดยรวมแล้วในวันที่ผ่านมาคุณรู้สึกดีขึ้นหรือแย่ลงกว่าเดิม
                    (QOL)
                  </td>
                  <td colSpan={2}>
                    <FormElementTextBox
                      fieldId={"22"}
                      value={dataForm.data_22}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"23"}
                      value={dataForm.data_23 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>คุณมีคำถามที่จะต้องการถามพยาบาลหรือไม่</td>
                  <td>
                    <FormElementRadio
                      fieldId={"24"}
                      value={dataForm.data_24}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"24"}
                      value={dataForm.data_24}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label=""
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"25"}
                      value={dataForm.data_25 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>ER visit</td>
                  <td>
                    <FormElementRadio
                      fieldId={"26"}
                      value={dataForm.data_26}
                      fieldValue={"1"}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"26"}
                      value={dataForm.data_26}
                      fieldValue={"0"}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"28"}
                      value={dataForm.data_28 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Re admit</td>
                  <td>
                    <FormElementRadio
                      fieldId={"27"}
                      value={dataForm.data_27}
                      fieldValue={"1"}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormElementRadio
                      fieldId={"27"}
                      value={dataForm.data_27}
                      fieldValue={"0"}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormElementTextArea
                      fieldId={"41"}
                      value={dataForm.data_41 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={3}
                      cols={80}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormElementLabel
                      label="การติดตามดูแลตนเอง"
                      fontWeight="normal"
                    />
                  </td>
                  <td colSpan={3}>
                    <FormElementLabel
                      label="การปฏิบัติตัว"
                      fontWeight="normal"
                    />
                  </td>
                </tr>
                <tr>
                  <td>อาหารที่รับประทาน/การหลีกเลี่ยงอาหารรสเค็ม</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"29"}
                      value={dataForm.data_29 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>การดื่มน้ำ</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"30"}
                      value={dataForm.data_30 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>การรับประทานยาต่อเนื่อง</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"31"}
                      value={dataForm.data_31 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>การเฝ้าระวังภาวะสารน้ำเกิน/การปรับยาขับปัสสาวะ</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"33"}
                      value={dataForm.data_33 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>การทำกิจกรรม</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"34"}
                      value={dataForm.data_34 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>การออกกำลังกายที่เหมาะสม</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"35"}
                      value={dataForm.data_35 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>การงดดื่มเหล้าและสูบบุหรี่</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"36"}
                      value={dataForm.data_36 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>การหลีกเลี่ยงการใช้สมุนไพร/ยา NSAIDS</td>
                  <td colSpan={3}>
                    <FormElementTextBox
                      fieldId={"37"}
                      value={dataForm.data_37 || ""}
                      onChange={handleChange}
                      rows={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>คำแนะนำให้กับผู้ป่วย</td>
                  <td colSpan={3}>
                    <FormElementTextArea
                      fieldId={"38"}
                      value={dataForm.data_38 || ""}
                      noLimit
                      onChange={handleChange}
                      rows={4}
                      cols={80}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={<FormHeartFailureClinicPaper
          dataForm={dataForm}
          patient={patient}
          profile={profile}
        />}
      />
    </div>
  );
};

export default FormHeartFailureClinic;
