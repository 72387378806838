import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from 'moment';

UIkit.use(Icons);

export default class Survey extends Component {
  state = {
    loadingData: true,
    data: {}
  };

  componentDidMount() { 
    this.init();
  }
  
  componentWillReceiveProps() { 
    this.init();
  }

  init = () => {
    this.dataGet();
  };

  dataGet = async () => {
    var _response = await Api.consult("SurveyDataTableGet", {
      year:  this.props.year || moment().format('YYYY'),
      campaignId: this.props.campaignId
    });

    var _key = {};

    if(Array.isArray(_response.data)) {
      _response.data.forEach((data) => {
        if(data.key === 'suggest') {
          if(_key[data.key] == null) {
            _key[data.key] = [];
          }

          _key[data.key].push(data.value);
        } else if(data.key === 'use_system_one_more_time') {
          if(_key[data.key] == null) {
            _key[data.key] = {
              'yes' : 0,
              'no' : 0,
              'not_sure' : 0
            }
          }

          _key[data.key][data.value]++;
        } else {
          if(_key[data.key] == null) {
            _key[data.key] = {
              '1' : 0,
              '2' : 0,
              '3' : 0,
              '4' : 0,
              '5' : 0
            }
          }
  
          _key[data.key][data.value]++;
        }
        
      })
    }

    this.setState({data: _key, loadingData: false});
  };

  app = [
    {
      label: '1. ความสะดวกในการล็อกอินใช้ระบบ',
      key: 'login_easy'
    },
    {
      label: '2. ระบบออกแบบให้ใช้งานง่าย เมนูไม่ซับซ้อน',
      key: 'system_easy'
    },
    {
      label: '3. ความสะดวกในการสนทนาผ่านระบบ',
      key: 'chat_easy'
    },
    {
      label: '4. ความมั่นใจเรื่องการรักษาความลับ',
      key: 'secret_confident'
    },
  ]

  service = [
    {
      label: '1. แบบประเมินก่อนพบแพทย์ครบถ้วนเหมาะสมเข้าใจง่าย',
      key: 'test_easy'
    },
    {
      label: '2. ขั้นตอนการรับบริการเข้าใจง่าย',
      key: 'service_step_easy'
    },
    {
      label: '3. การให้คำปรึกษาและคำแนะนำของเจ้าหน้าที่บุคลากร',
      key: 'consult_suggest'
    },
    {
      label: '4. ประหยัดค่าใช้จ่าย/ค่าเดินทางการมาโรงพยาบาล',
      key: 'save_money'
    },
    {
      label: '5. การใช้งานระบบ Telehealth ในภาพรวม',
      key: 'summary'
    },

  ]

  headerRender = () => {
    return <thead>
    <tr className="uk-text-center">
      <th className="uk-text-center" rowSpan={3} vAlign="top">ข้อคำถาม</th>
      <th className="uk-text-center" colSpan={10}>ระดับความพึงพอใจ</th>
    </tr>
    <tr className="uk-text-center">
      <th className="uk-text-center" colSpan={2}>มากที่สุด (5)</th>
      <th className="uk-text-center" colSpan={2}>มาก (4)</th>
      <th className="uk-text-center" colSpan={2}>ปานกลาง (3)</th>
      <th className="uk-text-center" colSpan={2}>น้อย (2)</th>
      <th className="uk-text-center" colSpan={2}>น้อยที่สุด (1)</th>
    </tr>
    <tr>
      <th className="uk-text-center">จำนวน</th>
      <th className="uk-text-center">ร้อยละ</th>
      <th className="uk-text-center">จำนวน</th>
      <th className="uk-text-center">ร้อยละ</th>
      <th className="uk-text-center">จำนวน</th>
      <th className="uk-text-center">ร้อยละ</th>
      <th className="uk-text-center">จำนวน</th>
      <th className="uk-text-center">ร้อยละ</th>
      <th className="uk-text-center">จำนวน</th>
      <th className="uk-text-center">ร้อยละ</th>
    </tr>
  </thead>
  }

  percentRender = (dataKey, amount_now) => {
    var _amount = 0;
    for(var i in dataKey) {
      _amount += parseFloat(dataKey[i]);
    }

    if(_amount > 0) {
      return ((amount_now/_amount) * 100 ).toFixed(2);
    } else {
      return '-';
    }
  }

  rowRender = (type) => {
    return <tbody>
    {
      this.state.loadingData === true ? 
      <tr>
        <td colSpan={'100%'} className="uk-text-center">
          <div uk-spinner=""></div>
        </td>
      </tr>
      :
      this[type].map((data, index) => {
        if(this.state.data[data.key] != null) {
          return (<tr className="uk-text-center" key={`${type}Row${index}`}>
          <td className="uk-text-left">{data.label}</td>
          <td>{this.state.data[data.key]['5']}</td>
          <td>{this.percentRender(this.state.data[data.key], this.state.data[data.key]['5'])}</td>
          <td>{this.state.data[data.key]['4']}</td>
          <td>{this.percentRender(this.state.data[data.key], this.state.data[data.key]['4'])}</td>
          <td>{this.state.data[data.key]['3']}</td>
          <td>{this.percentRender(this.state.data[data.key], this.state.data[data.key]['3'])}</td>
          <td>{this.state.data[data.key]['2']}</td>
          <td>{this.percentRender(this.state.data[data.key], this.state.data[data.key]['2'])}</td>
          <td>{this.state.data[data.key]['1']}</td>
          <td>{this.percentRender(this.state.data[data.key], this.state.data[data.key]['1'])}</td>
        </tr>);
        } else {
          return <tr><td className="uk-text-center" colSpan={'100%'}>ไม่พบข้อมูล</td></tr>
        }
        
      })
    }
  </tbody>
  }

  render() {
    return (
      <div className="uk-card uk-card-default uk-margin-bottom uk-padding-small">
        <div class="d-card-header">
          <div class="d-card-header-left">แบบสำรวจความพึงพอใจ</div>
          <div class="d-card-header-right"></div>
        </div>
        <div className="uk-overflow-auto">
          <div><u>ด้านแอพพลิเคชัน</u></div>
          <div className="uk-margin-small-top">
            <table className="uk-table uk-table-small uk-table-divider d-border d-table-border-vertical">
              {this.headerRender()}
              {this.rowRender('app')}
            </table>
          </div>
        </div>
        <div className="uk-overflow-auto uk-margin-top">
          <div><u>ด้านบริการ</u></div>
          <div className="uk-margin-small-top">
            <table className="uk-table uk-table-small uk-table-divider d-border d-table-border-vertical">
              {this.headerRender()}
              {this.rowRender('service')}
            </table>
          </div>
        </div>
        <div className="uk-overflow-auto uk-margin-top">
          <div>หากมีโอกาสได้ใช้งานระบบนี้อีกครั้ง</div>
          {
            this.state.loadingData === true ? 
            <div><div uk-spinner=""></div></div>
            :
            <div>
              <table className="uk-table uk-table-small uk-table-divider d-border d-table-border-vertical">
                <thead>
                  <tr>
                    <th className="uk-text-center" colSpan={2}>ใช้</th>
                    <th className="uk-text-center" colSpan={2}>ไม่ใช้</th>
                    <th className="uk-text-center" colSpan={2}>ไม่แน่ใจ</th>
                  </tr>
                  <tr>
                    <th className="uk-text-center">จำนวน</th>
                    <th className="uk-text-center">ร้อยละ</th>
                    <th className="uk-text-center">จำนวน</th>
                    <th className="uk-text-center">ร้อยละ</th>
                    <th className="uk-text-center">จำนวน</th>
                    <th className="uk-text-center">ร้อยละ</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.use_system_one_more_time ?
                    <tr className="uk-text-center">
                      <td>{this.state.data.use_system_one_more_time.yes}</td>
                      <td>{this.percentRender(this.state.data.use_system_one_more_time, this.state.data.use_system_one_more_time.yes)}</td>
                      <td>{this.state.data.use_system_one_more_time.no}</td>
                      <td>{this.percentRender(this.state.data.use_system_one_more_time, this.state.data.use_system_one_more_time.no)}</td>
                      <td>{this.state.data.use_system_one_more_time.not_sure}</td>
                      <td>{this.percentRender(this.state.data.use_system_one_more_time, this.state.data.use_system_one_more_time.not_sure)}</td>
                    </tr>
                    :
                    <tr><td className="uk-text-center" colSpan={'100%'}>ไม่พบข้อมูล</td></tr>
                  }
                </tbody>
              </table>
              <div>
                <div>ข้อเสนอแนะ/ข้อปรับปรุง</div>
                <div>{this.state.data.suggest && this.state.data.suggest.length ? <ul>{this.state.data.suggest.map((d)=>{
                  return <li>{d}</li>
                })}</ul> : '-'}</div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
