
import React, { Component } from 'react';

export default class FullScreen extends Component {
  state = {
    isFull: false
  }

  goFull = () => {
    document.documentElement.requestFullscreen();
    this.setState({isFull: true});
  }

  goMin = () => {
    document.exitFullscreen()
    this.setState({isFull: false});
  }

  render() {
    return  this.state.isFull ?
      <a className='icon-size-actual uk-link-reset' onClick={this.goMin}></a>
      :
      <a className='icon-frame uk-link-reset' onClick={this.goFull}></a>
  }
    
    

}
