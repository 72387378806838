import React, { useState, useEffect } from "react";

import "../../../css/dietz.css";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;

const FormHeartFailureOPDPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_heartfailureopd",
      formType: "15",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  console.log("dataForm: ", dataForm);

  const lblProtect = {
    1: "ไม่",
    2: "ถุงยาง",
  };

  const lblVaccine = {
    1: "Yes",
    2: "No",
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="บันทึกการตรวจโรคผู้ป่วยนอก (HEART FAILURE CLINIC)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%", padding: "0px 20px 0px 0px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 0px 0px 0px",
              }}
            >
              <FormElementLabel label="Date: " padding="0px 10px 0px 0px" />
              <FormElementTextBox
                value={dataForm.data_1}
                type="date"
                format="DD/MM/YYYY"
                mode="view"
                width="100"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px 20px 0px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormElementLabel
                  label="BW: "
                  padding="0px 10px 0px 0px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_2} mode="view" />
                <FormElementLabel
                  label="kgs."
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel
                  label="("
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_3} mode="view" />
                <FormElementLabel
                  label=")"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <FormElementLabel
                  label="HR: "
                  padding="0px 10px 0px 0px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_4} mode="view" />
                <FormElementLabel
                  label="ครั้ง/นาที"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel
                  label="BP: "
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_5} mode="view" />
                <FormElementLabel
                  label="/"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_6} mode="view" />
                <FormElementLabel
                  label="(นอน)"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <FormElementLabel
                  label="RR: "
                  padding="0px 10px 0px 0px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_7} mode="view" />
                <FormElementLabel
                  label="ครั้ง/นาที"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel
                  label="BP: "
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_8} mode="view" />
                <FormElementLabel
                  label="/"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_9} mode="view" />
                <FormElementLabel
                  label="(นั่ง)"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <FormElementLabel
                  label="SaO2: "
                  padding="0px 10px 0px 0px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_10} mode="view" />
                <FormElementLabel
                  label="%"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel
                  label="BP: "
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_11} mode="view" />
                <FormElementLabel
                  label="/"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
                <FormElementLabel label={dataForm.data_12} mode="view" />
                <FormElementLabel
                  label="(ยืน)"
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <FormElementLabel
                  label="Functional Class: "
                  padding="0px 10px 0px 0px"
                />
                <FormElementLabel
                  value={dataForm.data_13}
                  translate={{ 1: "I", 2: "II", 3: "III", 4: "IV" }}
                  mode="view"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <FormElementLabel
                  label="Nurse note: "
                  padding="0px 10px 0px 0px"
                />
                <FormElementTextArea value={dataForm.data_14} mode="view" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <FormElementLabel label="ER visit: " />
                <FormElementLabel
                  mode="view"
                  value={dataForm.data_125}
                  translate={{ 1: "Yes", 0: "No" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <FormElementLabel label="ER admit: " />
                <FormElementLabel
                  mode="view"
                  value={dataForm.data_126}
                  translate={{ 1: "Yes", 0: "No" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <FormElementLabel
                  label="Pharmacist note: "
                  padding="0px 10px 0px 0px"
                />
                <FormElementTextArea value={dataForm.data_15} mode="view" />
              </div>
            </div>
          </div>

          <div
            style={{
              width: "50%",
              padding: "0px 0px 0px 20px",
              borderLeft: "1px solid #000000",
            }}
          >
            <div style={{ position: "relative", marginLeft: "-48px" }}>
              <div style={{ position: "relative", width: "200px" }}>
                <div
                  style={{
                    position: "absolute",
                    width: "80px",
                    top: "48px",
                    left: "38px",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_16}
                    mode="view"
                    width="100"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "80px",
                    top: "48px",
                    left: "162px",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_17}
                    mode="view"
                    width="100"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "80px",
                    top: "132px",
                    left: "90px",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_18}
                    mode="view"
                    width="100"
                  />
                </div>
              </div>
              <div style={{ position: "absolute", left: "0px", top: "48px" }}>
                <div
                  style={{
                    position: "absolute",
                    width: "100px",
                    height: "1px",
                    left: "60px",
                    backgroundColor: "gray",
                    transform: "rotate(90deg)",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    width: "100px",
                    height: "1px",
                    left: "100px",
                    top: "70px",
                    backgroundColor: "gray",
                    transform: "rotate(30deg)",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    width: "100px",
                    height: "1px",
                    left: "20px",
                    top: "70px",
                    backgroundColor: "gray",
                    transform: "rotate(-30deg)",
                  }}
                ></div>
              </div>
              <div style={{ position: "relative" }}>
                <div style={{ position: "relative", width: "200px" }}>
                  <div
                    style={{
                      position: "absolute",
                      width: "80px",
                      top: "24px",
                      left: "280px",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_19}
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "80px",
                      top: "24px",
                      left: "410px",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_20}
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "80px",
                      top: "114px",
                      left: "280px",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_21}
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "80px",
                      top: "114px",
                      left: "410px",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_22}
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "80px",
                      top: "20px",
                      left: "580px",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_23}
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "80px",
                      top: "70px",
                      left: "580px",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_24}
                      mode="view"
                      width="100"
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "80px",
                      top: "120px",
                      left: "580px",
                    }}
                  >
                    <FormElementTextBox
                      value={dataForm.data_25}
                      mode="view"
                      width="100"
                    />
                  </div>
                </div>
                <div
                  style={{ position: "absolute", left: "264px", top: "20px" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "290px",
                      height: "1px",
                      left: "20px",
                      top: "70px",
                      backgroundColor: "gray",
                    }}
                  ></div>
                  <div
                    style={{
                      position: "absolute",
                      width: "200px",
                      height: "1px",
                      left: "20px",
                      top: "70px",
                      backgroundColor: "gray",
                      transform: "rotate(90deg)",
                    }}
                  ></div>
                  <div
                    style={{
                      position: "absolute",
                      width: "94px",
                      height: "1px",
                      left: "220px",
                      top: "94px",
                      backgroundColor: "gray",
                      transform: "rotate(35deg)",
                    }}
                  ></div>
                  <div
                    style={{
                      position: "absolute",
                      width: "94px",
                      height: "1px",
                      left: "220px",
                      top: "46px",
                      backgroundColor: "gray",
                      transform: "rotate(-35deg)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0px 0px 0px",
                marginTop: "200px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <FormElementLabel
                  label="Echo last: "
                  padding="0px 10px 0px 0px"
                  mode="view"
                />
                <FormElementTextBox
                  value={dataForm.data_26}
                  type="date"
                  format="DD/MM/YYYY"
                  mode="view"
                  width="100"
                />
                <FormElementLabel
                  label="EF: "
                  padding="0px 10px 0px 40px"
                  mode="view"
                />
                <FormElementTextBox value={dataForm.data_27} mode="view" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <FormElementLabel
                  label="NT-pro BNP: "
                  padding="0px 10px 0px 40px"
                  mode="view"
                />
                <FormElementTextBox value={dataForm.data_28} mode="view" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <FormElementLabel
                  label="Doctor note รายละเอียดการซักประวัติ ตรวจร่างกาย: "
                  padding="0px 10px 0px 40px"
                  mode="view"
                />
                <FormElementTextArea value={dataForm.data_29} mode="view" />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // paddingTop: "250px",
          }}
        >
          <FormElementLabel
            label="การคุมกำเนิด: "
            padding="0px 10px 0px 0px"
            mode="view"
          />
          <FormElementTextBox value={dataForm.data_30} mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px",
          }}
        >
          <FormElementLabel
            label="Flu. vaccine: "
            padding="0px 10px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            value={dataForm.data_31}
            translate={lblVaccine}
            padding="0px 10px 0px 0px"
            mode="view"
          />
          {dataForm.data_31 == "1" && (
            <FormElementTextBox value={dataForm.data_32} mode="view" />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px",
          }}
        >
          <FormElementLabel
            label="Covid vaccine: "
            padding="0px 10px 0px 0px"
            mode="view"
          />
          <FormElementLabel
            value={dataForm.data_33}
            mode="view"
            translate={{ 1: "Yes", 0: "No" }}
          />

          {dataForm.data_33 == "1" && (
            <div
              style={{ display: "inline-flex", gap: "8px", marginLeft: "8px" }}
            >
              <FormElementLabel
                label="วันที่ฉีดยาล่าสุด"
                mode="view"
                translate={{ 1: "Yes" }}
              />
              <FormElementTextBox
                value={dataForm.data_124}
                type="date"
                format="DD/MM/YYYY"
                mode="view"
                width="100"
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          <FormElementLabel
            label="Medications: "
            padding="0px 10px 0px 0px"
            mode="view"
          />

          <div>
            <table
              style={{ borderCollapse: "collapse", border: "1px solid grey" }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    Drug
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    รายการยาเดิม{" "}
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    As Ordered
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    Actual
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    จํานวนยาคงเหลือ
                  </th>
                  <th
                    style={{
                      background: "#EFF0F3",
                      borderCollapse: "collapse",
                      border: "1px solid grey",
                    }}
                  >
                    สรุปจํานวนยา
                  </th>
                </tr>
              </thead>
              <tbody>
                {(dataForm.data_34 ||
                  dataForm.data_35 ||
                  dataForm.data_36 ||
                  dataForm.data_37 ||
                  dataForm.data_38) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Antiplatelet
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_34}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_35}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_36}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_37}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_38}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_39 ||
                  dataForm.data_40 ||
                  dataForm.data_41 ||
                  dataForm.data_42 ||
                  dataForm.data_43) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Anticoagulant
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_39}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_40}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_41}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_42}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_43}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_44 ||
                  dataForm.data_45 ||
                  dataForm.data_46 ||
                  dataForm.data_47 ||
                  dataForm.data_48) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        ACEI/ARB/ARNI
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_44}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_45}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_46}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_47}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_48}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_49 ||
                  dataForm.data_50 ||
                  dataForm.data_51 ||
                  dataForm.data_52 ||
                  dataForm.data_53) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        β-Blocker
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_49}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_50}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_51}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_52}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_53}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_54 ||
                  dataForm.data_55 ||
                  dataForm.data_56 ||
                  dataForm.data_57 ||
                  dataForm.data_58) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Spironolactone
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_54}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_55}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_56}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_57}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_58}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_59 ||
                  dataForm.data_60 ||
                  dataForm.data_61 ||
                  dataForm.data_62 ||
                  dataForm.data_63) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        SGLT2i
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_59}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_60}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_61}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_62}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_63}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_64 ||
                  dataForm.data_65 ||
                  dataForm.data_66 ||
                  dataForm.data_67 ||
                  dataForm.data_68) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Ivabradine
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_64}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_65}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_66}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_67}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_68}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_69 ||
                  dataForm.data_70 ||
                  dataForm.data_71 ||
                  dataForm.data_72 ||
                  dataForm.data_73) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Digoxin
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_69}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_70}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_71}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_72}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_73}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_74 ||
                  dataForm.data_75 ||
                  dataForm.data_76 ||
                  dataForm.data_77 ||
                  dataForm.data_78) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Vericiguat
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_74}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_75}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_76}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_77}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_78}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_79 ||
                  dataForm.data_80 ||
                  dataForm.data_81 ||
                  dataForm.data_82 ||
                  dataForm.data_83) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Nitrate
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_79}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_80}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_81}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_82}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_83}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_84 ||
                  dataForm.data_85 ||
                  dataForm.data_86 ||
                  dataForm.data_87 ||
                  dataForm.data_88) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Hydralazine
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_84}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_85}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_86}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_87}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_88}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_89 ||
                  dataForm.data_90 ||
                  dataForm.data_91 ||
                  dataForm.data_92 ||
                  dataForm.data_93) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Diuretic
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_89}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_90}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_91}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_92}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_93}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_94 ||
                  dataForm.data_95 ||
                  dataForm.data_96 ||
                  dataForm.data_97 ||
                  dataForm.data_98) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Lipidloweringagent
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_94}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_95}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_96}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_97}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_98}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}
                {(dataForm.data_99 ||
                  dataForm.data_100 ||
                  dataForm.data_101 ||
                  dataForm.data_102 ||
                  dataForm.data_103) && (
                    <tr>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        Antidiabeticagent
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_99}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_100}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_101}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_102}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          border: "1px solid grey",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_103}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  )}

                {/* //TODO ADD MORE HERE */}






                {(dataForm.data_104 || dataForm.data_105 || dataForm.data_106 || dataForm.data_107 || dataForm.data_108
                  || dataForm.data_109 || dataForm.data_110 || dataForm.data_111 || dataForm.data_112 || dataForm.data_113
                  || dataForm.data_114 || dataForm.data_115 || dataForm.data_116 || dataForm.data_117 || dataForm.data_118
                  || dataForm.data_119 || dataForm.data_120 || dataForm.data_121 || dataForm.data_122 || dataForm.data_123) &&
                  (<>
                    <tr>
                      <td rowSpan={4} >Other antihypertensive</td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_104}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_105}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_106}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_107}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_108}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_109}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_110}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_111}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_112}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_113}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_114}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_115}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_116}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_117}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_118}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_119}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_120}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_121}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_122}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                      <td>
                        <FormElementTextBox
                          value={dataForm.data_123}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </td>
                    </tr>
                  </>)}
              </tbody>
            </table>
            <SavingProfileIPaper profile={profile} />
          </div>
        </div>
      </div>
    </div >
  );
};

export default FormHeartFailureOPDPaper;
