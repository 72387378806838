import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';
import numeral from 'numeral';

UIkit.use(Icons);

export default class CampaignReportChatView extends Component {

  state = {
    chat: <span uk-spinner=""></span>
  }

  componentDidMount() {
    this.userGet();
  }

  async userGet() {
    var {chat} = await Api.member('CampaignReportChatView', {campaignId: this.props.id});
    this.setState({chat});
  }

  render() {
    return <IconCard option={{
      backgroundColorLeft: '#008385',
      backgroundColorRight: '#00b5b8',
      icon: 'bubbles',
      title: 'Chat View',
      caption: '',
      value: this.state.chat || 0
    }} />
  }
};
