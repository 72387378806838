import Api from "../Api";

async function fetchEducationItem() {
    try {
        var result = await Api.consult("GetEducationItem", {});
        var { data } = result;

        if (!data) return [];

        const dataArray = Object.keys(data);

        return dataArray.map((item) => ({
            value: item,
            label: data[item],
        }));

    } catch (error) {
        console.error(`fetchEducationItem`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchEducationItem;