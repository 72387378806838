import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";

import "../../../css/dietz.css";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementTextBox from "../Form/FormElementTextBox";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

const FormHeartFailureDischargeCheckListPaper = (props) => {
  const { dataForm, patient, profile } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="Heart Failure Data ศูนย์โรคหัวใจภาคเหนือ โรงพยาบาลมหาราชนครเชียงใหม่"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementTitle
            label="โรงพยาบาลมหาราชนครเชียงใหม่คณะแพทยศาสตร์มหาวิทยาลัยเชียงใหม่ (PCT Cardiology)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementTitle
            label="เลือกช่อง Yes ถ้าได้ทำหรือ เลือกช่อง No ถ้าไม่ได้ทำ"
            mode="view"
          />
          <table
            style={{
              marginTop: "14px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  หัวข้อ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Reason Not Done/Contraindications
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={16}
                >
                  แพทย์/เภสัชกร
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1. ปัจจัยที่ทำให้อาการกำเริบ * ได้รับการค้นหาและแก้ไขแล้ว
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_1}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>

                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_2}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "450px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>
                    2. ได้รับการตรวจ Echocardiogram แล้ว
                    (ในรายที่ไม่เคยตรวจมาก่อน)
                  </span>
                  <FormElementTextBox
                    padding="0px 0px 0px 10px"
                    value={
                      "EF: " +
                      `${dataForm.data_105n ? dataForm.data_105n : ""}` +
                      " %"
                    }
                    width="100"
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_3}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>

                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_4}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3.ไม่มีภาวะน้ำ เกินหรือขาดสารน้ำ อย่างชัดเจน
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_5}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_6}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4. หยุด NTG, Dobutamine และ Furosemide
                  ที่ให้ทางหลอดเลือดดำมาเกิน 24 ชม.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_7}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_8}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5.
                  เปลี่ยนจากยาขับปัสสาวะแบบฉีดเป็นแบบรับประทานแล้วและได้ประเมินผลว่ายาขับปัสสาวะแบบรับประทานสามารถควบคุมอาการผู้ป่วยได้
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_9}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_10}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6. ไม่มีความจำเป็นในการปรับยารับประทานมาเกิน 24 ชั่วโมง
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_11}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_12}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7.
                  ค่าการทำงานของไตและเกลือแร่คงที่และอยู่ในระดับปกติหรือใกล้เคียงปกติตาม
                  baseline ของผู้ป่วย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_13}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_14}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  8. ในผู้ป่วย HFrEF ได้เริ่มยาดังนี้ (ในรายใหม่) หรือปรับ dose
                  ให้เหมาะสมแล้ว (ในรายเก่า)
                  หากไม่สามารถให้ยาได้หรือจำเป็นต้องลดขนาดยาได้มีการบันทึกเหตุผลไว้แล้ว
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_15}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_16}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - ACEI/ARB/ARNI
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_17}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_18}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - Beta-blocker
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_19}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_20}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - Aldosterone Receptor Antagonists
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_21}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_22}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - SGLT2 inhibitor
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_23}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_24}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  9.
                  ปัจจัยเสี่ยงของโรคหัวใจและหลอดเลือดได้รับการประเมินและควบคุมแล้ว
                  เช่น ระดับ LDL, FBS, HbA1C
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_25}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_26}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  10.
                  ผู้ป่วยได้รับการให้ความรู้เรื่องการปฏิบัติตัวในการดูแลตนเองสำหรับโรคหัวใจล้มเหลวโดยพยาบาลประจำหอผู้ป่วยหรือพยาบาลประจำ
                  HF clinic
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_27}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_28}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  11. มีการปรึกษาเพื่อนัดเข้าคลินิกหัวใจล้มเหลว
                  ในรายที่เข้าเกณฑ์
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_29}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_30}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  12. มีการนัดผู้ป่วย 1-2 สัปดาห์หลังจำหน่ายที่เรียบร้อยแล้ว
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_31}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_32}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={10}
                >
                  พยาบาล
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การวางแผนการจำหน่ายเน้นประเด็นเหล่านี้
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1.ให้ผู้ป่วยเริ่ม Ambulate เพื่อประเมิน Function Capacity
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_33}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_34}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2. การสอนและให้คำแนะนำในหัวข้อดังนี้
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_35}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_36}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - การเฝ้าระวังภาวะน้ำหนักเกิน สังเกตอาการบวม การชั่งน้ำหนัก
                  (แนะนำให้ผู้ป่วยมีเครื่องชั่งน้ำหนักที่บ้าน)
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_37}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_38}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - การรับประทานยาอย่างต่อเนื่องและถูกต้อง หลีกเลี่ยงยาสมุนไพรยา
                  NSAID
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_39}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_40}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - การงดเหล้าและบุหรี่{" "}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_41}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_42}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - การหลีกเลี่ยงอาหารรสเค็ม การจำกัดน้ำดื่ม (ในบางรายการ)
                  โดยเฉพาะผู้ป่วยที่ยังบวม, ผู้ป่วยโรคไต
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_43}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_44}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - การกลับไปทำงาน การทำกิจกรรมและการออกกำลังกายที่เหมาะสม
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_45}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_46}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  - การสอนการปรับยาขับปัสสาวะโดยผู้ป่วยเอง (Flexible diuretic
                  regimen)
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_47}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextArea
                    rows={1}
                    value={dataForm.data_48}
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementLabel
            mode="view"
            label="* ปัจจัยที่ทำให้อาการกำเริบเช่น – Noncompliance เรื่องการรับประทานยา อาหาร และน้ำดื่ม / การได้รับยา NSAID
          / COX 2 inhibitor / ยาเบาหวานกลุ่ม TZD / ภาวะโรคร่วมกำเริบ, ภาวะซีดมาก ไทรอยด์เป็นพิษ ภาวะติดเชื้อ"
          />
          <SavingProfileIPaper profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default FormHeartFailureDischargeCheckListPaper;
