import Api from "../../Components/Api";

async function fetchDrugAllergic(hn) {
    try {
        var result = await Api.consult("GetDrugAllergic", {
            hn: hn,
        });

        try {
            if (result.list.data.allergic_desc[0].list) {
                return result.list.data.allergic_desc[0].list;
            }
            return [];
        } catch (ignored) {
            return [];
        }
    } catch (error) {
        console.error(`fetchDrugAllergic: hn(${hn})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchDrugAllergic;