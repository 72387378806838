import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import UploadFirebase from '../../Components/UploadFirebase'; 
import ReactPaginate from 'react-paginate';
import ImageUri from '../../Components/ImageUri';

class AdminCompanyAddForm extends Component {
  field = [
    { key: 'name', label: 'ชื่อบริษัท' },
    { key: 'datestart', label: 'วันที่เริ่มต้น ค.ศ. (ปปปป-ดด-วว)' },
    { key: 'datestop', label: 'วันที่สิ้นสุด ค.ศ. (ปปปป-ดด-วว)' },
    { key: 'caption', label: 'รายละเอียด' },
    { key: 'hospital_number', label: 'เลขที่โรงพยาบาล' },
  ]

  state = {
    companygroup: [],
    companygroupNameNew: '',
    image: ''
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  companygroupGet = async (companyid) => {
    var {list = []} = await Api.admin('CompanygroupList', {companyid});
    this.setState({companygroup: list || []});
  }

  init = async () => {
    
    this.setState((state) => {
      var stateInit = {};
      stateInit.companygroup = [];

      if(this.props.company) {
        this.field.map(data => { stateInit[data.key] = this.props.company[data.key]; });
        stateInit.image = this.props.company.image || '';
      } else {
        this.field.map(data => { stateInit[data.key] = ''; });
        stateInit.image = '';
      }
      
      return stateInit;
    },()=>{

      if(this.props.company) this.companygroupGet(this.props.company.id);
    });

    if (this.props.show && UIkit && UIkit.modal('#modal-form-company')) {
      UIkit.modal('#modal-form-company').show();
    } else if(UIkit && UIkit.modal('#modal-form-company')) {
      UIkit.modal('#modal-form-company').hide();
    }

  }

  CompanygroupAdd = (e) => {
    e.preventDefault();

    var companygroup = this.state.companygroup;
    companygroup.push({
      id: false,
      name: this.state.companygroupNameNew,
      editing: false
    })

    this.setState({companygroup, companygroupNameNew: ''});
    
  }  

  CompanyAdd = async (e) => {
    e.preventDefault();
    var data = this.state;
    if(this.props.company) {
      data.id = this.props.company.id;
    }

    data.companygroup = this.state.companygroup;

    var { result, responseText } = await Api.admin( this.props.company ? 'CompanyUpdate' : 'CompanyAdd', data);
    UIkit.notification({ message: responseText, status: result ? 'success' : 'danger' })
    this.props.callback();
    UIkit.modal('#modal-form-company').hide();
  }


  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return <div id="modal-form-company" uk-modal="bg-close: false; esc-close: false; stack: true;">
      <div className="uk-modal-dialog uk-modal-body">
        <button className="uk-modal-close-default" type="button" uk-close=""></button>
        <h2 className="uk-modal-title">จัดการข้อมูลบริษัท</h2>
        <form onSubmit={this.CompanyAdd} className="uk-width-1-1">
          <div className="uk-margin-small-bottom">
            <label className="uk-button uk-button-default">
              เลือกรูปภาพ
              <UploadFirebase maxWidth={500} maxHeight={500} cb={(image)=>{
                this.setState({image})
              }} />
            </label>
            {this.state.image !== '' && <div className="uk-margin-small"><img src={ImageUri(this.state.image)} uk-img="" width="200" /></div>}
          </div>
          {
            this.field.map(data => {
              return <div className="uk-margin-small-bottom">
                <label>{data.label}</label>
                <input type="text" className="uk-input" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
              </div>
            })
          }
          <div className="uk-margin-small-bottom">
            <label>เพิ่มกลุ่ม</label>
            <div className="uk-width-1-1 uk-button-group">
              <input type="text" className="uk-input" placeholder="ชื่อกลุ่ม" value={this.state.companygroupNameNew} onChange={e=>this.setState({companygroupNameNew: e.target.value})} />
              <button type="button" className="uk-button uk-button-small uk-button-secondary" onClick={this.CompanygroupAdd} >
                <span uk-icon="icon: plus;"></span>
              </button>
            </div>
          </div>
          <div className="uk-margin-small-bottom">
            <label>รายชื่อกลุ่มปัจจุบัน</label>
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
              <tr>
                <th>ลำดับ</th>
                <th>รหัส</th>
                <th>ชื่อกลุ่ม</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {
                this.state.companygroup && this.state.companygroup.length > 0 ?
                this.state.companygroup.map((data,index)=>{
                  return <tr>
                    <td>{index+1}</td>
                    <td>{data.id}</td>
                    <td>
                      {
                        data.editing ? 
                        <input type="text" className="uk-input uk-form-small" value={data.name} onChange={e=>{
                          data.name= e.target.value;
                          this.setState({companygroup: this.state.companygroup});
                        }} />
                        :
                        data.name
                      }
                    </td>
                    <td>
                      <span uk-icon="icon: pencil;" onClick={()=>{
                        data.editing = true;
                        this.setState({companygroup: this.state.companygroup});
                      }} ></span>
                      <span uk-icon="icon: trash;" onClick={()=>{
                        if(window.confirm('ยืนยันการลบรายการ?')) {
                          var companygroup = this.state.companygroup;
                          if(companygroup[index].id) {
                            Api.admin('CompanygroupRemove', {id: companygroup[index].id});
                          }
                          
                          delete companygroup[index];
                          this.setState({companygroup: companygroup.filter(Boolean)})
                        }
                      }}></span>
                    </td>
                  </tr>
                })
                :
                <tr>
                  <td colspan="100%" className="uk-text-center">ไม่พบรายการ</td>
                </tr>
              }
              </tbody>
            </table>
          </div>
          <button className="uk-input uk-button-primary uk-margin-small-top" type="submit">{this.props.company ? 'บันทึก' : 'เพิ่ม' }</button>
        </form>
      </div>
    </div>
  }
}

export default class AdminCompany extends Component {

  state = {
    companyList: [],
    formShow: false,
    company: false,
    pageCount: 0,
    pageNow: 1,
    keyword: ''
  }

  perPage = 100

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    this.companyListGet();
  }

  companyListGet = async (e) => {
    if(e != null) e.preventDefault();
    
    var { list, amount } = await Api.admin('CompanyList', {
      perPage : this.perPage,
      pageNow : this.state.pageNow,
      keyword: this.state.keyword
    });

    this.setState({ companyList: list || [], formShow: false, pageCount: +amount/this.perPage  }, ()=>this.setState({formShow: false}));
  }

  
  handlePageClick = (page) => {
    this.setState({pageNow: page.selected+1}, ()=>this.companyListGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
  }

  render() {
    return <div>
      <div className="d-admin-header-row uk-text-right">
        <form className='uk-display-inline-block uk-margin-right' onSubmit={this.companyListGet}>
          <input type="text" placeholder='ค้นหาบริษัท' className="uk-input uk-width-small" onChange={(e)=>{
            this.setState({
              keyword: e.target.value
            });
          }} />
          <button className='uk-button uk-button-secondary' uk-icon="search"></button>
        </form>
        <button className="uk-button uk-button-small uk-button-secondary" onClick={() => this.setState({ company: false,formShow: true }, () => this.setState({ formShow: true }))} >เพิ่มบริษัท</button>
      </div>
      <AdminCompanyAddForm  {...this.props} callback={this.companyListGet} show={this.state.formShow} company={this.state.company} />

      {this.paginateRender()}
      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>รหัสบริษัท</th>
            <th>ชื่อบริษัท</th>
            <th>วันที่เริ่มต้น</th>
            <th>วันที่สิ้นสุด</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.companyList.map((data, key) => {
              return <tr key={`Company${data.id}`}>
                <td>{key + 1}</td>
                <td>{data.id}</td>
                <td>{data.name}</td>
                <td>{data.datestart}</td>
                <td>{data.datestop}</td>
                <td>
                  <button className="uk-button uk-button-small" onClick={() => this.setState({ company: data, formShow: true }, () => this.setState({ formShow: true }))} >
                    <i uk-icon="icon: pencil"></i>
                  </button>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>

      {this.paginateRender()}
    </div>
  }
};
