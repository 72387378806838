import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import ToolTipsPopUp from "../Utils/ToolTipsPopUp";
import SearchTeleSection from "../Utils/SearchTeleSection";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchNxOpdList from "../../API/fetchNxOpdList.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchConsultFormListByHn from "../../API/fetchConsultFormListByHn.js";
import fetchConsultFormListBySearch from "../../API/fetchConsultFormListBySearch.js";
import FormNoacPaper from "../FormPaper/FormNoacPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;
var utilGetValueChanged = require("../Common/FormUtil.js").getValueChanged;

const CONSTANTS = {
    DATA_NAME: "data_form_list",
    QUESTIONS: [
        " 1. ผู้ป่วยทราบว่ายา NOACs เป็นยาต้านการแข็งตัวของเลือด",
        " 2. ผู้ป่วยทราบข้อบ่งใช้ของยา NOACs และทราบความแตกต่างระหว่างยา NOACs และยาวาร์ฟาริน (กรณีเปลี่ยนยาวาร์ฟารินเป็นยา NOACs)",
        " 3. ผู้ป่วยทราบขนาดยาที่ได้รับวันนี้ และ ทราบวิธีปฏิบัติตัวเมื่อลืมรับประทานยา",
        " 4. ผู้ป่วยทราบว่า ไม่ควรปรับเพิ่มหรือลดขนาดยาเอง และทราบเหตุผลที่ต้องรับประทานยาตรงเวลาอย่างเคร่งครัด",
        " 5. ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะเลือดออกที่ผิดปกติ และทราบวิธีปฏิบัติตัวหากเกิดภาวะดังกล่าว",
        " 6. ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะลิ่มเลือดอุดตัน",
        " 7. ผู้ป่วยทราบว่าต้องแจ้งบุคลากรทางการแพทย์ทุกครั้งที่เข้ารับบริการว่าตนรับประทานยา NOACs อยู่ โดยเฉพาะการทำทันตกรรม การวางแผนตั้งครรภ์ และไม่ควรฉีดยาเข้ากล้ามเนื้อ",
        " 8. ผู้ป่วยทราบว่าต้องปรึกษาแพทย์หรือเภสัชกรทุกครั้งก่อนซื้อยา อาหารเสริม หรือสมุนไพรมารับประทานเอง และทราบว่าควรหลีกเลี่ยงการดื่มแอลกอฮอล์ งดสูบบุหรี่",
        " 9. ผู้ป่วยทราบว่าควรหลีกเลี่ยงกิจกรรมที่เสี่ยงต่อกระแทกหรือการบาดเจ็บ",
        " 10. ผู้ป่วยทราบว่าควรเก็บยาให้พ้นแสง และความชื้น",
    ],
    ADDITIONAL_LABELS: [
        "คะแนนรวม",
        "ผู้รับการประเมิน (ผู้ป่วย/ญาติ)",
        "เภสัชกรผู้ประเมิน",
    ],
};

const FormNoac = (props) => {
    const formConstants = {
        formName: "form_noac",
        formType: "23",
    };

    const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, txn, hn } = props;

    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);
    var [isShowToolTipsPopup, setIsShowToolTipsPopup] = useState(false);
    var [toolTipId, setToolTipId] = useState(null);
    var [lastIndex, setLastIndex] = useState(0);
    var parentTableContainer = useRef();
    var [tableMaxWidth, setTableMaxWidth] = useState(0);
    var [dataFormOlds, setDataFormOlds] = useState([]);
    var [nxOpdDropDownList, setNxOpdDropDownList] = useState([]);

    const [isPreview, setIsPreview] = useState(false);


    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    const handlePreviewOpenChange = useCallback((open) => {
        setIsPreview(open);
    }, [setIsPreview])

    const displayLoading = useCallback(() => {
        onLoading(true);
    }, [onLoading]);

    const hideLoading = useCallback(() => {
        onLoading(false);
    }, [onLoading]);

    const displayErrorFetchPopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
    }, [onErrorMessageChange]);

    const displayErrorSavePopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
    }, [onErrorMessageChange]);

    const prepareData = useCallback((form, patientResponse, oldDataResponse, profile) => {
        if (Array.isArray(oldDataResponse)) {
            if (oldDataResponse.length > 2) {
                const data = oldDataResponse.slice(-2);
                data.reverse();
                setDataFormOlds(data);
            } else if (oldDataResponse.length > 0 && oldDataResponse.length <= 2) {
                const data = oldDataResponse;
                data.reverse();
                setDataFormOlds(data);
            } else {
                setDataFormOlds([]);
            }
        }

        if (isNaN(form?.data_no)) {
            form.data_no = Array.isArray(oldDataResponse) ? oldDataResponse?.length + 1 : 1;
        }

        if (!form?.data_27) {
            form.data_27 = patientResponse.patient_name;
        }

        if (!form?.data_21) {
            form.data_21 = "1";
        }

        if (profile?.doctorName) {
            form.data_22 = profile.doctorName;
        }

        return form;
    }, []);

    const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
        displayLoading();
        const nxOpdListRequest = fetchNxOpdList();
        const oldDataRequest = fetchConsultFormListByHn(hn, txn, formConstants.formType);

        Promise.all([nxOpdListRequest, oldDataRequest]).then((values) => {
            const nxOpdListResponse = values[0];
            const oldDataResponse = values[1];

            data = prepareData({ ...data }, patient, oldDataResponse, profile);
            setNxOpdDropDownList(nxOpdListResponse);
            setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
            hideLoading();
            document.body.style.overflow = "hidden";
        }).catch((error) => {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
            document.body.style.overflow = "hidden";
        });
    });

    const fetchOtherData = useCallback(async (data, hn, txn) => {
        const patientData = fetchMemberByTxn(hn, txn);
        const nxOpdListRequest = fetchNxOpdList();
        const oldDataRequest = fetchConsultFormListByHn(hn, txn, formConstants.formType);
        const patientRoomRequest = fetchMemberListHis(hn, txn);
        Promise.all([nxOpdListRequest, patientData, oldDataRequest, patientRoomRequest]).then((values) => {
            const nxOpdListResponse = values[0];
            const patientResponse = values[1];
            const oldDataResponse = values[2];
            const patientRoomResponse = values[3];

            data = prepareData({ ...data }, patientResponse, oldDataResponse, profile);
            setNxOpdDropDownList(nxOpdListResponse);
            setPatient({ ...patientResponse, ...patientRoomResponse });
            setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
            hideLoading();
        }).catch((error) => {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
        });
    });

    const fetchData = useCallback(async (formId, hn, txn) => {
        try {
            displayLoading();
            setDataForm({});
            let data = {};
            let hnValue, txnValue;
            if (formId) {
                data = await fetchConsultFormByFormId(formId, formConstants.formType);
                hnValue = data.hn;
                txnValue = data.txn;
            } else {
                data = await fetchConsultFormByTxn(txn, formConstants.formType);
                hnValue = hn;
                txnValue = txn;
            }
            await fetchOtherData(data, hnValue, txnValue);
        } catch (error) {
            hideLoading();
            displayErrorFetchPopup();
        }
    }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

    // fetch data when has formId #Edit
    useEffect(() => {
        if (!isSubForm) {
            if (formId) fetchData(formId, null, null)
        }
    }, [formId, isSubForm]);

    // fetch data when formId is null and hn,txn are not null #Add or this is subForm
    useEffect(() => {
        if (!isSubForm) {
            if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
        }
    }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

    // use for subForm
    useEffect(() => {
        if (isSubForm) {
            fetchSubFormOtherData(data, data.hn, data.txn);
        }
    }, [isSubForm, data]);

    useEffect(() => {
        if (!isSubForm) {
            if (!formId && hn && txn) fetchData(null, hn, txn)
        }
    }, [hn, txn, formId, isSubForm]);

    useEffect(() => {
        if (parentTableContainer.current) {
            setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
        }
    }, [parentTableContainer]);

    const openToolTipsPopUp = useCallback((id) => {
        setIsShowToolTipsPopup(true);
        setToolTipId(id);
    });

    const closeToolTipsPopUp = useCallback(() => {
        setIsShowToolTipsPopup(false);
        setToolTipId(null);
    });

    const validateSave = useCallback(() => {
        return true;
    }, [])

    const handleSave = useCallback(async (html) => {
        async function saveForm(paperHtml) {
            displayLoading();
            try {
                if (validateSave()) {
                    const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
                    handlePreviewOpenChange(false);
                    await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
                    hideLoading();
                    onSuccessPopupOpen(true);
                } else {
                    hideLoading();
                }
            } catch (error) {
                hideLoading();
                displayErrorSavePopup();
            }
        }
        saveForm(html);
    });

    const getToolTipsContentBody = useMemo(() => {
        switch (toolTipId) {
            case 1:
                return <TooltipContent1 />;
            case 2:
                return <TooltipContent2 />;
            case 3:
                return <TooltipContent3 />;
            case 4:
                return <TooltipContent4 />;
            case 5:
                return <TooltipContent5 />;
            case 6:
                return <TooltipContent6 />;
            case 7:
                return <TooltipContent7 />;
            case 8:
                return <TooltipContent8 />;
            case 9:
                return <TooltipContent9 />;
            case 10:
                return <TooltipContent10 />;
            default:
                return null;
        }
    }, [isShowToolTipsPopup, toolTipId]);

    const renderToolTipsContent = useMemo(() => {
        return (
            <>
                <table
                    className="uk-table uk-table-small uk-table-divider uk-table-striped"
                    style={{ width: "400px" }}
                >
                    <thead>
                        <tr>
                            <th className="center">แนวทางคำตอบ</th>
                        </tr>
                    </thead>
                    <tbody>{getToolTipsContentBody}</tbody>
                </table>
            </>
        );
    }, [isShowToolTipsPopup, toolTipId]);

    const renderAssessmentFormSubHeader1 = useMemo(() => {
        return (
            <React.Fragment>
                <th key={1} colSpan={3}>
                    ประเมินครั้งที่ {dataForm.data_no}
                </th>
                {dataFormOlds.map((item, i) => (
                    <th key={i} colSpan={3}>
                        ประเมินครั้งที่ {item.data_no}
                    </th>
                ))}
            </React.Fragment>
        );
    }, [dataFormOlds, dataForm]);

    const renderAssessmentFormSubHeader2 = useMemo(() => {
        return (
            <React.Fragment key={1}>
                <th>
                    <FormElementTextBox
                        fieldId={"23"}
                        value={dataForm.data_23}
                        type="date"
                        onChange={handleChange}
                        width="100"
                    />
                </th>
                <th>ติดตามครั้งถัดไป</th>
                <th rowSpan={2}>หมายเหตุ</th>
                {dataFormOlds.map((item, i) => (
                    <React.Fragment key={i}>
                        <th>
                            <FormElementTextBox
                                value={item.data_23}
                                type="date"
                                width="100"
                                disabled
                            />
                        </th>
                        <th>ติดตามครั้งถัดไป</th>
                        <th rowSpan={2}>หมายเหตุ</th>
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }, [dataFormOlds, dataForm]);

    const renderAssessmentFormSubHeader3 = useMemo(() => {
        return (
            <React.Fragment key={1}>
                <th>ทราบ</th>
                <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
                {dataFormOlds.map((item, i) => (
                    <React.Fragment key={i}>
                        <th>ทราบ</th>
                        <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }, [dataFormOlds, dataForm]);

    const renderAssessmentFormHeader = useMemo(() => {
        return (
            <React.Fragment key={"header"}>
                <tr>
                    <th rowSpan={3}>
                        หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
                    </th>
                    {renderAssessmentFormSubHeader1}
                </tr>
                <tr>{renderAssessmentFormSubHeader2}</tr>
                <tr>{renderAssessmentFormSubHeader3}</tr>
            </React.Fragment>
        );
    }, [dataFormOlds, dataForm]);

    const renderAssessmentQuestionForm = useMemo(() => {
        return CONSTANTS.QUESTIONS.map((questionLabel, questionIndex) => {
            questionIndex = ++questionIndex; // for supporting fleid data start with data_1.
            return (
                <tr key={`questions_content_${questionIndex}`}>
                    <td className="td-form-label">
                        <div className="question-label-container">
                            <div className="question-label">{questionLabel}</div>
                            <button
                                className="tooltip-button"
                                onClick={() => openToolTipsPopUp(questionIndex)}
                            >
                                <span
                                    className="info-icon"
                                    uk-icon="icon: info; ratio: 1.4"
                                ></span>
                            </button>
                        </div>
                    </td>
                    <td className="td-radio-input">
                        &nbsp;
                        <FormElementRadio
                            fieldId={`${questionIndex}n`}
                            value={dataForm[`data_${questionIndex}n`]}
                            fieldValue={"1"}
                            onChange={handleChange}
                        />
                    </td>
                    <td className="td-radio-input">
                        &nbsp;
                        <FormElementRadio
                            fieldId={`${questionIndex}n`}
                            value={dataForm[`data_${questionIndex}n`]}
                            fieldValue={"0"}
                            onChange={handleChange}
                        />
                    </td>
                    <td className="td-remark">
                        <FormElementTextArea
                            fieldId={`${CONSTANTS.QUESTIONS.length + questionIndex}`}
                            value={
                                dataForm[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
                            }
                            onChange={handleChange}
                            width="100"
                            rows={4}
                            cols={50}
                            noLimit
                        />
                    </td>
                    {dataFormOlds.map((item, i) => (
                        <React.Fragment key={i}>
                            <td>{item[`data_${questionIndex}n`] == "1" && "ทราบ"}</td>
                            <td style={{ whiteSpace: "nowrap" }}>
                                {item[`data_${questionIndex}n`] != "1" && "ไม่ทราบ"}
                            </td>
                            <td className="td-remark">
                                <FormElementTextArea
                                    value={
                                        item[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
                                    }
                                    width="100"
                                    rows={4}
                                    cols={50}
                                    disabled
                                />
                            </td>
                        </React.Fragment>
                    ))}
                </tr>
            );
        });
    }, [dataFormOlds, dataForm]);

    const getTotalScore = useCallback(
        (data) => {
            let rawScore = utilSumTotalScore([
                data.data_1n,
                data.data_2n,
                data.data_3n,
                data.data_4n,
                data.data_5n,
                data.data_6n,
                data.data_7n,
                data.data_8n,
                data.data_9n,
                data.data_10n,
            ]);

            return (rawScore * 100) / CONSTANTS.QUESTIONS.length;
        },
        [dataForm]
    );

    const getScoreDesc = useCallback(
        (data) => {
            if (!getTotalScore(data)) {
                return "";
            } else if (getTotalScore(data) >= 80) {
                return "ผ่านเกณฑ์";
            } else {
                return "ไม่ผ่านเกณฑ์";
            }
        },
        [getTotalScore]
    );

    const getFieldByAdditionalLabel = useCallback(
        (data, disabled, labelIndex) => {
            if (labelIndex === 0) {
                return (
                    <td colSpan={3}>
                        ({getTotalScore(data)}%) {getScoreDesc(data)}
                    </td>
                );
            } else if (labelIndex === 1) {
                return (
                    <td colSpan={3}>
                        <FormElementTextBox
                            fieldId={"27"}
                            placeholder="ชื่อผู้รับการประเมิน"
                            onChange={handleChange}
                            width="100"
                            value={data.data_27}
                            disabled={disabled}
                        />
                        <FormElementDropdown
                            fieldId={"21"}
                            value={data.data_21}
                            dataList={[
                                { label: "เลือกผู้รับการประเมิน", value: "0" },
                                { label: "ผู้ป่วย", value: "1" },
                                { label: "ญาติ", value: "2" },
                            ]}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                    </td>
                );
            } else if (labelIndex === 2) {
                return <td colSpan={3}>{data.data_22}</td>;
            } else {
                return "";
            }
        },
        [dataForm]
    );

    const renderAssessmentAdditionalForm = useMemo(() => {
        return CONSTANTS.ADDITIONAL_LABELS.map((labelItem, labelIndex) => (
            <tr key={`additional_content_${labelIndex}`}>
                <td className="uk-text-right">{labelItem}</td>
                {getFieldByAdditionalLabel(dataForm, false, labelIndex)}
                {dataFormOlds.map((item) =>
                    getFieldByAdditionalLabel(item, true, labelIndex)
                )}
            </tr>
        ));
    }, [dataForm, dataFormOlds]);

    const onSearch = useCallback((search) => {
        async function searchOldData(hn, type, search) {
            try {
                onLoading(true);
                const response = await fetchConsultFormListBySearch(hn, type, search);
                response.reverse();
                setDataFormOlds(response);
                onLoading(false);
            } catch (error) {
                onLoading(false);
            }
        }
        searchOldData(dataForm.hn, formConstants.formType, search);
    });

    const onClear = useCallback(() => {
        async function fetchOldData(hn, txn, type) {
            try {
                onLoading(true);
                const response = await fetchConsultFormListByHn(hn, txn, type);
                if (response.length > 2) {
                    const data = response.slice(-2);
                    data.reverse();
                    setDataFormOlds(data);
                } else if (response.length > 0 && response.length <= 2) {
                    const data = response;
                    data.reverse();
                    setDataFormOlds(data);
                } else {
                    setDataFormOlds([]);
                }
                onLoading(false);
            } catch (error) {
                onLoading(false);
            }
        }
        fetchOldData(dataForm.hn, dataForm.txn, formConstants.formType);
    });

    const handleAddDataToParent = useCallback((html) => {
        onAdd(dataForm, html)
    }, [dataForm, onAdd]);

    return (
        <>
            {isSubForm &&
                <FormMemberProfileHeader
                    memberData={patient}
                    onSelectMember={() => { }}
                    isSelectDisabled={true}
                />}
            <div
                uk-grid=""
                className="uk-grid uk-padding-small"
                ref={parentTableContainer}
                style={{ overflowX: "hidden" }}
            >
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementLabel label=" 3.2.2 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยาNOACs " />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTextArea
                        fieldId={"28"}
                        onChange={handleChange}
                        width="100"
                        label="วิธีการให้คำแนะนำยา"
                        cols={120}
                        rows={5}
                        value={dataForm.data_28}
                        noLimit
                    />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementLabel label="ช่วงเวลาการประเมิน" />
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <SearchTeleSection onSearch={onSearch} onClear={onClear} />
                    </div>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <div
                        className="form-table-container"
                        style={{ maxWidth: `${tableMaxWidth}px` }}
                    >
                        <div className="form-table-scroll">
                            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                                <thead>{renderAssessmentFormHeader}</thead>
                                <tbody>
                                    {renderAssessmentQuestionForm}
                                    {renderAssessmentAdditionalForm}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <FormElementLabel
                            label=" คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥8คะแนน) จึงถือว่า “ผ่านเกณฑ์”  
  หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ "
                        />
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <FormElementLabel label=" การนัดหมายครั้งถัดไป" />
                        <FormElementDropdown
                            fieldId={"24"}
                            label="ห้องตรวจ"
                            dataList={[{ value: "", label: "เลือกห้องตรวจ" }].concat(
                                nxOpdDropDownList
                            )}
                            onChange={handleChange}
                            width="100"
                            value={dataForm?.data_24 || ''}
                        />
                        <FormElementTextBox
                            fieldId={"25"}
                            type="date"
                            onChange={handleChange}
                            width="100"
                            value={dataForm.data_25}
                            minDate={dataForm.data_23}
                        />
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                        <FormElementTextArea
                            fieldId={"26"}
                            onChange={handleChange}
                            width="100"
                            label="ข้อมูลเพิ่มเติม"
                            cols={120}
                            rows={5}
                            value={dataForm.data_26}
                            noLimit
                        />
                    </div>
                </div>
                <ToolTipsPopUp show={isShowToolTipsPopup} onClose={closeToolTipsPopUp}>
                    {renderToolTipsContent}
                </ToolTipsPopUp>
                <FormActionButtonSection
                    isPreview={isPreview}
                    onOpen={() => handlePreviewOpenChange(true)}
                    onClose={() => handlePreviewOpenChange(false)}
                    onSave={handleSave}
                    onAdd={(html) => handleAddDataToParent(html)}
                    isSubForm={isSubForm}
                    formContent={
                        <FormNoacPaper
                            dataForm={dataForm}
                            patient={patient}
                            profile={profile}
                            nxOpdDropDownList={nxOpdDropDownList}
                        />}
                />
            </div>
        </>
    );
};

export default FormNoac;

const TooltipContent1 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            1. สามารถ<b>ระบุข้อใดข้อหนึ่ง</b> ได้แก่
                            <br />
                            - ยาต้านการแข็งตัวของเลือด
                            <br />
                            - ยาละลายลิ่มเลือด
                            <br />
                            - ยากันเลือดแข็ง หรือ คำตอบที่มีความหมายคล้ายกัน
                            <br />
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent2 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            2. สามารถระบุโรคของตนเองที่จำเป็นต้องได้รับยา NOAC สามารถ
                            <b>ระบุชื่อยา NOACs ที่ได้รับ</b>
                        </span>
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            กรณีผู้ป่วยเปลี่ยนจาก warfarin เป็น NOACs หากติตตามครั้งแรก
                            <b>ต้องสามารถระบุชื่อยา NOAC ที่ได้รับแทน warfarin</b>
                            และระบุได้ว่าเป็นยาต้านการแข็งตัวของเลือดที่ไม่ต้องเจาะติตตามระดับยา
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent3 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            3. สามารถอธิบาย<b>วิธีปฏิบัติตัวกรณีลืมทานยา</b>
                        </span>
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            1. ยาที่รับประทานวันละ 1 ครั้ง <b>ต้องอธิบายได้ทั้ง 2 กรณี</b>
                        </span>
                    </div>
                    <div
                        className="uk-width-1-1@m uk-width-1-1"
                        style={{ paddingLeft: "14px" }}
                    >
                        <span className="tooltip-content-label">
                            1.1 หากลืมทานยา<b>ไม่เกิน 12 ชม.</b> ให้รีบทานทันทีที่นึกขึ้นได้
                            ในขนาดยาเท่าเดิม
                        </span>
                    </div>
                    <div
                        className="uk-width-1-1@m uk-width-1-1"
                        style={{ paddingLeft: "14px" }}
                    >
                        <span className="tooltip-content-label">
                            1.2 หากลืมทานยา<b>เกิน 12 ชม.</b>{" "}
                            ข้ามไปทานมื้อต่อไปในขนาดเดิมเวลาเดิม โดยไม่ต้องเพิ่มขนาดยาเป็น 2
                            เท่า
                        </span>
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            2. ยาที่รับประทานวันละ 2 ครั้ง <b>ต้องอธิบายได้ทั้ง 2 กรณี</b>
                        </span>
                    </div>
                    <div
                        className="uk-width-1-1@m uk-width-1-1"
                        style={{ paddingLeft: "14px" }}
                    >
                        <span className="tooltip-content-label">
                            2.1 หากลืมทานยา<b>ไม่เกิน 6 ชม.</b> ให้รีบทานทันทีที่นึกขึ้นได้
                            ในขนาดยาเท่าเดิม
                        </span>
                    </div>
                    <div
                        className="uk-width-1-1@m uk-width-1-1"
                        style={{ paddingLeft: "14px" }}
                    >
                        <span className="tooltip-content-label">
                            2.2 หากลืมทานยา<b>เกิน 6 ชม.</b>{" "}
                            ข้ามไปทานมื้อต่อไปในขนาดเดิมเวลาเดิม โดยไม่ต้องเพิ่มขนาดยาเป็น 2
                            เท่า
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent4 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            4. สามารถระบุว่า{" "}
                            <b>
                                ห้ามเพิ่มหรือลดขนาดยาเองโดยเด็ดขาด
                                และทราบเหตุผลที่ต้องรับประทานยาตรงเวลาอย่างเคร่งครัด
                            </b>{" "}
                            เนื่องจากยามี half-life สั้น{" "}
                            <b>
                                เพื่อลดความเสี่ยงการเกิดลิ่มเลือดอุดตัน อาการเลือดออกผิดปรกติ
                            </b>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent5 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            5. สามารถ<b>ยกตัวอย่างอาการแสดงภาวะเลือดออกผิดปกติ</b> เช่น
                            เลือดออกตามไรฟัน รอยจ้ำเขียวตามตัว เลือดกำเดาไหล เลือดออกในตาขาว
                            อุจจาระหรือปัสสาวะเป็นเลือด อุจจาระเป็นสีดำ ไอเป็นเลือด
                            อาเจียนเป็นเลือด หรือ ประจำเดือนออกมากผิดปกติ เป็นต้น
                            <br />
                            <b>หากมีอาการดังกล่าว ให้ติดต่อพบแพทย์ก่อนนัด</b>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent6 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            6. สามารถ<b>ยกตัวอย่างอาการภาวะลิ่มเลือดอุดตัน</b> เช่น
                            แขนขาอ่อนแรงครึ่งซีก หน้าเบี้ยว ปากเบี้ยว พูดไม่ออก พูดไม่ชัด
                            <br />
                            <b>
                                หากมีอาการดังกล่าว
                                ให้ติดต่อพบแพทย์ก่อนนัดหรือติดต่อพบแพทย์ใกล้บ้านทันที
                            </b>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent7 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            7. สามารถระบุ{" "}
                            <b>
                                ผู้ป่วยต้องแจ้งให้บุคลากรทางการแพทย์ทราบว่าใช้ยา NOAC
                                โดยเฉพาะเมื่อพบทันตแพทย์หรือแพทย์อื่น
                            </b>
                            ที่ให้การรักษาโรคร่วม (ทั้งในโรงพยาบาล, ร้านขายยา, คลินิก)
                            และทราบว่าให้<b>หลีกเลี่ยงการฉีดยาเข้ากล้ามเนื้อ</b>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent8 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            8. สามารถระบุ{" "}
                            <b>
                                ต้องปรึกษาเภสัชกรหรือแพทย์ทุกครั้งก่อนเริ่มใช้ยาอื่น
                                อาหารเสริมหรือสมุนไพรทุกชนิด หลีกเลี่ยงการดื่มแอลกอฮอล์
                                และงดสูบบุหรี่
                            </b>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent9 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            9. สามารถระบุ
                            <b>
                                ความสำคัญการเลี่ยงกิจกรรมเสี่ยงต่อการกระแทกหรือการบาดเจ็บ
                                เนื่องจากอาจทำให้เลือดออกผิดปรกติ
                            </b>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};

const TooltipContent10 = (props) => {
    return (
        <>
            <tr>
                <td>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="tooltip-content-label">
                            10. สามารถ<b>ระบุวิธีเก็บยาที่เหมาะสม</b> ได้แก่
                            <b>เก็บแผงยาให้พ้นแสง ไม่แกะยาไว้ล่วงหน้า</b>
                            สามารถระบุ<b>วิธีเก็บยาที่เหมาะสมให้พ้นความชื้น</b>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
};
