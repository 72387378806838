import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementImageView from "../Form/FormElementImageView";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";
const tmseImg = require("../../../images/TMSE.png");

import "../../../css/dietz.css";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

var utilLoadXHR = require("../FormType/FormUtil.js").loadXHR;
var utilGetBase64 = require("../FormType/FormUtil.js").getBase64;

const FormTMSEPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [image1, setImage1] = useState("");
  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_tmse",
      formType: "5",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getScore1 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1,
      dataForm.data_2,
      dataForm.data_3,
      dataForm.data_4,
      dataForm.data_5,
      dataForm.data_6,
    ]);
  }, [dataForm]);

  const getScore2 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_7,
      dataForm.data_8,
      dataForm.data_9,
    ]);
  }, [dataForm]);

  const getScore3 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_10,
      dataForm.data_11,
      dataForm.data_12,
      dataForm.data_13,
      dataForm.data_14,
    ]);
  }, [dataForm]);

  const getScore4 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_15,
      dataForm.data_16,
      dataForm.data_17,
    ]);
  }, [dataForm]);

  const getScore5_1 = useMemo(() => {
    return utilSumTotalScore([dataForm.data_18, dataForm.data_19]);
  }, [dataForm]);

  const getScore5 = useMemo(() => {
    return utilSumTotalScore([
      getScore5_1,
      dataForm.data_20n,
      dataForm.data_21n,
      dataForm.data_22n,
      dataForm.data_23n,
      dataForm.data_24n,
    ]);
  }, [dataForm]);

  const getScore6 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_25,
      dataForm.data_26,
      dataForm.data_27,
    ]);
  }, [dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      getScore1,
      getScore2,
      getScore3,
      getScore4,
      getScore5,
      getScore6,
    ]);
  }, [dataForm]);

  useEffect(() => {
    utilLoadXHR(tmseImg).then(function (blob) {
      utilGetBase64(blob, (result) => {
        setImage1(result);
      });
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="TMSE" mode="view" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={memberProfile} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <FormElementTitle
              label="การคัดกรองสภาพสมอง โดย Thai Mental State Examination"
              mode="view"
            />
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <FormElementTitle label="1. Orientation" mode="view" />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel label={`${getScore1}/6 คะแนน`} mode="view" />
              </div>
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_1 == "1" && "/"}
              </span>
              <FormElementLabel
                label="วัน วันอะไรของสัปดาห์ (จันทร์ อังคาร พุธ พฤหัส ศุกร์ เสาร์ อาทิตย์)"
                mode="view"
              />
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_2 == "1" && "/"}
              </span>
              <FormElementLabel label="วันที่ วันที่เท่าไหร่" mode="view" />
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_3 == "1" && "/"}
              </span>
              <FormElementLabel label="เดือน เดือนอะไร" mode="view" />
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_4 == "1" && "/"}
              </span>
              <FormElementLabel
                label="ขณะนี้เป็นช่วง (ตอน) ไหนของวัน (เช้า เที่ยง บ่าย เย็น)"
                mode="view"
              />
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_5 == "1" && "/"}
              </span>
              <FormElementLabel
                label="ที่นี้ที่ไหน(บริเวณที่ตรวจ)"
                mode="view"
              />
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_6 == "1" && "/"}
              </span>
              <FormElementLabel
                label="คนทเี่ห็นในภาพนี้มีอาชีพอะไร"
                mode="view"
              />
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <FormElementTitle label="2. Registration" mode="view" />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel label={`${getScore2}/3 คะแนน`} mode="view" />
              </div>
            </div>
            <span style={{ paddingLeft: "24px" }}>
              <FormElementLabel
                label="ผู้ทดสอบ บอกชื่อของ 3 อย่าง โดยพูดห่างกัน คร้ังละ 1 วินาที เพียงครั้งเดียว แล้วจึงให้ผู้ทดสอบบอกให้ครบ ตามที่ผู้ทดสอบบอกในครั้งแรก ให้ 1 คะแนนในแต่ละคำตอบที่ตอบถูก
(หมายเหตุ หลังจากให้คะแนนแล้วให้บอกซ้ำจนผู้ถูกทดสอบจำได้ทั้ง 3 อย่าง และบอกให้ผู้ถูกทดสอบทราบว่าสักครู่จะกลับมาถามใหม่)"
                mode="view"
              />
            </span>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_7 == "1" && "/"}
              </span>
              <FormElementLabel label="ต้นไม้" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_8 == "1" && "/"}
              </span>
              <FormElementLabel label="รถยนต์" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_9 == "1" && "/"}
              </span>
              <FormElementLabel label="มือ" mode="view" />
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <FormElementTitle label="3. Attention" mode="view" />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel label={`${getScore3}/5 คะแนน`} mode="view" />
              </div>
            </div>
            <span style={{ paddingLeft: "24px" }}>
              <FormElementLabel
                label="ให้บอกวันอาทิตย์-วันเสาร์ย้อนหลังให้ครบสัปดาห์ (ให้ตอบซ้าได้ 1 ครั้ง)"
                mode="view"
              />
            </span>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <FormElementLabel label="วันอาทิตย์..." mode="view" />
              <span style={{ width: "24px" }}>
                {dataForm.data_10 == "1" && "/"}
              </span>
              <FormElementLabel label="เสาร์" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_11 == "1" && "/"}
              </span>
              <FormElementLabel label="ศุกร์" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_12 == "1" && "/"}
              </span>
              <FormElementLabel label="พฤหัสบดี" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_13 == "1" && "/"}
              </span>
              <FormElementLabel label="พุธ" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_14 == "1" && "/"}
              </span>
              <FormElementLabel label="อังคาร" mode="view" />
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <FormElementTitle label="4. Calculation" mode="view" />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel label={`${getScore4}/3 คะแนน`} mode="view" />
              </div>
            </div>
            <span style={{ paddingLeft: "24px" }}>
              <FormElementLabel
                label="ให้คำนวณ 100-7 ไปเรื่อยๆ 3 คร้ัง (ให้ 1 คะแนน ในแต่ละครั้งที่ตอบถูก ใช้เวลาคิด ในแต่ละช่วงคำตอบไม่เกิน 1 นาที หลังจากจบคำถาม) ถ้าผู้ถูก ทดสอบไม่ตอบคำถามที่ 1 ให้ตั้งเลข
                93-7 ลองทาในการคำนวณครั้งต่อไป และ 86-7 ในคร้ังสุดท้ายตามลำดับ"
                mode="view"
              />
            </span>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_15 == "1" && "/"}
              </span>
              <FormElementLabel label="100 - 7" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_16 == "1" && "/"}
              </span>
              <FormElementLabel label="93 - 7" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_17 == "1" && "/"}
              </span>
              <FormElementLabel label="86 - 7" mode="view" />
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <FormElementTitle label="5. Language" mode="view" />
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "24px",
                }}
              >
                <FormElementLabel
                  label="- ผู้ทดสอบชี้ไปที่สิ่งของที่กำหนดแล้วถามผู้ถูกทดสอบว่าโดยทั่วไป “เราเรียกสิ่งนี้ว่าอะไร”"
                  mode="view"
                />
                <div style={{ display: "inline-flex" }}>
                  <span style={{ width: "24px", marginLeft: "16px" }}>
                    {dataForm.data_18 == "1" && "/"}
                  </span>
                  <FormElementLabel label="ปากกา" mode="view" />
                  <span style={{ width: "24px", marginLeft: "16px" }}>
                    {dataForm.data_19 == "1" && "/"}
                  </span>
                  <FormElementLabel label="กระดาษ" mode="view" />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel
                  label={`${getScore5_1}/2 คะแนน`}
                  mode="view"
                />
              </div>
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "24px",
                }}
              >
                <FormElementLabel
                  label="- ผู้ทดสอบบอกผู้ถูก ทดสอบว่า จงฟังประโยคต่อไปนี้ให้ดี แล้วจำไว้ จากนั้นให้พูดตาม “ยายพาหลานไปซื้อขนมที่ตลาด”"
                  mode="view"
                />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel
                  label={`${dataForm.data_20n || "0"}/1 คะแนน`}
                  mode="view"
                />
              </div>
            </div>
            <div style={{ display: "inline-flex", width: "100%", gap: "10px" }}>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "24px",
                }}
              >
                <FormElementLabel
                  label="- จงทำตามคำสั่งต่อไปนี้ (มี 3 ขั้นตอนคำสั่ง) ให้ผู้ทดสอบพูดต่อกัน ไปให้ครบประโยคทั้ง 3 ขั้นตอน ให้คะแนนขั้นตอนละ 1 คะแนน (หยิบกระดาษมือขวา-พับกระดาษเป็นครึ่งแผ่น-แล้วส่งกระดาษให้ผู้ตรวจ)"
                  mode="view"
                />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel
                  label={`${dataForm.data_21n || "0"}/3 คะแนน`}
                  mode="view"
                />
              </div>
            </div>
            <div style={{ display: "inline-flex", width: "100%", gap: "10px" }}>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "24px",
                }}
              >
                <FormElementLabel
                  label="- ให้ผู้ถูกทดสอบอ่านแล้วทำตาม(หลับตา)"
                  mode="view"
                />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel
                  label={`${dataForm.data_22n || "0"}/1 คะแนน`}
                  mode="view"
                />
              </div>
            </div>
            <div style={{ display: "inline-flex", width: "100%", gap: "10px" }}>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "24px",
                }}
              >
                <FormElementLabel
                  label="- จงวาดภาพต่อไปนี้ให้เหมือนตัวอย่างมากที่สุด เท่าที่ท่านจะสามารถทำได้"
                  mode="view"
                />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel
                  label={`${dataForm.data_23n || "0"}/2 คะแนน`}
                  mode="view"
                />
              </div>
            </div>
            <div style={{ display: "inline-flex", width: "100%", gap: "10px" }}>
              <div
                style={{
                  width: "100%",
                  paddingLeft: "24px",
                }}
              >
                <FormElementImageView 
                  value={dataForm.data_29} 
                  width="333"
                  height="500" />
              </div>
            </div>
            <div style={{ display: "inline-flex", width: "100%", gap: "10px" }}>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "24px",
                }}
              >
                <FormElementLabel
                  label="- กล้วยกับส้มเหมือนกันคือผลไม้แมวกับสุนัขเหมือนกันคือ(เป็นสัตว์,เป็นสิ่งมีชีวิต)"
                  mode="view"
                />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel
                  label={`${dataForm.data_24n || "0"}/1 คะแนน`}
                  mode="view"
                />
              </div>
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <FormElementTitle label="6. Recall" mode="view" />
              </div>
              <div style={{ width: "50%" }}>
                <FormElementLabel label={`${getScore6}/3 คะแนน`} mode="view" />
              </div>
            </div>
            <span style={{ paddingLeft: "24px" }}>
              <FormElementLabel
                label="สิ่งของ 3 อย่างที่ให้จำเมื่อสักครู่มีอะไรบ้าง"
                mode="view"
              />
            </span>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "32px",
              }}
            >
              <span style={{ width: "24px" }}>
                {dataForm.data_25 == "1" && "/"}
              </span>
              <FormElementLabel label="ต้นไม้" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_26 == "1" && "/"}
              </span>
              <FormElementLabel label="รถยนต์" mode="view" />
              <span style={{ width: "24px", marginLeft: "16px" }}>
                {dataForm.data_27 == "1" && "/"}
              </span>
              <FormElementLabel label="มือ" mode="view" />
            </div>
            <img src={image1} width={800} height={400} id="image_paper" />
            <FormElementTitle
              label={`(ผลรวมคะแนน: ${getTotalScore}) ปกติ มากกว่า 23 คะแนน`}
              mode="view"
            />
          </div>
        </div>
        {dataForm.data_28?.map((item) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{item.name}</span>
            <img src={item.base64} width={400} />
          </div>
        ))}
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormTMSEPaper;
