import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementImageView from "../Form/FormElementImageView";

import "../../../css/dietz.css";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

const FormConsultationPaper = (props) => {
  const { dataForm, patient, profile, nxOpdDropDownList, familyItemsDropDownList } = props;
  const [data2DropDown, setData2DropDown] = useState({});
  const [data10DropDown, setData10DropDown] = useState({});

  const lblMedicalEquipment = {
    1: "ไม่มี",
    2: "มี",
  };
  const lbl18 = {
    0: "",
    1: "NG tube,",
  };
  const lbl19 = {
    0: "",
    1: "Tracheostomy tube,",
  };
  const lbl20 = {
    0: "",
    1: "Foley's cath,",
  };
  const lbl21 = {
    0: "",
    1: "O2Canula,",
  };
  const lbl22 = {
    0: "",
    1: "อื่น ๆ ",
  };
  const lblVisitHome = {
    1: "เยี่ยมบ้าน onsite",
    2: "เยี่ยมแบบ Virtual Home Visit",
    3: "ยังไม่เยี่ยม",
  };

  const lbl28 = {
    1: "พักเขตอำเภอเมืองหรือระยะเวลาเดินทางไม่เกิน 15 นาที",
    0: "",
  };

  const lbl29 = {
    1: "ผู้ป่วย/ครอบครัวยินยอมให้เยี่ยมบ้าน",
    0: "",
  };

  useEffect(() => {
    let result = {};
    nxOpdDropDownList?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setData2DropDown(result)
  }, [nxOpdDropDownList, setData2DropDown]);

  useEffect(() => {
    let result = {};
    familyItemsDropDownList?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setData10DropDown(result)
  }, [familyItemsDropDownList, setData10DropDown]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="Family Medicine, CMU" mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "4px",
            padding: "10px 0px 0px 0px",
          }}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            <FormElementLabel label="ห้อง: " />
          </div>
          <div style={{ whiteSpace: "nowrap" }}>
            <FormElementTextBox
              value={dataForm.data_32}
              mode="view"
              width="100"
            />
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "4px",
            padding: "10px 0px 0px 0px",
          }}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            <FormElementLabel label="วันที่-เวลา: " />
          </div>
          <div style={{ whiteSpace: "nowrap" }}>
            <FormElementTextBox
              value={dataForm.data_1}
              type="date"
              format="DD/MM/YYYY"
              mode="view"
              width="100"
            />
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "4px",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel label="หน่วยงานที่ส่งปรึกษา: " />
          <FormElementLabel
            value={dataForm.data_2}
            mode="view"
            translate={data2DropDown}
          />
        </div>
        <div style={{ flexBasis: "100%", height: "0" }}></div>
        <FormElementLabel label="1. ข้อมูลผู้ป่วย" mode="view" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormElementLabel
              label="สิทธิ์การรักษา: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_3} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="ที่อยู่เลขที่: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_4} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="ญาติผู้ป่วย: "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_9} mode="view" />
            <FormElementLabel
              label="ความสัมพันธ์: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementLabel
              value={dataForm.data_10}
              mode="view"
              translate={data10DropDown}
            />
            <span hidden={dataForm.data_10 != "99"}>
              <FormElementLabel label={dataForm.data_31} mode="view" />
            </span>
            <FormElementLabel
              label="เบอร์ติดต่อ: "
              padding="0px 10px 0px 40px"
              mode="view"
            />
            <FormElementTextBox value={dataForm.data_11} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="Family tree and family profile (หากมีข้อมูล): "
              padding="0px 10px 0px 0px"
              mode="view"
            />
            <div style={{ paddingLeft: "40px", paddingTop: "10px" }}>
              <FormElementImageView
                value={dataForm.data_12}
                width="333"
                height="500"
              />
            </div>
          </div>
        </div>

        <FormElementLabel
          label="2. Biological problem and current management (+medications)"
          mode="view"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "40px",
            paddingTop: "10px",
          }}
        >
          <FormElementTextArea value={dataForm.data_13} mode="view" />
        </div>

        <FormElementLabel
          label="3. Psychosocial & spiritual problem and current management"
          mode="view"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "40px",
            paddingTop: "10px",
          }}
        >
          <FormElementTextArea value={dataForm.data_14} mode="view" />
        </div>

        <FormElementLabel label="4. อุปกรณ์การแพทย์ที่ต้องใช้" mode="view" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "0px",
            paddingTop: "10px",
          }}
        >
          {dataForm.data_15 == "1" ? (
            <>
              <FormElementLabel
                value={dataForm.data_15}
                translate={lblMedicalEquipment}
                padding="0px 10px 0px 40px"
                mode="view"
              />
            </>
          ) : dataForm.data_15 == "2" ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormElementLabel
                value={dataForm.data_15}
                translate={lblMedicalEquipment}
                padding="0px 10px 0px 40px"
                mode="view"
              />
              {dataForm.data_16 == "1" && (
                <FormElementLabel
                  value={dataForm.data_16}
                  translate={lbl18}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
              {dataForm.data_17 == "1" && (
                <FormElementLabel
                  value={dataForm.data_17}
                  translate={lbl19}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
              {dataForm.data_18 == "1" && (
                <FormElementLabel
                  value={dataForm.data_18}
                  translate={lbl20}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
              {dataForm.data_19 == "1" && (
                <FormElementLabel
                  value={dataForm.data_19}
                  translate={lbl21}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
              {dataForm.data_20 == "1" && (
                <FormElementLabel
                  value={dataForm.data_20}
                  translate={lbl22}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
              {dataForm.data_21 && (
                <FormElementTextBox
                  value={": " + dataForm.data_21}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <FormElementLabel label="5. Other suggestions" mode="view" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "40px",
            paddingTop: "10px",
          }}
        >
          <FormElementTextArea value={dataForm.data_22} mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              label="ผู้ส่งปรึกษา"
              mode="view"
              padding="0px 10px 0px 0px"
            />
            <FormElementTextArea value={dataForm.data_23} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <FormElementLabel
              padding="0px 10px 0px 40px"
              label="เบอร์ติดต่อ"
              mode="view"
            />
            <FormElementTextArea value={dataForm.data_24} mode="view" />
          </div>
        </div>
        <FormElementLabel
          label="การวางแผนการดูแลโดยแพทย์เวชศาสตร์ครอบครัว"
          mode="view"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "40px",
            paddingTop: "10px",
          }}
        >
          {dataForm.data_25 == "1" ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormElementLabel
                value={dataForm.data_25}
                translate={lblVisitHome}
                padding="0px 10px 0px 0px"
                mode="view"
              />
              {dataForm.data_26 == "1" && (
                <FormElementLabel
                  value={dataForm.data_26}
                  translate={lbl28}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
              {dataForm.data_27 == "1" && (
                <FormElementLabel
                  value={dataForm.data_27}
                  translate={lbl29}
                  padding="0px 10px 0px 10px"
                  mode="view"
                />
              )}
            </div>
          ) : dataForm.data_25 == "2" ? (
            <>
              <FormElementLabel
                value={dataForm.data_25}
                translate={lblVisitHome}
                padding="0px 10px 0px 0px"
                mode="view"
              />
            </>
          ) : (
            dataForm.data_25 == "3" && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormElementLabel
                  value={dataForm.data_25}
                  translate={lblVisitHome}
                  padding="0px 10px 0px 0px"
                  mode="view"
                />
                {dataForm.data_28 && (
                  <FormElementTextBox
                    value={"เนื่องจาก: " + dataForm.data_28}
                    padding="0px 10px 0px 40px"
                    mode="view"
                  />
                )}
              </div>
            )
          )}
        </div>
        <FormElementLabel label="Note" mode="view" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "40px",
            paddingTop: "10px",
          }}
        >
          <FormElementTextArea value={dataForm.data_29} mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "0px",
            paddingTop: "10px",
          }}
        >
          <FormElementLabel label="ผู้รับปรึกษา " mode="view" />
          <span style={{ paddingLeft: "10px" }}></span>
          <FormElementTextBox value={dataForm.data_30} mode="view" />
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormConsultationPaper;
