import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormDrugAdherence = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    var datas = { ...dataForm };

    datas = { ...datas, ["data_" + field]: valueData };

    setDataForm(datas);
  }

  function setDataObj(data) {
    var datas = { ...dataForm };

    datas = { ...datas, ...data };

    setDataForm(datas);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_drugadherence",
      formType: "37",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
    ]);
  }, [dataForm]);

  const getScoreDesc = useMemo(() => {
    if (getTotalScore >= 4) {
      return "สม่ำเสมอดี (4 คะแนน)";
    } else if (getTotalScore >= 2 && getTotalScore <= 3) {
      return "ปานกลาง (2-3 คะแนน)";
    } else {
      return "ไม่สม่ำเสมอ (0-1 คะแนน)";
    }
  }, [getTotalScore]);

  useEffect(() => {
    props.setScore(getTotalScore);
  }, [dataForm]);

  useEffect(() => {
    if (getTotalScore >= 4) {
      setData("6", "1");
    } else if (getTotalScore >= 2 && getTotalScore <= 3) {
      setData("6", "2");
    } else {
      setData("6", "3");
    }
  }, [dataForm.data_1n, dataForm.data_2n, dataForm.data_3n, dataForm.data_4n]);

  return (
    <div uk-grid="" className="uk-grid uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Drug adherence " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="4-item Morisky Green Levine Medication Adherence Scale" />
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th>คำถาม</th>
              <th>ใช่ (0 คะแนน)</th>
              <th>ไม่ใช่ (1 คะแนน)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>คุณเคยลืมใช้ยาควบคุมโรคประจำตัว</td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>คุณเคยรู้สึกไม่ใส่ใจกับการใช้ยาควบคุมโรคประจำตัว</td>
              <td>
                <FormElementRadio
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>เมื่อรู้สึกว่าโรคประจำตัวควบคุมได้ดี บางครั้งคุณหยุดใช้ยา</td>
              <td>
                <FormElementRadio
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                เมื่อรู้สึกไม่ดีเกี่ยวกับการทานยาควบคุมโรคประจำตัว
                คุณหยุดใช้ยาหรือไม่
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>คะแนนรวมทั้งหมด</td>
              <td colSpan={2}>{getTotalScore}/4</td>
            </tr>
          </tbody>
        </table>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span className="formTextBoxLabel">
            <strong>การแปลผล</strong>
          </span>
          {getScoreDesc}
        </div>
      </div>
    </div>
  );
};

export default FormDrugAdherence;
