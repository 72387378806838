import Api from "../../Components/Api";

async function fetchMemberListHis(hn, txn) {
    try {
        var result = await Api.consult("MemberListHIS", {
            hn: hn,
            txn: txn,
        });
        var { list } = result;

        if (!list) throw new Error(`member data not found`);

        return list[0];

    } catch (error) {
        console.error(`fetchMemberListHis: hn(${hn}) txn(${txn})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchMemberListHis;