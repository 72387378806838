import React, { useEffect } from 'react';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "../../../css/dietz.css";

UIkit.use(Icons);

const FormSuccessPopup = (props) => {
    const { onClose } = props;

    useEffect(() => {
        window.scroll(0, 0);
        document.body.style.overflow = "hidden";
    }, [document.body.style.overflow]);

    useEffect(() => {
        return () => { document.body.style.overflow = "auto"; }
    }, []);

    return (
        <>
            <div className="dietz-loading-background">
                <div className="dietz-success-popup-container" >
                    <div className='dietz-popup-header'>
                        <span className='dietz-save-successful-icon' uk-icon="icon: check; ratio: 1.2"></span>
                        <span className='dietz-popup-title'>บันทึกข้อมูลสำเร็จ</span>
                    </div>
                    <div className='dietz-popup-footer'>
                        <button className="uk-button uk-button-primary"
                            type="button" onClick={onClose}>
                            ไปที่หน้ารายการหลัก
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormSuccessPopup;
