import Api from "../../Components/Api";

async function saveConsultForm(
  saveData,
  loginToken,
  formId,
  consultId,
  doctorId,
  paperHtml,
  patient
) {
  try {
    const visit_datetime = `${patient?.reg_date} ${patient?.reg_time}:00`;

    var result = await Api.consult("ConsultFormSave", {
      ...saveData,
      loginToken: loginToken,
      formId: formId,
      consultId: consultId,
      doctorId: doctorId,
      html: paperHtml,
      memberId: 0,
      room_code: patient.room_code,
      room_name: patient.room,
      visit_datetime,
      //debug:1,
    });

    if (!result?.result) {
      throw new Error(result?.responseText || "ไม่สามารถทำรายการได้");
    }
  } catch (error) {
    console.error(`saveConsultForm`, error);
    throw new Error(error.toString());
  }
}
export default saveConsultForm;
