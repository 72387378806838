import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import Api from "../../Api";
import FormElementDatePicker from "../Form/FormElementDatePicker";
import GRACEScoreSection from "../Utils/GRACEScoreSection";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchPatientVisit from "../../API/fetchPatientVisit.js";
import fetchFamilyItem from "../../API/fetchFamilyItem.js";
import fetchOccupationItem from "../../API/fetchOccupationItem.js";
import fetchEducationItem from "../../API/fetchEducationItem.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormACSCaringSetPaper from "../FormPaper/FormACSCaringSetPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const HOSPITALS = [
  { value: "", label: "เลือกโรงพยาบาล" },
  { value: "06009", label: "โรงพยาบาลแม่ตื่น จ.เชียงใหม่" },
  { value: "10713", label: "โรงพยาบาลนครพิงค์ จ.เชียงใหม่" },
  { value: "10714", label: "โรงพยาบาลลำพูน จ.ลำพูน" },
  { value: "10719", label: "โรงพยาบาลศรีสังวาลย์ จ.แม่ฮ่องสอน" },
  { value: "11119", label: "โรงพยาบาลจอมทอง จ.เชียงใหม่" },
  {
    value: "11120",
    label: "โรงพยาบาลเทพรัตนเวชชานุกูล เฉลิมพระเกียรติ ๖๐ พรรษา จ.เชียงใหม่",
  },
  { value: "11121", label: "โรงพยาบาลเชียงดาว จ.เชียงใหม่" },
  { value: "11122", label: "โรงพยาบาลดอยสะเก็ด จ.เชียงใหม่" },
  { value: "11123", label: "โรงพยาบาลแม่แตง จ.เชียงใหม่" },
  { value: "11124", label: "โรงพยาบาลสะเมิง จ.เชียงใหม่" },
  { value: "11125", label: "โรงพยาบาลฝาง จ.เชียงใหม่" },
  { value: "11126", label: "โรงพยาบาลแม่อาย จ.เชียงใหม่" },
  { value: "11127", label: "โรงพยาบาลพร้าว จ.เชียงใหม่" },
  { value: "11128", label: "โรงพยาบาลสันป่าตอง จ.เชียงใหม่" },
  { value: "11129", label: "โรงพยาบาลสันกำแพง จ.เชียงใหม่" },
  { value: "11130", label: "โรงพยาบาลสันทราย จ.เชียงใหม่" },
  { value: "11131", label: "โรงพยาบาลหางดง จ.เชียงใหม่" },
  { value: "11132", label: "โรงพยาบาลฮอด จ.เชียงใหม่" },
  { value: "11133", label: "โรงพยาบาลดอยเต่า จ.เชียงใหม่" },
  { value: "11134", label: "โรงพยาบาลอมก๋อย จ.เชียงใหม่" },
  { value: "11135", label: "โรงพยาบาลสารภี จ.เชียงใหม่" },
  { value: "11136", label: "โรงพยาบาลเวียงแหง จ.เชียงใหม่" },
  { value: "11137", label: "โรงพยาบาลไชยปราการ จ.เชียงใหม่" },
  { value: "11138", label: "โรงพยาบาลแม่วาง จ.เชียงใหม่" },
  { value: "11139", label: "โรงพยาบาลแม่ออน จ.เชียงใหม่" },
  { value: "11140", label: "โรงพยาบาลแม่ทา จ.ลำพูน" },
  { value: "11141", label: "โรงพยาบาลบ้านโฮ่ง จ.ลำพูน" },
  { value: "11142", label: "โรงพยาบาลลี้ จ.ลำพูน" },
  { value: "11143", label: "โรงพยาบาลทุ่งหัวช้าง จ.ลำพูน" },
  { value: "11144", label: "โรงพยาบาลป่าซาง จ.ลำพูน" },
  { value: "11145", label: "โรงพยาบาลบ้านธิ จ.ลำพูน" },
  { value: "11507", label: "โรงพยาบาลกองบิน 41 จ.เชียงใหม่" },
  { value: "11508", label: "โรงพยาบาลค่ายกาวิละ เชียงใหม่ จ.เชียงใหม่" },
  { value: "11643", label: "โรงพยาบาลดอยหล่อ จ.เชียงใหม่" },
  { value: "11978", label: "โรงพยาบาลดารารัศมี จ.เชียงใหม่" },
  {
    value: "11992",
    label: "โรงพยาบาลเทพปัญญา 2 โรงพยาบาลทั่วไปขนาดกลาง จ.เชียงใหม่",
  },
  {
    value: "11995",
    label: "โรงพยาบาลทั่วไปขนาดใหญ่เซ็นทรัลเชียงใหม่ เมโมเรียล จ.เชียงใหม่",
  },
  {
    value: "11999",
    label: "โรงพยาบาลทั่วไปขนาดใหญ่เชียงใหม่เมดิคอลเซ็นเตอร์ จ.เชียงใหม่",
  },
  {
    value: "12279",
    label: "โรงพยาบาลส่งเสริมสุขภาพ ศูนย์อนามัยที่ 1 จ.เชียงใหม่",
  },
  { value: "12280", label: "โรงพยาบาลสวนปรุง จ.เชียงใหม่" },
  { value: "12281", label: "โรงพยาบาลประสาทเชียงใหม่ จ.เชียงใหม่" },
  { value: "12283", label: "โรงพยาบาลมะเร็งลำปาง จ.ลำปาง" },
  { value: "13775", label: "สถาบันพัฒนาการเด็กราชนครินทร์ จ.เชียงใหม่" },
  {
    value: "13780",
    label: "โรงพยาบาลมหาราชนครเชียงใหม่ มหาวิทยาลัยเชียงใหม่ จ.เชียงใหม่",
  },
  { value: "13785", label: "โรงพยาบาลธัญญารักษ์เชียงใหม่ จ.เชียงใหม่" },
  { value: "14197", label: "โรงพยาบาลธัญญรักษ์แม่ฮ่องสอน จ.แม่ฮ่องสอน" },
  {
    value: "14550",
    label: "โรงพยาบาลทั่วไปขนาดใหญ่ เชียงใหม่ใกล้หมอ จ.เชียงใหม่",
  },
  { value: "14555", label: "โรงพยาบาลทั่วไปขนาดกลางศิริเวช ลำพูน จ.ลำพูน" },
  {
    value: "14916",
    label: "โรงพยาบาลทั่วไปขนาดกลางเชียงใหม่ฮอสพิทอล จ.เชียงใหม่",
  },
  {
    value: "23736",
    label: "โรงพยาบาลวัดจันทร์ เฉลิมพระเกียรติ 80 พรรษา จ.เชียงใหม่",
  },
  {
    value: "99999",
    label: "อื่นๆ",
  },
];

const FormACSCaringSet = (props) => {
  const formConstants = {
    formName: "form_acscaringset",
    formType: "7",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [relationship, setRelationship] = useState([]);
  var [education, setEducation] = useState([]);
  var [occupation, setOccupation] = useState([]);
  var [patientVisit, setPatientVisit] = useState({});
  var [hospitals, setHospitals] = useState({});

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback((message) => {
    onErrorMessageChange({ open: true, header: 'ไม่สามารถบันทึกได้', description: message, isClose: true });
  }, [onErrorMessageChange]);

  const calculateTimeBetweenDates = useCallback(
    (startDateString, endDateString) => {
      if (!startDateString || !endDateString) {
        return "";
      }
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      return (endDate.getTime() - startDate.getTime()) / (1000 * 60) || "0";
    }
  );

  const prepareData = useCallback((form, patientVisit) => {
    if (!form?.data_34 && patientVisit?.tel) {
      form.data_34 = patientVisit?.tel;
    }

    if (!form?.data_32 && patientVisit?.emergency_tel) {
      form.data_32 = patientVisit?.emergency_tel;
    }
    return form;
  }, []);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientDataRequest = fetchMemberByTxn(hn, txn);
    const patientVisitDataRequest = fetchPatientVisit(hn, txn);
    const familyItemDataRequest = fetchFamilyItem();
    const occupationDataRequest = fetchOccupationItem();
    const educationDataRequest = fetchEducationItem();
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all(
      [patientDataRequest, patientVisitDataRequest, familyItemDataRequest,
        occupationDataRequest, educationDataRequest, patientRoomRequest]
    ).then((values) => {
      const patientResponse = values[0];
      const patientVisitDataResponse = values[1];
      const familyItemDataResposne = values[2];
      const occupationDataResponse = values[3];
      const educationDataResponse = values[4];
      const patientRoomResponse = values[5];

      data = prepareData({ ...data }, patientVisitDataResponse);
      setPatientVisit(patientVisitDataResponse);
      setRelationship(familyItemDataResposne);
      setOccupation(occupationDataResponse);
      setEducation(educationDataResponse);
      setPatient({ ...patientResponse, ...patientRoomResponse, hn, txn });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null)
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    if (dataForm.data_1) {
      if (dataForm.data_1 == "1" && !dataForm.data_2) {
        displayErrorValidatePopup("จำเป็นต้องระบุ New case & arrival by");
        return false;
      }
      if (dataForm.data_1 == "2" && !dataForm.data_3) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Refer form");
        return false;
      }
    } else {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ Timeline Record From For ACS โรงพยาบาลมหาราชนครเชียงใหม่"
      );
      return false;
    }

    if (!dataForm.data_4) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่-เวลา ของ 1. Onset of symptom");
      return false;
    }

    if (!dataForm.data_5) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 2. Arrive to ER 1st รพช"
      );
      return false;
    }

    if (!dataForm.data_7) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 3. First 12 leads ECG at รพช"
      );
      return false;
    }

    if (!dataForm.data_9) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 4. Depart from ER 1st hospital"
      );
      return false;
    }

    if (!dataForm.data_11) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 5. Arrive to ER Suandok"
      );
      return false;
    }

    if (!dataForm.data_13) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 6. First 12 leads ECG at Suandok"
      );
      return false;
    }

    if (!dataForm.data_15) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 7. ER Physician visit"
      );
      return false;
    }

    if (!dataForm.data_17) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 8. Final diagnosis at ER"
      );
      return false;
    }

    if (!dataForm.data_19) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 9. Consult cardiologist"
      );
      return false;
    }

    if (!dataForm.data_20) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 10. Cardiologist visit or call back"
      );
      return false;
    }

    if (!dataForm.data_22) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 11. Depart from ER Suandok"
      );
      return false;
    }

    if (!dataForm.data_23) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 12. Activated for PPCI"
      );
      return false;
    }

    if (!dataForm.data_24) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 13. Arrive to CCU/ward"
      );
      return false;
    }

    if (!dataForm.data_27) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 14. Arrive to Cath-Lab"
      );
      return false;
    }

    if (!dataForm.data_29) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 15. PPCI: Vascular access site"
      );
      return false;
    }

    if (!dataForm.data_31) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ วันที่-เวลา ของ 16. PPCI: Wire crossing"
      );
      return false;
    }

    if (!dataForm.data_32) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Emergency contact (Name)");
      return false;
    }

    if (!dataForm.data_33) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Relationship");
      return false;
    }

    if (!dataForm.data_34) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Phone");
      return false;
    }

    if (!dataForm.data_35) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Education");
      return false;
    }

    if (!dataForm.data_36) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Occupation");
      return false;
    }

    if (!dataForm.data_37) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Vital signs: Temp");
      return false;
    }

    if (!dataForm.data_38) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR");
      return false;
    }

    if (!dataForm.data_39) {
      displayErrorValidatePopup("จำเป็นต้องระบุ RR");
      return false;
    }

    if (!dataForm.data_40) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP");
      return false;
    }

    if (!dataForm.data_41) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ส่วนสูง");
      return false;
    }

    if (!dataForm.data_42) {
      displayErrorValidatePopup("จำเป็นต้องระบุ น้ำหนัก");
      return false;
    }

    if (!dataForm.data_43) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Smoking");
      return false;
    }

    if (!dataForm.data_44) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HT");
      return false;
    }

    if (!dataForm.data_45) {
      displayErrorValidatePopup("จำเป็นต้องระบุ DLP");
      return false;
    }

    if (!dataForm.data_46) {
      displayErrorValidatePopup("จำเป็นต้องระบุ DM");
      return false;
    } else {
      if (dataForm.data_46 == "2" && !dataForm.data_47) {
        displayErrorValidatePopup("จำเป็นต้องระบุ DM");
        return false;
      }
    }

    if (!dataForm.data_48) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Family Hx of CAD");
      return false;
    }

    if (!dataForm.data_49) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Prior MI");
      return false;
    }

    if (!dataForm.data_50) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Known CAD (stenosis > 50%)");
      return false;
    }

    if (!dataForm.data_51) {
      displayErrorValidatePopup("จำเป็นต้องระบุ CVD disease");
      return false;
    }

    if (!dataForm.data_52) {
      displayErrorValidatePopup("จำเป็นต้องระบุ PAD");
      return false;
    }

    if (!dataForm.data_53) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Prior PCI");
      return false;
    }

    if (!dataForm.data_53) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Prior PCI");
      return false;
    } else {
      if (dataForm.data_53 == "1" && !dataForm.data_54) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Prior PCI last year");
        return false;
      }
    }

    if (!dataForm.data_55) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Prior CABG");
      return false;
    } else {
      if (dataForm.data_55 == "1" && !dataForm.data_56) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Prior CABG last year");
        return false;
      }
    }

    if (!dataForm.data_57) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Prior HF");
      return false;
    }

    if (!dataForm.data_58) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Prior valve surgery");
      return false;
    }

    if (!dataForm.data_59) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Chronic lung");
      return false;
    }

    if (!dataForm.data_60) {
      displayErrorValidatePopup("จำเป็นต้องระบุ On dialysis");
      return false;
    }

    if (!dataForm.data_61) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 1. HF");
      return false;
    } else {
      if (dataForm.data_61 == "1" && !dataForm.data_62) {
        displayErrorValidatePopup("จำเป็นต้องระบุ HF class");
        return false;
      }
    }

    if (!dataForm.data_63) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 2. Arrhythmia");
      return false;
    } else {
      if (dataForm.data_63 == "1" && !dataForm.data_64) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Arrhythmia type");
        return false;
      }
    }

    if (!dataForm.data_65) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 3. CPR");
      return false;
    }

    if (!dataForm.data_66) {
      displayErrorValidatePopup("จำเป็นต้องระบุ 4. Death");
      return false;
    } else {
      if (dataForm.data_66 == "1" && !dataForm.data_67) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Death type");
        return false;
      }
    }

    if (!dataForm.data_68) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Diagnosis");
      return false;
    } else {
      if (
        dataForm.data_68 == "1" &&
        dataForm.data_137 != "1" &&
        dataForm.data_138 != "1" &&
        dataForm.data_139 != "1" &&
        dataForm.data_140 != "1" &&
        dataForm.data_141 != "1" &&
        dataForm.data_142 != "1"
      ) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Area of involvement");
        return false;
      }

      if (dataForm.data_68 == "1" && !dataForm.data_70) {
        displayErrorValidatePopup("จำเป็นต้องระบุ STEMI Plan");
        return false;
      }

      if (dataForm.data_68 == "2" && !dataForm.data_71) {
        displayErrorValidatePopup("จำเป็นต้องระบุ NSTEACS Plan");
        return false;
      }
    }

    if (!dataForm.data_72) {
      displayErrorValidatePopup("จำเป็นต้องระบุ GRACE Score");
      return false;
    }

    if (dataForm.data_68 == "1" && !dataForm.data_73) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ STEMI (ความเสี่ยงต่อ Cardiac arrest)"
      );
      return false;
    }

    if (dataForm.data_68 == "2" && !dataForm.data_74) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ NSTEMI (ความเสี่ยงต่อ Cardiac arrest)"
      );
      return false;
    }

    if (!dataForm.data_75) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Bleeding (ARC-HBR)");
      return false;
    }

    if (
      dataForm.data_76 != "1" &&
      dataForm.data_157 != "1" &&
      dataForm.data_158 != "1" &&
      dataForm.data_159 != "1" &&
      dataForm.data_160 != "1" &&
      dataForm.data_161 != "1"
    ) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Heart Failure");
      return false;
    }

    if (
      dataForm.data_77 != "1" &&
      dataForm.data_162 != "1" &&
      dataForm.data_163 != "1" &&
      dataForm.data_164 != "1"
    ) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Cardiac arrhythmia");
      return false;
    }

    if (
      dataForm.data_78 != "1" &&
      dataForm.data_165 != "1" &&
      dataForm.data_166 != "1" &&
      dataForm.data_167 != "1"
    ) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Stroke");
      return false;
    }

    if (!dataForm.data_79) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Diagnosis");
      return false;
    }

    if (!dataForm.data_80) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Type");
      return false;
    }

    if (dataForm.data_79 == "1" && !dataForm.data_81) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Area of involvement");
      return false;
    }

    if (!dataForm.data_82) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Treatment");
      return false;
    }

    if (!dataForm.data_83) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Underlying disease");
      return false;
    }

    if (!dataForm.data_84) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Coronary angiogram");
      return false;
    }

    if (!dataForm.data_85) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Revascularization");
      return false;
    }

    if (!dataForm.data_86) {
      displayErrorValidatePopup("จำเป็นต้องระบุ LVEF");
      return false;
    }

    if (!dataForm.data_87) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Bleeding BARC type");
      return false;
    } else {
      if (dataForm.data_87 == "1" && !dataForm.data_88) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Bleeding BARC type");
        return false;
      }
    }

    if (!dataForm.data_89) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Infection");
      return false;
    } else {
      if (dataForm.data_89 == "1" && !dataForm.data_90) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Infection");
        return false;
      }
    }

    if (!dataForm.data_91) {
      displayErrorValidatePopup("จำเป็นต้องระบุ AKI");
      return false;
    } else {
      if (dataForm.data_91 == "1" && !dataForm.data_92) {
        displayErrorValidatePopup("จำเป็นต้องระบุ AKI");
        return false;
      }
    }

    if (!dataForm.data_93) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Respiratory failure");
      return false;
    } else {
      if (dataForm.data_93 == "1" && !dataForm.data_94) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Respiratory failure");
        return false;
      }
    }

    if (!dataForm.data_95) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Mechanical complication");
      return false;
    } else {
      if (dataForm.data_95 == "1" && !dataForm.data_96) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Mechanical complication");
        return false;
      }
    }

    if (!dataForm.data_97) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Arrhythmia");
      return false;
    } else {
      if (dataForm.data_97 == "1" && !dataForm.data_98) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Arrhythmia");
        return false;
      }
    }

    if (!dataForm.data_99) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Recurrent ACS");
      return false;
    } else {
      if (dataForm.data_99 == "1" && !dataForm.data_100) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Recurrent ACS");
        return false;
      }
    }

    if (!dataForm.data_101) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Heart failure");
      return false;
    } else {
      if (dataForm.data_101 == "1" && !dataForm.data_102) {
        displayErrorValidatePopup("จำเป็นต้องระบุ Heart failure");
        return false;
      }
    }

    if (
      dataForm.data_103 != "1" &&
      dataForm.data_151 != "1" &&
      dataForm.data_152 != "1" &&
      dataForm.data_153 != "1" &&
      dataForm.data_154 != "1" &&
      dataForm.data_155 != "1"
    ) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Risk factors");
      return false;
    }

    if (!dataForm.data_143) {
      displayErrorValidatePopup("จำเป็นต้องระบุ LDL");
      return false;
    }

    if (!dataForm.data_144) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HDL");
      return false;
    }

    if (!dataForm.data_145) {
      displayErrorValidatePopup("จำเป็นต้องระบุ TG");
      return false;
    }

    if (!dataForm.data_146) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HbA1c");
      return false;
    }

    if (!dataForm.data_147) {
      displayErrorValidatePopup("จำเป็นต้องระบุ eGFR");
      return false;
    }

    if (!dataForm.data_148) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Hb");
      return false;
    }

    if (!dataForm.data_149) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Hct");
      return false;
    }

    if (!dataForm.data_105) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Plan of Treatment แผนการรักษา");
      return false;
    }

    if (!dataForm.data_106) {
      displayErrorValidatePopup("จำเป็นต้องระบุ BP");
      return false;
    }

    if (!dataForm.data_107) {
      displayErrorValidatePopup("จำเป็นต้องระบุ HR");
      return false;
    }

    if (!dataForm.data_108) {
      displayErrorValidatePopup("จำเป็นต้องระบุ Home environment");
      return false;
    }

    if (!dataForm.data_109) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน ประเมินความรู้ความเข้าใจของผู้ป่วยและญาติเกี่ยวกับโรคหัวใจและหลอดเลือดและการรักษาที่ได้รับ"
      );
      return false;
    }

    if (dataForm.data_110 == "1" && !dataForm.data_169) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การปฏิบัติ/คำแนะนำเมื่อ Depression เป็น Absent"
      );
      return false;
    }

    if (dataForm.data_110 == "2" && !dataForm.data_111) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินเมื่อ Depression เป็น Present (2Q)"
      );
      return false;
    }

    if (dataForm.data_112 == "1" && !dataForm.data_170) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การปฏิบัติ/คำแนะนำเมื่อ Anxiety เป็น Absent"
      );
      return false;
    }

    if (dataForm.data_112 == "2" && !dataForm.data_113) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมินเมื่อ Anxiety เป็น Present (VAS)"
      );
      return false;
    }

    if (!dataForm.data_114) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องโรคและการรักษาที่ได้รับ"
      );
      return false;
    }

    if (!dataForm.data_116) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องอาหารที่เหมาะสมกับโรค"
      );
      return false;
    }

    if (!dataForm.data_118) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องการรับประทานยา"
      );
      return false;
    }

    if (!dataForm.data_120) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน ให้ความรู้เรื่องการออกกำลังกายที่เหมาะสมกับโรค"
      );
      return false;
    }

    if (!dataForm.data_122) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน การสังเกตอาการผิดปกติที่ควรมาพบแพทย์ก่อนนัด"
      );
      return false;
    }

    if (!dataForm.data_125) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การประเมิน Cardiac rehabilitation");
      return false;
    }

    if (!dataForm.data_127) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การประเมิน Home walking program");
      return false;
    }

    if (!dataForm.data_128) {
      displayErrorValidatePopup("จำเป็นต้องระบุ การประเมิน คู่มือการปฏิบัติตัว");
      return false;
    }

    if (!dataForm.data_129) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน Occupation/ Return to work /Return to daily activity recommendation"
      );
      return false;
    }
    if (!dataForm.data_130) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน And need occ Med consultation"
      );
      return false;
    }
    if (!dataForm.data_130) {
      displayErrorValidatePopup(
        "จำเป็นต้องระบุ การประเมิน And need occ Med consultation"
      );
      return false;
    }
    if (!dataForm.data_131) {
      displayErrorValidatePopup("จำเป็นต้องระบุ วันที่ F/U");
      return false;
    }
    if (!dataForm.data_132) {
      displayErrorValidatePopup("จำเป็นต้องระบุ สถานที่");
      return false;
    }
    if (!dataForm.data_135) {
      displayErrorValidatePopup("จำเป็นต้องระบุ ส่งต่อข้อมูลผ่าน");
      return false;
    }
    if (!dataForm.data_136) {
      displayErrorValidatePopup("จำเป็นต้องระบุ สรุปปัญหาที่ส่งต่อ/ที่หลงเหลือ");
      return false;
    }
    return true;
  }, [dataForm, displayErrorValidatePopup]);

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  useEffect(() => {
    setDataForm((data) => ({
      ...data,
      data_6: calculateTimeBetweenDates(dataForm.data_4, dataForm.data_5),
      data_8: calculateTimeBetweenDates(dataForm.data_5, dataForm.data_7),
      data_10: calculateTimeBetweenDates(dataForm.data_5, dataForm.data_9),
      data_12: calculateTimeBetweenDates(dataForm.data_9, dataForm.data_11),
      data_14: calculateTimeBetweenDates(dataForm.data_11, dataForm.data_13),
      data_16: calculateTimeBetweenDates(dataForm.data_11, dataForm.data_15),
      data_18: calculateTimeBetweenDates(dataForm.data_11, dataForm.data_17),
      data_21: calculateTimeBetweenDates(dataForm.data_11, dataForm.data_20),
      data_25: calculateTimeBetweenDates(dataForm.data_22, dataForm.data_24),
      data_28: calculateTimeBetweenDates(dataForm.data_22, dataForm.data_27),
      data_30: calculateTimeBetweenDates(dataForm.data_11, dataForm.data_29),
      data_150: calculateTimeBetweenDates(dataForm.data_11, dataForm.data_31),
    }));
    // setData("6", calculateTimeBetweenDates(dataForm.data_4, dataForm.data_5));
    // setData("8", calculateTimeBetweenDates(dataForm.data_5, dataForm.data_7));
    // setData("10", calculateTimeBetweenDates(dataForm.data_5, dataForm.data_9));
    // setData("12", calculateTimeBetweenDates(dataForm.data_9, dataForm.data_11));
    // setData(
    //   "14",
    //   calculateTimeBetweenDates(dataForm.data_11, dataForm.data_13)
    // );
    // setData(
    //   "16",
    //   calculateTimeBetweenDates(dataForm.data_11, dataForm.data_15)
    // );
    // setData(
    //   "18",
    //   calculateTimeBetweenDates(dataForm.data_11, dataForm.data_17)
    // );
    // setData(
    //   "21",
    //   calculateTimeBetweenDates(dataForm.data_11, dataForm.data_20)
    // );
    // setData(
    //   "25",
    //   calculateTimeBetweenDates(dataForm.data_22, dataForm.data_24)
    // );
    // setData(
    //   "28",
    //   calculateTimeBetweenDates(dataForm.data_22, dataForm.data_27)
    // );
    // setData(
    //   "30",
    //   calculateTimeBetweenDates(dataForm.data_11, dataForm.data_29)
    // );
    // setData(
    //   "150",
    //   calculateTimeBetweenDates(dataForm.data_11, dataForm.data_31)
    // );
  }, [
    dataForm.data_4,
    dataForm.data_5,
    dataForm.data_7,
    dataForm.data_9,
    dataForm.data_11,
    dataForm.data_13,
    dataForm.data_15,
    dataForm.data_17,
    dataForm.data_19,
    dataForm.data_20,
    dataForm.data_22,
    dataForm.data_24,
    dataForm.data_27,
    dataForm.data_29,
    dataForm.data_31,
  ]);

  useEffect(() => {
    if (
      (dataForm.data_73 || dataForm.data_74) &&
      [dataForm.data_73, dataForm.data_74].includes("3")
    ) {
      setDataForm((data) => ({ ...data, data_160: "1" }));
    }
  }, [dataForm.data_73, dataForm.data_74, setDataForm]);

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Suandok ACS caring set" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Timeline" fontWeight="bold" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>Owner</th>
              <th colSpan={5}>
                Timeline Record from for ACS โรงพยาบาลมหาราชนครเชียงใหม่{" "}
                <span style={{ color: "red" }}>*</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ER</td>
              <td colSpan={5}>
                <FormElementRadio
                  label="New case & arrival by :"
                  fieldId={"1"}
                  value={dataForm.data_1}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
                <span hidden={dataForm.data_1 != "1"}>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementRadio
                      fieldId={"2"}
                      value={dataForm.data_2}
                      fieldValue={"1"}
                      onChange={handleChange}
                      label="byself"
                    />
                    <FormElementRadio
                      fieldId={"2"}
                      value={dataForm.data_2}
                      fieldValue={"2"}
                      onChange={handleChange}
                      label="family"
                    />
                    <FormElementRadio
                      fieldId={"2"}
                      value={dataForm.data_2}
                      fieldValue={"3"}
                      onChange={handleChange}
                      label="EMS"
                    />
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <td>ER</td>
              <td colSpan={5}>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <FormElementRadio
                    label="Refer from"
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={"2"}
                    onChange={handleChange}
                  />
                  <span hidden={dataForm.data_1 != "2"}>
                    <FormElementDropdown
                      fieldId={"3"}
                      value={dataForm.data_3}
                      onChange={handleChange}
                      dataList={HOSPITALS}
                      width="100"
                    />
                    <span hidden={dataForm.data_3 != "99999"}>
                      <FormElementTextBox
                        placeholder="ระบุ"
                        fieldId="182"
                        value={dataForm.data_182}
                        onChange={handleChange}
                      />
                    </span>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <th>Event</th>
              <th>
                วันที่ - เวลา <span style={{ color: "red" }}>*</span>
              </th>
              <th colSpan={2}>เวลาที่ใช้ไป</th>
            </tr>
            <tr>
              <td>ER</td>
              <td>
                <strong>1. Onset of symptom</strong>
              </td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_4}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_4: date }))}
                  withTime
                />
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>2. Arrive to ER 1st รพช</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_5}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_5: date }))}
                  withTime
                />
              </td>
              <td>Onset to FMC 2-1</td>
              <td>
                <FormElementTextBox
                  fieldId={"6"}
                  value={dataForm.data_6}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>3. First 12 leads ECG at รพช</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_7}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_7: date }))}
                  withTime
                />
              </td>
              <td>ER to EKG รพช 3-2</td>
              <td>
                <FormElementTextBox
                  fieldId={"8"}
                  value={dataForm.data_8}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>4. Depart from ER 1st hospital</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_9}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_9: date }))}
                  withTime
                />
              </td>
              <td>Di-Do 4-2</td>
              <td>
                <FormElementTextBox
                  fieldId={"10"}
                  value={dataForm.data_10}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>
                <strong>5. Arrive to ER Suandok</strong>
              </td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_11}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_11: date }))}
                  withTime
                />
              </td>
              <td>Transfer time 5-4</td>
              <td>
                <FormElementTextBox
                  fieldId={"12"}
                  value={dataForm.data_12}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>6. First 12 leads ECG at Suandok</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_13}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_13: date }))}
                  withTime
                />
              </td>
              <td>ER to EKG รพช 6-5</td>
              <td>
                <FormElementTextBox
                  fieldId={"14"}
                  value={dataForm.data_14}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>7. ER Physician visit</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_15}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_15: date }))}
                  withTime
                />
              </td>
              <td>Do to Dr 7-5</td>
              <td>
                <FormElementTextBox
                  fieldId={"16"}
                  value={dataForm.data_16}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>8. Final diagnosis at ER</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_17}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_17: date }))}
                  withTime
                />
              </td>
              <td>ER to Dx 8-5</td>
              <td>
                <FormElementTextBox
                  fieldId={"18"}
                  value={dataForm.data_18}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>9. Consult cardiologist</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_19}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_19: date }))}
                  withTime
                />
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>10. Cardiologist visit or call back</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_20}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_20: date }))}
                  withTime
                />
              </td>
              <td>ER to Cardio 10-5</td>
              <td>
                <FormElementTextBox
                  fieldId={"21"}
                  value={dataForm.data_21}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>CCU/CL</td>
              <td>11. Depart from ER Suandok</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_22}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_22: date }))}
                  withTime
                />
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td>Cath Lab</td>
              <td>12. Activated for PPCI</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_23}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_23: date }))}
                  withTime
                />
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td>CCU</td>
              <td>13. Arrive to CCU/ward</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_24}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_24: date }))}
                  withTime
                />
              </td>
              <td>ER to ward 13-11</td>
              <td>
                <FormElementTextBox
                  fieldId={"25"}
                  value={dataForm.data_25}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>Cath Lab</td>
              <td>
                <strong>14. Arrive to Cath-Lab</strong>
              </td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_27}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_27: date }))}
                  withTime
                />
              </td>
              <td>ER to cath 14-11</td>
              <td>
                <FormElementTextBox
                  fieldId={"28"}
                  value={dataForm.data_28}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>Cath Lab</td>
              <td>15. PPCI: Vascular access site</td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_29}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_29: date }))}
                  withTime
                />
              </td>
              <td>Do to vas access 15-5</td>
              <td>
                <FormElementTextBox
                  fieldId={"30"}
                  value={dataForm.data_30}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
            <tr>
              <td>Cath Lab</td>
              <td>
                <strong>16. PPCI: Wire crossing</strong>
              </td>
              <td>
                <FormElementDatePicker
                  value={dataForm.data_31}
                  onChange={(date) => setDataForm((data) => ({ ...data, data_31: date }))}
                  withTime
                />
              </td>
              <td>Do to wire 16-5</td>
              <td>
                <FormElementTextBox
                  fieldId={"150"}
                  value={dataForm.data_150}
                  onChange={handleChange}
                  label=""
                  width="100"
                  type="numeric"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Suandok ACS caring set" fontWeight="bold" />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLabel
            label="ข้อมูลพื้นฐาน 1/2 (สำหรับ CCU & IPD)"
            fontWeight="bold"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"32"}
            value={dataForm.data_32}
            label="Emergency contact (Name)"
            onChange={handleChange}
            width="100"
            isRequired
          />
          <FormElementDropdown
            fieldId={"33"}
            value={dataForm.data_33}
            label="Relationship"
            onChange={handleChange}
            dataList={[{ value: "0", label: "Choose relationship" }].concat(
              relationship
            )}
            width="100"
            isRequired
          />
          <span hidden={dataForm.data_33 != "99"}>
            <FormElementTextBox
              fieldId={"183"}
              value={dataForm.data_183}
              placeholder="ระบุ"
              onChange={handleChange}
              width="100"
              isRequired
            />
          </span>
          <FormElementTextBox
            fieldId={"34"}
            value={dataForm.data_34}
            label="Phone"
            onChange={handleChange}
            width="100"
            maxLength={10}
            type="numeric"
            isRequired
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementDropdown
            fieldId={"35"}
            value={dataForm.data_35}
            label="Education"
            onChange={handleChange}
            dataList={[{ value: "0", label: "Choose education" }].concat(
              education
            )}
            width="100"
            isRequired
          />
          <FormElementDropdown
            fieldId={"36"}
            value={dataForm.data_36}
            label="Occupation"
            onChange={handleChange}
            dataList={[{ value: "0", label: "Choose occupation" }].concat(
              occupation
            )}
            width="100"
            isRequired
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"37"}
            value={dataForm.data_37}
            label="Vital signs: Temp"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">oC</span>
          <FormElementTextBox
            fieldId={"38"}
            value={dataForm.data_38}
            label="HR"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">time/min</span>
          <FormElementTextBox
            fieldId={"39"}
            value={dataForm.data_39}
            label="RR"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">time/min</span>
          <FormElementTextBox
            fieldId={"40"}
            value={dataForm.data_40}
            label="BP"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">mmHg.</span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
          <FormElementTextBox
            fieldId={"41"}
            value={dataForm.data_41}
            label="ส่วนสูง"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">ซม.</span>
          <FormElementTextBox
            fieldId={"42"}
            value={dataForm.data_42}
            label="น้ำหนัก"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">กก.</span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementLine />
          <FormElementLabel
            label=" ข้อมูลพื้นฐาน 2/2 (สำหรับ CCU & IPD )"
            fontWeight="normal"
          />
          <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Smoking: <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"43"}
                value={dataForm.data_43}
                fieldValue={"1"}
                onChange={handleChange}
                label="Current smoker"
              />
              <FormElementRadio
                fieldId={"43"}
                value={dataForm.data_43}
                fieldValue={"2"}
                onChange={handleChange}
                label="Ex-smoker"
              />
              <FormElementRadio
                fieldId={"43"}
                value={dataForm.data_43}
                fieldValue={"3"}
                onChange={handleChange}
                label="Never"
              />
            </div>
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                HT: <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"44"}
                value={dataForm.data_44}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"44"}
                value={dataForm.data_44}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
          </div>

          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
            <span className="formTextBoxLabel">
              DLP: <span style={{ color: "red" }}>*</span>
            </span>
            <FormElementRadio
              fieldId={"45"}
              value={dataForm.data_45}
              fieldValue={"0"}
              onChange={handleChange}
              label="No"
            />
            <FormElementRadio
              fieldId={"45"}
              value={dataForm.data_45}
              fieldValue={"1"}
              onChange={handleChange}
              label="Yes"
            />
            <span className="formTextBoxLabel">
              (LDL {">"} 100 หรือ HDL {"<"} 40 ในเพศชาย หรือ HDL {"<"}{" "}
              50ในเพศหญิง หรือ มีประวัติการใช้ยา statin)
            </span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
            <span className="formTextBoxLabel">
              DM: <span style={{ color: "red" }}>*</span>
            </span>
            <FormElementRadio
              fieldId={"46"}
              value={dataForm.data_46}
              fieldValue={"1"}
              onChange={handleChange}
              label="No"
            />
            <FormElementRadio
              fieldId={"46"}
              value={dataForm.data_46}
              fieldValue={"2"}
              onChange={handleChange}
              label="Yes"
            />
            <span
              className={dataForm.data_46 == "2" ? "form-show" : "form-hidden"}
            >
              <span className="formTextBoxLabel"></span>
              <FormElementRadio
                fieldId={"47"}
                value={dataForm.data_47}
                fieldValue={"3"}
                onChange={handleChange}
                label="Diet"
              />
              <FormElementRadio
                fieldId={"47"}
                value={dataForm.data_47}
                fieldValue={"4"}
                onChange={handleChange}
                label="Oral"
              />
              <FormElementRadio
                fieldId={"47"}
                value={dataForm.data_47}
                fieldValue={"5"}
                onChange={handleChange}
                label="Insulin"
              />
            </span>
            <span className="formTextBoxLabel">
              (HbA1C {">"} 6.5 หรือ FBS {">"} 126 หรือ random BS {">"} 200 หรือ
              มีประวัติใช้ยาต้านเบาหวาน)
            </span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Family Hx of CAD: <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"48"}
                value={dataForm.data_48}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"48"}
                value={dataForm.data_48}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Prior MI: <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"49"}
                value={dataForm.data_49}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"49"}
                value={dataForm.data_49}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Known CAD (stenosis {">"} 50%):{" "}
                <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"50"}
                value={dataForm.data_50}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"50"}
                value={dataForm.data_50}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                CVD disease: <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"51"}
                value={dataForm.data_51}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"51"}
                value={dataForm.data_51}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes CVD=cerebrovascular disease"
              />
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                PAD <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"52"}
                value={dataForm.data_52}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"52"}
                value={dataForm.data_52}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Prior PCI <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"53"}
                value={dataForm.data_53}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"53"}
                value={dataForm.data_53}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
              <span
                className={
                  dataForm.data_53 == "1" ? "form-show" : "form-hidden"
                }
              >
                <FormElementTextBox
                  fieldId={"54"}
                  value={dataForm.data_54}
                  label="Last year"
                  onChange={handleChange}
                  width="100"
                  type="date"
                />
              </span>
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Prior CABG <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"55"}
                value={dataForm.data_55}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"55"}
                value={dataForm.data_55}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
              <span
                className={
                  dataForm.data_55 == "1" ? "form-show" : "form-hidden"
                }
              >
                <FormElementTextBox
                  fieldId={"56"}
                  value={dataForm.data_56}
                  label="Last year"
                  onChange={handleChange}
                  width="100"
                  type="date"
                />
              </span>
            </div>
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Prior HF <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"57"}
                value={dataForm.data_57}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"57"}
                value={dataForm.data_57}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Prior valve surgery <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"58"}
                value={dataForm.data_58}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"58"}
                value={dataForm.data_58}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
            <div className="uk-padding-small">
              <span className="formTextBoxLabel">
                Chronic lung <span style={{ color: "red" }}>*</span>
              </span>
              <FormElementRadio
                fieldId={"59"}
                value={dataForm.data_59}
                fieldValue={"0"}
                onChange={handleChange}
                label="No"
              />
              <FormElementRadio
                fieldId={"59"}
                value={dataForm.data_59}
                fieldValue={"1"}
                onChange={handleChange}
                label="Yes"
              />
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
            <span className="formTextBoxLabel">
              On dialysis <span style={{ color: "red" }}>*</span>
            </span>
            <FormElementRadio
              fieldId={"60"}
              value={dataForm.data_60}
              fieldValue={"0"}
              onChange={handleChange}
              label="No"
            />
            <FormElementRadio
              fieldId={"60"}
              value={dataForm.data_60}
              fieldValue={"1"}
              onChange={handleChange}
              label="Yes"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementLabel
              label=" Complication at admission:"
              fontWeight="normal"
            />
            <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
              <div className="uk-padding-small">
                <span className="formTextBoxLabel">
                  1. HF <span style={{ color: "red" }}>*</span>
                </span>
                <FormElementRadio
                  fieldId={"61"}
                  value={dataForm.data_61}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No"
                />
                <FormElementRadio
                  fieldId={"61"}
                  value={dataForm.data_61}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes"
                />
                <span
                  className={
                    dataForm.data_61 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <span className="formTextBoxLabel">: Class</span>
                  <FormElementRadio
                    fieldId={"62"}
                    value={dataForm.data_62}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="II"
                  />
                  <FormElementRadio
                    fieldId={"62"}
                    value={dataForm.data_62}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="III"
                  />
                  <FormElementRadio
                    fieldId={"62"}
                    value={dataForm.data_62}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="IV"
                  />
                </span>
              </div>
              <div className="uk-padding-small">
                <span className="formTextBoxLabel">
                  2. Arrhythmia <span style={{ color: "red" }}>*</span>
                </span>
                <FormElementRadio
                  fieldId={"63"}
                  value={dataForm.data_63}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No"
                />
                <FormElementRadio
                  fieldId={"63"}
                  value={dataForm.data_63}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes"
                />
                <span
                  className={
                    dataForm.data_63 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <span className="formTextBoxLabel">: Type</span>
                  <FormElementRadio
                    fieldId={"64"}
                    value={dataForm.data_64}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="CHB"
                  />
                  <FormElementRadio
                    fieldId={"64"}
                    value={dataForm.data_64}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="VT/VF"
                  />
                </span>
              </div>
            </div>

            <div className="uk-width-1-1@m uk-width-1-1 uk-column-1-2 uk-column-divider">
              <div className="uk-padding-small">
                <span className="formTextBoxLabel">
                  3. CPR <span style={{ color: "red" }}>*</span>
                </span>
                <FormElementRadio
                  fieldId={"65"}
                  value={dataForm.data_65}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No"
                />
                <FormElementRadio
                  fieldId={"65"}
                  value={dataForm.data_65}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes"
                />
              </div>
              <div className="uk-padding-small">
                <span className="formTextBoxLabel">
                  4. Death <span style={{ color: "red" }}>*</span>
                </span>
                <FormElementRadio
                  fieldId={"66"}
                  value={dataForm.data_66}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No"
                />
                <FormElementRadio
                  fieldId={"66"}
                  value={dataForm.data_66}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes"
                />
                <span
                  className={
                    dataForm.data_66 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <span className="formTextBoxLabel">:</span>
                  <FormElementRadio
                    fieldId={"67"}
                    value={dataForm.data_67}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Pre hosp"
                  />
                  <FormElementRadio
                    fieldId={"67"}
                    value={dataForm.data_67}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="ER"
                  />
                  <FormElementRadio
                    fieldId={"67"}
                    value={dataForm.data_67}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="On admission"
                  />
                  <FormElementRadio
                    fieldId={"67"}
                    value={dataForm.data_67}
                    fieldValue={"4"}
                    onChange={handleChange}
                    label="During transfer"
                  />
                </span>
              </div>
            </div>
            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small"></div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
          <FormElementLine />
          <FormElementLabel
            label="Diagnosis and plan management. (สำหรับแพทย์)"
            fontWeight="normal"
          />
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">
              <strong>
                Diagnosis: <span style={{ color: "red" }}>*</span>
              </strong>
            </span>
            <FormElementRadio
              fieldId={"68"}
              value={dataForm.data_68}
              fieldValue={"1"}
              onChange={handleChange}
              label="STEMI"
            />
            <FormElementRadio
              fieldId={"68"}
              value={dataForm.data_68}
              fieldValue={"2"}
              onChange={handleChange}
              label="NSTEACS"
            />
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small"
            hidden={dataForm.data_68 != "1"}
          >
            <span className="formTextBoxLabel" style={{ marginLeft: "80px" }}>
              <strong>
                Area of involvement: <span style={{ color: "red" }}>*</span>
              </strong>
            </span>
            <FormElementCheckBox
              fieldId={"137"}
              value={dataForm.data_137}
              onChange={handleChange}
              label="Anterior"
            />
            <FormElementCheckBox
              fieldId={"138"}
              value={dataForm.data_138}
              onChange={handleChange}
              label="Inferior"
            />
            <FormElementCheckBox
              fieldId={"139"}
              value={dataForm.data_139}
              onChange={handleChange}
              label="Lateral"
            />
            <FormElementCheckBox
              fieldId={"140"}
              value={dataForm.data_140}
              onChange={handleChange}
              label="Posterior"
            />
            <FormElementCheckBox
              fieldId={"141"}
              value={dataForm.data_141}
              onChange={handleChange}
              label="RV"
            />
            <FormElementCheckBox
              fieldId={"142"}
              value={dataForm.data_142}
              onChange={handleChange}
              label="Unknown"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">
              <strong>Plan</strong>
            </span>
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
            hidden={dataForm.data_68 != "1"}
          >
            <span className="formTextBoxLabel">
              STEMI Plan <span style={{ color: "red" }}>*</span>
            </span>
            <FormElementRadio
              fieldId={"70"}
              value={dataForm.data_70}
              fieldValue={"1"}
              onChange={handleChange}
              label="Fibrinolytic"
            />
            <FormElementRadio
              fieldId={"70"}
              value={dataForm.data_70}
              fieldValue={"2"}
              onChange={handleChange}
              label="PPCI"
            />
            <FormElementRadio
              fieldId={"70"}
              value={dataForm.data_70}
              fieldValue={"3"}
              onChange={handleChange}
              label="Rescue PCI"
            />
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small"
            style={{ marginLeft: "94px" }}
            hidden={dataForm.data_68 != "1"}
          >
            <FormElementRadio
              fieldId={"70"}
              value={dataForm.data_70}
              fieldValue={"4"}
              onChange={handleChange}
              label="PHI ( pharmacoinvasive)"
            />
            <FormElementRadio
              fieldId={"70"}
              value={dataForm.data_70}
              fieldValue={"5"}
              onChange={handleChange}
              label="Secondary PCI (PCI after 24hr)"
            />
            <FormElementRadio
              fieldId={"70"}
              value={dataForm.data_70}
              fieldValue={"6"}
              onChange={handleChange}
              label="Conservative"
            />
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1 uk-padding-small"
            hidden={dataForm.data_68 != "2"}
          >
            <span className="formTextBoxLabel">
              NSTEACS Plan <span style={{ color: "red" }}>*</span>
            </span>
            <FormElementRadio
              fieldId={"71"}
              value={dataForm.data_71}
              fieldValue={"1"}
              onChange={handleChange}
              label="Invasive"
            />
            <FormElementRadio
              fieldId={"71"}
              value={dataForm.data_71}
              fieldValue={"2"}
              onChange={handleChange}
              label="Conservative"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementLabel
              label="Risk assessment (สำหรับแพทย์ CCU&IPD) ประเมินแรกรับ ภายใน 24 ชั่วโมง"
              fontWeight="bold"
            />
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <tbody>
                <tr>
                  <td rowSpan={1}>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-top">
                      <GRACEScoreSection
                        data={dataForm.data_181}
                        onChange={(value) => {
                          setDataForm((data) => ({ ...data, data_181: { ...value } }))
                        }}
                        onCalculate={(score) => {
                          setDataForm((data) => ({ ...data, data_72: score.toString() }))
                        }}
                      />
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td hidden={dataForm.data_68 != "1"} colSpan={2}>
                    <span className="formTextBoxLabel">
                      STEMI (ความเสี่ยงต่อ Cardiac arrest){" "}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementRadio
                        fieldId={"73"}
                        value={dataForm.data_73}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Low (<126)"
                        fontWeight="normal"
                      />
                      <FormElementRadio
                        fieldId={"73"}
                        value={dataForm.data_73}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Intermediate(>=126 – <155)"
                        fontWeight="normal"
                      />
                      <FormElementRadio
                        fieldId={"73"}
                        value={dataForm.data_73}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label="High (>155)"
                        fontWeight="normal"
                      />
                    </div>
                  </td>
                  <td hidden={dataForm.data_68 != "2"} colSpan={2}>
                    <span className="formTextBoxLabel">
                      NSTEMI (ความเสี่ยงต่อ Cardiac arrest){" "}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                      <FormElementRadio
                        fieldId={"74"}
                        value={dataForm.data_74}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Low (1-108)"
                        fontWeight="normal"
                      />
                      <FormElementRadio
                        fieldId={"74"}
                        value={dataForm.data_74}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Intermediate (109 - 140)"
                        fontWeight="normal"
                      />
                      <FormElementRadio
                        fieldId={"74"}
                        value={dataForm.data_74}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label="High (>140)"
                        fontWeight="normal"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                      {" "}
                      <span className="formTextBoxLabel">
                        Bleeding (ARC-HBR){" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>{" "}
                    </div>
                  </td>
                  <td>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                      <FormElementRadio
                        fieldId={"75"}
                        value={dataForm.data_75}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Yes, high risk"
                        fontWeight="normal"
                      />
                      <FormElementRadio
                        fieldId={"75"}
                        value={dataForm.data_75}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="No risk"
                        fontWeight="normal"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                      {" "}
                      <span className="formTextBoxLabel">
                        Heart Failure <span style={{ color: "red" }}>*</span>
                      </span>{" "}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormElementCheckBox
                        fieldId={"76"}
                        value={dataForm.data_76}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" EF<40% "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"157"}
                        value={dataForm.data_157}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" Ant wall "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"158"}
                        value={dataForm.data_158}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=" Not reperfusion in STEMI "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"159"}
                        value={dataForm.data_159}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=" eGFR<60 "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"160"}
                        value={dataForm.data_160}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=" High risk GRACE"
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"161"}
                        value={dataForm.data_161}
                        fieldValue={"6"}
                        onChange={handleChange}
                        label="N/A"
                        fontWeight="normal"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                      {" "}
                      <span className="formTextBoxLabel">
                        Cardiac arrhythmia{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>{" "}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormElementCheckBox
                        fieldId={"77"}
                        value={dataForm.data_77}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="  Electrolyte (K,mg) "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"162"}
                        value={dataForm.data_162}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="  EF<40% "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"163"}
                        value={dataForm.data_163}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label="Ant wall"
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"164"}
                        value={dataForm.data_164}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label="N/A"
                        fontWeight="normal"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                      {" "}
                      <span className="formTextBoxLabel">
                        Stroke <span style={{ color: "red" }}>*</span>
                      </span>{" "}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "inline-flex",
                        gap: "8px",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormElementCheckBox
                        fieldId={"78"}
                        value={dataForm.data_78}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" EF<40% "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"165"}
                        value={dataForm.data_165}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" Ant wall "
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"166"}
                        value={dataForm.data_166}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=" Not reperfusion in STEMI"
                        fontWeight="normal"
                      />
                      <FormElementCheckBox
                        fieldId={"167"}
                        value={dataForm.data_167}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label="N/A"
                        fontWeight="normal"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
        <FormElementLine />
        <FormElementLabel
          label="Discharge summary physician"
          fontWeight="normal"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <tbody>
            <tr>
              <th className="center left">
                Diagnosis/ Type <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                  <FormElementRadio
                    fieldId={"79"}
                    value={dataForm.data_79}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="STEMI"
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"79"}
                    value={dataForm.data_79}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="NSTEMI/UA"
                    fontWeight="normal"
                  />
                </div>
              </td>
              <td>
                {" "}
                <FormElementTextBox
                  fieldId={"80"}
                  value={dataForm.data_80}
                  onChange={handleChange}
                  label="Type"
                  width="100"
                />{" "}
              </td>
            </tr>
            <tr hidden={dataForm.data_79 != "1"}>
              <th className="center left">
                Area of involvement <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"81"}
                  value={dataForm.data_81}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th className="center left">
                Treatment <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"82"}
                  value={dataForm.data_82}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th className="center left">
                Underlying disease <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"83"}
                  value={dataForm.data_83}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th className="center left">
                Coronary angiogram <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"84"}
                  value={dataForm.data_84}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th className="center left">
                Revascularization <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"85"}
                  value={dataForm.data_85}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th className="center left">
                LVEF <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementTextBox
                  fieldId={"86"}
                  value={dataForm.data_86}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <th className="center left" rowSpan={8}>
                Complication during admission
              </th>
              <th className="center left">
                Bleeding BARC type <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"87"}
                  value={dataForm.data_87}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_87 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"88"}
                    value={dataForm.data_88}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Infection <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"89"}
                  value={dataForm.data_89}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_89 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"90"}
                    value={dataForm.data_90}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                AKI <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"91"}
                  value={dataForm.data_91}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_91 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"92"}
                    value={dataForm.data_92}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Respiratory failure <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"93"}
                  value={dataForm.data_93}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_93 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"94"}
                    value={dataForm.data_94}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Mechanical complication <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"95"}
                  value={dataForm.data_95}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_95 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"96"}
                    value={dataForm.data_96}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Arrhythmia <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"97"}
                  value={dataForm.data_97}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_97 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"98"}
                    value={dataForm.data_98}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Recurrent ACS <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"99"}
                  value={dataForm.data_99}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_99 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"100"}
                    value={dataForm.data_100}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Heart failure <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"0"}
                  onChange={handleChange}
                  label="No "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"101"}
                  value={dataForm.data_101}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Yes "
                  fontWeight="normal"
                />
                <span
                  className={
                    dataForm.data_101 == "1" ? "form-show" : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    fieldId={"102"}
                    value={dataForm.data_102}
                    onChange={handleChange}
                    label="ระบุ"
                    width="100"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Risk factors <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementCheckBox
                  fieldId={"103"}
                  value={dataForm.data_103}
                  onChange={handleChange}
                  label="DLP"
                  fontWeight="normal"
                />
                <FormElementCheckBox
                  fieldId={"151"}
                  value={dataForm.data_151}
                  onChange={handleChange}
                  label="DM "
                  fontWeight="normal"
                />
                <FormElementCheckBox
                  fieldId={"152"}
                  value={dataForm.data_152}
                  onChange={handleChange}
                  label="HT "
                  fontWeight="normal"
                />
                <FormElementCheckBox
                  fieldId={"153"}
                  value={dataForm.data_153}
                  onChange={handleChange}
                  label="Smoking "
                  fontWeight="normal"
                />
                <FormElementCheckBox
                  fieldId={"154"}
                  value={dataForm.data_154}
                  onChange={handleChange}
                  label="BMI > 25 "
                  fontWeight="normal"
                />
                <FormElementCheckBox
                  fieldId={"155"}
                  value={dataForm.data_155}
                  onChange={handleChange}
                  label="FmHx "
                  fontWeight="normal"
                />
              </td>
            </tr>
            <tr>
              <th className="center left">
                Lab <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"143"}
                    value={dataForm.data_143}
                    onChange={handleChange}
                    label="LDL="
                    width="100"
                    isRequired
                  />
                  <FormElementTextBox
                    fieldId={"144"}
                    value={dataForm.data_144}
                    onChange={handleChange}
                    label="HDL="
                    width="100"
                    isRequired
                  />
                  <FormElementTextBox
                    fieldId={"145"}
                    value={dataForm.data_145}
                    onChange={handleChange}
                    label="TG="
                    width="100"
                    isRequired
                  />
                  <FormElementTextBox
                    fieldId={"146"}
                    value={dataForm.data_146}
                    onChange={handleChange}
                    label="HbA1c="
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
                  <FormElementTextBox
                    fieldId={"147"}
                    value={dataForm.data_147}
                    onChange={handleChange}
                    label="eGFR"
                    width="100"
                    isRequired
                  />
                  <FormElementTextBox
                    fieldId={"148"}
                    value={dataForm.data_148}
                    onChange={handleChange}
                    label="Hb"
                    width="100"
                    isRequired
                  />
                  <FormElementTextBox
                    fieldId={"149"}
                    value={dataForm.data_149}
                    onChange={handleChange}
                    label="Hct"
                    width="100"
                    isRequired
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="center left">
                Plan of Treatment แผนการรักษา{" "}
                <span style={{ color: "red" }}>*</span>
              </th>
              <td colSpan={2}>
                <FormElementTextArea
                  fieldId={"105"}
                  value={dataForm.data_105}
                  onChange={handleChange}
                  label=""
                  width="100"
                  rows={4}
                  cols={80}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
        <FormElementLine />
        <FormElementLabel
          label="Discharge summary nurse (หอผู้ป่วยที่จำหน่ายผู้ป่วย)"
          fontWeight="normal"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"106"}
            value={dataForm.data_106}
            onChange={handleChange}
            label="BP"
            width="100 "
            isRequired
          />
          <span className="formTextBoxLabel">mmHg</span>
          <FormElementTextBox
            fieldId={"107"}
            value={dataForm.data_107}
            onChange={handleChange}
            label="HR"
            width="100 "
            isRequired
          />
          <span className="formTextBoxLabel">bpm (at discharge time)</span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextArea
            fieldId={"108"}
            value={dataForm.data_108}
            rows={2}
            cols={80}
            onChange={handleChange}
            label="Home environment"
            width="100 "
            isRequired
          />
        </div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>รายละเอียดขั้นตอนการจำหน่าย</th>
              <th>
                การประเมิน <span style={{ color: "red" }}>*</span>
              </th>
              <th>
                การปฏิบัติ/คำแนะนำ <span style={{ color: "red" }}>*</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                ประเมินความรู้ความเข้าใจของผู้ป่วยและญาติเกี่ยวกับโรคหัวใจและหลอดเลือดและการรักษาที่ได้รับ
              </td>
              <td>
                <FormElementRadio
                  fieldId={"109"}
                  value={dataForm.data_109}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Done "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"109"}
                  value={dataForm.data_109}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Not Done"
                  fontWeight="normal"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"168"}
                  value={dataForm.data_168}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <strong>การประเมินด้านจิตสังคม</strong>
              </td>
            </tr>
            <tr>
              <td>
                <span className="formTextBoxLabel">
                  <strong>Depression</strong>
                </span>
                <FormElementRadio
                  fieldId={"110"}
                  value={dataForm.data_110}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Absent "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"110"}
                  value={dataForm.data_110}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Present (2Q)"
                  fontWeight="normal"
                />
              </td>
              <td>
                <span hidden={dataForm.data_110 != "2"}>
                  <FormElementRadio
                    fieldId={"111"}
                    value={dataForm.data_111}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Mild "
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"111"}
                    value={dataForm.data_111}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Moderate"
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"111"}
                    value={dataForm.data_111}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="Severe (9Q)"
                    fontWeight="normal"
                  />
                </span>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"169"}
                  value={dataForm.data_169}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>
                <span className="formTextBoxLabel">
                  <strong>Anxiety</strong>
                </span>
                <FormElementRadio
                  fieldId={"112"}
                  value={dataForm.data_112}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Absent "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"112"}
                  value={dataForm.data_112}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Present (VAS) "
                  fontWeight="normal"
                />
              </td>
              <td>
                <span hidden={dataForm.data_112 != "2"}>
                  <FormElementRadio
                    fieldId={"113"}
                    value={dataForm.data_113}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Mild "
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"113"}
                    value={dataForm.data_113}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Moderate"
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"113"}
                    value={dataForm.data_113}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label="Severe "
                    fontWeight="normal"
                  />
                </span>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"170"}
                  value={dataForm.data_170}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>ให้ความรู้เรื่องโรคและการรักษาที่ได้รับ</td>
              <td>
                <FormElementRadio
                  fieldId={"114"}
                  value={dataForm.data_114}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Done "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"114"}
                  value={dataForm.data_114}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Not done"
                  fontWeight="normal"
                />
                <FormElementTextBox
                  fieldId={"115"}
                  value={dataForm.data_115}
                  onChange={handleChange}
                  label="ระบุ"
                  width="100"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"171"}
                  value={dataForm.data_171}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>ให้ความรู้เรื่องอาหารที่เหมาะสมกับโรค</td>
              <td>
                <FormElementRadio
                  fieldId={"116"}
                  value={dataForm.data_116}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Done "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"116"}
                  value={dataForm.data_116}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Not done"
                  fontWeight="normal"
                />
                <FormElementTextBox
                  fieldId={"117"}
                  value={dataForm.data_117}
                  onChange={handleChange}
                  label="ระบุ"
                  width="100"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"172"}
                  value={dataForm.data_172}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>ให้ความรู้เรื่องการรับประทานยา</td>
              <td>
                <FormElementRadio
                  fieldId={"118"}
                  value={dataForm.data_118}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Done "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"118"}
                  value={dataForm.data_118}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Not done"
                  fontWeight="normal"
                />
                <FormElementTextBox
                  fieldId={"119"}
                  value={dataForm.data_119}
                  onChange={handleChange}
                  label="ระบุ"
                  width="100"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"173"}
                  value={dataForm.data_173}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>ให้ความรู้เรื่องการออกกำลังกายที่เหมาะสมกับโรค</td>
              <td>
                <FormElementRadio
                  fieldId={"120"}
                  value={dataForm.data_120}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Done "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"120"}
                  value={dataForm.data_120}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Not done"
                  fontWeight="normal"
                />
                <FormElementTextBox
                  fieldId={"121"}
                  value={dataForm.data_121}
                  onChange={handleChange}
                  label="ระบุ"
                  width="100"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"174"}
                  value={dataForm.data_174}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>การสังเกตอาการผิดปกติที่ควรมาพบแพทย์ก่อนนัด</td>
              <td>
                <FormElementRadio
                  fieldId={"122"}
                  value={dataForm.data_122}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Done "
                  fontWeight="normal"
                />
                <FormElementRadio
                  fieldId={"122"}
                  value={dataForm.data_122}
                  fieldValue={"2"}
                  onChange={handleChange}
                  label="Not done"
                  fontWeight="normal"
                />
                <FormElementTextBox
                  fieldId={"123"}
                  value={dataForm.data_123}
                  onChange={handleChange}
                  label="ระบุ"
                  width="100"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"175"}
                  value={dataForm.data_175}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>อื่นๆ</td>
              <td>
                <FormElementTextArea
                  fieldId={"124"}
                  value={dataForm.data_124}
                  rows={2}
                  cols={50}
                  onChange={handleChange}
                  label=""
                  width="100"
                />
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"176"}
                  value={dataForm.data_176}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>Cardiac Rehabilitation Program Phase I คำแนะนำที่ให้</td>
              <td>
                <span className="formTextBoxLabel">
                  <strong>
                    Cardiac rehabilitation{" "}
                    <span style={{ color: "red" }}>*</span>
                  </strong>
                </span>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                  <FormElementRadio
                    fieldId={"125"}
                    value={dataForm.data_125}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="No "
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"125"}
                    value={dataForm.data_125}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes "
                    fontWeight="normal"
                  />
                  <FormElementTextBox
                    fieldId={"126"}
                    value={dataForm.data_126}
                    onChange={handleChange}
                    width="100"
                  />
                  <span className="formTextBoxLabel">METs at discharge</span>
                </div>
                <span className="formTextBoxLabel">
                  <strong>
                    Home walking program <span style={{ color: "red" }}>*</span>
                  </strong>
                </span>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                  <FormElementRadio
                    fieldId={"127"}
                    value={dataForm.data_127}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="No "
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"127"}
                    value={dataForm.data_127}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                    fontWeight="normal"
                  />
                </div>
                <span className="formTextBoxLabel">
                  <strong>
                    คู่มือการปฏิบัติตัว <span style={{ color: "red" }}>*</span>
                  </strong>
                </span>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                  <FormElementRadio
                    fieldId={"128"}
                    value={dataForm.data_128}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="No "
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"128"}
                    value={dataForm.data_128}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                    fontWeight="normal"
                  />
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"177"}
                  value={dataForm.data_177}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>
                Occupation/ Return to work /Return to daily activity
                recommendation
              </td>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"129"}
                    value={dataForm.data_129}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="Not done "
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"129"}
                    value={dataForm.data_129}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Done "
                    fontWeight="normal"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
                  <span className="formTextBoxLabel">
                    <strong>
                      And need occ Med consultation{" "}
                      <span style={{ color: "red" }}>*</span>
                    </strong>
                  </span>
                  <FormElementRadio
                    fieldId={"130"}
                    value={dataForm.data_130}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label="No"
                    fontWeight="normal"
                  />
                  <FormElementRadio
                    fieldId={"130"}
                    value={dataForm.data_130}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Yes"
                    fontWeight="normal"
                  />
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"178"}
                  value={dataForm.data_178}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>
                ให้ความรู้เรื่องการมาตรวจตามนัด
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementTextBox
                    fieldId={"131"}
                    value={dataForm.data_131}
                    type="date"
                    label="วันที่ F/U"
                    onChange={handleChange}
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementTextBox
                    fieldId={"132"}
                    value={dataForm.data_132}
                    onChange={handleChange}
                    label="สถานที่"
                    width="100"
                    isRequired
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementCheckBox
                    fieldId={"133"}
                    value={dataForm.data_133}
                    onChange={handleChange}
                    label="Refer รพ."
                  />
                  <FormElementTextBox
                    fieldId={"134"}
                    value={dataForm.data_134}
                    onChange={handleChange}
                    label=""
                    width="100"
                  />
                </div>
              </td>

              <td>
                ส่งต่อข้อมูลผ่าน <span style={{ color: "red" }}>*</span>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"135"}
                    value={dataForm.data_135}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=" E-Cardio application "
                    fontWeight="normal"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"135"}
                    value={dataForm.data_135}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label=" Electronic/ LINE Tele Follow up  "
                    fontWeight="normal"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"135"}
                    value={dataForm.data_135}
                    fieldValue={"3"}
                    onChange={handleChange}
                    label=" Paper "
                    fontWeight="normal"
                  />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                  <FormElementRadio
                    fieldId={"135"}
                    value={dataForm.data_135}
                    fieldValue={"4"}
                    onChange={handleChange}
                    label=" None "
                    fontWeight="normal"
                  />
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"179"}
                  value={dataForm.data_179}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
            <tr>
              <td>
                สรุปปัญหาที่ส่งต่อ/ที่หลงเหลือ{" "}
                <span style={{ color: "red" }}>*</span>
              </td>
              <td>
                <div>
                  <FormElementTextArea
                    fieldId={"136"}
                    rows={5}
                    cols={50}
                    value={dataForm.data_136}
                    onChange={handleChange}
                    label=""
                    width="100"
                  />
                </div>
              </td>
              <td>
                <FormElementTextArea
                  fieldId={"180"}
                  value={dataForm.data_180}
                  onChange={handleChange}
                  noLimit
                  cols={80}
                  rows={3}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <span hidden={!dataForm.form_id}>
          <FormElementTextArea
            fieldId={"156"}
            rows={5}
            cols={100}
            noLimit
            value={dataForm.data_156}
            onChange={handleChange}
            label="เหตุผลในการแก้ไข"
            width="100"
          />
        </span>
      </div>
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={<FormACSCaringSetPaper
          dataForm={dataForm}
          patient={patient}
          profile={profile}
          relationshipList={relationship}
          educationList={education}
          occupationList={occupation}
        />}
      />
    </div>
  );
};

export default FormACSCaringSet;
