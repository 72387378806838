import React, { useState, useEffect, useRef, useCallback } from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementTagsInput from "../Form/FormElementTagsInput";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import dateFormat from "dateformat";
import moment from "moment";

import "../../../css/dietz.css";
import "../../../css/reactTags.css";
import FormElementDropdown from "../Form/FormElementDropdown";

import PopupForm from "../PopupForm";
import { set } from "date-fns";
import FormElementTagInput from "../Form/FormElementTagInput";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormStroke = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [hn, setHn] = useState(false);
  var [txn, setTxn] = useState(false);
  var [currentDate, setCurrentDate] = useState(null);
  var [strokeOnSet, setStrokeOnSet] = useState(null);
  var [oldData, setOldData] = useState([]);
  var [clinicalDiags, setClinicalDiags] = useState([]);

  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);

  //#region Form State
  const [data41Disabled, setData41Disabled] = useState([false]);
  const [data42Disabled, setData42Disabled] = useState([false]);
  const [data43Disabled, setData43Disabled] = useState([false]);
  const [data44Disabled, setData44Disabled] = useState([false]);
  const [data45Disabled, setData45Disabled] = useState([false]);

  const [data17Disabled, setData17Disabled] = useState([false]);
  const [data19Disabled, setData19Disabled] = useState([false]);
  const [data21Disabled, setData21Disabled] = useState([false]);
  const [data23Disabled, setData23Disabled] = useState([false]);

  const [dataStrokeDate, setDataStrokeDate] = useState("");
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  const [popupDataForm, setPopupDataForm] = useState({});
  //#endregion

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  useEffect(() => {
    var initValue = {
      formName: "form_stroke",
      formType: "18",
    };

    setCurrentDate(dateFormat(new Date(), "yyyy/mm/dd"));

    props.addValue(initValue);
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);

    if (typeof props.dataForm.data_4 != "undefined") {
      setVisibleData4(props.dataForm.data_4);
    }
  }, [props.dataForm]);

  useEffect(() => {
    setHn(props.hn);
    setTxn(props.txn);

    if (props.txn != null) {
      props.loadDataFormItem(19, props.txn);
      props.loadDataFormItem(17, props.txn);
      props.loadDataFormItem(34, props.txn);
    }
  }, [props.hn, props.txn]);

  useEffect(() => {
    setOldData(props.dataFormOlds);
  }, [props.dataFormOlds]);

  useEffect(() => {
    if (dataForm?.data_1) {
      setDataStrokeDate(dataForm?.data_1);
    } else {
      setDataStrokeDate("");
    }
  }, [dataForm?.data_1]);

  async function getJsonList(search) {
    try {
      var result = await Api.jsonList("diag", search);
      if (result) {
        var data = result.map((item) => item.label);
        data = data.filter(
          (value, index, array) => array.indexOf(value) == index
        );
        setClinicalDiags(data);
      } else {
        setClinicalDiags([]);
      }
    } catch (error) {
      setClinicalDiags([]);
      console.log(error);
    }
  }

  //#region Form Function
  function handleChange_data4(i, event) {
    setVisibleData4(event.target.value);

    if (event.target.value == "1") {
      setData(6, "");
      setData(7, "");
      setData(8, "");
      setData(9, "");
    } else if (event.target.value == "2") {
      setData(5, "");
      setData(7, "");
      setData(8, "");
      setData(9, "");
    } else if (event.target.value == "3") {
      setData(5, "");
      setData(6, "");
      setData(8, "");
      setData(9, "");
    } else if (event.target.value == "4") {
      setData(5, "");
      setData(6, "");
      setData(7, "");
      setData(9, "");
    } else if (event.target.value == "5") {
      setData(5, "");
      setData(6, "");
      setData(7, "");
      setData(8, "");
    }

    handleChange(i, event);
  }

  function setVisibleData4(value) {
    setData41Disabled(true);
    setData42Disabled(true);
    setData43Disabled(true);
    setData44Disabled(true);
    setData45Disabled(true);

    if (value == "1") {
      setData41Disabled(false);
    } else if (value == "2") {
      setData42Disabled(false);
    } else if (value == "3") {
      setData43Disabled(false);
    } else if (value == "4") {
      setData44Disabled(false);
    } else if (value == "5") {
      setData45Disabled(false);
    }
  }

  function handleChange_data17(i, event) {
    if (
      event == "1" ||
      (typeof event.target != "undefined" && event.target.checked)
    ) {
      setData17Disabled(false);
    } else {
      setData17Disabled(true);
      // setData(18, "");
    }

    handleChange(i, event);
  }

  function handleChange_data19(i, event) {
    if (
      event == "1" ||
      (typeof event.target != "undefined" && event.target.checked)
    ) {
      setData19Disabled(false);
    } else {
      setData19Disabled(true);
      // setData(20, "");
    }

    handleChange(i, event);
  }

  function handleChange_data21(i, event) {
    if (
      event == "1" ||
      (typeof event.target != "undefined" && event.target.checked)
    ) {
      setData21Disabled(false);
    } else {
      setData21Disabled(true);
      // setData(22, "");
    }

    handleChange(i, event);
  }

  function handleChange_data23(i, event) {
    if (
      event == "1" ||
      (typeof event.target != "undefined" && event.target.checked)
    ) {
      setData23Disabled(false);
    } else {
      setData23Disabled(true);
      // setData(24, "");
    }

    handleChange(i, event);
  }

  function handleChange_data1(i, event) {
    var dateForm = event;
    setDataStrokeDate(dateForm);

    var dateStroke = dateFormat(new Date(event), "yyyy-mm-dd");

    setStrokeOnSet(dateStroke);

    if (typeof dataForm.data_11 != "") {
      calCulateStrokeDate(dateStroke, dataForm.data_11);
    }

    handleChange(i, event);
  }

  // useEffect(() => {
  //   console.log("data_10", dataForm.data_10);
  //   if (dataForm.data_10) {
  //     const date = new Date();
  //     date.setDate(date.getDate() + dataForm.data_10);
  //     setData("11", dateFormat(date, "yyyy-mm-dd"));
  //   } else {
  //     setData("11", dateFormat(new Date(), "yyyy-mm-dd"));
  //   }
  // }, [dataForm.data_10]);

  // useEffect(() => {
  //   setData("11", dateFormat(new Date(), "yyyy-mm-dd"));
  // }, [dataForm.data_10 == "" || typeof dataForm.data_10 != 'undefined']);

  function handleChange_data10(i, event) {
    if (typeof event == "undefined") {
      event = i;
    }

    if (event?.target?.value) {
      const date = new Date();

      date.setDate(date.getDate() + parseInt(event.target.value));
      setData(11, dateFormat(date, "yyyy-mm-dd"));
      setData(10, event?.target?.value);
    } else if (typeof event == "string" && event) {
      const date = new Date();
      date.setDate(date.getDate() + parseInt(event));

      setData(11, dateFormat(date, "yyyy-mm-dd"));
      setData(10, event);
    } else {
      setData(10, "");
      setData(11, "");
    }
  }

  function handleChange_data11(i, event) {
    var dateForm = event;
    console.log("dataStrokeDate", dataStrokeDate);
    if (dataStrokeDate != "") {
      calCulateStrokeDate(dataStrokeDate, dateForm);
    }

    handleChange(i, event);
  }

  function calCulateStrokeDate(dateStroke, dateFrom) {
    const startDate = moment(dateStroke);
    const timeEnd = moment(dateFrom);
    const diff = timeEnd.diff(startDate);
    const diffDays = diff / 86400000;

    if (!isNaN(diffDays)) {
      setData(10, diffDays);
    }
  }

  function onClosePopup(data) {
    console.log("save data item");
    console.log(data);

    setShowFormPopup(false);
    props.addDataFormItem(data);

    if (typeof data != "undefined") {
      if (popupFormType == 19) {
        setData("12n", data.score.toString());
      } else if (popupFormType == 17) {
        setData("13n", data.score.toString());
      } else if (popupFormType == 34) {
        setData("14n", data.score.toString());
      }
    }
  }

  function doMRS() {
    var dataFormItem = props.getDataFormItem(19);
    setPopupDataForm(dataFormItem);
    setPopupFormType(19);
    setShowFormPopup(true);
  }

  function doBI() {
    var dataFormItem = props.getDataFormItem(17);
    setPopupDataForm(dataFormItem);
    setPopupFormType(17);
    setShowFormPopup(true);
  }

  function doPHQ9() {
    var dataFormItem = props.getDataFormItem(34);
    setPopupDataForm(dataFormItem);
    setPopupFormType(34);
    setShowFormPopup(true);
  }

  const onTagInputChange = useCallback((fieldId, data) => {
    setData(fieldId, data);
  });

  useEffect(() => {
    if (!dataForm?.data_11) {
      props.addValue({ data_11: dateFormat(new Date(), "yyyy-mm-dd") });
    }
  }, [dataForm]);

  return (
    <div
      uk-grid=""
      className="uk-grid"
      ref={parentTableContainer}
      style={{ overflowX: "hidden" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Ischemic Stroke F/U form" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <div
            className="dietz-display-inline-container"
            style={{ columnGap: "30px" }}
          >
            <div className="dietz-input-container" style={{ gap: "10px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                <FormElementLabel label="Stroke onset" />
              </span>
              <div style={{ marginTop: "-8px" }}>
                <FormElementTextBox
                  fieldId={"1"}
                  value={dataForm.data_1}
                  type="date"
                  maxDate={currentDate}
                  format="DD/MM/YYYY"
                  label=""
                  onChange={handleChange_data1}
                  width="100"
                />
              </div>
            </div>
            <div className="dietz-input-container" style={{ gap: "10px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                <FormElementLabel label="rtPA:" />
              </span>
              <div
                className="dietz-display-inline-container"
                style={{ gap: "0px" }}
              >
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"2"}
                  value={dataForm.data_2}
                  fieldValue={"Y"}
                  onChange={handleChange}
                  label="Yes"
                />
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"2"}
                  value={dataForm.data_2}
                  fieldValue={"N"}
                  onChange={handleChange}
                  label="No"
                />
              </div>
            </div>
            <div className="dietz-input-container" style={{ gap: "10px" }}>
              <span style={{ whiteSpace: "nowrap" }}>
                <FormElementLabel label="Mechanical thrombectomy:" />
              </span>
              <div
                className="dietz-display-inline-container"
                style={{ gap: "0px" }}
              >
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"3"}
                  value={dataForm.data_3}
                  fieldValue={"Y"}
                  onChange={handleChange}
                  label="Yes"
                />
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"3"}
                  value={dataForm.data_3}
                  fieldValue={"N"}
                  onChange={handleChange}
                  label="No"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1">
        <div className="uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <div className="dietz-input-container" style={{ gap: "10px" }}>
            <span style={{ whiteSpace: "nowrap" }}>
              <FormElementLabel label="Diagnosis:" />
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="uk-width-1-1">
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"1"}
                  onChange={handleChange_data4}
                  label=" Large artery atherosclerosis"
                />
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"2"}
                  onChange={handleChange_data4}
                  label="Cardioembolic stroke"
                />
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"3"}
                  onChange={handleChange_data4}
                  label=" Lacunar stroke"
                />
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"4"}
                  onChange={handleChange_data4}
                  label=" Stroke of other determined etiology"
                />
                <FormElementRadio
                  magicRadio={true}
                  padding="0px 10px 0px 0px"
                  fieldId={"4"}
                  value={dataForm.data_4}
                  fieldValue={"5"}
                  onChange={handleChange_data4}
                  label=" Stroke of undetermined etiology"
                />
              </div>
              <div className="uk-width-1-1" hidden={data41Disabled}>
                <FormElementTextArea
                  fieldId={"5"}
                  rows={2}
                  value={dataForm.data_5}
                  onChange={handleChange}
                  cols={100}
                />
              </div>
              <div className="uk-width-1-1" hidden={data42Disabled}>
                <FormElementTextArea
                  fieldId={"6"}
                  rows={2}
                  value={dataForm.data_6}
                  onChange={handleChange}
                  cols={100}
                />
              </div>
              <div className="uk-width-1-1" hidden={data43Disabled}>
                <FormElementTextArea
                  fieldId={"7"}
                  rows={2}
                  value={dataForm.data_7}
                  onChange={handleChange}
                  cols={100}
                />
              </div>
              <div className="uk-width-1-1" hidden={data44Disabled}>
                <FormElementTextArea
                  fieldId={"8"}
                  rows={2}
                  value={dataForm.data_8}
                  onChange={handleChange}
                  cols={100}
                />
              </div>
              <div className="uk-width-1-1" hidden={data45Disabled}>
                <FormElementTextArea
                  fieldId={"9"}
                  rows={2}
                  value={dataForm.data_9}
                  onChange={handleChange}
                  cols={100}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <div className="dietz-input-container" style={{ gap: "10px" }}>
            <span style={{ whiteSpace: "nowrap" }}>
              <FormElementLabel label="Clinical Diagnosis: " />
            </span>
            {/* <div>
              <FormElementTagsInput
                fieldId={"25"}
                value={dataForm.data_25}
                onChange={handleChange}
                txn={txn}
                titleSelect="Choose Diag"
                subType="diag"
              />
            </div> */}
            <div>
              <FormElementTagInput
                value={
                  dataForm.data_26 ? dataForm.data_26?.map((item) => item) : []
                }
                dataList={clinicalDiags}
                onChange={(data) => {
                  onTagInputChange("26", data);
                  setClinicalDiags([]);
                }}
                placeholder="ระบุ"
                onInputChange={(val) => {
                  getJsonList(val);
                }}
                isPreSelect
              />
            </div>
          </div>
        </div>
      </div>
      {oldData.length > 0 ? (
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div>
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>
                <tr>
                  <th rowSpan={2}>ครั้งที่</th>
                  <th rowSpan={2}>F/U</th>
                  <th colSpan={3}>Evaluation</th>
                </tr>
                <tr>
                  <th>MRS</th>
                  <th>BI</th>
                  <th>PHQ9</th>
                </tr>
              </thead>
              <tbody>
                {oldData.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <FormElementTextBox
                          type="numeric"
                          // disableKey={true}
                          value={item.data_10}
                          label={`F/U (`}
                          maxLength={4}
                          width="100"
                          withDropdown
                          dataList={[
                            { value: "1", label: "1" },
                            { value: "2", label: "2" },
                            { value: "3", label: "3" },
                            { value: "30", label: "1 month" },
                            { value: "60", label: "2 months" },
                            { value: "90", label: "3 months" },
                            { value: "120", label: "4 months" },
                            { value: "150", label: "5 months" },
                            { value: "180", label: "6 months" },
                            { value: "210", label: "7 months" },
                            { value: "240", label: "8 months" },
                            { value: "270", label: "9 months" },
                            { value: "300", label: "10 months" },
                            { value: "330", label: "11 months" },
                            { value: "365", label: "1 year" },
                          ]}
                          disabled={true}
                        />
                        <FormElementLabel label=" day)" />
                        &nbsp;
                        <FormElementTextBox
                          value={item.data_11}
                          type="date"
                          minDate={strokeOnSet}
                          format="DD/MM/YYYY"
                          onChange={handleChange}
                          width="100"
                          disabled={true}
                        />
                      </td>
                      <td>{item.data_12n}</td>
                      <td>{item.data_13n}</td>
                      <td>{item.data_14n}</td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          style={{
            display: "inline-flex",
            gap: "16px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormElementTextBox
            fieldId={"10"}
            type="numeric"
            value={dataForm.data_10}
            label={`ครั้งที่ ${oldData.length + 1} F/U (`}
            maxLength={4}
            width="100"
            withDropdown
            dataList={[
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "30", label: "1 month" },
              { value: "60", label: "2 months" },
              { value: "90", label: "3 months" },
              { value: "120", label: "4 months" },
              { value: "150", label: "5 months" },
              { value: "180", label: "6 months" },
              { value: "210", label: "7 months" },
              { value: "240", label: "8 months" },
              { value: "270", label: "9 months" },
              { value: "300", label: "10 months" },
              { value: "330", label: "11 months" },
              { value: "365", label: "1 year" },
            ]}
            onChange={handleChange_data10}
            onSelect={handleChange_data10}
          />
          <FormElementLabel label=" day)" />
          <FormElementTextBox
            fieldId={"11"}
            value={dataForm.data_11}
            type="date"
            minDate={strokeOnSet}
            format="DD/MM/YYYY"
            onChange={handleChange_data11}
            width="100"
          />
        </div>
      </div>
      <div className="uk-width-1-1">
        <div uk-grid="" className="uk-grid-small uk-padding-small">
          <FormElementLabel label="Evaluation " />
          <FormElementTextBox
            fieldId={"12n"}
            type="numeric"
            disableKey={true}
            value={dataForm.data_12n}
            label="MRS"
            onChange={handleChange}
            width="100"
            disabled
          />
          <FormElementButton label="MRS" onClick={doMRS} />
          <FormElementTextBox
            fieldId={"13n"}
            type="numeric"
            disableKey={true}
            value={dataForm.data_13n}
            label="BI"
            onChange={handleChange}
            width="100"
            disabled
          />
          <FormElementButton label="BI" onClick={doBI} />
          <FormElementTextBox
            fieldId={"14n"}
            type="numeric"
            disableKey={true}
            value={dataForm.data_14n}
            label="PHQ9"
            onChange={handleChange}
            width="100"
            disabled
          />
          <FormElementButton label="PHQ9" onClick={doPHQ9} />
        </div>
      </div>

      <div className="uk-width-2-3">
        <div className="uk-grid">
          <div className="uk-width-1-5">
            <FormElementLabel label="Feeding" />
          </div>
          <div className="uk-width-1-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"15"}
              value={dataForm.data_15}
              fieldValue={"1"}
              onChange={handleChange}
              label=" Oral"
            />
          </div>
          <div className="uk-width-3-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"15"}
              value={dataForm.data_15}
              fieldValue={"2"}
              onChange={handleChange}
              label=" Tube"
            />
          </div>
        </div>
      </div>
      <div className="uk-width-2-3">
        <div className="uk-grid">
          <div className="uk-width-1-5">
            <FormElementLabel label="Voiding" />
          </div>
          <div className="uk-width-1-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"16"}
              value={dataForm.data_16}
              fieldValue={""}
              onChange={handleChange}
              label=" Normal"
            />
          </div>
          <div className="uk-width-3-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"16"}
              value={dataForm.data_16}
              fieldValue={"2"}
              onChange={handleChange}
              label=" Catheter"
            />
          </div>
        </div>
      </div>
      <div className="uk-width-2-3">
        <div className="uk-grid">
          <div className="uk-width-1-5">
            <FormElementLabel label="Risk Factor" />
          </div>
          <div className="uk-width-1-5">
            <FormElementCheckBox
              fieldId={"17"}
              value={dataForm.data_17}
              onChange={handleChange_data17}
              label=" HTN"
            />
          </div>
        </div>
        <div className="uk-grid" hidden={dataForm.data_17 != '1'}>
          <div className="uk-width-1-5"></div>
          <div className="uk-width-1-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"18"}
              value={dataForm.data_18}
              fieldValue={"1"}
              onChange={handleChange}
              label=" Controlled"
              disabled={data17Disabled}
            />
          </div>
          <div className="uk-width-2-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"18"}
              value={dataForm.data_18}
              fieldValue={"2"}
              onChange={handleChange}
              label=" Uncontrolled"
              disabled={data17Disabled}
            />
          </div>
        </div>
      </div>
      <div className="uk-width-2-3">
        <div className="uk-grid">
          <div className="uk-width-1-5"></div>
          <div className="uk-width-1-5">
            <FormElementCheckBox
              fieldId={"19"}
              value={dataForm.data_19}
              onChange={handleChange_data19}
              label=" DM"
            />
          </div>
        </div>
        <div className="uk-grid" hidden={dataForm.data_19 != '1'}>
          <div className="uk-width-1-5"></div>
          <div className="uk-width-1-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"20"}
              value={dataForm.data_20}
              fieldValue={"1"}
              onChange={handleChange}
              label=" Controlled"
              disabled={data19Disabled}
            />
          </div>
          <div className="uk-width-2-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"20"}
              value={dataForm.data_20}
              fieldValue={"2"}
              onChange={handleChange}
              label=" Uncontrolled"
              disabled={data19Disabled}
            />
          </div>
        </div>
      </div>
      <div className="uk-width-2-3">
        <div className="uk-grid">
          <div className="uk-width-1-5"></div>
          <div className="uk-width-1-5">
            <FormElementCheckBox
              fieldId={"21"}
              value={dataForm.data_21}
              onChange={handleChange_data21}
              label=" AF"
            />
          </div>
        </div>
        <div className="uk-grid" hidden={dataForm.data_21 != '1'}>
          <div className="uk-width-1-5"></div>
          <div className="uk-width-1-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"22"}
              value={dataForm.data_22}
              fieldValue={"1"}
              onChange={handleChange}
              label=" Controlled"
              disabled={data21Disabled}
            />
          </div>
          <div className="uk-width-2-5">
            <FormElementRadio
              magicRadio={true}
              fieldId={"22"}
              value={dataForm.data_22}
              fieldValue={"2"}
              onChange={handleChange}
              label=" Uncontrolled"
              disabled={data21Disabled}
            />
          </div>
        </div>
      </div>
      <div className="uk-width-2-3">
        <div className="uk-grid ">
          <div className="uk-width-1-5"></div>
          <div className="uk-width-1-5">
            <FormElementCheckBox
              fieldId={"23"}
              value={dataForm.data_23}
              fieldValue={"3"}
              onChange={handleChange_data23}
              label=" Others"
            />
          </div>
        </div>
        <div className="uk-grid" hidden={dataForm.data_23 != '1'}>
          <div className="uk-width-1-5"></div>
          <div className="uk-width-1-1" style={{ marginLeft: "160px" }}>
            <FormElementTextArea
              fieldId={"24"}
              value={dataForm.data_24}
              label=""
              onChange={handleChange}
              width="100"
              noLimit
              cols={60}
              disabled={data23Disabled}
            />
          </div>
        </div>
      </div>
      <PopupForm
        hn={hn}
        txn={txn}
        show={showFormPopup}
        formType={popupFormType}
        dataForm={popupDataForm}
        onClose={onClosePopup}
        formId={props.formId || dataForm.form_id}
      ></PopupForm>
    </div>
  );
};

export default FormStroke;

FormStroke.defaultProps = {
  myRef: { current: {} },
};
