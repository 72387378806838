import React, {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useCallback,
} from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import { getDate, getMonth, getYear } from "date-fns";
import dateFormat from "dateformat";

import { FiCalendar } from "react-icons/fi";
import ReactInputMask from "react-input-mask";

const years = Array.from(
  { length: 50 },
  (_, i) => new Date().getFullYear() - i + 543
)
  .reverse()
  .concat(
    Array.from(
      { length: 49 },
      (_, i) => new Date().getFullYear() + (i + 1) + 543
    )
  );
const LIST_OF_MONTH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const FormElementDatePicker = (props) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (props.value) {
      setDate(new Date(props.value));
    } else {
      setDate(null);
    }
  }, [props]);

  const onDatePickerChange = useCallback((date) => {
    if (props.withTime) {
      props.onChange(dateFormat(date, "yyyy-mm-dd HH:MM:ss"));
    } else {
      props.onChange(dateFormat(date, "yyyy-mm-dd"));
    }
  });

  const renderCustomHeader = useCallback(
    ({ date, decreaseMonth, increaseMonth, changeYear }) => (
      <div style={{ width: "100%" }}>
        <button
          className="nav-month-button previous"
          style={{ position: "absolute", left: "0px" }}
          onClick={decreaseMonth}
        >
          <span uk-icon="icon: triangle-left; ratio: 1.0" />
        </button>
        <div
          style={{
            display: "inline-flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <strong className="header-label">
            {LIST_OF_MONTH[getMonth(date)]}
          </strong>
          <select
            value={getYear(date) + 543}
            onChange={({ target: { value } }) => changeYear(value - 543)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <button
          className="nav-month-button next"
          onClick={increaseMonth}
          style={{ position: "absolute", right: "0px" }}
        >
          <span uk-icon="icon: triangle-right; ratio: 1.0" />
        </button>
      </div>
    )
  );

  return (
    <DatePicker
      wrapperClassName="date-picker-custom"
      dateFormat={props.withTime ? "dd/MM/yyyy, HH:mm" : "dd/MM/yyyy"}
      placeholderText={props.placeholder}
      timeFormat="HH:mm"
      selected={date}
      locale="th"
      onChange={(date) => onDatePickerChange(date)}
      disabled={props.disabled}
      className="date-picker"
      customInput={<DateCustomInput />}
      timeInputLabel="เวลา:"
      renderCustomHeader={renderCustomHeader}
      customTimeInput={<TimeCustomInput />}
      showTimeInput={props.withTime}
    />
  );
};

const DateCustomInput = forwardRef(({ value, onClick }, ref) => {
  const renderDate = useCallback((value) => {
    let thYear = value.substring(6, 10);
    let enYear = parseInt(thYear) + 543;
    return value.replace(thYear, enYear);
  });

  return (
    <div style={{ width: "fit-content" }}>
      <button
        style={{ minWidth: "60px" }}
        className="uk-button uk-button-primary uk-width-1-1"
        onClick={onClick}
        ref={ref}
      >
        <span style={{ whiteSpace: "nowrap", width: "fit-content" }}>
          {value != null && value != "" ? renderDate(value) : <FiCalendar />}
        </span>
      </button>
    </div>
  );
});

const TimeCustomInput = ({ date, value, onChange }) => {
  const [inputTime, setInputTime] = useState("");

  useEffect(() => {
    if (value) {
      setInputTime(value);
    } else {
      setInputTime("00:00");
    }
  }, [value]);

  const onTimeChange = useCallback((e) => {
    setInputTime(e.target.value);
  });

  const onTimeFocus = useCallback(() => {
    setInputTime(inputTime.replace(/:/g, ""));
  });

  const onTimeBlur = useCallback(() => {
    if (!inputTime.includes("_")) {
      const hh = inputTime.substring(0, 2);
      const mm = inputTime.substring(3, 5);
      if (parseInt(hh) < 24 && parseInt(mm) < 60) {
        setInputTime(`${hh}:${mm}`);
        onChange(`${hh}:${mm}`);
      } else {
        setInputTime("00:00");
        onChange("00:00");
      }
    } else {
      setInputTime(value);
      onChange(value);
    }
  });

  return (
    <ReactInputMask
      size={5}
      value={inputTime}
      onChange={onTimeChange}
      onFocus={onTimeFocus}
      mask={"99:99"}
      onBlur={onTimeBlur}
      style={{ border: "solid 1px pink" }}
    />
  );
};

export default FormElementDatePicker;
