import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import FormTelePharmacy from '../../Components/Consult/FormList/FormTelePharmacy';
import MemberProfileFormHeader from "../../Components/Consult/MemberProfileFormHeader";
import FormLoading from '../../Components/Consult/Common/FormLoading';
import FormSuccessPopup from '../../Components/Consult/Common/FormSuccessPopup';
import FormErrorPopup from '../../Components/Consult/Common/FormErrorPopup';
import FormWafarin from '../../Components/Consult/FormList/FormWafarin';
import FormMemberProfileHeader from '../../Components/Consult/Common/FormMemberProfiileHeader';
import FormNoac from '../../Components/Consult/FormList/FormNoac';
import FormInhaler from '../../Components/Consult/FormList/FormInhaler';
import FormInsurine from '../../Components/Consult/FormList/FormInsurine';
import FormBispho from '../../Components/Consult/FormList/FormBispho';
import FormDeliveryGeneralMedicine from '../../Components/Consult/FormList/FormDeliveryGeneralMedicine';
import FormDeliveryDrugMedicine from '../../Components/Consult/FormList/FormDeliveryDrugMedicine';
import FormDeliveryWafarineMedicine from '../../Components/Consult/FormList/FormDeliveryWafarineMedicine';
import FormFammed from '../../Components/Consult/FormList/FormFammed';
import FormPPS from '../../Components/Consult/FormList/FormPPS';
import FormESAS from '../../Components/Consult/FormList/FormESAS';
import FormPosScale from '../../Components/Consult/FormList/FormPosScale';
import FormGoodDeath from '../../Components/Consult/FormList/FormGoodDeath';
import FormTMSE from '../../Components/Consult/FormList/FormTMSE';
import FormADLS from '../../Components/Consult/FormList/FormADLS';
import FormIADLS from '../../Components/Consult/FormList/FormIADLS';
import FormMoca from '../../Components/Consult/FormList/FormMoca';
import Form2Q from '../../Components/Consult/FormList/Form2Q';
import Form9Q from '../../Components/Consult/FormList/Form9Q';
import Form8Q from '../../Components/Consult/FormList/Form8Q';
import FormTGDS from '../../Components/Consult/FormList/FormTGDS';
import FormGAD7 from '../../Components/Consult/FormList/FormGAD7';
import FormZarit12 from '../../Components/Consult/FormList/FormZarit12';
import FormDrugAdherence from '../../Components/Consult/FormList/FormDrugAdherence';
import FormFearFalling from '../../Components/Consult/FormList/FormFearFalling';
import FormPostFalling from '../../Components/Consult/FormList/FormPostFalling';
import FormConsultation from '../../Components/Consult/FormList/FormConsultation';
import FormStroke from '../../Components/Consult/FormList/FormStroke';
import FormADL from '../../Components/Consult/FormList/FormADL';
import FormRankinScale from '../../Components/Consult/FormList/FormRankinScale';
import FormHeartFailure from '../../Components/Consult/FormList/FormHeartFailure';
import FormHeartFailureOPD from '../../Components/Consult/FormList/FormHeartFailureOPD';
import FormHeartFailureDischargeCheckList from '../../Components/Consult/FormList/FormHeartFailureDischargeCheckList';
import FormHeartFailureClinic from '../../Components/Consult/FormList/FormHeartFailureClinic';
import FormMinnesotaLivingWithHeartFailure from '../../Components/Consult/FormList/FormMinnesotaLivingWithHeartFailure';
import FormNursingAssessment from '../../Components/Consult/FormList/FormNursingAssessment';
import FormACSCaringSet from '../../Components/Consult/FormList/FormACSCaringSet';
import FormACSTeleFollowUp from '../../Components/Consult/FormList/FormACSTeleFollowUp';
import FormHeartRepair from '../../Components/Consult/FormList/FormHeartRepair';
import FormCathLabDischarge from '../../Components/Consult/FormList/FormCathLabDischarge';
import FormCathLabAssessment from '../../Components/Consult/FormList/FormCathLabAssessment';
import FormHomeVisit from '../../Components/Consult/FormList/FormHomeVisit';

const Form = (props) => {
    const history = useHistory();

    const query = new URLSearchParams(window.location.search);
    const formId = query.get('formId');
    const hn = query.get('hn');
    const txn = query.get('txn');

    const { path } = useRouteMatch();
    const [patient, setPatient] = useState({});
    const [profile, setProfile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState({ open: false, header: '', description: '', isClose: false, isGoToMainPage: false });

    useEffect(() => {
        const profileTxt = localStorage.getItem("consultProfileData");
        const profileData = JSON.parse(profileTxt);
        setProfile(profileData);
    }, [setProfile]);

    const handlePatientSelect = useCallback((data) => {
        setPatient((temp) => ({ ...temp, ...data }));
    }, [setPatient]);

    const handleLoadingChange = useCallback((loading) => {
        setIsLoading(loading);
    }, [setIsLoading])

    const handleSuccessPopupOpenChange = useCallback((open) => {
        setIsSuccessPopupOpen(open)
    }, [setIsLoading])

    const goToMainPage = useCallback(() => {
        history.push('/consult/formList');
    }, [history]);

    const handlePopupClose = useCallback(() => {
        setIsSuccessPopupOpen(false);
        goToMainPage();
    }, [setIsSuccessPopupOpen, goToMainPage])

    const handleErrorMessageChange = useCallback((message) => {
        setErrorMessage(message);
    }, [setErrorMessage])

    const getProps = useMemo(() => {
        return {
            patient: patient,
            profile: profile,
            onLoading: handleLoadingChange,
            setPatient: handlePatientSelect,
            formId: formId,
            onSuccessPopupOpen: handleSuccessPopupOpenChange,
            onErrorMessageChange: handleErrorMessageChange,
            hn: hn,
            txn: txn,
        }
    }, [patient, profile, handleLoadingChange, handlePatientSelect, formId, handleSuccessPopupOpenChange, handleErrorMessageChange, hn, txn]);

    const renderRoutes = useMemo(() => {
        return <Switch>
            <Route path={`${path}/telepharmacy`} render={() => <FormTelePharmacy {...getProps} />} />
            <Route path={`${path}/wafarin`} render={() => <FormWafarin {...getProps} />} />
            <Route path={`${path}/noac`} render={() => <FormNoac {...getProps} />} />
            <Route path={`${path}/inhaler`} render={() => <FormInhaler {...getProps} />} />
            <Route path={`${path}/insurin`} render={() => <FormInsurine {...getProps} />} />
            <Route path={`${path}/bispho`} render={() => <FormBispho {...getProps} />} />
            <Route path={`${path}/deliveryGeneralMedicine`} render={() => <FormDeliveryGeneralMedicine {...getProps} />} />
            <Route path={`${path}/deliveryDrugMedicine`} render={() => <FormDeliveryDrugMedicine {...getProps} />} />
            <Route path={`${path}/deliveryWafarinMedicine`} render={() => <FormDeliveryWafarineMedicine {...getProps} />} />
            <Route path={`${path}/fammed`} render={() => <FormFammed {...getProps} />} />
            <Route path={`${path}/pps`} render={() => <FormPPS {...getProps} />} />
            <Route path={`${path}/esas`} render={() => <FormESAS {...getProps} />} />
            <Route path={`${path}/posScale`} render={() => <FormPosScale {...getProps} />} />
            <Route path={`${path}/goodDeath`} render={() => <FormGoodDeath {...getProps} />} />
            <Route path={`${path}/tmse`} render={() => <FormTMSE {...getProps} />} />
            <Route path={`${path}/adls`} render={() => <FormADLS {...getProps} />} />
            <Route path={`${path}/iadls`} render={() => <FormIADLS {...getProps} />} />
            <Route path={`${path}/moca`} render={() => <FormMoca {...getProps} />} />
            <Route path={`${path}/2q`} render={() => <Form2Q {...getProps} />} />
            <Route path={`${path}/9q`} render={() => <Form9Q {...getProps} />} />
            <Route path={`${path}/8q`} render={() => <Form8Q {...getProps} />} />
            <Route path={`${path}/tgds`} render={() => <FormTGDS {...getProps} />} />
            <Route path={`${path}/gad7`} render={() => <FormGAD7 {...getProps} />} />
            <Route path={`${path}/zarit12`} render={() => <FormZarit12 {...getProps} />} />
            <Route path={`${path}/drugAdherence`} render={() => <FormDrugAdherence {...getProps} />} />
            <Route path={`${path}/fearFalling`} render={() => <FormFearFalling {...getProps} />} />
            <Route path={`${path}/postFalling`} render={() => <FormPostFalling {...getProps} />} />
            <Route path={`${path}/consultation`} render={() => <FormConsultation {...getProps} />} />
            <Route path={`${path}/stroke`} render={() => <FormStroke {...getProps} />} />
            <Route path={`${path}/adl`} render={() => <FormADL {...getProps} />} />
            <Route path={`${path}/rankleScale`} render={() => <FormRankinScale {...getProps} />} />
            <Route path={`${path}/heartFailure`} render={() => <FormHeartFailure {...getProps} />} />
            <Route path={`${path}/heartFailureOpd`} render={() => <FormHeartFailureOPD {...getProps} />} />
            <Route path={`${path}/heartFailureDischargeChecklist`} render={() => <FormHeartFailureDischargeCheckList {...getProps} />} />
            <Route path={`${path}/heartFailureClinic`} render={() => <FormHeartFailureClinic {...getProps} />} />
            <Route path={`${path}/minnesotaLivingWithHeartFailure`} render={() => <FormMinnesotaLivingWithHeartFailure {...getProps} />} />
            <Route path={`${path}/nursingAssesment`} render={() => <FormNursingAssessment {...getProps} />} />
            <Route path={`${path}/acsCaringSet`} render={() => <FormACSCaringSet {...getProps} />} />
            <Route path={`${path}/acsTeleFollowUp`} render={() => <FormACSTeleFollowUp {...getProps} />} />
            <Route path={`${path}/heartRepair`} render={() => <FormHeartRepair {...getProps} />} />
            <Route path={`${path}/cathLabDischarge`} render={() => <FormCathLabDischarge {...getProps} />} />
            <Route path={`${path}/cathLabAssessment`} render={() => <FormCathLabAssessment {...getProps} />} />
            <Route path={`${path}/homeVisit`} render={() => <FormHomeVisit {...getProps} />} />
            <Redirect to='/consult/error' />
        </Switch>
    }, [path, getProps]);

    return (
        <>
            <div className="uk-padding">
                {isLoading && <FormLoading />}
                <div className="uk-card uk-card-default">
                    <div uk-grid="" className="uk-grid-small uk-padding-small">
                        <div className="uk-width-1-1@m uk-width-1-1">
                            <FormMemberProfileHeader
                                memberData={patient}
                                onSelectMember={handlePatientSelect}
                                isSelectDisabled={Boolean(formId) || Boolean(hn && txn)}
                            />
                        </div>
                        {renderRoutes}
                    </div>
                </div>
            </div>
            {isSuccessPopupOpen && <FormSuccessPopup onClose={handlePopupClose} />}
            {errorMessage.open &&
                <FormErrorPopup
                    message={errorMessage}
                    onClose={() => handleErrorMessageChange({ open: false })}
                    onGoToMainPage={() => { handleErrorMessageChange({ open: false }); goToMainPage(); }} />}
        </>


        // <FormPaper type={formType} hn={hn} formName={formName} txn={txn} formId={formId} isPopup={false} />
    )
}

export default Form;