import React, { useState, useEffect, useMemo } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';

var utilHandleChange = require('./FormUtil.js').handleChange;
var utilSetData = require('./FormUtil.js').setData;
var useEffectDataForm = require('./FormUtil.js').useEffectDataForm;
var useEffectPropsDataForm = require('./FormUtil.js').useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormRankinScale = (props) => {
    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    const [score, setScore] = useState(0);
    const [scoreGroup1, setScoreGroup1] = useState({});
    
    function handleChange(i, event) {
		utilHandleChange(i, event, setIsEditForm, setDataForm);
	}

	function setData(field, valueData) {
		utilSetData(field, valueData, setIsEditForm, setDataForm);
	}

    useEffect(() => {
        props.addValue({
            formName: "form_rankinscale",
            formType: "19"
        });

    }, []);

    useEffect(() => {
		useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);

    }, [dataForm]);
    

	useEffect(() => {
		useEffectPropsDataForm(setDataForm, props.dataForm);
        
    }, [props.dataForm]);

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1,
        ]);
    }, [dataForm]);

    useEffect(() => {
        console.log(dataForm);
        props.setScore(getTotalScore);
    }, [dataForm])
    

    return (
        <div uk-grid="" className="uk-grid uk-padding-small">
            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementTitle label="แบบประเมินความพิการผู้ป่วยโรคหลอดเลือดสมอง (Modified Rankin Scale)" />
                <FormElementLabel color={"red"} label="( คะแนนรวม : " fontWeight="bold" />
                <FormElementLabel color={"red"} label={getTotalScore} />
                <FormElementLabel color={"red"} label=") " fontWeight="bold" />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                <thead>
                    <tr>
                        <td>คะแนน</td>
                        <td>ความพิการ</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td ><FormElementRadio  label="0" fieldId={"1"} value={dataForm.data_1} fieldValue={"0"} onChange={handleChange} /></td>
                        <td >ไม่มีความผิดปกติเลย (full recovery)</td>
                    </tr>
                    <tr>
                        <td ><FormElementRadio  label="1" fieldId={"1"} value={dataForm.data_1} fieldValue={"1"} onChange={handleChange} /></td>
                        <td >ไม่มีความผิดปกติที่รุนแรง สามารถประกอบกิจวัตรประจำวันได้ทุกอย่าง ทำงานอาชีพได้</td>
                    </tr>
                    <tr>
                        <td ><FormElementRadio  label="2" fieldId={"1"} value={dataForm.data_1} fieldValue={"2"} onChange={handleChange} /></td>
                        <td >มีความผิดปกติเล็กน้อย สามารถทำกิจวัตร ประจำวันได้ทุกอย่าง แต่ทำงานอาชีพไม่ได้</td>
                    </tr>
                    <tr>
                        <td ><FormElementRadio  label="3" fieldId={"1"} value={dataForm.data_1} fieldValue={"3"} onChange={handleChange} /></td>
                        <td >มีความผิดปกติพอควร ทำกิจวัตรประจำวันได้บางอย่าง สามารถเดินได้โดยไม่ต้องมีคนช่วย</td>
                    </tr>
                    <tr>
                        <td ><FormElementRadio  label="4" fieldId={"1"} value={dataForm.data_1} fieldValue={"4"} onChange={handleChange} /></td>
                        <td >มีความผิดปกติมาก ไม่สามารถทำกิจวัตรประจำวันเองโดยไม่มีคนช่วยได้ สามารถเดินได้แต่ต้องมีคนพยุง</td>
                    </tr>
                    <tr>
                        <td ><FormElementRadio  label="5" fieldId={"1"} value={dataForm.data_1} fieldValue={"5"} onChange={handleChange} /></td>
                        <td >มีความผิดปกติอย่างรุนแรง ต้องนอนบนเตียง ปัสสาวะราด ต้องการการดูแลอย่างใกล้ชิด (bed ridden)</td>
                    </tr>
                    <tr>
                        <td ><FormElementRadio  label="6" fieldId={"1"} value={dataForm.data_1} fieldValue={"6"} onChange={handleChange} /></td>
                        <td >ถึงแก่กรรม</td>
                    </tr>
                    
                </tbody>
                </table>
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementLabel color={"red"} label=" คะแนนรวม : " fontWeight="bold" />
                <FormElementLabel color={"red"} label={getTotalScore} />
                <FormElementLabel color={"red"} label=" " fontWeight="bold" />
            </div>
        </div>
    )
  }
  
  export default FormRankinScale;
  

