import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Api from '../Api';
import { Link } from "react-router-dom";
import ImageUri from '../ImageUri';

UIkit.use(Icons);

export default class CampaignList extends Component {
  state = {
    list: []
  }

  componentDidMount() {
    this.init();
  }

  init() {
    this.campaignListGet();
  }

  async campaignListGet() {
    var { list } = await Api.member('CampaignList');
    this.setState({ list: list || [] });
  }

  render() {
    return <div className="uk-margin-small-top">
      {
        this.state.list.length === 0 ?
          <div className="uk-text-center uk-padding uk-card uk-card-default">ไม่พบรายการแคมเปญที่เข้าร่วม</div>
          :
          <div className="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@l" uk-grid="masonry: true">
            {
              this.state.list.map((data, key) => {
                return <div key={`campaignLink${data.id}`}>
                  <a className="uk-link-reset uk-display-block uk-margin-bottom" href={`/campaign-report?id=${data.id}`}>
                  <div className="uk-card uk-card-default uk-padding-small">
                    {data.image !== '' && <img src={ImageUri(data.image)} uk-img="" />}
                    <div className="uk-padding-small uk-text-center">{data.name}</div>
                  </div>
                </a>
                </div>
              })
            }
          </div>
      }
    </div>
  }
};
