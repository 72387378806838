import React, { useState, useEffect } from 'react';
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import DateTimePicker from '../DatePicker.js';
import '../../css/dietz.css';

UIkit.use(Icons);

const SlotSelector = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataListOrg, setDataListOrg] = useState([]);
  const [dataList, setDataList] = useState([]);
  const { show, clinicCode } = props;

  async function listGet() {
    var { list } = await Api.consult("SlotList", {
      appointmentDate : '2023-04-03',
      clinicCode : clinicCode,
    });

    if(list != null) {
      setDataList(list);
      setDataListOrg(list);

    } else {
      setDataList([]);
      setDataListOrg([]);

    }
    
    setLoadingApi(false);
  }

  function filter() {
    const element = document.getElementById('txtSearch');
    
    const searchText = element.value;

    var dataFiltered = null;

    if(searchText != "") {
      dataFiltered = dataListOrg.filter(data=> data.code.indexOf(searchText) !== -1 )

    } else {
      dataFiltered = dataListOrg;
    }

    setDataList(dataFiltered);

  }

  function doSelect(id) {
    var dataSearch = dataListOrg.filter(data=> data.slot_id == id )

    var slotData = dataSearch[0];
    slotData.appointmentDate = '2023-04-03';

    props.onSelect(slotData);

    props.onClose();
    UIkit.modal('#modal-select-slot').hide();
  }

  function onClose() {
    props.onClose();
  }

  function showPopup() {
    UIkit.modal('#modal-select-slot').show();
  }

  useEffect(() => {
    listGet();
    
  }, [clinicCode]);

  useEffect(() => {
    if (show) {
        UIkit.modal('#modal-select-slot').show();
    
    } else {
        UIkit.modal('#modal-select-slot').hide();
    }
    
  }, [ show ]);

  return (
    <div id="modal-select-slot" className="uk-modal" >
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default uk-close" type="button" onClick={onClose} ></button>
          <div className="uk-modal-header">
              <h2 className="uk-modal-title">เลือก Slot เภสัชกร</h2>
          </div>
          <div className="uk-modal-body">
            <div><DateTimePicker id="dateStart" /> </div>
            <div className="popupSelectScroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                <thead>
                  <tr>
                    <th>ที่</th>
                    <th>เวลาเริ่ม</th>
                    <th>เวลาสิ้นสุด</th>
                    <th>จำนวน</th>
                    <th>เลือก</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    loadingApi == true ?
                    <tr>
                      <td colSpan="100%">
                        <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                      </td>
                    </tr>
                    :
                    dataList.length === 0 ? 
                    <tr>
                      <td colSpan="100%">
                        <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                      </td>
                    </tr>
                    :
                    dataList.map((data, key)=>{
                      return <tr key={`row${data.slot_id}`}>
                        <td>{key+1}</td>
                        <td>
                          {data.start_time}
                        </td>
                        <td>
                          {data.end_time}
                        </td>
                        <td>
                        {data.reserved} / {data.slot}
                        </td>
                        <td>
                        <button onClick={() => doSelect(data.slot_id)}>เลือก</button>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-modal-footer uk-text-right">
              <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={onClose}>Cancel</button>
          </div>
      </div>
    </div>
  )
}

export default SlotSelector;
