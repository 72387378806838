import React, { useState, useEffect, useRef, useCallback } from 'react';

import Tags from "../../node_modules/bootstrap5-tags/tags";

const TagsInput = (props) => {
    var _id = 'tagsInput' + props.id;
    var _ide = 'tagsInput' + props.id + "_empty";
    var subType = props.subType;

    function tagsOnChange(e) {
        let value = Array.from(e.target.selectedOptions, option => convertDataToJson(option.value));
        props.onChange(JSON.stringify(value));
    }

    function convertDataToJson(data) {
        if(data.indexOf('{') == -1) {
            var dataTemp = {
                "label":data,
                "icd10":"",
                "id":""
            }

            return JSON.stringify(dataTemp);

        } else {
            return data;
        }
    }

    var options = {
        queryParam : "term"
    }

    var serverUrl = process.env.REACT_APP_SERVER_URL + "?type=Consult/JsonList&subType=" + subType;

    useEffect(() => {
        console.log(serverUrl);
    })

    Tags.init("#" + _id, options);

    useEffect(() => {
        var tag = Tags.getInstance(document.querySelectorAll("#" + _id)[0]);

        if(typeof(tag) != "undefined") {
            if (props.value != null) {
                let valueTxt = props.value;
                valueTxt = valueTxt.split('["').join('[')
                valueTxt = valueTxt.split('"]').join(']')
                valueTxt = valueTxt.split('}"').join('}')
                valueTxt = valueTxt.split('"{').join('{')
                valueTxt = valueTxt.split('\\"').join('"')

                let items = JSON.parse(valueTxt);
        
                for(const item of items) {
                    tag._add(item.label, JSON.stringify(item));
                }
            }
            
        }
        
    }, [props.value]);

    return (
        <div className="uk-display-inline" >
           <select className="form-select" 
                id={_id} 
                multiple 
                data-allow-new="true"  
                data-allow-clear="true"
                data-live-server="1"
                data-server={serverUrl}
                onChange={tagsOnChange}
                >
                <option disabled hidden value="">{ props.titleSelect }...</option>
            </select>
        </div>
    )
}


export default TagsInput;