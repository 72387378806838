import React, {Component} from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

export default class AdminSetting extends Component {
  state = {
    password: '',
    passwordNew: '',
  }

  passwordUpdate = async (e) => {
    e.preventDefault();

    var result = false;

    if(this.state.passwordNew.trim().length < 4) {
      responseText = 'โปรดใส่รหัสผ่านมากกว่า 4 ตัวอักษร';
    } else if(this.state.passwordNew.trim() !== this.state.password ) {
      responseText = 'รหัสผ่านทั้งสองครั้งไม่ตรงกัน';
    } else {
      var {result, responseText} = await Api.admin('AdminPasswordUpdate', {password: this.state.password, passwordNew: this.state.passwordNew});
    }


    UIkit.notification(`<span uk-icon='icon: ${result ? 'check': 'close'}'></span> ${responseText}`);

    if(result) window.location.reload();

  }

  passwordFormRender = () => {
    return <form onSubmit={this.passwordUpdate}>
      <input type="password" placeholder="รหัสผ่านใหม่" className="uk-input uk-margin-small-top" onChange={e=>this.setState({password: e.target.value})} />
      <input type="password" placeholder="ยืนยันรหัสผ่านใหม่" className="uk-input uk-margin-small-top" onChange={e=>this.setState({passwordNew: e.target.value})} />
      <div className="uk-text-right">
        <button className="uk-button uk-button-primary uk-margin-small-top" type="submit">บันทึก</button>
      </div>
    </form>
  }
  
  render() {
    return <div>
      <h2>เปลี่ยนรหัสผ่าน</h2>
      {this.passwordFormRender()}
    </div>
  }
};
