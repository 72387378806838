import Api from "../Api";

async function fetchConsultFormListByHn(hn, txn, type) {
    try {
        var result = await Api.consult("ConsultFormGetList", {
            formType: type,
            txn: txn,
            hn: hn,
            loadOld: "1",
        });
        var { list } = result;

        if (!list) return [];

        return list;
    } catch (error) {
        console.error(`fetchConsultFormListByTxn: hn(${hn}), txn(${txn}), type(${type})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchConsultFormListByHn;