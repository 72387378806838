import React, { useState, useEffect, useCallback, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";

import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const Form2QPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_2q",
      formType: "32",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([dataForm.data_1, dataForm.data_2]);
  }, [dataForm]);

  const getScoreDesc = useMemo(() => {
    if (dataForm.data_1 && dataForm.data_2) {
      if (getTotalScore > 0) {
        return "มีแนวโน้มที่จะเป็นโรคซึมเศร้า";
      } else {
        return "ปกติ ไม่เป็นโรคซึมเศร้า";
      }
    }
  }, [getTotalScore, dataForm.data_1, dataForm.data_2]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบคัดกรองภาวะซึมเศร้า 2 คำถาม (2Q)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <table
          style={{
            marginTop: "10px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                คำถาม
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  width: "40px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                มี
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  width: "40px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ไม่มี
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                1. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ ท่านรู้สึก หดหู่ เศร้า
                หรือท้อแท้สิ้นหวัง หรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "0" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                2. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ท่านรู้สึก เบื่อ
                ทำอะไรก็ไม่เพลิดเพลิน หรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2 == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_2 == "0" && "/"}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การแปลผล
              </td>
              <td
                colSpan={2}
                style={{
                  whiteSpace: "nowrap",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                {getScoreDesc}
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementTitle label="การแปลผล" mode="view" />
        <FormElementTitle
          label="ถ้าคำตอบ ไม่มี ทั้ง 2 คำถาม ถือ ว่า ปกติ ไม่เป็นโรคซึมเศร้า"
          mode="view"
        />
        <FormElementTitle
          label="ถ้าคำตอบ มี ข้อใดข้อหนึ่งหรือทั้ง 2 ข้อ (มีอาการใดๆ ในคำถามที่ 1 และ 2 ) หมายถึง “เป็นผู้มีความเสี่ยง” หรือ “มีแนวโน้มที่จะเป็นโรคซึมเศร้า” ให้ประเมินต่อด้วยแบบประเมิน โรคซึมเศร้า 9Q 2 คำถาม (2Q)"
          mode="view"
        />
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default Form2QPaper;
