import React, { useEffect,useState } from "react";
import Api from "./Api";
import moment from "moment";
import UIkit from "uikit";

function SendFiles(props) {
  const [showSendfile, setShowSentfile] = useState(true);

  const getFilesList = async () => {
    // console.log(dataForm.txn);
    const { list } = await Api.consult("FormTxnFilesList", {
      txn: props.dataForm.txn,
      form_id: props.dataForm.formType
    });
    //  console.log(list);
    if (list) {
      setShowSentfile(false);
    } else {
      setShowSentfile(true);
    }
  };
  
  useEffect(() => {
    getFilesList();
  }, [props]);

  const sendFiles = async () => {
    // console.log(dataForm)
    var formData = [];
    const resultFilesList = await Api.consult("FormFilesList", {
      form_id: props.dataForm.formType
    });
    // console.log(resultFilesList.list);
    const profileTxt = localStorage.getItem("consultProfileData");
    const profileData = JSON.parse(profileTxt);
    // console.log(profileData);
    resultFilesList.list.map((value, key) => {
      formData.push({
        form_id: props.dataForm.formType,
        txn: props.dataForm.txn,
        file_name: value.file_name,
        file_data: value.file_data,
        created_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        created_by: profileData.consultId,
        updated_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        updated_by: profileData.consultId,
      });
    });
    // console.log(formData)

    const resultTxnFilesAdd = await Api.consult("FormTxnFilesAdd", {
      data: formData,
    });
    // console.log(resultTxnFilesAdd.list);
    if (!resultTxnFilesAdd.result) {
      UIkit.notification({
        message: resultTxnFilesAdd.responseText,
        status: "danger",
        pos: "top-right",
        timeout: 5000,
      });
    } else {
      UIkit.notification({
        message: resultTxnFilesAdd.responseText,
        status: "success",
        pos: "top-right",
        timeout: 5000,
      });
    }
    props.getData()
    // props.setReload(!props.reload);
    setShowSentfile(false);
  };
  return (
    <>
      {showSendfile ? (
        <>
          <button
            className="uk-button uk-button-primary"
            style={{ height: "40px"}}
            onClick={() => sendFiles()}
          >
            ส่งไฟล์
          </button>
          &nbsp;&nbsp;
        </>
      ) : (
        <>
          <button
            className="uk-button uk-button-default"
            style={{ height: "40px" }}
            disabled
          >
            ส่งไฟล์
          </button>
          &nbsp;&nbsp;
        </>
      )}
    </>
  );
}

export default SendFiles;
