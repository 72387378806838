import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import HeaderSub from '../../Components/Member/HeaderSub';
import PeopleTable from '../../Components/Member/PeopleTable';

UIkit.use(Icons);

export default class People extends Component {
  state = {
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="Find people" />
      <div className="uk-margin-small-top">
        <PeopleTable />
      </div>
    </div>
  }
};
