import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

import "../../../css/dietz.css";
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const Form8QPaper = (props) => {
  const { dataForm, patient, profile } = props;

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
    ]);
  }, [dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินการฆ่าตัวตาย 8 คำถาม (8Q)"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <table
            style={{
              marginTop: "16px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <td
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>ลำดับคำถาม</strong>
                </td>
                <td
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>ระยะเวลา</strong>
                </td>
                <td
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>คำถาม</strong>
                </td>
                <td
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>ไม่มี</strong>
                </td>
                <td
                  style={{
                    background: "#EFF0F3",
                    width: "40px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>มี</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คิดอยากตาย หรือคิดว่าตายไปจะดีกว่า
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_1n == "1" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  อยากทำร้ายตัวเอง หรือ ทำให้ตัวเองบาดเจ็บ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_2n == "2" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ในช่วง 1 เดือนที่ผ่านมารวมวันนี้
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คิดเกี่ยวกับการฆ่าตัวตาย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_3n == "6" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  rowSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  rowSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ในช่วง 1 เดือนที่ผ่านมารวมวันนี้
                </td>
                <td
                  rowSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  (ถ้าตอบว่าคิดเกี่ยวกับฆ่าตัวตายให้ถามต่อ) .... ท่านสามารถ
                  ควบคุมความอยากฆ่าตัวตายที่ท่านคิดอยู่นั้นได้หรือไม่ หรือ
                  บอกได้ไหมว่าคงจะไม่ทำตามความคิดนั้นในขณะนี้
                </td>
                <td
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>ได้</strong>
                </td>
                <td
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <strong>ไม่ได้</strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_4n == "8" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ในช่วง 1 เดือนที่ผ่านมารวมวันนี้
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  มีแผนการที่จะฆ่าตัวตาย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_5n == "8" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ในช่วง 1 เดือนที่ผ่านมารวมวันนี้
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ได้เตรียมการที่จะทำร้ายตนเองหรือเตรียมการที่จะฆ่าตัวตายโดยตั้งใจว่าจะให้ตายจริงๆ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_6n == "9" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ได้ทำให้ตนเองบาดเจ็บแต่ไม่ตั้งใจที่จะทำให้เสียชีวิต
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_7n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ได้พยายามฆ่าตัวตายโดยคาดหวัง/ตั้งใจที่จะให้ตาย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_8n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_8n == "10" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  8.
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ตลอดชีวิตที่ผ่านมา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ท่านเคยพยายามฆ่าตัวตาย
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_9n == "0" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_9n == "4" && "/"}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนนรวมทั้งหมด
                </td>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {getTotalScore}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{
              marginTop: "16px",
              width: "fit-content",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  คะแนนรวม
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  การแปลผล
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  0
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ไม่มีแนวโน้มฆ่าตัวตายในปัจจุบัน
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1 - 8
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  มีแนวโน้มที่จะฆ่าตัวตายในปัจจุบัน ระดับน้อย
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  9 - 16
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  มีแนวโน้มที่จะฆ่าตัวตายในปัจจุบัน ระดับปานกลาง
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ≥17
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  มีแนวโน้มที่จะฆ่าตัวตายในปัจจุบัน ระดับรุนแรง
                </td>
              </tr>
            </tbody>
          </table>
          <FormElementTitle
            label="ถ้าคะแนน 8Q ≥ 17 ส่งต่อโรงพยาบาลมีจิตแพทย์ด่วน"
            mode="view"
          />
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default Form8QPaper;
