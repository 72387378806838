import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import painImg from "../../../images/pain.png";
import FormElementButton from "../Form/FormElementButton";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementRadio from "../Form/FormElementRadio";

import "../../../css/dietz.css";

const settings = {
  width: 1000,
  height: 500,
};

const PainSelectorSection = (props) => {
  const { data, onChange, onCaptureImage } = props;
  const ref = useRef(null);
  const formRef = useRef(null);

  const [currentPoint, setCurrentPoint] = useState({
    x: undefined,
    y: undefined,
    id: 1,
  });

  const [currentPointForm, setCurrentPointForm] = useState({
    id: 1,
    index: undefined,
    pain: "",
    type: "",
    min: "",
    max: "",
    mean: "",
  });

  const [pointSelected, setPointSelected] = useState(undefined);

  const [isEditing, setIsEditing] = useState(false);

  function drawPoint(ctx, point) {
    ctx.fillStyle = point.isEditing ? "red" : "#00b5b8";
    ctx.beginPath();
    ctx.arc(point.x, point.y, 12, 0, Math.PI * 2);
    ctx.fill();
    ctx.font = "14px Georgia";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "white";
    ctx.fillText(point.id, point.x, point.y);
  }

  function drawOldPoints(ctx, data, background) {
    ctx.drawImage(background, 0, 0, settings.width, settings.height);
    if (data) {
      data.forEach((item) => drawPoint(ctx, item));
    }
  }

  const getRunningPoint = useMemo(() => {
    return data?.length > 0 ? Math.max(...data?.map((item) => item.id)) + 1 : 1;
  });

  const generateImage = useCallback(() => {
    const img = ref.current.toDataURL("image/png");
    onCaptureImage(img);
  });

  useEffect(() => {
    var points = Object.assign([], data);
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    canvas.width = settings.width;
    canvas.height = settings.height;

    points?.forEach((item) => {
      delete item.isEditing;
    });
    if (pointSelected != undefined) {
      points[pointSelected].isEditing = true;
    }

    var selectedPoint;
    var background = new Image();
    background.src = painImg;
    background.onload = function () {
      drawOldPoints(ctx, points, background);
      selectedPoint = points
        ? points?.find((item, i) => i == pointSelected)
        : null;
      if (pointSelected != undefined && isEditing) {
        points = points ? points?.filter((item, i) => i != pointSelected) : [];
        drawPoint(ctx, {
          x: selectedPoint.x,
          y: selectedPoint.y,
          id: selectedPoint.id,
          isEditing: true,
        });
      } else {
        points = points;
      }
      if (pointSelected == undefined) {
        generateImage();
      }
    };

    const mouse = {
      x: undefined,
      y: undefined,
    };

    canvas.addEventListener("mousemove", function (e) {
      var cRect = canvas.getBoundingClientRect();
      var canvasX = Math.round(e.clientX - cRect.left);
      var canvasY = Math.round(e.clientY - cRect.top);
      mouse.x = canvasX;
      mouse.y = canvasY;
    });

    const handlePoint = () => {
      if (isEditing) {
        drawOldPoints(ctx, points, background);
        const point = {
          ...mouse,
          id: selectedPoint ? selectedPoint.id : getRunningPoint,
          isEditing: true,
        };
        drawPoint(ctx, point);
        setCurrentPoint({
          x: point.x,
          y: point.y,
          id: point.id,
        });
      }
    };

    canvas.addEventListener("click", handlePoint);

    return () => {
      canvas.removeEventListener("click", handlePoint);
    };
  }, [ref.current, data, isEditing, pointSelected]);

  useEffect(() => {
    const container = formRef.current;
    if (isEditing && pointSelected == undefined) {
      container.scrollLeft = container.scrollWidth;
    }
  }, [formRef.current, isEditing, pointSelected]);

  const onSave = useCallback(() => {
    if (data) {
      if (pointSelected != undefined) {
        const saveData = data;
        saveData[pointSelected] = {
          ...currentPointForm,
          x: currentPoint.x,
          y: currentPoint.y,
        };
        onChange(saveData);
      } else {
        const saveData = data;
        saveData.push({
          ...currentPointForm,
          x: currentPoint.x,
          y: currentPoint.y,
        });
        onChange(saveData);
      }
    } else {
      onChange([{ ...currentPointForm, x: currentPoint.x, y: currentPoint.y }]);
    }
    setPointSelected(undefined);
    setIsEditing(false);
  });

  const handleCurrentPointFormChange = useCallback((id, value) => {
    setCurrentPointForm({ ...currentPointForm, [id]: value });
  });

  const onPointSelect = useCallback((index) => {
    if (!isEditing) {
      const form = data[index];
      console.log(form);
      setCurrentPoint({ id: form.id, x: form.x, y: form.y });
      setCurrentPointForm({ ...form });
      setPointSelected(index);
    }
  });

  const onEdit = useCallback(() => {
    setIsEditing(true);
  });

  const onAdd = useCallback(() => {
    setCurrentPoint({});
    setCurrentPointForm({ id: getRunningPoint });
    setPointSelected(undefined);
    setIsEditing(true);
  });

  const onDelete = useCallback(() => {
    if (pointSelected != undefined) {
      if (data) {
        const saveData = data.filter((item, i) => i != pointSelected);
        onChange(saveData);
        setPointSelected(undefined);
      }
    }
  });

  const onCancel = useCallback(() => {
    setIsEditing(false);
    setPointSelected(undefined);
    setCurrentPointForm({
      id: "",
      pain: "",
      type: "",
      min: "",
      max: "",
      mean: "",
    });
  });

  const renderPainForm = useMemo(() => {
    return (
      <>
        <th style={{ width: "80px" }} className="center">
          Pain
        </th>
        {data?.map((item, index) => {
          return (
            <>
              {!(isEditing && pointSelected == index) && (
                <th
                  style={{
                    background: `${pointSelected == index ? "#00b5b899" : ""}`,
                  }}
                  onClick={() => onPointSelect(index)}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "14px",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <strong># {item.id}</strong>
                    <FormElementTextBox value={item.pain} disabled />
                  </div>
                </th>
              )}
              {isEditing && pointSelected == index && (
                <th
                  style={{
                    background: "#00b5b899",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "14px",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <strong># {currentPointForm.id}</strong>
                    <FormElementTextBox
                      value={currentPointForm.pain}
                      onChange={(i, e) =>
                        handleCurrentPointFormChange("pain", e?.target?.value)
                      }
                    />
                  </div>
                </th>
              )}
            </>
          );
        })}
        {isEditing && pointSelected == undefined && (
          <th style={{ backgroundColor: "#00b5b899" }}>
            <div
              style={{
                display: "inline-flex",
                gap: "14px",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <strong># {currentPointForm.id}</strong>
              <FormElementTextBox
                value={currentPointForm.pain}
                onChange={(i, e) =>
                  handleCurrentPointFormChange("pain", e?.target?.value)
                }
              />
            </div>
          </th>
        )}
        {!data && !(isEditing && pointSelected == undefined) && (
          <th colSpan="100%"></th>
        )}
      </>
    );
  }, [isEditing, data, currentPointForm, pointSelected]);

  const renderTypeForm = useMemo(() => {
    return (
      <>
        <td className="center">Type</td>
        {data?.map((item, index) => {
          return (
            <>
              {!(isEditing && pointSelected == index) && (
                <td onClick={() => onPointSelect(index)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementRadio
                      fieldId={`item-${index}-neuropathic`}
                      value={item.type}
                      fieldValue={"1"}
                      label="Neuropathic"
                      disabled
                    />
                    <FormElementRadio
                      fieldId={`item-${index}-nociceptive`}
                      value={item.type}
                      fieldValue={"2"}
                      label="Nociceptive"
                      disabled
                    />
                    <FormElementRadio
                      fieldId={`item-${index}-visceral`}
                      value={item.type}
                      fieldValue={"3"}
                      label="Visceral"
                      disabled
                    />
                  </div>
                </td>
              )}
              {isEditing && pointSelected == index && (
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementRadio
                      fieldId={`item-${index}-neuropathic`}
                      value={currentPointForm.type}
                      fieldValue={"1"}
                      label="Neuropathic"
                      onChange={(i, e) =>
                        handleCurrentPointFormChange("type", e?.target?.value)
                      }
                    />
                    <FormElementRadio
                      fieldId={`item-${index}-nociceptive`}
                      value={currentPointForm.type}
                      fieldValue={"2"}
                      label="Nociceptive"
                      onChange={(i, e) =>
                        handleCurrentPointFormChange("type", e?.target?.value)
                      }
                    />
                    <FormElementRadio
                      fieldId={`item-${index}-visceral`}
                      value={currentPointForm.type}
                      fieldValue={"3"}
                      label="Visceral"
                      onChange={(i, e) =>
                        handleCurrentPointFormChange("type", e?.target?.value)
                      }
                    />
                  </div>
                </td>
              )}
            </>
          );
        })}
        {isEditing && pointSelected == undefined && (
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <FormElementRadio
                fieldId={`currentPointForm-neuropathic`}
                value={currentPointForm.type}
                fieldValue={"1"}
                label="Neuropathic"
                onChange={(i, e) =>
                  handleCurrentPointFormChange("type", e?.target?.value)
                }
              />
              <FormElementRadio
                fieldId={`currentPointForm-nociceptive`}
                value={currentPointForm.type}
                fieldValue={"2"}
                label="Nociceptive"
                onChange={(i, e) =>
                  handleCurrentPointFormChange("type", e?.target?.value)
                }
              />
              <FormElementRadio
                fieldId={`currentPointForm-visceral`}
                value={currentPointForm.type}
                fieldValue={"3"}
                label="Visceral"
                onChange={(i, e) =>
                  handleCurrentPointFormChange("type", e?.target?.value)
                }
              />
            </div>
          </td>
        )}
        {!data && !(isEditing && pointSelected == undefined) && (
          <td colSpan="100%"></td>
        )}
      </>
    );
  }, [isEditing, data, currentPointForm, pointSelected]);

  const renderPainScoreForm = useMemo(() => {
    return (
      <>
        <td style={{ width: "80px" }} className="center">
          Pain score
        </td>
        {data?.map((item, index) => {
          return (
            <>
              {!(isEditing && pointSelected == index) && (
                <td onClick={() => onPointSelect(index)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementTextBox
                      value={item.min}
                      type="decimal"
                      label="Min"
                      width="10"
                      disabled
                    />
                    <FormElementTextBox
                      value={item.max}
                      type="decimal"
                      label="Max"
                      width="10"
                      disabled
                    />
                    <FormElementTextBox
                      value={item.mean}
                      type="decimal"
                      label="Mean"
                      width="10"
                      disabled
                    />
                  </div>
                </td>
              )}
              {isEditing && pointSelected == index && (
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementTextBox
                      value={currentPointForm.min}
                      type="decimal"
                      label="Min"
                      width="10"
                      onChange={(i, e) =>
                        handleCurrentPointFormChange("min", e?.target?.value)
                      }
                    />
                    <FormElementTextBox
                      value={currentPointForm.max}
                      type="decimal"
                      label="Max"
                      width="10"
                      onChange={(i, e) =>
                        handleCurrentPointFormChange("max", e?.target?.value)
                      }
                    />
                    <FormElementTextBox
                      value={currentPointForm.mean}
                      type="decimal"
                      label="Mean"
                      width="10"
                      onChange={(i, e) =>
                        handleCurrentPointFormChange("mean", e?.target?.value)
                      }
                    />
                  </div>
                </td>
              )}
            </>
          );
        })}
        {isEditing && pointSelected == undefined && (
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <FormElementTextBox
                value={currentPointForm.min}
                type="decimal"
                label="Min"
                width="10"
                onChange={(i, e) => {
                  handleCurrentPointFormChange("min", e?.target?.value);
                }}
              />
              <FormElementTextBox
                value={currentPointForm.max}
                type="decimal"
                label="Max"
                width="10"
                onChange={(i, e) =>
                  handleCurrentPointFormChange("max", e?.target?.value)
                }
              />
              <FormElementTextBox
                value={currentPointForm.mean}
                type="decimal"
                label="Mean"
                width="10"
                onChange={(i, e) =>
                  handleCurrentPointFormChange("mean", e?.target?.value)
                }
              />
            </div>
          </td>
        )}
        {!data && !(isEditing && pointSelected == undefined) && (
          <td colSpan="100%"></td>
        )}
      </>
    );
  }, [isEditing, data, currentPointForm, pointSelected]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <canvas
        ref={ref}
        style={{
          width: "fit-content",
          border: "2px solid #00b5b8",
          borderRadius: "16px",
          cursor: `${isEditing ? "pointer" : "not-allowed"}`,
        }}
      ></canvas>
      <div style={{ display: "inline-flex", gap: "8px" }}>
        {!isEditing && (
          <FormElementButton
            label="เพิ่ม"
            onClick={onAdd}
            // disabled={isEditingData114}
          />
        )}
        {pointSelected != undefined && !isEditing && (
          <FormElementButton
            label="แก้ไข"
            onClick={onEdit}

            // onClick={addData114}
            // disabled={isEditingData114}
          />
        )}
        {pointSelected != undefined && !isEditing && (
          <FormElementButton
            label="ลบ"
            onClick={onDelete}
            type="danger"
            // onClick={addData114}
            // disabled={isEditingData114}
          />
        )}
        {isEditing && (
          <FormElementButton
            label="ยกเลิก"
            onClick={onCancel}
            type="danger"
            // onClick={addData114}
            // disabled={isEditingData114}
          />
        )}
        {isEditing && (
          <FormElementButton
            label="บันทึก"
            onClick={() => onSave()}
            // onClick={addData114}
            // disabled={isEditingData114}
          />
        )}
      </div>
      <div className="form-table-scroll" ref={formRef}>
        <table
          className="uk-table uk-table-small form-table bordered pain-selector-table"
          style={{
            marginTop: "0px",
          }}
        >
          <thead>
            <tr>{renderPainForm}</tr>
          </thead>
          <tbody>
            <tr>{renderTypeForm}</tr>
            <tr>{renderPainScoreForm}</tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PainSelectorSection;
