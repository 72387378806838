import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

import "../../../css/dietz.css";
import Api from "../../Api";


const FormGoodDeathPaper = (props) => {
  const { dataForm, patient, profile, familyItemsDropDown, wardDropDown } = props;

  var [familyItemsDropDownList, setFamilyItemsDropDownList] = useState({});
  var [wardDropDownList, setWardDropDownList] = useState({});

  useEffect(() => {
    let result = {};
    familyItemsDropDown?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setFamilyItemsDropDownList(result)
  }, [familyItemsDropDown, setFamilyItemsDropDownList]);

  useEffect(() => {
    let result = {};
    wardDropDown?.forEach((nx) => {
      result = { ...result, [nx.value]: nx.label }
    });
    setWardDropDownList(result)
  }, [wardDropDown, setWardDropDownList]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินการตายดี (Good death)"
            mode="view"
            padding="0px 0px 40px 0px"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={patient} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <FormElementTitle
              label="ผู้ตอบแบบประเมินมีความเกี่ยวข้องกับผู้ป่วย"
              mode="view"
            />
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="หอผู้ป่วย" mode="view" />
              <FormElementLabel
                value={dataForm.data_1}
                mode="view"
                translate={wardDropDownList}
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="สถานที่เสียชีวิต" mode="view" />
              <FormElementLabel
                value={dataForm.data_2}
                mode="view"
                translate={{ 1: "ที่บ้าน", 2: "ที่โรงพยาบาล", 3: "อื่นๆ" }}
              />
              <span hidden={dataForm.data_2 != "3"}>
                <FormElementLabel label={dataForm.data_13} mode="view" />
              </span>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="ผู้ตอบแบบประเมิน" mode="view" />
              <FormElementLabel label={dataForm.data_3} mode="view" />
              <FormElementLabel label="ความสัมพันธ์" mode="view" />
              <FormElementLabel
                value={dataForm.data_4}
                mode="view"
                translate={familyItemsDropDownList}
              />
              <span hidden={dataForm.data_4 != "99"}>
                <FormElementLabel label={dataForm.data_12} mode="view" />
              </span>
            </div>
            <FormElementTitle
              label="กรุณาทําเครื่องหมาย ในช่องที่ตรงกับความเป็นจริง"
              mode="view"
            />
            <FormElementTitle
              label="1. ผู้ป่วยได้เสียชีวิตในสถานที่ตรงกับความต้องการของผู้ป่วย"
              mode="view"
            />
            <FormElementLabel
              value={dataForm.data_5}
              mode="view"
              translate={{ 1: "ใช่", 2: "ไม่ใช่" }}
            />
            <span hidden={dataForm.data_5 != "2"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel
                    label="สถานที่ที่ผู้ป่วยต้องการเสียชีวิต คือ"
                    mode="view"
                  />
                  <FormElementLabel label={dataForm.data_6} mode="view" />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel
                    label="สถานที่ที่ผู้ป่วยได้เสียชีวิตจริง คือ"
                    mode="view"
                  />
                  <FormElementLabel label={dataForm.data_7} mode="view" />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel label="มีสาเหตุเพราะ" mode="view" />
                  <FormElementLabel label={dataForm.data_8} mode="view" />
                </div>
              </div>
            </span>
            <FormElementTitle
              label="2. ในความรู้สึกของญาติ คิดว่าผู้ป่วยได้จากไปอย่างสงบ โดยที่อาการต่างๆได้รับการดูแลหรือไม่"
              mode="view"
            />
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                paddingLeft: "8px",
              }}
            >
              <FormElementLabel
                value={dataForm.data_9}
                mode="view"
                translate={{ 1: "ใช่", 0: "ไม่ใช่" }}
              />
              <span hidden={dataForm.data_9 != "1"}>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel label="ระดับ" mode="view" />
                  <FormElementLabel
                    value={dataForm.data_11}
                    mode="view"
                    translate={{
                      1: "ดีที่สุด",
                      2: "ดีมาก",
                      3: "ดี",
                      4: "พอใช้",
                      5: "น้อย",
                    }}
                  />
                </div>
              </span>
              <span hidden={dataForm.data_9 != "0"}>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <FormElementLabel label="เพราะ (ต้องระบุ)" mode="view" />
                  <FormElementLabel label={dataForm.data_10} mode="view" />
                </div>
              </span>
            </div>
          </div>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormGoodDeathPaper;
