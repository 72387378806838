import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from "../../Components/Api";
import FullScreenElement from "../FullScreenElement";
import UserHealthBook from "./UserHealthBook";
import Formular from "../Formular.js";
import DataType from "../DataType.js";
import moment from "moment";
import UploadFirebase from "../UploadFirebase.js";
import ImageUri from "../ImageUri.js";
import WorkHealthRecord from "../../Pages/Member/WorkHealthRecord.js";
import numeral from 'numeral';

UIkit.use(Icons);

class PeopleEdit extends Component {
  state = {
    member: {},
    campaign: [],
    companygroup: [],
  };

  field = [
    { key: "nameprefix", label: "คำนำหน้าชื่อ", require: true },
    { key: "name", label: "ชื่อ", require: true },
    { key: "lastname", label: "นามสกุล", require: true },
    { key: "username", label: "ชื่อผู้ใช้", require: true },
    { key: "password", label: "รหัสผ่าน", require: true },
    { key: "email", label: "อีเมล", type: "email" },
    { key: "tel", label: "โทร", require: true },
    {
      key: "gender",
      label: "เพศ",
      type: "select",
      option: [
        { value: "1", label: "ชาย" },
        { value: "0", label: "หญิง" },
      ],
    },
    {
      key: "nid",
      label: "เลขบัตรประชาชน / เลขบัตรประกันสังคม / passport สำหรับชาวต่างชาติ",
      require: true,
    },
    { key: "birthday", label: "วันเกิด", type: "date" },
    { key: "lineuid", label: "uid Line application" },
  ];

  dataFind = async () => {
    var _response = await Api.member("PeopleFindByMemberId", {
      MemberId: this.props.memberId,
    });

    this.setState({
      member: _response.member_data || [],
      campaign: _response.campaign_data || [],
      companygroup: _response.companygroup_data || [],
    });
  };

  componentDidMount() {
    this.dataFind();
  }

  updateMember = async () => {
    var _response = await Api.member("PeopleUpdate", this.state.member);

    this.props.updateCallback({
      nameprefix: this.state.member.nameprefix,
      name: this.state.member.name,
      lastname: this.state.member.lastname,
      job: this.state.member.membercompany && this.state.member.membercompany[0] ? this.state.member.membercompany[0].job : "",
      position: this.state.member.membercompany && this.state.member.membercompany[0] ? this.state.member.membercompany[0].position : "",
    });

    UIkit.notification(_response.responseText);
  };

  removeMember = async () => {
    if(window.confirm('Confirm remove this member?')) {
      
      var _response = await Api.member("PeopleRemove", this.state.member);

      UIkit.notification(_response.responseText);

      if(_response.result) {
        this.props.removeCallback(this.state.member);
      this.props.closeCallback();
      }

      
    }
  }

  updateValue = (key, value) => {
    this.setState((state) => {
      state.member[key] = value;
      return { member: state.member };
    });
  };

  formGeneralRender = () => {
    return this.field.map((data, index) => {
      var _input = null;
      switch (data.type) {
        case "select":
          _input = (
            <select
              className="uk-select"
              name={data.key}
              onChange={this.updateValue}
              value={this.state.member[data.key]}
            >
              {data.option.map((o) => (
                <option value={o.value} key={o.key}>
                  {o.label}
                </option>
              ))}
            </select>
          );
          break;
        default:
          _input = (
            <input
              type={data.type || "text"}
              className="uk-input"
              name={data.key}
              onChange={(e) => {
                this.updateValue(data.key, e.target.value);
              }}
              value={this.state.member[data.key]}
            />
          );
      }

      return (
        <div key={data.key}>
          <label>
            {data.label}{" "}
            {data.require ? <span className="uk-text-danger">*</span> : null}
          </label>
          {_input}
        </div>
      );
    });
  };

  companygroupCheck = (id) => {
    var _result = [];

    if (Array.isArray(this.state.member.companygroup))
      _result = this.state.member.companygroup.filter((data) => {
        return data.companygroupid == id ? true : false;
      });

    return _result.length > 0 ? true : false;
  };

  companygroupFormRender = () => {
    return (
      <div className="uk-margin-small-top">
        <label>กลุ่ม</label>
        <div>
          {this.state.companygroup && this.state.companygroup.length ? (
            this.state.companygroup.map((data, index) => {
              return (
                <div key={`cg${index}`} className="uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom uk-padding-small">
                  <label
                    className="uk-grid uk-grid-small"
                    key={`row_campaign_${index}`}
                  >
                    <div className="uk-width-auto">
                      <input
                        type="checkbox"
                        className="uk-checkbox"
                        checked={this.companygroupCheck(data.id)}
                        onChange={() =>
                          this.setState((state) => {
                            var _is_checked = this.companygroupCheck(data.id);

                            if (!Array.isArray(state.member.companygroup))
                              state.member.companygroup = [];

                            if (_is_checked === true) {
                              state.member.companygroup.forEach(
                                (companygroup, companygroup_index) => {
                                  if (companygroup.companygroupid === data.id)
                                    delete state.member.companygroup[
                                      companygroup_index
                                    ];
                                }
                              );
                            } else {
                              state.member.companygroup.push({
                                companygroupid: data.id,
                              });
                            }

                            state.member.companygroup =
                              state.member.companygroup.filter(Boolean);

                            return { member: state.member };
                          })
                        }
                      />
                    </div>
                    <div className="uk-width-expand uk-margin-small-bottom uk-text-left">
                      {data.name}
                    </div>
                  </label>
                </div>
              );
            })
          ) : (
            <div className="uk-padding uk-text-center">ไม่พบกลุ่ม</div>
          )}
        </div>
      </div>
    );
  };

  campaignCheck = (id) => {
    var _result = [];

    if (Array.isArray(this.state.member.campaign))
      _result = this.state.member.campaign.filter((data) => {
        return data.campaignid == id ? true : false;
      });

    return _result.length > 0 ? true : false;
  };

  campaignFormRender = () => {
    return (
      <div className="uk-margin-small-top">
        <label>แคมเปญ</label>
        <div>
          {this.state.campaign && this.state.campaign.length ? (
            this.state.campaign.map((data, index) => {
              return (
                <div key={`campaign${index}`} className="uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom uk-padding-small">
                  <label
                    className="uk-grid uk-grid-small"
                    key={`row_campaign_${index}`}
                  >
                    <div className="uk-width-auto">
                      <input
                        type="checkbox"
                        className="uk-checkbox"
                        checked={this.campaignCheck(data.id)}
                        onChange={() =>
                          this.setState((state) => {
                            var _is_checked = this.campaignCheck(data.id);

                            if (!Array.isArray(state.member.campaign))
                              state.member.campaign = [];

                            if (_is_checked === true) {
                              state.member.campaign.forEach(
                                (campaign, campaign_index) => {
                                  if (campaign.campaignid === data.id)
                                    delete state.member.campaign[
                                      campaign_index
                                    ];
                                }
                              );
                            } else {
                              state.member.campaign.push({
                                campaignid: data.id,
                              });
                            }

                            state.member.campaign =
                              state.member.campaign.filter(Boolean);

                            return { member: state.member };
                          })
                        }
                      />
                    </div>
                    <div className="uk-width-auto">
                      {data.image && (
                        <img src={data.image} className="d-campaign-icon" />
                      )}
                    </div>
                    <div className="uk-width-expand uk-margin-small-bottom uk-text-left">
                      {data.name}
                    </div>
                    <div className="uk-width-auto uk-text-meta">
                      วันที่ {moment(data.date_start).format("DD/MM/YYYY")} -
                      {moment(data.date_stop).format("DD/MM/YYYY")}
                    </div>
                  </label>
                </div>
              );
            })
          ) : (
            <div className="uk-padding uk-text-center">ไม่พบแคมเปญ</div>
          )}
        </div>
      </div>
    );
  };

  updateMemberState = () => {
    this.setState(
      (state)=>{
        return {member: state.member}
      }
    );
  }

  membercompanyFormRender = () => {
    return <div className="uk-margin-small-top">
      <label>บริษัท</label>
      {
        this.state.member.membercompany && this.state.member.membercompany.length ? 
        this.state.member.membercompany.map((data, index) => {
          return <div key={`mc${index}`} className="uk-card uk-button-default uk-padding-small uk-margin-small-bottom">
            <div className="uk-grid uk-grid-small uk-child-widht-1-1 uk-child-width-1-2@m">
               <div>แผนก</div>
              <input type="text" value={data.position} className="uk-input" onChange={(e)=>{
                    data.position = e.target.value;
                this.updateMemberState();
              }} />
            </div>
            <div className="uk-grid uk-grid-small uk-child-widht-1-1 uk-child-width-1-2@m">
               <div>ตำแหน่ง</div>
              <input type="text" value={data.job} className="uk-input" onChange={(e)=>{
                    data.job = e.target.value;
                this.updateMemberState();
              }}  />
            </div>
            <div className="uk-grid uk-grid-small uk-child-widht-1-1 uk-child-width-1-2@m">
              <div>ระดับ</div>
              <select value={data.level} className="uk-select" onChange={(e)=>{
                    data.level = e.target.value;
                this.updateMemberState();
              }}>
                <option value="0">User</option>
                <option value="1">Admin</option>
              </select>
              </div>
          </div>
        })
        :
        <div className="uk-padding uk-text-center">ไม่พบข้อมูล</div>
      }
    </div>
  }

  render() {
    return (
      <div className="d-popup-background">
        <div className="d-popup-body uk-card-default">
          <div className="uk-text-right">
            <a uk-icon="close" onClick={this.props.closeCallback}></a>
          </div>
          <div className="uk-margin-small-bottom">
            <h4>จัดการข้อมูลสมาชิก</h4>

            <label className="uk-button uk-button-default">
              เลือกรูปภาพ
              <UploadFirebase
                cb={(image) => {
                  this.setState((state) => {
                    state.member.image = image;

                    return { member: state.member };
                  });
                }}
              />
              {this.state.member.image && (
                <div className="uk-margin-small">
                  <img
                    src={ImageUri(this.state.member.image)}
                    uk-img=""
                    width="200"
                  />
                </div>
              )}
            </label>
          </div>
          <div className="uk-grid uk-child-width-1-1 uk-child-width-1-3@l">
            {this.formGeneralRender()}
          </div>
          {this.campaignFormRender()}
          {this.companygroupFormRender()}
          {this.membercompanyFormRender()}
          <div className="uk-margin-top uk-text-right">
            <a className="uk-text-danger uk-margin-right" onClick={this.removeMember}><i uk-icon="trash"></i> ลบสมาชิก</a>
            <a
              className="uk-margin-right uk-text-meta"
              onClick={this.props.closeCallback}
            >
              ยกเลิก
            </a>
            <button
              className="uk-button uk-button-primary"
              onClick={this.updateMember}
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default class PeopleTable extends Component {
  state = {
    loadingApi: false,
    keyword: "",
    list: [],
    isAlreadySearch: false,
    showHealthBook: false,
    dataHealthBook: [],
    selectMemberId: false,
    selectMemberIndex: null,
    loadingDataHealthBook: false,
    page: 1,
    pageMax: 1,
    list_amount: 0,
    showPopup: false,
    showWorkHealthRecord: false,
    selectWorkHealthRecordMemberId: null,
    selectWorkHealthRecordDateadd: null,
  };

  componentDidMount() {
    this.findGet();
  }

  workhealthrecordById = {};

  workhealthrecordByYear = {}

  workhealthrecordByYearArray = [];

  findGet = async (e) => {
    if(e != null) e.preventDefault();

    if (this.state.loadingApi === true) return false;

    this.setState({ loadingApi: true });

    var { data, amount, pageMax } = await Api.member("PeopleFind", {
      keyword: this.state.keyword,
      page: this.state.page,
    });

    if (!data) data = [];

    this.workhealthrecordByYear = {};

    var _thisYear = moment().format('YYYY');
    for(var i=0; i<5; i++) {
      this.workhealthrecordByYear[_thisYear-i] = true;
    }

    data.forEach((value) => {
      if(!Array.isArray(value.workhealthrecord)) {
        value.workhealthrecord = [];
      }

      this.workhealthrecordById[value.id] = {};

      value.workhealthrecord.forEach((wh)=>{
        var _year = moment(wh.dateadd).format('YYYY');
        if(!this.workhealthrecordByYear[_year]) this.workhealthrecordByYear[_year] = true;

        if(!this.workhealthrecordById[value.id][_year] ) this.workhealthrecordById[value.id][_year]  = [];

        this.workhealthrecordById[value.id][_year].push(wh);

      })
    });

    this.workhealthrecordByYearArray = Object.keys(this.workhealthrecordByYear)

    this.setState(
      {
        loadingApi: false,
        isAlreadySearch: true,
        list: data,
        pageMax: pageMax,
        list_amount: amount
      }
    );
  };

  dataForHealthBookGet = async () => {
    this.setState({ showHealthBook: false });

    var { data } = await Api.member("UserSmartHealthRecord", {
      memberId: this.state.selectMemberId,
    });

    if (!data) data = [];
    var _data = [];

    for (var i = data.length; i-- > 0; ) {
      if (DataType.indexOf(data[i].name) >= 0) {
        data[i].detail = Formular[data[i].name](
          data[i].value,
          data[i].age,
          data[i].gender
        );
        _data.push(data[i]);
      } else {
        _data.push(data[i]);
      }
    }

    this.setState({ dataHealthBook: _data || [] }, () => {
      this.setState({ showHealthBook: true, loadingDataHealthBook: false });
    });
  };


  pageNext = () => {
    if (this.state.page < this.state.pageMax) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.findGet();
      });
    }
  };

  pagePrev = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.findGet();
      });
    }
  };

  workbookColumnRender = () => {
    return null;
  }

  render() {
    return (
      <div
        className="uk-card uk-card-default uk-padding-small"
        id="d-people-table"
      >
        {this.state.showPopup && (
          <PeopleEdit
            show={this.state.showPopup}
            memberId={this.state.selectMemberId}
            closeCallback={() => this.setState({ showPopup: false })}
            updateCallback={(data) => {
              this.setState((state)=>{
                state.list[this.state.selectMemberIndex].nameprefix = data.nameprefix;
                state.list[this.state.selectMemberIndex].name = data.name;
                state.list[this.state.selectMemberIndex].lastname = data.lastname;
                state.list[this.state.selectMemberIndex].job = data.job;
                state.list[this.state.selectMemberIndex].position = data.position;


                return {lislist: state.list};
              })
              
            }}
            removeCallback={(member)=>{
              
              this.setState({list: this.state.list.filter((data)=>{
                return data.id == member.id ? false : true;
              })})
            }}
          />
        )}
        {
          this.state.showWorkHealthRecord && <WorkHealthRecord qs={
            {
              dateadd: this.state.selectWorkHealthRecordDateadd,
              print: '1'
            }}
            
            memberId = {this.state.selectWorkHealthRecordMemberId}

            printCallback = {()=>{
              this.setState({showWorkHealthRecord: false})
            }}
          
         />
      }
        <div className="d-card-header">
          <div className="d-card-header-left">People</div>
          <div className="d-card-header-right">
            <FullScreenElement element="#d-people-table" />
          </div>
        </div>
        <h4 className="uk-margin-remove">ค้นหาข้อมูลสุขภาพรายบุคคล</h4>
        <form onSubmit={this.findGet}>
          <div className="uk-grid uk-grid-small uk-margin-small-top uk-flex-middle">
            <div className="uk-width-expand">
              <input
                type="type"
                className="uk-input"
                placeholder="ชื่อหรือนามสกุล"
                value={this.state.keyword}
                onChange={(e) => this.setState({ keyword: e.target.value })}
              />
            </div>
            <div className="uk-width-auto">
              <button
                type="submit"
                className="uk-button uk-button-primary uk-display-block"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </form>
        {this.state.showHealthBook === true && this.state.selectMemberId && (
          <UserHealthBook
            data={this.state.dataHealthBook}
            memberId={this.state.selectMemberId}
            printPrompt={true}
          />
        )}
        {
          this.state.loadingApi ? 
            <div className="uk-padding-small uk-text-center">
              <span uk-spinner=""></span>
            </div>
          :
          this.state.isAlreadySearch === true && (
            <div className="uk-margin-top">
              <div className="uk-text-bold">
                ค้นหาพบจำนวน {numeral(this.state.list_amount).format('0,0')} รายการ
              </div>
              <div className="uk-margin-top-small">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-striped uk-table-small">
                    <thead>
                      <tr>
                        <th rowSpan="2">ที่</th>
                        <th rowSpan="2">ชื่อ-นามสกุล</th>
                        <th rowSpan="2">ตำแหน่ง</th>
                        <th rowSpan="2">แผนก</th>
                        <th rowSpan="2"></th>
                        <th rowSpan="2"></th>
                        <th className="uk-text-center" colSpan={this.workhealthrecordByYearArray.length}>สมุดสุขภาพ</th>
                      </tr>
                      <tr>
                        {this.workhealthrecordByYearArray.map((year)=>{
                          return <th className="uk-text-center" key={`col${year}`}>{+year + 543}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {
                      
                      this.state.list.length ? (
                        this.state.list.map((data, index) => {
                          return (
                            <tr key={`people_row_${index}`}>
                              <td>{index + 1}</td>
                              <td>
                                {data.nameprefix} {data.name} {data.lastname}
                              </td>
                              <td>{data.job}</td>
                              <td>{data.position}</td>
                              <td>
                                <a
                                  onClick={() =>
                                    this.setState({
                                      selectMemberIndex: index,
                                      selectMemberId: data.id,
                                      showPopup: true,
                                    })
                                  }
                                >
                                  <i
                                    uk-icon="icon: pencil"
                                    className="uk-icon"
                                  ></i>{" "}
                                  แก้ไข
                                </a>
                              </td>
                              <td>
                                {this.state.loadingDataHealthBook === true ? (
                                  <div>
                                    <span uk-spinner=""></span>
                                  </div>
                                ) : (
                                  <a
                                    className="uk-display-inline-block"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          selectMemberId: data.id,
                                          loadingDataHealthBook: true,
                                        },
                                        () => {
                                          this.dataForHealthBookGet();
                                        }
                                      );
                                    }}
                                  >
                                    ดูสมุดสุขภาพ
                                  </a>
                                )}
                              </td>
                              
                              {this.workhealthrecordByYearArray.map((year)=>{
                                return <td key={`col${year}`}>{
                                  Array.isArray(this.workhealthrecordById[data.id][year]) && this.workhealthrecordById[data.id][year].map((wh, wh_index) => {
                                    return <a target="_blank" className="uk-text-small" key={`book${year}${wh_index}`} onClick={()=>{
                                      this.setState({
                                        showWorkHealthRecord: true,
                                        selectWorkHealthRecordMemberId: data.id,
                                        selectWorkHealthRecordDateadd: wh.dateadd
                                      });
                                    }}>
                                      <div style={{wordBreak: 'break-word'}}>{wh.datatype}</div>
                                      <div className="uk-margin-small-bottom">({moment(wh.dateadd).add(543, 'y').format('DD/MM/YY HH:mm')})</div>
                                    </a>
                                  })
                                }</td>
                              })}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="100%">
                            <div className="uk-text-center uk-padding-small">
                              ไม่พบรายการ
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="uk-text-right uk-margin-top">
                  <a
                    className="icon-arrow-left uk-margin-small-right "
                    onClick={this.pagePrev}
                  ></a>
                  {this.state.page}/{this.state.pageMax}
                  <a
                    className="icon-arrow-right uk-margin-small-left"
                    onClick={this.pageNext}
                  ></a>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
