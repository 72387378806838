import React, { useState, useEffect, useMemo } from "react";
import FormElementTitle from "../Form/FormElementTitle";

import "../../../css/dietz.css";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementTextBox from "../Form/FormElementTextBox";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

const FormHeartFailureClinicPaper = (props) => {
  const { dataForm, patient, profile } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="HEART FAILURE DISCHARGE CHECKLIST"
            mode="view"
          />
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <table
          style={{
            marginTop: "14px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
                rowSpan={3}
              >
                การติดตามอาการ
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextBox
                  value={dataForm.data_1n}
                  label="ครั้งที่"
                  mode="view"
                  width="100"
                />
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
                rowSpan={3}
              >
                หมายเหตุ
              </th>
            </tr>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextBox
                  type="date"
                  value={dataForm.data_2}
                  label="วันที่"
                  mode="view"
                  width="100"
                />
              </th>
            </tr>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                สถานะ
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการหายใจเหนื่อยหอบหรือหายใจลำบากในวันที่ผ่านมาหรือไม่
                (Dyspnea)
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_3}
                  translate={{ 1: "มี", 2: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_4}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ประเมิน NYHA Functional class
                <br />
                I ไม่มีอาการเหนื่อย
                <br />
                I ไม่มีอาการเหนื่อย
                <br />
                II มีอาการเหนื่อยเล็กน้อยขณะออกแรง
                <br />
                III มีอาการเหนื่อยเมื่อทำกิจวัตรประจำวัน
                <br />
                IV มีอาการเหนื่อยขณะที่พักอยู่เฉยๆ
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_5}
                  mode="view"
                  translate={{ 1: "I", 2: "II", 3: "III", 4: "IV" }}
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_6}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ไม่สามารถนอนราบได้เนื่องจากมีความผิดปกติของการหายใจในวันที่ผ่านมาหรือไม่
                (Orthopnea)
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_7}
                  translate={{ 1: "มี", 2: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_8}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการหายใจลำบากหลังจากนอนหลับในตอนกลางคืน
                พอลุกมาแล้วดีขึ้นในวันที่ผ่านมาหรือไม่ (PND)
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_9}
                  translate={{ 1: "มี", 2: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_10}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการบวมของร่างกายในวันที่ผ่านมาหรือไม่ ตำแหน่งไหน (Edema)
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_11}
                  translate={{ 1: "มี", 2: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_12}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div style={{ display: "inline-flex" }}>
                  <FormElementLabel label="น้ำหนักเดิม" mode="view" />
                  <FormElementTextBox value={dataForm.data_13n} mode="view" />
                </div>
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    whiteSpace: "nowrap",
                  }}
                >
                  <FormElementLabel label="น้ำหนักใหม่" mode="view" />
                  <FormElementTextBox value={dataForm.data_14n} mode="view" />
                </div>
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_15}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ความดันโลหิต
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextBox value={dataForm.data_16} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_17}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ชีพจรที่บ้าน (ถ้ามีเครื่องวัดความดันโลหิต)
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextBox value={dataForm.data_39n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  value={dataForm.data_40}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการอ่อนเพลียในวันที่ผ่านมาหรือไม่ (Fatigue)
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_18}
                  translate={{ 1: "มี", 2: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_19}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีอาการเวียนศีรษะ หรือหน้ามืดในวันที่ผ่านมาหรือไม่
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_20}
                  translate={{ 1: "มี", 2: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_21}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                โดยรวมแล้วในวันที่ผ่านมาคุณรู้สึกดีขึ้นหรือแย่ลงกว่าเดิม (QOL)
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextBox
                  value={dataForm.data_22}
                  mode="view"
                  width="100"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_23}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                คุณมีคำถามที่จะต้องการถามพยาบาลหรือไม่
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_24}
                  translate={{ 1: "มี", 2: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_25}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ER visit
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_26}
                  translate={{ 1: "มี", 0: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_28}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                Re admit
              </td>
              <td
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementLabel
                  value={dataForm.data_27}
                  translate={{ 1: "มี", 0: "ไม่มี" }}
                  mode="view"
                />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextArea
                  value={dataForm.data_41}
                  mode="view"
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การติดตามดูแลตนเอง
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                การปฏิบัติตัว
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                อาหารที่รับประทาน/การหลีกเลี่ยงอาหารรสเค็ม
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_29} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การดื่มน้ำ
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_30} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การรับประทานยาต่อเนื่อง
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_31} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การเฝ้าระวังภาวะสารน้ำเกิน/การปรับยาขับปัสสาวะ
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_33} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การทำกิจกรรม
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_34} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การออกกำลังกายที่เหมาะสม
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_35} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การงดดื่มเหล้าและสูบบุหรี่
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_36} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                การหลีกเลี่ยงการใช้สมุนไพร/ยา NSAIDS
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_37} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                คำแนะนำให้กับผู้ป่วย
              </td>
              <td
                colSpan={2}
                style={{
                  paddingLeft: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea value={dataForm.data_38} mode="view" />
              </td>
            </tr>
          </tbody>
        </table>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormHeartFailureClinicPaper;
