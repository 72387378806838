import Api from "../../Components/Api";

async function fetchPatientVisit(hn, txn) {
    try {
        var result = await Api.consult("GetPatientVisit", {
            hn: hn,
            txn: txn,
        });

        return result.data || {}
    } catch (error) {
        console.error(`GetPatientVisit hn(${hn}) txn(${txn})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchPatientVisit;