import React, {Component} from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import HeaderSub from '../../Components/Member/HeaderSub';
import DashboadUsers from '../../Components/Member/DashboardUsers';
import DashboadDepartment from '../../Components/Member/DashboardDepartment';
import DashboardCampaign from '../../Components/Member/DashboardCampaign';
import DashboardConsultant from '../../Components/Member/DashboardConsultant';
import DashboardSex from '../../Components/Member/DashboardSex';
import DashboardAvgBmiLoss from '../../Components/Member/DashboardAvgBmiLoss';
import DashboardDept from '../../Components/Member/DashboardDept';
import DashboardUserHealthOpnion from '../../Components/Member/DashboardUserHealthOpnion';
import DashboardCompanyLogo from '../../Components/Member/DashboardCompanyLogo';
//import DashboardLastContent from '../../Components/Member/DashboardLastContent';
import DashboardDeptTable from '../../Components/Member/DashboardDeptTable';
import DashboardHealthRiskByDepartment from '../../Components/Member/DashboardHealthRiskByDepartment.js';
import DashboardHealthRiskByJob from '../../Components/Member/DashboardHealthRiskByJob.js';

UIkit.use(Icons);

export default class MemberDashboard extends Component {
  state = {
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="DASHBOARD" />
      <div className="uk-flex-middle uk-margin-small-top" uk-grid="">
        <div className="uk-width-1-2@l uk-width-1-1">
          <DashboardCompanyLogo />
        </div>
        <div className="uk-width-1-4@l uk-width-1-2@s uk-width-1-1">
          <DashboadUsers />
          <div className="uk-margin-top">
            <DashboardCampaign />
          </div>
        </div>
        <div className="uk-width-1-4@l uk-width-1-2@s uk-width-1-1">
          <DashboadDepartment />
          <div className="uk-margin-top">
            <DashboardConsultant />
          </div>
        </div>
      </div>
      <div uk-grid="">
        <div className="uk-width-1-2@l uk-width-1-1">
          <DashboardDept />
        </div>
        <div className="uk-width-1-2@l uk-width-1-1">
          <DashboardDeptTable />
        </div>
      </div>
      <div uk-grid="">
        <div className="uk-width-1-2@l uk-width-1-1">
          <DashboardSex />
        </div>
        <div className="uk-width-1-2@l uk-width-1-1">
          <DashboardAvgBmiLoss />
        </div>
      </div>
      <div uk-grid="">
        <div className="uk-width-1-1">
          <DashboardUserHealthOpnion />
        </div>
      </div>
      <div uk-grid="">
        <div className="uk-width-1-1">
          <DashboardHealthRiskByDepartment />  
        </div>
      </div>
      <div uk-grid="">
        <div className="uk-width-1-1">
          <DashboardHealthRiskByJob />  
        </div>
      </div>
    </div>
  }
};
