import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import FormElementDatePicker from "../Form/FormElementDatePicker";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormPosScalePaper from "../FormPaper/FormPosScalePaper.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";


var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormPosScale = (props) => {
  const formConstants = {
    formName: "form_poscale",
    formType: "3",
  };

  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const getScore1 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
      dataForm.data_10n,
    ]);
  }, [dataForm]);

  const getScore2 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_13n,
      dataForm.data_14n,
      dataForm.data_15n,
      dataForm.data_16n,
      dataForm.data_17n,
      dataForm.data_18n,
      dataForm.data_19n,
      dataForm.data_20n,
      dataForm.data_21n,
      dataForm.data_22n,
    ]);
  }, [dataForm]);

  // useEffect(() => {
  //   props.setScore(getScore1 + getScore2);
  // }, [dataForm]);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      hideLoading();
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([patientData, patientRoomRequest]).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];
      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);

  useEffect(() => {
    if (isSubForm)
      if (!formId && hn && txn) fetchData(null, hn, txn)
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });


  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html)
  }, [dataForm, onAdd]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div uk-grid="" className="uk-grid-small uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="แบบประเมินผลลัพธ์การดูแลผู้ป่วยแบบประคับประคอง" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="(Palliative care Outcome Scale) (ฉบับใช้ถามผู้ดูแลหลักของผู้ป่วย)" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="โปรดใส่ตัวเลข (0-4) ที่ตรงกับความรู้สึกของท่านมากที่สุด ในช่วง 3 วันที่ผ่านมา" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div uk-grid="" className="uk-grid-small">
            <div className="uk-width-2-6@m uk-width-1-1">
              <FormElementLabel label="0 = ไม่มีอาการ/ ได้ข้อมูลหรือความช่วยเหลือเต็มที่" />
            </div>
            <div className="uk-width-4-6@m uk-width-1-1">
              <FormElementLabel label="1 = เล็กน้อย/ นาน ๆ ครั้ง" />
            </div>
            <div className="uk-width-2-6@m uk-width-1-1">
              <FormElementLabel label="2 = บางครั้ง" />
            </div>
            <div className="uk-width-4-6@m uk-width-1-1">
              <FormElementLabel label="3 = รุนแรง/ เป็นส่วนใหญ่" />
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <FormElementLabel label="4 = มากมายท่วมท้น/ไม่รู้สึกดีกับตัวเองเลย/เสียเวลามากกว่าครึ่งวัน/ปัญหาไม่ได้รับการช่วยเหลือ" />
            </div>
          </div>
        </div>

        <div className="uk-width-1-1@m uk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>
                  คะแนนผลลัพธ์การดูแลผู้ป่วยแบบประคับประคอง <br />
                  (Palliative care Outcome Scale )
                </th>
                <th>
                  แรกรับ
                  <FormElementDatePicker
                    value={dataForm.data_26}
                    onChange={(date) => handleChange("26", date)}
                    withTime
                  />
                </th>
                <th>
                  วันจำหน่าย
                  <FormElementDatePicker
                    value={dataForm.data_27}
                    onChange={(date) => handleChange("27", date)}
                    withTime
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1. ผู้ป่วยมีอาการปวดหรือไม่</td>
                <td>
                  <FormElementTextBox
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"13n"}
                    value={dataForm.data_13n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  2. ผู้ป่วยมีอาการอื่นๆที่มีผลกระทบต่อความรู้สึกของท่าน ได้แก่
                  <FormElementTextBox
                    fieldId={"25"}
                    value={dataForm.data_25}
                    onChange={handleChange}
                    label=""
                    width="50"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"14n"}
                    value={dataForm.data_14n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  3. ผู้ป่วยรู้สึกหงุดหงิดหรือกังวลใจเกี่ยวกับความเจ็บป่วย
                  หรือการรักษาหรือไม่
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"15n"}
                    value={dataForm.data_15n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  4.
                  สมาชิกในครอบครัวของผู้ป่วยหรือเพื่อนของผู้ป่วยมีความวิตกกังวลเกี่ยวกับ
                  ผู้ป่วยหรือไม่
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"16n"}
                    value={dataForm.data_16n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  5. ผู้ป่วยและสมาชิกในครอบครัว
                  หรือเพื่อนของท่านได้รับข้อมูลเกี่ยวกับการ
                  ดูแลรักษามากน้อยเพียงใด
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"17n"}
                    value={dataForm.data_17n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  6.
                  ผู้ป่วยได้เล่าระบายความรู้สึกให้กับสมาชิกในครอบครัวหรือเพื่อนหรือไม่
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"6n"}
                    value={dataForm.data_6n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"18n"}
                    value={dataForm.data_18n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>7. ท่านคิดว่าผู้ป่วยเศร้าใจหรือไม่</td>
                <td>
                  <FormElementTextBox
                    fieldId={"7n"}
                    value={dataForm.data_7n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"19n"}
                    value={dataForm.data_19n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>8. ท่านคิดว่าผู้ป่วยรู้สึกดีกับตัวเองหรือไม่</td>
                <td>
                  <FormElementTextBox
                    fieldId={"8n"}
                    value={dataForm.data_8n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"20n"}
                    value={dataForm.data_20n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  9.
                  ท่านรู้สึกว่าผู้ป่วยเสียเวลาในการนัดหมายเกี่ยวกับการดูแลสุขภาพของผู้ป่วย
                  หรือไม่ เช่น รอคอยการเจาะเลือด
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"9n"}
                    value={dataForm.data_9n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"21n"}
                    value={dataForm.data_21n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  10.
                  ผู้ป่วยได้รับความช่วยเหลือในการแก้ไขปัญหาที่เกิดจากความเจ็บป่วยของผู้ป่วย
                  ทั้งด้านปัญหาการเงิน หรือปัญหาส่วนตัวหรือไม่
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"10n"}
                    value={dataForm.data_10n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"22n"}
                    value={dataForm.data_22n}
                    onChange={handleChange}
                    label=""
                    type="numeric"
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
              <tr>
                <td>คะแนนรวม</td>
                <td>{getScore1}</td>
                <td>{getScore2}</td>
              </tr>
              <tr>
                <td>ปัญหาสําคัญของผู้ป่วยคือ</td>
                <td>
                  <FormElementTextBox
                    fieldId={"11"}
                    value={dataForm.data_11}
                    onChange={handleChange}
                    label=""
                    width="50"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"23"}
                    value={dataForm.data_23}
                    onChange={handleChange}
                    label=""
                    width="50"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  ความสามารถในการปฏิบัติกิจกรรมของผู้ป่วย <br />
                  0 = ปฏิบัติกิจกรรมได้ ตามปกติ ไม่มีอาการแสดงของโรค <br />
                  1 = ปฏิบัติกิจกรรมได้ ตามปกติ มีอาการแสดงของโรคเล็กน้อย <br />
                  2 = มีข้อจํากัดในการปฏิบัติกิจกรรม มีอาการของโรค <br />
                  3 = ช่วยเหลือตนเองได้น้อยลง อยู่บนเตียงมากกว่าครึ่งวัน <br />4 =
                  ช่วยเหลือตนเองได้น้อยมาก อยู่บนเตียงทั้งวัน
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"12n"}
                    value={dataForm.data_12n}
                    //   fieldValue={"1"}
                    onChange={handleChange}
                    label=""
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"24n"}
                    value={dataForm.data_24n}
                    //   fieldValue={"1"}
                    onChange={handleChange}
                    label=""
                    width="50"
                    regEx={/^[0,1,2,3,4]{0,1}?$/}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="uk-width-2-6@m uk-width-1-1">
          <FormElementLabel label="หมายเหตุ กรณีผู้ป่วยเสียชีวิตก่อนประเมินครั้งถัดไป ไม่ต้องเมิน POS" />
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <FormPosScalePaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
            />}
        />
      </div>
    </>
  );
};

export default FormPosScale;
