import React, { useState, useEffect } from "react";

import "../../../css/dietz.css";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementTitle from "../Form/FormElementTitle";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

const FormCathLabAssessmentPaper = (props) => {
  const { dataForm, patient, profile } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="Catheterization Laboratory Assessment"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="Cardiac Center, Maharaj Nakorn Chiang Mai Hospital tel: 36711, 36712"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <div style={{ paddingRight: "20px" }}>
            <FormElementTextBox
              label="ward: "
              value={dataForm.data_4}
              mode="view"
            />
          </div>
          <div style={{ paddingRight: "20px" }}>
            <FormElementTextBox
              label="Date: "
              type="date"
              value={dataForm.data_5}
              mode="view"
            />
          </div>
          <div style={{ paddingRight: "20px" }}>
            <FormElementTextBox
              label="Cathno: "
              value={dataForm.data_6}
              mode="view"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <div style={{ paddingRight: "20px" }}>
            <FormElementTextBox
              label="Procedure perform: "
              value={dataForm.data_7}
              mode="view"
            />
          </div>
          <div style={{ paddingRight: "20px" }}>
            <FormElementTextBox
              label="Diagnosis: "
              value={dataForm.data_8}
              mode="view"
            />
          </div>
          <div
            style={{ paddingRight: "20px", display: "inline-flex", gap: "4px" }}
          >
            <FormElementTextBox
              label="น้ำหนัก: "
              value={dataForm.data_9}
              mode="view"
            />
            <FormElementLabel label="กก." mode="view" />
          </div>
          <div
            style={{ paddingRight: "20px", display: "inline-flex", gap: "4px" }}
          >
            <FormElementTextBox
              label="สูง: "
              value={dataForm.data_10}
              mode="view"
            />
            <FormElementLabel label="ซม." mode="view" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            label="Consent: "
            padding="0px 10px 0px 0px"
            mode="view"
          />

          <FormElementLabel
            value={dataForm.data_11}
            translate={{ 1: "Yes", 2: "No" }}
            mode="view"
          />

          {dataForm.data_11 == "2" && (
            <FormElementTextBox value={": " + dataForm.data_12} mode="view" />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
        >
          <FormElementLabel
            label="สิทธิบัตร: "
            padding="0px 10px 0px 0px"
            mode="view"
          />

          <FormElementLabel
            value={dataForm.data_13}
            translate={{
              1: "เบิกได้",
              2: "บัตรทอง",
              3: "ปกส.",
              4: "จ่ายเอง",
              5: "อื่น ๆ",
            }}
            mode="view"
          />
          {dataForm.data_13 == "5" && (
            <FormElementTextBox value={" : " + dataForm.data_14} mode="view" />
          )}
        </div>
        <div style={{ paddingTop: "10px" }}>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={4}
                >
                  <FormElementTextBox
                    value={dataForm.data_15}
                    label="Body Temp = "
                    type="decimal"
                    mode="view"
                    width="100"
                  />
                  <span style={{ paddingLeft: "10px" }}>c</span>
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={10}
                >
                  Medication check list
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Yes / No
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={9}
                >
                  Lab Check list
                </th>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_16}
                    label="Hb"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_17}
                    label="BUN"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_18}
                    label="PT"
                    mode="view"
                    width="100"
                  />
                  <FormElementTextBox
                    value={dataForm.data_19}
                    label="PTT"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ASA
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_20}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_21}
                    label="Hct"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_22}
                    label="Cr"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_23}
                    label="INR"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Clopidogrel
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_24}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_25}
                    label="WBC"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_26}
                    label="eGFR"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ paddingRight: "10px" }}>HIV</span>

                    <FormElementLabel
                      value={dataForm.data_27}
                      translate={{ 1: "neg", 2: "pos", 3: "NA" }}
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Ticagrelor
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_115}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_30}
                    label="Plt"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_31}
                    label="K"
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span>ATK</span>
                  <FormElementLabel
                    value={dataForm.data_108}
                    translate={{ 0: "No", 1: "Yes" }}
                    mode="view"
                    width="100"
                  />
                  <span hidden={dataForm.data_108 != "1"}>
                    <FormElementLabel
                      value={dataForm.data_107}
                      translate={{ 0: "Negetive", 1: "Positive" }}
                      mode="view"
                      width="100"
                    />
                  </span>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Prasugrel
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_116}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={5}
                  colSpan={3}
                >
                  <span style={{ paddingRight: "10px" }}>Other Lab:</span>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    {dataForm.data_35?.map((item) => (
                      <span>{item.value}</span>
                    ))}
                  </div>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Enoxaparin
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_117}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Wafarin
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_118}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  NOAC
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_119}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Last dose at &nbsp;&nbsp;
                  <FormElementLabel
                    label={dataForm.data_33}
                    mode="mode"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_34}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  No of dose &nbsp;&nbsp;
                  <FormElementLabel
                    label={dataForm.data_36}
                    mode="mode"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_37}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={4}
                >
                  Pre-Cath assessment
                </th>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                    }}
                  >
                    <span style={{ paddingRight: "10px" }}>NPO</span>
                    <FormElementLabel
                      value={dataForm.data_38}
                      translate={{ 1: "Yes", 0: "No" }}
                      mode="view"
                      padding="0px 10px 0px 0px"
                    />
                    {dataForm.data_38 == "1" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <span style={{ paddingRight: "10px" }}>time </span>
                        <FormElementTextBox
                          value={dataForm.data_39}
                          label=""
                          mode="view"
                          width="100"
                        />
                      </div>
                    )}
                  </div>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>Clean&shave</span>
                  <FormElementLabel
                    value={dataForm.data_109}
                    translate={{ 1: "Yes" }}
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                </td>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={5}
                >
                  Vascular Complications Risk
                </th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      gap: '8px',
                      flexWrap: "wrap",
                    }}
                  >
                    <FormElementLabel
                      label="IV, Plug"
                      mode="view"
                      padding="0px 10px 0px 0px"
                    />
                    <FormElementLabel
                      value={dataForm.data_40}
                      translate={{ 1: "Yes" }}
                      mode="view"
                      padding="0px 10px 0px 0px"
                    />
                    <FormElementTextBox
                      value={dataForm.data_41}
                      label="note"
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={3}
                >
                  <span style={{ paddingRight: "10px" }}>PVD</span>
                  <FormElementLabel
                    value={dataForm.data_42}
                    translate={{ 0: "Yes", 1: "No", 2: "Unknown" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <span style={{ paddingRight: "10px" }}>
                      Allergic history
                    </span>
                    <FormElementLabel
                      value={dataForm.data_43}
                      translate={{ 0: "No", 1: "Yes" }}
                      mode="view"
                    />
                    <span hidden={dataForm.data_43 != "1"} style={{ marginLeft: '8px' }}>
                      <FormElementTextBox
                        value={dataForm.data_44}
                        label="note"
                        mode="view"
                        width="100"
                      />
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  <span style={{ paddingRight: "10px" }}>HT</span>
                  <FormElementLabel
                    value={dataForm.data_45}
                    translate={{ 0: "Yes", 1: "No", 2: "Unknown" }}
                    mode="view"
                  />
                </td>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  rowSpan={5}
                >
                  CAD risk
                </th>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_47}
                      translate={{ 1: "Dorsalis pedis", 2: "Other" }}
                      mode="view"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {dataForm.data_47 == "1" &&
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <FormElementLabel
                          value={dataForm.data_111}
                          translate={{ 1: "Left", 2: "Right" }}
                          mode="view"
                          padding="0px 10px 0px 0px"
                        />
                        {dataForm.data_111 == "1" &&
                          <FormElementTextBox
                            value={dataForm.data_48}
                            mode="view"
                            width="100"
                          />}
                        {dataForm.data_111 == "2" &&
                          <FormElementTextBox
                            value={dataForm.data_49}
                            mode="view"
                            width="100"
                          />}
                      </div>
                    }
                    {dataForm.data_47 == "2" &&
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <FormElementTextBox
                            value={dataForm.data_112}
                            mode="view"
                          />
                          <FormElementLabel
                            value={dataForm.data_113}
                            translate={{ 1: "Left", 2: "Right" }}
                            mode="view"
                            padding="0px 10px 0px 0px"
                          />
                          {dataForm.data_113 == "1" &&
                            <FormElementTextBox
                              value={dataForm.data_114}
                              mode="view"
                              width="100"
                            />}
                          {dataForm.data_113 == "2" &&
                            <FormElementTextBox
                              value={dataForm.data_120}
                              mode="view"
                              width="100"
                            />}
                        </div>
                      </div>}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  <span style={{ paddingRight: "10px" }}>DM</span>
                  <FormElementLabel
                    value={dataForm.data_50}
                    translate={{ 0: "Yes", 1: "No", 2: "Unknown" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ paddingRight: "10px" }}>
                      Bleeding risk score (BRC)
                    </span>
                    <FormElementLabel
                      value={dataForm.data_51}
                      translate={{ 1: "Yes", 2: "No" }}
                      mode="view"
                    />
                  </div>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  <span style={{ paddingRight: "10px" }}>DLP</span>
                  <FormElementLabel
                    value={dataForm.data_52}
                    translate={{ 0: "Yes", 1: "No", 2: "Unknown" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_53}
                    label="GRACE score ="
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={2}
                >
                  <span style={{ paddingRight: "10px" }}>Family history</span>
                  <FormElementLabel
                    value={dataForm.data_54}
                    translate={{ 0: "Yes", 1: "No", 2: "Unknown" }}
                    mode="view"
                  />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row", gap: '8px' }}>
                    <span style={{ paddingRight: "10px" }}>One day</span>
                    <FormElementLabel
                      value={dataForm.data_55}
                      translate={{ 0: "Yes", 1: "No" }}
                      mode="view"
                    />
                    <FormElementTextBox
                      value={dataForm.data_56}
                      label="note"
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
                <td
                  colSpan={2}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ paddingRight: "10px" }}>Smoking</span>
                    <FormElementLabel
                      value={dataForm.data_57}
                      translate={{ 0: "No", 1: "Ex", 2: "Yes" }}
                      mode="view"
                    />
                    <FormElementTextBox
                      value={dataForm.data_58}
                      mode="view"
                      width="100"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={8}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_59}
                    label="Telephone number:"
                    type="numeric"
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <FormElementLabel label="แบบบันทึกทางการพยาบาล" mode="view" />
          <table
            style={{
              paddingTop: "10px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ปัญหา
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผลที่คาดหวัง
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ประเมินผล
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1.วิตกกังวลเกียวกับการรักษา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1.ความวิตกกังวลลดลง
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_60}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2.พร่องความรู้เกี่ยวกับการตรวจรักษา
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  2.ให้ความร่วมมือปฏิบัติตัวถูกต้อง
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_61}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3.อาจเกิด/เสี่ยงต่อภาวะ vasovagal reflex
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3.ไม่มีภาวะ vasovagal reflex
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_62}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4.อาจเกิด/เสี่ยงต่อภาวะเลือดอุดตันจากก้อนเลือดหรือฟองอากาศ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4.ระดับความรู้สึกตัวปกติ peripheral perfusion ปกติ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_63}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5.อาจเกิด/เสี่ยงต่อภาวะ vascular complications
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  5.แผลไม่มีเลือดออกไม่ปวดไม่บวม
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_64}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6.อาจเกิด/เสี่ยงต่ออันตรายจากการได้รับสารทึบรังสีเช่นแพ้สารทึบรังสี
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  6.ไม่มีอาการแสดงของการแพ้สารทึบรังสี
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_65}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7.อาจเกิด/เสี่ยงต่อการติดเชื้อบริเวณที่ใส่สายสวนหรือในกระแสโลหิต
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  7.ไม่มีอาการแสดงของการติดเชื้อ
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_66}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Anxiety
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  pain
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Level rating scale
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                ></th>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Pre procedure
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_67}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_68}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  mild
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_69}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  During procedure
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_70}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_71}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  moderate
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_72}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Post procedure
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_73}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_74}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  severe
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox
                    value={dataForm.data_75}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{ paddingTop: "10px", display: "flex", flexDirection: "row" }}
        >
          <div
            style={{
              paddingRight: "10px",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_76}
              label=" ชื่อ-นามสกุล: "
              mode="view"
              width="100"
            />
          </div>
          <div
            style={{
              paddingRight: "10px",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_77}
              label=" HN: "
              mode="view"
              width="100"
            />
          </div>
          <div
            style={{
              paddingRight: "10px",
            }}
          >
            <FormElementTextBox
              value={dataForm.data_78}
              label=" Date: "
              type="date"
              mode="view"
              width="100"
            />
          </div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <table
            style={{ borderCollapse: "collapse", border: "1px solid grey" }}
          >
            <tbody>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  แผนการพยาบาล
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ✓
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  Time
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  PR
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  SpO2RR
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  IBP
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  BP
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  อาการ/การพยาบาล/การประเมิน
                </th>
              </tr>
              <tr hidden={dataForm?.data_79?.data_1 != "1"}>
                <td>
                  ประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม
                </td>
                <td>
                  {dataForm?.data_79?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_79?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_79?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_79?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_79?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_79?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_79?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_80?.data_1 != "1"}>
                <td>
                  อธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย
                </td>
                <td>
                  {dataForm?.data_80?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_80?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_80?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_80?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_80?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_80?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_80?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_81?.data_1 != "1"}>
                <td>ตรวจสอบ/ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ</td>
                <td>
                  {dataForm?.data_81?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_81?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_81?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_81?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_81?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_81?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_81?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_82?.data_1 != "1"}>
                <td>
                  สังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ
                </td>
                <td>
                  {dataForm?.data_82?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_82?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_82?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_82?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_82?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_82?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_82?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_83?.data_1 != "1"}>
                <td>Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม</td>
                <td>
                  {dataForm?.data_83?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_83?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_83?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_83?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_83?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_83?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_83?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_84?.data_1 != "1"}>
                <td>เตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้</td>
                <td>
                  {dataForm?.data_84?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_84?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_84?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_84?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_84?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_84?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_84?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_85?.data_1 != "1"}>
                <td>ประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง</td>
                <td>
                  {dataForm?.data_85?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_85?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_85?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_85?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_85?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_85?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_85?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_86?.data_1 != "1"}>
                <td>สวนล้างสายสวนทุกชนิดด้วย NSS + heparin</td>
                <td>
                  {dataForm?.data_86?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_86?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_86?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_86?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_86?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_86?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_86?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_87?.data_1 != "1"}>
                <td>ทำความสะอาดลวดนำก่อนใช้ทุกครั้ง</td>
                <td>
                  {dataForm?.data_87?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_87?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_87?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_87?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_87?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_87?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_87?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_88?.data_1 != "1"}>
                <td>
                  ไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย
                </td>
                <td>
                  {dataForm?.data_88?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_88?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_88?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_88?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_88?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_88?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_88?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_89?.data_1 != "1"}>
                <td>
                  กดแผลห้ามเลือดจนเลือดหยุด pressure dressing/หมอนทรายทับแผล
                </td>
                <td>
                  {dataForm?.data_89?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_89?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_89?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_89?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_89?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_89?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_89?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_90?.data_1 != "1"}>
                <td>สังเกตภาวะเลือดออกปวดบวมบริเวณแผล</td>
                <td>
                  {dataForm?.data_90?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_90?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_90?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_90?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_90?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_90?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_90?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_91?.data_1 != "1"}>
                <td>I=ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ/Timeout</td>
                <td>
                  {dataForm?.data_91?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_91?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_91?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_91?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_91?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_91?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_91?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_92?.data_1 != "1"}>
                <td>I=handhygiene/keepsterile</td>
                <td>
                  {dataForm?.data_92?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_92?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_92?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_92?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_92?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_92?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_92?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_93?.data_1 != "1"}>
                <td>S=ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน</td>
                <td>
                  {dataForm?.data_93?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_93?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_93?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_93?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_93?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_93?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_93?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_94?.data_1 != "1"}>
                <td>I=ตรวจสอบ IVtype, rate, IVsite</td>
                <td>
                  {dataForm?.data_94?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_94?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_94?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_94?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_94?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_94?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_94?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_95?.data_1 != "1"}>
                <td>T=เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ</td>
                <td>
                  {dataForm?.data_95?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_95?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_95?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_95?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_95?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_95?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_95?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_96?.data_1 != "1"}>
                <td>D=ปรับระดับเตียงให้สะดวกขึ้นลง</td>
                <td>
                  {dataForm?.data_96?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_96?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_96?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_96?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_96?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_96?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_96?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_97?.data_1 != "1"}>
                <td>O=ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา</td>
                <td>
                  {dataForm?.data_97?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_97?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_97?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_97?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_97?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_97?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_97?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_98?.data_1 != "1"}>
                <td>W=ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน</td>
                <td>
                  {dataForm?.data_98?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_98?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_98?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_98?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_98?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_98?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_98?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
              <tr hidden={dataForm?.data_99?.data_1 != "1"}>
                <td>N=สอบถามและตอบสนองความต้องการความช่วยเหลือ</td>
                <td>
                  {dataForm?.data_99?.data_1 == "1" && (
                    <FormElementLabel label="✓" mode="view" />
                  )}
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_99?.data_2}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_99?.data_3}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_99?.data_4}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_99?.data_5}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    value={dataForm?.data_99?.data_6}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextArea
                    cols={50}
                    rows={2}
                    value={dataForm?.data_99?.data_7}
                    label=""
                    mode="view"
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", gap: "4px", flexDirection: "column" }}>
            <div style={{ display: "inline-flex", gap: "4px" }}>
              <FormElementTextBox
                label="Contrast Type"
                value={dataForm?.data_101}
                mode="view"
                width="100"
              />
              <FormElementLabel label="/" mode="view" />
              <FormElementTextBox
                value={dataForm?.data_102}
                mode="view"
                width="100"
              />
              <FormElementLabel label="ml." mode="view" />
            </div>
            <div style={{ display: "inline-flex", gap: "4px" }}>
              <FormElementTextBox
                label="Flu time"
                value={dataForm?.data_103}
                mode="view"
                width="100"
              />
              <FormElementLabel label="min" mode="view" />
              <FormElementLabel label="/" mode="view" />
              <FormElementTextBox
                label="Exposure dose"
                value={dataForm?.data_104}
                mode="view"
                width="100"
              />
              <FormElementLabel label="mGy." mode="view" />
              <FormElementLabel label="/" mode="view" />
              <FormElementTextBox
                label="Area dose"
                value={dataForm?.data_105}
                mode="view"
                width="100"
              />
              <FormElementLabel label="cGyCm2" mode="view" />
            </div>
          </div>

          <div>
            <span hidden={!dataForm.form_id}>
              <FormElementTextArea
                label="เหตุผลในการแก้ไข"
                value={dataForm.data_106}
                mode="view"
              />
            </span>
          </div>
          <SavingProfileIPaper profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default FormCathLabAssessmentPaper;
