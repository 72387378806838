import React, { useState, useEffect, useRef, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import Api from "../../Api";
import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import FormElementTagInput from "../Form/FormElementTagInput";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilGetValueChanged = require("./FormUtil.js").getValueChanged;
var utilConvertDisplayDate = require("./FormUtil.js").convertDisplayDate;

const FormCathLabAssessment = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [historyWardList, setHistoryWardList] = useState([]);
  // var [historyWard, setHistoryWard] = useState({});

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_cathlabassessment",
      formType: "11",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer.current]);

  const onTagInputChange = useCallback((fieldId, data) => {
    var valueData = [];
    if (data) {
      valueData = data.map((value, index) => ({
        key: index,
        value: value,
      }));
    }
    setData(fieldId, valueData);
  });

  async function getHistoryWard() {
    try {
      if (props.hn) {
        var result = await Api.consult("GetHistoryWardByHN", {
          hn: props.hn,
        });

        if (result.list) {
          // setHistoryWard(result.list[0]);
          setHistoryWardList(result.list);
        } else {
          // setHistoryWard({});
          setHistoryWardList([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getHistoryWard();
  }, [props.hn]);

  // useEffect(() => {
  //   if (historyWard) {
  //     setData("5", historyWard.adm);
  //   } else {
  //     setData("5", null);
  //   }
  // }, [historyWard]);

  const handleJsonValueChange = useCallback((i, key, event) => {
    const value = utilGetValueChanged(event);
    const json = { ...dataForm[i], [key]: value };
    props.addValue({ [i]: json });
  });

  return (
    <div ref={parentTableContainer} style={{ overflowX: "hidden" }}>
      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="Catheterization Laboratory Assessment" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="Cardiac Center,Maharaj Nakorn Chiang Mai Hospital tel: 36711,36712" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTextBox
            fieldId={"4"}
            value={dataForm.data_4}
            label="ward"
            onChange={handleChange}
            width="100"
            isRequired
          />
          {/* <FormElementTextBox
            fieldId={"5"}
            value={dataForm.data_5}
            type="date"
            label="Date"
            onChange={handleChange}
            width="100"
            isRequired
          /> */}
          <FormElementDropdown
            fieldId={"5"}
            value={dataForm.data_3}
            label="Date"
            dataList={[{ value: "", label: "เลือกวันที่" }].concat(
              historyWardList.map((item) => ({
                value: item.adm,
                label: utilConvertDisplayDate(item.adm),
              }))
            )}
            onChange={handleChange}
            width="100"
            isRequired
          />
          <FormElementTextBox
            fieldId={"6"}
            value={dataForm.data_6}
            label="Cath no"
            onChange={handleChange}
            width="100"
            isRequired
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              rowGap: "14px",
              columnGap: "10px",
            }}
          >
            <FormElementTextBox
              fieldId={"7"}
              value={dataForm.data_7}
              label="Procedure perform"
              onChange={handleChange}
              width="100"
              isRequired
            />
            <FormElementTextBox
              fieldId={"8"}
              value={dataForm.data_8}
              label="Diagnosis"
              onChange={handleChange}
              width="100"
              isRequired
            />
            <div className="uk-display-inline">
              <FormElementTextBox
                fieldId={"9"}
                value={dataForm.data_9}
                type="decimal"
                label="น้ำหนัก"
                onChange={handleChange}
                width="100"
                isRequired
              />
              <span className="formTextBoxLabel">กก.</span>
            </div>
            <div className="uk-display-inline">
              <FormElementTextBox
                fieldId={"10"}
                value={dataForm.data_10}
                type="decimal"
                label="สูง"
                onChange={handleChange}
                width="100"
                isRequired
              />
              <span className="formTextBoxLabel">ซม.</span>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <span className="formTextBoxLabel">
            Consent <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"1"}
            onChange={handleChange}
            label="Yes"
          />
          <FormElementRadio
            fieldId={"11"}
            value={dataForm.data_11}
            fieldValue={"2"}
            onChange={handleChange}
            label="No"
          />
          <span
            className={dataForm.data_11 == "2" ? "form-show" : "form-hidden"}
          >
            <FormElementTextBox
              fieldId={"12"}
              value={dataForm.data_12}
              label=""
              onChange={handleChange}
              width="100"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <span className="formTextBoxLabel">
            สิทธิบัตร <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementDropdown
            fieldId={"13"}
            value={dataForm.data_13}
            dataList={[
              { value: "0", label: "เลือกสิทธิบัตร" },
              { value: "1", label: "เบิกได้" },
              { value: "2", label: "บัตรทอง" },
              { value: "3", label: "ปกส." },
              { value: "4", label: "จ่ายเอง" },
              { value: "5", label: "อื่นๆ" },
            ]}
            onChange={handleChange}
            width="100"
          />
          <span
            className={dataForm.data_13 == "5" ? "form-show" : "form-hidden"}
          >
            &nbsp;&nbsp;&nbsp;
            <FormElementTextBox
              fieldId={"14"}
              value={dataForm.data_14}
              label=""
              onChange={handleChange}
              width="100"
              placeholder="ระบุ"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div
            className="form-table-container"
            style={{ maxWidth: `${tableMaxWidth}px` }}
          >
            <div className="form-table-scroll">
              <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                <tbody>
                  <tr>
                    <th colSpan={4}>
                      <FormElementTextBox
                        fieldId={"15"}
                        value={dataForm.data_15}
                        label="Body Temp= "
                        type="decimal"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                      <span className="formTextBoxLabel">c</span>
                    </th>
                    <th rowSpan={10} className="center">
                      Medication check list
                    </th>
                    <th></th>
                    <th>Yes</th>
                    <th>No</th>
                  </tr>
                  <tr>
                    <th rowSpan={9} className="center">
                      Lab Check list
                    </th>
                    <td>
                      <FormElementTextBox
                        fieldId={"16"}
                        value={dataForm.data_16}
                        label="Hb"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"17"}
                        value={dataForm.data_17}
                        label="BUN"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"18"}
                        value={dataForm.data_18}
                        label="PT"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                      <FormElementTextBox
                        fieldId={"19"}
                        value={dataForm.data_19}
                        label="PTT"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      ASA <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"20"}
                        value={dataForm.data_20}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"20"}
                        value={dataForm.data_20}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementTextBox
                        fieldId={"21"}
                        value={dataForm.data_21}
                        label="Hct"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"22"}
                        value={dataForm.data_22}
                        label="Cr"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"23"}
                        value={dataForm.data_23}
                        label="INR"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      Clopidogrel <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"24"}
                        value={dataForm.data_24}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"24"}
                        value={dataForm.data_24}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementTextBox
                        fieldId={"25"}
                        value={dataForm.data_25}
                        label="WBC"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"26"}
                        value={dataForm.data_26}
                        label="eGFR"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <div className="uk-width-1-1@m uk-width-1-1">
                        <span className="formTextBoxLabel">
                          HIV <span style={{ color: "red" }}>*</span>
                        </span>
                        <FormElementRadio
                          fieldId={"27"}
                          value={dataForm.data_27}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label="neg"
                        />
                        <FormElementRadio
                          fieldId={"27"}
                          value={dataForm.data_27}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label="pos"
                        />
                        <FormElementRadio
                          fieldId={"27"}
                          value={dataForm.data_27}
                          fieldValue={"3"}
                          onChange={handleChange}
                          label="NA"
                        />
                      </div>
                    </td>
                    <td>
                      Ticagrelor <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"115"}
                        value={dataForm.data_115}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"115"}
                        value={dataForm.data_115}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementTextBox
                        fieldId={"30"}
                        value={dataForm.data_30}
                        label="Plt"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <FormElementTextBox
                        fieldId={"31"}
                        value={dataForm.data_31}
                        label="K"
                        onChange={handleChange}
                        width="100"
                        isRequired
                      />
                    </td>
                    <td>
                      <div className="dietz-input-container">
                        <span
                          className="formTextBoxLabel"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          ATK <span style={{ color: "red" }}>*</span> :
                        </span>
                        <FormElementRadio
                          fieldId={"108"}
                          value={dataForm.data_108}
                          label="No"
                          fieldValue="0"
                          onChange={handleChange}
                          width="100"
                        />
                        <FormElementRadio
                          fieldId={"108"}
                          value={dataForm.data_108}
                          label="Yes"
                          fieldValue="1"
                          onChange={handleChange}
                          width="100"
                        />
                        <span hidden={dataForm.data_108 != "1"}>
                          <FormElementRadio
                            fieldId={"107"}
                            value={dataForm.data_107}
                            label="Negative"
                            fieldValue="0"
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                        <span hidden={dataForm.data_999 != "1"}>
                          <FormElementRadio
                            fieldId={"107"}
                            value={dataForm.data_107}
                            label="Positive"
                            fieldValue="1"
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      Prasugrel <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"116"}
                        value={dataForm.data_116}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"116"}
                        value={dataForm.data_116}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} rowSpan={5}>
                      <span className="formTextBoxLabel">
                        Other Lab: <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementTagInput
                        value={
                          dataForm.data_35
                            ? dataForm.data_35.map((item) => item.value)
                            : []
                        }
                        onChange={(data) => onTagInputChange("35", data)}
                        dataList={["CXR", "EKG"]}
                        placeholder="ระบุ"
                      />
                    </td>
                    <td>
                      Enoxaparin <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"117"}
                        value={dataForm.data_117}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"117"}
                        value={dataForm.data_117}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Wafarin <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"118"}
                        value={dataForm.data_118}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"118"}
                        value={dataForm.data_118}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      NOAC <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"119"}
                        value={dataForm.data_119}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"119"}
                        value={dataForm.data_119}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Last dose at <span style={{ color: "red" }}>*</span>
                      &nbsp;&nbsp;{" "}
                      <FormElementTextBox
                        fieldId={"33"}
                        value={dataForm.data_33}
                        onChange={handleChange}
                        width="100"
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"34"}
                        value={dataForm.data_34}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"34"}
                        value={dataForm.data_34}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      No of dose <span style={{ color: "red" }}>*</span>
                      &nbsp;&nbsp;{" "}
                      <FormElementTextBox
                        fieldId={"36"}
                        value={dataForm.data_36}
                        onChange={handleChange}
                        width="100"
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"37"}
                        value={dataForm.data_37}
                        fieldValue={"1"}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <FormElementRadio
                        fieldId={"37"}
                        value={dataForm.data_37}
                        fieldValue={"0"}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan={4} className="center">
                      Pre-Cath assessment
                    </th>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        NPO <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"38"}
                        value={dataForm.data_38}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Yes,"
                      />
                      <span className="formTextBoxLabel">time </span>
                      <FormElementTextBox
                        fieldId={"39"}
                        value={dataForm.data_39}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormElementRadio
                        fieldId={"38"}
                        value={dataForm.data_38}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="No"
                      />
                    </td>
                    <td>
                      <span className="formTextBoxLabel">Clean&shave</span>
                      <FormElementCheckBox
                        fieldId={"109"}
                        value={dataForm.data_109}
                        onChange={handleChange}
                        label="Yes"
                      />
                    </td>
                    <th rowSpan={5} className="center">
                      Vascular Complications Risk
                    </th>
                    <td colSpan={3}></td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <span className="formTextBoxLabel">IV, Plug</span>
                      <FormElementCheckBox
                        fieldId={"40"}
                        value={dataForm.data_40}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementTextBox
                        fieldId={"41"}
                        value={dataForm.data_41}
                        label="note"
                        onChange={handleChange}
                        width="100"
                      />
                    </td>
                    <td colSpan={3}>
                      <span className="formTextBoxLabel">
                        PVD <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"42"}
                        value={dataForm.data_42}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"42"}
                        value={dataForm.data_42}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"42"}
                        value={dataForm.data_42}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <span className="formTextBoxLabel">
                        Allergic history <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"43"}
                        value={dataForm.data_43}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"43"}
                        value={dataForm.data_43}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <span hidden={dataForm.data_43 != "1"}>
                        <FormElementTextBox
                          fieldId={"44"}
                          value={dataForm.data_44}
                          label="note"
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        HT <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"45"}
                        value={dataForm.data_45}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"45"}
                        value={dataForm.data_45}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"45"}
                        value={dataForm.data_45}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                    <th rowSpan={5} className="center">
                      CAD risk
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <FormElementRadio
                            fieldId={"47"}
                            value={dataForm.data_47}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label="Dorsalis pedis"
                          />
                          <span hidden={dataForm.data_47 != "1"}>
                            <FormElementRadio
                              fieldId={"111"}
                              value={dataForm.data_111}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label="Left"
                            />
                            <FormElementTextBox
                              fieldId={"48"}
                              value={dataForm.data_48}
                              onChange={handleChange}
                              width="100"
                            />
                            <FormElementRadio
                              fieldId={"111"}
                              value={dataForm.data_111}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label="Right"
                            />
                            <FormElementTextBox
                              fieldId={"49"}
                              value={dataForm.data_49}
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <FormElementRadio
                            fieldId={"47"}
                            value={dataForm.data_47}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label="Other"
                          />
                          <span hidden={dataForm.data_47 != "2"}>
                            <FormElementTextBox
                              fieldId={"112"}
                              value={dataForm.data_112}
                              onChange={handleChange}
                              width="100"
                            />

                            <FormElementRadio
                              fieldId={"113"}
                              value={dataForm.data_113}
                              fieldValue={"1"}
                              onChange={handleChange}
                              label="Left"
                            />
                            <FormElementTextBox
                              fieldId={"114"}
                              value={dataForm.data_114}
                              onChange={handleChange}
                              width="100"
                            />
                            <FormElementRadio
                              fieldId={"113"}
                              value={dataForm.data_113}
                              fieldValue={"2"}
                              onChange={handleChange}
                              label="Right"
                            />
                            <FormElementTextBox
                              fieldId={"120"}
                              value={dataForm.data_120}
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        DM <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"50"}
                        value={dataForm.data_50}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"50"}
                        value={dataForm.data_50}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"50"}
                        value={dataForm.data_50}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <span className="formTextBoxLabel">
                        Bleeding risk score (BRC){" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"51"}
                        value={dataForm.data_51}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"51"}
                        value={dataForm.data_51}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="No"
                      />
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        DLP <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"52"}
                        value={dataForm.data_52}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"52"}
                        value={dataForm.data_52}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"52"}
                        value={dataForm.data_52}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <FormElementTextBox
                        fieldId={"53"}
                        value={dataForm.data_53}
                        label="GRACE score ="
                        onChange={handleChange}
                        width="100"
                        type="numeric"
                        isRequired
                      />
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        Family history <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"54"}
                        value={dataForm.data_54}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"54"}
                        value={dataForm.data_54}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"54"}
                        value={dataForm.data_54}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Unknown"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <span className="formTextBoxLabel">
                        One day <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"55"}
                        value={dataForm.data_55}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <FormElementRadio
                        fieldId={"55"}
                        value={dataForm.data_55}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="No,"
                      />
                      <FormElementTextBox
                        fieldId={"56"}
                        value={dataForm.data_56}
                        label="note"
                        onChange={handleChange}
                        width="100"
                        type="numeric"
                      />
                    </td>
                    <td colSpan={2}>
                      <span className="formTextBoxLabel">
                        Smoking <span style={{ color: "red" }}>*</span>
                      </span>
                      <FormElementRadio
                        fieldId={"57"}
                        value={dataForm.data_57}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label="No"
                      />
                      <FormElementRadio
                        fieldId={"57"}
                        value={dataForm.data_57}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label="Ex"
                      />
                      <FormElementRadio
                        fieldId={"57"}
                        value={dataForm.data_57}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label="Yes"
                      />
                      <span hidden={dataForm.data_57 != "2"}>
                        <FormElementTextBox
                          fieldId={"58"}
                          value={dataForm.data_58}
                          onChange={handleChange}
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={8}>
                      <FormElementTextBox
                        fieldId={"59"}
                        value={dataForm.data_59}
                        label="Telephone number:"
                        type="numeric"
                        onChange={handleChange}
                        width="100"
                        maxLength={10}
                        isRequired
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <FormElementLine />
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementLabel label="แบบบันทึกทางการพยาบาล" fontWeight="bold" />
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <thead>
              <tr>
                <th>ปัญหา</th>
                <th>ผลที่คาดหวัง</th>
                <th>
                  ประเมินผล <span style={{ color: "red" }}>*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.วิตกกังวลเกียวกับการรักษา</td>
                <td>1.ความวิตกกังวลลดลง</td>
                <td>
                  <FormElementTextBox
                    fieldId={"60"}
                    value={dataForm.data_60}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>2.พร่องความรู้เกี่ยวกับการตรวจรักษา</td>
                <td>2.ให้ความร่วมมือปฏิบัติตัวถูกต้อง</td>
                <td>
                  <FormElementTextBox
                    fieldId={"61"}
                    value={dataForm.data_61}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>3.อาจเกิด/เสี่ยงต่อภาวะ vasovagal reflex</td>
                <td>3.ไม่มีภาวะ vasovagal reflex</td>
                <td>
                  <FormElementTextBox
                    fieldId={"62"}
                    value={dataForm.data_62}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  4.อาจเกิด/เสี่ยงต่อภาวะเลือดอุดตันจากก้อนเลือดหรือฟองอากาศ
                </td>
                <td>4.ระดับความรู้สึกตัวปกติ peripheral perfusion ปกติ</td>
                <td>
                  <FormElementTextBox
                    fieldId={"63"}
                    value={dataForm.data_63}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>5.อาจเกิด/เสี่ยงต่อภาวะ vascular complications</td>
                <td>5.แผลไม่มีเลือดออกไม่ปวดไม่บวม</td>
                <td>
                  <FormElementTextBox
                    fieldId={"64"}
                    value={dataForm.data_64}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  6.อาจเกิด/เสี่ยงต่ออันตรายจากการได้รับสารทึบรังสีเช่นแพ้สารทึบรังสี
                </td>
                <td>6.ไม่มีอาการแสดงของการแพ้สารทึบรังสี</td>
                <td>
                  <FormElementTextBox
                    fieldId={"65"}
                    value={dataForm.data_65}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  7.อาจเกิด/เสี่ยงต่อการติดเชื้อบริเวณที่ใส่สายสวนหรือในกระแสโลหิต
                </td>
                <td>7.ไม่มีอาการแสดงของการติดเชื้อ</td>
                <td>
                  <FormElementTextBox
                    fieldId={"66"}
                    value={dataForm.data_66}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <FormElementLine />
        <div className="uk-width-1-1@muk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <tbody>
              <tr>
                <th></th>
                <th>
                  Anxiety <span style={{ color: "red" }}>*</span>
                </th>
                <th>
                  pain <span style={{ color: "red" }}>*</span>
                </th>
                <th>Level rating scale</th>
                <th></th>
              </tr>
              <tr>
                <td>Pre procedure</td>
                <td>
                  <FormElementTextBox
                    fieldId={"67"}
                    value={dataForm.data_67}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"68"}
                    value={dataForm.data_68}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>mild</td>
                <td>
                  <FormElementTextBox
                    fieldId={"69"}
                    value={dataForm.data_69}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>During procedure</td>
                <td>
                  <FormElementTextBox
                    fieldId={"70"}
                    value={dataForm.data_70}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"71"}
                    value={dataForm.data_71}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>moderate</td>
                <td>
                  <FormElementTextBox
                    fieldId={"72"}
                    value={dataForm.data_72}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
              <tr>
                <td>Post procedure</td>
                <td>
                  <FormElementTextBox
                    fieldId={"73"}
                    value={dataForm.data_73}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>
                  <FormElementTextBox
                    fieldId={"74"}
                    value={dataForm.data_74}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
                <td>severe</td>
                <td>
                  <FormElementTextBox
                    fieldId={"75"}
                    value={dataForm.data_75}
                    label=""
                    onChange={handleChange}
                    width="100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <FormElementLine />
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementTextBox
            fieldId={"76"}
            value={dataForm.data_76}
            label="ชื่อ-นามสกุล"
            onChange={handleChange}
            width="100"
            isRequired
          />
          <FormElementTextBox
            fieldId={"77"}
            value={dataForm.data_77}
            label="HN"
            onChange={handleChange}
            maxLength={7}
            type="numeric"
            width="100"
            isRequired
          />
          <FormElementTextBox
            fieldId={"78"}
            value={dataForm.data_78}
            label="Date"
            type="date"
            onChange={handleChange}
            width="100"
            isRequired
          />
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <div
            className="form-table-container"
            style={{ maxWidth: `${tableMaxWidth}px` }}
          >
            <div className="form-table-scroll">
              <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                <tbody>
                  <tr>
                    <th>แผนการพยาบาล</th>
                    <th>✓</th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Time <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      PR <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      SpO2RR <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      IBP <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      BP <span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ whiteSpace: "nowrap", minWidth: "400px" }}>
                      อาการ/การพยาบาล/การประเมิน{" "}
                      <span style={{ color: "red" }}>*</span>
                    </th>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ประเมินความรู้ความเข้าใจความวิตกกังวลของผู้ป่วยและญาติเปิดโอกาสให้ซักถาม
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_79.data_1"}
                        value={dataForm?.data_79?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_79", "data_1", e)
                        }
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_2"}
                          value={dataForm?.data_79?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_3"}
                          value={dataForm?.data_79?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_4"}
                          value={dataForm?.data_79?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_5"}
                          value={dataForm?.data_79?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_79.data_6"}
                          value={dataForm?.data_79?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_79?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_79.data_7"}
                          value={dataForm?.data_79?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_79", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      อธิบายให้ผู้ป่วยและญาติทราบเกี่ยวกับการตรวจรักษาและการปฏิบัติตัวของผู้ป่วย
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_80.data_1"}
                        value={dataForm?.data_80?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_80", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_2"}
                          value={dataForm?.data_80?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_3"}
                          value={dataForm?.data_80?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_4"}
                          value={dataForm?.data_80?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_5"}
                          value={dataForm?.data_80?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_80.data_6"}
                          value={dataForm?.data_80?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_80?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_80.data_7"}
                          value={dataForm?.data_80?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_80", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ตรวจสอบ / ดูแลให้ได้รับยาและสารน้ำทางหลอดเลือดดำ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_81.data_1"}
                        value={dataForm?.data_81?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_81", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_2"}
                          value={dataForm?.data_81?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_3"}
                          value={dataForm?.data_81?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_4"}
                          value={dataForm?.data_81?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_5"}
                          value={dataForm?.data_81?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_81.data_6"}
                          value={dataForm?.data_81?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_81?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_81.data_7"}
                          value={dataForm?.data_81?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_81", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      สังเกตการเปลี่ยนแปลงของผู้ป่วยอย่างใกล้ชิดขณะตรวจและหลังตรวจ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_82.data_1"}
                        value={dataForm?.data_82?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_82", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_2"}
                          value={dataForm?.data_82?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_3"}
                          value={dataForm?.data_82?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_4"}
                          value={dataForm?.data_82?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_5"}
                          value={dataForm?.data_82?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_82.data_6"}
                          value={dataForm?.data_82?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_82?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_82.data_7"}
                          value={dataForm?.data_82?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_82", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Monitor EKG, V/S, O2 sat ทุก15นาทีหรือตามความเหมาะสม
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_83.data_1"}
                        value={dataForm?.data_83?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_83", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_2"}
                          value={dataForm?.data_83?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_3"}
                          value={dataForm?.data_83?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_4"}
                          value={dataForm?.data_83?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_5"}
                          value={dataForm?.data_83?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_83.data_6"}
                          value={dataForm?.data_83?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_83?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_83.data_7"}
                          value={dataForm?.data_83?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_83", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      เตรียมยาอุปกรณ์ช่วยฟื้นคืนชีพให้พร้อมใช้
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_84.data_1"}
                        value={dataForm?.data_84?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_84", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_2"}
                          value={dataForm?.data_84?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_3"}
                          value={dataForm?.data_84?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_4"}
                          value={dataForm?.data_84?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_5"}
                          value={dataForm?.data_84?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_84.data_6"}
                          value={dataForm?.data_84?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_84?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_84.data_7"}
                          value={dataForm?.data_84?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_84", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ประเมินการไหลเวียนและ perfusion ปลายแขนหรือขาทั้ง2ข้าง
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_85.data_1"}
                        value={dataForm?.data_85?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_85", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_2"}
                          value={dataForm?.data_85?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_3"}
                          value={dataForm?.data_85?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_4"}
                          value={dataForm?.data_85?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_5"}
                          value={dataForm?.data_85?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_85.data_6"}
                          value={dataForm?.data_85?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_85?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_85.data_7"}
                          value={dataForm?.data_85?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_85", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      สวนล้างสายสวนทุกชนิดด้วย NSS + heparin
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_86.data_1"}
                        value={dataForm?.data_86?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_86", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_2"}
                          value={dataForm?.data_86?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_3"}
                          value={dataForm?.data_86?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_4"}
                          value={dataForm?.data_86?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_5"}
                          value={dataForm?.data_86?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_86.data_6"}
                          value={dataForm?.data_86?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_86?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_86.data_7"}
                          value={dataForm?.data_86?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_86", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ทำความสะอาดลวดนำก่อนใช้ทุกครั้ง
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_87.data_1"}
                        value={dataForm?.data_87?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_87", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_2"}
                          value={dataForm?.data_87?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_3"}
                          value={dataForm?.data_87?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_4"}
                          value={dataForm?.data_87?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_5"}
                          value={dataForm?.data_87?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_87.data_6"}
                          value={dataForm?.data_87?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_87?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_87.data_7"}
                          value={dataForm?.data_87?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_87", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      ไม่ให้ฟองอากาศถูกฉีดเข้าไปในขณะฉีดสารทึบรังสีเข้าตัวผู้ป่วย
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_88.data_1"}
                        value={dataForm?.data_88?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_88", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_2"}
                          value={dataForm?.data_88?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_3"}
                          value={dataForm?.data_88?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_4"}
                          value={dataForm?.data_88?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_5"}
                          value={dataForm?.data_88?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_88.data_6"}
                          value={dataForm?.data_88?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_88?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_88.data_7"}
                          value={dataForm?.data_88?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_88", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      กดแผลห้ามเลือดจนเลือดหยุด pressure dressing /
                      หมอนทรายทับแผล
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_90.data_1"}
                        value={dataForm?.data_90?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_90", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_2"}
                          value={dataForm?.data_90?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_3"}
                          value={dataForm?.data_90?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_4"}
                          value={dataForm?.data_90?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_5"}
                          value={dataForm?.data_90?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_90.data_6"}
                          value={dataForm?.data_90?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_90?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_90.data_7"}
                          value={dataForm?.data_90?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_90", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      สังเกตภาวะเลือดออกปวดบวมบริเวณแผล
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_91.data_1"}
                        value={dataForm?.data_91?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_91", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_2"}
                          value={dataForm?.data_91?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_3"}
                          value={dataForm?.data_91?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_4"}
                          value={dataForm?.data_91?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_5"}
                          value={dataForm?.data_91?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_91.data_6"}
                          value={dataForm?.data_91?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_91?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_91.data_7"}
                          value={dataForm?.data_91?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_91", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      I = ตรวจสอบชื่อผู้ป่วยตรงกับป้ายข้อมือ / Timeout
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_92.data_1"}
                        value={dataForm?.data_92?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_92", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_2"}
                          value={dataForm?.data_92?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_3"}
                          value={dataForm?.data_92?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_4"}
                          value={dataForm?.data_92?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_5"}
                          value={dataForm?.data_92?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_92.data_6"}
                          value={dataForm?.data_92?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_92?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_92.data_7"}
                          value={dataForm?.data_92?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_92", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      I = hand hygiene / keep sterile
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_93.data_1"}
                        value={dataForm?.data_93?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_93", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_2"}
                          value={dataForm?.data_93?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_3"}
                          value={dataForm?.data_93?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_4"}
                          value={dataForm?.data_93?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_5"}
                          value={dataForm?.data_93?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_93.data_6"}
                          value={dataForm?.data_93?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_93?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_93.data_7"}
                          value={dataForm?.data_93?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_93", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      S = ใช้ไม้กั้นเตียง, ล๊อคล้อเปล, ใช้ที่กั้นแขน
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_94.data_1"}
                        value={dataForm?.data_94?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_94", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_2"}
                          value={dataForm?.data_94?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_3"}
                          value={dataForm?.data_94?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_4"}
                          value={dataForm?.data_94?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_5"}
                          value={dataForm?.data_94?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_94.data_6"}
                          value={dataForm?.data_94?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_94?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_94.data_7"}
                          value={dataForm?.data_94?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_94", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      I = ตรวจสอบ IV type, rate, IV site
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_95.data_1"}
                        value={dataForm?.data_95?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_95", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_2"}
                          value={dataForm?.data_95?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_3"}
                          value={dataForm?.data_95?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_4"}
                          value={dataForm?.data_95?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_5"}
                          value={dataForm?.data_95?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_95.data_6"}
                          value={dataForm?.data_95?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_95?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_95.data_7"}
                          value={dataForm?.data_95?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_95", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      T = เฝ้าระวังการเลื่อนหลุดของsheath, ETT และท่อระบายต่างๆ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_96.data_1"}
                        value={dataForm?.data_96?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_96", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_2"}
                          value={dataForm?.data_96?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_3"}
                          value={dataForm?.data_96?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_4"}
                          value={dataForm?.data_96?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_5"}
                          value={dataForm?.data_96?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_96.data_6"}
                          value={dataForm?.data_96?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_96?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_96.data_7"}
                          value={dataForm?.data_96?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_96", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      D = ปรับระดับเตียงให้สะดวกขึ้นลง
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_97.data_1"}
                        value={dataForm?.data_97?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_97", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_2"}
                          value={dataForm?.data_97?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_3"}
                          value={dataForm?.data_97?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_4"}
                          value={dataForm?.data_97?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_5"}
                          value={dataForm?.data_97?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_97.data_6"}
                          value={dataForm?.data_97?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_97?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_97.data_7"}
                          value={dataForm?.data_97?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_97", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      O = ประเมิน RR, O2 sat, ดูแลให้ได้รับ O2 ตามแผนการรักษา
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_98.data_1"}
                        value={dataForm?.data_98?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_98", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_2"}
                          value={dataForm?.data_98?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_3"}
                          value={dataForm?.data_98?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_4"}
                          value={dataForm?.data_98?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_5"}
                          value={dataForm?.data_98?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_98.data_6"}
                          value={dataForm?.data_98?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_98?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_98.data_7"}
                          value={dataForm?.data_98?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_98", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      W = ดูแลความสะอาดหลังขับถ่ายหรืออาเจียน
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_99.data_1"}
                        value={dataForm?.data_99?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_99", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_2"}
                          value={dataForm?.data_99?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_3"}
                          value={dataForm?.data_99?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_4"}
                          value={dataForm?.data_99?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_5"}
                          value={dataForm?.data_99?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_99.data_6"}
                          value={dataForm?.data_99?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_99?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_99.data_7"}
                          value={dataForm?.data_99?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_99", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      N = สอบถามและตอบสนองความต้องการความช่วยเหลือ
                    </td>
                    <td>
                      <FormElementCheckBox
                        fieldId={"data_100.data_1"}
                        value={dataForm?.data_100?.data_1}
                        onChange={(i, e) =>
                          handleJsonValueChange("data_100", "data_1", e)
                        }
                        label=""
                      />
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_2"}
                          value={dataForm?.data_100?.data_2}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_2", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_3"}
                          value={dataForm?.data_100?.data_3}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_3", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_4"}
                          value={dataForm?.data_100?.data_4}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_4", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_5"}
                          value={dataForm?.data_100?.data_5}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_5", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextBox
                          fieldId={"data_100.data_6"}
                          value={dataForm?.data_100?.data_6}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_6", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          dataForm?.data_100?.data_1 == "1"
                            ? "form-show"
                            : "form-hidden"
                        }
                      >
                        <FormElementTextArea
                          cols={50}
                          rows={2}
                          fieldId={"data_100.data_7"}
                          value={dataForm?.data_100?.data_7}
                          onChange={(i, e) =>
                            handleJsonValueChange("data_100", "data_7", e)
                          }
                          width="100"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementTextBox
            fieldId={"101"}
            value={dataForm.data_101}
            label="Contrast Type"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">/</span>
          <FormElementTextBox
            fieldId={"102"}
            value={dataForm.data_102}
            label=""
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">ml.</span>
        </div>
        <div className="uk-width-1-1@muk-width-1-1">
          <FormElementTextBox
            fieldId={"103"}
            value={dataForm.data_103}
            label="Flu time"
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">
            min / Exposure dose <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementTextBox
            fieldId={"104"}
            value={dataForm.data_104}
            onChange={handleChange}
            width="100"
            type="decimal"
            isRequired
          />
          <span className="formTextBoxLabel">
            mGy. / Area dose <span style={{ color: "red" }}>*</span>
          </span>
          <FormElementTextBox
            fieldId={"105"}
            value={dataForm.data_105}
            onChange={handleChange}
            width="100"
            type="decimal"
          />
          <span className="formTextBoxLabel">cGyCm2</span>
          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ marginTop: "16px" }}
          >
            <span hidden={!dataForm.form_id}>
              <FormElementTextArea
                fieldId={"106"}
                rows={5}
                cols={100}
                value={dataForm.data_106}
                noLimit
                onChange={handleChange}
                label="เหตุผลในการแก้ไข"
                width="100"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormCathLabAssessment;
