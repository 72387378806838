import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';
import saveConsultForm from '../../API/saveConsultForm.js';
import fetchConsultFormByTxn from '../../API/fetchConsultFormByTxn.js';
import fetchConsultFormByFormId from '../../API/fetchConsultFormByFormId.js';
import fetchMemberByTxn from '../../API/fetchMemberByTxn.js';
import FormMemberProfileHeader from '../Common/FormMemberProfiileHeader.js';
import FormActionButtonSection from '../Common/FormActionButtionSection.js';
import FormZarit12Paper from '../FormPaper/FormZarit12Paper.js';
import fetchMemberListHis from '../../API/fetchMemberListHis.js';

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormZarit12 = (props) => {
    const formConstants = {
        formName: "form_zarit12",
        formType: "36"
    };
    const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    const [isPreview, setIsPreview] = useState(false);

    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    const handlePreviewOpenChange = useCallback((open) => {
        setIsPreview(open);
    }, [setIsPreview])

    const displayLoading = useCallback(() => {
        onLoading(true);
    }, [onLoading]);

    const hideLoading = useCallback(() => {
        onLoading(false);
    }, [onLoading]);

    const displayErrorFetchPopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
    }, [onErrorMessageChange]);

    const displayErrorSavePopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
    }, [onErrorMessageChange]);

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1n,
            dataForm.data_2n,
            dataForm.data_3n,
            dataForm.data_4n,
            dataForm.data_5n,
            dataForm.data_6n,
            dataForm.data_7n,
            dataForm.data_8n,
            dataForm.data_9n,
            dataForm.data_10n,
            dataForm.data_11n,
            dataForm.data_12n,
        ]);
    }, [dataForm]);

    const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
        try {
            displayLoading();
            setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
            hideLoading();
            document.body.style.overflow = "hidden";
        } catch (error) {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
            document.body.style.overflow = "hidden";
        }
    });

    const fetchOtherData = useCallback(async (data, hn, txn) => {
        const patientData = fetchMemberByTxn(hn, txn);
        const patientRoomRequest = fetchMemberListHis(hn, txn);
        Promise.all([patientData, patientRoomRequest]).then((values) => {
            const patientResponse = values[0];
            const patientRoomResponse = values[1];
            setPatient({ ...patientResponse, ...patientRoomResponse });
            setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
            hideLoading();
        }).catch((error) => {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
        });
    });

    const fetchData = useCallback(async (formId, hn, txn) => {
        try {
            displayLoading();
            setDataForm({});
            let data = {};
            let hnValue, txnValue;
            if (formId) {
                data = await fetchConsultFormByFormId(formId, formConstants.formType);
                hnValue = data.hn;
                txnValue = data.txn;
            } else {
                data = await fetchConsultFormByTxn(txn, formConstants.formType);
                hnValue = hn;
                txnValue = txn;
            }
            await fetchOtherData(data, hnValue, txnValue);
        } catch (error) {
            hideLoading();
            displayErrorFetchPopup();
        }
    }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

    // fetch data when has formId #Edit
    useEffect(() => {
        if (!isSubForm) {
            if (formId) fetchData(formId, null, null)
        }
    }, [formId, isSubForm]);

    // fetch data when formId is null and hn,txn are not null #Add or this is subForm
    useEffect(() => {
        if (!isSubForm) {
            if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
        }
    }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

    // use for subForm
    useEffect(() => {
        if (isSubForm) {
            fetchSubFormOtherData(data, data.hn, data.txn);
        }
    }, [isSubForm, data]);

    useEffect(() => {
        if (isSubForm)
            if (!formId && hn && txn) fetchData(null, hn, txn)
    }, [hn, txn, formId, isSubForm]);

    const validateSave = useCallback(() => {
        return true;
    }, [])

    const handleSave = useCallback(async (html) => {
        async function saveForm(paperHtml) {
            displayLoading();
            try {
                if (validateSave()) {
                    const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
                    handlePreviewOpenChange(false);
                    await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
                    hideLoading();
                    onSuccessPopupOpen(true);
                } else {
                    hideLoading();
                }
            } catch (error) {
                hideLoading();
                displayErrorSavePopup();
            }
        }
        saveForm(html);
    });

    const handleAddDataToParent = useCallback((html) => {
        onAdd(dataForm, html, getTotalScore);
    }, [dataForm, onAdd, getTotalScore]);

    return (
        <>
            {isSubForm &&
                <FormMemberProfileHeader
                    memberData={patient}
                    onSelectMember={() => { }}
                    isSelectDisabled={true}
                />}
            <div uk-grid="" className="uk-grid uk-padding-small">
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTitle label="Zarit-12 (Form ที่ใช้ใน Post-falling assessment)" />
                </div>

                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTitle label="ในแต่ละข้อให้เลือกช่องที่คิดว่าตรงกับท่านมากที่สุด" />
                </div>


                <div className="uk-width-1-1@m uk-width-1-1">
                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>ไม่เคยเลย (0)</th>
                                <th>นานๆครั้ง (1)</th>
                                <th>บางครั้ง (2)</th>
                                <th>ค่อนข้างบ่อย (3)</th>
                                <th>แทบทุกครั้ง (4)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1. ท่านรู้สึกว่าท่านไม่มีเวลาเพียงพอสำหรับตัวเอง เนื่องจากว่าใช้เวลาในการดูแล ไม่เวลาของตัวเอง</td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>2. ท่านรู้สึกว่ามีความเครียดทั้งงานที่ต้องดูแลผู้ป่วยและงานอื่นที่ต้องรับผิดชอบ</td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>3. ท่านรู้สึกหงุดหงิดใจ หรือโกรธ ขณะที่อยู่ใกล้ผู้ป่วย</td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>4. ท่านรู้สึกว่าผู้ป่วยทำให้ความสัมพันธ์ของท่านกับสมาชิกครอบครัวหรือเพื่อนแย่ลง</td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>5. ท่านรู้สึกตึงเครียดขณะที่อยู่ใกล้ผู้ป่วย</td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>6. ท่านรู้สึกว่าสุขภาพของท่านไม่ค่อยดี เนื่องมาจากการดูแลผู้ป่วย</td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>7. ท่านรู้สึกว่าท่านไม่มีความเป็นส่วนตัวเท่าที่ต้องการเนื่องจากการดูแลผู้ป่วย</td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>8. ท่านรู้สึกว่าท่านไม่สามารถมีสังคมได้ตามปกติเนื่องจากการดูแลผู้ป่วย</td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>9. ท่านรู้สึกว่าท่านไม่สามารถควบคุมจัดการชีวิตตนเองได้ ตั้งแต่ดูแลผู้ป่วย</td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>10. ท่านรู้สึกไม่มั่นใจในการดูแลผู้ป่วย</td>
                                <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"10n"} value={dataForm.data_10n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>11. ท่านรู้สึกว่าท่านควรจะได้รับการดูแลจากญาติคนอื่น</td>
                                <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"11n"} value={dataForm.data_11n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>12. ท่านรู้สึกว่าท่านน่าจะดูแลผู้ป่วยได้ดีกว่านี้</td>
                                <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"12n"} value={dataForm.data_12n} fieldValue={"4"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>คะแนนรวมทั้งหมด</td>
                                <td colSpan={5}>{getTotalScore}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <FormActionButtonSection
                    isPreview={isPreview}
                    onOpen={() => handlePreviewOpenChange(true)}
                    onClose={() => handlePreviewOpenChange(false)}
                    onSave={handleSave}
                    onAdd={(html) => handleAddDataToParent(html)}
                    isSubForm={isSubForm}
                    formContent={
                        <FormZarit12Paper
                            dataForm={dataForm}
                            patient={patient}
                            profile={profile}
                        />}
                />
            </div>
        </>
    )
}

export default FormZarit12;


