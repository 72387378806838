import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import numeral from "numeral";
UIkit.use(Icons);

export default class ConsultCampaign extends Component {
  state = {
    campaignList: [],
  };

  componentDidMount() {
    this.campaignListGet();
  }

  campaignListGet = async () => {
    var _response = await Api.consult("CampaignList");
    this.setState({campaignList: _response.list});

  };

  render() {
    return (
      <div className="uk-padding">
        <div className="uk-card uk-card-default uk-margin-top uk-padding-small">
          <div class="d-card-header">
            <div class="d-card-header-left">แคมเปญ</div>
            <div class="d-card-header-right"></div>
          </div>
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-divider">
              <thead>
              <tr>
                <th>ลำดับ</th>
                <th>รหัสแคมเปญ</th>
                <th>บริษัท</th>
                <th>ชื่อแคมเปญ</th>
                <th>ค่าใช้จ่าย</th>
                <th>รหัสโปรโมชัน</th>
                <th>ระยะเวลาแคมเปญ<div>กรณีคนไข้รายบุคคล</div></th>
                <th>วันที่่เริ่มต้น</th>
                <th>วันที่สิ้นสุด</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                {this.state.campaignList.length === 0 ? 
                <tr><td colSpan="100%" className="uk-padding-small uk-text-center">ไม่พบรายการ</td></tr> 
                  :

                this.state.campaignList.map((data, index) => {
                  return <tr key={`rowCampaign${index}`}>
                    <td>{index+1}</td>
                    <td>{data.id}</td>
                    <td>{data.companyid}</td>
                    <td>{data.name}</td>
                    <td>{numeral(data.price).format('0,0.00')}</td>
                    <td>{data.promocode}</td>
                    <td>{data.day} วัน</td>
                    <td>{data.datestart}</td>
                    <td>{data.datestop}</td>
                    <td>
                      <a href={'/consult/campaignDetail?id=' + data.id} uk-icon="settings"></a>
                    </td>
                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
