import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";

import Api from "../../Api";
import dateFormat from "dateformat";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

import PopupForm from "../PopupForm";
import FormElementTagInput from "../Form/FormElementTagInput";
import FormElementUploadImage from "../Form/FormElementUploadImage";
import FormElementIconButton from "../Form/FormElementIconButton";

import ShowFilesList from "../../ShowFilesList";
import SendFiles from "../../SendFiles";
import PainSelectorSection from "../Utils/PainSelectorSection";
import SOAPSection from "../Utils/SOAPSection";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormFammed = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const [data2Disabled, setData2Disabled] = useState([false]);
  var [hn, setHn] = useState(false);
  var [txn, setTxn] = useState(false);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [drugList, setDrugList] = useState([]);
  var [drugAllergics, setDrugAllergics] = useState([]);

  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  const [popupDataForm, setPopupDataForm] = useState({});
  const [isEditingData114, setIsEditingData114] = useState(false);

  var vaccineList = [
    { label: "Select Vaccine", value: "" },
    { label: "Influenza", value: "1" },
    { label: "Tetanus", value: "2" },
    { label: "Others", value: "3" },
  ];

  var assessment = [
    { label: "Select Assessment", value: "" },
    { label: "Bed ridden", value: "1" },
    { label: "Home bound", value: "2" },
    { label: "Restriction", value: "3" },
  ];

  var objectiveList = [
    "Clinical assessment (Other than pain)",
    "Pain assessment",
    "Advice for self-management",
    "Health promotion by med student",
    "Wound dressing",
    "NG care",
    "Foley's care",
  ];

  var problemList = [{ label: "Select Problem", value: "" }];

  var currentMedicationList = [
    { label: "Select Current Medication", value: "" },
  ];

  var drugAllergyList = [{ label: "Select Drug Allergy", value: "" }];

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  function handleChange_data1(i, event) {
    if (event.target.value == "1") {
      setData2Disabled(true);
    } else {
      setData2Disabled(false);
    }

    handleChange(i, event);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_fammed",
      formType: "1",
    });
  }, []);

  useEffect(() => {
    setHn(props.hn);
    setTxn(props.txn);

    if (props.txn != null) {
      props.loadDataFormItem(5, props.txn);
      props.loadDataFormItem(31, props.txn);
      props.loadDataFormItem(32, props.txn);
      props.loadDataFormItem(30, props.txn);
    }
  }, [props.hn, props.txn]);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  async function getDataDrug() {
    var result = await Api.consult("GetDrugByTxn", {
      hn: props.hn,
      txn: props.txn,
      patientType: "o",
    });

    var { list } = result;
    let drugList = [];

    if (list.data.length > 0) {
      setDrugList(list.data[0]);

      for (const drug of list.data[0]) {
        if (drug.tele_drug_type != "") {
          drugList.push(drug.tele_drug_type);
        }
      }
    } else {
      setDrugList([]);
    }
  }

  async function getDrugAllergic() {
    try {
      if (props.hn && props.txn) {
        var result = await Api.consult("GetDrugAllergic", {
          hn: props.hn,
        });

        if (result.list.data.allergic_desc[0].list) {
          setDrugAllergics(result.list.data.allergic_desc[0].list);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDataDrug();
    getDrugAllergic();
    setIsEditingData114(false);
  }, [props.hn, props.txn]);

  function onClosePopup(data) {
    setShowFormPopup(false);
    props.addDataFormItem(data);

    if (typeof data != "undefined") {
      if (popupFormType == 3) {
        setData("13n", data.score.toString());
      } else if (popupFormType == 5) {
        setData("37n", data.score.toString());
      } else if (popupFormType == 31) {
        setData("38n", data.score.toString());
      } else if (popupFormType == 32) {
        setData("39n", data.score.toString());
      } else if (popupFormType == 33) {
        setData("112n", data.score.toString());
      } else if (popupFormType == 34) {
        setData("40n", data.score.toString());
      } else if (popupFormType == 39) {
        setData("41n", data.score.toString());
      } else if (popupFormType == 40) {
        setData("42n", data.score.toString());
      } else if (popupFormType == 35) {
        setData("78n", data.score.toString());
      } else if (popupFormType == 36) {
        setData("79n", data.score.toString());
      } else if (popupFormType == 37) {
        setData("80n", data.score.toString());
      } else if (popupFormType == 30) {
        setData("13n", data.score.toString());
      } else if (popupFormType == 44) {
        setData("96n", data.score.toString());
      } else if (popupFormType == 45) {
        setData("97n", data.score.toString());
      } else if (popupFormType == 2) {
        setData("14n", data.score.toString());
      } else if (popupFormType == 3) {
        setData("15n", data.score.toString());
      }
    }
  }

  useEffect(() => {
    if (props.getDataFormItem(2)) {
      setData("14", "ทำการประเมินแล้ว");
    } else {
      setData("14", "");
    }
  }, [props.getDataFormItem(2)]);

  useEffect(() => {
    if (props.getDataFormItem(3)) {
      setData("15", "ทำการประเมินแล้ว");
    } else {
      setData("15", "");
    }
  }, [props.getDataFormItem(3)]);

  useEffect(() => {
    if (props.getDataFormItem(4)) {
      setData("16", "ทำการประเมินแล้ว");
    } else {
      setData("16", "");
    }
  }, [props.getDataFormItem(4)]);

  useEffect(() => {
    if (props.getDataFormItem(41)) {
      setData("78", "ทำการประเมินแล้ว");
    } else {
      setData("78", "");
    }
  }, [props.getDataFormItem(41)]);

  function doESAS() {
    var dataFormItem = props.getDataFormItem(2);
    setPopupDataForm(dataFormItem);
    setPopupFormType(2);
    setShowFormPopup(true);
  }

  function doPOS() {
    var dataFormItem = props.getDataFormItem(3);
    setPopupDataForm(dataFormItem);
    setPopupFormType(3);
    setShowFormPopup(true);
  }

  function doGoodDeath() {
    var dataFormItem = props.getDataFormItem(4);
    setPopupDataForm(dataFormItem);
    setPopupFormType(4);
    setShowFormPopup(true);
  }

  function doPPS() {
    var dataFormItem = props.getDataFormItem(30);
    setPopupDataForm(dataFormItem);
    setPopupFormType(30);
    setShowFormPopup(true);
  }

  function doTMSE() {
    var dataFormItem = props.getDataFormItem(5);
    setPopupDataForm(dataFormItem);
    setPopupFormType(5);
    setShowFormPopup(true);
  }

  function doMOCA() {
    var dataFormItem = props.getDataFormItem(31);
    setPopupDataForm(dataFormItem);
    setPopupFormType(31);
    setShowFormPopup(true);
  }

  function do2Q() {
    var dataFormItem = props.getDataFormItem(32);
    setPopupDataForm(dataFormItem);
    setPopupFormType(32);
    setShowFormPopup(true);
  }

  function do8Q() {
    var dataFormItem = props.getDataFormItem(33);
    setPopupDataForm(dataFormItem);
    setPopupFormType(33);
    setShowFormPopup(true);
  }

  function do9Q() {
    var dataFormItem = props.getDataFormItem(34);
    setPopupDataForm(dataFormItem);
    setPopupFormType(34);
    setShowFormPopup(true);
  }

  function doTGDS() {
    var dataFormItem = props.getDataFormItem(39);
    setPopupDataForm(dataFormItem);
    setPopupFormType(39);
    setShowFormPopup(true);
  }

  function doGAD7() {
    var dataFormItem = props.getDataFormItem(40);
    setPopupDataForm(dataFormItem);
    setPopupFormType(40);
    setShowFormPopup(true);
  }

  function doFear() {
    var dataFormItem = props.getDataFormItem(35);
    setPopupDataForm(dataFormItem);
    setPopupFormType(35);
    setShowFormPopup(true);
  }

  function doPostFalling() {
    var dataFormItem = props.getDataFormItem(41);
    setPopupDataForm(dataFormItem);
    setPopupFormType(41);
    setShowFormPopup(true);
  }

  function doZarit() {
    var dataFormItem = props.getDataFormItem(36);
    setPopupDataForm(dataFormItem);
    setPopupFormType(36);
    setShowFormPopup(true);
  }

  function doDrug() {
    var dataFormItem = props.getDataFormItem(37);
    setPopupDataForm(dataFormItem);
    setPopupFormType(37);
    setShowFormPopup(true);
  }

  function doADLS() {
    var dataFormItem = props.getDataFormItem(44);
    setPopupDataForm(dataFormItem);
    setPopupFormType(44);
    setShowFormPopup(true);
  }

  function doIADLS() {
    var dataFormItem = props.getDataFormItem(45);
    setPopupDataForm(dataFormItem);
    setPopupFormType(45);
    setShowFormPopup(true);
  }

  const handleValue6Change = useCallback((i, event) => {
    if (event.target.value !== "1") {
      utilHandleChange("8", "", setIsEditForm, setDataForm);
    }
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  });

  const getADLAndIADLTotalScore = useMemo(() => {
    return utilSumTotalScore([dataForm.data_97n, dataForm.data_96n]);
  }, [dataForm.data_97n, dataForm.data_96n]);

  const onTagInputChange = useCallback((fieldId, data) => {
    var valueData = [];
    if (data) {
      valueData = data.map((value, index) => ({
        key: index,
        value: value,
      }));
    }
    setData(fieldId, valueData);
  });

  const onUploadFileInputChange = useCallback((fieldId, files) => {
    setData(fieldId, files);
  });

  function doShowPic(name, code, howTo, amt) {
    let url =
      "/consult/phamacyImage?mode=iviewer&" +
      encodeURIComponent(
        "name=" +
          name +
          "&code=" +
          code +
          "&howTo=" +
          JSON.stringify(howTo) +
          "&amt=" +
          amt +
          "&patientName=" +
          dataForm.patient_name +
          "&hn=" +
          dataForm.hn
      );

    window.open(url, "imagePhama").focus();
  }

  const handleData114ValueChange = useCallback(
    (fieldName, dataIndex, event) => {
      if (typeof dataForm.data_114 == "undefined") {
        return;
      }
      if (event != null && typeof event.target != "undefined") {
        dataForm.data_114[dataIndex][fieldName] = event.target.value;
        setData("114", dataForm.data_114);
      } else {
        dataForm.data_114[dataIndex][fieldName] = event;
        setData("114", dataForm.data_114);
      }
    }
  );

  const addData114 = useCallback(() => {
    if (typeof dataForm.data_114?.length == "number") {
      const data = dataForm.data_114;
      data.push({
        group: "",
        sym: "",
        date_add: dateFormat(new Date(), "yyyy-mm-dd"),
        is_edit: true,
      });
      setData("114", data);
    } else {
      setData("114", [
        {
          group: "",
          sym: "",
          date_add: dateFormat(new Date(), "yyyy-mm-dd"),
          is_edit: true,
        },
      ]);
    }
    setIsEditingData114(true);
  });

  const editData114 = useCallback((index) => {
    dataForm.data_114?.forEach((item) => {
      delete item.is_edit;
    });
    const data = dataForm.data_114[index];
    data.is_edit = true;
    dataForm.data_114[index] = data;
    setData("114", dataForm.data_114);
    setIsEditingData114(true);
  });

  const deleteData114 = useCallback((index) => {
    dataForm.data_114.splice(index, 1);
    setData("114", dataForm.data_114);
  });

  const finishData114 = useCallback((index) => {
    const data = dataForm.data_114[index];
    if (data.is_edit) delete data.is_edit;
    dataForm.data_114[index] = data;
    setData("114", dataForm.data_114);
    setIsEditingData114(false);
  });

  useEffect(
    () => {
      var consultProfileDataStorage =
        localStorage.getItem("consultProfileData");
      var consultProfileData = JSON.parse(consultProfileDataStorage);
      setData("36", consultProfileData.doctorName);
    },
    [localStorage.getItem("consultProfileData")],
    dataForm.data_36
  );

  const [dataFilesList, setDataFilesList] = useState([]);
  const getDataFileList = async () => {
    const result = await Api.consult("FormTxnFilesList", {
      txn: props.dataForm.txn,
    });
    if (result.list) {
      setDataFilesList(result.list);
    } else {
      setDataFilesList([]);
    }
  };
  useEffect(() => {
    getDataFileList();
  }, [props]);

  useEffect(() => {
    console.log(dataForm);
  }, [dataForm]);

  return (
    <div
      uk-grid=""
      className="uk-grid-small uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "hidden" }}
    >
      <div className=" uk-flex uk-width-1-1@m uk-width-1-1">
        <div className="uk-width-1-1 ">
          <FormElementTitle label="HOME VISIT RECORD FORM (CMU 2023)" />
        </div>
        <div className="uk-flex uk-width-1-3@m uk-width-1-2@l uk-width-1-1  uk-flex-right">
          {dataForm.txn ? (
            <>
              <ShowFilesList
                dataForm={dataForm}
                dataFilesList={dataFilesList}
                getData={getDataFileList}
              />
              <SendFiles dataForm={dataForm} getData={getDataFileList} />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className="uk-width-1-1@m uk-width-1-1"
        style={{ paddingLeft: "24px" }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementCheckBox
                    fieldId={"1"}
                    value={dataForm.data_1}
                    onChange={handleChange}
                    label="Virtual OPD"
                  />
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_1 != "1"}
                >
                  Method:
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_1 != "1"}
                >
                  <FormElementRadio
                    fieldId={"90"}
                    value={dataForm.data_90}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Video call"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_1 != "1"}
                >
                  <FormElementRadio
                    fieldId={"90"}
                    value={dataForm.data_90}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Phone call"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="uk-width-1-1@m uk-width-1-1">
                  <FormElementCheckBox
                    fieldId={"89"}
                    value={dataForm.data_89}
                    onChange={handleChange}
                    label="Home visit"
                  />
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  Types:
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementRadio
                    fieldId={"91"}
                    value={dataForm.data_91}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Transitional case"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementRadio
                    fieldId={"91"}
                    value={dataForm.data_91}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Continuous case"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                  hidden={dataForm.data_91 != "2"}
                >
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="General Fammed"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                  hidden={dataForm.data_91 != "2"}
                >
                  <FormElementRadio
                    fieldId={"92"}
                    value={dataForm.data_92}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Palliative"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  Methods
                </div>
              </td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementCheckBox
                    fieldId={"93"}
                    value={dataForm.data_93}
                    onChange={handleChange}
                    label="Tele"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_93 != "1" || dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                >
                  <FormElementRadio
                    fieldId={"95"}
                    value={dataForm.data_95}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label="Video call"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_93 != "1" || dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  style={{ paddingLeft: "64px" }}
                  hidden={dataForm.data_93 != "1"}
                >
                  <FormElementRadio
                    fieldId={"95"}
                    value={dataForm.data_95}
                    fieldValue={"2"}
                    onChange={handleChange}
                    label="Phone call"
                  />
                </div>
              </td>
            </tr>
            <tr hidden={dataForm.data_89 != "1"}>
              <td></td>
              <td></td>
              <td>
                <div
                  className="uk-width-1-1@m uk-width-1-1"
                  hidden={dataForm.data_89 != "1"}
                >
                  <FormElementCheckBox
                    fieldId={"94"}
                    value={dataForm.data_94}
                    onChange={handleChange}
                    label="On-site"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <div className="dietz-input-container">
                <span
                  className="formTextBoxLabel"
                  style={{ paddingTop: "8px" }}
                >
                  Objective:
                </span>
                <FormElementTagInput
                  value={
                    dataForm.data_2
                      ? dataForm.data_2.map((item) => item.value)
                      : []
                  }
                  onChange={(data) => onTagInputChange("2", data)}
                  dataList={objectiveList}
                  placeholder="ระบุ"
                />
              </div>
            </div>
            <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <div className="dietz-input-container">
                <span className="formTextBoxLabel ">Problem List:</span>
                <div style={{ display: "inline-flex", gap: "8px" }}>
                  <FormElementCheckBox
                    fieldId={"104"}
                    label="HTN"
                    magicRadio
                    value={dataForm.data_104}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"105"}
                    label="DLP"
                    magicRadio
                    value={dataForm.data_105}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"106"}
                    label="DM"
                    magicRadio
                    value={dataForm.data_106}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"107"}
                    label="CVA"
                    magicRadio
                    value={dataForm.data_107}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"108"}
                    label="HD"
                    magicRadio
                    value={dataForm.data_108}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"109"}
                    label="COPD"
                    magicRadio
                    value={dataForm.data_109}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"110"}
                    label="OA"
                    magicRadio
                    value={dataForm.data_110}
                    onChange={handleChange}
                  />
                  <FormElementCheckBox
                    fieldId={"111"}
                    label="Dementia"
                    magicRadio
                    value={dataForm.data_111}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementTextArea
                fieldId={"3"}
                rows={5}
                cols={50}
                value={dataForm.data_3}
                onChange={handleChange}
                noLimit
              />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <FormElementLabel label="Current Medication:" />
              <div className="uk-width-1-1@m uk-width-1-1">
                <div
                  className="form-table-container"
                  style={{ maxWidth: `${tableMaxWidth}px` }}
                >
                  <div className="form-table-scroll">
                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th style={{ whiteSpace: "nowrap" }}>ชื่อยา</th>
                          <th style={{ width: "400px" }}>วิธีใช้</th>
                          <th>จำนวน</th>
                          <th style={{ whiteSpace: "nowrap" }}>
                            สถานะการปรับเปลี่ยนยา
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {drugList.length === 0 ? (
                          <tr>
                            <td colSpan="100%">
                              <div className="uk-padding-small uk-text-center">
                                ไม่พบข้อมูล
                              </div>
                            </td>
                          </tr>
                        ) : (
                          drugList.map((data, key) => {
                            const howToDesc = {
                              sig: data.sig,
                              sigadd: data.sigadd,
                              signote: data.signote,
                            };
                            return (
                              <tr key={`row${data.code}`}>
                                <td>{key + 1}</td>
                                <td>
                                  <a
                                    onClick={(e) =>
                                      doShowPic(
                                        data.name,
                                        data.code,
                                        howToDesc,
                                        data.amt
                                      )
                                    }
                                  >
                                    {data.name}
                                  </a>
                                </td>
                                <td>
                                  <p>{data.sig}</p>
                                  <p>{data.sigadd}</p>
                                  <p>{data.signote}</p>
                                </td>
                                <td>{data.amt}</td>
                                <td></td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
              <FormElementTextArea
                fieldId={"5"}
                rows={5}
                cols={50}
                value={dataForm.data_5}
                onChange={handleChange}
                noLimit
              />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementLabel label="Drug Allergy:" />
              <div
                className="form-table-container"
                style={{ maxWidth: `${tableMaxWidth}px` }}
              >
                <div className="form-table-scroll">
                  <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                    <thead>
                      <tr>
                        <th>Group</th>
                        <th>Sym</th>
                        <th>Date_add</th>
                        <th style={{ width: "120px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {drugAllergics.map((item) => (
                        <tr>
                          <td>{item.group}</td>
                          <td>{item.sym}</td>
                          <td>
                            <FormElementTextBox
                              value={item.date_add}
                              type="date"
                              onChange={(e) => {}}
                              disabled
                            />
                          </td>
                          <td></td>
                        </tr>
                      ))}
                      {dataForm.data_114?.map((item, index) => (
                        <tr>
                          <td>
                            {!item.is_edit ? (
                              item.group
                            ) : (
                              <FormElementTextBox
                                value={item.group}
                                onChange={(i, e) =>
                                  handleData114ValueChange("group", index, e)
                                }
                              />
                            )}
                          </td>
                          <td>
                            {!item.is_edit ? (
                              item.sym
                            ) : (
                              <FormElementTextArea
                                value={item.sym}
                                onChange={(i, e) =>
                                  handleData114ValueChange("sym", index, e)
                                }
                                cols={80}
                                rows={2}
                                noLimit
                              />
                            )}
                          </td>
                          <td>
                            <FormElementTextBox
                              value={item.date_add}
                              type="date"
                              onChange={(i, e) =>
                                handleData114ValueChange("date_add", index, e)
                              }
                              disabled={!item.is_edit}
                            />
                          </td>
                          <td>
                            <div
                              style={{
                                display: "inline-flex",
                                gap: "8px",
                              }}
                            >
                              {!isEditingData114 && (
                                <FormElementIconButton
                                  icon="pencil"
                                  sizeRatio={1}
                                  onClick={(e) => editData114(index)}
                                />
                              )}
                              {!isEditingData114 && (
                                <FormElementIconButton
                                  icon="trash"
                                  sizeRatio={1}
                                  onClick={(e) => deleteData114(index)}
                                />
                              )}
                              {item.is_edit && (
                                <FormElementIconButton
                                  icon="check"
                                  sizeRatio={1}
                                  onClick={(e) => finishData114(index)}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "14px",
                      marginTop: "-8px",
                      paddingLeft: "4px",
                    }}
                  >
                    <FormElementButton
                      label="เพิ่ม"
                      onClick={addData114}
                      disabled={isEditingData114}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementDropdown
                fieldId={"10"}
                value={dataForm.data_10}
                onChange={handleChange}
                dataList={vaccineList}
                label="Vaccination: "
              />
              <span
                className={
                  dataForm.data_10 == "3" ? "form-show" : "form-hidden"
                }
              >
                <span className="formTextBoxLabel">
                  <FormElementTextBox
                    fieldId={"11"}
                    value={dataForm.data_11}
                    onChange={handleChange}
                    width="100"
                    placeholder="ระบุ"
                  />
                </span>
              </span>
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label=" Palliative care" />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1.1 &nbsp;&nbsp; Advance care plan &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"98"}
                value={dataForm.data_98}
                fieldValue={"1"}
                onChange={handleChange}
                label="มี"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"98"}
                value={dataForm.data_98}
                fieldValue={"0"}
                onChange={handleChange}
                label="ไม่มี"
              />
            </div>
            <div
              className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top"
              style={{ paddingLeft: "40px" }}
            >
              DNR &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"99"}
                value={dataForm.data_99}
                fieldValue={"1"}
                onChange={handleChange}
                label="Full"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"99"}
                value={dataForm.data_99}
                fieldValue={"2"}
                onChange={handleChange}
                label="Other"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"100"}
                value={dataForm.data_100}
                onChange={handleChange}
                width="100"
                placeholder="ระบุ"
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1.2 &nbsp;&nbsp; Living will &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"101"}
                value={dataForm.data_101}
                fieldValue={"1"}
                onChange={handleChange}
                label="มี"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"101"}
                value={dataForm.data_101}
                fieldValue={"0"}
                onChange={handleChange}
                label="ไม่มี"
              />
            </div>
            <div
              className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top"
              style={{ paddingLeft: "42px" }}
            >
              DNR &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"102"}
                value={dataForm.data_102}
                fieldValue={"1"}
                onChange={handleChange}
                label="Full"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementRadio
                fieldId={"102"}
                value={dataForm.data_102}
                fieldValue={"2"}
                onChange={handleChange}
                label="Other"
              />
              &nbsp;&nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"103"}
                value={dataForm.data_103}
                onChange={handleChange}
                width="100"
                placeholder="ระบุ"
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              2. &nbsp;&nbsp;{" "}
              <FormElementTextBox
                fieldId={"13n"}
                value={dataForm.data_13n}
                disabled
                onChange={handleChange}
                label="PPS"
                width="50"
              />
              <FormElementButton label="PPS" onClick={doPPS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              3. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"14"}
                value={dataForm.data_14}
                disabled
                onChange={handleChange}
                label="ESAS"
                width="50"
              />
              <FormElementButton label="ESAS" onClick={doESAS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              4. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"15"}
                value={dataForm.data_15}
                disabled
                onChange={handleChange}
                label="POS"
                width="50"
              />
              <FormElementButton label="POS" onClick={doPOS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              5. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"16"}
                value={dataForm.data_16}
                disabled
                onChange={handleChange}
                label="Good death"
                width="50"
              />
              <FormElementButton label="Good death" onClick={doGoodDeath} />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementDropdown
                fieldId={"7"}
                dataList={[
                  {
                    label: "Select functional assessment",
                    value: "",
                  },
                  {
                    label: "Bed-ridden",
                    value: "1",
                  },
                  {
                    label: "Home bound",
                    value: "2",
                  },
                  {
                    label: "Restriction",
                    value: "3",
                  },
                ]}
                label="Functional assessment"
                value={dataForm.data_7}
                onChange={handleChange}
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementTextBox
                fieldId={"96n"}
                value={dataForm.data_96n}
                onChange={handleChange}
                label=""
                width="50"
                disabled
              />
              <FormElementButton label="ADLS" onClick={doADLS} />
              &nbsp;&nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"97n"}
                value={dataForm.data_97n}
                onChange={handleChange}
                label=""
                width="50"
                disabled
              />
              <FormElementButton label="IADLS" onClick={doIADLS} />
              &nbsp;&nbsp;&nbsp; คะแนนรวม: {getADLAndIADLTotalScore}
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label=" Psychology assessment" />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"37n"}
                value={dataForm.data_37n}
                onChange={handleChange}
                label="TMSE"
                width="50"
                disabled
              />
              <FormElementButton label="TMSE" onClick={doTMSE} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              2. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"38n"}
                value={dataForm.data_38n}
                onChange={handleChange}
                label="MOCA"
                width="50"
                disabled
              />
              <FormElementButton label="MOCA" onClick={doMOCA} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              3. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"39n"}
                value={dataForm.data_39n}
                onChange={handleChange}
                label="2Q"
                width="50"
                disabled
              />
              <FormElementButton label="2Q" onClick={do2Q} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              4. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"40n"}
                value={dataForm.data_40n}
                onChange={handleChange}
                label="PHQ-9"
                width="50"
                disabled
              />
              <FormElementButton label="PHQ-9" onClick={do9Q} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              5. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"112n"}
                value={dataForm.data_112n}
                onChange={handleChange}
                label="PHQ-8"
                width="50"
                disabled
              />
              <FormElementButton label="PHQ-8" onClick={do8Q} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              6. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"41n"}
                value={dataForm.data_41n}
                onChange={handleChange}
                label="TGDS"
                width="50"
                disabled
              />
              <FormElementButton label="TGDS" onClick={doTGDS} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              7. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"42n"}
                value={dataForm.data_42n}
                onChange={handleChange}
                label="GAD-7"
                width="50"
                disabled
              />
              <FormElementButton label="GAD-7" onClick={doGAD7} />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label="Other assessment tools" />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              1. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"78"}
                value={dataForm.data_78}
                onChange={handleChange}
                label="Post-falling assessment"
                width="50"
                disabled
              />
              <FormElementButton
                label="Post-falling assessment"
                onClick={doPostFalling}
              />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              2. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"78n"}
                value={dataForm.data_78n}
                onChange={handleChange}
                label="Fear of Falling"
                width="50"
                disabled
              />
              <FormElementButton label="Fear of Falling" onClick={doFear} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              3. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"79n"}
                value={dataForm.data_79n}
                onChange={handleChange}
                label="Zarit-12"
                width="50"
                disabled
              />
              <FormElementButton label="Zarit-12" onClick={doZarit} />
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              4. &nbsp;&nbsp;
              <FormElementTextBox
                fieldId={"80n"}
                value={dataForm.data_80n}
                onChange={handleChange}
                label="Drug adherence"
                width="50"
                disabled
              />
              <FormElementButton label="Drug adherence" onClick={doDrug} />
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <table className="uk-table uk-table-small uk-table-divider form-table">
                <tbody>
                  <tr>
                    <th
                      className="center"
                      rowSpan={3}
                      style={{ width: "80px" }}
                    >
                      PE
                    </th>
                    <td className="center" rowSpan={3}>
                      V/S
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"44"}
                          value={dataForm.data_44}
                          onChange={handleChange_data1}
                          label="BT"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"45"}
                          value={dataForm.data_45}
                          onChange={handleChange_data1}
                          label="BP1"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"46"}
                          value={dataForm.data_46}
                          onChange={handleChange_data1}
                          label="BP2"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"47"}
                          value={dataForm.data_47}
                          onChange={handleChange_data1}
                          label="HR"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"48"}
                          value={dataForm.data_48}
                          onChange={handleChange_data1}
                          label="RR"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextBox
                          fieldId={"49"}
                          value={dataForm.data_49}
                          onChange={handleChange_data1}
                          label="O2sat"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">GA</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"50"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_50}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">HEENT</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"51"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_51}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Heart</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"52"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_52}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Lung</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"53"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_53}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Abd</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"54"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_54}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Ext</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"55"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_55}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Neuro</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"56"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_56}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="center">Other</th>
                    <td colSpan={3}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementTextArea
                          fieldId={"57"}
                          rows={1}
                          cols={80}
                          value={dataForm.data_57}
                          onChange={handleChange_data1}
                          noLimit
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementUploadImage
                          value={dataForm.data_113}
                          onChange={(files) =>
                            onUploadFileInputChange("113", files)
                          }
                          maxLength={6}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top">
              <FormElementLabel label="Pain" />
              <PainSelectorSection
                data={dataForm.data_115}
                onChange={(data) => {
                  props.addValue({ data_115: data });
                }}
                onCaptureImage={(img) => props.addValue({ painImg: img })}
              />
            </div>
          </div>
          <FormElementLine />

          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small">
              <table className="uk-table uk-table-small uk-table-divider form-table">
                <thead>
                  <tr>
                    <th colSpan={2}>ILLNESS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Idea</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"29"}
                        value={dataForm.data_29}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Feeling</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"30"}
                        value={dataForm.data_30}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Function</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"31"}
                        value={dataForm.data_31}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Expectation</td>
                    <td>
                      <FormElementTextArea
                        rows={2}
                        cols={80}
                        fieldId={"32"}
                        value={dataForm.data_32}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        noLimit
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <FormElementLine />
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <table className="uk-table uk-table-small uk-table-divider form-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Assessment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nutrition:</td>
                    <td>
                      <FormElementTextArea
                        fieldId={"81"}
                        value={dataForm.data_81}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Exercise:</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"82"}
                        value={dataForm.data_82}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Home environment (Safety):</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"83"}
                        value={dataForm.data_83}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Spiritual:</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"84"}
                        value={dataForm.data_84}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Caregiver:</td>
                    <td>
                      <FormElementTextArea
                        rowSpan={2}
                        fieldId={"85"}
                        value={dataForm.data_85}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=""
                        width="50"
                        cols={80}
                        rows={2}
                        noLimit
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel
                label="Problem List and Plan of Management (SOAP)"
                fontWeight="bold"
              />
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                <SOAPSection
                  data={dataForm.data_116}
                  onChange={(data) => props.addValue({ data_116: data })}
                />
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel label="Follow up" fontWeight="bold" />
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-top uk-padding-remove-left">
                <FormElementTextArea
                  rows={1}
                  cols={80}
                  fieldId={"34"}
                  value={dataForm.data_34}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                  noLimit
                />
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <FormElementLabel label="Plan for next HV" fontWeight="bold" />
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top uk-padding-remove-left">
                <FormElementTextArea
                  rows={3}
                  cols={80}
                  fieldId={"35"}
                  value={dataForm.data_35}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label=""
                  width="50"
                  noLimit
                />
              </div>
            </div>
            <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
              <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top uk-padding-remove-left">
                <FormElementTextBox
                  fieldId={"36"}
                  value={dataForm.data_36}
                  fieldValue={"1"}
                  onChange={handleChange}
                  label="Medical Student/Physician"
                  width="50"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupForm
        title={""}
        hn={hn}
        txn={txn}
        show={showFormPopup}
        formType={popupFormType}
        dataForm={popupDataForm}
        onClose={onClosePopup}
        formId={props.formId || dataForm.form_id}
      ></PopupForm>
    </div>
  );
};

export default FormFammed;
