import React, { useEffect, useState } from 'react';
import Api from "../../Components/Api";
import UIkit from "uikit";
import moment from "moment";
import numeral from "numeral";
import __ from "../../Components/Language";
import $ from "jquery";
import Login from "../../Pages/Member/Login";
import SideMenu from "./SideMenu";
import Dashboard from "../../Pages/Member/Dashboard";
import HeaderMenu from "./HeaderMenu";
import Error404 from "../../Pages/Error404";
import User from "../../Pages/Member/User";
import SmartHealth from "../../Pages/Member/SmartHealth";
import CampaignReport from "../../Pages/Member/CampaignReport";
import UserSmartHealth from "../../Pages/Member/UserSmartHealth";
import Report from "../../Pages/Member/Report";
import People from "../../Pages/Member/People";
import Register from "../../Pages/Member/Register";
import AppointmentList from "../../Pages/Member/AppointmentList";
import HealthSummary from "../../Pages/Member/HealthSummary";
import WorkHealthRecord from "../../Pages/Member/WorkHealthRecord";
import WorkHealthRecordList from "../../Pages/Member/WorkHealthRecordList";
import PasswordRecover from "../../Pages/Member/PasswordRecover";
import prescriptionList from "../../Pages/Member/prescriptionList";
import invoiceList from "../../Pages/Member/InvoiceList";
import HIV from "./HIV";
import Survey from "../../Pages/Member/Survey";
import ImageUri from "../../Components/ImageUri";
import LanguageLoginSelect from "../../Components/LanguageLoginSelect";

const MemberRoute = (props) => {
  const [member, setMember] = useState({});
  const [login, setLogin] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [pathname, setPathName] = useState(props.location.pathname.split("/").filter(Boolean).join("/"));

  var qs = Api.queryString;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    var result = false;

    try {
      var resultReturn = await Api.member("MemberLoginBySession");

      var { result, member } = resultReturn;

      setLogin(result === true ? true : false);

      if(result) {
        setMember(member);
        setLoadingLogin(false);

        localStorage.setItem('memberProfileData', JSON.stringify(member));
      }

    } catch (e) {
      console.error(e);
    }
  }

  function routeRender() {
    var path = props.location.pathname
          .split("/")
          .filter(Boolean)
          .join("/");

    var routes = {
      "/": Chat,
      "/register": UserSmartHealth,
      "/member": UserSmartHealth,
      "/user": User,
      "/user-smart-health": UserSmartHealth,
      "/chat": Chat,
      "/campaign-chat": CampaignChat,
      "/appointment-list": AppointmentList,
      "/health-summary": HealthSummary,
      "/work-health-record": WorkHealthRecord,
      "/work-health-record-list": WorkHealthRecordList,
      "/prescriptionList": prescriptionList,
      "/invoiceList": invoiceList,
      "/hiv": HIV,
      "/survey": Survey,
    };

    var Route = routes[`/${path}`];

    if (Route) {
      return <Route {...props} qs={qs} member={member} />;
    } else {
      return <Error404 {...props} />;
    }
  };

    if (login) {
        return (
          <div uk-grid="" className="uk-grid-collapse">
            <div id="d-side" className="uk-width-1-1">
              <SideMenu
                {...props}
                level={1}
                member={member}
              />
            </div>
            <div id="d-body" className="uk-width-expand uk-width-1-1 ">
              <HeaderMenu {...props} />
              <div>{routeRender()}</div>
              <div className="d-footer">
                <div
                  uk-grid=""
                  className="uk-child-width-1-1 uk-child-width-1-2@l"
                >
                  <div>
                    {/*
                Copyright © {moment().format('YYYY')} <a className="text-bold-800 grey darken-2" href="https://dietz.asia/" target="_blank" rel="noopener">dietz.asia </a>
                */}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        );
    } else {
      return loadingLogin ? (
        <div className="uk-text-center uk-padding-small d-page-loadings-login">
          <span uk-spinner=""></span>
        </div>
      ) : (
        <Login {...props} qs={qs} />
      );
    }
}

export default MemberRoute;