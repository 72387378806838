import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../../css/dietz.css";

const IPaperPopupForm = (props) => {
    const { title, data, onSave, onClose } = props;

    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [document.body.style.overflow]);

    useEffect(() => {
        return () => { document.body.style.overflow = "auto"; }
    }, []);

    return (
        <>
            <div className="form-modal-container">
                <div
                    className="form-modal-dialog-container"
                    style={{ paddingBottom: "20px", width: '800px', paddingLeft: '8%', paddingRight: '8%' }}
                >
                    <div className="form-modal-dialog-header">
                        <div className="uk-modal-title">{title || ""}</div>
                        <div className="form-modal-right-header">
                            <button
                                className="uk-button-default form-modal-close-button"
                                type="button"
                                onClick={onClose}
                            >
                                <span uk-icon="icon: close; ratio: 1" className="dietz-danger-icon"></span>
                            </button>
                        </div>
                    </div>
                    <div className="form-modal-dialog-body" style={{ padding: '30px 40px' }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <div>{data}</div>
                        </div>
                    </div>
                    <div className="form-modal-dialog-footer uk-text-right" style={{ padding: '12px' }}>
                        <button
                            className="uk-button uk-button-primary uk-modal-close"
                            type="button"
                            onClick={onSave}
                        >
                            Save
                        </button>
                        <button
                            className="uk-button uk-button-default uk-modal-close"
                            style={{ marginLeft: '12px' }}
                            type="button"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IPaperPopupForm;
