var utilParseStringToJson = require("../Consult/Common/FormUtil").parseStringToJson;

function transformResponse(formType, data) {
    if (formType == 1) {
        data.data_2 = utilParseStringToJson(data.data_2);
        data.data_113 = utilParseStringToJson(data.data_113);
        data.data_114 = utilParseStringToJson(data.data_114);
        data.data_115 = utilParseStringToJson(data.data_115);
        data.data_116 = utilParseStringToJson(data.data_116);
    } else if (formType == 5) {
        data.data_28 = utilParseStringToJson(data.data_28);
    } else if (formType == 7) {
        data.data_181 = utilParseStringToJson(data.data_181);
        if (!data?.data_181) {
            data.data_181 = {
                age: "",
                pulse: "",
                sbp: "",
                creatinine: "",
                cardiacArrest: "0",
                st: "0",
                cardiacEnzymes: "0",
                killipClass: "0",
            };
        }
    } else if (formType == 10) {
        data.data_63 = utilParseStringToJson(data.data_63);
    } else if (formType == 11) {
        data.data_35 = utilParseStringToJson(data.data_35);
        data.data_79 = utilParseStringToJson(data.data_79);
        data.data_80 = utilParseStringToJson(data.data_80);
        data.data_81 = utilParseStringToJson(data.data_81);
        data.data_82 = utilParseStringToJson(data.data_82);
        data.data_83 = utilParseStringToJson(data.data_83);
        data.data_84 = utilParseStringToJson(data.data_84);
        data.data_85 = utilParseStringToJson(data.data_85);
        data.data_86 = utilParseStringToJson(data.data_86);
        data.data_87 = utilParseStringToJson(data.data_87);
        data.data_88 = utilParseStringToJson(data.data_88);
        data.data_89 = utilParseStringToJson(data.data_89);
        data.data_90 = utilParseStringToJson(data.data_90);
        data.data_91 = utilParseStringToJson(data.data_91);
        data.data_92 = utilParseStringToJson(data.data_92);
        data.data_93 = utilParseStringToJson(data.data_93);
        data.data_94 = utilParseStringToJson(data.data_94);
        data.data_95 = utilParseStringToJson(data.data_95);
        data.data_96 = utilParseStringToJson(data.data_96);
        data.data_97 = utilParseStringToJson(data.data_97);
        data.data_98 = utilParseStringToJson(data.data_98);
        data.data_99 = utilParseStringToJson(data.data_99);
        data.data_100 = utilParseStringToJson(data.data_100);
    } else if (formType == 14) {
        data.data_112 = utilParseStringToJson(data.data_112);
        data.data_114 = utilParseStringToJson(data.data_114);
        data.data_116 = utilParseStringToJson(data.data_116);
        data.data_120 = utilParseStringToJson(data.data_120);
        data.data_122 = utilParseStringToJson(data.data_122);
        data.data_124 = utilParseStringToJson(data.data_124);
        data.data_126 = utilParseStringToJson(data.data_126);
        data.data_128 = utilParseStringToJson(data.data_128);
        data.data_130 = utilParseStringToJson(data.data_130);
        data.data_132 = utilParseStringToJson(data.data_132);
        data.data_134 = utilParseStringToJson(data.data_134);
        data.data_136 = utilParseStringToJson(data.data_136);
        data.data_140 = utilParseStringToJson(data.data_140);
        data.data_142 = utilParseStringToJson(data.data_142);
        data.data_144 = utilParseStringToJson(data.data_144);
        data.data_146 = utilParseStringToJson(data.data_146);
        data.data_148 = utilParseStringToJson(data.data_148);
        data.data_150 = utilParseStringToJson(data.data_150);
        data.data_152 = utilParseStringToJson(data.data_152);
        data.data_154 = utilParseStringToJson(data.data_154);
        data.data_157 = utilParseStringToJson(data.data_157);
        data.data_159 = utilParseStringToJson(data.data_159);
        data.data_161 = utilParseStringToJson(data.data_161);
        data.data_165 = utilParseStringToJson(data.data_165);
        data.data_188 = utilParseStringToJson(data.data_188);
        data.data_167 = utilParseStringToJson(data.data_167);
        data.data_169 = utilParseStringToJson(data.data_169);
        data.data_138 = utilParseStringToJson(data.data_138);
    } else if (formType == 18) {
        data.data_26 = utilParseStringToJson(data.data_26);
    } else if (formType == 29) {
        data.data_61 = utilParseStringToJson(data.data_61);
    } else if (formType == 38) {
        data.data_drug = utilParseStringToJson(data.data_drug);
    }

    return data;
}

export default transformResponse;