import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import dateFormat from "dateformat";
import FormElementIconButton from "../Form/FormElementIconButton";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormHeartFailureOPD = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_heartfailureopd",
      formType: "15",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    if (!dataForm.data_1) {
      setData("1", dateFormat(new Date(), "yyyy-mm-dd"));
    }
  }, [dataForm]);

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="บันทึกการตรวจโรคผู้ป่วยนอก (HEART FAILURE CLINIC)" />
      </div>
      <div className="uk-width-1-1">
        <div className="uk-width-1-1">
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">Date</span>
            <FormElementTextBox
              fieldId={"1"}
              value={dataForm.data_1}
              type="date"
              onChange={handleChange}
              width="100"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextBox
              fieldId={"2"}
              value={dataForm.data_2}
              type="numeric"
              label="BW"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">kgs.</span>
            <span className="formTextBoxLabel">(</span>
            <FormElementTextBox
              fieldId={"3"}
              value={dataForm.data_3}
              type="numeric"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">)</span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextBox
              fieldId={"4"}
              value={dataForm.data_4}
              type="numeric"
              label="HR"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">ครั้ง/นาที</span>
            <FormElementTextBox
              fieldId={"5"}
              value={dataForm.data_5}
              type="numeric"
              label="BP"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">/</span>
            <FormElementTextBox
              fieldId={"6"}
              value={dataForm.data_6}
              type="numeric"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">(นอน)</span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextBox
              fieldId={"7"}
              value={dataForm.data_7}
              type="numeric"
              label="RR"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">ครั้ง/นาที</span>
            <FormElementTextBox
              fieldId={"8"}
              value={dataForm.data_8}
              type="numeric"
              label="BP"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">/</span>
            <FormElementTextBox
              fieldId={"9"}
              value={dataForm.data_9}
              type="numeric"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">(นั่ง)</span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextBox
              fieldId={"10"}
              value={dataForm.data_10}
              type="numeric"
              label="SaO2"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">%</span>
            <FormElementTextBox
              fieldId={"11"}
              value={dataForm.data_11}
              type="numeric"
              label="BP"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">/</span>
            <FormElementTextBox
              fieldId={"12"}
              value={dataForm.data_12}
              type="numeric"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">(ยืน)</span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">Functional Class</span>
            <FormElementRadio
              fieldId={"13"}
              value={dataForm.data_13}
              fieldValue={"1"}
              onChange={handleChange}
              label="I"
            />
            <FormElementRadio
              fieldId={"13"}
              value={dataForm.data_13}
              fieldValue={"2"}
              onChange={handleChange}
              label="II"
            />
            <FormElementRadio
              fieldId={"13"}
              value={dataForm.data_13}
              fieldValue={"3"}
              onChange={handleChange}
              label="III"
            />
            <FormElementRadio
              fieldId={"13"}
              value={dataForm.data_13}
              fieldValue={"4"}
              onChange={handleChange}
              label="IV"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextArea
              fieldId={"14"}
              value={dataForm.data_14}
              rows={5}
              cols={60}
              noLimit
              label="Nurse note"
              onChange={handleChange}
              width="100"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">ER visit</span>
            <FormElementRadio
              fieldId={"125"}
              value={dataForm.data_125}
              fieldValue={"1"}
              onChange={handleChange}
              label="Yes"
            />
            <span className="formTextBoxLabel" />
            <FormElementRadio
              fieldId={"125"}
              value={dataForm.data_125}
              fieldValue={"0"}
              onChange={handleChange}
              label="No"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">ER admit</span>
            <FormElementRadio
              fieldId={"126"}
              value={dataForm.data_126}
              fieldValue={"1"}
              onChange={handleChange}
              label="Yes"
            />
            <span className="formTextBoxLabel" />
            <FormElementRadio
              fieldId={"126"}
              value={dataForm.data_126}
              fieldValue={"0"}
              onChange={handleChange}
              label="No"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextArea
              fieldId={"15"}
              value={dataForm.data_15}
              noLimit
              rows={5}
              cols={60}
              label="Pharmacist note"
              onChange={handleChange}
              width="100"
            />
          </div>
        </div>
        <div className="uk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
          <table>
            <tbody>
              <tr>
                <td className="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "8px",
                    }}
                  >
                    <span>CBC</span>
                    <FormElementIconButton icon="search" sizeRatio="1" />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      gap: "8px",
                    }}
                  >
                    <span>Electrolyte</span>
                    <FormElementIconButton icon="search" sizeRatio="1" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="uk-flex-inline">
                    <div
                      style={{
                        position: "relative",
                        height: "200px",
                        width: "350px",
                      }}
                    >
                      <div style={{ position: "relative", width: "200px" }}>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "48px",
                            left: "48px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"16"}
                            value={dataForm.data_16}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "48px",
                            left: "192px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"17"}
                            value={dataForm.data_17}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "132px",
                            left: "120px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"18"}
                            value={dataForm.data_18}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                      </div>

                      <div className="line-background">
                        <div className="line-1" />
                        <div className="line-2" />
                        <div className="line-3" />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="uk-flex-inline">
                    <div
                      style={{
                        position: "relative",
                        height: "200px",
                        width: "400px",
                      }}
                    >
                      <div style={{ position: "relative", width: "200px" }}>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "24px",
                            left: "80px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"19"}
                            value={dataForm.data_19}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "24px",
                            left: "210px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"20"}
                            value={dataForm.data_20}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "114px",
                            left: "80px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"21"}
                            value={dataForm.data_21}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "114px",
                            left: "210px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"22"}
                            value={dataForm.data_22}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "20px",
                            left: "380px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"23"}
                            value={dataForm.data_23}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "70px",
                            left: "380px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"24"}
                            value={dataForm.data_24}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "80px",
                            top: "120px",
                            left: "380px",
                          }}
                        >
                          <FormElementTextBox
                            fieldId={"25"}
                            value={dataForm.data_25}
                            onChange={handleChange}
                            width="100"
                          />
                        </div>
                      </div>

                      <div className="line-background-2">
                        <div className="line-4" />
                        <div className="line-5" />
                        <div className="line-6" />
                        <div className="line-7" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextBox
              fieldId={"26"}
              value={dataForm.data_26}
              type="date"
              label="Echo last"
              onChange={handleChange}
              width="100"
            />
            <FormElementTextBox
              fieldId={"27"}
              value={dataForm.data_27}
              type="numeric"
              label="EF"
              onChange={handleChange}
              width="100"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">NT-pro BNP</span>
            <FormElementIconButton icon="search" sizeRatio="1" />
            &nbsp; &nbsp; &nbsp;
            <FormElementTextBox
              fieldId={"28"}
              value={dataForm.data_28}
              type="numeric"
              onChange={handleChange}
              width="100"
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">
              Doctor note รายละเอียดการซักประวัติ ตรวจร่างกาย
            </span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <FormElementTextArea
              fieldId={"29"}
              value={dataForm.data_29}
              noLimit
              label=""
              onChange={handleChange}
              width="100"
              rows={3}
              cols={60}
            />
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementTextBox
            fieldId={"30"}
            value={dataForm.data_30}
            onChange={handleChange}
            // dataList={[
            //   { label: "เลือกการคุมกำเนิด", value: "" },
            //   { label: "ไม่มี", value: "0" },
            //   { label: "ถุงยาง", value: "1" },
            // ]}
            label="การคุมกำเนิด"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small">
          <span className="formTextBoxLabel">Flu. vaccine:</span>
          <FormElementRadio
            fieldId={"31"}
            value={dataForm.data_31}
            fieldValue={"1"}
            onChange={handleChange}
            label="Yes"
          />
          <span
            className={dataForm.data_31 == "1" ? "form-show" : "form-hidden"}
          >
            <FormElementTextBox
              fieldId={"32"}
              value={dataForm.data_32}
              type="numeric"
              label="ปี"
              onChange={handleChange}
              width="100"
            />
          </span>
          <span className="formTextBoxLabel" />
          <FormElementRadio
            fieldId={"31"}
            value={dataForm.data_31}
            fieldValue={"2"}
            onChange={handleChange}
            label="No"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span className="formTextBoxLabel">Covid vaccine:</span>
          <FormElementRadio
            fieldId={"33"}
            value={dataForm.data_33}
            fieldValue={"1"}
            onChange={handleChange}
            label="Yes"
          />
          <div className="uk-display-inline" hidden={dataForm.data_33 != "1"}>
            <FormElementTextBox
              fieldId={"124"}
              value={dataForm.data_124}
              type="date"
              label="ฉีดยาล่าสุด"
              onChange={handleChange}
              width="100"
            />
          </div>
          <span className="formTextBoxLabel" />
          <FormElementRadio
            fieldId={"33"}
            value={dataForm.data_33}
            fieldValue={"0"}
            onChange={handleChange}
            label="No"
          />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="Medications" />
      </div>
      <div>
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th>Drug</th>
              <th>รายการยาเดิม </th>
              <th>As Ordered</th>
              <th>Actual</th>
              <th>จํานวนยาคงเหลือ</th>
              <th>สรุปจํานวนยา</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Antiplatelet</td>
              <td>
                <FormElementTextBox
                  fieldId={"34"}
                  value={dataForm.data_34}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"35"}
                  value={dataForm.data_35}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"36"}
                  value={dataForm.data_36}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"37"}
                  value={dataForm.data_37}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"38"}
                  value={dataForm.data_38}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Anticoagulant</td>
              <td>
                <FormElementTextBox
                  fieldId={"39"}
                  value={dataForm.data_39}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"40"}
                  value={dataForm.data_40}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"41"}
                  value={dataForm.data_41}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"42"}
                  value={dataForm.data_42}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"43"}
                  value={dataForm.data_43}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>ACEI/ARB/ARNI</td>
              <td>
                <FormElementTextBox
                  fieldId={"44"}
                  value={dataForm.data_44}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"45"}
                  value={dataForm.data_45}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"46"}
                  value={dataForm.data_46}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"47"}
                  value={dataForm.data_47}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"48"}
                  value={dataForm.data_48}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>β-Blocker</td>
              <td>
                <FormElementTextBox
                  fieldId={"49"}
                  value={dataForm.data_49}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"50"}
                  value={dataForm.data_50}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"51"}
                  value={dataForm.data_51}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"52"}
                  value={dataForm.data_52}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"53"}
                  value={dataForm.data_53}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Spironolactone</td>
              <td>
                <FormElementTextBox
                  fieldId={"54"}
                  value={dataForm.data_54}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"55"}
                  value={dataForm.data_55}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"56"}
                  value={dataForm.data_56}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"57"}
                  value={dataForm.data_57}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"58"}
                  value={dataForm.data_58}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>SGLT2i</td>
              <td>
                <FormElementTextBox
                  fieldId={"59"}
                  value={dataForm.data_59}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"60"}
                  value={dataForm.data_60}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"61"}
                  value={dataForm.data_61}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"62"}
                  value={dataForm.data_62}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"63"}
                  value={dataForm.data_63}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Ivabradine</td>
              <td>
                <FormElementTextBox
                  fieldId={"64"}
                  value={dataForm.data_64}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"65"}
                  value={dataForm.data_65}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"66"}
                  value={dataForm.data_66}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"67"}
                  value={dataForm.data_67}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"68"}
                  value={dataForm.data_68}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Digoxin</td>
              <td>
                <FormElementTextBox
                  fieldId={"69"}
                  value={dataForm.data_69}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"70"}
                  value={dataForm.data_70}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"71"}
                  value={dataForm.data_71}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"72"}
                  value={dataForm.data_72}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"73"}
                  value={dataForm.data_73}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Vericiguat</td>
              <td>
                <FormElementTextBox
                  fieldId={"74"}
                  value={dataForm.data_74}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"75"}
                  value={dataForm.data_75}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"76"}
                  value={dataForm.data_76}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"77"}
                  value={dataForm.data_77}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"78"}
                  value={dataForm.data_78}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Nitrate</td>
              <td>
                <FormElementTextBox
                  fieldId={"79"}
                  value={dataForm.data_79}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"80"}
                  value={dataForm.data_80}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"81"}
                  value={dataForm.data_81}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"82"}
                  value={dataForm.data_82}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"83"}
                  value={dataForm.data_83}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Hydralazine</td>
              <td>
                <FormElementTextBox
                  fieldId={"84"}
                  value={dataForm.data_84}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"85"}
                  value={dataForm.data_85}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"86"}
                  value={dataForm.data_86}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"87"}
                  value={dataForm.data_87}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"88"}
                  value={dataForm.data_88}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Diuretic</td>
              <td>
                <FormElementTextBox
                  fieldId={"89"}
                  value={dataForm.data_89}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"90"}
                  value={dataForm.data_90}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"91"}
                  value={dataForm.data_91}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"92"}
                  value={dataForm.data_92}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"93"}
                  value={dataForm.data_93}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Lipid lowering agent</td>
              <td>
                <FormElementTextBox
                  fieldId={"94"}
                  value={dataForm.data_94}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"95"}
                  value={dataForm.data_95}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"96"}
                  value={dataForm.data_96}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"97"}
                  value={dataForm.data_97}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"98"}
                  value={dataForm.data_98}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>Antidiabetic agent</td>
              <td>
                <FormElementTextBox
                  fieldId={"99"}
                  value={dataForm.data_99}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"100"}
                  value={dataForm.data_100}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"101"}
                  value={dataForm.data_101}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"102"}
                  value={dataForm.data_102}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"103"}
                  value={dataForm.data_103}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td rowSpan={4}>Other antihypertensive</td>
              <td>
                <FormElementTextBox
                  fieldId={"104"}
                  value={dataForm.data_104}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"105"}
                  value={dataForm.data_105}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"106"}
                  value={dataForm.data_106}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"107"}
                  value={dataForm.data_107}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"108"}
                  value={dataForm.data_108}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementTextBox
                  fieldId={"109"}
                  value={dataForm.data_109}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"110"}
                  value={dataForm.data_110}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"111"}
                  value={dataForm.data_111}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"112"}
                  value={dataForm.data_112}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"113"}
                  value={dataForm.data_113}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementTextBox
                  fieldId={"114"}
                  value={dataForm.data_114}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"115"}
                  value={dataForm.data_115}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"116"}
                  value={dataForm.data_116}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"117"}
                  value={dataForm.data_117}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"118"}
                  value={dataForm.data_118}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
            <tr>
              <td>
                <FormElementTextBox
                  fieldId={"119"}
                  value={dataForm.data_119}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"120"}
                  value={dataForm.data_120}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"121"}
                  value={dataForm.data_121}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"122"}
                  value={dataForm.data_122}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
              <td>
                <FormElementTextBox
                  fieldId={"123"}
                  value={dataForm.data_123}
                  label=""
                  onChange={handleChange}
                  width="100"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormHeartFailureOPD;
