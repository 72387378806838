import React, { Component, useEffect } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import ConsultProfileCard from "../../Components/Consult/ConsultProfileCard";
import ConsultAppointmentToday from "../../Components/Consult/ConsultAppointmentToday";
import ConsultMemberSummary from "../../Components/Consult/ConsultMemberSummary.js";
import ConsultCampaignTable from "../../Components/Consult/ConsultCampaignTable.js";
import { useCookies } from "react-cookie";

UIkit.use(Icons);

const ConsultDashboard = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "currentUrlBeforeLogin",
  ]);

  useEffect(() => {
    //Check last url login
    var currentUrlBeforeLogin = cookies.currentUrlBeforeLogin;

    if (currentUrlBeforeLogin != null) {
      removeCookie("currentUrlBeforeLogin");
      window.location.href = currentUrlBeforeLogin;
    }
  }, []);

  return (
    <div className="uk-padding">
      <div uk-grid="">
        <div className="uk-width-1-1 uk-width-1-2@m">
          <ConsultProfileCard
            setShowAlert={props.setShowAlert}
            setAlertMessage={props.setAlertMessage}
          />
        </div>
        <div className="uk-width-1-1 uk-width-1-2@m">
          <ConsultMemberSummary />
        </div>
        <div className="uk-width-1-1 uk-width-1-1@m">
          <ConsultAppointmentToday
            setShowAlert={props.setShowAlert}
            setAlertMessage={props.setAlertMessage}
          />
        </div>
      </div>
    </div>
  );
};
export default ConsultDashboard;
