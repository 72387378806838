import React, { useEffect, useState } from "react";

const CONSTANTS = {
  DEFAULT_COLS: 20,
};

const FormElementTextArea = (props) => {
  var mode = "edit";

  if (typeof props.mode != "undefined") {
    mode = props.mode;
  }

  var paddingStyle = "0px 0px 0px 0px";

  if (typeof props.padding != "undefined") {
    paddingStyle = props.padding;
  }

  const styleObj = {
    border: "none",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    padding: paddingStyle,
  };

  useEffect(() => {
    if (typeof props.value != "undefined") {
      //props.onChange(props.fieldId, props.value);
    }
  }, [props.value]);

  function onChange(e) {
    //console.log("onchange " + props.fieldId + " " + props.value);

    props.onChange(props.fieldId, e);
  }

  var valueTxt = "";

  if (props.value != null) {
    valueTxt = props.value;
  }

  if (mode != "view") {
    return (
      <div className="uk-inline text-area">
        {props.label ? (
          <span className="formTextBoxLabel">
            {props.label}{" "}
            {props.isRequired ? <span style={{ color: "red" }}>*</span> : null}
          </span>
        ) : null}
        <textarea
          rows={props.rows}
          className="formTextArea uk-textarea"
          maxLength={props.noLimit ? undefined : 255}
          onChange={(e) => onChange(e)}
          value={valueTxt}
          disabled={props.disabled}
          cols={props.cols ? props.cols : CONSTANTS.DEFAULT_COLS}
        />
      </div>
    );
  } else {
    if (props.value != "" && props.value != null) {
      return (
        <div
          style={{
            wordBreak: "break-word",
          }}
        >
          {props.label ? <span>{props.label}</span> : null}
          <pre
            style={styleObj}
            dangerouslySetInnerHTML={{ __html: props.value }}
          ></pre>
        </div>
      );
    } else {
      return <></>;
    }
  }
};

export default FormElementTextArea;
