import React, {Component} from 'react';
import Api from './Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Link } from "react-router-dom";

UIkit.use(Icons);

export default class UserProfileCardChangePassword extends Component {
  state = {
    password: '',
    passwordNew: '',
    passwordNew2: '',
    loadingApi: false,
    responseText: ''
  }

  componentDidMount() {
  }

  passwordChange = async () => {
    var responseText = '';
    if(this.state.loadingApi) return false;

    if(this.state.passwordNew.trim().length < 4) {
      responseText = 'โปรดใส่รหัสผ่านมากกว่า 4 ตัวอักษร';
    } else if(this.state.passwordNew.trim() === this.state.password ) {
      responseText = 'โปรดเปลี่ยนรหัสผ่าน';
    } else if(this.state.passwordNew.trim() !== this.state.passwordNew2 ) {
      responseText = 'รหัสผ่านทั้งสองครั้งไม่ตรงกัน';
    } else if(this.state.password.trim() === '' ) {
      responseText = 'โปรดใส่รหัสผ่านปัจจุบัน';
    } else {
      this.setState({loadingApi: true});

      var {responseText, result} = await Api[this.props.type==='c' ? 'consult': 'member']('PasswordChange',{
        id: this.props.id,
        password: this.state.password,
        passwordNew: this.state.passwordNew
      });

      this.setState({loadingApi: false});

      if(result) window.location.reload();
    }
    this.setState({responseText});

    UIkit.notification(responseText);
    
  }

  
  render() {
    return <div className="uk-padding-small">
      <div>เปลี่ยนรหัสผ่าน</div>
      <input className="uk-input uk-margin-small-top" type="password" value={this.state.password} onChange={(e)=>this.setState({password: e.target.value})} placeholder="รหัสผ่านปัจจุบัน" />
      <input className="uk-input uk-margin-small-top" type="password" value={this.state.passwordNew} onChange={(e)=>this.setState({passwordNew: e.target.value})} placeholder="รหัสผ่านใหม่"  />
      <input className="uk-input uk-margin-small-top" type="password" value={this.state.passwordNew2} onChange={(e)=>this.setState({passwordNew2: e.target.value})} placeholder="รหัสผ่านใหม่อีกครั้ง"  />
      <div className="uk-margin-small-top uk-text-right">
        <span className="uk-float-left uk-text-meta">{this.state.responseText}</span>
        <button className="uk-button uk-button-primary" onClick={()=>this.passwordChange()} disable={this.state.loadingApi} >บันทึก</button>
      </div>
    
    </div>
  }
};
