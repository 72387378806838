import React, { useState, useEffect } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import DateRangePicker from "../../Components/DateRangePicker";
import moment from 'moment';
import {
  formStroke, formNursingAssessment, formACSCaringSet
  , formACSFollowUP, formHeartFailure, formCathLabDischarge,
  formHeartFailureDischargeCheckList, formMinnesotaLivingWithHeartFailure,
  formHeartFailureClinic, formHeartFailureOPD
} from '../../Components/handleForm'
import { useHistory } from 'react-router-dom';


UIkit.use(Icons);

const FormList = (props) => {
  const history = useHistory();
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [formTypeList, setFormTypeList] = useState([]);
  const [dateQuery, setDateQuery] = useState({
    startDate: new Date(),
    endDate: new Date()
  });
  const [hn, setHN] = useState("");
  const [formType, setFormType] = useState("")
  const [isShowExport, setIsShowExport] = useState(false)
  const numForm = ["18", "14", "15", "16", "42", "43", "7", "10", "6", "8"]

  async function listGet() {
    var { list } = await Api.consult("FormList", {
      date: dateQuery,
      hn: hn,
      formType: formType,
    });
    // console.log(list)

    if (list != null) {
      setDataList(list);

    } else {
      setDataList([]);

    }

    setLoadingApi(false);

    var result = await Api.consult("FormTypeList", {});

    var { list } = result;

    if (list != null) {
      setFormTypeList(list);

    } else {
      setFormTypeList([]);

    }
  }

  function changeDate(data) {
    // console.log(data)
    setDateQuery(data);
  }

  function getFormUrl(formType) {
    switch (formType) {
      case "38":
        return '/consult/form/telepharmacy';
      case "22":
        return '/consult/form/wafarin';
      case "23":
        return '/consult/form/noac';
      case "24":
        return '/consult/form/inhaler';
      case "25":
        return '/consult/form/insurin';
      case "26":
        return '/consult/form/bispho';
      case "27":
        return '/consult/form/deliveryGeneralMedicine';
      case "28":
        return '/consult/form/deliveryDrugMedicine';
      case "29":
        return '/consult/form/deliveryWafarinMedicine';
      case "1":
        return '/consult/form/fammed';
      case "30":
        return '/consult/form/pps';
      case "2":
        return '/consult/form/esas';
      case "3":
        return '/consult/form/posScale';
      case "4":
        return '/consult/form/goodDeath';
      case "5":
        return '/consult/form/tmse';
      case "6":
        return '/consult/form/nursingAssesment';
      case "7":
        return '/consult/form/acsCaringSet';
      case "8":
        return '/consult/form/acsTeleFollowUp';
      case "9":
        return '/consult/form/heartRepair';
      case "10":
        return '/consult/form/cathLabDischarge';
      case "11":
        return '/consult/form/cathLabAssessment';
      case "12":
        return '/consult/form/homeVisit';
      case "44":
        return '/consult/form/adls';
      case "45":
        return '/consult/form/iadls';
      case "31":
        return '/consult/form/moca';
      case "32":
        return '/consult/form/2q';
      case "33":
        return '/consult/form/8q';
      case "34":
        return '/consult/form/9q';
      case "35":
        return '/consult/form/fearFalling';
      case "36":
        return '/consult/form/zarit12';
      case "37":
        return '/consult/form/drugAdherence';
      case "39":
        return '/consult/form/tgds';
      case "40":
        return '/consult/form/gad7';
      case "41":
        return '/consult/form/postFalling';
      case "13":
        return '/consult/form/consultation';
      case "18":
        return '/consult/form/stroke';
      case "17":
        return '/consult/form/adl';
      case "19":
        return '/consult/form/rankleScale';
      case "14":
        return '/consult/form/heartFailure';
      case "15":
        return '/consult/form/heartFailureOpd';
      case "16":
        return '/consult/form/heartFailureDischargeChecklist';
      case "42":
        return '/consult/form/heartFailureClinic';
      case "43":
        return '/consult/form/minnesotaLivingWithHeartFailure';
      default:
        return `/consult/formAdd?formType=${formType}`;
    }
  }

  function addForm() {
    const element = document.getElementById('formType');
    const url = getFormUrl(element.value);
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  function editForm(formType, formName, id) {
    const url = getFormUrl(formType);
    if (url.includes('formType')) {
      history.push(`${url}&formId=${id}&formName=${formName}`);
    } else {
      history.push(`${url}?formId=${id}`);
    }
  }

  function changeHN(event) {
    setHN(event.target.value);
  }
  function handleFormTypeList(event) {
    setFormType(event.target.value)
    if (numForm.includes(event.target.value)) {
      setIsShowExport(true)
    } else {
      setIsShowExport(false)
    }
  }

  async function getExportData() {
    const filter = {
      formType: formType,
      hn: hn,
      date: dateQuery
    }
    const { result, data, responseText } = await Api.consult("ExportExcel", filter);
    var status
    if (result) {
      switch (formType) {

        case "6":
          status = await formNursingAssessment(data, filter)
          break;
        case "7":
          status = await formACSCaringSet(data, filter)
          break;
        case "8":
          status = await formACSFollowUP(data, filter)
          break;
        case "10":
          status = await formCathLabDischarge(data, filter)
          break;
        case "14":
          status = await formHeartFailure(data, filter)
          break;

        case "15":
          status = await formHeartFailureOPD(data, filter)
          break;
        case "16":
          status = await formHeartFailureDischargeCheckList(data, filter)
          break;
        case "18":
          status = await formStroke(data, filter)
          break;
        case "42":
          status = await formHeartFailureClinic(data, filter)
          break;
        case "43":
          status = await formMinnesotaLivingWithHeartFailure(data, filter)
          break;
        //...other formType
        default:
          break;
      }
    } else {
      UIkit.notification({
        message: responseText,
        status: 'danger',
        pos: 'top-right',
        timeout: 5000
      });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    listGet();
    // console.log(formData);
  };

  useEffect(() => {
    listGet();
  }, []);

  return (
    <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">รายการแบบฟอร์ม</div>
        <form onSubmit={handleSubmit}>
          <div uk-grid="" className="uk-flex-middle">
            <div className="uk-width-2-5@l uk-width-1-1">
              <DateRangePicker callback={changeDate} />
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
              HN : <input id="hn" name='hn' type="text" style={{ width: "100px" }} onChange={changeHN} />
            </div>
            <div className="uk-width-2-5@m uk-width-1-2@l uk-width-1-1">
              Form Type :
              <select id="formType" name="form_type" value={formTypeList.form_type} onChange={handleFormTypeList}>
                <option value="" key="">เลือกแบบฟอร์ม</option>
                {
                  formTypeList.length === 0 ?
                    <div></div>
                    :
                    formTypeList.map((data, key) => {
                      return <option value={data.form_type} key={key}>{data.form_type_name}</option>
                    })
                }
              </select>
            </div>

            <div className="uk-flex uk-width-2-5@m uk-width-1-2@l uk-width-1-1 uk-flex-right">
              {isShowExport ? (
                <>
                  <button className="uk-button uk-button-primary" style={{ height: "40px" }} onClick={() => getExportData()}>export</button>&nbsp;&nbsp;
                </>
              ) : (<></>)
              }
              <button className="uk-button uk-button-primary" style={{ height: "40px" }} type="submit" onClick={listGet}>ค้นหา</button>&nbsp;&nbsp;
              <button className="uk-button uk-button-primary" style={{ height: "40px" }} onClick={addForm}>+</button>
            </div>

          </div>
        </form>
        <hr />
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>HN</th>
                <th>TXN</th>
                <th>ประเภทแบบฟอร์ม</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                loadingApi == true ?
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                    </td>
                  </tr>
                  :
                  dataList.length === 0 ?
                    <tr>
                      <td colSpan="100%">
                        <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                      </td>
                    </tr>
                    :
                    dataList.map((data, key) => {
                      return <tr key={`row${data.form_control_id}`}>
                        <td>{key + 1}</td>
                        <td>
                          {moment(data.created_date).format('DD/MM/YYYY')}
                        </td>
                        <td>
                          {moment(data.created_date).format('HH:mm น.')}
                        </td>
                        <td>{data.patient_name}</td>
                        <td>{data.hn}</td>
                        <td>{data.txn}</td>
                        <td>{data.form_type_name}</td>
                        <td><button className='uk-button uk-button-primary' target='_blank' onClick={() => editForm(data.form_type, data.form_name, data.form_id)}>แก้ไข</button> | <button className='uk-button uk-button-primary' target='_blank' onClick={() => deleteForm(data.form_id)}>ลบ</button></td>
                      </tr>
                    })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default FormList;