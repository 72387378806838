import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormADLPaper from "../FormPaper/FormADLPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormADL = (props) => {
  const formConstants = {
    formName: "form_adl",
    formType: "17",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [oldData, setOldData] = useState([]);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      hideLoading();
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all([patientData, patientRoomRequest]).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];

      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);

  useEffect(() => {
    if (!isSubForm) {
      if (!formId && hn && txn) fetchData(null, hn, txn)
    }
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
      dataForm.data_10n,
    ]);
  }, [dataForm]);

  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html, getTotalScore);
  }, [dataForm, onAdd, getTotalScore]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="แบบประเมินกิจวัตรประจําวัน ดัชนีบาร์เธลเอดีแอล (Barthel Activities of Daily Living : ADL)" />
          <FormElementLabel
            color={"red"}
            label="( คะแนนรวม : "
            fontWeight="bold"
          />
          <FormElementLabel color={"red"} label={getTotalScore} />
          <FormElementLabel color={"red"} label=") " fontWeight="bold" />
        </div>

        <div className="uk-width-2-3@m uk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <td colSpan={2}>การประเมินกิจวัตรประจำวัน</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  1. รับประทานอาหารเมื่อเตรียมไว้ให้เรียบร้อยต่อหน้า
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ไม่สามารถตักอาหารเข้าปากได้</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>
                  ตักอาหารเองได้ แต่ต้องมีคนช่วย เช่น
                  ช่วยใช้ช้อนตักเตรียมให้/ตัดเป็นชิ้นเล็กๆให้
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"1n"}
                    value={dataForm.data_1n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ตักอาหารและช่วยตัวเองได้เป็นปกติ</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  2. การล้างหน้า หวีผม แปรงฟัน โกนหนวดในระยะเวลา 24–48
                  ชั่วโมงที่ผ่านมา
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ต้องการความช่วยเหลือ</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"2n"}
                    value={dataForm.data_2n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ทำได้เอง (รวมทั้งที่ทำได้เองถ้าเตรียมอุปกรณ์ไว้ให้)</td>
              </tr>
              <tr>
                <td colSpan={2}>3. ลุกนั่งจากที่นอน หรือจากเตียงไปยังเก้าอี้</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>
                  ไม่สามารถนั่งได้ (นั่งแล้วจะล้มเสมอ) หรือต้องใช้คน 2
                  คนช่วยกันยกขึ้น
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>
                  ต้องใช้คนแข็งแรงหรือมีทักษะ 1 คน/ใช้คนทั่วไป 2
                  คนพยุงดันขึ้นมาจึงจะนั่งอยู่ได้
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>
                  ต้องการความช่วยเหลือบ้าง
                  เช่นช่วยพยุงเล็กน้อย/ต้องมีคนดูแลเพื่อความปลอดภัย
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"3n"}
                    value={dataForm.data_3n}
                    fieldValue={"15"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ทำได้เอง</td>
              </tr>
              <tr>
                <td colSpan={2}>4. การใช้ห้องน้ำ</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ช่วยตัวเองไม่ได้</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ทำเองได้บ้าง ต้องการความช่วยเหลือในบางสิ่ง</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"4n"}
                    value={dataForm.data_4n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ช่วยเหลือตนเองได้ดี</td>
              </tr>
              <tr>
                <td colSpan={2}>5. การเคลื่อนที่ภายในห้องหรือบ้าน</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>เคลื่อนที่ไปไหนไม่ได้</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>
                  ใช้รถเข็นช่วยให้เคลื่อนที่ได้เอง (ไม่ต้องมีคนเข็นให้)
                  เข้าออกมุมห้องหรือประตูได้
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>เดินหรือเคลื่อนที่โดยมีคนช่วย เช่น พยุง</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"5n"}
                    value={dataForm.data_5n}
                    fieldValue={"15"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>เดินหรือเคลื่อนที่ได้เอง</td>
              </tr>
              <tr>
                <td colSpan={2}>6. การสวมใส่เสื้อผ้า</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"6n"}
                    value={dataForm.data_6n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ต้องมีคนสวมใส่ให้ ช่วยตัวเองแทบไม่ได้หรือได้น้อย</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"6n"}
                    value={dataForm.data_6n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ช่วยตัวเองได้ประมาณร้อยละ 50 ที่เหลือต้องมีคนช่วย</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"6n"}
                    value={dataForm.data_6n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>
                  ช่วยตัวเองได้ดี (รวมทั้งการติดกระดุม รูดซิป
                  ใส่เสื้อผ้าที่ดัดแปลงให้เหมาะสมก็ได้)
                </td>
              </tr>
              <tr>
                <td colSpan={2}>7. การขึ้นลงบันได 1 ชั้น</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"7n"}
                    value={dataForm.data_7n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ไม่สามารถทำได้</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"7n"}
                    value={dataForm.data_7n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ต้องการคนช่วย</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"7n"}
                    value={dataForm.data_7n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>
                  ขึ้นลงได้เอง (ถ้าต้องใช้เครื่องช่วยเดิน เช่น Walker
                  จะต้องเอาขึ้นลงได้ด้วย)
                </td>
              </tr>
              <tr>
                <td colSpan={2}>8. การอาบน้ำ</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"8n"}
                    value={dataForm.data_8n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>ต้องมีคนช่วยทำให้</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"8n"}
                    value={dataForm.data_8n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>อาบน้ำได้เอง</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  9. การกลั้นการถ่ายอุจจาระใน 1 สัปดาห์ที่ผ่านมา
                </td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"9n"}
                    value={dataForm.data_9n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>กลั้นไม่ได้ หรือต้องการการสวนอุจจาระอยู่เสมอ</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"9n"}
                    value={dataForm.data_9n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>กลั้นไม่ได้บางครั้ง (ไม่เกิน 1 ครั้งต่อสัปดาห์)</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"9n"}
                    value={dataForm.data_9n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>กลั้นได้ปกติ</td>
              </tr>
              <tr>
                <td colSpan={2}>10. การกลั้นปัสสาวะในระยะ 1 สัปดาห์ที่ผ่านมา</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"10n"}
                    value={dataForm.data_10n}
                    fieldValue={"0"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>กลั้นไม่ได้ หรือใส่สายสวนปัสสาวะ แต่ไม่สามารถดูแลเองได้</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"10n"}
                    value={dataForm.data_10n}
                    fieldValue={"5"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>กลั้นไม่ได้บางครั้ง (ไม่เกิน 1 ครั้งต่อสัปดาห์)</td>
              </tr>
              <tr>
                <td>
                  <FormElementRadio
                    fieldId={"10n"}
                    value={dataForm.data_10n}
                    fieldValue={"10"}
                    label=""
                    onChange={handleChange}
                  />
                </td>
                <td>กลั้นได้ปกติ</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel
            color={"red"}
            label=" คะแนนรวม : "
            fontWeight="bold"
          />
          <FormElementLabel color={"red"} label={getTotalScore} />
          <FormElementLabel color={"red"} label=" " fontWeight="bold" />
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={<FormADLPaper
            dataForm={dataForm}
            patient={patient}
            profile={profile}
          />}
        />
      </div>
    </>
  );
};

export default FormADL;
