import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const FormPosScalePaper = (props) => {
  const { dataForm, patient, profile } = props;

  const getScore1 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
      dataForm.data_6n,
      dataForm.data_7n,
      dataForm.data_8n,
      dataForm.data_9n,
      dataForm.data_10n,
    ]);
  }, [dataForm]);

  const getScore2 = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_13n,
      dataForm.data_14n,
      dataForm.data_15n,
      dataForm.data_16n,
      dataForm.data_17n,
      dataForm.data_18n,
      dataForm.data_19n,
      dataForm.data_20n,
      dataForm.data_21n,
      dataForm.data_22n,
    ]);
  }, [dataForm]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินผลลัพธ์การดูแลผู้ป่วยแบบประคับประคอง"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <FormElementTitle
          label="(Palliative care Outcome Scale) (ฉบับใช้ถามผู้ดูแลหลักของผู้ป่วย)"
          mode="view"
        />
        <FormElementTitle
          label="โปรดใส่ตัวเลข (0-4) ที่ตรงกับความรู้สึกของท่านมากที่สุด ในช่วง 3 วันที่ผ่านมา"
          mode="view"
        />
        <article>0 = ไม่มีอาการ/ ได้ข้อมูลหรือความช่วยเหลือเต็มที่</article>
        <article>1 = เล็กน้อย/ นาน ๆ ครั้ง</article>
        <article>2 = บางครั้ง</article>
        <article>3 = รุนแรง/ เป็นส่วนใหญ่</article>
        <article>
          4 =
          มากมายท่วมท้น/ไม่รู้สึกดีกับตัวเองเลย/เสียเวลามากกว่าครึ่งวัน/ปัญหาไม่ได้รับการช่วยเหลือ
        </article>
        <table
          style={{
            marginTop: "10px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                คะแนนผลลัพธ์การดูแลผู้ป่วยแบบประคับประคอง <br />
                (Palliative Care Outcome Scale )
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                แรกรับ{" "}
                <FormElementTextBox
                  value={dataForm.data_26}
                  type="date"
                  mode="view"
                  dateFormat={"dd/MM/yyyy"}
                />
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                วันจำหน่าย{" "}
                <FormElementTextBox
                  value={dataForm.data_27}
                  type="date"
                  mode="view"
                  dateFormat={"dd/MM/yyyy"}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                1. ผู้ป่วยมีอาการปวดหรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_1n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_13n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                2. ผู้ป่วยมีอาการอื่นๆที่มีผลกระทบต่อความรู้สึกของท่าน ได้แก่{" "}
                <FormElementLabel label={dataForm.data_25} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_2n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_14n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                3. ผู้ป่วยรู้สึกหงุดหงิดหรือกังวลใจเกี่ยวกับความเจ็บป่วย
                หรือการรักษาหรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_3n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_15n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                4.
                สมาชิกในครอบครัวของผู้ป่วยหรือเพื่อนของผู้ป่วยมีความวิตกกังวลเกี่ยวกับ
                ผู้ป่วยหรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_4n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_16n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                5. ผู้ป่วยและสมาชิกในครอบครัว
                หรือเพื่อนของท่านได้รับข้อมูลเกี่ยวกับการ
                ดูแลรักษามากน้อยเพียงใด
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_5n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_17n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                6.
                ผู้ป่วยได้เล่าระบายความรู้สึกให้กับสมาชิกในครอบครัวหรือเพื่อนหรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_6n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_18n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                7. ท่านคิดว่าผู้ป่วยเศร้าใจหรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_7n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_19n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                8. ท่านคิดว่าผู้ป่วยรู้สึกดีกับตัวเองหรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_8n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_20n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                9.
                ท่านรู้สึกว่าผู้ป่วยเสียเวลาในการนัดหมายเกี่ยวกับการดูแลสุขภาพของผู้ป่วย
                หรือไม่ เช่น รอคอยการเจาะเลือด
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_9n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_21n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                10.
                ผู้ป่วยได้รับความช่วยเหลือในการแก้ไขปัญหาที่เกิดจากความเจ็บป่วยของผู้ป่วย
                ทั้งด้านปัญหาการเงิน หรือปัญหาส่วนตัวหรือไม่
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_10n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_22n} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                คะแนนรวม
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={getScore1} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={getScore2} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ปัญหาสําคัญของผู้ป่วยคือ
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_11} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_23} mode="view" />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <article>ความสามารถในการปฏิบัติกิจกรรมของผู้ป่วย</article>
                <article>
                  0 = ปฏิบัติกิจกรรมได้ ตามปกติ ไม่มีอาการแสดงของโรค
                </article>
                <article>
                  1 = ปฏิบัติกิจกรรมได้ ตามปกติ มีอาการแสดงของโรคเล็กน้อย
                </article>
                <article>
                  2 = มีข้อจํากัดในการปฏิบัติกิจกรรม มีอาการของโรค
                </article>
                <article>
                  3 = ช่วยเหลือตนเองได้น้อยลง อยู่บนเตียงมากกว่าครึ่งวัน
                </article>
                <article>
                  4 = ช่วยเหลือตนเองได้น้อยมาก อยู่บนเตียงทั้งวัน
                </article>
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_12n} mode="view" />
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox value={dataForm.data_24n} mode="view" />
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementTitle
          label="หมายเหตุ กรณีผู้ป่วยเสียชีวิตก่อนประเมินครั้งถัดไป ไม่ต้องเมิน POS"
          mode="view"
        />
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormPosScalePaper;
