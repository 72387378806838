import React, { useState, useEffect, useRef, useCallback } from 'react';


const JsonList = (props) => {

    var test = ["1/test1", "2/abc", "3/zen"];

    return (
        <code>{JSON.stringify(test)}</code>
    )
}


export default JsonList;