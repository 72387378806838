import React, { useState, useEffect } from 'react';
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import DateTimePicker from '../../Components/DatePicker.js';
import AppointmentDetail from './AppointmentDetail';
import { format } from 'date-fns'

UIkit.use(Icons);

const ConsultAppointmentToday = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [showPopupDetail, setShowPopupDetail] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [appointmentId, setAppointmentId] = useState(null);
  const [dateQuery, setDateQuery] = useState(format(new Date(), 'yyyy-MM-dd'));

  async function listGet() {
    const profileTxt = localStorage.getItem('consultProfileData')
    const profileData = JSON.parse(profileTxt);

    var { list } = await Api.consult("AppointmentList", {
      appointmentDate: dateQuery,
      clinicCode: profileData.clinicCode,
      typeIn : "'2'"
    });

    if(list != null) {
      setDataList(list);

    } else {
      setDataList([]);

    }

    setLoadingApi(false);
  }

  function addAppointment() {
    window.location.href = 'consult/appointmentAdd';
  }

  function changeDate(data) {
    setDateQuery(data);
  }

  async function doSendLinkAppointment(id) {
    var dataSearch = dataList.filter(data=> data.id == id )

    var { result } = await Api.consult("AppointmentSendLinkVideoCall", {
      "data" : {
        "sendType" : dataSearch[0].type,
        "hn" : dataSearch[0].hn,
        "appointmentId" : dataSearch[0].id,
      }
    });

    if(result) {
      props.setAlertMessage("ส่งลิงค์เสร็จสิ้น");
      props.setShowAlert(true);
    }
  }

  async function openAppointmentDetail(id) {
    setAppointmentId(id);

    setShowPopupDetail(true);
  }

  function closeAppointmentDetail() {
    setShowPopupDetail(false);
    listGet();
  }

  async function doSaveAppointmentDetail(data) {
    
  }

  useEffect(() => {
    listGet();
  }, []);

  return (
     <div className="">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">ใบนัดหมาย</div>
        <div uk-grid="" className="uk-flex-middle">
        <div className="uk-width-auto@m uk-width-1-1">
              <DateTimePicker id="dateStart" format="YYYY-MM-DD" callback={changeDate} />
          </div>
          
          <div className="uk-width-1-5@m uk-width-1-1">
            <button className="uk-button uk-button-primary" onClick={listGet}>ค้นหา</button>&nbsp;
          </div>
          
        </div>

        <hr />
        <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>วีดิโอคอล</th>
                <th>ส่งลิงค์</th>
                <th>ประเภทการนัดหมาย</th>
                <th>สถานะ</th>
                <th>การจัดการ</th>
              </tr>
            </thead>
            <tbody>
              {
                loadingApi == true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span className='uk-spinner'></span></div>
                  </td>
                </tr>
                :
                dataList.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :
                dataList.map((data, key)=>{
                  return <tr key={`row${data.id}`}>
                    <td>{key+1}</td>
                    <td>
                      { moment(data.appointment_date).format('DD/MM/YYYY') }
                    </td>
                    <td>
                      { data.start_time + " - " + data.end_time }
                    </td>
                    <td>
                      <a href={process.env.REACT_APP_WEB_URL + "/consult/member?memberID=" + data.memberid + "appointmentId=" +  data.id} target='_blank'>{data.name}</a>
                    </td>
                    <td>
                      <a href={process.env.REACT_APP_WEB_URL + "/consult/appointmentCall?mode=iviewer&appointmentId=" + data.id + "&type=" + data.type + "&actor=phamacy&openExternalBrowser=1"} target='_blank'>Video Call</a>
                    </td>
                    <td>
                    <button className="uk-button uk-button-primary" onClick={()=>doSendLinkAppointment(data.id)} >ส่งลิงค์</button>
                    </td>
                    <td>{data.appointment_type_name}</td>
                    <td>{data.status_name}</td>
                    <td><button className="uk-button uk-button-primary" onClick={()=>openAppointmentDetail(data.id)}>แก้ไข</button></td>
                  </tr>
                })
              }
            </tbody>
          </table>
          </div>
      </div>
      <AppointmentDetail id={appointmentId} show={showPopupDetail} onClose={closeAppointmentDetail} doSave={doSaveAppointmentDetail} />
    </div>
  )
}

export default ConsultAppointmentToday;
