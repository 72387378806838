import React, { useState, useEffect, useMemo, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import Form2QPaper from "../FormPaper/Form2QPaper.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const Form2Q = (props) => {
  const formConstants = {
    formName: "form_2q",
    formType: "32",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      hideLoading();
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all([patientData, patientRoomRequest]).then((values) => {
      const patientResponse = values[0];
      const patientRoomResponse = values[1];

      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);

  useEffect(() => {
    if (!isSubForm) {
      if (!formId && hn && txn) fetchData(null, hn, txn)
    }
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([dataForm.data_1, dataForm.data_2]);
  }, [dataForm]);

  const getScoreDesc = useMemo(() => {
    if (dataForm.data_1 && dataForm.data_2) {
      if (getTotalScore > 0) {
        return "มีแนวโน้มที่จะเป็นโรคซึมเศร้า";
      } else {
        return "ปกติ ไม่เป็นโรคซึมเศร้า";
      }
    }
  }, [getTotalScore, dataForm.data_1, dataForm.data_2]);

  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html, getTotalScore);
  }, [dataForm, onAdd, getTotalScore]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="แบบคัดกรองภาวะซึมเศร้า 2 คำถาม (2Q)" />
        </div>

        <div className="uk-width-1-1@m uk-width-1-1">
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
            <thead>
              <tr>
                <th>คำถาม</th>
                <th>มี</th>
                <th>ไม่มี</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  1. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ ท่านรู้สึก หดหู่ เศร้า
                  หรือท้อแท้สิ้นหวัง หรือไม่
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=""
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"1"}
                    value={dataForm.data_1}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  2. ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ท่านรู้สึก เบื่อ
                  ทำอะไรก็ไม่เพลิดเพลิน หรือไม่
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"2"}
                    value={dataForm.data_2}
                    fieldValue={"1"}
                    onChange={handleChange}
                    label=""
                  />
                </td>
                <td>
                  <FormElementRadio
                    fieldId={"2"}
                    value={dataForm.data_2}
                    fieldValue={"0"}
                    onChange={handleChange}
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <td className="uk-text-right">การแปลผล</td>
                <td colSpan={2}>{getScoreDesc}</td>
              </tr>
            </tbody>
          </table>

          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementTitle label="การแปลผล" />
          </div>

          <div className="uk-width-1-1@m uk-width-1-1">
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementLabel label="ถ้าคำตอบ ไม่มี ทั้ง 2 คำถาม ถือ ว่า ปกติ ไม่เป็นโรคซึมเศร้า" />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
              <FormElementLabel label="ถ้าคำตอบ มี ข้อใดข้อหนึ่งหรือทั้ง 2 ข้อ (มีอาการใดๆ ในคำถามที่ 1 และ 2 ) หมายถึง “เป็นผู้มีความเสี่ยง” หรือ “มีแนวโน้มที่จะเป็นโรคซึมเศร้า” ให้ประเมินต่อด้วยแบบประเมิน โรคซึมเศร้า 9Q 2 คำถาม (2Q)" />
            </div>
          </div>
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <Form2QPaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
            />}
        />
      </div>
    </>
  );
};

export default Form2Q;
