import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import ToolTipsPopUp from "../Utils/ToolTipsPopUp";
import SearchTeleSection from "../Utils/SearchTeleSection";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilHandleValueChange = require("./FormUtil.js").handleValueChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const CONSTANTS = {
  DATA_NAME: "data_form_list",
  QUESTIONS: [
    " 1. ผู้ป่วยทราบว่ายา NOACs เป็นยาต้านการแข็งตัวของเลือด",
    " 2. ผู้ป่วยทราบข้อบ่งใช้ของยา NOACs และทราบความแตกต่างระหว่างยา NOACs และยาวาร์ฟาริน (กรณีเปลี่ยนยาวาร์ฟารินเป็นยา NOACs)",
    " 3. ผู้ป่วยทราบขนาดยาที่ได้รับวันนี้ และ ทราบวิธีปฏิบัติตัวเมื่อลืมรับประทานยา",
    " 4. ผู้ป่วยทราบว่า ไม่ควรปรับเพิ่มหรือลดขนาดยาเอง และทราบเหตุผลที่ต้องรับประทานยาตรงเวลาอย่างเคร่งครัด",
    " 5. ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะเลือดออกที่ผิดปกติ และทราบวิธีปฏิบัติตัวหากเกิดภาวะดังกล่าว",
    " 6. ผู้ป่วยทราบวิธีการสังเกตตนเองของการเกิดภาวะลิ่มเลือดอุดตัน",
    " 7. ผู้ป่วยทราบว่าต้องแจ้งบุคลากรทางการแพทย์ทุกครั้งที่เข้ารับบริการว่าตนรับประทานยา NOACs อยู่ โดยเฉพาะการทำทันตกรรม การวางแผนตั้งครรภ์ และไม่ควรฉีดยาเข้ากล้ามเนื้อ",
    " 8. ผู้ป่วยทราบว่าต้องปรึกษาแพทย์หรือเภสัชกรทุกครั้งก่อนซื้อยา อาหารเสริม หรือสมุนไพรมารับประทานเอง และทราบว่าควรหลีกเลี่ยงการดื่มแอลกอฮอล์ งดสูบบุหรี่",
    " 9. ผู้ป่วยทราบว่าควรหลีกเลี่ยงกิจกรรมที่เสี่ยงต่อกระแทกหรือการบาดเจ็บ",
    " 10. ผู้ป่วยทราบว่าควรเก็บยาให้พ้นแสง และความชื้น",
  ],
  ADDITIONAL_LABELS: [
    "คะแนนรวม",
    "ผู้รับการประเมิน (ผู้ป่วย/ญาติ)",
    "เภสัชกรผู้ประเมิน",
  ],
};

const FormNoac = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [isShowToolTipsPopup, setIsShowToolTipsPopup] = useState(false);
  var [toolTipId, setToolTipId] = useState(null);
  var [lastIndex, setLastIndex] = useState(0);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [dataFormOlds, setDataFormOlds] = useState([]);
  var [nxOpdDropDownList, setNxOpdDropDownList] = useState([]);

  var [searchStartDate, setSearchStartDate] = useState(null);
  var [searchEndDate, setSearchEndDate] = useState(null);
  var [searchSeqNo, setSearchSeqNo] = useState("");
  var [isOnSearch, setIsOnSearch] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_noac",
      formType: "23",
    });
    getNxOPDList();
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  async function getNxOPDList() {
    try {
      var result = await Api.consult("GetNxOPDList", {});

      if (result.list.data) {
        const data = result.list.data;
        const dataArray = Object.keys(data);
        const list = dataArray.map((item) => ({
          value: item,
          label: data[item],
        }));

        setNxOpdDropDownList(list);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (props.dataFormOlds && props.dataFormOlds.length > 2) {
      const data = isOnSearch
        ? props.dataFormOlds
        : props.dataFormOlds.slice(-2);
      data.reverse();
      setDataFormOlds(data);
    } else if (props.dataFormOlds && props.dataFormOlds.length > 0) {
      const data = props.dataFormOlds;
      data.reverse();
      setDataFormOlds(data);
    } else {
      setDataFormOlds([]);
    }
  }, [props.dataFormOlds, isOnSearch]);

  useEffect(() => {
    setData("27", dataForm.patient_name);
  }, [dataForm.patient_name]);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const openToolTipsPopUp = useCallback((id) => {
    setIsShowToolTipsPopup(true);
    setToolTipId(id);
  });

  const closeToolTipsPopUp = useCallback(() => {
    setIsShowToolTipsPopup(false);
    setToolTipId(null);
  });

  const getToolTipsContentBody = useMemo(() => {
    switch (toolTipId) {
      case 1:
        return <TooltipContent1 />;
      case 2:
        return <TooltipContent2 />;
      case 3:
        return <TooltipContent3 />;
      case 4:
        return <TooltipContent4 />;
      case 5:
        return <TooltipContent5 />;
      case 6:
        return <TooltipContent6 />;
      case 7:
        return <TooltipContent7 />;
      case 8:
        return <TooltipContent8 />;
      case 9:
        return <TooltipContent9 />;
      case 10:
        return <TooltipContent10 />;
      default:
        return null;
    }
  }, [isShowToolTipsPopup, toolTipId]);

  const renderToolTipsContent = useMemo(() => {
    return (
      <>
        <table
          className="uk-table uk-table-small uk-table-divider uk-table-striped"
          style={{ width: "400px" }}
        >
          <thead>
            <tr>
              <th className="center">แนวทางคำตอบ</th>
            </tr>
          </thead>
          <tbody>{getToolTipsContentBody}</tbody>
        </table>
      </>
    );
  }, [isShowToolTipsPopup, toolTipId]);

  const renderAssessmentFormSubHeader1 = useMemo(() => {
    return (
      <React.Fragment>
        <th key={1} colSpan={3}>
          ประเมินครั้งที่ {dataForm.data_no}
        </th>
        {dataFormOlds.map((item, i) => (
          <th key={i} colSpan={3}>
            ประเมินครั้งที่ {item.data_no}
          </th>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader2 = useMemo(() => {
    return (
      <React.Fragment key={1}>
        <th>
          <FormElementTextBox
            fieldId={"23"}
            value={dataForm.data_23}
            type="date"
            onChange={handleChange}
            width="100"
          />
        </th>
        <th>ติดตามครั้งถัดไป</th>
        <th rowSpan={2}>หมายเหตุ</th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment key={i}>
            <th>
              <FormElementTextBox
                value={item.data_23}
                type="date"
                width="100"
                disabled
              />
            </th>
            <th>ติดตามครั้งถัดไป</th>
            <th rowSpan={2}>หมายเหตุ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader3 = useMemo(() => {
    return (
      <React.Fragment key={1}>
        <th>ทราบ</th>
        <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment key={i}>
            <th>ทราบ</th>
            <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormHeader = useMemo(() => {
    return (
      <React.Fragment key={"header"}>
        <tr>
          <th rowSpan={3}>
            หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
          </th>
          {renderAssessmentFormSubHeader1}
        </tr>
        <tr>{renderAssessmentFormSubHeader2}</tr>
        <tr>{renderAssessmentFormSubHeader3}</tr>
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentQuestionForm = useMemo(() => {
    return CONSTANTS.QUESTIONS.map((questionLabel, questionIndex) => {
      questionIndex = ++questionIndex; // for supporting fleid data start with data_1.
      return (
        <tr key={`questions_content_${questionIndex}`}>
          <td className="td-form-label">
            <div className="question-label-container">
              <div className="question-label">{questionLabel}</div>
              <button
                className="tooltip-button"
                onClick={() => openToolTipsPopUp(questionIndex)}
              >
                <span
                  className="info-icon"
                  uk-icon="icon: info; ratio: 1.4"
                ></span>
              </button>
            </div>
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"1"}
              onChange={handleChange}
            />
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"0"}
              onChange={handleChange}
            />
          </td>
          <td className="td-remark">
            <FormElementTextArea
              fieldId={`${CONSTANTS.QUESTIONS.length + questionIndex}`}
              value={
                dataForm[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
              }
              onChange={handleChange}
              width="100"
              rows={4}
              cols={50}
              noLimit
            />
          </td>
          {dataFormOlds.map((item, i) => (
            <React.Fragment key={i}>
              <td>{item[`data_${questionIndex}n`] == "1" && "ทราบ"}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item[`data_${questionIndex}n`] != "1" && "ไม่ทราบ"}
              </td>
              <td className="td-remark">
                <FormElementTextArea
                  value={
                    item[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
                  }
                  width="100"
                  rows={4}
                  cols={50}
                  disabled
                />
              </td>
            </React.Fragment>
          ))}
        </tr>
      );
    });
  }, [dataFormOlds, dataForm]);

  const getTotalScore = useCallback(
    (data) => {
      let rawScore = utilSumTotalScore([
        data.data_1n,
        data.data_2n,
        data.data_3n,
        data.data_4n,
        data.data_5n,
        data.data_6n,
        data.data_7n,
        data.data_8n,
        data.data_9n,
        data.data_10n,
      ]);

      return (rawScore * 100) / CONSTANTS.QUESTIONS.length;
    },
    [dataForm]
  );

  const getScoreDesc = useCallback(
    (data) => {
      if (!getTotalScore(data)) {
        return "";
      } else if (getTotalScore(data) >= 80) {
        return "ผ่านเกณฑ์";
      } else {
        return "ไม่ผ่านเกณฑ์";
      }
    },
    [getTotalScore]
  );

  const getFieldByAdditionalLabel = useCallback(
    (data, disabled, labelIndex) => {
      if (labelIndex === 0) {
        return (
          <td colSpan={3}>
            ({getTotalScore(data)}%) {getScoreDesc(data)}
          </td>
        );
      } else if (labelIndex === 1) {
        return (
          <td colSpan={3}>
            <FormElementTextBox
              fieldId={"27"}
              placeholder="ชื่อผู้รับการประเมิน"
              onChange={handleChange}
              width="100"
              value={data.data_27}
              disabled={disabled}
            />
            <FormElementDropdown
              fieldId={"21"}
              value={data.data_21}
              dataList={[
                { label: "เลือกผู้รับการประเมิน", value: "0" },
                { label: "ผู้ป่วย", value: "1" },
                { label: "ญาติ", value: "2" },
              ]}
              onChange={handleChange}
              disabled={disabled}
            />
          </td>
        );
      } else if (labelIndex === 2) {
        return <td colSpan={3}>{data.data_22}</td>;
      } else {
        return "";
      }
    },
    [dataForm]
  );

  const renderAssessmentAdditionalForm = useMemo(() => {
    return CONSTANTS.ADDITIONAL_LABELS.map((labelItem, labelIndex) => (
      <tr key={`additional_content_${labelIndex}`}>
        <td className="uk-text-right">{labelItem}</td>
        {getFieldByAdditionalLabel(dataForm, false, labelIndex)}
        {dataFormOlds.map((item) =>
          getFieldByAdditionalLabel(item, true, labelIndex)
        )}
      </tr>
    ));
  }, [dataForm, dataFormOlds]);

  const onSearch = useCallback((search) => {
    setIsOnSearch(true);
    props.onSearch(search);
  });

  const onClear = useCallback(() => {
    setIsOnSearch(false);
    props.onClear();
  });

  return (
    <div
      uk-grid=""
      className="uk-grid uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "hidden" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" 3.2.2 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยาNOACs " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"28"}
          onChange={handleChange}
          width="100"
          label="วิธีการให้คำแนะนำยา"
          cols={120}
          rows={5}
          value={dataForm.data_28}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ช่วงเวลาการประเมิน" />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <SearchTeleSection onSearch={onSearch} onClear={onClear} />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div className="form-table-scroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>{renderAssessmentFormHeader}</thead>
              <tbody>
                {renderAssessmentQuestionForm}
                {renderAssessmentAdditionalForm}
              </tbody>
            </table>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel
            label=" คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥8คะแนน) จึงถือว่า “ผ่านเกณฑ์”  
หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ "
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label=" การนัดหมายครั้งถัดไป" />
          <FormElementDropdown
            fieldId={"24"}
            label="ห้องตรวจ"
            dataList={[{ value: "", label: "เลือกห้องตรวจ" }].concat(
              nxOpdDropDownList
            )}
            onChange={handleChange}
            width="100"
            value={dataForm.data_24}
          />
          <FormElementTextBox
            fieldId={"25"}
            type="date"
            onChange={handleChange}
            width="100"
            value={dataForm.data_25}
            minDate={dataForm.data_23}
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom uk-padding-remove-left">
          <FormElementTextArea
            fieldId={"26"}
            onChange={handleChange}
            width="100"
            label="ข้อมูลเพิ่มเติม"
            cols={120}
            rows={5}
            value={dataForm.data_26}
            noLimit
          />
        </div>
      </div>
      <ToolTipsPopUp show={isShowToolTipsPopup} onClose={closeToolTipsPopUp}>
        {renderToolTipsContent}
      </ToolTipsPopUp>
    </div>
  );
};

export default FormNoac;

const TooltipContent1 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              1. สามารถ<b>ระบุข้อใดข้อหนึ่ง</b> ได้แก่
              <br />
              - ยาต้านการแข็งตัวของเลือด
              <br />
              - ยาละลายลิ่มเลือด
              <br />
              - ยากันเลือดแข็ง หรือ คำตอบที่มีความหมายคล้ายกัน
              <br />
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent2 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              2. สามารถระบุโรคของตนเองที่จำเป็นต้องได้รับยา NOAC สามารถ
              <b>ระบุชื่อยา NOACs ที่ได้รับ</b>
            </span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              กรณีผู้ป่วยเปลี่ยนจาก warfarin เป็น NOACs หากติตตามครั้งแรก
              <b>ต้องสามารถระบุชื่อยา NOAC ที่ได้รับแทน warfarin</b>
              และระบุได้ว่าเป็นยาต้านการแข็งตัวของเลือดที่ไม่ต้องเจาะติตตามระดับยา
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent3 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              3. สามารถอธิบาย<b>วิธีปฏิบัติตัวกรณีลืมทานยา</b>
            </span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              1. ยาที่รับประทานวันละ 1 ครั้ง <b>ต้องอธิบายได้ทั้ง 2 กรณี</b>
            </span>
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ paddingLeft: "14px" }}
          >
            <span className="tooltip-content-label">
              1.1 หากลืมทานยา<b>ไม่เกิน 12 ชม.</b> ให้รีบทานทันทีที่นึกขึ้นได้
              ในขนาดยาเท่าเดิม
            </span>
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ paddingLeft: "14px" }}
          >
            <span className="tooltip-content-label">
              1.2 หากลืมทานยา<b>เกิน 12 ชม.</b>{" "}
              ข้ามไปทานมื้อต่อไปในขนาดเดิมเวลาเดิม โดยไม่ต้องเพิ่มขนาดยาเป็น 2
              เท่า
            </span>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              2. ยาที่รับประทานวันละ 2 ครั้ง <b>ต้องอธิบายได้ทั้ง 2 กรณี</b>
            </span>
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ paddingLeft: "14px" }}
          >
            <span className="tooltip-content-label">
              2.1 หากลืมทานยา<b>ไม่เกิน 6 ชม.</b> ให้รีบทานทันทีที่นึกขึ้นได้
              ในขนาดยาเท่าเดิม
            </span>
          </div>
          <div
            className="uk-width-1-1@m uk-width-1-1"
            style={{ paddingLeft: "14px" }}
          >
            <span className="tooltip-content-label">
              2.2 หากลืมทานยา<b>เกิน 6 ชม.</b>{" "}
              ข้ามไปทานมื้อต่อไปในขนาดเดิมเวลาเดิม โดยไม่ต้องเพิ่มขนาดยาเป็น 2
              เท่า
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent4 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              4. สามารถระบุว่า{" "}
              <b>
                ห้ามเพิ่มหรือลดขนาดยาเองโดยเด็ดขาด
                และทราบเหตุผลที่ต้องรับประทานยาตรงเวลาอย่างเคร่งครัด
              </b>{" "}
              เนื่องจากยามี half-life สั้น{" "}
              <b>
                เพื่อลดความเสี่ยงการเกิดลิ่มเลือดอุดตัน อาการเลือดออกผิดปรกติ
              </b>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent5 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              5. สามารถ<b>ยกตัวอย่างอาการแสดงภาวะเลือดออกผิดปกติ</b> เช่น
              เลือดออกตามไรฟัน รอยจ้ำเขียวตามตัว เลือดกำเดาไหล เลือดออกในตาขาว
              อุจจาระหรือปัสสาวะเป็นเลือด อุจจาระเป็นสีดำ ไอเป็นเลือด
              อาเจียนเป็นเลือด หรือ ประจำเดือนออกมากผิดปกติ เป็นต้น
              <br />
              <b>หากมีอาการดังกล่าว ให้ติดต่อพบแพทย์ก่อนนัด</b>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent6 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              6. สามารถ<b>ยกตัวอย่างอาการภาวะลิ่มเลือดอุดตัน</b> เช่น
              แขนขาอ่อนแรงครึ่งซีก หน้าเบี้ยว ปากเบี้ยว พูดไม่ออก พูดไม่ชัด
              <br />
              <b>
                หากมีอาการดังกล่าว
                ให้ติดต่อพบแพทย์ก่อนนัดหรือติดต่อพบแพทย์ใกล้บ้านทันที
              </b>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent7 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              7. สามารถระบุ{" "}
              <b>
                ผู้ป่วยต้องแจ้งให้บุคลากรทางการแพทย์ทราบว่าใช้ยา NOAC
                โดยเฉพาะเมื่อพบทันตแพทย์หรือแพทย์อื่น
              </b>
              ที่ให้การรักษาโรคร่วม (ทั้งในโรงพยาบาล, ร้านขายยา, คลินิก)
              และทราบว่าให้<b>หลีกเลี่ยงการฉีดยาเข้ากล้ามเนื้อ</b>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent8 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              8. สามารถระบุ{" "}
              <b>
                ต้องปรึกษาเภสัชกรหรือแพทย์ทุกครั้งก่อนเริ่มใช้ยาอื่น
                อาหารเสริมหรือสมุนไพรทุกชนิด หลีกเลี่ยงการดื่มแอลกอฮอล์
                และงดสูบบุหรี่
              </b>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent9 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              9. สามารถระบุ
              <b>
                ความสำคัญการเลี่ยงกิจกรรมเสี่ยงต่อการกระแทกหรือการบาดเจ็บ
                เนื่องจากอาจทำให้เลือดออกผิดปรกติ
              </b>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent10 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              10. สามารถ<b>ระบุวิธีเก็บยาที่เหมาะสม</b> ได้แก่
              <b>เก็บแผงยาให้พ้นแสง ไม่แกะยาไว้ล่วงหน้า</b>
              สามารถระบุ<b>วิธีเก็บยาที่เหมาะสมให้พ้นความชื้น</b>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};
