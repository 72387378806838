import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from "../../Components/Api";
import Formular from "../Formular";
import moment from "moment";
import numeral from "numeral";
import $ from "jquery";
import FullScreenElement from "../FullScreenElement";
import ReactEcharts from "echarts-for-react";
import DataTypeSelect from "../DataTypeSelect";

UIkit.use(Icons);

export default class SmartHealthTrendingGraph extends Component {
  state = {
    loadingApi: false,
    selectRisk: "green",
    selectDateType: "Month",
    selectDataType: "bmi,waistline",
    selectDateStart: moment()
      .startOf("month")
      .subtract(3, "month")
      .format("YYYY-MM-DD"),
    selectDateStop: moment().endOf("month").format("YYYY-MM-DD"),
    optionDepartment: [{ label: "ทั้งหมด", value: "" }],
    optionJob: [{ label: "ทั้งหมด", value: "" }],
    data: null,
    xGraph: [],
    dataGraph: [],
  };

  componentDidMount() {
    this.datePickerGet();
    this.dataGet();
  }

  dataRender = () => {
    var data = this.state.data;

    var _dataByName = {};

    var _dataGraphObject = {};

    var _symbol = [
      "circle",
      "rect",
      "roundRect",
      "triangle",
      "diamond",
      "pin",
      "arrow",
    ];

    var _risk = {
      green: "Normal",
      yellow: "Low risk",
      orange: "Risk",
      red: "High risk",
    };

    var _index = 0;
    
    /*Format data */
    for (let i1 in data) {
      /** Data name */

      if (data[i1]) {
        if (!_dataByName[i1]) {
          _dataByName[i1] = {};

          _dataGraphObject[i1] = {
            name: i1.toUpperCase(),
            type: "line",
            symbol: _symbol[_index],
            symbolSize: 12,
            showAllSymbol: true,
            label: {
              show: false,
            },
            data: [],
          };

          _index++;
        }

        data[i1].forEach((memberData) => {
          var _dateTypeValue = "";
          switch (this.state.selectDateType) {
            case "Date":
              _dateTypeValue = moment(memberData.dateadd).format("YYYY-MM-DD");
              break;
            case "Week":
              _dateTypeValue = moment(memberData.dateadd).format("YYYY(WW)");
              break;
            case "Month":
              _dateTypeValue = moment(memberData.dateadd).format("YYYY-MM");
              break;
            case "Year":
            default:
              _dateTypeValue = moment(memberData.dateadd).format("YYYY");
              break;
          }

          if (!_dataByName[i1][_dateTypeValue])
            _dataByName[i1][_dateTypeValue] = {};

          _dataByName[i1][_dateTypeValue].detail = Formular[i1](
            memberData.value,
            memberData.age,
            memberData.gender
          );

            
          _dataGraphObject[i1].label.formatter = function (params) {
            var _data = numeral(params.data).format('0,0.00');
            if(_dataByName[i1] !== undefined) {
              return `${_risk[_dataByName[i1][_dateTypeValue].detail.color]}\n${
                _data
              }`;
            } else {
              return `${_data}`;
            }
          };

          /*
          switch (i1) {
            case "sys/dias":
              _dataByName[i1][_dateTypeValue].value = memberData.value.split(
                "/"
              )[0];
              break;
            default:
              _dataByName[i1][_dateTypeValue].value = memberData.value;

              break;
          }*/

          _dataByName[i1][_dateTypeValue].value = memberData.value;
        });
      }
    }

    var _range = [];
    switch (this.state.selectDateType) {
      case "Date":
        var _start = moment(this.state.selectDateStart, 'YYYY-MM-DD');
        var _stop = moment(this.state.selectDateStop, 'YYYY-MM-DD');

        while (_stop > _start || _start.format('YYYY-MM-DD') === _stop.format('YYYY-MM-DD')) {
          _range.push(_start.format('YYYY-MM-DD'));
          _start.add(1,'days');
       }
        break;
      case "Week":
        var _start = moment(this.state.selectDateStart, 'YYYY-MM-DD');
        var _stop = moment(this.state.selectDateStop, 'YYYY-MM-DD');

        while (_stop > _start || _start.format('YYYY-WW') === _stop.format('YYYY-WW')) {
          _range.push(_start.format('YYYY(WW)'));
          _start.add(1,'weeks');
       }
        break;
      case "Month":
        var _start = moment(this.state.selectDateStart, 'YYYY-MM-DD');
        var _stop = moment(this.state.selectDateStop, 'YYYY-MM-DD');

        while (_stop > _start || _start.format('YYYY-M') === _stop.format('YYYY-M')) {
          _range.push(_start.format('YYYY-MM'));
          _start.add(1,'months');
       }

        break;
      case "Year":
      default:
        var _start = +moment(this.state.selectDateStart, 'YYYY-MM-DD').format('YYYY');
        var _stop = +moment(this.state.selectDateStop, 'YYYY-MM-DD').format('YYYY');
        for(var i=_start; i<=_stop; i++) {
          _range.push(i);
        }
        break;
    }

    var _dataGraph = [];

    for (let name in _dataGraphObject) {
      _range.forEach((year) => {
        if (_dataByName[name] && _dataByName[name][year]) {
          _dataGraphObject[name].data.push(_dataByName[name][year].value);
        } else {
          _dataGraphObject[name].data.push(null);
        }
      });

      if(name === 'sys/dias') { 

        ['sys', 'dias'].forEach(function(ds) {
          var _v = JSON.parse(JSON.stringify(_dataGraphObject[name]));
          _v.data = _v.data.map(function(d) {
            if(d) d = d.split("/")[ds === 'sys' ? 0 : 1];

            return d;
          });

          _v.name = ds;

          _dataGraph.push(_v);
        });
       
        



      } else {
        _dataGraph.push(_dataGraphObject[name]);
      }
     
    }

    setTimeout(() => {
      this.setState({
        loadingApi: false,
        dataGraph: _dataGraph,
        xGraph: _range
      });
    }, 200);
  };

  selectDateType = () => {
    var _type = ["Date", "Week", "Month", "Year"];

    return (
      <select
        className="uk-select uk-width-small@s uk-width-1-1"
        value={this.state.selectDateType}
        onChange={(e) => this.setState({ selectDateType: e.target.value }, ()=>this.dataRender())}
      >
        {_type.map((type) => {
          return <option value={type}>{type}</option>;
        })}
      </select>
    );
  };

  datePickerGet = () => {
    $("#datetimepicker_datestart")
      .on("dp.change", (e) => {
        this.setState({ selectDateStart: e.date.format("YYYY-MM-DD") }, ()=>this.dataGet());
      })
      .datetimepicker({
        format: "DD/MM/YYYY",
        defaultDate: this.state.selectDateStart,
      });

    $("#datetimepicker_datestop")
      .on("dp.change", (e) => {
        this.setState({ selectDateStop: e.date.format("YYYY-MM-DD") }, ()=>this.dataGet());
      })
      .datetimepicker({
        format: "DD/MM/YYYY",
        defaultDate: this.state.selectDateStop,
      });
  };

  dataGet = async () => {
    if(this.state.loadingApi === true) return false;

    this.setState({ loadingApi: true });

    var response = await Api.consult("UserSmartHealthTrendingGraph", {
      selectDataType: this.state.selectDataType,
      selectDateStart: this.state.selectDateStart,
      selectDateStop: this.state.selectDateStop,
      memberId: this.props.memberId || ''
    });

    var data = response.data



    this.setState({ data }, this.dataRender);
  };

  renderGraph = () => {
    var _dataName = [];
    this.state.dataGraph.forEach((data) => {
      _dataName.push(data.name);
    });

    var option = {
      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25,
      },

      // Add tooltip
      tooltip: {
        trigger: "axis",
      },

      // Add legend
      legend: {
        right: 10,
        data: _dataName,
      },

      // Add custom colors
      color: ["#F98E76", "#16D39A", "#2DCEE3", "#FF7588", "#626E82"],

      // Enable drag recalculate
      calculable: true,

      // Hirozontal axis
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: this.state.xGraph,
          z: 10,
          axisLine: {
            lineStyle: {
              width: 2,
              color: "#3975AF",
            },
          },
          axisLabel: {
            color: "#000",
          },
        },
      ],

      // Vertical axis
      yAxis: [
        {
          name: "VALUE",
          type: "value",
          minInterval: 1,
          axisLine: {
            lineStyle: {
              width: 2,
              color: "#3975AF",
            },
          },
          axisLabel: {
            color: "#000",
          },
        },
      ],

      // Add series
      // Add series
      series: this.state.dataGraph,
    };
    return (
      <ReactEcharts
        option={option}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: 400 }}
        theme={"theme_name"}
      />
    );
  };

  print = () => {
    window.print();
  }

  render() {
    return (
      <div
        className="uk-card uk-card-default uk-padding-small"
        id="d-smarth-health-trending-graph"
      >
        <div className="d-card-header">
          <div className="d-card-header-left">TRENDING RISK</div>
          <div className="d-card-header-right">
            <span className="icon-reload" onClick={this.dataGet}></span>
            {this.props.fullscreen !== false && <FullScreenElement element="#d-smarth-health-trending-graph" />}
          </div>
        </div>
        <div uk-grid="" className="uk-grid-small uk-margin-small-top">
          <div className="uk-width-1-1 uk-width-auto@m">
            <div className="uk-text-meta">กลุ่มโรค</div>
            <DataTypeSelect
              onlyCategory={true}
              value={this.state.selectDataType}
              callback={(value) => {
                this.setState({ selectDataType: value }, () => this.dataGet());
              }}
            />
          </div>
          <div className="uk-width-1-1 uk-width-auto@m">
            <div className="uk-text-meta">รูปแบบ</div>
            {this.selectDateType()}
          </div>
          <div className="uk-width-1-1 uk-width-auto@m">
            <div className="uk-text-meta">ตั้งแต่วันที่</div>
            <div
              className="d-button-group input-group uk-button-group date uk-width-1-1"
              id="datetimepicker_datestart"
            >
              <input
                type="text"
                className="uk-input"
                placeholder="วันที่/เวลา"
              />
              <span
                className="uk-button input-group-addon"
                style={{ width: 45, marginLeft: 0 }}
              >
                <span className="glyphicon glyphicon-calendar"></span>
              </span>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-auto@m">
            <div className="uk-text-meta">ถึงวันที่</div>
            <div
              className="d-button-group input-group uk-button-group date uk-width-1-1"
              id="datetimepicker_datestop"
            >
              <input
                type="text"
                className="uk-input"
                placeholder="วันที่/เวลา"
              />
              <span
                className="uk-button input-group-addon"
                style={{ width: 45, marginLeft: 0 }}
              >
                <span className="glyphicon glyphicon-calendar"></span>
              </span>
            </div>
          </div>
          
          <div className="uk-width-expand uk-text-right">
            <br />
            <button className="uk-button uk-button-primary" onClick={this.print}><span uk-icon="print"></span> พิมพ์</button>
          </div>
        </div>
        <div className="uk-margin-small-top">
          {this.state.loadingApi === true ? (
            <div className="uk-padding uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            this.renderGraph()
          )}
        </div>
      </div>
    );
  }
}
