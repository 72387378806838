import React, { useState, useEffect, useRef } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import PopupForm from "../PopupForm";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;

const FormPostFalling = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [hn, setHn] = useState(false);
  var [txn, setTxn] = useState(false);

  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  const [popupDataForm, setPopupDataForm] = useState({});

  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_postfalling",
      formType: "41",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  function doFES_I() {
    var dataFormItem = props.getDataFormItem(35);
    setPopupDataForm(dataFormItem);
    setPopupFormType(35);
    setShowFormPopup(true);
  }

  function doZarit_12() {
    var dataFormItem = props.getDataFormItem(36);
    setPopupDataForm(dataFormItem);
    setPopupFormType(36);
    setShowFormPopup(true);
  }

  function doADLS() {
    var dataFormItem = props.getDataFormItem(44);
    setPopupDataForm(dataFormItem);
    setPopupFormType(44);
    setShowFormPopup(true);
  }

  function doIADLS() {
    var dataFormItem = props.getDataFormItem(45);
    setPopupDataForm(dataFormItem);
    setPopupFormType(45);
    setShowFormPopup(true);
  }

  useEffect(() => {
    setHn(props.hn);
    setTxn(props.txn);

    if (props.txn != null) {
      props.loadDataFormItem(35, props.txn);
      props.loadDataFormItem(36, props.txn);
      props.loadDataFormItem(44, props.txn);
      props.loadDataFormItem(45, props.txn);
    }
  }, [props.hn, props.txn]);

  function onClosePopup(data) {
    setShowFormPopup(false);

    if (typeof data != "undefined") {
      props.addDataFormItem(data);
      if (popupFormType == 35) {
        setData("45n", data.score.toString());
      } else if (popupFormType == 36) {
        setData("48n", data.score.toString());
      } else if (popupFormType == 44) {
        setData("38n", data.score.toString());
      } else if (popupFormType == 45) {
        setData("40n", data.score.toString());
      }
    }
  }

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  return (
    <div
      uk-grid=""
      className="uk-grid uk-padding-small"
      // ref={parentTableContainer}
      // style={{ overflowX: "hidden" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Post-falling assessment " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="POST-FALL ASSESSMENT (FAMILY MEDICINE, CHIANG MAI UNIVERSITY)" />
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <div className="dietz-display-inline-container">
            <FormElementRadio
              fieldId={"1"}
              value={dataForm.data_1}
              fieldValue={"1"}
              onChange={handleChange}
              label=" On admission "
            />
            <FormElementRadio
              fieldId={"1"}
              value={dataForm.data_1}
              fieldValue={"2"}
              onChange={handleChange}
              label=" Home visit "
            />
            <FormElementRadio
              fieldId={"1"}
              value={dataForm.data_1}
              fieldValue={"3"}
              onChange={handleChange}
              label=" Virtual home visit "
            />
          </div>
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span className="formTextBoxLabel">เป็นการประเมิน</span>
          <div className="dietz-display-inline-container">
            <FormElementRadio
              fieldId={"2"}
              value={dataForm.data_2}
              fieldValue={"1"}
              onChange={handleChange}
              label=" ครั้งแรก "
            />
            <FormElementRadio
              fieldId={"2"}
              value={dataForm.data_2}
              fieldValue={"2"}
              onChange={handleChange}
              label=" ติดตาม "
            />
          </div>
          <FormElementTextBox
            fieldId={"3"}
            value={dataForm.data_3}
            label="ที่"
            onChange={handleChange}
            width="100"
          />
          <span className="formTextBoxLabel">เดือนหลังล้ม</span>
        </div>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <div
            className="form-table-container"
            // style={{ maxWidth: `${tableMaxWidth}px` }}
          >
            <div className="form-table-scroll">
              <table className="uk-table uk-table-small uk-table-divider form-table">
                <tbody>
                  <tr>
                    <th colSpan={3}>
                      <span className="formTextBoxLabel">
                        ส่วนนี้ประเมินก่อนออกจากโรงพยาบาลและติดตามผู้ป่วย
                      </span>
                      <FormElementTextBox
                        fieldId={"4"}
                        value={dataForm.data_4}
                        type="date"
                        label="วันที่"
                        onChange={handleChange}
                        width="100"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <span className="formTextBoxLabel"> Physical health</span>
                    </th>
                    <th colSpan={2}>
                      <span className="formTextBoxLabel">
                        Review underlying diseases (ดูจากใบหลัก)
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <FormElementTextBox
                        fieldId={"5"}
                        value={dataForm.data_5}
                        label="Pain score: average"
                        type="numeric"
                        maxLength={2}
                        onChange={handleChange}
                        width="100" 
                      />
                      <span className="formTextBoxLabel">/10</span>
                      <FormElementTextBox
                        fieldId={"6"}
                        value={dataForm.data_6}
                        label="ambulation"
                        type="numeric"
                        maxLength={2}
                        onChange={handleChange}
                        width="100"
                      />
                      <span className="formTextBoxLabel">/10</span>
                    </td>
                    <th colSpan={2}>
                      <FormElementTextBox
                        fieldId={"7"}
                        value={dataForm.data_7}
                        label="Medication (ดูใบหลัก) ทานยาทั้งหมด"
                        onChange={handleChange}
                        type="numeric"
                        width="100"
                      />
                      <span className="formTextBoxLabel">ชนิด</span>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          Complain ปัญหาการมองเห็น
                        </span>
                        <FormElementRadio
                          fieldId={"8"}
                          value={dataForm.data_8}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"8"}
                          value={dataForm.data_8}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <span
                          className={
                            dataForm.data_8 == "1" ? "form-show" : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"9"}
                            value={dataForm.data_9}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">ปัญหาการได้ยิน</span>
                        <FormElementRadio
                          fieldId={"10"}
                          value={dataForm.data_10}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"10"}
                          value={dataForm.data_10}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <span
                          className={
                            dataForm.data_10 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"11"}
                            value={dataForm.data_11}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          ปัญหาการนอนหลับ
                        </span>
                        <FormElementRadio
                          fieldId={"12"}
                          value={dataForm.data_12}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"12"}
                          value={dataForm.data_12}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <span
                          className={
                            dataForm.data_12 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"13"}
                            value={dataForm.data_13}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">น้ำหนัก</span>
                        <FormElementTextBox
                          fieldId={"14"}
                          value={dataForm.data_14}
                          label="ระบุ"
                          type="numeric"
                          maxLength={3}
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">kg</span>
                        <FormElementRadio
                          fieldId={"15"}
                          value={dataForm.data_15}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่เปลี่ยนแปลง "
                        />
                        <FormElementRadio
                          fieldId={"15"}
                          value={dataForm.data_15}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" เพิ่ม "
                        />
                        <FormElementRadio
                          fieldId={"15"}
                          value={dataForm.data_15}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label=" ลด "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          รู้สึกไม่มั่นคงในการเดิน
                        </span>
                        <FormElementRadio
                          fieldId={"16"}
                          value={dataForm.data_16}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"16"}
                          value={dataForm.data_16}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <span
                          className={
                            dataForm.data_16 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"17"}
                            value={dataForm.data_17}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          มียากลุ่มเหล่านี้หรือไม่
                        </span>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                          <FormElementCheckBox
                            fieldId={"18"}
                            value={dataForm.data_18}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementCheckBox
                            fieldId={"19"}
                            value={dataForm.data_19}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label=" Diuretics  "
                          />
                          <FormElementCheckBox
                            fieldId={"20"}
                            value={dataForm.data_20}
                            fieldValue={"3"}
                            onChange={handleChange}
                            label=" Anti-hypertensive  "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                          <FormElementCheckBox
                            fieldId={"21"}
                            value={dataForm.data_21}
                            fieldValue={"4"}
                            onChange={handleChange}
                            label=" Anti-depressants  "
                          />
                          <FormElementCheckBox
                            fieldId={"22"}
                            value={dataForm.data_22}
                            fieldValue={"5"}
                            onChange={handleChange}
                            label=" Sedative/hypnotics  "
                          />
                          <FormElementCheckBox
                            fieldId={"23"}
                            value={dataForm.data_23}
                            fieldValue={"6"}
                            onChange={handleChange}
                            label=" Anti-histamine  "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                          <FormElementCheckBox
                            fieldId={"24"}
                            value={dataForm.data_24}
                            fieldValue={"7"}
                            onChange={handleChange}
                            label=" Anti-cholinergics "
                          />
                          <FormElementCheckBox
                            fieldId={"25"}
                            value={dataForm.data_25}
                            fieldValue={"8"}
                            onChange={handleChange}
                            label=" Anti-psychotics  "
                          />
                          <FormElementCheckBox
                            fieldId={"26"}
                            value={dataForm.data_26}
                            fieldValue={"9"}
                            onChange={handleChange}
                            label=" Anti-convulsants "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small  uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            ประเมินการกินยา
                          </span>
                          <FormElementRadio
                            fieldId={"27"}
                            value={dataForm.data_27}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" good adherence "
                          />
                          <FormElementRadio
                            fieldId={"27"}
                            value={dataForm.data_27}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label=" poor compliance "
                          />
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small  uk-padding-remove-left uk-padding-remove-bottom">
                          <span className="formTextBoxLabel">
                            ปัญหาในการทานยา
                          </span>
                          <FormElementRadio
                            fieldId={"28"}
                            value={dataForm.data_28}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" ไม่มี "
                          />
                          <FormElementRadio
                            fieldId={"28"}
                            value={dataForm.data_28}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" มี "
                          />
                          <span
                            className={
                              dataForm.data_28 == "1"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"29"}
                              value={dataForm.data_29}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementLine />
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                        <span className="formTextBoxLabel">Gait aid</span>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                          <FormElementRadio
                            fieldId={"30"}
                            value={dataForm.data_30}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" none "
                          />
                          <FormElementRadio
                            fieldId={"30"}
                            value={dataForm.data_30}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label=" ไม้เท้า "
                          />
                          <span
                            className={
                              dataForm.data_30 == "2"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"31"}
                              value={dataForm.data_31}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                            <span className="formTextBoxLabel">ขา</span>
                          </span>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                          <FormElementRadio
                            fieldId={"30"}
                            value={dataForm.data_30}
                            fieldValue={"3"}
                            onChange={handleChange}
                            label=" walker "
                          />
                          <FormElementRadio
                            fieldId={"30"}
                            value={dataForm.data_30}
                            fieldValue={"4"}
                            onChange={handleChange}
                            label=" อื่นๆ "
                          />
                          <span
                            className={
                              dataForm.data_30 == "4"
                                ? "form-show"
                                : "form-hidden"
                            }
                          >
                            <FormElementTextBox
                              fieldId={"32"}
                              value={dataForm.data_32}
                              label=""
                              onChange={handleChange}
                              width="100"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">การล้มซ้ำ</span>
                        <FormElementRadio
                          fieldId={"33"}
                          value={dataForm.data_33}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"33"}
                          value={dataForm.data_33}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <FormElementTextBox
                          fieldId={"34"}
                          value={dataForm.data_34}
                          label="ครั้งล่าสุดนับจากล้มครั้งก่อน"
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">เดือน</span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">Complication</span>
                        <FormElementRadio
                          fieldId={"35"}
                          value={dataForm.data_35}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"35"}
                          value={dataForm.data_35}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <span
                          className={
                            dataForm.data_35 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"36"}
                            value={dataForm.data_36}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <FormElementLine />
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                        <span className="formTextBoxLabel">
                          ปัจจุบัน ดื่มสุรา
                        </span>
                        <FormElementRadio
                          fieldId={"37"}
                          value={dataForm.data_37}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่ดื่ม "
                        />
                        <FormElementRadio
                          fieldId={"37"}
                          value={dataForm.data_37}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ดื่ม "
                        />
                        <div
                          className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom "
                          style={{ display: "inline-flex", flexWrap: "wrap" }}
                        >
                          <FormElementTextBox
                            fieldId={"38n"}
                            value={dataForm.data_38n}
                            onChange={handleChange}
                            width="100"
                            size={5}
                          />
                          <FormElementButton label="ADLs" onClick={doADLS} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <FormElementLine />
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                        <span className="formTextBoxLabel">สูบบุหรี่</span>
                        <FormElementRadio
                          fieldId={"39"}
                          value={dataForm.data_39}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่สูบ "
                        />
                        <FormElementRadio
                          fieldId={"39"}
                          value={dataForm.data_39}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" สูบ "
                        />
                        <div
                          className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom "
                          style={{ display: "inline-flex", flexWrap: "wrap" }}
                        >
                          <FormElementTextBox
                            fieldId={"40n"}
                            value={dataForm.data_40n}
                            onChange={handleChange}
                            width="100"
                            size={5}
                          />
                          <FormElementButton label="IADLs" onClick={doIADLS} />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span className="formTextBoxLabel">Mental health</span>
                    </th>
                    <th colSpan={2}>
                      <span className="formTextBoxLabel">
                        Caregiver burden and family issue
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                        <span className="formTextBoxLabel">
                          Cognitive problem
                        </span>
                        <FormElementRadio
                          fieldId={"41"}
                          value={dataForm.data_41}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"41"}
                          value={dataForm.data_41}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <span
                          className={
                            dataForm.data_41 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"42"}
                            value={dataForm.data_42}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">Depressed mood</span>
                        <FormElementRadio
                          fieldId={"43"}
                          value={dataForm.data_43}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"43"}
                          value={dataForm.data_43}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          Fear of falling กลัวล้มในอีก 1 ปีไหม
                        </span>
                        <FormElementRadio
                          fieldId={"44"}
                          value={dataForm.data_44}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่กลัว "
                        />
                        <FormElementRadio
                          fieldId={"44"}
                          value={dataForm.data_44}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" กลัว "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          คะแนนแบบประเมิน
                        </span>
                        <FormElementTextBox
                          fieldId={"45n"}
                          value={dataForm.data_45n}
                          label="Fear of falling"
                          onChange={handleChange}
                          width="100"
                          disabled
                        />
                        <FormElementButton
                          label="Fear of falling"
                          onClick={doFES_I}
                        />
                        <span className="formTextBoxLabel">
                          (ที่ 2 สัปดาห์ และ 2 เดือน)
                        </span>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                        <FormElementRadio
                          fieldId={"46"}
                          value={dataForm.data_46}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"46"}
                          value={dataForm.data_46}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี  "
                        />
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                          <FormElementTextArea
                            fieldId={"47"}
                            rows={4}
                            cols={70}
                            value={dataForm.data_47}
                            label="ระบุรายละเอียด "
                            onChange={handleChange}
                            width="100"
                            noLimit
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          คะแนนแบบประเมิน
                        </span>
                        <FormElementTextBox
                          fieldId={"48n"}
                          value={dataForm.data_48n}
                          label="Zarit-12 "
                          onChange={handleChange}
                          width="100"
                          disabled
                        />
                        <FormElementButton
                          label="Zarit-12"
                          onClick={doZarit_12}
                        />
                        <span className="formTextBoxLabel">
                          (ที่ 2 สัปดาห์ และ 2 เดือน)
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span className="formTextBoxLabel">
                        Environmental hazards (ขอภาพจากญาติ โดยเฉพาะจุดที่ล้ม)
                      </span>
                    </th>
                    <th colSpan={2}>
                      <span className="formTextBoxLabel">
                        ประเมินการดูแลตนเอง + Teach back
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                        <FormElementCheckBox
                          fieldId={"49"}
                          value={dataForm.data_49}
                          onChange={handleChange}
                          label=" แสงไฟไม่เพียงพอ "
                        />
                        <FormElementCheckBox
                          fieldId={"50"}
                          value={dataForm.data_50}
                          onChange={handleChange}
                          label=" พื้นลื่น  "
                        />
                        <FormElementCheckBox
                          fieldId={"51"}
                          value={dataForm.data_51}
                          onChange={handleChange}
                          label=" ของวางเกะกะ  "
                        />
                        <FormElementCheckBox
                          fieldId={"52"}
                          value={dataForm.data_52}
                          onChange={handleChange}
                          label=" พรมเช็ดเท้า "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <FormElementCheckBox
                          fieldId={"53"}
                          value={dataForm.data_53}
                          onChange={handleChange}
                          label=" ไม่มีราวจับห้องน้ำ  "
                        />
                        <FormElementCheckBox
                          fieldId={"54"}
                          value={dataForm.data_54}
                          onChange={handleChange}
                          label=" บันไดเสี่ยงต่อการหกล้ม "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <FormElementCheckBox
                          fieldId={"55"}
                          value={dataForm.data_55}
                          label="พื้นที่ภายนอกบ้านไม่เหมาะสม"
                          onChange={handleChange}
                        />
                        <span
                          className={
                            dataForm.data_55 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"57"}
                            value={dataForm.data_57}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <FormElementCheckBox
                          fieldId={"56"}
                          value={dataForm.data_56}
                          label="อื่นๆ"
                          onChange={handleChange}
                        />
                        <span
                          className={
                            dataForm.data_56 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextBox
                            fieldId={"58"}
                            value={dataForm.data_58}
                            label=""
                            onChange={handleChange}
                            width="100"
                          />
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          ได้ทำการปรับปรุงบ้านแล้ว
                        </span>
                        <FormElementRadio
                          fieldId={"59"}
                          value={dataForm.data_59}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่ใช่ "
                        />
                        <FormElementRadio
                          fieldId={"59"}
                          value={dataForm.data_59}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ใช่ "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span
                          className={
                            dataForm.data_59 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextArea
                            rows={5}
                            cols={70}
                            fieldId={"60"}
                            value={dataForm.data_60}
                            label="โดยวิธี"
                            onChange={handleChange}
                            width="100"
                            noLimit
                          />
                        </span>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                        <span className="formTextBoxLabel">
                          Clip VDO การดูแลตนเองในผู้ป่วย
                        </span>
                        <FormElementRadio
                          fieldId={"61"}
                          value={dataForm.data_61}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ได้ดู "
                        />
                        <FormElementRadio
                          fieldId={"61"}
                          value={dataForm.data_61}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ยังไม่ได้ดู "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          เข้าใจความสำคัญของการป้องกันความเสี่ยงในการล้ม
                        </span>
                        <FormElementRadio
                          fieldId={"62"}
                          value={dataForm.data_62}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ใช่ "
                        />
                        <FormElementRadio
                          fieldId={"62"}
                          value={dataForm.data_62}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่ใช่ "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          วางแผนแก้ไขปัญหาที่ทำให้เกิดความเสี่ยงล้ม
                        </span>
                        <FormElementRadio
                          fieldId={"63"}
                          value={dataForm.data_63}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ใช่ "
                        />
                        <FormElementRadio
                          fieldId={"63"}
                          value={dataForm.data_63}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่ใช่ "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">การฝึกกายภาพ</span>
                        <FormElementRadio
                          fieldId={"64"}
                          value={dataForm.data_64}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ได้ทำ "
                        />
                        <FormElementRadio
                          fieldId={"64"}
                          value={dataForm.data_64}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่ได้ทำ "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          เลือกการทานอาหารอย่างเหมาะสม
                        </span>
                        <FormElementRadio
                          fieldId={"65"}
                          value={dataForm.data_65}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" ตอบได้ "
                        />
                        <FormElementRadio
                          fieldId={"65"}
                          value={dataForm.data_65}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ตอบไม่ได้ "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          ความกังวลและความเครียด
                        </span>
                        <FormElementRadio
                          fieldId={"66"}
                          value={dataForm.data_66}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          fieldId={"66"}
                          value={dataForm.data_66}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span
                          className={
                            dataForm.data_66 == "1"
                              ? "form-show"
                              : "form-hidden"
                          }
                        >
                          <FormElementTextArea
                            rows={3}
                            cols={70}
                            fieldId={"67"}
                            value={dataForm.data_67}
                            label="ระบุ "
                            onChange={handleChange}
                            width="100"
                            noLimit
                          />
                        </span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          ความมั่นใจในการดูแลตนเองเมื่อกลับไปบ้าน
                        </span>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"0"}
                            onChange={handleChange}
                            label=" 0 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"1"}
                            onChange={handleChange}
                            label=" 1 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"2"}
                            onChange={handleChange}
                            label=" 2 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"3"}
                            onChange={handleChange}
                            label=" 3 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"4"}
                            onChange={handleChange}
                            label=" 4 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"5"}
                            onChange={handleChange}
                            label=" 5 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"6"}
                            onChange={handleChange}
                            label=" 6 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"7"}
                            onChange={handleChange}
                            label=" 7 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"8"}
                            onChange={handleChange}
                            label=" 8 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"9"}
                            onChange={handleChange}
                            label=" 9 "
                          />
                          <FormElementRadio
                            fieldId={"68n"}
                            value={dataForm.data_68n}
                            fieldValue={"10"}
                            onChange={handleChange}
                            label=" 10 "
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          สาเหตุที่ไม่มั่นใจ
                        </span>
                        <FormElementTextArea
                          rows={2}
                          cols={60}
                          fieldId={"69"}
                          value={dataForm.data_69}
                          label=" "
                          onChange={handleChange}
                          width="100"
                          noLimit
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <FormElementLine />
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom uk-padding-remove-top">
                        <span className="formTextBoxLabel">
                          หากเป็นการติดตาม
                          ประเด็นที่เป็นปัญหาเดิมได้รับการแก้ไขแล้วหรือไม่
                        </span>
                        <FormElementRadio
                          fieldId={"70"}
                          value={dataForm.data_70}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" แก้ไขเสร็จแล้ว "
                        />
                        <FormElementRadio
                          fieldId={"70"}
                          value={dataForm.data_70}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label=" แก้ไขบางส่วน "
                        />
                        <FormElementRadio
                          fieldId={"70"}
                          value={dataForm.data_70}
                          fieldValue={"3"}
                          onChange={handleChange}
                          label=" ยังไม่ได้แก้ไขเลย "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormElementLine />
                      <span className="formTextBoxLabel">
                        หมายเหตุ: การวางแผนการดูแลให้เขียนในใบหลัก
                      </span>
                    </td>
                    <td colSpan={2}>
                      <FormElementLine />
                      <FormElementTextBox
                        fieldId={"71"}
                        value={dataForm.data_71}
                        label="ชื่อผู้ประเมิน "
                        onChange={handleChange}
                        width="100"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PopupForm
        title={""}
        hn={hn}
        txn={txn}
        show={showFormPopup}
        dataForm={popupDataForm}
        formType={popupFormType}
        onClose={onClosePopup}
      ></PopupForm>
    </div>
  );
};

export default FormPostFalling;
