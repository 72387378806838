import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import UserProfileCardChangePassword from "../UserProfileCardChangePassword";
import UserProfileCardPersonalInformation from "../UserProfileCardPersonalInformation";
import UserHealthBook from './UserHealthBook';
import ImageUri from "../ImageUri";

UIkit.use(Icons);

export default class UserProfileCard2 extends Component {
  state = {
    profile: {},
    company: {},
    bmi: "-",
    group: [],
    selectLinkActive: "Profile Overview",
    editProfile: false,
  };

  componentDidMount() {
    this.profileGet();
  }

  profileGet = async () => {
    var { profile, bmi, company, group } = await Api.member("UserProfileCard");

    this.setState({
      bmi: bmi ? bmi : "-",
      profile: profile ? profile : {},
      company: company ? company : {},
      group: group || [],
    });
  };

  linkUserType = window.location.pathname;

  linkList = [
    {
      icon: "user",
      label: "Personal Information",
      before: "userProfileCardPersonalInformation",
      click: () => {
        this.setState({ selectLinkActive: "Personal Information" });
      },
    },
    {
      icon: "note",
      label: "Change Password",
      before: "userProfileCardChangePasswordRender",
      click: () => {
        this.setState({ selectLinkActive: "Change Password" });
      },
    },
  ];

  userProfileCardChangePasswordRender = () => {
    return (
      <UserProfileCardChangePassword type="m" id={this.state.profile.id} />
    );
  };

  userProfileCardPersonalInformation = () => {
    return (
      <UserProfileCardPersonalInformation
        type="m"
        profile={this.state.profile}
      />
    );
  };

  userProfileCardAccountInformation = () => {
    return (
      <div className="uk-padding-small">
        ประเภทผู้ใช้งาน :{" "}
        {+this.state.company.level === 1 ? "ผู้ดูแลระบบ" : "ผู้ใช้ทั่วไป"}
      </div>
    );
  };

  render() {
    return (
      <div className="uk-card uk-card-default">
        <div
          uk-grid=""
          className="uk-grid-small uk-padding-small uk-flex-middle"
        >
          <div className="uk-width-auto@s uk-width-1-1 uk-text-left@s uk-text-center">
            <div
              className="uk-background-cover uk-background-muted uk-display-inline-block d-user-profile-card-image"
              style={{
                backgroundImage:
                  this.state.profile.image 
                    ? `url(${ImageUri(this.state.profile.image)})`
                    : false,
              }}
            ></div>
          </div>
          <div className="uk-width-expand@s uk-width-1-1 uk-text-left@s uk-text-center">
            <div className="uk-padding-small">
              
              <div className="uk-margin-small-top">
                Name:{" "}
                <b>
                  {this.state.profile.prefixname} {this.state.profile.name}{" "}
                  {this.state.profile.lastname}
                </b>
              </div>
              
            </div>
          </div>
          <div className="uk-width-expand@s uk-width-1-1 uk-text-right">
            <div uk-grid="" className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-1">
              {/*}
              <div>
                <a
                  href="/chat"
                  target="_blank"
                  rel="noopener"
                  className="uk-button uk-button-small uk-button-danger uk-button-default uk-width-1-1"
                >
                  แชท/วีดีโอคอล/บันทึกสุขภาพ
                </a>
              </div>
              */}
              {
                /*
                <div>
                  <a
                    href={`https://smarthealth-line.dietz.asia/data?route=${encodeURIComponent(`/data?redirect=${encodeURIComponent(window.location.href)}`)}&token_member=${encodeURIComponent(localStorage.getItem('memberLogin'))}`}
                    target="_blank"
                    rel="noopenner"
                    className="uk-button uk-button-small uk-button-danger uk-width-1-1"
                  >
                    บันทึกสุขภาพ
                  </a>
              </div>
                */
              }
              {/*
              <div>
                <UserHealthBook data={this.props.data} />
              </div>*/
              }
              {/*
              <div>
                <button
                  className="uk-button uk-button-small uk-button-primary uk-width-1-1"
                  onClick={() => this.setState({ editProfile: true })}
                >
                  Edit Profile
                </button>
              </div>
              */
            }
              <div>
                <a
                  className="uk-button uk-button-small uk-button-primary uk-width-1-1"
                  href="/work-health-record"
                >
                  สมุดสุขภาพ
                </a>
              </div>
            </div>
          </div>
        </div>
        {this.state.editProfile === true ? (
              <div className="d-user-profile-card">
                {this.linkList.map((data, index) => (
                  <div key={`link${index}`}>
                    {data.before &&
                      this.state.selectLinkActive === data.label &&
                      this[data.before]()}
                    <a
                      className={
                        "d-user-profile-card-link " +
                        (this.state.selectLinkActive === data.label
                          ? "d-link-active"
                          : "")
                      }
                      onClick={() => {
                        if (data.click) {
                          data.click();
                        }
                      }}
                    >
                      <i className={`icon-${data.icon}`}></i> {data.label}
                    </a>
                  </div>
                ))}
              </div>
            ) : null 
        }
      </div>
    );
  }
}
