import React, { Component } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import IconCard from '../../Components/Member/IconCard';
import Api from '../../Components/Api';
import Formular from '../../Components/Formular';
import GroupReport from '../../Components/Formular/GroupReport';

UIkit.use(Icons);

export default class SmartHealthCriticalRisk extends Component {

  state = {
    risk: <span uk-spinner=""></span>
  }

  componentDidMount() {
    this.dataGet();
  }

  async dataGet() {
    var { data } = await Api.member('SmartHealthCriticalRisk');


    var _member = {};


    /*Format data */
    for (var i in data) { /** Data name */
      for (var i2 = data[i].length; i2-- > 0;) {
        if (data[i][i2]) {
          for (var i3 = data[i][i2].length; i3-- > 0;) {
            var _memberData = data[i][i2][i3];

            if (!_member[_memberData.memberid]) _member[_memberData.memberid] = {};
            if (!_member[_memberData.memberid][i]) _member[_memberData.memberid][i] = {};

            if (Formular[i]) {
              _member[_memberData.memberid][i] = {
                ..._memberData,
                detail: Formular[i](_memberData.value, _memberData.age, _memberData.gender)
              }
            }


          }
        }

      }
    }


    /** find summary of risk */
    var _dataByYear = {};
    var _sumRisk = 0;

    for (var i in _member) { /** member id */
      var _groupReport = GroupReport(_member[i]);
      var hasRisk = false;

      for(var i1 = _groupReport.length; i1-->0;) {
        if(_groupReport[i1].color === 'yellow' || _groupReport[i1].color === 'orange' || _groupReport[i1].color === 'red') hasRisk = true;
      }
      
      if(hasRisk) _sumRisk++;

    }

    this.setState({risk: _sumRisk});
  }

  render() {
    return <IconCard option={{
      backgroundColorLeft: '#ff425c',
      backgroundColorRight: '#ff7588',
      icon: 'exclamation',
      title: 'Critical Risk',
      caption: '',
      value: this.state.risk || 0
    }} />
  }
};
