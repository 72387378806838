import React from "react";

const FormElementButton = (props) => {
  const { type } = props;

  function getTypeDesc(type) {
    switch (type) {
      case "primary":
        return "uk-button-primary";
      case "danger":
        return "uk-button-danger";
      default:
        return "uk-button-primary";
    }
  }

  const typeClasses = getTypeDesc(type);

  return (
    <div className="uk-inline">
      <button
        className={`uk-button ${typeClasses}`}
        target="_blank"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.label}
      </button>
    </div>
  );
};

export default FormElementButton;
