import React, { Component } from "react";
import Api from "../Components/Api.js";

export default class TelemonitoringConsult extends Component {
  state = {
    amount: 0,
    loadingApi: false
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    var _result = await Api.dietzdashboard('TelemonitoringCampaign');
    

    this.setState({amount: _result.data.amount, loadingApi: false});
  }

  render() {
    return (
      <div className="dd-card-small dd-card-small-yellow dd-shadow">
        <div className="uk-grid uk-flex-middle">
          <div className="uk-width-expand">
            <div className="dd-card-small-title uk-text-uppercase">Campaign</div>
            {
              this.state.loadingApi === true ? 
              <div><span uk-spinner=""></span></div>
              :
              <div className="dd-card-small-text">{this.state.amount}</div>
            }
          </div>
          <div className="uk-width-auto">
            <i className="fas fa-handshake dd-card-small-icon"></i>
          </div>
        </div>
      </div>
    );
  }
}
