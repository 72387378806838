import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import ToolTipsPopUp from "../Utils/ToolTipsPopUp";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import SearchTeleSection from "../Utils/SearchTeleSection";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilHandleValueChange = require("./FormUtil.js").handleValueChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const CONSTANTS = {
  DATA_NAME: "data_form_list",
  QUESTIONS: [
    " 1. ผู้ป่วยทราบข้อบ่งใช้และชนิดของยาพ่นสูดที่ได้รับ",
    " 2. ผู้ป่วยทราบขนาดยาที่ได้รับในครั้งนี้ และทราบวิธีปฏิบัติตัวเมื่อลืมพ่นยา",
    " 3. ผู้ป่วยใช้ยาได้ถูกต้องตามขั้นตอนการใช้อุปกรณ์นั้นๆ",
    " 4. ผู้ป่วยทราบอาการไม่พึงประสงค์และวิธีการป้องกัน",
    " 5. ผู้ป่วยทราบวิธีการตรวจสอบปริมาณยาคงเหลือ",
    " 6. ผู้ป่วยทราบวิธีทำความสะอาดอุปกรณ์ และวิธีการเก็บรักษา",
    " 7. ผู้ป่วยทราบอาการที่ต้องมาพบแพทย์ก่อนนัด",
  ],
  ADDITIONAL_LABELS: [
    "คะแนนรวม",
    "ผู้รับการประเมิน (ผู้ป่วย/ญาติ)",
    "เภสัชกรผู้ประเมิน",
  ],
};

const FormInhaler = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [isShowToolTipsPopup, setIsShowToolTipsPopup] = useState(false);
  var [toolTipId, setToolTipId] = useState(null);
  var [lastIndex, setLastIndex] = useState(0);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [dataFormOlds, setDataFormOlds] = useState([]);
  var [nxOpdDropDownList, setNxOpdDropDownList] = useState([]);
  var [isOnSearch, setIsOnSearch] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_inhaler",
      formType: "24",
    });
    getNxOPDList();
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  async function getNxOPDList() {
    try {
      var result = await Api.consult("GetNxOPDList", {});

      if (result.list.data) {
        const data = result.list.data;
        const dataArray = Object.keys(data);
        const list = dataArray.map((item) => ({
          value: item,
          label: data[item],
        }));

        setNxOpdDropDownList(list);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (props.dataFormOlds && props.dataFormOlds.length > 2) {
      const data = isOnSearch
        ? props.dataFormOlds
        : props.dataFormOlds.slice(-2);
      data.reverse();
      setDataFormOlds(data);
    } else if (props.dataFormOlds && props.dataFormOlds.length > 0) {
      const data = props.dataFormOlds;
      data.reverse();
      setDataFormOlds(data);
    } else {
      setDataFormOlds([]);
    }
  }, [props.dataFormOlds, isOnSearch]);

  useEffect(() => {
    setData("35", dataForm.patient_name);
  }, [dataForm.patient_name]);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const openToolTipsPopUp = useCallback((id) => {
    setIsShowToolTipsPopup(true);
    setToolTipId(id);
  });

  const closeToolTipsPopUp = useCallback(() => {
    setIsShowToolTipsPopup(false);
    setToolTipId(null);
  });

  const getToolTipsContentBody = useMemo(() => {
    switch (toolTipId) {
      case 1:
        return <TooltipContent1 />;
      case 2:
        return <TooltipContent2 />;
      case 3:
        return <TooltipContent3 />;
      case 4:
        return <TooltipContent4 />;
      case 5:
        return <TooltipContent5 />;
      case 6:
        return <TooltipContent6 />;
      case 7:
        return <TooltipContent7 />;
      default:
        return null;
    }
  }, [isShowToolTipsPopup, toolTipId]);

  const renderToolTipsContent = useMemo(() => {
    return (
      <>
        <table
          className="uk-table uk-table-small uk-table-divider uk-table-striped"
          style={{ width: "500px" }}
        >
          <thead>
            <tr>
              <th className="center">แนวทางคำตอบ</th>
            </tr>
          </thead>
          <tbody>{getToolTipsContentBody}</tbody>
        </table>
      </>
    );
  }, [isShowToolTipsPopup, toolTipId]);

  const renderAssessmentFormSubHeader1 = useMemo(() => {
    return (
      <React.Fragment>
        <th key={1} colSpan={3}>
          ประเมินครั้งที่ {dataForm.data_no}
        </th>
        {dataFormOlds.map((item, i) => (
          <th key={i} colSpan={3}>
            ประเมินครั้งที่ {item.data_no}
          </th>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader2 = useMemo(() => {
    return (
      <React.Fragment key={1}>
        <th>
          <FormElementTextBox
            fieldId={"17"}
            value={dataForm.data_17}
            type="date"
            onChange={handleChange}
            width="100"
          />
        </th>
        <th>ติดตามครั้งถัดไป</th>
        <th rowSpan={2}>หมายเหตุ</th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment key={i}>
            <th>
              <FormElementTextBox
                value={item.data_17}
                type="date"
                width="100"
                disabled
              />
            </th>
            <th>ติดตามครั้งถัดไป</th>
            <th rowSpan={2}>หมายเหตุ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader3 = useMemo(() => {
    return (
      <React.Fragment key={1}>
        <th>ทราบ</th>
        <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment key={i}>
            <th>ทราบ</th>
            <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormHeader = useMemo(() => {
    return (
      <React.Fragment key={"header"}>
        <tr>
          <th rowSpan={3}>
            หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
          </th>
          {renderAssessmentFormSubHeader1}
        </tr>
        <tr>{renderAssessmentFormSubHeader2}</tr>
        <tr>{renderAssessmentFormSubHeader3}</tr>
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentQuestionForm = useMemo(() => {
    return CONSTANTS.QUESTIONS.map((questionLabel, questionIndex) => {
      questionIndex = ++questionIndex; // for supporting fleid data start with data_1.
      return (
        <tr key={`questions_content_${questionIndex}`}>
          <td className="td-form-label">
            <div className="question-label-container">
              <div className="question-label">{questionLabel}</div>
              <button
                className="tooltip-button"
                onClick={() => openToolTipsPopUp(questionIndex)}
              >
                <span
                  className="info-icon"
                  uk-icon="icon: info; ratio: 1.4"
                ></span>
              </button>
            </div>
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"1"}
              onChange={handleChange}
            />
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"0"}
              onChange={handleChange}
            />
          </td>
          <td className="td-remark">
            <FormElementTextArea
              fieldId={`${CONSTANTS.QUESTIONS.length + questionIndex}`}
              value={
                dataForm[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
              }
              onChange={handleChange}
              width="100"
              rows={4}
              cols={50}
              noLimit
            />
          </td>
          {dataFormOlds.map((item, i) => (
            <React.Fragment>
              <td>{item[`data_${questionIndex}n`] == "1" && "ทราบ"}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item[`data_${questionIndex}n`] != "1" && "ไม่ทราบ"}
              </td>
              <td className="td-remark">
                <FormElementTextArea
                  value={
                    item[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
                  }
                  width="100"
                  rows={4}
                  cols={50}
                  disabled
                />
              </td>
            </React.Fragment>
          ))}
        </tr>
      );
    });
  }, [dataFormOlds, dataForm]);

  const getTotalScore = useCallback(
    (data) => {
      let rawScore = utilSumTotalScore([
        data.data_1n,
        data.data_2n,
        data.data_3n,
        data.data_4n,
        data.data_5n,
        data.data_6n,
        data.data_7n,
      ]);

      return ((rawScore * 100) / CONSTANTS.QUESTIONS.length).toFixed(2);
    },
    [dataForm]
  );

  const getScoreDesc = useCallback(
    (data) => {
      if (!getTotalScore(data)) {
        return "";
      } else if (getTotalScore(data) >= 80) {
        return "ผ่านเกณฑ์";
      } else {
        return "ไม่ผ่านเกณฑ์";
      }
    },
    [getTotalScore]
  );

  const getFieldByAdditionalLabel = useCallback(
    (data, disabled, labelIndex) => {
      if (labelIndex === 0) {
        return (
          <td colSpan={3}>
            ({getTotalScore(data)}%) {getScoreDesc(data)}
          </td>
        );
      } else if (labelIndex === 1) {
        return (
          <td colSpan={3}>
            <FormElementTextBox
              fieldId={"35"}
              placeholder="ชื่อผู้รับการประเมิน"
              onChange={(i, event) => handleChange}
              width="100"
              value={data[`data_35`]}
              disabled={disabled}
            />
            <FormElementDropdown
              fieldId={`15`}
              value={data[`data_15`]}
              dataList={[
                { label: "เลือกผู้รับการประเมิน", value: "0" },
                { label: "ผู้ป่วย", value: "1" },
                { label: "ญาติ", value: "2" },
              ]}
              onChange={(i, event) => handleChange}
              disabled={disabled}
            />
          </td>
        );
      } else if (labelIndex === 2) {
        return <td colSpan={3}>{data[`data_16`]}</td>;
      } else {
        return "";
      }
    },
    [dataForm, lastIndex]
  );

  const renderAssessmentAdditionalForm = useMemo(() => {
    return CONSTANTS.ADDITIONAL_LABELS.map((labelItem, labelIndex) => (
      <tr key={`additional_content_${labelIndex}`}>
        <td className="uk-text-right">{labelItem}</td>
        {getFieldByAdditionalLabel(dataForm, false, labelIndex)}
        {dataFormOlds.map((item) =>
          getFieldByAdditionalLabel(item, true, labelIndex)
        )}
      </tr>
    ));
  }, [dataForm, dataFormOlds]);

  const onSearch = useCallback((search) => {
    setIsOnSearch(true);
    props.onSearch(search);
  });

  const onClear = useCallback(() => {
    setIsOnSearch(false);
    props.onClear();
  });

  return (
    <div
      uk-grid=""
      className="uk-grid uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "hidden" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" 3.2.3 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยาพ่นสูด Controller/Reliever " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"38"}
          onChange={handleChange}
          width="100"
          label="วิธีการให้คำแนะนำยา"
          cols={120}
          rows={5}
          value={dataForm.data_38}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ช่วงเวลาการประเมิน" />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <SearchTeleSection onSearch={onSearch} onClear={onClear} />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <strong>{dataForm.drug_name}</strong>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" รูปแบบยาพ่น " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider">
          <tbody>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"18"}
                  value={dataForm.data_18}
                  onChange={handleChange}
                  label="MDI"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_18
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"25"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_25
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_25", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"19"}
                  value={dataForm.data_19}
                  onChange={handleChange}
                  label="Turbuhaler"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_19
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"26"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_26
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_26", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"20"}
                  value={dataForm.data_20}
                  onChange={handleChange}
                  label="Respimat"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_20
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"27"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_27
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_27", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"21"}
                  value={dataForm.data_21}
                  onChange={handleChange}
                  label="Accuhaler"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_21
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"28"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_28
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_28", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"22"}
                  value={dataForm.data_22}
                  onChange={handleChange}
                  label="Breezhaler"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_22
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"29"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_29
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_29", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"23"}
                  value={dataForm.data_23}
                  onChange={handleChange}
                  label="Ellipta"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_23
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"30"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_30
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_30", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
            </tr>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"24"}
                  value={dataForm.data_24}
                  onChange={handleChange}
                  label="Handihaler"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_24
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"31"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_31
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_31", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"36"}
                  value={dataForm.data_36}
                  onChange={handleChange}
                  label="Rapihaler"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div className="form-table-scroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>{renderAssessmentFormHeader}</thead>
              <tbody>
                {renderAssessmentQuestionForm}
                {renderAssessmentAdditionalForm}
              </tbody>
            </table>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="การนัดหมายครั้งถัดไป" />
          <FormElementDropdown
            fieldId={"32"}
            label="ห้องตรวจ"
            onChange={handleChange}
            dataList={[{ value: "", label: "เลือกห้องตรวจ" }].concat(
              nxOpdDropDownList
            )}
            width="100"
            value={dataForm.data_32}
          />
          <FormElementTextBox
            fieldId={"33"}
            type="date"
            onChange={handleChange}
            width="100"
            value={dataForm.data_33}
            minDate={dataForm.data_17}
          />
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1"
          style={{ marginTop: "14px" }}
        >
          <article>
            <FormElementLabel label={`คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥ 6 คะแนน) จึงถือว่า "ผ่านเกณฑ์"`} />
          </article>
          <article>
            <FormElementLabel label="หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ" />
          </article>
        </div>
      </div>
      <ToolTipsPopUp show={isShowToolTipsPopup} onClose={closeToolTipsPopUp}>
        {renderToolTipsContent}
      </ToolTipsPopUp>
    </div>
  );
};

export default FormInhaler;

const TooltipContent1 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                1. คำถาม : ผู้ป่วยทราบหรือไม่ว่ายานี้ใช้สำหรับรักษาโรคอะไร
                ออกฤทธิ์การรักษาอย่างไร
              </article>
              <article>
                คำตอบ : ผู้ป่วยสามารถระบุโรคที่ผู้ป่วยได้รับการวินิจฉัย
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    หลอดลมอุดกั้นเรื้อรัง /ถุงลมโป่งพอง /หอบหืด /หอบเหนื่อย/ไอ
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>ออกฤทธิ์ขยายหลอดลม</article>
                </div>
              </article>
              <article>2. คำถาม : ผู้ป่วยทราบหรือไม่ว่าใช้ยาตอนไหน</article>
              <article>
                คำตอบ : พิจารณาตามชนิดยาพ่น ใช้เป็นประจำทุกวัน หรือ
                ใช้เมื่อมีอาการ
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent2 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>1. คำถาม : ขนาดยาที่ได้รับวันนี้เป็นอย่างไร</article>
              <article>
                คำตอบ : ผู้ป่วยสามารถบอกขนาดยาที่ได้รับ หรือ วิธีใช้ยาได้ เช่น
                “Seretide ขนาด 25/50 พ่น 2 กด เช้าและเย็น ทุกวัน”
              </article>
              <article>2. คำถาม : หากลืมพ่นยาต้องทำอย่างไร</article>
              <article>
                คำตอบ: ผู้ป่วยสามารถอธิบายวิธีปฏิบัติตัวเมื่อลืมพ่นยาได้ดังนี้
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    ให้พ่นยาทันทีที่นึกได้ แต่ถ้านึกได้ใกล้มื้อถัดไป
                    (น้อยกว่าครึ่งของ interval) ให้ข้ามมื้อที่ลืม
                    โดยไม่ต้องเพิ่มขนาดยาเป็น 2 เท่า
                  </article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent3 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                ให้ผู้ป่วยปฏิบัติจริง
                สามารถปฏิบัติได้อย่างถูกต้องตามแบบประเมินทักษะ (checklists)
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    หากใช้มากกว่า 1 ชนิด ต้องพ่นห่างกันอย่างน้อย 5 นาที
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    ยาตามอาการ หากไม่ได้ใช้นานเกิน 7 วัน ให้พ่นทิ้ง 1 ครั้ง
                    ก่อนใช้
                  </article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent4 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม :
                ผู้ป่วยบอกอาการไม่พึงประสงค์ที่อาจเกิดขึ้นและวิธีป้องกันได้หรือไม่
              </article>
              <article>คำตอบ: พิจารณาตามชนิดยาพ่นสูดที่ได้รับ</article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    Steroid ทำให้เกิดเชื้อราในช่องปาก เสียงแหบ เจ็บคอ
                    ป้องกันได้โดย
                    อมกลั้วปากและคอแล้วบ้วนน้ำทิ้งทุกครั้งหลังใช้ยา
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    ยาขยายหลอดลม เช่น salbutamol ทำให้หัวใจเต้นเร็ว มือสั่น
                    ปวดศีรษะ ปากแห้ง และนอนไม่หลับ
                    สามารถป้องกันได้โดยไม่ใช้ยามากเกินความจำเป็น
                    และให้ใช้ยารักษา/ควบคุมอาการอย่างสม่ำเสมอ
                  </article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent5 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>คำถาม : สามารถตรวจสอบปริมาณคงเหลือได้อย่างไร</article>
              <article>
                คำตอบ : ผู้ป่วยสามารถบอกวิธีประมาณหรือสังเกตยาหมด
                หรือกระบอกพ่นยามีช่องบอกจำนวนครั้งที่เหลืออยู่
                การสังเกตแถบสีบอกว่ายาหมด (ขึ้นกับชนิดอุปกรณ์ ตาม checklists)
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent6 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม : ผู้ป่วยจะเก็บรักษาและทำความสะอาดกระบอกยาอย่างไร
              </article>
              <article>
                คำตอบ: ผู้ป่วยสามารถบอกได้ว่า
                ทำความสะอาดปากกระบอกพ่นยาด้วยกระดาษทิชชู หรือผ้าแห้ง
                ไม่ใช้ผ้าชุบน้ำหรือทิชชูเปียก (ขึ้นกับชนิดอุปกรณ์ ตาม
                checklists)
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent7 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม : อาการใดที่หากเกิดขึ้นแล้วต้องมาพบแพทย์รพ.ใกล้บ้านก่อนนัด
              </article>
              <article>คำตอบ : ผู้ป่วยสามารถบอกอาการเหล่านี้ได้</article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    แพ้ยา; ผื่นขึ้น หน้าบวม ปากบวม หรือหายใจลำบาก
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    Exacerbation; แน่นหน้าอก หายใจลำบาก หายใจมีเสียงหวีด
                    มีอาการหอบเหนื่อยกำเริบ ใช้ยาบรรเทาอาการไม่หาย ไอมากขึ้น
                    เสมหะมากขึ้น เหนื่อยง่าย อ่อนแรง
                  </article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};
