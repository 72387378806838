import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Api from '../../Components/Api';
import moment from 'moment';
import Fullscreen from '../FullScreen';
import SearchResult from '../../Components/SearchResult';
import ImageUri from '../ImageUri';

export default class HeaderMenu extends Component {

  state = {
    searchShow: false,
    profile: {},
    notification: [],
    searchKeyword: '',
    searchLoading: false,
    searchResultShow: false,
    searchResultCampaign: [],
    searchResultChat: [],
  }

  componentDidMount() {
    /*this.profileGet();
    this.notiGet();
    
    setInterval(()=>{
      this.notiGet();
    }, 10000);*/
  }

  profileGet = async () => {
    var { profile } = await Api.member('UserProfileCard');
    this.setState({ profile });
  }

  notiGet = async () => {
    var { notification } = await Api.member('Notification');
    this.setState({ notification: notification || [] });
  }

  logout = () => {
    localStorage.setItem('memberLogin', '');
    window.location.href = '/';
  }


  link = [
    {
      icon: 'user',
      label: 'Edit Profile',
      link: '/user'
    },
  ]

  notiRender = () => {
    return <div uk-drop="mode: click: pos: bottom-center;" className="d-dropdown-card uk-text-left uk-animation-slide-bottom-small">
      <div className="uk-padding-small uk-padding-remove-top d-font-mon uk-flex-middle">
        NOTIFICATIONS
        {this.state.notification.length > 0 && <span className="d-noti-label uk-float-right">{this.state.notification.length} NEW</span>}
      </div>
      <div style={{ borderTop: '1px solid #CCC' }}></div>
      <div className="uk-overflow-auto uk-height-small">
        {
          this.state.notification.length === 0 ?
            <div className="uk-text-meta uk-text-center uk-padding">ไม่พบรายการ</div>
            :
            <div>
              <ul className="uk-list uk-list-divider">
                {this.state.notification.map((data, index) => {
                  return <li className="uk-padding-small" key={`notiHead${index}`}>
                    <div uk-grid="" className="uk-grid-collapse uk-flex-middle">
                      <div className="uk-width-1-4 uk-text-center">
                        <span className="d-noti-list-icon">
                        <i className="icon-bubbles"></i>
                        </span>
                      </div>
                      <div className="uk-width-3-4">
                        <div className="uk-text-primary">
                          <Link to={data.link}>{data.name}</Link>
                        </div>
                        <div className="d-noti-list-caption" dangerouslySetInnerHTML={{__html : data.caption }} ></div>
                        <div className="uk-text-meta">{moment(data.dateadd).fromNow()}</div>
                      </div>
                    </div>
                  </li>
                })}
                
              </ul>
            </div>
        }

      </div>
      <div style={{ borderTop: '1px solid #CCC' }}></div>
      <div className="d-font-mon uk-text-center">
        <Link to={'/user-smart-health'} id="d-noti-more">Read all notification</Link>
      </div>
    </div>
  }

  searchListRender = () => {
    if(this.state.searchKeyword) {
      this.setState({searchLoading: true});
      setTimeout(async ()=>{
        var {campaign, chat, query} = await Api.member('Search', { keyword: this.state.searchKeyword });

        this.setState({
          searchResultCampaign: campaign ? campaign : [],
          searchResultChat: chat ? chat : [],
          loading: false
        });
      }, 200);
    }
    
  }

  render() {
    return <div id="d-header-menu" className="uk-padding-small uk-box-shadow-medium d-background-white" uk-sticky="">
      <div className="uk-grid uk-grid-collapse uk-child-width-1-2 uk-flex-middle">
        <div>
          <span className="uk-margin-small-right"><Fullscreen /></span>
          <span className="icon-magnifier uk-margin-right" id="d-search-trigger" onClick={() => {
            this.setState({ searchShow: !this.state.searchShow });
            this.searchInput.focus();
          }}></span>
          <input type="text" autoComplete="off" ref={(input) => { this.searchInput = input; }} value={this.state.searchKeyword} className={"uk-input uk-form-small " + (this.state.searchShow ? 'd-search-active' : '')} id="d-search" placeholder="Explore Stack..." onChange={e=>this.setState({searchKeyword: e.target.value}, ()=>this.searchListRender())} onFocus={()=>this.setState({searchKeyword: ''})} onBlur={()=>setTimeout(()=>{
            this.setState({searchKeyword: ''})
          }, 200)} />
          {
          this.state.searchLoading && this.state.searchKeyword && <SearchResult campaign={this.state.searchResultCampaign} chat={this.state.searchResultChat} /> 
        }
        </div>
        
        <div className="uk-text-right uk-flex-middle">
          <div style={{paddingRight: 10}}>
            <div className="uk-inline">
              <a className="uk-margin-small-right uk-link-reset">
                <span className="icon-bell"></span>
                {this.state.notification.length > 0 && <span id="d-noti-header-badge">{this.state.notification.length}</span>}
              </a>
              {this.notiRender()}
            </div>
            <span>
              <div className="uk-background-cover d-header-menu-member-image uk-background-muted" style={{ backgroundImage: (this.state.profile.image === '' ? false : `url(${ImageUri(this.state.profile.image)})`) }}></div>
              {' '}
              {this.state.profile.nameprefix} {this.state.profile.name} {' '}
              <span>
                <a className="uk-link-reset icon-arrow-down d-header-menu-more"></a>
                <div uk-dropdown="mode: click; pos: bottom-right;" className="d-dropdown-card uk-text-left">
                  {
                    this.link.map(data => {
                      return <Link key={data.label} to={data.link}>
                        <span className={'uk-margin-small-right icon-' + data.icon}></span>{' '}
                        {data.label}
                      </Link>
                    })
                  }
                  <div style={{ borderTop: '1px solid #CCC', marginTop: 5, marginBottom: 5 }}></div>
                  <a onClick={this.logout}>
                    <span className="icon-power uk-margin-small-right "></span>
                    Logout
                  </a>
                </div>
              </span>
            </span>
          </div>

        </div>
      </div>
    </div>
  }
};
