import React, { useCallback, useEffect, useState } from "react";
import FormGenerate from "./FormGenerate";

const FormElementCheckBox = (props) => {
  const [checked, setChecked] = useState(false);

  var isMagicRadio = false;

  if (typeof props.magicRadio != "undefined") {
    isMagicRadio = props.magicRadio;
  }

  var mode = "edit";

  var paddingStyle = "0px 0px 0px 0px";

  if (typeof props.padding != "undefined") {
    paddingStyle = props.padding;
  }

  const styleObj = {
    padding: paddingStyle,
  };

  if (typeof props.mode != "undefined") {
    mode = props.mode;
  }

  var className = "uk-checkbox";

  if (isMagicRadio) {
    className += " magicRadio";
  }

  function onChange(e) {
    props.onChange(props.fieldId, e);
  }

  const handleLabelCheckboxChange = useCallback(() => {
    if (checked) {
      onChange("0");
    } else {
      onChange("1");
    }
  });

  useEffect(() => {
    if (typeof props.value != "undefined" && props.value != null) {
      if (props.value == "1") {
        setChecked(true);

        // if (mode != "view") {
        //   props.onChange(props.fieldId, "1");
        // }
      } else {
        setChecked(false);

        // if (mode != "view") {
        //   props.onChange(props.fieldId, "0");
        // }
      }
    } else {
      setChecked(false);
    }
  }, [props.value]);

  if (mode != "view") {
    return (
      <div className="uk-display-inline check-box-container">
        <input
          type="checkbox"
          className={className}
          checked={checked}
          onChange={(e) => onChange(e)}
          disabled={props.disabled}
        />
        {!isMagicRadio && (
          <span className="formTextBoxLabel">{props.label}</span>
        )}
        {isMagicRadio &&
        typeof props.label != "undefined" &&
        props.label != "" ? (
          <label
            className="labelMagicRadio"
            htmlFor={"data_" + props.fieldId + "_" + props.fieldValue}
            onClick={handleLabelCheckboxChange}
          >
            {props.label}
          </label>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    if (checked) {
      return (
        <div>
          <span>{props.label}</span>
          <span>/</span>
        </div>
      );
    } else {
      return <></>;
    }
  }
};

export default FormElementCheckBox;
