import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import dateFormat from "dateformat";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import PopupForm from "../PopupForm";
import { data, error } from "jquery";
import DrugAllergicSection from "../Utils/DrugAllergicSection";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchDiagByTxn from "../../API/fetchDiagByTxn.js";
import fetchDrugAllergic from "../../API/fetchDrugAllergic.js";
import fetchDrugOpdVerifyNoteByTxn from "../../API/fetchDrugOpdVerifyNoteByTxn.js";
import fetchNxOpdList from "../../API/fetchNxOpdList.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchDrugDataByTxn from "../../API/fetchDrugDataByTxn.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormTelePharmacyPaper from "../FormPaper/FormTelePharmacyPaper.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import FormPopup from "../Common/FormPopup.js";
import FormWafarin from "./FormWafarin.js";
import FormNoac from "./FormNoac.js";
import FormInsurine from "./FormInsurine.js";
import FormBispho from "./FormBispho.js";
import fetchConsultFormListByTxn from "../../API/fetchConsultFormListByTxn.js";
import FormInhaler from "./FormInhaler.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave =
  require("../Common/FormUtil.js").transformDataBeforeSave;
var utilGetValueChanged = require("../Common/FormUtil.js").getValueChanged;

const FormTelePharmacy = (props) => {
  const formConstants = { formName: "form_telepharmacy", formType: "38" };
  const {
    patient,
    onLoading,
    setPatient,
    formId,
    profile,
    onSuccessPopupOpen,
    onErrorMessageChange,
    hn,
    txn,
  } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [popupFormType, setPopupFormType] = useState(0);
  const [popupdataForm, setPopupdataForm] = useState({});
  var [diagList, setDiagList] = useState([]);
  var [hideSaveBtnPopup, setHideSaveBtnPopup] = useState(false);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [allOfData17Checked, setAllOfData17Checked] = useState("0");
  var [drugAllergics, setDrugAllergics] = useState([]);
  var [opdVerify, setOpdVerify] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [isNewDrugList, setIsNewDrugList] = useState(false);
  const [wafarinDataForm, setWafarinDataForm] = useState({
    data: {},
    html: "",
  });
  const [noacDataForm, setNoacDataForm] = useState({ data: {}, html: "" });
  const [insurinDataForm, setInsurinDataForm] = useState({
    data: {},
    html: "",
  });
  const [bisphoDataForm, setBisphoDataForm] = useState({ data: {}, html: "" });
  const [inhalerDataFormList, setInhalerDataFormList] = useState([]);
  const [inhalerSelectedDrugId, setInhalerSelectedDrugId] = useState("");
  const [inhalerSelectedDrugName, setInhalerSelectedDrugName] = useState("");
  const [inhalerTotalCount, setInhalerTotalCount] = useState(0);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback(
    (open) => {
      setIsPreview(open);
    },
    [setIsPreview]
  );

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถดึงข้อมูลได้",
      isGoToMainPage: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({
      open: true,
      header: "เกิดข้อผิดพลาด",
      description: "ไม่สามารถบันทึกรายการได้",
      isClose: true,
    });
  }, [onErrorMessageChange]);

  const displayErrorValidatePopup = useCallback(
    (message) => {
      onErrorMessageChange({
        open: true,
        header: "ไม่สามารถบันทึกได้",
        description: message,
        isClose: true,
      });
    },
    [onErrorMessageChange]
  );

  const prepareData = useCallback(
    (form, nxOpdListResponse, drugDataResponse) => {
      setIsNewDrugList(false);
      if (drugDataResponse.length > 0) {
        if (form?.data_drug) {
          const newDrugIdList = drugDataResponse?.map((drug) => drug.id);
          const oldDrugIdList = form?.data_drug?.map((drug) => drug.id);
          if (oldDrugIdList.length === newDrugIdList.length) {
            if (!oldDrugIdList.every((drug) => newDrugIdList.includes(drug))) {
              form.data_drug = drugDataResponse;
              setIsNewDrugList(true);
            }
          } else {
            setIsNewDrugList(true);
            form.data_drug = drugDataResponse;
          }
        } else {
          form.data_drug = drugDataResponse;
        }
      }

      if (drugDataResponse.length > 0) {
        form.data_32 = drugDataResponse[0].dtm;
        form.data_8 = drugDataResponse[0].doc_name;
      }

      if (drugDataResponse.length > 0 && nxOpdListResponse.length > 0) {
        form.data_7 =
          nxOpdListResponse.find((nx) => nx.value == drugDataResponse[0].room)
            ?.label || "";
      }

      return form;
    },
    []
  );

  useEffect(() => {
    console.log("patient", patient);
  }, [patient]);

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const diagRequest = fetchDiagByTxn(txn);
    const drugAllergicRequest = fetchDrugAllergic(hn);
    const drugOpdVerifyNoteRequest = fetchDrugOpdVerifyNoteByTxn(hn, txn);
    const nxOpdListRequest = fetchNxOpdList();
    const drugDataRequest = fetchDrugDataByTxn(hn, txn);
    const patientData = fetchMemberByTxn(hn, txn);
    const wafarinDataFormRequest = fetchConsultFormByTxn(txn, "22");
    const noacDataFormRequest = fetchConsultFormByTxn(txn, "23");
    const insurinDataFormRequest = fetchConsultFormByTxn(txn, "25");
    const bisphoDataFormRequest = fetchConsultFormByTxn(txn, "26");
    const inhalerDataFormRequest = fetchConsultFormListByTxn(txn, "24");
    const patientRoomRequest = fetchMemberListHis(hn, txn);
    Promise.all([
      diagRequest,
      drugAllergicRequest,
      drugOpdVerifyNoteRequest,
      nxOpdListRequest,
      drugDataRequest,
      patientData,
      wafarinDataFormRequest,
      noacDataFormRequest,
      insurinDataFormRequest,
      bisphoDataFormRequest,
      inhalerDataFormRequest,
      patientRoomRequest,
    ])
      .then((values) => {
        const diagResponse = values[0];
        const drugAllergicResponse = values[1];
        const drugOpdVerifyNoteResponse = values[2];
        const nxOpdListResponse = values[3];
        const drugDataResponse = values[4];
        const patientResponse = values[5];
        const wafarinDataFormResponse = values[6];
        const noacDataFormResponse = values[7];
        const insurinDataFormResponse = values[8];
        const bisphoDataFormResponse = values[9];
        const inhalerDataFormResponse = values[10];
        const patientRoomResponse = values[11];

        data = prepareData({ ...data }, nxOpdListResponse, drugDataResponse);

        const inhalerData = inhalerDataFormResponse.map((item) => ({
          data: { ...item, hn, txn },
          html: "",
        }));

        setDiagList(diagResponse);
        setDrugAllergics(drugAllergicResponse);
        setOpdVerify(drugOpdVerifyNoteResponse);
        console.log(patientResponse, patientRoomResponse);
        setPatient({ ...patientResponse, ...patientRoomResponse });
        setWafarinDataForm({ data: { ...wafarinDataFormResponse, hn, txn } });
        setNoacDataForm({ data: { ...noacDataFormResponse, hn, txn } });
        setInsurinDataForm({ data: { ...insurinDataFormResponse, hn, txn } });
        setBisphoDataForm({ data: { ...bisphoDataFormResponse, hn, txn } });
        setInhalerDataFormList(inhalerData);
        setDataForm((form) => ({
          ...form,
          ...data,
          ...formConstants,
          hn,
          txn,
          patient_name: patientResponse.patient_name,
        }));
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        displayErrorFetchPopup();
      });
  });

  const fetchData = useCallback(
    async (formId, hn, txn) => {
      try {
        displayLoading();
        setDataForm({});
        let data = {};
        let hnValue, txnValue;
        if (formId) {
          data = await fetchConsultFormByFormId(formId, formConstants.formType);
          hnValue = data.hn;
          txnValue = data.txn;
        } else {
          data = await fetchConsultFormByTxn(txn, formConstants.formType);
          hnValue = hn;
          txnValue = txn;
        }
        await fetchOtherData(data, hnValue, txnValue);
      } catch (error) {
        console.error(error);
        hideLoading();
        displayErrorFetchPopup();
      }
    },
    [
      displayLoading,
      hideLoading,
      displayErrorFetchPopup,
      setDataForm,
      fetchOtherData,
    ]
  );

  // fetch data when has formId #Edit
  useEffect(() => {
    if (formId) fetchData(formId, null, null);
  }, [formId]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!formId && !hn && !txn && patient.hn && patient.txn)
      fetchData(null, patient.hn, patient.txn);
  }, [patient.hn, patient.txn, formId, hn, txn]);

  useEffect(() => {
    if (!formId && hn && txn) fetchData(null, hn, txn);
  }, [hn, txn, formId]);

  const validateSave = useCallback(() => {
    if (dataForm?.data_drug) {
      if (
        dataForm?.data_drug?.some(
          (item) => item.data_1 == undefined || item.data_1 != "1"
        )
      ) {
        displayErrorValidatePopup("เนื่องจากท่านบันทึกคำแนะนำยาไม่ครบ");
        return false;
      }
    }
    return true;
  }, [dataForm, displayErrorValidatePopup]);

  const handleSave = useCallback(
    (html) => {
      async function saveForm(paperHtml) {
        displayLoading();
        try {
          if (validateSave()) {
            const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
            handlePreviewOpenChange(false);
            await saveConsultForm(
              saveData,
              profile.loginToken,
              dataForm.form_id,
              profile.consultId,
              profile.doctorId,
              paperHtml,
              patient
            );

            const subFormSaveRequest = [];

            const wafarinSaveData = utilTransformDataBeforeSave(
              { ...wafarinDataForm.data },
              []
            );
            if (wafarinDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  wafarinSaveData,
                  profile.loginToken,
                  wafarinSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  wafarinDataForm.html,
                  patient
                )
              );
            }
            const noacSaveData = utilTransformDataBeforeSave(
              { ...noacDataForm.data },
              []
            );
            if (noacDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  noacSaveData,
                  profile.loginToken,
                  noacSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  noacDataForm.html,
                  patient
                )
              );
            }

            for (var i = 0; i < inhalerDataFormList.length; i++) {
              const inhalerData = inhalerDataFormList[i];

              if (inhalerData.html) {
                const inhalerSaveData = utilTransformDataBeforeSave(
                  { ...inhalerData.data },
                  []
                );
                if (!inhalerSaveData.formId) {
                  inhalerSaveData.data_no = inhalerTotalCount + i + 1;
                }
                subFormSaveRequest.push(
                  saveConsultForm(
                    inhalerSaveData,
                    profile.loginToken,
                    inhalerSaveData.form_id,
                    profile.consultId,
                    profile.doctorId,
                    inhalerData.html,
                    patient
                  )
                );
              }
            }

            const insulinSaveData = utilTransformDataBeforeSave(
              { ...insurinDataForm.data },
              []
            );
            if (insurinDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  insulinSaveData,
                  profile.loginToken,
                  insulinSaveData.form_id,
                  profile.consultId,
                  profile.doctorId,
                  insurinDataForm.html,
                  patient
                )
              );
            }
            const bisphoSaveData = utilTransformDataBeforeSave(
              { ...bisphoDataForm.data },
              []
            );
            if (bisphoDataForm.html) {
              subFormSaveRequest.push(
                saveConsultForm(
                  bisphoSaveData,
                  profile.loginToken,
                  bisphoDataForm.form_id,
                  profile.consultId,
                  profile.doctorId,
                  bisphoDataForm.html,
                  patient
                )
              );
            }

            if (subFormSaveRequest.length > 0) {
              Promise.all([...subFormSaveRequest])
                .then(() => {
                  hideLoading();
                  onSuccessPopupOpen(true);
                })
                .catch((error) => {
                  hideLoading();
                  displayErrorSavePopup();
                });
            } else {
              hideLoading();
              onSuccessPopupOpen(true);
            }
          } else {
            hideLoading();
          }
        } catch (error) {
          hideLoading();
          displayErrorSavePopup();
        }
      }
      saveForm(html);
    },
    [
      dataForm,
      wafarinDataForm,
      noacDataForm,
      insurinDataForm,
      bisphoDataForm,
      validateSave,
      displayLoading,
      hideLoading,
      onSuccessPopupOpen,
      displayErrorSavePopup,
      inhalerDataFormList,
      inhalerTotalCount,
      patient,
    ]
  );

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const handleDataDrugValueChange = useCallback(
    (i, key, event) => {
      const temp_data_drug = dataForm?.data_drug;
      const value = utilGetValueChanged(event);
      const json = { ...temp_data_drug[i], [key]: value };
      temp_data_drug[i] = json;
      const allCheckedValue = temp_data_drug?.every(
        (item) => item.data_1 == "1"
      )
        ? "1"
        : "0";
      setAllOfData17Checked(allCheckedValue);
      setDataForm((data) => ({ ...data, data_drug: temp_data_drug }));
    },
    [dataForm?.data_drug, setAllOfData17Checked, setDataForm]
  );

  const handleAllCheckedChange = useCallback(
    (event) => {
      const value = utilGetValueChanged(event);

      if (dataForm?.data_drug) {
        const temp_data_drug = dataForm?.data_drug;
        temp_data_drug?.forEach((item) => (item.data_1 = value));
        setDataForm((data) => ({ ...data, data_drug: temp_data_drug }));
        setAllOfData17Checked(value);
      }
    },
    [dataForm?.data_drug, setAllOfData17Checked, setDataForm]
  );

  useEffect(() => {
    if (dataForm?.data_drug) {
      const value = dataForm?.data_drug?.every((item) => item.data_1 == "1")
        ? "1"
        : "0";
      setAllOfData17Checked(value);
    }
  }, [dataForm?.data_drug, allOfData17Checked]);

  const handleCloseSubFormPopup = useCallback(() => {
    setShowFormPopup(false);
    setPopupFormType(undefined);
  }, [setShowFormPopup, setPopupFormType]);

  function doPopupDrugForm(type, id, name) {
    if (type == "warfarin") {
      doWafarin();
    } else if (type == "Noacs") {
      doNoac();
    } else if (type == "สูดพ่น") {
      doInhaler(id, name);
    } else if (type == "insulin") {
      doInsurine();
    } else if (type == "bisphosphonates") {
      doBispho();
    }
  }

  function doWafarin() {
    setPopupFormType(22);
    setShowFormPopup(true);
  }

  function doNoac() {
    setPopupFormType(23);
    setShowFormPopup(true);
  }

  function doInhaler(id, name) {
    setPopupFormType(24);
    setShowFormPopup(true);
    setInhalerSelectedDrugId(id.toString());
    setInhalerSelectedDrugName(name);
  }

  function doInsurine() {
    setPopupFormType(25);
    setShowFormPopup(true);
  }

  function doBispho() {
    setPopupFormType(26);
    setShowFormPopup(true);
  }

  function doDeliveryGeneralMedicine() {
    var dataFormItem = props.getdataFormItem(27);
    setPopupdataForm(dataFormItem);
    setPopupFormType(27);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doDeliveryDrugMedicine() {
    var dataFormItem = props.getdataFormItem(28);
    setPopupdataForm(dataFormItem);
    setPopupFormType(28);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doDeliveryWafarineMedicine() {
    var dataFormItem = props.getdataFormItem(29);
    setPopupdataForm(dataFormItem);
    setPopupFormType(29);
    setHideSaveBtnPopup(false);
    setShowFormPopup(true);
  }

  function doShowPic(name, code, howTo, amt) {
    let url =
      "/consult/phamacyImage?mode=iviewer&" +
      encodeURIComponent(
        "name=" +
          name +
          "&code=" +
          code +
          "&howTo=" +
          JSON.stringify(howTo) +
          "&amt=" +
          amt +
          "&patientName=" +
          dataForm?.patient_name +
          "&hn=" +
          dataForm?.hn
      );

    window.open(url, "imagePhama").focus();
  }

  useEffect(() => {
    console.log("inhalerDataFormList", inhalerDataFormList);
  }, [inhalerDataFormList]);

  const handleSubFormDataChange = useCallback(
    (data, html) => {
      if (popupFormType == 22) {
        setWafarinDataForm({ data: { ...data }, html });
      } else if (popupFormType == 23) {
        setNoacDataForm({ data: { ...data }, html });
      } else if (popupFormType == 24) {
        const inhalerData = [...inhalerDataFormList];
        if (
          data.form_id &&
          inhalerData.some(
            (s) =>
              s.data.data_drug_id == data.data_drug_id &&
              s.data.form_id == data.form_id
          )
        ) {
          inhalerData
            .filter(
              (item) =>
                item.data.data_drug_id == data.data_drug_id &&
                item.data.form_id == data.form_id
            )
            .forEach((item) => {
              item.data = data;
              item.html = html;
            });
        } else {
          inhalerData.push({ data, html });
        }
        setInhalerDataFormList([...inhalerData]);
      } else if (popupFormType == 25) {
        setInsurinDataForm({ data: { ...data }, html });
      } else if (popupFormType == 26) {
        setBisphoDataForm({ data: { ...data }, html });
      }
      setShowFormPopup(false);
      setPopupFormType(undefined);
    },
    [
      setShowFormPopup,
      setPopupFormType,
      popupFormType,
      setWafarinDataForm,
      setNoacDataForm,
      setInsurinDataForm,
      setBisphoDataForm,
      setInhalerDataFormList,
    ]
  );

  const handleInhalerTotalChange = useCallback(
    (value) => {
      setInhalerTotalCount(value);
    },
    [setInhalerTotalCount]
  );

  const getSubFormProps = useMemo(() => {
    return {
      patient: patient,
      profile: profile,
      onLoading: onLoading,
      isSubForm: true,
      onAdd: handleSubFormDataChange,
      onErrorMessageChange: onErrorMessageChange,
    };
  }, [
    patient,
    profile,
    onLoading,
    handleSubFormDataChange,
    onErrorMessageChange,
  ]);

  const renderSubForm = useMemo(() => {
    if (popupFormType == 22) {
      return <FormWafarin data={wafarinDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 23) {
      return <FormNoac data={noacDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 24) {
      const inhalerData = inhalerDataFormList?.find(
        (item) => item.data.data_drug_id == inhalerSelectedDrugId
      )?.data || { hn: patient.hn, txn: patient.txn };
      return (
        <FormInhaler
          data={{
            ...inhalerData,
            data_drug_name: inhalerSelectedDrugName,
            data_drug_id: inhalerSelectedDrugId,
          }}
          {...getSubFormProps}
          onTotalCountChange={handleInhalerTotalChange}
        />
      );
    } else if (popupFormType == 25) {
      return <FormInsurine data={insurinDataForm.data} {...getSubFormProps} />;
    } else if (popupFormType == 26) {
      return <FormBispho data={bisphoDataForm.data} {...getSubFormProps} />;
    } else {
      return <>ไม่พบข้อมูล</>;
    }
  }, [
    getSubFormProps,
    popupFormType,
    wafarinDataForm.data,
    noacDataForm.data,
    insurinDataForm.data,
    bisphoDataForm.data,
    inhalerDataFormList,
    inhalerSelectedDrugId,
    inhalerSelectedDrugName,
    patient,
    handleInhalerTotalChange,
  ]);

  return (
    <div
      uk-grid=""
      className="uk-grid uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "none" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" แบบบันทึกการให้คำแนะนำการใช้ยาผ่านระบบ Telemedicine " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="Diagnosis " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>type</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {diagList.length === 0 ? (
              <tr>
                <td colSpan="100%">
                  <div className="uk-padding-small uk-text-center">
                    ไม่พบข้อมูล
                  </div>
                </td>
              </tr>
            ) : (
              diagList.map((data, key) => {
                return (
                  <tr key={`row${data.icd10}`}>
                    <td>{key + 1}</td>
                    <td>{data.dx_type_name}</td>
                    <td>{data.icd10_name}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"7"}
          value={dataForm?.data_7}
          label="OPD"
          onChange={handleChange}
          width="100"
        />
        <FormElementTextBox
          fieldId={"8"}
          value={dataForm?.data_8}
          label="แพทย์ "
          onChange={handleChange}
          width="100"
        />
        <span className="formTextBoxLabel">วันที่แพทย์สั่งยา</span>
        <FormElementTextBox
          fieldId={"32"}
          type="date"
          value={dataForm?.data_32}
          onChange={handleChange}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          label="วิธีการให้คำแนะนำยา"
          fieldId="34"
          value={dataForm?.data_34}
          onChange={handleChange}
          rows={4}
          cols={100}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ประวัติการแพ้ยา" />
        <div className="uk-width-1-1@m uk-width-1-1">
          <DrugAllergicSection data={drugAllergics} />
        </div>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" รายการยาที่แพทย์สั่ง ห้องตรวจ " /> &nbsp;
        &nbsp; &nbsp;
        {/* <FormElementButton label="รูปยา" onClick={doPhamacyImage} /> */}
        {isNewDrugList && dataForm?.data_drug && (
          <div>
            <span class="uk-label uk-label-success" style={{ padding: "6px" }}>
              ตัวยามีการเปลี่ยนแปลง กรุณาแนะนำตัวยาใหม่
            </span>
          </div>
        )}
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div className="form-table-scroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th style={{ whiteSpace: "nowrap" }}>ชื่อยา</th>
                  <th style={{ width: "400px" }}>วิธีใช้</th>
                  <th>จำนวน</th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    สถานะการปรับเปลี่ยนยา
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    แนะนำยา{" "}
                    <FormElementCheckBox
                      fieldId={"check-all"}
                      value={allOfData17Checked}
                      onChange={(ignored, event) =>
                        handleAllCheckedChange(event)
                      }
                    />
                  </th>
                  <th style={{ width: "400px" }}>ปัญหาการใช้ยาที่พบ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataForm?.data_drug?.length === 0 || !dataForm?.data_drug ? (
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                ) : (
                  dataForm?.data_drug?.map((data, key) => {
                    const howToDesc = {
                      sig: data.sig,
                      sigadd: data.sigadd,
                      signote: data.signote,
                    };
                    return (
                      <tr key={`row-drug-${key}`}>
                        <td>{key + 1}</td>
                        <td>
                          <a
                            onClick={(e) =>
                              doShowPic(
                                data.name,
                                data.code,
                                howToDesc,
                                data.amt
                              )
                            }
                          >
                            {data.name}
                          </a>
                        </td>
                        <td>
                          <p>{data.sig}</p>
                          <p>{data.sigadd}</p>
                          <p>{data.signote}</p>
                        </td>
                        <td>{data.amt}</td>
                        <td>{data.remed_status + " " + data.dru_note}</td>
                        <td>
                          <div className="uk-width-1-5">
                            <FormElementCheckBox
                              fieldId={`checkbox-${key}`}
                              value={data.data_1 || "0"}
                              onChange={(i, event) =>
                                handleDataDrugValueChange(key, "data_1", event)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <FormElementTextArea
                            fieldId={`textarea-${key}`}
                            value={data.data_2 || ""}
                            cols={80}
                            rows={3}
                            onChange={(i, event) =>
                              handleDataDrugValueChange(key, "data_2", event)
                            }
                            noLimit
                          />
                        </td>
                        <td>
                          {data.tele_drug_type != "" &&
                          data.tele_drug_type != "ยาเสพติด" ? (
                            <FormElementButton
                              label="ฟอร์ม"
                              onClick={() =>
                                doPopupDrugForm(
                                  data.tele_drug_type,
                                  data.id,
                                  data.name
                                )
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ข้อมูลที่ส่งต่อจากเภสัชกรคัดกรองคำสั่งใช้ยา " />
        <div className="uk-width-1-1@m uk-width-1-1">
          {Object.keys(opdVerify).length > 0 && (
            <div
              style={{ display: "flex", gap: "12px", flexDirection: "column" }}
            >
              <span>{opdVerify.text_head}</span>
              {opdVerify.text?.phar_note?.map((item, i) => {
                const texts = item?.note_desc?.split("<br>");
                return (
                  <div key={i} style={{ display: "inline-flex", gap: "8px" }}>
                    <strong>-</strong>
                    <span>
                      {texts.map((t, key) => (
                        <span key={key}>
                          {t}
                          <br />
                        </span>
                      ))}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          {Object.keys(opdVerify).length == 0 && "ไม่มีข้อมูล"}
        </div>
      </div>
      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"20"}
          value={dataForm?.data_20}
          cols={100}
          rows={5}
          onChange={handleChange}
          noLimit
        />
      </div> */}
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" รายการยาที่ได้รับจากสถานพยาบาลอื่น/ สมุนไพร/ อาหารเสริม " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"21"}
          value={dataForm?.data_21}
          cols={100}
          rows={5}
          onChange={handleChange}
          noLimit
        />
      </div>

      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" แบบติดตามการประเมินความรู้ผู้ป่วย  " />
      </div>

      <div className="uk-width-1-1">
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <FormElementLabel label="Warfarin" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doWafarin} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="NOAC" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doNoac} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="ยาพ่นสูด" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doInhaler} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="insulin" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doInsurine} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="bisphosphonate แบบรับประทาน" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doBispho} />
          </div>
        </div>
      </div>

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" แบบติดตามการได้รับยาและการใช้ยาที่จัดส่งทางไปรษณีย์  " />
      </div>

      <div className="uk-width-1-1">
        <div className="uk-grid">
          <div className="uk-width-1-2">
            <FormElementLabel label="ยาทั่วไป" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton
              label="ฟอร์ม"
              onClick={doDeliveryGeneralMedicine}
            />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="ยาเสพติด" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton label="ฟอร์ม" onClick={doDeliveryDrugMedicine} />
          </div>
          <div className="uk-width-1-2">
            <FormElementLabel label="Warfarin" />
          </div>
          <div className="uk-width-1-2">
            <FormElementButton
              label="ฟอร์ม"
              onClick={doDeliveryWafarineMedicine}
            />
          </div>
        </div>
      </div> */}

      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" ข้อมูลที่ต้องการส่งต่อนัดครั้งถัดไป  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="  แพทย์  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"23"}
          value={dataForm?.data_23}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="  พยาบาล  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"24"}
          value={dataForm?.data_24}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="  เภสัช  " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"25"}
          value={dataForm?.data_25}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="เจ้าหน้าที่อื่น" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"26"}
          value={dataForm?.data_26}
          onChange={handleChange}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ปัญหาอื่น" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          cols={100}
          rows={5}
          fieldId={"27"}
          value={dataForm?.data_27}
          onChange={handleChange}
          noLimit
        />
      </div>
      {/* <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ข้อมูลผู้ให้บริการ" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextBox
          fieldId={"28"}
          value={dataForm?.data_28}
          label="ชื่อ"
          onChange={handleChange}
          width="100"
        />
        <FormElementTextBox
          fieldId={"33"}
          value={dataForm?.data_33}
          label="นามสกุล"
          onChange={handleChange}
          width="100"
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <span className="formTextBoxLabel">วันที่</span>
        <FormElementTextBox
          fieldId={"29"}
          type="date"
          value={dataForm?.data_29}
          onChange={handleChange}
          width="100"
        />
        <FormElementTextBox
          fieldId={"30"}
          value={dataForm?.data_30}
          label="เวลาเริ่มต้น"
          onChange={() => {}}
          width="100"
        />
        <FormElementTextBox
          fieldId={"31"}
          value={dataForm?.data_31}
          label="เวลาสิ้นสุด"
          onChange={handleChange}
          width="100"
        />
      </div> */}
      <FormActionButtonSection
        isPreview={isPreview}
        onOpen={() => handlePreviewOpenChange(true)}
        onClose={() => handlePreviewOpenChange(false)}
        onSave={(html) => handleSave(html)}
        formContent={
          <FormTelePharmacyPaper
            dataForm={dataForm}
            patient={patient}
            profile={profile}
            drugAllergics={drugAllergics}
            opdVerify={opdVerify}
            diagList={diagList}
          />
        }
      />
      <FormPopup show={showFormPopup} onClose={handleCloseSubFormPopup}>
        {renderSubForm}
      </FormPopup>
    </div>
  );
};

export default FormTelePharmacy;
