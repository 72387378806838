import React, { useState, useEffect } from 'react';
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import '../../css/dietz.css';
import '../../css/dietz2.css';

UIkit.use(Icons);

const MemberSelector = (props) => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataList, setDataList] = useState([]);
  const { show } = props;

  async function listGet(event) {
    var { list } = await Api.consult("MemberListHIS", {
      hn: document.getElementById('txtHN').value,
    });

    const element = document.getElementById('txtTXN');
    const searchText = element.value;

    var dataFiltered = null;

    if (searchText != "") {
      list = list.filter(data => data.txn.indexOf(searchText) !== -1)

    } else {
      list = list;
    }

    // list = [{ ttl: 'นาย', name: 'ทดสอบ', lname: 'ทดสอบ', hn: '1234567', txn: '1234567' }]

    if (list.length > 0) {
      setDataList(list);

    } else {
      setDataList([]);
    }

    //setData(list);
    setLoadingApi(false);
  }

  function filter() {
    const element = document.getElementById('txtSearch');

    const searchText = element.value;

    var dataFiltered = null;

    if (searchText != "") {
      dataFiltered = dataListOrg.filter(data => data.code.indexOf(searchText) !== -1)

    } else {
      dataFiltered = dataListOrg;
    }

    setDataList(dataFiltered);

  }

  function testOnClick(event) {
    alert("abc");
  }

  function doSelectMember(data) {
    // console.log("doSelectMember");
    // console.log(data);
    props.onClose(data);
  }

  function onClose() {
    props.onClose();
  }

  function showPopup() {
    UIkit.modal('#modal-select-member').show();
  }

  useEffect(() => {

  }, []);


  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [show]);

  return (
    <>
      {show ?
        <div className="form-modal-container" >
          <div className="form-modal-dialog-container">
            <div className="form-modal-dialog-header">
              <h2 className="uk-modal-title">เลือก ผู้ป่วย</h2>
              <div className='form-modal-right-header'>
                <button className="uk-button-danger form-modal-close-button" type="button" onClick={onClose} > <span uk-icon="icon: close; ratio: 1"></span></button>
              </div>
            </div>
            <div className="form-modal-dialog-body">
              <div>HN : <input type="textbox" id="txtHN" />&nbsp;TXN : <input type="textbox" id="txtTXN" /> <button id="btnSearchMember" className="uk-button uk-button-primary" onClick={(event) => { listGet(event) }}>ค้นหา</button></div>
              <div className="popupSelectScroll">
                <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                  <thead>
                    <tr>
                      <th>ที่</th>
                      <th>ชื่อ</th>
                      <th>HN</th>
                      <th>TXN</th>
                      <th>เวลา</th>
                      <th>ห้อง</th>
                      <th>เลือก</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      loadingApi == true ?
                        <tr>
                          <td colSpan="100%">
                            <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                          </td>
                        </tr>
                        :
                        dataList.length === 0 ?
                          <tr>
                            <td colSpan="100%">
                              <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                            </td>
                          </tr>
                          :
                          dataList.map((data, key) => {
                            return <tr key={`row${data.txn}`}>
                              <td>{key + 1}</td>
                              <td>
                                {data.ttl} {data.name} {data.lname}
                              </td>
                              <td>
                                {data.hn}
                              </td>
                              <td>
                                {data.txn}
                              </td>
                              <td>
                                {data.reg_date} {data.reg_time}
                              </td>
                              <td>
                                {data.room}
                              </td>
                              <td>
                                <button className='uk-button uk-button-primary' onClick={() => doSelectMember(data)}>เลือก</button>
                              </td>
                            </tr>
                          })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-modal-dialog-footer uk-text-right">
              <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={onClose}>Cancel</button>
            </div>
          </div>
        </div> : null}
    </>

  )
}

export default MemberSelector;
