import React, { useState, useEffect, useMemo } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import FormElementLabel from "./Form/FormElementLabel";
import { Link } from "react-router-dom";
import moment from "moment";
import "../../css/dietz.css";
import FormElementButton from "./Form/FormElementButton";
import MemberSelector from "./MemberSelector";
import { createPortal } from "react-dom";

UIkit.use(Icons);

const MemberProfileFormHeader = (props) => {
  const { onLoading, isSelectDisabled } = props;
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [memberData, setMemberData] = useState(null);
  const [memberDisplayData, setMemberDisplayData] = useState(null);
  const [showMemberSelect, setShowMemberSelect] = useState(false);
  const [hideSelectBtn, setHideSelectBtn] = useState(false);
  const [divStyle, setDivStyle] = useState({});
  const [mode, setMode] = useState("edit");
  const [hn, setHn] = useState(null);
  const [txn, setTxn] = useState(null);

  async function listGet() {
    var { list } = await Api.consult("ClinicList", {});

    setDataListOrg(list);
    setDataList(list);
    setLoadingApi(false);
  }

  function doSelectMember() {
    setShowMemberSelect(true);
  }



  function onCloseMember(data) {
    if (data != null) {
      data = {
        ...data, patient_name:
          data.ttl + " " + data.name + " " + data.lname,
      };
      if (memberData == null) {
        setMemberData(data);
        props.onSelectMember(data);
      }
      else if (data.txn !== memberData.txn) {
        setMemberData(data);
        props.onSelectMember(data);
      }
    }


    setShowMemberSelect(false);
  }

  function filter() {
    const element = document.getElementById("txtSearch");

    const searchText = element.value;

    var dataFiltered = null;

    if (searchText != "") {
      dataFiltered = dataListOrg.filter(
        (data) => data.code.indexOf(searchText) !== -1
      );
    } else {
      dataFiltered = dataListOrg;
    }

    setDataList(dataFiltered);
  }

  function doSelect(code) {
    var dataSearch = dataListOrg.filter((data) => data.code == code);

    props.onSelect(dataSearch[0]);

    props.onClose();
    UIkit.modal("#modal-select-clinic").hide();
  }

  function onClose() {
    props.onClose();
  }

  function showPopup() {
    UIkit.modal("#modal-select-clinic").show();
  }

  useEffect(() => {
    if (typeof props.mode != "undefined") {
      if (props.mode == "view") {
        setHideSelectBtn(true);
        setDivStyle({ display: "flex", flexDirection: "row" });
      }

      setMode(props.mode);
    }
  }, []);

  useEffect(() => {
    if (props.formId) {
      setHideSelectBtn(true);
    } else {
      setHideSelectBtn(false);
    }
  }, [props.formId]);

  useEffect(() => {
    if (isSelectDisabled != undefined) {
      setHideSelectBtn(isSelectDisabled);
    }
  }, [isSelectDisabled])

  useEffect(() => {
    async function fetchData() {
      if (props.txn != null && props.txn != null) {
        onLoading ? onLoading(true) : null;
        var result = await Api.consult("MemberTxnDetail", {
          hn: props.hn,
          txn: props.txn,
        });

        var { data } = result;

        data.txn = props.txn;
        data.hn = props.hn;

        props.addValue({
          patient_name: (data.name =
            data.titleName + " " + data.firstName + " " + data.lastName),
          hn: props.hn,
          txn: props.txn,
        });

        // if (props.onSelectMember) {
        //   props.onSelectMember(data);
        // }

        props.setMemberData({
          ...data, patient_name:
            data.titleName + " " + data.firstName + " " + data.lastName,
          hn: props.hn,
          txn: props.txn,
          isSelectDisabled,
        });

        setMemberData(data);

        onLoading ? onLoading(false) : null;

        if (typeof props.onSelect != "undefined") {
          props.onSelect(data);
        }

        if (typeof props.onDataLoaded != "undefined") {
          props.onDataLoaded();
        }
      }
    }

    fetchData();
  }, [props.txn, props.hn, isSelectDisabled]);

  var paddingStyle = "0px 20px 0px 0px";

  const getBirthDate = useMemo(() => {
    if (memberData?.birthDate) {
      const year = memberData.birthDate.substring(0, 4);
      const month = memberData.birthDate.substring(5, 7);
      const date = memberData.birthDate.substring(8, 10);
      return date + "/" + month + "/" + (parseInt(year) + 543);
    }
    return "";
  }, [memberData]);

  const getGender = useMemo(() => {
    if (memberData?.sex) {
      if (memberData?.sex == "ช") {
        return "ชาย";
      }
      if (memberData?.sex == "ญ") {
        return "หญิง";
      }
    }
    return "";
  }, [memberData]);

  return (
    <div className="uk-width-1-1@m uk-width-1-1" key={"memberEdit"}>
      {memberData != null ? (
        <div style={divStyle} id="htmlMemberData">
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="ผู้ป่วย : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.name}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="HN : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.hn}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="TXN : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.txn}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="เพศ : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={getGender}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="อายุ : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={memberData.age}
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label="วันเกิด : "
            fontWeight="bold"
          />
          <FormElementLabel
            padding={paddingStyle}
            mode={mode}
            label={getBirthDate}
          />
          {!hideSelectBtn ? (
            <FormElementButton
              padding={paddingStyle}
              label="เลือกผู้ป่วย"
              onClick={doSelectMember}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div>
          <FormElementButton label="เลือกผู้ป่วย" onClick={doSelectMember} />
        </div>
      )}
      {createPortal(
        <MemberSelector show={showMemberSelect} onClose={onCloseMember} />,
        document.body
      )}
    </div>
  );
};

export default MemberProfileFormHeader;
