import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import ToolsTipPopUp from "../Utils/ToolTipsPopUp";
import SearchTeleSection from "../Utils/SearchTeleSection";
import fetchConsultFormListByHn from "../../API/fetchConsultFormListByHn.js";
import fetchConsultFormListBySearch from "../../API/fetchConsultFormListBySearch.js";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchNxOpdList from "../../API/fetchNxOpdList.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormBisphoPaper from "../FormPaper/FormBisphoPaper.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const CONSTANTS = {
  DATA_NAME: "data_form_list",
  QUESTIONS: [
    " 1. ผู้ป่วยทราบว่ายาที่ได้รับเป็นยารักษาโรคกระดูกพรุน",
    " 2. ผู้ป่วยทราบว่าควรทานยานี้เป็นสิ่งแรกในตอนเช้าขณะท้องว่าง ก่อนทานอาหารหรือเครื่องดื่มอื่น ๆ",
    " 3. ผู้ป่วยทราบว่าควรทานยานี้พร้อมน้ำเปล่า ประมาณ 180-240 มิลลิลิตร (ประมาณครึ่งถึงหนึ่งแก้ว)",
    " 4. ผู้ป่วยทราบว่าหลังทานยา ต้องอยู่ในท่าตั้งตรง (นั่งตัวตรงหรือยืนตัวตรง ห้ามก้ม เอนหรือนอน) ไม่น้อยกว่า 30 นาที",
    " 5. ผู้ป่วยทราบว่าไม่ควรทานอาหาร เครื่องดื่มและยาอื่น ๆ หลังทานยาเป็นเวลาอย่างน้อย 30 นาทีสำหรับยา Alendronate และ 60 นาที สำหรับยา Ibandronate",
    " 6. ผู้ป่วยทราบวิธีปฏิบัติตัวหากลืมทานยา",
    " 7. ผู้ป่วยทราบวิธีสังเกตตนเองหากมีอาการไม่พึงประสงค์ทางเดินอาหาร",
    " 8. ผู้ป่วยได้รับการตรวจรักษาทางทันตกรรมก่อนเข้าสู่กระบวนการรับยา bisphosphonate และทราบความเสี่ยงที่อาจเกิดขึ้นกับกระดูกขากรรไกรหลังรับยา",
  ],
  ADDITIONAL_LABELS: [
    "คะแนนรวม",
    "ผู้รับการประเมิน (ผู้ป่วย/ญาติ)",
    "เภสัชกรผู้ประเมิน",
  ],
};

const FormBispho = (props) => {
  const formConstants = {
    formName: "form_bispho",
    formType: "26",
  };
  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [isShowToolTipsPopup, setIsShowToolTipsPopup] = useState(false);
  var [toolTipId, setToolTipId] = useState(null);
  var [lastIndex, setLastIndex] = useState(0);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [dataFormOlds, setDataFormOlds] = useState([]);
  var [nxOpdDropDownList, setNxOpdDropDownList] = useState([]);
  var [isOnSearch, setIsOnSearch] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const prepareData = useCallback((form, patientResponse, oldDataResponse, profile) => {
    if (Array.isArray(oldDataResponse)) {
      if (oldDataResponse.length > 2) {
        const data = oldDataResponse.slice(-2);
        data.reverse();
        setDataFormOlds(data);
      } else if (oldDataResponse.length > 0 && oldDataResponse.length <= 2) {
        const data = oldDataResponse;
        data.reverse();
        setDataFormOlds(data);
      } else {
        setDataFormOlds([]);
      }
    }

    if (isNaN(form?.data_no)) {
      form.data_no = Array.isArray(oldDataResponse) ? oldDataResponse?.length + 1 : 1;
    }

    if (!form?.data_24) {
      form.data_24 = patientResponse.patient_name;
    }

    if (!form?.data_17) {
      form.data_17 = "1";
    }

    if (profile?.doctorName) {
      form.data_18 = profile.doctorName;
    }

    return form;
  }, []);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    try {
      displayLoading();
      const nxOpdListRequest = fetchNxOpdList();
      const oldDataRequest = fetchConsultFormListByHn(hn, txn, formConstants.formType);

      Promise.all([nxOpdListRequest, oldDataRequest]).then((values) => {
        const nxOpdListResponse = values[0];
        const oldDataResponse = values[1];

        data = prepareData({ ...data }, patient, oldDataResponse, profile);
        setNxOpdDropDownList(nxOpdListResponse);
        setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
        hideLoading();
        document.body.style.overflow = "hidden";
      }).catch((error) => {
        throw error;
      });
    } catch (error) {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    }
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const nxOpdListRequest = fetchNxOpdList();
    const oldDataRequest = fetchConsultFormListByHn(hn, txn, formConstants.formType);
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all([nxOpdListRequest, patientData, oldDataRequest, patientRoomRequest]).then((values) => {
      const nxOpdListResponse = values[0];
      const patientResponse = values[1];
      const oldDataResponse = values[2];
      const patientRoomResponse = values[3];

      data = prepareData({ ...data }, patientResponse, oldDataResponse, profile);
      setNxOpdDropDownList(nxOpdListResponse);
      setPatient({ ...patientResponse, ...patientRoomResponse });
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data])

  useEffect(() => {
    if (!isSubForm) {
      if (!formId && hn && txn) fetchData(null, hn, txn)
    }
  }, [hn, txn, formId, isSubForm]);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const openToolTipsPopUp = useCallback((id) => {
    setIsShowToolTipsPopup(true);
    setToolTipId(id);
  });

  const closeToolTipsPopUp = useCallback(() => {
    setIsShowToolTipsPopup(false);
    setToolTipId(null);
  });

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const getToolTipsContentBody = useMemo(() => {
    switch (toolTipId) {
      case 1:
        return <TooltipContent1 />;
      case 2:
        return <TooltipContent2 />;
      case 3:
        return <TooltipContent3 />;
      case 4:
        return <TooltipContent4 />;
      case 5:
        return <TooltipContent5 />;
      case 6:
        return <TooltipContent6 />;
      case 7:
        return <TooltipContent7 />;
      case 8:
        return <TooltipContent8 />;
      default:
        return null;
    }
  }, [isShowToolTipsPopup, toolTipId]);

  const renderToolTipsContent = useMemo(() => {
    return (
      <>
        <table
          className="uk-table uk-table-small uk-table-divider uk-table-striped"
          style={{ width: "400px" }}
        >
          <thead>
            <tr>
              <th className="center">แนวทางคำตอบ</th>
            </tr>
          </thead>
          <tbody>{getToolTipsContentBody}</tbody>
        </table>
      </>
    );
  }, [isShowToolTipsPopup, toolTipId]);

  const renderAssessmentFormSubHeader1 = useMemo(() => {
    return (
      <React.Fragment>
        <th rowSpan={3}>Checklist คำแนะนำของเภสัชกร</th>
        <th key={1} colSpan={3}>
          ประเมินครั้งที่ {dataForm.data_no}
        </th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment>
            <th rowSpan={3}>Checklist คำแนะนำของเภสัชกร</th>
            <th key={i} colSpan={3}>
              ประเมินครั้งที่ {item.data_no}
            </th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader2 = useMemo(() => {
    return (
      <React.Fragment>
        <th colSpan={2}>
          <FormElementTextBox
            fieldId="19"
            value={dataForm.data_19}
            type="date"
            onChange={handleChange}
            width="100"
          />
        </th>
        <th rowSpan={2}>หมายเหตุ</th>
        {dataFormOlds.map((data, i) => (
          <React.Fragment key={`header2_${i}`}>
            <th colSpan={2}>
              <FormElementTextBox
                value={data.data_19}
                type="date"
                width="100"
                disabled
              />
            </th>
            <th rowSpan={2}>หมายเหตุ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader3 = useMemo(() => {
    return (
      <React.Fragment key={1}>
        <th>ทราบ</th>
        <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment key={i}>
            <th>ทราบ</th>
            <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormHeader = useMemo(() => {
    return (
      <React.Fragment key={"header"}>
        <tr>
          <th rowSpan={3}>
            หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
          </th>
          {renderAssessmentFormSubHeader1}
        </tr>
        <tr>{renderAssessmentFormSubHeader2}</tr>
        <tr>{renderAssessmentFormSubHeader3}</tr>
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentQuestionForm = useMemo(() => {
    return CONSTANTS.QUESTIONS.map((questionLabel, questionIndex) => {
      questionIndex = ++questionIndex; // for supporting fleid data start with data_1.
      const textBox1Index = 24 + questionIndex;
      return (
        <tr key={`questions_content_${questionIndex}`}>
          <td className="td-form-label">
            <div className="question-label-container">
              <div className="question-label">{questionLabel}</div>
              <button
                className="tooltip-button"
                onClick={() => openToolTipsPopUp(questionIndex)}
              >
                <span
                  className="info-icon"
                  uk-icon="icon: info; ratio: 1.4"
                ></span>
              </button>
            </div>
          </td>
          <td>
            <FormElementTextBox
              fieldId={`${textBox1Index}`}
              onChange={handleChange}
              width="100"
              value={dataForm[`data_${textBox1Index}`]}
            />
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"1"}
              onChange={handleChange}
            />
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"0"}
              onChange={handleChange}
            />
          </td>
          <td className="td-remark">
            <FormElementTextArea
              fieldId={`${CONSTANTS.QUESTIONS.length + questionIndex}`}
              value={
                dataForm[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
              }
              onChange={handleChange}
              width="100"
              rows={4}
              cols={50}
              noLimit
            />
          </td>
          {dataFormOlds.map((data, dataIndex) => {
            return (
              <React.Fragment
                key={`questions_input_${dataIndex}_${questionIndex}`}
              >
                <td>{data[`data_${textBox1Index}`]}</td>
                <td>{data[`data_${questionIndex}n`] == "1" && "ทราบ"}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {data[`data_${questionIndex}n`] != "1" && "ไม่ทราบ"}
                </td>
                <td className="td-remark">
                  <FormElementTextArea
                    value={
                      data[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
                    }
                    width="100"
                    rows={4}
                    cols={50}
                    disabled
                  />
                </td>
              </React.Fragment>
            );
          })}
        </tr>
      );
    });
  }, [dataFormOlds, dataForm]);

  const getTotalScore = useCallback(
    (data) => {
      let rawScore = utilSumTotalScore([
        data.data_1n,
        data.data_2n,
        data.data_3n,
        data.data_4n,
        data.data_5n,
        data.data_6n,
        data.data_7n,
        data.data_8n,
      ]);

      return ((rawScore * 100) / CONSTANTS.QUESTIONS.length).toFixed(2);
    },
    [dataForm]
  );

  const getScoreDesc = useCallback(
    (data) => {
      if (!getTotalScore(data)) {
        return "";
      } else if (getTotalScore(data) >= 80) {
        return "ผ่านเกณฑ์";
      } else {
        return "ไม่ผ่านเกณฑ์";
      }
    },
    [getTotalScore]
  );

  const getFieldByAdditionalLabel = useCallback(
    (data, disabled, labelIndex) => {
      if (labelIndex === 0) {
        return (
          <td colSpan={4}>
            ({getTotalScore(data)}%) {getScoreDesc(data)}
          </td>
        );
      } else if (labelIndex === 1) {
        return (
          <td colSpan={4}>
            <FormElementTextBox
              fieldId={"24"}
              placeholder="ชื่อผู้รับการประเมิน"
              onChange={handleChange}
              width="100"
              value={data.data_24}
              disabled={disabled}
            />
            <FormElementDropdown
              fieldId={"17"}
              value={data.data_17}
              dataList={[
                { label: "เลือกผู้รับการประเมิน", value: "0" },
                { label: "ผู้ป่วย", value: "1" },
                { label: "ญาติ", value: "2" },
              ]}
              onChange={handleChange}
              disabled={disabled}
            />
          </td>
        );
      } else if (labelIndex === 2) {
        return <td colSpan={4}>{data.data_18}</td>;
      } else {
        return "";
      }
    },
    [dataForm]
  );

  const renderAssessmentAdditionalForm = useMemo(() => {
    return CONSTANTS.ADDITIONAL_LABELS.map((labelItem, labelIndex) => (
      <tr key={`additional_content_${labelIndex}`}>
        <td className="uk-text-right">{labelItem}</td>
        {getFieldByAdditionalLabel(dataForm, false, labelIndex)}
        {dataFormOlds.map((item) =>
          getFieldByAdditionalLabel(item, true, labelIndex)
        )}
      </tr>
    ));
  }, [dataForm, dataFormOlds]);

  const onSearch = useCallback((search) => {
    async function searchOldData(hn, type, search) {
      try {
        onLoading(true);
        const response = await fetchConsultFormListBySearch(hn, type, search);
        response.reverse();
        setDataFormOlds(response);
        onLoading(false);
      } catch (error) {
        onLoading(false);
      }
    }
    searchOldData(dataForm.hn, formConstants.formType, search);
  });

  const onClear = useCallback(() => {
    async function fetchOldData(hn, txn, type) {
      try {
        onLoading(true);
        const response = await fetchConsultFormListByHn(hn, txn, type);
        if (response.length > 2) {
          const data = response.slice(-2);
          data.reverse();
          setDataFormOlds(data);
        } else if (response.length > 0 && response.length <= 2) {
          const data = response;
          data.reverse();
          setDataFormOlds(data);
        } else {
          setDataFormOlds([]);
        }
        onLoading(false);
      } catch (error) {
        onLoading(false);
      }
    }
    fetchOldData(dataForm.hn, dataForm.txn, formConstants.formType);
  });

  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html)
  }, [dataForm, onAdd]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />
      }
      <div
        uk-grid=""
        className="uk-grid uk-padding-small"
        ref={parentTableContainer}
        style={{ overflowX: "hidden" }}
      >
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label=" 3.2.5 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยา bisphosphonate แบบรับประทาน " />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTextArea
            fieldId={"33"}
            onChange={handleChange}
            width="100"
            label="วิธีการให้คำแนะนำยา"
            cols={120}
            rows={5}
            value={dataForm.data_33}
            noLimit
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="ช่วงเวลาการประเมิน" />
          <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <SearchTeleSection onSearch={onSearch} onClear={onClear} />
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <div
            className="form-table-container"
            style={{ maxWidth: `${tableMaxWidth}px` }}
          >
            <div className="form-table-scroll">
              <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                <thead>{renderAssessmentFormHeader}</thead>
                <tbody>
                  {renderAssessmentQuestionForm}
                  {renderAssessmentAdditionalForm}
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementLabel
              label=" คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥7คะแนน) จึงถือว่า “ผ่านเกณฑ์”  
หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ "
            />
          </div>
          <div className="uk-width-1-1@m uk-width-1-1">
            <FormElementLabel label=" การนัดหมายครั้งถัดไป" />
            <FormElementDropdown
              fieldId={"21"}
              label="ห้องตรวจ"
              onChange={handleChange}
              dataList={[{ value: "", label: "เลือกห้องตรวจ" }].concat(
                nxOpdDropDownList
              )}
              width="100"
              value={dataForm.data_21}
            />
            <FormElementTextBox
              fieldId={"22"}
              type="date"
              onChange={handleChange}
              width="100"
              value={dataForm.data_22}
              minDate={dataForm.data_19}
            />
          </div>
        </div>
        <ToolsTipPopUp show={isShowToolTipsPopup} onClose={closeToolTipsPopUp}>
          {renderToolTipsContent}
        </ToolsTipPopUp>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <FormBisphoPaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
              nxOpdDropDownList={nxOpdDropDownList}
            />}
        />
      </div>
    </>

  );
};

export default FormBispho;

const TooltipContent1 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              1. ยา bisphosphonate เป็นยาที่มีข้อบ่งใช้ในการรักษาโรคกระดูกพรุน
              เพื่อป้องกันการเกิดกระดูกแตกหัก
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent2 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              2. เนื่องจากยาดูดซึมในทางเดินอาหารค่อนข้างน้อย หากทานในมื้ออื่น
              ยาอาจดูดซึมได้น้อยลงเนื่องจากถูกรบกวนจากอาหารในมื้อที่ทานไปก่อนหน้า
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent3 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              3. ควรรับประทานยาโดยกลืนทั้งเม็ดพร้อมน้ำเปล่าเท่านั้น
              เนื่องจากเครื่องดื่มอื่นๆ เช่น น้ำแร่ กาแฟ น้ำส้ม รบกวนการดูดซึมยา
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent4 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              4.
              การอยู่ในท่าตั้งตรงเป็นการป้องกันการไหลย้อนกลับของยามาที่หลอดอาหาร
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent5 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              5. เนื่องจากอาหารและเครื่องดื่มอื่นๆยกเว้นน้ำเปล่า ลดการดูดซึมยา
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent6 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              6. ผู้ป่วยสามารถอธิบายวิธีปฏิบัติตัวกรณีลืมทานยาได้
              เมื่อลืมมื้อหนึ่งไป ให้ข้ามมื้อนั้นไป
              และให้ทานในวันรุ่งขึ้นโดยไม่ต้องเพิ่มขนาดยาเป็น 2 เท่า
              และให้กลับมาทานในวันเดิมของสัปดาห์ถัดไปตามปกติ
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent7 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                7. มักพบอาการดังต่อไปนี้ ได้แก่ ปวดท้อง อาหารไม่ย่อย คลื่นไส้
              </article>
              <article>
                กรณีเกิดอาการ จุกเสียดบริเวณลิ้นปี่ เจ็บหรือกลืนอาหารลำบาก
                ปวดหน้าอก ครวรหยุดรับประทานยา และกลับมาพบแพทย์
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent8 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>8. ประเมินครั้งที่ 1</article>
              <article style={{ paddingLeft: "32px" }}>
                - ก่อนรับยา ผู้ป่วยต้องได้รับการตรวจฟันเนื่องจาก
                ยามีผลข้างเคียงต่อกระดูและฟัน
              </article>
              <article style={{ paddingLeft: "32px" }}>
                - อาการข้างเคียงที่รุนแรงคือ ภาวะกระดูกกรรไกรตาย (Osteonecrosis
                of jaw) โดยผู้ป่วยจะรู้สึกปวด, พบหนองเนื่องจากเกิดการติดเชื้อ,
                มีรูเปิดมองเห็นกระดูก, ฟันโยก
              </article>
              <article style={{ paddingLeft: "32px" }}>
                - แนะนำให้ผู้ป่วยตรวจฟันประจำปีอยู่เสมอ
              </article>
              <br />
              <article>ประเมินครั้งที่ 2</article>
              <article style={{ paddingLeft: "32px" }}>
                - เภสัชกรสอบถามอาการข้างไม่พึงประสงค์ทางทันตกรรม
              </article>
              <article style={{ paddingLeft: "32px" }}>
                - หากมีอาการรู้สึกปวด, พบหนอง, มีรูเปิดมองเห็นกระดูก, ฟันโยก
                แนะนำให้พบทันตแพทย์และแจ้งประวัติการรับประทานหรือฉีดยากลุ่ม
                Bisphosphonate
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};
