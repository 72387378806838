import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import Api from "../../Api";
import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import ToolsTipPopUp from "../Utils/ToolTipsPopUp";
import SearchTeleSection from "../Utils/SearchTeleSection";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilHandleValueChange = require("./FormUtil.js").handleValueChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const CONSTANTS = {
  DATA_NAME: "data_form_list",
  QUESTIONS: [
    " 1. ผู้ป่วยทราบข้อบ่งใช้ของยาฉีดอินซูลิน",
    " 2. ผู้ป่วยทราบชนิด ขนาดยาและวิธีการฉีดยาตามคำสั่งใช้ยาของแพทย์",
    " 3. ผู้ป่วยทราบวิธีปฏิบัติตัวเมื่อลืมฉีดยา",
    " 4. ผู้ป่วยทราบวิธีตรวจสอบความพร้อมใช้งานของอุปกรณ์ฉีดยาอินซูลินกรณีเปลี่ยนเข็มใหม่ หรือเปลี่ยนหลอดยาใหม่",
    " 5. ผู้ป่วยใช้ยาได้อย่างถูกต้องตามขั้นตอนการใช้อุปกรณ์นั้นๆ",
    " 6. ผู้ป่วยทราบวิธีการเก็บรักษายาฉีดและการทิ้งเข็มอย่างเหมาะสม",
    " 7. ผู้ป่วยทราบอาการไม่พึงประสงค์จากการใช้ยา และวิธีการจัดการเบื้องต้น",
    " 8. ผู้ป่วยทราบอาการที่ต้องมาพบแพทย์ก่อนนัด",
  ],
  ADDITIONAL_LABELS: [
    "คะแนนรวม",
    "ผู้รับการประเมิน (ผู้ป่วย/ญาติ)",
    "เภสัชกรผู้ประเมิน",
  ],
};

const FormInsurine = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [isShowToolTipsPopup, setIsShowToolTipsPopup] = useState(false);
  var [toolTipId, setToolTipId] = useState(null);
  var [lastIndex, setLastIndex] = useState(0);
  var parentTableContainer = useRef();
  var [tableMaxWidth, setTableMaxWidth] = useState(0);
  var [dataFormOlds, setDataFormOlds] = useState([]);
  var [nxOpdDropDownList, setNxOpdDropDownList] = useState([]);
  var [isOnSearch, setIsOnSearch] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_insulin",
      formType: "25",
    });
    getNxOPDList();
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  async function getNxOPDList() {
    try {
      var result = await Api.consult("GetNxOPDList", {});

      if (result.list.data) {
        const data = result.list.data;
        const dataArray = Object.keys(data);
        const list = dataArray.map((item) => ({
          value: item,
          label: data[item],
        }));

        setNxOpdDropDownList(list);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (props.dataFormOlds && props.dataFormOlds.length > 2) {
      const data = isOnSearch
        ? props.dataFormOlds
        : props.dataFormOlds.slice(-2);
      data.reverse();
      setDataFormOlds(data);
    } else if (props.dataFormOlds && props.dataFormOlds.length > 0) {
      const data = props.dataFormOlds;
      data.reverse();
      setDataFormOlds(data);
    } else {
      setDataFormOlds([]);
    }
  }, [props.dataFormOlds, isOnSearch]);

  useEffect(() => {
    setData("29", dataForm.patient_name);
  }, [dataForm.patient_name]);

  useEffect(() => {
    if (parentTableContainer.current) {
      setTableMaxWidth(parentTableContainer.current.offsetWidth - 64);
    }
  }, [parentTableContainer]);

  const openToolTipsPopUp = useCallback((id) => {
    setIsShowToolTipsPopup(true);
    setToolTipId(id);
  });

  const closeToolTipsPopUp = useCallback(() => {
    setIsShowToolTipsPopup(false);
    setToolTipId(null);
  });

  const getToolTipsContentBody = useMemo(() => {
    switch (toolTipId) {
      case 1:
        return <TooltipContent1 />;
      case 2:
        return <TooltipContent2 />;
      case 3:
        return <TooltipContent3 />;
      case 4:
        return <TooltipContent4 />;
      case 5:
        return <TooltipContent5 />;
      case 6:
        return <TooltipContent6 />;
      case 7:
        return <TooltipContent7 />;
      case 8:
        return <TooltipContent8 />;
      default:
        return null;
    }
  }, [isShowToolTipsPopup, toolTipId]);

  const renderToolTipsContent = useMemo(() => {
    return (
      <>
        <table
          className="uk-table uk-table-small uk-table-divider uk-table-striped"
          style={{ width: "500px" }}
        >
          <thead>
            <tr>
              <th className="center">แนวทางคำตอบ</th>
            </tr>
          </thead>
          <tbody>{getToolTipsContentBody}</tbody>
        </table>
      </>
    );
  }, [isShowToolTipsPopup, toolTipId]);

  const renderAssessmentFormSubHeader1 = useMemo(() => {
    return (
      <React.Fragment>
        <th key={1} colSpan={3}>
          ประเมินครั้งที่ {dataForm.data_no}
        </th>
        {dataFormOlds.map((item, i) => (
          <th key={i} colSpan={3}>
            ประเมินครั้งที่ {item.data_no}
          </th>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader2 = useMemo(() => {
    return (
      <React.Fragment key={1}>
        <th>
          <FormElementTextBox
            fieldId={"19"}
            value={dataForm.data_19}
            type="date"
            onChange={handleChange}
            width="100"
          />
        </th>
        <th>ติดตามครั้งถัดไป</th>
        <th rowSpan={2}>หมายเหตุ</th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment key={i}>
            <th>
              <FormElementTextBox
                value={item.data_19}
                type="date"
                width="100"
                disabled
              />
            </th>
            <th>ติดตามครั้งถัดไป</th>
            <th rowSpan={2}>หมายเหตุ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormSubHeader3 = useMemo(() => {
    return (
      <React.Fragment key={1}>
        <th>ทราบ</th>
        <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
        {dataFormOlds.map((item, i) => (
          <React.Fragment key={i}>
            <th>ทราบ</th>
            <th style={{ whiteSpace: "nowrap" }}>ไม่ทราบ</th>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentFormHeader = useMemo(() => {
    return (
      <React.Fragment key={"header"}>
        <tr>
          <th rowSpan={3}>
            หัวข้อการให้คำแนะนำของเภสัชกรและการประเมินความรู้ของผู้ป่วย
          </th>
          {renderAssessmentFormSubHeader1}
        </tr>
        <tr>{renderAssessmentFormSubHeader2}</tr>
        <tr>{renderAssessmentFormSubHeader3}</tr>
      </React.Fragment>
    );
  }, [dataFormOlds, dataForm]);

  const renderAssessmentQuestionForm = useMemo(() => {
    return CONSTANTS.QUESTIONS.map((questionLabel, questionIndex) => {
      questionIndex = ++questionIndex; // for supporting fleid data start with data_1.
      return (
        <tr key={`questions_content_${questionIndex}`}>
          <td className="td-form-label">
            <div className="question-label-container">
              <div className="question-label">{questionLabel}</div>
              <button
                className="tooltip-button"
                onClick={() => openToolTipsPopUp(questionIndex)}
              >
                <span
                  className="info-icon"
                  uk-icon="icon: info; ratio: 1.4"
                ></span>
              </button>
            </div>
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"1"}
              onChange={handleChange}
            />
          </td>
          <td className="td-radio-input">
            &nbsp;
            <FormElementRadio
              fieldId={`${questionIndex}n`}
              value={dataForm[`data_${questionIndex}n`]}
              fieldValue={"0"}
              onChange={handleChange}
            />
          </td>
          <td className="td-remark">
            <FormElementTextArea
              fieldId={`${CONSTANTS.QUESTIONS.length + questionIndex}`}
              value={
                dataForm[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
              }
              onChange={handleChange}
              width="100"
              rows={4}
              cols={50}
              noLimit
            />
          </td>
          {dataFormOlds.map((item, i) => (
            <React.Fragment>
              <td>{item[`data_${questionIndex}n`] == "1" && "ทราบ"}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                {item[`data_${questionIndex}n`] != "1" && "ไม่ทราบ"}
              </td>
              <td className="td-remark">
                <FormElementTextArea
                  value={
                    item[`data_${CONSTANTS.QUESTIONS.length + questionIndex}`]
                  }
                  width="100"
                  rows={4}
                  cols={50}
                  disabled
                />
              </td>
            </React.Fragment>
          ))}
        </tr>
      );
    });
  }, [dataFormOlds, dataForm]);

  const getTotalScore = useCallback(
    (data) => {
      let rawScore = utilSumTotalScore([
        data.data_1n,
        data.data_2n,
        data.data_3n,
        data.data_4n,
        data.data_5n,
        data.data_6n,
        data.data_7n,
        data.data_8n,
      ]);

      return ((rawScore * 100) / CONSTANTS.QUESTIONS.length).toFixed(2);
    },
    [dataForm]
  );

  const getScoreDesc = useCallback(
    (data) => {
      if (!getTotalScore(data)) {
        return "";
      } else if (getTotalScore(data) >= 80) {
        return "ผ่านเกณฑ์";
      } else {
        return "ไม่ผ่านเกณฑ์";
      }
    },
    [getTotalScore]
  );

  const getFieldByAdditionalLabel = useCallback(
    (data, disabled, labelIndex) => {
      if (labelIndex === 0) {
        return (
          <td colSpan={3}>
            ({getTotalScore(data)}%) {getScoreDesc(data)}
          </td>
        );
      } else if (labelIndex === 1) {
        return (
          <td colSpan={3}>
            <FormElementTextBox
              fieldId={"29"}
              placeholder="ชื่อผู้รับการประเมิน"
              onChange={handleChange}
              width="100"
              value={data[`data_29`]}
              disabled={disabled}
            />
            <FormElementDropdown
              fieldId={"17"}
              value={data[`data_17`]}
              dataList={[
                { label: "เลือกผู้รับการประเมิน", value: "0" },
                { label: "ผู้ป่วย", value: "1" },
                { label: "ญาติ", value: "2" },
              ]}
              onChange={handleChange}
              disabled={disabled}
            />
          </td>
        );
      } else if (labelIndex === 2) {
        return <td colSpan={3}>{data["data_18"]}</td>;
      } else {
        return "";
      }
    },
    [dataForm, lastIndex]
  );

  const renderAssessmentAdditionalForm = useMemo(() => {
    return CONSTANTS.ADDITIONAL_LABELS.map((labelItem, labelIndex) => (
      <tr key={`additional_content_${labelIndex}`}>
        <td className="uk-text-right">{labelItem}</td>
        {getFieldByAdditionalLabel(dataForm, false, labelIndex)}
        {dataFormOlds.map((item) =>
          getFieldByAdditionalLabel(item, true, labelIndex)
        )}
      </tr>
    ));
  }, [dataForm, dataFormOlds]);

  const onSearch = useCallback((search) => {
    setIsOnSearch(true);
    props.onSearch(search);
  });

  const onClear = useCallback(() => {
    setIsOnSearch(false);
    props.onClear();
  });

  return (
    <div
      uk-grid=""
      className="uk-grid uk-padding-small"
      ref={parentTableContainer}
      style={{ overflowX: "hidden" }}
    >
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" 3.2.4 แบบประเมินความรู้ของผู้ป่วยที่ได้รับยาฉีดอินซูลิน " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTextArea
          fieldId={"31"}
          onChange={handleChange}
          width="100"
          label="วิธีการให้คำแนะนำยา"
          cols={120}
          rows={5}
          value={dataForm.data_31}
          noLimit
        />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label="ช่วงเวลาการประเมิน" />
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <SearchTeleSection onSearch={onSearch} onClear={onClear} />
        </div>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementLabel label=" รูปแบบอินซูลิน " />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider">
          <tbody>
            <tr>
              <td>
                <FormElementCheckBox
                  fieldId={"20"}
                  value={dataForm.data_20}
                  onChange={handleChange}
                  label="Novopen"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_20
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"23"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_23
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_23", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"21"}
                  value={dataForm.data_21}
                  onChange={handleChange}
                  label="Pen"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_21
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"24"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_24
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_24", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"22"}
                  value={dataForm.data_22}
                  onChange={handleChange}
                  label="Vial"
                />
                {/* <span
                  className={
                    (typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                      ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_22
                      : "") == "1"
                      ? "form-show"
                      : "form-hidden"
                  }
                >
                  <FormElementTextBox
                    placeholder="ชื่อยา"
                    fieldId={"25"}
                    value={
                      typeof dataForm[CONSTANTS.DATA_NAME] != "undefined"
                        ? dataForm[CONSTANTS.DATA_NAME][lastIndex]?.data_25
                        : ""
                    }
                    onChange={(i, event) =>
                      handleValueChange("data_25", lastIndex, event)
                    }
                    width="100"
                  />
                </span> */}
              </td>
              <td>
                <FormElementCheckBox
                  fieldId={"30"}
                  value={dataForm.data_30}
                  onChange={handleChange}
                  label="Humapen"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <div
          className="form-table-container"
          style={{ maxWidth: `${tableMaxWidth}px` }}
        >
          <div className="form-table-scroll">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
              <thead>{renderAssessmentFormHeader}</thead>
              <tbody>
                {renderAssessmentQuestionForm}
                {renderAssessmentAdditionalForm}
              </tbody>
            </table>
          </div>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label=" การนัดหมายครั้งถัดไป" />
          <FormElementDropdown
            fieldId={"26"}
            label="ห้องตรวจ"
            onChange={handleChange}
            width="100"
            value={dataForm.data_26}
            dataList={[{ value: "", label: "เลือกห้องตรวจ" }].concat(
              nxOpdDropDownList
            )}
          />
          <FormElementTextBox
            fieldId={"27"}
            type="date"
            onChange={handleChange}
            width="100"
            value={dataForm.data_27}
            minDate={dataForm.data_19}
          />
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1"
          style={{ marginTop: "14px" }}
        >
          <article>
            <FormElementLabel label='คะแนนรวมควรมากกว่าหรือเท่ากับ 80% (≥ 7 คะแนน) จึงถือว่า "ผ่านเกณฑ์"' />
          </article>
          <article>
            <FormElementLabel label="หากไม่ถึงเกณฑ์ควรให้คำแนะนำและทำการประเมินซ้ำ" />
          </article>
        </div>
      </div>
      <ToolsTipPopUp show={isShowToolTipsPopup} onClose={closeToolTipsPopUp}>
        {renderToolTipsContent}
      </ToolsTipPopUp>
    </div>
  );
};

export default FormInsurine;

const TooltipContent1 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม : ผู้ป่วยทราบหรือไม่ว่ายานี้ใช้สำหรับรักษาโรคอะไร
                ออกฤทธิ์การรักษาอย่างไร
              </article>
              <article>
                คำตอบ : ผู้ป่วยสามารถระบุโรคที่ได้รับการวินิจฉัย
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>เบาหวาน</article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>ออกฤทธิ์ควบคุมระดับน้ำตาลในเลือด</article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent2 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม : ขนาดยาที่ได้รับวันนี้
                และวิธีการฉีดยาตามคำสั่งใช้ยาของแพทย์ เป็นอย่างไร
              </article>
              <article>
                คำตอบ : ผู้ป่วยสามารถตอบ ชื่อยา ขนาดยาแต่ละมื้อ
                และความถี่ในการฉีดต่อวันได้ตรงกับคำสั่งแพทย์
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent3 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>คำถาม : หากลืมฉีดยาต้องทำอย่างไร</article>
              <article>คำตอบ : แนวทางคำตอบขึ้นกับชนิดอินซูลิน</article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    ชนิดออกฤทธิ์เร็ว/สั้น:
                    ฉีดระหว่างทานอาหารหรือหลังอาหารทันทีได้
                    แต่ไม่ควรฉีดหากไม่ได้รับประทานอาหาร
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    ชนิดออกฤทธิ์ยาว: หากไม่เกิน 12 ชม. ฉีดทันทีที่นึกออก หากเกิน
                    12 ชม. ให้ข้ามมื้อที่ลืม ฉีดยามื้อถัดไปตามปกติ
                    และจดบันทึกการลืมฉีดยาไว้ให้แพทย์ทราบ
                  </article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent4 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม :
                ทราบวิธีตรวจสอบความพร้อมใช้งานของอุปกรณ์ฉีดยาอินซูลินกรณีเปลี่ยนเข็มใหม่
                หรือเปลี่ยนหลอดยาใหม่ หรือไม่
              </article>
              <article>
                คำตอบ : ผู้ป่วยตอบได้ครบถ้วนตามรายละเอียดใน checklists
                ของยานั้นๆ
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent5 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                ให้ผู้ป่วยปฏิบัติจริง
                สามารถปฏิบัติตามขั้นตอนการฉีดยาอินซูลินได้อย่างถูกต้องตาม
                <b>แบบประเมินทักษะ (checklists)</b>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent6 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>1. คำถาม : ผู้ป่วยจะเก็บรักษายาฉีดอย่างไร</article>
              <article>
                คำตอบ : ผู้ป่วยตอบได้ครบถ้วนตามรายละเอียดใน checklists
                ของยานั้นๆ ดังต่อไปนี้
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>การเก็บรักษายาที่ยังไม่ได้เปิดใช้</article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>การเก็บรักษาและอายุยาหลังเปิดใช้</article>
                </div>
              </article>
              <article>2. คำถาม : ผู้ป่วยมีวิธีการทิ้งเข็มอย่างไร</article>
              <article>
                คำตอบ : นำใส่ภาชนะแข็ง มีฝาปิด (ระบุบนบรรจุภัณฑ์ว่า
                “ระวังของมีคม”) แยกจากการทิ้งขยะของบ้านหรือทิ้งในถังขยะติดเชื้อ
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent7 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม :
                ผู้ป่วยทราบอาการไม่พึงประสงค์และวิธีปฏิบัติตัวเมื่อเกิดอาการไม่พึงประสงค์หรือไม่
                อย่างไร
              </article>
              <article>คำตอบ : ผู้ป่วยสามารถตอบได้ว่า</article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    อาการไม่พึงประสงค์ที่อาจเกิดขึ้นได้แก่ภาวะน้ำตาลต่ำ
                    (หรือตอบอาการ เช่น ใจสั่น ใจไม่ดี ใจหวิว เหงื่อออก ตัวเย็น
                    มือสั่น ชาที่ปาก เดินเซ มองภาพไม่ชัด)
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    หากเกิดอาการจะต้องอมลูกอมหรือดื่มน้ำหวาน/น้ำผลไม้
                  </article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

const TooltipContent8 = (props) => {
  return (
    <>
      <tr>
        <td>
          <div className="uk-width-1-1@m uk-width-1-1">
            <span className="tooltip-content-label">
              <article>
                คำถาม : อาการใดที่หากเกิดขึ้นแล้วต้องไปพบแพทย์รพ.ใกล้บ้าน
                ก่อนนัด
              </article>
              <article>คำตอบ : ผู้ป่วยสามารถบอกอาการเหล่านี้ได้</article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    แพ้ยา; ผื่นขึ้น หน้าบวม ปากบวม หรือหายใจลำบาก
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    เกิดภาวะน้ำตาลต่ำบ่อยครั้ง อมลูกอมหรือดื่มน้ำหวาน/น้ำผลไม้
                    แล้วไม่รู้สึกดีขึ้น
                  </article>
                </div>
              </article>
              <article style={{ paddingLeft: "14px" }}>
                <div style={{ display: "inline-flex", gap: "6px" }}>
                  <article>-</article>
                  <article>
                    ภาวะน้ำตาลในเลือดสูง (ขนาดยาไม่เพียงพอ): ปัสสาวะบ่อย คอแห้ง
                    กระหายน้ำ หิวบ่อย ปวดศีรษะ คลื่นไส้ มึนงง เป็นลม
                  </article>
                </div>
              </article>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};
