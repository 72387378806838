import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

const FormPostFallingPaper = (props) => {
  const { dataForm, patient, profile } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="Post-falling assessment" mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <FormElementTitle
          label="POST-FALL ASSESSMENT (FAMILY MEDICINE, CHIANG MAI UNIVERSITY)"
          mode="view"
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <FormElementLabel
            value={dataForm.data_1}
            translate={{
              1: "On admission",
              2: "Home visit",
              3: "Virtual home visit",
            }}
            mode="view"
          />
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <span>เป็นการประเมิน</span>
            <FormElementLabel
              value={dataForm.data_2}
              translate={{
                1: "ครั้งแรก",
                2: "ติดตาม",
              }}
              mode="view"
            />
            <FormElementTextBox
              value={dataForm.data_3}
              label="ที่"
              mode="view"
            />
            <span>เดือนหลังล้ม</span>
          </div>
        </div>
        <table style={{ borderCollapse: "collapse", border: "1px solid grey" }}>
          <tbody>
            <tr>
              <td
                colSpan={2}
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <div style={{ display: "inline-flex", gap: "8px" }}>
                  <span>
                    ส่วนนี้ประเมินก่อนออกจากโรงพยาบาลและติดตามผู้ป่วย วันที่
                  </span>
                  <FormElementTextBox
                    value={dataForm.data_4}
                    type="date"
                    mode="view"
                    dateFormat={"dd/MM/yyyy"}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Physical Health
              </td>
              <td
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Review Underlying Diseases (ดูจากใบหลัก)
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div style={{ display: "inline-flex", gap: "8px" }}>
                  <span>Pain score: average</span>
                  <FormElementTextBox value={dataForm.data_5} mode="view" />
                  <span>/10</span>
                  <span>ambulation</span>
                  <FormElementTextBox value={dataForm.data_6} mode="view" />
                  <span>/10</span>
                </div>
              </td>
              <td
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <div style={{ display: "inline-flex", gap: "8px" }}>
                  <span>Medication (ดูใบหลัก) ทานยาทั้งหมด</span>
                  <FormElementTextBox value={dataForm.data_7} mode="view" />
                  <span>ชนิด</span>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>Complain ปัญหาการมองเห็น</span>
                    <FormElementLabel
                      value={dataForm.data_8}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span hidden={dataForm.data_8 != "1"}>
                      <FormElementLabel label={dataForm.data_9} mode="view" />
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ปัญหาการได้ยิน</span>
                    <FormElementLabel
                      value={dataForm.data_10}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span hidden={dataForm.data_10 != "1"}>
                      <FormElementLabel label={dataForm.data_11} mode="view" />
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ปัญหาการนอนหลับ</span>
                    <FormElementLabel
                      value={dataForm.data_12}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span hidden={dataForm.data_12 != "1"}>
                      <FormElementLabel label={dataForm.data_13} mode="view" />
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>น้ำหนัก ระบุ</span>
                    <FormElementLabel label={dataForm.data_14} mode="view" />
                    <span>kg</span>
                    <FormElementLabel
                      value={dataForm.data_15}
                      mode="view"
                      translate={{ 0: "ไม่เปลี่ยนแปลง", 1: "เพิ่ม", 2: "ลด" }}
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>รู้สึกไม่มั่นคงในการเดิน</span>
                    <FormElementLabel
                      value={dataForm.data_16}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span hidden={dataForm.data_16 != "1"}>
                      <FormElementLabel label={dataForm.data_17} mode="view" />
                    </span>
                  </div>
                </div>
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <span>มียากลุ่มเหล่านี้หรือไม่</span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_18}
                        mode="view"
                        translate={{ 1: "ไม่มี" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_19}
                        mode="view"
                        translate={{ 1: "Diuretics" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_20}
                        mode="view"
                        translate={{ 1: "Anti-hypertensive" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_21}
                        mode="view"
                        translate={{ 1: "Anti-depressants" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_22}
                        mode="view"
                        translate={{ 1: "Sedative/hypnotics" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_23}
                        mode="view"
                        translate={{ 1: "Anti-histamine" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_24}
                        mode="view"
                        translate={{ 1: "Anti-cholinergics" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_25}
                        mode="view"
                        translate={{ 1: "Anti-psychotics" }}
                      />
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormElementLabel
                        value={dataForm.data_26}
                        mode="view"
                        translate={{ 1: "Anti-convulsants" }}
                      />
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ประเมินการกินยา</span>
                    <FormElementLabel
                      value={dataForm.data_27}
                      mode="view"
                      translate={{ 1: "good adherence", 2: "poor compliance" }}
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ปัญหาในการทานยา</span>
                    <FormElementLabel
                      value={dataForm.data_28}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span hidden={dataForm.data_28 != "1"}>
                      <FormElementLabel label={dataForm.data_29} mode="view" />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <span>Gait aid</span>
                  <div style={{ display: "inline-flex", gap: "4px" }}>
                    <FormElementLabel
                      value={dataForm.data_30}
                      mode="view"
                      translate={{
                        1: "none",
                        2: "ไม้เท้า",
                        3: "walker",
                        4: "อื่นๆ",
                      }}
                    />
                    <span hidden={dataForm.data_30 != "2"}>
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "4px",
                          paddingLeft: "14px",
                        }}
                      >
                        <FormElementLabel
                          label={dataForm.data_31}
                          mode="view"
                        />
                        <span>ขา</span>
                      </div>
                    </span>
                    <span hidden={dataForm.data_30 != "4"}>
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "4px",
                          paddingLeft: "14px",
                        }}
                        hidden={dataForm.data_30 != "4"}
                      >
                        <FormElementLabel
                          label={dataForm.data_32}
                          mode="view"
                        />
                      </div>
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>การล้มซ้ำ</span>
                    <FormElementLabel
                      value={dataForm.data_33}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span>ครั้งล่าสุดนับจากล้มครั้งก่อน</span>
                    <FormElementLabel label={dataForm.data_34} mode="view" />
                    <span>เดือน</span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>Complication</span>
                    <FormElementLabel
                      value={dataForm.data_35}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span hidden={dataForm.data_35 != "1"}>
                      <FormElementLabel label={dataForm.data_36} mode="view" />
                    </span>
                  </div>
                </div>
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ปัจจุบัน ดื่มสุรา</span>
                    <FormElementLabel
                      value={dataForm.data_37}
                      mode="view"
                      translate={{ 0: "ไม่ดื่ม", 1: "ดื่ม" }}
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ADLs</span>
                    <FormElementTextBox
                      value={dataForm.data_38n?.toString()}
                      mode="view"
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>สูบบุหรี่</span>
                    <FormElementLabel
                      value={dataForm.data_39}
                      mode="view"
                      translate={{ 0: "ไม่สูบ", 1: "สูบ" }}
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>IADLs</span>
                    <FormElementTextBox
                      value={dataForm.data_40n?.toString()}
                      mode="view"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Mental Health
              </td>
              <td
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Caregiver Burden And Family Issue
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>Cognitive problem</span>
                    <FormElementLabel
                      value={dataForm.data_41}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                    <span hidden={dataForm.data_41 != "1"}>
                      <FormElementLabel label={dataForm.data_42} mode="view" />
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>Depressed mood</span>
                    <FormElementLabel
                      value={dataForm.data_43}
                      mode="view"
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>Fear of falling กลัวล้มในอีก 1 ปีไหม</span>
                    <FormElementLabel
                      value={dataForm.data_44}
                      mode="view"
                      translate={{ 0: "ไม่กลัว", 1: "กลัว" }}
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>คะแนนแบบประเมิน Fear of falling</span>
                    <FormElementLabel label={dataForm.data_45n} mode="view" />
                    <span>(ที่ 2 สัปดาห์ และ 2 เดือน)</span>
                  </div>
                </div>
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_46}
                    mode="view"
                    translate={{ 0: "ไม่มี", 1: "มี" }}
                  />
                  <FormElementTextArea
                    label="ระบุรายละเอียด"
                    value={dataForm.data_47}
                    mode="view"
                  />
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>คะแนนแบบประเมิน Zarit-12</span>
                    <FormElementLabel label={dataForm.data_48n} mode="view" />
                    <span>(ที่ 2 สัปดาห์ และ 2 เดือน)</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Environmental Hazards (ขอภาพจากญาติ โดยเฉพาะจุดที่ล้ม)
              </td>
              <td
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ประเมินการดูแลตนเอง + Teach Back
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_49}
                    translate={{ 1: "แสงไฟไม่เพียงพอ" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_50}
                    translate={{ 1: "พื้นลื่น " }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_51}
                    translate={{ 1: "ของวางเกะกะ " }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_52}
                    translate={{ 1: "พรมเช็ดเท้า" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_53}
                    translate={{ 1: "ไม่มีราวจับห้องน้ำ" }}
                    mode="view"
                  />
                  <FormElementLabel
                    value={dataForm.data_54}
                    translate={{ 1: "บันไดเสี่ยงต่อการหกล้ม" }}
                    mode="view"
                  />
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      value={dataForm.data_55}
                      translate={{ 1: "พื้นที่ภายนอกบ้านไม่เหมาะสม" }}
                      mode="view"
                    />
                    <span hidden={dataForm.data_55 != "1"}>
                      <FormElementLabel label={dataForm.data_57} mode="view" />
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementLabel
                      value={dataForm.data_56}
                      translate={{ 1: "อื่นๆ" }}
                      mode="view"
                    />
                    <span hidden={dataForm.data_56 != "1"}>
                      <FormElementLabel label={dataForm.data_58} mode="view" />
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ได้ทำการปรับปรุงบ้านแล้ว</span>
                    <FormElementLabel
                      value={dataForm.data_59}
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                      mode="view"
                    />
                  </div>
                  <span hidden={dataForm.data_59 != "1"}>
                    <FormElementTextArea
                      label="โดยวิธี"
                      value={dataForm.data_60}
                      mode="view"
                    />
                  </span>
                </div>
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>Clip VDO การดูแลตนเองในผู้ป่วย</span>
                    <FormElementLabel
                      value={dataForm.data_61}
                      translate={{ 1: "ได้ดู", 0: "ยังไม่ได้ดู" }}
                      mode="view"
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>เข้าใจความสำคัญของการป้องกันความเสี่ยงในการล้ม</span>
                    <FormElementLabel
                      value={dataForm.data_62}
                      translate={{ 1: "ใช่", 0: "ไม่ใช่" }}
                      mode="view"
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>วางแผนแก้ไขปัญหาที่ทำให้เกิดความเสี่ยงล้ม</span>
                    <FormElementLabel
                      value={dataForm.data_63}
                      translate={{ 1: "ใช่", 0: "ไม่ใช่" }}
                      mode="view"
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>การฝึกกายภาพ</span>
                    <FormElementLabel
                      value={dataForm.data_64}
                      translate={{ 1: "ได้ทำ", 0: "ไม่ได้ทำ" }}
                      mode="view"
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>เลือกการทานอาหารอย่างเหมาะสม</span>
                    <FormElementLabel
                      value={dataForm.data_65}
                      translate={{ 1: "ตอบได้", 0: "ตอบไม่ได้" }}
                      mode="view"
                    />
                  </div>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ความกังวลและความเครียด</span>
                    <FormElementLabel
                      value={dataForm.data_66}
                      translate={{ 0: "ไม่มี", 1: "มี" }}
                      mode="view"
                    />
                  </div>
                  <span hidden={dataForm.data_66 != "1"}>
                    <FormElementTextArea
                      label="ระบุ"
                      value={dataForm.data_67}
                      mode="view"
                    />
                  </span>
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <span>ความมั่นใจในการดูแลตนเองเมื่อกลับไปบ้าน</span>
                    <FormElementLabel label={dataForm.data_68n} mode="view" />
                  </div>
                  <FormElementTextArea
                    label="สาเหตุที่ไม่มั่นใจ"
                    value={dataForm.data_69}
                    mode="view"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div style={{ display: "inline-flex", gap: "8px" }}>
                  <span>
                    หากเป็นการติดตาม
                    ประเด็นที่เป็นปัญหาเดิมได้รับการแก้ไขแล้วหรือไม่
                  </span>
                  <FormElementLabel
                    value={dataForm.data_70}
                    mode="view"
                    translate={{
                      1: "แก้ไขเสร็จแล้ว",
                      2: "แก้ไขบางส่วน",
                      3: "ยังไม่ได้แก้ไขเลย",
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                หมายเหตุ: การวางแผนการดูแลให้เขียนในใบหลัก
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <FormElementTextBox
                  label="ชื่อผู้ประเมิน"
                  value={dataForm.data_71}
                  mode="view"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <SavingProfileIPaper profile={profile} />
      </div>
      {/* <div uk-grid="" className="uk-grid uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="Post-falling assessment " />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="POST-FALL ASSESSMENT (FAMILY MEDICINE, CHIANG MAI UNIVERSITY)" />
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <span className="formTextBoxLabel">เป็นการประเมิน</span>
            <FormElementRadio
              value={dataForm.data_30}
              fieldValue={"1"}
              onChange={handleChange}
              label=" ครั้งแรก "
            />
            <FormElementRadio
              value={dataForm.data_30}
              fieldValue={"2"}
              onChange={handleChange}
              label=" ติดตาม "
            />
            <FormElementTextBox
              value={dataForm.data_1}
              label="ที่"
              onChange={handleChange}
              width="100"
            />
            <span className="formTextBoxLabel">เดือนหลังล้ม</span>
          </div>
          <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
            <table className="uk-table uk-table-small uk-table-divider form-table">
              <tbody>
                <tr>
                  <th colSpan={3}>
                    <span className="formTextBoxLabel">
                      ส่วนนี้ประเมินก่อนออกจากโรงพยาบาลและติดตามผู้ป่วย
                    </span>
                    <FormElementTextBox
                      value={dataForm.data_2}
                      type="date"
                      label="วันที่"
                      onChange={handleChange}
                      width="100"
                    />
                  </th>
                </tr>
                <tr>
                  <th>
                    <span className="formTextBoxLabel"> Physical health</span>
                  </th>
                  <th colSpan={2}>
                    <span className="formTextBoxLabel">
                      Review underlying diseases (ดูจากใบหลัก)
                    </span>
                  </th>
                </tr>
                <tr>
                  <td>
                    <FormElementTextBox
                      value={dataForm.data_4}
                      label="Pain score: average"
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">/10</span>
                    <FormElementTextBox
                      value={dataForm.data_5}
                      label="ambulation"
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">/10</span>
                  </td>
                  <th colSpan={2}>
                    <FormElementTextBox
                      value={dataForm.data_15}
                      label="Medication (ดูใบหลัก) ทานยาทั้งหมด"
                      onChange={handleChange}
                      width="100"
                    />
                    <span className="formTextBoxLabel">ชนิด</span>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        Complain ปัญหาการมองเห็น
                      </span>
                      <FormElementRadio
                        value={dataForm.data_31}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_31}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                      <FormElementTextBox
                        value={dataForm.data_6}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">ปัญหาการได้ยิน</span>
                      <FormElementRadio
                        value={dataForm.data_32}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_32}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                      <FormElementTextBox
                        value={dataForm.data_7}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">ปัญหาการนอนหลับ</span>
                      <FormElementRadio
                        value={dataForm.data_33}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_33}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                      <FormElementTextBox
                        value={dataForm.data_8}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">น้ำหนัก</span>
                      <FormElementTextBox
                        value={dataForm.data_9}
                        label="ระบุ"
                        onChange={handleChange}
                        width="100"
                      />
                      <span className="formTextBoxLabel">kg</span>
                      <FormElementRadio
                        value={dataForm.data_34}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่เปลี่ยนแปลง "
                      />
                      <FormElementRadio
                        value={dataForm.data_34}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" เพิ่ม "
                      />
                      <FormElementRadio
                        value={dataForm.data_34}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ลด "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        รู้สึกไม่มั่นคงในการเดิน
                      </span>
                      <FormElementRadio
                        value={dataForm.data_35}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_35}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                      <FormElementTextBox
                        value={dataForm.data_10}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        มียากลุ่มเหล่านี้หรือไม่
                      </span>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label=" Diuretics  "
                        />
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"3"}
                          onChange={handleChange}
                          label=" Anti-hypertensive  "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"4"}
                          onChange={handleChange}
                          label=" Anti-depressants  "
                        />
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"5"}
                          onChange={handleChange}
                          label=" Sedative/hypnotics  "
                        />
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"6"}
                          onChange={handleChange}
                          label=" Anti-histamine  "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"7"}
                          onChange={handleChange}
                          label=" Anti-cholinergics "
                        />
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"8"}
                          onChange={handleChange}
                          label=" Anti-psychotics  "
                        />
                        <FormElementRadio
                          value={dataForm.data_39}
                          fieldValue={"9"}
                          onChange={handleChange}
                          label=" Anti-convulsants "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small  uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          ประเมินการกินยา
                        </span>
                        <FormElementRadio
                          value={dataForm.data_40}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" good adherence "
                        />
                        <FormElementRadio
                          value={dataForm.data_40}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label=" poor compliance "
                        />
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small  uk-padding-remove-left uk-padding-remove-bottom">
                        <span className="formTextBoxLabel">
                          ปัญหาในการทานยา
                        </span>
                        <FormElementRadio
                          value={dataForm.data_41}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" ไม่มี "
                        />
                        <FormElementRadio
                          value={dataForm.data_41}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" มี "
                        />
                        <FormElementTextBox
                          value={dataForm.data_16}
                          label=""
                          onChange={handleChange}
                          width="100"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormElementLine />
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                      <span className="formTextBoxLabel">Gait aid</span>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementRadio
                          value={dataForm.data_36}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" none "
                        />
                        <FormElementTextBox
                          value={dataForm.data_11}
                          label="ไม้เท้า"
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">ขา</span>
                      </div>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementRadio
                          value={dataForm.data_36}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label=" walker "
                        />
                        <FormElementTextBox
                          value={dataForm.data_12}
                          label="อื่นๆ"
                          onChange={handleChange}
                          width="100"
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">การล้มซ้ำ</span>
                      <FormElementRadio
                        value={dataForm.data_37}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_37}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                      <FormElementTextBox
                        value={dataForm.data_13}
                        label="ครั้งล่าสุดนับจากล้มครั้งก่อน"
                        onChange={handleChange}
                        width="100"
                      />
                      <span className="formTextBoxLabel">เดือน</span>
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">Complication</span>
                      <FormElementRadio
                        value={dataForm.data_38}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_38}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                      <FormElementTextBox
                        value={dataForm.data_14}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                  </td>
                  <td>
                    <FormElementLine />
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                      <span className="formTextBoxLabel">
                        ปัจจุบัน ดื่มสุรา
                      </span>
                      <FormElementRadio
                        value={dataForm.data_42}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่ดื่ม "
                      />
                      <FormElementRadio
                        value={dataForm.data_42}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ดื่ม "
                      />
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom ">
                        <FormElementTextBox
                          value={dataForm.data_17}
                          label="ADLs"
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">/20</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <FormElementLine />
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                      <span className="formTextBoxLabel">สูบบุหรี่</span>
                      <FormElementRadio
                        value={dataForm.data_43}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่สูบ "
                      />
                      <FormElementRadio
                        value={dataForm.data_43}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" สูบ "
                      />
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom ">
                        <FormElementTextBox
                          value={dataForm.data_18}
                          label="IADLs"
                          onChange={handleChange}
                          width="100"
                        />
                        <span className="formTextBoxLabel">/9</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="formTextBoxLabel">Mental health</span>
                  </th>
                  <th colSpan={2}>
                    <span className="formTextBoxLabel">
                      Caregiver burden and family issue
                    </span>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                      <span className="formTextBoxLabel">
                        Cognitive problem
                      </span>
                      <FormElementRadio
                        value={dataForm.data_44}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_44}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                      <FormElementTextBox
                        value={dataForm.data_19}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">Depressed mood</span>
                      <FormElementRadio
                        value={dataForm.data_45}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่ดื่ม "
                      />
                      <FormElementRadio
                        value={dataForm.data_45}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ดื่ม "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        Fear of falling กลัวล้มในอีก 1 ปีไหม
                      </span>
                      <FormElementRadio
                        value={dataForm.data_46}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่กลัว "
                      />
                      <FormElementRadio
                        value={dataForm.data_46}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" กลัว "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">คะแนนแบบประเมิน</span>
                      <FormElementTextBox
                        value={dataForm.data_20}
                        label="Short FES-I "
                        onChange={handleChange}
                        width="100"
                      />
                      <span className="formTextBoxLabel">
                        (ที่ 2 สัปดาห์ และ 2 เดือน)
                      </span>
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                      <FormElementRadio
                        value={dataForm.data_49}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_49}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี  "
                      />
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <FormElementTextArea
                          rows={4}
                          cols={70}
                          value={dataForm.data_24}
                          label="ระบุรายละเอียด "
                          onChange={handleChange}
                          width="100"
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">คะแนนแบบประเมิน</span>
                      <FormElementTextBox
                        value={dataForm.data_25}
                        label="Zarit-12 "
                        onChange={handleChange}
                        width="100"
                      />
                      <span className="formTextBoxLabel">
                        (ที่ 2 สัปดาห์ และ 2 เดือน)
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="formTextBoxLabel">
                      Environmental hazards (ขอภาพจากญาติ โดยเฉพาะจุดที่ล้ม)
                    </span>
                  </th>
                  <th colSpan={2}>
                    <span className="formTextBoxLabel">
                      ประเมินการดูแลตนเอง + Teach back
                    </span>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" แสงไฟไม่เพียงพอ "
                      />
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" พื้นลื่น  "
                      />
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"3"}
                        onChange={handleChange}
                        label=" ของวางเกะกะ  "
                      />
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"4"}
                        onChange={handleChange}
                        label=" พรมเช็ดเท้า "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"5"}
                        onChange={handleChange}
                        label=" ไม่มีราวจับห้องน้ำ  "
                      />
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"6"}
                        onChange={handleChange}
                        label=" บันไดเสี่ยงต่อการหกล้ม "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"7"}
                        label="พื้นที่ภายนอกบ้านไม่เหมาะสม"
                        onChange={handleChange}
                      />
                      <FormElementTextBox
                        value={dataForm.data_21}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <FormElementRadio
                        value={dataForm.data_47}
                        fieldValue={"8"}
                        label="อื่นๆ"
                        onChange={handleChange}
                      />
                      <FormElementTextBox
                        value={dataForm.data_22}
                        label=""
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        ได้ทำการปรับปรุงบ้านแล้ว
                      </span>
                      <FormElementRadio
                        value={dataForm.data_48}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่ใช่ "
                      />
                      <FormElementRadio
                        value={dataForm.data_48}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ใช่ "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <FormElementTextArea
                        rows={5}
                        cols={70}
                        value={dataForm.data_23}
                        label="โดยวิธี"
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom  uk-padding-remove-top">
                      <span className="formTextBoxLabel">
                        Clip VDO การดูแลตนเองในผู้ป่วย hip fracture
                      </span>
                      <FormElementRadio
                        value={dataForm.data_50}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ได้ดู "
                      />
                      <FormElementRadio
                        value={dataForm.data_50}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ยังไม่ได้ดู "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        เข้าใจความสำคัญของการป้องกันความเสี่ยงในการล้ม
                      </span>
                      <FormElementRadio
                        value={dataForm.data_51}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ใช่ "
                      />
                      <FormElementRadio
                        value={dataForm.data_51}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่ใช่ "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        วางแผนแก้ไขปัญหาที่ทำให้เกิดความเสี่ยงล้ม
                      </span>
                      <FormElementRadio
                        value={dataForm.data_52}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ใช่ "
                      />
                      <FormElementRadio
                        value={dataForm.data_52}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่ใช่ "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">การฝึกกายภาพ</span>
                      <FormElementRadio
                        value={dataForm.data_53}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ได้ทำ "
                      />
                      <FormElementRadio
                        value={dataForm.data_53}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่ได้ทำ "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        เลือกการทานอาหารอย่างเหมาะสม
                      </span>
                      <FormElementRadio
                        value={dataForm.data_54}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" ตอบได้ "
                      />
                      <FormElementRadio
                        value={dataForm.data_54}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ตอบไม่ได้ "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        ความกังวลและความเครียด
                      </span>
                      <FormElementRadio
                        value={dataForm.data_55}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" ไม่มี "
                      />
                      <FormElementRadio
                        value={dataForm.data_55}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" มี "
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <FormElementTextArea
                        rows={3}
                        cols={70}
                        value={dataForm.data_26}
                        label="ระบุ "
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        ความมั่นใจในการดูแลตนเองเมื่อกลับไปบ้าน
                      </span>
                      <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"0"}
                          onChange={handleChange}
                          label=" 0 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"1"}
                          onChange={handleChange}
                          label=" 1 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"2"}
                          onChange={handleChange}
                          label=" 2 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"3"}
                          onChange={handleChange}
                          label=" 3 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"4"}
                          onChange={handleChange}
                          label=" 4 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"5"}
                          onChange={handleChange}
                          label=" 5 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"6"}
                          onChange={handleChange}
                          label=" 6 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"7"}
                          onChange={handleChange}
                          label=" 7 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"8"}
                          onChange={handleChange}
                          label=" 8 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"9"}
                          onChange={handleChange}
                          label=" 9 "
                        />
                        <FormElementRadio
                          value={dataForm.data_56}
                          fieldValue={"10"}
                          onChange={handleChange}
                          label=" 10 "
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                      <span className="formTextBoxLabel">
                        สาเหตุที่ไม่มั่นใจ
                      </span>
                      <FormElementTextArea
                        rows={2}
                        cols={60}
                        value={dataForm.data_27}
                        label=" "
                        onChange={handleChange}
                        width="100"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <FormElementLine />
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom uk-padding-remove-top">
                      <span className="formTextBoxLabel">
                        หากเป็นการติดตาม
                        ประเด็นที่เป็นปัญหาเดิมได้รับการแก้ไขแล้วหรือไม่
                      </span>
                      <FormElementRadio
                        value={dataForm.data_57}
                        fieldValue={"0"}
                        onChange={handleChange}
                        label=" แก้ไขเสร็จแล้ว "
                      />
                      <FormElementRadio
                        value={dataForm.data_57}
                        fieldValue={"1"}
                        onChange={handleChange}
                        label=" แก้ไขบางส่วน "
                      />
                      <FormElementRadio
                        value={dataForm.data_57}
                        fieldValue={"2"}
                        onChange={handleChange}
                        label=" ยังไม่ได้แก้ไขเลย "
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormElementLine />
                    <span className="formTextBoxLabel">
                      หมายเหตุ: การวางแผนการดูแลให้เขียนในใบหลัก
                    </span>
                  </td>
                  <td colSpan={2}>
                    <FormElementLine />
                    <FormElementTextBox
                      value={dataForm.data_28}
                      label="ชื่อผู้ประเมิน "
                      onChange={handleChange}
                      width="100"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default FormPostFallingPaper;
