import Api from "../Api";

async function fetchOPDCardByHn(hn, txn, roomCode) {
    try {
        var result = await Api.consult("GetOPDCardByHN", {
            hn, txn, roomCode
        });

        if (result.list) {
            var data = result.list.find((item) => item.txn == props.txn);
            if (data) {
                return data;
            }
        }
        return null;

    } catch (error) {
        console.error(`fetchOPDCardByHn hn(${hn}) txn(${txn}) roomCode(${roomCode})`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchOPDCardByHn;