import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from "../../Components/Api";
import Formular from "../Formular";
import moment from "moment";
import numeral from "numeral";
import $ from "jquery";
import FullScreenElement from "../FullScreenElement";
import ReactEcharts from "echarts-for-react";

UIkit.use(Icons);

export default class UserSmartHealthFbsGraph extends Component {
  state = {
    loadingApi: false,
    selectDateType: "Date",
    selectDateStart: moment()
      .startOf("month")
      .subtract(60, "day")
      .format("YYYY-MM-DD"),
    selectDateStop: moment().endOf("month").add(1, 'day').format("YYYY-MM-DD"),
    data: null,
    xGraph: [],
    dataGraph: [],
  };

  componentDidMount() {
    this.dataGet();
    this.datePickerGet();
  }

  dataRender = () => {
    var _range = [];
    switch (this.state.selectDateType) {
      case "Date":
        var _start = moment(this.state.selectDateStart, 'YYYY-MM-DD');
        var _stop = moment(this.state.selectDateStop, 'YYYY-MM-DD');

        while (_stop > _start || _start.format('YYYY-MM-DD') === _stop.format('YYYY-MM-DD')) {
          _range.push(_start.format('YYYY-MM-DD'));
          _start.add(1,'days');
       }
        break;
      case "Week":
        var _start = moment(this.state.selectDateStart, 'YYYY-MM-DD');
        var _stop = moment(this.state.selectDateStop, 'YYYY-MM-DD');

        while (_stop > _start || _start.format('YYYY-WW') === _stop.format('YYYY-WW')) {
          _range.push(_start.format('YYYY(WW)'));
          _start.add(1,'weeks');
       }
        break;
      case "Month":
        var _start = moment(this.state.selectDateStart, 'YYYY-MM-DD');
        var _stop = moment(this.state.selectDateStop, 'YYYY-MM-DD');

        while (_stop > _start || _start.format('YYYY-M') === _stop.format('YYYY-M')) {
          _range.push(_start.format('YYYY-MM'));
          _start.add(1,'months');
       }

        break;
      case "Year":
      default:
        var _start = +moment(this.state.selectDateStart, 'YYYY-MM-DD').format('YYYY');
        var _stop = +moment(this.state.selectDateStop, 'YYYY-MM-DD').format('YYYY');
        for(var i=_start; i<=_stop; i++) {
          _range.push(i);
        }
        break;
    }
    
    var _data = {};
    if(Array.isArray(this.state.data)) {
      this.state.data.forEach((d) => {
        var _date_add = moment(d.dateadd, 'YYYY-MM-DD HH:mm:ss');

        if( _date_add >= moment(this.state.selectDateStart, 'YYYY-MM-DD') && _date_add <= moment(this.state.selectDateStop, 'YYYY-MM-DD') ) {
 
          

          var _dateTypeValue = "";
          switch (this.state.selectDateType) {
            case "Date":
              _dateTypeValue = moment(d.dateadd).format("YYYY-MM-DD");
              break;
            case "Week":
              _dateTypeValue = moment(d.dateadd).format("YYYY(WW)");
              break;
            case "Month":
              _dateTypeValue = moment(d.dateadd).format("YYYY-MM");
              break;
            case "Year":
            default:
              _dateTypeValue = moment(d.dateadd).format("YYYY");
              break;
          }

          if(!_data[d.note]) _data[d.note] = {};

          if(!_data[d.note][_dateTypeValue]) _data[d.note][_dateTypeValue] = {value: +d.value,
          /*itemStyle: {color: +d.value > 180 ? 'blue' : +d.value < 70  ? 'red' : 'green'}*/};
  
        }

      })
      
    }

    var _dataGraph = [];
    
    //Add upper and lowerline
    [70,180].forEach((value) => {
      _dataGraph.push({
        type: 'line',
        colorBy: 'data',
        symbolSize: 0,
        lineStyle: {
          width: 1,
        },
        
        name: value === 80 ? "เส้นล่าง" : "เส้นบน",
        data: _range.map(()=>{
          return value;
        })
      })
    });


    for(var note in _data) {

      var _dataInLine = [];

      _range.forEach((day) => {
        if(_data[note][day] == null) _dataInLine.push(null);
        else _dataInLine.push(_data[note][day])
      });


      _dataGraph.push({
        type: 'line',
        colorBy: 'data',
        symbol: 'circle', 
        symbolSize: 8,
        lineStyle: {
          width: 1,
        },
        name: note.replace(",", ""),
        data: _dataInLine
      })


    }


    setTimeout(()=>{
      this.setState({loadingApi: false, xGraph: _range, dataGraph: _dataGraph});
    }, 100);


    return _data;
  };


  selectDateType = () => {
    var _type = ["Date", "Week", "Month", "Year"];

    return (
      <select
        className="uk-select uk-width-small@s uk-width-1-1"
        value={this.state.selectDateType}
        onChange={(e) => this.setState({ selectDateType: e.target.value }, ()=>this.dataRender())}
      >
        {_type.map((type) => {
          return <option value={type}>{type}</option>;
        })}
      </select>
    );
  };

  datePickerGet = () => {
    $("#datetimepicker_fbs_datestart")
      .on("dp.change", (e) => {
        this.setState({ selectDateStart: e.date.format("YYYY-MM-DD") }, ()=>this.dataGet());
      })
      .datetimepicker({
        format: "DD/MM/YYYY",
        defaultDate: this.state.selectDateStart,
      });

    $("#datetimepicker_fbs_datestop")
      .on("dp.change", (e) => {
        this.setState({ selectDateStop: e.date.format("YYYY-MM-DD") }, ()=>this.dataGet());
      })
      .datetimepicker({
        format: "DD/MM/YYYY",
        defaultDate: this.state.selectDateStop,
      });
  };


  dataGet = async () => {
    if(this.state.loadingApi !== false) return;

    this.setState({loadingApi: true});

    var _response = await Api[this.props.typeApi || 'member']("UserSmartHealthFbsGraphData", {memberId: this.props.memberId});

    this.setState({data: _response.data || []}, this.dataRender);
  };

  renderGraph = () => {
    var _dataName = [];
    this.state.dataGraph.forEach((data) => {
      //console.log(data)
      _dataName.push(data.name);
    });

    var option = {
      // Setup grid
      grid: {
        x: 40,
        x2: 20,
        y: 35,
        y2: 25,
      },

      // Add tooltip
      tooltip: {
        trigger: "axis",
      },

      // Add legend
      legend: {
        right: 10,
        height: 50,
        width: 200
      },
      

      // Add custom colors
      color: ["red", "blue", "#F98E76", "#16D39A", "#2DCEE3", "#FF7588", "#626E82"],
      // Enable drag recalculate
      calculable: true,

      // Hirozontal axis
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: this.state.xGraph,
          z: 10,
          axisLine: {
            lineStyle: {
              width: 2,
              color: "#3975AF",
            },
          },
          axisLabel: {
            color: "#000",
          },
        },
      ],

      // Vertical axis
      yAxis: [
        {
          name: "FBS/FPG\r\n(mg/dL)",
          type: "value",
          minInterval: 1,
          nameTextStyle: {
            fontSize: 10
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: "#3975AF",
            },
          },
          axisLabel: {
            color: "#000",
          },
        },
      ],

      // Add series
      series: this.state.dataGraph,
    };
    return (
      <ReactEcharts
        option={option}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: 500 }}
        theme={"theme_name"}
      />
    );
  };

  print = () => {
    window.print();
  }

  render() {
    return (
      <div
        className="uk-card uk-card-default uk-padding-small"
        id="d-smarth-health-fbs-graph"
      >
        <div className="d-card-header">
          <div className="d-card-header-left">FBS GRAPH</div>
          <div className="d-card-header-right">
            <span className="icon-reload" onClick={this.dataGet}></span>
            {this.props.fullscreen !== false && <FullScreenElement element="#d-smarth-health-fbs-graph" />}
          </div>
        </div>
        <div uk-grid="" className="uk-grid-small uk-margin-small-top">
          <div className="uk-width-1-1 uk-width-auto@m">
            <div className="uk-text-meta">รูปแบบ</div>
            {this.selectDateType()}
          </div>
          <div className="uk-width-1-1 uk-width-auto@m">
            <div className="uk-text-meta">ตั้งแต่วันที่</div>
            <div
              className="d-button-group input-group uk-button-group date uk-width-1-1"
              id="datetimepicker_fbs_datestart"
            >
              <input
                type="text"
                className="uk-input"
                placeholder="วันที่/เวลา"
              />
              <span
                className="uk-button input-group-addon"
                style={{ width: 45, marginLeft: 0 }}
              >
                <span className="glyphicon glyphicon-calendar"></span>
              </span>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-auto@m">
            <div className="uk-text-meta">ถึงวันที่</div>
            <div
              className="d-button-group input-group uk-button-group date uk-width-1-1"
              id="datetimepicker_fbs_datestop"
            >
              <input
                type="text"
                className="uk-input"
                placeholder="วันที่/เวลา"
              />
              <span
                className="uk-button input-group-addon"
                style={{ width: 45, marginLeft: 0 }}
              >
                <span className="glyphicon glyphicon-calendar"></span>
              </span>
            </div>
          </div>
          <div className="uk-width-expand uk-text-right">
            <br />
            <button className="uk-button uk-button-primary" onClick={this.print}><span uk-icon="print"></span> พิมพ์</button>
          </div>
        </div>
        <div className="uk-margin-small-top">
          {this.state.loadingApi === true ? (
            <div className="uk-padding uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            this.renderGraph()
          )}
        </div>
      </div>
    );
  }
}
