import React, { useState, useEffect, useCallback, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";

import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

const FormPPSPaper = (props) => {
  const { dataForm, patient, profile } = props;

  const getValue = useCallback((isValid, label) => {
    if (isValid) {
      return <u>{label}</u>;
    } else {
      return label;
    }
  });

  const getTotalScore = useMemo(() => {
    const { data_1n, data_2n, data_3n, data_4n, data_5n } = dataForm;
    const data = [
      data_1n || 0,
      data_2n || 0,
      data_3n || 0,
      data_4n || 0,
      data_5n || 0,
    ];
    return Math.min(...data);
  }, [dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินระดับผู้ป่วยที่ได้รับการดูแลแบบประคับประคอง ฉบับสวนดอก"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <FormElementTitle
          label="(Palliative performance scale for adult Suandok) (PPS Adult Suandok)"
          mode="view"
        />
        <table
          style={{
            marginTop: "14px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ระดับ PPS ร้อยละ
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                การเคลื่อนไหว
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                การปฏิบัติกิจกรรมและการดำเนินโรค
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                การทำกิจวัตรประจำวัน
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                การรับประทานอาหาร
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                ระดับความรู้สึกตัว
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 100, "100")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_1n == "100", "เคลื่อนไหวปกติ")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "100",
                  "ทำกิจกรรมและงานได้ตามปกติและไม่มีอาการของโรค"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_3n == "100", "ทำได้เอง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "100", "ปกติ")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_5n == "100", "รู้สึกตัวดี")}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 90, "90")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_1n == "90", "เคลื่อนไหวปกติ")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "90",
                  "ทำกิจกรรมและทำงานได้ตามปกติและมีอาการของโรคบางอาการ"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_3n == "90", "ทำได้เอง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "90", "ปกติ")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_5n == "90", "รู้สึกตัวดี")}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 80, "80")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_1n == "80", "เคลื่อนไหวปกติ")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "80",
                  "ต้องออกแรงอย่างมากในการทำกิจกรรมตามปกติและมีอาการของโรคบางอาการ"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_3n == "80", "ทำได้เอง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "80", "ปกติ หรือ ลดลง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_5n == "80", "รู้สึกตัวดี")}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 70, "70")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_1n == "70",
                  "ความสามารถในการเคลื่อนไหวลดลง"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "70",
                  "ไม่สามารถทำงานได้ตามปกติและมีอาการของโรคอย่างมาก"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_3n == "70", "ทำได้เอง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "70", "ปกติ หรือ ลดลง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_5n == "70", "รู้สึกตัวดี")}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 60, "60")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_1n == "60",
                  "ความสามารถในการเคลื่อนไหวลดลง"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "60",
                  "ไม่สามารถทำงานอดิเรกหรืองานบ้านได้และมีอาการของโรคอย่างมาก"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_3n == "60",
                  "ต้องการความช่วยเหลือเป็นบางครั้ง/บางเรื่อง"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "60", "ปกติ หรือ ลดลง/บางเรื่อง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_5n == "60", "รู้สึกตัวดี หรือ สับสน")}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 50, "50")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_1n == "50",
                  "นั่ง หรือ นอนเป็นส่วนใหญ่"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "50",
                  "ไม่สามารถทำงานได้เลยและมีการลุกลามของโรค"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_3n == "50",
                  "ต้องการความช่วยเหลือมากขึ้น"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "50", "ปกติ หรือ ลดลง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_5n == "50", "รู้สึกตัวดี หรือ สับสน")}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 40, "40")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_1n == "40",
                  "นอนอยู่บนเตียงเป็นส่วนใหญ่"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "40",
                  "ทำกิจกรรมได้น้อยมากและมีการลุกลามของโรค"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_3n == "40",
                  "ต้องการความช่วยเหลือเป็นส่วนใหญ่"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "40", "ปกติ หรือ ลดลง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_5n == "40",
                  "รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 30, "30")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_1n == "30", "นอนอยู่บนเตียงตลอดเวลา")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "30",
                  "ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_3n == "30",
                  "ต้องการความช่วยเหลือทั้งหมด"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "30", "ปกติ หรือ ลดลง")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_5n == "30",
                  "รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 20, "20")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_1n == "20", "นอนอยู่บนเตียงตลอดเวลา")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "20",
                  "ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_3n == "20",
                  "ต้องการความช่วยเหลือทั้งหมด"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "20", "จิบน้ำได้เล็กน้อย")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_5n == "20",
                  "รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 10, "10")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_1n == "10", "นอนอยู่บนเตียงตลอดเวลา")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_2n == "10",
                  "ไม่สามารถทำกิจกรรมใดๆ และมีการลุกลามของโรค"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_3n == "10",
                  "ต้องการความช่วยเหลือทั้งหมด"
                )}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_4n == "10", "รับประทานทางปากไม่ได้")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(
                  dataForm.data_5n == "10",
                  "รู้สึกตัวดี หรือ ง่วงซึม +/- สับสน"
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(getTotalScore == 0 && dataForm?.data_1n, "0")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {getValue(dataForm.data_1n == "0", "เสียชีวิต")}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              ></td>
            </tr>
          </tbody>
        </table>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormPPSPaper;
