import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import UploadFirebase from '../../Components/UploadFirebase'; 
import ReactPaginate from 'react-paginate';

class AdminHospitalAddForm extends Component {
  field = [
    { key: 'name', label: 'ชื่อโรงพยาบาล' },
    { key: 'code', label: 'รหัสโรงพยาบาล' }
  ]

  state = {
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    
    this.setState((state) => {
      var stateInit = {};
      stateInit.Hospitalgroup = [];

      if(this.props.Hospital) {
        this.field.map(data => { stateInit[data.key] = this.props.Hospital[data.key]; });
        stateInit.image = this.props.Hospital.image || '';
      } else {
        this.field.map(data => { stateInit[data.key] = ''; });
        stateInit.image = '';
      }
      
      return stateInit;
    });

    if (this.props.show && UIkit) {
      UIkit.modal('#modal-form-Hospital').show();
    } else {
      UIkit.modal('#modal-form-Hospital').hide();
    }

  }

  HospitalAdd = async (e) => {
    e.preventDefault();
    var data = this.state;
    if(this.props.Hospital) {
      data.id = this.props.Hospital.id;
    }

    var { result, responseText } = await Api.admin( this.props.Hospital ? 'HospitalUpdate' : 'HospitalAdd', data);

    UIkit.notification({ message: responseText, status: result ? 'success' : 'danger' })
    this.props.callback();
    UIkit.modal('#modal-form-Hospital').hide();
  }


  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return <div id="modal-form-Hospital" uk-modal="bg-close: false; esc-close: false; stack: true;">
      <div className="uk-modal-dialog uk-modal-body">
        <button className="uk-modal-close-default" type="button" uk-close=""></button>
        <h2 className="uk-modal-title">จัดการข้อมูลโรงพยาบาล</h2>
        <form onSubmit={this.HospitalAdd} className="uk-width-1-1">
          <div className="uk-margin-small-bottom">
            {this.state.image !== '' && <div className="uk-margin-small"><img src={this.state.image} uk-img="" width="200" /></div>}
          </div>
          {
            this.field.map(data => {
              return <div className="uk-margin-small-bottom">
                <label>{data.label}</label>
                <input type="text" className="uk-input" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
              </div>
            })
          }
          <button className="uk-input uk-button-primary uk-margin-small-top" type="submit">{this.props.Hospital ? 'บันทึก' : 'เพิ่ม' }</button>
        </form>
      </div>
    </div>
  }
}

export default class AdminHospital extends Component {

  state = {
    HospitalList: [],
    formShow: false,
    Hospital: false,
    pageCount: 0,
    pageNow: 1,
  }

  perPage = 100

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = async () => {
    this.HospitalListGet();
  }

  HospitalListGet = async () => {
    var { list, amount } = await Api.admin('HospitalList', {
      perPage : this.perPage,
      pageNow : this.state.pageNow
    });
    this.setState({ HospitalList: list || [], formShow: false, pageCount: +amount/this.perPage  }, ()=>this.setState({formShow: false}));
  }

  paginateRender = () => {
    return <div className="uk-text-right">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
  }

  render() {
    return <div>
      <div className="d-admin-header-row uk-text-right">
        <button className="uk-button uk-button-small uk-button-secondary" onClick={() => this.setState({ Hospital: false,formShow: true }, () => this.setState({ formShow: true }))} >เพิ่มโรงพยาบาล</button>
      </div>
      <AdminHospitalAddForm  {...this.props} callback={this.HospitalListGet} show={this.state.formShow} Hospital={this.state.Hospital} />

      {this.paginateRender()}
      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>รหัส id</th>
            <th>รหัสโรงพยาบาล</th>
            <th>ชื่อโรงพยาบาล</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.HospitalList.map((data, key) => {
              return <tr key={`Hospital${data.id}`}>
                <td>{key + 1}</td>
                <td>{data.id}</td>
                <td>{data.code}</td>
                <td>{data.name}</td>
                <td>
                  <button className="uk-button uk-button-small" onClick={() => this.setState({ Hospital: data, formShow: true }, () => this.setState({ formShow: true }))} >
                    <i uk-icon="icon: pencil"></i>
                  </button>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>

      {this.paginateRender()}
    </div>
  }
};
