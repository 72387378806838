import Api from "../Api";

async function fetchWardList() {
    try {
        var result = await Api.consult("GetWardList", {});
        var { data } = result;

        if (!result.list.data) return [];

        const { _value } = result.list.data;
        const list = _value.map((item) => ({
            value: item.code,
            label: item.name,
        }));

        return list;
    } catch (error) {
        console.error(`fetchWardList`, error);
        throw new Error(`Something went wrong.`);
    }
}
export default fetchWardList;