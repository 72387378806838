import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';
import fetchConsultFormByTxn from '../../API/fetchConsultFormByTxn.js';
import fetchConsultFormByFormId from '../../API/fetchConsultFormByFormId.js';
import fetchMemberByTxn from '../../API/fetchMemberByTxn.js';
import saveConsultForm from '../../API/saveConsultForm.js';
import FormMemberProfileHeader from '../Common/FormMemberProfiileHeader.js';
import FormActionButtonSection from '../Common/FormActionButtionSection.js';
import Form9QPaper from '../FormPaper/Form9QPaper.js';
import fetchMemberListHis from '../../API/fetchMemberListHis.js';

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const Form9Q = (props) => {
    const formConstants = {
        formName: "form_9Q",
        formType: "34"
    };
    const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    const [isPreview, setIsPreview] = useState(false);

    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    const handlePreviewOpenChange = useCallback((open) => {
        setIsPreview(open);
    }, [setIsPreview])

    const displayLoading = useCallback(() => {
        onLoading(true);
    }, [onLoading]);

    const hideLoading = useCallback(() => {
        onLoading(false);
    }, [onLoading]);

    const displayErrorFetchPopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
    }, [onErrorMessageChange]);

    const displayErrorSavePopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
    }, [onErrorMessageChange]);

    const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
        try {
            displayLoading();
            setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
            hideLoading();
            document.body.style.overflow = "hidden";
        } catch (error) {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
            document.body.style.overflow = "hidden";
        }
    });

    const fetchOtherData = useCallback(async (data, hn, txn) => {
        const patientData = fetchMemberByTxn(hn, txn);
        const patientRoomRequest = fetchMemberListHis(hn, txn);

        Promise.all([patientData, patientRoomRequest]).then((values) => {
            const patientResponse = values[0];
            const patientRoomResponse = values[1];

            setPatient({ ...patientResponse, ...patientRoomResponse })
            setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
            hideLoading();
        }).catch((error) => {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
        });
    });

    const fetchData = useCallback(async (formId, hn, txn) => {
        try {
            displayLoading();
            setDataForm({});
            let data = {};
            let hnValue, txnValue;
            if (formId) {
                data = await fetchConsultFormByFormId(formId, formConstants.formType);
                hnValue = data.hn;
                txnValue = data.txn;
            } else {
                data = await fetchConsultFormByTxn(txn, formConstants.formType);
                hnValue = hn;
                txnValue = txn;
            }
            await fetchOtherData(data, hnValue, txnValue);
        } catch (error) {
            hideLoading();
            displayErrorFetchPopup();
        }
    }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

    // fetch data when has formId #Edit
    useEffect(() => {
        if (!isSubForm) {
            if (formId) fetchData(formId, null, null)
        }
    }, [formId, isSubForm]);

    // fetch data when formId is null and hn,txn are not null #Add or this is subForm
    useEffect(() => {
        if (!isSubForm) {
            if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
        }
    }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

    // use for subForm
    useEffect(() => {
        if (isSubForm) {
            fetchSubFormOtherData(data, data.hn, data.txn);
        }
    }, [isSubForm, data]);

    useEffect(() => {
        if (!isSubForm) {
            if (!formId && hn && txn) fetchData(null, hn, txn)
        }
    }, [hn, txn, formId, isSubForm]);

    const validateSave = useCallback(() => {
        return true;
    }, [])

    const handleSave = useCallback(async (html) => {
        async function saveForm(paperHtml) {
            displayLoading();
            try {
                if (validateSave()) {
                    const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
                    handlePreviewOpenChange(false);
                    await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
                    hideLoading();
                    onSuccessPopupOpen(true);
                } else {
                    hideLoading();
                }
            } catch (error) {
                hideLoading();
                displayErrorSavePopup();
            }
        }
        saveForm(html);
    });

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1n,
            dataForm.data_2n,
            dataForm.data_3n,
            dataForm.data_4n,
            dataForm.data_5n,
            dataForm.data_6n,
            dataForm.data_7n,
            dataForm.data_8n,
            dataForm.data_9n
        ]);
    }, [dataForm]);

    const handleAddDataToParent = useCallback((html) => {
        onAdd(dataForm, html, getTotalScore);
    }, [dataForm, onAdd, getTotalScore]);

    return (
        <>
            {isSubForm &&
                <FormMemberProfileHeader
                    memberData={patient}
                    onSelectMember={() => { }}
                    isSelectDisabled={true}
                />}
            <div uk-grid="" className="uk-grid uk-padding-small">
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTitle label="แบบประเมินภาวะซึมเศร้า 9 คำถาม (9Q)" />
                    <FormElementLabel color={"red"} label="( คะแนนรวม : " fontWeight="bold" />
                    <FormElementLabel color={"red"} label={getTotalScore} />
                    <FormElementLabel color={"red"} label=") " fontWeight="bold" />
                </div>

                <div className="uk-width-1-1@m uk-width-1-1">
                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                        <thead>
                            <tr>
                                <th><FormElementLabel label="ในช่วง 2 สัปดาห์ที่ผ่านมารวมทั้งวันนี้ท่านมีอาการเหล่านี้บ่อยแค่ไหน" /></th>
                                <th><FormElementLabel label="ไม่มีเลย" /></th>
                                <th><FormElementLabel label="เป็นบางวัน 1-7 วัน" /></th>
                                <th><FormElementLabel label="เป็นบ่อย > 7วัน" /></th>
                                <th><FormElementLabel label="เป็นทุกวัน" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1. เบื่อ ไม่สนใจอยากทำอะไร</td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>2. ไม่สบายใจ ซึมเศร้า ท้อแท้ </td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>3. หลับยากหรือหลับๆตื่นๆหรือหลับมากไป </td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>4. เหนื่อยง่ายหรือไม่ค่อยมีแรง  </td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>5. เบื่ออาหารหรือกินมากเกินไป  </td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>6. รู้สึกไม่ดีกับตัวเอง คิดว่าตัวเองล้มเหลวหรือครอบครัวผิดหวัง  </td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>7. สมาธิไม่ดี เวลาทำอะไร เช่น ดูโทรทัศน์ ฟังวิทยุ หรือทำงานที่ต้องใช้ความตั้งใจ </td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>8. พูดช้า ทำอะไรช้าลงจนคนอื่นสังเกตเห็นได้หรือกระสับกระส่ายไม่สามารถอยู่นิ่งได้เหมือนที่เคยเป็น </td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"8n"} value={dataForm.data_8n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td>9. คิดทำร้ายตนเอง หรือคิดว่าถ้าตายไปคงจะดี </td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                                <td><FormElementRadio fieldId={"9n"} value={dataForm.data_9n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>คะแนนรวมทั้งหมด</td>
                                <td colSpan={2}><FormElementLabel color={"red"} label={getTotalScore} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                            <thead>
                                <tr>
                                    <th>คะแนนรวม</th>
                                    <th>การแปรผล</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><FormElementLabel label="< 7" /></td>
                                    <td>ไม่มีอาการของโรคซึมเศร้าหรือมีอาการของโรคซึมเศร้าระดับน้อยมาก</td>
                                </tr>
                                <tr>
                                    <td><FormElementLabel label="7-12" /></td>
                                    <td>มีอาการของโรคซึมเศร้า ระดับน้อย</td>
                                </tr>
                                <tr>
                                    <td><FormElementLabel label="13-18" /></td>
                                    <td>มีอาการของโรคซึมเศร้า ระดับปานกลาง</td>
                                </tr>
                                <tr>
                                    <td><FormElementLabel label=" ≥19  " /></td>
                                    <td>มีอาการของโรคซึมเศร้า ระดับรุนแรง</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="uk-width-1-1@m uk-width-1-1">
                        <FormElementTitle label="ถ้าคะแนน 9Q ≥ 7 ให้ประเมินแนวโน้มการฆ่าตัวตาย ด้วย 8Q" />
                    </div>
                    <div className="uk-width-1-1@m uk-width-1-1">
                        <FormElementLabel color={"red"} label=" คะแนนรวม : " fontWeight="bold" />
                        <FormElementLabel color={"red"} label={getTotalScore} />
                        <FormElementLabel color={"red"} label=" " fontWeight="bold" />
                    </div>
                </div>
                <FormActionButtonSection
                    isPreview={isPreview}
                    onOpen={() => handlePreviewOpenChange(true)}
                    onClose={() => handlePreviewOpenChange(false)}
                    onSave={handleSave}
                    onAdd={(html) => handleAddDataToParent(html)}
                    isSubForm={isSubForm}
                    formContent={
                        <Form9QPaper
                            dataForm={dataForm}
                            patient={patient}
                            profile={profile}
                        />}
                />
            </div>
        </>
    )
}

export default Form9Q;


