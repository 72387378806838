import React, { useState, useEffect } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';
import MemberProfileFormHeader from '../MemberProfileFormHeader';

const FormPhamacy = (props) => {
    

    var [dataForm, setDataForm] = useState({});

    function handleChange(i, event) {
        props.onChange(i, event);
    }

    function setData(field, valueData) {
		var datas = {...dataForm};

		datas = {...datas, ["data_" + field]:valueData};

		setDataForm(datas);
	}

	function setDataObj(data) {
		var datas = {...dataForm};

		datas = {...datas, ...data};

		setDataForm(datas);
	}


    useEffect(() => {
        props.addValue({
            formName: "form_phamacy",
            formType: "20"
        });
    }, []);

    function gotToImagePage() {
        window.location.href = 'https://telemed-kkk.ap.ngrok.io/consult/formAdd?mode=iviewer&formType=21'
    }    

    return (
        <div uk-grid="" className="uk-grid uk-padding-small">
            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementTitle label="แบบบันทึกการให้คำแนะนำการใช้ยาผ่านระบบ Telemedicine" />
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped"  >
                <thead>
                    <tr>
                        <td colSpan={9}>รายการยาที่แพทย์สั่ง </td>
                    </tr>
                    <tr>
                        <td >#</td>
                        <td >ชื่อการค้า generic</td>
                        <td >วิธีใช้</td>
                        <td >จำนวน</td>
                        <td >สถานะการปรับเปลี่ยนยา</td>
                        <td colSpan={2}>แนะนำยา</td>
                        <td >ปัญหาการใช้ยาที่พบ</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1.</td>
                        <td>พาราเซตามอน</td>
                        <td>กิน</td>
                        <td>1</td>
                        <td></td>
                        <td><FormElementRadio fieldId={"1"} value={dataForm.data_1} fieldValue={"0"} label="" onChange={handleChange}  /></td>
                        <td><FormElementRadio fieldId={"1"} value={dataForm.data_1} fieldValue={"0"} label="" onChange={handleChange}  /></td>
                        <td><FormElementTextBox fieldId={"2"} value={dataForm.data_2} label="" onChange={handleChange}  /></td>
                    </tr>
            </tbody>
            </table>
            </div>
            <div className="uk-width-1-1@m uk-width-1-1">
                <a href='/consult/formAdd?mode=iviewer&formType=21' target='_blank'>แสดงรูปยา</a>
            </div>
        </div>
    )
  }
  
  export default FormPhamacy;
  

