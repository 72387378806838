import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';

var utilHandleChange = require('./FormUtil.js').handleChange;
var utilSetData = require('./FormUtil.js').setData;
var useEffectDataForm = require('./FormUtil.js').useEffectDataForm;
var useEffectPropsDataForm = require('./FormUtil.js').useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormGAD7 = (props) => {


    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    function setData(field, valueData) {
        var datas = { ...dataForm };

        datas = { ...datas, ["data_" + field]: valueData };

        setDataForm(datas);
    }

    function setDataObj(data) {
        var datas = { ...dataForm };

        datas = { ...datas, ...data };

        setDataForm(datas);
    }

    useEffect(() => {
        props.addValue({
            formName: "form_gad7",
            formType: "40"
        });
    }, []);

    useEffect(() => {
        useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
      }, [dataForm]);
    
      useEffect(() => {
        useEffectPropsDataForm(setDataForm, props.dataForm);
      }, [props.dataForm]);

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1n,
            dataForm.data_2n,
            dataForm.data_3n,
            dataForm.data_4n,
            dataForm.data_5n,
            dataForm.data_6n,
            dataForm.data_7n,
        ]);
    }, [dataForm]);

    useEffect(() => {
        props.setScore(getTotalScore);
    }, [dataForm]);

    return (
        <div uk-grid="" className="uk-grid uk-padding-small">


            <div className="uk-width-1-1@m uk-width-1-1">
                <FormElementTitle label="GAD-7" />
            </div>

            <div className="uk-width-1-1@m uk-width-1-1">
                <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
                    <thead>
                        <tr>
                            <th>คำถาม</th>
                            <th>ไม่เลย (0 คะแนน)</th>
                            <th>บางวัน (1 คะแนน)</th>
                            <th>เกินกว่า 7 วัน ในช่วง 2 อาทิตย์ที่ผ่านมา (2 คะแนน)</th>
                            <th>เกือบทุกวัน (3 คะแนน)</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1. รู้สึกตึงเครียด วิตกกังวล หรือ กระวนกระวาย</td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"1n"} value={dataForm.data_1n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>2. ไม่สามารถหยุดหรือควบคุมความกังวลได้</td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"2n"} value={dataForm.data_2n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>3. กังวลมากเกินไปในเรื่องต่างๆ</td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"3n"} value={dataForm.data_3n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>4. ทำตัวให้ผ่อนคลายได้ยาก</td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"4n"} value={dataForm.data_4n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>5. รู้สึกกระสับกระส่ายจนไม่สามารถนั่งนิ่งๆ ได้</td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"5n"} value={dataForm.data_5n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>6. กลายเป็นคนขี้รำคาญ หรือ หงุดหงิดง่าย</td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"6n"} value={dataForm.data_6n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td>7. รู้สึกกลัวเหมือนว่าจะมีอะไรร้ายๆ เกิดขึ้น</td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"0"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"1"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"2"} onChange={handleChange} label="" /></td>
                            <td><FormElementRadio fieldId={"7n"} value={dataForm.data_7n} fieldValue={"3"} onChange={handleChange} label="" /></td>
                        </tr>
                        <tr>
                            <td >คะแนนรวมทั้งหมด</td>
                            <td colSpan={4}>{getTotalScore}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-bottom">
                        <strong>ขอให้ท่านรวบรวมคะแนนตั้งแต่ข้อที่ 1 ถึงข้อที่ 7 (นำคะแนนทั้งหมดที่ได้มาบวกกัน)</strong>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <strong>ได้ 0-9 คะแนน หมายถึง ท่านมีความวิตกกังวลในระดับเฉลี่ยหรือสูงกว่าเกณฑ์เฉลี่ยเพียงเล็กน้อย</strong>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <strong>ได้ 10-14 คะแนน หมายถึง ท่านมีความวิตกกังวลในระดับปานกลาง และควรทำแบบประเมินซ้ำในอีก 1-2 สัปดาห์</strong>
                        </div>
                        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
                            <strong>ได้ 15-21 คะแนน หมายถึง ท่านมีความวิตกกังวลในระดับสูง ควรได้รับการประเมินจากผู้เชี่ยวชาญ</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormGAD7;


