import React, { useState, useEffect, useMemo } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("../FormType/FormUtil.js").sumTotalScore;

const FormDeliveryGeneralMedicinePaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_deliverygeneralmedicine",
      formType: "27",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="3.3.1 แบบฟอร์มการติดตามการใช้ยาทั่วไปที่จัดส่งทางไปรษณีย์"
            mode="view"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px 0px 0px 0px",
            }}
            id="htmlPreviewMemberData"
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <PatientProfileIPaper memberProfile={memberProfile} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="เบอร์โทรศัพท์"
                value={dataForm.data_1}
                mode="view"
              />
              <FormElementTextBox
                label="เบอร์โทรสำรอง"
                value={dataForm.data_2}
                mode="view"
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="ห้องตรวจ"
                value={dataForm.data_3}
                mode="view"
              />
              <FormElementTextBox
                label="วันที่สั่งยา"
                value={dataForm.data_4}
                type="date"
                dateFormat="dd/MM/yyyy"
                mode="view"
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="วันที่ติดตาม"
                value={dataForm.data_5}
                type="date"
                dateFormat="dd/MM/yyyy"
                mode="view"
              />
              <FormElementTextBox
                label="เภสัชกรผู้ติดตาม"
                value={dataForm.data_6}
                mode="view"
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="วิธีการให้คำแนะนำยา" mode="view" />
              <FormElementLabel
                value={dataForm.data_37}
                mode="view"
                translate={{ 1: "วิดีโอคอล", 2: "โทรศัพท์" }}
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="ผลการติดตาม" mode="view" />
              <FormElementLabel
                value={dataForm.data_7}
                mode="view"
                translate={{
                  1: "ติดตามสำเร็จ",
                  2: "ต้องติดตามซ้ำ (โทรแล้วต้องติดตามซ้ำ)",
                  3: "ติดต่อไม่ได้ (ไม่รับสาย)",
                }}
              />
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementLabel label="ผู้ให้ข้อมูลการติดตาม" mode="view" />
              <FormElementLabel
                value={dataForm.data_8}
                mode="view"
                translate={{
                  1: "ผู้ป่วย",
                  2: "อื่นๆ",
                }}
              />
              <span hidden={dataForm.data_8 != "2"}>
                <FormElementTextBox value={dataForm.data_9} mode="view" />
              </span>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <FormElementTextBox
                label="Pharmacist note"
                value={dataForm.data_10}
                mode="view"
              />
            </div>
          </div>
          <table
            style={{
              marginTop: "8px",
              borderCollapse: "collapse",
              border: "1px solid grey",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  หัวข้อ
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ใช่
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ไม่ใช่
                </th>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  หมายเหตุ
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    background: "#EFF0F3",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                  colSpan={4}
                >
                  ความถูกต้อง สมบูรณ์ของยาที่ได้รับทางไปรษณีย์
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  1. ผู้ป่วยได้รับพัสดุหรือไม่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_11 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_11 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_12} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "4px" }}>
                    <span>2. จำนวนรายการยาครบถ้วนหรือไม่ (ยาจำนวน</span>
                    <span>
                      <FormElementTextBox
                        value={dataForm.data_13}
                        mode="view"
                      />
                    </span>
                    <span>รายการ)</span>
                  </div>
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_14 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_14 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_15} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  3. รายการยาถูกต้องตามใบรายการยาหรือไม่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_16 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_16 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_17} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  4. พัสดุยาสภาพสมบูรณ์ สติกเกอร์ฉีกขาดหรือไม่
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_18 == "1" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  {dataForm.data_18 == "2" && "/"}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_19} mode="view" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  ผู้รับการประเมิน (ผู้ป่วย/ญาติ)
                </td>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <div style={{ display: "inline-flex", gap: "8px" }}>
                    <FormElementTextBox value={dataForm.data_20} mode="view" />
                    <FormElementLabel
                      value={dataForm.data_21}
                      mode="view"
                      translate={{ 1: "ผู้ป่วย", 2: "ญาติ" }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  เภสัชกรผู้ประเมิน
                </td>
                <td
                  colSpan={4}
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid grey",
                  }}
                >
                  <FormElementTextBox value={dataForm.data_22} mode="view" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormDeliveryGeneralMedicinePaper;
