import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";

var utilHandleChange = require("./FormUtil.js").handleChange;
var utilSetData = require("./FormUtil.js").setData;
var useEffectDataForm = require("./FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm = require("./FormUtil.js").useEffectPropsDataForm;
var utilSumTotalScore = require("./FormUtil.js").sumTotalScore;

const FormIADLS = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  function setData(field, valueData) {
    utilSetData(field, valueData, setIsEditForm, setDataForm);
  }

  useEffect(() => {
    props.addValue({
      formName: "form_iadls",
      formType: "45",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  useEffect(() => {
    props.setScore(getTotalScore);
  }, [dataForm]);

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([
      dataForm.data_1n,
      dataForm.data_2n,
      dataForm.data_3n,
      dataForm.data_4n,
      dataForm.data_5n,
    ]);
  }, [dataForm]);

  return (
    <div uk-grid="" className="uk-grid-small uk-padding-small">
      <div className="uk-width-1-1@m uk-width-1-1">
        <FormElementTitle label="IADLS" />
      </div>
      <div className="uk-width-1-1@m uk-width-1-1">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped form-table">
          <thead>
            <tr>
              <th></th>
              <th>0</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Walking</td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"2"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"1n"}
                  value={dataForm.data_1n}
                  fieldValue={"3"}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>Cooking</td>
              <td>
                <FormElementRadio
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"2n"}
                  value={dataForm.data_2n}
                  fieldValue={"2"}
                  onChange={handleChange}
                />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Housework</td>
              <td>
                <FormElementRadio
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"3n"}
                  value={dataForm.data_3n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Money exchange</td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"4n"}
                  value={dataForm.data_4n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Transportation</td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"0"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"1"}
                  onChange={handleChange}
                />
              </td>
              <td>
                <FormElementRadio
                  fieldId={"5n"}
                  value={dataForm.data_5n}
                  fieldValue={"2"}
                  onChange={handleChange}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="uk-width-1-1@muk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span style={{ color: "red" }}>คะแนนรวม: {getTotalScore}/9</span>
        </div>
      </div>
    </div>
  );
};

export default FormIADLS;
