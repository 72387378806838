import React, { useState, useEffect, useMemo, useCallback } from 'react';

import FormElementTitle from '../Form/FormElementTitle';
import FormElementSubTitle from '../Form/FormElementSubTitle';
import FormElementLine from '../Form/FormElementLine';
import FormElementTextBox from '../Form/FormElementTextBox';
import FormElementBoxLine from '../Form/FormElementBoxLine';
import FormElementLabel from '../Form/FormElementLabel';
import FormElementTextArea from '../Form/FormElementTextArea';
import FormElementRadioGroup from '../Form/FormElementRadioGroup';
import FormElementRadio from '../Form/FormElementRadio';
import FormElementCheckBox from '../Form/FormElementCheckBox';
import FormElementButton from '../Form/FormElementButton';
import FormElementSpace from '../Form/FormElementSpace';
import FormElementTableGroupYesNoReason from '../Form/FormElementTableGroupYesNoReason';
import FormElementTableGroupTextBox from '../Form/FormElementTableGroupTextBox';
import FormElementTableGroupLevel from '../Form/FormElementTableGroupLevel';
import painImg from '../../../images/pain.png'
import MemberProfileFormHeader from '../MemberProfileFormHeader';

import '../../../css/dietz.css';
import FormElementDropdown from '../Form/FormElementDropdown';
import saveConsultForm from '../../API/saveConsultForm.js';
import fetchConsultFormByTxn from '../../API/fetchConsultFormByTxn.js';
import fetchConsultFormByFormId from '../../API/fetchConsultFormByFormId.js';
import fetchMemberByTxn from '../../API/fetchMemberByTxn.js';
import FormMemberProfileHeader from '../Common/FormMemberProfiileHeader.js';
import FormActionButtonSection from '../Common/FormActionButtionSection.js';
import FormRankinScalePaper from '../FormPaper/FormRankinScalePaper.js';
import fetchMemberListHis from '../../API/fetchMemberListHis.js';

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;
var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormRankinScale = (props) => {
    const formConstants = {
        formName: "form_rankinscale",
        formType: "19"
    };
    const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;
    var [dataForm, setDataForm] = useState({});
    var [isEditForm, setIsEditForm] = useState(false);

    const [isPreview, setIsPreview] = useState(false);

    function handleChange(i, event) {
        utilHandleChange(i, event, setIsEditForm, setDataForm);
    }

    const handlePreviewOpenChange = useCallback((open) => {
        setIsPreview(open);
    }, [setIsPreview])

    const displayLoading = useCallback(() => {
        onLoading(true);
    }, [onLoading]);

    const hideLoading = useCallback(() => {
        onLoading(false);
    }, [onLoading]);

    const displayErrorFetchPopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
    }, [onErrorMessageChange]);

    const displayErrorSavePopup = useCallback(() => {
        onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
    }, [onErrorMessageChange]);

    const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
        try {
            displayLoading();
            setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
            hideLoading();
            document.body.style.overflow = "hidden";
        } catch (error) {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
            document.body.style.overflow = "hidden";
        }
    });

    const fetchOtherData = useCallback(async (data, hn, txn) => {
        const patientData = fetchMemberByTxn(hn, txn);
        const patientRoomRequest = fetchMemberListHis(hn, txn);
        Promise.all([patientData, patientRoomRequest]).then((values) => {
            const patientResponse = values[0];
            const patientRoomResponse = values[1];
            setPatient({ ...patientResponse, ...patientRoomResponse });
            setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
            hideLoading();
        }).catch((error) => {
            console.error(error);
            hideLoading();
            displayErrorFetchPopup();
        });
    });

    const fetchData = useCallback(async (formId, hn, txn) => {
        try {
            displayLoading();
            setDataForm({});
            let data = {};
            let hnValue, txnValue;
            if (formId) {
                data = await fetchConsultFormByFormId(formId, formConstants.formType);
                hnValue = data.hn;
                txnValue = data.txn;
            } else {
                data = await fetchConsultFormByTxn(txn, formConstants.formType);
                hnValue = hn;
                txnValue = txn;
            }
            await fetchOtherData(data, hnValue, txnValue);
        } catch (error) {
            hideLoading();
            displayErrorFetchPopup();
        }
    }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

    // fetch data when has formId #Edit
    useEffect(() => {
        if (!isSubForm) {
            if (formId) fetchData(formId, null, null)
        }
    }, [formId, isSubForm]);

    // fetch data when formId is null and hn,txn are not null #Add or this is subForm
    useEffect(() => {
        if (!isSubForm) {
            if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
        }
    }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

    // use for subForm
    useEffect(() => {
        if (isSubForm) {
            fetchSubFormOtherData(data, data.hn, data.txn);
        }
    }, [isSubForm, data]);

    useEffect(() => {
        if (isSubForm)
            if (!formId && hn && txn) fetchData(null, hn, txn)
    }, [hn, txn, formId, isSubForm]);

    const validateSave = useCallback(() => {
        return true;
    }, [])

    const handleSave = useCallback(async (html) => {
        async function saveForm(paperHtml) {
            displayLoading();
            try {
                if (validateSave()) {
                    const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
                    handlePreviewOpenChange(false);
                    await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
                    hideLoading();
                    onSuccessPopupOpen(true);
                } else {
                    hideLoading();
                }
            } catch (error) {
                hideLoading();
                displayErrorSavePopup();
            }
        }
        saveForm(html);
    });

    const getTotalScore = useMemo(() => {
        return utilSumTotalScore([
            dataForm.data_1,
        ]);
    }, [dataForm]);

    const handleAddDataToParent = useCallback((html) => {
        onAdd(dataForm, html, getTotalScore);
    }, [dataForm, onAdd, getTotalScore]);

    return (
        <>
            {isSubForm &&
                <FormMemberProfileHeader
                    memberData={patient}
                    onSelectMember={() => { }}
                    isSelectDisabled={true}
                />}
            <div uk-grid="" className="uk-grid uk-padding-small">
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementTitle label="แบบประเมินความพิการผู้ป่วยโรคหลอดเลือดสมอง (Modified Rankin Scale)" />
                    <FormElementLabel color={"red"} label="( คะแนนรวม : " fontWeight="bold" />
                    <FormElementLabel color={"red"} label={getTotalScore} />
                    <FormElementLabel color={"red"} label=") " fontWeight="bold" />
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
                        <thead>
                            <tr>
                                <td>คะแนน</td>
                                <td>ความพิการ</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td ><FormElementRadio label="0" fieldId={"1"} value={dataForm.data_1} fieldValue={"0"} onChange={handleChange} /></td>
                                <td >ไม่มีความผิดปกติเลย (full recovery)</td>
                            </tr>
                            <tr>
                                <td ><FormElementRadio label="1" fieldId={"1"} value={dataForm.data_1} fieldValue={"1"} onChange={handleChange} /></td>
                                <td >ไม่มีความผิดปกติที่รุนแรง สามารถประกอบกิจวัตรประจำวันได้ทุกอย่าง ทำงานอาชีพได้</td>
                            </tr>
                            <tr>
                                <td ><FormElementRadio label="2" fieldId={"1"} value={dataForm.data_1} fieldValue={"2"} onChange={handleChange} /></td>
                                <td >มีความผิดปกติเล็กน้อย สามารถทำกิจวัตร ประจำวันได้ทุกอย่าง แต่ทำงานอาชีพไม่ได้</td>
                            </tr>
                            <tr>
                                <td ><FormElementRadio label="3" fieldId={"1"} value={dataForm.data_1} fieldValue={"3"} onChange={handleChange} /></td>
                                <td >มีความผิดปกติพอควร ทำกิจวัตรประจำวันได้บางอย่าง สามารถเดินได้โดยไม่ต้องมีคนช่วย</td>
                            </tr>
                            <tr>
                                <td ><FormElementRadio label="4" fieldId={"1"} value={dataForm.data_1} fieldValue={"4"} onChange={handleChange} /></td>
                                <td >มีความผิดปกติมาก ไม่สามารถทำกิจวัตรประจำวันเองโดยไม่มีคนช่วยได้ สามารถเดินได้แต่ต้องมีคนพยุง</td>
                            </tr>
                            <tr>
                                <td ><FormElementRadio label="5" fieldId={"1"} value={dataForm.data_1} fieldValue={"5"} onChange={handleChange} /></td>
                                <td >มีความผิดปกติอย่างรุนแรง ต้องนอนบนเตียง ปัสสาวะราด ต้องการการดูแลอย่างใกล้ชิด (bed ridden)</td>
                            </tr>
                            <tr>
                                <td ><FormElementRadio label="6" fieldId={"1"} value={dataForm.data_1} fieldValue={"6"} onChange={handleChange} /></td>
                                <td >ถึงแก่กรรม</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1">
                    <FormElementLabel color={"red"} label=" คะแนนรวม : " fontWeight="bold" />
                    <FormElementLabel color={"red"} label={getTotalScore} />
                    <FormElementLabel color={"red"} label=" " fontWeight="bold" />
                </div>
                <FormActionButtonSection
                    isPreview={isPreview}
                    onOpen={() => handlePreviewOpenChange(true)}
                    onClose={() => handlePreviewOpenChange(false)}
                    onSave={handleSave}
                    onAdd={(html) => handleAddDataToParent(html)}
                    isSubForm={isSubForm}
                    formContent={<FormRankinScalePaper
                        dataForm={dataForm}
                        patient={patient}
                        profile={profile}
                    />}
                />
            </div>
        </>
    )
}

export default FormRankinScale;


