import React, { useState, useEffect, useMemo } from "react";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTitle from "../Form/FormElementTitle";
import { SavingProfileIPaper, PatientProfileIPaper } from "../IPaper/FormPaperUtil";

var utilSumTotalScore = require("../Common/FormUtil.js").sumTotalScore;

const FormRankinScalePaper = (props) => {
  const { dataForm, patient, profile } = props;

  const getTotalScore = useMemo(() => {
    return utilSumTotalScore([dataForm.data_1]);
  }, [dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle
            label="แบบประเมินความพิการผู้ป่วยโรคหลอดเลือดสมอง (Modified Rankin Scale)"
            mode="view"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={patient} />
        </div>
        <table
          className="table-border uk-table uk-table-small"
          style={{
            marginTop: "14px",
            borderCollapse: "collapse",
            border: "1px solid grey",
          }}
        >
          <thead>
            <th
              style={{ borderCollapse: "collapse", border: "1px solid grey" }}
            >
              คะแนน
            </th>
            <th
              style={{ borderCollapse: "collapse", border: "1px solid grey" }}
            >
              ความพิการ
            </th>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "0" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ไม่มีความผิดปกติเลย (full recovery)
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "1" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ไม่มีความผิดปกติที่รุนแรง สามารถประกอบกิจวัตรประจำวันได้ทุกอย่าง
                ทำงานอาชีพได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "2" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีความผิดปกติเล็กน้อย สามารถทำกิจวัตร ประจำวันได้ทุกอย่าง
                แต่ทำงานอาชีพไม่ได้
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "3" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีความผิดปกติพอควร ทำกิจวัตรประจำวันได้บางอย่าง
                สามารถเดินได้โดยไม่ต้องมีคนช่วย
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "4" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีความผิดปกติมาก ไม่สามารถทำกิจวัตรประจำวันเองโดยไม่มีคนช่วยได้
                สามารถเดินได้แต่ต้องมีคนพยุง
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "5" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                มีความผิดปกติอย่างรุนแรง ต้องนอนบนเตียง ปัสสาวะราด
                ต้องการการดูแลอย่างใกล้ชิด (bed ridden)
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_1 == "6" && "/"}
              </td>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                ถึงแก่กรรม
              </td>
            </tr>
          </tbody>
        </table>
        <FormElementLabel label={`คะแนนรวม: ${getTotalScore}`} />
        <div>
          <SavingProfileIPaper profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default FormRankinScalePaper;
