import React, { useState, useEffect, useCallback } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementSubTitle from "../Form/FormElementSubTitle";
import FormElementLine from "../Form/FormElementLine";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementBoxLine from "../Form/FormElementBoxLine";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import FormElementRadioGroup from "../Form/FormElementRadioGroup";
import FormElementRadio from "../Form/FormElementRadio";
import FormElementCheckBox from "../Form/FormElementCheckBox";
import FormElementButton from "../Form/FormElementButton";
import FormElementSpace from "../Form/FormElementSpace";
import FormElementTableGroupYesNoReason from "../Form/FormElementTableGroupYesNoReason";
import FormElementTableGroupTextBox from "../Form/FormElementTableGroupTextBox";
import FormElementTableGroupLevel from "../Form/FormElementTableGroupLevel";
import painImg from "../../../images/pain.png";
import MemberProfileFormHeader from "../MemberProfileFormHeader";
import Api from "../../Api";

import "../../../css/dietz.css";
import FormElementDropdown from "../Form/FormElementDropdown";
import saveConsultForm from "../../API/saveConsultForm.js";
import fetchConsultFormByTxn from "../../API/fetchConsultFormByTxn.js";
import fetchConsultFormByFormId from "../../API/fetchConsultFormByFormId.js";
import fetchMemberByTxn from "../../API/fetchMemberByTxn.js";
import fetchFamilyItem from "../../API/fetchFamilyItem.js";
import fetchWardList from "../../API/fetchWardList.js";
import FormActionButtonSection from "../Common/FormActionButtionSection.js";
import FormGoodDeathPaper from "../FormPaper/FormGoodDeathPaper.js";
import FormMemberProfileHeader from "../Common/FormMemberProfiileHeader.js";
import fetchMemberListHis from "../../API/fetchMemberListHis.js";

var utilHandleChange = require("../Common/FormUtil.js").handleChange;
var utilTransformDataBeforeSave = require("../Common/FormUtil.js").transformDataBeforeSave;

const FormGoodDeath = (props) => {
  const formConstants = {
    formName: "form_gooddeath",
    formType: "4",
  };

  const { patient, onLoading, setPatient, formId, profile, onSuccessPopupOpen, onErrorMessageChange, isSubForm, data, onAdd, hn, txn } = props;

  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  var [familyItemsDropDownList, setFamilyItemsDropDownList] = useState([]);
  var [wardDropDownList, setWardDropDownList] = useState([]);

  const [isPreview, setIsPreview] = useState(false);

  function handleChange(i, event) {
    utilHandleChange(i, event, setIsEditForm, setDataForm);
  }

  const handlePreviewOpenChange = useCallback((open) => {
    setIsPreview(open);
  }, [setIsPreview])

  const displayLoading = useCallback(() => {
    onLoading(true);
  }, [onLoading]);

  const hideLoading = useCallback(() => {
    onLoading(false);
  }, [onLoading]);

  const displayErrorFetchPopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถดึงข้อมูลได้', isGoToMainPage: true });
  }, [onErrorMessageChange]);

  const displayErrorSavePopup = useCallback(() => {
    onErrorMessageChange({ open: true, header: 'เกิดข้อผิดพลาด', description: 'ไม่สามารถบันทึกรายการได้', isClose: true });
  }, [onErrorMessageChange]);

  const fetchSubFormOtherData = useCallback(async (data, hn, txn) => {
    displayLoading();
    const familyItemRequest = fetchFamilyItem();
    const wardListRequest = fetchWardList();

    Promise.all([familyItemRequest, wardListRequest]).then((values) => {
      const familyItemResponse = values[0];
      const wardListResponse = values[1];

      setFamilyItemsDropDownList(familyItemResponse);
      setWardDropDownList(wardListResponse);
      setDataForm((form) => ({ ...data, ...formConstants, hn, txn, patient_name: patient.patient_name }));
      hideLoading();
      document.body.style.overflow = "hidden";
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
      document.body.style.overflow = "hidden";
    });
  });

  const fetchOtherData = useCallback(async (data, hn, txn) => {
    const patientData = fetchMemberByTxn(hn, txn);
    const familyItemRequest = fetchFamilyItem();
    const wardListRequest = fetchWardList();
    const patientRoomRequest = fetchMemberListHis(hn, txn);

    Promise.all([patientData, familyItemRequest, wardListRequest, patientRoomRequest]).then((values) => {
      const patientResponse = values[0];
      const familyItemResponse = values[1];
      const wardListResponse = values[2];
      const patientRoomResponse = values[3];

      setPatient({ ...patientResponse, ...patientRoomResponse });
      setFamilyItemsDropDownList(familyItemResponse);
      setWardDropDownList(wardListResponse);
      setDataForm((form) => ({ ...form, ...data, ...formConstants, hn, txn, patient_name: patientResponse.patient_name }));
      hideLoading();
    }).catch((error) => {
      console.error(error);
      hideLoading();
      displayErrorFetchPopup();
    });
  });

  const fetchData = useCallback(async (formId, hn, txn) => {
    try {
      displayLoading();
      setDataForm({});
      let data = {};
      let hnValue, txnValue;
      if (formId) {
        data = await fetchConsultFormByFormId(formId, formConstants.formType);
        hnValue = data.hn;
        txnValue = data.txn;
      } else {
        data = await fetchConsultFormByTxn(txn, formConstants.formType);
        hnValue = hn;
        txnValue = txn;
      }
      await fetchOtherData(data, hnValue, txnValue);
    } catch (error) {
      hideLoading();
      displayErrorFetchPopup();
    }
  }, [displayLoading, hideLoading, displayErrorFetchPopup, setDataForm, fetchOtherData]);

  // fetch data when has formId #Edit
  useEffect(() => {
    if (!isSubForm) {
      if (formId) fetchData(formId, null, null)
    }
  }, [formId, isSubForm]);

  // fetch data when formId is null and hn,txn are not null #Add or this is subForm
  useEffect(() => {
    if (!isSubForm) {
      if (!formId && !hn && !txn && patient.hn && patient.txn) fetchData(null, patient.hn, patient.txn)
    }
  }, [patient.hn, patient.txn, formId, isSubForm, hn, txn]);

  // use for subForm
  useEffect(() => {
    if (isSubForm) {
      fetchSubFormOtherData(data, data.hn, data.txn);
    }
  }, [isSubForm, data]);

  useEffect(() => {
    if (!isSubForm) {
      if (!formId && hn && txn) fetchData(null, hn, txn)
    }
  }, [hn, txn, formId, isSubForm]);

  const validateSave = useCallback(() => {
    return true;
  }, [])

  const handleSave = useCallback(async (html) => {
    async function saveForm(paperHtml) {
      displayLoading();
      try {
        if (validateSave()) {
          const saveData = utilTransformDataBeforeSave({ ...dataForm }, []);
          handlePreviewOpenChange(false);
          await saveConsultForm(saveData, profile.loginToken, dataForm.form_id, profile.consultId, profile.doctorId, paperHtml, patient);
          hideLoading();
          onSuccessPopupOpen(true);
        } else {
          hideLoading();
        }
      } catch (error) {
        hideLoading();
        displayErrorSavePopup();
      }
    }
    saveForm(html);
  });

  const handleAddDataToParent = useCallback((html) => {
    onAdd(dataForm, html)
  }, [dataForm, onAdd]);

  return (
    <>
      {isSubForm &&
        <FormMemberProfileHeader
          memberData={patient}
          onSelectMember={() => { }}
          isSelectDisabled={true}
        />}
      <div uk-grid="" className="uk-grid-small uk-padding-small">
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTitle label="แบบประเมินการตายดี (Good death)" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="ผู้ตอบแบบประเมินมีความเกี่ยวข้องกับผู้ป่วย" />
        </div>

        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementDropdown
            fieldId={"1"}
            value={dataForm?.data_1 || ''}
            label="หอผู้ป่วย"
            onChange={handleChange}
            width="100"
            dataList={[{ value: "", label: "เลือกหอผู้ป่วย" }].concat(
              wardDropDownList
            )}
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <span className="formTextBoxLabel">สถานที่เสียชีวิต</span>
          <div className="dietz-display-inline-container">
            <FormElementRadio
              fieldId={"2"}
              fieldValue={"1"}
              value={dataForm.data_2}
              onChange={handleChange}
              label="ที่บ้าน"
            />
            <FormElementRadio
              fieldId={"2"}
              fieldValue={"2"}
              value={dataForm.data_2}
              onChange={handleChange}
              label="ที่โรงพยาบาล"
            />
            <FormElementRadio
              fieldId={"2"}
              fieldValue={"3"}
              value={dataForm.data_2}
              onChange={handleChange}
              label="อื่นๆ"
            />
          </div>
          <span hidden={dataForm.data_2 != "3"}>
            <FormElementTextBox
              fieldId={"13"}
              value={dataForm.data_13}
              placeholder="ระบุ"
              onChange={handleChange}
              width="100"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementTextBox
            fieldId={"3"}
            value={dataForm.data_3}
            label="ผู้ตอบแบบประเมิน"
            onChange={handleChange}
            width="100"
          />
          <FormElementDropdown
            fieldId={"4"}
            value={dataForm?.data_4 || ""}
            label="ความสัมพันธ์"
            onChange={handleChange}
            dataList={[{ value: "", label: "เลือกความสัมพันธ์" }].concat(
              familyItemsDropDownList
            )}
            width="100"
          />
          <span hidden={dataForm.data_4 != "99"}>
            <FormElementTextBox
              fieldId={"12"}
              value={dataForm.data_12}
              placeholder="ระบุ"
              onChange={handleChange}
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLine />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="กรุณาทําเครื่องหมาย  ในช่องที่ตรงกับความเป็นจริง" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="1. ผู้ป่วยได้เสียชีวิตในสถานที่ตรงกับความต้องการของผู้ป่วย" />
        </div>

        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"5"}
            value={dataForm.data_5}
            fieldValue={"1"}
            onChange={handleChange}
            name="data2"
            label="ใช่"
          />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom">
          <FormElementRadio
            fieldId={"5"}
            value={dataForm.data_5}
            fieldValue={"2"}
            onChange={handleChange}
            name="data2"
            label="ไม่ใช่"
          />
          <span className={dataForm.data_5 == "2" ? "form-show" : "form-hidden"}>
            <FormElementTextBox
              fieldId={"6"}
              value={dataForm.data_6}
              label="สถานที่ที่ผู้ป่วยต้องการเสียชีวิต คือ"
              onChange={handleChange}
              width="100"
            />
          </span>
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
          style={{ marginLeft: "50px" }}
        >
          <span className={dataForm.data_5 == "2" ? "form-show" : "form-hidden"}>
            <FormElementTextBox
              fieldId={"7"}
              value={dataForm.data_7}
              label="สถานที่ที่ผู้ป่วยได้เสียชีวิตจริง คือ"
              onChange={handleChange}
              width="100"
            />
          </span>
        </div>
        <div
          className="uk-width-1-1@m uk-width-1-1 uk-padding-small uk-padding-remove-bottom"
          style={{ marginLeft: "50px" }}
        >
          <span className={dataForm.data_5 == "2" ? "form-show" : "form-hidden"}>
            <FormElementTextArea
              fieldId={"8"}
              value={dataForm.data_8}
              rows={3}
              cols={70}
              onChange={handleChange}
              label="มีสาเหตุเพราะ"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLine />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementLabel label="2. ในความรู้สึกของญาติ คิดว่าผู้ป่วยได้จากไปอย่างสงบ โดยที่อาการต่างๆได้รับการดูแลหรือไม่" />
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementRadio
            fieldId={"9"}
            value={dataForm.data_9}
            fieldValue={"1"}
            onChange={handleChange}
            name="data3"
            label="ใช่"
          />
          <span className={dataForm.data_9 == "1" ? "form-show" : "form-hidden"}>
            <span
              className="formTextBoxLabel"
              style={{ marginLeft: "30px", marginRight: "20px" }}
            >
              ระดับ
            </span>
            <FormElementRadio
              fieldId={"11"}
              value={dataForm.data_11}
              fieldValue={"1"}
              onChange={handleChange}
              name="data3"
              label="ดีที่สุด"
            />
            <FormElementRadio
              fieldId={"11"}
              value={dataForm.data_11}
              fieldValue={"2"}
              onChange={handleChange}
              name="data3"
              label="ดีมาก"
            />
            <FormElementRadio
              fieldId={"11"}
              value={dataForm.data_11}
              fieldValue={"3"}
              onChange={handleChange}
              name="data3"
              label="ดี"
            />
            <FormElementRadio
              fieldId={"11"}
              value={dataForm.data_11}
              fieldValue={"4"}
              onChange={handleChange}
              name="data3"
              label="พอใช้"
            />
            <FormElementRadio
              fieldId={"11"}
              value={dataForm.data_11}
              fieldValue={"5"}
              onChange={handleChange}
              name="data3"
              label="น้อย"
            />
          </span>
        </div>
        <div className="uk-width-1-1@m uk-width-1-1">
          <FormElementRadio
            fieldId={"9"}
            value={dataForm.data_9}
            fieldValue={"0"}
            onChange={handleChange}
            name="data3"
            label="ไม่ใช่"
          />
          <span className={dataForm.data_9 == "0" ? "form-show" : "form-hidden"}>
            <FormElementTextBox
              fieldId={"10"}
              value={dataForm.data_10}
              onChange={handleChange}
              label="เพราะ (ต้องระบุ)"
              width="100"
            />
          </span>
          {/* <FormElementTextBox fieldId={""} value={dataForm.data_} width="100" /> */}
        </div>
        <FormActionButtonSection
          isPreview={isPreview}
          onOpen={() => handlePreviewOpenChange(true)}
          onClose={() => handlePreviewOpenChange(false)}
          onSave={handleSave}
          onAdd={(html) => handleAddDataToParent(html)}
          isSubForm={isSubForm}
          formContent={
            <FormGoodDeathPaper
              dataForm={dataForm}
              patient={patient}
              profile={profile}
              familyItemsDropDown={familyItemsDropDownList}
              wardDropDown={wardDropDownList}
            />}
        />
      </div>
    </>
  );
};

export default FormGoodDeath;
