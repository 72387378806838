import React, { useState, useEffect } from "react";

import FormElementTitle from "../Form/FormElementTitle";
import FormElementTextBox from "../Form/FormElementTextBox";
import FormElementLabel from "../Form/FormElementLabel";
import FormElementTextArea from "../Form/FormElementTextArea";
import { SavingProfileIPaper, PatientProfileIPaper } from "./FormPaperUtil";

import "../../../css/dietz.css";

var useEffectDataForm = require("../FormType/FormUtil.js").useEffectDataForm;
var useEffectPropsDataForm =
  require("../FormType/FormUtil.js").useEffectPropsDataForm;

const FormACSTeleFollowUpPaper = (props) => {
  var [dataForm, setDataForm] = useState({});
  var [isEditForm, setIsEditForm] = useState(false);
  const { memberProfile, profile } = props;

  useEffect(() => {
    props.addValue({
      formName: "form_acstelefollowup",
      formType: "8",
    });
  }, []);

  useEffect(() => {
    useEffectDataForm(isEditForm, dataForm, setIsEditForm, props.addValue);
  }, [dataForm]);

  useEffect(() => {
    useEffectPropsDataForm(setDataForm, props.dataForm);
  }, [props.dataForm]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "800px",
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 0px 0px 0px",
            justifyContent: "center",
          }}
        >
          <FormElementTitle label="ACS Tele Follow Up" mode="view" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px 0px 0px",
          }}
          id="htmlPreviewMemberData"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <PatientProfileIPaper memberProfile={memberProfile} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <span style={{ paddingRight: "10px" }}>Diagnosis: </span>
            <FormElementLabel
              value={dataForm.data_1}
              mode="view"
              translate={{ 1: "STEMI", 2: "NSTEMI", 3: "unsatble angina" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <span style={{ paddingRight: "10px" }}>Underlying diseuse: </span>
            <FormElementTextBox value={dataForm.data_2} mode="view" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <span style={{ paddingRight: "10px" }}>LVEF: </span>

            <FormElementTextBox value={dataForm.data_3} mode="view" />
            <span style={{ paddingRight: "10px" }}>% </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <span style={{ paddingRight: "10px" }}>Last admission: </span>

            <FormElementTextBox
              value={dataForm.data_4}
              type="date"
              mode="view"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <span style={{ paddingRight: "10px" }}>
              ACS Tele follow up date of:
            </span>

            <FormElementTextBox
              value={dataForm.data_5}
              type="date"
              mode="view"
            />
            <span style={{ paddingRight: "10px" }}>Follow-up times</span>
            <FormElementTextBox value={dataForm.data_101n} mode="view" />
          </div>
        </div>
        <table style={{ borderCollapse: "collapse", border: "1px solid grey" }}>
          <thead>
            <tr>
              <th
                style={{
                  background: "#EFF0F3",
                  width: "300px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Topics
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  width: "250px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Nursing Management
              </th>
              <th
                style={{
                  background: "#EFF0F3",
                  width: "250px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                Comment
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>1. Knowlegde of diseuse and treatment plan</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_6}
                    mode="view"
                    translate={{ 1: "Yes", 2: "No" }}
                    padding="0px 10px 0px 40px"
                  />
                  {dataForm.data_6 == "1" && (
                    <FormElementTextBox
                      value={dataForm.data_7}
                      label=""
                      mode="view"
                    />
                  )}
                  {dataForm.data_6 == "2" && (
                    <FormElementTextBox
                      value={dataForm.data_8}
                      label=""
                      mode="view"
                    />
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_9}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_10}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                {dataForm.data_11 == "1" && (
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      2. &nbsp;&nbsp;&nbsp;
                      <FormElementLabel
                        value={dataForm.data_11}
                        translate={{ 2: "ER Visit", 1: "Re-admission" }}
                        mode="view"
                      />
                    </div>
                    <span hidden={dataForm.data_101 != "1"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          paddingLeft: "30px",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_13}
                          type="date"
                          label="Date: "
                          mode="view"
                        />
                        <span style={{ paddingLeft: "10px" }}></span>
                        <FormElementTextBox
                          value={dataForm.data_14}
                          label="Hospital: "
                          mode="view"
                        />
                      </div>
                      <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                        <FormElementLabel
                          value={dataForm.data_12}
                          translate={{
                            1: "Cardiac caused",
                            2: "Non-cardiac caused",
                          }}
                          mode="view"
                        />
                        <span hidden={dataForm.data_12 != "1"}>
                          <FormElementTextBox
                            value={dataForm.data_115}
                            mode="view"
                          />
                        </span>
                        <span hidden={dataForm.data_12 != "2"}>
                          <FormElementTextBox
                            value={dataForm.data_116}
                            mode="view"
                          />
                        </span>
                      </div>
                    </span>
                  </React.Fragment>
                )}
                {dataForm.data_11 == "2" && (
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      2. &nbsp;&nbsp;&nbsp;
                      <FormElementLabel
                        value={dataForm.data_11}
                        translate={{ 2: "ER Visit", 1: "Re-admission" }}
                        mode="view"
                      />
                    </div>
                    <span hidden={dataForm.data_102 != "1"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          paddingLeft: "30px",
                        }}
                      >
                        <FormElementTextBox
                          value={dataForm.data_103}
                          type="date"
                          label="Date: "
                          mode="view"
                        />
                        <span style={{ paddingLeft: "10px" }}></span>
                        <FormElementTextBox
                          value={dataForm.data_104}
                          label="Hospital: "
                          mode="view"
                        />
                      </div>
                      <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                        <FormElementLabel
                          value={dataForm.data_112}
                          translate={{
                            1: "Cardiac caused",
                            2: "Non-cardiac caused",
                          }}
                          mode="view"
                        />
                        <span hidden={dataForm.data_112 != "1"}>
                          <FormElementTextBox
                            value={dataForm.data_117}
                            mode="view"
                          />
                        </span>
                        <span hidden={dataForm.data_112 != "2"}>
                          <FormElementTextBox
                            value={dataForm.data_118}
                            mode="view"
                          />
                        </span>
                      </div>
                    </span>
                  </React.Fragment>
                )}
                {!dataForm.data_11 && <div>2. Re-admission/ER Visit</div>}
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_15}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_16}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr
            // hidden={
            //   dataForm.data_17 != "1" &&
            //   dataForm.data_18 != "1" &&
            //   dataForm.data_19 != "1" &&
            //   dataForm.data_20 != "1" &&
            //   dataForm.data_21 != "1" &&
            //   dataForm.data_22 != "1" &&
            //   dataForm.data_23 != "1" &&
            //   dataForm.data_24 != "1"
            // }
            >
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>3. Abnormal symptoms</div>
                <div style={{ paddingLeft: "40px" }}>
                  {dataForm.data_17 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Chest pain"
                        padding="10px 0px 0px 0px"
                      />
                    </div>
                  )}
                  {dataForm.data_18 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="dyspenea"
                        padding="10px 0px 0px 0px"
                      />
                    </div>
                  )}
                  {dataForm.data_19 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="PND"
                        padding="10px 0px 0px 0px"
                      />
                    </div>
                  )}
                  {dataForm.data_20 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="orthopnea"
                        padding="10px 0px 0px 0px"
                      />
                    </div>
                  )}

                  {dataForm.data_21 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="edema"
                        padding="10px 0px 0px 0px"
                      />
                    </div>
                  )}

                  {dataForm.data_22 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label=" bleeding/hematoma/pain/บวม/แดง/ร้อน"
                        padding="10px 0px 0px 0px"
                      />
                    </div>
                  )}

                  {dataForm.data_23 == "1" && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label=" Orthostatic hypotension"
                        padding="10px 0px 0px 0px"
                      />
                    </div>
                  )}
                </div>
                {dataForm.data_24 == "1" && (
                  <div style={{ paddingTop: "10px" }}>
                    <FormElementLabel mode="view" label="Other: " />
                    <FormElementTextBox
                      value={dataForm.data_25}
                      label=""
                      mode="view"
                    />
                  </div>
                )}
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_26}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_27}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>

            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>4. Recognize abnormal symptoms and basic self care</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_28}
                    mode="view"
                    translate={{ 1: "Yes", 2: "No" }}
                    padding="0px 10px 0px 40px"
                  />
                  {dataForm.data_28 == "1" && (
                    <FormElementTextBox
                      value={dataForm.data_29}
                      label=""
                      mode="view"
                    />
                  )}
                  {dataForm.data_28 == "2" && (
                    <FormElementTextBox
                      value={dataForm.data_30}
                      label=""
                      mode="view"
                    />
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_31}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_32}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>5. Medication</div>
                <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                  <FormElementLabel
                    value={dataForm.data_33}
                    translate={{
                      1: "Well adherence and have knowledge of medicine",
                      2: "Good adherence",
                      3: "Non adherence to medication",
                    }}
                    mode="view"
                  />
                </div>
                <div>
                  {dataForm.data_34 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Forget to take medicine"
                      />
                    </div>
                  )}
                  {dataForm.data_35 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Buy self medication"
                      />
                    </div>
                  )}
                  {dataForm.data_36 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Stop taking medicine"
                      />
                    </div>
                  )}
                  {dataForm.data_37 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Self medication adjustment"
                      />
                    </div>
                  )}
                  {dataForm.data_38 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Taking medicine incorrectly"
                      />
                    </div>
                  )}

                  {dataForm.data_39 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel mode="view" label="NSAIDs used" />
                    </div>
                  )}
                  {dataForm.data_40 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel mode="view" label="Herb used" />
                    </div>
                  )}

                  {dataForm.data_41 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Improper adjustment of diuretics"
                      />
                    </div>
                  )}

                  {dataForm.data_42 && (
                    <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                      <FormElementLabel
                        mode="view"
                        label="Improper use of sublingual medicine"
                      />
                    </div>
                  )}

                  {dataForm.data_43 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                        paddingLeft: "40px",
                      }}
                    >
                      <FormElementLabel
                        mode="view"
                        label="Other"
                        padding="0px 10px 0px 0px"
                      />
                      <FormElementTextBox
                        value={dataForm.data_44}
                        label=""
                        mode="view"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_45}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_46}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>6. Exercise</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 0px 0px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <FormElementLabel
                      value={dataForm.data_105}
                      mode="view"
                      translate={{
                        1: "Walking program",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_106}
                      mode="view"
                      translate={{
                        1: "Calisthenic exercise (warm-up-cool-down)",
                      }}
                    />
                    <FormElementLabel
                      value={dataForm.data_107}
                      mode="view"
                      translate={{
                        1: "Other:",
                      }}
                    />
                    {dataForm.data_107 == "1" && (
                      <FormElementTextBox
                        value={dataForm.data_48}
                        label=""
                        mode="view"
                      />
                    )}
                  </div>
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_49}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_50}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>7. activity</div>
                <div style={{ paddingTop: "10px", paddingLeft: "40px" }}>
                  <FormElementTextBox
                    value={dataForm.data_51}
                    label="7.1 Pre D/C (METs): "
                    mode="view"
                  />
                  <div style={{ display: "inline-flex", gap: "4px" }}>
                    <FormElementLabel
                      value={dataForm.data_114}
                      translate={{ 1: "น้อยกว่า", 2: "มากกว่า" }}
                      mode="view"
                    />
                    {dataForm.data_114 == "1" && (
                      <FormElementTextBox
                        value={dataForm.data_113}
                        mode="view"
                      />
                    )}
                    {dataForm.data_114 == "2" && (
                      <FormElementTextBox
                        value={dataForm.data_108}
                        mode="view"
                      />
                    )}
                  </div>

                  <FormElementTextBox
                    value={dataForm.data_52}
                    label="7.2 F/U (METs): "
                    mode="view"
                  />
                  <div style={{ display: "inline-flex", gap: "4px" }}>
                    <FormElementLabel
                      value={dataForm.data_109}
                      translate={{ 1: "น้อยกว่า", 2: "มากกว่า" }}
                      mode="view"
                    />
                    {dataForm.data_109 == "1" && (
                      <FormElementTextBox
                        value={dataForm.data_111}
                        mode="view"
                      />
                    )}
                    {dataForm.data_109 == "2" && (
                      <FormElementTextBox
                        value={dataForm.data_110}
                        mode="view"
                      />
                    )}
                  </div>
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_53}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_54}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>8. Return to work</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    paddingLeft: "40px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_55}
                    translate={{ 1: "Yes", 0: "No" }}
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                  {dataForm.data_55 == "1" && (
                    <FormElementTextBox
                      value={dataForm.data_56}
                      label=""
                      mode="view"
                    />
                  )}
                  {dataForm.data_55 == "0" && (
                    <FormElementTextBox
                      value={dataForm.data_63}
                      label=""
                      mode="view"
                    />
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_57}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_58}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>9. Psychosocial problem</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "10px",
                    paddingLeft: "40px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_59}
                    translate={{
                      1: "Yes",
                      2: "No",
                    }}
                    mode="view"
                    padding="0xp 10px 0px 10px"
                  />
                  <span style={{ paddingLeft: "10px" }}></span>
                  {dataForm.data_95 == "1" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      <span style={{ paddingLeft: "10px" }}></span>

                      <FormElementLabel
                        mode="view"
                        value={dataForm.data_95}
                        translate={{
                          1: "Anxiety",
                          2: "Depression",
                          3: "Sleeping disorder",
                          4: "Other: ",
                        }}
                        padding="0xp 10px 0px 10px"
                      />
                    </div>
                  )}
                  {dataForm.data_96 == "1" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      <span style={{ paddingLeft: "10px" }}></span>

                      <FormElementLabel
                        mode="view"
                        value={dataForm.data_96}
                        translate={{
                          1: "Depression",
                          3: "Sleeping disorder",
                          4: "Other: ",
                        }}
                        padding="0xp 10px 0px 10px"
                      />
                    </div>
                  )}
                  {dataForm.data_97 == "1" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      <span style={{ paddingLeft: "10px" }}></span>

                      <FormElementLabel
                        mode="view"
                        value={dataForm.data_97}
                        translate={{
                          1: "Sleeping disorder",
                          4: "Other: ",
                        }}
                        padding="0xp 10px 0px 10px"
                      />
                    </div>
                  )}
                  {dataForm.data_98 == "1" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                      }}
                    >
                      <span style={{ paddingLeft: "10px" }}></span>

                      <FormElementLabel
                        mode="view"
                        value={dataForm.data_98}
                        translate={{
                          1: "Other:",
                        }}
                        padding="0xp 10px 0px 10px"
                      />
                      <span style={{ paddingLeft: "10px" }}></span>

                      <FormElementTextBox
                        value={dataForm.data_60}
                        label=""
                        mode="view"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <span style={{ paddingLeft: "20px" }}></span>

                <FormElementTextArea
                  value={dataForm.data_61}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_62}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>10. smoking cessation</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    paddingLeft: "40px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_64}
                    translate={{
                      1: "No Smoking",
                      2: "Smoking",
                      3: "Quit Smoking",
                      4: "Current Smoking: ",
                    }}
                    padding="0px 10px 0px 0px"
                    mode="view"
                  />
                  {dataForm.data_99 == "1" && (
                    <FormElementLabel
                      value={dataForm.data_99}
                      translate={{
                        1: "Quit Smoking",
                        2: "Current Smoking: ",
                      }}
                      padding="0px 10px 0px 0px"
                      mode="view"
                    />
                  )}
                  {dataForm.data_99 == "2" && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <FormElementLabel
                        value={dataForm.data_100}
                        translate={{
                          1: "Current Smoking: ",
                        }}
                        padding="0px 10px 0px 0px"
                        mode="view"
                      />
                      <FormElementTextBox
                        value={dataForm.data_65}
                        label=""
                        mode="view"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_66}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_67}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>11. Risk factor control and secondary prevention</div>
                <div>
                  {dataForm.data_68 && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="diet"
                        padding="0px 10px 0px 40px"
                      />
                    </div>
                  )}
                  {dataForm.data_69 && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="exercise"
                        padding="0px 10px 0px 40px"
                      />
                    </div>
                  )}
                  {dataForm.data_70 && (
                    <div style={{ paddingTop: "10px" }}>
                      <FormElementLabel
                        mode="view"
                        label="medication"
                        padding="0px 10px 0px 40px"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_71}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_72}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>12. Diet</div>
                <div style={{ padding: "10px 0px 0px 40px" }}>
                  <FormElementLabel
                    value={dataForm.data_73}
                    translate={{
                      1: "Knowledge of diet suitable for disease",
                    }}
                    mode="view"
                  />
                </div>
                <div style={{ padding: "10px 0px 0px 40px" }}>
                  <FormElementLabel
                    value={dataForm.data_119}
                    translate={{
                      1: "Good adherence",
                    }}
                    mode="view"
                  />
                </div>
                <div style={{ padding: "10px 0px 0px 40px" }}>
                  <FormElementLabel
                    value={dataForm.data_120}
                    translate={{
                      1: "Non adherence to dietary recommendation",
                    }}
                    mode="view"
                  />
                  <span hidden={dataForm.data_120 != "1"}>
                    <FormElementTextBox value={dataForm.data_121} mode="view" />
                  </span>
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_74}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_75}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>13. An appointment for next follow up</div>
                <div style={{ padding: "10px 0px 0px 40px" }}>
                  <FormElementLabel
                    value={dataForm.data_76}
                    mode="view"
                    translate={{ 1: "Yes", 2: "No" }}
                  />
                  {dataForm.data_76 == "1" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FormElementTextBox
                        value={dataForm.data_77}
                        type="date"
                        label="Date: "
                        mode="view"
                      />
                      <FormElementTextBox
                        value={dataForm.data_78}
                        label="Hospital: "
                        mode="view"
                      />
                    </div>
                  )}
                  {dataForm.data_76 == "2" && (
                    <FormElementTextBox
                      value={dataForm.data_79}
                      label=""
                      mode="view"
                    />
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_80}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_81}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>14. มีคำถามเพิ่มเติมหรือสิ่งสงสัยใดๆหรือไม่</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    paddingLeft: "40px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_82}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                  {dataForm.data_82 == "1" && (
                    <FormElementTextBox
                      value={dataForm.data_83}
                      label=""
                      mode="view"
                    />
                  )}
                  {dataForm.data_82 == "2" && (
                    <FormElementTextBox
                      value={dataForm.data_84}
                      label=""
                      mode="view"
                    />
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_85}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_86}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>15. รับทราบให้ฉีด Influenza vaccine ทุกปี</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    paddingLeft: "40px",
                  }}
                >
                  <FormElementLabel
                    value={dataForm.data_87}
                    translate={{ 1: "Yes", 2: "No" }}
                    mode="view"
                    padding="0px 10px 0px 0px"
                  />
                  {dataForm.data_87 == "1" && (
                    <FormElementTextBox
                      value={dataForm.data_88}
                      label=""
                      mode="view"
                    />
                  )}
                  {dataForm.data_87 == "2" && (
                    <FormElementTextBox
                      value={dataForm.data_89}
                      label=""
                      mode="view"
                    />
                  )}
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_90}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_91}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{ borderCollapse: "collapse", border: "1px solid grey" }}
              >
                <div>
                  16. ความพึงพอใจในการโทรศัพท์ติดตามอาการและให้ คำแนะนำ (1-5
                  คะแนน)
                </div>
                <div style={{ padding: "10px 0px 0px 40px" }}>
                  <FormElementLabel
                    value={dataForm.data_92}
                    translate={{
                      1: "พึงพอใจมากที่สุด (5 คะแนน)",
                      2: "พึงพอใจมาก (4 คะแนน)",
                      3: "พึงพอใจปานกลาง (3 คะแนน)",
                      4: "พึงพอใจเล็กน้อย (2 คะแนน)",
                      5: "ไม่พึงพอใจ (1 คะแนน)",
                    }}
                    mode="view"
                  />
                </div>
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_93}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
              <td
                style={{
                  maxWidth: "200px",
                  borderCollapse: "collapse",
                  border: "1px solid grey",
                }}
              >
                <FormElementTextArea
                  value={dataForm.data_94}
                  mode="view"
                  width="100"
                  rows="5"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <span hidden={!dataForm.form_id}>
          <FormElementTextArea
            label="เหตุผลในการแก้ไข"
            value={dataForm.data_122}
            mode="view"
          />
        </span>
        <SavingProfileIPaper profile={profile} />
      </div>
    </div>
  );
};

export default FormACSTeleFollowUpPaper;
